import React, { Component } from "react";
import { connect } from "react-redux";
import { MenuPromocion } from "./promocion/PromocionMenu";
import {
	mostrarModulo,
	actualizarMensaje,
} from "../../redux/actions/actGlobal";
import {
	mostrarModalCrearPromocion,
	mostrarModalDetallePromocion,
	mostrarModalEditarPromocion,
	actualizarDataPromocion,
	mostrarModalEliminarPromocion,
	mostrarModalCrearVisita,
	mostrarModalEditarVisita,
	mostrarModalDetalleVisita,
	mostrarModalGestionarVisita,
	actualizarRenderVisitas,
	actualizarDataVisitas,
	mostrarModalInforme,
	mostrarModalFiltrosColegio, //Filtro
	mostrarModalFiltrosVisita, //Filtro
} from "../../redux/actions/actPromocion";
import colegio from "../../global/imagenes/colegio.png";
import visita from "../../global/imagenes/visita.png";
import visita_peque from "../../global/imagenes/localizacion.png";
import PromocionCrear from "./promocion/PromocionCrear";
import PromocionDetalle from "./promocion/PromocionDetalle";
import PromocionFiltrar from "./promocion/PromocionFiltrar";
import VisitaDetalle from "./promocion/VisitaDetalle";
import VisitaGestionar from "./promocion/VisitaGestionar";
import PromocionEditar from "./promocion/PromocionEditar";
import VisitaCrear from "./promocion/VisitaCrear";
import VisitaEditar from './promocion/VisitaEditar';
import VisitaFiltrar from "./promocion/VisitaFiltrar";
import VisitaCalendario from "./promocion/VisitaCalendario";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from "../../global/imagenes/emma_w.png";
import PropTypes from "prop-types";
import ListarDatos from "../general/ListarDatos";
import moment from "moment";
import ConfirmarAccion from "../general/ConfirmarAccion";
import {
	BtnDetalle,
	BtnEditar,
	BtnEliminar,
	BtnGestionar,
	BtnVer,
	BtnRegresar,
  BtnValidar,
  BtnAgregar,
  BtnAcciones
} from "../general/BotonesAccion";
import {
	consulta,
	mostrarError,
	crear_form_data,
	formulario,
	generarFiltros,
} from "../../global/js/funciones";
import {
	Grid,
	Paper,
	Typography,
	Card,
	CardHeader,
	CardContent,
	Avatar,
} from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import fechaIcon from "../../global/imagenes/calendar.png";
import recordatorio from "../../global/imagenes/recordatorio.png";
import { obtenerValoresFiltros } from "../../global/js/funciones";
import { TIPO_INFORME } from './helper';
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import colegio2 from '../../global/imagenes/colegio2.png';
import reportIcon from '../../global/imagenes/report.png';
import ListarTarjetas from '../general/ListarTarjetas';
import PromocionInforme from "./promocion/PromocionInforme";
import PromocionSeguimiento from "./promocion/PromocionSeguimiento";

export class PromocionAdministrar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			historial: [],
			datosNoti: [],
			notificaciones: [],
			enNotificacion: "",
			filtros_visitas: [],
			procesoActual: {},
			procesoActualVisita: {},
			cargando: true,
			menu: true,
			seleccion: "agregar",
			activeStep: 0,
			nombreColegio: "",
			direccion: "",
			nombreRector: "",
			fax: "",
			nombreContacto: "",
			telefonoContacto: "",
			correo: "",
			telefonos: "",
			paginaWeb: "",
			cargoContacto: "",
			id_promocion: 0,
			promocion: [],
			Tipo: "",
			tipos: [],
			barrio: "",
			barrios: [],
			departamento: "",
			departamentos: [],
			ciudad: "",
			historialVisita: [],
			numero_estudiante: "",
			lugar: "",
			fecha_registro: null,
			hora_inicio: null,
			hora_fin: null,
			responsable: "",
			colegio: "",
			colegios: [],
			persona: {
				id: 0,
				nombre: "",
			},
			datos: [],
			cargandoNotif: true,
			datosFiltro: '',
			visitas: [],
			informes: [],
			administrador: false,
      informe: { 'nombre': '', 'codigo': '' },
      modalSeguimiento: false,
      dataColegio: {}
		};
	}

	async componentDidMount() {
		// this.setState({ cargando: true });

		let { mostrarModulo } = this.props;
		mostrarModulo({ ruta: "/promocion", nombre: "Promoción" });
		// await this.obtenerPromocion();
		await this.obtenerAlertas();
		// await this.obtenerHistorialColegio();
		// await this.obtenerHistorialVisita();
		// this.detalleNotificacion();
		this.esAdministrador()
		// this.setState({ cargando: false });
	}

	async componentDidUpdate({ }, { seleccion }) {
		let { seleccion: seleccionNew, enNotificacion: enNotificacionNew } =
			this.state;
		if (seleccion !== seleccionNew) {
			if (seleccionNew === "colegio") {
				await this.obtenerHistorialColegio();
			} else {
				if (seleccionNew === "visita" && enNotificacionNew !== "si") {
					await this.obtenerHistorialVisita();
				} else if (seleccionNew === "visita") {
					await this.obtenerHistorialVisita();
				}
				else if (seleccionNew === "calendario") {
					await this.obtenerHistorialVisita();
					this.pintarVisitasCalendario()
				} else if (seleccionNew === 'informes') {
					await this.obtenerInformes();
				}
			}
		}
	}


	obtenerInformes = async () => {
		this.setState({ cargando: true });
		let informes = await obtenerValoresFiltros([{
			'llave': 'generica', 'valor': TIPO_INFORME
		}, { 'llave': 'valora', 'valor': '1' }])
		this.setState({ cargando: false, informes });
	}

	async obtenerPromocion() {
		let { actualizarDataPromocion } = this.props;
		// this.setState({ cargando: true });

		return new Promise((resolve) => {
			consulta(
				`api/v1.0/promocion/listar`,
				null,
				null,
				(error, estado, resp) => {
					if (estado === 200) {
						actualizarDataPromocion(resp);
						// this.setState({ cargando: false });
						resolve(estado === 200 && !error ? resp : null);
					}
				}
			);
		});
	}

	async obtenerVisita() {
		let { actualizarDataPromocion } = this.props;
		// this.setState({ cargando: true });
		return new Promise((resolve) => {
			consulta(`api/v1.0/visitas/listar`, null, null, (error, estado, resp) => {
				if (estado === 200) {
					actualizarDataPromocion(resp);
					// this.setState({ cargando: false });
					resolve(estado === 200 && !error ? resp : null);
				}
			});
		});
	}

	async obtenerVisitas(id) {
		let { actualizarDataPromocion } = this.props;
		let f = await generarFiltros(id);
		return new Promise((resolve) => {
			consulta(
				`api/v1.0/visitas/listar?${f}`,
				null,
				null,
				(error, estado, resp) => {
					if (estado === 200) {
						actualizarDataPromocion(resp);
						this.setState({ cargando: false });
						resolve(estado === 200 && !error ? resp : null);
					}
				}
			);
		});
	}

	editarVisita = async (data, id) => {
		console.log(data);
		let { actualizarMensaje, mostrarModalEditarVisita, obtenerHistorialVisita, pintarVisitasCalendario, obtenerVisita } = this.props; //edi
		formulario(`api/v1.0/visita/editar/${id}`, data, "put", (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					mostrarModalEditarVisita(false)
					this.obtenerHistorialVisita();
					// this.pintarVisitasCalendario()
					// this.reiniciarEstado()
				} else {
					tipo = 'info'
					titulo = resp.titulo
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
		})
	}

	cargarVisitas = async (filtros_visitas = []) => {
		let f = await generarFiltros(filtros_visitas);
		this.setState({ cargando: true });
		consulta(`api/v1.0/visitas?1${f}`, null, null, (error, estado, resp) => {
			if (estado === 200) {
				this.setState({ cargando: false, datosNoti: resp });
			}
		});
	};


	FiltroPromocion = async (filtros_visitas = []) => {
		let f = await generarFiltros(filtros_visitas);
		this.setState({ cargando: true });
		consulta(`api/v1.0/promocion/listar/1?${f}`, null, null, (error, estado, resp) => {
			if (estado === 200) {
				this.setState({ seleccion: "detalleFiltro", cargando: false, datosFiltro: resp });
			}
		});
	};

	detalleFiltroPromocion() {
		let { historial, procesoActual, id_promocion, administrador } = this.state;
		let {
			modalDetPromocion,
			mostrarModalDetallePromocion,
			mostrarModalEditarPromocion,
			modalEdiPromocion,
			mostrarModalEliminarPromocion,
			modalEliPromocion,
			mostrarModalFiltrosColegio,
		} = this.props;
		const acciones = (rowData) => {
			const onClickDetalle = (data) => {
				this.setState({ procesoActual: data, id_promocion: data.id });
				mostrarModalDetallePromocion(true);
			};

			const onClickEditar = (data) => {
				this.setState({
					procesoActual: data,
					id_promocion: data.id,
				});
				mostrarModalEditarPromocion(true);
			};

			const onClickEliminar = (data) => {
				this.setState({
					procesoActual: data,
				});
				mostrarModalEliminarPromocion(true);
			};

			let detalle = (
				<BtnDetalle
					callback={() => onClickDetalle(rowData)}
					color="#01579b"
					texto="Abrir"
				/>
			);

			let editar = (
				<BtnEditar callback={() => onClickEditar(rowData)} texto="Editar" />
			);

			let eliminar = (
				<BtnEliminar
					callback={() => onClickEliminar(rowData)}
					texto="Eliminar"
				/>
			);

			return administrador ? (
				<div>
					{detalle} {editar} {eliminar}
				</div>
			) : (<div>
				{detalle} {editar}
			</div>
			)
		};
		let { cargando, datosFiltro } = this.state;
		if (!cargando) {
			return (
				<>
					<div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: "colegio" })} /></div>
					<ListarDatos
						id="tbl_promocion"
						titulo="Lista de Colegios"
						datos={datosFiltro}
						buscar={true}
						opciones={true}
						descargar="COLEGIOS - EMMA"
						filtrar={true}
						actfiltrar={() => mostrarModalFiltrosColegio(true)}
						refrescar={true}
						actRefrescar={() => this.obtenerHistorialColegio()}
						sub_titulo="Lista de adjuntos"
						acciones={(row) => acciones(row)}
						fila_principal={({ nombreColegio }) => nombreColegio.toUpperCase()}
						filas={[
							{
								id: "ciudad",
								enLista: true,
								mostrar: ({ ciudad, departamento }) =>
									`${ciudad.nombre}, ${departamento.nombre}`,
							},
							{
								id: "fecha_registro",
								enLista: true,
								mostrar: ({ fecha_registro }) =>
									moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
								nombre: "Fecha Registro: ",
							},
						]}
					/>
					<PromocionDetalle
						mostrarModalDetallePromocion={mostrarModalDetallePromocion}
						modalDetPromocion={modalDetPromocion}
						id_promocion={id_promocion}
						vistaDetalle="detalle"
						promocion={procesoActual}
					/>
					<PromocionEditar
						editarPromocion={this.EditarPromocion}
						mostrarModalEditarPromocion={mostrarModalEditarPromocion}
						modalEdiPromocion={modalEdiPromocion}
						vistaDetalle="editar"
						id_promocion={id_promocion}
						promocion={procesoActual}
					/>
					<ConfirmarAccion
						mensaje1={`El colegio "${procesoActual.nombreColegio}" será eliminado, `}
						ejecutarAccion={this.eliminarPromocion}
						mostrarModalConfAccion={mostrarModalEliminarPromocion}
						modalConAccion={modalEliPromocion}
						dato={procesoActual}
						titulo={"¿ Eliminar Colegio ?"}
					/>
				</>
			);
		} else
			return (
				<TareasTerminadas
					imagen={emma_w}
					mensaje="Cargando..."
					widthImg="7%"
					marginTop="7%"
				/>
			);

	}


	obtenerNotificacionesVisita = () => {
		return new Promise((resolve) => {
			consulta(
				`api/v1.0/visitas/notificaciones`,
				null,
				null,
				(error, estado, resp) => {
					resolve(resp);
				}
			);
		});
	};

	esAdministrador() {
		let { usuario } = this.props;
		if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Pro")) {
			this.setState({ administrador: true });
		}
		this.setState({ cargando: false })
		console.log(usuario)
	}

	obtenerAlertas = async () => {
		console.log(this.state.cargando)
		this.setState({ cargandoNotif: true });
		let noti = await this.obtenerNotificacionesVisita();
		let notificaciones = [];
		const accionesMensajes = (data) => {
			const onClickAbrir = ({ id }) => {
				this.setState({ seleccion: "detalleNoti" });
				this.cargarVisitas([{ llave: "id", valor: id }]);
			};
			return (
				<>
					<BtnVer callback={() => onClickAbrir(data)} texto="Ver" />
				</>
			);
		};

		noti.creadas.map((data) => {
			let { fecha_visita, colegio, hora_inicio, hora_fin } = data;
			let notificacion = {
				icono: recordatorio,
				titulo: `¡ Visita Creada !`,
				/* mensaje: `El tiempo ${moment(fecha_visita).format(
					"YYYY-MM-DD"
				)} asignado para que el estudiante encontrara sus practicas ha caducado `, */
				mensaje: `La visita del colegio ${colegio.nombreColegio} se programó para el día ${fecha_visita} a las ${moment(hora_inicio, "hh: mm").format("LT")} hasta ${moment(hora_fin, "hh: mm").format("LT")}`,
				fecha: `Disponible hasta ${moment(fecha_visita).format(
					"YYYY-MM-DD, h:mm a"
				)}`,
				tipo: "mensaje",
				acciones: () => accionesMensajes(data),
			};
			notificaciones.push(notificacion);
		});

		// noti.rechazadas.map((data) => {
		//   let { fecha_visita, colegio } = data;
		//   let notificacion = {
		//     icono: rechazado,
		//     titulo: `${colegio.nombreColegio}`,
		//     mensaje: `El tiempo ${moment(fecha_visita).format(
		//       "YYYY-MM-DD"
		//     )} asignado para que el estudiante encontrara sus practicas ha caducado `,
		//     fecha: `Disponible hasta ${moment(fecha_visita).format(
		//       "YYYY-MM-DD, h:mm a"
		//     )}`,
		//     tipo: "mensaje",
		//     acciones: () => accionesMensajes(data),
		//   };
		//   notificaciones.push(notificacion);
		// });
		this.setState({ notificaciones, cargandoNotif: false });
	}

	guardarPromocion = async (datos, callback) => {
		let { actualizarMensaje, mostrarModalCrearPromocion } = this.props;
		let data = await crear_form_data(datos);
		formulario(
			`api/v1.0/promocion/crear`,
			data,
			"post",
			(error, estado, resp) => {
				let titulo = "Ha ocurrido un error, contacte con el administrador.",
					tipo = "error";
				if (!error) {
					if (estado === 200) {
						tipo = "success";
						titulo = resp.titulo;
						this.setState({ cargando: true });
						mostrarModalCrearPromocion(false);
						this.setState({ cargando: false });
					} else {
						tipo = "info";
						titulo = resp.titulo ? resp.titulo : mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
				callback();
			}
		);
	};

	EditarPromocion = (
		id,
		nombreColegio,
		ciudad,
		direccion,
		nombreRector,
		fax,
		nombreContacto,
		telefonoContacto,
		Tipo,
		barrio,
		correo,
		telefonos,
		paginaWeb,
		cargoContacto,
		departamento
	) => {
		let { actualizarMensaje, mostrarModalEditarPromocion } = this.props;
		consulta(
			`api/v1.0/promocion/${id}`,
			{
				nombreColegio,
				ciudad,
				direccion,
				nombreRector,
				fax,
				nombreContacto,
				telefonoContacto,
				Tipo,
				barrio,
				correo,
				telefonos,
				paginaWeb,
				cargoContacto,
				departamento,
			},
			"patch",
			(error, estado, resp) => {
				let titulo = "Ha ocurrido un error, contacte con el administrador.",
					tipo = "error";
				if (!error) {
					if (estado === 200) {
						tipo = "success";
						titulo = resp.titulo;
						this.setState({ cargando: true });
						this.obtenerHistorialColegio();
						mostrarModalEditarPromocion(false);
					} else {
						tipo = "info";
						titulo = mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			}
		);
	};

	pintarNuevoColegio() {
		let { modalSolPromocion, mostrarModalCrearPromocion, actualizarMensaje } =
			this.props;
		let {
			nombreColegio,
			direccion,
			nombreRector,
			fax,
			nombreContacto,
			telefonoContacto,
			correo,
			telefonos,
			paginaWeb,
			cargoContacto,
			Tipo,
			tipos,
			barrio,
			barrios,
			departamento,
			departamentos,
			ciudad,
			ciudades,
		} = this.state;
		return (
			<>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
					lg={4}
					style={{ padding: "2% 2% 0% 2%" }}
					onClick={() => {
						mostrarModalCrearPromocion(true);
					}}
				>
					<Paper elevation={8} square>
						<Card
							style={{
								maxWidth: "100%",
								height: "380px",
								cursor: "pointer",
							}}
							elevation={1}
						>
							<CardHeader
								avatar={
									<Avatar
										aria-label="recipe"
										style={{ backgroundColor: red[500] }}
									></Avatar>
								}
								title={`Inscripción de Nuevo Colegio`}
							/>
							<CardContent>
								<Grid container direction={"row"} justifyContent={"center"}>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										style={{ textAlign: "center", height: "150px" }}
									>
										<img
											src={colegio}
											style={{
												width: "190px",
												maxWidth: "200px",
												minWidth: "100px",
												height: "190px",
											}}
											alt="Imagen Tipo"
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										style={{ padding: "75px 10px 0px 10px" }}
									>
										<Typography
											variant="body2"
											color="textSecondary"
											component="p"
											align="center"
										>
											Para inscribir un nuevo colegio ¡Dar click aqui!
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Paper>
				</Grid>

				<PromocionCrear
					modalSolPromocion={modalSolPromocion}
					mostrarModalCrearPromocion={mostrarModalCrearPromocion}
					actualizarMensaje={actualizarMensaje}
					guardarPromocion={this.guardarPromocion}
					nombreColegio={nombreColegio}
					direccion={direccion}
					fax={fax}
					nombreContacto={nombreContacto}
					nombreRector={nombreRector}
					telefonoContacto={telefonoContacto}
					correo={correo}
					telefonos={telefonos}
					paginaWeb={paginaWeb}
					cargoContacto={cargoContacto}
					tipo={Tipo}
					tipos={tipos}
					barrio={barrio}
					barrios={barrios}
					departamento={departamento}
					departamentos={departamentos}
					ciudad={ciudad}
					ciudades={ciudades}
				/>
			</>
		);
	}

	// guardarVisita = async (datos, callback) => {
	//   let { actualizarMensaje, mostrarModalCrearVisita } = this.props;
	//   let data = await crear_form_data(datos);
	//   formulario(
	//     `api/v1.0/visitas/crear`,
	//     data,
	//     "POST",
	//     (error, estado, resp) => {
	//       let titulo = "Ha ocurrido un error, contacte con el administrador.",
	//         tipo = "error";
	//       if (!error) {
	//         if (estado === 200) {
	//           tipo = "success";
	//           titulo = resp.titulo;
	//           mostrarModalCrearVisita(false);
	//           this.obtenerAlertas();
	//         } else {
	//           tipo = "info";
	//           titulo = resp.titulo ? resp.titulo : mostrarError(resp);
	//         }
	//       }
	//       actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	//       callback();
	//     }
	//   );
	// };

	pintarNuevaVisita() {
		let { modalSolVisita, mostrarModalCrearVisita, actualizarMensaje } =
			this.props;
		let {
			colegio,
			fecha_visita,
			hora_inicio,
			hora_fin,
			lugar,
			numero_estudiante,
			persona,
			colegios,
			cargandoNotif
		} = this.state;
		return (
			<>
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
					lg={4}
					style={{ padding: "2% 2% 0% 2%" }}
					onClick={() => {
						mostrarModalCrearVisita(true);
					}}
				>
					<Paper elevation={8} square>
						<Card
							style={{
								maxWidth: "100%",
								height: "380px",
								cursor: "pointer",
							}}
							elevation={1}
						>
							<CardHeader
								avatar={
									<Avatar
										aria-label="recipe"
										style={{ backgroundColor: green[500] }}
									></Avatar>
								}
								title={`Registrar Nueva Visita`}
							/>
							<CardContent>
								<Grid container direction={"row"} justifyContent={"center"}>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										style={{ textAlign: "center", height: "150px" }}
									>
										<img
											src={visita}
											style={{
												width: "190px",
												maxWidth: "200px",
												minWidth: "100px",
												height: "190px",
											}}
											alt="Imagen Tipo"
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										style={{ padding: "75px 10px 0px 10px" }}
									>
										<Typography
											variant="body2"
											color="textSecondary"
											component="p"
											align="center"
										>
											Para registrar una nueva visita ¡Dar click aqui!
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Paper>
				</Grid>

				<VisitaCrear
					modalSolVisita={modalSolVisita}
					mostrarModalCrearVisita={mostrarModalCrearVisita}
					actualizarMensaje={actualizarMensaje}
					// guardarVisita={this.guardarVisita}
					colegio={colegio}
					fecha_visita={fecha_visita}
					hora_inicio={hora_inicio}
					hora_fin={hora_fin}
					lugar={lugar}
					numero_estudiante={numero_estudiante}
					persona={persona}
					colegios={colegios}
					obtenerAlertas={this.obtenerAlertas}
				/>
			</>
		);
	}

	pintarPromocionNuevo() {
		return (
			<>
				<Grid
					container
					direction={"row"}
					justifyContent={"center"}
					style={{ paddingTop: 20 }}
				>
					{this.pintarNuevoColegio()}
					{this.pintarNuevaVisita()}
				</Grid>
			</>
		);
	}

	async obtenerHistorialColegio() {
		this.setState({ cargando: true });
		let historial = await this.obtenerPromocion();
		this.setState({ historial, cargando: false });
	}

	async obtenerHistorialVisita() {
		this.setState({ cargando: true });
		let historialVisita = await this.obtenerVisita();
		this.setState({ historialVisita: historialVisita, cargando: false });
	}

	gestionarVisita = async (id, data, callback) => {
		let { mostrarModalGestionarVisita, actualizarMensaje } = this.props;
		let form = await crear_form_data(data);
		formulario(
			`api/v1.0/visitas/${id}/gestionar`,
			form,
			"POST",
			(error, estado, resp) => {
				let titulo = "Ha ocurrido un error, contacte con el administrador.",
					tipo = "error";
				if (!error) {
					if (estado === 200) {
						tipo = "success";
						titulo = resp.titulo;
						this.obtenerHistorialVisita();
						this.obtenerAlertas();
						this.cargarVisitas([{ llave: "id", valor: id }])
						mostrarModalGestionarVisita(false);
					} else {
						tipo = "info";
						titulo = resp.titulo ? resp.titulo : mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
				callback();
			}
		);
	};

	eliminarPromocion = (id) => {
		let { actualizarMensaje, mostrarModalEliminarPromocion } = this.props;
		consulta(
			`api/v1.0/promocion/${id}/eliminar`,
			{},
			"put",
			(error, estado, resp) => {
				let titulo = "Ha ocurrido un error, contacte con el administrador.",
					tipo = "error";
				if (!error) {
					if (estado === 200) {
						tipo = "success";
						titulo = resp.titulo;
						this.obtenerHistorialColegio();
						this.obtenerAlertas();

						mostrarModalEliminarPromocion(false);
					} else {
						tipo = "info";
						titulo = mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			}
		);
	};

	eliminarVisita = (id) => {
		let { actualizarMensaje, mostrarModalEliminarPromocion } = this.props;
		consulta(
			`api/v1.0/visita/${id}/eliminar`,
			{},
			"put",
			(error, estado, resp) => {
				let titulo = "Ha ocurrido un error, contacte con el administrador.",
					tipo = "error";
				if (!error) {
					if (estado === 200) {
						tipo = "success";
						titulo = resp.titulo;
						this.obtenerHistorialVisita();
						this.obtenerAlertas();
						mostrarModalEliminarPromocion(false);
					} else {
						tipo = "info";
						titulo = mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			}
		);
	};
	pintarHistorialColegio() {
		let { historial, procesoActual, id_promocion, administrador, modalSeguimiento } = this.state;
		let {
			modalDetPromocion,
			mostrarModalDetallePromocion,
			mostrarModalEditarPromocion,
			modalEdiPromocion,
			mostrarModalEliminarPromocion,
			modalEliPromocion,
			mostrarModalFiltrosColegio,
		} = this.props;

		const acciones = (rowData) => {
      const onClickSeguimiento = (data) => {
        this.setState({ dataColegio: {id: data.id, nombreColegio: data.nombreColegio, } , modalSeguimiento: true });
			};
			const onClickDetalle = (data) => {
				this.setState({ procesoActual: data, id_promocion: data.id });
				mostrarModalDetallePromocion(true);
			};

			const onClickEditar = (data) => {
				this.setState({
					procesoActual: data,
					id_promocion: data.id,
				});
				mostrarModalEditarPromocion(true);
			};

			const onClickEliminar = (data) => {
				this.setState({
					procesoActual: data,
				});
				mostrarModalEliminarPromocion(true);
			};

			let seguimiento = (
				<BtnAcciones
					callback={() => onClickSeguimiento(rowData)}
					color="#009688"
					texto="Seguimiento"
				/>
			);
			let detalle = (
				<BtnDetalle
					callback={() => onClickDetalle(rowData)}
					color="#01579b"
					texto="Abrir"
				/>
			);

			let editar = (
				<BtnEditar callback={() => onClickEditar(rowData)} texto="Editar" />
			);

			let eliminar = (
				<BtnEliminar
					callback={() => onClickEliminar(rowData)}
					texto="Eliminar"
				/>
			);

			return administrador ? (
				<div>
					{detalle} {editar} {eliminar} {seguimiento}
				</div>
			) : (<div>
				{detalle} {editar} {seguimiento}
			</div>
			)
		};
		let { cargando, dataColegio } = this.state;
		if (!cargando) {
			return (
				<>
					<div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: "historial" })} /></div>
					<ListarDatos
						id="tbl_promocion"
						titulo="Lista de Colegios"
						datos={historial}
						buscar={true}
						opciones={true}
						descargar="COLEGIOS - EMMA"
						filtrar={true}
						actfiltrar={() => mostrarModalFiltrosColegio(true)}
						refrescar={true}
						actRefrescar={() => this.obtenerHistorialColegio()}
						sub_titulo="Lista de adjuntos"
						acciones={(row) => acciones(row)}
						fila_principal={({ nombreColegio }) => nombreColegio.toUpperCase()}
						avatar={({ nombreColegio }) => nombreColegio.charAt(0).toUpperCase()}

						filas={[
							{
								id: "nombreColegio",
								enLista: false,
								mostrar: ({ nombreColegio }) => nombreColegio ?
									`${nombreColegio}` : '',
							},
							{
								id: "direccion",
								enLista: false,
								mostrar: ({ direccion }) => direccion ? direccion : '',
							},
							{
								id: "nombreContacto",
								enLista: false,
								mostrar: ({ nombreContacto }) => nombreContacto ? nombreContacto : '',
							},
							{
								id: "telefonoContacto",
								enLista: false,
								mostrar: ({ telefonoContacto }) => telefonoContacto ? telefonoContacto : '',
							},
							{
								id: "ciudad",
								enLista: true,
								mostrar: ({ ciudad, departamento }) => ciudad && departamento ?
									`${ciudad.nombre}, ${departamento.nombre}` : '',
							},
							{
								id: "fecha_registro",
								enLista: true,
								mostrar: ({ fecha_registro }) =>
									moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
								nombre: "Fecha Registro: ",
							},
						]}
					/>
					<PromocionDetalle
						mostrarModalDetallePromocion={mostrarModalDetallePromocion}
						modalDetPromocion={modalDetPromocion}
						id_promocion={id_promocion}
						vistaDetalle="detalle"
						promocion={procesoActual}
					/>
					<PromocionEditar
						editarPromocion={this.EditarPromocion}
						mostrarModalEditarPromocion={mostrarModalEditarPromocion}
						modalEdiPromocion={modalEdiPromocion}
						vistaDetalle="editar"
						id_promocion={id_promocion}
						promocion={procesoActual}
					/>
					<ConfirmarAccion
						mensaje1={`El colegio "${procesoActual.nombreColegio}" será eliminado, `}
						ejecutarAccion={this.eliminarPromocion}
						mostrarModalConfAccion={mostrarModalEliminarPromocion}
						modalConAccion={modalEliPromocion}
						dato={procesoActual}
						titulo={"¿ Eliminar Colegio ?"}
          />
          <PromocionSeguimiento
            modalSeguimiento={modalSeguimiento}
            cerrarModalSeguimiento={() => this.setState({ modalSeguimiento: false })}
            dataColegio={dataColegio}
            actualizarMensaje={this.props.actualizarMensaje}
          />
				</>
			);
		} else
			return (
				<TareasTerminadas
					imagen={emma_w}
					mensaje="Cargando..."
					widthImg="7%"
					marginTop="7%"
				/>
			);
	}

	pintarHistorialVisita() {
		let { historialVisita, procesoActualVisita, id_visita } = this.state;
		let {
			modalDetVisita,
			mostrarModalDetalleVisita,
			mostrarModalGestionarVisita,
			modalGestVisita,
			mostrarModalFiltrosVisita,
			mostrarModalEditarVisita,
			modalEdiVisita,
			modalEliPromocion,
			mostrarModalEliminarPromocion
		} = this.props;
		const obtenerColor = (codigo) => {
			let color = "white";
			const colores = {
				Est_Vis_Env: { backgroundColor: "#f5f5f5", color: "#757575" },
				Est_Vis_Apr: { backgroundColor: "#01519B", color },
				Est_Vis_Rec: { backgroundColor: "#b71c1c", color },
				Est_Vis_Fin: { backgroundColor: "#009688", color },
			};
			return colores[codigo];
		};

		const acciones = (data) => {
			let {
				estado_actual: { codigo },
			} = data;
			const onClickDetalle = (data) => {
				this.setState({ procesoActualVisita: data, id_visita: data.id });
				mostrarModalDetalleVisita(true);
			};

			const onClickGestionar = (data) => {
				this.setState({ procesoActualVisita: data, id_visita: data.id });
				mostrarModalGestionarVisita(true);
			};

			const onClickEliminar = (data) => {
				this.setState({
					procesoActualVisita: data, id_visita: data.id
				});
				mostrarModalEliminarPromocion(true);
			};

			const onClickEditar = (data) => {
				this.setState({
					procesoActual: data,
					id_visita: data.id,
				});
				mostrarModalEditarVisita(true);
			};

			let detalle = (
				<BtnDetalle
					callback={() => onClickDetalle(data)}
					color="#01579b"
					texto="Abrir"
				/>
			);

			let editar = (
				<BtnEditar callback={() => onClickEditar(data)} texto="Editar" />
			);
			let eliminar = (
				<BtnEliminar
					callback={() => onClickEliminar(data)}
					texto="Eliminar"
				/>
			);
			let gestionar = codigo !== "Est_Vis_Fin" && codigo !== "Est_Vis_Rec" && (
				<BtnGestionar
					callback={() => onClickGestionar(data)}
					color="#009688"
					texto="Gestionar"
				/>
			);

			return (
				<div>
					{detalle}
					{editar}
					{eliminar}
					{gestionar}
				</div>
			);
		};

		let { cargando } = this.state;
		if (!cargando) {
			return (
				<>
					<div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: "historial" })} /></div>
					<ListarDatos
						id="tbl_visita"
						titulo="Historial de Visitas"
						datos={historialVisita}
						buscar={true}
						opciones={true}
						descargar="VISITA COLEGIOS - EMMA"
						filtrar={true}
						actfiltrar={() => mostrarModalFiltrosVisita(true)}
						refrescar={true}
						actRefrescar={() => this.obtenerHistorialVisita()}
						sub_titulo="Lista de adjutos"
						acciones={(row) => acciones(row)}
						fila_principal={({ colegio }) =>
							colegio.nombreColegio.toUpperCase()
						}
						avatar={({ estado_actual }) =>
							estado_actual.nombre.toUpperCase().charAt(0)
						}
						color_avatar={({ estado_actual }) =>
							obtenerColor(estado_actual.codigo)
						}
						filas={[
							{
								id: "Colegio",
								enLista: false,
								mostrar: ({ colegio }) =>
									colegio.nombreColegio
							},
							{
								id: "fecha_visita",
								enLista: true,
								mostrar: ({ fecha_visita }) =>
									moment(fecha_visita).format("YYYY-MM-DD"),
								nombre: "Fecha Visita: ",
							},
							{
								id: "hora_inicio",
								enLista: true,
								mostrar: ({ hora_inicio, hora_fin }) =>
									`Hora Inicio: ${moment(hora_inicio, "hh: mm").format("LT")} Hora Fin: ${moment(hora_fin, "hh: mm").format("LT")}`,
							},
							{ id: "lugar", enLista: true, mostrar: ({ lugar }) => lugar.nombre },
							{
								id: "estado_actual",
								enLista: true,
								mostrar: ({ estado_actual }) => estado_actual.nombre,
							},
						]}
					/>
					<VisitaDetalle
						vistaDetalle="detalle"
						mostrarModalDetalleVisita={mostrarModalDetalleVisita}
						modalDetVisita={modalDetVisita}
						id_visita={id_visita}
						visita={procesoActualVisita}
					/>

					<VisitaEditar
						actualizarMensaje={actualizarMensaje}
						id={id_visita}
						mostrarModalEditarVisita={mostrarModalEditarVisita}
						modalEdiVisita={modalEdiVisita}
						editarVisita={this.editarVisita}
					/>

					<VisitaGestionar
						vistaDetalle="gestionar"
						mostrarModalGestionarVisita={mostrarModalGestionarVisita}
						modalGestVisita={modalGestVisita}
						id_visita={id_visita}
						visita={procesoActualVisita}
						actualizarMensaje={actualizarMensaje}
						gestionarVisita={this.gestionarVisita}
					/>

					<ConfirmarAccion
						// mensaje1={`La visita del colegio "${procesoActualVisita.colegio.nombre}" será eliminada. `}
						ejecutarAccion={this.eliminarVisita}
						mostrarModalConfAccion={mostrarModalEliminarPromocion}
						modalConAccion={modalEliPromocion}
						dato={procesoActualVisita}
						titulo={"¿ Eliminar Visita ?"}
					/>
				</>
			);
		} else
			return (
				<TareasTerminadas
					imagen={emma_w}
					mensaje="Cargando..."
					widthImg="7%"
					marginTop="7%"
				/>
			);
	}

	pintarHistorial() {
		return (
			<div style={{ padding: '2% 1% 0% 1%' }} >
				<Grid container direction={'row'} spacing={1} justify={'center'} >
					<Grid item xs={12} sm={12} md={10} lg={8} >
						<TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
						<Paper elevation={0} square className="scroll contenido_fijo">
							<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccion: 'colegio' })}>
										<AccionesRapidas imagen={colegio2} titulo='¡ Colegios !' sub_titulo='' des_sub_titulo='Historial, detalle, modifica y elimina colegios.' />
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccion: 'visita' })}>
										<AccionesRapidas imagen={visita_peque} titulo='¡ Visitas !' sub_titulo='' des_sub_titulo='Historial, detalle y gestiona visitas.' />
									</Paper>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</div>
		)
	}

	pintarAdministrar() {
		return (
			<div style={{ padding: '2% 2% 0% 2%' }} >
				<Grid container direction={'row'} spacing={1} justify={'center'} >
					<Grid item xs={12} sm={12} md={10} lg={8} >
						<TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
						<Paper elevation={0} square className="scroll contenido_fijo">
							<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccion: 'calendario' })}>
										<AccionesRapidas imagen={fechaIcon} titulo='¡ Calendario de visitas !' sub_titulo='' des_sub_titulo='Historial, detalle, modifica y elimina colegios.' />
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ seleccion: 'informes' })}>
										<AccionesRapidas imagen={reportIcon} titulo='¡ Informes !' sub_titulo='' des_sub_titulo='Genera reporte de los procesos de visitas.' />
									</Paper>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</div>
		)
	}

	detalleNotificacion() {
		let {
			modalDetVisita,
			mostrarModalDetalleVisita,
			mostrarModalGestionarVisita,
			modalGestVisita,
			mostrarModalFiltrosVisita,
			mostrarModalEliminarPromocion,
			mostrarModalEditarVisita,
			modalEdiVisita,
			modalEliPromocion
		} = this.props;
		let { historialVisita, procesoActualVisita, id_visita } = this.state;

		const obtenerColor = (codigo) => {
			let color = "white";
			const colores = {
				Est_Vis_Env: { backgroundColor: "#f5f5f5", color: "#757575" },
				Est_Vis_Apr: { backgroundColor: "#01519B", color },
				Est_Vis_Rec: { backgroundColor: "#b71c1c", color },
				Est_Vis_Fin: { backgroundColor: "#009688", color },
			};
			return colores[codigo];
		};

		const acciones = (data) => {
			let {
				estado_actual: { codigo },
			} = data;
			const onClickDetalle = (data) => {
				this.setState({ procesoActualVisita: data, id_visita: data.id });
				mostrarModalDetalleVisita(true);
			};
			const onClickEliminar = (data) => {
				this.setState({
					procesoActualVisita: data, id_visita: data.id
				});
				mostrarModalEliminarPromocion(true);
			};

			const onClickEditar = (data) => {
				this.setState({
					procesoActual: data,
					id_visita: data.id,
				});
				mostrarModalEditarVisita(true);
			};

			const onClickGestionar = (data) => {
				this.setState({ procesoActualVisita: data, id_visita: data.id });
				mostrarModalGestionarVisita(true);
			};

			let detalle = (
				<BtnDetalle
					callback={() => onClickDetalle(data)}
					color="#01579b"
					texto="Abrir"
				/>
			);
			let editar = (
				<BtnEditar callback={() => onClickEditar(data)} texto="Editar" />
			);
			let eliminar = (
				<BtnEliminar
					callback={() => onClickEliminar(data)}
					texto="Eliminar"
				/>
			);

			let gestionar = codigo !== "Est_Vis_Fin" && codigo !== "Est_Vis_Rec" && (
				<BtnGestionar
					callback={() => onClickGestionar(data)}
					color="#009688"
					texto="Gestionar"
				/>
			);

			return (
				<div>
					{detalle}
					{editar}
					{eliminar}
					{gestionar}
				</div>
			);
		};
		let { cargando, datosNoti } = this.state;
		if (!cargando) {
			return (
				<>
					<div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: "historial" })} /></div>
					<ListarDatos
						id="tbl_visita"
						titulo="Historial de Visitas"
						datos={datosNoti}
						buscar={true}
						opciones={true}
						descargar="VISITA COLEGIOS - EMMA"
						filtrar={true}
						actfiltrar={() => mostrarModalFiltrosVisita(true)}
						refrescar={true}
						actRefrescar={() => this.obtenerHistorialVisita()}
						sub_titulo="Lista de adjutos"
						acciones={(row) => acciones(row)}
						fila_principal={({ colegio }) =>
							colegio.nombreColegio.toUpperCase()
						}
						avatar={({ estado_actual }) =>
							estado_actual.nombre.toUpperCase().charAt(0)
						}
						color_avatar={({ estado_actual }) =>
							obtenerColor(estado_actual.codigo)
						}
						filas={[
							{
								id: "fecha_visita",
								enLista: true,
								mostrar: ({ fecha_visita }) =>
									moment(fecha_visita).format("YYYY-MM-DD"),
								nombre: "Fecha Visita: ",
							},
							{
								id: "hora_inicio",
								enLista: true,
								mostrar: ({ hora_inicio, hora_fin }) =>
									`Hora Inicio: ${moment(hora_inicio, "hh: mm").format("LT")} Hora Fin: ${moment(hora_fin, "hh: mm").format("LT")}`,
							},
							{ id: "lugar", enLista: true, mostrar: ({ lugar }) => lugar.nombre },
							{
								id: "estado_actual",
								enLista: true,
								mostrar: ({ estado_actual }) => estado_actual.nombre,
							},
						]}
					/>
					<VisitaDetalle
						vistaDetalle="detalle"
						mostrarModalDetalleVisita={mostrarModalDetalleVisita}
						modalDetVisita={modalDetVisita}
						id_visita={id_visita}
						visita={procesoActualVisita}
					/>

					<VisitaEditar
						actualizarMensaje={actualizarMensaje}
						id={id_visita}
						mostrarModalEditarVisita={mostrarModalEditarVisita}
						modalEdiVisita={modalEdiVisita}
						editarVisita={this.editarVisita}
					/>

					<VisitaGestionar
						vistaDetalle="gestionar"
						mostrarModalGestionarVisita={mostrarModalGestionarVisita}
						modalGestVisita={modalGestVisita}
						id_visita={id_visita}
						visita={procesoActualVisita}
						actualizarMensaje={actualizarMensaje}
						gestionarVisita={this.gestionarVisita}
					/>

					<ConfirmarAccion
						// mensaje1={`La visita del colegio "${procesoActualVisita.colegio.nombre}" será eliminada. `}
						ejecutarAccion={this.eliminarVisita}
						mostrarModalConfAccion={mostrarModalEliminarPromocion}
						modalConAccion={modalEliPromocion}
						dato={procesoActualVisita}
						titulo={"¿ Eliminar Visita ?"}
					/>
				</>
			);
		} else
			return (
				<TareasTerminadas
					imagen={emma_w}
					mensaje="Cargando..."
					widthImg="7%"
					marginTop="7%"
				/>
			);
	}

	FiltroVisita = async (filtros_visitas = []) => {
		let f = await generarFiltros(filtros_visitas);
		this.setState({ cargando: true });
		consulta(`api/v1.0/visitas/listar/1?${f}`, null, null, (error, estado, resp) => {
			if (estado === 200) {
				this.setState({ seleccion: "detalleNoti", cargando: false, datosNoti: resp, filtros_visitas: '' });
			}
		});
	};

	pintarVisitasCalendario = () => {
		let { historialVisita, procesoActualVisita } = this.state;
		let { modalEdiVisita, mostrarModalEditarVisita, actualizarMensaje, mostrarModalEliminarPromocion, modalEliPromocion } = this.props
		const transformar = data => {
			return data.map(e => ({
				Id: e.id,
				Subject: `${e.colegio.nombreColegio} <br> ${e.lugar.nombre} <br> ${e.responsable.primer_nombre} ${e.responsable.segundo_nombre} ${e.responsable.primer_apellido} ${e.responsable.segundo_apellido}`,
				StartTime: e.fecha_visita + 'T' + e.hora_inicio,
				EndTime: e.fecha_visita + 'T' + e.hora_fin,
				Location: e.lugar.nombre,
				Description: `${e.responsable.primer_nombre} ${e.responsable.segundo_nombre} ${e.responsable.primer_apellido} ${e.responsable.segundo_apellido}`
			}));
		}

		return (
			<Grid container direction={'row'} spacing={1} >
				<div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: "administrar" })} />

				</div>
				<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '60px' }} >

					<VisitaCalendario
						eliminarVisita={this.eliminarVisita}
						mostrarModalEliminarPromocion={mostrarModalEliminarPromocion}
						modalEliPromocion={modalEliPromocion}
						editarVisita={this.editarVisita} obtenerHistorialVisita={this.obtenerHistorialVisita} pintarVisitasCalendario={this.pintarVisitasCalendario} actualizarMensaje={actualizarMensaje} mostrarModalEditarVisita={mostrarModalEditarVisita} modalEdiVisita={modalEdiVisita} datos={transformar(historialVisita)}></VisitaCalendario>
				</Grid>
			</Grid>
		)
	}

	pintarInformes() {
		let { informes, informe } = this.state
		let { modalInforme, mostrarModalInforme, actualizarMensaje } = this.props
		console.log(informes)
		const acciones = (data) => {
			this.setState({ informe: { 'nombre': data.nombre, 'codigo': data.codigo } })
			mostrarModalInforme(true)
		}

		return (
			<>
				<div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: "administrar" })} /></div>
				<ListarTarjetas
					datos={informes}
					titulo={'Informes Promoción'}
					acciones={(data) => acciones(data)}
					avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
					color_avatar={({ valorc }) => ({ 'backgroundColor': ((valorc) ? valorc : '#EEEEEE'), 'color': ((valorc) ? 'white' : '#757575') })}
					orden={'valorb'}
				/>

				<PromocionInforme
					informe={informe}
					modalInforme={modalInforme}
					mostrarModalInforme={mostrarModalInforme}
					actualizarMensaje={actualizarMensaje}
				/>

			</>
		)
	}

	render() {
		let { seleccion, cargando, notificaciones, cargandoNotif, administrador } = this.state;
		let { mostrarModalFiltrosColegio, modalAddFiltros, modalAddFiltrosVisita, mostrarModalFiltrosVisita } = this.props
		return (
			<>
				<MenuPromocion
					administrador={administrador}
					notificaciones={notificaciones}
					seleccionar={(seleccion) => {
						if (seleccion === "notificacion") {
							this.setState({ enNotificacion: "si" });
							this.obtenerAlertas();
						} else this.setState({ enNotificacion: "no", seleccion });
					}}
					sele={seleccion}
					cargandoNotif={cargandoNotif}
				/>
				<PromocionFiltrar
					seleccion={seleccion}
					modalAddFiltros={modalAddFiltros}
					mostrarModalFiltrosColegio={mostrarModalFiltrosColegio}
					filtrar={(filtros) => { this.FiltroPromocion(filtros) }}
					actualizarMensaje={actualizarMensaje}
				/>
				<VisitaFiltrar
					modalAddFiltrosVisita={modalAddFiltrosVisita}
					mostrarModalFiltrosVisita={mostrarModalFiltrosVisita}
					filtrar={(filtros) => { this.FiltroVisita(filtros) }}
					actualizarMensaje={actualizarMensaje}
				/>

				<div className="contenido_modulo">
					{!cargando ? (
						<>
							{/* MENÚ */}
							{seleccion === "agregar" && this.pintarPromocionNuevo()}
							{seleccion === "historial" && this.pintarHistorial()}
							{seleccion === "administrar" && this.pintarAdministrar()}
							{/* SELECCIÓN HISTORIAL */}
							{seleccion === "visita" && this.pintarHistorialVisita()}
							{seleccion === "colegio" && this.pintarHistorialColegio()}
							{/* SELECCIÓN ADMINISTRAR */}

							{seleccion === 'calendario' && this.pintarVisitasCalendario()}
							{/* {seleccion === 'calendario' && <div className='regresar' ><BtnRegresar callback={() => this.setState({ seleccion: "administrar" })} /></div>} */}
							{seleccion === 'informes' && this.pintarInformes()}

							{/* NOTIFICACION */}
							{seleccion === 'detalleNoti' && this.detalleNotificacion()}
							{seleccion === 'detalleFiltro' && this.detalleFiltroPromocion()}
						</>
					) : (
						<TareasTerminadas
							mensaje="Cargando.."
							marginTop="7%"
							imagen={emma_w}
							widthImg="7%"
						/>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	let { usuario } = state.redGlobal;
	let {
		modalSolPromocion,
		modalDetPromocion,
		modalEdiPromocion,
		modalEliPromocion,
		modalSolVisita,
		modalEdiVisita,
		modalDetVisita,
		modalGestVisita,
		visitas,
		modalAddFiltros,
		modalAddFiltrosVisita,
		modalInforme,
	} = state.redPromocion;
	return {
		usuario,
		modalSolPromocion,
		modalDetPromocion,
		modalEdiPromocion,
		modalEliPromocion,
		modalSolVisita,
		modalEdiVisita,
		modalDetVisita,
		modalGestVisita,
		visitas,
		modalAddFiltros,
		modalAddFiltrosVisita,
		modalInforme,
	};
};

const mapDispatchToProps = {
	mostrarModulo,
	mostrarModalCrearPromocion,
	actualizarMensaje,
	mostrarModalDetallePromocion,
	mostrarModalEditarPromocion,
	mostrarModalEliminarPromocion,
	actualizarDataPromocion,
	mostrarModalCrearVisita,
	mostrarModalEditarVisita,
	mostrarModalDetalleVisita,
	mostrarModalGestionarVisita,
	actualizarRenderVisitas,
	actualizarDataVisitas,
	mostrarModalFiltrosColegio,
	mostrarModalFiltrosVisita,
	mostrarModalInforme,
};

PromocionAdministrar.propTypes = {
	modalSolPromocion: PropTypes.bool.isRequired,
	actualizarMensaje: PropTypes.func.isRequired,
	mostrarModalDetallePromocion: PropTypes.func.isRequired,
	mostrarModalEditarPromocion: PropTypes.func.isRequired,
	mostrarModalEliminarPromocion: PropTypes.func.isRequired,
	actualizarDataPromocion: PropTypes.func.isRequired,
	modalSolVisita: PropTypes.bool.isRequired,
	modalEdiVisita: PropTypes.bool.isRequired,
	mostrarModalEditarVisita: PropTypes.func.isRequired,
	mostrarModalDetalleVisita: PropTypes.func.isRequired,
	mostrarModalGestionarVisita: PropTypes.func.isRequired,
	actualizarRenderVisitas: PropTypes.func.isRequired,
	actualizarDataVisitas: PropTypes.func.isRequired,
	visitas: PropTypes.array.isRequired,
	mostrarModalFiltrosColegio: PropTypes.func.isRequired,
	mostrarModalFiltrosVisita: PropTypes.func.isRequired,
	mostrarModalInforme: PropTypes.func.isRequired,

};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PromocionAdministrar);
