import React, { Component } from 'react';
// Material-UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Stepper,
    Step,
    StepLabel
} from '@material-ui/core/';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import 'date-fns';
// Components
import AppBarModal from '../../general/AppBarModal';
import {
    formulario,
    obtenerValores,
    mostrarError,
    crear_form_data,
    consulta,
    obtenerValoresFiltros,
    obtenerPermisosValorAlt
} from '../../../global/js/funciones';
import { PasosCrearSolicitud } from './MatriculasMenu';
import {
    DEPARTAMENTOS_ACADEMICOS,
    PROGRAMAS,
    MOTIVOS_SOLICITUD_NOTAS,

} from './helper';
import AlertasSimple from '../../general/AlertasSimple';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import BuscarSelect from '../../general/BuscarSelect';


const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
        marginTop: 15,
        '& > *': {
            margin: theme.spacing(0.5)
        }
    },
    alert: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    },
    list: {
        marginTop: 20,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    backButton: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    inline: {
        display: 'inline'
    },
    textarea: {
        width: '100%',
        padding: theme.spacing(2),
        border: '1px solid #ccc',
        borderRadius: theme.spacing(1),
        fontSize: '1rem',
        marginBottom: theme.spacing(2), // Espacio inferior para separar el botón
        // minHeight: '50px',
        // maxHeight: '50px',
        resize: 'none'  // Esto deshabilitará el cambio de tamaño
    },
}));

export default class ModalCorreccionNotas extends Component {

    constructor(props) {
        super(props);
        this.formEndRef = React.createRef();
        this.state = {
            steps: ['Datos Correción'],
            activeStep: 0,
            MotivosNotas: [],
            MotivoNotas: '',
            cargando: false,
            listarPrograma: [],
            programaSelected: '',
            nombre_materia: '',
            materiasProfesor: [],
            comentario: '',
            cargando_text: ''
        };
    }


    async componentDidUpdate({ open }, { programaSelected }) {
        const { open: openNew } = this.props
        const { programaSelected: programaSelectedNew } = this.state
        if (open !== openNew) {
            if (openNew) {
                this.reiniciarEstado();
                this.setState({ cargando: true, cargando_text: 'Cargando ..' })
                let programas = await this.ObtenerProgramasProfesor()
                let listarPrograma = []
                if(!(programas.error)) listarPrograma = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'codigos_in', 'valor': programas.programas }]);                let MotivosNotas = await obtenerValores(MOTIVOS_SOLICITUD_NOTAS)
                listarPrograma = listarPrograma.map(( Val ) => ({
                    value: Val.id,
                    label: Val.nombre,
                    codigo: Val.codigo,
                }));
                this.setState({
                    listarPrograma,
                    MotivosNotas,
                    cargando: false
                })
            }
        }


        if (programaSelectedNew.value && programaSelectedNew.value !== programaSelected.value) {
            this.setState({ cargando: true })
            let DatosProfesor = await this.ObtenerDatosProfesor(programaSelectedNew.codigo)

            let materiasProfesor = DatosProfesor.materias.map((e) => ({
                value: `${e.nombre_grupo} - ${e.nombre_materia}`,
                label: `${e.nombre_grupo} - ${e.nombre_materia}`,
                cod_materia: e.cod_materia,
                cod_pensum: e.cod_pensum,
                cod_unidad: e.cod_unidad,
                id_grupo: e.id_grupo,
                nombre_materia: e.nombre_materia,
                nombre_grupo: e.nombre_grupo,
            }));

            this.setState({ cargando: false, materiasProfesor })
        }
    }

    reiniciarEstado() {
        this.setState({
            cargando: true,
            cargando_text: "Cargando..",
            programaSelected: '',
            MotivoNotas: '',
            nombre_materia: '',
            comentario: ''
        });
    }


    handleNext = () => {
        const { steps, activeStep } = this.state
        const { crear_solicitud, validar_datos } = this

        if (activeStep == 0 && validar_datos()) return;

        if (activeStep == steps.length - 1) {
            crear_solicitud();
        } else {
            this.setState({ activeStep: activeStep + 1 })
        }
    };


    // handleBack = () => {
    //     const { activeStep } = this.state
    //     this.setState({ activeStep: activeStep - 1 });
    // };

    validar_estado_no_null = (nombre_estados) => {
        for (let index = 0; index < nombre_estados.length; index++) {
            const element = nombre_estados[index];
            if (
                this.state[element] == '' ||
                this.state[element] == undefined ||
                this.state[element] == null
            ) return true;
        }
        return false
    }


    ObtenerDatosProfesor = (programa) => {
        return new Promise((resolve) => {
            consulta(`api/v1.0/matriculas/ObtenerDatosProfesor/${programa}`, null, null, (error, estado, resp) => {
                resolve(resp);
            });
        });
    }

    ObtenerProgramasProfesor = () => {
        return new Promise((resolve) => {
            consulta(`api/v1.0/matriculas/ObtenerProgramasProfesor`, null, null, (error, estado, resp) => {
                resolve(resp);
            });
        });
    }


    cambiarEstado = (estados) => {
        this.setState(estados)
    }


    onChangeStateForInput = async (event) => {
        const { target: { value, name } } = event
        this.setState({
            [name]: value
        })
    }

    Datos_Correcion = () => {
        const {
            nombre_materia,
            MotivoNotas,
            comentario,
            MotivosNotas,
            listarPrograma,
            programaSelected,
            materiasProfesor
        } = this.state

        const { onChangeStateForInput } = this

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BuscarSelect datos={listarPrograma} cambiarEstado={(value) => this.setState({ programaSelected: value })} valor={programaSelected} id='select-programa' nombre='Programa' placeholder='Seleccione Programa' />
                </Grid>
                <Grid item xs={12}>
                    <BuscarSelect datos={programaSelected.value ? materiasProfesor : [{ 'label': <em>Para mostrar debe seleccionar el programa</em> }]} cambiarEstado={(value) => this.setState({ nombre_materia: value })} valor={nombre_materia} id='select-materia' nombre='Materia' placeholder='Seleccione Materia' />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Motivo de la solicitud de apertura o correccion de notas</InputLabel>
                        <Select
                            name='MotivoNotas'
                            value={MotivoNotas}
                            onChange={onChangeStateForInput}
                        >
                            {
                                MotivosNotas.map((ti) => (
                                    <MenuItem key={ti.id} value={ti.id}> {ti.nombre} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <FormControl className="form-control">
                        <TextField
                            value={comentario}
                            id="txtMotivoNegado"
                            label='Comentarios'
                            name="comentario"
                            minRows={3}
                            maxRows={12}
                            placeholder={'Escribe tus comentarios aquí'}
                            multiline
                            onChange={onChangeStateForInput}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    validar_datos = () => {
        const { validar_estado_no_null } = this
        const { actualizarMensaje } = this.props
        const array_name_state = [
            'programaSelected',
            'nombre_materia',
            'MotivoNotas',
            'comentario'

        ]
        if (validar_estado_no_null(array_name_state)) {
            actualizarMensaje({
                titulo: "Campos sin digitar.",
                tipo: "info",
                mostrar: true,
                tiempo: 6000
            });
            return true
        }

    }


    getStepContent(step) {
        const { Datos_Correcion } = this
        switch (step) {
            case 0:
                return <Datos_Correcion
                    setNombre_materia

                />
            default:
                return 'Unknown step';
        }
    }

    crear_solicitud = async () => {
        let { actualizarMensaje, tipo, setOpen, misDatos } = this.props

        const {
            programaSelected,
            nombre_materia,
            MotivoNotas,
            comentario

        } = this.state


        let auxData = {
            tipo: tipo.id,
            tipoCode: tipo.codigo,
            programa: programaSelected.value,
            misCursos: [],
            comentario,
            MotivoNotas,

            cod_materia: nombre_materia.cod_materia,
            cod_pensum: nombre_materia.cod_pensum,
            // id_grupo: nombre_materia.id_grupo,
            nombre_grupo: nombre_materia.nombre_grupo,
            nombre_materia: nombre_materia.nombre_materia,

            celular: misDatos.persona.celular,
            correo: misDatos.persona.correo_personal,
            telefono: misDatos.persona.telefono,
            numero_documento: misDatos.persona.identificacion,
            ciudad_expedicion: misDatos.persona.lugar_expedicion,
            tipoIdentificacion: misDatos.persona.tipo_identificacion,
            primer_nombre: misDatos.persona.primer_nombre,
            segundo_nombre: misDatos.persona.segundo_nombre,
            primer_apellido: misDatos.persona.primer_apellido,
            segundo_apellido: misDatos.persona.segundo_apellido,
        };

        const datos = await crear_form_data(auxData);

        this.setState({ cargando: true, cargando_text: 'Enviando, por favor espere' })

        formulario(`api/v1.0/matriculas/crear`, datos, 'post', async (error, estado, resp) => {

            let title = 'Ha ocurrido un error, contacte con el administrador.';
            let icon = 'error';
            if (!error) {
                if (estado === 200) {
                    icon = 'success';
                    title = resp.titulo;
                    setOpen(false);
                    this.setState({ activeStep: 0 });
                } else if (estado === 302) {
                    icon = 'info';
                    title = resp.titulo;
                } else {
                    tipo = 'info';
                    title = resp.titulo ? resp.titulo : mostrarError(resp);
                }
            }
            actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
            this.setState({ cargando: false })
        });
    }

    render() {
        const { open, setOpen, tipo } = this.props
        const { steps, activeStep, cargando, cargando_text } = this.state
        let lista = [
            {
                item: `
              IMPORTANTE: A través de este formulario podrá ingresar su solicitud de corrección de notas, recuerde que debe diligenciar una solicitud por cada materia a la que desee se le habilite el sistema para ingreso de notas.
          `
            },
        ]
        return (
            <div>

                <Dialog
                    onClose={() => setOpen(false)}
                    aria-labelledby='customized-dialog-title'
                    open={open}
                    fullWidth={true}
                    maxWidth='md'
                    className='scroll'
                >
                    <AppBarModal
                        titulo={`${tipo && tipo.nombre}`}
                        mostrarModal={() => setOpen(false)}
                        titulo_accion='Cerrar'
                        accion={() => setOpen(false)}
                    />
                    <Grid item xs={12}>
                        <AlertasSimple
                            tipo='info'
                            titulo='Estimado Docente, Datos que debe tener en cuenta:'
                            sub_titulo=''
                            lista={lista}

                        />

                    </Grid>
                    <DialogContent>
                        {cargando ? (
                            <TareasTerminadas mensaje={cargando_text} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
                        ) : (
                            <div>
                                <Stepper activeStep={0} >
                                    <Step key={1} style={{ margin: 'auto' }}>
                                        <StepLabel >{'Datos Corrección'}</StepLabel>
                                    </Step>

                                </Stepper>
                                {this.getStepContent(activeStep)}
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {!cargando &&
                            (
                                <Button
                                    color='primary'
                                    onClick={this.handleNext}
                                >
                                    {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
                                </Button>
                            )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}