import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { mostrarModulo } from '../../redux/actions/actGlobal';
import TareasTerminadas from '../general/TareasTerminadas';
import emma_s from '../../global/imagenes/emma_s1.png';
import LogoCuc from '../../global/imagenes/logo_cuc3.png';
import imagen_certificado from '../../global/imagenes/background-certificado.png';
import firmavice from '../../global/imagenes/firma_vice.png';
import firmasemi from '../../global/imagenes/firma_semillero.png';
import firma_curso from '../../global/imagenes/firma_curso.png';
import * as Funciones from '../../global/js/funciones';
import TituloAccion from "../general/TituloAccion";
import { Grid, Paper, Button, Typography, Box, Tooltip, IconButton } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import revisar from '../../global/imagenes/revisar.png';
import AccionesRapidas from '../general/AccionesRapidas'
// import SemillerosListSemi from './Hijos/SemillerosListSemi'
// import SemilleroSolicitudesEst from './Hijos/SemilleroSolicitudesEst'
// import { SemilleroTableParticipante } from './Hijos/SemilleroTables'
import { Document, Image, Page, PDFDownloadLink, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import sourcebold from './../../global/fonts/PTSerif-Bold.ttf'
import source from './../../global/fonts/Roboto-Regular.ttf'
import sourcerobotobold from './../../global/fonts/Roboto-Bold.ttf'
import file from '../../global/imagenes/file.png'
import moment from 'moment';
// import Semilleros from './Semilleros'
import MenuCursosFormacion from './MenuCursosFormacion';
import SemilleroSolicitudesEst from './CursosSolicitudesEstudiantes';
import CardSolicitud from './Cursos/CardSolicitud';
import CursosList from './Cursos/CursosList';
import SolicitudTables from './Cursos/CursosTables';
import BarraProceso from '../general/BarraProceso';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contenidoModulo: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: "90px !important"
    },
  }
}));
Font.register({ family: 'PTSerifBold', src: sourcebold });
Font.register({ family: 'Roboto', src: source });
Font.register({ family: 'RobotoBold', src: sourcerobotobold });
const styles = StyleSheet.create({
  body: {
    border: '1.1px solid #DFDDDD',
    width: '100%',
    height: '100%',
    paddingLeft: '45px',
    paddingRight: '45px',
  },
  imagen: {
    width: '100%',
    display: 'flex',
    margin: '20px 0 20px 0',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    objectFit: 'cover',
    width: '200px'
  },
  titular: {
    fontSize: '12px',
    textAlign: 'center',
    margin: '10px 0 15px 0',
    fontFamily: 'PTSerifBold',

  },
  negrita: {
    fontSize: '12px',
    fontFamily: 'PTSerifBold',
    lineHeight: 1.5,
    textAlign: 'justify',
  },
  parrafo: {
    fontSize: '12px',
    margin: '20px 0 0 0',
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  parrafo1: {
    fontSize: '12px',
    margin: '70px 0 0 0',
    textAlign: 'justify',
  },
  firma: {
    paddingLeft: '10px',
    width: '100px',
    height: '135px',
    display: 'flex',
    margin: '35px 0 -80px -5px'
  },
  piefirma: {
    fontFamily: 'PTSerifBold',
    fontSize: '13px',
  },
  piefirma1: {
    fontSize: '13px',
    textAlign: 'justify',
    lineHeight: 1.3,
  },
  piepagina: {
    fontSize: '11px',
    padding: '50px',
  },
  informacion: {
    textAlign: 'justify',
    fontSize: '11px',
    color: 'lightcoral',
    margin: '70px 0 0 0',
  },
  linea: {
    margin: '-20px 0 0 0',
  },

  // certificado

  backgroundWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '792px',
    height: '612px'
  },

  titulo_ceritficado: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    lineHeight: 1.5,
    letterSpacing: 1,
    margin: '15px',
    textAlign: 'center',
    marginTop: '100px',
  },

  bold_certificado: {
    fontFamily: 'RobotoBold',
    fontSize: '18px',
  },


  contentWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative'
  },

  textWrapper: {
    paddingLeft: '160px',
    paddingRight: '160px'
  },

  certificaWrapper1: {
    paddingLeft: '115px',
    paddingRight: '115px',
    marginTop: '0px'
  },
  certificaWrapper: {
    paddingTop: '20px',
  },
  certifica:{
    color: '#AB7B35' ,
    fontFamily: 'RobotoBold',
    fontSize: '25px',
  },
  lineWrapper: {
    paddingTop: '10px',
  },
  titulo_nombre: {
    fontFamily: 'RobotoBold',
    fontSize: '25px',
    lineHeight: 1.5,
    letterSpacing: 1,
    margin: '15px',
    textAlign: 'center',
    marginTop: '10px'
  },
  titulo_cc: {
    marginTop: '-30px',
    fontFamily: 'RobotoBold'
  },
  parrafo_ceritficado: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: 1.2,
    letterSpacing: 1,
    marginTop: '15px',
    textAlign: 'center',
  },
  parrafo_ceritficado1: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: 1.2,
    letterSpacing: 1,
    marginTop: '15px',
  },
  bold_parrafo: {
    fontFamily: 'RobotoBold',
  },
  containergeneral:{
    display:'flex',
    flexDirection:'row',
    marginTop:'20px',
    justifyContent:'space-between',
    width:'90%'
  },
  containerfirmas: {
    marginRight:'5px',
    width: '30%',
    textAlign:'center',
  },
  lineacertificado: {
    borderBottom: '1px solid #AB7B35',
  },

  nombres: {
    fontFamily: 'RobotoBold',
    fontSize: '15px',
    color:'#AB7B35',
    lineHeight: 1.2,
    letterSpacing: 1,
  },
  nombrescargos: {
    fontFamily: 'RobotoBold',
    fontSize: '8.5px',
    color:'#561515',
    padding:'3px 10px 0',
    lineHeight: 1.2,
    letterSpacing: 1,

  },
  firmacertificado:{
    width: '150px',
    paddingLeft:'50px',

  }


});
const DescargarCertificado = (props)=>{
    const obtenerMes = (mes) => {
        const meses = {
          '1': 'Enero',
          '2': 'Febrero',
          '3': 'Marzo',
          '4': 'Abril',
          '5': 'Mayo',
          '6': 'Junio',
          '7': 'Julio',
          '8': 'Agosto',
          '9': 'Septiembre',
          '10': 'Octubre',
          '11': 'Noviembre',
          '12': 'Diciembre'
        }
        return (meses[mes])
      }
     
      const generarPDF_certificado = () => {
        let fecha_actual = moment()
        let hoy = `${fecha_actual.format('D')} dias del mes de ${obtenerMes(fecha_actual.format('M'))} de ${fecha_actual.format('YYYY')}`
        let data = props.idusuario
        let nombre = props.nombre
        let cedula = props.identificacion
        let firma = props.cordinador
        // let imgfirma= props.firma_coordinador
        let firmavicerector = props.vicerrector
        // let imgfirmavice=props.firma_Vicerrector
        let firmaunidad = props.unidadCientifica
        // let imgfirmaunidad=props.firma_UnidadCientifica
        // let programa = data.solicitud_curso_formacion[0].programa.nombre
        console.log('hola');
        return (
          <Document>
            <Page size="LETTER" orientation="landscape">
              <View>
                <Image src={imagen_certificado} style={styles.backgroundWrapper} alt="background" />
              </View>
    
              <View style={styles.contentWrapper}>
                <View style={styles.textWrapper}>
                  <Text style={styles.titulo_ceritficado}>La <Text style={styles.bold_certificado}>Universidad de la Costa</Text> por intermedio de la Vicerrectoría de Ciencia, Tecnología e Innovación</Text>
                </View>
                <View style={styles.certificaWrapper}>
                  <Text style={styles.certifica}>CERTIFICA QUE:</Text>
                </View>
                <View style={styles.certificaWrapper}>
                  <Text style={styles.titulo_nombre} >{nombre}</Text>
                </View>
                <View style={styles.certificaWrapper}>
                  <Text style={styles.titulo_cc}>CC. {cedula}</Text>
                </View>
                <View style={styles.certificaWrapper}>
                  <Text style={{ borderBottom: '2px solid #AB7B35', width: '550px' }}></Text>
                </View>
                <View style={styles.certificaWrapper1}>
                  <Text style={styles.parrafo_ceritficado}>
                    Cursó y aprobó el <Text style={styles.bold_parrafo}>Seminario de Investigación Adjunto </Text>ofrecido por el<Text style={styles.bold_parrafo}> Programa de Semilleros de Investigación e Innovación, </Text> realizado {props.iniciocurso} {props.fincurso}, con una intensidad de<Text style={styles.bold_parrafo}> {props.horas} horas.</Text>
                  </Text>
                </View>
                <View>
                  <Text style={styles.parrafo_ceritficado1}>Dado en Barranquilla, Colombia, a los {hoy}</Text>
                </View>
                <View style={styles.containergeneral}>
                  <View style={styles.containerfirmas}>
                    <Image src={firmavice} style={styles.firmacertificado} />
                    <Text style={styles.lineacertificado}></Text>
                    <Text style={styles.nombres}>{firmavicerector}</Text>
                    <Text style={styles.nombrescargos}>VICERRECTOR DE CIENCIA, TECNOLOGÍA E INNOVACIÓN</Text>
                  </View>
                  <View style={styles.containerfirmas}>
                    <Image src={firmasemi} style={styles.firmacertificado}  />
                    <Text style={styles.lineacertificado}></Text>
                    <Text style={styles.nombres}>{firmaunidad}</Text>
                    <Text style={styles.nombrescargos}>JEFA UNIDAD DE FORMACION Y COMUNICACIÓN CIENTÍFICA</Text>
                  </View>
                  <View style={styles.containerfirmas}>
                    <Image  src={firma_curso} style={styles.firmacertificado}  />
                    <Text style={styles.lineacertificado}></Text>
                    <Text style={styles.nombres}>{firma}</Text>
                    <Text style={styles.nombrescargos}>COORDINADOR (A) SEMILLEROS DE INVESTIGACIÓN E INNOVACIÓN</Text>
                  </View>
                </View>
              </View>
    
            </Page>
          </Document>
        )
      }
    const descargar = (
      <Box style={{ display: 'flex', justifyContent: 'space-evenly', 'marginLeft': 'auto' }}>
        <PDFDownloadLink style={{ textDecoration: 'none' }} document={ generarPDF_certificado()} fileName="certificado.pdf">
          {({ blob, url, loading, error }) => (
            <Tooltip title='Descargar Certificado' aria-label="add">
              <IconButton style={{ color: '#2196f3' }} aria-label="download">
                <GetAppIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </PDFDownloadLink>
      </Box>
    );
  
    return (
      <div>
        <React.Fragment>
          {descargar}
        </React.Fragment>
      </div>
    );
  } 

export default DescargarCertificado 