import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//FUNCIONES GENERALES
import { consulta, mostrarError } from '../../global/js/funciones';

//COMPONENTES DEL MODULO
import GenericasListar from './GenericasListar';
import GenericasAgregar from './GenericasAgregar';
import GenericasModificar from './GenericasModificar';

import Valores from './valores/Valores';

//ACCIONES DEL MODULO
import {
	mostrarModalAdd,
	mostrarModalMod,
	mostrarModalDel,
	actualizarDataGenericas,
	actualizarRender,
	actualizarDataGenerica,
	mostrarModalAdmValores
} from '../../redux/actions/actGenericas';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal';
import emma_w from '../../global/imagenes/emma_w.png';
import TareasTerminadas from '../general/TareasTerminadas';

class Genericas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cargando: true
		};
	}

	componentDidMount() {
		let { mostrarModulo } = this.props;
		mostrarModulo({ ruta: '/genericas', nombre: 'genericas' });
		this.obtenerGenericas();
		
	}

	obtenerGenericas = () => {
		this.setState({ cargando: true });
		let { actualizarDataGenericas, busquedaFija, idGenerica } = this.props;
		if(busquedaFija){
			consulta(`api/v1.0/genericas/preuniversitario/${idGenerica}`,  null, null, (error, estado, resp) => {
				if (estado === 200) {
					actualizarDataGenericas(resp);
					this.setState({ cargando: false });
				}
			});
		}
		else {
			consulta('api/v1.0/genericas', null, null, (error, estado, resp) => {
			if (estado === 200) {
				actualizarDataGenericas(resp);
				this.setState({ cargando: false });
			}
		});
	}
	};

	guardarGenerica = (codigo, nombre, descripcion) => {
		let { actualizarMensaje, mostrarModalAdd } = this.props;
		consulta('api/v1.0/genericas/crear', { codigo, nombre, descripcion }, 'post', (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.obtenerGenericas();
					mostrarModalAdd(false);
				} else {
					tipo = 'info';
					titulo = mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});
	};

	modificarGenerica = (id, codigo, nombre, descripcion) => {
		let { actualizarMensaje, mostrarModalMod } = this.props;
		consulta(`api/v1.0/genericas/${id}`, { codigo, nombre, descripcion }, 'patch', (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.obtenerGenericas();
					mostrarModalMod(false);
				} else {
					tipo = 'info';
					titulo = mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});	
	};

	eliminarGenerica = (id) => {
		let { actualizarMensaje, mostrarModalDel } = this.props;
		consulta(`api/v1.0/genericas/${id}`, {}, 'delete', (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.obtenerGenericas();
					mostrarModalDel(false);
				} else {
					tipo = 'info';
					titulo = mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});
	};

	render() {
		let {
			genericas,
			mostrarModalMod,
			actualizarDataGenerica,
			mostrarModalDel,
			mostrarModalAdd,
			modalMod,
			generica,
			modalAdd,
			modalAdmValores,
			mostrarModalAdmValores,
			actualizarMensaje,
			busquedaFija
		} = this.props;

		let { cargando } = this.state;
		if (!cargando) {
			return (
				<div>
					<Valores
						modalAdmValores={modalAdmValores}
						mostrarModalAdmValores={mostrarModalAdmValores}
						genericas={genericas}
					/>
					{!busquedaFija &&
					<GenericasAgregar
						guardarGenerica={this.guardarGenerica}
						mostrarModalAdd={mostrarModalAdd}
						modalAdd={modalAdd}
					/>
				}
					<GenericasModificar
						modificarGenerica={this.modificarGenerica}
						mostrarModalMod={mostrarModalMod}
						modalMod={modalMod}
						generica={generica}
					/>
					<GenericasListar
						mostrarModalAdmValores={mostrarModalAdmValores}
						mostrarModalAdd={mostrarModalAdd}
						mostrarModalMod={mostrarModalMod}
						mostrarModalDel={mostrarModalDel}
						actualizarDataGenerica={actualizarDataGenerica}
						genericas={genericas}
						actualizarMensaje={actualizarMensaje}
					/>
				</div>
			);
		} else return <TareasTerminadas imagen={emma_w} mensaje="Cargando..." widthImg="7%" marginTop="7%" />;
	}
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
	let { generica, render, genericas, modalMod, modalAdd, modalDel, modalAdmValores } = state.redGenericas;
	return {
		render,
		generica,
		genericas,
		modalMod,
		modalAdd,
		modalDel,
		modalAdmValores
	};
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
	actualizarMensaje,
	actualizarRender,
	actualizarDataGenericas,
	actualizarDataGenerica,
	mostrarModalAdd,
	mostrarModalMod,
	mostrarModalDel,
	mostrarModulo,
	mostrarModalAdmValores
};

//Props del componente
Genericas.propTypes = {
	//variables
	render: PropTypes.bool.isRequired,
	genericas: PropTypes.array.isRequired,
	modalMod: PropTypes.bool.isRequired,
	modalAdd: PropTypes.bool.isRequired,
	modalDel: PropTypes.bool.isRequired,
	modalAdmValores: PropTypes.bool.isRequired,
	generica: PropTypes.shape({
		id: PropTypes.number.isRequired,
		nombre: PropTypes.string.isRequired,
		descripcion: PropTypes.string.isRequired
	}),
	//funciones
	actualizarMensaje: PropTypes.func.isRequired,
	actualizarRender: PropTypes.func.isRequired,
	actualizarDataGenericas: PropTypes.func.isRequired,
	actualizarDataGenerica: PropTypes.func.isRequired,
	mostrarModalAdd: PropTypes.func.isRequired,
	mostrarModalMod: PropTypes.func.isRequired,
	mostrarModalDel: PropTypes.func.isRequired,
	mostrarModulo: PropTypes.func.isRequired,
	mostrarModalAdmValores: PropTypes.func.isRequired
};
Genericas.defaultProps = {
	busquedaFija: false,
  };
// busquedaFija es un props diseñado para el modulo de preuniversitario en el apartado setting 
// donde el usuario podra agragar y eliminar nuevos colegios solo en los valores 
// escrito por Juan Caceres

export default connect(mapStateToProps, mapDispatchToProps)(Genericas);
