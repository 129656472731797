import React, { Component } from 'react';
import {
    mostrarModalDetallePostulante, mostrarModalDescargarHojaDeVida
} from "../../../redux/actions/actPortal";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
    Grid,
    Paper,
    MenuItem,
    ListItemText,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Button,
    TextField,
    Input
} from '@material-ui/core';
import DetallePostulante from '../Empresa/DetallePostulante'
import {
    AREAS_INTERES
} from '../helper'

//components
import ListarDatos from '../../general/ListarDatos';
import { BtnDetalle, BtnDescargar } from '../../general/BotonesAccion';
import DescargarHoJaDeVida from '../DescargarHoJaDeVida';

import { MenuProps, obtenerValores, consulta, transformar } from '../../../global/js/funciones'
import BuscarSelect from "../../general/BuscarSelect";




class BuscarPostulantes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dato_buscar: '',
            areas: [],
            _areas: [],
            programa: '',
            identificacion: '',
            _programas: [],
            cv: [],
            detalleCV: [],
            idDetallePostulante: 0,
            detallePostulante: [],
            setearEstado: (estado) => this.setState(estado)
        }
    }

    componentDidMount() {
        this.cargarProgramas();
        this.cargarAreas();
    }

    cargarAreas = async () => {
        const _areas = await obtenerValores(AREAS_INTERES);
        this.setState({
            _areas
        })
    }

    cargarProgramas = async () => {
        const _posgrados = await obtenerValores(31);
        const _diplomados = await obtenerValores(30);
        const valorPrograma = await obtenerValores(5);
        const _programas = transformar(valorPrograma);
        this.setState({
            _programas: [
                ..._programas,
                ..._diplomados,
                ..._posgrados
            ]
        })
    }

    setRol = ({ target }) => {
        this.setState({
            areas: target.value
        })
    }
    setPro = ({ target }) => {
        this.setState({
            programa: target.value
        })
    }

    setIde = ({ target }) => {
        this.setState({
            identificacion: target.value
        })
    }

    pintarNombresRoles = (selected) => {
        let resp = [];
        let { _areas } = this.state;
        selected.map((element) => {
            _areas.map((e) => {
                if (element === e.id) return resp.push(e.nombre)
                return null;
            })
            return null;
        });
        return resp.join(", ")
    }

    pintarTiposRoles = () => {
        let { areas, _areas } = this.state;
        if (Array.isArray(_areas)) {
            return _areas.map(({ nombre, id }) => (
                <MenuItem key={id} value={id}>
                    <Checkbox checked={areas.indexOf(id) > -1} />
                    <ListItemText primary={nombre} />
                </MenuItem>
            ))
        }
        return [];
    }

    listarInformacionPostulante = (row) => {
        let idDetallePostulante = row.id
        let { mostrarModalDetallePostulante } = this.props
        this.setState({ idDetallePostulante })

        // consulta("api/v1.0/portal/obtener_detalle_postulante", { id }, "post", (error, estado, resp) => {
        //     this.setState({
        //         detalleCV: resp
        //     })
        // })
        mostrarModalDetallePostulante(true)
    }

    actionSearch = _ => {
        let { programa, areas, identificacion } = this.state;
        let idPrograma = programa.value ? programa.value: "";
        consulta(`api/v1.0/portal/buscarcv`, { idPrograma, areas, identificacion }, 'post', (error, estado, resp) => {
            this.setState({
                cv: resp.map(item => item.usuario_registro)
            })
        })
    }

    actions = row => {
        return (
            <>
            <div style={{display: 'flex'}}>
                <BtnDetalle
                    callback={() => { this.listarInformacionPostulante(row) }}
                />

                <DescargarHoJaDeVida
                    idDetallePostulante={row.id}
                />
            </div>
            </>
        )
    }

    obtenerColor = codigo => {
        let color = '#ffffff'
        const colores = {
            '1': { 'backgroundColor': '#f5f5f5', 'color': '#000000' },
            '2': { 'backgroundColor': '#44c408', 'color': color },
            '3': { 'backgroundColor': '#c21b1b', 'color': color }
        }
        return codigo == null ? colores["1"] : colores["2"];
    }

    renderListarHistorial = () => {
        let { modalDetallePostulante, mostrarModalDetallePostulante} = this.props
        let { areas, programa, _programas, cv, idDetallePostulante, identificacion, detallePostulante,setearEstado } = this.state
        return (
            <>
                <div style={{
                    width: '100%',
                    paddingLeft: 40,
                    paddingBottom: 10
                }}>
                    <Grid container direction={'row'} spacing={2} >
                        {/* <Grid item xs={2} sm={8} md={3}>
                            <FormControl variant="outlined" className='form-control' style={{ marginTop: "7px" }}>
                                <InputLabel >Programas</InputLabel>
                                <Select
                                    value={programa}
                                    onChange={this.setPro}
                                    label="Programas"
                                >
                                    {
                                        _programas.map(({ id, nombre }) => {
                                            return <MenuItem key={id} value={id}>
                                                {nombre}
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={2} sm={8} md={3}>
                            <FormControl variant="outlined" className='form-control' style={{ marginTop: "3px" }}>
                                <BuscarSelect datos={_programas} cambiarEstado={(value) => setearEstado({programa : value})} valor={programa} id='programa' nombre='Programas' placeholder='Seleccione Programa' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={8} md={3}>
                            <FormControl variant="outlined" className='form-control' style={{ marginTop: "7px" }}>
                                <InputLabel htmlFor="select-multiple-checkbox">Areas de interes</InputLabel>
                                <Select
                                    multiple
                                    value={areas}
                                    input={<Input />}
                                    onChange={this.setRol}
                                    renderValue={selected => {
                                        return this.pintarNombresRoles(selected)
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {this.pintarTiposRoles()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={8} md={3}>
                            <FormControl /* variant="outlined" */ className='form-control' style={{ marginTop: "7px" }}>
                                <TextField
                                    value={identificacion}
                                    required
                                    type="text"
                                    id="Identificacion"
                                    name="Identificacion"
                                    label="Identificacion"
                                    fullWidth
                                    onChange={this.setIde}
                                    /* variant="outlined" */
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={12} md={3}>
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                style={{ color: "white", marginTop: 13 }}
                                onClick={this.actionSearch}
                            >
                                buscar
                            </Button>
                        </Grid>
                    </Grid>

                </div>
                <ListarDatos
                    datos={cv}
                    avatar={({ estado_proceso }) => null}
                    color_avatar={({ respuesta }) => this.obtenerColor(respuesta)}
                    acciones={row => this.actions(row)}
                    fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `Nombre: ${primer_nombre.toUpperCase()} ${primer_apellido.toUpperCase()} ${segundo_apellido.toUpperCase()}`}
                    filas={[]}
                />


                <DetallePostulante
                    // detallePostulante={detalleCV}
                    idDetallePostulante={idDetallePostulante}
                    mostrarModalDetallePostulante={mostrarModalDetallePostulante}
                    modalDetallePostulante={modalDetallePostulante}
                />

            </>


        )
    }

    render() {
        return (
            <div className='' >
                <div style={{ paddingTop: '2%' }} >
                    <Grid container direction={'row'} spacing={2} >
                        <Grid item xs={12} sm={12} md={12} >
                            <Paper elevation={0} square className="scroll" style={{ maxHeight: 400, width: "100%" }}>
                                {this.renderListarHistorial()}
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let {
        modalDetallePostulante,
        modalDescargarHojaDeVida,
    } = state.redPortal
    return {
        modalDetallePostulante,
        modalDescargarHojaDeVida,
    }
}

const mapDispatchToProps = {
    mostrarModalDetallePostulante,
    mostrarModalDescargarHojaDeVida
}

BuscarPostulantes.propTypes = {
    //funciones
    mostrarModalDetallePostulante: PropTypes.func.isRequired,
    mostrarModalDescargarHojaDeVida: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(BuscarPostulantes);
