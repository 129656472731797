import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// Material UI
import { Grid, FormControl, FormLabel, Button, Box, AppBar, Toolbar, makeStyles, useMediaQuery, useTheme, } from "@material-ui/core";

// Funciones globales
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import TareasTerminadas from "../TareasTerminadas";

// Componentes locales
import PintarPreguntas from "./PintarPreguntas";

// Imagenes
import emma_w from "../../../global/imagenes/emma_w.png";
import { coloresEmma, consulta } from "../../../global/js/funciones";
const useStyles = makeStyles((theme) => ({
    fixedColumn: { position: "fixed", left: 0, top: 0, height: "100%", width: "25%", boxShadow: "0 0 20px 0 rgba(0,0,0,0.1)", padding: "90px 30px", },
}));

const PintarEncuestaGenero = ({ mostrar_encuesta, setMostrarEncuesta }) => {
    const [preguntas, setPreguntas] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [respuestas, setRespuestas] = useState({});
    const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState({});
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        cargarDatos();
    }, []);

    const cargarDatos = async () => {
        consulta(`api/v1.0/personas/encuesta_genero`, null, null, (error, estado, resp) => {
            if (estado === 200) {
                setPreguntas(resp.preguntas);
                setCargando(false);
            } else {
                dispatch(
                    actualizarMensaje({
                        titulo: "Ha ocurrido un error, contacte con el administrador.",
                        tipo: "warning",
                        mostrar: true,
                        tiempo: 4000,
                    })
                );
                setCargando(false);
            }
        })
    };

    const handleChange = (pregunta_id, valor, tipo) => {
        setRespuestas((prevRespuestas) => ({
            ...prevRespuestas,
            [pregunta_id]: {
                pregunta_id: pregunta_id,
                valor: valor,
                tipo: tipo,
            }
        }));
        setRespuestasSeleccionadas((prev) => {
            return {
                ...prev,
                [pregunta_id]: { pregunta_id, respuesta: valor },
            };
        });
    };

    const onSubmit = () => {

        if (parseInt(Object.keys(respuestas).length) < 5) {
            dispatch(
                actualizarMensaje({
                    titulo: "Por favor, responda todas las preguntas.",
                    tipo: "info",
                    mostrar: true,
                    tiempo: 4000,
                })
            );
        } else {
            consulta(`api/v1.0/personas/guardar_encuesta_genero`, { respuestas: Object.values(respuestas) }, 'post', (error, estado, resp) => {
                if (estado === 201) {
                    dispatch(
                        actualizarMensaje({
                            titulo: "Encuesta guardada correctamente.",
                            tipo: "success",
                            mostrar: true,
                            tiempo: 4000,
                        })
                    );
                } else {
                    dispatch(
                        actualizarMensaje({
                            titulo: "Ha ocurrido un error, contacte con el administrador.",
                            tipo: "warning",
                            mostrar: true,
                            tiempo: 4000,
                        })
                    );
                }
            });
            setMostrarEncuesta(false);
        }
    }

    return (
        <>
            {!cargando ? (
                <Grid
                    container
                    direction={"row"}
                    alignItems="center"
                    justifyContent="space-evenly"
                >
                    {isMobile ? (
                        <>
                            <Grid item xs={12} sm={12} md={12}>
                                <AppBar position="fixed" elevation={0}>
                                    <Toolbar style={{ display: "flex", justifyContent: "center", }}>
                                        <div
                                            style={{
                                                padding: "5px 0px 5px 3px",
                                                color: "white",
                                                textAlign: "center",
                                            }}
                                        >
                                            <p>
                                                ENCUESTA ENFOQUE Y EQUIDAD DE GÉNERO
                                            </p>
                                        </div>
                                    </Toolbar>
                                </AppBar>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                className="contenido_encuesta"
                            >
                                {preguntas.map((pregunta, index) => (
                                    // Necesito validar que el pregunta.tipo == 3 si asi debo colocarle un div con un parrafo de lo contrario no se coloca nada
                                    <FormControl
                                        component="fieldset"
                                        style={{
                                            width: "85%",
                                            margin: "0 30px 40px 30px"
                                        }}
                                    >
                                        {parseInt(pregunta.tipo) == 3 && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "start", textAlign: "left", margin: "0px" }}>
                                                    <p>
                                                    La universidad de la Costa esta comprometida en la construcción de entornos seguros y libres de cualquier tipología de violencia basada en género (VBG) dentro del campus universitario.
                                                    </p>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "start", textAlign: "left", margin: "0px" }}>
                                                    <p>
                                                    Por ese motivo te invitamos a sumarte a nuestra iniciativa desde la estrategia de Equidad de Género de Bienestar Estudiantil.
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        <FormLabel
                                            style={{
                                                textAlign: "justify",
                                                margin: "0 0 15px 0",
                                                color: "#000",
                                            }}
                                        >
                                            {pregunta.pregunta}
                                        </FormLabel>
                                        <PintarPreguntas pregunta={pregunta} respuestas={pregunta.respuestas} handleChange={handleChange} respuestasSeleccionadas={respuestasSeleccionadas} isMobile={isMobile} />
                                    </FormControl>
                                ))}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={onSubmit}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                xs={7}
                                style={{ backgroundColor: "#fbfafb", right: "0" }}
                            >
                                <Box px={10} py={3}>
                                    <h3>ENCUESTA ENFOQUE Y EQUIDAD DE GÉNERO</h3>
                                    {preguntas.map((pregunta, index) => (
                                        <FormControl
                                            component="fieldset"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            {parseInt(pregunta.tipo) == 3 && (
                                                <>
                                                    <div style={{ display: "flex", justifyContent: "start", textAlign: "left", margin: "0px" }}>
                                                        <p>
                                                        La universidad de la Costa esta comprometida en la construcción de entornos seguros y libres de cualquier tipología de violencia basada en género (VBG) dentro del campus universitario.
                                                        </p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "start", textAlign: "left", margin: "0px" }}>
                                                        <p>
                                                        Por ese motivo te invitamos a sumarte a nuestra iniciativa desde la estrategia de Equidad de Género de Bienestar Estudiantil.
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                            <FormLabel
                                                style={{
                                                    textAlign: "justify",
                                                    margin: "30px 0 18px 0",
                                                    color: "#000",
                                                }}
                                            >
                                                {pregunta.pregunta}
                                            </FormLabel>
                                            <PintarPreguntas pregunta={pregunta} respuestas={pregunta.respuestas} handleChange={handleChange} respuestasSeleccionadas={respuestasSeleccionadas} isMobile={isMobile} />
                                        </FormControl>
                                    ))}
                                    <div style={{ display: "flex", justifyContent: "center", margin: "30px 0 0 0", width: "100%" }}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            style={{ width: "50%" }}
                                            onClick={onSubmit}
                                        >
                                            Guardar
                                        </Button>
                                    </div>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            ) : (
                <TareasTerminadas
                    mensaje={"Cargando.."}
                    marginTop="7%"
                    imagen={emma_w}
                    widthImg="7%"
                    cargando={true}
                />
            )}
        </>
    );
};

export default PintarEncuestaGenero;
