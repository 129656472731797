import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { mostrarModalDel } from "../../redux/actions/actGenericas";
import { obtenerValores, obtenerPermisosValor, formulario, mostrarError, obtenerValoresFiltros } from "../../global/js/funciones";
import SolicitudesForm from "./SolicitudesForm";
import {TIPOS_IDENTIFICACION, DEPARTAMENTOS, COMUNIDAD, GENERO, REQUISITOS_CONSULTORIA,AREA_DERECHO, TIPOS_CONSULTORIA} from './helper';


class SolicitudesAgregar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tipos_identificacion: [],
      area_derecho: [],
      programas:[],
      programa:'',
      tipo_identificacion: '',
      area:'',
      identificacion: '',
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      telefono: '',
      correo: '',
      departamentos: [],
      departamento: '',
      ciudades: [],
      ciudad: '',
      barrio: '',
      direccion: '',
      comunidad:'',
      egresado:false,
      comunidades:[],
      generos:[],
      requisitos:[],
      archivos: [],
      archivos2:[],
      genero:'',
      anho:'',
      adj_identificacion: '',
      adj_identificacion_final: '',
      discapacidad:'',
      discapacidades: [
        {
          value: '1',
          label: 'SI',
        },
        {
          value: '0',
          label: 'NO',
        }
      ],
      hechos:'',
      Pretensiones:'',
      identidad_doc:'',
      identidad_doc_final:'',
      adjuntoOpcionales:false,
      tipos_consultorias: [],
      tipo_consultoria: ''
    }
  }

  componentDidMount() {
    this.cargarTipos();
  }

  async componentDidUpdate({}, { departamento }) {
    let { departamento: departamentoNew } = this.state;
    if (departamentoNew.value !== departamento.value) {
      let ciudades = await obtenerPermisosValor(departamentoNew.value);
      ciudades = ciudades.map(({ secundario }) => ({
        value: secundario.id,
        label: secundario.nombre,
      }));

      this.changeState('ciudades', ciudades);
      this.changeState('ciudad', '');
    }
  }

  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  guardarSolicitud = async (archivos) => {
    let {actualizarMensaje } = this.props;
    actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 4000 });
    let {
      tipo_identificacion,
      area,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      telefono,
      departamento,
      ciudad,
      barrio,
      direccion,
      comunidad,
      genero,
      programa,
      anho,
      discapacidad,
      hechos,
      Pretensiones,
      archivos2,
      correo,
      tipo_consultoria
    } = this.state;
    const data = new FormData();
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo);
      data.append(`codigo${index}`, codigo);
      return true;
    });
    archivos2.map((file, index) => data.append(`archivo2${index}`, file));
    data.append('total', archivos.length);
    data.append('total2', archivos2.length);
    data.append('hechos', hechos);
    data.append('Pretensiones', Pretensiones);
    data.append('discapacidad', discapacidad.value);
    data.append('tipo_identificacion', tipo_identificacion.value);
    data.append('area', area.value);
    data.append('identificacion', identificacion);
    data.append('telefono', telefono);
    data.append('correo', correo);
    data.append('primer_nombre', primer_nombre);
    data.append('segundo_nombre', segundo_nombre);
    data.append('primer_apellido', primer_apellido);
    data.append('segundo_apellido', segundo_apellido);
    data.append('departamento', departamento.value);
    data.append('ciudad', ciudad.value);
    data.append('comunidad', comunidad.value);
    data.append('genero', genero.value);
    data.append('lugar_residencia', barrio);
    data.append('direccion', direccion);
    data.append('tipo_consultoria', tipo_consultoria.value);
    if(programa !== ''){
      data.append('programa', programa.value);
    }
    if(anho !== ''){
      data.append('anho', anho);
    }
    formulario("api/v1.0/consultoria/crear", data, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.reset()
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }, false
    );

  }

  reset(){
    this.setState({
      tipo_identificacion: '',
      area:'',
      identificacion: '',
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      telefono: '',
      correo: '',
      ciudad: '',
      barrio: '',
      direccion: '',
      comunidad:'',
      archivos: [],
      archivos2:[],
      genero:'',
      anho:'',
      adj_identificacion: '',
      adj_identificacion_final: '',
      discapacidad:'',
      hechos:'',
      Pretensiones:'',
      identidad_doc:'',
      identidad_doc_final:'',
      adjuntoOpcionales:false,
      egresado:false,
      programa:'',
      tipo_consultoria: ''
    })
  }
  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  onChange = ({ target }) => {
    let { value, name } = target;
      this.setState({
        [name]: value
      })
  }
  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let{archivos2}= this.state;
    archivos2.splice(i, 1)
    this.setState({ archivos2 })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  agregarArchivos = (arc) => {
    let{archivos2}= this.state;
    archivos2 = archivos2.concat(arc);
    this.setState({ archivos2 })
  }

  checked = ({target}) => {
    this.setState({
      [target.name]: target.checked
    })
  }
  
  submit = (archivos) => {
    let {requisitos} =this.state
    let {actualizarMensaje } = this.props;
    let contador=0
    let contador2=0
    requisitos.map((info)=>{
      if(info.valora === '1'){
        contador=contador + 1;
        archivos.map((j)=>{
          if(j.codigo === info.id){
            contador2=contador2+1
          }
        })
      }
      
    })
     if (contador2 >= contador) {
      this.guardarSolicitud(archivos)
    } else actualizarMensaje({ titulo: 'Debe adjuntar los documentos que son obligatorio', tipo: 'info', mostrar: true, tiempo: 6000 });

  }

  cargarTipos = async () => {
    const transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
      }));
    }
    const tipos_identificacion = await obtenerValores(TIPOS_IDENTIFICACION);
    const departamentos = await obtenerValores(DEPARTAMENTOS);
    const comunidades = await obtenerValores(COMUNIDAD);
    const generos = await obtenerValores(GENERO);
    const requisitos = await obtenerValores(REQUISITOS_CONSULTORIA);
    const programas = await obtenerValores(5);
    const area_derecho = await obtenerValores(AREA_DERECHO);
    const tipos_consultorias = await obtenerValores(TIPOS_CONSULTORIA);
    this.setState({
      requisitos,
      tipos_identificacion: transformar(tipos_identificacion),
      departamentos: transformar(departamentos),
      comunidades: transformar(comunidades),
      generos: transformar(generos),
      programas: transformar(programas),
      area_derecho:transformar(area_derecho),
      tipos_consultorias: transformar(tipos_consultorias),
      cargando : false
    })
  }

  validarDatos = (step) => {
    let {
      tipo_identificacion,
      area,
      identificacion,
      primer_nombre,
      primer_apellido,
      telefono,
      correo,
      departamento,
      ciudad,
      barrio,
      direccion,
      comunidad,
      genero,
      discapacidad,
      hechos,
      Pretensiones,
      tipo_consultoria
    } = this.state;
    let mensaje = '';
    if(step === 1) {
      if (!tipo_consultoria) mensaje = 'Por favor su tipo de asesoria.';
      else if (!area) mensaje = 'Por favor seleccione tipo de area.';
      else if (!Pretensiones) mensaje = 'Por favor escriba las pretensiones.';
      else if (!hechos) mensaje = 'Por favor escriba los hechos.';
    }
    if(step === 0) {
      if (!tipo_identificacion) mensaje = 'Seleccione tipo identificación.';
      else if (!identificacion) mensaje = 'Ingrese #identificación.';
      else if (!primer_nombre) mensaje = 'Ingrese primer nombre.';
      else if (!primer_apellido) mensaje = 'Ingrese primer apellido.';
      else if (!telefono) mensaje = 'Ingrese telefono.';
      else if (!correo) mensaje = 'Ingrese correo.';
      else if (!departamento) mensaje = 'Ingrese departamento.';
      else if (!ciudad) mensaje = 'Ingrese ciudad.';
      else if (!barrio) mensaje = 'Ingrese barrio.';
      else if (!direccion) mensaje = 'Ingrese direccion.';
      else if (!comunidad) mensaje = 'Ingrese tcomunidad.';
      else if (!genero) mensaje = 'Ingrese genero.';
      else if (!discapacidad) mensaje = 'Ingrese discapacidad.';
    }
    return mensaje;
  }


  render() {
    let {
      tipo_identificacion,
      programas,
      programa,
      tipos_identificacion,
      area_derecho,
      area,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      barrio,
      direccion,
      departamentos,
      departamento,
      ciudades,
      ciudad,
      telefono,
      correo,
      comunidades,
      comunidad,
      discapacidad,
      discapacidades,
      genero,
      generos,
      hechos,
      Pretensiones,
      anho,
      requisitos,
      archivos,
      archivos2,
      egresado,
      adjuntoOpcionales,
      tipo_consultoria,
      tipos_consultorias
    } = this.state;
    let { actualizarMensaje } = this.props;
      return (
        <div>
          <SolicitudesForm
            props_c={{
              obtenerValores: () => obtenerValores,
              tipo_identificacion,
              tipos_identificacion,
              area_derecho,
              area,
              identificacion,
              primer_nombre,
              segundo_nombre,
              primer_apellido,
              segundo_apellido,
              barrio,
              archivos2,
              direccion,
              departamentos,
              departamento,
              ciudades,
              ciudad,
              telefono,
              correo,
              comunidad,
              comunidades,
              discapacidad,
              discapacidades,
              genero,
              generos,
              hechos,
              Pretensiones,
              egresado,
              requisitos,
              archivos,
              programas,
              programa,
              anho,
              adjuntoOpcionales,
              onChange: this.onChange,
              submit: this.submit,
              checked : this.checked,
              validarDatos: this.validarDatos,
              eliminarArchivo:this.eliminarArchivo,
              agregarArchivos:this.agregarArchivos,
              cambiarEstado:this.cambiarEstado,
              actualizarMensaje,
              tipo_consultoria,
              tipos_consultorias,
              setTipo_identificacion: (value) => {
                this.changeState('tipo_identificacion', value)
              },
              setDepartamento: (value) => {
                this.changeState('departamento', value)
              },
              setPrograma: (value) => {
                this.changeState('programa', value)
              },
              setCiudad: (value) => {
                this.changeState('ciudad', value)
              },
              setGenero: (value) => {
                this.changeState('genero', value)
              },
              setComunidad: (value) => {
                this.changeState('comunidad', value)
              },
              setDiscapacidad: (value) => {
                this.changeState('discapacidad', value)
              },
              setArea_derecho: (value) => {
                this.changeState('area', value)
              },
              setTipo_Consultoria: (value) => {
                this.changeState('tipo_consultoria', value)
              },
            }}
          />
        </div>
      )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalDel } = state.redGenericas;
  return {
    modalDel,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalDel,
}

SolicitudesAgregar.propTypes = {
  //variables
  modalDel: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SolicitudesAgregar);
