import React, { useEffect, useState } from "react";
import {
    Button,
    Typography,
    Link,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import {
    api,
    obtenerValores,
    obtenerPermisosValorAlt,
} from "../../global/js/funciones";

import { ThemeProvider, Fab } from "@material-ui/core";
import { EstiloApp } from "./EstiloApp";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import HelpIcon from '@material-ui/icons/Help';
import ComputerIcon from '@material-ui/icons/Computer';
import PhoneIcon from '@material-ui/icons/Phone';
import ClearIcon from '@material-ui/icons/Clear';
import SchoolIcon from '@material-ui/icons/School';
import { coloresEmma } from "../../global/js/funciones";
class FloatingMenuItem extends React.Component {
    handleClick() {
        if (this.props.action) this.props.action();
    }

    render() {
        let label;

        if (this.props.label) {
            label = <label>{this.props.label}</label>;
        }
        return (
            <div onClick={this.handleClick.bind(this)} className="floating-menu-item">
                {label}
                <Fab style={{'background': coloresEmma.primarycolor}}  aria-label="add" elevation={0}>
                    {this.props.icon}
                </Fab>
            </div>
        );
    }
}

const Contacts = () => {
    const [contactoData, setContactoData] = useState([]);
    const [valoresData, setValoresData] = useState([]);
    const [dataC, setDataC] = useState([]);
    const [toggled, setToggled] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [open, setOpen] = React.useState(false);

    let buttons = [];
    let className = "floating-menu";
    let icon = <PhoneIcon style={{'color': coloresEmma.colorblanco}} />;

    useEffect(() => {
        obtenerData();
    }, []);

    const boton = () => {
        setToggled(!toggled);
    };

    const mostrarDataContacto = (data) => {
        obtenerValoresContactos(data.id);
        if (data.valorb === "link") {
            window.open(data.valorc);
        } else {
            setDataC(data);
            setOpen(true);
        }
    };

    const obtenerData = async () => {
        let name = await obtenerValores(124);
        
        name = name
            .sort(function (a, b) {
                return a.valora < b.valora ? -1 : a.valora > b.valora ? 1 : 0;
            })
            .filter(function (data) {
                return data.id != "17889";
            });
     
        setContactoData(name);
    };

    const obtenerValoresContactos = async (id) => {
        setCargando(true);
        let resp = await obtenerPermisosValorAlt([
            { llave: "principal", valor: id },
            { llave: "secundario__generica", valor: 125 },
        ]);
        resp.sort(function (a, b) {
            return a.secundario.valorb - b.secundario.valorb;
        });
        console.log(resp);
        setCargando(false);
        setValoresData(resp);
    };
    const Pintar = ({ correo }) => {
        let correos = correo.split(";");
        return correos.map((e) => (
            <Link
                className="linkPreguntas"
                target="_blank"
                href={`https://teams.microsoft.com/l/chat/0/0?users=${e}`}
            >
                {e} <span> </span>
            </Link>
        ));
    };

    if (toggled) {
        className += " open";
        icon = <ClearIcon style={{'color': coloresEmma.colorblanco}}/>;
        contactoData.map((data, index) => {
            buttons.push(
                <FloatingMenuItem
                    label={data.nombre}
                    icon={
                        data.nombre.includes("Whatsapp")
                            ? <WhatsAppIcon style={{'color': coloresEmma.colorblanco}}/>
                            : data.nombre.includes("Auxiliares")
                                ? <SchoolIcon style={{'color': coloresEmma.colorblanco}}/>
                                : data.nombre.includes("Consulta")
                                    ? <ComputerIcon style={{'color': coloresEmma.colorblanco}}/>
                                    : <HelpIcon style={{'color': coloresEmma.colorblanco}}/>
                    }
                    action={() => mostrarDataContacto(data)}
                    key={"i" + index}
                />
            );
        });
    }

    buttons.push(
        <FloatingMenuItem
            // label="CANALES DE ATENCION"
            icon={icon}
            action={() => {
                boton();
            }}
            key="m"
        />
    );

    return (
        <>
            <ThemeProvider theme={EstiloApp}>
                <div className="container">
                    <div className={className}>{buttons}</div>
                </div>
            </ThemeProvider>

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    style={{ background: coloresEmma.secondarycolor, color: coloresEmma.colorblanco }}
                >
                    <Typography>
                        <Box
                            fontWeight="fontWeightMedium"
                            fontSize={30}
                            style={{ textAlign: "center" }}
                        >
                            {dataC.nombre}
                        </Box>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ float: "left" }}>
                        {cargando ? (
                            <div style={{ color: coloresEmma.secondarycolor }}>
                                <Typography>
                                    <Box fontWeight="fontWeightMedium">Cargando...</Box>
                                </Typography>
                            </div>
                        ) : (
                            valoresData.map(({ secundario, principal }) => {
                                // if (dataC.id == principal.id) {
                                if (secundario.valora == "parrafo") {
                                    return (
                                        <Typography className="containerItem_p">
                                            {" "}
                                            {secundario.nombre}{" "}
                                        </Typography>
                                    );
                                } else {
                                    return (
                                        <Typography className="containerItem_q">
                                            <span className="item_p"> {secundario.valorb} </span>
                                            {secundario.nombre}
                                            <span> </span>
                                            <Pintar correo={secundario.valorc}></Pintar>
                                        </Typography>
                                    );
                                }
                            })
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} style={{ color: coloresEmma.secondarycolor }}>
                        <Typography>
                            <Box fontWeight="fontWeightBold">CERRAR</Box>
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Contacts;
