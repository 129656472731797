import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';


const Copyright = () => {
  return (
    <div className='footer'>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.cuc.edu.co/">
          Universidad de la Costa CUC
      </Link>{' '}
        {`${new Date().getFullYear()}.`}
      </Typography>
    </div>
  );
}

export default Copyright;

