import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { obtenerValoresFiltros, transformar,consulta } from "../../../global/js/funciones";
import BuscarSelect from '../../general/BuscarSelect';
import BuscarPersona from '../../general/BuscarPersona';
import InputBuscar from '../../general/InputBuscar';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import {AREA_DERECHO} from '../helper';


class ConsultoriaFiltrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Area_derecho: [],
      area: null,
      periodo: null,
      cargando: true,
      cargar: true,
    }
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
        consulta(`api/v1.0/consultoria/periodos`, null, null,
            (error, estado, resp) => {
                resolve(resp)
            }
        );
    })
  }

  async componentDidUpdate({ ModalAddFiltrosConsultoria, accionProceso }) {
    let { ModalAddFiltrosConsultoria: modalAddFiltrosConsultoriaNew, accionProceso: accionProcesoNew } = this.props;
    if (ModalAddFiltrosConsultoria !== modalAddFiltrosConsultoriaNew && this.state.cargar) {
      this.setState({ cargando: true })
      let Area_derecho = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': AREA_DERECHO}]);
      let periodos = await this.obtenerPeriodos();
      this.setState({
        'Area_derecho': transformar(Area_derecho),
        'periodos': transformar(periodos, 'periodo', 'periodo'),
        cargando: false,
        cargar: false
      })
    }

    if (accionProceso != accionProcesoNew && accionProceso != 'inicio') {
      this.setState({
        area: null,
        busca_empresa: '',
        trabaja: '',
        en_area: '',
        per: '',
        estudiante: {
          id: 0,
          nombre: ''
        }
      })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  resetState = () => {
    let { limpiar, mostrarModalAddFiltrosConsultoria } = this.props;
    this.setState({
      area: null,
      per: '',
    })
    limpiar();
    mostrarModalAddFiltrosConsultoria(false)
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje, accionProceso, mostrarTodos, mostrarModalAddFiltrosConsultoria } = this.props;
    let {
      periodo,
      area,
    } = this.state;
    let filtros = [];
    if (periodo !== null) filtros.push({ 'llave': 'periodo', 'valor': periodo.value });
    if (area !== null) filtros.push({ 'llave': 'area', 'valor': area.value });
    if (filtros.length > 0){
      if (accionProceso == 'inicio') mostrarTodos('Tod_Consul')
      mostrarModalAddFiltrosConsultoria(false)
      filtrar(filtros);
    } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', area: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { ModalAddFiltrosConsultoria, mostrarModalAddFiltrosConsultoria, accionProceso } = this.props;
    let {
      periodo,
      area,
      Area_derecho,
      periodos,
      cargando
    } = this.state;
    let habilitar_limpiar = accionProceso !== 'Tod_Consul' && accionProceso !== 'inicio'
    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={ModalAddFiltrosConsultoria} onClose={() => mostrarModalAddFiltrosConsultoria(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalAddFiltrosConsultoria} titulo_accion={ habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.resetState : ''} />
          <DialogContent className='scroll'>
            {cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/> :
              <Grid container spacing={3} className='margin_cont' >
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                      <BuscarSelect datos={periodos} cambiarEstado={(value) => this.setState({ periodo: value })} valor={periodo} id='select-periodo' nombre='Periodo' placeholder='Seleccione Periodo' />
                    </Grid>
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                      <BuscarSelect datos={Area_derecho} cambiarEstado={(value) => this.setState({ area: value })} valor={area} id='select-area' nombre='Area Derechos' placeholder='Seleccione Area de derecho' />
                    </Grid>
              </Grid>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalAddFiltrosConsultoria(false)} />
            {!cargando && <BtnForm texto="FILTRAR" callback={this.onSubmit} />}
          </DialogActions>
        </Dialog >
      </>
    )
  }
}

//Props del componente
ConsultoriaFiltrar.propTypes = {
  //variables
  ModalAddFiltrosConsultoria: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAddFiltrosConsultoria: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
  limpiar: PropTypes.func.isRequired,
}
export default ConsultoriaFiltrar;
