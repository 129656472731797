import React, { useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import imagenInicio from '../../global/imagenes/InicioBloques.svg';
import emma_s from '../../global/imagenes/emma_s1.png';
import TareasTerminadas from '../general/TareasTerminadas';
import * as Helper from './children/helpers';
import * as Funciones from '../../global/js/funciones';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, Paper, Typography, Card, CardHeader, CardContent, Avatar, Grow, Slide, createMuiTheme } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress';
import ListarDatos from '../general/ListarDatos';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from '../general/AppBarModal';
import { api } from "../../global/js/funciones";
import SearchIcon from '@material-ui/icons/Search';
import { InputBase, Fab } from '@material-ui/core'
import obrero from '../../global/imagenes/obrero.png';
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {  mostrarModulo } from '../../redux/actions/actGlobal';
import BannerAdmin from './AdminSalas/ui/BannerAdminProcesos';
import { coloresEmma } from '../../global/js/funciones';
// import BannerAdmin from './ui/BannerAdminProcesos'
const useStyles = makeStyles((theme) => ({
    header: {
        background: coloresEmma.secondarycolor,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            padding: '20px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '40px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
    },
    imgInicio: {
        [theme.breakpoints.down('md')]: {
            minHeight: '30px',
            minHeight: '20%',
            marginTop: '25px',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '50px',
            maxWidth: '40%'
        },
    },
    headerTitleTextBox:{
        [theme.breakpoints.down('md')]: {
            maxHeight: '30%',

        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40%',
            
        },
        
    },
    headerTitle: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '26px',
            fontWeight: '800',
            color: '#ffffff',
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '46px',
            fontWeight: '800',
            color: '#ffffff'
        },
    },
    headerText: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px',
            fontWeight: '300',
            color: '#ffffff',
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            fontWeight: '300',
            color: '#ffffff'
        },
    },
    cardsBox:{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },    
    cardsbloques:{
        position: 'relative',
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        margin: '20px',
        '&:hover': {
            backgroundColor: coloresEmma.primarycolor,
            color: '#FFFFFF',
            transition: "all 0.1s",
            justifyContent: 'center',
            padding: '0px',
            transform: 'scale(1.05)',
            "& $cardsImgBloques": {
                display: "none"
            },
            "& $cardsTitleBloques": {
                display: "block",
                transform: 'translate(0%, -180%)'
            },
            "& $cardsText": {
                display: "block"
            },
            "& $cardsButton": {
                display: "block"
            }
        },
    },
    cards:{
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        margin: '20px',
        '&:hover': {
            backgroundColor: coloresEmma.secondarycolor,
            color: '#FFFFFF',
            transition: "all 0.1s",
            justifyContent: 'center',
            padding: '0px',
            transform: 'scale(1.05)',
            "& $cardsImg": {
                display: "none"
            },
            "& $cardsText": {
                display: "block"
            },
            "& $cardsButton": {
                display: "block"
            }
        },
    },
    cards1:{
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        margin: '20px',
        '&:hover': {
            backgroundColor: '#ad2623',
            color: '#FFFFFF',
            transition: "all 0.1s",
            justifyContent: 'center',
            padding: '0px',
            transform: 'scale(1.05)',
            "& $cardsImg": {
                display: "none"
            },
            "& $cardsText": {
                display: "block"
            },
            "& $cardsButton": {
                display: "block"
            }
        },
    },
    cardsSoporte:{
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px',
    },
    cardsTextSoporte:{
        fontSize: '20px',
        fontWeight: '300',
        maxWidth: '70%',
        textAlign: 'center'
    },
    cardsImg:{
        maxHeight: '70%',
        maxWidth: '90%',
        display: 'block'
    },
    cardsTitle:{
        maxHeight: '20%',
        maxWidth: '90%'
    },
    cardsImgBloques:{
        width: '100%',
        height: '130%',
        objectFit: 'cover',
        borderRadius: '2%',
        position: 'relative',
        zIndex: 1,
    },
    cardsTitleBloques:{
        position: 'absolute',
        margin: 0,
        padding: '10px',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '10px',
        zIndex: 2,
    },
    cardsText:{
        fontSize: '20px',
        fontWeight: '300',
        color: '#FFFFFF',
        display: 'none',
        maxWidth: '70%',
        margin:'1px 0 3px 0',
    },
    cardsButton:{
        display: 'none',
        textDecoration: 'none',
        border: 'none',
        borderRadius: '100px',
        background: '#FDFDFD',
        width: '188px',
        height: '50px',
        fontSize: '20px',
        color: coloresEmma.primarycolor,
        cursor: 'pointer'
    },
    searchBox:{
        marginTop: '50px'
    },
    searchButton:{
        textDecoration: 'none',
        border: 'none',
        borderRadius: '100px',
        background: '#c79e32',
        width: '150px',
        height: '50px',
        fontSize: '20px',
        color: '#FDFDFD',
        cursor: 'pointer'
    },
    inputRoot: {
        color: 'black',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(0)}px)`,
        width: '60%',
        background: '#FFFFFF',
        borderRadius: '50px',
        height: '30px'
    },
    searchIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: coloresEmma.secondarycolor,
        height: '50px',
        borderTopRightRadius: '50px',
        borderBottomRightRadius: '50px'
    },
    search: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        backgroundColor: '#FFFFFF',
        width: '40%',
        height: '50px',  
        margin:'0 300px 10px' 
    },
    searchFirstChildBox:{
        maxWidth: '500px', 
        border: '1px solid rgba(0, 0, 0, 0.25)', 
        borderRadius: '50px'
    },
    search2: {
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        height: '50px',
        width: '40%',
        [theme.breakpoints.down('xs')]: {
          width: '85%',
        },
    },
    cardSolicitudes: {
        maxWidth: "100%",
    },
    image: {
        width: '50%',
        maxWidth: '200px',
        minWidth: '100px',
    },
    content_text: {
        padding: "0px 10px 0px 10px",
    },
    content_img: {
        textAlign: 'center'
    },

    avatar: {
        backgroundColor: coloresEmma.primarycolor,
    },

    paperCard:{
        borderRadius: '25px', 
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border:'1px solid rgba(0, 0, 0, 0.25)',
        '&:hover': {
            transition: "all 0.1s",
            transform: 'scale(1.05)'
        },
    },

    midTitle: {
        fontSize: '36px',
        fontWeight: '800',
        color: '#000000'
    },
    midSubTitle: {
        fontSize: '26px',
        fontWeight: '600',
        color: '#000000'
    },
    midDescription:{
        fontSize: '16px',
        fontWeight: '400',
        color: '#000000'
    }
}));

const Bloques = (props) => {

    const classes = useStyles()
    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true);
    const [bloques, setBloques] = React.useState([]);
    const [solicitudesBloques, setSolicitudesBloques] = React.useState([]);
    const [salas, setSalas] = React.useState([]);
    const [seleccion, setSeleccion] = React.useState("SOLICITUDES");
    const [seleccionGeneral, setSeleccionGeneral] = React.useState("NOLIST");
    const [idSala, setIdSala] = React.useState([]);
    const [buscarSoftware, setBuscarSoftware] = React.useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const GridBuscar = useRef();
    const [opacity, setOpacity] = useState("0");
    const [position, setPosition] = useState('0')
    const [software, setSoftware] = React.useState([]);
    const [soporte, setSoporte] = React.useState([]);
    const [salaTitle, setSalaTitle] = React.useState("");
    const [bloqueTitle, setBloqueTitle] = React.useState("");
    const [virtuales, setVirtuales] = React.useState([]);
    //Errores
    const [errores, setErrores] = React.useState([]);
    const dispatchI = useDispatch();

    const getError = (llave, errores) => {
        let existe = errores.find(e => e.llave === llave);
        return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
        return new Promise((resolve) => {
        let errores = [];

        campos.map(({ value, name, err, value2 }) => {
            if (err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
        });
        
        resolve(errores);
        });
    }

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            dispatchI(mostrarModulo({ ruta: '/salas', nombre: 'Salas' }))
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                await getSolicitudes();
                setCargando(false)
                
            }
            obtenerProcesos()
            // mostrarModulo({ ruta: 'salas', nombre: 'Salas' })
        }

        // const handleScroll = () => {
        //     const grid = GridBuscar.current;
        //     const { y } = grid.getBoundingClientRect();
        //     const opacityCambiar = y - 20 <= 0 ? "1" : "0"
        //     setOpacity(opacityCambiar)
        //     const positionCambiar = y - 20 <= 0 ? "fixed" : ""
        //     setPosition(positionCambiar)
      
        // }
        //     document.getElementById('seccion-vista').addEventListener('scroll', handleScroll)
    
        // return () => {
        //     document.getElementById('seccion-vista').removeEventListener('scroll', handleScroll)
        // }

    }, [])
    
    //Obtener Bloques
    const getSolicitudes = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.SOLICITUDES_BLOQUES)
            data.sort((a, b) => {
                if(a.valorb < b.valorb) return -1;
                if(a.valorb > b.valorb) return 1;
            
                return 0;
            })
            console.log(data)
            setSolicitudesBloques(data)
        }catch(err){
          console.log(err)
        } 
    }

    //Obtener Bloques
    const getSalas= async (id) => {
        try{
            let data = await Funciones.obtenerPermisosValor(id)
            
            data = data.map(({ secundario }) => ({
                id: secundario.id,
                nombre: secundario.nombre,
                codigo: secundario.codigo,
                valora: secundario.valora,
                valorb: secundario.valorb,
                valorc: secundario.valorc,
                valord: secundario.valord,
                valore: secundario.valore,
                valorf: secundario.valorf,
                archivo: secundario.archivo
            }));
            data.sort((a, b) => {
                if(a.valorb < b.valorb) return -1;
                if(a.valorb > b.valorb) return 1;
            
                return 0;
            })
            setSalas(data)
        }catch(err){
          console.log(err)
        } 
    }

    //Evento click bloques a salas
    const changeBloquesToSalas = (id, nombre) =>{
        setCargando(true)
        const obtenerProcesos = async (id) => {
            
            await getSalas(id);
            setBloqueTitle(nombre)
            setSeleccion('SALAS')
            setCargando(false)
            
        }
        obtenerProcesos(id)
    }

    //Evento click bloques a salas
    const changeBloquesToSoporte = (id) =>{
        setCargando(true)
        const obtenerProcesos = async (id) => {
            
            await getSoporte(id);
            setSeleccion('SOPORTE')
            setCargando(false)
            
        }
        obtenerProcesos(id)
    }

    //Obtener Soporte
    const getSoporte= async (id) => {
        try{
            let data = await Funciones.obtenerPermisosValor(id)
            data = data.map(({ secundario }) => ({
                id: secundario.id,
                nombre: secundario.nombre,
                codigo: secundario.codigo,
                valora: secundario.valora,
                valorb: secundario.valorb
            }));
            setSoporte(data)
        }catch(err){
          console.log(err)
        } 
    }

    //Obtener Software
    const getSoftware= async (id) => {
        try{
            let data = await Funciones.obtenerPermisosValor(id)
            data = data.map(({ secundario }) => ({
                id: secundario.id,
                nombre: secundario.nombre,
                codigo: secundario.codigo,
                valora: secundario.valora,
                valorb: secundario.valorb
            }));
            setSoftware(data)
        }catch(err){
          console.log(err)
        } 
    }

    //Evento click salas a SOFTWARE
    const changeSalasToSoftware = (id, nombre) =>{
        setCargando(true)
        const obtenerProcesos = async (id) => {
            
            await getSoftware(id);
            setSeleccion('SOFTWARE')
            setSalaTitle(nombre)
            setCargando(false)
            
        }
        obtenerProcesos(id)
       
    }

    const buttonBack = () =>{
        if(seleccion == "SOFTWARE"){
            setSeleccion("SALAS")
        }else if(seleccion == "SALAS"){
            setSeleccion("BLOQUES")
        }else if(seleccion == "SOPORTE"){
            setSeleccion("SOLICITUDES")
        }else if(seleccion == "BLOQUES"){
            setSeleccion("SOLICITUDES")
        }
    }

    const handleOnchange = async (e) =>{
        if(e.target.name == "buscarSoftware"){
            setBuscarSoftware(e.target.value)
            if(errores.length > 0){
                setErrores(errores.filter(er => er.llave != "buscarSoftware"));
                // Elimina error cuando se escribe en el campo
            }
        }
    }

    const openModalSearch = async () => {
        let error = await validateInputs([
            { value: buscarSoftware, name: 'buscarSoftware', err: { empty: true } },
        ])

        setErrores(error)

        if(error.length > 0){
            
        }else{
            setOpenModal(true)
        }
    }

    const handleChangeToSolicitud = (id, codigo) => {
        setCargando(true)
        const obtenerProcesos = async (id) => {
            
            if(codigo == "soporte"){
                await getBloques(id, true);
                setSeleccion('SOPORTE')
            }else{
                await getBloques(id);
                setSeleccion('BLOQUES')
            }
            setCargando(false)
            
        }
        obtenerProcesos(id)
    }

    const getBloques = async (id, soporte = false) =>{
        try{
            let data = await Funciones.obtenerPermisosValor(id)
            let fisicos = []
            let virtuales = []
            console.log(data)
            if(soporte){
                data.map(({ secundario }) => 
                {
                    if(secundario.valorc == "fisico"){
                        fisicos.push({
                            id: secundario.id,
                            nombre: secundario.nombre,
                            codigo: secundario.codigo,
                            valora: secundario.valora,
                            valorb: secundario.valorb,
                            valorc: secundario.valorc,
                            valord: secundario.valord,
                            valore: secundario.valore,
                            valorf: secundario.valorf,
                            archivo: secundario.archivo
                        })
                    }else if(secundario.valorc == "virtual"){
                        virtuales.push({
                            id: secundario.id,
                            nombre: secundario.nombre,
                            codigo: secundario.codigo,
                            valora: secundario.valora,
                            valorb: secundario.valorb,
                            valorc: secundario.valorc,
                            valord: secundario.valord,
                            valore: secundario.valore,
                            valorf: secundario.valorf,
                            archivo: secundario.archivo
                        })
                    }
                    
                });
                setBloques(fisicos)
                setVirtuales(virtuales)
            }else{
                data = data.map(({ secundario }) => ({
                    id: secundario.id,
                    nombre: secundario.nombre,
                    codigo: secundario.codigo,
                    valora: secundario.valora,
                    valorb: secundario.valorb,
                    valorc: secundario.valorc,
                    valord: secundario.valord,
                    valore: secundario.valore,
                    valorf: secundario.valorf,
                    archivo: secundario.archivo
                }));
                setBloques(data)
            }
        }catch(err){
          console.log(err)
        } 
    }

    const vista = () => {
        let titulo= <h2 style={{ margin: '10px 0 0px 0' }}>Selecciona un Bloque</h2>
        let subtitulo=<p style={{ margin: '0 0 10px 0' }}>Estos son los bloques con salas disponibles, puedes dar clic en el bloque que desees para mas información.</p>
        let buscar = <SearchInput openModalSearch={openModalSearch} buscarSoftware={buscarSoftware} handleOnchange={handleOnchange}/>
        let titulo1= <h2 style={{ margin: '10px 0 0px 0' }}>Estas son las Salas disponibles del {bloqueTitle}</h2>
        let subtitulo1= <h3 style={{ margin: '10px 0 0px 0' }}>Si estas buscando un Software en especifico puedes escribir su nombre aquí:</h3>
        let titulo2= <h2 style={{ margin: '10px 0 0px 0' }}>Lista de Softwares disponibles en {salaTitle}</h2>
        let subtitulo2=<p style={{ margin: '10px 0 10px 0' }}>Ten en cuenta que puedes acceder de forma virtual a estos Softwares dando <Button target="_blank" href="https://labs.cuc.edu.co/" variant="contained" color="secondary" style={{color: '#FFFFFF'}}>click aquí</Button></p>
        switch (seleccion) {
          case 'SOLICITUDES':
            return (
                <React.Fragment>
                    
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={9} lg={6}>
                            <Grid container direction={'row'} justifyContent={'center'}>
                            {solicitudesBloques.map((element) => (
                                <Grid item xs={12} sm={12} md={12} style={{ padding: '2% 2% 0% 2%' }} onClick={() => handleChangeToSolicitud(element.id, element.codigo)}>
                                    <Paper elevation={8} square className={classes.paperCard}>
                                        <CardsSolicitudesBloques image={element.archivo == null ? element.archivo : api+element.archivo} nombre={element.nombre} descripcion={element.descripcion}/>
                                    </Paper>
                                </Grid>
                            ))}
                            </Grid>
                        </Grid>
                    </Grid>
                   
                </React.Fragment>
            )
            case 'BLOQUES':
                return(
                    <React.Fragment>
                        <Grid container direction={'row'} justifyContent={'center'}>
                            
                            <Grid xs={12} style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                                <Grid container direction={'column'}>
                                    <Grid item xs={12} md={4}>
                                        <Button  onClick={() => buttonBack()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon/> <Typography variant="body2" style={{ color: '#000000' }} component="p" align='justify'> Regresar</Typography> </Button>
                                    </Grid>
                                    <Grid xs={12} style={{paddingLeft:'14%',paddingRight:'14%',textAlign:'center'}} >
                                        {/* <h2 className={classes.midTitle}>Selecciona un Bloque</h2>
                                        <p className={classes.midDescription}>Estos son los bloques con salas disponibles, puedes dar clic en el bloque que desees para mas información. <br></br>Si estas buscando un Software en especifico puedes escribir su nombre aquí:</p> */}
                                        <BannerAdmin titulo={titulo} subtitulo={subtitulo} buscar={buscar}> 
                                        </BannerAdmin>
                                    </Grid>
                                    <Grid container direction={'row'} justifyContent={'center'} style={{marginTop: '50px',paddingLeft:'9%',paddingRight:'9%'}}>
                                        {bloques.map((element) => (
                                            <>
                                            {element.codigo!='soporte_tecnologia' ? 
                                            <div className={classes.cardsbloques}>
                                                <h2 className={classes.cardsTitleBloques}>{element.nombre}</h2>
                                                <p className={classes.cardsText}>{element.valora}</p>
                                                <button className={classes.cardsButton} onClick={() => changeBloquesToSalas(element.id, element.nombre)}>Ver mas...</button>
                                                <img className={classes.cardsImgBloques} src={element.archivo}></img>
                                            </div>
                                            :''}
                                            </>
                                        ))}
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </React.Fragment> 
                )
            case 'SOPORTE':
                return(
                    <Grid container direction={'row'} justifyContent={'center'}  style={{ padding: '10px'}}>
                        <Grid xs={12} style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                            <Grid container direction={'column'}>
                                <Grid item xs={12} md={4}>
                                    <Button  onClick={() => buttonBack()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon/> <Typography variant="body2" style={{ color: '#000000' }} component="p" align='justify'> Regresar</Typography> </Button>
                                </Grid>
                                <Grid xs={12} style={{textAlign:'center'}}>
                                    <h2 className={classes.midTitle}>MEDIOS FÍSICOS Y VIRTUALES DE COMUNICACIÓN</h2>
                                </Grid>
                                <Grid xs={12} style={{textAlign:'center'}}>
                                    <h2 className={classes.midSubTitle}>MEDIOS FÍSICOS</h2>
                                </Grid>
                                <Grid container direction={'row'} justifyContent={'center'}>
                                    {bloques.map((element) => (
                                        <div className={classes.cardsSoporte} style={{justifyContent: 'center'}}>
                                            <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                            <p className={classes.cardsTextSoporte}>{element.valora}</p>
                                        </div>
                                    ))}
                                </Grid>
                                <Grid xs={12} style={{textAlign:'center'}}>
                                    <h2 className={classes.midSubTitle}>MEDIOS VIRTUALES</h2>
                                </Grid>
                                <Grid container direction={'row'} justifyContent={'center'}>
                                    {virtuales.map((element) => (
                                        <div className={classes.cardsSoporte} style={{justifyContent: 'center'}}>
                                            <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                            <p className={classes.cardsTextSoporte}>{element.valora}</p>
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
          case 'SALAS':
            return (
                <React.Fragment>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        
                        <Grid xs={12} style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                            <Grid container direction={'column'}>
                                <Grid item xs={12} md={4}>
                                    <Button  onClick={() => buttonBack()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon/> <Typography variant="body2" style={{ color: '#000000' }} component="p" align='justify'> Regresar</Typography> </Button>
                                </Grid>
                                {/* <Grid xs={12} style={{textAlign:'center'}}>
                                    <h2 className={classes.midTitle}>Estas son las Salas disponibles del {bloqueTitle}</h2>
                                    <p className={classes.midDescription}>Si estas buscando un Software en especifico puedes escribir su nombre aquí: </p>
                                    <SearchInput openModalSearch={openModalSearch} buscarSoftware={buscarSoftware} handleOnchange={handleOnchange}/>
                                </Grid> */}
                                <Grid xs={12} style={{paddingLeft:'14%',paddingRight:'14%',textAlign:'center'}} >
                                        <BannerAdmin titulo={titulo1} subtitulo={subtitulo1} buscar={buscar}> 
                                        </BannerAdmin>
                                </Grid>
                                <Grid container direction={'row'} justifyContent={'center'} style={{marginTop: '50px',paddingLeft:'9%',paddingRight:'9%'}}>
                                {salas.map((element) => (
                                    <>
                                    {element.valorc==element.valorf ? 
                                    
                                    <div className={classes.cards1} style={{justifyContent: 'center'}}>
                                        <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                        <p className={classes.cardsText}>Piso: {element.valora}</p>
                                        <p className={classes.cardsText}>La Sala Esta Ocupada en Clases</p>
                                        <p className={classes.cardsText}>Capacidad Total Sala:{element.valorf}</p>
                                        <button className={classes.cardsButton} onClick={() => changeSalasToSoftware(element.id, element.nombre)}>Software</button>
                                        <img className={classes.cardsImg}src={element.archivo}></img>
                                    </div>

                                    : 
                                    <div className={classes.cards} style={{justifyContent: 'center'}}>
                                        <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                        <p className={classes.cardsText}>Piso: {element.valora}</p>
                                        <p className={classes.cardsText}>Puestos Ocupado: {element.valorc}</p>
                                        <p className={classes.cardsText}>Cupos Disponibles:{element.valord}</p>
                                        <p className={classes.cardsText}>Capacidad Total Sala:{element.valorf}</p>
                                        <button className={classes.cardsButton} onClick={() => changeSalasToSoftware(element.id, element.nombre)}>Software</button>
                                        <img className={classes.cardsImg}src={element.archivo}></img>
                                    </div>
                                    }
                                    </>
                                ))}
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </React.Fragment>      
            )
            case 'SOFTWARE':
                return(
                    <React.Fragment>
                        <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid xs={12} style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                            <Grid container direction={'column'}>
                                <Grid item xs={12} md={4}>
                                    <Button  onClick={() => buttonBack()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon/> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Regresar</Typography> </Button>
                                </Grid>
                                {/* <Grid xs={12} style={{textAlign:'center'}}>
                                    <h2 className={classes.midTitle}>Lista de Softwares disponibles en {salaTitle}</h2>
                                    <p className={classes.midDescription}>Ten en cuenta que puedes acceder de forma virtual a estos Softwares dando <Button target="_blank" href="https://labs.cuc.edu.co/" variant="contained" color="secondary" style={{color: '#FFFFFF'}}>click aquí</Button></p>
                                    
                                </Grid> */}
                                 <Grid xs={12} style={{paddingLeft:'14%',paddingRight:'14%',textAlign:'center'}} >
                                        <BannerAdmin titulo={titulo2} subtitulo={subtitulo2} > 
                                        </BannerAdmin>
                                </Grid>
                                <Grid container direction={'row'} justifyContent={'center'} style={{marginTop: '50px'}}>
                                    <div style={{width: '50%', minWidth: '300px'}}>
                                        <ListarTecnologias 
                                            software={software}

                                            vistaSearch={false}
                                        />
                                    </div>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                        
                    </React.Fragment>
                )
          default:
            break;
        }
    }

    const vistaGeneral = () => {
        switch (seleccionGeneral) {
            case 'NOLIST':
              return (
                <React.Fragment>
                    <div className={classes.header}>
                        <div className={classes.headerTitleTextBox}>
                            <h2 className={classes.headerTitle}>Bienvenido</h2>
                            <p className={classes.headerText}>Aquí encontrarás todos los servicios, recursos y softwares que UniCosta tiene para ti</p>
                            {/* <Grid container ref={GridBuscar} className={classes.search} id='input'>
                                <Grid xs={6} style={{ background: '#ffffff', borderRadius:'50px' }}>
                                    <InputBase
                                        value={buscarSoftware}
                                        onChange={handleOnchange}
                                        placeholder="Buscar Software"
                                        name='buscarSoftware'
                                        // error = { getError('buscarSoftware', errores).length > 0}
                                        // helperText = { getError('buscarSoftware', errores) }
                                        classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                        }}
                                    />
                                </Grid>
                                <Grid xs={6} className={classes.searchIcon}>
                                    <Fab onClick={() => openModalSearch()} style={{ background: '#3F4E54' }}>
                                        <SearchIcon />
                                    </Fab>
                                </Grid>
                            </Grid> */}
                        </div>
                        <img className={classes.imgInicio} src={imagenInicio}></img>
                    </div>

                    {cargando == true ? 
                    <div> <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /></div>
                        :
                        vista()
                    }
                    
                    {/* <Grid item xs={12} className={("scrollBuscar", "buscarS")} style={{ opacity: opacity, transition: 'all 0.5s' }}>
                        <Grid container className={(classes.search2)} style={{ position: position, zIndex: 100, top: 80, border: 'solid 1px #c79e32' }}>
                        <Grid xs={6}>
                            <InputBase
                            value={buscarSoftware}
                            onChange={handleOnchange}
                            placeholder="Buscar Software"
                            name='buscarSoftware'
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            style={{ color: '#c79e32' }}
                            />
                        </Grid>
                        <Grid xs={6} className={classes.searchIcon} >

                            <Fab onClick={() => openModalSearch()} style={{ background: '#3F4E54' }}>
                            <SearchIcon />
                            </Fab>
                        </Grid>
                        </Grid><br />
                    </Grid> */}
                    {openModal == true ?
                    <SearchListSoftware 
                        openModal={openModal}
                        setOpenModal={setOpenModal}

                        buscarSoftware={buscarSoftware}
                    /> 
                    : null}
                </React.Fragment>
              )
            default:
              break;
          }
    }

    return (
        vistaGeneral()
    )
}

const ListarTecnologias = (props) => {

    const [cargando, setCargando] = React.useState(true);
    const isInitialMount = React.useRef(true);
    const [software, setSoftware] = React.useState([]);

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                if(props.vistaSearch == true){
                    setCargando(false)
                }else{
                    setCargando(false)
                }
            }
            obtenerProcesos()
        }
    })

    

    return (
        cargando == true ? <div> <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /></div> :
        <React.Fragment>
            <ListarDatos
                id="tbl_listar_encargados"
                datos={props.vistaSearch == false ? props.software : props.software}
                opciones={true}
                buscar={true}
                avatar={props.vistaSearch == false ? ({ nombre }) => nombre.toUpperCase().charAt(0) :({ nombreBloque }) => nombreBloque.toUpperCase().charAt(0) }
                //color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
                titulo={props.vistaSearch == false ? "Lista de Software's Disponibles" : "Lista de Bloques y Salas Encontradas"}
                fila_principal={props.vistaSearch == false ? ({ nombre }) => `${nombre}` : ({ nombreBloque }) => `${nombreBloque}`}
                filas={props.vistaSearch == false ? [
                {
                    'mostrar': ({ nombre }) => `${nombre}`,
                    'id': 'nombre', 'enLista': false
                },
                ] : 
                [
                    {
                        'mostrar': ({ nombreSala }) => `${nombreSala}`,
                        'id': 'Nombre Sala', 'enLista': true
                    },
                ]
                }
            />
        </React.Fragment>
    )
}

const SearchListSoftware = (props) =>{

    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true);
    const [software, setSoftware] = React.useState(true);
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                let data = await getBloquesBySoftwareName(props.buscarSoftware, Helper.SOFTWARES);
                data = data.map(({ secundario, principal }) => ({
                    idSala: secundario.id,
                    idBloque: principal.id,
                    nombreSala: secundario.nombre,
                    valoraSala: secundario.valora,
                    nombreBloque: principal.nombre,
                    valoraBloque: principal.valora
                }));
                setSoftware(data)
                setCargando(false)
            }
            obtenerProcesos()
        }
    })

    const handleOnClose = () =>{
        props.setOpenModal(false)
    }

    //Obtener semilleros solicitudes
    const getBloquesBySoftwareName = async (f, generica) => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/bloques/${f}/${generica}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    return(
        <Dialog TransitionComponent={Slide} open={props.openModal} onClose={()=>{handleOnClose()}} aria-labelledby="form-dialog-title">

            <AppBarModal 
            titulo={"Software Encontrado En"} 
            mostrarModal={()=>{handleOnClose()}} 
            titulo_accion="" a
            ccion="" />
            {/* Contenido del modal del formulario de */}
            <DialogContent >
                {cargando == true ? <div> <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /></div> :
                <ListarTecnologias 
                    vistaSearch={true}

                    software={software}
                />}
            </DialogContent>

            <DialogActions>
                
            </DialogActions>

        </Dialog>
    )
}


function CardsSolicitudesBloques(props){

    const classes = useStyles();

    return(
        <Card className={classes.cardSolicitudes} style={{ cursor: 'pointer', borderRadius: '25px', boxShadow:'none' }} elevation={1}>
            <CardHeader
                avatar={<Avatar aria-label="recipe" className={classes.avatar}> {props.nombre.charAt(0)} </Avatar>}
                title={`${props.nombre}`}
            />
            <CardContent >
                <Grid container direction={'row'} justifyContent={'center'}>
                <Grid item xs={12} sm={2} md={2} className={classes.content_img}>
                    <img src={props.image} className={classes.image} alt='Imagen Tipo' />
                </Grid>
                <Grid item xs={12} sm={10} md={10} className={classes.content_text}>
                    <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                    {props.descripcion}
                    </Typography>
                </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

function SearchInput(props){

    const classes = useStyles();

    return(
        <Grid container className={classes.search} id='input' justifyContent={'center'}>
            <Grid xs={12} className={classes.searchFirstChildBox}>
                <Grid container direction={'row'}>
                    <Grid xs={6} style={{ borderRadius:'50px' }}>
                        <InputBase
                            value={props.buscarSoftware}
                            onChange={props.handleOnchange}
                            placeholder="Buscar Software"
                            name='buscarSoftware'
                            // error = { getError('buscarSoftware', errores).length > 0}
                            // helperText = { getError('buscarSoftware', errores) }
                            classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                            }}
                        />
                    </Grid>
                    <Grid xs={6} className={classes.searchIcon}>
                        <Fab onClick={() => props.openModalSearch()} style={{ background: '#3F4E54' }}>
                            <SearchIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Bloques