import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import moment from 'moment'

import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";
class EleccionesModificar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      nombre: '',
      fecha_inicio: null,
      fecha_fin: null,
    }
  }

  componentDidUpdate({ modalModEleccion }) {
    let { modalModEleccion: modalAddNew, eleccion } = this.props;
    if (modalAddNew !== modalModEleccion) {
      this.changeState(eleccion);
    }
  }

  changeState = ({ id, nombre, fecha_inicio, fecha_fin }) => {
    this.setState({
      id,
      nombre,
      fecha_inicio,
      fecha_fin,
    })
  }
  onSubmit = e => {
    let { id, nombre, fecha_inicio, fecha_fin } = this.state;
    fecha_inicio = moment(fecha_inicio).format('YYYY-MM-DD');
    fecha_fin = moment(fecha_fin).format('YYYY-MM-DD');
    this.props.modificarEleccion(id, nombre, fecha_inicio, fecha_fin);
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  setFechaInicio = (fecha_inicio) => {
    this.setState({
      fecha_inicio
    })
  }
  setFechaFin = (fecha_fin) => {
    this.setState({
      fecha_fin
    })
  }

  render() {
    let { nombre, fecha_inicio, fecha_fin } = this.state;
    fecha_inicio = moment(fecha_inicio, "YYYY-MM-DD");
    fecha_fin = moment(fecha_fin, "YYYY-MM-DD");
    let { modalModEleccion, mostrarModalModEleccion } = this.props;
    return (
      <Dialog open={modalModEleccion} onClose={mostrarModalModEleccion.bind(this, false)} aria-labelledby="form-dialog-title">
        <AppBarModal titulo="Modificar Elección" mostrarModal={mostrarModalModEleccion} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextValidator
                  autoFocus
                  margin="dense"
                  id="nombre"
                  label="Nombre"
                  type="text"
                  fullWidth
                  name="nombre"
                  value={nombre}
                  validators={["required"]}
                  errorMessages={["El campo es requerido"]}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      minDate={Date("YYYY-MM-dd")}
                      id="date-picker-dialog"
                      label="Fecha Inicio"
                      format="yyyy-MM-dd"
                      value={fecha_inicio}
                      onChange={this.setFechaInicio}
                      style={{ width: '100%' }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                      invalidDateMessage='Formato Invalido'
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      minDate={Date("YYYY-MM-dd")}
                      id="date-picker-dialog"
                      label="Fecha Fin"
                      format="yyyy-MM-dd"
                      value={fecha_fin}
                      onChange={this.setFechaFin}
                      style={{ width: '100%' }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                      invalidDateMessage='Formato Invalido'
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Button color="primary" type="submit" id='enviar_form_mod_eleccion' className="oculto"> Guardar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalModEleccion(false)} />
          <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_mod_eleccion').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
EleccionesModificar.propTypes = {
  //variables
  modalModEleccion: PropTypes.bool.isRequired,
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalModEleccion: PropTypes.func.isRequired,
  modificarEleccion: PropTypes.func.isRequired,
}


export default EleccionesModificar;
