import React, { useState, useEffect } from "react";
import moment from "moment";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../redux/actions/actInscripcionesPosgrado";

// Material UI
import { Dialog, DialogActions, DialogContent, Link } from "@material-ui/core";

// General components
import AppBarModal from "../../general/AppBarModal";
import { BtnDetalle, BtnForm } from "../../general/BotonesAccion";
import TareasTerminadas from "../../general/TareasTerminadas";
import ListarDatos from "../../general/ListarDatos";

// images
import emma_w from "../../../global/imagenes/emma_w.png";

// Components
import {
  InscripcionDetalleCarrera,
  InscripcionDetalleContacto,
  InscripcionDetalleDatos,
} from "./InscripcionPosgradoDetalles";
import { MenuDetalleInscripciones } from "./MenuInscripciones";

// General functions
import { consulta, generarFiltros } from "../../../global/js/funciones";

const ProcesosInscripcionesPosgrado = ({
  procesoSeleccionDetalle,
  inscripcion,
}) => {
  const [cargando, setCargando] = useState(true);
  const [contactoInscripcion, setContactoInscripcion] = useState([]);
  const [estadosInscripcion, setEstadosInscripcion] = useState([]);
  const [detalleInscripcion, setDetalleInscripcion] = useState([]);
  const [adjuntosInscripcion, setAdjuntosInscripcion] = useState([]);
  const [carreraInscripcion, setCarreraInscripcion] = useState([]);
  const [seleccion, setSeleccion] = useState("");
  const [prevSeleccion, setPrevSeleccion] = useState("");

  const { modalDetalle } = useSelector(
    (state) => state.redInscripcionesPosgrado
  );

  const dispatch = useDispatch();
  const { mostrarModalDetalleInsPosgrado } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
    (async () => {
      if (procesoSeleccionDetalle) {
        await obtenerInscripcion(inscripcion);
        setSeleccion(procesoSeleccionDetalle);
      }
    })();
  }, [modalDetalle]);

  useEffect(() => {
    (async () => {
      if (prevSeleccion !== seleccion) {
        if (seleccion === "detalle") {
          await obtenerInscripcion(inscripcion);
        } else if (seleccion === "contacto") {
          await obtenerContacto(inscripcion);
        } else if (seleccion === "adjuntos") {
          await obtenerAdjuntos(inscripcion);
        } else if (seleccion === "carrera") {
          await obtenerCarrera(inscripcion);
        } else if (seleccion === "estados") {
          await obtenerEstados(inscripcion);
        }
      }
    })();
    setPrevSeleccion(seleccion);
  }, [seleccion, prevSeleccion]);

  const obtenerInscripcion = async (procesoId) => {
    setCargando(true);
    let filtro = await generarFiltros([{ llave: "id", valor: procesoId }]);
    consulta(
      `api/v1.0/inscripciones_posgrado/datos?${filtro}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) {
          setCargando(false);
          setDetalleInscripcion(resp);
        }
      }
    );
  };

  const obtenerContacto = async (procesoId) => {
    setCargando(true);
    let filtro = await generarFiltros([{ llave: "id", valor: procesoId }]);
    consulta(
      `api/v1.0/inscripciones_posgrado/contacto?${filtro}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) {
          setCargando(false);
          setContactoInscripcion(resp);
        }
      }
    );
  };

  const obtenerEstados = async (procesoId) => {
    setCargando(true);
    let filtro = await generarFiltros([
      { llave: "inscripcion", valor: procesoId },
    ]);
    consulta(
      `api/v1.0/inscripciones_posgrado/estados?${filtro}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) {
          setCargando(false);
          setEstadosInscripcion(resp);
        }
      }
    );
  };

  const obtenerCarrera = async (procesoId) => {
    setCargando(true);
    let filtro = await generarFiltros([{ llave: "id", valor: procesoId }]);
    consulta(
      `api/v1.0/inscripciones_posgrado/carrera?${filtro}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) {
          setCargando(false);
          setCarreraInscripcion(resp);
        }
      }
    );
  };

  const obtenerAdjuntos = async (procesoId) => {
    setCargando(true);
    let filtro = await generarFiltros([
      { llave: "inscripcion_posgrados", valor: procesoId },
    ]);
    consulta(
      `api/v1.0/inscripciones_posgrado/adjuntos?${filtro}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) {
          setCargando(false);
          setAdjuntosInscripcion(resp);
        }
      }
    );
  };

  const pintarAdjuntos = () => {
    const acciones = (data) => {
      return (
        <Link href={data.archivo} target="_blank">
          <BtnDetalle color="#01579b" texto="Abrir" callback={() => {}} />
        </Link>
      );
    };

    return (
      <ListarDatos
        id="tbl_mis_adjuntos"
        datos={adjuntosInscripcion}
        avatar={({ nombre_archivo }) => nombre_archivo.charAt(0).toUpperCase()}
        acciones={(data) => acciones(data)}
        fila_principal={({ nombre_archivo }) => nombre_archivo}
        filas={[
          {
            id: "fecha",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            nombre: "Fecha: ",
          },
        ]}
      />
    );
  };

  const pintarEstados = () => {
    const obtenerColor = (codigo) => {
      const color = "white";
      const colores = {
        Ins_Pos_Reg: { backgroundColor: "#f5f5f5", color: "#757575" },
        Ins_Pos_Pre: { backgroundColor: "#ffc107", color },
        Ins_Pos_Ins: { backgroundColor: "#54a0ff", color },
        Ins_Pos_Adm: { backgroundColor: "#27ae60", color },
        Ins_Pos_Nadm: { backgroundColor: "#e67e22", color },
        Ins_Pos_Mat: { backgroundColor: "#706fd3", color },
        Ins_Pos_Can: { backgroundColor: "#e95a43", color },
        Ins_Pen_Est: { backgroundColor: "#b2bec3", color },
      };
      if (!colores[codigo])
        return { backgroundColor: "#f5f5f5", color: "#757575" };
      return colores[codigo];
    };
    return (
      <ListarDatos
        datos={estadosInscripcion}
        id="tbl_estados_proceso"
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre}
        filas={[
          {
            id: "fecha",
            mostrar: ({ fecha_registro }) =>
              moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            nombre: "Fecha: ",
          },
          {
            id: "usuario",
            mostrar: ({
              usuario_registro: {
                primer_nombre,
                primer_apellido,
                segundo_apellido,
              },
            }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
            nombre: "Gestor: ",
          },
          {
            id: "observaciones",
            mostrar: ({ mensaje }) => mensaje,
            nombre: "Observación: ",
          },
        ]}
      />
    );
  };

  const pintarProceso = () => {
    if (detalleInscripcion[0]) {
      return <InscripcionDetalleDatos inscripcion={detalleInscripcion[0]} />;
    }
  };

  const pintarContacto = () => {
    if (contactoInscripcion[0]) {
      return (
        <InscripcionDetalleContacto inscripcion={contactoInscripcion[0]} />
      );
    }
  };

  const pintarCarrera = () => {
    if (carreraInscripcion[0]) {
      return <InscripcionDetalleCarrera inscripcion={carreraInscripcion[0]} />;
    }
  };

  const pintar = () => {
    if (cargando) {
      return (
        <TareasTerminadas
          mensaje="Cargando..."
          marginTop="7%"
          widthImg="7%"
          imagen={emma_w}
          cargando={true}
        />
      );
    }

    switch (seleccion) {
      case "detalle":
        return pintarProceso();
      case "contacto":
        return pintarContacto();
      case "adjuntos":
        return pintarAdjuntos();
      case "carrera":
        return pintarCarrera();
      case "estados":
        return pintarEstados();
      default:
        return pintarProceso();
    }
  };

  return (
    <>
      <Dialog
        open={modalDetalle}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => dispatch(mostrarModalDetalleInsPosgrado(false))}
      >
        <AppBarModal
          titulo="Detalle proceso"
          mostrarModal={mostrarModalDetalleInsPosgrado}
          titulo_accion=""
        />
        <MenuDetalleInscripciones
          selec={seleccion}
          seleccionar={(seleccion) => setSeleccion(seleccion)}
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          {pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="Cerrar"
            callback={() => dispatch(mostrarModalDetalleInsPosgrado(false))}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProcesosInscripcionesPosgrado;
