import {
    MODAL_ADD_VALIDACIONES,
    MODAL_DET_VALIDACIONES,
    MODAL_DET_GES_VALIDACION,
    MODAL_GES_VALIDACION,
    MODAL_ADJ_VALIDACIONES,
    MODAL_ADD_ADJVALIDACION,
    MODAL_ADJ_VOLANTE,
    MODAL_FILTRO_VALIDACION,
    MODAL_UPDATE_PERIODO,
  } from "../actions/actValidaciones"
  
  const initialState = {
    modalAddValidaciones: false,
    modalDetValidaciones: false,
    modalDetGestionarValidacion: false,
    modalGestionValidacion: false,
    modalAdjuntosValidacion: false,
    modalAddAdjValidacion: false,
    modalAdjuntarVolante: false,
    modalFiltroValidacion: false,
    modalModificarPeriodo: false,
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case MODAL_ADD_VALIDACIONES:
        return Object.assign({}, state, {
            modalAddValidaciones: action.show
        })
        case MODAL_DET_VALIDACIONES:
          return Object.assign({}, state, {
            modalDetValidaciones: action.show
          })
        case MODAL_DET_GES_VALIDACION:
          return Object.assign({}, state, {
            modalDetGestionarValidacion: action.show
          })
          case MODAL_GES_VALIDACION:
            return Object.assign({}, state, {
              modalGestionValidacion: action.show
            })
          case MODAL_ADJ_VALIDACIONES:
            return Object.assign({}, state, {
              modalAdjuntosValidacion: action.show
            })
          case MODAL_ADD_ADJVALIDACION:
            return Object.assign({}, state, {
              modalAddAdjValidacion: action.show
            })
          case MODAL_ADJ_VOLANTE:
            return Object.assign({}, state, {
              modalAdjuntarVolante: action.show
            })
          case MODAL_FILTRO_VALIDACION:
            return Object.assign({}, state, {
              modalFiltroValidacion: action.show
            })
          case MODAL_UPDATE_PERIODO:
            return Object.assign({}, state, {
              modalModificarPeriodo: action.show
            })
      default:
        return state;
    }
  }
  
export default reducer;
  