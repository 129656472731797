import React, { useState, Component } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogContent,StepLabel, DialogActions, Stepper, Step, Grid, TextField,
  InputLabel, Card, Typography, CardContent, FormControl, makeStyles, Button
  } from '@material-ui/core';
import { BtnFormPage } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png'; 
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import gifRea from '../../../global/imagenes/gifRea2.gif';
import {obtenerValoresFiltros, obtenerPermisosValorAlt } from "../../../global/js/funciones";
import NativeSelect from '@material-ui/core/NativeSelect';
import {VALORES_PREGUNTAS, VALORES_RESPUESTAS, INSTRUCCIONES, 
  ENCABEZADO_INSTRUCCIONES, GENERO,CONDICIONALES_TIPO_REA } from './helper';



const configStep = ['Datos Iniciales','Instrucciones', 'Encuesta'];

function FormFirst(component_props) {
  let {onChange, programas, carrera,telefono, celular,genero, sexo, correo_personal} = component_props

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '40px' }}>
        <Grid item xs={6} >
          <TextField
            value={celular}
            required
            type="number"
            id="celular"
            name="celular"
            label="Celular"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={telefono}
            required
            type="number"
            id="telefono"
            name="telefono"
            label="Telefono"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={correo_personal}
            required
            type="text"
            id="correo_personal"
            name="correo_personal"
            label="Correo Personal"
            fullWidth
            onChange={onChange}
          />
        </Grid>
  
        <Grid item md={2} xs={5}> 
          <FormControl>
            <InputLabel>Sexo</InputLabel>
            <NativeSelect id='genero' value={sexo} onChange={onChange} name='sexo' defaultValue={genero.nombre}>
              { 
                genero.map(( datos ) => (
                  <option key={datos.id} value={datos.id}> {datos.nombre}</option>
                ))
              }
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={7}>
          <FormControl className='form-control'>
            <InputLabel>Programa</InputLabel>
            <NativeSelect id='programas' value={carrera} onChange={onChange} name='carrera' defaultValue={programas.nombre}>
            { 
              programas.map(({ relacion }) => (
                <option key={relacion.id} value={relacion.id} label={relacion.nombre}/>
              ))
            }
           </NativeSelect>
          </FormControl>
        </Grid>
        
      </Grid>
    </>
  )
}
function Instrucciones(component_props) {
  let { instrucciones, encabezadoInstrucciones} = component_props
  return(
    <>
    {
      encabezadoInstrucciones.map(( encabezado, i ) => (
            <Typography key={i} style={{ textAlign: "justify" }}>
                {encabezado.nombre}
            </Typography>
         ))
      }
      <Card >
        <Grid container>
          <Grid item md={12} xs={12} >
            <CardContent>
            {
            instrucciones.map(( relacion, i ) => (
              <Typography key={i} style={{ textAlign: "justify" }}>
                {relacion.nombre}
              </Typography>
            ))
            }
            </CardContent>
          </Grid>
          <Grid item md={1} >
          </Grid>
          <Grid item md={10} xs={12} >
            
              <img src={gifRea} alt="Gif..." />
            
          </Grid>
          <Grid item md={1} >
          </Grid>
        </Grid>
      </Card>
      </>
  )
}
	
function FormSeconds(component_props) {
  let {  valoresPreguntas, respuestas, valid1, valid2, valid3, cambiarEstado, instrucciones, encabezadoInstrucciones} = component_props


  function aumentar (index, dato){
    let auxiliar
    if(dato === respuestas[0]){
      valid1=true
     cambiarEstado({valid1})
    }
    if(dato === respuestas[4]){
      valid2=true
     cambiarEstado({valid2})
    }
    if(dato === respuestas[7]){
      valid3=true
     cambiarEstado({valid3})
    }

    auxiliar=dato[index]
    dato[index]=dato[index-1]
    dato[index-1]=auxiliar
    return(
      dato[index]
    )  
}


function disminuir (index, dato){
  let auxiliar1
  if(dato === respuestas[0]){
    valid1=true
   cambiarEstado({valid1})
  }
  if(dato === respuestas[4]){
    valid2=true
   cambiarEstado({valid2})
  }
  if(dato === respuestas[7]){
    valid3=true
   cambiarEstado({valid3})
  }
  auxiliar1=dato[index]
  dato[index]=dato[index+1]
  dato[index+1]=auxiliar1
  return(
    dato[index]
  )
  
}



  const classes = useStyles();
  const [respuesta, setRespuesta] = useState(respuestas)
  return (
    <div>
       {
      encabezadoInstrucciones.map(( encabezado, i ) => (
            <Typography key={i} style={{ textAlign: "justify" }}>
                {encabezado.nombre}
            </Typography>
         ))
      }
      <Card >
        <Grid container>
          <Grid item md={6} xs={12} >
            <CardContent>
            {
            instrucciones.map(( relacion, i ) => (
              <Typography key={i} style={{ textAlign: "justify" }}>
                {relacion.nombre}
              </Typography>
            ))
            }
            </CardContent>
          </Grid>
        </Grid>
      </Card>
          {
          respuestas.map(( relacion, i ) => (
          <div key={i}>
            <p className={classes.title}><b>{i+1}) {valoresPreguntas[i].nombre}</b></p>
            {respuestas[i].map(( info, index ) => (
            <Card key={index} className={classes.root} style={{backgroundColor: respuestas[i][index].secundario.valora}} variant="outlined" >
               <Grid container>
                <Grid item md={1} xs={1} >
                  <p>{index + 1}</p>
                </Grid>
                <Grid item md={9} xs={8} >
                  <CardContent>
                    <Typography className={classes.texto} gutterBottom>
                      {respuestas[i][index].secundario.nombre}
                    </Typography>
                  </CardContent>
                </Grid>
              <Grid item md={2} xs={1} >
                <CardContent>
              {
              index===0 ?  <Button variant="outlined" color='primary' size="small" onClick={() => setRespuesta(disminuir(index,respuestas[i]))}><ExpandMoreIcon color="action"/></Button>:
                index===3 ? <Button variant="outlined" color='primary' size="small" onClick={() => setRespuesta(aumentar(index,respuestas[i]))}><ExpandLessIcon color="action"/></Button>:
                  <div>
                    <Button variant="outlined" color='primary' size="small" onClick={() => setRespuesta(aumentar(index,respuestas[i]))}><ExpandLessIcon color="action"/></Button>
                    <Button variant="outlined" color='primary' size="small" onClick={() => setRespuesta(disminuir(index,respuestas[i]))}><ExpandMoreIcon color="action"/></Button>
                  </div>
                  }
              </CardContent>
              </Grid>
            
            </Grid>
            </Card>
          ))}
          </div>
          ))
       }
    </div>
  )
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  
  title: {
    fontSize: 16,
    textAlign: "justify",
  },
  texto: {
    fontSize: "1em",
    textAlign: "justify",
  },
 
});











class ReaEncuesta extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      initialTransition: true,
      cargando: true,
      celular: '',
      carrera: '',
      telefono: '',
      genero:[],
      sexo:'',
      instrucciones:[],
      encabezadoInstrucciones:[],
      valoresPreguntas: [],
      valoresRespuestas: [],
      respuestas:[],
      valid1:false,
      correo_personal:'',
      titulo_carg : 'Cargando ...',
      cargar: true,
      condicionalesTipo:[],
      cargando: true
    }
  }

  async componentDidMount() {
    this.setState({ cargando: true })
    let { programas } = this.props
    let valoresPreguntas = await obtenerValoresFiltros([{"llave" : 'generica', 'valor' : VALORES_PREGUNTAS}]);
    let valoresRespuestas = await obtenerPermisosValorAlt([{ 'llave': 'secundario__generica', 'valor': VALORES_RESPUESTAS }])
    let instrucciones = await obtenerValoresFiltros([{"llave" : 'generica', 'valor' : INSTRUCCIONES}]);
    let encabezadoInstrucciones = await obtenerValoresFiltros([{"llave" : 'generica', 'valor' : ENCABEZADO_INSTRUCCIONES}]);
    let genero = await obtenerValoresFiltros([{"llave" : 'generica', 'valor' : GENERO}]);
    let condicionalesTipo = await obtenerValoresFiltros([{"llave" : 'generica', 'valor' : CONDICIONALES_TIPO_REA}]);
    this.cambiarEstado({valoresPreguntas, valoresRespuestas, instrucciones, encabezadoInstrucciones, genero, condicionalesTipo})
    let { telefono, celular, correo_personal } = this.props
    this.obtenerRespuestas()
    this.setState({ telefono, celular, correo_personal, cargando: false, sexo:genero[0].id, carrera:programas[0].relacion.id })
  }

  componentDidUpdate(prevProps) {
    let { programas, telefono, celular, correo_personal} = this.props
    if((programas && programas !== prevProps.programas) && programas.length === 1) {
      this.setState({ carrera: programas[0].relacion.id })
    }
    if(telefono && telefono !== prevProps.telefono) {
      this.setState({ telefono })
    }
    if(celular && celular !== prevProps.celular) {
      this.setState({ celular })
    }
    if(correo_personal && correo_personal !== prevProps.correo_personal) {
      this.setState({ correo_personal })
    }

  } 

  obtenerRespuestas = () => {
    let { valoresRespuestas, valoresPreguntas, respuestas} = this.state
    let arr=[]
    valoresPreguntas.forEach(function(elemento,j ) {
      valoresRespuestas.forEach(function(datoRespuesta, h ) {
        if(datoRespuesta.principal.id === valoresPreguntas[j].id) {
           arr.push(datoRespuesta)
        }
      })
      respuestas[j]=arr
      arr=[]
    }) 
    this.cambiarEstado({ respuestas})
  }


 
  cambiarEstado = (estados) => {
    this.setState(estados)
  }


  handleStep = (direction) => {//Con esta funcion Valido si se llenaron los campos
    let { activeStep, telefono, carrera, celular,sexo, correo_personal} = this.state
    let { actualizarMensaje } = this.props
    let sw = true
    
    if ((!telefono || !carrera || !celular || !correo_personal || !sexo) && activeStep === 0) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false
    }
    if(sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }

  onChange = ({ target }) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }
  onSubmit = (e) => {
    let { actualizarMensaje } = this.props
    let { telefono, celular, carrera, correo_personal } = this.state;
    let error = false;
    let msgError = ''
    if (!celular) { msgError = 'El campo Celular es obligatorio.'; error = true; }
    else if (!Number(celular)) { msgError = 'El campo Celular debe ser numerico.'; error = true; }
    else if (!telefono) { msgError = 'El campo Telefono es obligatorio.'; error = true; }
    else if (!Number(telefono)) { msgError = 'El campo Telefono debe ser numerico.'; error = true; }
    else if (!carrera) { msgError = 'El campo Programa es obligatorio.'; error = true; }
    else if (!correo_personal) {  msgError = 'El campo Correo personal es obligatorio.'; error = true; }
    if (!error) this.enviarDatos();
    else actualizarMensaje({ titulo: msgError, tipo: 'info', mostrar: true, tiempo: 4000 });
    // else this.setState({ telefono, celular, carrera, correo_personal})
  }

  enviarDatos() {
    let { correo_personal, telefono, celular, carrera, sexo, respuestas, valid1, valid2, valid3, condicionalesTipo} = this.state;
    let { actualizarMensaje } = this.props
    let EA=0 //Experimentacion Activa   EA
    let CA=0 //Conceptualizacion Abstract CA
    let OR=0 //Observacion Reflexiva OR
    let EC=0 //Experiencia Concreta EC
    let X=0 //Coordenadas x
    let Y=0  // Coordenadas y 
    let tipo=''
    let reaAcomodador
    let reaAsimilador
    let reaConvergente
    let reaDivergente

    
    condicionalesTipo.forEach((elemento_rea) =>{
      if(elemento_rea.codigo === 'rea_acomodador'){reaAcomodador = elemento_rea.id}
      if(elemento_rea.codigo === 'rea_asimilador'){reaAsimilador = elemento_rea.id}
      if(elemento_rea.codigo === 'rea_convergente'){reaConvergente = elemento_rea.id}
      if(elemento_rea.codigo === 'rea_divergente'){reaDivergente = elemento_rea.id}
    })
    if( valid1 && valid2 && valid3){
      this.setState({ cargando: true, titulo_carg: 'Enviando..' })
      let respuestasOrdenadas=[]
      let diccionario ={'':''}
      respuestas.forEach((elemento, j) =>{
        respuestas[j].forEach((element, s) =>{
          respuestasOrdenadas.push(diccionario={"idRespuesta" : respuestas[j][s].secundario.id,"peso":s+1, "codigo" : respuestas[j][s].secundario.valorb , "idPregunta":respuestas[j][0].principal.id})
       
        })
      })

      //Los codigos EC, OR, CA, EA deben estar en valor-b de cada respuesta
      respuestasOrdenadas.forEach((elemento) =>{
        if(elemento.codigo === 'EC'){
          EC=EC+elemento.peso
        }
        if(elemento.codigo === 'OR'){
          OR=OR+elemento.peso
        }
        if(elemento.codigo === 'CA'){
          CA=CA+elemento.peso
        }
        if(elemento.codigo === 'EA'){
          EA=EA+elemento.peso
        }
      })
      //Formula para calcular las coordenadas
      X= CA - EC
      Y= EA - OR
//------- Condiciones para saber e tipo de aprendizaje del estudiante
      if((X <= 2 && X >= -12) && (Y >= 3  && Y <= 17)){tipo=reaAcomodador}//acomodador 
      if((X >= 3 && X <= 18) && (Y <= 2  && Y >= -11)){tipo=reaAsimilador}//{tipo=condicionalesTipo[1].id}//asimilador
      if((X >= 3 && X <= 18) && (Y >= 3  && Y <= 17)){tipo=reaConvergente}//{tipo=condicionalesTipo[2].id}//convergente
      if((X <= 2 && X >= -12) && (Y <= 2  && Y >= -12)){tipo=reaDivergente}//{tipo=condicionalesTipo[3].id}//divergente
      let data = {
        telefono: telefono,
        celular: celular,
        carrera: carrera,
        correo_personal: correo_personal,
        sexo: sexo,
        respuestas:respuestasOrdenadas,
        EC,
        EA,
        OR,
        CA,
        tipo,
      }
      
      this.props.guardarRea(data, () => this.setState({ cargando: false, titulo_carg: 'Cargando..' }));
      this.reiniciarEstado()
    
    }else{
      actualizarMensaje({ titulo: 'Debe mover por lo menos una respuesta de cada pregunta', tipo: 'info', mostrar: true, tiempo: 4000 });
    }
   

  }
  reiniciarEstado() {
    this.setState(
      {
        activeStep: 0,
        initialTransition: true,
        cargando: true,
        carrera: '',
        sexo:'',
        respuestas:[],
      }
    )
   
    this.obtenerRespuestas()
  }

  


  configStepContent = () => {//Muestra los formularios dependiendo del valor de la variable activeStep
    let { activeStep, initialTransition, accepted, telefono,celular, carrera, correo_personal, genero,sexo, respuestas,
       valoresPreguntas, instrucciones, encabezadoInstrucciones, valid1, valid2,valid3 } = this.state
    let {programas} = this.props
    switch (activeStep) {
      case 0:
        return (
          <FormFirst
            {...{
              initialTransition,
              accepted,
              programas,
              carrera,
              telefono,
              celular,
              sexo,
              genero,
              correo_personal,
              onChange: this.onChange,
              setearEstado : (estados) => this.setState(estados),
            }}
          />
        )
        case 1:
        return (
          <Instrucciones
          {...{
            initialTransition,
            instrucciones,
            encabezadoInstrucciones,
            cambiarEstado:this.cambiarEstado,
            setearEstado : (estados) => this.setState(estados),
          }}
        />
        )
        case 2:
        return (
          <FormSeconds
          {...{
            initialTransition,
            valoresPreguntas,
            respuestas,
            valid1,
            valid2,
            valid3,
            instrucciones,
            encabezadoInstrucciones,
            cambiarEstado:this.cambiarEstado,
            setearEstado : (estados) => this.setState(estados),
          }}
        />
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
    }
  }

  render() {
    let { modalAddRea, mostrarModalAddRea} = this.props
    let { activeStep, cargando, titulo_carg } = this.state;
   
    return (
      <>
          <div>
            <Dialog fullWidth={true} maxWidth="md" open={modalAddRea} onClose={() => mostrarModalAddRea(false)}>
            <AppBarModal titulo={"¡ Estilos de Aprendizaje !"} mostrarModal={() => mostrarModalAddRea(false)} titulo_accion="Cerrar" accion={() => mostrarModalAddRea(false)}/>
            <DialogContent className='scroll'>
              {!cargando ?
                <Grid container>
                  <Grid item xs={12} md={12} >
                    <Stepper activeStep={activeStep}>
                      {
                        configStep.map(label => (
                          <Step key={label}>
                            <StepLabel><p>{label}</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {this.configStepContent()}
                  </Grid>
                </Grid>
                :
                <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
              }
            </DialogContent>
            <DialogActions>
              { !cargando &&
                <>
                  {activeStep > 0 ?
                    activeStep > 1 ?
                      <div>
                        <BtnFormPage texto="ATRAS" callback={() => this.handleStep(false)} />
                        <BtnFormPage texto="GUARDAR" callback={this.onSubmit} />
                      </div>
                      : <div>
                        <BtnFormPage texto="ATRAS" callback={() => this.handleStep(false)} />
                        <BtnFormPage texto="SIGUIENTE" callback={() => this.handleStep(true)} />
                      </div>
                    : <BtnFormPage texto="SIGUIENTE" callback={() => this.handleStep(true)} />
                  }
                </>
              }
            </DialogActions>
          </Dialog >
        </div> 
      </>
    )
    }
  
}

ReaEncuesta.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
  modalAddRea: PropTypes.bool.isRequired,
  guardarRea: PropTypes.func.isRequired,
}

export default ReaEncuesta
