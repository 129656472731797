import React from 'react';

// COPONENTES MATERIALS
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';


// ICONOS MENU
import { Hidden } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CrearRetos from '@material-ui/icons/Edit';
import LoopIcon from '@material-ui/icons/Loop';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

// MENU RETOS - ESTUDIANTES

export function MenuEstudiantes({ sele, seleccionar }) {

	const pintar = (app, claseN = '') => {
		if (!app) {
			return (
				<div className={claseN}>
					<BottomNavigation showLabels className={clase('miproceso', sele)} onClick={() => seleccionar('miproceso')}>
						<BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
					</BottomNavigation>

					<BottomNavigation showLabels className={clase('nuevo', sele)} onClick={() => seleccionar('nuevo')}>
						<BottomNavigationAction label={'Nuevo'} icon={<CrearRetos />} />
					</BottomNavigation>

					<BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
						<BottomNavigationAction label={'Historial'} icon={<ImportContactsIcon />} />
					</BottomNavigation>
				</div>
			)
		} else {
			return (
				<div className={claseN}>
					<BottomNavigation showLabels >
						<BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('miproceso', sele, '_app')} onClick={() => seleccionar('miproceso')} />

						<BottomNavigationAction label={'Nuevo'} icon={<CrearRetos />} className={clase('nuevo', sele, '_app')} onClick={() => seleccionar('nuevo')} />

						<BottomNavigationAction label={'Historial'} icon={<ImportContactsIcon />} className={clase('historial', sele, '_app')} onClick={() => seleccionar('historial')} />

					</BottomNavigation>
				</div>
			)
		}
	}

	return (
		<div>
			<div>
				<Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
				<Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
			</div>
		</div>
	);

}

// MENU RETOS SOLICITUD DETALLES

export function RetosMenu({ sele = 0, seleccionar = () => { } }) {
	const pintar = (claseN = '') => {
		return (
			<div className={claseN}>
				<BottomNavigation showLabels >
					<BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} />
					<BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', sele)} onClick={() => seleccionar('documentos')} />
					<BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
					<BottomNavigationAction label={'Retroalimentación'} icon={<PlaylistAddCheckIcon />} className={clase('historial', sele)} onClick={() => seleccionar('historial')} />
					<BottomNavigationAction label={'Linea De Estado'} icon={<CheckCircleIcon />} className={clase('LineaDeEstados', sele)} onClick={() => seleccionar('LineaDeEstados')} />
				</BottomNavigation>
			</div>
		)
	}

	return pintar('menu_modal');
}


// MENU RETOS - ADMINISTRAR

export function MenuAdministrar({ administrador, sele = 0, seleccionar = () => { } }) {
	const pintar = (app, claseN = '') => {
		if (!app) {
			return (
				<div className={claseN}>
					<BottomNavigation showLabels className={clase('Procesos', sele)} onClick={() => seleccionar('Procesos')}>
						<BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
					</BottomNavigation>
					{administrador &&
						<BottomNavigation showLabels className={clase('Permisos', sele)} onClick={() => seleccionar('Permisos')}>
							<BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
						</BottomNavigation>
					}
				</div>
			)
		} else {
			return (
				<div className={claseN}>
					<BottomNavigation showLabels >
						<BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('Procesos', sele, '_app')} onClick={() => seleccionar('Procesos')} />
						{
							administrador && <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('Permisos', sele, '_app')} onClick={() => seleccionar('Permisos')} />
						}
					</BottomNavigation>
				</div>
			)
		}
	}

	return (
		<div>
			<div>
				<Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
				<Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
			</div>
		</div>
	);
}

