import React, { Component } from 'react';
import { Paper, List, Dialog, DialogActions, DialogContent, Grid, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import { obtenerValoresFiltros, transformar, api } from "../../global/js/funciones";
import AppBarModal from '../general/AppBarModal'
import { BtnDescargar, BtnCargar, BtnEditar, BtnSeleccion, BtnVer, BtnRegresar, BtnForm } from "../general/BotonesAccion";
import EnviarMultiplesArchivos from "../general/EnviarMultiplesArchivos";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';


export default function PracticasPendientes({adj_faltantes, modalAdjFaltantes, mostrarModalAdjFaltantes, guardarAdjuntosNuevos, mostrarModalIniActa, setActa, cargando, admin }) {

  const accionesAdjFaltantes = (data, callback) => {
    const onClickCargar = (data) => callback(data);
    const onClickDescargar = ({ archivo }) => archivo && window.open(`${api + archivo}`, '_blank');

    const onClickActa = (data) => {
      setActa(data)
      mostrarModalIniActa(true);
    }

    let descargar = data.archivo && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    let formato = <BtnEditar callback={() => onClickActa(data)} color='#00bfa5' texto='Diligenciar' />

    let cf = parseInt(data.tipo) === 1 ? formato : cargar;
    let cf2 = data.valord === '1' && data.tipo !== '1' ? cargar : '';
    let secun = admin ? cf : cf2

    return <div>{descargar} {secun}</div>;
  }

  // accionesAdjFaltantes = (data, callback) => {
  //   const onClickCargar = (data) => callback(data);
  //   const onClickDescargar = ({ archivo }) => archivo && window.open(`${api + archivo}`, '_blank')

  //   let descargar = data.archivo && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
  //   let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
  //   return data.valord === '1' && data.tipo !== '1' && <div>{descargar} {cargar}</div>;
  // }

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={modalAdjFaltantes} onClose={() => mostrarModalAdjFaltantes(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <AppBarModal titulo='¡ Requisitos Pendientes !' mostrarModal={mostrarModalAdjFaltantes} titulo_accion='' accion='' />
      <DialogContent className='scroll' style={{ padding: 0 }}>
        {cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
          <Grid container >
            <Grid item xs={12}>
              <Paper elevation={0} square>
                <List component="nav" arial-label="main">
                  {/* <EnviarMultiplesArchivos
                    acciones={accionesAdjFaltantes}
                    enModal={false}
                    enviarArchivos={(archivos, callback) => guardarAdjuntosNuevos(archivos, callback)}
                    limite={2}
                    mostrarMensaje={false}
                    enLista={true}
                    lista={adj_faltantes}
                    formatos={true}
                  /> */}
                </List>
              </Paper>
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <BtnForm texto="CERRAR" callback={() => mostrarModalAdjFaltantes(false)} />
        {!cargando && <BtnForm texto="TERMINAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />}
      </DialogActions>
    </Dialog >
  )
}

PracticasPendientes.defaultProps = {
  mostrarModalIniActa: () => { },
  setActa: () => { },
};

