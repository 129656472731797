import React from "react";
import { useSelector } from "react-redux";
import { MostrarSubmodulo } from "../general/MostrarSubmodulo";
import SemilleroEstudiante from "./SemilleroEstudiante";
import SemilleroAdministrador from "./SemilleroAdministrador";
import Semilleros from "./Semilleros";
import CursoEstudiante from "../cursodeformacion/CursoEstudiante";
import GestionarCurso from "../cursodeformacion/Cursos/gestionar/GestionarCurso";

const SemilleroPrincipal = () => {
   const usuario = useSelector((state) => state.redGlobal.usuario);

   const sub_actividades = [
      {
         codigo: "semi_est",
         nombre: "semilleros_estudiante",
         componente: () => SemilleroEstudiante,
      },
      {
         codigo: "semi_act",
         nombre: "gestion_semilleros",
         componente: () => SemilleroAdministrador,
      },
      {
         codigo: "semi_pro",
         nombre: "semilleros_profesor",
         componente: () => Semilleros,
      },
      {
         codigo: "curso_formacion_estudiante",
         nombre: "curso_estudiante",
         componente: () => CursoEstudiante,
      },
      {
         codigo: "gestion_curso_formacion",
         nombre: "gestion_curso",
         componente: () => GestionarCurso,
      },
   ];

   const codigo_principal = "semilleros_principal";

   return (
      <>
         <MostrarSubmodulo
            sub_actividades={sub_actividades}
            codigo_principal={codigo_principal}
            usuario={usuario.id}
         />
      </>
   );
};

export default SemilleroPrincipal;
