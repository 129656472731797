
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//COMPONENTES DEL MODULO
import PracticasSucursalesListar from "./PracticasSucursalesListar";
import ValoresModificar from '../../genericas/valores/ValoresModificar'
import ConfirmarAccion from '../../general/ConfirmarAccion';
import Detalle from '../../general/Detalle';

class PracticasSucursales extends Component {


  render() {

    let {
      mostrarModalDelSucursal,
      mostrarModalModSucursal,
      actualizarDataSucursal,
      sucursales,
      sucursal,
      modalDelSucursal,
      modalModSucursal,
      modificarSucursal,
      eliminarSucursal,
      modalDetValores,
      mostrarModalDetValores,
    } = this.props;

    const config = () => {
      return {
        inputCodigo: { 'label': 'NIT(Ejemplo : 890104530-9)', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputNombre: { 'label': 'Ubicación', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputDescripcion: { 'label': 'Actividad Económica', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputValora: { 'label': 'Barrio', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorb: { 'label': 'Ciudad', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorc: { 'label': 'Departamento', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValord: { 'label': 'Telefono', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValore: { 'label': 'Fax', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorf: { 'label': 'Correo', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorg: { 'label': 'Dirección', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputArchivo: { 'label': 'Adjuntar certificado (Camara de comercio)', 'mostrar': false, 'tipo': 'file', 'requerido': false },
      }
    }

    const configDetail = data => {
      let { valorc, valorb, valora, nombre, valord, valore, valorf, valorg } = data
      return (
        [
          { 'label': 'Programa - Área de la empresa', 'value': nombre, },
          { 'label': 'Barrio', 'value': valora, },
          { 'label': 'Ciudad', 'value': valorb, },
          { 'label': 'Departamento', 'value': valorc, },
          { 'label': 'Telefono', 'value': valord, },
          { 'label': 'Fax', 'value': valore, },
          { 'label': 'Correo', 'value': valorf, },
          { 'label': 'Dirección', 'value': valorg, },
        ]
      )
    }

    return (
      <div>

        <PracticasSucursalesListar
          mostrarModalModSucursal={mostrarModalModSucursal}
          mostrarModalDelSucursal={mostrarModalDelSucursal}
          actualizarDataSucursal={actualizarDataSucursal}
          sucursales={sucursales}
          mostrarModalDetValores={mostrarModalDetValores}

        />
        <ValoresModificar
          mostrarABC={true}
          modalModValores={modalModSucursal}
          mostrarModalModValores={mostrarModalModSucursal}
          valor={sucursal}
          modificarValor={modificarSucursal}
          inputs={config()}
          titulo='¡ Modificar Sucursal !'
        />

        <Detalle
          titulo={'¡ Detalle Sucursal !'}
          open={modalDetValores}
          config={sucursal ? configDetail(sucursal) : []}
          cerrar={() => mostrarModalDetValores(false)}
        />
        <ConfirmarAccion
          mensaje1={`La empresa "${sucursal.nombre}" será eliminada, `}
          ejecutarAccion={eliminarSucursal}
          mostrarModalConfAccion={mostrarModalDelSucursal}
          modalConAccion={modalDelSucursal}
          dato={sucursal}
          titulo={'¿ Eliminar Empresa ?'}
        />

      </div >
    )
  }
}

//Props del componente
PracticasSucursales.propTypes = {
  //variables
  sucursales: PropTypes.array.isRequired,
  sucursal: PropTypes.object.isRequired,
  modalAddSucursal: PropTypes.bool.isRequired,
  modalDelSucursal: PropTypes.bool.isRequired,
  modalModSucursal: PropTypes.bool.isRequired,
  //funciones
  mostrarModalDelSucursal: PropTypes.func.isRequired,
  mostrarModalModSucursal: PropTypes.func.isRequired,
  mostrarModalAddSucursal: PropTypes.func.isRequired,
  actualizarDataSucursal: PropTypes.func.isRequired,
}



export default PracticasSucursales;
