import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select
} from '@material-ui/core';
//FUNCIONES GENERALES
import { obtenerValorsPermisos, consulta, mostrarError, obtenerValoresFiltrosContenido } from '../../../global/js/funciones';
//COMPONENTES DEL MODULO
import ValoresListarPermisos from './ValoresListarPermisos';
import ValoresAgregar from './ValoresAgregar';
//ACCIONES DEL MODULO
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';

class ValoresAgregarPermiso extends Component {
	constructor(props) {
		super(props);
		this.state = {
			permisos: [],
			cargando: false,
			valorSelect: '',
			
		};
	}
	componentDidUpdate({ modalAddPermisosValores, modalAddValorPermiso }, { valorSelect }) {
		let { modalAddPermisosValores: modalAddNew, modalAddValorPermiso: modalAddValorPermisoNew, valor, filtro } = this.props;
		if (modalAddNew !== modalAddPermisosValores) {
			if (modalAddNew) this.setState({ valorSelect: filtro });
			else this.setState({ valorSelect: '' });
		}
		if ((valorSelect !== this.state.valorSelect && this.state.valorSelect) || modalAddValorPermiso != modalAddValorPermisoNew && modalAddValorPermisoNew == false) this.cargarPermisos(valor.id);

	}

	cargarPermisos = async (valor) => {
		this.setState({ cargando: true });
		const permisos = await obtenerValorsPermisos(valor, [{ llave: 'generica', valor: this.state.valorSelect }]);
		this.setState({ permisos, cargando: false });
		
	};

	guardarPermiso = (valor_secundario) => {
		let { valor } = this.props;
		consulta(
			'api/v1.0/permisos/crear',
			{ secundario: valor_secundario.id, principal: valor.id },
			'post',
			(error, estado, resp) => {
				let titulo = 'Ha ocurrido un error, contacte con el administrador.',
					tipo = 'error';
				if (!error) {
					if (estado === 200) {
						tipo = 'success';
						titulo = resp.titulo;
						this.cargarPermisos(valor.id);
					} else {
						tipo = 'info';
						titulo = mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			}
		);
	};

	

	eliminarPermiso = (data) => {
		let { valor } = this.props;
		consulta(`api/v1.0/permisos/${data.permiso}/eliminar`, {}, 'put', (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.cargarPermisos(valor.id);
				} else {
					tipo = 'info';
					titulo = mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});
	};

	render() {
		let { modalAddPermisosValores, mostrarModalAddPermisoValores, titulo, administra, genericas, modalAddValorPermiso, mostrarModalAddValorPermiso, inputs_agregar, titulo_agregar, agregarValor, guardar_agregar } = this.props;

		let { permisos, cargando, valorSelect } = this.state;

		return (
			<div>
				<Dialog
					open={modalAddPermisosValores}
					onClose={mostrarModalAddPermisoValores.bind(this, false)}
					aria-labelledby="form-dialog-title"
					fullWidth={true}
					maxWidth="md"
				>
					<AppBarModal
						titulo={titulo}
						mostrarModal={mostrarModalAddPermisoValores}
						titulo_accion="Cerrar"
						accion={mostrarModalAddPermisoValores}
					/>
					<DialogContent style={{ padding: 0 }} className="scroll">
						<Grid container style={{ padding: 30 }}>
							<Grid item xs={8}>
								{
									administra &&
									<FormControl className="form-control" /*error={this.conError(programa.error)}*/>
										<InputLabel htmlFor="parametro">Seleccione Parámetro</InputLabel>
										<Select
											id="parametro"
											value={valorSelect}
											onChange={(e) => this.setState({ valorSelect: e.target.value })}
											name="parametro"
										>
											{genericas.map(({ nombre, id }) => (
												<MenuItem key={id} value={id}>
													{`${id} - ${nombre}`}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								}
							</Grid>
						</Grid>
						{!cargando ? (
							<ValoresListarPermisos
								agregarValor={agregarValor}
								permisos={permisos}
								guardarPermiso={this.guardarPermiso}
								eliminarPermiso={this.eliminarPermiso}
								administra={administra}
								mostrarModalAddValorPermiso={mostrarModalAddValorPermiso}
							/>
						) : (
								<TareasTerminadas imagen={emma_w} mensaje="Cargando..." widthImg="7%" marginTop="7%" />
							)}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => mostrarModalAddPermisoValores(false)} />
					</DialogActions>
				</Dialog>
				<ValoresAgregar
					mostrarABC={true}
					modalAddValores={modalAddValorPermiso}
					mostrarModalAddValores={mostrarModalAddValorPermiso}
					guardarValor={guardar_agregar}
					titulo={titulo_agregar}
					inputs={inputs_agregar}
				/>
			</div>
		);
	}
}

//Props del componente
ValoresAgregarPermiso.propTypes = {
	administra: PropTypes.bool.isRequired,
	//variables
	modalAddPermisosValores: PropTypes.bool.isRequired,
	mostrarModalAddPermisoValores: PropTypes.func.isRequired
};

ValoresAgregarPermiso.defaultProps = {
	genericas: [],
	titulo: 'Asignar Relacion',
	administra: true,
	filtro: '',
	agregarValor: false,
	titulo_agregar: '',
	modalAddValorPermiso: false,
	mostrarModalAddValorPermiso: () => {},
	guardar_agregar : () => {},
	inputs_agregar: {
		inputCodigo: { 'label': 'Codigo', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputNombre: { 'label': 'Nombre', 'mostrar': true, 'tipo': 'text', requerido: true, mayusculas: false },
		inputDescripcion: { 'label': 'Descripción', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValora: { 'label': 'Valor A', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorb: { 'label': 'Valor B', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorc: { 'label': 'Valor C', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValord: { 'label': 'Valor D', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValore: { 'label': 'Valor E', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorf: { 'label': 'Valor F', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputValorg: { 'label': 'Valor G', 'mostrar': false, 'tipo': 'text', requerido: false, mayusculas: false },
		inputArchivo: { 'label': 'Adjuntar Archivo', 'mostrar': false, 'tipo': 'file', requerido: false, },
	  }
};

export default ValoresAgregarPermiso;
