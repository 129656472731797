import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  selecteditem: {
    borderBottom: '5px solid #900807!important',
    backgroundColor: '#fffde7!important',
  },
  noSelectedItem:{
    margin: '0 3px!important',
    border: '1px solid #eee!important',
    width: '30px!important',
    backgroundColor: '#eee!important',
    padding: '0',
  }
}));

export function SemilleroMenuProfesorParticipantes({  profesor,sele = 0, seleccionar = () => { } }) {
  const clase = (item, selec, tipo = '') => item === selec ? `${classes.selecteditem}` : `item_modulo`;
  const classes = useStyles()
    const pintar = (claseN = '') => {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            {profesor == true  ? <BottomNavigationAction label={'Participantes'} icon={<ContactMailIcon />} className={clase('Información del Semillero', sele)} onClick={() => seleccionar('Información del Semillero')} />: ''}
          </BottomNavigation>
        </div>
      )
    }
  
    return pintar('menu_modal');
  }