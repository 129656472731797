import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, Checkbox, DialogActions, Grid, Dialog, DialogContent, Paper, Select, InputLabel, MenuItem, Table, Button, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { FormControl, TextField } from '@material-ui/core';

import { obtenerPermisosValor } from "../../../global/js/funciones";
import AppBarModal from '../../general/AppBarModal'
import { BtnEditar, BtnEliminar, BtnForm } from "../../general/BotonesAccion";
import InputFile from "./../../general/InputFile";
import { MenuGestionar } from "./InscripcionesMenu";
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import BuscarSelect from '../../general/BuscarSelect';
import AccionesRapidas from '../../general/AccionesRapidas';
import { mostrarPrueba } from '../../../redux/actions/actPracticas';
import AlertasSimple from '../../general/AlertasSimple';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  return (
    <List subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

class InscripcionesGestionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      estados_siguiente: [],
      archivo: null,
      seleccion: 'gestion',
      adj_archivo: '',
      fecha: null,
      horario: null,
    }
    this.fileInput = React.createRef();
  }

  async componentDidUpdate({ modalGestionarInscripcion }) {
    let { modalGestionarInscripcion: modalGestionarInscripcionNew, inscripcion } = this.props;
    if (modalGestionarInscripcion !== modalGestionarInscripcionNew) {
      if (modalGestionarInscripcionNew) {
        this.reiniciarEstado();
        let estados_siguiente = inscripcion.estado_actual ? await obtenerPermisosValor(inscripcion.estado_actual.id) : []
        this.setState({ estado: '', motivo: '', estados_siguiente, cargando: false });
      }
    }
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      estados_siguiente: [],
      archivo: null,
      seleccion: 'gestion',
      adj_archivo: '',
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  agregarArchivo = ({ target }) => {
    let { value, files } = target;
    this.setState({ adj_archivo: value, archivo: files[0] });
  }

  onSubmit = e => {
    let { inscripcion, actualizarMensaje, props_c } = this.props;
    let { estado, motivo, archivo } = this.state;
    console.log(estado)
    if (estado == 'Ins_Reg_Edu') {
      let titulo = this.validarDatos();
      console.log(titulo)
      if (titulo) {
        console.log(titulo)
        actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
      } else {
        this.setState({ cargando: true, titulo_res: 'Enviando..' })
        let data = {
          motivo,
          estado,
          archivo,
          total: archivo ? 1 : 0,
          carrera: props_c.carrera,
          costear_estudio: props_c.costear_estudio.value,
          nivel_educacion: props_c.nivel_educacion.value,
          linea_edu: props_c.linea_edu.value,
          jornada: props_c.jornada.value,
          nombre_colegio: props_c.nombre_colegio,
          estudiante_11: props_c.estudiante_11.value,
          nombre_madre: props_c.nombre_madre,
          celular_madre: props_c.celular_madre,
          nombre_padre: props_c.nombre_padre,
          celular_padre: props_c.celular_padre,
          otro_jornada: props_c.otro_jornada,
          otro_costear: props_c.otro_costear,
          periodo: props_c.peridoAcademicosSelected.codigo,
          fecha_entrevista_prog: this.state.fecha ? moment(this.state.fecha).format('YYYY-MM-DD') : null,
          hora_entrevista_prog: this.state.horario ? moment(this.state.horario).format('HH:mm:ss') : null,
          observacion_entrevista_prog: this.state.observacion,
        }
        this.props.gestionarInscripcion(inscripcion.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
      }
    } else {
      this.setState({ cargando: true, titulo_res: 'Enviando..' })
      let data = {
        motivo,
        estado,
        archivo,
        total: archivo ? 1 : 0,
        fecha_entrevista_prog: this.state.fecha ? moment(this.state.fecha).format('YYYY-MM-DD') : null,
        hora_entrevista_prog: this.state.horario ? moment(this.state.horario).format('HH:mm:ss') : null,
        observacion_entrevista_prog: this.state.observacion,
      }
      this.props.gestionarInscripcion(inscripcion.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    }

    e.preventDefault();
  }

  validarDatos = () => {
    let { props_c } = this.props;
    let mensaje = '';

    if (props_c.carrera === '') mensaje = 'Ingrese Carrera.';//
    else if (props_c.costear_estudio === '') mensaje = 'Seleccione como costeara sus estudios.';//
    else if (props_c.nivel_educacion === '') mensaje = 'Seleccione Nivel de Educacion.';//
    else if (props_c.linea_edu === '') mensaje = 'Seleccione una Linea EDU.co.';//
    else if (props_c.jornada === '') mensaje = 'Seleccione una Jornada.';//
    else if (props_c.nombre_colegio === '') mensaje = 'Ingrese Colegio.';//
    else if (props_c.estudiante_11 === '') mensaje = 'Seleccione si cursa o no actualmente el grado 11.';//
    else if (props_c.nombre_madre === '') mensaje = 'Ingrese Nombre de la Madre.';//
    else if (props_c.celular_madre === '') mensaje = 'Ingrese Celular de la Madre.';//
    else if (props_c.nombre_padre === '') mensaje = 'Ingrese Nombre del Padre.';//
    else if (props_c.celular_padre === '') mensaje = 'Ingrese Celular del Padre.';//
    else if (props_c.peridoAcademicosSelected === '') mensaje = 'Ingrese el Periodo.';
    return mensaje;
  }

  pintarEstados() {
    let { estado, estados_siguiente, adj_archivo } = this.state;
    let { props_c } = this.props;

    if (props_c.accionTarjeta === 'Tipo_Homo' && props_c.accionProceso === 'Ins_Reg' && this.state.seleccion === 'gestion') {
      estados_siguiente = estados_siguiente.filter(
        (estado) => ['Ins_Can', 'Ins_Pro'].includes(estado.secundario.codigo)
      )
    }

    if (props_c.accionTarjeta === 'Tipo_Homo' && this.state.seleccion === 'gestion') {
      estados_siguiente = estados_siguiente.map(
        (estado) => (estado.secundario.codigo === 'Ins_Pro' ? { ...estado, secundario: { ...estado.secundario, nombre: 'Procesado' } } : estado)
      );
    }

    if (props_c.accionTarjeta === 'Tipo_Homo' && props_c.accionProceso === 'Ins_Pro' && this.state.seleccion === 'gestion') {
      estados_siguiente = estados_siguiente.filter(
        (estado) => ['Ins_Pag'].includes(estado.secundario.codigo)
      )
    }

    if (props_c.accionTarjeta === 'Tipo_Ins') {
      estados_siguiente = estados_siguiente.filter(
        (estado) => !['Ins_Pag'].includes(estado.secundario.codigo)
      )
    }

    if (props_c.accionTarjeta === 'Tipo_Ins' && props_c.accionProceso === 'Ins_Pro' && this.state.seleccion === 'gestion') {
      estados_siguiente = estados_siguiente.filter(
        (estado) => !['Ins_Estu_Rea'].includes(estado.secundario.codigo)
      )
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
        </Grid>
        {estado === 'Ins_Can' && (
          <Grid item xs={12} md={12}>
            <FormControl className="form-control">
              <TextField
                id="txtMotivoRechazo"
                label="Motivo Cancelación"
                name="motivo"
                placeholder="Escriba el motivo de cancelación de la solicitud"
                multiline
                onChange={this.onChange}
              />
            </FormControl>
          </Grid>
        )}
        {((estado === 'Ins_Env' && props_c.accionTarjeta === 'Tipo_Ins') || (estado === 'Ins_Pro' && props_c.accionTarjeta === 'Tipo_Homo')) && (
          <Grid item xs={12} md={12}>
            <Grid item xs={12}>
              <InputFile label='Adjuntar Volante' id='adj_archivo' value={adj_archivo} />
            </Grid>
            <Grid item xs={12} className='oculto'>
              <TextField
                ref={this.fileInput}
                type="file"
                id="adj_archivo"
                name="adj_archivo"
                label="Adjuntar Volante"
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.agregarArchivo}
              />
            </Grid>
          </Grid>
        )}
        {(estado === 'Ins_Estu_Rea') && (
          <Grid item xs={12} md={12}>
            <Grid item xs={12}>
              <InputFile label='Adjuntar Estudio de Homologación' id='adj_archivo' value={adj_archivo} />
            </Grid>
            <Grid item xs={12} className='oculto'>
              <TextField
                ref={this.fileInput}
                type="file"
                id="adj_archivo"
                name="adj_archivo"
                label="Adjuntar Estudio de Homologación"
                fullWidth
                autoComplete="billing address-line1"
                onChange={this.agregarArchivo}
              />
            </Grid>
          </Grid>
        )}
        {estado === 'Ins_Reg_Edu' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BuscarSelect datos={props_c.opciones_estudiante} cambiarEstado={(value) => props_c.setOpcion_estudiante(value)} valor={props_c.estudiante_11} id='select-tipo-estudiante_11' nombre='¿ Estás cursando 11° actualmente ?' placeholder='Seleccione Opción' />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={props_c.nombre_colegio.toUpperCase()}
                required
                id="nombre_colegio"
                name="nombre_colegio"
                label="¿Cuál es el nombre del colegio donde estudias?"
                fullWidth
                autoComplete="fname"
                onChange={props_c.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect datos={props_c.niveles_educacion} cambiarEstado={(value) => props_c.setOpcion_nivelEducacion(value)} valor={props_c.nivel_educacion} id='select-tipo-nivel' nombre='¿A que nivel de la educación superior planeas ingresar ?' placeholder='Seleccione Opción' />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect
                datos={props_c.periodosAcademicos}
                cambiarEstado={(value) => props_c.setOpcion_periodoAcademico(value)}
                valor={props_c.peridoAcademicosSelected}
                id='select-tipo-nivel'
                nombre='Periodo para la Solicitud'
                placeholder='Seleccione Opción'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={props_c.carrera.toUpperCase()}
                required
                id="carrera"
                name="carrera"
                label="¿Que quieres estudiar?"
                fullWidth
                autoComplete="fname"
                onChange={props_c.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect datos={props_c.lineas_edu_co} cambiarEstado={(value) => props_c.setTipo_linea(value)} valor={props_c.linea_edu} id='select-tipo-EDU.co' nombre='¿En cuál linea EDU.co te quieres inscribir?' placeholder='Seleccione linea' />
            </Grid>
            <Grid item xs={12}>
              <BuscarSelect datos={props_c.jornada_preinscripcion} cambiarEstado={(value) => props_c.setTipo_jornada(value)} valor={props_c.jornada} id='select-tipo-jornada' nombre='¿Qué disponiilidad tienes para realizar este entrenamiento?' placeholder='Seleccione disponibilidad' />
            </Grid>
            {props_c.jornada.label === 'OTRO' && (
              <Grid item xs={12}>
                <TextField
                  value={props_c.otro_jornada.toUpperCase()}
                  required
                  id="otro_jornada"
                  name="otro_jornada"
                  label="Escriba la Disponibilidad"
                  fullWidth
                  autoComplete="fname"
                  onChange={props_c.onChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <BuscarSelect datos={props_c.costear_estudios} cambiarEstado={(value) => props_c.setOpcion_costearEstudio(value)} valor={props_c.costear_estudio} id='select-tipo-costear' nombre='Cómo tienes pensado costear tus estudios de educación superior' placeholder='Seleccione Opción' />
            </Grid>
            {props_c.costear_estudio.label === 'OTRO' && (
              <Grid item xs={12}>
                <TextField
                  value={props_c.otro_costear.toUpperCase()}
                  required
                  id="otro_costear"
                  name="otro_costear"
                  label="Ingrese Otra Forma de Costear sus Estudios"
                  fullWidth
                  autoComplete="fname"
                  onChange={props_c.onChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                value={props_c.nombre_madre.toUpperCase()}
                required
                id="nombre_madre"
                name="nombre_madre"
                label="¿Cuál es el nombre de la Madre?"
                fullWidth
                autoComplete="fname"
                onChange={props_c.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type='number'
                value={props_c.celular_madre}
                required
                id="celular_madre"
                name="celular_madre"
                label="¿Cuál es el número de celular de tu madre?"
                fullWidth
                autoComplete="fname"
                onChange={props_c.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={props_c.nombre_padre.toUpperCase()}
                required
                id="nombre_padre"
                name="nombre_padre"
                label="¿Cuál es el nombre del Padre?"
                fullWidth
                autoComplete="fname"
                onChange={props_c.onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={props_c.celular_padre}
                required
                type='number'
                id="celular_padre"
                name="celular_padre"
                label="¿Cuál es el número de celular de su Padre?"
                fullWidth
                autoComplete="fname"
                onChange={props_c.onChange}
              />
            </Grid>
          </Grid>
        )}
        {/* {estado === 'Ins_Ent_Prog' && (
          <>
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              {this.renderInputs(this.state.estado, estados_siguiente)}
            </Grid>
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              {(parseInt(this.state.estado.valora) !== 2 && this.state.estado.codigo !== 'Est_Pra_Arl') &&
                <TextField multiline value={this.state.observacion} onChange={this.onChange} name="observacion" id="observacion" label="Observaciones" fullWidth />
              }
            </Grid>
          </>
        )} */}
      </Grid >
    )
  }

  setFecha = (fecha) => {
    this.setState({
      fecha
    })
  }

  setHorario = (horario) => {
    this.setState({
      horario
    })
  }

  renderInputs = () => {
    let { horario, fecha } = this.state;
    return (
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <Grid container>
              <KeyboardDatePicker
                required={true}
                style={{ marginTop: 3, width: '100%' }}
                minDate={Date("YYYY-MM-dd")}
                margin="normal"
                label="Fecha"
                format="yyyy-MM-dd"
                value={fecha}
                onChange={this.setFecha}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                invalidDateMessage='Formato Invalido'
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <Grid container>
              <KeyboardTimePicker
                required={true}
                style={{ marginTop: 3, width: '100%' }}
                margin="normal"
                label="Hora"
                value={horario}
                onChange={this.setHorario}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage='Formato Invalido'
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }

  pintar() {
    let { seleccion } = this.state;
    return (<div>
      {seleccion === 'gestion' && this.pintarEstados()}
      {/* {seleccion === 'adjuntos' && this.pintarAdjuntos()} */}
    </div>)
  }
  render() {
    let { modalGestionarInscripcion, mostrarModalGestionarInscripcion, inscripcion } = this.props;
    let { cargando, titulo_res, seleccion } = this.state;

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalGestionarInscripcion} onClose={mostrarModalGestionarInscripcion.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={'¿ Gestionar Inscripcion ?'} mostrarModal={mostrarModalGestionarInscripcion} titulo_accion="" accion="" />
        <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} inscripcion={inscripcion} />
        <DialogContent style={{ padding: '1' }} className='scroll'>
          {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} /> : <div style={{ padding: "5px 0 5px 0" }}> {this.pintar()} </div>}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalGestionarInscripcion(false)} />
          {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
        </DialogActions>

      </Dialog>
    );
  }
}

InscripcionesGestionar.propTypes = {
  //variables
  // inscripcion: PropTypes.object.isRequired,
  modalGestionarInscripcion: PropTypes.bool.isRequired,
  //funciones
  mostrarModalGestionarInscripcion: PropTypes.func.isRequired,
  gestionarInscripcion: PropTypes.func.isRequired,
}

export default InscripcionesGestionar;
