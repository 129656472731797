import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader,
   Checkbox, DialogActions, Grid, Dialog, DialogContent } from '@material-ui/core';
import {FormControl, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";
import InputFile from "./../../general/InputFile";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import BuscarPersona from '../../general/BuscarPersona';
import { consulta, obtenerPermisosValor} from "./../../../global/js/funciones";
import BuscarSelect from '../../general/BuscarSelect';
import { coloresEmma } from './../../../global/js/funciones';

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  return (
    <List subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

class InscripcionePreuniversitarioGestionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      observacion:'',
      adj_archivo: '',
      archivo: null,
      estados_siguiente: [],
      seleccion: 'gestion',
      buscarGestor: false,
      gestion:[],
      gestor: {
        id: 0,
        nombre_completo: 'Clic aquí para buscar',
      },
      periodosAcademicos: [],
      peridoAcademicosSelected: {}
    }
    this.fileInput = React.createRef();
  }

  async componentDidUpdate({ modalAsignarPreUniversitario }) {
    let { modalAsignarPreUniversitario: modalAsignarPreUniversitarioNew, proceso } = this.props;
    if (modalAsignarPreUniversitario !== modalAsignarPreUniversitarioNew) {
      let gestion = await this.obtenerInscripcionPreUniversitarioGestion(proceso.id)
      let periodos = await this.obtenerPeriodos(1)
      let estados_siguiente = proceso.estado_actual ? await obtenerPermisosValor(proceso.estado_actual.id) : []
      this.setState({ periodosAcademicos: this.transformarEnviadoPeriodos(periodos), estado: '', motivo: '', estados_siguiente, cargando: false, gestion:gestion[0] });
    }
  }
  
  async obtenerInscripcionPreUniversitarioGestion(id) {
    this.setState({ cargando: true });
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripcion/datos/preuniversitario/gestion/${id}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
      })
    }  

  reiniciarEstado() {
    this.setState({
      cargando: false,
      area:'',
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      adj_archivo: '',
      seleccion: 'gestion',
    })
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  agregarArchivo = ({ target }) => {
    let { value, files } = target;
    this.setState({ adj_archivo: value, archivo: files[0]});
  }
  
  enviarDatos = e =>{
    let {actualizarMensaje } = this.props;
    let { estado, motivo, gestor, gestion,  adj_archivo} = this.state;
    let error=false
    if(estado === 'Est_Ins_Asig_Edu'){
      if(gestor.id === 0){
        error=true
        actualizarMensaje({ titulo: `Debe asiganar un Gestor.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
      
  }else if(estado === 'Ins_Can'){
    if(motivo === ''){
      error=true
      actualizarMensaje({ titulo: `Debe especificar un motivo de rechazo.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
  }else if(estado === 'Ins_Env_Pre'){
    if(gestion.colegio_asignado == null) {
      error=true
      actualizarMensaje({ titulo: `Debe asignar un colegio.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.calificacion_contacto == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignar una Calificación Contacto.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.generacion == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignar una Generación E.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.nombre_financiero === '') {
      error=true
      actualizarMensaje({ titulo: `Escriba quien es el responsable financiero.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.telefono_financiero == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignar un Numero del Responsable Financiero.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.metodo_pago == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignar un Metodo de Pago.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.resultado_preuniversitario == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignar un Resultado PreUniversitario.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.checkedIdentificacion == false){
      error=true
      actualizarMensaje({ titulo: `Debe verificar el Numero de Documento.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.checkedCelular == false){
      error=true
      actualizarMensaje({ titulo: `Debe verificar el Numero de Celular.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.checkedTelefono == false){
      error=true
      actualizarMensaje({ titulo: `Debe verificar el Numero de Telefono.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.checkedCorreo == false){
      error=true
      actualizarMensaje({ titulo: `Debe verificar el Correo Personal.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.checkedCelular_madre == false){
      error=true
      actualizarMensaje({ titulo: `Debe verificar el Celular de la Madre.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.checkedCelular_padre == false){
      error=true
      actualizarMensaje({ titulo: `Debe verificar el Celular del Padre.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.estudiante.identificacion == null){
      error=true
      actualizarMensaje({ titulo: `Debe agregar una Identificación al estudiante.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.celular1 == null){
      error=true
      actualizarMensaje({ titulo: `Debe agregar un Numero de Celular.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.telefono1 == null){
      error=true
      actualizarMensaje({ titulo: `Debe agregar un Numero de Telefono.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.estudiante.correo == null){
      error=true
      actualizarMensaje({ titulo: `Debe agregar un Correo al estudiante.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.codigo_pruebas == null){
      error=true
      actualizarMensaje({ titulo: `Debe agregar el Codigo de Pruebas Saber 11.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.carrera == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignarle una carrera al estudiante.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.grupo == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignarle un grupo al estudiante.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.celular_madre == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignarle un numero de Celular de la Madre al estudiante.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.celular_padre == null){
      error=true
      actualizarMensaje({ titulo: `Debe asignarle un numero de Celular del Padre al estudiante.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(gestion.inscripcion.adj_identificacion == null){
      error=true
      actualizarMensaje({ titulo: `Debe adjuntar el Numero de Identificación del estudiante.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
    else if(adj_archivo === '') {
      error=true
      actualizarMensaje({ titulo: `Debe adjuntar el volante de Matricula.`, tipo: 'info', mostrar: true, tiempo: 6000 })
    }
  }
    if(!error)this.onSubmit()
    e.preventDefault();
  }

  onSubmit = () => {
    let {proceso}= this.props;
    let { estado, motivo, gestor, observacion, archivo, peridoAcademicosSelected} = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = { motivo, observacion, estado, gestor:gestor.id, nombre:gestor.nombre_completo,  archivo, total : archivo ? 1 : 0, periodo : peridoAcademicosSelected.codigo}
    this.props.enviar(proceso.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()
  }

    transformarEnviadoPeriodos(data) {
      return data.map((e, index) => ({
        value: index,
        label: e.periodo,
        codigo: e.periodo,
      }));
    }

    async obtenerPeriodos(tipo) {
      return new Promise(resolve => {
        consulta(`api/v1.0/inscripciones/periodos/${tipo}`, null, null,
          (error, estado, resp) => {
            resolve(resp)
          }
        );
      })
    }

    changeState (name, value) {
      this.setState({
        [name]: value
      })
    }

    setOpcion_periodoAcademico (value) {
      this.changeState('peridoAcademicosSelected', value)
    }

  pintarEstados() {
    let { estado, estados_siguiente, gestor,  adj_archivo, periodosAcademicos, peridoAcademicosSelected } = this.state;   
    console.log(periodosAcademicos, peridoAcademicosSelected);
    return (
      <Grid container>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
        </Grid>
        {estado === 'Ins_Can' && (
					<Grid item xs={12} md={12}>
						<FormControl className="form-control">
							<TextField
								id="txtMotivoRechazo"
                label="Motivo Cancelación"
                name="motivo"
                placeholder="Escriba el motivo de cancelación de la solicitud"
                multiline
                onChange={this.onChange}
							/>
						</FormControl>
					</Grid>
				)}
        {estado === 'Est_Ins_Asig_Edu' && (<>
            <Grid container spacing={1} alignItems="flex-end" onClick={() => this.setState({ buscarGestor: true })} className='pointer' style={{ marginBottom: '10px' }}>
            <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
              <TextField label="Gestor" disabled={true} type="text" value={gestor.nombre_completo} style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
            <SearchIcon style={{ color: coloresEmma.secondarycolor }} />
            </Grid>
          </Grid>
          </>
         )}
         {estado === 'Ins_Env_Pre' && (
					<Grid item xs={12} md={12}>
            <Grid item xs={12}>
                <InputFile label='Adjuntar Volante' id='adj_archivo' value={adj_archivo} />
              </Grid>
              <Grid item xs={12} className='oculto'>
                <TextField
                  ref={this.fileInput}
                  type="file"
                  id="adj_archivo"
                  name="adj_archivo"
                  label="Adjuntar Volante"
                  fullWidth
                  autoComplete="billing address-line1"
                  onChange={this.agregarArchivo}
                />
              </Grid>
              <Grid item xs={12}>
              <BuscarSelect
                datos={periodosAcademicos}
                cambiarEstado={(value) =>  this.setOpcion_periodoAcademico(value)}
                valor={peridoAcademicosSelected}
                id='select-tipo-nivel'
                nombre='Periodo para la Solicitud'
                placeholder='Seleccione Opción' />
            </Grid>
					</Grid>
				)}
      </Grid>
    )
  }

  pintar() {
    let { seleccion } = this.state;
    return (<div>
      {seleccion === 'gestion' && this.pintarEstados()}
    </div>)
  }
  render() {
    let { mostrarModalAsignarPreUniversitario, modalAsignarPreUniversitario } = this.props;
    let { cargando, titulo_res, seleccion, buscarGestor } = this.state;

    return (
      <>
      <Dialog fullWidth={true} maxWidth="sm" open={modalAsignarPreUniversitario} onClose={mostrarModalAsignarPreUniversitario.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <AppBarModal titulo={'¿ Gestionar ?'} mostrarModal={mostrarModalAsignarPreUniversitario} titulo_accion="" accion="" />
      <DialogContent style={{ padding: '1' }} className='scroll'>
          {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/> : <div style={{ padding: "5px 0 5px 0" }}> {this.pintar()} </div>}
      </DialogContent>
      <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAsignarPreUniversitario(false)} />
          {!cargando && <BtnForm texto="ACEPTAR" callback={this.enviarDatos} />}
      </DialogActions>
      </Dialog>


      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarGestor} onClose={() => this.setState({ buscarGestor: false })}>
          <AppBarModal titulo={'¡ Buscar Gestor !'} mostrarModal={() => this.setState({ buscarGestor: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarGestor: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            < BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              gestor: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarGestor: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarGestor: false })} />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

InscripcionePreuniversitarioGestionar.propTypes = {
  //variables
  // inscripcion: PropTypes.object.isRequired,
  modalAsignarPreUniversitario: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAsignarPreUniversitario: PropTypes.func.isRequired,
 
}

export default InscripcionePreuniversitarioGestionar;
