import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import moment from 'moment'

import { consulta, mostrarError, copiarPortaPapeles, obtenerValores, generarFiltros } from "../../global/js/funciones";

//COMPONENTES DEL MODULO
import EleccionesAgregar from './EleccionesAgregar'
import EleccionesListar from './EleccionesListar'
import EleccionesModificar from './EleccionesModificar'
import EleccionesProlongar from './EleccionesProlongar'
import EleccionLink from './EleccionLink'
import Eleccion from './Eleccion'
import ConfirmarAccion from '../general/ConfirmarAccion';
import EleccionesFiltrar from './EleccionesFiltrar'
import TareasTerminadas from '../general/TareasTerminadas'
//IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';
import { mostrarModalLinkEleccion, mostrarModalAddEleccion, mostrarModalModEleccion, mostrarModalCanEleccion, mostrarModalFinEleccion, mostrarModalProlEleccion, actualizarDataElecciones, actualizarRenderEleccion, actualizarDataEleccion, mostrarModalAdmCandidatos, mostrarModalFiltrarEleccion } from "../../redux/actions/actElecciones";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
//ACCIONES DEL MODULO



class Elecciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      seleccion: 0,
    };
  }

  componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/votaciones', nombre: 'votaciones' })
    this.obtenerElecciones();
  }

  obtenerElecciones = async (filtros = [{ 'llave': 'estado', 'valor': 1 }]) => {
    let { actualizarDataElecciones, actualizarRenderEleccion, mostrarModalFiltrarEleccion } = this.props;
    let f = await generarFiltros(filtros);
    this.setState({ cargando: true })
    consulta(`api/v1.0/elecciones?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        actualizarDataElecciones(resp);
        actualizarRenderEleccion(true);
      }
      this.setState({ cargando: false })
      mostrarModalFiltrarEleccion(false)
    }
    );
  }

  guardarEleccion = (nombre, fecha_inicio, fecha_fin, votantes) => {
    let { actualizarMensaje, actualizarDataEleccion, mostrarModalAddEleccion, mostrarModalAdmCandidatos } = this.props;
    consulta("api/v1.0/elecciones/crear", { nombre, fecha_inicio, fecha_fin, votantes }, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerElecciones();
            mostrarModalAddEleccion(false);
            actualizarDataEleccion(resp.eleccion)
            mostrarModalAdmCandidatos(true)
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  modificarEleccion = (id, nombre, fecha_inicio, fecha_fin) => {
    let { actualizarMensaje, mostrarModalModEleccion } = this.props;
    consulta(`api/v1.0/elecciones/${id}`, { nombre, fecha_inicio, fecha_fin }, "patch",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerElecciones();
            mostrarModalProlEleccion(false);
            mostrarModalModEleccion(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  validarEleccion = (id, estado) => {
    let { actualizarMensaje, mostrarModalCanEleccion, mostrarModalFinEleccion } = this.props;
    consulta(`api/v1.0/elecciones/${id}/validar`, { estado }, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerElecciones();
            mostrarModalCanEleccion(false);
            mostrarModalFinEleccion(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }



  copiarPortaPapelesLink = (text, container) => {
    let { actualizarMensaje, mostrarModalLinkEleccion } = this.props;
    copiarPortaPapeles(text, container);
    mostrarModalLinkEleccion(false);
    actualizarMensaje({ titulo: 'Link copiado', tipo: 'success', mostrar: true, tiempo: 6000 });
  }

  desactivar_acciones = (fecha, estado) => {
    let fecha_actual = moment().format('YYYY-MM-DD');
    fecha = moment(fecha).format('YYYY-MM-DD');
    if (parseInt(estado) !== 1 || fecha_actual > fecha) return true;
    return false;
  }


  render() {
    let {
      eleccion,
      elecciones,
      modalAdmCandidatos,
      modalModEleccion,
      modalAddEleccion,
      modalCanEleccion,
      modalFinEleccion,
      modalProlEleccion,
      modalLinkEleccion,
      actualizarDataEleccion,
      mostrarModalAddEleccion,
      modalFiltrarElecciones,
      mostrarModalModEleccion,
      mostrarModalCanEleccion,
      mostrarModalFinEleccion,
      mostrarModalProlEleccion,
      mostrarModalAdmCandidatos,
      mostrarModalLinkEleccion,
      mostrarModalFiltrarEleccion,
      candidatos
    } = this.props;
    return (
      <Fragment>
        {!this.state.cargando ?
          <div>
            <Eleccion candidatos={candidatos} eleccion={eleccion} seleccion={this.state.seleccion} seleccionar={(seleccion)=>this.setState({seleccion})} desactivar_acciones={this.desactivar_acciones} mostrarModalAdmCandidatos={mostrarModalAdmCandidatos} modalAdmCandidatos={modalAdmCandidatos} />
            <EleccionLink modalLinkEleccion={modalLinkEleccion} mostrarModalLinkEleccion={mostrarModalLinkEleccion} eleccion={eleccion} copiarPortaPapelesLink={this.copiarPortaPapelesLink} />
            <EleccionesAgregar obtenerValores={obtenerValores} modalAddEleccion={modalAddEleccion} mostrarModalAddEleccion={mostrarModalAddEleccion} guardarEleccion={this.guardarEleccion} />
            <EleccionesModificar modalModEleccion={modalModEleccion} mostrarModalModEleccion={mostrarModalModEleccion} modificarEleccion={this.modificarEleccion} eleccion={eleccion} />
            <EleccionesListar desactivar_acciones={this.desactivar_acciones} seleccionar={(seleccion)=>this.setState({seleccion})} refrescar={() => this.obtenerElecciones()} mostrarModalLinkEleccion={mostrarModalLinkEleccion} mostrarModalAdmCandidatos={mostrarModalAdmCandidatos} mostrarModalAddEleccion={mostrarModalAddEleccion} mostrarModalModEleccion={mostrarModalModEleccion} mostrarModalCanEleccion={mostrarModalCanEleccion} mostrarModalFinEleccion={mostrarModalFinEleccion} mostrarModalProlEleccion={mostrarModalProlEleccion} mostrarModalFiltrarEleccion={mostrarModalFiltrarEleccion} actualizarDataEleccion={actualizarDataEleccion} elecciones={elecciones} eleccion={eleccion} />
            <EleccionesFiltrar modalFiltrarElecciones={modalFiltrarElecciones} mostrarModalFiltrarEleccion={mostrarModalFiltrarEleccion} filtrar={(filtros) => this.obtenerElecciones(filtros)} limpiar={() => { }} />
            <ConfirmarAccion ejecutarAccion={(id) => this.validarEleccion(id, 2)} mostrarModalConfAccion={mostrarModalCanEleccion} modalConAccion={modalCanEleccion} dato={eleccion} titulo={`¿ Cancelar Elección ?`} mensaje1={`El proceso de elección para "${eleccion.nombre}" será cancelado, `} />
            <ConfirmarAccion ejecutarAccion={(id) => this.validarEleccion(id, 3)} mostrarModalConfAccion={mostrarModalFinEleccion} modalConAccion={modalFinEleccion} dato={eleccion} titulo={`¿ Cerrar Elección ?`} mensaje1={`El proceso de elección para "${eleccion.nombre}" será cerrado y no podrá modificar más esta solicitid`} />
            <EleccionesProlongar modalProlEleccion={modalProlEleccion} mostrarModalProlEleccion={mostrarModalProlEleccion} prolongarEleccion={this.modificarEleccion} eleccion={eleccion} />
          </div> :
          <TareasTerminadas mensaje='Cargando..' cargando={true} marginTop='7%' imagen={emma_w} widthImg="7%" />
        }
      </Fragment>
    )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { eleccion, renderElecciones, elecciones, modalModEleccion, modalAddEleccion, modalCanEleccion, modalFinEleccion, modalProlEleccion, modalAdmCandidatos, modalLinkEleccion, modalFiltrarElecciones } = state.redElecciones;
  let { candidatos } = state.redCandidatos;
  return {
    renderElecciones,
    eleccion,
    elecciones,
    modalModEleccion,
    modalAddEleccion,
    modalCanEleccion,
    modalFinEleccion,
    modalProlEleccion,
    modalAdmCandidatos,
    modalLinkEleccion,
    modalFiltrarElecciones,
    candidatos
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  actualizarRenderEleccion,
  actualizarDataEleccion,
  mostrarModalAddEleccion,
  actualizarDataElecciones,
  mostrarModalModEleccion,
  mostrarModalCanEleccion,
  mostrarModalFinEleccion,
  mostrarModalProlEleccion,
  mostrarModalAdmCandidatos,
  mostrarModulo,
  mostrarModalLinkEleccion,
  mostrarModalFiltrarEleccion,
}


//Props del componente
Elecciones.propTypes = {
  //variables
  renderElecciones: PropTypes.bool.isRequired,
  elecciones: PropTypes.array.isRequired,
  modalModEleccion: PropTypes.bool.isRequired,
  modalAddEleccion: PropTypes.bool.isRequired,
  modalCanEleccion: PropTypes.bool.isRequired,
  modalAdmCandidatos: PropTypes.bool.isRequired,
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarRenderEleccion: PropTypes.func.isRequired,
  actualizarDataEleccion: PropTypes.func.isRequired,
  mostrarModalAddEleccion: PropTypes.func.isRequired,
  actualizarDataElecciones: PropTypes.func.isRequired,
  mostrarModalModEleccion: PropTypes.func.isRequired,
  mostrarModalCanEleccion: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalAdmCandidatos: PropTypes.func.isRequired,
  mostrarModalLinkEleccion: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(Elecciones);
