import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBuscar from "../../general/InputBuscar";
import BuscarColegio from "../../general/BuscarColegio";
import { obtenerValoresFiltros } from "../../../global/js/funciones";
import { Id_Estado_visita } from '../helper';
import BuscarPersona from "../../general/BuscarPersona";


class VisitaFiltrar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cargando: true,
			buscarColegio: false,
			Colegio: {
				id: 0,
				nombre: '',
			},
			estado: '',
			estados: [],
			buscarPersona: false,
			persona: { id: 0, nombre: "" },
		}
	}

	// async componentDidMount() {

	// 	this.obtenerGenericas()
	// }

	async componentDidUpdate({ modalAddFiltrosVisita }) {
    let { modalAddFiltrosVisita: modalAddFiltrosVisitaNew } = this.props;
    if(modalAddFiltrosVisita !== modalAddFiltrosVisitaNew){
			if(modalAddFiltrosVisitaNew) await this.obtenerGenericas();
		} 

  }

	async obtenerGenericas() {
		let tipo_C = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': Id_Estado_visita }]);
		this.setState({ estados: tipo_C });
	}

	onSubmit = e => {
		let { Colegio, estado, persona } = this.state
		let { filtrar, mostrarModalFiltrosVisita, actualizarMensaje } = this.props;
		let filtros = [];
		if (Colegio !== '' && Colegio.id !== 0) filtros.push({ 'llave': 'colegio', 'valor': Colegio.id })
		if (estado !== '') filtros.push({ 'llave': 'estado_actual', 'valor': estado })
		if (persona !== '' && persona.id !== 0) filtros.push({ 'llave': 'responsable', 'valor': persona.id })
		if (filtros.length > 0) {
			mostrarModalFiltrosVisita(false)
			filtrar(filtros);
			this.reiniciarEstado()
		} else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
		e.preventDefault();
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value,
		});
	};

	reiniciarEstado = () => {
		this.setState({
			Colegio: '',
			estado: '',
			persona: '',
		})
	}


	render() {
		let { modalAddFiltrosVisita, mostrarModalFiltrosVisita } = this.props;
		let { Colegio, buscarColegio, estado, buscarPersona, persona, estados } = this.state;
		return (
			<>
				<Dialog fullWidth={true} maxWidth="sm" open={modalAddFiltrosVisita} onClose={() => mostrarModalFiltrosVisita(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalFiltrosVisita} titulo_accion="Cerrar"
						accion={() => mostrarModalFiltrosVisita(false)} />
					<DialogContent className='scroll'>
						<Grid container spacing={0} className='margin_cont' >
							<Grid item xs={12} xl={12} sm={12} md={12} lg={12} style={{ marginBottom: "15px" }}>
								<InputBuscar
									required={false}
									id="colegio"
									label="Colegios"
									placeholder="Clic aquí para buscar el colegio"
									value={Colegio.nombre}
									callback={() => this.setState({ buscarColegio: true })}
								/>
							</Grid>
							<Grid item xs={12} xl={12} sm={12} md={12} lg={12} style={{ marginBottom: "10px" }}>
								<InputBuscar
									id="persona"
									label="Responsable"
									placeholder="Clic aquí para buscar"
									value={persona.nombre}
									callback={() => this.setState({ buscarPersona: true })}
								/>
							</Grid>
							<Grid item xs={12} xl={12} sm={12} md={12} lg={12} style={{ marginBottom: "10px" }}>
								<FormControl className="form-control" required>
									<InputLabel id="demo-simple-select-label">Seleccione el tipo de estado</InputLabel>
									<Select id="estado" value={estado} name='estado' onChange={this.onChange} >
										{estados.map(estado => <MenuItem key={estado.id} value={estado.id}>{estado.nombre}</MenuItem>)}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => mostrarModalFiltrosVisita(false)} />
						<BtnForm texto="FILTRAR" callback={this.onSubmit} />
					</DialogActions>
				</Dialog >
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					fullScreen={window.innerWidth < 600}
					open={buscarPersona}
					onClose={() => this.setState({ buscarPersona: false })}
				>
					<AppBarModal
						titulo={"¡ Buscar Persona !"}
						mostrarModal={() => this.setState({ buscarPersona: false })}
						titulo_accion="CERRAR"
						accion={() => this.setState({ buscarPersona: false })}
					/>
					<DialogContent style={{ padding: "0" }} className="scroll">
						<BuscarPersona
							callback={({
								id,
								primer_nombre,
								primer_apellido,
								segundo_apellido,
							}) =>
								this.setState({
									persona: {
										id,
										nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
									},
									buscarPersona: false,
								})
							}
						/>
					</DialogContent>
					<DialogActions>
						<BtnForm
							texto="CANCELAR"
							callback={() => this.setState({ buscarPersona: false })}
						/>
					</DialogActions>
				</Dialog>

				{/* BUSCAR COLEGIO */}
				<Dialog
					fullWidth={true}
					maxWidth="sm"
					fullScreen={window.innerWidth < 600}
					open={buscarColegio}
					onClose={() => this.setState({ buscarColegio: false })}
				>
					<AppBarModal
						titulo={"¡ Buscar Colegio !"}
						mostrarModal={() => this.setState({ buscarColegio: false })}
						titulo_accion="CERRAR"
						accion={() => this.setState({ buscarColegio: false })}
					/>
					<DialogContent style={{ padding: "0" }} className="scroll">
						<BuscarColegio
							callback={({
								id,
								nombreColegio,
							}) =>
								this.setState({
									Colegio: {
										id,
										nombre: nombreColegio,
									},
									buscarColegio: false,
								})
							}
						/>
					</DialogContent>
					<DialogActions>
						<BtnForm
							texto="CANCELAR"
							callback={() => this.setState({ buscarColegio: false })}
						/>
					</DialogActions>
				</Dialog>
			</>
		)
	}
}

VisitaFiltrar.propTypes = {
	//variables
	modalAddFiltrosVisita: PropTypes.bool.isRequired,
	//funciones
	mostrarModalFiltrosVisita: PropTypes.func.isRequired,
	filtrar: PropTypes.func.isRequired,
}
export default VisitaFiltrar;