export const PROGRAMAS = 5
export const ENTIDADES_FINANCIERAS = 35
export const PROCESOS_CREDITO = 36
export const ESTADOS_FINANCIEROS = 37
export const MOTIVOS_FINANCIEROS = 38
export const TIPOS_FINANCIEROS = 52
export const PAGOS_LEGALIZACION = 53
export const PECUNIARIOS_LEGALIZACION = 54
export const REQUISITOS_CREDITOS = 132
export const CREDITO_DIRECTO_POARA = 130
export const CREDITO_DIRECTO_OCUPACION = 131
export const CODIGO_MODELO_LETRA_CAMBIO = 18281
export const LONGITUD_REQUISITOS = 4
export const OBSERVACIONES_FINANCIEROS = 64
export const SOLICITUDES_DESCONGELAMIENTO = 109
export const ORIGEN_DINERO_DESCONGELAMIENTO = 110
export const MODALIDADES_CONGELAMIENTO = 111
export const REQUISITOS_FINANCIEROS = 112
export const TIPOS_DEVOLUCION = 282
export const ORIGEN_DINEROS_DEVOLUCION = 283
export const CODIGO_ICETEX_DEVOLUCION = 24955
export const CODIGO_ENTIDAD_FINANCIERA_DEVOLUCION = 24957
export const CODIGO_ABONO_DEVOLUCION = 24953
export const RESPUESTAS_FINALIZADO_ESTADO_FINANCIERO = 306
export const TIPOS_SOLICITUDES_ESTADO_FINANCIERO = 307

// DESARROLLO
// export const PROGRAMAS = 5
// export const ENTIDADES_FINANCIERAS = 35
// export const PROCESOS_CREDITO = 36
// export const ESTADOS_FINANCIEROS = 37
// export const MOTIVOS_FINANCIEROS = 38
// export const TIPOS_FINANCIEROS = 52
// export const PAGOS_LEGALIZACION = 53
// export const PECUNIARIOS_LEGALIZACION = 54
// export const REQUISITOS_CREDITOS = 132
// export const CREDITO_DIRECTO_POARA = 130
// export const CREDITO_DIRECTO_OCUPACION = 131
// export const CODIGO_MODELO_LETRA_CAMBIO = 18281
// export const LONGITUD_REQUISITOS = 4
// export const OBSERVACIONES_FINANCIEROS = 64
// export const SOLICITUDES_DESCONGELAMIENTO = 109
// export const ORIGEN_DINERO_DESCONGELAMIENTO = 110
// export const MODALIDADES_CONGELAMIENTO = 111
// export const REQUISITOS_FINANCIEROS = 112
// export const TIPOS_DEVOLUCION = 119
// export const ORIGEN_DINEROS_DEVOLUCION = 120
// export const CODIGO_ICETEX_DEVOLUCION = 24955
// export const CODIGO_ENTIDAD_FINANCIERA_DEVOLUCION = 24957
// export const CODIGO_ABONO_DEVOLUCION = 24953
// export const RESPUESTAS_FINALIZADO_ESTADO_FINANCIERO = 122
// export const TIPOS_SOLICITUDES_ESTADO_FINANCIERO = 121

// DESARROLLO

// export const PROGRAMAS = 5
// export const ENTIDADES_FINANCIERAS = 2052
// export const PROCESOS_CREDITO = 2053
// export const ESTADOS_FINANCIEROS = 2054
// export const MOTIVOS_FINANCIEROS = 2055
// export const TIPOS_FINANCIEROS = 2056
// export const PAGOS_LEGALIZACION = 2057
// export const PECUNIARIOS_LEGALIZACION = 2062 
// export const REQUISITOS_CREDITOS = 2063  
// export const CREDITO_DIRECTO_POARA = 2064 
// export const CREDITO_DIRECTO_OCUPACION = 2065 
// export const CODIGO_MODELO_LETRA_CAMBIO = 2066 
// export const LONGITUD_REQUISITOS = 4 
// export const OBSERVACIONES_FINANCIEROS = 2058
// export const SOLICITUDES_DESCONGELAMIENTO = 2059
// export const ORIGEN_DINERO_DESCONGELAMIENTO = 2060
// export const MODALIDADES_CONGELAMIENTO = 2061
// export const REQUISITOS_FINANCIEROS = 2068 

// export const TIPOS_DEVOLUCION = 2069
// export const ORIGEN_DINEROS_DEVOLUCION = 2070
// export const CODIGO_ICETEX_DEVOLUCION = 3414
// export const CODIGO_ENTIDAD_FINANCIERA_DEVOLUCION = 3418
// export const CODIGO_ABONO_DEVOLUCION = 3416
