import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import { Dialog, DialogContent, DialogActions, } from '@material-ui/core';
import AppBarModal from "../../general/AppBarModal";
import { BtnForm, BtnCancelar, BtnSeleccion } from "../../general/BotonesAccion";
import TareasTerminadas from "../../general/TareasTerminadas";
import ListarDatos from "../../general/ListarDatos";
import { obtenerValoresFiltros } from '../../../global/js/funciones';

import { ASIGNATURAS } from "../Helpers";

// Imagenes
import emma_w from "../../../global/imagenes/emma_w.png";

class BuscarAsignatura extends Component {
    constructor(props) {
        super(props);
        this.state = {
          cargando: false,
          modal_filtro_asignaturas: false,
          asignaturas: [],
        };
    }

    async componentDidUpdate({ modalBuscarAsignatura }) {
        let { modalBuscarAsignatura: modalBuscarAsignaturaNew } = this.props;
        if (modalBuscarAsignaturaNew !== modalBuscarAsignatura) {
            this.setState({ cargando: true });
            await this.obtenerUniversidades();
            this.setState({ cargando: false });
        }
    }

    obtenerUniversidades = async() => {
        let asignaturas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ASIGNATURAS }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({ asignaturas });
    }

    acciones = (data) => {
        let { seleccionadas } = this.props;
            const onClickSeleccionar = (data) => {
            this.props.seleccionar(data)
        }
        const onClickQuitar = (data) => {
            this.props.quitar(data)
        }

        return (
        seleccionadas.map(u => u.id).includes(data.id) ? <BtnCancelar callback = { () => onClickQuitar(data) } /> : <BtnSeleccion callback = { () => onClickSeleccionar(data) } />
    );
    }

    render() {
        let { mostrarModalBuscar, modalBuscarAsignatura } = this.props;
        let { cargando, asignaturas } = this.state;
        return <>
            <Dialog open = { modalBuscarAsignatura } onClose = { mostrarModalBuscar.bind(this, false) } aria-labelledby = "form-dialog-title" fullWidth = { true } maxWidth = "sm">
            <AppBarModal titulo = { '¡ Buscar Asignatura !' } mostrarModal = { mostrarModalBuscar } titulo_accion = "" accion = "" />
            <DialogContent className = 'scroll' style = { { padding: 0 } }>
                {
                cargando ? <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = "7%" /> :
                    <ListarDatos
                    datos = { asignaturas }
                    titulo = 'Lista de Asignaturas'
                    opciones = { true }
                    buscar = { true }
                    filtrar = { true }
                    actfiltrar = { () => this.setState({ modal_filtro_asignaturas: true }) }
                    sub_titulo = 'Asignaturas'
                    id = 'tbl_asignaturas_seleccion'
                    acciones = { row => this.acciones(row) }
                    fila_principal = { ({ nombre }) => nombre.toUpperCase() }
                    filas = { [
                        // { 'id': 'valora', 'nombre': 'País: ' },
                        // { 'id': 'valorb', 'nombre': 'Ciudad: ' },
                        // { 'id': 'valore', 'nombre': 'Sede: ' }
                    ] }
                    />
                }
            </DialogContent>
            <DialogActions>
                <BtnForm texto = "CERRAR" callback = { () => mostrarModalBuscar(false) } />
            </DialogActions>
            </Dialog>
        </>;
    }
}
const mapStateToProps = state => {

}

const mapDispatchToProps = { actualizarMensaje }

BuscarAsignatura.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(BuscarAsignatura);