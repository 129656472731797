import { createStore, combineReducers } from "redux"
import redGenericas from "./reducers/redGenericas"
import redValores from "./reducers/redValores"
import redGlobal from "./reducers/redGlobal"
import redPersonas from "./reducers/redPersonas"
import redElecciones from "./reducers/redElecciones"
import redCandidatos from "./reducers/redCandidatos"
import redVotantes from "./reducers/redVotantes"
import redPracticas from "./reducers/redPracticas"
import redInscripciones from "./reducers/redInscripciones"
import redInternacionalizacion from "./reducers/redInternacionalizacion"
import redGrados from './reducers/redGrados'
import redBecas from './reducers/redBecas'
import redMatriculas from './reducers/redMatriculas'
import redCreditos from './reducers/redCreditos'
import redPortal from './reducers/redPortal'
import redAsistencia from './reducers/redAsistencia'
import redInvitados from './reducers/redInvitados'
//import redMovilidades from './reducers/redMovilidades';
import redValidaciones from './reducers/redValidaciones'
import redRea from './reducers/redRea'
import redTutoria from './reducers/redTutoria'
import redConsultoria from './reducers/redConsultoria'
import redAutodiagnostico from './reducers/redAutodiagnostico'
import redOva from './reducers/redOva'
import redRetos from './reducers/redRetos'
import redPromocion from './reducers/redPromocion'
import redInvitadosVirtual from "./reducers/redInvitadosVirtual"
import redRetoscuc from './reducers/redRetoscuc'
import redInscripcionesPosgrado from "./reducers/redInscripcionesPosgrado"
import redAtencion from "./reducers/redAtencion"
import redCentro from './reducers/redCentro'
import redSisef from './reducers/redSisef';

const reducer = combineReducers({
  redGenericas,
  redValores,
  redGlobal,
  redPersonas,
  redElecciones,
  redCandidatos,
  redVotantes,
  redPracticas,
  redInscripciones,
  redInternacionalizacion,
  redGrados,
  redBecas,
  redMatriculas,
  redCreditos,
  redPortal,
  redAsistencia,
  redInvitados,
  //redMovilidades,
  redValidaciones,
  redRea,
  redTutoria,
  redConsultoria,
  redAutodiagnostico,
  redOva,
  redRetos,
  redInvitadosVirtual,
  redRetoscuc,
  redPromocion,
  redInscripcionesPosgrado,
  redAtencion,
  redCentro,
  redSisef,
})

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store
