import React from 'react'
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "../../personas/generica/Generica";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";

class PracticasModalidadPermisos extends React.Component {

  render() {
    let { coordinador, mostrarModalPermisoModalidad, modalPermisoModalidad } = this.props;
    if (coordinador && coordinador.id > 0) {
      return (
        <Dialog open={modalPermisoModalidad} fullWidth={true} maxWidth='sm' onClose={mostrarModalPermisoModalidad.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Modalidades Asignadas !' mostrarModal={mostrarModalPermisoModalidad} accion={() => mostrarModalPermisoModalidad(false)} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica tipo={10} persona={coordinador} genericaRelacionId={11} titulo="¡ Nueva Modalidad !" tituloInput="Modalidades" tituloEliminar='¿ Eliminar Modalidad ?' />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalPermisoModalidad(false)} />
          </DialogActions>
        </Dialog>
      );
    }
    return '';

  }

}
//Props del componente
PracticasModalidadPermisos.propTypes = {
  //variables
  coordinador: PropTypes.object.isRequired,
  modalPermisoModalidad: PropTypes.bool.isRequired,
  //funciones
  mostrarModalPermisoModalidad: PropTypes.func.isRequired,

}


export default PracticasModalidadPermisos;


