import React, { Component } from "react";
import { connect } from "react-redux";

// Funciones Globales
import { Dialog, DialogContent } from '@material-ui/core'
import { consulta,  } from "../../global/js/funciones";
import AppBarModal from "../general/AppBarModal";

// Funciones -Componentes del Módulo
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { MenuEstudiantes } from "./invitadosVirtual/MenuLateral";
import { MenuSeleccionar } from "./invitadosVirtual/MenuSeleccionar";
import { OpenNewModal } from "./invitadosVirtual/OpenNewModal";
import { PintarProcesos, InvVirtMenu } from "./invitadosVirtual/PintarProcesos";
import SolicitudDetalle from "./invitadosVirtual/SolicitudDetalle";
import TareasTerminadas from "../general/TareasTerminadas";
import ListarDatos from "../general/ListarDatos";
import { BtnDetalle } from "../general/BotonesAccion";

import { showModalDetalle } from "../../redux/actions/actInvitadosVirtuales";

// IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import moment from 'moment'


const API_URI = `api/v1.0/invitadosVirtual/`;

class InvitadosVirtualEstudiante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "miproceso",
      asignaturas: [],
      procesos: [],
      lineaEstados: [],
      cargando: true,
      id_estudiante: 0,
      historial: [],
      procesoActual: null,
      notificaciones: [],
      anchorEl: null,
      informacionPrincipal: null,
      cargandoGuardar: false,
      openModalNuevo: false,
      activeStep: 0,
      celular: null,
      telefono: null,
      correo_personal: null,
      programa: "",
      programa_selec: "",
      programas: [],
      current_user: null,
      abrirProgramas: null,
      cantidad: 0,
      datos_solicitud: [],
      periodo: null,
      estado_ini: 0,
      seleccion_menu: "detalle",
      valorc: 0,
      openModalDeta: false,
      all_request: [],
      openDetaSolicitud: false,
      data_solicitud: [],
      informacionCards: [],
      inscripcion_seleccionada: null,
      historial: []
    };
  }

  handleSubmit = () => {
    this.guardar_inscripcion();
  };

  componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: "/invitadosVirtual", nombre: "Invitados Virtual" });
    console.log('ento')
    this.getInformacionPrincipal();
    // this.obtenerCards();
    // this.obtenerDatosIniciales();
  }

  async componentDidUpdate({modalDetInvVir }, { seleccion, validacionActual }) {
    let { seleccion: seleccionNew, validacionActual: validacionActualNew } = this.state;
    let { modalDetInvVir: modalDetBecaNew, vistaDetalle } = this.props;

    if (validacionActual !== validacionActualNew && seleccionNew === 'miproceso' && validacionActualNew != 0) this.obtenerDataProcesoActual(validacionActualNew);
    if (seleccion !== seleccionNew) {
      if(seleccionNew === 'miproceso') this.getInformacionPrincipal();
      else if (seleccionNew === 'nuevo'){
        this.getInformacionPrincipal();
        this.obtenerCards();
      }else if(seleccionNew === "historial"){
        this.historialEstudiante(); // cambiar
      }
    }
    if (modalDetInvVir !== modalDetBecaNew) this.setState({ seleccion: modalDetBecaNew ? vistaDetalle : '' })
  }

  async historialEstudiante() {
    this.setState({ cargando: true })
    let historial = await this.obtenerSolicitudes();
    this.setState({ historial, cargando: false })
  }

  async obtenerSolicitudes() {
    return new Promise(resolve => {
      consulta(`api/v1.0/invitadosVirtual/listar`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  obtenerProgramas() {
    const { current_user } = this.state
    return new Promise(resolve => {
        consulta(`api/v1.0/personas/${current_user}/programas`, null, null, (error, estado, resp) => {
            let programas = resp.map(item => item.relacion);
            this.setState({ programas })
            this.setState({ programa: programas[0].codigo })
            this.setState({ programa_selec: programas[0].id })
            resolve(true)
        })
    })
  }

  async obtenerInformacionSolicitud() {
    // this.setState({ cargando: true });
    let datos = await this.getInfoSolicitud();

    if(datos[0]){
      this.setState({
        datos_solicitud: datos[0],
        all_request: datos,
        valorc: datos[0]["estado_solicitud"]["valorc"],
        // cargando: false
      });
    }
  }

  abrirDetalle = async () => {
    let { showModalDetalle } = this.props;
    let { seleccion_menu, openModalDeta } = this.state;
    return (
      <>
        <Dialog open={openModalDeta} maxWidth="sm" fullWidth={true}>
          <AppBarModal titulo='¡ Detalle Solicitud !' mostrarModal={showModalDetalle} titulo_accion='' />
          <InvVirtMenu sele={seleccion_menu} seleccionar={seleccion_menu => this.setState({ seleccion_menu })} />
          <DialogContent style={{ padding: 0 }} className='scroll' >
            {/* {this.pintar()} */}
          </DialogContent>
          {/* <DialogActions>
            {datos[0] && datos[0].volante ? <Link href={`${datos[0].volante}`} target="_blank"><BtnForm texto="DESCARGAR VOLANTE" callback={() => { }} color="#01579b" /></Link> : ''}
            <BtnForm texto="Cerrar" callback={() => showModalDetalle(false)} />
          </DialogActions> */}
        </Dialog>
      </>
    );
	};

  obtenerColor = (codigo) => {
    let color = 'white';

    const colores = {
      'Est_IVir_Ini': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Est_IVir_Trami': { 'backgroundColor': '#ffc107', color },
      'Est_IVir_Asig_Cursar': { 'backgroundColor': '#01519B', color },
      'Est_IVir_Neg': { 'backgroundColor': '#b71c1c', color },
      'Est_IVir_Fin': { 'backgroundColor': '#009688', color },
      'Tod_Inv_Vir': { 'backgroundColor': '#0d47a1', color },
    };

    return colores[codigo];
  };

  vista = () => {
    let { informacionCards, data_solicitud, openDetaSolicitud, all_request, seleccion, informacionPrincipal,cargandoGuardar, cargando, cantidad, datos_solicitud, valorc, historial } = this.state;
    switch (seleccion) {
      case "miproceso":
        return PintarProcesos(cargando, cantidad, this.cambiarOpcion, datos_solicitud, valorc, this.abrir_modal_info);

      case "nuevo":
        return( (cantidad === 0) ?
          <MenuSeleccionar
            openModalNuevo={(resp) => this.setState({ openModalNuevo: resp })}
            informacionPrincipal={informacionPrincipal}
            cargandoGuardar={cargandoGuardar}
            cards={informacionCards}
            inscripcion_seleccionada={(resp) => this.setState({inscripcion_seleccionada: resp})}
          /> : <div onClick={() => this.setState({ seleccion: 'miproceso' })} className='pointer'><TareasTerminadas mensaje='Hola, solo puedes tener un proceso activo en el periodo. Clic aquí para ver tu proceso actual' marginTop='7%' imagen={emma_s} widthImg="7%" /></div >
          
          )

      case "historial":
        return (
          <div>
            <SolicitudDetalle
              data={data_solicitud}
              openModal={openDetaSolicitud}
              closeModal={(openDetaSolicitud) => this.setState({ openDetaSolicitud })}
            />
            <ListarDatos
              id="tbl_clases_invitados"
              titulo="Lista Solicitudes Invitados"
              datos={historial}
              opciones={true}
              buscar={true}
              acciones={(row) => <div><BtnDetalle callback={() => this.setState({ data_solicitud: row, openDetaSolicitud: true }) } color='#01579b' texto='Abrir' /></div>}
              color_avatar={({ estado_solicitud: { codigo } }) => this.obtenerColor(codigo)}
              avatar={({ estado_solicitud }) => `${estado_solicitud["valora"]}`}
              fila_principal={(row) =>
                `${row["persona"]["primer_nombre"]} ${row["persona"]["segundo_nombre"]} ${row["persona"]["primer_apellido"]} ${row["persona"].segundo_apellido}`.toUpperCase()
              }
              filas={[
                {
                  mostrar: ({ estado_solicitud }) =>
                    `Estado: ${estado_solicitud["nombre"]}`,
                  id: "estado_sol",
                  enLista: true,
                },
                {
                  mostrar: ({ fecha_registra }) =>
                    moment(fecha_registra).format("YYYY-MM-DD, h:mm a"),
                  id: "estado_sol",
                  enLista: true,
                },
              ]}
            />
          </div>
        );
        // return this.pintarHistorial();
      default:
        break;
    }
  };

  cambiarOpcion = (seleccion) => {
    this.setState({ seleccion: seleccion });
  };

  abrir_modal_info = (dato) => {
    this.setState({ openModalDeta: dato });
    this.abrirDetalle();
  };

  async obtenerInformacionEstudiante() {
    // this.setState({ cargando: true });
    let { celular, telefono, correo_personal, id, periodo, estado_ini } = await this.obtenerInfo();
    this.setState({
      celular: celular,
      telefono: telefono,
      correo_personal: correo_personal,
      cargando: false,
      current_user: id,
      periodo: periodo,
      estado_ini: estado_ini
    });
    await this.obtenerProgramas();
    // this.setState({ cargando: false });
  }

  // obtener info del Estudiante
  async obtenerInfo() {
    return new Promise((resolve) => {
      consulta(`${API_URI}obtenerInfo`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }
  async getInfoSolicitud() {
    return new Promise((resolve) => {
      consulta(`${API_URI}getInfoSolicitud`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  async obtenerSolicitudesPorEstudiante() {
    return new Promise((resolve) => {
      consulta(`${API_URI}obtenerSolicitudesPorEstudiante`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  obtenerCards = () => {
    this.setState({cargando: true});
    consulta( `${API_URI}informacionCards`, null, null, (error, estado, informacionCards) => {
      if (!error && estado === 200) {
        console.log(informacionCards);
        this.setState({ informacionCards });
      } else
        actualizarMensaje({
          titulo: "Hubo un error, inténtalo más tarde o contacta con el administrador", tipo: "info", mostrar: true, tiempo: 6000,
        });
      this.setState({ cargando: false });
    });
  }

  getInformacionPrincipal = () =>  {
    this.setState({ cargando: true });
    const { actualizarMensaje } = this.props;
    consulta( `${API_URI}informacion_principal`, null, null, async (error, estado, informacionPrincipal) => {
      if (!error && estado === 200) {
        this.setState({ cantidad: informacionPrincipal["cantidad"] });
        this.setState({ informacionPrincipal });
        if(informacionPrincipal["cantidad"] === 0) await this.obtenerInformacionEstudiante();
        if(informacionPrincipal["cantidad"] > 0) await this.obtenerInformacionSolicitud();
      } else
        actualizarMensaje({
          titulo:
            "Hubo un error, inténtalo más tarde o contacta con el administrador",
          tipo: "info",
          mostrar: true,
          tiempo: 6000,
        });
      this.setState({ cargando: false });
    });
  };

  guardar_inscripcion = () => {
    const { actualizarMensaje } = this.props;
    let { celular, telefono, correo_personal, programa, current_user, programa_selec, periodo, estado_ini, inscripcion_seleccionada } = this.state;

    if (!telefono || !celular || !correo_personal || !programa)
      actualizarMensaje({
        titulo: "Debes diligenciar todos los campos para poder continuar",
        tipo: "info",
        mostrar: true,
        tiempo: 6000,
      });
    else{
      this.setState({ cargandoGuardar: true });
      let data = { celular, telefono, correo_personal, programa: programa_selec, persona : current_user, persona_registra: current_user, estado_solicitud : estado_ini, periodo, tipo_solicitud: inscripcion_seleccionada  };
  
      let tipo = "";
      let titulo = "";
      consulta(`${API_URI}guardar_inscripcion`, data, "post", (error, estado, resp) => {
        if (!error && estado === 200) {
          tipo = "success";
          titulo = resp.titulo;
        } else {
          tipo = "info";
          titulo = resp.titulo || "Ha ocurrido un error";
        }
        this.setState({ cargandoGuardar: false, openModalNuevo: false });
        this.setState({ seleccion: "miproceso" });
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 10000 });
      });
    }
      

  };


  render() {
    let {
      seleccion,
      openModalNuevo,
      activeStep,
      celular,
      telefono,
      correo_personal,
      cargando,
      programas,
      abrirProgramas,
      programa,
      openModalDeta,
      datos_solicitud,
    } = this.state;
    return (
      <div>
        <MenuEstudiantes
          sele={seleccion}
          seleccionar={(seleccion) => this.cambiarOpcion(seleccion)}
        />
        <div className="contenido_modulo">
          <SolicitudDetalle data={datos_solicitud} openModal={openModalDeta} closeModal={openModalDeta => this.setState({ openModalDeta })}/>
          <OpenNewModal
            openModalNuevo={openModalNuevo}
            activeStep={activeStep}
            closeModalNuevo={() => this.setState({ openModalNuevo: false })}
            celular={celular}
            telefono={telefono}
            correo_personal={correo_personal}
            cargando={cargando}
            handleSubmit={this.handleSubmit}
            onChange={this.onChange}
            programas={programas}
            abrirProgramas={abrirProgramas}
            programa={programa}
          />
          {!cargando ? (
            this.vista()
          ) : (
            <TareasTerminadas
              mensaje="Cargando.."
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { mostrarModulo,  actualizarMensaje, showModalDetalle, };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitadosVirtualEstudiante);
