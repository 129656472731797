import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ID_PRE2, OPCION_OTRO__ENCUESTA_AUTOGESTION_ESTUDIANTIL, RESPUESTAS_MULTI_RADIO_BUTTONS, OPCION_SI_ENCUESTA_AUTOGESTION_ESTUDIANTIL,ID_PRE1,OPCION_MINORIAS } from "./helper";
import ItemComponent from './ItemComponent'
const PintarRespuestas = ({ pregunta, respuestas, handleChange }) => {
  switch (pregunta.tipo) {
    case "1":
      const repuestaMultiRadioButtonsFilter =
        pregunta.id === ID_PRE2 || pregunta.id === ID_PRE1
          ? RESPUESTAS_MULTI_RADIO_BUTTONS.filter((item) => item.pre === "1")
          : RESPUESTAS_MULTI_RADIO_BUTTONS.filter((item) => item.pre === "2");

      return (
        <>
          <TableContainer component={Paper} variant="outlined">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  {repuestaMultiRadioButtonsFilter.map((item) => (
                    <TableCell key={item.id} align="right">
                      <Typography variant="body2">{item.nombre}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {respuestas?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        <ItemComponent item={item}/>
                      </Typography>
                    </TableCell>
                    {repuestaMultiRadioButtonsFilter.map((rbItem) => {
                      const checked =
                        pregunta.valor?.find((i) => i.id === item.id)?.valor === rbItem.id;
                      return (
                        <TableCell key={rbItem.id} align="right">
                          <Radio
                            checked={checked}
                            onChange={(e) => handleChange(pregunta, e, item)}
                            value={rbItem.id}
                            name={`radio-${item.id}`}
                            id={`radio-${item.id}-${rbItem.id}`}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {
            (pregunta.valor.find((item) => item.id === OPCION_OTRO__ENCUESTA_AUTOGESTION_ESTUDIANTIL)?.valor === "1") ? (
              <></>
            ) :
              (pregunta.valor.find((item) => item.id === OPCION_OTRO__ENCUESTA_AUTOGESTION_ESTUDIANTIL) &&
                (<TextField
                  required
                  value={pregunta.valor.find((item) => item.id === OPCION_OTRO__ENCUESTA_AUTOGESTION_ESTUDIANTIL)?.valor || ""}
                  onChange={(e) =>
                    handleChange(pregunta, e, { id: OPCION_OTRO__ENCUESTA_AUTOGESTION_ESTUDIANTIL })
                  }
                  label="Escriba su respuesta"
                  id="outlined-size-small"
                  name={`otro ${pregunta.nombre}`}
                />
                )
              )
          }
          {
            (pregunta.valor.find((item) => item.id === OPCION_MINORIAS)?.valor === "1") ? (
              <></>
            ) :
              (pregunta.valor.find((item) => item.id === OPCION_MINORIAS) &&
                (<TextField
                  required
                  value={pregunta.valor.find((item) => item.id === OPCION_MINORIAS)?.valor || ""}
                  onChange={(e) =>
                    handleChange(pregunta, e, { id: OPCION_MINORIAS })
                  }
                  label="Escriba su respuesta"
                  id="outlined-size-small"
                  name={`otro ${pregunta.nombre}`}
                />
                )
              )
          }
        </>
      );
    case "2":
      return (
        <>
          {respuestas?.map((item) => (
            <FormControlLabel
              value={item.id}
              control={<Radio />}
              checked={parseInt(pregunta.valor[0]?.id) === item.id}
              onChange={(e) => handleChange(pregunta, item)}
              label={item.nombre}
              key={item.id}
            />
          ))}
          {pregunta.valor[0]?.id === OPCION_SI_ENCUESTA_AUTOGESTION_ESTUDIANTIL && (
            <TextField
              required
              value={pregunta.valor[0]?.valor}
              onChange={(e) =>
                handleChange(pregunta, pregunta.valor[0], e.target.value)
              }
              label="Escriba su respuesta"
              id="outlined-size-small"
              name={`otro ${pregunta.nombre}`}
            />
          )}
        </>
      );
    case "3":
      return (
        <FormGroup>
          {respuestas?.map((item) => (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  checked={
                    pregunta.valor.find((i) => i.id === item.id)?.valor ?? false
                  }
                  onChange={(e) => handleChange(pregunta, e, item)}
                  name={item.nombre}
                />
              }
              label={
                <span>
                  <ItemComponent item={item}/>
                </span>
              }
            />
          ))}
        </FormGroup>
      );
    case "4":
      return (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={pregunta.valor.id}
          onChange={(e) => handleChange(pregunta, e.target.value)}
        >
          {respuestas?.map((item) => (
            <MenuItem value={item}>{item.nombre}</MenuItem>
          ))}
        </Select>
      );
    default:
      return <></>;
  }
};

export default PintarRespuestas;
