const MODAL_ADD_PERSONA = 'MODAL_ADD_PERSONA'
const MODAL_MOD_PERSONA = 'MODAL_MOD_PERSONA'
const MODAL_EDIT_PASS = 'MODAL_EDIT_PASS'
const MODAL_DEL_PERSONA = 'MODAL_DEL_PERSONA'
const MODAL_DETALLE_PERSONA = 'MODAL_DETALLE_PERSONA'
const ACT_DATA__PERSONA = 'ACT_DATA__PERSONA'
const ACT_DATA__PERSONAS = 'ACT_DATA__PERSONAS'
const ACT_RENDER_PERSONAS = 'ACT_RENDER_PERSONAS'
const PROGRAMAS = 'PROGRAMAS'


const mostrarModalAddPersona = show => {
  return {
    type: MODAL_ADD_PERSONA,
    show,
  }
}

const setProgramas = programas => {
  return {
    type: PROGRAMAS,
    programas,
  }
}

const mostrarModalDetallePersona = show => {
  return {
    type: MODAL_DETALLE_PERSONA,
    show,
  }
}

const mostrarModalModPersona = show => {
  return {
    type: MODAL_MOD_PERSONA,
    show,
  }
}

const mostrarModalDelPersona = show => {
  return {
    type: MODAL_DEL_PERSONA,
    show,
  }
}

const mostrarModalCambiarPassword =  show => {
  return {
    type: MODAL_EDIT_PASS,
    show
  }
}

const actualizarDataPersona = persona => {
  return {
    type: ACT_DATA__PERSONA,
    persona,
  }
}


const actualizarDataPersonas = personas => {
  return {
    type: ACT_DATA__PERSONAS,
    personas,
  }
}

const actualizarRenderPersona = render => {
  return {
    type: ACT_RENDER_PERSONAS,
    render,
  }
}




module.exports = {
  mostrarModalAddPersona,
  mostrarModalModPersona,
  mostrarModalDelPersona,
  mostrarModalCambiarPassword,
  actualizarDataPersona,
  actualizarDataPersonas,
  actualizarRenderPersona,
  mostrarModalDetallePersona,
  setProgramas,
  MODAL_DETALLE_PERSONA,
  MODAL_ADD_PERSONA,
  MODAL_MOD_PERSONA,
  MODAL_EDIT_PASS,
  MODAL_DEL_PERSONA,
  ACT_DATA__PERSONA,
  ACT_DATA__PERSONAS,
  ACT_RENDER_PERSONAS,
  PROGRAMAS,
}
