import { consulta } from "../../../global/js/funciones";

export const createApi = (url, acceptedResources) => {
  const proxyGetter = (target, prop) => {
    return (id, queryParams, data) => {
      if (!acceptedResources.includes(prop))
        return Promise.reject({ error: `Resource ${prop} not accepted` })
      
      return new Promise(resolve => {
        consulta(`${url}/${prop}/${id ?? ''}`, data, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null)
        })
      })
    }
  }

  return new Proxy({}, { get: proxyGetter })
}