import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppBarModal from '../../general/AppBarModal';
import { TableRow, TableCell, TableBody, Table,Dialog, DialogContent,DialogActions, Grid} from '@material-ui/core';
import moment from 'moment'
import TareasTerminadas from '../../general/TareasTerminadas'
import emma_w from '../../../global/imagenes/emma_w.png';
import {CentroMenu} from "./CentroConciliacionMenu";
import { api, consulta} from "../../../global/js/funciones";
import ListarDatos from "./../../general/ListarDatos";
import {BtnDetalle, BtnForm } from "../../general/BotonesAccion";


class DetalleCentro extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      seleccion: 'detalle',
      datos:[],
      id:'',
      id_consultas_juridicas:'',
      tipo_identificacion:'',
      identificacion:'',
      primer_nombre:'',
      primer_apellido:'',
      segundo_apellido:'',
      telefono:'',
      departamento:'',
      ciudad:'',
      barrio:'',
      direccion:'',
      comunidad:'',
      genero:'',
      discapacidad:'',
      hechos:'',
      pretensiones:'',
      correo:'',
      fecha:'',
      programa:'',
      documentos:[],
      estados: [],
      tipo_consultoria:'',
    }
  }

  componentDidUpdate({modalDetalleCentro},{ seleccion }) {
    let { modalDetalleCentro: modalDetalleCentroNew, cargar } = this.props;
    if (modalDetalleCentro !== modalDetalleCentroNew && cargar) {
      this.setState({ cargando: true, cargar:false })
      this.obtenerDatos()
      this.obtenerAdjuntos()
      this.obtenerEstados()
    }
    let { seleccion: seleccionNew } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'adjunto')this.obtenerAdjuntos();
      else if (seleccionNew === 'detalle')this.obtenerDatos();
      else if (seleccionNew === 'estado') this.obtenerEstados();
    } 
  } 

  async obtenerSolicitud() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    return new Promise(resolve => {
       consulta(`api/v1.0/centro/detalle/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerAdjuntos() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    consulta(`api/v1.0/centro/adjuntos/${f}?`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp });
    })
  }

  async obtenerEstados() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    consulta(`api/v1.0/centro/estados/detalle/${f}?`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  async obtenerDatos(){
    let datos = await this.obtenerSolicitud();
    this.setState({
      id:datos[0].id,
      id_consultas_juridicas:datos[0].id_consultas_juridicas,
      tipo_identificacion:datos[0].solicitante.tipo_identificacion.nombre,
      identificacion:datos[0].solicitante.identificacion,
      primer_nombre:datos[0].solicitante.primer_nombre,
      primer_apellido:datos[0].solicitante.primer_apellido,
      segundo_apellido:datos[0].solicitante.segundo_apellido,
      telefono:datos[0].solicitante.telefono,
      departamento:datos[0].solicitante.departamento ? datos[0].solicitante.departamento.nombre:'',
      ciudad:datos[0].solicitante.ciudad ? datos[0].solicitante.ciudad.nombre:'',
      barrio:datos[0].solicitante.lugar_residencia,
      direccion:datos[0].solicitante.direccion,
      comunidad:datos[0].solicitante.comunidad ? datos[0].solicitante.comunidad.nombre:'',
      genero:datos[0].solicitante.genero ? datos[0].solicitante.genero.nombre:'',
      discapacidad:datos[0].solicitante.discapacidad === '0'? 'NO': 'SI',
      area:datos[0].area.nombre,
      hechos:datos[0].hechos,
      pretensiones:datos[0].Pretensiones,
      correo:datos[0].solicitante.correo,
      fecha:datos[0].fecha_registro,
      programa:datos[0].programa?datos[0].programa.nombre:'',
      anho:datos[0].anho?datos[0].anho:'',
      cargando: false, 
      cargar: false,
      tipo_consultoria: datos[0].tipo.nombre,
    })
  }

  pintarDetalle =() =>{
    let {tipo_identificacion,
      identificacion,
      primer_nombre,
      primer_apellido,
      segundo_apellido,
      telefono,
      departamento,
      ciudad,
      barrio,
      direccion,
      comunidad,
      genero,
      discapacidad,
      fecha,
      anho,
      programa,
      area,
      correo,
      id,
      id_consultas_juridicas} = this.state
    
    let fecha_registro = moment(fecha).format('YYYY-MM-DD, h:mm a');
    return (
      <Table>
        <TableBody>
        <TableRow key={16}>
            <TableCell component="th" scope="row">Id</TableCell>
            <TableCell align="left">{`Radicado CC-${id}`}</TableCell>
          </TableRow>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Solicitante</TableCell>
            <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Tipo Identificacion</TableCell>
            <TableCell align="left">{tipo_identificacion}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Identificacion</TableCell>
            <TableCell align="left">{identificacion}</TableCell>
          </TableRow>
          { correo == 'consultario'+identificacion+'@cuc.edu.co'?
          <TableRow key={4}>
            <TableCell component="th" scope="row">Correo</TableCell>
            <TableCell align="left">No ingreso correo</TableCell>
          </TableRow>:
          <TableRow key={4}>
            <TableCell component="th" scope="row">Correo</TableCell>
            <TableCell align="left">{correo}</TableCell>
          </TableRow>
          }
          <TableRow key={5}>
            <TableCell component="th" scope="row">Telefono</TableCell>
            <TableCell align="left">{telefono}</TableCell>
          </TableRow>
          <TableRow key={6}>
            <TableCell component="th" scope="row">Barrio</TableCell>
            <TableCell align="left">{barrio}</TableCell>
          </TableRow>
          <TableRow key={7}>
            <TableCell component="th" scope="row">Direccion</TableCell>
            <TableCell align="left">{direccion}</TableCell>
          </TableRow>
          <TableRow key={8}>
            <TableCell component="th" scope="row">Departamento</TableCell>
            <TableCell align="left">{departamento}</TableCell>
          </TableRow>
          <TableRow key={9}>
            <TableCell component="th" scope="row">Ciudad</TableCell>
            <TableCell align="left">{ciudad}</TableCell>
          </TableRow>
          <TableRow key={10}>
            <TableCell component="th" scope="row">Comunidad</TableCell>
            <TableCell align="left">{comunidad}</TableCell>
          </TableRow>
          
          <TableRow key={11}>
            <TableCell component="th" scope="row">Genero</TableCell>
            <TableCell align="left">{genero}</TableCell>
          </TableRow>
          <TableRow key={12}>
            <TableCell component="th" scope="row">Discapacidad</TableCell>
            <TableCell align="left">{discapacidad}</TableCell>
          </TableRow>
          <TableRow key={12}>
            <TableCell component="th" scope="row">Area</TableCell>
            <TableCell align="left">{area}</TableCell>
          </TableRow>
          <TableRow key={17}>
            <TableCell component="th" scope="row">Id asociado consultas juridicas</TableCell>
            <TableCell align="left">{`Radicado SC-${id_consultas_juridicas}`}</TableCell>
          </TableRow>
          {programa !== '' && (
          <TableRow key={13}>
            <TableCell component="th" scope="row">Programa de Egreso</TableCell>
            <TableCell align="left">{programa}</TableCell>
          </TableRow>
          )}
          {anho !== '' && (
          <TableRow key={14}>
            <TableCell component="th" scope="row">Año de Egreso</TableCell>
            <TableCell align="left">{anho}</TableCell>
          </TableRow>
          )}
          <TableRow key={15}>
            <TableCell component="th" scope="row">Fecha Registro</TableCell>
            <TableCell align="left">{fecha_registro}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
        )
  }

  pintarDetalleHechos =() =>{
    let { hechos, pretensiones, tipo_consultoria } = this.state
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Tipo Asesoría</TableCell>
            <TableCell align="left">{tipo_consultoria}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Hechos</TableCell>
            <TableCell align="left">{hechos}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Pretensiones</TableCell>
            <TableCell align="left">{pretensiones}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
        )
  }

  pintarDetalleAdjuntos =() =>{
    let { documentos } = this.state;
    const acciones = ({ archivo }) => {
			let detalle = (
				<a href={`${api}${archivo}`} target="_blank" rel="noopener noreferrer" className="sin_decorador">
					<BtnDetalle callback={() => {}} color="#01579b" texto="Abrir" />
				</a>
			);
			return <div>{detalle}</div>;
		};

    return (
        <ListarDatos 
          datos={documentos}
          cargando={this.state.cargando}
          id="tbl_adjuntos_centro"
          avatar={({ requisito:{nombre} }) => nombre.charAt(0)}
          acciones={(data) => acciones(data)}
          color_avatar={() => ({ backgroundColor: '#f5f5f5', color: '#757575' })}
          fila_principal={({ requisito: {nombre}}) => nombre}
          filas={[
            { 'id': 'nombre_archivo', 'mostrar': ({ nombre_archivo }) => nombre_archivo},
            {
              id: 'fecha_registro',
              mostrar: ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
              nombre: 'Fecha Registro : '
            },
          ]}
        />
    )
  }


  pintarDetalleEstado =() =>{
      let { estados } = this.state
      const obtenerColor = (estado) => {
        let color = 'white';
        const colores = {
          'Est_Centro_Asig': { 'backgroundColor': '#9315b8', color },
          'Est_Centro_Aprob': { 'backgroundColor': '#009688', color },
          'Est_Centro_AsigCar': { 'backgroundColor': '#n76c1c', color },
          'Est_Centro_Nega': { 'backgroundColor': '#b71c1c', color },
          'Est_Centro_Agen_audiencia_con': { 'backgroundColor': '#FFFF00', color },
          'Est_Centro_Red_Citaci': { 'backgroundColor': '#800080', color },
          'Est_Centro_Aprob_citaci': { 'backgroundColor': '#008000', color },
          'Est_Centro_Negar_Citacio': { 'backgroundColor': '#8B0000', color },
          'Est_Centro_Entre_Citaci': { 'backgroundColor': '#00FF00', color },
          'Est_Centro_CartaFir': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
          'Est_Centro_Asiste_Lugar': { 'backgroundColor': '#00008B', color },
          'Est_Centro_Llegan_Acuerdo': { 'backgroundColor': '#33FFA5', color },
          'Est_Centro_No_llegan_Acuerdo': { 'backgroundColor': '#51254C', color },
          'Est_Centro_Suspender_Audiencia': { 'backgroundColor': '#EAEBBA', color },
          'Est_Centro_Uno_No_Asiste_Audiencia': { 'backgroundColor': '#6C3206', color },
          'Est_Centro_Ninguno_Asiste_Audiencia': { 'backgroundColor': '#F00BA4', color },
          'Est_Centro_Redactar_Acta_Concili': { 'backgroundColor': '#33FFA5', color },
          'Est_Centro_Redactar_Cons_No_Acuerdo': { 'backgroundColor': '#51254C', color },
          'Est_Centro_Entr_Info_Suspen': { 'backgroundColor': '#EAEBBA', color },
          'Est_Centro_Agendar_Conti_Audien': { 'backgroundColor': '#EAEBBA', color },
          'Est_Centro_Despues_dili_continu_audi': { 'backgroundColor': '#EAEBBA', color },
          'Est_Centro_Verificar_Notifi_Partes': { 'backgroundColor': '#6C3206', color },
          'Est_Centro_Esperar_Excusas': { 'backgroundColor': '#6C3206', color },
          'Est_Centro_Usu_Cons_Inasis': { 'backgroundColor': '#6C3206', color },
          'Est_Centro_Agendar_Nuev_Audi': { 'backgroundColor': '#6C3206', color },
          'Est_Centro_Eperar_Dias_Reci_Excu': { 'backgroundColor': '#F00BA4', color },
          'Est_Centro_Soli_nueva_Audiencia': { 'backgroundColor': '#F00BA4', color },
          'Est_Centro_Aprobar_Resul_Conciliato': { 'backgroundColor': '#0CE3BC', color },
          'Est_Centro_Recoger_Firmas_Requi': { 'backgroundColor': '#4D4C04', color },
          'Est_Centro_Carpeta_Orga': { 'backgroundColor': '#2B021E', color },
          'Est_Centro_Revision_Final_Carp': { 'backgroundColor': '#3C663F', color },
          'Est_Centro_Asig_Num_Radica': { 'backgroundColor': '#B87A07', color },
          'Est_Centro_Digitalizar_Car_Fisi': { 'backgroundColor': '#6A552E', color },
          'Est_Centro_Resul_Conci_Regis_SICACC': { 'backgroundColor': '#92F76D', color },
          'Est_Centro_Imprimir_Adjuntar': { 'backgroundColor': '#5FB63E', color },
          'Est_Centro_Proyec_Const_Desisti':{ 'backgroundColor': '#5FB63E', color },
          'Tod_Centro': { 'backgroundColor': '#0d47a1', color },
      }
        return (colores[estado])
      }
  
      return (
        <ListarDatos
          datos={estados}
          titulo=''
          id='tbl_estados_centro'
          avatar={({ tipo_estado }) => tipo_estado.nombre.toUpperCase().charAt(0)}
          color_avatar={({ tipo_estado }) => obtenerColor(tipo_estado.codigo)}
          fila_principal={({ tipo_estado }) => tipo_estado.nombre}
          filas={[
            { 'id': 'fecha', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
            { 'id': 'usuario', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
            { 'id': 'mensaje', 'mostrar': ({ mensaje }) => mensaje },
            { 'id': 'observacion', 'mostrar': ({ observaciones }) => observaciones },
  
          ]}
        />
        )
  }

  pintar = () => {
    let { cargando, seleccion} = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <>
        {seleccion === 'detalle' && this.pintarDetalle()}
        {seleccion === 'hechos' && this.pintarDetalleHechos()}
        {seleccion === 'adjunto' && this.pintarDetalleAdjuntos()}
        {seleccion === 'estado' && this.pintarDetalleEstado()}
      </>
    )

  }

  render() {
    let { modalDetalleCentro, mostrarModalDetalleCentro} = this.props
    let { seleccion } = this.state;
    return (
      <>
         
         <Dialog fullWidth={true} maxWidth="sm" open={modalDetalleCentro} onClose={() => mostrarModalDetalleCentro(false)}>
         <AppBarModal titulo={" ¡ Detalle Solicitud ! "} mostrarModal={() => mostrarModalDetalleCentro(false)} titulo_accion="Cerrar" accion={() => mostrarModalDetalleCentro(false)}/>
         <CentroMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
         <DialogContent className='scroll'>
            <Grid container>
              <Grid item xs={12}  md={12}> 
                {this.pintar()}
              </Grid>
           </Grid>
         </DialogContent>
         <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleCentro(false)} />
        </DialogActions>
       </Dialog > 
      </>
    )
    }

}

DetalleCentro.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
  modalDetalleCentro: PropTypes.bool.isRequired,
}



export default DetalleCentro