import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl, TextField} from '@material-ui/core';
import { DialogActions, Grid, Dialog, DialogContent, ListSubheader, InputLabel, Select, MenuItem } from '@material-ui/core';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import { obtenerEstadosProceso, obtenerValoresFiltros } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import CustomDropzone from "../../general/CustomDropzone";
import emma_w from '../../../global/imagenes/emma_w.png';
import { MenuGestionar } from "./CreditoMenu";
import { ENTIDADES_FINANCIERAS, ESTADOS_FINANCIEROS, MOTIVOS_FINANCIEROS, RESPUESTAS_FINALIZADO_ESTADO_FINANCIERO } from "./../helper";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}
function RenderMotivos({ motivos, motivo, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Motivo
      </ListSubheader>
    }>
      {motivos.map(({ id, nombre, codigo }) => {
        return (
          <ListItem key={id} role={undefined} dense button onClick={() => cambiarSeleccion(id, codigo)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={motivo.id === id} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={id} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

function RenderRespuestas({ respuestas, respuesta, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Respuesta
      </ListSubheader>
    }>
      {respuestas.map(({ id, nombre, codigo }) => {
        return (
          <ListItem key={id} role={undefined} dense button onClick={() => cambiarSeleccion(id, codigo)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={respuesta?.id === id} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={id} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}


class CreditosGestionar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      motivos: [],
      estados_siguiente: [],
      archivos: [],
      seleccion: 'gestion',
      valor_aprobado : '',
      entidades: [],
      entidad: '',
      observaciones: '',
      formatos_archivos: '',
      tipoSolicitudTitulo: '',
      respuestas: [],
      respuesta: '',
      valor_respuesta: ''
    }
  }

  async componentDidUpdate({ modalGestionarCredito }, { estado }) {
    let { modalGestionarCredito: modalGestionarPracticaNew, credito } = this.props;
    let { estado: estadoNew } = this.state;

    if (estadoNew !== estado && estadoNew === 'Cre_Car') {
      this.setState({ cargando: true, titulo_res: 'Cargando..'});

      let valora = '';

      if (credito.tipo.codigo === 'sol_conestfin') valora = 'res_estado'

      let respuestas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': RESPUESTAS_FINALIZADO_ESTADO_FINANCIERO }, { 'llave': 'estado', 'valor': 1 }, {'llave': 'valora', 'valor': valora}]);

      this.setState({ cargando: false, respuestas, respuesta: '' });
    }

    if ((estadoNew !== estado && estadoNew === 'Cre_Rec') || (estadoNew !== estado && estadoNew === 'Cre_Rech' && credito.tipo.codigo === 'sol_pazsalvo')) {
      this.setState({ cargando: true, titulo_res: 'Cargando..'});
      let valora = ''
      if(credito.tipo.codigo === 'sol_cred') valora = 'credito'
      else if(credito.tipo.codigo === 'sol_lega') valora = 'legalizacion'
      else if (credito.tipo.codigo === 'sol_desc') valora = 'descongelamiento'
      else if (credito.tipo.codigo === 'sol_cong') valora = 'congelamiento'
      else if (credito.tipo.codigo === 'sol_dev') valora = 'devoluciones'
      else if (credito.tipo.codigo === 'sol_pazsalvo') valora = 'paz_salvo'

      let motivos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MOTIVOS_FINANCIEROS }, { 'llave': 'estado', 'valor': 1 }, {'llave': 'valora', 'valor': valora}]);
      
      this.setState({ motivos, motivo: '', cargando: false });
    }
    
    if (estadoNew !== estado && estadoNew === 'Cre_Ges') {
      this.setState({ cargando: true, titulo_res: 'Cargando..' });
      let entidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ENTIDADES_FINANCIERAS }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'legalizacion' }])
      this.setState({ entidades, entidad: '', cargando: false });
    }

    if (modalGestionarCredito !== modalGestionarPracticaNew) {
      if (modalGestionarPracticaNew) {
        this.reiniciarEstado();
        let formatos_archivos = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'For_Arc_Cre' }]);
        let estados_siguiente = await obtenerEstadosProceso(credito.tipo.id, [{ 'llave': 'principal', 'valor': credito.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS_FINANCIEROS }]);
        this.setState({ estado: '', mensaje: '', estados_siguiente, cargando: false, formatos_archivos: formatos_archivos ? formatos_archivos[0].valora : '' }
        );
      }
    }
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      motivos: [],
      estados_siguiente: [],
      archivos: [],
      seleccion: 'gestion',
      valor_aprobado : '',
      entidades: [],
      entidad: '',
      observaciones: '',
      formatos_archivos: '',
      respuestas: [],
      respuesta: '',
      valor_respuesta: ''
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  onSubmit = e => {
    let { credito } = this.props;
    let { estado, motivo, archivos, valor_aprobado, entidad, observaciones, respuesta, valor_respuesta } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = { motivo, estado, valor_aprobado, archivos, entidad, observaciones, valor_respuesta, respuesta, total: archivos.length }
    this.props.gestionarCredito(credito.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    e.preventDefault();
  }

  pintarEstados() {
    let { credito } = this.props;
    let { estado, estados_siguiente, motivo, motivos, respuestas, respuesta, entidad, entidades, observaciones, valor_respuesta } = this.state;
    
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
        </Grid>
        {estado === 'Cre_Ace' && 
					<Grid item xs={12} md={12}>
						<FormControl className="form-control">
							<TextField
                type="number"
								id="txtValorAprobado"
                label="Valor Aprobado"
                name="valor_aprobado"
                placeholder="Digite el valor aprobado de créditos"
                onChange={this.onChange}
							/>
						</FormControl>
					</Grid>
				}
        {
          
          (estado === 'Cre_Rec' || (estado === 'Cre_Rech' && credito.tipo.codigo === 'sol_pazsalvo')) && 
          <Grid item xs={12} md={12}>
            <RenderMotivos cambiarSeleccion={(id, codigo) => this.setState({ motivo: {id, codigo} })} motivo={motivo} motivos={motivos} />
          </Grid>
        }

        {
          estado === 'Cre_Car' && credito.tipo.codigo === 'sol_conestfin' &&
          <Grid item xs={12} md={12}>
            <RenderRespuestas cambiarSeleccion={(id, codigo) => this.setState({ respuesta: {id, codigo} })} respuesta={respuesta} respuestas={respuestas} />
          </Grid>
          
        }
          {/* && estado == 'Cre_Rec' */}
          {estado && (estado !== 'Cre_Car' || credito.tipo.codigo !== 'sol_conestfin' || motivo.codigo === 'otro_mot_rech') && (
          <Grid item xs={12} md={12}>
            <FormControl className="form-control" >
              <TextField
                value={observaciones}
                id="observaciones"
                label={estado === 'Cre_Rec' && motivo.codigo === 'otro_mot_rech' ? 'Otro motivo' : 'Observaciones'}
                name="observaciones"
                placeholder={estado === 'Cre_Rec' && motivo.codigo === 'otro_mot_rech' ? 'Escriba otro motivo' : 'Escriba observaciones'}
                multiline
                onChange={this.onChange}
                inputProps={{ maxLength: "200" }}
              />
            </FormControl>
          </Grid>
        )}

        {
          (respuesta?.codigo === 'posee_cred' || respuesta?.codigo === 'posee_salfav') && estado === 'Cre_Car' && (
            <Grid item xs={12} md={12}>
            <FormControl className="form-control" >
              <TextField
                autoFocus 
                value={valor_respuesta}
                id="valor_respuesta"
                label={'Valor'}
                name="valor_respuesta"
                placeholder={'Digite valor'}
                multiline
                onChange={this.onChange}
                inputProps={{ maxLength: "200" }}
              />
            </FormControl>
          </Grid>
          )
        }
        {estado === 'Cre_Ges' && credito.tipo.codigo === 'sol_lega' &&
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="entidad">Seleccione Entidad Financiera</InputLabel>
              <Select
                inputProps={{
                  name: 'entidad',
                  id: 'entidad',
                }}
                value={entidad}
                onChange={this.onChange}
                required={true}>
                {entidades.map(({ id, nombre }, i) => <MenuItem key={i} value={id}>{nombre}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        }

        {/* Aca iba el campo de observaciones */}
      </Grid >
    )
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  agregarArchivos = (arc) => {
    let { archivos } = this.state;
    archivos = archivos.concat(arc);
    this.setState({ archivos })
  }

  pintarAdjuntos() {
    let { formatos_archivos } = this.state
    return <CustomDropzone archivos={this.state.archivos} addFunction={this.agregarArchivos} deleteFunction={this.eliminarArchivo} actualizarMensaje={this.props.actualizarMensaje} acceptedFiles={formatos_archivos}/>
  }

  pintar() {
    let { seleccion } = this.state;
    return (<div>
      {seleccion === 'gestion' && this.pintarEstados()}
      {seleccion === 'adjuntos' && this.pintarAdjuntos()}
    </div>)
  }


  render() {
    let { modalGestionarCredito, mostrarModalGestionarCredito, credito } = this.props;
    let { cargando, titulo_res, seleccion, tipoSolicitudTitulo } = this.state;

    const nomTitulo = credito?.tipo?.nombre.toLowerCase().split(' ').map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(' ') || "Sin título";
    
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalGestionarCredito} onClose={mostrarModalGestionarCredito.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={`¿ Gestionar ${nomTitulo}  ?`} mostrarModal={mostrarModalGestionarCredito} titulo_accion="" accion="" />
        <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} />
        <DialogContent style={{ padding: '1' }} className='scroll'>
          {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" /> : <div style={{ padding: "5px 0 5px 0" }}>{this.pintar()}</div>}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalGestionarCredito(false)} />
          {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
CreditosGestionar.propTypes = {
  //variables
  modalGestionarCredito: PropTypes.bool.isRequired,
  // credito: PropTypes.object.isRequired,
  //funciones
  mostrarModalGestionarCredito: PropTypes.func.isRequired,
  gestionarCredito: PropTypes.func.isRequired,
}


export default CreditosGestionar;
