import React from 'react'
import PropTypes from 'prop-types';
//componentes
import { BtnDetalle, BtnPermiso, BtnForm } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";
import AppBarModal from '../../general/AppBarModal'
import BuscarPersona from '../../general/BuscarPersona'
import ConfirmarAccion from '../../general/ConfirmarAccion'
import Generica from "../../personas/generica/Generica";
import ModalAyuda from '../../general/ModalAyuda'
import { rutaImg, obtenerValoresFiltros } from "../../../global/js/funciones";
//Material ui
import BtnArea from '@material-ui/icons/DeviceHub';
import { Tooltip, IconButton, DialogActions, DialogContent, Dialog } from '@material-ui/core/';

class GradosPermisos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalBuscarPerso: false,
			modalConfirPerso: false,
			modalAyuda: false,
			perfil: null,
		}
	}

	async componentDidMount() {
		let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Adm_Gra3' }]);
		if (p.length > 0) this.setState({ perfil: p[0].id })
	}

	// Acciones



	//Handlers
	onClickSeleccionarPersona = (data) => {
		this.props.actualizarDataPersona(data);
		this.setState({ modalConfirPerso: true })
	}

	submit = (persona) => {
		let relaciones = [this.state.perfil]
		let tipo = 1
		this.props.guardarPersonal(persona, relaciones, tipo)
		this.setState({ modalConfirPerso: false })
	}

	// Acciones listar

	acciones = (data) => {
		let { personas, actProgramas, actEstados, actualizarDataPersona, showModalProgramaGestor, showModalPermisoEstadoGestor, showModalEvalComite } = this.props;
		data.identificacion = parseInt(data.identificacion);
		data.login_directorio = parseInt(data.login_directorio);

		const onClickProgramas = (data) => {
			actualizarDataPersona(data);
			showModalProgramaGestor(true);
		}

		const onClickPermiso = (data) => {
			actualizarDataPersona(data);
			showModalPermisoEstadoGestor(true);
		}

		const onClickAreas = (data) => {
			actualizarDataPersona(data);
			showModalEvalComite(true);
		}

		let programas = actProgramas && <BtnDetalle callback={() => onClickProgramas(data)} texto='Programas' color='#2196f3' />;
		// let areas = <Tooltip title={"Áreas de investigación"} aria-label="add" onClick={() => onClickAreas(data)}><IconButton style={{ color: '#00bfa5' }} aria-label="delete"><BtnArea fontSize="small" /></IconButton></Tooltip>;
		let estados = actEstados && <BtnPermiso callback={() => onClickPermiso(data)} texto='Estados' />;
		// let tipos = <BtnDetalle callback={() => onClickTipo(data)} texto='Tipos' color='#0000FF' />;

		// return <div>{programas} {areas} {estados}</div>
		return <div>{programas} {estados}</div>
	}

	// modales

	modalNuevoPersonal = () => {
		let { persona } = this.props
		let { modalBuscarPerso, modalConfirPerso } = this.state
		return (
			<div>
				<Dialog open={modalBuscarPerso} onClose={() => this.setState({ modalBuscarPerso: false })} maxWidth='sm' fullWidth={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Buscar Persona !' mostrarModal={() => this.setState({ modalBuscarPerso: false })} titulo_accion='' />
					<DialogContent style={{ padding: 0 }} className='scroll' >
						<BuscarPersona callback={(data) => this.onClickSeleccionarPersona(data)} />
					</DialogContent>
					<DialogActions>
						<BtnForm texto="Cerrar" callback={() => this.setState({ modalBuscarPerso: false })} />
					</DialogActions>
				</Dialog>
				<ConfirmarAccion
					titulo="¿Agregar a mi personal?"
					mensaje1={`Seguro que desea agregar a ${persona.primer_nombre} ${persona.primer_apellido}  a su personal.`}
					dato={persona}
					modalConAccion={modalConfirPerso}
					mostrarModalConfAccion={() => this.setState({ modalConfirPerso: false })}
					ejecutarAccion={(id) => this.submit(id)}
				/>
			</div>
		)
	}

	modalAsignarPrograma = () => {
		let { persona, showModalProgramaGestor, modalProgramaGestor } = this.props;
		return (
			<Dialog open={modalProgramaGestor} fullWidth={true} fullScreen={window.innerWidth < 600} maxWidth='sm' onClose={showModalProgramaGestor.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<AppBarModal titulo='¡ Programas Asignados !' mostrarModal={showModalProgramaGestor} titulo_accion="" />
				<DialogContent style={{ padding: 0 }} className='scroll'>
					<Generica tipo={34} persona={persona} genericaRelacionId={5} titulo="¡ Nuevo Programa !" tituloInput="Programas" tituloEliminar='¿ Eliminar Programa ?' />
				</DialogContent>
				<DialogActions>
					<BtnForm texto="Cerrar" callback={() => showModalProgramaGestor(false)} />
				</DialogActions>
			</Dialog>
		);
	}

	modalAsignarEstado = () => {
		let { persona, showModalPermisoEstadoGestor, modalPermisoEstadoGestor } = this.props;
		if (Object.entries(persona).length > 0) {
			return (
				<Dialog open={modalPermisoEstadoGestor} fullWidth={true} fullScreen={window.innerWidth < 600} maxWidth='sm' onClose={() => showModalPermisoEstadoGestor(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Estados Asignados !' mostrarModal={showModalPermisoEstadoGestor} titulo_accion="" />
					<DialogContent style={{ padding: 0 }} className='scroll'>
						<Generica tipo={35} persona={persona} genericaRelacionId={23} titulo="¡ Nuevo Estado !" tituloInput="Estados" tituloEliminar='¿ Eliminar Estado ?' />
					</DialogContent>
					<DialogActions>
						<BtnForm texto="Cerrar" callback={() => showModalPermisoEstadoGestor(false)} />
					</DialogActions>
				</Dialog>
			);
		}
	}

	modalAyudaPersonal = () => {
		let { modalAyuda } = this.state
		return (
			<ModalAyuda
				modalAyuda={modalAyuda}
				mostrarModalAyuda={() => this.setState({ modalAyuda: false })}
				titulo="¿ Personal ?"
				mensaje1="El Personal, son personas pertecientes a la universidad(Docentes, Coordinadores de área) 
					que pueden ser expertos en alguna línea de investigación de la universidad. las cuales podrás asignar 
					como coordinadores de área, tutores o cotutores en las solicitudes de trabajos de grado de los estudiantes. 
					Sí desear agregar una persona al personal, por favor de click en el botón con el signo (+) que se se encuentra abajo.
					"
			/>
		)
	}

	render() {
		let { personas } = this.props;
		return (
			<div>
				<ListarDatos
					id='tbl_gestores_gra'
					datos={personas}
					titulo='Lista Gestores'
					opciones={true}
					buscar={true}
					agregar={true}
					ayuda={true}
					descargar='EMMA - GESTORES'
					acciones={row => this.acciones(row)}
					actAgregar={() => this.setState({ modalBuscarPerso: true })}
					actAyuda={()=> this.setState({ modalAyuda: true })}
					avatarImg={true}
					avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
					fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
					filas={[
						{ 'id': 'correo', 'nombre': 'Email: ' },
						{ 'mostrar': ({ celular }) => celular ? celular : 'No registra', 'id': 'celular', 'nombre': 'Cel: ' },
						{ 'id': 'identificacion', 'nombre': 'identificación: ' },
					]}
				/>
				{/* Modales */}
				{this.modalNuevoPersonal()}
				{this.modalAsignarPrograma()}
				{this.modalAsignarEstado()}
				{this.modalAyudaPersonal()}
			</div>
		);
	}

}
//Props del componente
GradosPermisos.propTypes = {
	//variables
	personas: PropTypes.array.isRequired,
	//funciones
}


export default GradosPermisos;
