import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { saveAs } from "file-saver";
import { Dialog, DialogActions, DialogContent, Grid, Link } from '@material-ui/core';
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import ListarDatos from "../../general/ListarDatos";
import { BtnDetalle } from "../../general/BotonesAccion";
import { PracticaDetalleMenu } from "./MenuPracticas";
import { mostrarModalAddNota } from "../../../redux/actions/actPracticas";
import {  actualizarMensaje } from "../../../redux/actions/actGlobal";
import PracticaDetalle from "./PracticaDetalle";
import PracticasEmpresasHV from "../hojas_vida/PracticasEmpresasHV";
import Adjunto from "../adjuntos/Adjunto";
import { coloresEmma, consulta, formulario, generarFiltros, mostrarError } from "../../../global/js/funciones";
import moment from 'moment'
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from 'react-redux';
import { propTypes } from 'react-bootstrap/esm/Image';
let JSZip = require("jszip");


class Practica extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      cargandoI: true,
      seleccion: '',
      practica: null,
      requisito: null,
      estados: [],
      Notas: [],
      hv: [],
      requisitos: [],
      modalRequisito: false,
      adjAdicionales: [],
      inducciones: [],
      modalDetalleEst: false,
      tituloEst: '',
      notaagg:'',
      interes:[]
    }
  }

  async componentDidUpdate({ modalDetallePractica }, { seleccion }) {
    let { seleccion: seleccionNew,practica,interes } = this.state;
    let { practica_id, modalDetallePractica: modalDetallePracticaNew, vistaDetalle,modaladdnota } = this.props;
    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'practica') {
        this.obtenerPractica(practica_id) 
        this.ObtenerAreainteres(practica_id)}
      else if (seleccionNew === 'estados') {this.obtenerEstados(practica_id)
      console.log(this.state.estados)
      }
      else if (seleccionNew === 'requisitos') this.obtenerRequisitos(practica_id);
      else if (seleccionNew === 'notas') this.ObtenerNotas(practica_id);
      else if (seleccionNew === 'hv') this.obtenerHv(practica_id);
      else if (seleccionNew === 'adjuntos') this.obtenerAdjAdicionales(practica_id);
      
    }
    // if(modaladdnota===false){
    //   this.ObtenerNotas(practica_id)
    // }

    if (modalDetallePractica !== modalDetallePracticaNew) this.setState({ seleccion: modalDetallePracticaNew ? vistaDetalle : '' ,practica:'',interes:''})
  }

  async obtenerPractica(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/practica?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({  practica: resp.length > 0 ? resp[0] : null });
    })
  }

  async obtenerEstados(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'practica', 'valor': id }]);
    consulta(`api/v1.0/practicas/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }
  async ObtenerNotas(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'practica', 'valor': id }]);
    consulta(`api/v1.0/practicas/notas?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, Notas: resp });
    })
  }
  async ObtenerAreainteres(id) {
    this.setState({ cargando: true });
    consulta(`api/v1.0/practicas/areadeinteres/${id}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, interes: resp });
    })
  }

  async obtenerRequisitos(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'estado_pr__practica__id', 'valor': id }]);
    consulta(`api/v1.0/practicas/adjuntos?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, requisitos: resp });
    })
  }

  async obtenerHv(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'practica', 'valor': id }]);
    consulta(`api/v1.0/practicas/listarhv?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, hv: resp });
    })
  }

  async obtenerAdjAdicionales(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'practica', 'valor': id }]);
    consulta(`api/v1.0/practicas/adicionales?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, adjAdicionales: resp });
    })
  }

  async obtenerInducciones(id){
    this.setState({ cargandoI: true });
    let f = await generarFiltros([{ 'llave': 'estados', 'valor': id }]);
    consulta(`api/v1.0/practicas/induccion?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargandoI: false, inducciones: resp });
    })
  }


  pintarPractica = () => {
    let { practica,interes } = this.state;
    console.log(interes)
    if (practica) return <PracticaDetalle practica={practica} interes={interes} />;
  }

  pintarEstados = () => {
    let { estados, modalDetalleEst, tituloEst, inducciones, cargandoI } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Pra_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_Pra_Ini': { 'backgroundColor': coloresEmma.solicitudInicada, color },
        'Est_Pra_Rec': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Est_Pra_Fin': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_Pra_Rev': { 'backgroundColor': coloresEmma.ReemplazaroReenviar, color },
        'Est_Pra_Acep': { 'backgroundColor': coloresEmma.solicitudAceptada, color },
        'Est_Pra_Vis': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Est_Pra_Vif': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Est_Pra_Vio': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => { 
      const onClickDetalle = proceso => {
        this.setState({ proceso })
        this.obtenerInducciones(proceso.id)
        this.setState({ tituloEst: proceso.tipo_estado.nombre, modalDetalleEst : true })
      }

      let detalle = data.tipo_estado.valora == '2' ? <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' /> : '';
    
      return <div>{detalle}</div>; 
    }

    return (
      <div>
        <ListarDatos
          datos={estados}
          id='tbl_estados'
          css={{}}
          buscar={false}
          avatar={({ tipo_estado }) => tipo_estado.valorc}
          acciones={(data) => acciones(data)}
          color_avatar={({ tipo_estado }) => obtenerColor(tipo_estado.codigo)}
          fila_principal={({ tipo_estado }) => tipo_estado.nombre}
          filas={[
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
            { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`, 'nombre': 'Registrado por : ' },
            { 'id': 'fecha_visita', 'nombre': 'Fecha Visita : ' },
            { 'id': 'horario', 'mostrar': ({ horario_entrada, horario_salida }) => horario_entrada ? `De ${horario_entrada} hasta ${horario_salida}` : '' },
            { 'id': 'cancelado', 'mostrar': ({ cancelado }) => cancelado === '1' ? 'VISITA CANCELADA' : '' }
          ]}
        />

        <Dialog fullWidth={true} maxWidth="xs" open={modalDetalleEst} onClose={() => this.setState({modalDetalleEst : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={'Detalle ' + tituloEst} mostrarModal={() => this.setState({modalDetalleEst : false})} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            { (cargandoI) ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <ListarDatos
                datos={inducciones}
                id='tbl_inducciones'
                css={{}}
                buscar={false}
                fila_principal={({ fecha }) => 'Fecha de inducción: '+fecha }
                filas={[
                  { 'id': 'horario', 'mostrar': ({ hora_inicio, hora_final }) => hora_inicio ? `Desde ${hora_inicio} hasta ${hora_final}` : '' },
                  { 'id': 'enlace', 'mostrar': ({ enlace }) => enlace ? `Enlace: ${enlace}` : ''},
                  { 'id': 'observaciones', 'nombre': 'Observaciones : ' }
                ]}
              />  
            }

          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => this.setState({modalDetalleEst : false})} />
          </DialogActions>
        </Dialog >
      </div>
    );
  }

  pintarRequisitos = () => {
    let { requisitos, requisito, modalRequisito } = this.state;
    let { usuario, admin } = this.props;

    const obtenerColor = (codigo, valorReq) => {
      if( codigo === '1' && valorReq == '1' ) codigo = '-1'
      let color = 'white';
      const colores = {
        '0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        '1': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        '2': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        '3': { 'backgroundColor': coloresEmma.ReemplazaroReenviar, color },
        '-1': { 'backgroundColor': coloresEmma.ReemplazaroReenviar, color }
      }
      return (colores[codigo])
    }


    const obtenerEstadoAdj = (codigo, valorReq) => {
      if( codigo === '1' && valorReq == '1' ) codigo = '-1'
      const titulos = {
        '0': 'Enviado',
        '1': 'Negado',
        '2': 'Aprobado',
        '3': 'Remplazado',
        '4': 'Pendiente',
        '-1': 'Reenviado'
      }
      return (titulos[codigo])
    }

    const acciones = (requisito) => {

      const eventoDetalle = (requisito) => {
        this.setState({ modalRequisito: true, requisito })
      }

      let detalle = <BtnDetalle callback={() => eventoDetalle(requisito)} />;
      return detalle

    }

    const descargarMasivo = (archivos) => {
      let { practica } = this.state
      let nombreZip = practica.usuario_registro ? `${practica.usuario_registro.primer_apellido}_${practica.usuario_registro.primer_nombre}` : ''
      let zip = new JSZip();
      const urlToPromise = url => Axios.get(url, { responseType: 'blob' }).then(res => res.data)

      archivos.forEach((req) => {
        let extension = (/[.]/.exec(req.archivo)) ? /[^.]+$/.exec(req.archivo)[0] : undefined;
        let nombre_archivo = req.requisito.nombre + '.' + extension
        zip.file(`${nombreZip}/${nombre_archivo}`, urlToPromise(req.archivo), { binary: true });
      });

      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${nombreZip}.zip`);
      });
    }

    return (
      <div>
        <Adjunto requisito={requisito} modalDetalleAdjunto={modalRequisito} mostrarModalDetalleAdjunto={(modalRequisito) => this.setState({ modalRequisito })} usuario={usuario}/>
        <ListarDatos
          datos={requisitos}
          css={{}}
          buscar={false}
          titulo='Requisitos'
          opciones={admin}
          opcionAdicional={true}
          actDescargar={() => descargarMasivo(requisitos.filter(req => req.tipo == '1'))}
          avatar={({ validacion, requisito : {valorc} }) => obtenerEstadoAdj(validacion, valorc).toUpperCase().charAt(0)}
          color_avatar={({ validacion, requisito : {valorc} }) => obtenerColor(validacion, valorc)}
          id='tbl_adjuntos'
          acciones={row => acciones(row)}
          fila_principal={({ requisito, nombre_archivo }) => requisito ? requisito.nombre : nombre_archivo}
          filas={[
            {
              'mostrar': ({ fecha_registro }) => `${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
              'id': 'fecha_registro',
            },
            {
              'mostrar': ({ validacion, requisito : {valorc} }) => obtenerEstadoAdj(validacion, valorc),
              'id': 'estado',
            },
            {
              
              'mostrar': ({ nota }) => `${nota ? nota:''}`,
              'id': 'nota',
            },
            {
              'nombre': 'Agregado por : ',
              'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`,
              'id': 'nombre_completo',
              'enLista': false,
            },
            {
              'nombre': 'Revisado el : ',
              'mostrar': ({ fecha_valida }) => fecha_valida && `${moment(fecha_valida).format('YYYY-MM-DD, h:mm a')}`,
              'id': 'fecha_valida',
              'enLista': false,
            },
            {
              'nombre': 'Revisado por : ',
              'mostrar': ({ usuario_valida }) => usuario_valida && `${usuario_valida.primer_nombre} ${usuario_valida.primer_apellido} ${usuario_valida.segundo_apellido}`,
              'id': 'usuario_valida',
              'enLista': false,
            },
            {
              'nombre': 'Nota : ',
              'id': 'nota',
              'enLista': false,
            },
            {
              'nombre': 'Nota : ',
              'id': 'nota2',
              'enLista': false,
            },
          ]}
        />
      </div>
    );
  }

  pintarHV = () => {
    let { hv } = this.state;
    return <PracticasEmpresasHV hojas={hv} admin={false} />
  }





  pintarAdjAdicionales = () => {
    let { adjAdicionales } = this.state;

    const acciones = ({ archivo }) => {
      let detalle = <Link href={`${archivo}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>;
      return <div>{detalle}</div>;
    }

    return (
      <ListarDatos
        datos={adjAdicionales}
        id='tbl_adjuntos_ad_practica'
        avatar={({ nombre_archivo }) => nombre_archivo.charAt(0)}
        acciones={(data) => acciones(data)}
        fila_principal='nombre_archivo'
        filas={[
          { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
          { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
        ]}
      />
    )
  }
  guardarNotas = (inputnota,practica_id) => { //
    let { actualizarMensaje,mostrarModalAddNota } = this.props;
   console.log(practica_id)
  if(inputnota==="" || practica_id===""){
    let titulo = 'Rellene todos los campos', tipo = 'info';
    actualizarMensaje({titulo,tipo,mostrar:true,tiempo:6000})
  }else{
    const data = new FormData();
    data.append('nota', inputnota);
    data.append('practica_id', practica_id);
    formulario(`api/v1.0/practicas/guardar/notas`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ seleccion: 'notas' })
          mostrarModalAddNota(false) 
          this.ObtenerNotas(practica_id)   
          this.setState({notaagg:''})     
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      
    })
  }
  }

  pintarNotas=()=>{
    let {Notas} =this.state
    let {mostrarModalAddNota,adminuser}= this.props

    console.log(adminuser)

    return(
      <>

      <ListarDatos
      datos={Notas}
      css={{}}
      agregar={adminuser}
      buscar={false}
      actAgregar={() => this.props.mostrarModalAddNota(true)}
      opciones={false}
      // sub_titulo={'Seleccionar y Continuar'}
      id='tbl_Notas'
      // acciones={(row, i) => this.acciones(row, i)}
      fila_principal={(Notas) => `${Notas.notas}`}
      filas={[
        { 'id': 'creador', 'mostrar': (Notas) => `${Notas.usuario_registro.primer_nombre} ${Notas.usuario_registro.primer_apellido}`, 'nombre': 'Registrado por : ' },
        { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
      ]}
    />
        </>
    )
  }

  pintar = () => {
    let { seleccion, cargando } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'practica' && this.pintarPractica()}
        {seleccion === 'estados' && this.pintarEstados()}
        {seleccion === 'requisitos' && this.pintarRequisitos()}
        {seleccion === 'notas' && this.pintarNotas()}
        {seleccion === 'hv' && this.pintarHV()}
        {seleccion === 'adjuntos' && this.pintarAdjAdicionales()}
        
      </div>
    )

  }
  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }
  onSubmit = e => {
    let { notaagg } = this.state;
    let {practica_id}=this.props;
    this.setState({ cargando: true });
    this.guardarNotas(notaagg ? notaagg : "",practica_id);
    this.setState({cargando:false})
    
    e.preventDefault();
  }


  render() {

    let { seleccion,notaagg } = this.state;
    let { modalDetallePractica, mostrarModalDetallePractica ,mostrarModalAddNota,modaladdnota} = this.props;

    return (
      <>
      <Dialog open={modalDetallePractica} maxWidth='sm' fullWidth={true} onClose={mostrarModalDetallePractica.bind(this, false)}>
        <AppBarModal titulo='¡ Detalle Practica !' mostrarModal={mostrarModalDetallePractica} titulo_accion='' />
        <PracticaDetalleMenu admin={this.props.adminuser} sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} hbEmpresa={true} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetallePractica(false)} />
        </DialogActions>
      </Dialog>

      <Dialog open={modaladdnota} maxWidth='sm' fullWidth={true} onClose={mostrarModalAddNota.bind(this, false)}>
        <AppBarModal titulo='¡ Agregar Nota !' mostrarModal={mostrarModalAddNota} titulo_accion='' />

        <DialogContent style={{ padding: 0 }} className='scroll' >
          {
          <Grid container>
            <Grid style={{ spacing:"0" ,margin: "0 auto", padding: '1em'}} item xs={12}  >
            <ValidatorForm >
          <TextValidator 
          multiline
          margin="dense"
          id="agg_nota"
          label="Agregar nota"
          type="text"
          fullWidth
          name="notaagg"
          value={notaagg}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={this.onChange}
          />
        </ValidatorForm>
      </Grid>
      </Grid>
      }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalAddNota(false)} />
          <BtnForm texto="Guardar" callback={(e) => this.onSubmit(e)} />
        </DialogActions>
      </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let { modaladdnota } = state.redPracticas;
  return {
    modaladdnota
  }
};
const mapDispatchToProps = {
  mostrarModalAddNota,
  actualizarMensaje,
}


Practica.propTypes = {
  //variables
  practica_id: PropTypes.number.isRequired,
  vistaDetalle: PropTypes.string.isRequired,
  modalDetallePractica: PropTypes.bool.isRequired,
  modaladdnota: PropTypes.bool.isRequired,
  mostrarModalDetallePractica: PropTypes.func.isRequired,
  mostrarModalAddNota: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,

}

Practica.defaultProps = {
  usuario: {codigo: 'Estudiante_Pra'}
}
export default connect(mapStateToProps, mapDispatchToProps)(Practica);
