import React from 'react';
import { consulta, rutaImg } from '../../../global/js/funciones';
import ListarDatos from '../../general/ListarDatos';
import AppBarModal from '../../general/AppBarModal';
import { BtnPermiso, BtnForm, BtnRegresar } from '../../general/BotonesAccion';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from '../../personas/generica/Generica';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import * as Helper from '../children/helpers';


const AdminSalasPermisos = (props) => {
  const [cargando, setCargando] = React.useState(true);
  const [personas, setPersonas] = React.useState([]);
  const [generica, setGenerica] = React.useState({
    tipo: 0,
    id: 0,
  });
  const [modal, setModal] = React.useState(false);
  const [idPersona, setIdPersona] = React.useState(null);

  React.useEffect(() => {
    const obtenerDatos = async () => {
      const personas = await obtenerGestores();
      setPersonas(personas);
      setCargando(false);
    };
    obtenerDatos();
  }, []);


  const obtenerGestores = async () => {
    return new Promise((resolve) => {
      consulta(`api/v1.0/bloques/listarGestores`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  };

  const acciones = (data) => {
  
    const onClickSalas = (data) => {

        setIdPersona(data.id)
        setGenerica({ tipo: Helper.PERMISO_SALAS, id: Helper.SALAS })
        setModal(true)
    }

    const estados = <BtnPermiso callback={() => onClickSalas(data)} texto="Salas" />;
    return (
      <div>
        {estados}
      </div>
    );
  };

  if (cargando)
    return (
      <TareasTerminadas
        mensaje="Cargando.."
        marginTop="7%"
        imagen={emma_w}
        widthImg="7%"
      />
    );

  return (
    <div>
      <div>
       <div className='regresar' ><BtnRegresar callback={props.handleClicki} /></div>
        <ListarDatos
          id="tbl_becas_permisos"
          titulo="Lista Gestores"
          descargar="EMMA - GESTORES BECAS"
          datos={personas}
          opciones={true}
          buscar={true}
          acciones={(row) => acciones(row)}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={row => `${row.primer_nombre} ${row.segundo_nombre} ${row.primer_apellido} ${row.segundo_apellido}`.toUpperCase()}
          filas={[
          { 'mostrar': ({ correo }) => `${correo}`, 'id': 'correo', 'enLista': true },
          { 'mostrar': ({ identificacion }) => `${identificacion}`, 'id': 'identificacion', 'enLista': true }
          ]}
        />

        </div>
        <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => setModal(false)}>
          <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => setModal(false)} accion={() => setModal(false)} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica  tipo={generica.tipo} persona={{ id: idPersona }} genericaRelacionId={generica.id} titulo="¡ Nuevo Permiso !" tituloInput="Permisos" tituloEliminar='¿ Eliminar Permiso ?' />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() =>  setModal(false)} />
          </DialogActions>
        </Dialog>
    </div>
  )
}
export default AdminSalasPermisos
