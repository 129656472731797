import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Grid, Paper, Typography, Card, CardHeader, CardContent, Avatar, Button } from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


// FUNCIONAS GLOBALES
import { consulta, mostrarError, formulario, obtenerMisDatos, generarFiltros, obtenerValoresFiltros, crear_form_data } from "../../global/js/funciones";
import TareasTerminadas from '../general/TareasTerminadas';
import ListarDatos from '../general/ListarDatos';
import { BtnDetalle } from '../general/BotonesAccion';
import TituloAccion from "../general/TituloAccion";
import AccionesRapidas from '../general/AccionesRapidas';
import BarraProceso from "../general/BarraProceso";
import moment from 'moment';

// FUNCIONES DEL MODULO
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { mostrarModalAddRetos, mostrarModalAddGrupoRetos, mostrarModalDetalleRetos, mostrarModalAdjuntos, mostrarModalActualizarEvidencia } from "../../redux/actions/actRetoscuc";

// COMPONENTES MUDLOS
import { MenuEstudiantes } from './retoscuc/MenuRetos'
import { RetosDetalles } from "./retoscuc/DetalleRetos";
import RetosCrear from "./retoscuc/RetosCrear";
import GrupoRetosCrear from "./retoscuc/GrupoRetosCrear";
import RetosDetallescompletos from "./retoscuc/SolicitudRetos";
import RetosBanner from './retoscuc/RetosBanner';
import RetosActivos from './retoscuc/RetosActivos';
import RetosActualizarEvidencia from './retoscuc/RetosActualizarEvidencia';

// IMAGENES
import emma_s from '../../global/imagenes/emma_s1.png';
import emma_w from '../../global/imagenes/emma_w.png';
import revisar from '../../global/imagenes/revisar.png';
import folder from "../../global/imagenes/folder.png"
import retos_grupos from '../../global/imagenes/retos_grupos.png'
import Retos_seguimiento from '../../global/imagenes/retos_segumiento.png';

class RetosEstudiantes extends Component {
	constructor(props) {
		super(props)
		this.state =
		{
			seleccion: 'miproceso',
			asignaturas: [],
			procesos: [],
			lineaEstados: [],
			cargando: false,
			enListado: true,
			id_estudiante: 0,
			historial: [],
			procesoActual: null,
			notificaciones: [],
			anchorEl: null,
			retoActual: 0,
			obtenerMisDatos: [],
			estadoActual: '',
			perfilEstudiante: false,
		}
	}

	async componentDidMount() {
		let { mostrarModulo } = this.props;
		this.obtenerDataProcesoActual(true)
		this.misDatos()
		mostrarModulo({ rutas: 'RetosEstudiantes', nombre: 'retos' })
		this.esEstudiante()
	}

	async componentDidUpdate({ }, { seleccion, retoActual, procesosDetalle }) {
		let { seleccion: seleccionNew, retoActual: retoActualNew, procesosDetalle: procesosDetalleNew, enListado } = this.state
		if (retoActual !== retoActualNew && seleccionNew === 'miproceso' && retoActualNew != 0);

		if (procesosDetalle !== procesosDetalleNew) {
			if (procesosDetalleNew !== 0) {
				let data = await this.obtenerDatalleRetoInforme(procesosDetalleNew);
				if (Object.keys(data).length > 0) this.setState({ lineaEstados: data.linea_estados, procesoActual: data.retoscuc, id_estudiante: data.retoscuc.usuario_registro.id, evidencia_retos: data.evidencia });
				this.setState({ cargando: false });
			}
		};


		if (seleccion !== seleccionNew) {
			if (seleccionNew === 'miproceso');
			else if (seleccionNew === 'historial') await this.obtenerHistorial();
			if (seleccionNew === 'miproceso' && enListado === false){
				this.setState({ enListado: true, seleccion: seleccionNew})
			}
		}
	}

	obtenerDatalleRetoInforme = (id) => {
		return new Promise(resolve => {
			consulta(`api/v1.0/retoscuc/completa/${id}`, null, null, (error, estado, resp) => {
				resolve(resp);
			})
		})
	}

	esEstudiante() {
		let { usuario } = this.props;
		if (usuario) {
			if (usuario.perfil.codigo === 'Per_Nor') {
				this.setState({ perfilEstudiante: true })
			}
		}
	}

	misDatos = async () => {
		const datos_sesion = await obtenerMisDatos();
		this.setState({ datos_sesion })

	}

	vista = () => {
		let { seleccion, enListado } = this.state

		switch (seleccion) {
			case 'miproceso':
				return (enListado ? this.pintarSolicitudes() : this.pintarSolicitudSeleccionada())
			case 'nuevo':
				return (this.pintarNuevo())
			case 'historial':
				return (this.pintarHistorial())
			default:
				break;
		}
	}



	pintarSolicitudes() {
		let { procesos, datos_sesion } = this.state;
		if (procesos.length === 0) {
			return (
				<div className="pointer" onClick={() => this.setState({ seleccion: "nuevo" })}>
					<TareasTerminadas
						mensaje="No tienes solicitudes activas, clic aquí para hacer una solicitud de Retos"
						marginTop="7%"
						imagen={emma_s}
						widthImg="7%"
					/>
				</div>
			);
		}


		const handleClick = (reto) => {
			this.setState({ enListado: false, procesosDetalle: reto.id });
		};

		if (procesos && procesos.length > 0) {
			return (
				<>
					<div style={{ padding: '2% 2% 0% 2%' }}>
						<Grid container direction={'row'} spacing={1}>
							<TituloAccion principal="" alterno="¡ Estas son tus solicitudes de Retos Unicosta para este periodo !" />
							{procesos.map((reto, i) => {
								return (
									console.log(reto),
									<Grid item xs={12} sm={6} md={4} lg={3} key={i}>
										<Paper elevation={1} onClick={() => handleClick(reto)} className="pointer">
											<RetosActivos
												nombre={"RetoUnicosta"}
												fecha={reto.fecha_registro}
												descripcion={`Tu solicitud de reto de ${reto.asignatura}\n, se encuentra en estado ${reto.estado_actual.nombre}\n Cli aquí para obtener mas información.`}
												estado={reto.estado_actual}
												imagen={Retos_seguimiento}
											/>
										</Paper>
									</Grid>
								);
							})}
						</Grid>
					</div>
				</>
			);
		} else {
			return (
				<div className="pointer" onClick={() => this.setState({ seleccion: "nuevo" })}>
					<RetosBanner
						mensaje="No tienes solicitudes activas, clic aquí para hacer una solicitud de retos"
						marginTop="7%"
						imagen={emma_s}
						widthImg="7%"
						datos_sesion={datos_sesion}
					/>
				</div>
			)
		}
	}

	pintarSolicitudSeleccionada() {
		let { mostrarModalDetalleRetos, mostrarModalAddRetos, mostrarModalActualizarEvidencia } = this.props
		let { procesoActual, datos_sesion, evidencia_retos } = this.state;

		const ejecutarAccionDetalle = vistaDetalle => {
			this.setState({ vistaDetalle, retoActual: procesoActual.id })
			mostrarModalDetalleRetos(true);
		}

		const ejecutarAdjuntarEvidencia = () => {
			mostrarModalAddRetos(true);
		}

		const ejecutarActulizarEvidencia = () => {
			mostrarModalActualizarEvidencia(true);
		}
    
		if (procesoActual) {
			return (
				<div className='' >
					<Button onClick={() => this.setState({ enListado: true, reto: 0 })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography>
					</Button>
					<div style={{ padding: '0% 2% 0% 2%' }} >

						<Grid container direction={'row'} spacing={1} >

							<Grid item xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
								<TituloAccion principal='' alterno='¡ Información Básica !' />
								<Paper elevation={0} square className="scroll">

									<RetosDetalles retoscuc={procesoActual} />

								</Paper>
							</Grid>

							<Grid item xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
								<TituloAccion principal='' alterno='¿ Que deseas hacer ?' />


								<Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
									<Grid item xs={12} sm={12} md={12} >
										<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccionDetalle('detalle')}>
											<AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
										</Paper>
									</Grid>

									{
										procesoActual.estado_actual.codigo == 'Ret_Gru'?

											<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAdjuntarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Evidencia de Retos Etapa 1!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									{
										procesoActual.estado_actual.codigo == 'Ret_Env' && evidencia_retos.retos_solicitud == '3' ?
										<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarActulizarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Actualizar evidencia de Retos Etapa 1!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									{
										procesoActual.estado_actual.codigo == 'Ret_Cal' ?
											<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAdjuntarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Evidencia de Retos Estapa 2!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									{
										procesoActual.estado_actual.codigo == 'Ret_Env_e2' && evidencia_retos.retos_solicitud == '3' ?
										<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarActulizarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Actualizar evidencia de Retos Etapa!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									{
										procesoActual.estado_actual.codigo == 'Ret_Cal_e2' ?
											<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAdjuntarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Evidencia de Retos Etapa 3!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									{
										procesoActual.estado_actual.codigo == 'Ret_Cal_e3' ?
											<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAdjuntarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Evidencia de Retos Etapa Final!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									{
									procesoActual.estado_actual.codigo == 'Ret_Env_e3' && evidencia_retos.retos_solicitud == '3' ?
										<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarActulizarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Actualizar evidencia de Retos Etapa 3!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									{
									procesoActual.estado_actual.codigo == 'Ret_Env_Fn' && evidencia_retos.retos_solicitud == '3' ?
										<Grid item xs={12} sm={12} md={12} >
												<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarActulizarEvidencia()}>
													<AccionesRapidas imagen={folder} titulo='¡ Actualizar evidencia de Retos Etapa 3!' sub_titulo='Listo' des_sub_titulo=', Aquí debes cargar tu evidencia Reto.' />
												</Paper>
											</Grid> : ''
									}
									
								</Grid>
							</Grid>
							{
								procesoActual.estado_actual.codigo !== 'Val_Neg' &&
								<Grid item xs={12} sm={12} md={12} >
									<TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
									{this.lineaDeEstados()}
								</Grid>
							}
						</Grid>
					</div >
				</div>
			)
		} else {
			return (
				<div className="pointer" onClick={() => this.setState({ seleccion: "nuevo" })}>
					<RetosBanner
						mensaje="No tienes solicitudes activas, clic aquí para hacer una solicitud de retos"
						marginTop="7%"
						imagen={emma_s}
						widthImg="7%"
						datos_sesion={datos_sesion}
					/>
				</div>
			);
		}
	}


	guardarGrupoRetos = async (datos, callback) => {
		let { actualizarMensaje, mostrarModalAddGrupoRetos } = this.props

		consulta(`api/v1.0/retos/gruposcrear`, datos, "post", (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.obtenerDataProcesoActual();
					this.setState({ seleccion: 'miproceso' })
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
				mostrarModalAddGrupoRetos(false);
				callback();
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
		})
	}

	guardarRetos = async (archivos, datos, idreto, callback) => {
		let { procesosDetalle } = this.state
		let { actualizarMensaje, mostrarModalAddRetos  } = this.props
		let data = await crear_form_data(datos);
		archivos.map(({ archivo, codigo }, index) => {
			data.append(`archivo`, archivo)
			data.append(`codigo`, codigo)
			return true;
		})

		formulario(`api/v1.0/retos/${idreto}/crear`, data, "post", async (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					mostrarModalAddRetos(false);
					this.setState({cargando: true})
					await this.obtenerDataProcesoActual();
					let data = await this.obtenerDatalleRetoInforme(procesosDetalle);
					if (Object.keys(data).length > 0) this.setState({ lineaEstados: data.linea_estados, procesoActual: data.retoscuc, id_estudiante: data.retoscuc.usuario_registro.id });
					callback();
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
			await this.obtenerDataProcesoActual();
		})
		
	}

	actualizarEvidenciaRetos = async (archivos, datos, idreto, callback) => {
		let { procesosDetalle , evidencia_retos } = this.state
		let { actualizarMensaje, mostrarModalActualizarEvidencia } = this.props

		let data = await crear_form_data(datos);
		archivos.map(({ archivo, codigo }, index) => {
			data.append(`archivo`, archivo)
			data.append(`codigo`, codigo)
			return true;
		})
		formulario(`api/v1.0/retoscuc/actualizarevidencia/${evidencia_retos.id}`, data, "put", async (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					mostrarModalActualizarEvidencia(false);
					// this.setState({cargando: true})
					await this.obtenerDataProcesoActual();
					let data = await this.obtenerDatalleRetoInforme(procesosDetalle);
					if (Object.keys(data).length > 0) this.setState({ lineaEstados: data.linea_estados, procesoActual: data.retoscuc, id_estudiante: data.retoscuc.usuario_registro.id, evidencia_retos: data.evidencia });
					callback();
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
			await this.obtenerDataProcesoActual();
		})
	}


	obtenerDatalleReto = (periodo) => {
		return new Promise(resolve => {
			consulta(`api/v1.0/retos/completa/${periodo}`, null, null, (error, estado, resp) => {
				resolve(resp);
			})
		})
	}

	async obtenerDataProcesoActual() {
		this.setState({ cargando: true })
		let data_periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Ret' }]);
		let data = await this.obtenerDatalleReto(data_periodo[0].nombre);
		this.setState({ cargando: false, procesos: data });
		console.log(data);
	}

	pintarNuevo = () => {
		let { mostrarModalAddGrupoRetos } = this.props
		let { procesos, datos_sesion } = this.state

		return (
			<div>
				{
					Object.keys(procesos).length < 20 ?
						<Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
							<Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalAddGrupoRetos(true) }}>
								<Paper elevation={8} square>
									<Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
										<CardHeader
											avatar={<Avatar aria-label="recipe" style={{ backgroundColor: indigo[900] }}>G</Avatar>}
											title={`Crea tu grupo`}
										/>
										<CardContent >
											<Grid container direction={'row'} justifyContent={'center'}>
												<Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
													<img src={retos_grupos} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
												</Grid>
												<Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
													<Typography variant="body2" color="textSecondary" component="p" align='justify'>
														Para crear tu grupo de retos haz clic aqui.
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Paper>
							</Grid>
						</Grid> :

						<RetosBanner datos_sesion={datos_sesion} mensaje='Ya superaste el numero de grupos' marginTop='7%' imagen={emma_s} widthImg="7%" />

				}
			</div>

		)
	}

	async obtenerRetos(filtros = []) {
		let f = await generarFiltros(filtros);
		return new Promise(resolve => {
			consulta(`api/v1.0/retos/listar/0?${f}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : []);
			})
		})
	}

	async obtenerHistorial() {
		this.setState({ cargando: true })
		let historial = await this.obtenerRetos();
		this.setState({ historial, cargando: false })
	}

	pintarHistorial = () => {
		let { historial } = this.state;
		let { mostrarModalDetalleRetos } = this.props;

		const obtenerColor = (codigo) => {
			let color = 'white';
			const colores = {
				'Ret_Gru': { 'backgroundColor': '#0044ff', color },
				'Ret_Env': { 'backgroundColor': '#ffc107', color },
				'Ret_Cal': { 'backgroundColor': '#5f02f5', color },
				'Ret_Env_e2': { 'backgroundColor': '#005E66', color },
				'Ret_Cal_e2': { 'backgroundColor': '#AF601A', color },
				'Ret_Env_e3': { 'backgroundColor': '#2E4053', color },
				'Ret_Cal_e3': { 'backgroundColor': '#685DE3', color },
				'Ret_Env_Fn': { 'backgroundColor': '#B329E6', color },
				'Ret_Cal_Fn': { 'backgroundColor': '#E4D281', color },
				'Ret_Sel': { 'backgroundColor': '#09BA69', color },
				'Ret_Dep': { 'backgroundColor': '#5af7fa', color },
				'Ret_Ire': { 'backgroundColor': '#bffa5a', color },
				'Ret_Gse': { 'backgroundColor': '#46fa64', color },
				'Ret_Act': { 'backgroundColor': '#6c036e', color },
				'Ret_Des': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Prof': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Depr': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Intr': { 'backgroundColor': '#fa3802', color },
				'Ret_Cer': { 'backgroundColor': '#fa3802', color },
				'Tod_Ret': { 'backgroundColor': '#0d47a1', color },
			}
			return (colores[codigo])
		}

		const acciones = (data) => {
			const onClickDetalle = ({ id, usuario_registro }) => {
				this.setState({ retoActual: id, id_estudiante: usuario_registro.id })
				mostrarModalDetalleRetos(true)
			}
			let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
			return detalle;
		}

		return (
			<div>
				<ListarDatos
					titulo='Lista de Retos'
					datos={historial}
					buscar={true}
					opciones={true}
					sub_titulo="Lista de adjuntos"
					avatar={({ estado_actual }) => estado_actual.nombre.charAt(0).toUpperCase()}
					color_avatar={({ estado_actual: { codigo } }) => obtenerColor(codigo)}
					acciones={(row) => acciones(row)}
					filas={[
						{ 'id': 'tipo_estado', 'enLista': true, 'mostrar': ({ estado_actual }) => estado_actual.nombre.toUpperCase() },
						{ 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), nombre: 'Fecha Registro: ' }
					]}
				/>
			</div>
		)
	}

	cambiarOpcion = (seleccion) => {
		this.setState({ seleccion })
	}

	lineaDeEstados = () => {
		let { cargando, lineaEstados, procesoActual } = this.state;
		if (!cargando && procesoActual && lineaEstados.length > 0) {
			let activeStep = 0;
			let steps = [];
			if (procesoActual.estado_actual.codigo === 'Ret_Des' || procesoActual.estado_actual.codigo === 'Ret_Des_Prof' || procesoActual.estado_actual.codigo === 'Ret_Des_Depr' || procesoActual.estado_actual.codigo === 'Ret_Des_Intr' || procesoActual.estado_actual.codigo === 'Ret_Cer') {
				steps.push({ label: procesoActual.estado_actual.nombre });
			} else {
				lineaEstados.map(({ secundario }, i) => {
					if (secundario.id === procesoActual.estado_actual.id) activeStep = i;
					steps.push({ label: secundario.nombre })
				})
			}

			return <BarraProceso steps={steps} activeStep={activeStep} />;
		}
		return null;
	}

	render() {
		let {
			modalAddRetos, mostrarModalAddRetos, modalAddGrupoRetos, mostrarModalAddGrupoRetos, modalDetRetos, mostrarModalDetalleRetos, mostrarModalActualizarEvidencia, modalactualizarEvidencia,actualizarMensaje, retoscuc
		} = this.props

		let
			{
				seleccion, cargando, asignaturas, anchorEl, mensaje, mensaje_grupo, retoActual, procesoActual, estadoActual, perfilEstudiante
			} = this.state

		return (
			<div>
				<MenuEstudiantes anchorEl={anchorEl} setanchorEl={(valor) => this.setState({ 'anchorEl': valor })} sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} />
				<div className='contenido_modulo'>

					<GrupoRetosCrear
						guardarGrupoRetos={this.guardarGrupoRetos}
						actualizarMensaje={actualizarMensaje}
						mostrarModalAddGrupoRetos={mostrarModalAddGrupoRetos}
						modalAddGrupoRetos={modalAddGrupoRetos}
						asignaturas={asignaturas}
						mensaje_grupo={mensaje_grupo}
					/>


					<RetosCrear
						guardarRetos={this.guardarRetos}
						retoscuc_id={procesoActual ? procesoActual.id : 0}
						actualizarMensaje={actualizarMensaje}
						mostrarModalAddRetos={mostrarModalAddRetos}
						modalAddRetos={modalAddRetos}
						mensaje={mensaje}
						estadoActual={procesoActual}
					/>

					<RetosActualizarEvidencia  
						actualizarEvidenciaRetos={this.actualizarEvidenciaRetos}
						retoscuc_id={procesoActual ? procesoActual.id : 0}
						actualizarMensaje={actualizarMensaje}
						mostrarModalActualizarEvidencia={mostrarModalActualizarEvidencia}
						modalactualizarEvidencia={modalactualizarEvidencia}
						mensaje={mensaje}
					/>


					<RetosDetallescompletos
						mostrarModalDetalleRetos={mostrarModalDetalleRetos}
						modalDetRetos={modalDetRetos}
						vistaDetalle='detalle'
						admin={false}
						retoActual={retoActual}
						perfilEstudiante={perfilEstudiante}
						reset={() => {}}
					/>

					{
						!cargando ? this.vista() :
							<RetosBanner mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
					}
				</div>
			</div>


		)
	}
}

const mapStateToProps = (state) => {
	let { modalAddRetos, modalAddGrupoRetos, modalDetRetos, modalAdjuntoRetos, modalactualizarEvidencia } = state.redRetoscuc;
	let { usuario } = state.redGlobal;
	return {
		// reducers Modulo Retoscuc
		modalAddRetos,
		modalAddGrupoRetos,
		modalDetRetos,
		modalAdjuntoRetos,
		modalactualizarEvidencia,

		// Reducers Global
		usuario
	}
}

const mapDispatchToProps =
{
	actualizarMensaje,
	mostrarModalAddRetos,
	mostrarModalAddGrupoRetos,
	mostrarModalDetalleRetos,
	mostrarModalAdjuntos,
	mostrarModulo,
	mostrarModalActualizarEvidencia
}

RetosEstudiantes.propTypes =
{
	// Funciones
	actualizarMensaje: PropTypes.func.isRequired,
	mostrarModulo: PropTypes.func.isRequired,
	mostrarModalAddRetos: PropTypes.func.isRequired,
	mostrarModalAddGrupoRetos: PropTypes.func.isRequired,
	mostrarModalDetalleRetos: PropTypes.func.isRequired,
	mostrarModalAdjuntos: PropTypes.func.isRequired,
	mostrarModalActualizarEvidencia: PropTypes.func.isRequired,


	// Variables
	modalAddRetos: PropTypes.bool.isRequired,
	modalAddGrupoRetos: PropTypes.bool.isRequired,
	modalDetRetos: PropTypes.bool.isRequired,
	modalAdjuntoRetos: PropTypes.bool.isRequired,
	modalactualizarEvidencia: PropTypes.bool.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(RetosEstudiantes)