import React, { useState } from 'react';

const ItemComponent = ({ item }) => {
  const [activar, setActivar] = useState(null);

  const definitionStyle = {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer'
  };

  const calculateLeftPosition = () => {
    if (activar === '(Dicción,') {
      return '50%';
    } else if (activar === 'ROOM') {
      return '20px';
    } else if (activar === 'Zona') {
      return '90px';
    } else {
      return '90px'; // Valor por defecto en caso de que no coincida
    }
  };

  const tooltipBaseStyle = {
    visibility: 'hidden',
    width: '300px',
    backgroundColor: '#B70E0C',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '5px',
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    bottom: '125%', /* Ajusta este valor según sea necesario */
    left: calculateLeftPosition(),
    marginLeft: '-100px', /* Ajusta este valor según sea necesario */
    opacity: 0,
    transition: 'opacity 0.3s'
  };

  const tooltipVisibleStyle = {
    ...tooltipBaseStyle,
    visibility: 'visible',
    opacity: 1
  };

  const handleMouseEnter = (e) => {
    const tooltip = e.target.querySelector('.tooltip-text');
    if (tooltip) {
      tooltip.style.visibility = 'visible';
      tooltip.style.opacity = '1';
    }
  };

  const handleMouseLeave = (e) => {
    const tooltip = e.target.querySelector('.tooltip-text');
    if (tooltip) {
      tooltip.style.visibility = 'hidden';
      tooltip.style.opacity = '0';
    }
  };

  const handleWordClick = (word) => {
    setActivar(activar === word ? null : word);
  };

  const processText = (text) => {
    return text.split(' ').map((word, index) => {
      if (word === 'aquí)') {
        return (
          <a
            key={index}
            href="https://bienestar.cuc.edu.co/ruta-de-atencion-de-vbg/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: 'blue' }}
          >
            {word}
          </a>
        );
      } else if (word === 'Zona') {
        return (
          <span
            key={index}
            style={definitionStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleWordClick('Zona')}
          >
            <strong>{word}</strong>
            <span
              style={activar === 'Zona' ? tooltipVisibleStyle : tooltipBaseStyle}
              className="tooltip-text"
            >
              Es una área rural que cumple con ciertos criterios para ser clasificada como tal, incluyendo: la necesidad de usar dos o más medios de transporte para llegar a un área urbana; la falta de vías de comunicación adecuadas para el tránsito motorizado durante la mayor parte del año escolar; y la prestación del servicio de transporte público terrestre, fluvial o marítimo con una frecuencia diaria limitada.
            </span>
          </span>
        );
      } else if (word === 'ROOM') {
        return (
          <span
            key={index}
            style={definitionStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleWordClick('ROOM')}
          >
            <strong>{word}</strong>
            <span
              style={activar === 'ROOM' ? tooltipVisibleStyle : tooltipBaseStyle}
              className="tooltip-text"
            >
              Es una comunidad étnica en Colombia con origen en el norte de la India hace más de 1.000 años. Se identifica como gitano por vínculo paterno, derecho de nacimiento o sangre gitana. Su idioma es el Romaní. Tradicionalmente nómadas, su forma de vida ha evolucionado con el tiempo. Las principales instituciones político-sociales de esta comunidad incluyen la Kumpania, la Kriss, la Kriss Romaní y los Seré Romengué.
            </span>
          </span>
        );
      } else if (word === '(Dicción,') {
        return (
          <span
            key={index}
            style={definitionStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleWordClick('(Dicción,')}
          >
            <strong>{word}</strong>
            <span
              style={activar === '(Dicción,' ? tooltipVisibleStyle : tooltipBaseStyle}
              className="tooltip-text"
            >
              Se refiere a la articulación y pronunciación correcta de las palabras. En este sentido, implica cómo se emiten los sonidos y se forman las palabras al hablar, asegurando que se entiendan claramente.
            </span>
          </span>
        );
      } else {
        return <span key={index}>{word}</span>;
      }
    }).reduce((prev, curr) => [prev, ' ', curr]);
  };

  return <span>{processText(item.nombre)}</span>;
};

export default ItemComponent;

