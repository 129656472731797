import React from 'react'
import { Typography, Grid, } from '@material-ui/core'
import '../../../global/css/stylePortal.css'

const DetalleAca = (props) => {
    let { 
        tipo, 
        titulo, 
        universidad, 
        ciudad, 
        fecha_inicio_est, 
        fecha_final_est,
        en_curso_aca
    } = props.detalle
    let x = ''
    if(en_curso_aca == "1") x = 'Presente' 
    else x = fecha_final_est.substring(0, 4)
    return (
        <Grid item xs={12} md={12} style={{'marginBottom': '30px'}}>
            <Typography variant="h4" className="__titulo">
                {`${tipo.toUpperCase()} - ${titulo}`}
            </Typography>
            <Typography variant="h6" className="__lugar">
                {`${ universidad } - ${ciudad}`}
            </Typography>
            <Typography component="p">
                {`(${fecha_inicio_est.substring(0, 4)} - ${x})`}
            </Typography>
        </Grid>
    )
}

export default DetalleAca