import React, { useState, useEffect } from 'react';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { MENCIONES } from './helper';
import {
	consulta,
	obtenerValores,
} from '../../../global/js/funciones';
import {
	Dialog,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	DialogContent,
	DialogActions
} from '@material-ui/core/';

const ModalMenciones = ({ modalFechaSolicitud, handleCloseModal, matricula, actualizarMensaje }) => {
	const [menciones, setMenciones] = useState([]);
	const [mencion, setMencion] = useState('');

	useEffect(() => {
		obtenerValores(MENCIONES).then((data) => {
			setMenciones(data);
		});
	}, [])

	const CloseModal = () => {
		handleCloseModal()
		setMencion('')
  	};

	const agregarMencion = () => {
		let data = {
			mencion,
			matricula: matricula.id
		}
		consulta("api/v1.0/matricula/solicitud/agregarmencion", data, "POST", (error, estado, resp) => {
			let title = 'Ha ocurrido un error, contacte con el administrador.';
			let icon = 'error';
			if (!error) {
				if (estado === 200) {
					icon = 'success';
					title = resp.titulo;
				} else {
					icon = 'info';
					title = resp.titulo;
				}
			}
			actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
			handleCloseModal();
			setMencion('')
		});
	}

	return (
		<Dialog open={modalFechaSolicitud} fullWidth={true} maxWidth='sm' onClose={CloseModal}>
			<AppBarModal
				titulo='¡ Agregar Menciones !'
				mostrarModal={CloseModal}
				accion={CloseModal}
				titulo_accion='CERRAR'
			/>
			<DialogContent style={{ padding: 25 }} className='scroll'>
				<Grid container>
					<Grid item xs={12}>
						<FormControl className='form-control'>
							<InputLabel>Tipo de Menciones</InputLabel>
							<Select
								value={mencion}
								onChange={(e) => setMencion(e.target.value)}
							>
								{
									menciones.map((fg) => (
										<MenuItem key={fg.id} value={fg.id}> {fg.nombre} </MenuItem>
									))
								}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<BtnForm texto='Cerrar' callback={CloseModal} />
				<BtnForm texto='Editar' callback={agregarMencion} />
			</DialogActions>
		</Dialog>
	);
};

export default ModalMenciones;
