import React, { useState, useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../redux/actions/actInscripcionesPosgrado";
import * as actionCreatorsGlobal from "../../../redux/actions/actGlobal";
// Material UI
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  FormControl,
  TextField,
} from "@material-ui/core";

// General components
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnForm } from "../../general/BotonesAccion";
import CustomDrozone from "../../general/CustomDropzone";

// Components
import { MenuGestionarinscripcion } from "./MenuInscripciones";

// Golbal funcion
import {
  obtenerPermisosValor,
  obtenerValoresFiltros,
} from "../../../global/js/funciones";

// Images
import emma_w from "../../../global/imagenes/emma_w.png";

const InscripcionesPosgradoGestionar = ({
  inscripcion,
  gestionarInscripcion,
}) => {
  const { modalGestionar } = useSelector(
    (state) => state.redInscripcionesPosgrado
  );

  const dispatch = useDispatch();
  const { mostrarModalGestionarInsPosgrado } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { actualizarMensaje } = bindActionCreators(
    actionCreatorsGlobal,
    dispatch
  );

  const [cargando, setCargando] = useState(true);
  const [seleccion, setSeleccion] = useState("gestion");
  const [estado, setEstado] = useState("");
  const [estadoSiguiente, setEstadoSiguiente] = useState([]);
  const [estadoAdjunto, setEstadoAdjunto] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [tituloRespuesta, setTituloRespuesta] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [formatosArchivo, setFormatosArchivo] = useState([]);

  useEffect(() => {
    if (inscripcion) {
      (async () => {
        reiniciarEstados();
        const estadosSiguienteConsulta =
          inscripcion.estado_actual &&
          (await obtenerPermisosValor(inscripcion.estado_actual?.id));

        const formatosArchivoConsulta = await obtenerValoresFiltros([
          { llave: "codigo", valor: "For_Arc_Ins" },
        ]);

        setEstado("");
        setCargando(false);
        setEstadoAdjunto(
          estadosSiguienteConsulta.filter(
            ({ secundario }) => secundario.codigo === "Ins_Pos_Pre"
          )
        );
        setFormatosArchivo(
          formatosArchivoConsulta && formatosArchivoConsulta[0].valora
        );
        setEstadoSiguiente(estadosSiguienteConsulta);
      })();
    }
  }, [modalGestionar]);

  const reiniciarEstados = () => {
    setCargando(true);
    setEstado("");
    setEstadoSiguiente([]);
    setSeleccion("gestion");
  };

  const agregarArchivo = (arc) => {
    const nuevosArchivos = archivos.concat(arc);
    setArchivos(nuevosArchivos);
  };

  const eliminarArchivo = (index) => {
    const nuevosArchivos = [...archivos];
    nuevosArchivos.splice(index, 1);
    setArchivos(nuevosArchivos);
  };

  const onSubmit = async () => {
    setCargando(true);
    setTituloRespuesta("Enviando...");

    let data = {
      estado,
      archivos,
      total: archivos.length,
      observaciones,
    };

    gestionarInscripcion(inscripcion.id, data, () => {
      setCargando(false);
      setTituloRespuesta("Cargarndo...");
    });
  };

  const pintarEstados = () => {
    return (
      <Grid container>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <List
            subheader={
              <ListSubheader component="div">
                Seleccione el estado al que desea cambiar
              </ListSubheader>
            }
          >
            {estadoSiguiente.map(({ secundario }, index) => (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={() => setEstado(secundario.codigo)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={estado === secundario.codigo}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id={secundario.codigo}
                  primary={secundario.nombre}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl className="form-control">
            <TextField
              id="txtMotivoRechazo"
              label="Observación (Opcional)"
              name="motivo"
              placeholder="Escriba una observación para el estado actual"
              multiline
              onChange={(e) => setObservaciones(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const pintarAdjunto = () => {
    return (
      <CustomDrozone
        archivos={archivos}
        addFunction={agregarArchivo}
        deleteFunction={eliminarArchivo}
        actualizarMensaje={actualizarMensaje}
        acceptedFiles={formatosArchivo}
      />
    );
  };

  const pintar = () => {
    switch (seleccion) {
      case "gestion":
        return pintarEstados();
      case "adjuntos":
        return pintarAdjunto();
      default:
        return pintarEstados();
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={modalGestionar}
      onClose={() => dispatch(mostrarModalGestionarInsPosgrado(false))}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBarModal
        titulo={"¿ Gestionar Inscripcion ?"}
        mostrarModal={mostrarModalGestionarInsPosgrado}
        titulo_accion=""
        accion=""
      />
      <MenuGestionarinscripcion
        seleccionar={(seleccion) => setSeleccion(seleccion)}
        selec={seleccion}
        estadoAdjunto={estadoAdjunto}
        estado={estado}
      />
      <DialogContent style={{ padding: "1" }} className="scroll">
        {cargando ? (
          <TareasTerminadas
            mensaje={tituloRespuesta}
            marginTop="7%"
            imagen={emma_w}
            widthImg="7%"
            cargando={true}
          />
        ) : (
          <div style={{ padding: "5px 0 5px 0" }}> {pintar()} </div>
        )}
      </DialogContent>
      <DialogActions>
        <BtnForm
          texto="CANCELAR"
          callback={() => dispatch(mostrarModalGestionarInsPosgrado(false))}
        />
        {!cargando && <BtnForm texto="ACEPTAR" callback={onSubmit} />}
      </DialogActions>
    </Dialog>
  );
};

export default InscripcionesPosgradoGestionar;
