import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { getError } from '../../global/js/funciones';
import { coloresEmma } from '../../global/js/funciones';
const useStyles = makeStyles(theme => ({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

InputBuscar.propTypes = {
  //variables
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  errores: PropTypes.array
}

export default function InputBuscar({ required = true, errores = [], placeholder, label, id, value, callback = () => { }, icon_default = true }) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        required={required}
        error = { getError(`${ id }`, errores).length > 0 }
        helperText = { getError(`${ id }`, errores) }
        readOnly
        InputLabelProps={{
          shrink: true,
        }}
        type="text"
        id={`${id}_nombre`}
        label={label}
        value={value}
        fullWidth
        placeholder={placeholder}
        autoComplete="billing address-line1"
        onClick={
          callback
        }
      />
      <IconButton className={classes.iconButton} aria-label="search" onClick={callback} style={{ color: coloresEmma.secondarycolor }}>
        { icon_default ? <SearchIcon /> : <AddIcon /> } { /*icono de agregar para cuando el valor de icon_default sea false*/ }
      </IconButton>
    </div>
  );
}

