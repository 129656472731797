import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'
import ListarDatos from '../../general/ListarDatos'
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnGestionar, BtnDetalle } from '../../general/BotonesAccion'
import { consulta } from '../../../global/js/funciones'
import emma_w from "../../../global/imagenes/emma_w.png";
import DescargarHoJaDeVida from '../DescargarHoJaDeVida';


class ListarPostulantes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cargando: true,
            postulantes: []
        }
    }

    componentDidUpdate({ modalListarPostulantes }) {
        let { modalListarPostulantes: modalListarPostulantesNew, idVacantePostulantes } = this.props;
        if (modalListarPostulantes !== modalListarPostulantesNew && modalListarPostulantesNew) this.obtenerPostulantes(idVacantePostulantes)
    }

    obtenerPostulantes(id) {
        this.setState({ cargando: true });
        consulta("api/v1.0/portal/postulantes_listar", { id }, "post", (error, estado, resp) => {
            if (estado === 200) this.setState({ cargando: false, postulantes: resp }); // Mejorar esto .filter(vac => vac.estado_proceso.codigo == "Por_Proc") 
        })
    }

    actions = row => {
        let { obtenerEstadoProceso, listarInformacionPostulante } = this.props
        return (
            <div style={{display: 'flex'}}>
                <BtnDetalle
                    callback={() => {
                        listarInformacionPostulante(row)
                    }}
                />
                {
                    row.estado_proceso.codigo != 'Por_Rech' && row.estado_proceso.codigo != 'Por_Cont' &&
                    <BtnGestionar
                        callback={() => {
                            obtenerEstadoProceso(row)
                        }}
                    />
                }
                <DescargarHoJaDeVida
                    idDetallePostulante={row.postulante.id}
                />
            </div>
        )
    }

    obtenerColor = (codigo) => {
        let color = '#ffffff';
        const colores = {
            'Por_Proc': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Por_Rech': { 'backgroundColor': '#F91235', color },
            'Por_Acep': { 'backgroundColor': '#0d47a1', color },
            'Por_Cont': { 'backgroundColor': '#27F912', color }
        }
        return (colores[codigo])
    }

    renderListarPostulantes = () => {
        let { postulantes, cargando } = this.state
        return (
            <>
                {
                    !cargando ?
                        <ListarDatos
                            id="tbl_inf_ofertas"
                            datos={postulantes}
                            titulo={"Lista de postulantes"}
                            agregar={false}
                            descargar={''}
                            actAgregar={() => console.log('Hola')}
                            opciones={true}
                            buscar={true}
                            sub_titulo={"Lista de mis Ofertas"}
                            avatar={({ postulante }) => postulante.primer_nombre[0].toUpperCase()}
                            color_avatar={({ estado_proceso }) => this.obtenerColor(estado_proceso.codigo)}
                            acciones={row => this.actions(row)}
                            fila_principal={({ postulante }) => `${postulante.primer_nombre} ${postulante.segundo_nombre} ${postulante.primer_apellido} ${postulante.segundo_apellido}`}
                            filas={[
                                {
                                    'mostrar': ({ postulante }) => `Teléfono: ${postulante.telefono}`,
                                    'id': 'telefono',
                                    'enLista': true
                                },
                                {
                                    'mostrar': ({ postulante }) => `Correo: ${postulante.correo}`,
                                    'id': 'correo',
                                    'enLista': true
                                },
                                {
                                    'mostrar': ({ estado_proceso }) => `Estado Solicitud: ${estado_proceso.nombre}`,
                                    'id': 'nombre',
                                    'enLista': true
                                }
                            ]}
                        /> :
                        <TareasTerminadas mensaje={'Cargando..'} marginTop="7%" imagen={emma_w} widthImg="7%" />
                }
            </>
        )
    }

    render() {
        let {
            mostrarModalListarPostulantes,
            modalListarPostulantes
        } = this.props
        return (
            <div>
                <Dialog open={modalListarPostulantes} onClose={() => mostrarModalListarPostulantes(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={"Lista de Postulantes"} mostrarModal={() => mostrarModalListarPostulantes(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 0, overflowX: 'hidden' }} className="scroll">
                        <div>
                            {
                                this.renderListarPostulantes()
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalListarPostulantes(false)} />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ListarPostulantes;