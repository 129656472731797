import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography } from '@material-ui/core/';
import study from '../../../global/imagenes/study.png';
import cursosLibres from '../../../global/imagenes/cursoslibres.png';
import diplomados from '../../../global/imagenes/graduated.png';
import librespregrado from '../../../global/imagenes/librespregrado.png';
import solicitudGrado from '../../../global/imagenes/solicitudGrado.png';
import reintegros from '../../../global/imagenes/reintegros.png';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: '100%',
		minHeight: '50px',
		height: '300px'
	},
	image: {
		width: '100px',
		maxWidth: '100px',
		minWidth: '100px',
		height: '100px'
	},
	content_text: {
		padding: '0px 0px 0px 10px'
	},
	content_img: {
		textAlign: 'center',
		height: '150px'
	}
}));

export default function MatriculaActiva({ tipo, fecha, descripcion, estado }) {
	let fecha_r = moment(fecha).format('YYYY-MM-DD, h:mm a');
	const classes = useStyles();

	const obtenerColor = (codigo) => {
		let color = 'white';
		const colores = {
			Mat_Env: { backgroundColor: '#f5f5f5', color: '#757575' },
			Mat_Rev: { backgroundColor: '#ffc107', color },
			Mat_Rec: { backgroundColor: '#b71c1c', color },
			Mat_Ter: { backgroundColor: '#009688', color },
			Mat_Sol_Exo: { backgroundColor: '#009688', color },
			Mat_Pro_Sol_Cam_Fec: { backgroundColor: '#009688', color },
			Mat_Pro: { backgroundColor: '#ffc107', color },
			Mat_En_Est: { backgroundColor: "#AFF9DB", color: "#000"  },
			Mat_Com_Env: { backgroundColor: '#DBB62C', color },
			Mat_Pag: { backgroundColor: "#3498db", color },
			Mat_Aval_Fac_Rein_Tran: { backgroundColor: '#5cb85c', color },
      Mat_Act_Pen: { backgroundColor: "#ffc107", color },
			Mat_Act_Rev_Are: { backgroundColor: '#3498db', color },
			Mat_Rein_Tran_Doc_Ok: { backgroundColor: '#2950a6', color },
			Mat_Rein_Tran_Est: { backgroundColor: '#2950a6', color },
			Mat_Rein_Tran_Apr: { backgroundColor: '#ec9c06', color }
		};
		return colores[codigo];
	};

	return (
		<Card className={classes.card}>
			<CardHeader
				avatar={
					<Avatar aria-label="Matrícula" style={obtenerColor(estado.codigo)}>
						{' '}
						{estado.nombre[0]}{' '}
					</Avatar>
				}
				title={tipo.nombre}
				subheader={fecha_r}
			/>
			<CardContent>
				<Grid container direction={'row'} justifyContent={'center'}>
					<Grid item xs={12} sm={12} md={12} className={classes.content_img}>
						<img
							src={
								tipo.codigo === 'Mat_Pro_Dip' ? (
									diplomados
								) : tipo.codigo === 'Mat_Pro_Int' ? (
									study
								) : tipo.codigo === 'Mat_Pro_Pre' ? (
									librespregrado
								) : tipo.codigo === 'Mat_Pro_Sol_Gra' ? (
									solicitudGrado
								) : tipo.codigo === 'Mat_Pro_Sol_Rein_Tran' ? (
									reintegros
								) : (
									cursosLibres
								)
							}
							className={classes.image}
							alt="Imagen Tipo"
						/>
						<Typography variant="body2" color="textSecondary" component="p" align="justify">
							{descripcion}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
