import React from 'react'

import { Hidden, Badge, Tab } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Procesos from '@material-ui/icons/ImportContacts';
import CrearBeca from '@material-ui/icons/Edit'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Notificaciones from '@material-ui/icons/NotificationImportant';
// import SemillerosNotificaciones from './SemillerosNotificaciones'
import SettingsIcon from '@material-ui/icons/Settings';
import Instructivo from '@material-ui/icons/LocalLibrary';
import LoopIcon from "@material-ui/icons/Loop";
import MenuBookIcon from '@material-ui/icons/MenuBook';

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export default function MenuCursos(props) {

  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('miproceso', props.sele)} onClick={() => props.seleccionar('miproceso')}>
            <BottomNavigationAction label={'Solicitudes'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          {/* {props.vistaEstudiante!=true ?
            <SemillerosNotificaciones cargandoProcesos={props.cargandoProcesos} notificaciones={props.notificaciones} render={(click) => (<BottomNavigation showLabels className={clase('notificaciones', props.sele)}
            onClick={(e) => {click(e); props.seleccionar('notificacion');}}  ><BottomNavigationAction label={'Notificación'} icon={<Badge badgeContent={props.notificaciones.length} color="secondary"><Notificaciones /></Badge>} />  </BottomNavigation>)}  />
            :''} */}

          <BottomNavigation showLabels className={clase('nuevo', props.sele)} onClick={() => props.seleccionar('nuevo')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CrearBeca />} />
          </BottomNavigation>
          {/* {props.vistaEstudiante == true ?<BottomNavigation showLabels className={clase('Instructivo', props.sele)} onClick={() => window.open(props.instructivo_estudiante, '_blank')}>
              <BottomNavigationAction label={'Instructivo'} icon={<Instructivo />} />
            </BottomNavigation>
            :''} */}
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Solicitudes'} icon={<LocationOnIcon />} className={clase('miproceso', props.sele, '_app')} onClick={() => props.seleccionar('miproceso')} />
            {/* {props.vistaEstudiante!=true ?
              <SemillerosNotificaciones mdlIntegrantes={props.mdlIntegrantes} cargandoProcesos={props.cargandoProcesos} notificaciones={props.notificaciones} horizontal='left' render={(click) => <Tab showLabels onClick={click} label={'Notificación'} icon={<Badge badgeContent={props.notificaciones.length} color="secondary"><Notificaciones /></Badge>} className={clase('notificaciones', props.sele, '_app')} value='notificaciones' />} />
              :''} */}
            <BottomNavigationAction label={'Nuevo'} icon={<CrearBeca />} className={clase('nuevo', props.sele, '_app')} onClick={() => props.seleccionar('nuevo')} />
            {/* {props.vistaEstudiante == true ?<BottomNavigationAction label={'Instructivo'} icon={<Instructivo />} className={clase('instructivo', props.sele,'_app')}onClick={() => window.open(props.instructivo_estudiante, '_blank')}>
              </BottomNavigationAction>
              :''} */}
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function MenuGestionarCursos(props) {

  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('procesos', props.sele)} onClick={() => props.seleccionar('procesos')}>
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('admin', props.sele)} onClick={() => props.seleccionar('admin')}>
            <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
          </BottomNavigation>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('procesos', props.sele, '_app')} onClick={() => props.seleccionar('procesos')} />
            <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('admin', props.sele, '_app')} onClick={() => props.seleccionar('admin')} />
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

//   export function MenuCursosAdministrador(props) {

//     const pintar = (app, claseN = '') => {
//       if (!app) {
//         return (
//           <div className={claseN}>
//             <BottomNavigation showLabels className={clase('miproceso', props.sele)} onClick={() => props.seleccionar('miproceso')}>
//               <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
//             </BottomNavigation>
//             <BottomNavigation showLabels className={clase('administrar', props.sele)} onClick={() => props.seleccionar('administrar')}>
//               <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
//             </BottomNavigation>
//           </div>
//         )
//       } else {
//         return (
//           <div className={claseN}>
//             <BottomNavigation showLabels >
//               <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('miproceso', props.sele, '_app')} onClick={() => props.seleccionar('miproceso')} />
//               <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('administrar', props.sele, '_app')} onClick={() => props.seleccionar('administrar')} />
//             </BottomNavigation>
//           </div>
//         )
//       }
//     }

//     return (
//       <div>
//         <div>
//           <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
//           <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
//         </div>
//       </div>
//     );
//   }


export function CursoMenuDetalle({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction
            label={"Información General"}
            icon={<ImportContactsIcon />}
            className={clase("detalle", sele)}
            onClick={() => seleccionar("detalle")}
          />
          <BottomNavigationAction
            label={"Adjuntos"}
            icon={<MenuBookIcon />}
            className={clase("adjuntos", sele)}
            onClick={() => seleccionar("adjuntos")}
          />
          <BottomNavigationAction
            label={"Estados"}
            icon={<LoopIcon />}
            className={clase("estados", sele)}
            onClick={() => seleccionar("estados")}
          />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}
