import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import {
  Select, InputLabel, MenuItem, List, ListItem, ListItemIcon, ListItemText, ListSubheader,
  Checkbox, DialogActions, Grid, Dialog, DialogContent
} from '@material-ui/core';
import { FormControl, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { obtenerPermisosValor, obtenerValoresFiltros,crear_form_data} from "../../../global/js/funciones";
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from "../../general/BotonesAccion";
import CustomDropzone from "../../general/CustomDropzone";
import { MenuGestionar, MenuGestionarEstudiante } from "./CentroConciliacionMenu";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import BuscarPersona from '../../general/BuscarPersona';
import { api, consulta, formulario, mostrarError } from "../../../global/js/funciones";
import AlertasSimple from '../../general/AlertasSimple';


function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  
  return (
    <List subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
          let { codigo, nombre } = secundario;
          return (
            <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
              <ListItemIcon>
                <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText id={codigo} primary={nombre} />
            </ListItem>
          );
        })
      }
    </List>
  );
}


class CentroGestionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      observacion: '',
      estados_siguiente: [],
      archivos: [],
      seleccion: 'gestion',
      formatos_archivos: '',
      buscarGestor: false,
      gestor: {
        id: 0,
        nombre_completo: 'Clic aquí para buscar',
      }
    }
    this.fileInput = React.createRef();
  }

  async componentDidUpdate({ modalGestionarCentro }) {
    let { seleccion } = this.state;
    let { modalGestionarCentro: modalGestionarCentroNew, proceso, usuario } = this.props;
    if (modalGestionarCentro !== modalGestionarCentroNew) {
      let formatos_archivos = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'For_Arc_Centro' }]);
      let estados_siguiente = proceso.estado_actual ? await obtenerPermisosValor(proceso.estado_actual.id) : []
      let historialEstados= await this.getEstadosProcesos(proceso.id)
      let cantidad = historialEstados.filter(element => element.tipo_estado.codigo === 'Est_Centro_Agen_audiencia_con')
      let cantidadN = historialEstados.filter(element => element.tipo_estado.codigo === 'Est_Centro_Negar_Citacio')

      if(cantidad.length == 1 && cantidadN.length == 0){
        estados_siguiente.splice(0, 1);
      }else if(cantidad.length == 2){
        estados_siguiente.splice(1, 1);
      }
      this.setState({ estado: '', motivo: '', estados_siguiente, cargando: false, formatos_archivos: formatos_archivos ? formatos_archivos[0].valora : ''});
    }
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      area: '',
      titulo_res: 'Cargando..',
      estado: '',
      motivo: '',
      archivos: [],
      seleccion: 'gestion',
    })
  }

  async getEstadosProcesos(proceso_id) { 
    return new Promise(resolve => {
      consulta(`api/v1.0/centro/${proceso_id}/estados`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  agregarArchivos = (arc) => {
    let { archivos } = this.state;
    archivos = archivos.concat(arc);
    this.setState({ archivos })
  }

  eliminarArchivo = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }

  gestionarConsultoria = async (id, data, callback) => {
    let {actualizarMensaje } = this.props;
    actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 4000 });
    let form = await crear_form_data(data);
    data.archivos.map((file, index) => form.append(`archivo${index}`, file));
    formulario(`api/v1.0/consultoria/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }

  enviarDatos = async (e) => {
    let { actualizarMensaje } = this.props;
    let { estado, motivo, gestor, observacion, area, archivos } = this.state;
    let error = false
    if (estado === 'Est_Centro_AsigCar') {
      if (gestor.id === 0) {
        error = true
        actualizarMensaje({ titulo: `Debe asiganar un Gestor.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    } else if (estado === 'Est_Centro_Nega') {
      let { proceso } = this.props;
      if (motivo === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar un motivo de la negación.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }else{
        estado = "Est_Consul_Centro_Nega"
        let data = { area, motivo, observacion, estado, gestor: gestor.id, archivos, total: archivos.length }
        this.gestionarConsultoria(proceso.id_consultas_juridicas, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
      }
    } else if (estado === 'Est_Centro_Aprob') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado aprobado.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    else if (estado === 'Est_Centro_AsigCar') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado Carpeta asignada.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    else if (estado === 'Est_Centro_Agen_audiencia_con') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado Audiencia de conciliación agendada.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    else if (estado === 'Est_Centro_Red_Citaci') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado Citaciones redactadas.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    else if (estado === 'Est_Centro_Aprob_citaci') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado Citaciones Aprobadas.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    else if (estado === 'Est_Centro_Negar_Citacio') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado Citaciones negadas.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    else if (estado === 'Est_Centro_Entre_Citaci') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado Entregar citaciones.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    else if (estado === 'Est_Centro_CartaFir') {
      if (observacion === '') {
        error = true
        actualizarMensaje({ titulo: `Debe especificar una observacion para el estado Carta firmada.`, tipo: 'info', mostrar: true, tiempo: 6000 })
      }
    }
    if (!error) this.onSubmit()
  }

  onSubmit = () => {
    let { proceso } = this.props;
    let { estado, motivo, archivos, gestor, observacion } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = { motivo, observacion, estado, gestor: gestor.id, archivos, total: archivos.length }
    this.props.gestionarCentro(proceso.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    this.reiniciarEstado()
  }

  pintarAdjuntos() {
    let { formatos_archivos } = this.state
    return <CustomDropzone archivos={this.state.archivos} addFunction={this.agregarArchivos} deleteFunction={this.eliminarArchivo} actualizarMensaje={this.props.actualizarMensaje} acceptedFiles={formatos_archivos} />
  }

  pintarEstados() {
    let { estado, estados_siguiente, gestor } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
        </Grid>
        {estado === 'Est_Centro_Nega' && (
          <Grid item xs={12} md={12}>
            <FormControl className="form-control">
              <TextField
                id="txtMotivoRechazo"
                label="Motivo Negación"
                name="motivo"
                placeholder="Escriba el motivo de la Negación de la solicitud"
                multiline
                onChange={this.onChange}
              />
            </FormControl>
          </Grid>
        )}
        {estado === 'Est_Centro_AsigCar' && (<>
          <Grid container spacing={1} alignItems="flex-end" onClick={() => this.setState({ buscarGestor: true })} className='pointer' style={{ marginBottom: '10px' }}>
            <Grid item xs={11} xl={11} sm={11} md={11} lg={11}>
              <TextField label="Gestor" disabled={true} type="text" value={gestor.nombre_completo} style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={1} xl={1} sm={1} md={1} lg={1}>
              <SearchIcon style={{ color: '#c79e32' }} />
            </Grid>
          </Grid>
        </>
        )}
          <DialogContent>
            <AlertasSimple
                tipo='info'
                titulo={'Estimado Gestor, Recuerda que para pasar al siguiente estado, es de vital importancia ir al apartado de adjuntos, y agregar la documentación correspondiente en caso de ser necesaria.'}
                /* lista={'hola'} */
                margin='0px'
            />
          </DialogContent>
        <Grid item xs={12}>
          <TextField
            type="text"
            id="observacion"
            name="observacion"
            label="Escriba las Observaciones"
            multiline
            fullWidth
            rows={4}
            onChange={this.onChange}
          />
        </Grid>
      </Grid>
    )
  }

  pintar() {
    let { usuario } = this.props;
    let { seleccion } = this.state;
    return (
      <div>
        {seleccion === 'gestion' && this.pintarEstados()}
        {seleccion === 'adjuntos' && this.pintarAdjuntos()}
      </div>
    )
  }

  render() {
    let { modalGestionarCentro, mostrarModalGestionarCentro, usuario } = this.props;
    let { cargando, titulo_res, seleccion, buscarGestor } = this.state;
    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalGestionarCentro} onClose={mostrarModalGestionarCentro.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={'¿ Gestionar Centro ?'} mostrarModal={mostrarModalGestionarCentro} titulo_accion="" accion="" />
          {/* {usuario.perfil.codigo === "Per_Centro_Est" ? <MenuGestionarEstudiante seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} /> : <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} />} */}
          <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} />
          <DialogContent style={{ padding: '1' }} className='scroll'>
            {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} /> : <div style={{ padding: "5px 0 5px 0" }}> {this.pintar()} </div>}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalGestionarCentro(false)} />
            {!cargando && <BtnForm texto="ACEPTAR" callback={this.enviarDatos} />}
          </DialogActions>
        </Dialog>


        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarGestor} onClose={() => this.setState({ buscarGestor: false })}>
          <AppBarModal titulo={'¡ Buscar Gestor !'} mostrarModal={() => this.setState({ buscarGestor: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarGestor: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            < BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              gestor: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarGestor: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarGestor: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

CentroGestionar.propTypes = {
  //variables
  // inscripcion: PropTypes.object.isRequired,
  modalGestionarCentro: PropTypes.bool.isRequired,
  //funciones
  mostrarModalGestionarCentro: PropTypes.func.isRequired,

}

export default CentroGestionar;