import React, { Component } from 'react'
import { CODIGO_CURSO_CORTO, CODIGO_CURSO_CORTO_PROFE, CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD, DEPARTAMENTO_RESPONSABLE, DEPENDENCIAS_FINANCIACIONES_MOVILIDAD, FACULTAD_RESPONSABLE, SUBTIPOS_ACTIVIDADES_MOVILIDAD, SUBTIPO_ACTI_ACADEMICO, SUBTIPO_ACTI_EMPRESARIAL, TIPOS_ACTIVIDADES_MOVILIDAD, TIPOS_FINANCIACIONES_MOVILIDAD, TIPOS_INFORMES, TIPOS_MODALIDADES, TIPOS_MOVILIDADES, TIPO_MOVILIDADES_PROFESOR, UNIDAD_ADMIN_RESPONSABLE } from './helper';
import { consulta, generarFiltros, obtenerPermisosValorAlt, obtenerValoresFiltros } from '../../../global/js/funciones';
import ListarTarjetas from '../../general/ListarTarjetas';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from "../../../global/imagenes/emma_w.png";
import { Checkbox, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import BuscarSelect from '../../general/BuscarSelect';
import ListarTabla from '../../general/ListarTabla';
import ConvocatoriasBuscarConvenio from '../convocatorias/ConvocatoriasBuscarConvenio';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertasSimple from '../../general/AlertasSimple';
import ExportarReporteInter from './ExportarReporteInter';
import { PAISES } from '../administrar/helper';
import { es } from 'date-fns/locale'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

export default class InformesRegistro extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      cargandoGen: false,
      tipos_informes: [],
      mostrar: 'filtros',
      accion: '',
      modalInforme: false,
      informe: {},
      maxWidth: 'sm',
      modal_buscar_institucion: false,
      modal_quitar_institucion: false,
      // CAMPOS FILTROS
      periodosMulti: [],
      tipo_actividadMulti: [],
      subtipo_actividad: '',
      tipo_movilidad: [],
      tipo_modalidad: '',
      dpto_responsable: '',
      facultad_responsable: '',
      admin_responsable: '',
      es_financiado: '',
      tipo_financiacion: '',
      institucion_seleccionada: '',
      instituciones: [],
      dptos_lideresMulti: [],
      prog_lideresMulti: [],
      pais: '',
      fecha_inicio: null,
      fecha_fin: null,
      // CONSULTAS
      periodos: [],
      tipos_actividades: [],
      subtipos_actividades: [],
      tipos_modalidades: [],
      tipos_movilidades: [],
      departamentos_resp: [],
      facultades_resp: [],
      administrativos_resp: [],
      tipos_financiaciones: [],
      data_informe: [],
      num_final: [],
      dptos_lideres: [],
      prog_lideres: [],
      paises: [],
      departamentos_tributa: [],
      depart_tributa: '',
    }
  }

  async componentDidMount() {
    let { lideres } = this.props;
    if (!lideres) {
      this.setState({ cargando: true });
      let tipos_informes = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_INFORMES }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({ tipos_informes, cargando: false });
    } else {
      let permisos = await this.obtenerPermisosLideres();
      if (permisos) {
        this.setState({ dptos_lideres: permisos['perm_dptos'], prog_lideres: permisos['perm_prog'] });
      }
      this.setState({ mostrar: 'filtros', modalInforme: true })
    }
  }

  async componentDidUpdate({ }, { modalInforme, mostrar, accion, tipo_actividadMulti, subtipo_actividad, tipo_movilidad, tipo_actividad }) {
    let { mostrar: mostrarNew, accion: accionNew, modalInforme: modalInformeNew, tipo_actividadMulti: tipo_actividadMultiNew, subtipo_actividad: subtipo_actividadNew, tipo_movilidad: tipo_movilidadNew, tipos_actividades } = this.state;
    let { reset, lideres } = this.props;
    if (modalInforme !== modalInformeNew) {
      if (!modalInformeNew) {
        if (lideres) {
          reset()
        }
        this.setState({ accion: '', mostrar: 'filtros', periodosMulti: [], tipo_actividadMulti: [], subtipo_actividad: '', tipo_movilidad: [], tipo_modalidad: '', dpto_responsable: '', facultad_responsable: '', admin_responsable: '', es_financiado: '', tipo_financiacion: '', institucion_seleccionada: '', instituciones: [], dptos_lideresMulti: [], prog_lideresMulti: [], pais: '', data_informe: [], num_final: [], maxWidth: 'sm', fecha_inicio: null, fecha_fin: null })
      }
    }
    if (accion !== accionNew) {
      if (accionNew === 'filtro') {
        this.setState({ cargandoGen: true })
        // PERIODOS
        let periodos = await this.obtenerPeriodos();
        // TIPOS DE ACTIVIDADES
        let tipos_actividades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_ACTIVIDADES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': '1' }]);
        tipos_actividades.unshift({ 'codigo': "ActividadesAll", 'nombre': 'TODOS', 'id': 0 })
        // TIPOS DE SUBACTIVIDADES
        let subtipos_actividades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': SUBTIPOS_ACTIVIDADES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': '1' }]);
        // TIPOS DE MOVILIDADES
        let tipos_movilidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_MOVILIDADES }, { 'llave': 'estado', 'valor': 1 }]);
        // TIPOS DE MODALIDADES
        let tipos_modalidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_MODALIDADES }, { 'llave': 'estado', 'valor': 1 }]);
        //  DEPARTAMENTOS
        let departamentos_resp = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPARTAMENTO_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
        // FACULTADES
        let facultades_resp = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FACULTAD_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
        // UNIDADES ADMINISTRATIVAS
        let administrativos_resp = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIDAD_ADMIN_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
        // PAISES
        let paises = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PAISES }, { 'llave': 'estado', 'valor': 1 }]);
        // TIPO FINANCIACION
        let tipos_financiaciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);

        let departamentos_tributa = [];
        let administrativo = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPARTAMENTO_RESPONSABLE }, { 'llave': 'estado', 'valor': 1}, { 'llave': 'valorc', 'valor': 'administrativo'}]);
        let facultades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FACULTAD_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }]);
        let departamentos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPARTAMENTO_RESPONSABLE }, { 'llave': 'estado', 'valor': 1 }, {'llave': 'valorc', 'valor': 'docente' }])
        departamentos_tributa=[...facultades, ...departamentos, ...administrativo];

        this.setState({ periodos, tipos_actividades, subtipos_actividades, tipos_movilidades, tipos_modalidades, departamentos_resp, facultades_resp, administrativos_resp, cargandoGen: false, tipos_financiaciones, paises, departamentos_tributa, depart_tributa: '' })
      }
    }
    if (tipo_actividadMulti !== tipo_actividadMultiNew) {
      if (tipo_actividadMultiNew) {
        if (tipo_actividadMultiNew.includes('ActividadesAll')) {
          let tipos_actividad = tipos_actividades.filter(item => item.codigo !== 'ActividadesAll').map(item => item.codigo);
          console.log(tipos_actividad)
          this.setState({ tipo_actividadMulti: tipos_actividad, subtipo_actividad: '', tipo_movilidad: [], tipo_modalidad: '' })
        }
      }
    }
  }

  async obtenerPermisosLideres() {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/registro/listar_permisos_lideres`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target
    this.setState({ [name]: value })
  }

  seleccionarInstitucionActividad = ({ id, institucion }) => {
    const { instituciones } = this.state;
    this.setState({ instituciones: instituciones.concat({ id, institucion }) });
  }

  quitarInstiListarDatos = (id) => {
    const { instituciones } = this.state;
    const inst = instituciones.filter(inst => inst.id !== id);
    this.setState({ instituciones: inst });
  };

  quitarInstitucionSeleccionada = id => {
    const { instituciones } = this.state;
    if (id) this.setState({ modal_quitar_institucion: false, institucion_seleccionada: '', instituciones: instituciones.filter(inst => inst.id !== id) });
  };

  async obtenerPeriodos(id) {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitudes/periodos`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  pintarNombres = (selected, listado, tipo = null) => {
    let resp = [];
    let a = []
    if (tipo == 'periodo') {
      a = listado.filter((e) => selected.indexOf(e) != -1)
      a.map((e) => resp.push(e))
    } else if (tipo == 'codigo') {
      a = listado.filter(({ codigo }) => selected.indexOf(codigo) != -1)
      a.map(({ nombre }) => resp.push(nombre))
    } else if (tipo == 'relacion') {
      a = listado.filter(({ relacion }) => selected.indexOf(relacion.id) != -1)
      a.map(({ relacion: { nombre } }) => resp.push(nombre))
    } else {
      a = listado.filter(({ id }) => selected.indexOf(id) != -1)
      a.map(({ nombre }) => resp.push(nombre))
    }
    return resp.join(", ")
  }

  pintarInforme = () => {
    let { tipos_informes, cargando } = this.state;
    const acciones = (data) => {
      this.setState({ informe: { 'nombre': data.nombre, 'codigo': data.codigo }, modalInforme: true, accion: 'filtro' })
    }
    return (
      <>
        {tipos_informes.length === 1 ? (
          <Grid container >
            <Grid item xs={6} md={6} lg={6}>
              <ListarTarjetas
                datos={tipos_informes}
                titulo="Informes Internacionalización"
                acciones={(data) => acciones(data)}
                avatar={({ valora }) => valora.toUpperCase()}
                color_avatar={({ valorb }) => ({
                  backgroundColor: valorb ? valorb : '#EEEEEE',
                  color: valorb ? 'white' : '#757575'
                })}
              />
            </Grid>
          </Grid>
        ) : (
          <ListarTarjetas
            datos={tipos_informes}
            titulo="Informes Internacionalización"
            acciones={(data) => acciones(data)}
            avatar={({ valora }) => valora.toUpperCase()}
            color_avatar={({ valorb }) => ({
              backgroundColor: valorb ? valorb : '#EEEEEE',
              color: valorb ? 'white' : '#757575'
            })}
          />
        )}
      </>
    )
  }

  modalInforme = () => {
    let { modalInforme, informe, mostrar, cargandoGen, maxWidth } = this.state;
    let { lideres } = this.props;
    return (
      <>
        <Dialog fullWidth={true} maxWidth={maxWidth} open={modalInforme} onClose={() => this.setState({ modalInforme: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <AppBarModal titulo={lideres ? "Generar consolidado" : informe.nombre} mostrarModal={() => this.setState({ modalInforme: false })} titulo_accion={(mostrar === 'filtros') ? "Limpiar" : 'Volver'} /* accion={this.resetState} */ />
          <DialogContent className='scroll'>
            <Grid style={{ padding: "0% 4% 0% 0%" }} item xs={12} md={12}>
              {this.pintar()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto='Cerrar' callback={() => this.setState({ modalInforme: false })} />
            {!cargandoGen && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
          </DialogActions>
        </Dialog>
      </>
    )
  }

  onSubmit = (e) => {
    let { periodosMulti, tipo_actividadMulti, subtipo_actividad, tipo_movilidad, tipo_modalidad, informe, dpto_responsable, facultad_responsable, admin_responsable, es_financiado, tipo_financiacion, instituciones, dptos_lideresMulti, prog_lideresMulti, pais, fecha_inicio, fecha_fin, depart_tributa } = this.state;
    let { actualizarMensaje, lideres } = this.props;
    let filtros = [], titulo = ''


    if (!lideres) {
      filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
      if (periodosMulti.length === 0) {
        titulo = 'Por favor, seleccione al menos un periodo'
      }
      if (periodosMulti.length > 0) filtros.push({ 'llave': 'periodo_in', 'valor': periodosMulti })
      if (tipo_actividadMulti.length > 0) filtros.push({ 'llave': 'tipo_actividad', 'valor': tipo_actividadMulti })
      if (subtipo_actividad) filtros.push({ 'llave': 'subtipo_actividad', 'valor': subtipo_actividad })
      if (tipo_movilidad.length > 0) filtros.push({ 'llave': 'tipo_movilidad', 'valor': tipo_movilidad })
      if (tipo_modalidad) filtros.push({ 'llave': 'tipo_modalidad', 'valor': tipo_modalidad })
      // if (dpto_responsable) filtros.push({ 'llave': 'dpto_resp', 'valor': dpto_responsable })
      // console.log("depart_tributa a enviar: ", depart_tributa)
      if (depart_tributa) filtros.push({ 'llave': 'depart_tributa', 'valor': depart_tributa })
      if (facultad_responsable) filtros.push({ 'llave': 'facul_resp', 'valor': facultad_responsable })
      if (admin_responsable) filtros.push({ 'llave': 'admin_resp', 'valor': admin_responsable })
      if (es_financiado) filtros.push({ 'llave': 'es_financiado', 'valor': es_financiado })
      if (es_financiado === '1' && tipo_financiacion) filtros.push({ ' ': 'tipo_financiacion', 'valor': tipo_financiacion })
      if (instituciones.length > 0) filtros.push({ 'llave': 'institucion', 'valor': instituciones.map(({ id }) => id) })
      if (pais) filtros.push({ 'llave': 'pais', 'valor': pais })
      if (fecha_inicio) filtros.push({ llave: 'fecha_inicio', valor: moment(fecha_inicio).format('YYYY-MM-DD') });
      if (fecha_fin) filtros.push({ llave: 'fecha_fin', valor: moment(fecha_fin).format('YYYY-MM-DD') });
    } else {
      if (dptos_lideresMulti.length === 0 && prog_lideresMulti.length === 0) {
        titulo = 'Por favor, seleccione al menos un departamento o un programa'
      }
      filtros.push({ 'llave': 'informe', 'valor': 'informe_lideres' });
      if (dptos_lideresMulti.length > 0) filtros.push({ 'llave': 'dpto_solicitante', 'valor': dptos_lideresMulti })
      if (prog_lideresMulti.length > 0) filtros.push({ 'llave': 'prog_solicitante', 'valor': prog_lideresMulti })
    }

    if (titulo) {
      actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
    } else {
      // Cerramos el modal inmediatamente
      this.setState({ 
        cargandoGen: true,
      });
      this.obtenerInforme(filtros);  
  }

  e.preventDefault();
  }
  obtenerInforme = async (filtros = []) => {
    let { actualizarMensaje, lideres } = this.props;
    let f = await generarFiltros(filtros);
    let dataTotal = [];
    let max_data = 0;
    let offset = 0;
    const limit = 1500;
    let cargando = true;

    this.setState({ cargandoGen: true });

    try {
      do {
        const url = `api/v1.0/internacionalizacion/informe?${f}&limit=${limit}&offset=${offset}`;
        console.log(`Fetching: ${url}`);
        const respuesta = await new Promise((resolve, reject) => {
          consulta(url, null, null, (error, estado, resp) => {
            if (estado === 200) resolve(resp);
            else reject(error || 'Error al obtener datos');
          });
        });

        dataTotal = [...dataTotal, ...respuesta['data']];

        offset += limit;

        max_data = respuesta['max_data'];

        if (respuesta['data'].length < limit) cargando = false;
      } while (cargando);
      if (dataTotal.length === 0) {
        actualizarMensaje({ titulo: 'No se encontraron datos', tipo: 'info', mostrar: true, tiempo: 6000 });
        this.setState({ cargandoGen: false });
        return;
      }

      this.setState({ cargandoGen: false,  modalInforme: false, data_informe: dataTotal, mostrar: 'informe', maxWidth: lideres ? 'sm' : 'lg', num_final: max_data });
    } catch (error) {
      this.setState({ cargandoGen: false });
      actualizarMensaje({ titulo: 'Ha ocurrido un error al obtener los datos, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000, });
    }
  };



  // obtenerInforme = async (filtros = []) => {
  //   let { actualizarMensaje, lideres } = this.props
  //   let f = await generarFiltros(filtros);
  //   this.setState({ cargandoGen: true });
  //   consulta(`api/v1.0/internacionalizacion/informe?${f}`, null, null,
  //     (error, estado, resp) => {
  //       if (estado === 200) this.setState({ cargandoGen: false, data_informe: resp['data'], mostrar: 'informe', maxWidth: lideres ? 'sm' : 'lg', num_final: resp['max_data'] });
  //       else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
  //     }
  //   );

  // }

  pintar = () => {
    let { cargandoGen, mostrar } = this.state;
    if (cargandoGen) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
    return (
      <>
        {mostrar === 'filtros' && this.pintarFiltros()}
        {mostrar === 'informe' && this.pintarDataInforme()}
      </>
    )
  }

  pintarFiltros = () => {
    let { lideres } = this.props;
    let { periodos, periodosMulti, tipos_actividades, tipo_actividadMulti, subtipos_actividades, subtipo_actividad, tipos_movilidades, tipo_movilidad, tipos_modalidades, tipo_modalidad, departamentos_resp, facultades_resp, administrativos_resp, dpto_responsable, facultad_responsable, admin_responsable, es_financiado, tipos_financiaciones, tipo_financiacion, modal_quitar_institucion, modal_buscar_institucion, instituciones, institucion_seleccionada, dptos_lideres, prog_lideres, dptos_lideresMulti, prog_lideresMulti, paises, pais, fecha_inicio, fecha_fin, departamentos_tributa, depart_tributa } = this.state;
    const seleccion = institucion_seleccionada ? instituciones.find(inst => inst.id === institucion_seleccionada) : { id: 0 };

    return (
      <>
        <Grid container spacing={1} className='margin_cont'>
          {
            !lideres ? (
              <>
                <InputLabel>Campos a Filtrar</InputLabel>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodosMulti">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{ name: 'periodosMulti', id: 'periodosMulti' }}
                      multiple
                      value={periodosMulti}
                      onChange={this.onChange}
                      renderValue={selected => { return this.pintarNombres(selected, periodosMulti, 'periodo') }}
                      required={true}>
                      {periodos.map(({ periodo }, i) =>
                        <MenuItem key={i} value={periodo}>
                          <Checkbox checked={periodosMulti.indexOf(periodo) > -1} />
                          <ListItemText primary={periodo} />
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small'>
                    <InputLabel id='tipo_actividad_label'>Tipo de actividad</InputLabel>
                    <Select
                      labelId='tipo_actividad_label'
                      id='tipo_actividad'
                      name='tipo_actividad'
                      value={tipo_actividadMulti}
                      label='Tipo de actividad'
                      onChange={this.onChange}
                      fullWidth
                      renderValue={selected => { return this.pintarNombres(selected, tipos_actividades, 'codigo') }}
                      multiple
                      inputProps={{ name: 'tipo_actividadMulti', id: 'tipo_actividadMulti' }}
                    >
                      {
                        tipos_actividades.length > 0 ?
                          tipos_actividades
                            // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>
                              <Checkbox checked={tipo_actividadMulti.indexOf(tipo.codigo) > -1} />
                              <ListItemText primary={tipo.nombre} />
                            </MenuItem>)
                          : <MenuItem value=""><em>Seleccione tipo de movilidad</em></MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small'>
                    <InputLabel id='subtipo_actividad_label'>Subtipo de actividad</InputLabel>
                    <Select
                      labelId='subtipo_actividad_label'
                      id='subtipo_actividad'
                      name='subtipo_actividad'
                      value={subtipo_actividad}
                      label='Subtipo de actividad'
                      fullWidth
                      onChange={this.onChange}
                    // disabled={tipo_actividadMulti.length > 0 ? false : true}
                    >
                      {subtipos_actividades.length > 0 ?
                        subtipos_actividades
                          .map(({ id, codigo, nombre }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                        : <MenuItem value=""><em>No hay subtipos</em></MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small'>
                    <InputLabel id='tipo_movilidad_label'>Tipo de movilidad</InputLabel>
                    <Select
                      labelId='tipo_movilidad_label'
                      id='tipo_movilidad'
                      name='tipo_movilidad'
                      value={tipo_movilidad}
                      label='Tipo de movilidad'
                      onChange={this.onChange}
                      fullWidth
                      renderValue={selected =>
                        this.pintarNombres(selected, tipos_movilidades, 'codigo')
                      }
                      multiple
                      inputProps={{ name: 'tipo_movilidad', id: 'tipo_movilidad' }}
                    // disabled={tipo_actividadMulti.length > 0 ? false : true}
                    >
                      {
                        tipos_movilidades.length > 0 ?
                        tipos_movilidades
                          //.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                          .map(mov => <MenuItem key={mov.id} value={mov.codigo}>
                            <Checkbox checked={tipo_movilidad.indexOf(mov.codigo) > -1} />
                            <ListItemText primary={mov.nombre} />
                          </MenuItem>)
                        : <MenuItem value=""><em>Seleccione tipo de movilidad</em></MenuItem>
                          
                      }
                    </Select>
                    {/* <FormHelperText>{getError('tipo_movilidad', errores)}</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small'>
                    <InputLabel id='modalidad_label'>Tipo de modalidad</InputLabel>
                    <Select
                      labelId='modalidad_label'
                      id='tipo_modalidad'
                      name='tipo_modalidad'
                      value={tipo_modalidad}
                      label='tipo_modalidad'
                      onChange={this.onChange}
                      fullWidth
                    // disabled={tipo_movilidad !== '' ? false : true}
                    >
                      {tipos_modalidades.map(({ id, codigo, nombre }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                      }
                    </Select>
                    {/* <FormHelperText>{getError('tipo_modalidad', errores)}</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                <FormControl className='form-control' size='small' >
                    <InputLabel id='depart_tribu_label'>Departamento, unidad o dependecia a la que tributa</InputLabel>
                    <Select
                      labelId='depart_tribu_label'
                      id='depart_tributa'
                      name='depart_tributa'
                      value={depart_tributa}
                      fullWidth
                      onChange={this.onChange}
                    // multiple
                    // renderValue={selected => { return this.pintarNombres(selected, departamentos_resp, 'id') }}
                    >
                      {
                        departamentos_tributa
                          .map(({ nombre, id }) => {
                            return (
                              <MenuItem key={id} value={id}>
                                {/* <Checkbox checked={dpto_responsable.indexOf(id) > -1} /> */}
                                {nombre}
                                {/* <ListItemText primary={nombre} /> */}
                              </MenuItem>)
                          })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small' >
                    <InputLabel id='facultad_departamento_label'>Departamento responsable/s de la actividad</InputLabel>
                    <Select
                      labelId='facultad_departamento_label'
                      id='dpto_responsable'
                      name='dpto_responsable'
                      value={dpto_responsable}
                      fullWidth
                      onChange={this.onChange}
                    // multiple
                    // renderValue={selected => { return this.pintarNombres(selected, departamentos_resp, 'id') }}
                    >
                      {
                        departamentos_resp
                          .map(({ nombre, id }) => {
                            return (
                              <MenuItem key={id} value={id}>
                                {/* <Checkbox checked={dpto_responsable.indexOf(id) > -1} /> */}
                                {nombre}
                                {/* <ListItemText primary={nombre} /> */}
                              </MenuItem>)
                          })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small' >
                    <InputLabel id='facultad_responsable_label'>Facultad responsable/s de la actividad</InputLabel>
                    <Select
                      required
                      labelId='facultad_responsable_label'
                      id='facultad_responsable'
                      name='facultad_responsable'
                      value={facultad_responsable}
                      fullWidth
                      onChange={this.onChange}
                    // multiple
                    // renderValue={selected => { return this.pintarNombres(selected, facultades_resp, 'id') }}
                    >
                      {
                        facultades_resp.map(({ nombre, id }) => {
                          return (
                            <MenuItem key={id} value={id}>
                              {/* <Checkbox checked={facultad_responsable.indexOf(id) > -1} /> */}
                              {/* <ListItemText primary={nombre} /> */}
                              {nombre}
                            </MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small' >
                    <InputLabel id='ud_administrativa_responsable_label'>Unidad administrativa responsable/s de la actividad</InputLabel>
                    <Select
                      required
                      labelId='ud_administrativa_responsable_label'
                      id='admin_responsable'
                      name='admin_responsable'
                      value={admin_responsable}
                      fullWidth
                      onChange={this.onChange}
                    // multiple
                    // renderValue={selected => { return this.pintarNombres(selected, administrativos_resp, 'id') }}
                    >
                      {
                        administrativos_resp.map(({ nombre, id }) => {
                          return (<MenuItem key={id} value={id}>
                            {/* <Checkbox checked={admin_responsable.indexOf(id) > -1} />
                      <ListItemText primary={nombre} /> */}
                            {nombre}
                          </MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <FormControl className='form-control' size='small' >
                    <Select
                      id='institucion_seleccionada'
                      name='institucion_seleccionada'
                      value={institucion_seleccionada}
                      fullWidth
                      displayEmpty
                      onChange={this.onChange}
                      style={{ height: '2.8rem' }}
                    >
                      <MenuItem value="">
                        {instituciones.length === 0 ? 'Seleccione institución' :
                          instituciones.length === 1 ? instituciones[0].institucion : 'Seleccione institución'
                        }
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <ConvocatoriasBuscarConvenio
                    seleccionar={({ id, institucion }) => this.seleccionarInstitucionActividad({ id, institucion })}
                    quitar={({ id }) => this.quitarInstiListarDatos(id)}
                    seleccionadas={instituciones}
                    modalBuscarUniversidad={modal_buscar_institucion}
                    mostrarModalBuscarUniversidad={(modal_buscar_institucion) => this.setState({ modal_buscar_institucion })}
                    noMulti={true}
                  />
                  <ConfirmarAccion
                    mensaje1={institucion_seleccionada && `Se quitará ${seleccion.institucion} de la lista, `}
                    ejecutarAccion={this.quitarInstitucionSeleccionada}
                    mostrarModalConfAccion={(modal_quitar_institucion) => this.setState({ modal_quitar_institucion })}
                    modalConAccion={modal_quitar_institucion}
                    dato={institucion_seleccionada ? seleccion : { id: 0 }}
                    titulo={'¿ Quitar institución ?'}
                  />
                  <IconButton aria-label="add" style={{ color: 'darkgreen' }} onClick={() => this.setState({ modal_buscar_institucion: true })}>
                    <AddIcon />
                  </IconButton>
                  <IconButton aria-label="delete" style={{ color: 'darkred' }} onClick={() => this.setState({ modal_quitar_institucion: true })}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small' >
                    <InputLabel id='pais_label'>País de la institución de la actividad</InputLabel>
                    <Select
                      labelId='pais_label'
                      id='pais'
                      name='pais'
                      value={pais}
                      fullWidth
                      onChange={this.onChange}
                    // multiple
                    // renderValue={selected => { return this.pintarNombres(selected, departamentos_resp, 'id') }}
                    >
                      {
                        paises
                          .map(({ nombre, id }) => {
                            return (
                              <MenuItem key={id} value={id}>
                                {/* <Checkbox checked={dpto_responsable.indexOf(id) > -1} /> */}
                                {nombre}
                                {/* <ListItemText primary={nombre} /> */}
                              </MenuItem>)
                          })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className='form-control' >
                    <InputLabel id="label_financiacion">¿Hubo financiación de la actividad?</InputLabel>
                    <Select
                      labelId="label_financiacion"
                      id="es_financiado"
                      name='es_financiado'
                      value={es_financiado}
                      onChange={this.onChange}
                    >
                      <MenuItem value="1">Si</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {
                  es_financiado === '1' && (
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                      <FormControl className='form-control' >
                        <InputLabel id='tipo_financiacion_label'>Tipo de financiación</InputLabel>
                        <Select
                          labelId='tipo_financiacion_label'
                          id='tipo_financiacion'
                          name='tipo_financiacion'
                          value={tipo_financiacion}
                          label='tipo_financiacion'
                          fullWidth
                          onChange={this.onChange}
                        >
                          {tipos_financiaciones
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  )
                }
                <Grid item xs={12} xl={6} sm={6} md={6} lg={6}>
                  <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                    <KeyboardDatePicker
                      id='fecha_inicio'
                      label='Fecha inicial de registro'
                      name='fecha_inicio'
                      format='dd/MM/yyyy'
                      value={fecha_inicio}
                      onChange={(fecha_inicio) => this.setState({ fecha_inicio })}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} xl={6} sm={6} md={6} lg={6}>
                  <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                    <KeyboardDatePicker
                      id='fecha_inicio'
                      label='Fecha final de registro'
                      name='fecha_inicio'
                      format='dd/MM/yyyy'
                      value={fecha_fin}
                      onChange={(fecha_fin) => this.setState({ fecha_fin })}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )
              :
              <>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <AlertasSimple
                    tipo='info'
                    titulo={''}
                    sub_titulo={'Estimado líder, tenga en cuenta los siguientes puntos importantes:'}
                    lista={[
                      { "item": "Utilice los filtros para generar un archivo Excel con el consolidado de las solicitudes registradas hasta la fecha, basado en los criterios seleccionados." },
                      { "item": "Puede seleccionar varios departamentos y programas en la generación del consolidado." },
                      { "item": "Se mostrarán únicamente los departamentos y programas para los cuales tiene permisos asignados." },
                      { "item": "Si no tiene los permisos de departamentos y programas necesarios, por favor comuníquese con el departamento de tecnología." },
                    ]}
                    margin='0px'
                  />
                </Grid>
                <InputLabel>Campos a Filtrar</InputLabel>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small'>
                    <InputLabel id='permiso_dpto_label'>Departamento</InputLabel>
                    <Select
                      labelId='permiso_dpto_label'
                      id='permiso_dpto'
                      name='permiso_dpto'
                      value={dptos_lideresMulti}
                      label='Departamento'
                      onChange={this.onChange}
                      fullWidth
                      renderValue={selected => { return this.pintarNombres(selected, dptos_lideres, 'relacion') }}
                      multiple
                      inputProps={{ name: 'dptos_lideresMulti', id: 'dptos_lideresMulti' }}
                    >
                      {
                        dptos_lideres.length > 0 ?
                          dptos_lideres
                            // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(({ relacion }) => <MenuItem key={relacion.id} value={relacion.id}>
                              <Checkbox checked={dptos_lideresMulti.indexOf(relacion.id) > -1} />
                              <ListItemText primary={relacion.nombre} />
                            </MenuItem>)
                          : <MenuItem value=""><em>Seleccione el departamento</em></MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control' size='small'>
                    <InputLabel id='permiso_prog_label'>Programa</InputLabel>
                    <Select
                      labelId='permiso_prog_label'
                      id='permiso_prog'
                      name='permiso_prog'
                      value={prog_lideresMulti}
                      label='Programa'
                      onChange={this.onChange}
                      fullWidth
                      renderValue={selected => { return this.pintarNombres(selected, prog_lideres, 'relacion') }}
                      multiple
                      inputProps={{ name: 'prog_lideresMulti', id: 'prog_lideresMulti' }}
                    >
                      {
                        prog_lideres.length > 0 ?
                          prog_lideres
                            // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(({ relacion }) => <MenuItem key={relacion.id} value={relacion.id}>
                              <Checkbox checked={prog_lideresMulti.indexOf(relacion.id) > -1} />
                              <ListItemText primary={relacion.nombre} />
                            </MenuItem>)
                          : <MenuItem value=""><em>Seleccione el programa</em></MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </>

          }

        </Grid>
      </>
    )
  }

  pintarDataInforme = () => {
    let { lideres } = this.props;
    let { data_informe, num_final: { max_financiaciones: { num_ext_int, num_ext_nac, num_insti }, max_instituciones } } = this.state;
    if (data_informe.length > 0) {
      const titulo_informe = "CONSOLIDADO DE REGISTROS DE MOVILIDAD";
      let columnas = [
        { title: 'SOLICITANTE', field: 'solicitante', orden: 1 },
        { title: 'FECHA DE REGISTRO', field: 'fecha_solicitud', orden: 2 },
        { title: 'DEPARTAMENTO DEL SOLICITANTE', field: 'dpto_solicitante', orden: 3 },
        { title: 'PROGRAMA DEL SOLICITANTE', field: 'prog_solicitante', orden: 4 },
        { title: 'TIPO DE ACTIVIDAD', field: 'nombre_tipo_actividad', orden: 5 },
        { title: 'SUBTIPO DE ACTIVIDAD', field: 'nombre_subtipo_actividad', orden: 6 },
        { title: 'TITULO DEL EVENTO', field: 'nombre_titulo_evento', orden: 7 },
        { title: 'DESCRIPCION DE LA ACTIVIDAD', field: 'desc_actividad', orden: 8 },
        { title: 'DESCRIPCION DE RESULTADOS Y/O PRODUCTOS OBTENIDOS', field: 'desc_resultados', orden: 9 },
      ];

      let ordern_c = 10;



      for (let i = 1; i <= max_instituciones; i++) {
        columnas.push({ title: `INSTITUCIÓN ${i}`, field: `nombre_insti_act${i}`, orden: ordern_c++ });
        columnas.push({ title: `PAÍS ${i}`, field: `pais_insti_act${i}`, orden: ordern_c++ });
      }

      let columnas_add = [
        { title: 'PERIODO', field: 'periodo_sol', orden: ordern_c++ },
        { title: 'FECHA INICIO', field: 'fecha_ini', orden: ordern_c++ },
        { title: 'FECHA FINALIZACIÓN', field: 'fecha_fin', orden: ordern_c++ },
        { title: 'DEPARTAMENTO', field: 'dpto_resp', orden: ordern_c++ },
        { title: 'FACULTAD', field: 'facul_resp', orden: ordern_c++ },
        { title: 'UNIDADES ADMINISTRATIVAS RESPONSABLES', field: 'admin_resp', orden: ordern_c++ },
        { title: 'NOMBRE', field: 'nomb_parti', orden: ordern_c++ },
        { title: 'TIPO DE DOCUMENTO', field: 'tipo_doc_parti', orden: ordern_c++ },
        { title: 'NUMERO DE DOCUMENTO', field: 'num_identi_parti', orden: ordern_c++ },
        { title: 'INDICATIVO DEL PAÍS', field: 'indicativo_parti', orden: ordern_c++ },
        { title: 'TELÉFONO', field: 'telefono_parti', orden: ordern_c++ },
        { title: 'CORREO ELECTRÓNICO INSTITUCIONAL', field: 'correo_insti_parti', orden: ordern_c++ },
        { title: 'NACIONALIDAD', field: 'nacionalidad_parti', orden: ordern_c++ },
        { title: 'TIPO DE MOVILIDAD', field: 'tipo_movilidad_parti', orden: ordern_c++ },
        { title: 'TIPO DE MODALIDAD', field: 'tipo_modalidad_parti', orden: ordern_c++ },
        { title: '¿HUBO FINANCIACIÓN DE LA ACTIVIDAD?', field: 'hubo_fin', orden: ordern_c++ },
        { title: 'TIPO DE FINANCIACIÓN', field: 'tipo_fin', orden: ordern_c++ }
      ]

      columnas = columnas.concat(columnas_add);

      // Cuando es financiación institucional
      for (let i = 1; i <= num_insti; i++) {
        columnas.push({ title: `DEPENDENCIA ${i}`, field: `dependencia_${i}`, orden: ordern_c++ });
        columnas.push({ title: `INDIQUE EL VALOR EN PESOS COLOMBIANOS ${i}`, field: `valor_cop_insti_${i}`, orden: ordern_c++ });
        columnas.push({ title: `MONEDA EXTRANJERA ${i}`, field: `mon_ext_insti_${i}`, orden: ordern_c++ });
        columnas.push({ title: `EQUIVALENCIA EN MONEDA EXTRANJERA ${i}`, field: `valor_ext_insti_${i}`, orden: ordern_c++ });
        columnas.push({ title: `CLASIFICACIÓN DE FINANCIACIÓN ${i}`, field: `clasi_insti_${i}`, orden: ordern_c++ });
      }

      // Cuando es financiación externa nacional
      for (let i = 1; i <= num_ext_nac; i++) {
        columnas.push({ title: `FUENTE DE FINANCIACIÓN ${i}`, field: `fuente_finan_nac_${i}`, orden: ordern_c++ });
        columnas.push({ title: `ENTIDAD ${i}`, field: `enti_nac_${i}`, orden: ordern_c++ });
        columnas.push({ title: `INDIQUE EL VALOR EN PESOS COLOMBIANOS ${i}`, field: `valor_cop_nac_${i}`, orden: ordern_c++ });
        columnas.push({ title: `MONEDA EXTRANJERA ${i}`, field: `mon_ext_nac_${i}`, orden: ordern_c++ });
        columnas.push({ title: `EQUIVALENCIA EN MONEDA EXTRANJERA ${i}`, field: `valor_ext_nac_${i}`, orden: ordern_c++ });
        columnas.push({ title: `CLASIFICACIÓN DE FINANCIACIÓN ${i}`, field: `clasi_nac_${i}`, orden: ordern_c++ });
      }

      // Cuando es financiación externa internacional
      for (let i = 1; i <= num_ext_int; i++) {
        columnas.push({ title: `FUENTE DE FINANCIACIÓN ${i}`, field: `fuente_finan_ext_${i}`, orden: ordern_c++ });
        columnas.push({ title: `ENTIDAD ${i}`, field: `enti_ext_${i}`, orden: ordern_c++ });
        columnas.push({ title: `INDIQUE EL VALOR EN PESOS COLOMBIANOS ${i}`, field: `valor_cop_ext_${i}`, orden: ordern_c++ });
        columnas.push({ title: `MONEDA EXTRANJERA ${i}`, field: `mon_ext_ext_${i}`, orden: ordern_c++ });
        columnas.push({ title: `EQUIVALENCIA EN MONEDA EXTRANJERA ${i}`, field: `valor_ext_ext_${i}`, orden: ordern_c++ });
        columnas.push({ title: `CLASIFICACIÓN DE FINANCIACIÓN ${i}`, field: `clasi_ext_${i}`, orden: ordern_c++ });
      }

      columnas.push({ title: 'INSTITUCIÓN DE ORIGEN O DESTINO', field: 'nombre_insti', orden: ordern_c++ });
      columnas.push({ title: 'FACULTAD', field: 'facul_insti', orden: ordern_c++ });
      columnas.push({ title: 'DEPARTAMENTO', field: 'dpto_insti', orden: ordern_c++ });
      columnas.push({ title: 'NIVEL DE ESTUDIO', field: 'nivel_insti', orden: ordern_c++ });
      columnas.push({ title: 'PROGRAMA RECEPTOR', field: 'programa_insti', orden: ordern_c++ });
      columnas.push({ title: 'ARCHIVOS ADJUNTOS', field: 'archivos_adjuntos', orden: ordern_c++ });

      return (
        <ExportarReporteInter 
        data={data_informe} 
        columnas={columnas} 
        nombre={titulo_informe} 
        lideres={false}
        modal={() => {
          this.setState({ mostrar: 'filtros' }) 
        }}
        autoDownload={true} 
      />
    );
  } else {
    return (
      <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_w} widthImg="7%" />
    )
  }
  }

  render() {
    let { tipos_informes, cargando, modalInforme, data_informe, mostrar  } = this.state;
    let { lideres } = this.props;
    return (
      <>
        {cargando && <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
        {!cargando && tipos_informes && tipos_informes.length > 0 && this.pintarInforme()}
        {
          !cargando && modalInforme && !lideres && this.modalInforme()
        }
        {
          !cargando && lideres && this.modalInforme()
        }
        {!cargando && data_informe && data_informe.length > 0 && mostrar === 'informe' && this.pintarDataInforme()}
      </>
    )
  }
}