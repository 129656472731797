import React, { useEffect, useState } from "react";
import inicial from "../../global/imagenes/IlustracionInicial.svg";
import profesor from "../../global/imagenes/profesor informacion general.svg";
import hombre1 from "../../global/imagenes/Hombre1.svg";
import hombre2 from "../../global/imagenes/Hombre2.svg";
import hombre3 from "../../global/imagenes/Hombre3.svg";
import hombre4 from "../../global/imagenes/Hombre4.svg";
import hombre5 from "../../global/imagenes/Hombre5.svg";
import iconoResultado from "../../global/imagenes/IconoResultadosAprendizaje.svg";
import iconoUnidadesFor from "../../global/imagenes/IconoUnidadesFormacion.svg";
import iconoReferenciasBi from "../../global/imagenes/IconoReferenciasBibliograficas.svg";
import profesoraUnidades from "../../global/imagenes/profesoraUnidades.svg";
import iconoPregunta from "../../global/imagenes/IconoGranPregunta.svg";
import IcoCompGenericas from "../../global/imagenes/IconoCompetenciasGenericas.svg";
import IconoCompEspe from "../../global/imagenes/IconoCompetenciasEspecificas.svg";
import profesora from "../../global/imagenes/profesoraResumen.svg";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { BtnForm } from "../general/BotonesAccion";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import HelpIcon from "@material-ui/icons/Help";
import AppBarModal from "../general/AppBarModal";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
// import AppBar from '@material-ui/core/AppBar';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
  AppBar,
  Tabs,
  Tab,
  Link,
  TableBody,
  Paper,
} from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Button from "@material-ui/core/Button";
import useStyles from "./css.js";
import Grid from "@material-ui/core/Grid";
import { consulta } from "../../global/js/funciones";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReplyIcon from "@material-ui/icons/Reply";
import LensIcon from "@material-ui/icons/Lens";
// import ArchitectureIcon from '@material-ui/icons/Architecture';
import { coloresEmma } from "../../global/js/funciones";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ListarSyllabus = (props) => {
  let { status, toggle, dataPlan, statePlan } = props;
  const classes = useStyles();
  const [planAsignatura, setPlanAsignatura] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [unidadesIndi, setUnidadesIndi] = useState([]);
  const [contenidoFor, setContenidoFor] = useState([]);
  const [unidadesInden, setUnidadesInden] = useState([]);

  const [referencia, setReferencia] = useState([]);
  const [recursos, setRecursos] = useState([]);
  const [recursosHerra, setRecursosHerra] = useState([]);
  const [unidadDescp, setUnidadDescp] = useState([]);
  const [contenidoDes, setContenidoDes] = useState([]);
  const [indicador, setIndicador] = useState([]);
  const [resultado, setResultado] = useState([]);
  const [independiente, setIndependiente] = useState([]);
  const [presencial, setPresencial] = useState([]);
  const [evaluativa, setEvaluativa] = useState([]);
  const [estrategias, setEstrategias] = useState([]);
  const [mayor, setMayor] = useState([]);
  const [cargando, setCargando] = useState(true);

  // let estrategias = []

  const [Horario, setHorario] = useState([]);
  const [isCheck2, setIsCheck2] = useState(false);
  const [gran, setGran] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [idPlan, setIdPlan] = useState(0);
  const [imagen, setImagen] = useState("0");
  const [unidadesStep, setUnidadesStep] = useState("0");
  const [resumen, setResumen] = useState("");
  const [textoPregunta, setTextoPregunta] = useState("");

  const [texto, setTexto] = useState("1");
  const [asignatura, setAsignatura] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [unit, setUnit] = React.useState("1");
  const [pregunta, setPregunta] = useState("");
  const [docente, setDocente] = useState("");
  const [generica, setGenerica] = useState("");
  const [especifica, setEspecifica] = useState("");
  const [titulo, setTitulo] = useState("");
  const [contenido, setContenido] = useState("");
  const [posicionUnidades, setPosicionUnidades] = useState(0);
  const [posicionUnidadesUni, setPosicionUnidadesUni] = useState(0);
  const [posicionUnidadesResp, setPosicionUnidadesResp] = useState(0);
  const [granPregunta, setGranPregunta] = useState([]);
  const [competenciaG, setCompetenciaG] = useState([]);
  const [competenciaE, setCompetenciaE] = useState([]);

  const [value, setValue] = React.useState(0);
  const [valueUni, setValueUni] = React.useState(0);
  const [modal, setModal] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setImagen(newValue);
    if (newValue === 4) {
      setPosicionUnidades(0);
      obtenerContenidos(unidades.length > 0 ? unidades[0].id : -1);
    }
  };

  const handleChangeUni = (event, newValue) => {
    setValueUni(newValue);
    setUnidadesStep(newValue);
  };

  useEffect(() => {
    obtenerDataAsignatura(dataPlan.codigo, dataPlan.programa);
  }, [props.statePlan]);

  const obtenerDataAsignatura = async (materia, programa) => {
    setActiveStep(0);
    let data = await obtenerPlanesAsig(materia, programa);
    setCompetenciaE("");
    setGranPregunta("");
    setImagen(0);
    setValue(0);
    setResumen("");
    setCompetenciaG([]);
    setIdPlan(0);
    if (Array.isArray(data) && data.length > 0) {
      let idPlan = data[0].id;
      let ArrayCompG = [];
      await data.map((item, i) => {
        let GranP = item.gran_pregunta;
        let CompeG = item.competencia_generica;
        let CompeE = item.compentencia_especifica;
        let idPlan = item.id;
        if (i == 0) {
          setIdPlan(idPlan);
          setResumen(item.resumen);
        }
        if (CompeG) ArrayCompG.push(CompeG);
        if (CompeE) setCompetenciaE(item.compentencia_especifica);
        if (GranP) setGranPregunta(item.gran_pregunta);
      });
      setPlanAsignatura(data);
      obtenerUnidadesForma(idPlan);
      setCompetenciaG(ArrayCompG);
    } else {
      setPlanAsignatura([]);
      obtenerUnidadesForma(-1);
      setCompetenciaG([]);
    }
  };

  const obtenerUnidadesForma = async (idPlan) => {
    setCargando(true);
    setResultado([]);
    setUnidadDescp([]);
    setUnidades([]);
    let data = await obtenerUnidades(idPlan);
    if (Array.isArray(data) && data.length > 0) {
      let array = [];
      let arrayApren = [];
      await data.map((item) => {
        let descripcion = item.descripcion;
        let resultado = item.resultado_aprendizaje;
        array.push(descripcion);
        arrayApren.push(resultado);
      });
      setCargando(false);
      setResultado(arrayApren);
      setUnidadDescp(array);
      setUnidades(data);
    }
  };

  const getAllInfoUnidades = (unidad) => {
    obtenerUnidadesIndi(unidad);
    obtenerContenidos(unidad);
    obtenerUindependiente(unidad);
    obtenerUpresencial(unidad);
    obtenerUevaluativa(unidad);
    obtenerRecurso(unidad);
    obtenerReferencia(unidad);
    obtenerRecursosHerra(unidad);
    MostarEstrategias();
    MostarRecursos();
  };

  const obtenerUnidadesIndi = async (unidad) => {
    let data = await obtenerUnidadesIndicadoras(unidad);
    let array = [];
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((element) => {
        array.push(element.indicador);
      });
    }
    setIndicador(array);
    setUnidadesIndi(data);
  };
  const obtenerContenidos = async (unidad) => {
    setCargando(true);
    let data = await obtenerContenido(unidad);
    let array = [];
    if (Array.isArray(data) && data.length > 0) {
      data.map((item) => {
        let contenido = item.contenidos;
        if (contenido != null) {
          array.push(item.contenidos);
        }
      });
      setContenidoDes(array);
      setContenidoFor(data);
    } else {
      setContenidoDes([]);
      setContenidoFor([]);
    }
    setCargando(false);
  };
  const obtenerUnidadesTrabajo = async (unidad) => {
    let data = await obtenerUnidadesTrabajos();
    setUnidadesInden(data);
  };
  const obtenerReferencia = async (unidad) => {
    let data = await obtenerReferen(unidad);
    let array = [];
    if (Array.isArray(data) && data.length > 0) {
      data.forEach((element) => {
        array.push(element.descripcion);
      });
    }
    setReferencia(array);
  };

  const obtenerRecurso = async (unidad) => {
    let data = await obtenerRecursos(unidad);

    setRecursos(data);
    return recursos;
  };

  const obtenerUindependiente = async (unidad) => {
    let data = await unidadIndependiente(unidad);

    setIndependiente(data);
    estrategias.push("1" + data);
  };

  const obtenerUpresencial = async (unidad) => {
    let data = await unidadPresencial(unidad);

    setPresencial(data);
    estrategias.push("2" + data);
  };

  const obtenerUevaluativa = async (unidad) => {
    let data = await unidadEvaluativa(unidad);

    setEvaluativa(data);
    estrategias.push("3" + data);
  };

  const obtenerRecursosHerra = async (unidad) => {
    let data = await obtenerRecursos(unidad);
    setRecursosHerra(data);
    return recursos;
  };

  //Endpoints
  const obtenerUnidades = (idPlan) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidades?plan=${idPlan}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };
  const obtenerPlanesAsig = (materia, programa) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan?materia=${materia}&programa=${programa}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };
  const obtenerUnidadesIndicadoras = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/indicadores?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };
  const obtenerContenido = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/contenidos?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };
  const obtenerUnidadesTrabajos = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/trabajos?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };
  const obtenerReferen = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/referencias?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };
  const obtenerRecursos = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/recursos?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  const unidadIndependiente = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/independiente?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  const unidadPresencial = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/presencial?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  const unidadEvaluativa = (unidad) => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/consultas/plan/unidad/evaluativa?unidad=${unidad}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  };

  const unidadesFormacionMovil = () => {
    console.log(contenidoDes);
    //  if(contenidoDes.length > 0){
    //   contenidoDes.map((item, i) => (
    //     <Box
    //       fontWeight="fontWeightMedium"
    //       style={{ textAlign: "center" }}
    //     >
    //       {item}
    //     </Box>
    //   ))
    //  }else{
    //     return <h3>No hay informacion</h3>
    //  }
  };

  const Introduccion = ({
    setImagen,
    setResumen,
    setTitulo,
    classname,
    design,
  }) => {
    // let resumenC = ["Al contrario del pensamiento popular, el texto de Lorem Ipsum no es simplemente texto aleatorio. Tiene sus raices en una pieza cl´sica de la literatura del Latin, que data del año 45 antes de Cristo, haciendo que este adquiera mas de 2000 años de antiguedad. Richard McClintock, un profesor de Latin de la Universidad de Hampden-Sydney en Virginia, encontró una de las palabras más oscuras de la lengua del latín, consecteur, en un pasaje de Lorem Ipsum, y al seguir leyendo distintos textos del latín, descubrió la fuente indudable. Lorem Ipsum viene de las secciones 1.10.32 y 1.10.33 de de Finnibus Bonorum et Malorum (Los Extremos del Bien y El Mal) por Cicero, escrito en el año 45 antes de Cristo. Este libro es un tratado de teoría de éticas, muy popular durante el Renacimiento. La primera linea del Lorem Ipsum, Lorem ipsum dolor sit amet.., viene de una linea en la sección 1.10.32 Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño. El punto de usar Lorem Ipsum es que tiene una distribución más o menos normal de las letras, al contrario de usar textos como por ejemplo Contenido aquí, contenido aquí. Estos textos hacen parecerlo un español que se puede leer. Muchos paquetes de autoedición y editores de páginas web usan el Lorem Ipsum como su texto por defecto, y al hacer una búsqueda de Lorem Ipsum va a dar por resultado muchos sitios web que usan este texto si se encuentran en estado de desarrollo. Muchas versiones han evolucionado a través de los años, algunas veces por accidente, otras veces a propósito (por ejemplo insertándole humor y cosas por el estilo)."]
    let resumenC = [resumen];
    let final = resumenC[0].length;
    let pagina = 800;
    let residuo = 0;
    if (pagina == final || pagina > final) {
      residuo = 1;
    } else {
      residuo = Math.trunc(final / 800) + 1;
    }
    let extraida = "";
    let sw = true;
    let resumenArray = [];

    let h = pagina;
    for (let i = 0; i < residuo; i++) {
      resumenArray.push(resumenC[0].substring(h - 800, h));
      h = h + 800;
    }

    return (
      <>
        {isMobile ? (
          <>
            <Grid
              item
              xs={12}
              style={{
                textAling: "center",
                padding: "10px",
                background: coloresEmma.primarycolor,
              }}
            >
              <Typography style={{ color: coloresEmma.fondocolor }}>
                <Box
                  textAlign="center"
                  fontSize={18}
                  fontWeight="fontWeightMedium"
                >
                  INTRODUCCION A LA ASIGNATURA
                </Box>
              </Typography>
            </Grid>
            <div style={{ textAling: "center" }} className={classname}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", padding: "0 10px" }}
                >
                  <h1>Resumen</h1>
                  <div
                    className="scroll"
                    style={{
                      height: 380,
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <Typography>
                      <Box fontWeight="fontWeightRegular" fontSize={15}>
                        {resumen == "" ? (
                          <h3>
                            Por el momento no se ha cargado informacion en esta
                            asignatura
                          </h3>
                        ) : (
                          resumen
                        )}
                      </Box>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5px",
                background: coloresEmma.primarycolor,
              }}
            >
              <Link onClick={handleStep(0)}>
                <ReplyIcon style={{ color: coloresEmma.fondocolor }} />
              </Link>
              <Link onClick={handleStep(0)}>
                <Typography>
                  <Box
                    fontSize={15}
                    fontWeight="fontWeightMedium"
                    style={{ color: coloresEmma.fondocolor }}
                  >
                    Regresar
                  </Box>
                </Typography>
              </Link>
            </Grid>
          </>
        ) : (
          <>
            <div style={{ textAling: "center" }} className={classname}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={6}
                  lg={6}
                  xl={5}
                  style={{ float: "left", padding: "0px 80px 10px 50px" }}
                >
                  <h1>Resumen</h1>
                  <div
                    className="scroll"
                    style={{
                      height: 260,
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <Typography style={{ paddingRight: "20px" }}>
                      <Box fontWeight="fontWeightRegular" fontSize={15}>
                        {resumen == "" ? (
                          <h3>
                            Por el momento no se ha cargado informacion de esta
                            asignatura
                          </h3>
                        ) : (
                          resumen
                        )}
                      </Box>
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={0}
                  sm={4}
                  md={6}
                  lg={6}
                  xl={2}
                  className="ContainerIntro"
                >
                  <div style={{ textAling: "center" }}>
                    <img
                      src={setImagen}
                      width="220px"
                      style={{ paddingTop: "5px", textAlign: "center" }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </>
    );
  };

  const Informacion = ({
    setDepartamento,
    setImagen,
    setAsignatura,
    setDocente,
    setRequisito,
    setHoras,
    setCreditos,
    classes,
    tablero,
  }) => {
    return (
      <>
        {isMobile ? (
          <>
            <Grid
              item
              xs={12}
              style={{
                textAling: "center",
                padding: "8px",
                background: coloresEmma.primarycolor,
              }}
            >
              <Typography style={{ color: coloresEmma.fondocolor }}>
                <Box
                  textAlign="center"
                  fontSize={18}
                  fontWeight="fontWeightMedium"
                >
                  INFORMACIÓN GENERAL
                </Box>
              </Typography>
            </Grid>
            <div className={classes}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} className={tablero}>
                  {/* TABLA */}
                  <TableContainer style={{ color: "#2C2D2D" }}>
                    <Table
                      className={classes.table}
                      aria-label="spanning table"
                    >
                      <TableHead>
                        <TableCell colSpan={13}>
                          <Typography>
                            <Box
                              textAlign="center"
                              fontSize={16}
                              fontWeight="fontWeightBold"
                            >
                              {setDepartamento}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableRow>
                          <TableCell align="left" colSpan={13} pageSize={5}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Asignatura <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.nombre : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" colSpan={13}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Código <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.codigo : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={13}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Tipo de Asignatura{" "}
                                  <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.tipo : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" colSpan={6}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Total, horas <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.horas : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                          <TableCell align="left" colSpan={6}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Créditos <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>
                                  {dataPlan ? dataPlan.creditos : null}
                                </span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Profesor <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span className="textTablero">
                                  {dataPlan ? dataPlan.docente : null}
                                </span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Prerrequisito{" "}
                                  <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>
                                  {dataPlan && dataPlan.prerrequisito ? dataPlan.prerrequisito : 'Ninguno'}
                                </span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5px",
                background: coloresEmma.primarycolor,
              }}
            >
              <Link onClick={handleStep(0)}>
                <ReplyIcon style={{ color: coloresEmma.fondocolor }} />
              </Link>
              <Link onClick={handleStep(0)}>
                <Typography>
                  <Box
                    fontSize={15}
                    fontWeight="fontWeightMedium"
                    style={{ color: coloresEmma.fondocolor }}
                  >
                    Regresar
                  </Box>
                </Typography>
              </Link>
            </Grid>
          </>
        ) : (
          <>
            <div className={classes}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <img
                    src={setImagen}
                    width="170px"
                    style={{ paddingTop: "5px" }}
                  />
                </Grid>
                <Grid item xs={6} className={tablero}>
                  {/* TABLA */}
                  <TableContainer style={{ color: "#2C2D2D" }}>
                    <Table
                      className={classes.table}
                      aria-label="spanning table"
                    >
                      <TableHead>
                        <TableCell colSpan={13}>
                          <Typography>
                            <Box
                              textAlign="center"
                              fontSize={16}
                              fontWeight="fontWeightBold"
                            >
                              {setDepartamento}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableRow>
                          <TableCell align="left" colSpan={6} pageSize={5}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Asignatura<b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.nombre : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Código<b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.codigo : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Tipo de <br /> Asignatura{" "}
                                  <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.tipo : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                          <TableCell align="left" colSpan={3}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  {" "}
                                  Total, horas<b className="lineaTablero">
                                    {" "}
                                  </b>{" "}
                                </b>
                                <span>{dataPlan ? dataPlan.horas : null}</span>
                              </Box>
                            </Typography>
                          </TableCell>
                          <TableCell align="left" colSpan={3}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Créditos<b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>
                                  {dataPlan ? dataPlan.creditos : null}
                                </span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Profesor <b className="lineaTablero"> </b>{" "}
                                </b>
                                <span className="textTablero">
                                  {dataPlan ? dataPlan.docente : null}
                                </span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Typography>
                              <Box fontSize={15}>
                                <b>
                                  Prerrequisito<b className="lineaTablero"> </b>{" "}
                                </b>
                                <span>
                                  {dataPlan && dataPlan.prerrequisito ? dataPlan.prerrequisito : 'Ninguno'}
                                </span>
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </>
    );
  };

  const Imagen = ({ setImagen, diseño }) => {
    return (
      <div className="ImagenSyllabus">
        {isMobile ? (
          <img src={setImagen} width="100%" style={{ textAlign: "center" }} />
        ) : (
          <img src={setImagen} width="50%" style={{ textAlign: "center" }} />
        )}
      </div>
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const GranPregunta = ({
    imagen,
    setTexto1,
    classesContenido,
    design,
    isCheck,
  }) => {
    // const [activeStep, setActiveStep] = React.useState('');
    //setTexto1=obtenerPreguntas()
    return (
      <>
        {isMobile ? (
          <>
            <div className={classes.rootPregunta}>
              <Grid
                item
                xs={12}
                style={{
                  textAling: "center",
                  padding: "10px",
                  background: coloresEmma.primarycolor,
                }}
              >
                <Typography style={{ color: coloresEmma.fondocolor }}>
                  <Box
                    textAlign="center"
                    fontSize={18}
                    fontWeight="fontWeightMedium"
                  >
                    LA GRAN PREGUNTA
                  </Box>
                </Typography>
              </Grid>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="true"
                  aria-label="scrollable auto tabs example"
                  // onClick={() => setImagen(value)}
                >
                  <Tab label="Gran pregunta" {...a11yProps(0)} />
                  <Tab label="Competencias Genericas" {...a11yProps(1)} />
                  <Tab label="Competencias especificas" {...a11yProps(2)} />
                  <Tab label="Resultado de aprendizaje" {...a11yProps(3)} />
                  <Tab label="Unidades de formación" {...a11yProps(4)} />
                </Tabs>
              </AppBar>
              <div className={classes.RespuestaMovil}>
                <TabPanel value={value} index={0}>
                  {setTexto1}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {setTexto1}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {setTexto1}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  {setTexto1}
                </TabPanel>
                <TabPanel value={value} index={4}>
                  {setTexto1}
                </TabPanel>
              </div>
            </div>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5px",
                background: coloresEmma.primarycolor,
              }}
            >
              <Link onClick={handleStep(0)}>
                <ReplyIcon style={{ color: coloresEmma.fondocolor }} />
              </Link>
              <Link onClick={handleStep(0)}>
                <Typography>
                  <Box
                    fontSize={15}
                    fontWeight="fontWeightMedium"
                    style={{ color: coloresEmma.fondocolor }}
                  >
                    Regresar
                  </Box>
                </Typography>
              </Link>
            </Grid>
          </>
        ) : (
          <>
            <Grid container className={design}>
              <Grid
                item
                xs={2.5}
                sm={2.5}
                md={3}
                lg={3}
                xl={2.5}
                style={{ paddingTop: "25px", textAlign: "end" }}
              >
                <img src={imagen} height="380px" />
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={3}
                lg={3}
                xl={2}
                style={{ textAling: "start" }}
                className="btnPregunta"
              >
                <div className="btnPreguntas2">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        variant="rounded"
                        style={{ width: "30px", height: "30px" }}
                        src={iconoPregunta}
                      />
                    }
                    className={classesContenido}
                    // style={{ background: i == posicionUnidades && coloresEmma.primarycolor }}
                    onClick={() => {
                      setImagen(0);
                      // setActiveStep("pregunta")
                    }}
                  >
                    <Typography>
                      <Box lineHeight={1}>Gran Pregunta</Box>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        variant="rounded"
                        className={classes.avatar}
                        src={IcoCompGenericas}
                      />
                    }
                    className={classesContenido}
                    onClick={() => setImagen(1)}
                  >
                    <Typography>
                      <Box lineHeight={1}>
                        Competencias <br /> genéricas
                      </Box>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        variant="rounded"
                        className={classes.avatar}
                        src={IconoCompEspe}
                      />
                    }
                    className={classesContenido}
                    onClick={() => setImagen(2)}
                  >
                    <Typography>
                      <Box lineHeight={1}>Competencias especificas</Box>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        variant="rounded"
                        className={classes.avatar}
                        src={iconoResultado}
                      />
                    }
                    className={classesContenido}
                    onClick={() => {
                      setPosicionUnidades(0);
                      setImagen(3);
                    }}
                  >
                    <Typography>
                      <Box lineHeight={1}>Resultados de aprendizaje</Box>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        variant="rounded"
                        className={classes.avatar}
                        src={iconoUnidadesFor}
                      />
                    }
                    className={classesContenido}
                    onClick={() => {
                      setPosicionUnidades(0);
                      obtenerContenidos(
                        unidades.length > 0 ? unidades[0].id : -1
                      );
                      setImagen(4);
                    }}
                  >
                    <Typography>
                      <Box lineHeight={1}>Unidades de formación</Box>
                    </Typography>
                  </Button>
                </div>
              </Grid>
              {/* CONTAINER DE INFORMACION */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className={classes.RespuestasP}
              >
                <Typography>
                  <Box fontWeight="fontWeightMedium">{setTexto1}</Box>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };
  function BotonesMenu(props) {
    const classes = useStyles();
    const { titulo, indice, isCheck } = props;
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.botonesM}
      >
        <Grid item xs={3} lg={3}>
          <div
            class="circulo"
            style={{ backgroundColor: isCheck && "#750000" }}
          >
            <div
              class="circulo_interno"
              style={{
                backgroundColor: isCheck && "#750000",
                border: isCheck && "solid 3px #ffffff",
              }}
            >
              <div
                class="indice"
                style={{
                  backgroundColor: isCheck && "#750000",
                  color: isCheck && "#ffffff",
                }}
              >
                {indice}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={1} lg={1}>
          <div id="lineaVertical" />
        </Grid>
        <Grid item xs={3} lg={8}>
          <Typography className={classes.textoMenu}>{titulo}</Typography>
          <Button
            id="Steps"
            variant="contained"
            size="large"
            className={classes.botonesMenu}
            aria-label="add"
            style={{ backgroundColor: isCheck && coloresEmma.primarycolor }}
            onClick={handleStep(indice)}
          >
            <Box textAlign="center" fontWeight="fontWeightLight" lineHeight={2}>
              VER MÁS
            </Box>
            <NavigateNextIcon style={{ color: coloresEmma.fondocolor }} />
          </Button>
        </Grid>
      </Grid>
    );
  }
  const UnidadesItems = ({
    setTitulo,
    setContenido,
    classesContenido,
    classname,
  }) => {
    return (
      <>
        {isMobile ? (
          <div className={classname}>
            <Grid container direction="row" justifyContent="center">
              <div className={classes.rootUnidades}>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAling: "center",
                    padding: "10px",
                    background: coloresEmma.primarycolor,
                  }}
                >
                  <Typography style={{ color: coloresEmma.fondocolor }}>
                    <Box
                      textAlign="center"
                      fontSize={18}
                      fontWeight="fontWeightMedium"
                    >
                      UNIDADES DE FORMACIÓN
                    </Box>
                  </Typography>
                </Grid>
                <AppBar position="static" color="default">
                  <Tabs
                    value={valueUni}
                    onChange={handleChangeUni}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    // onClick={() => setImagen(value)}
                  >
                    {/* <Tab label={<HelpIcon />}{...a11yProps(0)} /> */}
                    <Tab label="Introducción" {...a11yProps(0)} />
                    <Tab label="Estrategias Didacticas" {...a11yProps(1)} />
                    <Tab label="Recursos Educativos" {...a11yProps(2)} />
                    <Tab label="Referencias Bibliograficas" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
                <div className={classes.RespuestaMovil}>
                  <TabPanel value={valueUni} index={0}>
                    {setTitulo}
                    {setContenido}
                  </TabPanel>
                  <TabPanel value={valueUni} index={1}>
                    {setTitulo}
                    {setContenido}
                  </TabPanel>
                  <TabPanel value={valueUni} index={2}>
                    {setTitulo}
                    {setContenido}
                  </TabPanel>
                  <TabPanel value={valueUni} index={3}>
                    {setTitulo}
                    {setContenido}
                  </TabPanel>
                  {/* <TabPanel value={valueUni} index={4}>
                        {setTitulo}
                        {setContenido}
                      </TabPanel> */}
                </div>
              </div>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                  background: coloresEmma.primarycolor,
                }}
              >
                <Link onClick={handleStep(4)}>
                  <ReplyIcon style={{ color: coloresEmma.fondocolor }} />
                </Link>
                <Link onClick={handleStep(4)}>
                  <Typography>
                    <Box
                      fontSize={15}
                      fontWeight="fontWeightMedium"
                      style={{ color: coloresEmma.fondocolor }}
                    >
                      Regresar
                    </Box>
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={classname}>
            <Grid container direction="row" justifyContent="center">
              <Grid
                md={5}
                lg={12}
                style={{ textAlign: "center", padding: "10px" }}
              >
                <Typography>
                  <Box
                    fontWeight="fontWeightBold"
                    fontSize={28}
                    style={{
                      color: "#0E344B",
                      paddingBottom: "5px",
                      textAling: "center",
                    }}
                  >
                    {setTitulo}
                  </Box>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={8}
                xl={5}
                style={{ paddingRight: "20px" }}
              >
                <Typography>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize={14}
                    style={{ float: "left" }}
                  >
                    {setContenido}
                  </Box>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                lg={2}
                xl={2}
                className="UnidadesItems"
              >
                <div className="UnidadesItems2">
                  <Button
                    variant="contained"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        className={classes.avatar}
                        src={IcoCompGenericas}
                      />
                    }
                    className={classesContenido}
                    onClick={() => {
                      setUnidadesStep(0);
                    }}
                    // onClick={handleStep(8)}
                  >
                    <Typography>
                      <Box lineHeight={1}>Introducción</Box>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar src={IconoCompEspe} className={classes.avatar} />
                    }
                    className={classesContenido}
                    onClick={() => {
                      // handleStep(9)
                      setUnidadesStep(1);
                    }}
                    // onClick={handleStep(9)}
                  >
                    <Typography>
                      <Box lineHeight={1}>Estrategias Didacticas</Box>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        src={iconoUnidadesFor}
                        className={classes.avatar}
                      />
                    }
                    className={classesContenido}
                    onClick={() => {
                      // handleStep(10)
                      setUnidadesStep(2);
                    }}
                    // onClick={handleStep(10)}
                  >
                    <Typography>
                      <Box lineHeight={1}>Recursos Educativos</Box>
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    aria-label="add"
                    startIcon={
                      <Avatar
                        src={iconoReferenciasBi}
                        className={classes.avatar}
                      />
                    }
                    className={classesContenido}
                    onClick={() => {
                      // handleStep(11)
                      setUnidadesStep(3);
                    }}
                    // onClick={handleStep(11), setUnidadesStep(4) }
                  >
                    <Typography>
                      <Box lineHeight={1}>Referencias Bibliográficas</Box>
                    </Typography>
                  </Button>
                </div>
              </Grid>
              <Grid item lg={1} style={{ textAlign: "end" }}>
                <Button className="btnClose" onClick={handleStep(4)}>
                  <CancelIcon style={{ color: coloresEmma.primarycolor, fontSize: "40px" }} />
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  };
  function obtenerTitulo() {
    return (
      <>
        <Typography className={classes.textoMenu}>
          LA GRAN <br /> PREGUNTA
        </Typography>
      </>
    );
  }
  function obtenerInformacion() {
    return (
      <>
        <Typography className={classes.textoMenu}>
          INFORMACIÓN <br /> GENERAL
        </Typography>
      </>
    );
  }

  function introduccion() {
    let arrayIntro = [];
    let unidad = unidadDescp[0];
    arrayIntro.push(
      <h2>
        <b>{unidad}</b>
      </h2>
    );
    let ra = resultado[0];
    // arrayIntro.push(<br></br>)
    arrayIntro.push(
      <span>
        <b>{"RA 1: "}</b> {ra}
      </span>
    );
    arrayIntro.push(<br></br>);
    arrayIntro.push(<h3>{"INDICADORES DE DESEMPEÑO"}</h3>);
    // arrayIntro.push(<br/>)
    // arrayIntro.push(indicador)
    indicador.forEach((element, i) => {
      arrayIntro.push(i + 1 + "." + " " + element);
      arrayIntro.push(<br />);
    });
    arrayIntro.push(<h3>{"CONTENIDOS"}</h3>);
    contenidoDes.forEach((element, i) => {
      arrayIntro.push(i + 1 + "." + " " + element);
      arrayIntro.push(<br />);
    });

    return arrayIntro;
  }

  const EstrategiaPresencial = () => {
    if (presencial.length > 0) {
      return presencial.map((item, i) => (
        <Grid container className={i % 2 == 0 ? "filaAzul" : "filaNaranja"}>
          <Grid className="filas" item xs={12} sm={12} md={12} lg={12} xl={12}>
            • {item.descripcion}
          </Grid>
        </Grid>
      ));
    } else {
      return (
        <h3>Por el momento no se ha cargado informacion de esta estrategia</h3>
      );
    }
  };

  const EstrategiaIndependiente = () => {
    if (independiente.length > 0) {
      return independiente.map((item, i) => (
        <Grid container className={i % 2 == 0 ? "filaAzul" : "filaNaranja"}>
          <Grid className="filas" item xs={12} sm={12} md={12} lg={12} xl={12}>
            • {item.descripcion}
          </Grid>
        </Grid>
      ));
    } else {
      return (
        <h3>Por el momento no se ha cargado informacion en esta asignatura</h3>
      );
    }
  };

  const EstrategiaEvaluativa = () => {
    if (evaluativa.length > 0) {
      return evaluativa.map((item, i) => (
        <Grid container className={i % 2 == 0 ? "filaAzul" : "filaNaranja"}>
          <Grid className="filas" item xs={12} sm={12} md={12} lg={12} xl={12}>
            • {item.descripcion}
          </Grid>
        </Grid>
      ));
    } else {
      return (
        <h3>Por el momento no se ha cargado informacion en esta asignatura</h3>
      );
    }
  };

  const MostrarIndicadores = () => {
    if (indicador.length > 0) {
      return indicador.map((element, i) => {
        return (
          <Typography>
            <Box fontWeight="fontWeightMedium" fontSize={14}>
              {i + 1 + "." + " " + element}
              <br />
            </Box>
          </Typography>
        );
      });
    } else {
      return (
        <h3>Por el momento no se ha cargado informacion en esta asignatura</h3>
      );
    }
  };
  const MostarEstrategias = () => {
    return "";
    if (
      presencial.length > independiente.length &&
      presencial.length > evaluativa.length
    ) {
      return presencial.map((item, i) => (
        <TableRow>
          <TableCell>{item.descripcion}</TableCell>
          <TableCell>{independiente[i].descripcion}</TableCell>
          <TableCell>{evaluativa[i].descripcion}</TableCell>
        </TableRow>
      ));
    } else if (
      independiente.length > presencial.length &&
      independiente.length > evaluativa.length
    ) {
      return independiente.map((item, i) => (
        <TableRow>
          <TableCell>{presencial[i].descripcion}</TableCell>
          <TableCell>{item.descripcion}</TableCell>
          <TableCell>{evaluativa[i].descripcion}</TableCell>
        </TableRow>
      ));
    } else if (
      evaluativa.length > presencial.length &&
      evaluativa.length > independiente.length
    ) {
      return evaluativa.map((item, i) => (
        <TableRow>
          <TableCell>{presencial[i].descripcion}</TableCell>
          <TableCell>{independiente[i].descripcion}</TableCell>
          <TableCell>{item.descripcion}</TableCell>
        </TableRow>
      ));
    } else if (
      independiente.length == presencial.length &&
      independiente.length == evaluativa.length &&
      evaluativa.length == presencial.length
    ) {
      return independiente.map((item, i) => (
        <Grid container className={i % 2 == 0 ? "filaAzul" : "filaNaranja"}>
          <Grid className="filas" item xs={4} sm={4} md={4} lg={4} xl={4}>
            • {presencial[i].descripcion}
          </Grid>
          <Grid className="filas" item xs={4} sm={4} md={4} lg={4} xl={4}>
            • {item.descripcion}
          </Grid>
          <Grid className="filas" item xs={4} sm={4} md={4} lg={4} xl={4}>
            • {evaluativa[i].descripcion}
          </Grid>
        </Grid>
      ));
    }
  };
  const MostarRecursos = () => {
    if (recursosHerra.length > 0) {
      return recursosHerra.map((item, i) => (
        <Grid container className={i % 2 == 0 ? "filaAzul" : "filaNaranja"}>
          <Grid className="filas" item xs={4} sm={4} md={4} lg={4} xl={4}>
            • {item.equipos} <br />
          </Grid>
          <Grid className="filas" item xs={4} sm={4} md={4} lg={4} xl={4}>
            • {item.herramientas} <br />
          </Grid>
          <Grid className="filas" item xs={4} sm={4} md={4} lg={4} xl={4}>
            • {item.materiales} <br />
          </Grid>
        </Grid>
      ));
    } else {
      return (
        <h3>Por el momento no se ha cargado informacion en esta asignatura</h3>
      );
    }
  };
  const traerGranPregunta = () => {
    let text = granPregunta;
    if (text) {
      text = text.split("?");
      return (
        <Typography>
          {text.map((t, i) => (
            <Box fontWeight="fontWeightMedium" style={{ textAlign: "center" }}>
              {t + `${i < text.length - 1 ? "?" : ""}`} <br />
              <br />
            </Box>
          ))}
        </Typography>
      );
    } else {
      return (
        <h3>Por el momento no se ha cargado informacion en esta asignatura</h3>
      );
    }
  };
  const traerCompetenciasG = () => {
    let aux = [];
    if (competenciaG == "") {
      return (
        <h3>Por el momento no se ha cargado informacion en esta asignatura</h3>
      );
    } else {
      competenciaG.map((item) =>
        aux.push(
          <Typography>
            <Box fontWeight="fontWeightMedium" style={{ textAlign: "center" }}>
              {item}
            </Box>
          </Typography>
        )
      );
      return aux;
    }
  };
  function getStepContent(step) {
    switch (step) {
      //PRINCIPAL DEL SYLLABUS
      case 0:
        return (
          <>
            {isMobile ? (
              <>
                {/* <Grid item xs={12}>
                    <img src={inicial} width="100%" style={{ textAlign: "center" }} />
                  </Grid> */}
                <Grid
                  container
                  className={classes.menuPrincipal}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Grid
                    item
                    xs={12}
                    style={{
                      textAling: "center",
                      padding: "10px",
                      background: coloresEmma.primarycolor`,
                    }}
                  >
                    <Typography style={{ color: coloresEmma.fondocolor }}>
                      <Box
                        textAlign="center"
                        fontSize={18}
                        fontWeight="fontWeightMedium"
                      >
                        SYLLABUS
                      </Box>
                    </Typography>
                  </Grid> */}

                  <Grid item xs={12} sm={12} md={12}>
                    <BotonesMenu
                      titulo={obtenerInformacion()}
                      indice={1}
                      isCheck={activeStep == 1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BotonesMenu
                      titulo={"INTRODUCCIÓN A LA ASIGNATURA"}
                      indice={2}
                      isCheck={activeStep == 2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BotonesMenu
                      titulo={obtenerTitulo()}
                      indice={3}
                      isCheck={activeStep == 3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BotonesMenu
                      titulo={"UNIDADES DE FORMACIÓN "}
                      indice={4}
                      isCheck={activeStep == 4}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Imagen
                class={classes.solosimg}
                setImagen={inicial}
                setTexto={setTexto}
                type="1"
              />
            )}
          </>
        );
      //INFORMACIÓN GENERAL
      case 1:
        return (
          <>
            <Informacion
              tablero={classes.tablero}
              classes={classes.informacion}
              setDepartamento={"DEPARTAMENTO"}
              setImagen={profesor}
              setAsignatura={asignatura}
              setDocente={docente}
              setRequisito=""
              setHoras="144"
              setCreditos=" 3"
            />
          </>
        );
      //INTRODUCCIÓN A LA ASIGNATURA
      case 2:
        return (
          <Introduccion
            classname={classes.introduccion}
            setImagen={profesora}
          />
        );
      //LA GRAN PREGUNTA
      case 3:
        let ras = unidades.map((item, i) => {
          return item.id;
        });
        return (
          <div>
            {imagen == 0 ? (
              <div>
                <GranPregunta
                  classesContenido={classes.buttons}
                  design={classes.granPregunta}
                  imagen={hombre1}
                  valor={1}
                  setTexto1={
                    isMobile ? (
                      <>
                        <div
                          className="scroll"
                          style={{
                            height: 300,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            textAlign: "center",
                          }}
                        >
                          {traerGranPregunta()}
                        </div>
                      </>
                    ) : (
                      <>{traerGranPregunta()}</>
                    )
                  }
                />
              </div>
            ) : imagen == 1 ? (
              <div>
                <GranPregunta
                  classesContenido={classes.buttons}
                  design={classes.granPregunta}
                  imagen={hombre2}
                  valor={1}
                  setTexto1={traerCompetenciasG()}
                />
              </div>
            ) : imagen == 2 ? (
              <div>
                <GranPregunta
                  classesContenido={classes.buttons}
                  design={classes.granPregunta}
                  imagen={hombre3}
                  valor={1}
                  setTexto1={
                    <Typography>
                      <Box
                        fontWeight="fontWeightMedium"
                        style={{ textAlign: "center" }}
                      >
                        {competenciaE == "" ? (
                          <h3>
                            Por el momento no se ha cargado informacion en esta
                            asignatura
                          </h3>
                        ) : (
                          competenciaE
                        )}
                      </Box>
                    </Typography>
                  }
                />
              </div>
            ) : imagen == 3 ? (
              <div>
                <GranPregunta
                  classesContenido={classes.buttons}
                  design={classes.granPregunta}
                  imagen={hombre4}
                  valor={1}
                  setTexto1={
                    <>
                      {isMobile ? (
                        <>
                          <Typography>
                            <Box
                              fontWeight="fontWeightBold"
                              style={{ textAlign: "center" }}
                            >
                              RA {[posicionUnidades + 1]}
                            </Box>
                            <Box
                              fontWeight="fontWeightMedium"
                              style={{ textAlign: "center" }}
                            >
                              {unidades.length > 0 ? (
                                unidades[posicionUnidades].resultado_aprendizaje
                              ) : (
                                <h3>
                                  Por el momento no se ha cargado informacion en
                                  esta asignatura
                                </h3>
                              )}
                            </Box>
                          </Typography>
                          <Grid xs={12} className="contenedorCirculos">
                            {ras.map((item, i) => (
                              <>
                                <div
                                  class="circulo2"
                                  onClick={() => setPosicionUnidades(i)}
                                  style={{
                                    background:
                                      i == posicionUnidades && coloresEmma.primarycolor,
                                  }}
                                ></div>
                              </>
                            ))}
                          </Grid>
                        </>
                      ) : (
                        <>
                          {/* {resultado === "" ? <h3>No hay nada</h3>: "hay algo"} */}
                          <Typography>
                            <Box
                              fontWeight="fontWeightBold"
                              style={{ textAlign: "center" }}
                            >
                              RA {[posicionUnidades + 1]}
                            </Box>
                            <Box
                              fontWeight="fontWeightMedium"
                              style={{ textAlign: "center" }}
                            >
                              {unidades.length > 0 ? (
                                unidades[posicionUnidades].resultado_aprendizaje
                              ) : (
                                <h3>
                                  Por el momento no se ha cargado informacion en
                                  esta asignatura
                                </h3>
                              )}
                            </Box>
                          </Typography>
                          <Grid xs={12} className="contenedorCirculos">
                            {ras.map((item, i) => (
                              <>
                                <div
                                  class="circulo2"
                                  onClick={() => setPosicionUnidades(i)}
                                  style={{
                                    background:
                                      i == posicionUnidades && coloresEmma.primarycolor,
                                  }}
                                ></div>
                              </>
                            ))}
                          </Grid>
                        </>
                      )}
                    </>
                  }
                />
              </div>
            ) : imagen == 4 ? (
              <div>
                <GranPregunta
                  classesContenido={classes.buttons}
                  design={classes.granPregunta}
                  imagen={hombre5}
                  valor={1}
                  setTexto1={
                    <Grid container justify="center" alignItems="center">
                      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography>
                          <Box
                            fontWeight="fontWeightBold"
                            style={{ textAlign: "center" }}
                          >
                            {unidadDescp[posicionUnidadesUni]}
                          </Box>
                          <br />
                          {cargando ? (
                            <Box
                              fontWeight="fontWeightMedium"
                              style={{ textAlign: "center", color: coloresEmma.primarycolor }}
                            >
                              cargando...
                            </Box>
                          ) : (
                            <div>
                              {contenidoDes.length > 0 ? (
                                contenidoDes.map((item, i) => (
                                  <Box
                                    fontWeight="fontWeightMedium"
                                    style={{ textAlign: "center" }}
                                  >
                                    {`${
                                      posicionUnidadesUni +
                                      1 +
                                      "." +
                                      (i + 1) +
                                      "."
                                    } ${item}`}
                                  </Box>
                                ))
                              ) : (
                                <h3>
                                  Por el momento no se ha cargado informacion en
                                  esta asignatura
                                </h3>
                              )}
                            </div>
                          )}
                        </Typography>
                      </Grid>
                      <Grid xs={12} className="contenedorCirculos2">
                        {unidades.map((item, i) => (
                          <>
                            <div
                              class="circulo2"
                              onClick={() => {
                                obtenerContenidos(
                                  unidades.length > 0 ? unidades[0].id : -1
                                );
                                setPosicionUnidadesUni(i);
                              }}
                              style={{
                                background:
                                  i == posicionUnidadesUni && coloresEmma.primarycolor,
                              }}
                            ></div>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  }
                />
              </div>
            ) : null}
          </div>
        );
      //UNIDADES DE INFORMACIÓN
      case 4:
        return (
          <>
            {isMobile ? (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAling: "center",
                    padding: "10px",
                    background: coloresEmma.primarycolor,
                  }}
                >
                  <Typography style={{ color: "#fff" }}>
                    <Box
                      textAlign="center"
                      fontSize={18}
                      fontWeight="fontWeightMedium"
                    >
                      UNIDADES DE FORMACIÓN
                    </Box>
                  </Typography>
                </Grid>
                <div className={classes.unidadesFormacion}>
                  <Grid container>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          textAlign: "-webkit-center",
                          paddingTop: "20px",
                        }}
                      >
                        <Button
                          id="bots"
                          variant="contained"
                          size="large"
                          aria-label="add"
                          className={classes.redondo}
                          onClick={() => {
                            setActiveStep(8);
                            setPosicionUnidades(0);
                            getAllInfoUnidades(
                              unidades.length > 0 ? unidades[0].id : 0
                            );
                            setUnidadesStep(0);
                            setValueUni(0);
                          }}
                        >
                          {"Unidad 1"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Button
                          id="bots"
                          variant="contained"
                          size="large"
                          aria-label="add"
                          className={classes.redondo}
                          onClick={() => {
                            getAllInfoUnidades(
                              unidades.length > 1 ? unidades[1].id : -1
                            );
                            setPosicionUnidades(1);
                            setActiveStep(8);
                            setUnidadesStep(0);
                            setValueUni(0);
                          }}
                        >
                          {"Unidad 2"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Button
                          id="bots"
                          variant="contained"
                          size="large"
                          aria-label="add"
                          className={classes.redondo}
                          onClick={() => {
                            getAllInfoUnidades(
                              unidades.length > 2 ? unidades[2].id : 0
                            );
                            setPosicionUnidades(2);
                            setActiveStep(8);
                            setUnidadesStep(0);
                            setValueUni(0);
                          }}
                        >
                          {"Unidad 3"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                    background: coloresEmma.primarycolor,
                  }}
                >
                  <Link onClick={handleStep(0)}>
                    <ReplyIcon style={{ color: "#fff" }} />
                  </Link>
                  <Link onClick={handleStep(0)}>
                    <Typography>
                      <Box
                        fontSize={15}
                        fontWeight="fontWeightMedium"
                        style={{ color: "#fff" }}
                      >
                        Regresar
                      </Box>
                    </Typography>
                  </Link>
                </Grid>
              </>
            ) : (
              <>
                <div className={classes.unidadesFormacion}>
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{ paddingTop: "5px" }}
                      className="Unidades"
                    >
                      <img src={profesoraUnidades} width="450px" />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      className="Unidades"
                    >
                      <Button
                        id="bots"
                        variant="contained"
                        size="large"
                        aria-label="add"
                        className={classes.redondo}
                        onClick={() => {
                          setActiveStep(8);
                          setPosicionUnidades(0);
                          getAllInfoUnidades(
                            unidades.length > 0 ? unidades[0].id : -1
                          );
                          setUnidadesStep(0);
                        }}
                      >
                        {"Unidad 1"}
                      </Button>
                      <Button
                        id="bots"
                        variant="contained"
                        size="large"
                        aria-label="add"
                        className={classes.redondo}
                        onClick={() => {
                          setActiveStep(8);
                          getAllInfoUnidades(
                            unidades.length > 1 ? unidades[1].id : -1
                          );
                          setPosicionUnidades(1);
                          setUnidadesStep(0);
                        }}
                      >
                        {"Unidad 2"}
                      </Button>
                      <Button
                        id="bots"
                        variant="contained"
                        size="large"
                        aria-label="add"
                        className={classes.redondo}
                        onClick={() => {
                          setActiveStep(8);
                          getAllInfoUnidades(
                            unidades.length > 2 ? unidades[2].id : -1
                          );
                          setPosicionUnidades(2);
                          setUnidadesStep(0);
                        }}
                      >
                        {"Unidad 3"}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </>
        );
      //VISTA DE LOS BOTONES DE UNIDADES
      case 5:
        return (
          <UnidadesItems
            classesContenido={classes.buttonsUnidades}
            classname={classes.introduccionUnidades}
          />
        );
      case 6:
        return (
          <UnidadesItems
            classesContenido={classes.buttonsUnidades}
            classname={classes.introduccionUnidades}
          />
        );
      case 7:
        return (
          <UnidadesItems
            classesContenido={classes.buttonsUnidades}
            classname={classes.introduccionUnidades}
          />
        );

      //INFORMACION DE LOS BOTONES DE UNIDADES
      case 8:
        return (
          <>
            {isMobile ? (
              <>
                {unidadesStep == 0 ? (
                  <UnidadesItems
                    setTitulo={
                      <div
                        style={{
                          paddingTop: "70px",
                          paddingBottom: "10px",
                          textAling: "center",
                        }}
                      >
                        <Typography style={{ fontSize: "20px" }}>
                          <b> {unidadDescp[posicionUnidades]} </b>
                        </Typography>
                      </div>
                    }
                    setContenido={
                      <div>
                        {cargando ? (
                          <Box
                            fontWeight="fontWeightMedium"
                            fontSize={20}
                            style={{ color: "#2E2D2D" }}
                          >
                            Cargando...
                          </Box>
                        ) : (
                          <div
                            className="scroll"
                            style={{
                              height: 320,
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            <span style={{ paddingRight: "30px" }}>
                              <b>{`RA ${posicionUnidades + 1}: `}</b>{" "}
                              {resultado[posicionUnidades]}
                            </span>

                            <Typography style={{ paddingTop: "15px" }}>
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize={15}
                                style={{ color: "#2E2D2D" }}
                              >
                                INDICADORES DE DESEMPEÑO:
                              </Box>
                            </Typography>
                            {MostrarIndicadores()}
                            <br />
                            <Typography>
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize={15}
                                style={{ color: "#2E2D2D" }}
                              >
                                CONTENIDOS:
                              </Box>
                            </Typography>
                            {contenidoDes.map((element, i) => {
                              return (
                                <Typography>
                                  <Box
                                    fontWeight="fontWeightMedium"
                                    fontSize={14}
                                  >
                                    {i + 1 + "." + " " + element}
                                    <br />
                                  </Box>
                                </Typography>
                              );
                            })}
                            <br />
                          </div>
                        )}
                      </div>
                    }
                    classesContenido={classes.buttonsUnidades}
                    classname={classes.introduccionUnidades}
                  />
                ) : unidadesStep == 1 ? (
                  <UnidadesItems
                    setTitulo={""}
                    setContenido={
                      <div style={{ paddingTop: "50px" }}>
                        <div
                          className="scroll"
                          style={{
                            height: 350,
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          <Typography>
                            <b>Estrategias de Trabajo directo/sincrónico</b>
                          </Typography>
                          {presencial.length > 0 ? (
                            presencial.map((item) => (
                              <Typography> - {item.descripcion}</Typography>
                            ))
                          ) : (
                            <h3>
                              Por el momento no se ha cargado informacion en
                              esta asignatura
                            </h3>
                          )}
                          <br />
                          <Typography>
                            <b>Estrategias de Trabajo independiente</b>
                          </Typography>
                          {independiente.length > 0 ? (
                            independiente.map((item) => (
                              <Typography> - {item.descripcion}</Typography>
                            ))
                          ) : (
                            <h3>
                              Por el momento no se ha cargado informacion en
                              esta asignatura
                            </h3>
                          )}
                          <br />
                          <Typography>
                            <b>Estrategias de Trabajo evaluativa</b>
                          </Typography>
                          {evaluativa.length > 0 ? (
                            evaluativa.map((item) => (
                              <Typography> - {item.descripcion}</Typography>
                            ))
                          ) : (
                            <h3>
                              Por el momento no se ha cargado informacion en
                              esta asignatura
                            </h3>
                          )}
                          <br />
                        </div>
                      </div>
                    }
                  />
                ) : unidadesStep == 2 ? (
                  <UnidadesItems
                    setTitulo={""}
                    setContenido={
                      <div style={{ paddingTop: "50px" }}>
                        <div
                          className="scroll"
                          style={{
                            height: 350,
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          {recursosHerra.length > 0 ? (
                            recursosHerra.map((item, i) => (
                              <>
                                <Typography>
                                  <b>Equipos</b>
                                </Typography>
                                <Typography> - {item.equipos}</Typography>
                                <br />
                                <Typography>
                                  <b>Herramientas</b>
                                </Typography>
                                <Typography> - {item.herramientas}</Typography>
                                <br />
                                <Typography>
                                  <b>Materiales</b>
                                </Typography>
                                <Typography> - {item.materiales}</Typography>
                              </>
                            ))
                          ) : (
                            <h3>
                              Por el momento no se ha cargado informacion en
                              esta asignatura
                            </h3>
                          )}
                        </div>
                      </div>
                    }
                    classesContenido={classes.buttons2}
                    classname={classes.introduccion2}
                  />
                ) : (
                  <UnidadesItems
                    setTitulo={""}
                    setContenido={
                      <div style={{ paddingTop: "50px" }}>
                        <div
                          className="scroll"
                          style={{
                            height: 350,
                            width: "300px",
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          {referencia.length > 0 ? (
                            referencia.map((item) => (
                              <Typography style={{ paddingRight: "5px" }}>
                                <Box
                                  fontWeight="fontWeightMedium"
                                  fontSize={14}
                                >
                                  {item} <br />
                                  <br />
                                </Box>
                              </Typography>
                            ))
                          ) : (
                            <h3>
                              Por el momento no se ha cargado informacion en
                              esta asignatura
                            </h3>
                          )}
                        </div>
                      </div>
                    }
                    classesContenido={classes.buttons2}
                    classname={classes.introduccion2}
                  />
                )}
              </>
            ) : (
              <>
                {unidadesStep == 0 ? (
                  <UnidadesItems
                    setTitulo={<div>{unidadDescp[posicionUnidades]}</div>}
                    setContenido={
                      <>
                        {cargando ? (
                          <Box
                            fontWeight="fontWeightMedium"
                            fontSize={20}
                            style={{ color: "#2E2D2D" }}
                          >
                            Cargando...
                          </Box>
                        ) : (
                          <div
                            className="scroll"
                            style={{
                              height: 280,
                              overflowY: "scroll",
                              overflowX: "hidden",
                              paddingRight: "30px",
                            }}
                          >
                            <span style={{ paddingRight: "30px" }}>
                              <b>{`RA ${posicionUnidades + 1}: `}</b>{" "}
                              {resultado[posicionUnidades]}
                            </span>

                            <Typography style={{ paddingTop: "15px" }}>
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize={15}
                                style={{ color: "#2E2D2D" }}
                              >
                                INDICADORES DE DESEMPEÑO:
                              </Box>
                            </Typography>
                            {MostrarIndicadores()}
                            <br />
                            <Typography>
                              <Box
                                fontWeight="fontWeightBold"
                                fontSize={15}
                                style={{ color: "#2E2D2D" }}
                              >
                                CONTENIDOS:
                              </Box>
                            </Typography>
                            {contenidoDes.length > 0 ? (
                              contenidoDes.map((element, i) => {
                                return (
                                  <Typography>
                                    <Box
                                      fontWeight="fontWeightMedium"
                                      fontSize={14}
                                    >
                                      {i + 1 + "." + " " + element}
                                      <br />
                                    </Box>
                                  </Typography>
                                );
                              })
                            ) : (
                              <h3>
                                Por el momento no se ha cargado informacion en
                                esta asignatura
                              </h3>
                            )}
                            <br />
                          </div>
                        )}
                      </>
                    }
                    classesContenido={classes.buttonsUnidades}
                    classname={classes.introduccionUnidades}
                  />
                ) : unidadesStep == 1 ? (
                  <UnidadesItems
                    setTitulo={"Estrategias Didácticas"}
                    setContenido={
                      <>
                        <div
                          className="scroll"
                          style={{
                            height: 300,
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          <Grid container>
                            <Grid
                              container
                              style={{
                                textAlign: "center",
                                paddingBottom: "10px",
                              }}
                            >
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <b>Estrategias de trabajo directo/sincrónico</b>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <b>
                                  Estrategias de trabajo
                                  independiente/asincrónico
                                </b>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <b>Estrategias de trabajo evaluativo</b>
                              </Grid>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              {EstrategiaPresencial()}
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              {EstrategiaIndependiente()}
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              {EstrategiaEvaluativa()}
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    }
                    classesContenido={classes.buttonsUnidades}
                    classname={classes.introduccionUnidades}
                  />
                ) : unidadesStep == 2 ? (
                  <UnidadesItems
                    setTitulo={"Recursos Educativos"}
                    setContenido={
                      <div style={{ paddingTop: "90px" }}>
                        <Grid container>
                          <Grid
                            container
                            style={{
                              textAlign: "center",
                              paddingBottom: "10px",
                            }}
                          >
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <b>Equipos</b>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <b>Herramientas</b>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                              <b>Materiales</b>
                            </Grid>
                          </Grid>
                          {MostarRecursos()}
                        </Grid>
                      </div>
                    }
                    classesContenido={classes.buttonsUnidades}
                    classname={classes.introduccionUnidades}
                  />
                ) : (
                  <UnidadesItems
                    setTitulo={"Referencias Bibliográficas"}
                    setContenido={
                      <div
                        className="scroll"
                        style={{
                          height: 280,
                          overflowY: "scroll",
                          overflowX: "hidden",
                          paddingRight: "30px",
                        }}
                      >
                        {referencia.length > 0 ? (
                          referencia.map((item) => (
                            <Typography style={{ paddingRight: "5px" }}>
                              <Box fontWeight="fontWeightMedium" fontSize={14}>
                                {item} <br />
                                <br />
                              </Box>
                            </Typography>
                          ))
                        ) : (
                          <h3>
                            Por el momento no se ha cargado informacion en esta
                            asignatura
                          </h3>
                        )}
                      </div>
                    }
                    classesContenido={classes.buttonsUnidades}
                    classname={classes.introduccionUnidades}
                  />
                )}
              </>
            )}
          </>
        );
      case 9:
        return (
          <UnidadesItems
            setTitulo={"Estrategias Didácticas"}
            setContenido={
              <>
                <div
                  className="scroll"
                  style={{
                    height: 300,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Grid container>
                    <Grid
                      container
                      style={{ textAlign: "center", paddingBottom: "10px" }}
                    >
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <b>Estrategias de trabajo directo/sincrónico</b>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <b>Estrategias de trabajo independiente/asincrónico</b>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <b>Estrategias de trabajo evaluativo</b>
                      </Grid>
                    </Grid>
                    {MostarEstrategias()}
                  </Grid>
                </div>
              </>
            }
            classesContenido={classes.buttonsUnidades}
            classname={classes.introduccionUnidades}
          />
        );
      case 10:
        return (
          <UnidadesItems
            setTitulo={"Recursos Educativos"}
            setContenido={
              <div style={{ paddingTop: "90px" }}>
                <Grid container>
                  <Grid
                    container
                    style={{ textAlign: "center", paddingBottom: "10px" }}
                  >
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <b>Equipos</b>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <b>Herramientas</b>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <b>Materiales</b>
                    </Grid>
                  </Grid>
                  {MostarRecursos()}
                </Grid>
              </div>
            }
            classesContenido={classes.buttonsUnidades}
            classname={classes.introduccionUnidades}
          />
        );
      case 11:
        return (
          <UnidadesItems
            setTitulo={"Referencias Bibliográficas"}
            setContenido={
              <div
                className="scroll"
                style={{
                  height: 280,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {referencia.map((item) => (
                  <Typography style={{ paddingRight: "5px" }}>
                    <Box fontWeight="fontWeightMedium" fontSize={14}>
                      {item} <br />
                      <br />
                    </Box>
                  </Typography>
                ))}
              </div>
            }
            classesContenido={classes.buttonsUnidades}
            classname={classes.introduccionUnidades}
          />
        );

      default:
        return "Step no encontrado";
    }
  }
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <>
      <Dialog
        open={status}
        onClose={() => toggle({ modal: "dialogSyllabus", value: false })}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="l"
      >
        <AppBarModal
          mostrarModal={() => toggle({ modal: "dialogSyllabus", value: false })}
        />
        <DialogContent style={{ padding: 0 }} className="scroll">
          <Typography className={useStyles.instructions}>
            {getStepContent(activeStep)}
          </Typography>
          <div>
            {isMobile ? (
              <></>
            ) : (
              <>
                <Grid container className={classes.menuPrincipal}>
                  <Grid
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={3}
                    lg={3}
                    xl={3}
                  >
                    <BotonesMenu
                      titulo={"INFORMACIÓN GENERAL"}
                      indice={1}
                      isCheck={activeStep == 1}
                    />
                  </Grid>
                  <Grid
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={3}
                    lg={3}
                    xl={3}
                  >
                    <BotonesMenu
                      titulo={"INTRODUCCIÓN A LA ASIGNATURA"}
                      indice={2}
                      isCheck={activeStep == 2}
                    />
                  </Grid>
                  <Grid
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={3}
                    lg={3}
                    xl={3}
                  >
                    <BotonesMenu
                      titulo={obtenerTitulo()}
                      indice={3}
                      isCheck={activeStep == 3}
                    />
                  </Grid>
                  <Grid
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    sm={3}
                    lg={3}
                    xl={3}
                  >
                    <BotonesMenu
                      titulo={"UNIDADES DE FORMACIÓN "}
                      indice={4}
                      isCheck={activeStep == 4}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ListarSyllabus;
