import React from 'react'
import TareasTerminadas from '../../../general/TareasTerminadas'
import emma_w from "../../../../global/imagenes/emma_w.png"
import { Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import BeenhereIcon from '@material-ui/icons/Beenhere';
import AppBarModal from '../../../general/AppBarModal';
import { BtnForm } from '../../../general/BotonesAccion';


const Datos = (criterios) => {
  const nivelesPorTipo = {
    excelente: [],
    avanzado: [],
    satisfactorio: [],
    minimo: [],
    insuficiente: [],
  };

  criterios.forEach(criterio => {
    Object.keys(nivelesPorTipo).forEach(tipo => {
      nivelesPorTipo[tipo].push(criterio[tipo]);
    });
  });

  return nivelesPorTipo;
};

export const TablaValoracion = ({ cargando, onClose, open, titulo, criterios, selectedCells, classes, onClick, onSubmit, calificar_varios, siguiente, mostrarBtn }) => {

  const nivelesPorTipo = Datos(criterios);


  return (
    <>
      <Modal
        onClose={onClose}
        open={open}
        titulo={titulo}
        onSubmit={onSubmit}
        calificar_varios={calificar_varios}
        siguiente={siguiente}
        mostrarBtn={mostrarBtn}
      >
        {
          cargando ?
            <TareasTerminadas
              mensaje='Cargando..'
              marginTop='7%' imagen={emma_w}
              widthImg="7%" />
            :
            <ValorarComponent
              criterios={criterios}
              nivelesPorTipo={nivelesPorTipo}
              selectedCells={selectedCells}
              classes={classes}
              onClick={onClick}
            />
        }

      </Modal>
    </>
  )
}


const Modal = ({ children, onClose, open, titulo, onSubmit, calificar_varios, siguiente, mostrarBtn }) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo={titulo}
          mostrarModal={onClose}
          titulo_accion="Modificar Actividad"
          accion=""
        />
        <DialogContent className="scroll">
          {children}
        </DialogContent>
        <DialogActions>
          {
            calificar_varios ?
              // mostrarBtn ?
              <BtnForm texto={mostrarBtn ? "FINALIZAR" : "SIGUIENTE"} callback={siguiente} />
                // :
                // null
                // <BtnForm texto={mostrarBtn ? "FINALIZAR" : "SIGUIENTE"} callback={siguiente} />
              :
              <>
                <BtnForm texto="CANCELAR" callback={onClose} />
                <BtnForm texto="GUARDAR" callback={onSubmit} />
              </>
          }
        </DialogActions>
      </Dialog>
    </>
  )

}


const ValorarComponent = ({ criterios, nivelesPorTipo, selectedCells, classes, onClick }) => {
  return (
    <>
      {criterios.length > 0 ?
        <Tabla
          criterios={criterios}
          selectedCells={selectedCells}
          nivelesPorTipo={nivelesPorTipo}
          classes={classes}
          onClick={onClick}
        />
        :
        <TareasTerminadas
          mensaje='No hay criterios disponibles'
          marginTop='7%' imagen={emma_w}
          widthImg="7%" />
      }
    </>
  )
}

const Tabla = ({ criterios, selectedCells, nivelesPorTipo, classes, onClick }) => {
  return (
    <>
      <Grid container style={classes.gridContainer} >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TableContainer component={Paper} style={classes.tableContainer}>
            <Table aria-label="simple table" size={'small'} style={classes.table} >
              <TableHead>
                <TableRow style={classes.tableRow}>
                  <TableCell style={{ padding: '1%',/*  width: '10%' */ fontSize: '13px' }} align="center" > Indicadores de desempeño </TableCell>
                  {Object.keys(nivelesPorTipo).map((nivel, index) => (
                    <TableCell key={index} style={{ lineHeight: '1.5', padding: '1%', fontSize: '13px' }} align="center" > {nivel.toUpperCase()} {/* <br /> {this.obtenerRango(nivel)} */} </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {criterios.map((criterio, index) => (
                  <TableRow key={index}>
                    <TableCell style={classes.columnaNivel} variant="head" align="center">{criterio.criterio}</TableCell>
                    {Object.keys(nivelesPorTipo).map((nivel, subindex) => {
                      const valor = nivelesPorTipo[nivel][index];
                      const isSelected = selectedCells[`${index}-${subindex}`] || false;
                      return (
                        <TableCell
                          key={subindex}
                          style={{
                            padding: "0.7% 1.5% 0.6% 1.5%", fontSize: "13px", width: '17%',
                            backgroundColor: selectedCells[`${index}-${subindex}`] ? "#eaeaea" : "",
                            cursor: "pointer",
                          }}
                          align="center"
                          onClick={() => onClick(index, subindex, valor, nivel, criterio)}
                        >
                          {valor}
                          {isSelected && (
                            <div style={classes.containerRadio}>
                              <IconButton aria-label="check" style={{ color: '#C79e32', padding: 0, margin: 0 }}>
                                <BeenhereIcon style={classes.smallRadio} />
                              </IconButton>

                            </div>
                          )}
                          {/* <div style={classes.containerRadio}>
                                  <IconButton aria-label="check" style={{ color: '#C79e32', padding: 0, margin: 0 }}>
                                    <BeenhereIcon style={classes.smallRadio} />
                                  </IconButton>
                                   <Radio
                                    checked={isSelected}
                                    // onChange={() => this.onClick(index, subindex, valor, nivel, criterio)}
                                    style={classes.smallRadio}
                                  />
                                </div> */}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
