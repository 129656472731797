

import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, InputBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import {coloresEmma } from '../../../global/js/funciones';
const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: '5px',
    border: `solid 1px ${coloresEmma.secondarycolor}`,
    '&:hover': {
      backgroundColor: '#F8F9F9',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: coloresEmma.secondarycolor,
  },
  inputRoot: {
    color: 'black',
    fontWeight: '700'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  }
}));

export function Buscar({ dato_buscar, setBuscar, className, StyleExt }) {
  const classes = useStyles();
  return (
    <Grid container justifyContent="flex-start" className={className} alignItems="center" style={{...StyleExt, textAlign: 'center', width: '100%'}} >
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={dato_buscar}
            onChange={({ target }) => setBuscar(target.value)}
            name='dato_buscar'
            placeholder="Buscar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
      </Grid >
    </Grid >
  )
}