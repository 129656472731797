import React, { useState, useEffect } from "react";

// Material UI
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

// Material UI Icons
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

// Components Globales
import AppBarModal from "../../../../general/AppBarModal";
import TareasTerminadas from "../../../../general/TareasTerminadas";
import emma_w from "./../../../../../global/imagenes/emma_w.png";
import { DetalleMenuEncuesta } from "../../MatriculasMenu";
import { consulta } from "../../../../../global/js/funciones";
import { RESPUESTAS_MULTI_RADIO_BUTTONS } from "../../helper";

export const ModalDetalleEncuesta = ({ open, setOpen, matricula }) => {
  const [opcionModal, setOpcionModal] = useState("Enc_Gra_Sat");
  const [respuestas, setRespuestas] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(false);
  const [openRow, setOpenRow] = useState({});

  useEffect(() => {
    const fetchRespuestas = async () => {
      setCargando(true);
      try {
        const resp = await obtenerrespuestas(opcionModal);
        setRespuestas(resp);
      } catch (error) {
        setError(true);
      } finally {
        setCargando(false);
      }
    };

    fetchRespuestas();
  }, [opcionModal, matricula]);

  const obtenerrespuestas = (codigoPaso = "Enc_Gra_Sat") => {
    return new Promise((resolve, reject) => {
      consulta(
        `api/v1.0/matriculas/detalle_escuesta_graduados/${codigoPaso}/${matricula?.id}`,
        null,
        null,
        (error, estado, resp) => {
          if (estado === 200) {
            resolve(resp);
            setError(false);
          } else {
            reject(resp);
          }
        }
      );
    });
  };

  const handleToggle = (index) => {
    setOpenRow((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const pintarRespuestas = () => {
    if (cargando) {
      return (
        <TareasTerminadas
          mensaje="Cargando.."
          marginTop="7%"
          imagen={emma_w}
          widthImg="7%"
        />
      );
    }
    return !error ? (
      <Table>
        <TableBody>
          {respuestas?.map((item, index) => (
            <React.Fragment key={index}>
              {item.respuestas.length > 1 ? (
                <>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {item.pregunta}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleToggle(index)}
                      >
                        {openRow[index] ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={openRow[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Table size="small" aria-label="respuestas">
                            <TableBody>
                              {item.respuestas.map((respuesta, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{`${idx + 1}) ${
                                    respuesta.nombre
                                  }`}</TableCell>
                                  <TableCell align="right">
                                    {typeof respuesta.valor === "boolean"
                                      // ? +respuesta.valor
                                      ? ""
                                      : RESPUESTAS_MULTI_RADIO_BUTTONS.find(
                                          (item) => item.id === respuesta.valor
                                        )?.nombre || respuesta.valor}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {item.pregunta}
                  </TableCell>
                  <TableCell align="right">
                    {item.respuestas[0].nombre}
                    {typeof item.respuestas[0].valor !== "boolean" &&
                    item.respuestas[0].valor
                      ? ` - ${item.respuestas[0].valor}`
                      : ""}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    ) : (
      <TareasTerminadas
        mensaje="No hay respuestas"
        marginTop="7%"
        imagen={emma_w}
        widthImg="7%"
      />
    );
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => setOpen(false)}
    >
      <AppBarModal
        titulo="¡ Detalle Encuesta Graduados -OLE !"
        mostrarModal={setOpen}
        // accion={() => setOpen(false)}
        titulo_accion=""
      />
      <DetalleMenuEncuesta
        seleccionar={(seleccion) => setOpcionModal(seleccion)}
        sele={opcionModal}
      />
      <DialogContent>{pintarRespuestas()}</DialogContent>
    </Dialog>
  );
};
