import {
  MODAL_ADD,
  MODAL_MOD,
  MODAL_DEL,
  ACT_DATA_GENERICA,
  ACT_DATA_GENERICAS,
  ACT_RENDER,
  MODAL_ADM_VALORES,
} from "../actions/actGenericas";

const initialState = {
  modalAdd: false,
  modalMod: false,
  modalDel: false,
  generica: {
    id: 0,
    nombre: "",
    descripcion: "Ninguna",
  },
  genericas: [],
  render: false,
  modalAdmValores: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD:
      return Object.assign({}, state, {
        modalAdd: action.show
      })
    case MODAL_MOD:
      return Object.assign({}, state, {
        modalMod: action.show
      })
    case MODAL_DEL:
      return Object.assign({}, state, {
        modalDel: action.show
      })
    case ACT_DATA_GENERICA:
      return Object.assign({}, state, {
        generica: action.generica
      })
    case ACT_DATA_GENERICAS:
      return Object.assign({}, state, {
        genericas: action.genericas
      })
    case ACT_RENDER:
      return Object.assign({}, state, {
        render: action.render
      })
    case MODAL_ADM_VALORES:
      return Object.assign({}, state, {
        modalAdmValores: action.show
      })
    default:
      return state;
  }
}

export default reducer;
