import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm, BtnEliminar, BtnDetalle } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import ListarDatos from "../../general/ListarDatos";
import Checkbox from '@material-ui/core/Checkbox';
// import moment from 'moment'
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';
import { consulta, generarFiltros } from "../../../global/js/funciones";
import PracticasBuscarEmpresa from "../PracticasBuscarEmpresa";
import Practica from '../administrar/Practica';

class PracticasEnviarHV extends Component {

  constructor(props) {
    super(props)
    this.state = {
      empresas: [],
      procesos: [],
      cargando: false,
      procesos_hv: [],
      enProcesos: false,
      modal_buscar_empresa: false,
      mensaje: 'Cargando..',
      practica:0
    }
  }

  async componentDidUpdate({ modalEnviarHV }, { enProcesos }) {
    let { enProcesos: enProcesosNew } = this.state;
    let { modalEnviarHV: modalEnviarHVNew } = this.props;
    if (modalEnviarHV !== modalEnviarHVNew && modalEnviarHVNew) {
      this.setState({
        empresas: [],
        procesos_hv: [],
        enProcesos: false,
      })
    }
    if (enProcesos !== enProcesosNew) {
      this.setState({ cargando: true, })
      if (!enProcesosNew) {
        this.setState({ cargando: false, })
      } else if (enProcesosNew) {
        let procesos = await this.obtenerPracticas([{ 'llave': 'estado_actual__codigo', 'valor': 'Est_Pra_Ini' }, { 'llave': 'busca_empresa', 'valor': '1' }, { 'llave': 'sin_empresa', 'valor': '1' }]);
        this.setState({ procesos, cargando: false, })
      }
    }
  }

  obtenerPracticas = async (filtros_practica = []) => {
    let f = await generarFiltros(filtros_practica);
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/listar/1?${f}`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  }

  obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Est_Pra_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Est_Pra_Ini': { 'backgroundColor': '#01579b', color },
      'Est_Pra_Rec': { 'backgroundColor': '#b71c1c', color },
      'Est_Pra_Fin': { 'backgroundColor': '#009688', color },
      'Est_Pra_Rev': { 'backgroundColor': '#ffc107', color },
      'Est_Pra_Acep': { 'backgroundColor': '#1a237e', color },
      'Est_Pra_Vis': { 'backgroundColor': '#6200ea', color },
    }
    return (colores[codigo])
  }



  pintarLista = () => {
    let { cargando, empresas, enProcesos, procesos, mensaje, modal_buscar_empresa } = this.state;
    let {practica_id,modalDetallePractica,mostrarModalDetallePractica,usuario}=this.props
    if (cargando) return <TareasTerminadas imagen={emma_w} mensaje={mensaje} widthImg='10%' marginTop='10%' cargando={true} />;
    if (!enProcesos) {
      return (
        <div>
          <PracticasBuscarEmpresa
            busqueda={2}
            seleccionar={(id) => this.SeleccionarEmpresa(id)}
            modalBuscarEmpresa={modal_buscar_empresa}
            mostrarModalBuscarEmpresa={(modal_buscar_empresa) => this.setState({ modal_buscar_empresa })}
          />
          <ListarDatos
            datos={empresas}
            css={{}}
            agregar={true}
            buscar={false}
            actAgregar={() => this.setState({ modal_buscar_empresa: true })}
            opciones={true}
            titulo={'¡ Empresas Seleccionadas !'}
            sub_titulo={'Seleccionar y Continuar'}
            id='tbl_empresas_hv'
            acciones={(row, i) => this.acciones(row, i)}
            fila_principal={({ principal }) => `${principal.nombre}`}
            filas={[
              { 'id': 'codigo', 'mostrar': ({ principal }) => `${principal.codigo}`, 'nombre': 'NIT : ' },
              { 'id': 'sucursal', 'mostrar': ({ secundario }) => `${secundario.nombre}`, 'nombre': 'Sucursal : ' },
            ]}
          />
        </div>
      )

    } else {
      return (
        <>
        <ListarDatos
          id='tbl_lista_practica_hv'
          datos={procesos}
          agregar={false}
          opciones={true}
          titulo={'¡ Lista Procesos !'}
          sub_titulo={'Seleccionar y Terminar'}
          acciones={(row) => this.accionesProcesos(row)}
          accion_general={(row) => this.SeleccionarProcesos(row.id)}
          avatar={({ estado_actual }) => estado_actual.valorc}
          color_avatar={({ estado_actual }) => this.obtenerColor(estado_actual.codigo)}
          fila_principal={({ usuario_registro }) => `${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido} ${usuario_registro.primer_nombre}`}
          filas={[
            {
              'mostrar': ({ programa }) => `${programa.nombre}`,
              'id': 'programa',
            },
            {
              'mostrar': ({ tipo }) => tipo ? tipo.nombre : '',
              'id': 'tipo',
            },
            {
              'mostrar': ({ promedioacumulado }) => promedioacumulado ? promedioacumulado : '',
              'id': 'tipo',
            },
          ]}
        />
        
</>
      )
    }
  }

  SeleccionarEmpresa = (data) => {
    let { empresas } = this.state;
    empresas.push(data);
    this.setState({ empresas, modal_buscar_empresa: false });
    this.props.actualizarMensaje({ titulo: 'Empresa Agregada', tipo: 'success', mostrar: true, tiempo: 6000 });
  }

  eliminarEmpresa = (seleccionado) => {
    let { empresas } = this.state;
    empresas.splice(seleccionado, 1);
    this.setState({ empresas });
    this.props.actualizarMensaje({ titulo: 'Empresa Retirada', tipo: 'success', mostrar: true, tiempo: 6000 });
  }

  SeleccionarProcesos = (value) => {
    let { procesos_hv } = this.state;
    const seleccionado = procesos_hv.indexOf(value);
    if (seleccionado === -1) procesos_hv.push(value);
    else procesos_hv.splice(seleccionado, 1);
    this.setState({ procesos_hv })
  }

  acciones = (data, i) => {

    let ver = <BtnEliminar callback={() => this.eliminarEmpresa(i)} />
    return ver;
  }
   obtenerdetalle=(id)=>{
     this.setState({practica:id})
     this.props.mostrarModalDetallePractica(true)
    

  }

  accionesProcesos = (data) => {

    let ver = <Checkbox edge="end" onChange={() => this.SeleccionarProcesos(data.id)} checked={this.state.procesos_hv.indexOf(data.id) !== -1} inputProps={{ 'aria-labelledby': data.id }} />
    let detalle = <BtnDetalle callback={() => this.obtenerdetalle(data.id) } color='#01579b' texto='Abrir' /> ;
    return <div>{detalle}{ver}</div>
  }

  terminar = () => {
    let { empresas, enProcesos, procesos_hv } = this.state;
    let { actualizarMensaje, enviarHV, configurarAccionConf, mostrarModalGestionar } = this.props;
    if (!enProcesos) {
      if (empresas.length > 0) this.setState({ enProcesos: true })
      else actualizarMensaje({ titulo: 'Seleccione Empresas', tipo: 'info', mostrar: true, tiempo: 6000 });
    } else {
      if (procesos_hv.length === 0) actualizarMensaje({ titulo: 'Seleccione Procesos', tipo: 'info', mostrar: true, tiempo: 6000 });
      else {
        let data = [];
        empresas.map((item) => {
          data.push({ 'empresa': item.principal.id, 'sucursal': item.secundario.id, })
        })
        let conf = {
          titulo: '¿ Estas Seguro ?',
          ejecutarAccion: () => {
            mostrarModalGestionar(false);
            this.setState({ cargando: true, mensaje: 'Estoy enviando la información a cada una de la empresas seleccionadas, esto puede tardar unos segundos' })
            enviarHV({ 'empresas': data, 'procesos': procesos_hv });
          }
        }
        configurarAccionConf(conf);
        mostrarModalGestionar(true);

      }
    }
  }

  render() {
    let { mostrarModalEnviarHV, modalEnviarHV ,practica_id,modalDetallePractica,mostrarModalDetallePractica,usuario} = this.props;
    let { enProcesos, cargando,procesos,practica } = this.state;
    console.log('procesos',practica)
    
    

    return (
      <>
      <Dialog fullWidth={true} maxWidth="sm" open={modalEnviarHV} onClose={() => mostrarModalEnviarHV(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='Enviar HV' mostrarModal={mostrarModalEnviarHV} titulo_accion="CERRAR" accion={() => mostrarModalEnviarHV(false)} />
        <DialogContent className='scroll' style={{ padding: 0 }}>
          {this.pintarLista()}
        </DialogContent>
        <DialogActions>
          {(enProcesos && !cargando) && <BtnForm texto="REGRESAR" callback={() => this.setState({ enProcesos: false })} />}
          {!cargando && <BtnForm texto={enProcesos ? 'TERMINAR' : 'CONTINUAR'} callback={this.terminar} />}

        </DialogActions>
      </Dialog>
      <Practica
        practica_id={practica}
        vistaDetalle='practica'
        modalDetallePractica={modalDetallePractica}
        mostrarModalDetallePractica={mostrarModalDetallePractica}
        usuario={usuario.id}
        adminuser={true}
      />
</>
    )
  }
}

PracticasEnviarHV.propTypes = {
  modalEnviarHV: PropTypes.bool.isRequired,
  mostrarModalEnviarHV: PropTypes.func.isRequired,
}

export default PracticasEnviarHV
