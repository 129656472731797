import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment";

export function DetallePromocion({ datos }) {
	let {
		nombreColegio,
		ciudad,
		direccion,
		nombreRector,
		fax,
		nombreContacto,
		telefonoContacto,
		Tipo,
		barrio,
		correo,
		telefonos,
		paginaWeb,
		cargoContacto,
		departamento,
	} = datos;
	return (
		<Table>
			<TableBody>
				<TableRow key={1}>
					<TableCell component="th" scope="row">Nombre del colegio</TableCell>
					{nombreColegio ?
						<TableCell align="left">{nombreColegio}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={2}>
					<TableCell component="th" scope="row">Tipo de Colegio</TableCell>
					{Tipo ?
						<TableCell align="left">{Tipo.nombre}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={3}>
					<TableCell component="th" scope="row">Departamento</TableCell>
					{departamento ?
						<TableCell align="left">{departamento.nombre}</TableCell>
						:
						<TableCell align="left">DATO NO INGRESADO</TableCell>
					}
				</TableRow>
				<TableRow key={4}>
					<TableCell component="th" scope="row">Ciudad</TableCell>
					{ciudad ?
						<TableCell align="left">{ciudad.nombre}</TableCell>
						:
						<TableCell align="left">DATO NO INGRESADO</TableCell>
					}
				</TableRow>
				<TableRow key={5}>
					<TableCell component="th" scope="row">Barrio</TableCell>
					{barrio ?
						<TableCell align="left">{barrio.nombre}</TableCell>
						:
						<TableCell align="left">DATO NO INGRESADO</TableCell>
					}
				</TableRow>
				<TableRow key={6}>
					<TableCell component="th" scope="row">Dirección</TableCell>
					{direccion ?
						<TableCell align="left">{direccion}</TableCell>
						:
						<TableCell align="left">DATO NO INGRESADO</TableCell>
					}
				</TableRow>
				<TableRow key={7}>
					<TableCell component="th" scope="row">E-mail</TableCell>
					{correo ?
						<TableCell align="left">{correo}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={8}>
					<TableCell component="th" scope="row">Nombre del Rector</TableCell>
					{nombreRector ?
						<TableCell align="left">{nombreRector}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={9}>
					<TableCell component="th" scope="row">Teléfonos</TableCell>
					{telefonos ?
						<TableCell align="left">{telefonos}</TableCell>
						:
						<TableCell align="left">DATO NO INGRESADO</TableCell>
					}
				</TableRow>
				<TableRow key={10}>
					<TableCell component="th" scope="row">Fax</TableCell>
					{fax  ?
						<TableCell align="left">{fax}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={11}>
					<TableCell component="th" scope="row">Página Web</TableCell>
					{paginaWeb  ?
						<TableCell align="left">{paginaWeb}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={12}>
					<TableCell component="th" scope="row">Nombre del contacto</TableCell>
					{nombreContacto ?
						<TableCell align="left">{nombreContacto}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={13}>
					<TableCell component="th" scope="row">Cargo del contacto</TableCell>
					{cargoContacto  ?
						<TableCell align="left">{cargoContacto}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
				<TableRow key={14}>
					<TableCell component="th" scope="row">Teléfonos del contacto</TableCell>
					{telefonoContacto  ?
						<TableCell align="left">{telefonoContacto}</TableCell>
						: <TableCell align="left">DATO NO INGRESADO</TableCell>}
				</TableRow>
			</TableBody>
		</Table>
	)
}

export function DetalleVisita({ datos }) {
	let {
		hora_inicio,
		colegio,
		fecha_visita,
		hora_fin,
		lugar,
		numero_estudiante,
		responsable,
    material_audio,
    stand
	} = datos;
	return (
		<Table>
			<TableBody>
				<TableRow key={1}>
					<TableCell component="th" scope="row">Nombre del colegio</TableCell>
					<TableCell align="left">{colegio.nombreColegio}</TableCell>
				</TableRow>
				<TableRow key={2}>
					<TableCell component="th" scope="row">Fecha</TableCell>
					<TableCell align="left">{fecha_visita}</TableCell>
				</TableRow>
				<TableRow key={3}>
					<TableCell component="th" scope="row">Hora Inicio</TableCell>
					<TableCell align="left">{moment(hora_inicio, "hh: mm").format("LT")}</TableCell>
				</TableRow>
				<TableRow key={4}>
					<TableCell component="th" scope="row">Hora Fin</TableCell>
					<TableCell align="left">{moment(hora_fin, "hh: mm").format("LT")}</TableCell>
				</TableRow>
				<TableRow key={5}>
					<TableCell component="th" scope="row">Lugar</TableCell>
					<TableCell align="left">{lugar.nombre}</TableCell>
				</TableRow>
				<TableRow key={6}>
					<TableCell component="th" scope="row">Número Estudiantes</TableCell>
					<TableCell align="left">{numero_estudiante}</TableCell>
				</TableRow>
				<TableRow key={7}>
					<TableCell component="th" scope="row">Responsable</TableCell>
					<TableCell align="left">{`${responsable.primer_nombre} ${responsable.segundo_nombre} ${responsable.primer_apellido} ${responsable.segundo_apellido}`}</TableCell>
				</TableRow>
				<TableRow key={8}>
          <TableCell component="th" scope="row">¿ Requiere material audiovisual ? </TableCell>
          <TableCell align="left">{material_audio == 1 ? 'Si' : 'No'}</TableCell>
				</TableRow>
				<TableRow key={9}>
          <TableCell component="th" scope="row">¿ Requiere stand ? </TableCell>
          <TableCell align="left">{stand == 1 ? 'Si' : 'No'}</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	)
}
