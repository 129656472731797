const MODAL_ADD_BECA = 'MODAL_ADD_BECA'
const MODAL_DET_BECA = 'MODAD_DET_BECA'
const MODAL_GES_BECA = 'MODAL_GES_BECA'
const MODAL_COMMENT = 'MODAL_COMMENT'
const MODAL_VALIDACION = 'MODAL_VALIDACION'
const MODAL_ADJUNTOS = 'MODAL_ADJUNTOS'
const MODAL_VOLANTE = 'MODAL_VOLANTE'
const MODAL_FILTRO_BECA = 'MODAL_FILTRO_BECA'
const MODAL_ADD_ADJBECA = 'MODAL_ADD_ADJBECA'
const MODAL_HABILITAR_ESTUDIANTE = 'MODAL_HABILITAR_ESTUDIANTE'

const mostrarModalAddBeca = show => {
  return {
    type: MODAL_ADD_BECA,
    show,
  }
}

const mostrarModalValidacion = show => {
  return {
    type: MODAL_VALIDACION,
    show,
  }
}

const mostrarModalAdjuntos = show => {
  return {
    type: MODAL_ADJUNTOS,
    show,
  }
}

const mostrarModalComment = show => {
  return {
    type: MODAL_COMMENT,
    show,
  }
}

const mostrarModalDetalleBeca = show => {
  return {
    type: MODAL_DET_BECA,
    show,
  }
}

const mostrarGestionBeca = show => {
  return {
    type: MODAL_GES_BECA,
    show,
  }
}

const mostrarModalVolante = show => {
  return {
    type: MODAL_VOLANTE,
    show,
  }
}

const mostrarModalFiltroBeca = show => {
  return {
    type: MODAL_FILTRO_BECA,
    show,
  }
}

const mostrarModalAddAdjBeca = show => {
  return {
    type: MODAL_ADD_ADJBECA,
    show,
  }
}

const mostrarModalHabilitarestudiante = show => {
  return {
    type: MODAL_HABILITAR_ESTUDIANTE,
    show,
  }
}

module.exports = {
  MODAL_ADD_BECA,
  MODAL_DET_BECA,
  MODAL_GES_BECA,
  MODAL_VALIDACION,
  MODAL_ADJUNTOS,
  MODAL_COMMENT,
  mostrarModalAddBeca,
  mostrarModalValidacion,
  mostrarModalComment,
  mostrarModalAdjuntos,
  mostrarModalDetalleBeca,
  mostrarGestionBeca,
  mostrarModalVolante,
  MODAL_VOLANTE,
  mostrarModalFiltroBeca,
  MODAL_FILTRO_BECA,
  mostrarModalAddAdjBeca,
  MODAL_ADD_ADJBECA,
  mostrarModalHabilitarestudiante,
  MODAL_HABILITAR_ESTUDIANTE
}
