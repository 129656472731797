import React from 'react'
import { BottomNavigation, BottomNavigationAction, Hidden } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CreateIcon from '@material-ui/icons/Create'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoopIcon from '@material-ui/icons/Loop';
import BallotIcon from '@material-ui/icons/Ballot';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Instructivo from '@material-ui/icons/LocalLibrary';

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`

export function MenuInternacionalizacion({ sele = 0, seleccionar = () => { }, instructivo }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={ clase('proceso', sele) } onClick={ () => seleccionar('proceso') }>
            <BottomNavigationAction label={ 'Procesos' } icon={ <LocationOnIcon /> } />
          </BottomNavigation>
          <BottomNavigation showLabels className={ clase('nuevo', sele) } onClick={ () => seleccionar('nuevo') } >
            <BottomNavigationAction label={ 'Nuevo' } icon={ <CreateIcon /> } />
          </BottomNavigation>
          <BottomNavigation showLabels className={ clase('historial', sele) } onClick={ () => seleccionar('historial') } >
            <BottomNavigationAction label={ 'Historial' } icon={ <ImportContactsIcon /> } />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('instructivo', sele)} onClick={() => window.open(instructivo, '_blank')}>
            <BottomNavigationAction label={'Instructivo'} icon={<Instructivo />} />
          </BottomNavigation>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={ <LocationOnIcon /> } className={ clase('proceso', sele, '_app') } onClick={ () => seleccionar('proceso') } />
            <BottomNavigationAction label={'Nuevo'} icon={ <CreateIcon /> } className={ clase('nuevo', sele, '_app') } onClick={ () => seleccionar('nuevo') } />
            <BottomNavigationAction label={'Historial'} icon={ <ImportContactsIcon /> } className={ clase('historial', sele, '_app') } onClick={ () => seleccionar('historial') } />
            <BottomNavigationAction label={'Instructivo'} icon={ <Instructivo /> } className={ clase('instructivo', sele, '_app') }  onClick={() => window.open(instructivo, '_blank') } />
          </BottomNavigation>
        </div>
      )
    }
  }
  return (
    <div>
      <div>
        <Hidden xsDown>{ pintar(false, 'menu_modulo') }</Hidden>
        <Hidden smUp>{ pintar(true, 'menu_modulo_app') }</Hidden>
      </div>
    </div>
  )
}

export function MenuGestionInter({ administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className = { claseN }>
          <BottomNavigation showLabels className={clase('procesos', sele)} onClick={() => seleccionar('procesos')}>
            <BottomNavigationAction label={'Procesos'} icon={<ImportContactsIcon />} />
          </BottomNavigation>
          {
            administrador &&
              <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
                <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
              </BottomNavigation>
          }
        </div>
      )
    } else {
      return (
        <div className = { claseN }>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={ <ImportContactsIcon /> } className={ clase('procesos', sele, '_app') } onClick={ () => seleccionar('procesos') } />
            {
              administrador &&
                <BottomNavigationAction label={'Administrar'} icon={ <SettingsIcon /> } className={ clase('administrar', sele, '_app') } onClick={ () => seleccionar('administrar') } />
            }
          </BottomNavigation>
        </div>
      )
    }
  }
  return (
    <div>
      <Hidden xsDown>{ pintar(false, 'menu_modulo') }</Hidden>
      <Hidden smUp>{ pintar(true, 'menu_modulo_app') }</Hidden>
    </div>
  )
}

export function SolicitudesMenu({ sele = 0, seleccionar = () => { }, tipo_solicitud = '', admin }) {
  const pintar = (claseN = '') => {
    return (
      <div className = { claseN }>
        <BottomNavigation showLabels >
          <BottomNavigationAction label = { 'Detalle' } icon = { <ImportContactsIcon /> } className = { clase('detalle', sele) } onClick = { () => seleccionar('detalle') } />
          { tipo_solicitud === 'Reg_Mov_Int' && <BottomNavigationAction label = { 'Participantes' } icon = { <PeopleIcon /> } className = { clase('participantes', sele) } onClick = { () => seleccionar('participantes') } /> }
          {/* { tipo_solicitud === 'Reg_Mov_Int' && <BottomNavigationAction label = { 'Financiaciones' } icon = { <AttachMoneyIcon /> } className = { clase('financiaciones', sele) } onClick = { () => seleccionar('financiaciones') } /> } */}
          <BottomNavigationAction label = { 'Adjuntos' } icon = { <MenuBookIcon /> } className = { clase('documentos', sele) } onClick = { () => seleccionar('documentos') } />
          {/* { tipo_solicitud === 'Con_Int' ? '' : <BottomNavigationAction label = { 'Adicionales' } icon = { <BallotIcon /> } className = { clase('adjuntos', sele) } onClick = { () => seleccionar('adjuntos') } /> } */}
          <BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
          {tipo_solicitud === 'Reg_Mov_Int' && admin && <BottomNavigationAction label = { 'PDF' } icon = { <PeopleIcon /> } className = { clase('descargar_pdf', sele) } onClick = { () => seleccionar('descargar_pdf') } />}
        </BottomNavigation>
      </div>
    )
  }
  return pintar('menu_modal');
}

export function MenuGestionarSolicitud({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className = { claseN }>
        <BottomNavigation showLabels >
          <BottomNavigationAction label = { 'Gestionar' } icon = { <LoopIcon /> } className = { clase('gestion', sele) } onClick = { () => seleccionar('gestion') } />
          <BottomNavigationAction label = { 'Documentos' } icon = { <MenuBookIcon /> } className = { clase('adjuntos', sele) } onClick = { () => seleccionar('adjuntos') } />
        </BottomNavigation>
      </div>
    )
  }
  return (
    <div>
      { pintar('menu_modal') }
    </div>
  );
}
export function MenuGestionarConvenio({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className = { claseN }>
        <BottomNavigation showLabels >
          <BottomNavigationAction label = { 'Gestionar' } icon = { <LoopIcon /> } className = { clase('gestion', sele) } onClick = { () => seleccionar('gestion') } />
        </BottomNavigation>
      </div>
    )
  }
  return (
    <div>
      { pintar('menu_modal') }
    </div>
  );
}
