
const MODAL_DET_CONSULTORIA = 'MODAL_DET_CONSULTORIA'
const MODAL_ADD_CONSULTAS = 'MODAL_ADD_CONSULTAS'
const MODAL_GES_CONSULTORIA = 'MODAL_GES_CONSULTORIA'
const MODAL_ADD_FILTROS_CONSULTORIA = 'MODAL_ADD_FILTROS_CONSULTORIA'
const MODAL_INFORME_CONSULTORIA = 'MODAL_INFORME_CONSULTORIA'
const MODAL_MOD_PERIODO_CONSULTORIA = 'MODAL_MOD_PERIODO_CONSULTORIA'
const MODAL_MOD_DUPLICAR_CONSULTORIA = 'MODAL_MOD_DUPLICAR_CONSULTORIA'
const MODAL_MOD_EDITAR_CONSULTORIA = 'MODAL_MOD_EDITAR_CONSULTORIA'

const mostrarModalDetalleConsultoria = show => {
    return {
      type: MODAL_DET_CONSULTORIA,
      show,
    }
  }
  const mostrarModalGestionarConsultoria = show => {
    return {
      type: MODAL_GES_CONSULTORIA,
      show,
    }
  }
  const mostrarModalAddConsultas = show => {
    return {
      type: MODAL_ADD_CONSULTAS,
      show,
    }
  }

  const mostrarModalAddFiltrosConsultoria = show => {
    return {
      type: MODAL_ADD_FILTROS_CONSULTORIA,
      show,
    }
  }

  const mostrarModalInformeConsultoria = show => {
    return {
      type: MODAL_INFORME_CONSULTORIA,
      show,
    }
  }

  const mostrarModalModPeriodoConsultoria = show => {
    return {
      type: MODAL_MOD_PERIODO_CONSULTORIA,
      show,
    }
  }

  const mostrarModalDuplicarConsultoria = show => {
    return {
      type: MODAL_MOD_DUPLICAR_CONSULTORIA,
      show,
    }
  }

  const mostrarModalEditarConsultoria = show => {
    return {
      type: MODAL_MOD_EDITAR_CONSULTORIA,
      show,
    }
  }


module.exports = {
    MODAL_DET_CONSULTORIA,
    MODAL_ADD_CONSULTAS,
    MODAL_GES_CONSULTORIA,
    MODAL_ADD_FILTROS_CONSULTORIA,
    MODAL_INFORME_CONSULTORIA,
    MODAL_MOD_PERIODO_CONSULTORIA,
    MODAL_MOD_DUPLICAR_CONSULTORIA,
    MODAL_MOD_EDITAR_CONSULTORIA,
    mostrarModalDetalleConsultoria,
    mostrarModalGestionarConsultoria,
    mostrarModalAddConsultas,
    mostrarModalAddFiltrosConsultoria,
    mostrarModalInformeConsultoria,
    mostrarModalModPeriodoConsultoria,
    mostrarModalDuplicarConsultoria,
    mostrarModalEditarConsultoria,
  }
  