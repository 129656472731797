import React, { Component } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	InputLabel,
	FormControl,
	Grid,
	MenuItem,
	Select
} from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { obtenerValores, consulta, generarFiltros, transformar } from '../../../global/js/funciones';
import { PROCESOS, ESTADOS_ID, PROGRAMAS, FECHASGRADO } from './helper';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnForm } from "../../general/BotonesAccion";
import BuscarSelect from '../../general/BuscarSelect';
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from '../../general/BuscarPersona';

class MatriculasFiltrar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cargando: true,
			tipoSolicitud: '',
			procesos: [],
			programas: [],
			fechasGrado:[],
			programa: null,
			fechaGrado: null,
			buscarEstudiante: false,
			estudiante: {
			  id: 0,
			  nombre: ''
			}
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.open && prevProps.open !== this.props.open) {
			if(this.props.open) {
				this.setState({ cargando: true });
				this.setState({ fechaGrado: null, programa: null ,tipoSolicitud: '', estudiante: { id: 0, nombre: '' } });
				this.getDatosFiltros();
			}

		}
	

		if (prevProps.accionProceso != this.props.accionProceso && prevProps.accionProceso != 'inicio') {
			this.setState({
				programa: null,
				tipoSolicitud: '',
				estudiante: {
					id: 0,
					nombre: ''
				}
			})
		}
	}

	fechasgrados() {
		return new Promise((resolve) => {
		consulta(`api/v1.0/matriculas/fechasgrados`, null, null, (error, estado, resp) => {
			resolve(estado === 200 && !error ? resp : null);
		});
		});
	}

	getDatosFiltros = async () => {
		const procesos = await obtenerValores(PROCESOS);
		const programas = await obtenerValores(PROGRAMAS);
		const fechasGrado = await this.fechasgrados();
		this.setState({ 
			procesos, 
			programas : transformar(programas),
			fechasGrado,
			cargando: false, 
		});
	};

	handleChangeSelect = (valor) => this.setState(valor);

	limpiarFiltros = async () => {
		const { setOpen, listarHistorial } = this.props;
		this.setState({ tipoSolicitud: '', estado: '', periodo: '', estudiante: { id: 0, nombre: '' } });
		setOpen(false);
		// await listarHistorial([ { llave: 'estado_solicitud__valorb', valor: 'si' } ]);
	};

	handleSubmit = async () => {
		const { periodo, estado, tipoSolicitud, programa, estudiante, fechaGrado } = this.state;
		const { listarHistorial, setOpen, survey, handlerSurvey, downloadExcelEncuestas,accionProceso,mostrarTodos, seleccion } = this.props;
		let filtros = [];
		let tipoSolicitud_new = tipoSolicitud ? tipoSolicitud : seleccion[0].id
		if (tipoSolicitud_new) filtros.push({ llave: 'tipo__id', valor: tipoSolicitud_new });
		if (programa) filtros.push({ llave: 'programa', valor: programa.value });
		if (fechaGrado) filtros.push({ llave: 'solicitud_grado__fechaGrado', valor: fechaGrado });
		if (estudiante.id) filtros.push({ llave: 'usuario_registro', valor: estudiante.id });
		if (filtros.length > 0) {
			if (accionProceso == 'inicio') mostrarTodos('Tod_Mat')	
			if( !survey) {
				setOpen(false);
				await listarHistorial(filtros);
			}else{
				downloadExcelEncuestas(filtros);
				handlerSurvey(false);
				
			}
		}
	};

	cerrar_modal = () => {
		const { setOpen } = this.props;
		setOpen(false);
		this.limpiarFiltros()
	}


	render() {
		const { setOpen, open, survey, accionProceso } = this.props;
		const { cargando, periodo, periodos, tipoSolicitud, fechasGrado, fechaGrado, procesos, estados, estado, encuestas, programas, programa, buscarEstudiante, estudiante } = this.state;
		let habilitar_limpiar = accionProceso !== 'Tod_Mat' && accionProceso !== 'inicio'
		return (
			<>
				<Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => this.cerrar_modal()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cerrar_modal()} titulo_accion={habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.limpiarFiltros : ''} />
					<DialogContent className='scroll'>
						{
							cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
								<Grid container spacing={3} className=''>
									<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
										<InputBuscar
											id='estudiante'
											label='Estudiante'
											placeholder='Clic aquí para buscar'
											value={estudiante.nombre}
											callback={() => this.setState({ buscarEstudiante: true })} />
									</Grid>
									<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
										<BuscarSelect datos={programas} cambiarEstado={(value) => this.setState({ programa: value })} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione Programa' />
									</Grid>
									<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
										<FormControl className="form-control" /*error={this.conError(programa.error)}*/>
											<InputLabel htmlFor="tipoSolicitud">Seleccione Tipo de Solicitud</InputLabel>
											<Select
												id="tipoSolicitud"
												value={tipoSolicitud}
												onChange={(e) => this.handleChangeSelect({ tipoSolicitud: e.target.value })}
												name="tipoSolicitud"
											>
												{procesos &&
													procesos.map(({ nombre, id }) => (
														<MenuItem key={id} value={id}>
															{nombre}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl className="form-control" /*error={this.conError(programa.error)}*/>
											<InputLabel htmlFor="fechasGrados">Seleccione Fecha de Grado</InputLabel>
											<Select
												id="fechaGrado"
												value={fechaGrado}
												onChange={(e) => this.handleChangeSelect({ fechaGrado: e.target.value })}
												name="fechaGrado"
											>
												{fechasGrado &&
													fechasGrado.map(({ nombre, id }) => (
														<MenuItem key={id} value={id}>
															{nombre}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
						}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => this.cerrar_modal()} />
						{!cargando && <BtnForm texto="FILTRAR" callback={this.handleSubmit} />}
					</DialogActions>
				</Dialog >

				<Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
					<AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
					<DialogContent style={{ padding: '0' }} className='scroll'>
						<BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
							estudiante: {
								id,
								nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
							}, buscarEstudiante: false
						})} />
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default MatriculasFiltrar;