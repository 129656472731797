import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import EnProceso from '@material-ui/icons/Book';
import {Hidden } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import LoopIcon from '@material-ui/icons/Loop';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import BookIcon from '@material-ui/icons/Book';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CrearBeca from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  selecteditem: {
    borderBottom: '5px solid #900807!important',
    backgroundColor: '#fffde7!important',
  },
  noSelectedItem:{
    margin: '0 3px!important',
    border: '1px solid #eee!important',
    width: '30px!important',
    backgroundColor: '#eee!important',
    padding: '0',
  }
}));

export function SemilleroMenuProfesor({ profesor, vistaEstudiante,  gestor, sele = 0, seleccionar = () => { } }) {
  const clase = (item, selec, tipo = '') => item === selec ? `${classes.selecteditem}` : `item_modulo`;
  const classes = useStyles()
    const pintar = (claseN = '') => {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Información General'} icon={<MenuBookIcon />} className={clase('Información del Semillero', sele)} onClick={() => seleccionar('Información del Semillero')} />
            <BottomNavigationAction label={'Departamento y Programa'} icon={<BookIcon />} className={clase('Departamento y Programa', sele)} onClick={() => seleccionar('Departamento y Programa')} />
            <BottomNavigationAction label={'Lideres'} icon={<PeopleIcon />} className={clase('Lideres', sele)} onClick={() => seleccionar('Lideres')} />
            {gestor || (profesor == true && vistaEstudiante != true) ? <BottomNavigationAction label={'Estudiante Vinculado'} icon={<ContactMailIcon />} className={clase('Solicitudes', sele)} onClick={() => seleccionar('Solicitudes')} />: ''}
            { <BottomNavigationAction label={'Produccion del Semillero'} icon={<PeopleIcon />} className={clase('Produccion del Semillero', sele)} onClick={() => seleccionar('Produccion del Semillero')} />}
             {gestor ?  <BottomNavigationAction label={'Historial Semillero'} icon={<HistoryIcon />} className={clase('Historial Semillero', sele)} onClick={() => seleccionar('Historial Semillero')} />: ''}
          </BottomNavigation>
        </div>
      )
    }
  
    return pintar('menu_modal');
  }