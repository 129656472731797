import { Avatar, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import emma_w from '../../global/imagenes/emma_w.png';
import { obtenerPermisosValorAlt } from '../../global/js/funciones';
import TareasTerminadas from "../general/TareasTerminadas";
import { EXCLUIR_REQ_CONVGOB } from './helper';

export default class PracticasModalidad extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      requisitos: [],
      expand: false
    }
  }

  componentDidMount() {
    let { mod } = this.props
    this.obtenerRequisitos(mod.id);
  }

  obtenerRequisitos = async (modalidad) => {
    let { programas } = this.props
    this.setState({ cargando: true });
    // valori = 1 es para los requisitos de la ubarrio
    // valorb = 1 es para los requisitos de la cuc
    let requisitosDB = []
    if (programas[0].relacion.valorc == 'ubarrio') {
      requisitosDB = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': modalidad }, { 'llave': 'secundario__generica', 'valor': 12 }, { 'llave': 'secundario__valori', 'valor': 1 }, { 'llave': 'secundario__valorb', 'valor': 1 }])
      console.log(requisitosDB);
    } if (programas[0].relacion.valorc == 'convgob') {
      console.log('convgob');
      requisitosDB = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': modalidad },
      { 'llave': 'secundario__generica', 'valor': 12 }, { 'llave': 'secundario__valorb', 'valor': 1 },])
      requisitosDB = requisitosDB.filter((item) => !EXCLUIR_REQ_CONVGOB.includes(item.secundario.id));
    }else {
      requisitosDB = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': modalidad }, { 'llave': 'secundario__generica', 'valor': 12 }, { 'llave': 'secundario__valorb', 'valor': 1 }])
    }
    this.setState({
      requisitos: requisitosDB,
      cargando: false,
    });
  }

  changeExpand = () => {
    let { expand } = this.state
    this.setState({ expand: !expand })
  }

  colapseExpand = () => {
    this.setState({ expand: false })
  }

  obtenerColor = (codigo) => {
    const imagenes = {
      'Mod_Epr_Pra': '#9c27b0',
      'Mod_Emt_Pra': '#f44336',
      'Mod_Emp_Pra': '#3f51b5',
      'Mod_Inv_Pra': '#1b5e20',
      'Mod_Rce_Pra': '#795548',
      'Mod_Rci_Pra': '#e91e63',
      'Mod_Dip_Pra': '#ffc107',
      'Mod_Cli_Pre': '#8faac4',
      'Mod_Cli_Tel': '#8996a2',
      'Mod_Soc_Pra': '#636060',
      'Mod_Rpro_Pra': '#47408F',
      'Mod_Rcem_Pra': '#18F270',
      'Mod_Dip_Pra_Uab': '#ffc107'
    }
    return (imagenes[codigo])
  }

  configurarPractica = (mod, requisitos) => {
    let { actualizarModalidadPractica, actualizarRequisitosModalidad, mostrarModalAddPracticas } = this.props;
    mostrarModalAddPracticas(true)
    actualizarModalidadPractica(mod)
    actualizarRequisitosModalidad(requisitos)
  }

  render() {
    let { mod } = this.props
    let { requisitos, cargando } = this.state

    const verificarTotal = () => {
      let total = 0;
      requisitos.map(({ secundario }) => {
        if (secundario.valora === '1') total++;
      })
      return total;
    }

    return (
      <Card elevation={6} component="div" style={{ margin: 0, width: "100%", minHeight: 420, height: 420, cursor: 'pointer' }} onClick={() => this.configurarPractica(mod, requisitos)}>
        <CardHeader
          avatar={<Avatar style={{ backgroundColor: this.obtenerColor(mod.codigo) }}>{mod.nombre.charAt(0)}</Avatar>}
          title={mod.nombre}
          subheader={`${verificarTotal()} Requisitos`}
        />
        <CardContent style={{ margin: 0, minHeight: 320, overflow: 'auto' }} className='scroll'>
          <Grid container direction={'row'} style={{ maxHeight: '250px', overflowY: 'auto', padding: "2% 5% 0 5%", borderRadius: "5%", borderLeft: `3px solid ${this.obtenerColor(mod.codigo)}` }} justify={'center'}>
            <Table >
              <TableBody>

                {!cargando ? requisitos.map((item, key) => {
                  if ((item.secundario.valora === '1' && mod.codigo === 'Mod_Emp_Pra') || mod.codigo !== 'Mod_Emp_Pra') {
                    return (
                      <TableRow key={key}>
                        <TableCell align="left" style={{ padding: '8px 5px 8px 5px' }}> <Typography style={{ fontSize: 13 }} variant="body2" component="p">{item.secundario.nombre}</Typography></TableCell>
                      </TableRow>
                    )
                  }
                })
                  : <TableRow key={1}><TableCell><TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /></TableCell></TableRow>}
              </TableBody>
            </Table>
          </Grid>
        </CardContent>

      </ Card>
    )
  }
}
