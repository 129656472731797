import React from 'react';
import { Typography, Link } from '@material-ui/core';
import useWindowSize from '../../../general/hooks/useWindowSize';
import instructivoRetoscuc from '../doc/instructivoretos.pdf'


const Banner = ({nombre, programa}) => {
    const windowSize = useWindowSize();

    return (
        <div style={{
            alignSelf: 'center',
            margin: '15px 0px',
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 20px',
            backgroundColor: '#eaefef',
            borderRadius: '10px',
            width: windowSize < 600 ? 'auto' : '95%'
        }}>
            <Typography variant="h4" gutterBottom>Hola, {nombre} !</Typography>
            <span>Perteneces al programa de: {programa}</span>
            <span>Estimado estudiante , bienvenido al modulo RETOS UNICOSTA , para tu mayor comodidad , en el siguiente enlace encontraras un paso paso guiado del proceso. <Link href={instructivoRetoscuc} >Instructivo</Link></span>
        </div>
    )
}

export default Banner