import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Dialog, DialogActions, DialogContent, Grid, Typography, Link } from "@material-ui/core";
import AppBarModal from "../general/AppBarModal";
import { BtnForm } from "../general/BotonesAccion";
import file from '../../global/imagenes/file.png';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "250px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_img: {
    marginTop: '10px',
    textAlign: 'center',
    height: "100px",
  },
  title:{
    fontSize: '14px',
    height: "100px"
  }
}));


function CardDoc({ nombre, archivo }) {
  const classes = useStyles();

  return (
    <Link href={archivo} target="_blank" underline='none'>
      <Card className={classes.card}>
        <CardContent>
          <Grid container direction={'row'} justifyContent={'center'}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {nombre}
            </Typography>
            <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
              <img src={file} className={classes.image} alt='Imagen Tipo' />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  )
}


class DocInformativos extends Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      modal: false,
    }
  }

  componentDidUpdate({ Documentos }) {
    let { Documentos: DocumentosNew } = this.props;
    if (Documentos.length != DocumentosNew.length && DocumentosNew.length > 0) this.setState({ modal: true });
  }

  render() {
    let { rendering, Documentos } = this.props;
    let { modal } = this.state;

    const handleClick = event => {
      this.setState({ anchorEl: event.currentTarget, modal: true });
    }

    return (
      <>
        { rendering(handleClick) }
        <Dialog fullWidth={true} maxWidth="sm" open = { modal } onClose = { () => this.setState({ modal: false }) } aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='Documentos Informativos' mostrarModal={ () => this.setState({ modal: false }) } titulo_accion="" accion="" />
          <DialogContent style={{ padding: '1' }} className='scroll'>
            <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 20 }}>
              {
                Documentos.map(({ nombre, archivo }, i) => {
                  return (
                    <Grid item xs={6} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} key={i}>
                      <CardDoc nombre={nombre} archivo={archivo}/>
                    </Grid>
                  )
                })
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback = { () => this.setState({ modal: false }) } />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DocInformativos;