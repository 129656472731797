import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import { coloresEmma } from '../../../global/js/funciones';

const InfoBadgeConfi = ({ Icon, title, text, color, iconColor, functional, action }) => {

const CustomLinearProgress = withStyles((theme) => ({
    root: {
    borderRadius: 5,
    },
    colorPrimary: {
    backgroundColor: coloresEmma.colorblanco,
    },
    bar: {
    borderRadius: 5,
    backgroundColor: color,
    },
}))(LinearProgress);

return (
    <div style={{
    display: 'flex',
    cursor: functional ? 'pointer' : 'default',
    }} onClick={async () => await action()}>
    <div style={{
        backgroundColor: color,
        padding: '10px',
        margin: '8px',
        borderRadius: '10px',
        display: 'grid',
        placeItems: 'center',
        boxShadow: '0 2px 26px rgba(0, 0, 0, 0.07)',
    }}>
        <Icon style={{ color: iconColor }} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <span style={{ fontWeight: 'bold' }}>{title}</span>
        <span style={{ fontWeight: 'bold', color: '#62727b' }}>{text || text === 0 ? text : <CustomLinearProgress />}</span>
    </div>
    </div>
);
};

InfoBadgeConfi.propTypes = {
Icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.elementType,
]),
title: PropTypes.string,
text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
]),
color: PropTypes.string,
iconColor: PropTypes.string,
shadow: PropTypes.bool,
functional: PropTypes.bool,
action: PropTypes.func,
};

InfoBadgeConfi.defaultProps = {
functional: false,
action: () => {},
};

export default InfoBadgeConfi;
