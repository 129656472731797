import React from "react";
import { BottomNavigation, BottomNavigationAction, Tab, Tabs, Hidden } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LoopIcon from '@material-ui/icons/Loop';
import CreateIcon from '@material-ui/icons/Create';


const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuAutodiagnostico({ administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('Procesos', sele)} onClick={() => seleccionar('Procesos')}>
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          {administrador &&
            <BottomNavigation showLabels className={clase('Permisos', sele)} onClick={() => seleccionar('Permisos')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
            </BottomNavigation>
          }
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
          <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('Procesos', sele, '_app')} onClick={() => seleccionar('Procesos')} />
            {
              administrador && <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('Permisos', sele, '_app')} onClick={() => seleccionar('Permisos')} />
            }
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function MenuDetalle({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} />
          <BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}

export function MenuGestionar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Gestionar'} icon={<LoopIcon />} className={clase('gestionar', sele)} onClick={() => seleccionar('gestionar')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}

export function MenuEstudiante({ sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('proceso', sele)} onClick={() => seleccionar('proceso')}>
            <BottomNavigationAction label={'Proceso'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('nuevo', sele)} onClick={() => seleccionar('nuevo')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
            <BottomNavigationAction label={'Historial'} icon={<ImportContactsIcon />} />
          </BottomNavigation>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Proceso'} icon={<LocationOnIcon />} className={clase('proceso', sele, '_app')} onClick={() => seleccionar('proceso')} />
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} className={clase('nuevo', sele, '_app')} onClick={() => seleccionar('nuevo')} />
            <BottomNavigationAction label={'Historial'} icon={<ImportContactsIcon />} className={clase('historial', sele, '_app')} onClick={() => seleccionar('historial')} />
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}