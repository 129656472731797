import {
    MODAL_DET_OVA,

  } from "../actions/actOva"
  
  const initialState = {
    modalDetalleOva: false,
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case MODAL_DET_OVA:
        return Object.assign({}, state, {
          modalDetalleOva: action.show
        })
      default:
        return state;
    }
  }
  
  export default reducer;
  