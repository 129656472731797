//CORE
import React, { Component, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
//COMPONENTS
import ListarDatos from '../general/ListarDatos';
import { Dialog, DialogContent, DialogActions, Link, Table, TableBody, TableRow, TableCell, Collapse, Box, IconButton, AppBar, Toolbar, Typography, Tooltip } from '@material-ui/core';
import MuiTableCell from "@material-ui/core/TableCell";
import AppBarModal from '../general/AppBarModal';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, Font } from '@react-pdf/renderer';
import { consulta, obtenerValoresFiltros } from '../../global/js/funciones';
import TareasTerminadas from "../general/TareasTerminadas";
import { BtnForm } from '../general/BotonesAccion';
import emma_s from '../../global/imagenes/emma_s1.png';
import emma_w from '../../global/imagenes/emma_w.png';
import cuc from '../../global/imagenes/logo_cuc2.png'; 
import moment from 'moment';
import source from './../../global/fonts/PTSerif-Regular.ttf'
import sourcebold from './../../global/fonts/PTSerif-Bold.ttf'
import Cargando from "./../general/app/Cargando";


Font.register({ family: 'PTSerif', src: source });
Font.register({ family: 'PTSerifBold', src: sourcebold });
const styles = StyleSheet.create({
  body: {
    margin: 30,
    flexGrow: 1,
    fontFamily: 'PTSerif'
  },
  titlePrincipal: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,
    marginTop: 10,
    textAlign: 'center',
    color: '#8C222D',
    fontSize: 18
  },
  imagen: {
    width: '30%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,
    textAlign: 'center',
    color: '#B22507'
  },
  encabezado: {
    width: '80%',
    fontSize: 8,
    textAlign: 'left'
  },
  fecha:{
    fontSize: 8,
    textAlign: 'center'
  },
  row: {
    flexGrow: 0,
    flexDirection: 'row'
  },
  em:{
    fontFamily: 'PTSerifBold'
  }, 
  table: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 20
  },
  tableRow:{
    display: 'flex',
    flexDirection: 'row'
  },
  cell: {
    fontSize: 8,
    borderWidth: 1,
    margin: '-1px 0 0 -1px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    // flexWrap: 'wrap',
    // height: 'auto'
  },
  borderB: {
    borderBottom: 1, 
    margin: '0 -1px -1px 0'
  }
});

const estilos = theme => ({
  appBar: {
    width: '100%',
    backgroundColor: 'white',
    padding: '0 10px'
  },
  title: {
    margin: '0 auto',
    color: '#757575',
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
});

const TableCell2 = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

function Row({ nota_general, subnotas, corte }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell rowSpan={3} >Corte {corte}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell2 align='center' style={{ fontWeight: 'bold' }}>{`${nota_general.nota} (${nota_general.peso}%)`}</TableCell2>
        <TableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell2>
      </TableRow>


      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell2 align='center'>{`${subnotas[0].nota} (${subnotas[0].peso}%)`}</TableCell2>
                  </TableRow>
                  <TableRow>
                    <TableCell2 align='center'>{`${subnotas[1].nota} (${subnotas[1].peso}%)`}</TableCell2>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function Definitiva({ nota, focalizada }) {
  return (
    <>
      <TableRow>
        <TableCell rowSpan={2} style={{ fontWeight: 'bold' }}>Definitiva </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align='center' style={{ fontWeight: 'bold' }}>{nota}</TableCell>
        {focalizada && <TableCell> </TableCell>}
      </TableRow>
    </>
  )
}


class ListarNotas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openNotas: false,
      notas: [],
      cargando: true,
      // link_generica: {},
      tipo_val: '',
      linkValidacion: '',
      mensajeValidacion: ''
    };
  }

  async componentDidUpdate({ status }, { }) {
    let { status: statusNew, modalidad: modalidadNew } = this.props;
    if (statusNew !== status && statusNew) {
      this.setState({ cargando: true })
      let link_vacuna = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Link_Vac' }, { 'llave': 'estado', 'valor': '1' }]);
      let link_eval = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Mens_Eva' }, { 'llave': 'estado', 'valor': '1' }]);
      let data_notas = await this.obtenerNotas({ id_alum: modalidadNew.id_alum, tipo: modalidadNew.tipo })
      let newData = [];
      // let link_generica = {}

      data_notas.notas.forEach(materia => {
        const index = newData.findIndex(data => data.id === materia[2])
        if (index !== -1) {
          newData[index].cortes.push({ corte: materia[5], nota: parseFloat(materia[6]).toFixed(1), peso: materia[7] });
          if ((newData[index].cortes).length == 4) {
            newData[index].focalizada = false;
          } else if ((newData[index].cortes).length == 10) {
            newData[index].focalizada = true;
          } else {
            newData[index].focalizada = null;
          }

        } else {
          newData.push({
            id: materia[2],
            nombre: materia[3],
            grupo: materia[4],
            focalizada: null,
            cortes: [
              { corte: materia[5], nota: parseFloat(materia[6]).toFixed(1), peso: materia[7] }
            ],
            definitiva: materia[8],
            periodo: materia[9]
          });
        }
      });

      newData.map(data => {
        data.cortes = data.cortes.sort((a, b) => a.corte - b.corte);
        return data;
      })

      this.setState({ notas: newData, tipo_val: data_notas.tipo, linkValidacion: data_notas.linkValidacion, mensajeValidacion: data_notas.mensajeValidacion, cargando: false })
    }
  }

  obtenerNotas(data) {
    return new Promise(resolve => {
      consulta(`api/v1.0/consultas/NotasNivel`, data, "post", (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  generarPDF() {
    let { notas } = this.state
    let { modalidad, current_user } = this.props
    let fecha_actual = moment();
    let periodo = notas.length != 0 ? notas[0].periodo : ''
    
    const obtenerMes = (mes) => {
      const meses = {
        '1': 'Enero',
        '2': 'Febrero',
        '3': 'Marzo',
        '4': 'Abril',
        '5': 'Mayo',
        '6': 'Junio',
        '7': 'Julio',
        '8': 'Agosto',
        '9': 'Septiembre',
        '10': 'Octubre',
        '11': 'Noviembre',
        '12': 'Diciembre'
      }
      return (meses[mes])
    }
    let hoy = `${fecha_actual.format('D')} de ${obtenerMes(fecha_actual.format('M'))} de ${fecha_actual.format('YYYY')}`

    
    return (
      <Document>
        <Page size="A4">
          <View style={styles.body}>
            <View style={styles.row}>
              <Image src={cuc} style={styles.imagen} />
              <Text style={styles.titlePrincipal}>Notas Registradas</Text>
            </View>

            <View style={styles.row}>
              <View style={{ width: '70%' }}>
                <View style={styles.row}>
                  <Text style={[styles.encabezado, { width: '15%', fontFamily: 'PTSerifBold' }]}>Programa: </Text>
                  <Text style={styles.encabezado}>{modalidad.cod_programa} {modalidad.nombre}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.encabezado, { width: '15%', fontFamily: 'PTSerifBold' }]}>Pensum: </Text>
                  <Text style={styles.encabezado}>{modalidad.cod_pensum}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.encabezado, { width: '15%', fontFamily: 'PTSerifBold' }]}>Periodo: </Text>
                  <Text style={styles.encabezado}>{periodo}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.encabezado, { width: '15%', fontFamily: 'PTSerifBold' }]}>Estudiante: </Text>
                  <Text style={styles.encabezado}>{current_user.identificacion}  {current_user.primer_apellido} {current_user.segundo_apellido} {current_user.primer_nombre} {current_user.segundo_nombre}</Text>
                </View>
              </View>
              <View style={{ width: '30%' }}>
                <Text style={styles.fecha}>{hoy}</Text>
                <Text style={styles.fecha}>{fecha_actual.format('hh:mm:ss')}</Text>
              </View>
            </View>

            <View style={styles.table}>
              <View style={[styles.tableRow, styles.em]}>
                <View style={[styles.cell, { width: '15%', textAlign: 'center', }]}>
                  <Text>COD. ASIGNATURA</Text>
                </View>
                <View style={[styles.cell, { width: '20%', textAlign: 'center', }]}>
                  <Text>ASIGNATURA</Text>
                </View>
                <View style={[styles.cell, { width: '20%', textAlign: 'center', }]}>
                  <Text>GRUPO</Text>
                </View>
                <View style={[styles.cell, { width: '25%', textAlign: 'center', }]}>
                  <Text>NOMBRE DE LA NOTA</Text>
                </View>
                <View style={[styles.cell, { width: '10%', textAlign: 'center', }]}>
                  <Text>NOTA</Text>
                </View>
                <View style={[styles.cell, { width: '10%', textAlign: 'center', }]}>
                  <Text>DEF</Text>
                </View>
              </View>

              {
                notas.map(item =>
                  <View style={[styles.tableRow]}>
                    <View style={[styles.cell, { width: '15%', textAlign: 'center', }]}>
                      <Text>{item.id}</Text>
                    </View>
                    <View style={[styles.cell, { width: '20%', textAlign: 'center', }]}>
                      <Text>{item.nombre}</Text>
                    </View>
                    <View style={[styles.cell, { width: '20%', textAlign: 'center', }]}>
                      <Text>{item.grupo}</Text>
                    </View>
                    <View style={[styles.cell, { width: '25%', textAlign: 'left', }]}>
                      {
                        this.pdfNombreNota(item)
                      }
                    </View>
                    <View style={[styles.cell, { width: '10%', textAlign: 'center', }]}>
                      {
                        this.pdfValorNota(item)
                      }
                    </View>
                    <View style={[styles.cell, { width: '10%', textAlign: 'center', }]}>
                      <Text>{item.definitiva}</Text>
                    </View>
                  </View>
                )
              }
            </View>
          </View>
        </Page>
      </Document>
    )
  };

  notasRender = (cortes, focalizada, definitiva) => {
    let render = ''

    switch (cortes.length) {
      case 10: // Notas Materias Focalizadas
        render = <Table size="small" style={{ marginTop: '10px' }}>
          <TableBody>

            <Row nota_general={cortes[0]} subnotas={[cortes[1], cortes[2]]} corte={1} />
            <Row nota_general={cortes[3]} subnotas={[cortes[4], cortes[5]]} corte={2} />
            <Row nota_general={cortes[6]} subnotas={[cortes[7], cortes[8]]} corte={3} />

            <TableRow>
              <TableCell rowSpan={2} >Corte 4</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' style={{ fontWeight: 'bold' }}>{`${cortes[9].nota} (${cortes[9].peso}%)`}</TableCell>
              <TableCell> </TableCell>
            </TableRow>

            <Definitiva nota={definitiva} focalizada={true} />
          </TableBody>
        </Table>
        break;
      case 6: // Notas Programas Viruales
        render = <Table size="small" style={{ marginTop: '10px' }}>
          <TableBody>

            <Row nota_general={cortes[0]} subnotas={[cortes[1], cortes[2]]} corte={1} />
            <Row nota_general={cortes[3]} subnotas={[cortes[4], cortes[5]]} corte={2} />

            <Definitiva nota={definitiva} focalizada={true} />
          </TableBody>
        </Table>
        break;
      default:
        render = <Table size="small" style={{ marginTop: '10px' }}>
          <TableBody>
            {cortes.map((corte, i) => (
              <>
                <TableRow key={i}>
                  <TableCell rowSpan={2}>{`Corte ${corte.corte}`} </TableCell>
                </TableRow>
                <TableRow key={i + 1}>
                  <TableCell align='center' style={{ fontWeight: 'bold' }}>{`${corte.nota} (${corte.peso}%)`}</TableCell>
                </TableRow>
              </>
            ))}
            <Definitiva nota={definitiva} focalizada={false} />
          </TableBody>
        </Table>
        break;
    }
    return render
  }

  pdfNombreNota = (item) => {
    let render = ''

    const textoCorte = numeroCorte => {
      const cortes = {
        '1': 'PRIMER',
        '2': 'SEGUNDO',
        '3': 'TERCER',
        '4': 'CUARTO'
      }
      return (cortes[numeroCorte])
    }

    switch (item.cortes.length) {
      case 10:
        render = <>
          <Text style={styles.borderB}>{`(${item.cortes[0].peso}%)`} PRIMER CORTE</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 1 {`(${item.cortes[1].peso}%)`}</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 2 {`(${item.cortes[2].peso}%)`}</Text>

          <Text style={styles.borderB}>{`(${item.cortes[3].peso}%)`} SEGUNDO CORTE</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 1 {`(${item.cortes[4].peso}%)`}</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 2 {`(${item.cortes[5].peso}%)`}</Text>

          <Text style={styles.borderB}>{`(${item.cortes[6].peso}%)`} TERCER CORTE</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 1 {`(${item.cortes[7].peso}%)`}</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 2 {`(${item.cortes[8].peso}%)`}</Text>

          <Text style={styles.borderB}>{`(${item.cortes[9].peso}%)`} CUARTO CORTE</Text>
        </>
        break;
      case 6:
        render = <>
          <Text style={styles.borderB}>{`(${item.cortes[0].peso}%)`} PRIMER CORTE</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 1 {`(${item.cortes[1].peso}%)`}</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 2 {`(${item.cortes[2].peso}%)`}</Text>

          <Text style={styles.borderB}>{`(${item.cortes[3].peso}%)`} SEGUNDO CORTE</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 1 {`(${item.cortes[4].peso}%)`}</Text>
          <Text style={[styles.borderB, { paddingLeft: '10px' }]}>Nota 2 {`(${item.cortes[5].peso}%)`}</Text>

        </>
        break;
      default:
        render = item.cortes.map((corte) => (
          <Text style={styles.borderB}>{`(${corte.peso}%)`} {textoCorte(corte.corte)} CORTE</Text>
        ))
        break;
    }

    return render
  }

  pdfValorNota = (item) => {
    let render = ''

    // const textoCorte = numeroCorte => {
    //   const cortes = {
    //     '1': 'PRIMER',
    //     '2': 'SEGUNDO',
    //     '3': 'TERCER',
    //     '4': 'CUARTO'
    //   }
    //   return (cortes[numeroCorte])
    // }

    switch (item.cortes.length) {
      case 10:
        render = <>
          <Text style={styles.borderB}>{item.cortes[0].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[1].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[2].nota}</Text>

          <Text style={styles.borderB}>{item.cortes[3].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[4].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[5].nota}</Text>

          <Text style={styles.borderB}>{item.cortes[6].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[7].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[8].nota}</Text>

          <Text style={styles.borderB}>{item.cortes[9].nota}</Text>
        </>
        break;
      case 6:
        render = <>
          <Text style={styles.borderB}>{item.cortes[0].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[1].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[2].nota}</Text>

          <Text style={styles.borderB}>{item.cortes[3].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[4].nota}</Text>
          <Text style={styles.borderB}>{item.cortes[5].nota}</Text>
        </>
        break;
      default:
        render = item.cortes.map((corte) => (
          <Text style={styles.borderB}>{corte.nota}</Text>
        ))
        break;
    }

    return render
  }

  render() {
    let { cargando, notas, tipo_val, linkValidacion, mensajeValidacion } = this.state
    let { toggle, status, modalidad: { nombre : nombre_programa } } = this.props
    let periodo = notas.length != 0 ? notas[0].periodo : ''
    const { classes } = this.props;

    return (
      <Dialog open={status} onClose={() => toggle(false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
        <AppBarModal titulo="Notas" mostrarModal={() => toggle(false)} titulo_accion="" accion="" />
        <DialogContent style={{ padding: 0 }} className='scroll'>

          {!cargando ?
            ((tipo_val == 'no_reporte' || tipo_val == 'no_eval' || tipo_val == 'no_encu' || tipo_val == 'no_spa' ) ?
              <div style={{ width: "90%", margin: "auto" }}>
                {linkValidacion ?
                  <Link href={linkValidacion} target="_blank" underline="none" variant='body2'>
                    <TareasTerminadas mensaje={mensajeValidacion} marginTop='7%' imagen={emma_s} widthImg="7%" />
                  </Link>
                  :
                  <TareasTerminadas mensaje={mensajeValidacion} marginTop='7%' imagen={emma_s} widthImg="7%" />
                }
              </div>
              :
              <>
                <AppBar position='static' className={classes.appBar} elevation={0} id='appBar_listar_datos'>
                  <Toolbar>
                    <Typography variant="subtitle2" className={classes.title}>
                      Mis Notas
                    </Typography>
                    <div className={classes.grow} />

                    <PDFDownloadLink style={{ textDecoration: 'none' }} document={this.generarPDF([])} fileName={`NOTAS ${periodo} - ${nombre_programa} ${moment().format('YYYY-MM-DD, h:mm a')}.pdf`}>
                      {
                        ({ loading }) => (loading ? <Cargando marginTop="" texto=""/> : 
                      <Tooltip title='Descargar Notas' aria-label="add">
                        <IconButton edge="end" color="secondary">
                          <GetAppIcon />
                        </IconButton>
                      </Tooltip>
                       )
                       } 
                    </PDFDownloadLink>
                  </Toolbar>
                </AppBar>
              <ListarDatos
                id="tbl_listar_notas"
                descargar="EMMA - NOTAS"
                datos={notas}
                opciones={false}
                agregar={false}
                buscar={false}
                acciones={{}}
                avatar={({ definitiva }) => definitiva >= 3.0 ? <CheckIcon /> : <ClearIcon />}
                color_avatar={({ definitiva }) => definitiva >= 3.0 ? ({ 'backgroundColor': '#00c853', 'color': '#ffffff' }) : ({ 'backgroundColor': '#ff4726', 'color': '#fff' })}
                titulo={"Notas"}
                fila_principal={({ nombre }) => `${nombre}`}
                filas={[
                  {
                    'id': 'nombre',
                    'enLista': false,
                  },
                  {
                    'mostrar': ({ grupo }) => `Grupo: ${grupo}`,
                    'id': 'grupo',
                    'enLista': true,
                  },
                  {
                    'mostrar': ({ cortes, focalizada, definitiva }) => (
                      <div>
                        {this.notasRender(cortes, focalizada, definitiva)}
                      </div>
                    ),
                    'enLista': true,
                  }
                ]}
              />
              </>
            ):
            <TareasTerminadas mensaje={'Cargando..'} marginTop='7%' imagen={emma_w} widthImg="7%" />
          }

        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => toggle(false)} />
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(estilos)(ListarNotas)
