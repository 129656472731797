import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// FUNCIONES GLOBALES
import { Paper, Grid, Tooltip, Dialog, DialogActions, DialogContent, IconButton, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import { consulta, mostrarError, formulario, generarFiltros, obtenerValoresFiltros, api, crear_form_data, modificarValorGenericaAlter } from "../../global/js/funciones";
import ListarDatos from "../general/ListarDatos";
import TareasTerminadas from "../general/TareasTerminadas";
import { BtnDetalle, BtnGestionar, BtnFolder, BtnForm, BtnRegresar, BtnVer, BtnSeleccion, BtnEditar } from "../general/BotonesAccion";
import AppBarModal from '../general/AppBarModal'
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import FilterListIcon from '@material-ui/icons/FilterList';
import EnviarMensajeFecha from '../general/EnviarMensajeFecha';

// FUNCIONES - COMPONENTES DEL MODULO
import { mostrarModalDetalleValidaciones, mostrarGestionValidacion, mostrarModalFiltroValidacion, mostrarModalModificarPeriodo } from "../../redux/actions/actValidaciones";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { MenuValidaciones } from './validaciones/MenuValidaciones';
import ValidacionesDetalle from './validaciones/Validacion';
import ValidacionesGestionar from './validaciones/ValidacionesGestionar';
import ValidacionesPermisos from './validaciones/ValidacionesPermisos';
import ValidacionesFiltro from './validaciones/ValidacionesFiltro';

// IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import fechaIcon from '../../global/imagenes/calendar.png';
import permisosIcon from '../../global/imagenes/lightbulb.png';
import vistaIcon from '../../global/imagenes/hourglass.png';
import moment from 'moment'


export class ValidacionesAdministrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filtros_validaciones: [],
      cargando: false,
      accion: 'Procesos',
      accionProceso: 'inicio',
      accionAdmin: 'inicio',
      administrador: false,
      periodo: '',
      validaciones: [],
      validacion: null,
      modalFiltros: false,
      vistaDetalle: 'detalle',
      id_estudiante: 0,
      estados_procesos: [],
      cargarFiltroP: true,
      cargarFiltroP: true,
      periodo_filtro: '',
      periodos: [],
      filtrarPeriodo: false,
      periodo_mod: '',
      fecha_periodo: '',
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/gestion_validaciones', nombre: 'validación' })
    let periodo_mod = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Val' }]);
    this.setState({ periodo_mod: periodo_mod[0].nombre, fecha_periodo: periodo_mod[0].valora })
    this.obtenerDatosIniciales();
    this.esAdministrador();
  }

  async componentDidUpdate({ }, { accion, accionProceso }) {
    let { accion: accionNew, periodo, enNotificacion: enNotificacionNew, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
    if (accion !== accionNew) {
      if (accionNew === 'Procesos');
    }

    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew != 'Tod_Val') {
        this.cambiarEstado({ cargando: true })
        if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
        else this.actualizarDataValidaciones([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
      }
    }
  }

  async actualizarDataValidaciones(filtros = [{ 'llave': 'estado_actual__valorc', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    this.setState({ cargando: true })
    let validacion = await this.obtenerValidaciones(filtros);
    this.setState({ validacion, cargando: false, filtros_beca: filtros })
  }


  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Val_n1")) {
      this.setState({ administrador: true })
    }
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Val' }]);
    this.obtenerEstadoSolicitudes(periodo[0].nombre);
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '' });

  }

  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(periodo_filtro)
  }

  obtenerEstadoSolicitudes = (periodo) => {
    consulta(`api/v1.0/validaciones/estados/${periodo}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte);
          })
        }
        this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden), cargando: false });
      }
    })
  }



  async obtenerValidaciones(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/validaciones/listar/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : []);
      })
    })
  }

  gestionarValidaciones = async (id, data, archivos, callback, requisitos) => {
    let { mostrarGestionValidacion, actualizarMensaje, mostrarModalVolante } = this.props
    let form = await crear_form_data(data);
    let sw = true;

    if (archivos.length <= 0) {
      requisitos.map(({ secundario: { valorc } }) => {
        if (valorc == 'obligatorio') {
          sw = false;
          actualizarMensaje({ titulo: "Adjuntar documento.", tipo: "info", mostrar: true, tiempo: 6000 });
          this.setState({ cargando: false })
          return false
        }
      })
    } else if (archivos.length > 0) {
      requisitos.map(({ secundario: { id, valorc } }) => {
        let found = archivos.find(element => element.codigo == id)
        console.log(found);
        if (!found) {
          sw = false;
          actualizarMensaje({ titulo: "Adjuntar documento.", tipo: "info", mostrar: true, tiempo: 6000 });
          this.setState({ cargando: false })
          return false
        }
      })
    }

    if (sw) {
      this.setState({ cargando: true, titulo_res: 'Enviando..' })
      archivos.map(({ archivo, codigo }, index) => {
        form.append(`archivo${index}`, archivo)
        form.append(`codigo${index}`, codigo)
        return true;
      })
      formulario(`api/v1.0/validaciones/${id}/gestionar`, form, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarGestionValidacion(false)
            // mostrarModalVolante(false);
            this.actualizarDataValidaciones(this.state.filtros_validaciones);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        callback()
      })
    }
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }


  pintarProcesos() {
    let { validaciones, validacion } = this.state;
    let { mostrarModalDetalleValidaciones, mostrarGestionValidacion, modalGestionValidacion, actualizarMensaje, usuario, mostrarModalFiltroValidacion } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Val_Rec': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Val_Rev': { 'backgroundColor': '#ffc107', color },
        'Val_Apr': { 'backgroundColor': '#01519B', color },
        'Val_Adm': { 'backgroundColor': '#18F270', color },
        'Val_Pag': { 'backgroundColor': '#A90AE2', color },
        'Val_Env': { 'backgroundColor': '#009688', color },
        'Val_Asi': { 'backgroundColor': '#636060', color },
        'Val_Not': { 'backgroundColor': '#BB0061', color },
        'Val_Aex': { 'backgroundColor': '#FF5551', color },
        'Val_Ecr': { 'backgroundColor': '#47408F', color },
        'Val_Ate': { 'backgroundColor': '#2e86de', color },
        'Val_Rep': { 'backgroundColor': '#4A235A', color },
        'Val_Neg': { 'backgroundColor': '#b71c1c', color },
        'Val_Can': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso, coordinador } = data;

      const onClickDetalle = validacion => {
        this.setState({ validacion, vistaDetalle: 'detalle', id_estudiante: validacion.usuario_registro.id })
        mostrarModalDetalleValidaciones(true)
      }

      const onClickGestionar = validacion => {
        this.setState({ validacion })
        mostrarGestionValidacion(true)

      }

      const onClickAdjuntos = validacion => {
        this.setState({ validacion, vistaDetalle: 'documentos', id_estudiante: validacion.usuario_registro.id })
        mostrarModalDetalleValidaciones(true)
      }


      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Detalle Solicitud' />;
      let gestionar = ((codigo !== 'Val_Neg' && codigo !== 'Val_Ate' && codigo !== 'Val_Con') && (permiso == 1 || (coordinador && coordinador.id == usuario.id  && codigo == 'Val_Env') )) 
      && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;

      let adjuntos = (codigo == 'Val_Rec' && (permiso == 1 || coordinador && coordinador.id == usuario.id)) && <BtnFolder callback={() => onClickAdjuntos(data)} color='#ffd600' texto='Documentos' />;
      return <div>{detalle}{adjuntos}{gestionar}</div>;
    }
    return (
      <div>

        <ValidacionesGestionar actualizarMensaje={actualizarMensaje} validacion={validacion} gestionarValidaciones={this.gestionarValidaciones} mostrarGestionValidacion={mostrarGestionValidacion} modalGestionValidacion={modalGestionValidacion} guardarVolante={this.cambiarEstado} />

        <ListarDatos
          datos={validaciones}
          titulo='Lista de Validación'
          id='tbl_validaciones_listar'
          opciones={true}
          descargar='VALIDACIONES - EMMA'
          filtrar={true}
          refrescar={true}
          actfiltrar={() => mostrarModalFiltroValidacion(true)}
          actRefrescar={() => this.actualizarDataValidaciones(this.state.filtros_validaciones)}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
            { 'id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            { 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => `${estado_actual.nombre}`.toUpperCase(), 'enLista': true },
            { 'id': 'programa', 'mostrar': ({ programa }) => `${programa}`, 'enLista': false }
          ]}
        />
      </div>
    )
  }

  pintarInicioProcesos = () => {
    let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo } = this.state;
    let { mostrarModalFiltroValidacion } = this.props

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Val_Rec': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Val_Rev': { 'backgroundColor': '#ffc107', color },
        'Val_Apr': { 'backgroundColor': '#01519B', color },
        'Val_Adm': { 'backgroundColor': '#18F270', color },
        'Val_Pag': { 'backgroundColor': '#A90AE2', color },
        'Val_Env': { 'backgroundColor': '#009688', color },
        'Val_Asi': { 'backgroundColor': '#636060', color },
        'Val_Not': { 'backgroundColor': '#BB0061', color },
        'Val_Aex': { 'backgroundColor': '#FF5551', color },
        'Val_Ecr': { 'backgroundColor': '#47408F', color },
        'Val_Ate': { 'backgroundColor': '#2e86de', color },
        'Val_Rep': { 'backgroundColor': '#4A235A', color },
        'Val_Neg': { 'backgroundColor': '#b71c1c', color },
        'Val_Can': { 'backgroundColor': '#b71c1c', color },
        'Val_Con': { 'backgroundColor': '#b71c1c', color },
        'Tod_Val': { 'backgroundColor': '#0d47a1', color }
      }
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      if (codigo != 'Tod_Val') this.setState({ accionProceso: codigo })
      else {
        mostrarModalFiltroValidacion(true);
      }
    };

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {(estados_procesos.length === 0) ?
                  <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                          <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                            <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >
      </>
    )
  }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      if (!(periodos.find(e => e.periodo == periodo))) periodos.push({ 'periodo': periodo })
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/validaciones/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  renderProcesos() {
    let { accionProceso } = this.state;
    return (
      <>
        {accionProceso == 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
        {accionProceso != 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_validaciones: [], accionProceso: 'inicio' })} /></div>}
      </>
    )
  }

  pintarAdministrar() {
    let { accionAdmin, administrador } = this.state;
    if (!administrador) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
    return (
      <>
        {accionAdmin === 'inicio' && this.pintarInicioAdm()}
        {accionAdmin !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionAdmin: 'inicio' })} /></div>}

        {accionAdmin === 'permisos' && <ValidacionesPermisos />}
      </>
    )
  }

  pintarInicioAdm = () => {
    let { periodo_mod, fecha_periodo } = this.state;
    return (
      <div style={{ padding: '2% 2% 0% 2%' }}>
        <Grid container direction={'row'} spacing={1} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <TituloAccion principal='' alterno='¿ Qué deseas hacer ?' />
            <Paper elevation={0} square className='scroll contenido_fijo'>
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.props.mostrarModalModificarPeriodo(true)}>
                    <AccionesRapidas imagen={fechaIcon} titulo='¡ Gestionar el periodo !' sub_titulo='' des_sub_titulo={`El periodo actual ${periodo_mod} esta disponible hasta ${fecha_periodo}.`} />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdmin: 'permisos' })}>
                    <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores del módulo de validaciones.' />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  configFiltros = (accion_actual) => {
    let { periodo_filtro } = this.state
    switch (accion_actual) {
      case 'Tod_Val':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      case 'inicio':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
    }
  }

  modificarPeriodo = async (periodo_mod, fecha_periodo) => {
    let { actualizarMensaje, mostrarModalModificarPeriodo } = this.props;
    if (periodo_mod && fecha_periodo) {
      let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Val' }]);
      if (p.length > 0) {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'nombre': periodo_mod, 'valora': fecha_periodo })
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalModificarPeriodo(false);
            this.cambiarEstado({ periodo_mod, fecha_periodo, periodo_filtro: periodo_mod });
            this.obtenerAlertas(periodo_mod, fecha_periodo);
            this.setState({ cargando: true })
            this.obtenerEstadoSolicitudes(periodo_mod)
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      } else actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 });
    } else actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  async obtenerAlertas(periodo_mod, fecha_periodo) {
    this.setState({ cargandoNotif: true })
    let mensajes = [];
    let periodo_noti = await this.notificacionPeriodo(periodo_mod, fecha_periodo);
    let notificaciones = [];

    // const accionesMensajes = (data) => {
    //   const onClickSeleccionar = ({ id }) => {
    //     this.gestionarMensaje(id);
    //   }

    //   const onClickAbrir = ({ practica }) => {
    //     this.setState({ accion: 'procesos', accionProceso: 'Tod_Pra' });
    //     this.obtenerPracticas([{ 'llave': 'id', 'valor': practica }]);
    //   }

    //   return <><BtnSeleccion callback={() => onClickSeleccionar(data)} texto='Leído' /> <BtnVer callback={() => onClickAbrir(data)} texto='Ver' /></>;
    // }

    // const accionesSolicitudes = (data) => {
    //   const onClickAbrir = ({ id }) => {
    //     this.setState({ accion: 'procesos', accionProceso: 'Tod_Pra' });
    //     this.obtenerPracticas([{ 'llave': 'id', 'valor': id }]);
    //   }
    //   return <BtnVer callback={() => onClickAbrir(data)} texto='Ver' />;
    // }

    if (periodo_noti) notificaciones.push(periodo_noti);

    // mensajes.map((data) => {
    //   let { mensaje, fecha_registro, usuario_registro } = data;
    //   let notificacion = {
    //     icono: mensajesIcon,
    //     titulo: '¡ Nuevo Mensaje !',
    //     mensaje: `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} dice : ${mensaje}`,
    //     fecha: `Registrado el ${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
    //     tipo: 'mensaje',
    //     acciones: () => accionesMensajes(data),
    //   }
    //   notificaciones.push(notificacion)
    // });

    // sol_vencidas.map((data) => {
    //   let { fecha_limite, usuario_registro } = data;
    //   let notificacion = {
    //     icono: fechaIcon,
    //     titulo: `¡ Buscar Empresa !`,
    //     mensaje: `El tiempo asignado para que el estudiante ${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} encontrara sus practicas ha caducado `,
    //     fecha: `Disponible hasta ${moment(fecha_limite).format('YYYY-MM-DD, h:mm a')}`,
    //     tipo: 'mensaje',
    //     acciones: () => accionesSolicitudes(data),
    //   }
    //   notificaciones.push(notificacion)
    // })

    this.setState({ notificaciones, cargandoNotif: false });
  }

  notificacionPeriodo(periodo_mod, fecha_periodo) {
    let { administrador } = this.state;
    let notificacion = null;
    if (fecha_periodo) {
      let fecha1 = moment();
      let fecha2 = moment(fecha_periodo);
      let dias = fecha2.diff(fecha1, 'days');
      if (dias < 15) {
        let mensaje = dias < 0 ? `El período actual ${periodo_mod} se ha cerrado, tener en cuenta que los estudiantes no podrán realizar solicitudes. ${!administrador ? 'Por favor, contacta al administrador del modulo para que actualice el periodo.' : ''}` : `El periodo actual ${periodo_mod} se cerrara ${dias === 0 ? 'el día de hoy' : `en ${dias} días`}, una vez el periodo se cierre los estudiantes no podrán realizar solicitudes.`
        notificacion = {
          icono: vistaIcon,
          titulo: '¡ Actualizar Período !',
          mensaje,
          tipo: 'fecha_periodo',
          acciones: () => administrador && <BtnEditar texto='Actualizar Periodo' callback={() => this.props.mostrarModalModificarPeriodo(true)} color='#01579b' />,
        }
      }
    }
    return notificacion;
  }

  async actualizarDataValidaciones(filtros = [{ 'llave': 'estado_actual__valorc', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    this.setState({ cargando: true })
    let validaciones = await this.obtenerValidaciones(filtros);
    this.setState({ validaciones, cargando: false, filtros_validaciones: filtros })
  }

  render() {
    let { accion, cargando, validacion, administrador, vistaDetalle, id_estudiante, accionProceso, periodo_mod, fecha } = this.state;
    let { mostrarModalDetalleValidaciones, modalDetValidaciones, mostrarModalComment, modalAddComment, mostrarModalFiltroValidacion, modalFiltroValidacion, modalModificarPeriodo, mostrarModalModificarPeriodo } = this.props;
    return (
      <div>
        <MenuValidaciones
          seleccionar={(accion) => this.cambiarEstado({ accion })}
          sele={accion}
          administrador={administrador} />
        <div className='contenido_modulo'>
          <ValidacionesFiltro
            actualizarMensaje={actualizarMensaje}
            modalFiltroValidacion={modalFiltroValidacion}
            mostrarModalFiltroValidacion={mostrarModalFiltroValidacion}
            filtrar={(filtros) => this.actualizarDataValidaciones(this.configFiltros(accionProceso).concat(filtros))}
            limpiar={() => this.actualizarDataValidaciones(this.configFiltros(accionProceso))}
            accionProceso={accionProceso}
            mostrarTodos={(accion) => this.setState({ accionProceso: accion })}
          />
          <ValidacionesDetalle
            id_estudiante={id_estudiante}
            mostrarModalDetalleValidaciones={mostrarModalDetalleValidaciones}
            modalDetValidaciones={modalDetValidaciones}
            vistaDetalle='detalle'
            admin={false}
            validacionActual={validacion ? validacion.id : 0}
          />
          <EnviarMensajeFecha
            modal={modalModificarPeriodo}
            mostrarModal={mostrarModalModificarPeriodo}
            enviar={(periodo_mod, fecha) => this.modificarPeriodo(periodo_mod, fecha)} label='Periodo' type='text' titulo={`Nuevo Periodo`} label_fecha='Fecha Vencimiento'
          />
          {
            !cargando ?
              <div>
                {accion === 'Procesos' && this.renderProcesos()}
                {accion === 'Permisos' && this.pintarAdministrar()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalDetValidaciones, modalGestionValidacion, modalFiltroValidacion, modalModificarPeriodo, } = state.redValidaciones
  let { usuario } = state.redGlobal;
  return {
    usuario,
    modalDetValidaciones,
    modalGestionValidacion,
    modalFiltroValidacion,
    modalModificarPeriodo,
  }
}

const mapDispatchToProps = {
  mostrarModulo,
  mostrarModalDetalleValidaciones,
  mostrarGestionValidacion,
  actualizarMensaje,
  mostrarModalFiltroValidacion,
  mostrarModalModificarPeriodo
}

ValidacionesAdministrar.propTypes = {
  //funciones
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalDetalleValidaciones: PropTypes.func.isRequired,
  mostrarGestionValidacion: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalModificarPeriodo: PropTypes.func.isRequired,

  //variables
  modalDetValidaciones: PropTypes.bool.isRequired,
  modalGestionValidacion: PropTypes.bool.isRequired,
  modalFiltroValidacion: PropTypes.bool.isRequired,
  modalModificarPeriodo: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidacionesAdministrar);