import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, DialogActions, Grid, Dialog, DialogContent, ListSubheader, List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal';
import { obtenerEstadosProceso } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { MenuGestionar } from "./MenuAutodiagnostico";
import { ESTADOS } from "../helper";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

class AutodiagnosticoGestionar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      observacion: '',
      estados_siguiente: [],
    }
  }

  async componentDidUpdate({ modalGestionAutodiag }, { estado }) {
    let { modalGestionAutodiag: modalGestionAutodiagNew, autodiagnostico } = this.props;
    let { estado: estadoNew } = this.state;
    if (estadoNew !== estado) this.setState({ observacion: '' });
    if (modalGestionAutodiag !== modalGestionAutodiagNew) {
      if (modalGestionAutodiagNew) {
        this.reiniciarEstado();
        let estados_siguiente = await obtenerEstadosProceso(autodiagnostico.tipo.id, [{ 'llave': 'principal', 'valor': autodiagnostico.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS }]);
        this.setState({ estados_siguiente, cargando: false }
        );
      }
    }
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: '',
      observacion: '',
      estados_siguiente: [],
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  onSubmit = e => {
    let { autodiagnostico, gestionarAutodiagnostico } = this.props;
    let { estado, observacion } = this.state;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = { estado, observacion }
    gestionarAutodiagnostico(autodiagnostico.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
    e.preventDefault();
  }

  pintarEstados() {
    let { estado, estados_siguiente, observacion } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado} estados_siguiente={estados_siguiente} />
        </Grid>
        {estado && (
          <Grid item xs={12} md={12}>
            <FormControl className="form-control" >
              <TextField
                value={observacion}
                id="observacion"
                label="Observaciones"
                name="observacion"
                placeholder="Escriba Observaciones"
                multiline
                onChange={this.onChange}
                inputProps={{ maxLength: "200" }}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    )
  }

  render() {
    let { modalGestionAutodiag, mostrarGestionAutodiag } = this.props;
    let { cargando, titulo_res } = this.state;
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalGestionAutodiag} onClose={() => mostrarGestionAutodiag(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={'¿ Gestionar Autodiagnóstico ?'} mostrarModal={mostrarGestionAutodiag} titulo_accion="" accion="" />
        <MenuGestionar seleccionar={() => { }} sele={'gestionar'} />
        <DialogContent style={{ padding: '1' }} className='scroll'>
          {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" /> : <div style={{ padding: "5px 0 5px 0" }}>{this.pintarEstados()}</div>}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={() => mostrarGestionAutodiag(false)} />
          {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    )
  }
}

export default AutodiagnosticoGestionar;
