import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BtnEliminar, BtnEditar, BtnPermiso, BtnDetalle } from "../../general/BotonesAccion";
import moment from 'moment'
import ListarDatos from '../../general/ListarDatos';

class ValoresListar extends Component {

  render() {
    let { valores, mostrarModalAddValores, mostrarModalModValores, actualizarDataValor, mostrarModalDelValores, mostrarModalAddPermisoValores, mostrarModalDetValores } = this.props;

    const actions = (rowData) => {

      const onClickEliminar = (data) => {
        actualizarDataValor(data);
        mostrarModalDelValores(true);
      }

      const onClickEditar = (data) => {
        actualizarDataValor(data);
        mostrarModalModValores(true);
      }

      const onClickPermisos = (data) => {
        actualizarDataValor(data);
        mostrarModalAddPermisoValores(true);
      }

      const onClickDetalle = (data) => {
        actualizarDataValor(data);
        mostrarModalDetValores(true);
      }

      return <div> <BtnDetalle callback={() => onClickDetalle(rowData)} /> <BtnEliminar callback={() => onClickEliminar(rowData)} /> <BtnEditar callback={() => onClickEditar(rowData)} /> <BtnPermiso callback={() => onClickPermisos(rowData)} /></div>;
    }

    return (
      <div>
        <ListarDatos
          id="tbl_listar_valores"
          descargar="EMMA - VALORES"
          datos={valores}
          opciones={true}
          agregar={true}
          buscar={true}
          actAgregar={() => mostrarModalAddValores(true)}
          acciones={(row) => actions(row)}
          titulo={"Lista valores"}
          fila_principal={({ nombre, id }) => `${id} - ${nombre}`}
          filas={[
            {
              'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
              'id': 'fecha_registro',
              'enLista': true
            },
            { 'id': 'id', 'enLista':false },
            { 'id': 'nombre', 'enLista':false },
            { 'id': 'descripcion' },
            { 'id': 'codigo' },
            { 'id': 'archivo', 'enLista': false },
            { 'id': 'id', 'enLista': false },
            { 'id': 'estado', 'enLista': false },
            { 'id': 'fecha_elimino', 'enLista': false },
            { 'id': 'generica', 'enLista': false },
            { 'id': 'valora', 'enLista': false },
            { 'id': 'valorb', 'enLista': false },
            { 'id': 'valorc', 'enLista': false },
            { 'id': 'valord', 'enLista': false },
            { 'id': 'valore', 'enLista': false },
            { 'id': 'valorf', 'enLista': false },
            { 'id': 'valorg', 'enLista': false },
          ]}
        />
      </div>
    );
  }

}

//Props del componente
ValoresListar.propTypes = {
  //variables
  valores: PropTypes.array.isRequired,
  //funciones
  actualizarDataValor: PropTypes.func.isRequired,
  mostrarModalModValores: PropTypes.func.isRequired,
  mostrarModalDelValores: PropTypes.func.isRequired,
  mostrarModalAddValores: PropTypes.func.isRequired,
}


export default ValoresListar;


