import React, { Component, version } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Paper,
  Grid,
  Button,
  Typography,
  Tooltip,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  List,
  ListItemText,
  Divider,
  TableBody,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import CheckIcon from "@material-ui/icons/Check";

//COMPONENTES DE SISEF
import SisefCardSolicitud from "./sisef/SisefCardSolicitud";
import SisefNewSolicitud from "./sisef/SisefNewSolicitud";
import SisefTables from "./sisef/SisefTables";
import {
  C_Especifica,
  Gran_Pregunta,
  C_Generica,
  U_Formacion,
  InfoBasica,
} from "./sisef/Syllabus_Opciones";
import Sisef from "./Sisef";
import moment from "moment";
//MENÚ
import { Menu } from "./sisef/MenuSisef";

//COMPONENTES GENERALES
import AccionesRapidas from "../general/AccionesRapidas";
import TituloAccion from "../general/TituloAccion";
import {
  mostrarModulo,
  actualizarMensaje,
} from "../../redux/actions/actGlobal";
import {
  coloresEmma,
  consulta,
  crear_form_data,
  formulario,
  mostrarError,
  obtenerValoresFiltros,
} from "../../global/js/funciones";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_s from "../../global/imagenes/emma_s1.png";
import emma_w from "./../../global/imagenes/emma_w.png";
import book from "./../../global/imagenes/asignatura8.png";
import articleInvestigacion from "./../../global/imagenes/articleInvestigacion.png";
import cgenerica from "./../../global/imagenes/readocente.png";
import cespecifica from "./../../global/imagenes/lightbulb.png";
import uformacion from "./../../global/imagenes/study.png";
import { BtnApagar, BtnDuplicar, BtnEditar, BtnEliminar, BtnEncender, BtnRegresar, BtnRegresarinicio } from "../general/BotonesAccion";
import GetAppIcon from "@material-ui/icons/GetApp";
import source from "./../../global/fonts/PTSerif-Regular.ttf";
import sourcebold from "./../../global/fonts/PTSerif-Bold.ttf";
import cuc from "../../global/imagenes/logo_cuc_vertical.png";
import Cargando from "../general/app/Cargando";
import { CheckBox, Style } from "@material-ui/icons";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Styles } from "docx";
import ConfirmarAccion from "../general/ConfirmarAccion";
import Listar_Asignaturas from "./gestion/Listar_Asignaturas";
import SisefModificar from "./sisef/SisefModificar";
import { mostrarModificarSisef } from "../../redux/actions/actSisef";
import { SISEF_ESTADOS, SISEF_MASIVO } from "./helper";
import { CargaMasiva } from "./sisef/cargaMasiva";
import { Buscar } from "../general/app/Buscar";

Font.register({ family: "PTSerif", src: source });
Font.register({ family: "PTSerifBold", src: sourcebold });
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    container: 0,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    marginLeft: 'auto',
    marginRight: 'auto',
    // width: "200px",
    // height: "100px",
    padding: 'auto 45px auto 45px',
    borderBottomLeftRadius: "1",

  },
  title: {
    fontSize: 15,
    textAlign: "center",
    fontFamily: "PTSerifBold",
  },
  author: {
    fontSize: 12,
    textAlign: "lef",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 13,
    // margin: 12,
    textAlign: "center",
    fontFamily: "PTSerifBold",
  },
  text: {
    margin: 5,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: "PTSerif",

  },
  image: {
    marginVertical: 10,
    marginHorizontal: 150,
    width: "220px",
    height: "140px",
  },
  header: {
    fontSize: 8,
    marginBottom: 20,
    textAlign: "center",
    color: "black",
    margin: "15px 0 15px 0",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginVertical: 20,

  },
  container: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    // borderTop: '1px solid black',
    // marginBottom: "10px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    // borderTop: "1px solid black",
    // borderRight: "1px solid black",
    // borderLeft: "1px solid black",
  },
  cell: {
    fontSize: 7,
    borderBottom: '1px solid black',
    // borderTop: '1px solid black',
    // borderLeft: '1px solid black',
    // marginTop: "0 0 0 0",
    // marginTop: "3 3 3 3",
    display: "flex",
    justifyContent: "center",
    // alignItems: "stretch",
    alignContent: "center",
    padding: "3px",
    // borderWidth: 1.2,
    // flexWrap: 'wrap',
    // height: 'auto'
  },
  borderB: {
    borderBottom: 1,
    margin: "0 -1px -1px 0",
  },
  em: {
    fontFamily: "PTSerifBold",
  },
  ul: {
    liststyleType: "square",
  },
  li: {
    lineHeight: 1.7,
  },
  btn_carga: {
    backgroundColor: coloresEmma.secondarycolor,
    color: "white",
    borderRadius: "10px",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  },

});

class Sisef_Syllabus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccion: "miproceso",
      id_plan: "",
      cargando: true,
      nuevo: true,
      ModalGranPreg: false,
      ModalComEsp: false,
      ModalComGen: false,
      ModalUndFor: false,
      ModalEliminar: false,
      ModalEditar: true,
      ModalDuplicar: false,
      plan_actual: {},
      estado: true,
      mensaje: "",
      syllabus: [],
      tipos: [],
      estados_genericas: [],
      tipoSolicitud: {},
      data_principal: {},
      periodo_actual: null,
      modalCargarSyllabus: false,
      titulo: "Cargando...",
      archivo_syllabus: [],
      ModalInfoBasica: false,
      data_basica: {},
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ rutas: "sisef", nombre: "Sisef" });
    await this.obtenerDatosIniciales();
  }

  async componentDidUpdate({ }, { seleccion, modalCargarSyllabus, dato_buscar }) {
    let { seleccion: seleccionNew, modalCargarSyllabus: modalCargarSyllabusNew, dato_buscar: dato_buscarNew } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === "miproceso") await this.obtenerDatosIniciales();
      else if (seleccionNew === "nuevo") this.setState({ nuevo: true });
    }
    if (modalCargarSyllabus !== modalCargarSyllabusNew) {
      if (modalCargarSyllabusNew) {
        let archivo_syllabus = await obtenerValoresFiltros([{ llave: "generica", valor: SISEF_MASIVO }, { llave: "estado", valor: "1" }, { llave: "codigo", valor: "masivo_syllabus" }]);
        this.setState({ archivo_syllabus: archivo_syllabus[0].archivo })
      }
    }
    // if (dato_buscar !== dato_buscarNew) {
    //   let syllabus = this.buscar();
    //   this.setState({ syllabus });
    // }
  }

  convertir = dato => typeof dato === 'string' || dato instanceof String ? dato.toLowerCase() : `${dato}`;

  buscar = () => {
    const { dato_buscar, syllabus } = this.state;
  
    const datoConvertido = this.convertir(dato_buscar);
  
    const regex = new RegExp(datoConvertido, 'i');
  
    const datosFiltrados = syllabus.filter(data => {
      return regex.test(data.asignatura_nombre) || regex.test(data.codigo_asignatura);
    });
  
    return datosFiltrados;
  }

  cambiarEstado = (estados) => {
    this.setState(estados);
  };

  cambiarOpcion = (seleccion) => {
    this.cambiarEstado({ seleccion });
  };

  toggleDialog(toggle) {
    let { modal, value } = toggle;
    this.setState({ [modal]: value });
  }

  async obtenerSyllabus(periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_syllabus/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerEstados(id) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_estados/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerInfoSyllabus(id) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/obtener_info_syllabus/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerDatosPdf() {
    let data = {};
    let mision_i = await obtenerValoresFiltros([
      { llave: "codigo", valor: "mision_institucional" }, { llave: 'estado', valor: '1' }
    ]);
    let vision_i = await obtenerValoresFiltros([
      { llave: "codigo", valor: "vision_institucional" }, { llave: 'estado', valor: '1' }
    ]);
    data.mision_i = mision_i[0].descripcion;
    data.vision_i = vision_i[0].descripcion;
    this.setState({ data_principal: data });
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    // let mensaje = "Usted aún no cuenta con un proceso";
    let data_periodo = await obtenerValoresFiltros([
      { llave: "codigo", valor: "Per_Sisef" }, { llave: 'estado', valor: '1' }
    ]);
    let estados_genericas = await obtenerValoresFiltros([{ llave: 'generica', valor: SISEF_ESTADOS }, { llave: 'estado', valor: '1' }])

    data_periodo = data_periodo[0].nombre;
    await this.ObtenerDatosPdf();
    await this.obtenerProcesos(data_periodo);
    this.setState({ cargando: false, periodo_actual: data_periodo, estados_genericas: estados_genericas });
  }

  async obtenerDatosEditar(id) {
    this.setState({ cargando: true });
    try {
      const response = await fetch(`/api/sisef/${id}`);
      const data = await response.json();
      const { codigo_asignatura, plan_syll, valores_plan, resumen_plan, perfil_profesor } = data;
      this.setState({
        codigo_asignatura: { valor: codigo_asignatura },
        plan_syll: { valor: plan_syll },
        valores_plan: { valor: valores_plan },
        resumen_plan: { valor: resumen_plan },
        perfil_profesor: { valor: perfil_profesor },
        asignatura_nombre: { valor: Listar_Asignaturas.filter(item => item.codigo_asignatura == codigo_asignatura)[0].nombre_asignatura },
        cargando: false,
      });
    } catch (error) {
      console.error('Error al obtener los datos del registro a editar:', error);
      this.setState({ cargando: false });
    }
  }

  async obtenerProcesos(periodo) {
    // this.setState({ cargando: true });
    let data = await this.obtenerSyllabus(periodo);
    console.log(data)
    // if (data) {
    //   data.map(async (syll) => {
    //     // syll.unidades.map(
    //     //   async (uni) => (
    //     //     uni.contenidos = uni.indicadores.filter(item => item.tipo.codigo === "cont_sisef"),
    //     //     uni.indicadores = uni.indicadores.filter(item => item.tipo.codigo === "ind_dsp"),
    //     //     uni.asincronico = uni.estrategias.filter(item => item.tipo.codigo === "est_asincr"),
    //     //     uni.sincronico = uni.estrategias.filter(item => item.tipo.codigo === "est_sincr"),
    //     //     uni.evaluativa = uni.estrategias.filter(item => item.tipo.codigo === "est_eva"),
    //     //     uni.basica = uni.referencias.filter(item => item.tipo.codigo === "bibliografia_basica_sisef"),
    //     //     uni.complementaria = uni.referencias.filter(item => item.tipo.codigo === "sisef_bibliografia_complementaria"),
    //     //     uni.web = uni.referencias.filter(item => item.tipo.codigo === "sisef_sitio_web"),
    //     //     uni.equipos = uni.recursos.filter(item => item.tipo.codigo === "sisef_equipos"),
    //     //     uni.materiales = uni.recursos.filter(item => item.tipo.codigo === "sisef_materiales"),
    //     //     uni.herramientas = uni.recursos.filter(item => item.tipo.codigo === "herramientas_sisef")
    //     //   )
    //     // );
    //     // let datos = await this.obtenerEstados(syll.id);
    //     // syll.estados = datos;
    //   });
    this.setState({ syllabus: data, /* cargando: false  */ });
    console.log(data);
    // }
  }

  EditarSyllabus = async (data) => {
    let { id_plan } = this.state;
    let { actualizarMensaje, mostrarModificarSisef } = this.props
    let form = await crear_form_data(data)
    formulario(
      `api/v1.0/sisef/${id_plan}/editar_syllabus`,
      form,
      "patch",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.obtenerDatosIniciales()
            mostrarModificarSisef(false)
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  EliminarSyllabus = async () => {
    let { plan_actual, periodo_actual } = this.state;
    let { actualizarMensaje } = this.props;
    consulta(
      `api/v1.0/sisef/${plan_actual.id}/eliminar_syllabus`,
      {},
      "put",
      async (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            await this.obtenerProcesos(periodo_actual);
            this.setState({ ModalEliminar: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  /*duplicar el syllabus */
  duplicarSyllabus = async () => {
    let { plan_actual, periodo_actual } = this.state;
    let { actualizarMensaje } = this.props;
    consulta(
      `api/v1.0/sisef/${plan_actual.id}/duplicar_syllabus`,
      {},
      "post",
      async (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            await this.obtenerProcesos(periodo_actual);
            this.setState({ ModalDuplicar: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  enviarArchivo = async (archivo) => {
    let { periodo_actual } = this.state;
    let { actualizarMensaje } = this.props;

    if (archivo == undefined) return actualizarMensaje({ titulo: 'Debe adjuntar el documento', tipo: 'info', mostrar: true, tiempo: 6000 })

    this.setState({ modalCargarSyllabus: false, cargando: true, titulo: 'Subiendo archivo...' });
    let data = await crear_form_data({ archivo });
    formulario(`api/v1.0/sisef/cargarSyllabusMasivo`, data, 'post', async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ titulo: 'Cargando...' });
          await this.obtenerProcesos(periodo_actual);
        } else {
          tipo = 'info';
          console.log(resp);
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      this.setState({ cargando: false });
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
    });
  }

  vista = () => {
    let { seleccion, estado } = this.state;
    switch (seleccion) {
      case "miproceso":
        return estado
          ? this.pintarSolicitudes()
          : this.pintarSolicitudSeleccionada();
      case "nuevo":
        return this.pintarNewSolicitud();
      default:
        break;
    }
  };

  generarPDF(syll, preguntass, genericas, especificas, unidades) {
    let { data_principal } = this.state;
    let fecha_actual = moment();
    let hoy = `${fecha_actual.format("D")}/${fecha_actual.format(
      "M"
    )}/${fecha_actual.format("YYYY")}`;
    console.log(syll[0].unidades)

    return (
      <>
        <Document>
          <Page size={'A4'} style={styles.body}>
            {/* <Page size="letter" style={styles.page}> */}
            <p style="width: 100%; text-align: center, borderTopLeftRadius;">
              <Image style={styles.image} src={cuc} />
            </p>
            <Text style={styles.title}>
              CORPORACIÓN UNIVERSITARIA DE LA COSTA - CUC
            </Text>
            <Text style={styles.title}>VICERRECTORÍA ACADÉMICA</Text>
            <Text style={styles.title}>FORMATO PLAN DE ASIGNATURA PA04</Text>
            <Text style={styles.header}>
              GENERADO EL {hoy + " " + fecha_actual.format("hh:mm:ss")}
            </Text>
            <Text style={styles.subtitle}>{syll[0].asignatura_nombre}</Text>
            <View style={styles.table}>

              <View style={styles.container}>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center", borderTop: "1px solid black" }]}>
                    <Text style={styles.subtitle}> 1. HORIZONTE INSTITUCIONAL</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>1.1 MISIÓN</Text>
                  </View>
                </View>
                <View style={[styles.tableRow, styles.em]}>
                  <View style={[styles.cell, { width: "50%", textAlign: "center" }]}>
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]}>
                      Misión Institucional
                    </Text>
                  </View>
                  <View style={[styles.cell, { width: "50%", textAlign: "center", borderLeft: "1px solid black" }]}>
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]}>
                      Misión del programa
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "50%", textAlign: "justify" }]}>
                    <Text style={styles.text}>{data_principal.mision_i}</Text>
                  </View>
                  <View style={[styles.cell, { width: "50%", textAlign: "justify", borderLeft: "1px solid black" }]}>
                    <Text style={styles.text}></Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>1.2 VISIÓN</Text>
                  </View>
                </View>
                <View style={[styles.tableRow, styles.em]}>
                  <View style={[styles.cell, { width: "50%", textAlign: "center" }]}>
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]}>
                      Visión Institucional
                    </Text>
                  </View>
                  <View style={[styles.cell, { width: "50%", textAlign: "center", borderLeft: "1px solid black" }]}>
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]}>
                      Visión del programa
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "50%", textAlign: "justify" }]}>
                    <Text style={styles.text}>{data_principal.vision_i}</Text>
                  </View>
                  <View style={[styles.cell, { width: "50%", textAlign: "justify", borderLeft: "1px solid black" }]}>
                    <Text style={styles.text}></Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, styles.em, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>1.3 VALORES</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "justify" }]}>
                    <Text style={styles.text}>{syll[0].valores_plan}</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>2. PERFILES</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>2.1 PERFIL PROFESOR</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.text}>{syll[0].perfil_profesor}</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>2.2 PERFIL DE FORMACIÓN</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.text}>{syll[0].perfil_profesor}</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>3. IDENTIFICACIÓN DE LA ASIGNATURA</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableRow, styles.em]}>
                    <View style={[styles.cell, { width: "50%", textAlign: "center" }]}>
                      <Text style={[styles.text, styles.em, { textAlign: "center" }]}>Departamento</Text>
                    </View>
                    <View style={[styles.cell, { width: "50%", textAlign: "center", borderLeft: "1px solid black" }]}>
                      <Text style={[styles.text, styles.em, { textAlign: "center" }]}>Programa</Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "50%", textAlign: "justify" }]}>
                    <Text style={styles.text}>{syll[0].departamento_nombre}</Text>
                  </View>
                  <View style={[styles.cell, { width: "50%", textAlign: "justify", borderLeft: "1px solid black" }]}>
                    <Text style={styles.text}>{syll[0].programa_nombre}</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, styles.em, { width: "30%", textAlign: "lef" }]}>
                    <Text style={[styles.text, styles.em]}>Nivel de formación</Text>
                  </View>
                  <View style={[styles.cell, { width: "70%", textAlign: "justify", borderLeft: "1px solid black" }]}>
                    <Text style={styles.text}></Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "30%", textAlign: "center" }]}>
                    <Text style={[styles.text, styles.em]}>Nombre de la asignatura: {syll[0].asignatura_nombre}</Text>
                  </View>
                  <View style={[styles.cell, { width: "18%", textAlign: "center", borderLeft: "1px solid black" }]}>
                    <Text style={[styles.text, styles.em]}>Horas de trabajo presencial: </Text>
                  </View>
                  <View style={[styles.cell, { width: "18%", textAlign: "center", borderLeft: "1px solid black" }]}>
                    <Text style={[styles.text, styles.em]}>Horas de trabajo independiente: </Text>
                  </View>
                  <View style={[styles.cell, { width: "17%", textAlign: "center", borderLeft: "1px solid black" }]}>
                    <Text style={[styles.text, styles.em]}>Total de horas: </Text>
                  </View>
                  <View style={[styles.cell, { width: "17%", textAlign: "center", borderLeft: "1px solid black" }]}>
                    <Text style={[styles.text, styles.em]}>Número de créditos: </Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>3.1 RESUMEN</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.text}>{syll[0].resumen_plan}</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>3.2 GRAN PREGUNTA</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%" }]}>
                    {syll[0].preguntas?.map((item, index) => (
                      <View style={{ flexDirection: "row", marginBottom: 4, width: "95%" }}>
                        <Text style={[styles.text, { marginHorizontal: 8 }]}>{index + 1}.</Text>
                        <Text style={styles.text}>{item.pregunta}</Text>
                      </View>
                    ))}
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>3.3 PLAN DE ESTUDIO</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.text}>{syll[0].plan_syll.nombre}</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}>
                      3.4 COMPETENCIAS A DESARROLLAR
                    </Text>
                  </View>
                </View>

                <View style={[styles.tableRow, styles.em]}>
                  <View style={[styles.cell, { width: "50%", textAlign: "center" }]}>
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]}>
                      Competencias Genéricas
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "50%",
                        textAlign: "center",
                        borderLeft: "1px solid black",
                      },
                    ]}
                  >
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]}>
                      Competencias Específicas
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "50%", textAlign: "center" }]} >
                    {syll[0].genericas?.map((item) => (
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: 4,
                          width: "45%",
                        }}
                      >
                        <Text style={[styles.text, { marginHorizontal: 8 }]}>
                          •
                        </Text>
                        <Text style={styles.text}>{item.competencia.nombre}</Text>
                      </View>
                    ))}
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "50%",
                        textAlign: "center",
                        borderLeft: "1px solid black",
                      },
                    ]}
                  >
                    {syll[0].especificas?.map((item) => (
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: 4,
                          width: "45%",
                        }}
                      >
                        <Text style={[styles.text, { marginHorizontal: 8 }]}>
                          •
                        </Text>
                        <Text style={styles.text}>{item.competencia}</Text>
                      </View>
                    ))}
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "100%", textAlign: "center" }]}>
                    <Text style={styles.subtitle}> 3.5 PLANEACIÓN UNIDADES DE FORMACIÓN </Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.cell, { width: "60%", textAlign: "center" }]} >
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]} >
                      Unidades
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "20%",
                        textAlign: "center",
                        borderLeft: "1px solid black",
                      },
                    ]}
                  >
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]} >
                      Horas presenciales
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        width: "20%",
                        textAlign: "center",
                        borderLeft: "1px solid black",
                      },
                    ]}
                  >
                    <Text style={[styles.text, styles.em, { textAlign: "center" }]}>
                      Horas trabajo independiente
                    </Text>
                  </View>
                </View>
                {syll[0].unidades?.map((item) => (
                  <View style={[styles.tableRow, styles.em]}>
                    <View style={[styles.cell, { width: "60%" }]}>
                      <Text style={[styles.text, { textAlign: "justify" }]}>
                        {item.nombre}
                      </Text>
                    </View>
                    <View
                      style={[styles.cell, { width: "20%", borderLeft: "1px solid black" },]}>
                      <Text style={[styles.text, { textAlign: "justify" }]}>
                        {item.hrs_presenciales}
                      </Text>
                    </View>
                    <View
                      style={[styles.cell, { width: "20%", borderLeft: "1px solid black" },]}>
                      <Text style={[styles.text, { textAlign: "justify" }]}>
                        {item.hrs_trabajo_indp}
                      </Text>
                    </View>
                  </View>
                ))}
                {syll[0].unidades?.map((item, i) => (
                  <>
                    <View style={[styles.tableRow]}>
                      <View
                        style={[
                          styles.cell, styles.em, styles.text,
                          { width: "100%", textAlign: "center" },
                        ]}
                      >
                        <Text style={styles.subtitle}>
                          3.5.{i + 1} UNIDAD N° {i + 1} {item.nombre}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.tableRow]}>
                      <View style={[styles.cell, { width: "50%" }]}>
                        <Text
                          style={[
                            styles.text,
                            styles.em,
                            { textAlign: "center" },
                          ]}
                        >
                          Resultado de aprendizaje
                        </Text>
                      </View>
                      <View
                        style={[styles.cell, { width: "50%", borderLeft: "1px solid black" },]}>
                        <Text
                          style={[
                            styles.text,
                            styles.em,
                            { textAlign: "center" },
                          ]}
                        >
                          Indicadores de desempeño
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.tableRow]}>
                      <View style={[styles.cell, { width: "50%" }]}>
                        <Text style={[styles.text, { textAlign: "center" }]}>
                          {item.resul_aprendizaje}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          { width: "50%", borderLeft: "1px solid black" },
                        ]}
                      >
                        {item.indicador?.map((ind) => (
                          <View
                            style={{
                              flexDirection: "row",
                              marginBottom: 4,
                              width: "45%",
                            }}
                          >
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              •
                            </Text>
                            <Text style={styles.text}>{ind.info}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                    <View style={[styles.tableRow]}>
                      <View
                        style={[
                          styles.cell,
                          { width: "100%", textAlign: "center" },
                        ]}
                      >
                        <Text style={styles.subtitle}>
                          3.5.{i + 1}.1 ESTRATEGIAS DIDÁCTICAS
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.tableRow, styles.em]}>
                      <View style={[styles.cell, { width: "25%" }]}>
                        <Text
                          style={[
                            styles.text,
                            styles.em,
                            { textAlign: "center" },
                          ]}
                        >
                          CONTENIDOS
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          { width: "25%", borderLeft: "1px solid black" },
                        ]}
                      >
                        <Text
                          style={[
                            styles.text,
                            styles.em,
                            { textAlign: "center" },
                          ]}
                        >
                          ESTRATEGIAS DE TRABAJO PRESENCIAL / SINCRÓNICO
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          { width: "25%", borderLeft: "1px solid black" },
                        ]}
                      >
                        <Text
                          style={[
                            styles.text,
                            styles.em,
                            { textAlign: "center" },
                          ]}
                        >
                          {" "}
                          ESTRATEGIA DE TRABAJO INDEPENDIENTE / ASINCRÓNICO
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          { width: "25%", borderLeft: "1px solid black" },
                        ]}
                      >
                        <Text
                          style={[
                            styles.text,
                            styles.em,
                            { textAlign: "center" },
                          ]}
                        >
                          ESTRATEGIAS EVALUATIVAS
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.tableRow]}>
                      <View style={[styles.cell, { width: "25%" }]}>
                        {item.contenido?.map((con, n) => (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              {i}.{n}
                            </Text>
                            <Text style={styles.text}>{con.info}</Text>
                          </View>
                        ))}
                      </View>
                      <View
                        style={[
                          styles.cell,
                          { width: "25%", borderLeft: "1px solid black" },
                        ]}
                      >
                        {item.sincronico?.map((est) => (
                          <View
                            style={{
                              flexDirection: "row",
                              // borderBottom: "1px solid black",
                            }}
                          >
                            <Text style={styles.text}>{est.nombre}</Text>
                          </View>
                        ))}
                      </View>
                      <View
                        style={[
                          styles.cell,
                          { width: "25%", borderLeft: "1px solid black" },
                        ]}
                      >
                        {item.asincronico?.map((est) => (
                          <View
                            style={{
                              flexDirection: "row",
                              // borderBottom: "1px solid black",
                            }}
                          >
                            <Text style={styles.text}>{est.nombre}</Text>
                          </View>
                        ))}
                      </View>
                      <View
                        style={[
                          styles.cell,
                          { width: "25%", borderLeft: "1px solid black" },
                        ]}
                      >
                        {item.evaluativa?.map((est) => (
                          <View
                            style={{
                              flexDirection: "row",
                              // borderBottom: "1px solid black",
                            }}
                          >
                            <Text style={styles.text}>{est.nombre}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                    <View style={[styles.tableRow]}>
                      <View
                        style={[
                          styles.cell,
                          { width: "100%", textAlign: "center" },
                        ]}
                      >
                        <Text style={styles.subtitle}>
                          3.5.{i + 1}.2 RECURSOS EDUCATIVOS
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.tableRow, styles.em]}>
                      <View
                        style={[
                          styles.cell,
                          { width: "34%", textAlign: "center" },
                        ]}
                      >
                        <Text style={[styles.text, styles.em]}>Equipos</Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          {
                            width: "33%",
                            textAlign: "center",
                            borderLeft: "1px solid black",
                          },
                        ]}
                      >
                        <Text style={[styles.text, styles.em]}>Herramientas</Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          {
                            width: "33%",
                            textAlign: "center",
                            borderLeft: "1px solid black",
                          },
                        ]}
                      >
                        <Text style={[styles.text, styles.em]}>Materiales</Text>
                      </View>
                    </View>
                    <View style={[styles.tableRow]}>
                      <View
                        style={[
                          styles.cell,
                          { width: "34%", textAlign: "center" },
                        ]}
                      >
                        {item.equipos?.map((rec) => (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              •
                            </Text>
                            <Text style={styles.text}>{rec.recurso}</Text>
                          </View>
                        ))}
                      </View>
                      <View
                        style={[
                          styles.cell,
                          {
                            width: "33%",
                            textAlign: "center",
                            borderLeft: "1px solid black",
                          },
                        ]}
                      >
                        {item.herramientas?.map((rec) => (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              •
                            </Text>
                            <Text style={styles.text}>{rec.recurso}</Text>
                          </View>
                        ))}
                      </View>
                      <View
                        style={[
                          styles.cell,
                          {
                            width: "33%",
                            textAlign: "center",
                            borderLeft: "1px solid black",
                          },
                        ]}
                      >
                        {item.materiales?.map((rec) => (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              •
                            </Text>
                            <Text style={styles.text}>{rec.recurso}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                    <View style={[styles.tableRow, // { borderBottom: "1px solid black" },
                    ]}
                    >
                      <View
                        style={[styles.cell, { width: "100%", textAlign: "lef" }]}
                      >
                        <Text style={[styles.subtitle, { textAlign: "lef" }]}>
                          REFERENCIAS
                        </Text>
                        <Text
                          style={[styles.text, styles.em, { textAlign: "lef" }]}
                        >
                          Bibliografía Básica
                        </Text>
                        {item.basica?.map((rec) => (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              •
                            </Text>
                            <Text style={styles.text}>{rec.descripcion}</Text>
                          </View>
                        ))}
                        <Text
                          style={[styles.text, styles.em, { textAlign: "lef" }]}
                        >
                          Bibliografía Complementaria
                        </Text>
                        {item.complementaria?.map((rec) => (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              •
                            </Text>
                            <Text style={styles.text}>{rec.descripcion}</Text>
                          </View>
                        ))}
                        <Text
                          style={[styles.text, styles.em, { textAlign: "lef" }]}
                        >
                          Sitio Web
                        </Text>
                        {item.web?.map((rec) => (
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.text, { marginHorizontal: 8 }]}>
                              •
                            </Text>
                            <Text style={styles.text}>{rec.descripcion}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  </>
                ))}





              </View>


            </View>
          </Page>
        </Document>
      </>
    );

  }

  pintarSolicitudSeleccionada() {
    let { plan_actual, ModalGranPreg, ModalComEsp, ModalComGen, ModalUndFor, id_plan, ModalInfoBasica, data_basica } = this.state;
    console.log(plan_actual)

    let { ModalAdd } = this.props;

    const handleClick = (syll) => {
      this.setState({ estado: true });
    };
    const handleClickInfoBasica = () => {
      let aux = {
        id: plan_actual[0].id ? plan_actual[0].id : "",
        area_formacion: plan_actual[0].area_formacion ? plan_actual[0].area_formacion : "",
        asignatura_nombre: plan_actual[0].asignatura_nombre ? plan_actual[0].asignatura_nombre : "",
        codigo_asignatura: plan_actual[0].codigo_asignatura ? plan_actual[0].codigo_asignatura : "",
        creditos: plan_actual[0].creditos ? plan_actual[0].creditos : "",
        departamento_nombre: plan_actual[0].departamento_nombre ? plan_actual[0].departamento_nombre : "",
        horas_presenciales: plan_actual[0].horas_presenciales ? plan_actual[0].horas_presenciales : "",
        horas_trabajo_independiente: plan_actual[0].horas_trabajo_independiente ? plan_actual[0].horas_trabajo_independiente : "",
        perfil_formacion: plan_actual[0].perfil_formacion ? plan_actual[0].perfil_formacion : "",
        perfil_profesor: plan_actual[0].perfil_profesor ? plan_actual[0].perfil_profesor : "",
        periodo: plan_actual[0].periodo ? plan_actual[0].periodo : "",
        pre_requisitos: plan_actual[0].pre_requisitos ? plan_actual[0].pre_requisitos : "",
        programa_nombre: plan_actual[0].programa_nombre ? plan_actual[0].programa_nombre : "",
        resumen_plan: plan_actual[0].resumen_plan ? plan_actual[0].resumen_plan : "",
        total_horas: plan_actual[0].total_horas ? plan_actual[0].total_horas : "",
        valores_plan: plan_actual[0].valores_plan ? plan_actual[0].valores_plan : "",
        nivel_formacion: plan_actual[0].nivel_formacion ? plan_actual[0].nivel_formacion.nombre : "",
      }
      this.setState({ data_basica: aux })
      this.toggleDialog({ modal: "ModalInfoBasica", value: true });
    };
    const handleClickGranPregunta = () => {
      this.toggleDialog({ modal: "ModalGranPreg", value: true });
    };
    const handleClickCompEsp = () => {
      this.setState({ ModalComEsp: true });
    };
    const handleClickCompGen = () => {
      this.toggleDialog({ modal: "ModalComGen", value: true });
    };
    const handleClickUndForm = () => {
      this.toggleDialog({ modal: "ModalUndFor", value: true });
    };


    return (
      <div className="">

        <div style={{ padding: "0% 2% 0% 2%", marginTop: "1%" }}>
          <Grid container direction={"row"}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container item xs={9} justifyContent="flex-start" alignContent="center">
                <Button onClick={() => handleClick()} style={{ display: "flex", alignItems: "center" }} color="secondary" >
                  <ArrowBackIcon />
                  <Typography variant="body2" style={{ color: "#9e9e9e" }} component="p" align="justify" />
                </Button>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BtnRegresarinicio callback={() => this.setState({ seleccion: "inicio" })} />
                </div>
                <PDFDownloadLink
                  style={{ textDecoration: "none", display: "flex", alignItems: "center" }}
                  document={this.generarPDF(plan_actual, plan_actual.preguntas, plan_actual.genericas, plan_actual.especificas, plan_actual.unidades)}
                  fileName={`SYLLABUS ` + plan_actual[0].asignatura_nombre}
                >
                  {({ loading }) =>
                    loading ? (
                      <Cargando marginTop="" texto="" />
                    ) : (
                      <Tooltip title="Descargar Syllabus" aria-label="add">
                        <IconButton edge="end" color="secondary">
                          <GetAppIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </PDFDownloadLink>
              </Grid>

              <Paper elevation={0} square className="scroll">
                <SisefTables plan={plan_actual} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid
                container
                direction={"row"}
                spacing={1}
                style={{ width: "100%", margin: "20px 0px 0px 10px" }}
              >
                <TituloAccion principal="" alterno="¿ Que deseas hacer ?" />
                <Grid item xs={12} sm={12} md={12} onClick={() => handleClickInfoBasica()}>
                  <Paper elevation={1} style={{ border: "1px solid #e0e0e0", display: "flex", alignItems: "center" }}>
                    <AccionesRapidas imagen={articleInvestigacion} titulo="¡Información Básica!" sub_titulo={plan_actual[0].asignatura_nombre ? "Bien" : "No hay información registrada"} des_sub_titulo={plan_actual[0].asignatura_nombre ? ", Aquí puedes ver toda la información actual." : ""} IconButton={<CheckBox icon="CheckBox" />} /* activeStep={plan_actual[0].preguntas.estados} */ />
                    <div style={{ marginLeft: "auto" }}>
                      {plan_actual[0].asignatura_nombre ?
                        <Tooltip title="Información Básica">
                          <CheckBox style={{ color: "#c79c3e" }} />
                        </Tooltip>
                        :
                        <Tooltip title="Información Básica">
                          <CheckBox style={{ color: "gray" }} />
                        </Tooltip>
                      }
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  onClick={() => handleClickGranPregunta()}
                >
                  <Paper elevation={1} style={{ border: "1px solid #e0e0e0", display: "flex", alignItems: "center" }}>
                    <AccionesRapidas
                      imagen={book}
                      titulo="¡Gran pregunta!"
                      sub_titulo={plan_actual[0].preguntas.length > 0 ? "Bien" : "No hay preguntas registradas"}
                      des_sub_titulo={plan_actual[0].preguntas.length > 0 ? ", Aquí puedes ver toda la información actual." : ""}
                      IconButton={<CheckBox icon="CheckBox" />}
                      activeStep={plan_actual[0].preguntas.estados}
                    />
                    <div style={{ marginLeft: "auto" }}>
                      {plan_actual[0].preguntas.length > 0 ?
                        <Tooltip title="Gran Pregunta">
                          <CheckBox style={{ color: "#c79c3e" }} />
                        </Tooltip>
                        :
                        <Tooltip title="Gran Pregunta">
                          <CheckBox style={{ color: "gray" }} />
                        </Tooltip>
                      }
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  onClick={() => handleClickCompGen()}
                >
                  <Paper elevation={3} style={{ border: "1px solid #e0e0e0", display: "flex", alignItems: "center" }}>
                    <AccionesRapidas
                      imagen={cgenerica}
                      titulo="¡ Competencias genericas !"
                      sub_titulo={plan_actual[0].genericas.length > 0 ? "Bien" : "No hay competencias genericas registradas"}
                      des_sub_titulo={plan_actual[0].genericas.length > 0 ? ", Aquí puedes ver toda la información actual." : ""}
                      IconButton={<CheckBox icon="CheckBox" />}
                      activeStep={plan_actual[0].genericas.estados}
                    />
                    <div style={{ marginLeft: "auto" }}>
                      {plan_actual[0].genericas.length > 0 ?
                        <Tooltip title="Competencia generica">
                          <CheckBox style={{ color: "#c79c3e" }} />
                        </Tooltip>
                        :
                        <Tooltip title="Competencia generica">
                          <CheckBox style={{ color: "gray" }} />
                        </Tooltip>
                      }
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  onClick={() => handleClickCompEsp()}
                >
                  <Paper elevation={1} style={{ border: "1px solid #e0e0e0", display: "flex", alignItems: "center" }}>
                    <AccionesRapidas
                      imagen={cespecifica}
                      titulo="¡Competencias especificas!"
                      sub_titulo={plan_actual[0].especificas.length > 0 ? "Bien" : "No hay competencias especificas registradas"}
                      des_sub_titulo={plan_actual[0].especificas.length > 0 ? ", Aquí puedes ver toda la información actual." : ""}
                      IconButton={<CheckBox icon="CheckBox" />}
                      activeStep={plan_actual[0].especificas.estados}
                    />
                    <div style={{ marginLeft: "auto" }}>
                      {plan_actual[0].especificas.length > 0 ?
                        <Tooltip title="Competencias especificas">
                          <CheckBox style={{ color: "#c79c3e" }} />
                        </Tooltip>
                        :
                        <Tooltip title="Competencias especificas">
                          <CheckBox style={{ color: "gray" }} />
                        </Tooltip>
                      }
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  onClick={() => handleClickUndForm()}
                >
                  <Paper elevation={3} style={{ border: "1px solid #e0e0e0", display: "flex", alignItems: "center" }}>
                    <AccionesRapidas
                      imagen={uformacion}
                      titulo="¡ Unidades de formación !"
                      sub_titulo={plan_actual[0].unidades.length > 0 ? "Bien" : "No hay unidades de Formacion registradas"}
                      des_sub_titulo={plan_actual[0].unidades.length > 0 ? ", Aquí puedes ver toda la información actual." : ""}
                      IconButton={<CheckBox icon="CheckBox" />}
                      activeStep={plan_actual[0].unidades.estados}
                    />
                    <div style={{ marginLeft: "auto" }}>
                      <Tooltip title="Unidades de formación">
                        {plan_actual[0].unidades.length > 0 ?
                          <CheckBox style={{ color: "#c79c3e" }} />
                          :
                          <CheckBox style={{ color: "gray" }} />
                        }
                      </Tooltip>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* MODALES */}
        {
          ModalInfoBasica && data_basica &&
          <InfoBasica Modal={ModalInfoBasica} cerrarModal={(resp) => this.setState({ ModalInfoBasica: resp })} data={data_basica} />
        }
        {
          ModalComGen &&
          <C_Generica Modal={ModalComGen} cerrarModal={(resp) => this.setState({ ModalComGen: resp })} id_plan={id_plan} />
        }
        {
          ModalGranPreg &&
          <Gran_Pregunta Modal={ModalGranPreg} cerrarModal={(resp) => this.setState({ ModalGranPreg: resp })} id_plan={id_plan} />
        }
        {
          ModalComEsp &&
          <C_Especifica Modal={ModalComEsp} cerrarModal={(resp) => this.setState({ ModalComEsp: resp })} id_plan={id_plan} />
        }
        {
          ModalUndFor &&
          <U_Formacion Modal={ModalUndFor} cerrarModal={(resp) => this.setState({ ModalUndFor: resp })} id_plan={id_plan} />
        }
      </div>
    );
  }

  async UpdateSyllabus(id, data) {
    let { actualizarMensaje } = this.props;
    let { periodo_actual } = this.state;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_syllabus`,
      form,
      "patch",
      async (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            await this.obtenerProcesos(periodo_actual);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        }
      }
    );
  }

  async ActiveSyllabus(id, data) {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_syllabus`,
      form,
      "patch",
      async (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            await this.obtenerProcesos();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        }
      }
    );
  }

  menu() {
    return (
      <>
        <div style={{ padding: "1% 0% 0% 1%" }}>
          <BtnRegresar
            callback={() => this.setState({ seleccion: "inicio" })}
          />
        </div>
        <div style={{ padding: "2% 2% 0% 2%" }}>
          {/* outline: "1px solid #e0e0e0", padding: 0, margin: 0 */}
          <>
            <Grid container direction={"row"} spacing={2}>
              <Grid container spacing={1} direction="row">
                <Grid item xs={5} sm={5} md={4} lg={4}>
                  <TituloAccion principal="" alterno="¡ Lista de Procesos !" />
                </Grid>
                <Grid item xs={2} sm={2} md={6} lg={6}>
                  <Buscar dato_buscar={this.state.dato_buscar} setBuscar={(dato_buscar) => this.setState({ dato_buscar })} className='Buscar2' />
                </Grid>
                <Grid item xs={3} sm={3} md={2} lg={2}>
                  <Button
                    onClick={() => this.setState({ modalCargarSyllabus: true })}
                    variant="contained"
                    color="secondary"
                    style={styles.btn_carga}
                  >
                    Cargar Syllabus
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        </div>
      </>
    )
  }

  pintarSolicitudes() {
    let { syllabus, id_plan, plan_actual, estados_genericas } = this.state;
    let { mostrarModificarSisef, modalModificarSisef, actualizarMensaje } = this.props;
    let copia_syll = this.state.dato_buscar ? this.buscar() : syllabus;
    if ((!syllabus || syllabus.length === 0)|| (!copia_syll || copia_syll.length === 0)) {
      return (
        <>
          {this.menu()}
          <div
            className="pointer"
            onClick={() => this.setState({ seleccion: "nuevo" })}
          >
            <TareasTerminadas
              mensaje="Usted aún no cuenta con un proceso"
              marginTop="7%"
              imagen={emma_s}
              widthImg="7%"
            />
          </div>
        </>
      );
    } else {
      const handleClick = async (syll_id) => {
        let syll_new = await this.ObtenerInfoSyllabus(syll_id)
        syll_new[0].unidades.map(async (uni) => (
          uni.contenidos = uni.indicadores.filter(item => item.tipo.codigo === "cont_sisef"),
          uni.indicadores = uni.indicadores.filter(item => item.tipo.codigo === "ind_dsp"),
          uni.asincronico = uni.estrategias.filter(item => item.tipo.codigo === "est_asincr"),
          uni.sincronico = uni.estrategias.filter(item => item.tipo.codigo === "est_sincr"),
          uni.evaluativa = uni.estrategias.filter(item => item.tipo.codigo === "est_eva"),
          uni.basica = uni.referencias.filter(item => item.tipo.codigo === "bibliografia_basica_sisef"),
          uni.complementaria = uni.referencias.filter(item => item.tipo.codigo === "sisef_bibliografia_complementaria"),
          uni.web = uni.referencias.filter(item => item.tipo.codigo === "sisef_sitio_web"),
          uni.equipos = uni.recursos.filter(item => item.tipo.codigo === "sisef_equipos"),
          uni.materiales = uni.recursos.filter(item => item.tipo.codigo === "sisef_materiales"),
          uni.herramientas = uni.recursos.filter(item => item.tipo.codigo === "herramientas_sisef")
        )
        );
        console.log(syll_new)
        this.setState({ id_plan: syll_id, estado: false, plan_actual: syll_new });
      };
      const handleClickEditar = async (syll) => {
        mostrarModificarSisef(true)
        this.setState({ id_plan: syll.id, plan_actual: syll });
      };

      const ModEstado = async (row) => {
        let data = {
          publicar: row.publicar == 0 ? 1 : 0,
        };
        this.UpdateSyllabus(row.id, data);
      };

      return (
        <>
          <div className="pointer">
            {/* Menu buscar */}
            {this.menu()}
            <div style={{ padding: "2% 2% 0% 2%" }}>
              <>
                <Grid container direction={"row"} spacing={2} >
                  {copia_syll.map((syll) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={syll.id}
                        container
                      >
                        <Paper elevation={1}>
                          <div style={{ opacity: syll.publicar == 0 ? 0.5 : 1 }}>
                            <Grid container spacing={3}>
                              <Grid item xs={2}>
                                {syll.publicar == 0 ? (
                                  <BtnEncender
                                    texto="Publicar"
                                    callback={() => ModEstado(syll)}
                                  />
                                ) : (
                                  <BtnApagar
                                    texto="Ocultar"
                                    callback={() => ModEstado(syll)}
                                  />
                                )}
                              </Grid >
                              <Grid item xs={2}>
                                <BtnEliminar texto="Eliminar" callback={() => this.setState({ ModalEliminar: true, plan_actual: syll })} />
                              </Grid>
                              <Grid item xs={2}>
                                <BtnDuplicar texto="Duplicar" color='#C79C3E' callback={() => this.setState({ ModalDuplicar: true, plan_actual: syll })} />
                                {/* <BtnDuplicar callback={() => onClickDuplicar(data)} color='#01579b' texto='Duplicar' />; */}
                              </Grid>
                              <Grid item xs={2}>
                                <BtnEditar texto="Editar" color='#C79C3E' callback={() => handleClickEditar(syll)} />
                              </Grid>
                            </Grid>
                            <Box
                              onClick={() => handleClick(syll.id)}
                              className="pointer"
                            >
                              <SisefCardSolicitud
                                id={syll.id}
                                procesos={`Versión ${syll.version} `}
                                codigo_asignatura={`${syll.codigo_asignatura}`}
                                nombre={`${syll.asignatura_nombre}`}

                                descripcion={`Este es el plan de estudio de ${syll.asignatura_nombre}. Clic aquí para obtener mas información.`}
                                imagen={book}
                                activeStep={syll.estados}
                                syllPublicar={syll.publicar}
                                estados_genericas={estados_genericas}
                              />
                            </Box>
                          </div>
                        </Paper>
                      </Grid>
                    </>
                  ))}
                </Grid>
                {modalModificarSisef == true ? (
                  <SisefModificar
                    actualizarMensaje={actualizarMensaje}
                    mostrarModificarSisef={mostrarModificarSisef}
                    modalModificarSisef={modalModificarSisef}
                    data_syllabus={plan_actual}
                    id_syllabus={id_plan}
                    EditarSyllabus={this.EditarSyllabus}
                  />
                ) : ""}

              </>
            </div>
          </div>
        </>
      );
    }

  }


  pintarNewSolicitud() {
    let { tipoSolicitud } = this.props;
    let { periodo_actual } = this.state;
    return (
      <>
        <div style={{ padding: "1% 0% 0% 1%" }}>
          <BtnRegresar
            callback={() => this.setState({ seleccion: "inicio" })}
          />
        </div>
        <div>
          <SisefNewSolicitud
            tipoSolicitud={tipoSolicitud}
            periodo_actual={periodo_actual}
            Seleccion={(resp) => this.setState({ seleccion: resp })}
          />
        </div>
      </>
    );
  }

  pintarInicio() {
    return <Sisef />;
  }

  render() {
    let { seleccion, cargando, ModalEliminar, ModalDuplicar, modalCargarSyllabus, periodo_actual, titulo, archivo_syllabus } = this.state;
    let { modalModificarSisef, mostrarModificarSisef } = this.props
    return seleccion != "inicio" ? (
      <>
        <div>
          <Menu
            sele={seleccion}
            seleccionar={(seleccion) => this.cambiarOpcion(seleccion)}
          />
          <div className="contenido_modulo">
            {!cargando ? (
              this.vista()
            ) : (
              <TareasTerminadas
                mensaje={titulo}
                marginTop="10%"
                imagen={emma_w}
                widthImg="7%"
                cargando={true}
              />
            )}
          </div>
        </div>
        <ConfirmarAccion
          titulo="¿ Eliminar syllabus ?"
          dato={{ id: 0 }}
          mensaje1="¿Estás seguro(a) que deseas eliminar la syllabus?"
          modalConAccion={ModalEliminar}
          mostrarModalConfAccion={(resp) => this.setState({ ModalEliminar: resp })}
          ejecutarAccion={this.EliminarSyllabus}

        />
        <ConfirmarAccion
          titulo="¿ Duplicar syllabus ?"
          dato={{ id: 0 }}
          mensaje1="¿Estás seguro(a) que deseas Duplicar el syllabus?"
          modalConAccion={ModalDuplicar}
          mostrarModalConfAccion={(resp) => this.setState({ ModalDuplicar: resp })}
          ejecutarAccion={this.duplicarSyllabus}
        />
        {
          modalCargarSyllabus && archivo_syllabus && archivo_syllabus.length > 0 && (
            <CargaMasiva
              enviarArchivo={this.enviarArchivo}
              modalCargarMasivo={modalCargarSyllabus}
              titulo="Cargar Syllabus"
              cerrarModal={(resp) => this.setState({ modalCargarSyllabus: resp })}
              archivo={archivo_syllabus}
            />
          )
        }
      </>
    ) : (
      this.pintarInicio()
    );
  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { ModalAdd, modalModificarSisef } = state.redSisef;
  return { usuario, ModalAdd, modalModificarSisef };
};

const mapDispatchToProps = {
  mostrarModulo,
  mostrarModificarSisef,
  actualizarMensaje,
};

Sisef_Syllabus.propTypes = {
  //Funciones
  ModalAdd: PropTypes.bool.isRequired,
  modalModificarSisef: PropTypes.bool.isRequired,
  mostrarModificarSisef: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sisef_Syllabus);
