const MODAL_INICIAR_ASISTENCIA = 'MODAL_INICIAR_ASISTENCIA'

const mostrarModalIniciarAsitencia = show => {
  return {
    type: MODAL_INICIAR_ASISTENCIA,
    show,
  }
}

module.exports = {
  MODAL_INICIAR_ASISTENCIA,
  mostrarModalIniciarAsitencia
}
