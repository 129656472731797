import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";

export function InscripcionDetalleDatos({ inscripcion }) {
  let {
    estudiante,
    fecha_registro,
    estado_actual,
    discapacidad_valor,
    gestor,
    pais_nacimiento,
    gestor_nombre,
    gestor_correo,
  } = inscripcion;
  let fecha = moment(fecha_registro).format("YYYY-MM-DD, h:mm a");
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">
            Nombre Completo
          </TableCell>
          <TableCell align="left">{`${estudiante.primer_nombre} ${
            estudiante.segundo_nombre ?? ""
          } ${estudiante.primer_apellido} ${
            estudiante.segundo_apellido
          }`}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">
            Correo
          </TableCell>
          <TableCell align="left">{estudiante?.correo ?? "NINGUNO"}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">
            Tipo Identificación
          </TableCell>
          <TableCell align="left">
            {estudiante?.tipo_identificacion?.nombre ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">
            Identificación
          </TableCell>
          <TableCell align="left">
            {estudiante?.identificacion ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">
            Libreta militar
          </TableCell>
          <TableCell align="left">
            {!estudiante.libreta_militar
              ? "NINGUNO"
              : estudiante.libreta_militar}
          </TableCell>
        </TableRow>
        <TableRow key={6}>
          <TableCell component="th" scope="row">
            Telefono - Celular
          </TableCell>
          <TableCell align="left">{`${estudiante?.telefono ?? "NINGUNO"} - ${
            estudiante?.celular ?? "NINGUNO"
          }`}</TableCell>
        </TableRow>
        <TableRow key={7}>
          <TableCell component="th" scope="row">
            Discapacidad
          </TableCell>
          <TableCell align="left">
            {estudiante.discapacidad === "1"
              ? discapacidad_valor.nombre
              : "NINGUNO"}
          </TableCell>
        </TableRow>

        {gestor && (
          <>
            <TableRow key={8}>
              <TableCell component="th" scope="row">
                Gestor
              </TableCell>
              <TableCell align="left">{gestor_nombre}</TableCell>
            </TableRow>
            <TableRow key={9}>
              <TableCell component="th" scope="row">
                Correo Gestor
              </TableCell>
              <TableCell align="left">{gestor_correo}</TableCell>
            </TableRow>
          </>
        )}
        <TableRow key={10}>
          <TableCell component="th" scope="row">
            Genero
          </TableCell>
          <TableCell align="left">
            {estudiante?.genero?.nombre ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={11}>
          <TableCell component="th" scope="row">
            Fecha nacimiento
          </TableCell>
          <TableCell align="left">
            {estudiante?.fecha_nacimiento ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={12}>
          <TableCell component="th" scope="row">
            País de nacimiento
          </TableCell>
          <TableCell align="left">
            {pais_nacimiento?.nombre ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={13}>
          <TableCell component="th" scope="row">
            Ciudad de nacimiento
          </TableCell>
          <TableCell align="left">
            {estudiante?.lugar_nacimiento?.toUpperCase() ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={14}>
          <TableCell component="th" scope="row">
            Estado Actual
          </TableCell>
          <TableCell align="left">
            {estado_actual?.nombre.toUpperCase() ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={15}>
          <TableCell component="th" scope="row">
            Fecha Registro
          </TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function InscripcionDetalleContacto({ inscripcion }) {
  let { estado_civil_otro, estrato, estudiante, nombre_otro_eps, nombre_eps } =
    inscripcion;
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">
            Departamento
          </TableCell>
          <TableCell align="left">
            {estudiante?.departamento?.nombre ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">
            Ciudad/Municipio
          </TableCell>
          <TableCell align="left">
            {estudiante?.ciudad?.nombre ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">
            Barrio
          </TableCell>
          <TableCell align="left">
            {estudiante?.lugar_residencia?.toUpperCase() ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">
            Dirección
          </TableCell>
          <TableCell align="left">
            {estudiante?.direccion?.toUpperCase() ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">
            EPS
          </TableCell>
          <TableCell align="left">
            {nombre_otro_eps
              ? `${nombre_eps?.nombre} - ${nombre_otro_eps.toUpperCase()}`
              : nombre_eps?.nombre ?? "NINGUNO"}
          </TableCell>
        </TableRow>
        <TableRow key={6}>
          <TableCell component="th" scope="row">
            Estrato
          </TableCell>
          <TableCell align="left">{estrato.nombre ?? "NINGUNO"}</TableCell>
        </TableRow>
        <TableRow key={7}>
          <TableCell component="th" scope="row">
            Estado civil
          </TableCell>
          <TableCell align="left">
            {estado_civil_otro
              ? `${
                  estudiante?.estado_civil
                } - ${estado_civil_otro.toUpperCase()}`
              : estudiante?.estado_civil ?? "NINGUNO"}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function InscripcionDetalleCarrera({ inscripcion }) {
  let {
    estudio_homologacion,
    check_trabaja,
    carrera,
    conocio,
    nombre_empresa,
    actividad_empresa,
    direccion_empresa,
    telefono_empresa,
    cargo_empresa,
    tiempo_trabajo,
    rango_ingresos,
    observaciones,
  } = inscripcion;
  if (!carrera) {
    carrera = { nombre: "" };
  }

  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">
            Carrera
          </TableCell>
          <TableCell align="left">{carrera.nombre}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">
            Conoció la universidad
          </TableCell>
          <TableCell align="left">{conocio.nombre}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">
            Estudio de homologación
          </TableCell>
          <TableCell align="left">
            {estudio_homologacion === "1" ? "SI" : "NO"}
          </TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">
            Trabaja este período
          </TableCell>
          <TableCell align="left">
            {check_trabaja === "1" ? "SI" : "NO"}
          </TableCell>
        </TableRow>
        {check_trabaja === "1" && (
          <>
            <TableRow key={5}>
              <TableCell component="th" scope="row">
                Nombre de empresa
              </TableCell>
              <TableCell align="left">
                {nombre_empresa.toUpperCase() ?? "Nunguno"}
              </TableCell>
            </TableRow>
            <TableRow key={6}>
              <TableCell component="th" scope="row">
                Actividad de empresa
              </TableCell>
              <TableCell align="left">
                {actividad_empresa.toUpperCase() ?? "Nunguno"}
              </TableCell>
            </TableRow>
            <TableRow key={7}>
              <TableCell component="th" scope="row">
                Dirección de empresa
              </TableCell>
              <TableCell align="left">
                {direccion_empresa.toUpperCase() ?? "Nunguno"}
              </TableCell>
            </TableRow>
            <TableRow key={8}>
              <TableCell component="th" scope="row">
                Telefono de empresa
              </TableCell>
              <TableCell align="left">
                {telefono_empresa ?? "Nunguno"}
              </TableCell>
            </TableRow>
            <TableRow key={9}>
              <TableCell component="th" scope="row">
                Cargo en la empresa
              </TableCell>
              <TableCell align="left">
                {cargo_empresa.toUpperCase() ?? "Nunguno"}
              </TableCell>
            </TableRow>
            <TableRow key={10}>
              <TableCell component="th" scope="row">
                Jornada laboral
              </TableCell>
              <TableCell align="left">
                {tiempo_trabajo.nombre ?? "Nunguno"}
              </TableCell>
            </TableRow>
            <TableRow key={11}>
              <TableCell component="th" scope="row">
                Rango de ingresos
              </TableCell>
              <TableCell align="left">
                {rango_ingresos?.nombre ?? "Nunguno"}
              </TableCell>
            </TableRow>
          </>
        )}
        <TableRow key={12}>
          <TableCell component="th" scope="row">
            Observaciones
          </TableCell>
          <TableCell
            align="left"
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              maxWidth: "200px",
            }}
          >
            {observaciones ? observaciones : "NINGUNA"}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
