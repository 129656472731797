import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Hidden, Card, CardActionArea, CardMedia } from '@material-ui/core';
import { Link } from 'react-router-dom';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import TareasTerminadas from './TareasTerminadas';
import { coloresEmma } from '../../global/js/funciones';


const useStyles = makeStyles({
    root: {
        border: '1px solid ' + coloresEmma.secondarycolor,
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '20px',
        fontSize: '10px',
        textAlign: 'center',
        maxWidth: 250,
        background: '#FFF',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.7)',
        transition: 'background 0.3s ease',
        '&:hover': {
            background: coloresEmma.secondarycolor,
            '& $texto': {
                color: '#FFF'
            }
        },
    },
    img: {
        margin: '0 auto',
        width: '70px',
        padding: '5px',
        // background: 'blue'
    },
    texto: {
        textTransform: 'uppercase',
        color: '#000',
        fontSize: 13,
        fontWeight: 600,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 3,
        lineClamp: 3,
        wordBreak: 'break-word',
        hyphens: 'auto',
        paddingRight: '10px'
        // background: 'yellow'
    }
});

function MenuItem({ actividad: { titulo, imagen, nombre } }) {
    const { root, img, texto } = useStyles();

    return (
        <>
            <Card className={root}>
                <Link to={`/${nombre}`} className='sin_decorador'>
                    <CardActionArea style={{ height: "80px" }}>
                        <Grid container alignItems="center" style={{ height: "100%" }}>
                            <Grid item xs={4}>
                                <CardMedia
                                    component="img"
                                    image={imagen}
                                    className={img}

                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    variant="p"
                                    component="p"
                                    className={texto}
                                >
                                    {titulo}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Link>
            <div className='boxAmarilla'></div>
            </Card>
            </>
    );
}


export default function Menu({ actividades, submodulo }) {
    const pintar = xs => {
        return (
            <Grid rowSpacing={20} className='menu_emma_inicio' container justifyContent='center' alignItems='center' alignContent='center'>
                {actividades.length > 0 ?
                    <>
                        {
                            actividades.map((actividad, key) => (
                                <Grid item key={key} xs={12} sm={6} md={4} lg={4}>
                                    <MenuItem actividad={actividad} />
                                </Grid>)
                            )
                        }
                    </>
                    :
                    <TareasTerminadas imagen={emma_s1} mensaje='No se encontraron datos' widthImg='7%' marginTop='3%' />
                }
            </Grid>
        )
    }
    return (
        <>
            <Hidden xsDown> {pintar(4)} </Hidden>
            <Hidden smUp> {pintar(6)}</Hidden>
        </>
    );
}



