import React from 'react'
import { mostrarModulo } from '../../../../redux/actions/actGlobal';
import { useDispatch, useSelector } from 'react-redux';
import MenuCursosFormacion, { MenuGestionarCursos } from '../../MenuCursosFormacion';
import { makeStyles } from '@material-ui/core';
import AdminAdministrar from './AdminAdministrar';
import { AdminListarCursos } from './AdminListarCursos';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TareasTerminadas from '../../../general/TareasTerminadas';
import emma_s from '../../../../global/imagenes/emma_s1.png'
const useStyles = makeStyles((theme) => ({
    contenidoModulo: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "90px !important"
        },
    }
}));

export default function GestionarCurso() {
    const dispatchI = useDispatch();
    const usuario = useSelector(state => state.redGlobal.usuario);
    const [seleccion, setSeleccion] = React.useState('procesos');
    const [mensaje, setMensaje] = React.useState(null);
    const [detalle, setDetalle] = React.useState(true)
    const [procesos, setProcesos] = React.useState([])
    const [data, setData] = React.useState({});
    const [openModalInfo, setOpenModalInfo] = React.useState(false);
    const [instructivo, setInstructivo] = React.useState([]);
    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(false)
    const [solicitudActiva, setSolicitudActiva] = React.useState(false)
    const classes = useStyles()
    

    React.useEffect(() => {
        if(usuario.perfil.codigo=='Per_Semi_Ges'||usuario.perfil.codigo=='Per_Adm'){
        dispatchI(mostrarModulo({ ruta: '/gestion_curso', nombre: 'Gestionar Niveles de Formación' }))
        }else{
            setMensaje('permiso')
        }
    }, []);

    const vista = () => {
        switch (seleccion) {
            case 'procesos':
                return (
                    <AdminListarCursos usuario={usuario}/>
                )
            case 'admin':
                return (
                    <AdminAdministrar/>
                )
        }
    }

    return (
        <div>
             {mensaje ? (
            <div>
                <Link to="/semilleros">
                 <ArrowBackIcon style={{ fontSize: '35px', color: '#c79e32', marginTop: '5px' }} />
                </Link>
                <TareasTerminadas mensaje='No tienes permiso para este modulo' marginTop='7%' imagen={emma_s} widthImg="7%" /> 
            </div>
            ) : (
            <>
            <MenuGestionarCursos sele={seleccion} seleccionar={(seleccion) => setSeleccion(seleccion)} />
            <div className={classes.contenidoModulo} >
                {vista()}
            </div>
            </>
            )}
        </div>
    )
}


const PintarProcesos = (props) => {

    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
          isInitialMount.current = false
        //   const obtenerProcesos = async () => {
        //     await obtenerSolicitud();
        //     setCargando(false)
        //   }
        //   obtenerProcesos()
        }
      })
}