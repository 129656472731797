import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BtnEliminar } from "../../general/BotonesAccion";
import moment from 'moment';
import ListarDatos from '../../general/ListarDatos';

class PermisoListar extends Component {

  render() {
    let { genericas, mostrarModalDel, mostrarModalAdd ,actualizarDataGenerica} = this.props;

    const actions = (rowData) => {
      const onClickEliminar = (data) => {
        actualizarDataGenerica({ id: data.id, 'nombre':data.secundario.nombre})
        console.log(data);
        mostrarModalDel(true);
      }

      let elimina = <BtnEliminar callback={() => onClickEliminar(rowData)} />;
      return <div>{elimina}</div>;
    }

    return (
      <div className='seccion-modal'>
        <ListarDatos
          id="tbl_listar_genericas_personas"
          descar="EMMA - GENERICA - PERSONA"
          titulo='Lista Acceso'
          datos={genericas}
          opciones={true}
          acciones={row => actions(row)}
          sub_titulo={"Lista de genericas"}
          buscar={true}
          agregar={true}
          actAgregar={() => mostrarModalAdd(true)}
          fila_principal={({ secundario }) => secundario.nombre.toUpperCase()}
          filas={[
            {
              'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
              'id': 'fecha_registro',
              'enLista': true
            },
            { 'id': 'usuario_registra', 'enLista': false }
          ]}
        />
      </div>
    );
  }

}

//Props del componente
PermisoListar.propTypes = {
  //variables
  genericas: PropTypes.array.isRequired,
  tituloAdd: PropTypes.string.isRequired,
  //funciones
  actualizarDataGenerica: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
}

PermisoListar.defaultProps = {
  tituloAdd: 'Nuevo',
};


export default PermisoListar;
