import { coloresEmma, consulta, generarFiltros } from './../../global/js/funciones';
import doiRegex from "doi-regex"

// Recibe funcion 'solicitudesGrados'
export const getGradosAdm = async (filtros, callback) => {
	let f = await generarFiltros(filtros);
	consulta(`api/v1.0/grados/1?${f}`, null, null, (error, estado, resp) => callback(estado, resp));
}

// Recibe funcion 'setEstadosSolicitud'
export const getEstadosSolicitud = (grado_id, callback) =>
	consulta(`api/v1.0/grados/${grado_id}/estados`, null, null, (error, estado, resp) => callback(resp));

export const getUltimoEstadoSolicitud = (grado_id, callback) =>
	consulta(`api/v1.0/grados/${grado_id}/estado/ultimo`, null, null, (error, estado, resp) => callback(resp));

// Recibe funcion 'obtenerUltimoGrado'
export const getLineaEstados = (grado_id, callback) =>
	consulta(`api/v1.0/grados/${grado_id}/lineaEstados`, null, null, (error, estado, resp) => callback(resp));

export const obtenerAdjuntos = async (solicitud, callback) => {
	let f = [{ 'llave': 'estado_gr__grado__id', 'valor': solicitud }];
	let filtros = await generarFiltros(f);
	consulta(`api/v1.0/grados/grado/adjuntos?${filtros}`, null, null, (error, estado, resp) => callback(resp))
}

export const getEventosSolictud = (grado, callback) =>
	consulta(`api/v1.0/grados/${grado}/eventos`, null, null, (error, estado, resp) => callback(resp));

export const getEvaluadoresEstado = (estado, callback) =>
	consulta(`api/v1.0/grados/estado/${estado}/evaluadores`, null, null, (error, estado, resp) => callback(resp));

export const getGestoresSolicitud = (solicitud, callback) =>
	consulta(`api/v1.0/grados/${solicitud}/gestores`, null, null, (error, estado, resp) => callback(resp));

export const getProgramasPersona = (callback) =>
	consulta(`api/v1.0/programas`, null, null, (error, estado, resp) => callback(resp));

export const validarProcentajeCreditosAprobados = (callback) =>
	consulta(`api/v1.0/grados/creditos/validar`, null, "post", async (error, estado, resp) => await callback(resp));

// Utils
export const deleteSegundos = (cadena) => cadena.substring(0, cadena.length - 3)

export const validarDOI = doi => doiRegex({ exact: true }).test(doi)

export const validarISSN = issn => {
	let re = new RegExp('^([0-9a-zA-Z]{4})-([0-9a-zA-Z]{4})$');
	return re.test(issn)
}

// export const obtenerColorAvatarSolicitud = (codigo) => {
// 	let color = 'white';
// 	const colores = {
// 		'gr_est_ini': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
// 		'gr_est_rec': { 'backgroundColor': '#f44336', color },
// 		'gr_est_fin': { 'backgroundColor': '#009688', color },
// 		'tr_est_proc': { 'backgroundColor': '#03a9f4', color },
// 		'tr_est_acep': { 'backgroundColor': '#009688', color },
// 	}
// 	return (colores[codigo])
// }
export const obtenerColorAvatarSolicitud = (codigo, condecoracion) => {
	let color = '#FFF';
	if (codigo == 'gr_est_ini') return { 'backgroundColor': '#f5f5f5', 'color': '#757575' } //gris
	else if (['tr_est_coor', 'gr_est_pro'].includes(codigo)) return { 'backgroundColor': coloresEmma.solicitudenrevision, 'color': color } //amarillo
	else if ([ 'tr_est_des_tr'].includes(codigo)) return { 'backgroundColor': '#01579B', 'color': color } //azul
	else if (['tr_est_sus_tr', 'tr_est_des_inv', 'gr_est_sus', 'tr_est_sus_inv'].includes(codigo)) return { 'backgroundColor': coloresEmma.solicitudenrevision, color } //morado
	else if (['gr_est_rec', 'gr_est_can', 'no_aprob'].includes(codigo)) return { 'backgroundColor': coloresEmma.solicitudcancelada, color } // vinotinto
	else if (codigo == 'apr_obv') return { 'backgroundColor': coloresEmma.solicitudenrevision, color } // vinotinto
	else if (codigo == 'gr_est_fin') return { 'backgroundColor': coloresEmma.solicitudfinalizada, color }//verde
	else if (codigo == 'apl_tr') return { 'backgroundColor': coloresEmma.filtrotodos, color }//verde
	else if (codigo == 'Tod_Gra') return { 'backgroundColor':  coloresEmma.filtrotodos, color }//verde

	// if (codigo == 'gr_est_fin' && condecoracion == 1) return { 'backgroundColor': '#f44336', color } //rojo
	// if (codigo == 'gr_est_fin' && ['2', '3', '4'].includes(condecoracion)) return { 'backgroundColor': '#009688', color }//verde
}

export const obtenerColorAvatar = (codigo) => {
	let color = 'white';
	const colores = {
		'0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
		'1': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
		'2': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
		'3': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
		'4': { 'backgroundColor': coloresEmma.solicitudactiva, color },
		'5': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
		'6': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
		'7': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
		'8': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
		'9': { 'backgroundColor': '#757575', color },
	}
	return (colores[codigo])
}

export const obtenerTituloEstadoAdjunto = (codigo) => {
	const titulos = {
		'0': 'Enviado',
		'1': 'Negado',
		'2': 'Aprobado',
		'3': 'Remplazado',
		'4': 'En revisión',
		'5': 'En comite',
		'6': 'Rechazado por director',
		'7': 'Aprobado por comité',
		'8': 'Rechazado por comité',
		'9': 'Observación',
	}
	return (titulos[codigo])
}