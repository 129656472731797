import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow, Paper, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnEliminar, BtnForm,BtnEditar } from '../../general/BotonesAccion';
import { consulta, modificarValorGenerica, mostrarError, obtenerPermisosValorAlt } from '../../../global/js/funciones';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import {EmpresaDetalleMenu} from '../administrar/MenuPracticas';
import ListarDatos from '../../general/ListarDatos';
import { actualizarDataMensajes, actualizarMensaje} from "../../../redux/actions/actGlobal";
import ValoresAgregarPermisoPractica from './ValoresAgregarPermisoPractica';
import ValoresModificar from '../../genericas/valores/ValoresModificar';
import { connect } from 'react-redux'

class     PracticaDetalleEmpresa extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      seleccion: '',
      sucursales: [],
      modalDetalleEst: false,
      tituloEst: '',
      editarsucursal:[]
    }
  }



  async componentDidUpdate({open,modalAddValorPermiso,modalAddPermisosValores},{ seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { open: openNew } = this.props;
    let { modalAddValorPermiso: modalAddValorPermisoNew} = this.props;
    let { modalAddPermisosValores:modalAddPermisosValoresNew } = this.props;
    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'Sucursal') await this.ObtenerSucursales();
    }
    if (open !== openNew) this.setState({ seleccion: openNew ?  'Empresa' : '' })
    if ((modalAddValorPermiso !== modalAddValorPermisoNew) || (modalAddPermisosValores!==modalAddPermisosValoresNew)) this.ObtenerSucursales()
    // if (modalAddPermisoValores!==modalAddPermisoValoresNew) {this.ObtenerSucursales()}

  }


  pintar = () => {
    let { seleccion, cargando } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'Sucursal' && this.pintarSucursales()}
        {seleccion==='Empresa'&& this.pintarEmpresa()}

      </div>
    )

  }
  
 
   


  async ObtenerSucursales () {
    this.setState({ cargando: true })
    let { idEmpresa } = this.props
    
    let sucursales = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': idEmpresa }, { 'llave': 'secundario__generica', 'valor': 17 }])
    this.setState({ sucursales,cargando: false })
  } 

         eliminarRelacion = (sucursales) => {
          let { actualizarMensaje} = this.props;
         this.setState({cargando:true})
          consulta(`api/v1.0/permisos/${sucursales.id}/eliminar`, {}, 'put', (error, estado, resp) => {
          let titulo = 'Ha ocurrido un error, contacte con el administrador.',
            tipo = 'error';
          if (!error) {
            if (estado === 200) {
              tipo = 'success';
              titulo = resp.titulo;
              this.ObtenerSucursales()
            } else {
              tipo = 'info';
              titulo = mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        });
        this.setState({cargando:false})
        
      };
  
       modificarSucursal = async (id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
        let { actualizarMensaje, mostrarModalAddPermisoValores } = this.props;
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        let { error, estado, resp } = await modificarValorGenerica(id, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            // this.obtenerInstituciones();
            mostrarModalAddPermisoValores(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
  
  pintarSucursales = () => {
    let { sucursales } = this.state;
    let { modalAddPermisosValores ,mostrarModalAddPermisoValores,inputs_agregar} = this.props;

   
    const acciones = (data) => {

  
      const onClickEliminar = (data) => {
        console.log(data)
        this.eliminarRelacion(data)
      }
  
  
      const onClickEditar = (data) => {
        mostrarModalAddPermisoValores(true)
        this.setState({editarsucursal:data})

      }
      return (

      <div> 
        <BtnEditar callback={() => onClickEditar(data)}  />
        <BtnEliminar callback={() => onClickEliminar(data)}  />
      </div>
      )
    }

    
    // this.eliminarPermiso()
    // acciones = (sucursales) => {
    //   let {actualizarDataValor,mostrarModalDelValores}=this.props;
   
    //    // const onClickEliminar = (sucursales) => {
    //    //   actualizarDataValor(sucursales);
    //    //   mostrarModalDelValores(true);
    //    // }

   
    //    return <div>  <BtnEliminar callback={() => eliminarPermiso(sucursales)} /> </div>;
    //  }
    return (
 
    <ListarDatos
    id="tbl_empresa_sucursales"
    datos={sucursales}
    avatar={({ secundario:{nombre} }) => nombre.charAt(0).toUpperCase()}
    fila_principal={({ secundario:{nombre} }) => nombre.toUpperCase()}
    acciones={row => acciones(row)}
    titulo={`Lista de Sucursales`}
    agregar={true}
    opciones={true}
    actAgregar={() => this.props.mostrarModalAddValorPermiso(true)}
    filas={[
      { 'id': 'valora', 'enLista': true, 'mostrar': ({ secundario:{valora} }) => valora ? valora.toUpperCase():'No hay datos','nombre': 'Barrio: ' },
      { 'id': 'valorb', 'enLista': true, 'mostrar': ({ secundario:{valorb} }) => valorb ? valorb.toUpperCase():'No hay datos','nombre': 'Ciudad: ' },
      { 'id': 'valorc', 'enLista': true, 'mostrar': ({ secundario:{valorc} }) => valorc ? valorc.toUpperCase():'No hay datos','nombre': 'Departamento: ' },
      { 'id': 'valord', 'enLista': true, 'mostrar': ({ secundario:{valord} }) => valord ? valord.toUpperCase():'No hay datos','nombre': 'Telefono: ' },
      { 'id': 'valore', 'enLista': true, 'mostrar': ({ secundario:{valore} }) => valore ? valore.toUpperCase():'No hay datos','nombre': 'Fax: ' },
      { 'id': 'valorf', 'enLista': true, 'mostrar': ({ secundario:{valorf} }) => valorf ? valorf.toUpperCase():'No hay datos','nombre': 'Correo: ' },
      { 'id': 'valorg', 'enLista': true, 'mostrar': ({ secundario:{valorg} }) => valorg ? valorg.toUpperCase():'No hay datos','nombre': 'Programa - Area de la empresa: ' },
      // { 'id': 'fecha_registro', 'enLista': true, 'mostrar': },
      // { 'id': 'mensaje', 'enLista': true, 'mostrar': },
    ]}
    
  />
  
    )
    
  }
    
  pintarEmpresa=()=>{
    let {config}=this.props
    return(
      <Paper square elevation={0}>
      <Table >
        <TableBody>
          {config.map(campo => {
            return (
              <TableRow key={campo.label}>
                <TableCell component="th" scope="row">{campo.label}</TableCell>
                <TableCell align="left" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{campo.value}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
    )
  }
  
  
  
  render() {
    let { seleccion, sucursales, tituloEst } = this.state;
    // let {editarsucursal}=this.state
    
  
    let {secundario}=this.state.editarsucursal
    let { titulo, open, config, cerrar,modalAddPermisosValores,mostrarModalAddPermisoValores,valor,filtro,administra,modalAddValorPermiso,mostrarModalAddValorPermiso,inputs_agregar,agregarValor,guardar_agregar,actualizarDataPermisos} = this.props
  return (
    <>

    <Dialog open={open} onClose={cerrar} fullWidth={true} maxWidth="sm" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <AppBarModal titulo={titulo} mostrarModal={cerrar} titulo_accion="" accion={() => { }} />
      <EmpresaDetalleMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
      
      <DialogContent style={{ padding: 0 }} className='scroll'>
        {this.pintar()}
 
      </DialogContent>
      <DialogActions>
        <BtnForm texto="CERRAR" callback={cerrar} />
      </DialogActions>
    </Dialog>
    
    <ValoresAgregarPermisoPractica
    // mostrarModalAddPermisoValores={mostrarModalAddPermisoValores}
    // modalAddPermisosValores={modalAddPermisosValores}
    actualizarDataPermisos={actualizarDataPermisos}
    valor={valor}
    filtro={filtro}
    titulo='¡ Asignar Sucursales !'
    administra={administra}
    modalAddValorPermiso={modalAddValorPermiso}
    mostrarModalAddValorPermiso={mostrarModalAddValorPermiso}
    inputs_agregar={inputs_agregar}
    titulo_agregar='¡ Nueva Sucursal !'
    agregarValor={agregarValor}
    guardar_agregar={guardar_agregar}
    sucursalesSelec={sucursales}
  />
   <ValoresModificar
          mostrarABC = { true }
          modalModValores = { modalAddPermisosValores }
          mostrarModalModValores = { mostrarModalAddPermisoValores }
          valor = { secundario?secundario:'' }
          modificarValor = { this.modificarSucursal }
          inputs = { inputs_agregar }
          titulo = '¡ Modificar Sucursal !'
        />
  </>
  )
}}
const mapStateToProps = (state) => {

  let { usuario } = state.redGlobal;
  return {
    usuario,
  }
}
const mapDispatchToProps = {

  actualizarMensaje,
}
PracticaDetalleEmpresa.propTypes = {
  config: PropTypes.array.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
}
// export default PracticaDetalleEmpresa;
export default connect(mapStateToProps,mapDispatchToProps)(PracticaDetalleEmpresa);
