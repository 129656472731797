import {
  ACT_DATA_CONVOCATORIA,
  ACT_DATA_CONVOCATORIAS,
  ACT_DATA_INSTITUCION,
  ACT_DATA_INSTITUCIONES,
  ACT_DATA_REQUISITO,
  ACT_DATA_REQUISITOS,
  ACT_DATA_SOLICITUD,
  MODAL_ADD_CONVENIO,
  MODAL_ADD_CONVOCATORIAS,
  MODAL_ADD_FILTROS,
  MODAL_ADD_INSTITUCIONES,
  MODAL_ADD_MOV_ESTUDIANTIL,
  MODAL_ADD_PER_DEP,
  MODAL_ADD_REGISTRO,
  MODAL_ADD_REQUISITOS,
  MODAL_ADJUNTAR_DOCUMENTOS,
  MODAL_AGREGAR_FINANCIACIONES,
  MODAL_APROBAR_ADJUNTO,
  MODAL_CANCELAR_SOLICITUD,
  MODAL_DEL_CONVOCATORIAS,
  MODAL_DEL_INSTITUCIONES,
  MODAL_DEL_REQUISITOS,
  MODAL_DET_ADJUNTOS,
  MODAL_DET_CONVOCATORIAS,
  MODAL_DET_CONV_INST,
  MODAL_DET_INSTITUCIONES,
  MODAL_DET_REQUISITOS,
  MODAL_DET_SOLICITUD,
  MODAL_EDI_CONVENIO,
  MODAL_GESTIONAR_SOLICITUD,
  MODAL_LISTA_ARCHIVOS,
  MODAL_LISTA_REQUISITOS,
  MODAL_LISTA_UNIVERSIDADES,
  MODAL_MOD_ADJUNTOS,
  MODAL_MOD_CONVOCATORIAS,
  MODAL_MOD_INSTITUCIONES,
  MODAL_MOD_REQUISITOS,
  MODAL_NEGAR_ADJUNTO,
  MODAL_SOL_INSTI,
  MODAL_SOL_RED,
  MODAL_EDI_IES
} from '../actions/actInternacionalizacion';

const initialState = {
  modalEdiConvenio: false,
  modalDetSolicitud: false,
  modalGestionarSolicitud: false,
  modalCancelarSolicitud: false,
  modalAddMovEstudiantil: false,
  modalAddConvenio: false,
  modalAddRegistro: false,
  modalAddConvocatorias: false,
  modalModConvocatorias: false,
  modalDelConvocatorias: false,
  modalDetConvocatorias: false,
  modalAddInstituciones: false,
  modalModInstituciones: false,
  modalDelInstituciones: false,
  modalDetInstituciones: false,
  modalAddRequisitos: false,
  modalModRequisitos: false,
  modalDelRequisitos: false,
  modalDetRequisitos: false,
  modalListaUniversidades: false,
  modalListaRequisitos: false,
  modalListaArchivos: false,
  modalAdjuntarDocumentos: false,
  modalAddFiltros: false,
  modalDetAdjuntos: false,
  modalModAdjuntos: false,
  modalAprobarAdjunto: false,
  modalNegarAdjunto: false,
  modalAddFinanciaciones: false,
  modalAddPermisosDep: false,
  modalDetConvInst: false,
  modalSolInsti: false,
  modalSolRed: false,
  modalEdiIes: false,
  solicitud: {
    id: 0,
  },
  convocatorias: [],
  convocatoria: {
    id: 0,
  },
  instituciones: [],
  institucion: {
    id: 0,
  },
  requisitos: [],
  requisito: {
    id: 0,
  },
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case MODAL_DET_SOLICITUD:
      return Object.assign({}, state, {
        modalDetSolicitud: action.show
      });
    case MODAL_GESTIONAR_SOLICITUD:
      return Object.assign({}, state, {
        modalGestionarSolicitud: action.show
      });
    case MODAL_CANCELAR_SOLICITUD:
      return Object.assign({}, state, {
        modalCancelarSolicitud: action.show
      });
    case MODAL_ADD_MOV_ESTUDIANTIL:
      return Object.assign({}, state, {
        modalAddMovEstudiantil: action.show
      });
    case MODAL_ADD_CONVENIO:
      return Object.assign({}, state, {
        modalAddConvenio: action.show
      });
    case MODAL_ADD_REGISTRO:
      return Object.assign({}, state, {
        modalAddRegistro: action.show
      });
    case MODAL_ADD_CONVOCATORIAS:
      return Object.assign({}, state, {
        modalAddConvocatorias: action.show
      });
    case MODAL_MOD_CONVOCATORIAS:
      return Object.assign({}, state, {
        modalModConvocatorias: action.show
      });
    case MODAL_DEL_CONVOCATORIAS:
      return Object.assign({}, state, {
        modalDelConvocatorias: action.show
      });
    case MODAL_DET_CONVOCATORIAS:
      return Object.assign({}, state, {
        modalDetConvocatorias: action.show
      });
    case MODAL_ADD_INSTITUCIONES:
      return Object.assign({}, state, {
        modalAddInstituciones: action.show
      });
    case MODAL_MOD_INSTITUCIONES:
      return Object.assign({}, state, {
        modalModInstituciones: action.show
      });
    case MODAL_DEL_INSTITUCIONES:
      return Object.assign({}, state, {
        modalDelInstituciones: action.show
      });
    case MODAL_DET_INSTITUCIONES:
      return Object.assign({}, state, {
        modalDetInstituciones: action.show
      });
    case MODAL_ADD_REQUISITOS:
      return Object.assign({}, state, {
        modalAddRequisitos: action.show
      });
    case MODAL_MOD_REQUISITOS:
      return Object.assign({}, state, {
        modalModRequisitos: action.show
      });
    case MODAL_DEL_REQUISITOS:
      return Object.assign({}, state, {
        modalDelRequisitos: action.show
      });
    case MODAL_DET_REQUISITOS:
      return Object.assign({}, state, {
        modalDetRequisitos: action.show
      });
    case MODAL_LISTA_UNIVERSIDADES:
      return Object.assign({}, state, {
        modalListaUniversidades: action.show
      });
    case MODAL_LISTA_REQUISITOS:
      return Object.assign({}, state, {
        modalListaRequisitos: action.show
      });
    case MODAL_LISTA_ARCHIVOS:
      return Object.assign({}, state, {
        modalListaArchivos: action.show
      });
    case MODAL_ADJUNTAR_DOCUMENTOS:
      return Object.assign({}, state, {
        modalAdjuntarDocumentos: action.show
      });
    case MODAL_ADD_FILTROS:
      return Object.assign({}, state, {
        modalAddFiltros: action.show
      });
    case MODAL_DET_ADJUNTOS:
      return Object.assign({}, state, {
        modalDetAdjuntos: action.show
      });
    case MODAL_MOD_ADJUNTOS:
      return Object.assign({}, state, {
        modalModAdjuntos: action.show
      });
    case MODAL_APROBAR_ADJUNTO:
      return Object.assign({}, state, {
        modalAprobarAdjunto: action.show
      });
    case MODAL_NEGAR_ADJUNTO:
      return Object.assign({}, state, {
        modalNegarAdjunto: action.show
      });
    case ACT_DATA_SOLICITUD:
      return Object.assign({}, state, {
        solicitud: action.solicitud
      });
    case ACT_DATA_CONVOCATORIA:
      return Object.assign({}, state, {
        convocatoria: action.convocatoria
      });
    case ACT_DATA_CONVOCATORIAS:
      return Object.assign({}, state, {
        convocatorias: action.convocatorias
      });
    case ACT_DATA_INSTITUCION:
      return Object.assign({}, state, {
        institucion: action.institucion
      });
    case ACT_DATA_INSTITUCIONES:
      return Object.assign({}, state, {
        instituciones: action.instituciones
      });
    case ACT_DATA_REQUISITO:
      return Object.assign({}, state, {
        requisito: action.requisito
      });
    case ACT_DATA_REQUISITOS:
      return Object.assign({}, state, {
        requisitos: action.requisitos
      });
      case MODAL_AGREGAR_FINANCIACIONES:
      return Object.assign({}, state, {
        modalAddFinanciaciones: action.show
      });
    case MODAL_EDI_CONVENIO:
      return Object.assign({}, state, {
        modalEdiConvenio: action.show
      });
    case MODAL_ADD_PER_DEP:
      return Object.assign({}, state, {
        modalAddPermisosDep: action.show
      });
    case MODAL_DET_CONV_INST:
      return Object.assign({}, state, {
        modalDetConvInst: action.show
      });
    case MODAL_SOL_INSTI:
      return Object.assign({}, state, {
        modalSolInsti: action.show
      });
    case MODAL_SOL_RED:
      return Object.assign({}, state, {
        modalSolRed: action.show
      });
    case MODAL_EDI_IES:
      return Object.assign({}, state, {
        modalEdiIes: action.show
      });
    default:
      return state;
  }
}

export default reducer;
