import React, { Component } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import { consulta, generarFiltros, coloresEmma } from '../../../global/js/funciones'
import  { AtencionMenu } from './MenuAtencion';
import { DetalleSolicitud } from "./AtencionDetalle"
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment'


class AtencionDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estados: [],
      datos: [],
      documentos: [],
      cargando: false,
      seleccion: '',
      nota : '',
      id_atencion: ''
    };
  }
    
    
  async componentDidUpdate({ modalDetValidaciones }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { validacionActual, modalDetValidaciones: modalDetValidacionesNew, vistaDetalle } = this.props;

    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'detalle') await this.obtenerInfoValidaciones(validacionActual);
      // if (seleccionNew === 'detallenoti') await this.obtenerInfoValidaciones(validacionActual);
      else if (seleccionNew === 'estados') await this.obtenerEstadosValidaciones(validacionActual);
    }

    if (modalDetValidaciones !== modalDetValidacionesNew) this.setState({ seleccion: modalDetValidacionesNew ? vistaDetalle : '' })
  }

  async obtenerInfoValidaciones(id) {
    this.setState({ cargando: true });
    consulta(`api/v1.0/atencion/solicitudes/${id}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
    })
  }

  async obtenerEstadosValidaciones(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'atencion', 'valor': id }]);
    consulta(`api/v1.0/atencion/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  pintarEstados = () => {

    let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'STATE_CREATE_ATENCION': { 'backgroundColor': coloresEmma.filtrotodos, color  },
        'STATE_ASIGNADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'STATE_GESTION_ATENCION': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'STATE_FINALIZADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      }
      return (colores[codigo])
    }
    

    return (
      <ListarDatos
        id="tbl_abjuntos_Validacion"
        datos={estados}
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
        filas={[
          { 'id': 'usurio_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'mensaje', 'enLista': true, 'mostrar': ({ mensaje }) => mensaje },
        ]}
      />
    )
  };


  pintarAtencion = () => {
    let { datos } = this.state;

    if (Object.entries(datos).length > 0) {
      return <DetalleSolicitud atencion={datos.atencion}/>;
    }
  }
    
  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'detalle' && this.pintarAtencion()}
        {seleccion === 'estados' && this.pintarEstados()}
      </div>
    )

  }
    
  render() {
    let { seleccion } = this.state;
    let { modalDetValidaciones, mostrarModalDetalleAtencion} = this.props

    return (
    <>
      <Dialog open={modalDetValidaciones} maxWidth="sm" fullWidth={true} onClose={ () => mostrarModalDetalleAtencion(false)}>
        <AppBarModal titulo='¡ Detalle Solicitud !' mostrarModal={mostrarModalDetalleAtencion} titulo_accion='' />
        <AtencionMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          {/* {datos[0] && datos[0].volante ? <Link href={`${datos[0].volante}`} target="_blank"><BtnForm texto="DESCARGAR VOLANTE" callback={() => { }} color="#01579b" /></Link> : ''} */}
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleAtencion(false)} />
        </DialogActions>
      </Dialog>
    </>
    );
  }
}

export default AtencionDetalle