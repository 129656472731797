import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Fab, FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem, Select, Step, StepLabel, Stepper, TextField, withStyles, IconButton } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import { obtenerValoresFiltros, validateInputs, getError } from '../../../global/js/funciones';
import moment from 'moment';
import TareasTerminadas from '../../general/TareasTerminadas';
import ConvocatoriasBuscarUniversidad from './ConvocatoriasBuscarUniversidad';
import emma_w from '../../../global/imagenes/emma_w.png';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import CustomDropzone from '../../general/CustomDropzone';
import { MODALIDADES, REQUISITOS, SEMESTRES, TIPOS_ACTIVIDADES, UNIVERSIDADES } from '../administrar/helper';

const useStyles = makeStyles(theme => ({
  fabButton: {
    position: 'fixed',
    left: "45%",
    zIndex: 1000,
    top: '83%',
    [theme.breakpoints.up('sm')]: {
      top: '89%',
      left: "50%",
    },
  }
}));

const useStyles2 = () => ({
  flexCenter: {
    textAlign: "center",
    marginTop: "5px"
  },
  colorEmmma: {
    color: "#c79e32",
    transition: 'all 0.3s',
    '&:hover': {
      color: "#fff",
      backgroundColor: "#c79e32",
      transform: "scale(1.3)",
    },
  },
  colorDelete: {
    color: "#d11a2a",
    transition: "all 0.3s",
    '&:hover': {
      color: "#fff",
      backgroundColor: "#d11a2a",
      transform: "scale(1.3)",
    },
  },
});

const configStep = ['Datos Iniciales', 'Requisitos', 'Archivos'];

function Agregar({ actAgregar }) {
  const classes = useStyles();
  return (
    <Fab color = "secondary" aria-label = "add" className = { classes.fabButton } onClick = { () => actAgregar() }>
      <AddIcon />
    </Fab>
  )
}

class ConvocatoriasAgregar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descripcion: '',
      modalidad: '',
      nombre_convocatoria: '',
      semestre_minimo: '',
      tipo_mov_estudiantil: '',
      tit_cargando: 'Cargando...',
      universidad_seleccionada: '',
      activeStep: 0,
      cantidad_universidades: 0,
      promedio_requerido: 0.0,
      cargando: true,
      fecha_final: null,
      fecha_inicio: null,
      modal_buscar_universidad: false,
      modal_quitar_universidad: false,
      onChange: this.onChange,
      onChangeDate: this.onChangeDate,
      archivos: [],
      errores: [],
			idiomas_destino: [],
      modalidades: [],
      requisitos: [],
      requisitos_opciones: [],
      semestres: [],
      tipos_mov_estudiantiles: [],
      universidades: [],
      universidades_destino: [],
    }
  }

  async componentDidUpdate({ modalAddConvocatorias }, { universidades_destino }) {
    let { modalAddConvocatorias: modalAddConvocatoriasNew } = this.props;
    let { universidades_destino: universidades_destino_new, errores } = this.state;
    if (modalAddConvocatorias !== modalAddConvocatoriasNew) {
      if (modalAddConvocatoriasNew) {
        this.setState({ cargando: true })
        let semestres = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': SEMESTRES }])
        let modalidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': MODALIDADES }])
        let universidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': UNIVERSIDADES }, { 'llave': 'estado', 'valor': 1}])
        let tipos_mov_estudiantiles = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_ACTIVIDADES }, { 'llave': 'valorb', 'valor': 'convocatoria' }])
        let requisitos_opciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': REQUISITOS }])
        this.actualizarEstados({ semestres, modalidades, universidades, tipos_mov_estudiantiles, requisitos_opciones });
      }
    }
    if (universidades_destino !== universidades_destino_new) {
      let idiomas = [];
      idiomas = universidades_destino_new.length > 0 ? universidades_destino_new.map((univ) => univ.idioma) : [];
      errores.length > 0 && this.setState({ errores: errores.filter(er => er.llave !== 'universidades_destino') }); // Elimina error cuando se seleccione universidad
      this.setState({ idiomas_destino: [...new Set(idiomas)] }); // Llena lista de idiomas según las universidades seleccionadas
    }
  }

  handleStep = async direction => {
    let { mostrarModalAddConvocatorias } = this.props
    let {
      activeStep,
      cantidad_universidades,
      errores,
      fecha_final,
      fecha_inicio,
      modalidad,
      nombre_convocatoria,
      promedio_requerido,
      requisitos,
      semestre_minimo,
			tipo_mov_estudiantil,
      universidades_destino,
    } = this.state;
    switch (activeStep) {
      case 0:
        errores = await validateInputs([
          { value: nombre_convocatoria, name: 'nombre_convocatoria', err: { empty: true } },
          { value: fecha_inicio, name: 'fecha_inicio', err: { not_null: true, after_today: true } },
          { value: fecha_final, name: 'fecha_final', err: { not_null: true, finish_date: true }, value2: fecha_inicio },
          { value: tipo_mov_estudiantil, name: 'tipo_mov_estudiantil', err: { empty: true } },
          { value: modalidad, name: 'modalidad', err: { empty: true } },
        ])
        break;
      case 1:
        errores = await validateInputs([
          { value: semestre_minimo, name: 'semestre_minimo', err: { empty: true } },
          { value: promedio_requerido, name: 'promedio_requerido', err: { empty: true, numeric: true, range: true }, value2: 4.6 },
          { value: universidades_destino, name: 'universidades_destino', err: { empty: true } },
          { value: cantidad_universidades, name: 'cantidad_universidades', err: { empty: true, numeric: true, not_decimal: true, range: true }, value2: universidades_destino.length },
          { value: requisitos, name: 'requisitos', err: { empty: true } },
        ])
        break;
      default:
        break;
    }
    if(direction) {
      this.setState({ errores });
      if (errores.length === 0) {
        activeStep === 2 ? this.enviarDatosConvocatoria() : this.setState({ activeStep: activeStep + 1 });
      } else {
        return false;
      }
    } else {
      activeStep === 0 ? mostrarModalAddConvocatorias(false) : this.setState({ activeStep: activeStep - 1 });
    }
  }
  
  actualizarEstados(datos) {
    let { modalidades, requisitos_opciones, semestres, tipos_mov_estudiantiles, universidades } = datos;
    this.setState({ semestres, modalidades, universidades, tipos_mov_estudiantiles, requisitos_opciones, cargando: false })
  }

  agregarArchivos = (arc) => {
		let { archivos } = this.state;
		archivos = archivos.concat(arc);
		this.setState({ archivos });
	}
  
  configStepContent = () => {
    let { activeStep } = this.state;
    return (
      <>
        { activeStep === 0 && this.renderDatosIniciales() }
        { activeStep === 1 && this.renderRequisitos() }
        { activeStep === 2 && this.renderArchivos() }
      </>
    );
  }

  eliminarArchivo = (i) => {
		let { archivos } = this.state;
		let { actualizarMensaje } = this.props;
		archivos.splice(i, 1);
		this.setState({ archivos });
		actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
	}
  
  enviarDatosConvocatoria() {
    let { actualizarMensaje } = this.props;
    let { 
      archivos,
      cantidad_universidades,
      descripcion,
      fecha_final,
      fecha_inicio,
      modalidad,
      nombre_convocatoria,
      promedio_requerido,
      requisitos,
      semestre_minimo,
      tipo_mov_estudiantil,
      universidades_destino,
    } = this.state;
    if(archivos.length) {
      let data = {
        nombre_convocatoria,
        fecha_inicio: fecha_inicio ? moment(fecha_inicio).format('YYYY-MM-DD') : '',
        fecha_final: fecha_final ? moment(fecha_final).format('YYYY-MM-DD') : '',
        semestre_minimo,
        promedio_requerido,
        modalidad,
        cantidad_universidades,
        descripcion,
        tipo_mov_estudiantil
      }
      this.setState({ cargando: true, tit_cargando: 'Enviando..' });
      this.props.guardarConvocatoria(data, universidades_destino, requisitos, archivos, (resp) => this.reiniciarEstados(resp)); 
    } else actualizarMensaje({ titulo: 'Agregar un archivo.', tipo: 'info', mostrar: true, tiempo: 6000 });
  }
  
  onChange = ({ target }) => {
    let { value, name } = target;  
    let { errores } = this.state;
    errores.length > 0 && this.setState({ errores: errores.filter(er => er.llave !== name) }); // Elimina error cuando se escribe en el campo
    this.setState({
      [name]: value
    });
  }
  
  onChangeDate = (name, date) => {
    let { errores } = this.state;
    errores.length > 0 && this.setState({ errores: errores.filter(er => er.llave !== name) }); // Elimina error cuando se seleccione fecha
    this.setState({ [name]: date });
  }

  // Recibe id de universidad seleccionada y la quita de las universidades destino y reinicia universidad seleccionada.
  quitarUniversidadSeleccionada = (id) => {
    let { universidades_destino } = this.state;
    if(id) this.setState({ 
      universidades_destino: universidades_destino.filter(univ => univ.id !== id), 
      universidad_seleccionada: '',
      modal_quitar_universidad: false,
    });
  }

  reiniciarEstados(respuesta) {
    if(respuesta) {
      this.setState({
        descripcion: '',
        idiomas_destino: '',
        tipo_mov_estudiantil: '',
        modalidad: '',
        nombre_convocatoria: '',
        semestre_minimo: '',
        universidad_seleccionada: '',
        activeStep: 0,
        cantidad_universidades: 0,
        promedio_requerido: 0.0,
        cargando: true,
        fecha_final: null,
        fecha_inicio: null,
        archivos: [],
        requisitos: [],
        universidades_destino: []
      });
    } else this.setState({ activeStep: 0, cargando: false });
  }

  renderDatosIniciales = () => {
    let {
      descripcion,
      errores,
      fecha_final,
      fecha_inicio,
      modalidad,
      modalidades,
      nombre_convocatoria, 
      onChange, 
      onChangeDate,
      tipos_mov_estudiantiles,
      tipo_mov_estudiantil
    } = this.state;
    return (
      <Grid container spacing = { 3 } style = { { marginBottom: '40px' } }>
        <Grid item xs={ 12 }>
          <TextField
            value={ nombre_convocatoria }
            required
            error = { getError('nombre_convocatoria', errores).length > 0 }
            helperText = { getError('nombre_convocatoria', errores) }
            autoFocus
            id='nombre_convocatoria'
            name='nombre_convocatoria'
            label='Nombre de Convocatoria'
            fullWidth
            onChange = { onChange }
            variant = 'outlined'
          />
        </Grid>
        <Grid item xs = { 6 }>
          <MuiPickersUtilsProvider utils = { DateFnsUtils }>
            <KeyboardDatePicker
              id = 'fecha_inicio'
              required
              error = { getError('fecha_inicio', errores).length > 0 }
              helperText = { getError('fecha_inicio', errores) }
              label = 'Fecha de inicio'
              name = 'fecha_inicio'
              format = 'dd/MM/yyyy'
              value = { fecha_inicio }
              onChange = { (date) => onChangeDate('fecha_inicio', date) }
              fullWidth
              inputVariant="outlined"
              KeyboardButtonProps = { { 
                'aria-label': 'cambiar fecha'
                } }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs = { 6 }>
          <MuiPickersUtilsProvider utils = { DateFnsUtils }>
            <KeyboardDatePicker
              id = 'fecha_final'
              required
              error = { getError('fecha_final', errores).length > 0 }
              helperText = { getError('fecha_final', errores) }
              label = 'Fecha de finalización'
              name = 'fecha_finalización'
              format = 'dd/MM/yyyy'
              value = { fecha_final }
              onChange = { (date) => onChangeDate('fecha_final', date) }
              fullWidth
              inputVariant="outlined"
              KeyboardButtonProps = { { 
                'aria-label': 'cambiar fecha'
                } }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs = { 6 }>
          <FormControl className = 'form-control' required variant = 'outlined' error = { getError('tipo_mov_estudiantil', errores).length > 0 }>
            <InputLabel id = 'tipo_mov_estudiantil_label'>Tipo de movilidad estudiantil</InputLabel>
            <Select
              labelId = 'tipo_mov_estudiantil_label'
              id = 'tipo_mov_estudiantil'
              name = 'tipo_mov_estudiantil'
              value = { tipo_mov_estudiantil }
              label = 'Tipo de movilidad estudiantil'
              fullWidth
              onChange = { onChange }
            >
              { tipos_mov_estudiantiles.map(tipo => <MenuItem key = { tipo.id } value = { tipo.id }>{ tipo.nombre }</MenuItem>) }
            </Select>
            <FormHelperText>{ getError('tipo_mov_estudiantil', errores) }</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs = { 6 }>
          <FormControl className = 'form-control' required variant = 'outlined' error = { getError('modalidad', errores).length > 0 }>
            <InputLabel id = 'modalidad_label'>Modalidad</InputLabel>
            <Select
              labelId = 'modalidad_label'
              id = 'modalidad'
              name = 'modalidad'
              value = { modalidad }
              label = 'Modalidad'
              fullWidth
              onChange = { onChange }
            >
              { modalidades.map((modalidad) => (
                <MenuItem key = { modalidad.id } value = { modalidad.id }>{ modalidad.nombre }</MenuItem>
              )) }
            </Select>
            <FormHelperText>{ getError('modalidad', errores) }</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={ 12 }>
          <TextField
            value = { descripcion }
            multiline
            rows = { 4 }
            id = 'descripcion'
            name = 'descripcion'
            label = 'Descripción de la convocatoria'
            fullWidth
            variant = 'outlined'
            onChange={ onChange }
          />
        </Grid>
      </Grid>
    )
  }

  renderRequisitos = () => {
    let {
      cantidad_universidades,
      errores,
      idiomas_destino,
      modal_buscar_universidad,
      modal_quitar_universidad,
      onChange,
      promedio_requerido,
      requisitos,
      requisitos_opciones,
      semestre_minimo,
      semestres,
      universidades_destino,
      universidad_seleccionada
    } = this.state;
    let seleccionada = universidad_seleccionada ? universidades_destino.find(univ => univ.id === universidad_seleccionada) : {id:0};
    const onClickEliminar = (data) => {
      if (data) this.setState({ modal_quitar_universidad: true });
    }
    const { classes } = this.props;
    return (
      <Grid container spacing = { 3 } style = { { marginBottom: '40px' } }>
        <Grid item xs = { 6 }>
          <FormControl className='form-control' required variant = 'outlined' error = { getError('semestre_minimo', errores).length > 0 }>
            <InputLabel id = 'semestre_minimo_label'>Semestre mínimo</InputLabel>
            <Select
              labelId = 'semestre_minimo_label'
              id= 'semestre_minimo'
              name= 'semestre_minimo'
              value = { semestre_minimo }
              label = 'Semestre mínimo'
              fullWidth
              onChange = { onChange }
            >
              { semestres
                  .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                  .map((semestre) => (
                    <MenuItem key= { semestre.id } value = { semestre.id }>{ semestre.nombre }</MenuItem>
              )) }
            </Select>
            <FormHelperText>{ getError('semestre_minimo', errores) }</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs = { 6 }>
          <TextField
            value = { promedio_requerido }
            required
            error = { getError('promedio_requerido', errores).length > 0 }
            helperText = { getError('promedio_requerido', errores) }
            type = 'number'
            id = 'promedio_requerido'
            name = 'promedio_requerido'
            label = 'Promedio académico requerido'
            fullWidth
            onChange = { onChange }
            variant = 'outlined'
          />
        </Grid>
        <Grid item xs = { 9 }>
          <FormControl className='form-control' required variant = 'outlined' error = { getError('universidades_destino', errores).length > 0  }>
            <Select
              id = 'universidad_seleccionada'
              name = 'universidad_seleccionada'
              value = { universidad_seleccionada }
              fullWidth
              displayEmpty
              onChange = { onChange }
            >
              <MenuItem value = "">
                <em>{ `${universidades_destino.length} universidad(es) seleccionada(s)` }</em>
              </MenuItem>
              { 
                universidades_destino.map((univ) => (
                  <MenuItem key= { univ.id } value = { univ.id }>{ univ.nombre }</MenuItem>
                ))
              }
            </Select>
            <FormHelperText>{ getError('universidades_destino', errores) }</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs = { 3 }>
          <ConvocatoriasBuscarUniversidad 
            seleccionar = { ({ id, nombre, valord }) => { this.setState({ universidades_destino: universidades_destino.concat({ id: id, nombre: nombre, idioma: valord }) }) } }
            quitar = { ({ id }) => { this.setState({ universidades_destino: universidades_destino.filter(univ => univ.id !== id) })} }
            seleccionadas = { universidades_destino }
            modalBuscarUniversidad = { modal_buscar_universidad }
            mostrarModalBuscarUniversidad = { (modal_buscar_universidad) => this.setState({ modal_buscar_universidad }) }
          />
          <ConfirmarAccion 
            mensaje1 = { universidad_seleccionada && `Se quitará ${seleccionada.nombre} de la lista` }
            ejecutarAccion = { this.quitarUniversidadSeleccionada } 
            mostrarModalConfAccion = { (modal_quitar_universidad) => this.setState({ modal_quitar_universidad }) } 
            modalConAccion = { modal_quitar_universidad } 
            dato = { universidad_seleccionada ? seleccionada : { id: 0 } } 
            titulo = { '¿ Quitar universidad ?' } 
          />
          <IconButton aria-label = "add"  className = { classes.colorEmmma } onClick = { () => this.setState({ modal_buscar_universidad: true }) }>
            <AddIcon />
          </IconButton>
          <IconButton aria-label = "delete" className = { classes.colorDelete } onClick = { () => onClickEliminar(universidad_seleccionada) }>
            <DeleteIcon />
          </IconButton>
        </Grid>
				<Grid item xs = { 6 }>
          <TextField
						id = 'idiomas_destino'
						disabled
						name = 'idiomas_destino'
						value = { idiomas_destino }
						label = 'Idioma en países destino'
						fullWidth
						variant = 'outlined'
						onChange = { onChange }
          />
        </Grid>
        <Grid item xs = { 6 }>
          <TextField
            value={ cantidad_universidades }
            required
            error = { getError('cantidad_universidades', errores).length > 0 }
            helperText = { getError('cantidad_universidades', errores) }
            type = 'number'
            id = "cantidad_universidades"
            name = "cantidad_universidades"
            label = "Cantidad de universidades posibles"
            fullWidth
            onChange = { onChange }
            variant = 'outlined'
          />
        </Grid>
        <Grid item xs = { 12 }>
          <FormControl className = 'form-control' required variant = 'outlined' error = { getError('requisitos', errores).length > 0 }>
            <InputLabel id = 'requisitos_label'>Requisitos</InputLabel>
            <Select
              labelId = 'requisitos_label'
              id = 'requisitos'
              required
              multiple
              name = 'requisitos'
              value = { requisitos }
              label = 'Requisitos'
              fullWidth
              onChange = { onChange }
            >
              { requisitos_opciones.map((req) => (
                <MenuItem key= { req.id } value = { req.id }>{ req.nombre }</MenuItem>
              )) }
            </Select>
            <FormHelperText>{ getError('requisitos', errores) }</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  renderArchivos = () => {
    let { archivos } = this.state;
    return <CustomDropzone archivos = { archivos } addFunction = { this.agregarArchivos } deleteFunction = { this.eliminarArchivo } />
  }
  
  render () {
    let { modalAddConvocatorias, mostrarModalAddConvocatorias } = this.props;
    let { activeStep, cargando, tit_cargando } = this.state;
    return (
      <>
        <Dialog fullWidth = { true } maxWidth = 'sm' open = { modalAddConvocatorias } onClose = { () => mostrarModalAddConvocatorias(false) }>
          <AppBarModal titulo = { '¡ Nueva Convocatoria !' } mostrarModal = { () => mostrarModalAddConvocatorias(false) } titulo_accion = 'Cerrar' accion = { () => mostrarModalAddConvocatorias(false) } />
          <DialogContent className = 'scroll'>
            {
              !cargando ?
                <Grid container>
                  <Grid item xs = { 12 } md = { 12 }>
                    <Stepper activeStep = { activeStep }>
                      {
                        configStep.map(label => (
                          <Step key = { label }>
                            <StepLabel><p>{ label }</p></StepLabel>
                          </Step>
                        ))
                      }
                    </Stepper>
                  </Grid>
                  <Grid item xs = { 12 }>
                    { this.configStepContent() }
                  </Grid>
                </Grid>
                : <TareasTerminadas mensaje = { tit_cargando } marginTop = '7%' imagen = { emma_w } widthImg = '7%' />
            }
          </DialogContent>
          <DialogActions>
            <>
              {
                activeStep == 0 &&
                  <div>
                    <BtnForm texto = "CANCELAR" callback = { () => this.handleStep(false) } />
                    <BtnForm texto = "SIGUIENTE" callback = { () => this.handleStep(true) } />
                  </div>
              }
              {
                activeStep == 1 &&
                  <div>
                    <BtnForm texto = "REGRESAR" callback = { () => this.handleStep(false) } />
                    <BtnForm texto = "SIGUIENTE" callback = { () => this.handleStep(true) } />
                  </div>
              }
              {
                activeStep == 2 &&
                  <div>
                    <BtnForm texto = "REGRESAR" callback = { () => this.handleStep(false) } />
                    <BtnForm texto = "GUARDAR" callback = { () => this.handleStep(true) } />
                  </div>
              }
            </>
          </DialogActions>
        </Dialog>
        <Agregar actAgregar = { () => mostrarModalAddConvocatorias(true) } />
      </>
    );
  }
}

ConvocatoriasAgregar.propTypes = {
  modalAddConvocatorias: PropTypes.bool.isRequired,
  mostrarModalAddConvocatorias: PropTypes.func.isRequired
}

export default withStyles(useStyles2)(ConvocatoriasAgregar);
