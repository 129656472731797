import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import {Grid,FormControl, TextField} from '@material-ui/core';

class ConfirmarAccionRetosCuc extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      observaciones_adjunto: '',
    }
  }

  componentDidUpdate({ modalConAccion }) {
    let { modalConAccion: modalConNew, dato } = this.props;
    if (modalConNew !== modalConAccion) {
      this.changeState(dato.id);
    }
  }

  changeState = (id) => {
    this.setState({
      id
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  onSubmit = e => {
    let { id,observaciones_adjunto } = this.state;
    let { dato } = this.props;
    if (!observaciones_adjunto || observaciones_adjunto === '') {
      this.props.actualizarMensaje({ titulo: 'Debe diligenciar el campo de observacion para continuar', tipo: 'info', mostrar: true, tiempo: 6000 })
      return false
    }
    
    this.props.ejecutarAccion(id == 0 ? dato.id: id,observaciones_adjunto);
    e.preventDefault();
  }

  render() {
    let { modalConAccion, mostrarModalConfAccion, titulo, mensaje1, mensaje2 } = this.props;
    let { observaciones_adjunto } = this.state;
    return (
      <Dialog open={modalConAccion} onClose={mostrarModalConfAccion.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={titulo} mostrarModal={mostrarModalConfAccion} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <DialogContentText id="alert-dialog-description">
            {mensaje1} {mensaje2}
          </DialogContentText>
          <Grid item xs={12} md={12}>
            <FormControl className="form-control">
              <TextField
                value={observaciones_adjunto}
                id="txtMotivoNegado"
                label="Observaciones"
                name="observaciones_adjunto"
                placeholder={"Ingrese observaciones"}
                multiline={true}
                minRows={5}
                onChange={this.onChange}
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalConfAccion(false)} />
          <BtnForm texto="ACEPTAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
ConfirmarAccionRetosCuc.propTypes = {
  //variables
  modalConAccion: PropTypes.bool.isRequired,
  titulo: PropTypes.string,
  dato: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  //funciones
  mostrarModalConfAccion: PropTypes.func.isRequired,
  ejecutarAccion: PropTypes.func.isRequired,
}

ConfirmarAccionRetosCuc.defaultProps = {
  titulo: 'Eliminar Dato ?',
  mensaje1: 'Tener en cuenta que no podrá revertir esta acción.',
  mensaje2: 'Si desea continuar presione la opción de "Aceptar".'
};

export default ConfirmarAccionRetosCuc;