import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {consulta, rutaImg } from "../../../global/js/funciones";


//COMPONENTES GENERALES
import { mostrarModulo} from "../../../redux/actions/actGlobal";
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import ListarDatos from '../../general/ListarDatos';
import { BtnPermiso } from '../../general/BotonesAccion';
import Asignar_Asignatura from './Asignar_Asignatura';

class Sisef_Gestion extends Component {

    constructor(props) {
        super(props)
        this.state = {
          seleccion: 'nuevo',
          cargando: true,
          nuevo: true,
          asignar:false,
          data:{},
          mensaje: '',
          tipoSolicitud: {},
          docentes:[],
        }
        this.toggleDialog = this.toggleDialog.bind(this)
      }

    async componentDidMount() {
        let { mostrarModulo } = this.props;
        mostrarModulo({ rutas: 'gestion_sisef', nombre: 'Gestión MENTORA' })
        this.obtenerDatosIniciales();
    }

    async componentDidUpdate({}, { seleccion}) {
        let { seleccion: seleccionNew} = this.state;
        if (seleccion !== seleccionNew) {
          if (seleccionNew === 'nuevo')  this.setState({ nuevo: true })
        }
    }

    cambiarEstado = (estados) => {
        this.setState(estados)
    }
    
    cambiarOpcion = (seleccion) => {
        this.cambiarEstado({ seleccion })
    }

    toggleDialog(toggle) {
      let { modal, value } = toggle
      this.setState({ [modal]: value })
    }

    async obtenerDatosIniciales() {
      let {docentes} = this.state
      this.setState({ cargando: true})
      docentes = await this.obtenerDocentes("Per_Doc");
      let mensaje = "Estas en el proceso";
      this.setState({ mensaje,cargando: false, docentes})
    }

    async obtenerDocentes(cod) {
      return new Promise(resolve => {
        consulta(`api/v1.0/sisef/buscar_docentes/${cod}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        })
      })
    }

      vista = () => {
        let {seleccion} = this.state
        switch (seleccion) {
          case 'nuevo':
            return this.pintarListaDocentes();
          default:
            break;
        }
      };

      acciones(rowData){
        const Asignar_Asignatura = (row) => {
          this.setState({asignar: true, data:row})
        }
         return <div> <BtnPermiso texto="Asinar Asignaturas" callback={() => Asignar_Asignatura(rowData)}/></div>;
       }

      pintarListaDocentes = () => {
        let {docentes, asignar, data} = this.state
        return (
          <>
            <ListarDatos
              id='tbl_lista_docentes'
              datos={docentes}
              acciones={(row) => this.acciones(row.persona)}
              opciones={true}
              titulo='Profesores'
              buscar={true}
              filtrar={false}
              avatar={( persona) => `${rutaImg}${persona.identificacion}.jpg`}
              fila_principal={({ persona }) => `${persona.primer_nombre} ${persona.segundo_nombre} ${persona.primer_apellido} ${persona.segundo_apellido}`.toUpperCase()}
              filas={[                
                  { 'mostrar': ({ persona }) => `${persona.identificacion}`,
                    'id': 'identificacion', 'enLista': true
                  },
              ]}
            />
            <Asignar_Asignatura
            Modal={asignar}
            cerrarModal = {(resp) => this.setState({asignar: resp})}
            data={data}
            />
          </>
        );
      };

    render() {
        let {cargando} = this.state
        return (
            <div>
                {
                    !cargando ?  this.vista() :
                    <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>
                } 
            </div>
            
        );
    }
}
    const mapStateToProps = state => {
        let { usuario } = state.redGlobal;
        let { ModalAdd } = state.redSisef;
        return { usuario, ModalAdd }
    }

    const mapDispatchToProps = { 
      mostrarModulo 
    }
    
      Sisef_Gestion.propTypes = {
        //Funciones
        ModalAdd: PropTypes.bool.isRequired,
        mostrarModulo: PropTypes.func.isRequired,
    }
    

export default connect(mapStateToProps, mapDispatchToProps)(Sisef_Gestion);