import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { consulta, mostrarError, obtenerValores } from "../../../global/js/funciones";
import VotantesListar from "./VotantesListar";
import VotantesAgregar from "./VotantesAgregar";
import ConfirmarAccion from '../../general/ConfirmarAccion';
import TareasTerminadas from '../../general/TareasTerminadas'

//IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

//ACCIONES DEL MODULO
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import { mostrarModalAddVotantes, actualizarDataVotantes, actualizarRenderVotantes, mostrarModalDelVotantes, actualizarDataVotante } from "../../../redux/actions/actVotantes";


class Votantes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
    };
  }

  componentDidMount() {
    let { eleccion } = this.props;
    this.obtenerVotantes(eleccion.id);
  }

  obtenerVotantes = (eleccion) => {
    let { actualizarDataVotantes, actualizarRenderVotantes } = this.props;
    this.setState({ cargando: true })
    consulta(`api/v1.0/elecciones/${eleccion}/votantes`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataVotantes(resp);
          actualizarRenderVotantes(true);
        }
        this.setState({ cargando: false })
      }
    );

  }

  eliminarVotante = (id) => {
    let { actualizarMensaje, mostrarModalDelVotantes, eleccion } = this.props;
    consulta(`api/v1.0/elecciones/${eleccion.id}/votantes/${id}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerVotantes(eleccion.id);
            mostrarModalDelVotantes(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }
  guardarVotante = (votantes) => {
    let { actualizarMensaje, mostrarModalAddVotantes, eleccion } = this.props;
    consulta(`api/v1.0/elecciones/${eleccion.id}/votantes/crear`, { votantes }, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerVotantes(eleccion.id);
            mostrarModalAddVotantes(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  render() {
    let { desactivar_acciones, eleccion, actualizarDataVotante, modalAddVotantes, mostrarModalAddVotantes, votantes, votante, modalDelVotantes, mostrarModalDelVotantes } = this.props;
    return (
      <Fragment>
        {!this.state.cargando ?
          <div>
            <VotantesListar eleccion={eleccion} votantes={votantes} desactivar_acciones={desactivar_acciones} actualizarDataVotante={actualizarDataVotante} mostrarModalDelVotantes={mostrarModalDelVotantes} mostrarModalAddVotantes={mostrarModalAddVotantes} />
            <VotantesAgregar obtenerValores={obtenerValores} modalAddVotantes={modalAddVotantes} mostrarModalAddVotantes={mostrarModalAddVotantes} guardarVotante={this.guardarVotante} />
            <ConfirmarAccion mensaje1={`El tipo de votante "${votante.nombre}" será eliminado, `} ejecutarAccion={this.eliminarVotante} mostrarModalConfAccion={mostrarModalDelVotantes} modalConAccion={modalDelVotantes} dato={votante} titulo={'¿ Eliminar Votante ?'} />
          </div> :
          <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
        }
      </Fragment>
    );
  }

}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalDelVotantes, modalAddVotantes, votantes, renderVotantes, votante } = state.redVotantes;
  return {
    modalAddVotantes,
    votantes,
    renderVotantes,
    votante,
    modalDelVotantes,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  mostrarModalAddVotantes,
  mostrarModalDelVotantes,
  actualizarMensaje,
  actualizarDataVotantes,
  actualizarRenderVotantes,
  actualizarDataVotante,
}

//Props del componente
Votantes.propTypes = {
  //variables
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalAddVotantes: PropTypes.func.isRequired,
  mostrarModalDelVotantes: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarDataVotantes: PropTypes.func.isRequired,
  actualizarDataVotante: PropTypes.func.isRequired,
  actualizarRenderVotantes: PropTypes.func.isRequired,
  desactivar_acciones: PropTypes.func.isRequired,
}



export default connect(mapStateToProps, mapDispatchToProps)(Votantes);

