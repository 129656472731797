import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Paper, Grid } from '@material-ui/core'

//components
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal'
import AccionesRapidas from '../../general/AccionesRapidas'

//imagenes
import datos_personales from '../../../global/imagenes/portal/portfolio.png'
import responsable from '../../../global/imagenes/account.png'

class AccioneRapidasEmpresa extends Component {
    render() {
        let { modalAccionesRapidas, mostrarModalAccionesRapidas, abrirFormConLaInfo } = this.props
        return (
            <div> 
                <Dialog open={modalAccionesRapidas} onClose={() => mostrarModalAccionesRapidas(false)} fullWidth={true} maxWidth="md" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo="Información Empresa" mostrarModal={() => mostrarModalAccionesRapidas(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 40 }} className="scroll">
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper elevation={0} square style={{ maxHeight: 400, width: "100%" }}>
                                <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper 
                                            onClick={
                                                () => abrirFormConLaInfo('Empresa','Datos de la Empresa')
                                            }
                                            elevation={3} 
                                            style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={datos_personales}  titulo='Datos de la empresa' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar información de la empresa.' />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <Paper onClick={() => abrirFormConLaInfo('Responsable', 'Datos del Responsable')} elevation={3} style={{ border: '1px solid #e0e0e0' }} >
                                            <AccionesRapidas imagen={responsable} titulo='Responsable' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar información del responsable' />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <button type="submit" className='oculto' id='btn_crear_empresa_pro'></button>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalAccionesRapidas(false)} />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AccioneRapidasEmpresa;