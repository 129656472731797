import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomNavigation, BottomNavigationAction, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
//ACCIONES DEL MODULO
import { BtnForm } from "../general/BotonesAccion";
import EnviarMensaje from "../general/EnviarMensaje";
import ConfirmarAccion from "../general/ConfirmarAccion";
import AppBarModal from '../general/AppBarModal'
import Adjuntos from "./adjuntos/Adjuntos";

class PracticasAdjuntos extends Component {


  constructor(props) {
    super(props)
    this.state = {
      accionesM: false,
      Modalconfirmar: false,
      ModalconfirmarMensaje: false,
      adjSeleccionados: [],
      confirmar: false,
      tipo: 0,
      validacion: null,
    }
  }

  componentDidUpdate({ modalAdmAdjuntosPractica }) {
    let { modalAdmAdjuntosPractica: modalAdmAdjuntosPracticaMNew } = this.props;
    if (modalAdmAdjuntosPracticaMNew !== modalAdmAdjuntosPractica && modalAdmAdjuntosPracticaMNew) this.setState({ accionesM: false, tipo: 0, adjSeleccionados: [] });
  }


  actualizarDataAdjSeleccionados = (adjSeleccionados) => this.setState({ adjSeleccionados })

  accionesMasivas = (accionesM = false, tipo = 0, validacion = null) => this.setState({ accionesM, tipo, validacion })


  pintarAccionesMs = () => {
    let { tipo } = this.state;
    const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

    const pintar = (claseN) => {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels>
            <BottomNavigationAction label={"Lista"} icon={< ImportContactsIcon className='menu_icono' />} className={clase(0, tipo)} value={0} onClick={() => this.accionesMasivas()} />
            <BottomNavigationAction label={"Negar"} icon={<BlockIcon className='menu_icono' />} className={clase(1, tipo)} value={1} onClick={() => this.accionesMasivas(true, 1, 0)} />
            <BottomNavigationAction label={"Aprobar"} icon={<CheckCircleOutlineIcon className='menu_icono' />} className={clase(2, tipo)} value={2} onClick={() => this.accionesMasivas(true, 2, 0)} />
          </BottomNavigation>
        </div>
      )
    }

    return pintar('menu_modal');
  }


  render() {
    let {
      modalAdmAdjuntosPractica,
      mostrarModalAdmAjuntosPractica,
      config,
      practica,
      estado,
      adj_faltantes,
      mostrarModalAdjFaltantes,
      gestionarMasivos,
      obtenerAdjFaltantes,
      usuario,
      admin
    } = this.props;

    let { adjSeleccionados, accionesM, tipo, validacion } = this.state;

    const accionesAdj = () => {
      if (practica && practica.id > 0 && estado.id > 0) {
        let cod_pr = practica.estado_actual.codigo;
        let cod_et = estado.codigo;
        if (cod_pr === cod_et && cod_pr !== 'Est_Pra_Rec' && cod_pr !== 'Est_Pra_Fin') return true;
      }
      return false;
    }


    return (
      <>
        <Dialog maxWidth="sm" fullWidth open={modalAdmAdjuntosPractica} onClose={mostrarModalAdmAjuntosPractica.bind(this, false)} aria-labelledby="form-dialog-title">
          <AppBarModal titulo={`¡ Requisitos ! ${practica && practica.usuario_registro ? `${` - ${practica.usuario_registro.primer_apellido + ' ' + practica.usuario_registro.primer_nombre}`}` : ''}`} mostrarModal={mostrarModalAdmAjuntosPractica} titulo_accion={accionesAdj() && adj_faltantes.length > 0 && tipo === 0 ? 'Pendientes' : ''} accion={accionesAdj() && adj_faltantes.length > 0 ? () => mostrarModalAdjFaltantes(true) : ''} />
          {config && this.pintarAccionesMs()}
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Adjuntos
              tipo={tipo}
              obtenerAdjFaltantes={obtenerAdjFaltantes}
              adjSeleccionados={adjSeleccionados}
              accionesM={accionesM}
              accionesMasivas={this.accionesMasivas}
              actualizarDataAdjSeleccionados={this.actualizarDataAdjSeleccionados}
              config={config}
              accionesAct={accionesAdj()}
              validacion={validacion}
              usuario={usuario}
              admin={admin}
            />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => mostrarModalAdmAjuntosPractica(false)} />
            {(accionesM && adjSeleccionados.length > 0) && < BtnForm texto="TERMINAR" callback={() => tipo === 2 ? this.setState({ Modalconfirmar: true }) : this.setState({ ModalconfirmarMensaje: true })} />}
          </DialogActions>
        </Dialog>
        < ConfirmarAccion
          dato={{ id: 0 }}
          ejecutarAccion={() => gestionarMasivos(tipo, adjSeleccionados, '', () => this.setState({ accionesM: false, Modalconfirmar: false, tipo: 0, adjSeleccionados: [], validacion: null }))}
          modalConAccion={this.state.Modalconfirmar}
          mostrarModalConfAccion={Modalconfirmar => this.setState({ Modalconfirmar })} />
        <EnviarMensaje
          modal={this.state.ModalconfirmarMensaje}
          mostrarModal={ModalconfirmarMensaje => this.setState({ ModalconfirmarMensaje })}
          enviar={mensaje => gestionarMasivos(tipo, adjSeleccionados, mensaje, () => this.setState({ accionesM: false, ModalconfirmarMensaje: false, tipo: 0, adjSeleccionados: [], validacion: null }))}
          label='Nota'
          type='text'
          titulo='¿ Negar Documentos ?' />
      </>
    )
  }
}

//Props del componente
PracticasAdjuntos.propTypes = {
  //variables
  modalAdmAdjuntosPractica: PropTypes.bool.isRequired,
  estado: PropTypes.object.isRequired,
  practica: PropTypes.object.isRequired,
  config: PropTypes.bool.isRequired,
  mostrarModalAdmAjuntosPractica: PropTypes.func.isRequired,
  obtenerAdjFaltantes: PropTypes.func.isRequired,
  //gestionarPractica: PropTypes.func.isRequired,
}

PracticasAdjuntos.defaultProps = {
  adj_faltantes: [],
  obtenerAdjFaltantes: () => { },
  usuario: {codigo: 'Estudiante_Pra'}
};


export default PracticasAdjuntos;
