import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, Link } from '@material-ui/core'
import { BtnForm } from '../../general/BotonesAccion';
import moment from 'moment'
import { api } from '../../../global/js/funciones'
import { BtnEditar, BtnEliminar, BtnSeleccion, BtnDetalle, BtnCancelar, BtnAgregar } from '../../general/BotonesAccion'

//component
import AppBarModal from '../../general/AppBarModal'
import ListarDatos from '../../general/ListarDatos'

class ListarOpcionesPerfil extends Component {

    actions = row => {
        let { funcion, funcionEliminar, llave, actualizarValidarp } = this.props
        return (
            <div>
                <BtnEditar
                    callback={() => {
                        funcion(row, llave)
                    }}
                />
                <BtnEliminar
                    callback={() => {
                        row.estado = "0"
                        funcionEliminar(row, llave)
                        actualizarValidarp()
                    }}
                />
            </div>
        )
    }

    actions_adj = row => {
        let { funcionEliminar, llave,actualizarValidarp } = this.props
        return (
            <div>
                <Link href={`${api + row.archivo}`} target="_blank">
                    <BtnDetalle callback={() => { }} />
                </Link>
                <BtnEliminar
                    callback={() => {
                        delete row.archivo
                        row.estado = "0"
                        funcionEliminar(row, llave)
                        actualizarValidarp()
                    }}
                />
            </div>
        )
    }

    acciones = (row) => {
        let { agregarInteres, eliminarInteres, misIntereses } = this.props
        let auxBtn = misIntereses.find((interes) => row.id == interes.area_interes.id);
        return (
            <div>
                {
                    auxBtn ? (
                        <BtnEliminar
                            callback={() => {
                                eliminarInteres(row)
                            }}
                        />
                    )
                        :
                        (
                            <BtnAgregar
                                callback={() => {
                                    agregarInteres(row)
                                }}
                            />
                        )
                }
            </div>
        )
    }

    renderListarDatos = () => {
        let { llave, datos, datos_modal, cargarFunciones } = this.props
        switch (llave) {
            case "Academica":
                return (
                    <ListarDatos
                        id="tbl_inf_academica"
                        datos={datos}
                        /*  agregar={datos_modal.btnAdd} */
                        descargar={''}
                        /* actAgregar={() => cargarFunciones(llave)} */
                        opciones={true}
                        buscar={true}
                        titulo={"Academica"}
                        sub_titulo={"Lista de mis Estudios"}
                        avatar={({ tipo }) => tipo[0].toUpperCase()}
                        acciones={row => this.actions(row)}
                        fila_principal={({ titulo }) => titulo.toUpperCase()}
                        filas={[
                            {
                                'mostrar': ({ universidad }) => universidad.toUpperCase(),
                                'id': 'estado_actual',
                                'enLista': true
                            },
                            {
                                'mostrar': ({ fecha_inicio_est, fecha_final_est, en_curso_aca }) => `${moment(fecha_inicio_est).format('YYYY-MM-DD')} - ${(en_curso_aca === '0') ? fecha_final_est : "En Curso"}`,
                                'id': 'fecha_inicio',
                                'enLista': true
                            }
                        ]}
                    />
                )
            case "Experiencia":
                return (
                    <ListarDatos
                        id="tbl_inf_experiencia"
                        datos={datos}
                        /* agregar={datos_modal.btnAdd} */
                        descargar={''}
                        /* actAgregar={() => cargarFunciones(llave)} */
                        opciones={true}
                        buscar={true}
                        titulo={"Experiencia"}
                        sub_titulo={"Lista de mi experiencia"}
                        avatar={({ cargo }) => cargo != null ? cargo[0].toUpperCase() : "NA"}
                        acciones={row => this.actions(row)}
                        fila_principal={({ cargo }) => cargo != null ? cargo.toUpperCase() : "No cuenta con experiencia laboral"}
                        filas={[
                            {
                                'mostrar': ({ empresa }) => empresa != null ? empresa.toUpperCase() : "NA",
                                'id': 'estado_actual',
                                'enLista': true
                            },
                            {
                                'mostrar': ({ fecha_inicio_exp, fecha_final_exp, en_curso_lab }) => `${moment(fecha_inicio_exp).format('YYYY-MM-DD')} - ${(en_curso_lab === '0') ? fecha_final_exp : "En Curso"}`,
                                'id': 'fecha_inicio',
                                'enLista': true
                            }
                        ]}
                    />
                )
            case "Habilidad":
                return (
                    <ListarDatos
                        id="tbl_inf_habilidad"
                        datos={datos}
                        /* agregar={datos_modal.btnAdd} */
                        descargar={''}
                        /* actAgregar={() => cargarFunciones(llave)} */
                        opciones={true}
                        buscar={true}
                        titulo={"Habilidades"}
                        sub_titulo={"Lista de mis habilidades"}
                        avatar={({ nivel }) => nivel}
                        acciones={row => this.actions(row)}
                        fila_principal={({ tipo }) => "HABILIDAD " + tipo.toUpperCase()}
                        filas={[
                            {
                                'mostrar': ({ descripcion }) => descripcion,
                                'id': 'estado_actual',
                                'enLista': true
                            }
                        ]}
                    />
                )
            case "Distincion":
                return (
                    <ListarDatos
                        id="tbl_inf_distincion"
                        datos={datos}
                        /* agregar={datos_modal.btnAdd} */
                        descargar={''}
                        /* actAgregar={() => cargarFunciones(llave)} */
                        opciones={true}
                        buscar={true}
                        titulo={"Logros y Distinciones"}
                        sub_titulo={"Lista de mis Logros y distinciones"}
                        avatar={({ descripcion }) => descripcion[0].toUpperCase()}
                        acciones={row => this.actions(row)}
                        fila_principal={({ descripcion }) => descripcion}
                        filas={[
                            {
                                'mostrar': ({ descripcion }) => `${descripcion.substring(0, 10)}...`,
                                'id': 'estado_actual',
                                'enLista': true
                            }
                        ]}
                    />
                )
            case "Anexos":
                return (
                    <ListarDatos
                        id="tbl_inf_anexos"
                        datos={datos}
                        /* agregar={datos_modal.btnAdd} */
                        descargar={''}
                        /* actAgregar={() => cargarFunciones(llave)} */
                        opciones={true}
                        buscar={true}
                        titulo={"Documentos"}
                        sub_titulo={"Lista de mis documentos"}
                        acciones={row => this.actions_adj(row)}
                        avatar={({ nombre_archivo }) => nombre_archivo[0].toUpperCase()}
                        fila_principal={({ nombre_archivo }) => nombre_archivo}
                        filas={[]}
                    />
                )
            case "Intereses":
                return (
                    <ListarDatos
                        id="tbl_inf_intereses"
                        datos={datos}
                        /* agregar={datos_modal.btnAdd} */
                        descargar={''}
                        /* actAgregar={() => cargarFunciones(llave)} */
                        opciones={true}
                        buscar={true}
                        titulo={"Areas de Interes"}
                        sub_titulo={"Lista de mis áres de interes"}
                        avatar={({ nombre }) => nombre[0].toUpperCase()}
                        acciones={row => this.acciones(row)}
                        fila_principal={({ nombre }) => nombre}
                        filas={[]}
                    />
                )
        }
    }

    render() {
        let {
            modalListarInformacionPerfil,
            mostrarModalListarInformacionPerfil,
            datos_modal,
            cargarFunciones,
            llave,
            datos
        } = this.props
        let nombre = datos_modal.nombre_modal
        return (
            <Dialog open={modalListarInformacionPerfil} onClose={() => mostrarModalListarInformacionPerfil(false)} fullWidth={true} maxWidth="md" fullScreen={window.innerWidth < 600}>
                <AppBarModal titulo={nombre} mostrarModal={() => mostrarModalListarInformacionPerfil(false)} titulo_accion="" />
                <DialogContent className="scroll">
                    {this.renderListarDatos()}
                </DialogContent>
                <DialogActions>
                    {
                        (datos_modal.btnAdd !== false &&
                            llave !== 'Intereses' &&
                            (llave !== 'Anexos' || datos?.length !== 3))
                            ? <BtnForm texto="AGREGAR INFORMACIÓN" callback={() => cargarFunciones(llave)} />
                            : ''
                    }
                    <BtnForm texto="CERRAR" callback={() => mostrarModalListarInformacionPerfil(false)} />
                    <button type="submit" className='oculto' id='btn_crear_empresa_pro'></button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ListarOpcionesPerfil;
