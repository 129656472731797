import React, { Component } from 'react'
import {
    FormControl,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Container,
    Checkbox,
    FormControlLabel,
    DialogContent,
    Paper,
    List
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import TextField from '@material-ui/core/TextField';
import InputFile from "../../general/InputFile";
import Chip from '@material-ui/core/Chip';
import AttachFile from '@material-ui/icons/AttachFile'
import { api } from '../../../global/js/funciones'
import AlertasSimple from '../../general/AlertasSimple';
import AccionesRapidas from '../../general/AccionesRapidas'
import anexos from '../../../global/imagenes/files.png'

import CustomDropzone from "../../general/CustomDropzone"
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import { BtnCargar, BtnForm } from "../../general/BotonesAccion";
import { Class } from '@material-ui/icons';

class FormInformacionPrincipal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombre_archivo: '',
        }
    }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
            [name]: value
        })
    }

    render() {
        let { onChangeState, datosPrincipales } = this.props
        let { nombre_archivo } = this.state;
        let { persona, archivo } = datosPrincipales;
        return (
            <React.Fragment>
                <FormControl className='form-control'>
                    <Container maxWidth="sm">
                        <Grid
                            container
                            spacing={3}
                            direction='column'
                            justify='center'
                        >
                            {
                                (datosPrincipales.id && persona) ?
                                    <>
                                        <Grid item container xs={12} direction="row" justify="space-between">
                                            <Grid item xs={5}>
                                                <TextValidator
                                                    margin="dense"
                                                    id="inf_primer_nombre"
                                                    label="Primer Nombre"
                                                    type="text"
                                                    disabled={true}
                                                    fullWidth
                                                    // name="inf_primer_nombre"
                                                    value={persona.primer_nombre}
                                                // onChange={onChangeState}
                                                // validators={['required']}
                                                // errorMessages={["El campo es requerido"]}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextValidator
                                                    margin="dense"
                                                    id="inf_segundo_nombre"
                                                    label="Segundo nombre"
                                                    disabled={true}
                                                    type="text"
                                                    value={persona.segundo_nombre}
                                                    // onChange={onChangeState}
                                                    fullWidth
                                                // name="inf_segundo_nombre"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} direction="row" justify="space-between">
                                            <Grid item xs={5}>
                                                <TextValidator
                                                    margin="dense"
                                                    id="inf_primer_apellido"
                                                    label="Primer apellido"
                                                    disabled={true}
                                                    type="text"
                                                    fullWidth
                                                    value={persona.primer_apellido}
                                                // name="inf_primer_apellido"
                                                // onChange={onChangeState}
                                                // validators={["required"]}
                                                // errorMessages={["El campo es requerido"]}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextValidator
                                                    margin="dense"
                                                    id="inf_segundo_apellido"
                                                    label="Segundo apellido"
                                                    disabled={true}
                                                    type="text"
                                                    fullWidth
                                                    // name="inf_segundo_apellido"
                                                    value={persona.segundo_apellido}
                                                // onChange={onChangeState}
                                                // validators={["required"]}
                                                // errorMessages={["El campo es requerido"]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                margin="dense"
                                                id="inf_doc_identidad"
                                                label="Número de documento de identidad"
                                                type="text"
                                                disabled={true}
                                                fullWidth
                                                // name="inf_doc_identidad"
                                                value={persona.identificacion}
                                            // onChange={onChangeState}
                                            // validators={["required", "isNumber"]}
                                            // errorMessages={["El campo es requerido", "El campo debe ser un número"]}
                                            />
                                        </Grid>
                                    </> : <></>
                            }
                            <Grid item xs={12}>
                                <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                    <InputLabel>Género</InputLabel>
                                    <Select
                                        value={datosPrincipales.genero}
                                        onChange={(e) => onChangeState({
                                            target: {
                                                value: e.target.value,
                                                name: "genero"
                                            }
                                        })}
                                    >
                                        <MenuItem value={"masculino"}>Masculino</MenuItem>
                                        <MenuItem value={"femenino"}>Femenino</MenuItem>
                                        <MenuItem value={"no binario"}>No binario</MenuItem>
                                        <MenuItem value={"otro"}>Otro</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                    <InputLabel>Estado Civil</InputLabel>
                                    <Select
                                        value={datosPrincipales.estado_civil}
                                        onChange={(e) => onChangeState({
                                            target: {
                                                value: e.target.value,
                                                name: "estado_civil"
                                            }
                                        })}
                                    >
                                        <MenuItem value={"soltero"}>Soltero/a</MenuItem>
                                        <MenuItem value={"union_libre"}>Unión libre o unión de hecho</MenuItem>
                                        <MenuItem value={"casado"}>Casado/a</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="inf_email"
                                    label="E-Mail"
                                    type="text"
                                    fullWidth
                                    value={datosPrincipales.email}
                                    name="email"
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>

                            {/* campos nuevos */}
                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="fecha_nac"
                                        label="Fecha de nacimiento"
                                        type="date"
                                        value={datosPrincipales.fecha_nacimiento}
                                        fullWidth
                                        name="fecha_nacimiento"
                                        onChange={onChangeState}
                                        // validators={["rewquired"]}
                                        // errorMessages={["El campo es requerido"]}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="pais_nac"
                                        label="Pais de nacimiento"
                                        type="text"
                                        fullWidth
                                        value={datosPrincipales.pais_nacimiento}
                                        name="pais_nacimiento"
                                        onChange={onChangeState}
                                    // validators={["rewquired"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="departamento_nac"
                                        label="Departamento de nacimiento"
                                        type="text"
                                        value={datosPrincipales.departamento_nacimiento}
                                        fullWidth
                                        name="departamento_nacimiento"
                                        onChange={onChangeState}
                                    // validators={["rewquired"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="municipio_nac"
                                        label="Municipio de nacimiento"
                                        type="text"
                                        fullWidth
                                        value={datosPrincipales.municipio_nacimiento}
                                        name="municipio_nacimiento"
                                        onChange={onChangeState}
                                    // validators={["rewquired"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="pais_res"
                                        label="Pais de residencia"
                                        type="text"
                                        value={datosPrincipales.pais_residencia}
                                        fullWidth
                                        name="pais_residencia"
                                        onChange={onChangeState}
                                    // validators={["rewquired"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="departamento_Res"
                                        label="Departamento residencia"
                                        type="text"
                                        fullWidth
                                        value={datosPrincipales.departamento_residencia}
                                        name="departamento_residencia"
                                        onChange={onChangeState}
                                    // validators={["rewquired"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid>
                            {/* fin campos nuevos */}

                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="inf_direccion"
                                        label="Dirección"
                                        type="text"
                                        fullWidth
                                        value={datosPrincipales.direccion}
                                        name="direccion"
                                        onChange={onChangeState}
                                    // validators={["rewquired"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="ciudad"
                                        label="Ciudad"
                                        type="text"
                                        value={datosPrincipales.ciudad}
                                        fullWidth
                                        name="ciudad"
                                        onChange={onChangeState}
                                    // validators={["rewquired"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="inf_telefono"
                                    label="Teléfono"
                                    type="text"
                                    fullWidth
                                    value={datosPrincipales.telefono}
                                    name="telefono"
                                    onChange={onChangeState}
                                // validators={["required", "isNumber"]}
                                // errorMessages={["El campo es requerido", "El campo debe ser un número"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    required
                                    rows={3}
                                    id="inf_perfil"
                                    label="Perfil profesional"
                                    value={datosPrincipales.perfil}
                                    type="text"
                                    fullWidth
                                    name="perfil"
                                    onChange={onChangeState}
                                />
                            </Grid>
                            <Grid container item xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="salario_min"
                                        label="Aspiración salarial mínima"
                                        type="text"
                                        fullWidth
                                        value={datosPrincipales.salario_min}
                                        name="salario_min"
                                        onChange={onChangeState}
                                    // validators={["isNumber"]}
                                    // errorMessages={["El campo debe ser un número"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator
                                        margin="dense"
                                        id="inf_salario_max"
                                        label="Aspiración salarial máxima"
                                        type="text"
                                        fullWidth
                                        name="salario_max"
                                        value={datosPrincipales.salario_max}
                                        onChange={onChangeState}
                                    // validators={["isNumber"]}
                                    // errorMessages={["El campo debe ser un número"]}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="inf_linkedin"
                                    label="Linkedin"
                                    value={datosPrincipales.linkedin}
                                    type="text"
                                    fullWidth
                                    name="linkedin"
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className='oculto'
                                    ref={this.fileInput}
                                    type="file"
                                    id="info_archivo_mod"
                                    name="nombre_archivo"
                                    label={"Foto de la Empresa"}
                                    fullWidth
                                    autoComplete="billing address-line1"
                                    onChange={tag => {
                                        this.onChange(tag)
                                        onChangeState(tag)
                                    }}
                                />
                                <Grid item xs={12}>
                                    <InputFile
                                        label={"Foto de Perfil"}
                                        id='info_archivo_mod'
                                        value={typeof archivo === 'string' ? archivo.replace(/^.*[\\\/]/, '') : nombre_archivo}
                                    />
                                    {
                                        typeof archivo === 'string' && nombre_archivo ? (
                                            <Chip
                                                size="small"
                                                color="primary"
                                                clickable
                                                label="Ver Archivo"
                                                icon={<AttachFile />}
                                                component="a"
                                                href={`${api + archivo}`}
                                                target="_blank"
                                            />
                                        ) : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}

class FormInformacionAcademica extends Component {

    state = {
        onCheck: false,
        value: null
    }

    onCheck = async (e) => {
        let { onChangeState } = this.props
        if (e.target.checked) {
            await this.setState({
                onCheck: true,
                value: "1"
            })
        } else {
            await this.setState({
                onCheck: false,
                value: "0"
            })
        }
        return onChangeState({
            target: {
                value: this.state.value,
                name: "en_curso_aca"
            }
        })
    }

    thisChecked = async () => {
        let { datosAcademicos } = this.props
        if (datosAcademicos.en_curso_aca == "1") {
            await this.setState({
                onCheck: true
            })
            return true
        } else {
            await this.setState({
                onCheck: false
            })
            return false
        }
    }

    componentDidMount() {
        this.thisChecked()
    }

    render() {
        let { onChangeState, datosAcademicos } = this.props
        return (
            <React.Fragment>
                <FormControl className="form-control">
                    <Container maxWidth="sm">
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                            justify="center"
                        >
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="profesion"
                                    label="Profesión"
                                    type="text"
                                    fullWidth
                                    name="profesion"
                                    value={datosAcademicos.profesion}
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="universidad"
                                    label="Institución donde realizó sus estudios"
                                    type="text"
                                    fullWidth
                                    name="universidad"
                                    value={datosAcademicos.universidad}
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                    <InputLabel>Tipo de estudio</InputLabel>
                                    <Select
                                        value={datosAcademicos.tipo}
                                        onChange={(e) => onChangeState({
                                            target: {
                                                value: e.target.value,
                                                name: "tipo"
                                            }
                                        })}>
                                        <MenuItem value={"pregrado"}>Pregrado</MenuItem>
                                        <MenuItem value={"posgrado"}>Posgrado</MenuItem>
                                        <MenuItem value={"especializacion"}>Especialización</MenuItem>
                                        <MenuItem value={"maestria"}>Maestría</MenuItem>
                                        <MenuItem value={"doctorado"}>Doctorado</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="titulo"
                                    label="Título obtenido"
                                    type="text"
                                    fullWidth
                                    name="titulo"
                                    value={datosAcademicos.titulo}
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>

                            {/* campos nuevos */}
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="pais"
                                    label="Pais"
                                    type="text"
                                    fullWidth
                                    name="pais"
                                    value={datosAcademicos.pais}
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            {/* fin campos nuevos */}

                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="departamento"
                                    label="Departamento/Región"
                                    type="text"
                                    fullWidth
                                    name="departamento"
                                    value={datosAcademicos.departamento}
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="cuidad"
                                    label="Ciudad"
                                    type="text"
                                    fullWidth
                                    name="ciudad"
                                    value={datosAcademicos.ciudad}
                                    onChange={onChangeState}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>


                            <Grid item container xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <Grid container>
                                            <KeyboardDatePicker
                                                id="fecha_inicio_est"
                                                required={true}
                                                value={datosAcademicos.fecha_inicio_est}
                                                style={{ marginTop: 3, width: '100%' }}
                                                // minDate={Date("YYYY-MM-dd")}
                                                margin="normal"
                                                label="Fecha de inicio"
                                                format="yyyy-MM-dd"
                                                onChange={(value) => onChangeState({
                                                    target: {
                                                        value: moment(value).format('YYYY-MM-DD'),
                                                        name: "fecha_inicio_est"
                                                    }
                                                })}
                                                // KeyboardButtonProps={{
                                                //     'aria-label': 'change date',
                                                // }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <Grid container>
                                            <KeyboardDatePicker
                                                id="fecha_final_est"
                                                // required={true}
                                                value={datosAcademicos.fecha_final_est}
                                                style={{ marginTop: 3, width: '100%' }}
                                                // minDate={Date("YYYY-MM-dd")}
                                                margin="normal"
                                                disabled={this.state.onCheck}
                                                label="Fecha de finalización"
                                                format="yyyy-MM-dd"
                                                onChange={(value) => onChangeState({
                                                    target: {
                                                        value: moment(value).format('YYYY-MM-DD'),
                                                        name: "fecha_final_est"
                                                    }
                                                })}
                                                // KeyboardButtonProps={{
                                                //     'aria-label': 'change date',
                                                // }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.onCheck}
                                            onChange={(e) => this.onCheck(e)}
                                        />}
                                    label="En curso"
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}

class FormInformacionExperiencia extends Component {

    state = {
        onCheck: false,
        value: null
    }

    state = {
        onCheck1: false,
        value: null
    }

    onCheck = async (e) => {
        let { onChangeState } = this.props
        if (e.target.checked) {
            await this.setState({
                onCheck: true,
                value: "1"
            })
        } else {
            await this.setState({
                onCheck: false,
                value: "0"
            })
        }
        return onChangeState({
            target: {
                value: this.state.value,
                name: "en_curso_lab"
            }
        })
    }

    onCheck1 = async (d) => {
        let { onChangeState } = this.props
        if (d.target.checked) {
            await this.setState({
                onCheck1: true,
                value: "1"
            })
        } else {
            await this.setState({
                onCheck1: false,
                value: "0"
            })
        }
        return onChangeState({
            target: {
                value: this.state.value,
                name: "no_cuento_lab"
            }
        })
    }

    thisChecked = async () => {
        let { datosExperiencia } = this.props
        if (datosExperiencia.en_curso_lab == "1") {
            await this.setState({
                onCheck: true
            })
            return true
        } else {
            await this.setState({
                onCheck: false
            })
            return false
        }
    }

    thisChecked1 = async () => {
        let { datosExperiencia } = this.props
        if (datosExperiencia.no_cuento_lab == "1") {
            await this.setState({
                onCheck1: true
            })
            return true
        } else {
            await this.setState({
                onCheck1: false
            })
            return false
        }
    }

    componentDidMount() {
        this.thisChecked()
        this.thisChecked1()
    }

    render() {
        let { onChangeState, datosExperiencia } = this.props
        return (
            <React.Fragment>
                <FormControl className="form-control">
                    <Container maxWidth="sm">
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                            justify="center"
                        >
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={this.state.onCheck}
                                            checked={this.state.onCheck1}
                                            onChange={(d) => this.onCheck1(d)}
                                        />}
                                    label="No cuento con experiencia laboral"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="empresa"
                                    label="Empresa"
                                    type="text"
                                    name="empresa"
                                    value={datosExperiencia.empresa}
                                    fullWidth
                                    onChange={onChangeState}
                                    disabled={this.state.onCheck1}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            {/* campos nuevos */}
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="pais"
                                    label="Pais"
                                    type="text"
                                    fullWidth
                                    name="pais"
                                    value={datosExperiencia.pais}
                                    onChange={onChangeState}
                                    disabled={this.state.onCheck1}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="departamento"
                                    label="Departamento/Región"
                                    type="text"
                                    fullWidth
                                    name="departamento"
                                    value={datosExperiencia.departamento}
                                    onChange={onChangeState}
                                    disabled={this.state.onCheck1}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="cuidad"
                                    label="Ciudad"
                                    type="text"
                                    fullWidth
                                    name="ciudad"
                                    value={datosExperiencia.ciudad}
                                    onChange={onChangeState}
                                    disabled={this.state.onCheck1}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>

                            {/* fin campos nuevos */}

                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="cargo"
                                    label="Cargo"
                                    type="text"
                                    fullWidth
                                    value={datosExperiencia.cargo}
                                    onChange={onChangeState}
                                    name="cargo"
                                    disabled={this.state.onCheck1}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="ocupacion"
                                    label="Ocupación"
                                    type="text"
                                    fullWidth
                                    value={datosExperiencia.ocupacion}
                                    onChange={onChangeState}
                                    name="ocupacion"
                                    disabled={this.state.onCheck1}
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    required
                                    rows={3}
                                    id="responsabilidades"
                                    label="Principales responsabilidades y logros alcanzados"
                                    value={datosExperiencia.responsabilidades}
                                    type="text"
                                    fullWidth
                                    name="responsabilidades"
                                    onChange={onChangeState}
                                    disabled={this.state.onCheck1}
                                />
                            </Grid>
                            <Grid item container xs={12} direction="row" justify="space-between">
                                <Grid item xs={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <Grid container>
                                            <KeyboardDatePicker
                                                id="fecha_inicio_exp"
                                                required={true}
                                                style={{ marginTop: 3, width: '100%' }}
                                                margin="normal"
                                                label="Fecha de inicio"
                                                format="yyyy-MM-dd"
                                                disabled={this.state.onCheck1}
                                                value={datosExperiencia.fecha_inicio_exp}
                                                // value={fecha_inicio}
                                                onChange={(value) => onChangeState({
                                                    target: {
                                                        value: moment(value).format('YYYY-MM-DD'),
                                                        name: "fecha_inicio_exp"
                                                    }
                                                })}
                                                // KeyboardButtonProps={{
                                                //     'aria-label': 'change date',
                                                // }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <Grid container>
                                            <KeyboardDatePicker
                                                id="fecha_final_exp"
                                                style={{ marginTop: 3, width: '100%' }}
                                                margin="normal"
                                                label="Fecha de finalización"
                                                format="yyyy-MM-dd"
                                                disabled={this.state.onCheck || this.state.onCheck1}
                                                value={datosExperiencia.fecha_final_exp}
                                                onChange={(value) => onChangeState({
                                                    target: {
                                                        value: moment(value).format('YYYY-MM-DD'),
                                                        name: "fecha_final_exp"
                                                    }
                                                })}
                                                // KeyboardButtonProps={{
                                                //     'aria-label': 'change date',
                                                // }}
                                                invalidDateMessage='Formato Invalido'
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={this.state.onCheck1}
                                            checked={this.state.onCheck}
                                            onChange={(e) => this.onCheck(e)}
                                        />}
                                    label="En curso"
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}

class FormInformacionHabilidades extends Component {
    render() {
        let { onChangeState, datosHabilidad } = this.props
        return (
            <React.Fragment>
                <FormControl className="form-control">
                    <Container maxWidth="sm">
                        <Grid
                            container
                            direction="column"
                            justify="center"
                        >
                            <Grid item container xs={12} direction="column" justify="space-between">
                                <Grid item xs={12}>
                                    <FormControl style={{ width: '-webkit-fill-available' }} fullWidth={true} >
                                        <InputLabel>Tipo de habilidad</InputLabel>
                                        <Select
                                            value={datosHabilidad.tipo}
                                            onChange={(e) => onChangeState({
                                                target: {
                                                    value: e.target.value,
                                                    name: "tipo"
                                                }
                                            })}
                                        >
                                            <MenuItem value={"laboral"}>Laboral</MenuItem>
                                            <MenuItem value={"personal"}>Personal</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        margin="dense"
                                        id="descripcion"
                                        name="descripcion"
                                        value={datosHabilidad.descripcion}
                                        type="text"
                                        fullWidth
                                        onChange={onChangeState}
                                        label="Nombre"
                                    // validators={["required"]}
                                    // errorMessages={["El campo es requerido"]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        margin="dense"
                                        id="nivel"
                                        label="Nivel (1 - 5)"
                                        type="text"
                                        fullWidth
                                        name="nivel"
                                        value={datosHabilidad.nivel}
                                        onChange={onChangeState}
                                    // validators={["required", "isNumber"]}
                                    // errorMessages={["El campo es requerido", "El campo debe ser un número"]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}

class FormInformacionLogros extends Component {
    render() {
        let { onChangeState, datosDistincion } = this.props
        return (
            <React.Fragment>
                <FormControl className="form-control">
                    <Container maxWidth="sm">
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                            justify="center"
                        >
                            <Grid item xs={12}>
                                <TextValidator
                                    margin="dense"
                                    id="descripcion"
                                    name="descripcion"
                                    value={datosDistincion.descripcion}
                                    fullWidth
                                    type="text"
                                    onChange={onChangeState}
                                    label="Descripción de las distinciones y logros alcanzados"
                                // validators={["required"]}
                                // errorMessages={["El campo es requerido"]}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </FormControl>
            </React.Fragment>
        )
    }
}

/* class Requisitos extends Component {
    render() {
        let { agregarArchivos, requisitos, archivos } = this.props

        let obligatorio = []
        requisitos.map((info) => {
            if (info.valora === '1') {
                obligatorio.push({ item: info.nombre })
            }
        })

        const trasnformarDataAdj = (data) => {
            let r = [];
            data.map((info) => r.push({ nombre: info.nombre,descripcion: info.descripcion,codigo: info.id, archivo: info.archivo, formatos: info.valorg, obligatorio: info.valora }))
            return r;
        }

        const acciones = (data, callback) => {
            const onClickCargar = (data) => callback(data);
            let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />

            return <div>{cargar}</div>;
        }

        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper elevation={0} square>
                            <List component="nav" arial-label="main">
                                <EnviarMultiplesArchivos
                                    acciones={acciones}
                                    enModal={false}
                                    enviarArchivos={(archivos) => agregarArchivos(archivos)}
                                    limite={3}
                                    mostrarMensaje={false}
                                    enLista={true}
                                    lista={trasnformarDataAdj(requisitos)}
                                    formatos={true}
                                />
                            </List>
                        </Paper>
                    </Grid>
                    <AlertasSimple
                        tipo='info'
                        titulo={'Estimado, tener en cuenta que estos documentos son obligatorios: '}
                        lista={obligatorio}
                        margin='0px'
                    />
                </Grid>
            </React.Fragment>
        );
    }
} */

/* class FormInformacionAnexos extends Component {
    render() {
        let { agregarArchivos, eliminarArchivo, archivos, actualizarMensaje,requisitos } = this.props
        return (
            <React.Fragment>
                <DialogContent>
                    <AlertasSimple
                        tipo='info'
                        titulo={'Estimado Estudiante/Graduado, para la efectividad de tu proceso, asegúrate de cargar todos los documentos solicitados.'}
                        lista={'hola'}
                        margin='0px'
                    />
                </DialogContent>
                <Grid container justifyContent="center" alignItems="center">
                    <Paper elevation={3} onClick={() => console.log("Alerta clickeada")} style={{ border: '1px solid #e0e0e0', padding: '20px', textAlign: 'center' }} >
                        <AccionesRapidas imagen={anexos} titulo='Cargar hoja de vida y diplomas' sub_titulo='Bien' des_sub_titulo=', Aquí puedes agregar tus certificados...' />
                    </Paper>
                </Grid>
                <Grid container>
                    <CustomDropzone
                        addFunction={agregarArchivos}
                        actualizarMensaje={actualizarMensaje}
                        archivos={archivos}
                        deleteFunction={eliminarArchivo}
                    />
                    <Requisitos
                        requisitos={requisitos}
                        agregarArchivos={agregarArchivos}
                        archivos={archivos}
                    />
                </Grid>
            </React.Fragment>
        )
    }
} */


class Perfil extends Component {
    renderFuncionDataPerfil = () => {
        let { llave, onChangeState, datosPrincipales, datosAcademicos, datosExperiencia, datosNoExperiencia, datosDistincion, datosHabilidad, agregarArchivos, eliminarArchivo, archivos, actualizarMensaje,requisitos } = this.props
        switch (llave) {
            case 'Personal':
                return (
                    <FormInformacionPrincipal
                        onChangeState={onChangeState}
                        datosPrincipales={datosPrincipales}
                    />
                )
            case 'Academica':
                return (
                    <FormInformacionAcademica
                        onChangeState={onChangeState}
                        datosAcademicos={datosAcademicos}
                    />
                )
            case 'Experiencia':
                return (
                    <FormInformacionExperiencia
                        onChangeState={onChangeState}
                        datosExperiencia={datosExperiencia}
                    />
                )
            case 'Habilidad':
                return (
                    <FormInformacionHabilidades
                        onChangeState={onChangeState}
                        datosHabilidad={datosHabilidad}
                    />
                )
            case 'Distincion':
                return (
                    <FormInformacionLogros
                        onChangeState={onChangeState}
                        datosDistincion={datosDistincion}
                    />
                )
            /* case 'Anexos':
                return (
                    <FormInformacionAnexos
                        agregarArchivos={agregarArchivos}
                        eliminarArchivo={eliminarArchivo}
                        archivos={archivos}
                        actualizarMensaje={actualizarMensaje}
                        requisitos={requisitos}
                    />
                ) */
            default:
                return <> </>;
        }
    }

    render() {
        return (
            <div>
                <ValidatorForm
                    className="scroll"
                    style={{ marginTop: "20px" }}
                >
                    {this.renderFuncionDataPerfil()}
                    <button type="submit" className='oculto' id='btn_crear_empresa_pro'></button>
                </ValidatorForm>
            </div>
        )
    }
}

export default Perfil