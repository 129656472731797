
import { Document, Paragraph, TextRun, Header, AlignmentType, Media } from "docx";
import moment from 'moment';
import logo_cuc from '../../global/imagenes/logo_cuc3.png'

import mariaO from '../../global/imagenes/firmas_practicas/mariaO.jpeg'
import maria from '../../global/imagenes/firmas_practicas/maria.png'
import jonatan from '../../global/imagenes/firmas_practicas/jonatan.png'
import keidy from '../../global/imagenes/firmas_practicas/keidy.png'
import martha from '../../global/imagenes/firmas_practicas/martha.png'
import yesenia from '../../global/imagenes/firmas_practicas/yesenia.png'
import noris from '../../global/imagenes/firmas_practicas/noris.png'
import yair from '../../global/imagenes/firmas_practicas/yair.png'
import yira from '../../global/imagenes/firmas_practicas/yira.png'
import oscar from '../../global/imagenes/firmas_practicas/oscar.png'


export const crearDocumento = async (info_coord, practica) => {
  let hoy = moment()
  let periodo_practica = practica.periodo
  periodo_practica = `${periodo_practica.slice(0, -1)}-${periodo_practica.slice(-1)}`

  const obtenerMes = (mes) => {
    const meses = {
      '1': 'Enero',
      '2': 'Febrero',
      '3': 'Marzo',
      '4': 'Abril',
      '5': 'Mayo',
      '6': 'Junio',
      '7': 'Julio',
      '8': 'Agosto',
      '9': 'Septiembre',
      '10': 'Octubre',
      '11': 'Noviembre',
      '12': 'Diciembre'
    }
    return (meses[mes])
  }

  const doc = new Document({
    sections: []
  });

  const firma_coor = correo => {
    if(correo == "ylarios1@cuc.edu.co") return yair
    else if(correo == "molivero4@cuc.edu.co") return mariaO
    else if(correo == "mbabilon@cuc.edu.co") return maria
    else if(correo == "jmacias4@cuc.edu.co") return jonatan
    else if(correo == "mmarin@cuc.edu.co") return martha
    else if(correo == "yoliveros4@cuc.edu.co") return yesenia
    else if(correo == "nnavarro5@cuc.edu.co") return noris
    else if(correo == "yhernand39@cuc.edu.co") return yira
    else if(correo == "ogiraldo@cuc.edu.co") return oscar
    else return keidy
  }

  const img_logo = await fetch(
    logo_cuc
  ).then(r => r.blob());

  const img_fima = await fetch(
    firma_coor(info_coord.secundario.valorb)
  ).then(r => r.blob());

  const logo = Media.addImage(doc, img_logo, 220, 220)
  const firma = Media.addImage(doc, img_fima, 190, 60)
  // const logo = new ImageRun({
  //   data: img_logo,
  //   transformation: {
  //     width: 220,
  //     height: 220,
  //   }
  // });

  // const firma = new ImageRun({
  //   data: img_fima,
  //   transformation: {
  //     width: 190,
  //     height: 60,
  //   }
  // });

  const crearParrafo = (text, estilos_secundarios = {}) => {
    let estilos_generales = {
      text,
      font: "Arial",
      size: 24,
      break: 1,
    }
    return new Paragraph({
      alignment: AlignmentType.JUSTIFIED,
      children: [
        new TextRun(Object.assign(estilos_generales, estilos_secundarios))
      ]
    });
  }

  const crearLista = (text) => {
    return new Paragraph({
      alignment: AlignmentType.JUSTIFIED,
      children: [
        new TextRun({
          text,
          font: "Arial",
          size: 24,
          // break: 3
        }),
      ],
      // numbering: {
      //   reference: "my-number-numbering-reference",
      //   level: 0,
      // },
      // spacing: {
      //   before: 300,
      // },
    })
  }
  const infoCoordinador = () => {
    const estilos = (text) => {
      return {
        text,
        font: "Arial",
        size: 22,
        break: 1,
      }
    }

    return new Paragraph({
      children: [
        firma,
        new TextRun(
          Object.assign(estilos(info_coord.secundario.nombre), { bold: true })
        ),
        new TextRun(
          estilos("Coordinador de Prácticas Universitarias")
        ),
        new TextRun(
          estilos("Departamento de Empleabilidad")
        ),
        new TextRun(
          estilos("Universidad de la Costa")
        ),
        (info_coord.secundario.valora) && new TextRun(
          estilos(`Celular: +57 ${info_coord.secundario.valora}`)
        ),
        new TextRun(
          estilos(`Correo: ${info_coord.secundario.valorb}`)
        )
      ]
    })
  }
  // doc.addSection({
  //   properties: {},

  // })

  doc.addSection({
    // sections: [
      // {
        properties: {},
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  logo
                ],
              }),
            ],
          }),
        },
        children: [
          crearParrafo(`Barranquilla, ${hoy.format('D')} de ${obtenerMes(hoy.format('M'))} de ${hoy.format('YYYY')}`),
          new Paragraph({
            children: [
              new TextRun({
                text: "Señores:",
                font: "Arial",
                size: 24,
                break: 3,
              }),
              new TextRun({
                text: new TextRun({
                  text: "(NOMBRE DE EMPRESA) ",
                  font: "Arial",
                  size: 24,
                  bold: true,
                  break: 1,
                }),
                font: "Arial",
                size: 24,
                break: 1,
              })
            ]
          }),
          crearParrafo(`Asunto: Presentación Estudiante para Práctica Universitaria, periodo ${periodo_practica}.`, { bold: true }),
          crearParrafo(`En el programa de ${practica.programa.nombre} de la Universidad De La Costa CUC, para optar el título profesional un estudiante que cumpla con los requisitos académicos debe desarrollar una práctica universitaria, la cual corresponde a una asignatura de 10 semestre y puede tener una duración de 3 a 6 meses calendario (presencial, semipresencial o teletrabajo), teniendo en cuenta las necesidades de la empresa o institución. El objetivo de esta práctica es que el estudiante, aplique las competencias adquiridas en su periodo de formación en una empresa u organización de la región o el país, mediante el desempeño de actividades de mejoramiento al interior de la misma.`, { break: 3 }),
          crearParrafo(`Es así que la Universidad propone al estudiante ${practica.usuario_registro.primer_nombre} ${practica.usuario_registro.primer_apellido} ${practica.usuario_registro.segundo_apellido} identificado(a) con C.C N° ${practica.usuario_registro.identificacion} de xxxxxxxxxx, que este ha cumplido con los requisitos para ingresar al proceso de prácticas y por tal motivo deseamos que su entidad pueda tenerlo en cuenta en el proceso de selección para ingresar como practicante en el periodo académico ${periodo_practica}.`),
          crearParrafo("Metodología:", { bold: true, break: 2 }),
          crearParrafo("Durante todo el periodo correspondiente a la práctica, se realiza un seguimiento y evaluación por parte del tutor académico o coordinador de prácticas para brindar asesorías y guiar al estudiante en los procesos que vienen desarrollando y serían las siguientes:"),
          crearLista("Visitas a la empresa (telefónica o video conferencia) durante el periodo de práctica con el objetivo de evaluar el desempeño del estudiante dentro de la empresa, así como brindar seguimiento del ejercicio de la labor y la supervisión del cumplimiento de las expectativas que sobre el desarrollo del mismo tienen las partes vinculadas en el proceso, evaluaciones que serán realizadas por su tutor o jefe directo. En caso de que se desarrollen las prácticas fuera de la ciudad, el seguimiento se realizará de manera virtual o telefónica."),
          crearLista("La presentación y entrega de un informe ejecutivo al finalizar la práctica en la empresa donde el estudiante plasme todas las actividades desarrolladas durante su periodo, documento que debe ser avalado por el jefe inmediato o tutor empresarial antes de ser enviado a la institución."),
          crearParrafo("Atentamente,", { break: 3 }),
          infoCoordinador()
        ],
      // }
    // ]
  });

  return doc

}
