import { MODAL_CREAR_PROMOCION, MODAL_DET_PROMOCION, MODAL_EDI_PROMOCION, MODAL_ELI_PROMOCION, MODAL_CREAR_VISITA, MODAL_EDI_VISITA , MODAL_DET_VISITA, MODAL_GEST_VISITA, ACT_RENDER_VISITA, ACT_DATA_VISITAS, MODAL_ADD_FILTROS_COLEGIO, MODAL_ADD_FILTROS_VISITA, MODAL_INFORME } from "../actions/actPromocion";
const initialState = {
  modalSolPromocion: false,
  modalDetPromocion: false,
  modalEdiPromocion: false,
  modalEliPromocion: false,
  modalSolVisita: false,
  modalEdiVisita: false,
  modalDetVisita: false,
  modalGestVisita: false,
  renderVisitas: false,
  modalAddFiltros: false,
  modalAddFiltrosVisita: false,
  visitas: [],
  modalInforme: false, 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_CREAR_PROMOCION:
      return Object.assign({}, state, {
        modalSolPromocion: action.show,
      })
    case MODAL_DET_PROMOCION:
      return Object.assign({}, state, {
        modalDetPromocion: action.show,
      })
    case MODAL_EDI_PROMOCION:
      return Object.assign({}, state, {
        modalEdiPromocion: action.show,
      })
    case MODAL_ELI_PROMOCION:
      return Object.assign({}, state, {
        modalEliPromocion: action.show,
      })
    case MODAL_CREAR_VISITA:
      return Object.assign({}, state, {
        modalSolVisita: action.show,
      })
    case MODAL_EDI_VISITA:
      return Object.assign({}, state, {
        modalEdiVisita: action.show,
      })
    case MODAL_DET_VISITA:
      return Object.assign({}, state, {
        modalDetVisita: action.show,
      })
    case MODAL_GEST_VISITA:
      return Object.assign({}, state, {
        modalGestVisita: action.show,
      })
    case ACT_RENDER_VISITA:
      return Object.assign({}, state, {
        renderVisitas: action.render
      })
    case ACT_DATA_VISITAS:
      return Object.assign({}, state, {
        visitas: action.visitas
      })
    case MODAL_ADD_FILTROS_COLEGIO:
      return Object.assign({}, state, {
        modalAddFiltros: action.show
      })
    case MODAL_ADD_FILTROS_VISITA:
      return Object.assign({}, state, {
        modalAddFiltrosVisita: action.show
      })
    case MODAL_INFORME:
      return Object.assign({}, state, {
        modalInforme: action.show,
      })
    default:
      return state;
  }
}

export default reducer;
