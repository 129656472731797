import React, { Component } from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View, pdf } from '@react-pdf/renderer';
import moment from 'moment';
import logo_cuc from '../../global/imagenes/logo_cuc3.png';

import mariaO from '../../global/imagenes/firmas_practicas/mariaO.jpeg';
import maria from '../../global/imagenes/firmas_practicas/maria.png';
import jonatan from '../../global/imagenes/firmas_practicas/jonatan.png';
import keidy from '../../global/imagenes/firmas_practicas/keidy.png';
import martha from '../../global/imagenes/firmas_practicas/martha.png';
import yesenia from '../../global/imagenes/firmas_practicas/yesenia.png';
import noris from '../../global/imagenes/firmas_practicas/noris.png';
import yair from '../../global/imagenes/firmas_practicas/yair.png';
import yira from '../../global/imagenes/firmas_practicas/yira.png';
import oscar from '../../global/imagenes/firmas_practicas/oscar.png';
import source from '../../global/fonts/Roboto-Regular.ttf';
import sourcerobotobold from '../../global/fonts/Roboto-Bold.ttf';
import { obtenerPermisosValorAlt } from '../../global/js/funciones';

Font.register({ family: 'Roboto', src: source });
Font.register({ family: 'RobotoBold', src: sourcerobotobold });

const styles = StyleSheet.create({
    page: {
      paddingLeft: 50,
      paddingRight: 50,
      justifyContent: 'center',
    },
    header: {
      alignItems: 'center',
      marginBottom: 20
    },
    logo: {
      width: 100,
      height: 100
    },
    content: {
      fontSize: 11,
      lineHeight: 1.3,
      width: '100%'
    },
    date: {
      textAlign: 'left',
      marginBottom: 20
    },
    paragraph: {
      marginBottom: 10,
      textAlign: 'justify'
    },
    paragraphBold: {
      fontWeight: 'bold',
      marginBottom: 10
    },
    listItem: {
      marginBottom: 10
    },
    signature: {
      width: 100,
      height: 40,
      marginBottom: 10
    },
    coordinatorName: {
      fontWeight: 'bold'
    },
    coordinatorTitle: {},
    coordinatorContact: {}
  });  

class DescargarPresentacionEstudiantePdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practica: this.props.practica,
      empresa: this.props.empresa,
      info_coord: {}
    };
  }

  async componentDidMount() {
    let coord = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': this.state.practica.programa.id }, { 'llave': 'secundario__generica', 'valor': 61 }])
    this.setState({ info_coord: coord[0] });
    this.generarPDF();
    
  }

  firmaCoor = (correo) => {
    if (correo === "ylarios1@cuc.edu.co") return yair;
    else if (correo === "molivero4@cuc.edu.co") return mariaO;
    else if (correo === "mbabilon@cuc.edu.co") return maria;
    else if (correo === "jmacias4@cuc.edu.co") return jonatan;
    else if (correo === "mmarin@cuc.edu.co") return martha;
    else if (correo === "yoliveros4@cuc.edu.co") return yesenia;
    else if (correo === "nnavarro5@cuc.edu.co") return noris;
    else if (correo === "yhernand39@cuc.edu.co") return yira;
    else if (correo === "ogiraldo@cuc.edu.co") return oscar;
    else return keidy;
  };

  generarPDF = async () => {
    const { practica, info_coord, empresa } = this.state;
    const hoy = moment();
    const periodo_practica = `${practica.periodo.slice(0, -1)}-${practica.periodo.slice(-1)}`;

    const obtenerMes = (mes) => {
      const meses = {
        '1': 'Enero',
        '2': 'Febrero',
        '3': 'Marzo',
        '4': 'Abril',
        '5': 'Mayo',
        '6': 'Junio',
        '7': 'Julio',
        '8': 'Agosto',
        '9': 'Septiembre',
        '10': 'Octubre',
        '11': 'Noviembre',
        '12': 'Diciembre'
      };
      return meses[mes];
    };

    const pdfDoc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.logo} src={logo_cuc} />
          </View>
          <View wrap style={styles.content}>
            <Text style={styles.date}>{`Barranquilla, ${hoy.format('D')} de ${obtenerMes(hoy.format('M'))} de ${hoy.format('YYYY')}`}</Text>
            <Text style={styles.paragraph}>Señores:</Text>
            <Text style={styles.paragraphBold}>{empresa.nombre}</Text>
            <Text style={styles.paragraphBold}>Asunto: Presentación Estudiante para Práctica Universitaria, periodo {periodo_practica}.</Text>
            <Text style={styles.paragraph}>
              En el programa de {practica.programa.nombre} de la Universidad De La Costa CUC, para optar el título profesional un estudiante que cumpla con los requisitos académicos debe desarrollar una práctica universitaria, la cual corresponde a una asignatura de 10 semestre y puede tener una duración de 3 a 6 meses calendario (presencial, semipresencial o teletrabajo), teniendo en cuenta las necesidades de la empresa o institución. El objetivo de esta práctica es que el estudiante, aplique las competencias adquiridas en su periodo de formación en una empresa u organización de la región o el país, mediante el desempeño de actividades de mejoramiento al interior de la misma.
            </Text>
            <Text style={styles.paragraph}>
              Es así que la Universidad propone al estudiante {practica.usuario_registro.primer_nombre} {practica.usuario_registro.primer_apellido} {practica.usuario_registro.segundo_apellido}, identificado(a) con C.C N° {practica.usuario_registro.identificacion}, que este ha cumplido con los requisitos para ingresar al proceso de prácticas y por tal motivo deseamos que su entidad pueda tenerlo en cuenta en el proceso de selección para ingresar como practicante en el periodo académico {periodo_practica}.
            </Text>
            <Text style={styles.paragraphBold}>Metodología:</Text>
            <Text style={styles.paragraph}>
              Durante todo el periodo correspondiente a la práctica, se realiza un seguimiento y evaluación por parte del tutor académico o coordinador de prácticas para brindar asesorías y guiar al estudiante en los procesos que vienen desarrollando y serían las siguientes:
            </Text>
            <Text style={styles.listItem}>
            Visitas a la empresa (telefónica o video conferencia) durante el periodo de práctica con el objetivo de evaluar el desempeño del estudiante dentro de la empresa, así como brindar seguimiento del ejercicio de la labor y la supervisión del cumplimiento de las expectativas que sobre el desarrollo del mismo tienen las partes vinculadas en el proceso, evaluaciones que serán realizadas por su tutor o jefe directo. En caso de que se desarrollen las prácticas fuera de la ciudad, el seguimiento se realizará de manera virtual o telefónica.
            </Text>
            <Text style={styles.listItem}>
              La presentación y entrega de un informe ejecutivo al finalizar la práctica en la empresa donde el estudiante plasme todas las actividades desarrolladas durante su periodo, documento que debe ser avalado por el jefe inmediato o tutor empresarial antes de ser enviado a la institución.
            </Text>
            <Text style={styles.paragraph}>Atentamente,</Text>
            <Image style={styles.signature} src={this.firmaCoor(info_coord?.secundario?.valorb)} />
            <Text style={styles.coordinatorName}>{info_coord?.secundario?.nombre}</Text>
            <Text style={styles.coordinatorTitle}>Coordinador de Prácticas Universitarias</Text>
            <Text style={styles.coordinatorTitle}>Departamento de Empleabilidad</Text>
            <Text style={styles.coordinatorContact}>Correo: {info_coord?.secundario?.valorb}</Text>
          </View>
        </Page>
      </Document>
    );

    const pdfblob = await pdf(pdfDoc).toBlob();    
    const pdfBase64 = await this.blobToBase64(pdfblob);
    return pdfBase64;
  };

  blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Eliminamos el prefijo 'data:application/pdf;base64,' del base64
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  render() {
    return null;
  }
}

export default DescargarPresentacionEstudiantePdf;
