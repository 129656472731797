import React, { Component } from 'react';

import { connect } from 'react-redux';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal';
import { 
	showModalAux,
	showModalDiplomadoAgregar,
	showModalDiplomadoEditar,
	showModalDiplomadoEliminar,
	showModalFiltros
} from '../../redux/actions/actMatriculas';
import { mostrarModalAdd, mostrarModalMod } from '../../redux/actions/actGenericas';

import { Dialog, DialogContent, DialogActions } from '@material-ui/core';

import { MenuConfiguracion } from './MenuMatriculas';
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import ListarDatos from '../general/ListarDatos';
import AppBarModal from '../general/AppBarModal';
import { BtnForm, BtnPermiso, BtnCancelar, BtnSeleccion,BtnEditar, BtnEliminar, BtnHabilitar, BtnDeshabilitar } from '../general/BotonesAccion';
import {
	obtenerValorsPermisos,
	modificarValorGenericaAlter,
	Toast,
	personaSesionGenericas,
	// brandon
	consulta,
	obtenerValores,
	guardarValorGenerica,
	mostrarError,
	modificarValorGenerica,
	obtenerValoresFiltros,
	valor_peso
} from './../../global/js/funciones';
import {
	CURSOS_LIBRES,
	POSGRADOS,
	MATERIAS,
	getDataTabla,
	guardarPermiso,
	eliminarPermiso
} from './matriculas/helper';
import AgregarParametro from './matriculas/AgregarParametro';
import EditarParametro from './matriculas/EditarParametro';
//modal diplomado
import AgregarDiplomado from './matriculas/AgregarDiplomado';
import EditarDiplomado from './matriculas/EditarDiplomado';
import EliminarDiplomado from './matriculas/EliminarDiplomado';
import FiltrarCursos from './MatriculasConf/FiltrarCursos';

// const formatter = new Intl.NumberFormat('en-US', {
// 	style: 'currency',
// 	currency: 'USD', 
// 	maximumFractionDigits: 0,
// });
const filasModal =[
	{
		mostrar: ({ valora }) => `Creditos: ${valora}`,
		id: 'valora' 
	},
	{ 
		mostrar: ({ valord }) => `Horario: ${valord}`,
		id: 'valord' 
	},
	{ 
		mostrar: ({ valore }) => `Hora: ${valore}`,
		id: 'valore' 
	},
	{ 
		mostrar: ({ valorf }) => `Profesor: ${valorf}`,
		id: 'valorf' 
	},
	{ 
		mostrar: ({ valorg }) => `Pensum: ${valorg}`,
		id: 'valorg' 
	},
	{ 
		mostrar: ({ permiso }) => `Estado: ${permiso !== 0 ? "Activo" :"No Asignado"}`,
		id: 'permiso' 
	},
];

class MatriculasConf extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seleccion: '',
			titulo: 'Lista Programas de Pregrado',
			render: false,
			cargando: false,
			cargandoModal: false,
			data: [],
			data_aux: [],
			programa: [],
			permisos: [],
			parametro: MATERIAS,
			perfil: null,
			// brandon barreto
			menu:[],
			idDiplomado:null,
			diplomadoNombre: '',
			diplomadoValor:0,
			diplomadoGrado:'',
			dipSelec: {},
			estado_diplomado: ''
		};
		this.cambiarEstado = this.cambiarEstado.bind(this);
		this.actions = this.actions.bind(this);
		this.cargarPermisos = this.cargarPermisos.bind(this);
	}

	async componentDidMount() {
		const { mostrarModulo} = this.props;
		
		mostrarModulo({ ruta: '/matriculasConf', nombre: 'Config. Matrículas' });
		const res = await personaSesionGenericas(11);
		const data = res.map(p => p.relacion);
		
		// brandon barreto
		this.permisosActividad();
		this.setState({ data, cargando: false, render: true });
	}


	async componentDidUpdate(prevProps, prevState) {
		const { seleccion, programa } = this.state;

		if (seleccion !== prevState.seleccion) {
			this.cargarDatos(seleccion);
		}
		if (programa !== prevState.programa) {
			this.cargarPermisos(programa);
		}
	}

	async mostrarDataTabla(seleccion) {
		this.setState({ render: false });
		const resp = await getDataTabla(seleccion);
		const { data, titulo, parametro } = resp;
		this.setState({ data, render: true, titulo, parametro });
	}

	accionesPrograma(data,menu) {
		const { showModalDiplomadoEditar, showModalDiplomadoEliminar } = this.props;
		switch (menu) {
			case "Diplomado":
				return <> 
					<BtnEditar callback={ _ =>{
						let { id: idDiplomado, nombre: diplomadoNombre, valora: diplomadoValor, valorc:diplomadoGrado } = data;
						this.setState({ diplomadoNombre, diplomadoValor, idDiplomado, diplomadoGrado, dipSelec: data })
						showModalDiplomadoEditar(true)
					}}/> 
					{data.valore == 'mostrar' ?
						<BtnDeshabilitar callback={_ => {
							let { id: idDiplomado, nombre: diplomadoNombre } = data;
							this.setState({ idDiplomado, diplomadoNombre, estado_diplomado: 0, dipSelec: data })
							showModalDiplomadoEliminar(true)
						}} texto='Deshabilitar' color='#E14141'/>
						:
						<BtnHabilitar callback={_ => {
							let { id: idDiplomado, nombre: diplomadoNombre } = data;
							this.setState({ idDiplomado, diplomadoNombre, estado_diplomado: 1, dipSelec: data })
							showModalDiplomadoEliminar(true)
						}} texto='Habilitar' color='#35B670'/>
					}
				</>
			default:
				const { seleccion } = this.state;
				const onClickPermiso = (data) => {
					const parametro = seleccion === 'Intersemestrales' ? MATERIAS : CURSOS_LIBRES;
					this.props.showModalAux(true);
					this.setState({ programa: data.id, parametro });
				};
				const permisos = <BtnPermiso callback={() => onClickPermiso(data)} texto='Materias' />;
				return permisos;
		}
	}

	accionesDiplomados(data) {
		const handleAgregarPermiso = async () => {
			await modificarValorGenericaAlter(data.id, { estado: 1 });
			await this.mostrarDataTabla('diplomados');
			Toast.fire({ icon: 'success', title: 'Diplomado activado!' });
		};

		const handleQuitarPermiso = async () => {
			await modificarValorGenericaAlter(data.id, { estado: 0 });
			await this.mostrarDataTabla('diplomados');
			Toast.fire({ icon: 'success', title: 'Diplomado eliminado!' });
		};

		const permisos =
			data.estado === '1' ? (
				<BtnCancelar callback={() => handleQuitarPermiso(data)} texto='Activar' />
			) : (
				<BtnSeleccion callback={() => handleAgregarPermiso(data)} texto='Desactivar' />
			);
		return permisos;
	}

	cambiarEstado(state) {
		this.setState(state);
	}

	async cargarPermisos(programa) {
		this.setState({ cargandoModal: true });

		const { parametro } = this.state;
		const permisos = await obtenerValorsPermisos(programa, [ { llave: 'generica', valor: parametro } ]);
		this.setState({ permisos, cargandoModal: false });
	}

	actions = ({ permiso, id }) => {
		const { programa, permisos } = this.state;
		const { mostrarModalMod } = this.props;

		return <div>
			{
				permiso !== 0 ? (
					<BtnCancelar callback={() => eliminarPermiso(permiso, programa, this.cargarPermisos)} />
				) : (
					<BtnSeleccion callback={() => guardarPermiso(id, programa, this.cargarPermisos)} />
				)
			}
			<BtnEditar callback={()=> {
				let datosMateria = permisos.find( row => row.id == id);
				this.cambiarEstado({datosMateria})
				mostrarModalMod(true)
			}}/>
		</div>
	};

	// brandon barreto

	permisosActividad = ()=>{
		consulta(
			"api/v1.0/matriculas/config",
			null, 
			"get", 
			(error,estado,menu)=>{
				if(error) return;
				this.cambiarEstado({menu});
				if(menu.length > 0) this.cambiarEstado({seleccion: menu[0].nombre})
			}
		);
	}

	getProgramas = ()=>{
		this.setState({ render: false });
		consulta(
			"api/v1.0/matriculas/config/intersemestrales",
			null, 
			"get", 
			(error,estado,data)=>{
				if(error) return;
				this.cambiarEstado({data});
				this.setState({ render: true });
			}
		);
	}

	verLista = (menu) =>{
		const { titulo, data, cargando} = this.state;
		const { showModalDiplomadoAgregar } = this.props;
		switch (menu) {
			case "Intersemestrales":
				return <ListarDatos
						datos={data}
						titulo='Lista Programas Pregrado'
						buscar={true}
						agregar={false}
						opciones={true}
						cargando={cargando}
						descargar={`EMMA - ${titulo.toLocaleUpperCase()}`}
						id='tbl_materias'
						avatar={({ nombre }) => nombre.charAt(0)}
						acciones={(row) => this.accionesPrograma(row)}
						fila_principal={({ nombre }) => nombre}
						filas={[]}
					/>
			case "Diplomado":
				return <ListarDatos
						datos={data}
						titulo='Lista Programas Pregrado'
						buscar={true}
						agregar={true}
						actAgregar={()=> showModalDiplomadoAgregar(true)}
						opciones={true}
						cargando={cargando}
						descargar={`EMMA - ${titulo.toLocaleUpperCase()}`}
						id='tbl_materias'
						avatar={({ nombre }) => nombre.charAt(0)}
						color_avatar={({valore}) => valore == 'mostrar' ? {backgroundColor: '#35B670' } : {backgroundColor: '#E14141' }}
						acciones={(row) => this.accionesPrograma(row,menu)}
						fila_principal={({ nombre }) => nombre}
						filas={[{
							id:"valora",
							mostrar: ({ valora }) => valor_peso(valora),
							nombre: 'Valor: ' 
						},{
							id:"valorc",
							mostrar: ({ valorc }) => valorc,
							nombre: 'Opción a grado de: ' 
						}]}
					/>
			case "Curso Libre de Posgrado":
				return <ListarDatos
						datos={data}
						titulo='Lista Programas Pregrado'
						buscar={true}
						agregar={false}
						opciones={true}
						cargando={cargando}
						descargar={`EMMA - ${titulo.toLocaleUpperCase()}`}
						id='tbl_materias'
						avatar={({ nombre }) => nombre.charAt(0)}
						acciones={(row) => this.accionesPrograma(row)}
						fila_principal={({ nombre }) => nombre}
						filas={[]}
					/>
			case "Curso Libre de Pregrado":
				return <ListarDatos
						datos={data}
						titulo='Lista Programas Pregrado'
						buscar={true}
						agregar={false}
						opciones={true}
						cargando={cargando}
						descargar={`EMMA - ${titulo.toLocaleUpperCase()}`}
						id='tbl_materias'
						avatar={({ nombre }) => nombre.charAt(0)}
						acciones={(row) => this.accionesPrograma(row)}
						fila_principal={({ nombre }) => nombre}
						filas={[]}
					/>
			default:
				return <ListarDatos
						datos={data}
						titulo='Lista Programas Pregrado'
						buscar={true}
						agregar={false}
						opciones={true}
						cargando={cargando}
						descargar={`EMMA - ${titulo.toLocaleUpperCase()}`}
						id='tbl_materias'
						avatar={({ nombre }) => nombre.charAt(0)}
						acciones={(row) => this.accionesPrograma(row)}
						fila_principal={({ nombre }) => nombre}
						filas={[]}
					/>
		}

	}

	getCursoPregado = async () =>{
		let data = await obtenerValores(28); 
		this.cambiarEstado({data})
	}

	getCursoPosgrado = async () =>{
		let data = await obtenerValores(24); 
		this.cambiarEstado({data})
	}

	getDiplomados = async () => {
		const ordernar = (a, b) => {
			if (a.valore === 'mostrar' && b.valore !== 'mostrar') {
				return -1;
			}

			if (a.valore !== 'mostrar' && b.valore === 'mostrar') {
				return 1;
			}

			// En cualquier otro caso, no se altera el orden
			return 0;
		}

		this.cambiarEstado({render: false})
		let data = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 30 }, { 'llave': 'estado', 'valor': '1' }])
		data = data.filter(d => d.codigo != 'Na_dip').sort(ordernar);
		this.cambiarEstado({data, render: true})
	}

	cargarDatos = (selecion)=>{
		switch (selecion) {
			case "Intersemestrales":
				this.getProgramas();
				break;
			case "Diplomado":
				this.getDiplomados();
				break;
			case "Curso Libre de Posgrado":
				this.getCursoPosgrado();
				break;
			case "Curso Libre de Pregrado":
				this.getCursoPregado();
				break;
		}
	}

	//metodos diplomado
	guardarDiplomado = async ( nombre, valora, valorc ) => { 
		let { actualizarMensaje, showModalDiplomadoAgregar } = this.props;
		let { error, estado, resp } = await guardarValorGenerica( 30, "", nombre, "", valora, "", valorc, "", "mostrar", "", "", "");
		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
			tipo = 'error';
		if (!error) {
			if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				this.getDiplomados();
				showModalDiplomadoAgregar(false);
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	};
	
	editarDiplomado = async (id,nombre, valor, valorc)=>{
		let { dipSelec } = this.state;
		let { actualizarMensaje, showModalDiplomadoEditar } = this.props;
		let { error, estado, resp } = await modificarValorGenerica( id, dipSelec.codigo, nombre, dipSelec.descripcion, valor, valor, valorc, dipSelec.valord, dipSelec.valore, dipSelec.valorf, dipSelec.valorg, "");
		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
			tipo = 'error';
		if (!error) {
			if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				this.getDiplomados();
				showModalDiplomadoEditar(false);
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	}
	
	// eliminarDiplomado = (id)=>{
	// 	let { actualizarMensaje, showModalDiplomadoEliminar} = this.props;
	// 	consulta(`api/v1.0/valores/${id}/eliminar`, {}, 'put', (error, estado, resp) => {
	// 		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
	// 			tipo = 'error';
	// 		if (!error) {
	// 			if (estado === 200) {
	// 				tipo = 'success';
	// 				titulo = resp.titulo;
	// 				this.getDiplomados();
	// 				showModalDiplomadoEliminar(false);
	// 			} else {
	// 				tipo = 'info';
	// 				titulo = mostrarError(resp);
	// 			}
	// 		}
	// 		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	// 	});
	// }

	cambiarEstadoDiplomado = async () => {
		let valor_valore = ''
		let { dipSelec, estado_diplomado, idDiplomado } = this.state;
		if(estado_diplomado) valor_valore = 'mostrar'
		else valor_valore = 'no_mostrar'
		let { actualizarMensaje, showModalDiplomadoEliminar } = this.props;
		let { error, estado, resp } = await modificarValorGenerica( idDiplomado, dipSelec.codigo, dipSelec.nombre, dipSelec.descripcion, dipSelec.valora, dipSelec.valorb, dipSelec.valorc, dipSelec.valord, valor_valore, dipSelec.valorf, dipSelec.valorg, "");
		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
			tipo = 'error';
		if (!error) {
			if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				this.getDiplomados();
				showModalDiplomadoEliminar(false);
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	}


	render() {
		const { 
			seleccion, 
			render,  
			permisos, 
			cargandoModal, 
			parametro, 
			programa, 
			menu, 
			datosMateria, 
			diplomadoNombre, 
			diplomadoValor, 
			idDiplomado, 
			diplomadoGrado,
			estado_diplomado
		} = this.state;
		const { 
			modalAux, 
			showModalAux, 
			modalAdd, 
			modalMod,
			modalFiltros,  
			mostrarModalAdd, 
			mostrarModalMod,
			showModalDiplomadoAgregar,
			showModalDiplomadoEditar,
			showModalDiplomadoEliminar,
			showModalFiltros,
			modalDiplomadoAgregar, 
			modalDiplomadoEditar, 
			modalDiplomadoEliminar,
		} = this.props;
		
		return (
			<div>
				{menu.length != 0 &&
					<MenuConfiguracion
						sele={seleccion}
						seleccionar={(seleccion) => this.cambiarEstado({ seleccion })}
						menu = {menu}
					/>
				}
				<div className='contenido_modulo'>
					{render ? (
						menu.length != 0 ?
						<div>
							{
								this.verLista(seleccion)
							}

							<Dialog
								open={modalAux}
								fullWidth={true}
								maxWidth='sm'
								onClose={() => {
									showModalAux(false);
									const parametro = seleccion === 'materias' ? MATERIAS : POSGRADOS;
									this.setState({ parametro });
								}}
							>
								<AppBarModal
									titulo='Lista Permisos'
									mostrarModal={() => showModalAux(false)}
									accion={() => showModalAux(false)}
									titulo_accion='CERRAR'
								/>
								<DialogContent style={{ padding: 0 }} className='scroll'>
									{!cargandoModal ? (
										<ListarDatos
											id='tbl_listar_permisos'
											titulo={'Lista permisos'}
											avatar={({permiso})=>permiso===0 ? 'N':'A'}
											color_avatar={({permiso})=>permiso===0 ? {backgroundColor: '#b71c1c' }:{backgroundColor: '#009688' }}
											enModal={true}
											datos={permisos}
											opciones={true}
											buscar={true}
											agregar={true}
											filtrar={true}
											actfiltrar={() => showModalFiltros(true)}
											actAgregar={() => mostrarModalAdd(true)}
											refrescar={true}
											actRefrescar={() => this.cargarPermisos(programa)}
											acciones={(row) => this.actions(row)}
											sub_titulo={'Lista de permisos'}
											fila_principal={({ nombre }) => nombre.toUpperCase()}
											filas={filasModal}
										/>
									) : (
										<TareasTerminadas
											imagen={emma_w}
											mensaje='Cargando...'
											widthImg='7%'
											marginTop='7%'
										/>
									)}
								</DialogContent>
								<DialogActions>
									<BtnForm texto='Cerrar' callback={() => showModalAux(false)} />
								</DialogActions>
							</Dialog>

							<AgregarDiplomado
								guardarDiplomado={this.guardarDiplomado}
								mostrarModalAdd={showModalDiplomadoAgregar}
								modalAdd={modalDiplomadoAgregar}
							/>
							<EditarDiplomado
								editarDiplomado={this.editarDiplomado}
								mostrarModalAdd={showModalDiplomadoEditar}
								modalAdd={modalDiplomadoEditar}
								nombre={diplomadoNombre}
								valor={diplomadoValor}
								grado={diplomadoGrado}
								idDiplomado={idDiplomado}
							/>
							<EliminarDiplomado
								cambiarEstadoDiplomado={this.cambiarEstadoDiplomado}
								mostrarModalAdd={showModalDiplomadoEliminar}
								modalAdd={modalDiplomadoEliminar}
								nombre={diplomadoNombre}
							/>
							<FiltrarCursos
							open={modalFiltros}
							setOpen={showModalFiltros}
							cargarPermisos={this.cargarPermisos}
							permisos={permisos}
							cambiarEstado={this.cambiarEstado}
							programa={programa}
							>

							</FiltrarCursos>
						</div>
						:
						<TareasTerminadas mensaje='Esta bandeja esta vacia' marginTop='7%' imagen={emma_s} widthImg='7%' />
					) : (
						<TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg='7%' />
					)}
					<AgregarParametro
						mostrarModalAdd={mostrarModalAdd}
						modalAdd={modalAdd}
						parametro={parametro}
						seleccion={seleccion}
						cambiarEstado={this.cambiarEstado}
						programa={programa}
						cargarPermisos={this.cargarPermisos}
					/>
					<EditarParametro
						datosMateria={datosMateria}
						mostrarModalMod={mostrarModalMod}
						modalMod={modalMod}
						parametro={parametro}
						seleccion={seleccion}
						cambiarEstado={this.cambiarEstado}
						programa={programa}
						cargarPermisos={this.cargarPermisos}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	let { usuario } = state.redGlobal;
	let { 
		modalAux,
		modalDiplomadoAgregar, 
		modalDiplomadoEditar, 
		modalDiplomadoEliminar, 
		modalFiltros
	} = state.redMatriculas;
	let { modalAdd, modalMod } = state.redGenericas;
	return {
		usuario,
		modalAux,
		modalAdd,
		modalFiltros,
		modalMod,
		modalDiplomadoAgregar, 
		modalDiplomadoEditar,
		modalDiplomadoEliminar,
	};
};

const mapDispatchToProps = {
	mostrarModulo,
	showModalAux,
	mostrarModalAdd,
	mostrarModalMod,
	showModalDiplomadoAgregar,
	showModalDiplomadoEditar,
	showModalDiplomadoEliminar,
	showModalFiltros,
	actualizarMensaje
};

export default connect(mapStateToProps, mapDispatchToProps)(MatriculasConf);
