import {
	MODAL_SOLICITUDES,
	MODAL_DETALLE,
	MODAL_GESTIONAR,
	MODAL_FILTROS,
	MATRICULAS,
	MIS_DATOS,
	CURSOS,
	MODAL_AUX,
	MODAL_PROGRAMAS,
	MODAL_OBSERVACIONES,
	MODAL_DIPLOMADO_AGREGAR,
	MODAL_DIPLOMADO_EDITAR,
	MODAL_DIPLOMADO_VER,
	MODAL_DIPLOMADO_ELIMINAR,
	MODAL_ADJ_VOLANTE,
	MODAL_CONFIRMACION,
	MODAL_EDIT_MATRICULA,
  	MODAL_INFORME,
	MODAL_OPEN_STATE,
	MODAL_MOD_PERIODO_MATRICULAS
} from './../actions/actMatriculas';

const initialState = {
	modalSolicitudes: false,
	modalDetalle: false,
	modalGestionar: false,
	modalFiltros: false,
	modalAux: false,
	matriculas: [],
	misCursos: [],
	misDatos: {},
	modalProgramas: false,
	modalObservaciones: false,
	modalDiplomadoAgregar: false,
	modalDiplomadoEditar: false,
	modalDiplomadoVer: false,
	modalDiplomadoEliminar: false,
	modalAdjuntarVolante: false,
	modalConfirmar:false,
	modalEditarMatricula: false,
	modalInforme: false,
	modalState: false,
	modalModPeriodo: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case MODAL_DIPLOMADO_AGREGAR:
			return Object.assign({}, state, {
				modalDiplomadoAgregar: action.show
			});
		case MODAL_DIPLOMADO_EDITAR:
			return Object.assign({}, state, {
				modalDiplomadoEditar: action.show
			});
		case MODAL_DIPLOMADO_VER:
			return Object.assign({}, state, {
				modalDiplomadoVer: action.show
			});
		case MODAL_DIPLOMADO_ELIMINAR:
			return Object.assign({}, state, {
				modalDiplomadoEliminar: action.show
			});
		case MODAL_SOLICITUDES:
			return Object.assign({}, state, {
				modalSolicitudes: action.show
			});
		case MODAL_PROGRAMAS:
			return Object.assign({}, state, {
				modalProgramas: action.show
			});
		case MODAL_DETALLE:
			return Object.assign({}, state, {
				modalDetalle: action.show
			});
		case MODAL_GESTIONAR:
			return Object.assign({}, state, {
				modalGestionar: action.show
			});
		case MATRICULAS:
			return Object.assign({}, state, {
				matriculas: action.matriculas
			});
		case MIS_DATOS:
			return Object.assign({}, state, {
				misDatos: action.misDatos
			});
		case CURSOS:
			return Object.assign({}, state, {
				misCursos: action.misCursos
			});
		case MODAL_FILTROS:
			return Object.assign({}, state, {
				modalFiltros: action.show
			});
		case MODAL_AUX:
			return Object.assign({}, state, {
				modalAux: action.show
			});
		case MODAL_OBSERVACIONES:
			return Object.assign({}, state, {
				modalObservaciones: action.show
			})
		case MODAL_ADJ_VOLANTE:
			return Object.assign({}, state, {
				modalAdjuntarVolante: action.show
			})
		case MODAL_CONFIRMACION:
			return Object.assign({}, state, {
				modalConfirmar: action.show
			})
		case MODAL_EDIT_MATRICULA:
			return Object.assign({}, state, {
				modalEditarMatricula: action.show
			})
		case MODAL_INFORME:
      		return Object.assign({}, state, {
        		modalInforme: action.show
      		})
		case MODAL_OPEN_STATE:
      		return Object.assign({}, state, {
        		modalState: action.show
      		})
		case MODAL_MOD_PERIODO_MATRICULAS:
			return Object.assign({}, state, {
				modalModPeriodo: action.show
			})
		default:
			return state;
	}
};

export default reducer;
