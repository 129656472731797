import React, { useState, Fragment }  from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from '../../general/AppBarModal'
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_s from '../../../global/imagenes/emma_s1.png';
import emma_w from '../../../global/imagenes/emma_w.png'; 
import * as Funciones from '../../../global/js/funciones';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Grid, Link, Paper, Button, Typography, Card, CardHeader, CardContent, Avatar, Grow, Slide, createMuiTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import * as Helper from '../Hijos/Helper';
import { obtenerValores, obtenerValoresFiltros, validarCorreo, obtenerPermisosValor, formulario, mostrarError, crear_form_data } from "../../../global/js/funciones";
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import BuscarSelect from '../../general/BuscarSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SemilleroAddParti from '../Hijos/SemilleroAddParti';
import { BtnEliminar } from "../../general/BotonesAccion";
import LinearProgress from '@material-ui/core/LinearProgress';
import ListarDatos from '../../general/ListarDatos';
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputFile from '../../general/InputFile';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    contenidoModulo: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "90px !important"
        },
    },
    formControl: {
        margin: "0",
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
      },
    colorEmmma: {
        color: "#900807",
        transition: 'all 0.3s',
        '&:hover': {
          color: "#fff",
          backgroundColor: "#900807",
          transform: "scale(1.3)",
        }
    },
    color:{
        transition: 'all 0.3s',
        borderRadius: "20px",
        '&:hover': {
            transform: "scale(1.01)",
            borderRadius: "0px",
            
        },
    },
    center:{
        textAlign: 'center'
    },
    colorEdit: {
        color: "#0074C5",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#0074C5",
          transform: "scale(1.3)",
        },
    },
      colorDelete: {
        color: "#d11a2a",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#d11a2a",
          transform: "scale(1.3)",
        },
      },
      colorCheck: {
        color: "#2ECC71",
        transition: "all 0.3s",
        '&:hover': {
          color: "#fff",
          backgroundColor: "#2ECC71",
          transform: "scale(1.2)",
        },
      },
      modalMinWith: {
            [theme.breakpoints.up('sm')]: {
                minWidth:'500px'
            },
        },
        dialogCustomizedWidth: {
            'max-width': '800px'
          },
    escalar: {
        transition: "all 0.3s",
        border: '1px solid #e0e0e0',
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                transform: "scale(1.05)",
                border: '1px solid #900807'
            },
            
        },
    },
    girar360: {
        transition: "all 0.3s",
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                transform: "rotate(1turn)",
            },
            
        },
    } 
}));

const SemillerosGestionEdit = (props) =>{
    const classes = useStyles()
    //Aquí declaro las variables para que exsta persistencia y hago las funciones de validate data y handleSendToApi
    const [cargandoModal, setCargandoModal] = React.useState(true)
    const isInitialMount = React.useRef(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = React.useState(['Información General', 'Autores'])
    const [tipo, setTipo] = React.useState('')
    const [data, setData] = React.useState([])
    //ActiveStep para produccion bibliografica y produccion tecnica
    const [activeStepBiblio, setActiveStepBiblio] = React.useState(0);

    //Variables de eventos
    const [nombreDelEvento, setNombreDelEvento] = React.useState('');
    const [anio, setAnio] = React.useState(new Date().getFullYear());
    const [departamentos, setDepartamentos] = React.useState([]);
    const [departamento, setDepartamento] = React.useState('');
    const [ciudades, setCiudades] = React.useState([])
    const [ciudad, setCiudad] = React.useState('')
    const [nombreDelAmbito, setNombreDelAmbito] = React.useState('')
    const [nombreAmbitos, setNombreAmbitos] = React.useState([])//Array de ambitos
    const [nombreModalidadPar, setNombreModalidadPar] = React.useState('')
    const [nombreModalidadPars, setNombreModalidadPars] = React.useState([])//Array de modalidad de particiapacion
    const [fechaInicioEvento, setFechaInicioEvento] = React.useState(new Date());
    const [fechaFinEvento, setFechaFinEvento] = React.useState(new Date());
    const [adj_evento, setAdj_evento] = React.useState('')
    const [adj_evento_final, setAdj_evento_final] = React.useState('')
    //Variables proyectos
    const [fechaInicioProyecto, setFechaInicioProyecto] = React.useState(new Date());
    const [fechaFinProyecto, setFechaFinProyecto] = React.useState(new Date());
    const [nombreProyecto, setNombreProyecto] = React.useState('')
    const [descripcionProyecto, setDescripcionProyecto] = React.useState('')
    const [asignaturaProyecto, setAsignaturaProyecto] = React.useState('')
    const [nombreprofesorProyecto, setNombreprofesorProyecto] = React.useState('')
    const [profesorProyecto, setProfesorProyecto] = React.useState([])// Array de Profesores
    //Array para los Autores y Participantes
    const [usuarios, setUsuarios] = React.useState([])
    const [arrai, setArrai] = React.useState([])

    //Array de los eliminados
    const [usuariosEliminados, setUsuariosEliminados] = React.useState([])

    //Errores
    const [errores, setErrores] = React.useState([]);

    //Tipo de producto Bibliografica
    const [tipoProductoBibliografica, setTipoProductoBibliografica] = React.useState([]);
    //Campos del tipo Libro de produccion bibliografica
    const [tipoLibroSelected, setTipoLibroSelected] = React.useState("")
    const [tipoLibroOtro, setTipoLibroOtro] = React.useState("")
    const [tituloLibro, setTituloLibro] = React.useState("")
    const [isbnLibro, setIsbnLibro] = React.useState("")
    const [editorialLibro, setEditorialLibro] = React.useState("")
    const [anioPublicacionLibro, setAnioPublicacionLibro] = React.useState(new Date())
    const [paisPublicacionLibro, setPaisPublicacionLibro] = React.useState("")
    const [idiomaLibro, setIdiomaLibro] = React.useState("")
    const [volumenLibro, setVolumenLibro] = React.useState("")
    const [numeroEdicionLibro, setNumeroEdicionLibro] = React.useState("")
    const [tiposLibros, setTiposLibros] = React.useState([])
    const [paisTipoLibroSelected, setPaisTipoLibroSelected] = React.useState("defecto")
    const [idiomasLibro, setIdiomasLibro] = React.useState([])
    const [idiomaLibroSelected, setIdiomaLibroSelected] = React.useState("")
    //Capos del tipo Capitulo de Libro de produccion bibliografica
    const [tipoLibroSelectedCap, setTipoLibroSelectedCap] = React.useState("")
    const [tipoLibroOtroCap, setTipoLibroOtroCap] = React.useState("")
    const [tituloCapLibro, setTituloCapLibro] = React.useState("")
    const [tituloLibroCap, setTituloLibroCap] = React.useState("")
    const [isbnLibroCap, setIsbnLibroCap] = React.useState("")
    const [editorialLibroCap, setEditorialLibroCap] = React.useState("")
    const [anioPublicacionLibroCap, setAnioPublicacionLibroCap] = React.useState(new Date())
    const [paisPublicacionLibroCap, setPaisPublicacionLibroCap] = React.useState("")
    const [idiomaLibroCap, setIdiomaLibroCap] = React.useState("")
    const [volumenLibroCap, setVolumenLibroCap] = React.useState("")
    const [numeroEdicionLibroCap, setNumeroEdicionLibroCap] = React.useState("")
    const [tiposLibrosCap, setTiposLibrosCap] = React.useState([])
    const [paisTipoCapLibroSelected, setPaisCapTipoLibroSelected] = React.useState("defecto")
    const [idiomasCapituloLibro, setIdiomasCapituloLibro] = React.useState([])
    const [idiomaCapituloLibroSelected, setIdiomaCapituloLibroSelected] = React.useState("")
    //Campos del tipo Articulo de produccion bibliografica
    const [indexacionSelected, setIndexacionSelected] = React.useState("")
    const [tipoArticuloSelected, setTipoArticuloSelected] = React.useState("")
    const [tipoArticuloOtro, setTipoArticuloOtro] = React.useState("")
    const [tituloArticulo, setTituloArticulo] = React.useState("")
    const [issn, setIssn] = React.useState("")
    const [nombreRevista, setNombreRevista] = React.useState("")
    const [anioPublicacion, setAnioPublicacion] = React.useState(new Date())
    const [numPagTotales, setNumPagTotales] = React.useState("")
    const [volumenRevista, setVolumenRevista] = React.useState("")
    const [numeroRevista, setNumeroRevista] = React.useState("")
    const [paisPublicacion, setPaisPublicacion] = React.useState("")
    const [idioma, setIdioma] = React.useState("")
    const [enlace, setEnlace] = React.useState("")
    const [doi, setDoi] = React.useState("")
    const [indexaciones, setIndexaciones] = React.useState([])
    const [tipoArticulo, setTipoArticulo] = React.useState([])
    const [paisesTipoArticulo, setPaisesTipoArticulo] = React.useState([])
    const [paisTipoArticuloSelected, setPaisTipoArticuloSelected] = React.useState("defecto")
    const [idiomasTipoArticulo, setIdiomasTipoArticulo] = React.useState([])
    const [idiomaTipoArticuloSelected, setIdiomaTipoArticuloSelected] = React.useState("")
    //Campos del tipo Patente de producion bibliografica
    const [tipoPatente, setTipoPatente] = React.useState([])
    const [tipoPatenteSelected, setTipoPatenteSelected] = React.useState("")
    const [anioObtencionPatente, setAnioObtencionPatente] = React.useState(new Date())
    const [numeroRegistroPatente, setNumeroRegistroPatente] = React.useState("")
    const [tituloPatente, setTituloPatente] = React.useState("")
    const [numeroPatente, setNumeroPatente] = React.useState("")
    const [paisObtencionPatente, setPaisObtencionPatente] = React.useState("")
    const [gacetaIndustrialPatente, setGaceteIndustrialPatente] = React.useState("")
    const [institucionPatente, setInstitucionPatente] = React.useState("")
    const [tipoPatenteOtro, setTipoPatenteOtro] = React.useState("")
    const [paisPatenteSelected, setPaisPatenteSelected] = React.useState("defecto")
    //Estados para rol y participacion de algunos formularios para los autores o participantes
    const [rolActive, setRolActive] = React.useState(false)
    const [modalidadParticipacionActive, setModalidadParticipacionActive] = React.useState(false)

    const dispatch = useDispatch();
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                let datos = await getInfoRow();
                if(datos[0].tipo.codigo == "Soli_inves_eventos"){
                    let users = await getInfoRowUsers();

                    users = users.map((item)=>({
                        id: item.participante.id,
                        nombre: `${item.participante.primer_nombre} ${item.participante.primer_apellido}`,
                        correo: `${item.participante.correo}`,
                        identificacion: `${item.participante.identificacion}`,
                        modalidadParticipacion: item.modalidad_de_participacion,
                        nuevo: false,
                    }))
                    setUsuarios(users)
                    setArrai(users)
                }else{
                    let users = await getInfoRowUsers();
                    users = users.map(({participante})=>({
                        id: participante.id,
                        nombre: `${participante.primer_nombre} ${participante.primer_apellido}`,
                        correo: `${participante.correo}`,
                        identificacion: `${participante.identificacion}`,
                        nuevo: false,
                    }))

                    setUsuarios(users)
                    setArrai(users)
                }
                setTipo(datos[0].tipo.codigo)
                if(datos[0].tipo.codigo == "Semi_inves_bibliografica"){
                    setTipoProductoBibliografica(datos[0].tipo_de_producto.codigo)
                }

                if(datos[0].tipo.codigo == "Soli_inves_eventos"){
                    setSteps(['Información del General', 'Participantes'])
                    let nombre = datos[0].nombre

                    setNombreDelEvento(nombre)
                    let anio = new Date()
                    anio.setFullYear(datos[0].anio)
                    setAnio(anio)
                    setNombreDelAmbito(datos[0].ambito.id)
                    console.log(datos[0].ambito.id);
                    setAdj_evento(datos[0] && datos[0].adj_evento ? datos[0].adj_evento.split('/').pop() : '');
                    // setAdj_evento(datos[0].adj_evento.split('/').pop())
                    let fechaInicio = new Date()
                    let fechaFin = new Date()
                    fechaInicio.setFullYear(moment(datos[0].fecha_de_inicio).format('YYYY'), moment(datos[0].fecha_de_inicio).format('MM')-1, moment(datos[0].fecha_de_inicio).format('DD'))
                    fechaFin.setFullYear(moment(datos[0].fecha_de_fin).format('YYYY'), moment(datos[0].fecha_de_fin).format('MM')-1, moment(datos[0].fecha_de_fin).format('DD'))
                    setFechaInicioEvento(fechaInicio)
                    setFechaFinEvento(fechaFin)
                    setData(datos[0])
                    obtenerDepartamentos(datos[0])
                    obtenerAmbitoModalidad()
                    setModalidadParticipacionActive(true)
                    console.log(datos);
                }else if(datos[0].tipo.codigo == "Semi_inves_proyectos"){
                    setSteps(['Información del General', 'Autores'])
                    obtenerProfesores()
                    let nombre = datos[0].nombre
                    let descripcion = datos[0].descripcion_proyecto
                    let asignatura = datos[0].asignatura_asociada
                    setNombreProyecto(nombre)
                    setDescripcionProyecto(descripcion)
                    setAsignaturaProyecto(asignatura)
                    // setNombreprofesorProyecto(datos[0].profesor.id)
                    // setNombreprofesorProyecto(datos[0] ? datos[0].profesor.id : '')
                    setNombreprofesorProyecto(datos?.[0]?.profesor?.id ?? '');
                    let fechaInicio = new Date()
                    let fechaFin = new Date()
                    fechaInicio.setFullYear(moment(datos[0].fecha_de_inicio).format('YYYY'), moment(datos[0].fecha_de_inicio).format('MM')-1, moment(datos[0].fecha_de_inicio).format('DD'))
                    fechaFin.setFullYear(moment(datos[0].fecha_de_fin).format('YYYY'), moment(datos[0].fecha_de_fin).format('MM')-1, moment(datos[0].fecha_de_fin).format('DD'))
                    setFechaInicioProyecto(fechaInicio)
                    setFechaFinProyecto(fechaFin)
                    setData(datos[0])
                    setCargandoModal(false)
                }else if(datos[0].tipo.codigo == "Semi_inves_bibliografica"){
                    setSteps(['Información del General', 'Participantes'])
                    
                    if(datos[0].tipo_de_producto.codigo == "Inves_tipo_biblio_libro"){
                        let datosLibro = await getInfoRowLibro(datos[0].tipo_de_producto.codigo, datos[0].id);
                        console.log(datosLibro)
                        if(datosLibro[0].tipo_libro.codigo == "Inves_tipo_libro_otro"){
                            setTipoLibroSelected(datosLibro[0].tipo_libro.codigo)//Validar si es OTRO
                            setTipoLibroOtro(datosLibro[0].tipo_libro_otro)
                        }else{
                            setTipoLibroSelected(datosLibro[0].tipo_libro.codigo)
                        }
                        setTituloLibro(datosLibro[0].nombre)
                        setIsbnLibro(datosLibro[0].isbn)
                        setEditorialLibro(datosLibro[0].editorial)
                        let anio = new Date()

                        anio.setFullYear(moment(datosLibro[0].anio).format('YYYY'))
                        setAnioPublicacionLibro(anio)
                        setPaisTipoLibroSelected(datosLibro[0].pais.id)
                        setIdiomaLibroSelected(datosLibro[0].idioma.id)
                        setVolumenLibro(datosLibro[0].volumen_libro)
                        setNumeroEdicionLibro(datosLibro[0].no_edicion)
                        obtenerTiposDeLibros()
                        obtenerPaises()
                        obtenerIdiomas()
                    }else if(datos[0].tipo_de_producto.codigo == "Inves_tipo_biblio_capituloLib"){
                        let datosCapLibro = await getInfoRowLibro(datos[0].tipo_de_producto.codigo, datos[0].id);
                        if(datosCapLibro[0].tipo_libro.codigo == "Inves_tipo_capLibro_otro"){
                            setTipoLibroSelectedCap(datosCapLibro[0].tipo_libro.codigo)
                            setTipoLibroOtroCap(datosCapLibro[0].tipo_libro_otro)
                        }else{
                            setTipoLibroSelectedCap(datosCapLibro[0].tipo_libro.codigo)
                        }
                        setTituloCapLibro(datosCapLibro[0].nombre)
                        setTituloLibroCap(datosCapLibro[0].titulo_del_libro)
                        setIsbnLibroCap(datosCapLibro[0].isbn)
                        setEditorialLibroCap(datosCapLibro[0].editorial)
                        let anio = new Date()
                        anio.setFullYear(moment(datosCapLibro[0].anio).format('YYYY'))
                        setAnioPublicacionLibroCap(anio)
                        setPaisCapTipoLibroSelected(datosCapLibro[0].pais.id)
                        setIdiomaCapituloLibroSelected(datosCapLibro[0].idioma.id)
                        setVolumenLibroCap(datosCapLibro[0].volumen_libro)
                        setNumeroEdicionLibroCap(datosCapLibro[0].no_edicion)
                        obtenerTiposDeCapitulosLibros()
                        obtenerPaises()
                        obtenerIdiomas()
                    }else if(datos[0].tipo_de_producto.codigo == "Inves_tipo_biblio_articulo"){
                        let articulo = await getInfoRowLibro(datos[0].tipo_de_producto.codigo, datos[0].id);

                        setIndexacionSelected(articulo[0].indexacion.codigo)
                        if(articulo[0].tipo_de_articulo.codigo == "Inves_tipo_articulo_otro"){
                            setTipoArticuloSelected(articulo[0].tipo_de_articulo.codigo)
                            setTipoArticuloOtro(articulo[0].tipo_de_articulo_otro)
                        }else{
                            setTipoArticuloSelected(articulo[0].tipo_de_articulo.codigo)
                        }
                        setTituloArticulo(articulo[0].nombre)
                        setIssn(articulo[0].issn)
                        setNombreRevista(articulo[0].nombre_de_revista)
                        let anio = new Date()
                        anio.setFullYear(moment(articulo[0].anio).format('YYYY'))
                        setAnioPublicacion(anio)
                        setNumPagTotales(articulo[0].numero_de_paginas)
                        setVolumenRevista(articulo[0].volumen_de_revista)
                        setNumeroRevista(articulo[0].no_revista)
                        setPaisTipoArticuloSelected(articulo[0].pais.id)
                        setIdiomaTipoArticuloSelected(articulo[0].idioma.id)
                        setEnlace(articulo[0].enlace)
                        setDoi(articulo[0].doi)
                        obtenerIndexacion()
                        obtenerTiposDeArticulo()
                        obtenerPaises()
                        obtenerIdiomas()
                    }else if(datos[0].tipo_de_producto.codigo == "Inves_tipo_biblio_patente"){
                        let patente = await getInfoRowLibro(datos[0].tipo_de_producto.codigo, datos[0].id);
                        if(patente[0].tipo_patente.codigo == "Inves_tipo_patente_otro"){
                            setTipoPatenteSelected(patente[0].tipo_patente.codigo)
                            setTipoPatenteOtro(patente[0].tipo_patente_otro)
                        }else{
                            setTipoPatenteSelected(patente[0].tipo_patente.codigo)
                        }
                        let anio = new Date()
                        anio.setFullYear(moment(patente[0].anio).format('YYYY'))
                        setAnioObtencionPatente(anio)
                        setNumeroRegistroPatente(patente[0].numero_patente)
                        setTituloPatente(patente[0].nombre)
                        setNumeroPatente(patente[0].numero_patente)
                        setPaisPatenteSelected(patente[0].pais.id)
                        setGaceteIndustrialPatente(patente[0].gaceta_industrial)
                        setInstitucionPatente(patente[0].institucion)
                        obtenerTiposDePatentes()
                        obtenerPaisesPatente()
                    }
                }
                
            }
            obtenerProcesos()
        }
    })

    const getInfoRow = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listaGestionSemillero?id=${props.data.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const getInfoRowLibro = async (codigo, id) => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listGestionSemilleroBibliografiaSimple?tipo__codigo=${codigo}&id=${id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const getInfoRowUsers = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listaGestionSemilleroAutores?gestion_investigacion=${props.data.id}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const obtenerPaises= async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.PAISES)
            setPaisesTipoArticulo(data)
        }catch(err){
          console.log(err)
        } 
    }

    const obtenerPaisesPatente = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.PAISES)
            setPaisesTipoArticulo(data)
            setCargandoModal(false)
        }catch(err){
          console.log(err)
        } 
    }

    const obtenerIdiomas = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.IDIOMAS)
            setIdiomasTipoArticulo(data)
            setIdiomasLibro(data)
            setIdiomasCapituloLibro(data)
            setCargandoModal(false)
        }catch(err){
          console.log(err)
        }
    }

    const obtenerIndexacion = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.INDEXACION_INVESTIGACION)
            setIndexaciones(data)
            
        }catch(err){
          console.log(err)
        } 
    }

    const obtenerTiposDeArticulo = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPO_DE_ARTICULO_INVESTIGACION)
            setTipoArticulo(data)
        }catch(err){
          console.log(err)
        } 
    }

    const obtenerAmbitoModalidad = async () => {
        try{
            
          const data = await Funciones.obtenerValores(Helper.AMBITO_INVESTIGACION)
          const dataModalidad = await Funciones.obtenerValores(Helper.MODALIDAD_PAR_INVESTIGACION)
          setNombreAmbitos(data)
          setNombreModalidadPars(dataModalidad)
          setCargandoModal(false)
        }catch(err){
          console.log(err)
        } 
        
    }
    const obtenerliderSemillero = async () => {
            return new Promise(resolve => {
                Funciones.consulta(`api/v1.0/investigacion/listalideres/${props.data.semillero.id}`, null, null, (error, estado, resp) => {
                  resolve(estado === 200 && !error ? resp : null);
                })
            })
    }
    const obtenerProfesores = async () =>{
        let data = await obtenerliderSemillero()
        let profesore= data.lideres.filter(l=>l.rol.codigo != 'Semi_rol1')
        setProfesorProyecto(profesore)
    }


    const obtenerTiposDeLibros = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPO_DE_LIBRO_INVESTIGACION)
            setTiposLibros(data)
        }catch(err){
          console.log(err)
        } 
    }

    const obtenerTiposDeCapitulosLibros = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPO_DE_CAPITULO_LIBRO_INVESTIGACION)
            setTiposLibrosCap(data)
        }catch(err){
          console.log(err)
        } 
    }

    const obtenerTiposDePatentes = async () => {
        try{
            let data = await Funciones.obtenerValores(Helper.TIPOS_DE_PATENTE_INVESTIGACION)
            setTipoPatente(data)
        }catch(err){
          console.log(err)
        } 
    }

    const obtenerDepartamentos = async (datos = {}) =>{
        let departamentos = await obtenerValores(7);
        const transformar = data => {
            return data.map(e => ({
              value: e.id,
              label: e.nombre,
              codigo: e.codigo,
            }));
        }

        let departamentosTransformados = transformar(departamentos)
        let departamentoEncontrado = {}

        setDepartamentos(departamentosTransformados)
        departamentosTransformados.map((e) => {
            if(e.value == datos.departamento.id){
                departamentoEncontrado = e
                setDepartamento(e)
            }
        })

        const obtenerCiudades = async (value) =>{

            if(ciudad != ''){
                setCiudad('')
            }
            let ciudades = await obtenerPermisosValor(value.value);
            ciudades = ciudades.map(({ secundario }) => ({
                value: secundario.id,
                label: secundario.nombre,
              }));
            setCiudades(ciudades)
            ciudades.map((e) => {
                if(e.value == datos.ciudad.id){
                    setCiudad(e)
                }
            })
        }

        await obtenerCiudades(departamentoEncontrado)
    }

    const getError = (llave, errores) => {
        let existe = errores.find(e => e.llave === llave);
        return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
        return new Promise((resolve) => {
        let errores = [];
        let date_now = new Date()

        function isObjEmpty(obj) {

            if(typeof obj === 'object'){
                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop)) return false;
                  }

                  return true;
            }else if(typeof obj === 'string'){
                return true
            }else{
                return false;
            }
            
        }

        campos.map(({ value, name, err, value2 }) => {
            
            if (err.numeric == false && !err.obj && err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
            if (err.numeric == false && !err.obj && err.empty == false && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            //Validacion exclusiva para buscar Select.            
            if (err.numeric == false && err.empty && isObjEmpty(value) && err.obj){
                errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
            } 
            console.log(isNaN(value))
            if (err.numeric == true && isNaN(value)){
                
                errores.push({ llave: name, 'mensaje': `Este campo debe estar lleno` });
            } 
            /*if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
            if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
            if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
            if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
            if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
            if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
            if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
        });
        
        resolve(errores);
        });
    }

    const handleNext = async () => {
        if(tipo == "Soli_inves_eventos"){
            if(activeStepBiblio == 0){

                let arrai = await validateInputs([
                    { value: nombreDelEvento, name: 'nombreDelEvento', err: { empty: true, obj: false, numeric: false } },
                    { value: departamento, name: 'departamento', err: { empty: true, obj: true, numeric: false } },
                    { value: ciudad, name: 'ciudad', err: { empty: true, obj: true, numeric: false } },
                    { value: nombreDelAmbito, name: 'nombreDelAmbito', err: { empty: true, obj: true, numeric: false } },
                ])

                setErrores(arrai)

                let fecha_inicio = moment(fechaInicioEvento).format('YYYY-MM-DD');
                let fecha_fin = moment(fechaFinEvento).format('YYYY-MM-DD');
                let aniio = moment(anio).format('YYYY')
                let fecha_inicio_1 = moment(fechaInicioEvento).format('YYYY');
                let fecha_fin_1 = moment(fechaFinEvento).format('YYYY');

                if(aniio != fecha_inicio_1 || aniio != fecha_fin_1){
                    let tipo = "info"
                    let titulo = "El año no coincide con la fecha de inicio o fin."
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                }else if(fecha_inicio > fecha_fin){
                    let tipo = "info"
                    let titulo = "La Fecha Inicio es mayor que la Fecha Fin"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                }else if(arrai.length > 0){
                    
                }else {
                    let adj_evento1 = document.getElementById('adj_evento').files[0];
                    if (adj_evento1) {
                      setAdj_evento_final(adj_evento1);
                    } else if (adj_evento) {
                      // Usuario elige no actualizar el archivo adjunto
                      setAdj_evento_final(null); // Establecer el valor a null para conservar el archivo adjunto existente
                    }
                    setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                  }
            }
        }else if(tipo == "Semi_inves_proyectos"){
            if(activeStepBiblio == 0){

                let array = await validateInputs([
                    { value: nombreProyecto, name: 'nombreProyecto', err: { empty: true, obj: false, numeric: false } },
                    { value: descripcionProyecto, name: 'descripcionProyecto', err: { empty: true, obj: false } },
                    { value: asignaturaProyecto, name: 'asignaturaProyecto', err: { empty: true, obj: false } },
                    // { value: nombreprofesorProyecto, name: 'nombreprofesorProyecto', err: { empty: true, obj: false } },
                ])

                setErrores(array)

                let fecha_inicio = moment(fechaInicioProyecto).format('YYYY-MM-DD');
                let fecha_fin = moment(fechaFinProyecto).format('YYYY-MM-DD');

                if(fecha_inicio > fecha_fin){
                    let tipo = "info"
                    let titulo = "La Fecha Inicio es mayor que Fecha Fin"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                      );
                }else if(array.length > 0){
                    
                }else{
                    setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                }
            }
        }else if(tipo == "Semi_inves_bibliografica"){
            if(tipoProductoBibliografica == 'Inves_tipo_biblio_articulo'){
                if(activeStepBiblio == 0){

                    let array = await validateInputs([
                        { value: indexacionSelected, name: 'indexacionSelected', err: { empty: true, obj: false, numeric: false } },
                        { value: tipoArticuloSelected, name: 'tipoArticuloSelected', err: { empty: true, obj: false, numeric: false } },
                        { value: tituloArticulo, name: 'tituloArticulo', err: { empty: true, obj: false, numeric: false } },
                        { value: issn, name: 'issn', err: { empty: true, obj: false, numeric: false } },
                        { value: nombreRevista, name: 'nombreRevista', err: { empty: true, obj: false, numeric: false } },
                        { value: numPagTotales, name: 'numPagTotales', err: { empty: false, obj: false, numeric: false } },
                        { value: volumenRevista, name: 'volumenRevista', err: { empty: true, obj: false, numeric: false } },
                        { value: numeroRevista, name: 'numeroRevista', err: { empty: true, obj: false, numeric: false } },
                        { value: paisTipoArticuloSelected, name: 'paisTipoArticuloSelected', err: { empty: false, obj: false, numeric: true } },
                        { value: idiomaTipoArticuloSelected, name: 'idiomaTipoArticuloSelected', err: { empty: false, obj: false, numeric: true } },
                        { value: enlace, name: 'enlace', err: { empty: true, obj: false, numeric: false } },
                        { value: doi, name: 'doi', err: { empty: true, obj: false, numeric: false } },
                    ])
                    setErrores(array)
                    if(array.length > 0){

                    }else if(tipoArticuloSelected == "Inves_tipo_articulo_otro"){

                        let array = await validateInputs([
                            { value: indexacionSelected, name: 'indexacionSelected', err: { empty: true, obj: false } },
                            { value: tipoArticuloSelected, name: 'tipoArticuloSelected', err: { empty: true, obj: false, numeric: false } },
                            { value: tipoArticuloOtro, name: 'tipoArticuloOtro', err: { empty: true, obj: false, numeric: false } },
                            { value: tituloArticulo, name: 'tituloArticulo', err: { empty: true, obj: false, numeric: false } },
                            { value: issn, name: 'issn', err: { empty: true, obj: false, numeric: false } },
                            { value: nombreRevista, name: 'nombreRevista', err: { empty: true, obj: false, numeric: false } },
                            { value: numPagTotales, name: 'numPagTotales', err: { empty: false, obj: false, numeric: false } },
                            { value: volumenRevista, name: 'volumenRevista', err: { empty: true, obj: false, numeric: false } },
                            { value: numeroRevista, name: 'numeroRevista', err: { empty: true, obj: false, numeric: false } },
                            { value: paisTipoArticuloSelected, name: 'paisTipoArticuloSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: idiomaTipoArticuloSelected, name: 'idiomaTipoArticuloSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: enlace, name: 'enlace', err: { empty: true, obj: false, numeric: false } },
                            { value: doi, name: 'doi', err: { empty: true, obj: false, numeric: false } },
                        ])

                        setErrores(array)

                        if(array.length > 0){

                        }else{
                            
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }else{
                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }else if(tipoProductoBibliografica == 'Inves_tipo_biblio_libro'){
                if(activeStepBiblio == 0){
                    let array = await validateInputs([
                        { value: tipoLibroSelected, name: 'tipoLibroSelected', err: { empty: true, obj: false, numeric: false } },
                        { value: tituloLibro, name: 'tituloLibro', err: { empty: true, obj: false, numeric: false } },
                        { value: isbnLibro, name: 'isbnLibro', err: { empty: true, obj: false, numeric: false } },
                        { value: editorialLibro, name: 'editorialLibro', err: { empty: true, obj: false, numeric: false } },
                        { value: paisTipoLibroSelected, name: 'paisTipoLibroSelected', err: { empty: false, obj: false, numeric: true } },
                        { value: idiomaLibroSelected, name: 'idiomaLibroSelected', err: { empty: false, obj: false, numeric: true } },
                        { value: volumenLibro, name: 'volumenLibro', err: { empty: true, obj: false, numeric: false } },
                        { value: numeroEdicionLibro, name: 'numeroEdicionLibro', err: { empty: true, obj: false, numeric: false } },
                    ])
                    setErrores(array)
                    if(array.length > 0){

                    }else if(tipoLibroSelected == 'Inves_tipo_libro_otro'){

                        let array = await validateInputs([
                            { value: tipoLibroSelected, name: 'tipoLibroSelected', err: { empty: true, obj: false, numeric: false } },
                            { value: tipoLibroOtro, name: 'tipoLibroOtro', err: { empty: true, obj: false, numeric: false } },
                            { value: tituloLibro, name: 'tituloLibro', err: { empty: true, obj: false, numeric: false } },
                            { value: isbnLibro, name: 'isbnLibro', err: { empty: true, obj: false, numeric: false } },
                            { value: editorialLibro, name: 'editorialLibro', err: { empty: true, obj: false, numeric: false } },
                            { value: paisTipoLibroSelected, name: 'paisTipoLibroSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: idiomaLibroSelected, name: 'idiomaLibroSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: volumenLibro, name: 'volumenLibro', err: { empty: true, obj: false, numeric: false } },
                            { value: numeroEdicionLibro, name: 'numeroEdicionLibro', err: { empty: true, obj: false, numeric: false } },
                        ])
                        setErrores(array)

                        if(array.length > 0){

                        }else{
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }

                    }else{
                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }else if(tipoProductoBibliografica == 'Inves_tipo_biblio_capituloLib'){
                if(activeStepBiblio == 0){

                    let array = await validateInputs([
                        { value: tipoLibroSelectedCap, name: 'tipoLibroSelectedCap', err: { empty: true, obj: false, numeric: false } },
                        { value: tituloCapLibro, name: 'tituloCapLibro', err: { empty: true, obj: false, numeric: false } },
                        { value: tituloLibroCap, name: 'tituloLibroCap', err: { empty: true, obj: false, numeric: false } },
                        { value: isbnLibroCap, name: 'isbnLibroCap', err: { empty: true, obj: false, numeric: false } },
                        { value: editorialLibroCap, name: 'editorialLibroCap', err: { empty: false, obj: false, numeric: false } },
                        { value: paisTipoCapLibroSelected, name: 'paisTipoCapLibroSelected', err: { empty: false, obj: false, numeric: true } },
                        { value: idiomaCapituloLibroSelected, name: 'idiomaCapituloLibroSelected', err: { empty: false, obj: false, numeric: true } },
                        { value: volumenLibroCap, name: 'volumenLibroCap', err: { empty: true, obj: false, numeric: false } },
                        { value: numeroEdicionLibroCap, name: 'numeroEdicionLibroCap', err: { empty: true, obj: false, numeric: false } },
                    ])
                    setErrores(array)
                    if(array.length > 0){

                    }else if(tipoLibroSelectedCap == 'Inves_tipo_capLibro_otro'){
                        let array = await validateInputs([
                            { value: tipoLibroSelectedCap, name: 'tipoLibroSelectedCap', err: { empty: true, obj: false, numeric: false } },
                            { value: tipoLibroOtroCap, name: 'tipoLibroOtroCap', err: { empty: true, obj: false, numeric: false } },
                            { value: tituloCapLibro, name: 'tituloCapLibro', err: { empty: true, obj: false, numeric: false } },
                            { value: tituloLibroCap, name: 'tituloLibroCap', err: { empty: true, obj: false, numeric: false } },
                            { value: isbnLibroCap, name: 'isbnLibroCap', err: { empty: true, obj: false, numeric: false } },
                            { value: editorialLibroCap, name: 'editorialLibroCap', err: { empty: false, obj: false, numeric: false } },
                            { value: paisTipoCapLibroSelected, name: 'paisTipoCapLibroSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: idiomaCapituloLibroSelected, name: 'idiomaCapituloLibroSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: volumenLibroCap, name: 'volumenLibroCap', err: { empty: true, obj: false, numeric: false } },
                            { value: numeroEdicionLibroCap, name: 'numeroEdicionLibroCap', err: { empty: true, obj: false, numeric: false } },
                        ])
                        setErrores(array)
                        if(array.length > 0){

                        }else{
                            setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                        }
                    }else{
                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }else if(tipoProductoBibliografica == 'Inves_tipo_biblio_patente'){
                if(activeStepBiblio == 0){
                    let arrai 
                    if(tipoPatenteSelected == "Inves_tipo_patente_otro"){
                        arrai = await validateInputs([
                            { value: tipoPatenteSelected, name: 'tipoPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: tipoPatenteOtro, name: 'tipoPatenteOtro', err: { empty: true, obj: false, numeric: false } },
                            { value: tituloPatente, name: 'tituloPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: numeroPatente, name: 'numeroPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: paisPatenteSelected, name: 'paisPatenteSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: gacetaIndustrialPatente, name: 'gacetaIndustrialPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: institucionPatente, name: 'institucionPatente', err: { empty: true, obj: false, numeric: false } },
                        ])
                    }else{
                        arrai = await validateInputs([
                            { value: tipoPatenteSelected, name: 'tipoPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: tituloPatente, name: 'tituloPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: numeroPatente, name: 'numeroPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: paisPatenteSelected, name: 'paisPatenteSelected', err: { empty: false, obj: false, numeric: true } },
                            { value: gacetaIndustrialPatente, name: 'gacetaIndustrialPatente', err: { empty: true, obj: false, numeric: false } },
                            { value: institucionPatente, name: 'institucionPatente', err: { empty: true, obj: false, numeric: false } },
                        ])
                    }
                    

                    setErrores(arrai)
                    
                    if(arrai.length > 0){

                    }else{

                        setActiveStepBiblio((prevActiveStep) => prevActiveStep + 1);
                    }
                }
            }
        }
    }

    //Funcion para hacer un reset en el Step
    const handleReset = () => {
        setActiveStepBiblio(0);
    };

    //Funcion para hacer un back en el Step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Funcion para hacer un back en el Step de Bibliografia
    const handleBackTipoBiblio = () => {
        setActiveStepBiblio((prevActiveStep) => prevActiveStep - 1);
    };

    const handleOnClose = () =>{
        setActiveStep(0);
        setActiveStepBiblio(0);
        props.setOpenModal(false)
    }

    const vista = () => {
        switch(tipo){
            case 'Soli_inves_eventos':
                return(
                    <ModalStepperFormEventos 
                        //Stepper
                        hola={activeStepBiblio}
                        handleReset={handleReset}
                        steps={steps}
                
                        cargandoModal={cargandoModal}
                        setCargandoModal={setCargandoModal}
                        //Informacion de los inputs
                        datos={data}

                        //Variables Eventos
                        nombreDelEvento={nombreDelEvento}
                        setNombreDelEvento={setNombreDelEvento}

                        anio={anio}
                        setAnio={setAnio}

                        departamentos={departamentos}
                        setDepartamentos={setDepartamentos}

                        departamento={departamento}
                        setDepartamento={setDepartamento}

                        ciudades={ciudades}
                        setCiudades={setCiudades}

                        ciudad={ciudad}
                        setCiudad={setCiudad}

                        nombreDelAmbito={nombreDelAmbito}
                        setNombreDelAmbito={setNombreDelAmbito}

                        nombreAmbitos={nombreAmbitos}
                        setNombreAmbitos={setNombreAmbitos}

                        nombreModalidadPar={nombreModalidadPar}
                        setNombreModalidadPar={setNombreModalidadPar}

                        nombreModalidadPars={nombreModalidadPars}
                        setNombreModalidadPars={setNombreModalidadPars}

                        fechaInicioEvento={fechaInicioEvento}
                        setFechaInicioEvento={setFechaInicioEvento}

                        fechaFinEvento={fechaFinEvento}
                        setFechaFinEvento={setFechaFinEvento}

                        adj_evento={adj_evento}
                        setAdj_evento={setAdj_evento}

                        //Usuarios
                        usuarios={usuarios}
                        setUsuarios={setUsuarios}

                        arrai={arrai}
                        setArrai={setArrai}

                        usuariosEliminados = {usuariosEliminados}
                        setUsuariosEliminados = {setUsuariosEliminados}

                        modalidadParticipacionActive={modalidadParticipacionActive}
                        setModalidadParticipacionActive={setModalidadParticipacionActive}
                        //Errores
                        getError={getError}
                        errores={errores}
                        setErrores={setErrores}
                    />
                )
            case 'Semi_inves_proyectos':
                return(
                    <ModalStepperFormEventos 
                        //Stepper
                        hola={activeStepBiblio}
                        handleReset={handleReset}
                        steps={steps}
                
                        cargandoModal={cargandoModal}
                        setCargandoModal={setCargandoModal}
                        //Informacion de los inputs
                        datos={data}

                        //Variables Eventos
                        nombreDelEvento={nombreDelEvento}
                        setNombreDelEvento={setNombreDelEvento}

                        anio={anio}
                        setAnio={setAnio}

                        departamentos={departamentos}
                        setDepartamentos={setDepartamentos}

                        departamento={departamento}
                        setDepartamento={setDepartamento}

                        ciudades={ciudades}
                        setCiudades={setCiudades}

                        ciudad={ciudad}
                        setCiudad={setCiudad}

                        nombreDelAmbito={nombreDelAmbito}
                        setNombreDelAmbito={setNombreDelAmbito}

                        nombreAmbitos={nombreAmbitos}
                        setNombreAmbitos={setNombreAmbitos}

                        nombreModalidadPar={nombreModalidadPar}
                        setNombreModalidadPar={setNombreModalidadPar}

                        nombreModalidadPars={nombreModalidadPars}
                        setNombreModalidadPars={setNombreModalidadPars}

                        fechaInicioEvento={fechaInicioEvento}
                        setFechaInicioEvento={setFechaInicioEvento}

                        fechaFinEvento={fechaFinEvento}
                        setFechaFinEvento={setFechaFinEvento}
                        //Proyectos
                        fechaInicioProyecto={fechaInicioProyecto}
                        setFechaInicioProyecto = {setFechaInicioProyecto}
                        fechaFinProyecto={fechaFinProyecto}
                        setFechaFinProyecto={setFechaFinProyecto}
                        nombreProyecto={nombreProyecto}
                        setNombreProyecto={setNombreProyecto}

                        nombreprofesorProyecto={nombreprofesorProyecto}
                        setNombreprofesorProyecto={setNombreprofesorProyecto}

                        profesorProyecto={profesorProyecto}
                        setProfesorProyecto={setProfesorProyecto}

                        descripcionProyecto={descripcionProyecto}
                        setDescripcionProyecto={setDescripcionProyecto}

                        asignaturaProyecto={asignaturaProyecto}
                        setAsignaturaProyecto={setAsignaturaProyecto}
                        //Usuarios
                        usuarios={usuarios}
                        setUsuarios={setUsuarios}

                        arrai={arrai}
                        setArrai={setArrai}

                        usuariosEliminados = {usuariosEliminados}
                        setUsuariosEliminados = {setUsuariosEliminados}

                        //Errores
                        getError={getError}
                        errores={errores}
                        setErrores={setErrores}
                    />
                )
            case "Semi_inves_bibliografica":
                return(
                    <ModalStepperFormBibliografia 
                        datos = {tipoProductoBibliografica}

                        //Stepper
                        hola={activeStepBiblio}
                        handleReset={handleReset}
                        steps={steps}

                        cargandoModal={cargandoModal}
                        setCargandoModal={setCargandoModal}

                        //Variables de articulo
                        indexacionSelected = {indexacionSelected}
                        setIndexacionSelected = {setIndexacionSelected}

                        tipoArticuloSelected={tipoArticuloSelected}
                        setTipoArticuloSelected={setTipoArticuloSelected}

                        tipoArticuloOtro={tipoArticuloOtro}
                        setTipoArticuloOtro={setTipoArticuloOtro}

                        tituloArticulo={tituloArticulo}
                        setTituloArticulo={setTituloArticulo}

                        issn={issn}
                        setIssn={setIssn}

                        nombreRevista={nombreRevista}
                        setNombreRevista={setNombreRevista}

                        anioPublicacion={anioPublicacion}
                        setAnioPublicacion={setAnioPublicacion}

                        numPagTotales={numPagTotales}
                        setNumPagTotales={setNumPagTotales}

                        volumenRevista={volumenRevista}
                        setVolumenRevista={setVolumenRevista}

                        numeroRevista={numeroRevista}
                        setNumeroRevista={setNumeroRevista}
                        
                        paisPublicacion={paisPublicacion}
                        setPaisPublicacion={setPaisPublicacion}

                        idioma={idioma}
                        setIdioma={setIdioma}

                        enlace={enlace}
                        setEnlace={setEnlace}

                        doi={doi}
                        setDoi={setDoi}

                        indexaciones={indexaciones}
                        setIndexaciones={setIndexaciones}

                        tipoArticulo={tipoArticulo}
                        setTipoArticulo={setTipoArticulo}

                        paisesTipoArticulo={paisesTipoArticulo}
                        setPaisesTipoArticulo={setPaisesTipoArticulo}
                        paisTipoArticuloSelected={paisTipoArticuloSelected}
                        setPaisTipoArticuloSelected={setPaisTipoArticuloSelected}
                        
                        paisTipoLibroSelected={paisTipoLibroSelected}
                        setPaisTipoLibroSelected={setPaisTipoLibroSelected}

                        paisTipoCapLibroSelected={paisTipoCapLibroSelected}
                        setPaisCapTipoLibroSelected={setPaisCapTipoLibroSelected}
                        
                        paisPatenteSelected={paisPatenteSelected}
                        setPaisPatenteSelected={setPaisPatenteSelected}

                        idiomasTipoArticulo={idiomasTipoArticulo}
                        setIdiomasTipoArticulo={setIdiomasTipoArticulo}
    
                        idiomaTipoArticuloSelected={idiomaTipoArticuloSelected}
                        setIdiomaTipoArticuloSelected={setIdiomaTipoArticuloSelected}
                        
                        //Variables de Libros
                        idiomasLibro={idiomasLibro}
                        setIdiomasLibro={setIdiomasLibro}

                        idiomaLibroSelected={idiomaLibroSelected}
                        setIdiomaLibroSelected={setIdiomaLibroSelected}

                        tipoLibroSelected={tipoLibroSelected}
                        setTipoLibroSelected={setTipoLibroSelected}

                        tipoLibroOtro={tipoLibroOtro}
                        setTipoLibroOtro={setTipoLibroOtro}

                        tituloLibro={tituloLibro}
                        setTituloLibro={setTituloLibro}

                        isbnLibro={isbnLibro}
                        setIsbnLibro={setIsbnLibro}

                        editorialLibro={editorialLibro}
                        setEditorialLibro={setEditorialLibro}

                        anioPublicacionLibro={anioPublicacionLibro}
                        setAnioPublicacionLibro={setAnioPublicacionLibro}

                        paisPublicacionLibro={paisPublicacionLibro}
                        setPaisPublicacionLibro={setPaisPublicacionLibro}

                        idiomaLibro={idiomaLibro}
                        setIdiomaLibro={setIdiomaLibro}

                        volumenLibro={volumenLibro}
                        setVolumenLibro={setVolumenLibro}

                        numeroEdicionLibro={numeroEdicionLibro}
                        setNumeroEdicionLibro={setNumeroEdicionLibro}

                        tiposLibros={tiposLibros}
                        setTiposLibros={setTiposLibros}

                        //Variables de Capitulo de libro
                        idiomasCapituloLibro={idiomasCapituloLibro}
                        setIdiomasCapituloLibro={setIdiomasCapituloLibro}

                        idiomaCapituloLibroSelected={idiomaCapituloLibroSelected}
                        setIdiomaCapituloLibroSelected={setIdiomaCapituloLibroSelected}

                        tipoLibroSelectedCap={tipoLibroSelectedCap}
                        setTipoLibroSelectedCap={setTipoLibroSelectedCap}

                        tipoLibroOtroCap={tipoLibroOtroCap}
                        setTipoLibroOtroCap={setTipoLibroOtroCap}

                        tituloCapLibro={tituloCapLibro}
                        setTituloCapLibro={setTituloCapLibro}

                        tituloLibroCap={tituloLibroCap}
                        setTituloLibroCap={setTituloLibroCap}

                        isbnLibroCap={isbnLibroCap}
                        setIsbnLibroCap={setIsbnLibroCap}

                        editorialLibroCap={editorialLibroCap}
                        setEditorialLibroCap={setEditorialLibroCap}

                        anioPublicacionLibroCap={anioPublicacionLibroCap}
                        setAnioPublicacionLibroCap={setAnioPublicacionLibroCap}

                        paisPublicacionLibroCap={paisPublicacionLibroCap}
                        setPaisPublicacionLibroCap={setPaisPublicacionLibroCap}

                        idiomaLibroCap={idiomaLibroCap}
                        setIdiomaLibroCap={setIdiomaLibroCap}

                        volumenLibroCap={volumenLibroCap}
                        setVolumenLibroCap={setVolumenLibroCap}

                        numeroEdicionLibroCap={numeroEdicionLibroCap}
                        setNumeroEdicionLibroCap={setNumeroEdicionLibroCap}

                        tiposLibrosCap={tiposLibrosCap}
                        setTiposLibrosCap={setTiposLibrosCap}

                        //Patente
                        tipoPatente={tipoPatente}
                        setTipoPatente={setTipoPatente}

                        tipoPatenteSelected={tipoPatenteSelected}
                        setTipoPatenteSelected={setTipoPatenteSelected}

                        anioObtencionPatente={anioObtencionPatente}
                        setAnioObtencionPatente={setAnioObtencionPatente}

                        numeroRegistroPatente={numeroRegistroPatente}
                        setNumeroRegistroPatente={setNumeroRegistroPatente}

                        tituloPatente={tituloPatente}
                        setTituloPatente={setTituloPatente}

                        numeroPatente={numeroPatente}
                        setNumeroPatente={setNumeroPatente}

                        paisObtencionPatente={paisObtencionPatente}
                        setPaisObtencionPatente={setPaisObtencionPatente}

                        gacetaIndustrialPatente={gacetaIndustrialPatente}
                        setGaceteIndustrialPatente={setGaceteIndustrialPatente}

                        institucionPatente={institucionPatente}
                        setInstitucionPatente={setInstitucionPatente}

                        tipoPatenteOtro={tipoPatenteOtro}
                        setTipoPatenteOtro={setTipoPatenteOtro}

                        //Usuarios
                        usuarios={usuarios}
                        setUsuarios={setUsuarios}

                        arrai={arrai}
                        setArrai={setArrai}

                        usuariosEliminados = {usuariosEliminados}
                        setUsuariosEliminados = {setUsuariosEliminados}

                        //Errores
                        getError={getError}
                        errores={errores}
                        setErrores={setErrores}
                    />
                )
        }
    }

    const handleSendToApi = async () =>{

        if(tipo == 'Soli_inves_eventos'){
            let fecha_inicio = moment(fechaInicioEvento).format('YYYY-MM-DD');
            let fecha_fin = moment(fechaFinEvento).format('YYYY-MM-DD');
            let aniio = moment(anio).format('YYYY')
            let integrantes = usuarios.length
            let eliminados = usuariosEliminados.length
            let data;
            console.log(adj_evento_final);
            data = await Funciones.crear_form_data({ 
                id: props.data.id,
                nombre:nombreDelEvento,
                anio: aniio,
                departamento:departamento.value,
                ciudad: ciudad.value,
                ambito:nombreDelAmbito,
                fecha_inicio_evento: fecha_inicio,
                fecha_fin_evento: fecha_fin,
                autores: integrantes,
                autoresEliminados: eliminados,
                tipo: tipo,
                adj_evento:adj_evento_final
            })
            usuarios.map(({id,modalidadParticipacion:{codigo}},index)=>{
                data.append(`id${index}`,id)
                data.append(`codigo${index}`,codigo)
            })
            usuariosEliminados.map(({id,modalidadParticipacion:{codigo}},index)=>{
                data.append(`id${index}`,id)
                data.append(`codigo${index}`,codigo)
            })
            if(usuarios.length == 0){
                let tipo = "info"
                let titulo = "Inserte por lo menos 1 Participante"
                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
            }else{
                setCargandoModal(true)
                let url = "api/v1.0/investigacion/EditGestionSemilleros"
                const callback = (error, estado, resp) => {
                    let titulo = "Ha ocurrido un error, contacte con el administrador."
                    let tipo = "error"

                    if(!error){
                        if(estado === 201){
                            tipo = "success"
                            titulo = resp.titulo
                        }
                    }

                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoModal(false)
                    handleOnClose()
                }
                const msg = await Funciones.formulario(url, data, 'POST', callback)
            }
        
        }else if (tipo == 'Semi_inves_proyectos'){
            
            let fecha_inicio = moment(fechaInicioProyecto).format('YYYY-MM-DD');
            let fecha_fin = moment(fechaFinProyecto).format('YYYY-MM-DD');
            let data;
            data = {
                id: props.data.id,
                nombre:nombreProyecto,
                descripcionProyecto:descripcionProyecto,
                asignaturaProyecto:asignaturaProyecto,
                profesor:nombreprofesorProyecto,
                fecha_inicio_proyecto: fecha_inicio,
                fecha_fin_proyecto: fecha_fin,
                autores: usuarios,
                autoresEliminados: usuariosEliminados,
                tipo: tipo,
            }
            if(usuarios.length == 0){
                let tipo = "info"
                let titulo = "Inserte por lo menos 1 Participante"
                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
            }else{
                setCargandoModal(true)
                let url = "api/v1.0/investigacion/EditGestionSemilleros"
                const callback = (error, estado, resp) => {
                    let titulo = "Ha ocurrido un error, contacte con el administrador."
                    let tipo = "error"

                    if(!error){
                        if(estado === 201){
                            tipo = "success"
                            titulo = resp.titulo
                        }
                    }

                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                    );
                    setCargandoModal(false)
                    handleOnClose()
                }
                const msg = await Funciones.consulta(url, data, 'POST', callback)
            }

        }else if(tipo == "Semi_inves_bibliografica"){
            if(tipoProductoBibliografica == 'Inves_tipo_biblio_articulo'){
                if(usuarios.length == 0){
                    let tipo = "info"
                    let titulo = "Inserte por lo menos 1 Participante"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                }else{
                    setCargandoModal(true)
                    let data
                    if(tipoArticuloSelected == "Inves_tipo_articulo_otro"){
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            articuloId: props.data.id,
                            tipoArticulo: tipoArticuloSelected,
                            indexacion: indexacionSelected,
                            tipo_de_articulo_otro: tipoArticuloOtro,
                            nombre:tituloArticulo,
                            issn:issn,
                            nombre_de_revista: nombreRevista,
                            anio: moment(anioPublicacion).format('YYYY'),
                            numero_de_paginas:numPagTotales,
                            volumen_de_revista:volumenRevista,
                            no_revista:numeroRevista,
                            pais:paisTipoArticuloSelected,
                            idioma:idiomaTipoArticuloSelected,
                            enlace: enlace,
                            doi: doi,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }else{
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            articuloId: props.data.id,
                            tipoArticulo: tipoArticuloSelected,
                            indexacion: indexacionSelected,
                            nombre:tituloArticulo,
                            issn:issn,
                            nombre_de_revista: nombreRevista,
                            anio: moment(anioPublicacion).format('YYYY'),
                            numero_de_paginas:numPagTotales,
                            volumen_de_revista:volumenRevista,
                            no_revista:numeroRevista,
                            pais:paisTipoArticuloSelected,
                            idioma:idiomaTipoArticuloSelected,
                            enlace: enlace,
                            doi: doi,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }

                    let url = "api/v1.0/investigacion/EditGestionSemillerosBibliografica"
                    const callback = (error, estado, resp) => {
                        let titulo = "Ha ocurrido un error, contacte con el administrador."
                        let tipo = "info"

                        if(!error){
                            if(estado === 201){
                                tipo = "success"
                                titulo = resp.titulo
                            }
                        }
                        
                        if(estado == 201){
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            handleOnClose()
                        }else{
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            setCargandoModal(true)
                        }
                        
                    }
                    const msg = await Funciones.consulta(url, data, 'POST', callback)

                }
            }else if(tipoProductoBibliografica == 'Inves_tipo_biblio_libro'){
                if(usuarios.length == 0){
                    let tipo = "info"
                    let titulo = "Inserte por lo menos 1 Participante"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                }else{
                    setCargandoModal(true)
                    let data
                    if(tipoLibroSelected == "Inves_tipo_libro_otro"){
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            libroId: props.data.id,
                            tipoLibro: tipoLibroSelected,
                            tipo_de_articulo_otro: tipoLibroOtro,
                            nombre:tituloLibro,
                            isbn:isbnLibro,
                            editorial:editorialLibro,
                            anio:moment(anioPublicacionLibro).format('YYYY'),
                            pais:paisTipoLibroSelected,
                            idioma:idiomaLibroSelected,
                            volumen_libro:volumenLibro,
                            no_edicion:numeroEdicionLibro,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }else{
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            libroId: props.data.id,
                            tipoLibro: tipoLibroSelected,
                            nombre:tituloLibro,
                            isbn:isbnLibro,
                            editorial:editorialLibro,
                            anio:moment(anioPublicacionLibro).format('YYYY'),
                            pais:paisTipoLibroSelected,
                            idioma:idiomaLibroSelected,
                            volumen_libro:volumenLibro,
                            no_edicion:numeroEdicionLibro,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }

                    let url = "api/v1.0/investigacion/EditGestionSemillerosBibliografica"
                    const callback = (error, estado, resp) => {
                        let titulo = "Ha ocurrido un error, contacte con el administrador."
                        let tipo = "info"

                        if(!error){
                            if(estado === 201){
                                tipo = "success"
                                titulo = resp.titulo
                            }
                        }
                        
                        if(estado == 201){
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            handleOnClose()
                        }else{
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            setCargandoModal(true)
                        }
                        
                    }

                    const msg = await Funciones.consulta(url, data, 'POST', callback)
                }
            }else if(tipoProductoBibliografica == 'Inves_tipo_biblio_capituloLib'){
                if(usuarios.length == 0){
                    let tipo = "info"
                    let titulo = "Inserte por lo menos 1 Participante"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                }else{
                    setCargandoModal(true)
                    let data

                    if(tipoLibroSelectedCap == "Inves_tipo_capLibro_otro"){
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            capituloLibId: props.data.id,
                            tipoLibro: tipoLibroSelectedCap,
                            tipo_de_capitulo_otro: tipoLibroOtroCap,
                            nombre: tituloCapLibro,
                            titulo_del_libro: tituloLibroCap,
                            isbn: isbnLibroCap,
                            editorial: editorialLibroCap,
                            anio: moment(anioPublicacionLibroCap).format('YYYY'),
                            pais: paisTipoCapLibroSelected,
                            idioma: idiomaCapituloLibroSelected,
                            volumen_libro: volumenLibroCap,
                            no_edicion: numeroEdicionLibroCap,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }else{
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            capituloLibId: props.data.id,
                            tipoLibro: tipoLibroSelectedCap,
                            nombre: tituloCapLibro,
                            titulo_del_libro: tituloLibroCap,
                            isbn: isbnLibroCap,
                            editorial: editorialLibroCap,
                            anio: moment(anioPublicacionLibroCap).format('YYYY'),
                            pais: paisTipoCapLibroSelected,
                            idioma: idiomaCapituloLibroSelected,
                            volumen_libro: volumenLibroCap,
                            no_edicion: numeroEdicionLibroCap,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }

                    let url = "api/v1.0/investigacion/EditGestionSemillerosBibliografica"
                    const callback = (error, estado, resp) => {
                        let titulo = "Ha ocurrido un error, contacte con el administrador."
                        let tipo = "info"

                        if(!error){
                            if(estado === 201){
                                tipo = "success"
                                titulo = resp.titulo
                            }
                        }
                        
                        if(estado == 201){
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            handleOnClose()
                        }else{
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            setCargandoModal(true)
                        }
                        
                    }

                    const msg = await Funciones.consulta(url, data, 'POST', callback)
                }
            }else if(tipoProductoBibliografica == 'Inves_tipo_biblio_patente'){
                if(usuarios.length == 0){
                    let tipo = "info"
                    let titulo = "Inserte por lo menos 1 Participante"
                    dispatch(
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                        );
                }else{
                    setCargandoModal(true)
                    let data

                    if(tipoPatenteSelected == "Inves_tipo_capLibro_otro"){
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            patenteId: props.data.id,
                            tipoPatente: tipoPatenteSelected,
                            tipo_de_patente_otro: tipoPatenteOtro,
                            nombre: tituloPatente,
                            numero_patente: numeroPatente,
                            pais: paisPatenteSelected,
                            gaceta_industrial: gacetaIndustrialPatente,
                            institucion: institucionPatente,
                            anio: anioObtencionPatente,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }else{
                        data = {
                            producto: props.data.tipo_de_producto.codigo,
                            patenteId: props.data.id,
                            tipoPatente: tipoPatenteSelected,
                            tipo_de_patente_otro: tipoPatenteOtro,
                            nombre: tituloPatente,
                            numero_patente: numeroPatente,
                            pais: paisPatenteSelected,
                            gaceta_industrial: gacetaIndustrialPatente,
                            institucion: institucionPatente,
                            anio: anioObtencionPatente,
                            autores: usuarios,
                            autoresEliminados: usuariosEliminados,
                        }
                    }

                    let url = "api/v1.0/investigacion/EditGestionSemillerosBibliografica"
                    const callback = (error, estado, resp) => {
                        let titulo = "Ha ocurrido un error, contacte con el administrador."
                        let tipo = "info"

                        if(!error){
                            if(estado === 201){
                                tipo = "success"
                                titulo = resp.titulo
                            }
                        }
                        
                        if(estado == 201){
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            handleOnClose()
                        }else{
                            dispatch(
                                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                            );
                            setCargandoModal(true)
                        }
                        
                    }

                    const msg = await Funciones.consulta(url, data, 'POST', callback)
                }
            }
        }
    }

    return (
        <div >

            <Dialog TransitionComponent={Slide} open={props.openModal} onClose={()=>{handleOnClose()}} aria-labelledby="form-dialog-title">

                <AppBarModal 
                titulo='Editar Información' 
                mostrarModal={()=>{handleOnClose()}} 
                titulo_accion="" a
                ccion="" />
                {/* Contenido del modal del formulario de */}
                <DialogContent className={classes.modalMinWith}>
                    {cargandoModal == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> 
                    : vista()}
                </DialogContent>

                <DialogActions>
                    {/* className={classes.backButton}*/}
                    <div>
                        <Button disabled={activeStepBiblio === 0} onClick={handleBackTipoBiblio} >
                            Atras
                        </Button>
                        {activeStepBiblio === steps.length - 1 ? <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={()=>{handleSendToApi()}}>Enviar</Button> : <Button variant="contained" color="primary" style={{color: '#fff'}} onClick={() => handleNext()}>Siguiente</Button>}
                    </div>

                </DialogActions>

            </Dialog>
        </div>
    )
}

const ModalStepperFormBibliografia = (props) =>{
    const classes = useStyles()
    return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {getStepContentBibliografia(props.hola, props)}
                </div>
            )}
          </div>
        </div>
    )
}

const getStepContentBibliografia = (stepIndex, props) => {
    
    switch (stepIndex) {
        case 0:
            return (
                <FormularioBibliografia
                    datos = {props.datos}

                    //Stepper
                    hola={props.activeStepBiblio}
                    handleReset={props.handleReset}
                    steps={props.steps}

                    cargandoModal={props.cargandoModal}
                    setCargandoModal={props.setCargandoModal}

                    //Variables de articulo
                    idiomasTipoArticulo={props.idiomasTipoArticulo}
                    setIdiomasTipoArticulo={props.setIdiomasTipoArticulo}

                    idiomaTipoArticuloSelected={props.idiomaTipoArticuloSelected}
                    setIdiomaTipoArticuloSelected={props.setIdiomaTipoArticuloSelected}
                   
                    indexacionSelected = {props.indexacionSelected}
                    setIndexacionSelected = {props.setIndexacionSelected}

                    tipoArticuloSelected={props.tipoArticuloSelected}
                    setTipoArticuloSelected={props.setTipoArticuloSelected}

                    tipoArticuloOtro={props.tipoArticuloOtro}
                    setTipoArticuloOtro={props.setTipoArticuloOtro}

                    tituloArticulo={props.tituloArticulo}
                    setTituloArticulo={props.setTituloArticulo}

                    issn={props.issn}
                    setIssn={props.setIssn}

                    nombreRevista={props.nombreRevista}
                    setNombreRevista={props.setNombreRevista}

                    anioPublicacion={props.anioPublicacion}
                    setAnioPublicacion={props.setAnioPublicacion}

                    numPagTotales={props.numPagTotales}
                    setNumPagTotales={props.setNumPagTotales}

                    volumenRevista={props.volumenRevista}
                    setVolumenRevista={props.setVolumenRevista}

                    numeroRevista={props.numeroRevista}
                    setNumeroRevista={props.setNumeroRevista}
                    
                    paisPublicacion={props.paisPublicacion}
                    setPaisPublicacion={props.setPaisPublicacion}

                    idioma={props.idioma}
                    setIdioma={props.setIdioma}

                    enlace={props.enlace}
                    setEnlace={props.setEnlace}

                    doi={props.doi}
                    setDoi={props.setDoi}

                    indexaciones={props.indexaciones}
                    setIndexaciones={props.setIndexaciones}

                    tipoArticulo={props.tipoArticulo}
                    setTipoArticulo={props.setTipoArticulo}

                    paisesTipoArticulo={props.paisesTipoArticulo}
                    setPaisesTipoArticulo={props.setPaisesTipoArticulo}
                    paisTipoArticuloSelected={props.paisTipoArticuloSelected}
                    setPaisTipoArticuloSelected={props.setPaisTipoArticuloSelected}

                    paisTipoLibroSelected={props.paisTipoLibroSelected}
                    setPaisTipoLibroSelected={props.setPaisTipoLibroSelected}
                    
                    paisTipoCapLibroSelected={props.paisTipoCapLibroSelected}
                    setPaisCapTipoLibroSelected={props.setPaisCapTipoLibroSelected}
                    
                    paisPatenteSelected={props.paisPatenteSelected}
                    setPaisPatenteSelected={props.setPaisPatenteSelected}
                    //Variables libros
                    idiomasLibro={props.idiomasLibro}
                    setIdiomasLibro={props.setIdiomasLibro}

                    idiomaLibroSelected={props.idiomaLibroSelected}
                    setIdiomaLibroSelected={props.setIdiomaLibroSelected}

                    tipoLibroSelected={props.tipoLibroSelected}
                    setTipoLibroSelected={props.setTipoLibroSelected}

                    tipoLibroOtro={props.tipoLibroOtro}
                    setTipoLibroOtro={props.setTipoLibroOtro}

                    tituloLibro={props.tituloLibro}
                    setTituloLibro={props.setTituloLibro}

                    isbnLibro={props.isbnLibro}
                    setIsbnLibro={props.setIsbnLibro}

                    editorialLibro={props.editorialLibro}
                    setEditorialLibro={props.setEditorialLibro}

                    anioPublicacionLibro={props.anioPublicacionLibro}
                    setAnioPublicacionLibro={props.setAnioPublicacionLibro}

                    paisPublicacionLibro={props.paisPublicacionLibro}
                    setPaisPublicacionLibro={props.setPaisPublicacionLibro}

                    idiomaLibro={props.idiomaLibro}
                    setIdiomaLibro={props.setIdiomaLibro}

                    volumenLibro={props.volumenLibro}
                    setVolumenLibro={props.setVolumenLibro}

                    numeroEdicionLibro={props.numeroEdicionLibro}
                    setNumeroEdicionLibro={props.setNumeroEdicionLibro}

                    tiposLibros={props.tiposLibros}
                    setTiposLibros={props.setTiposLibros}

                    //Variables de capitulo del libro
                    idiomasCapituloLibro={props.idiomasCapituloLibro}
                    setIdiomasCapituloLibro={props.setIdiomasCapituloLibro}

                    idiomaCapituloLibroSelected={props.idiomaCapituloLibroSelected}
                    setIdiomaCapituloLibroSelected={props.setIdiomaCapituloLibroSelected}

                    tipoLibroSelectedCap={props.tipoLibroSelectedCap}
                    setTipoLibroSelectedCap={props.setTipoLibroSelectedCap}

                    tipoLibroOtroCap={props.tipoLibroOtroCap}
                    setTipoLibroOtroCap={props.setTipoLibroOtroCap}

                    tituloCapLibro={props.tituloCapLibro}
                    setTituloCapLibro={props.setTituloCapLibro}

                    tituloLibroCap={props.tituloLibroCap}
                    setTituloLibroCap={props.setTituloLibroCap}

                    isbnLibroCap={props.isbnLibroCap}
                    setIsbnLibroCap={props.setIsbnLibroCap}

                    editorialLibroCap={props.editorialLibroCap}
                    setEditorialLibroCap={props.setEditorialLibroCap}

                    anioPublicacionLibroCap={props.anioPublicacionLibroCap}
                    setAnioPublicacionLibroCap={props.setAnioPublicacionLibroCap}

                    paisPublicacionLibroCap={props.paisPublicacionLibroCap}
                    setPaisPublicacionLibroCap={props.setPaisPublicacionLibroCap}

                    idiomaLibroCap={props.idiomaLibroCap}
                    setIdiomaLibroCap={props.setIdiomaLibroCap}

                    volumenLibroCap={props.volumenLibroCap}
                    setVolumenLibroCap={props.setVolumenLibroCap}

                    numeroEdicionLibroCap={props.numeroEdicionLibroCap}
                    setNumeroEdicionLibroCap={props.setNumeroEdicionLibroCap}

                    tiposLibrosCap={props.tiposLibrosCap}
                    setTiposLibrosCap={props.setTiposLibrosCap}

                    //Patente
                    tipoPatente={props.tipoPatente}
                    setTipoPatente={props.setTipoPatente}

                    tipoPatenteSelected={props.tipoPatenteSelected}
                    setTipoPatenteSelected={props.setTipoPatenteSelected}

                    anioObtencionPatente={props.anioObtencionPatente}
                    setAnioObtencionPatente={props.setAnioObtencionPatente}

                    numeroRegistroPatente={props.numeroRegistroPatente}
                    setNumeroRegistroPatente={props.setNumeroRegistroPatente}

                    tituloPatente={props.tituloPatente}
                    setTituloPatente={props.setTituloPatente}

                    numeroPatente={props.numeroPatente}
                    setNumeroPatente={props.setNumeroPatente}

                    paisObtencionPatente={props.paisObtencionPatente}
                    setPaisObtencionPatente={props.setPaisObtencionPatente}

                    gacetaIndustrialPatente={props.gacetaIndustrialPatente}
                    setGaceteIndustrialPatente={props.setGaceteIndustrialPatente}

                    institucionPatente={props.institucionPatente}
                    setInstitucionPatente={props.setInstitucionPatente}

                    tipoPatenteOtro={props.tipoPatenteOtro}
                    setTipoPatenteOtro={props.setTipoPatenteOtro}

                    //Errores
                    getError={props.getError}
                    errores={props.errores}
                    setErrores={props.setErrores}
                />
            )
            case 1:
                return(
                    <ModalFormGestionAutores 
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}
                
                    //Variables Autores y Particiapntes
                    numeroIdParEvent={props.usuarios}
                    setNumeroIdParEvent={props.setUsuarios}
                    arrai={props.arrai}
                    setArrai={props.setArrai}

                    usuariosEliminados = {props.usuariosEliminados}
                    setUsuariosEliminados = {props.setUsuariosEliminados}
                    />
                )
    }
}

const FormularioBibliografia = (props) =>{
    const classes = useStyles()

    const handleOnchange = (e) =>{
        if(props.datos == "Inves_tipo_biblio_articulo"){
            if(e.target.name == "indexaciones"){
                props.setIndexacionSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "indexacionSelected"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoArticulo"){
                props.setTipoArticuloSelected(e.target.value)
                props.setTipoArticuloOtro('')
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoArticuloSelected"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoArticuloOtro"){
                props.setTipoArticuloOtro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoArticuloOtro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloArticulo"){
                props.setTituloArticulo(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloArticulo"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "issn"){
                props.setIssn(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "issn"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "nombreRevista"){
                props.setNombreRevista(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreRevista"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numPagTotales"){
                props.setNumPagTotales(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numPagTotales"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "volumenRevista"){
                props.setVolumenRevista(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "volumenRevista"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroRevista"){
                props.setNumeroRevista(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroRevista"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisTipoArticuloSelected"){
                props.setPaisTipoArticuloSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisTipoArticuloSelected"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idioma"){
                props.setIdioma(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idioma"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "enlace"){
                props.setEnlace(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "enlace"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "doi"){
                props.setDoi(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "doi"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaTipoArticuloSelected"){
                props.setIdiomaTipoArticuloSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaTipoArticuloSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.datos == "Inves_tipo_biblio_libro"){
            if(e.target.name == "tipoDeLibro"){
                props.setTipoLibroSelected(e.target.value)
                props.setTipoLibroOtro('')
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroSelected"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoLibroOtro"){
                props.setTipoLibroOtro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroOtro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloLibro"){
                props.setTituloLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloLibro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "isbnLibro"){
                props.setIsbnLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "isbnLibro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "editorialLibro"){
                props.setEditorialLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "editorialLibro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisTipoLibroSelected"){
                props.setPaisTipoLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisTipoLibroSelected"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaLibro"){
                props.setIdiomaLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaLibro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "volumenLibro"){
                props.setVolumenLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "volumenLibro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroEdicionLibro"){
                props.setNumeroEdicionLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroEdicionLibro"));
                    //Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaLibroSelected"){
                props.setIdiomaLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.datos == "Inves_tipo_biblio_capituloLib"){
            if(e.target.name == "tipoDeLibro"){
                props.setTipoLibroSelectedCap(e.target.value)
                props.setTipoLibroOtroCap('')
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroSelectedCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoLibroOtroCap"){
                props.setTipoLibroOtroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoLibroOtroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloCapLibro"){
                props.setTituloCapLibro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloCapLibro"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloLibroCap"){
                props.setTituloLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "isbnLibroCap"){
                props.setIsbnLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "isbnLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "editorialLibroCap"){
                props.setEditorialLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "editorialLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisTipoCapLibroSelected"){
                props.setPaisCapTipoLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisTipoCapLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaLibroCap"){
                props.setIdiomaLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "volumenLibroCap"){
                props.setVolumenLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "volumenLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroEdicionLibroCap"){
                props.setNumeroEdicionLibroCap(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroEdicionLibroCap"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "idiomaCapituloLibroSelected"){
                props.setIdiomaCapituloLibroSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "idiomaCapituloLibroSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.datos == "Inves_tipo_biblio_patente"){
            if(e.target.name == "tipoPatente"){
                props.setTipoPatenteSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tituloPatente"){
                props.setTituloPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tituloPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "numeroPatente"){
                props.setNumeroPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "numeroPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "paisPatenteSelected"){
                props.setPaisPatenteSelected(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "paisPatenteSelected"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "gacetaIndustrialPatente"){
                props.setGaceteIndustrialPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "gacetaIndustrialPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "institucionPatente"){
                props.setInstitucionPatente(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "institucionPatente"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "tipoPatenteOtro"){
                props.setTipoPatenteOtro(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "tipoPatenteOtro"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }
        
    }

    return(
        <div>
            {/* Seccion de Produccion Bibliografica */}
            { props.datos == "Inves_tipo_biblio_articulo" ? 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Indexacion</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('indexacionSelected', props.errores).length > 0}>
                                <Select
                                    value={props.indexacionSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="indexaciones"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.indexaciones.length} Indexaciones para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.indexaciones.map((item, index) => (
                                    <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('indexacionSelected', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Articulo</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoArticuloSelected', props.errores).length > 0}>
                                <Select
                                    value={props.tipoArticuloSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoArticulo"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tipoArticulo.length} Tipos de Articulos para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                             </FormControl>
                    <FormHelperText>{ props.getError('tipoArticuloSelected', props.errores) }</FormHelperText>
                    </Grid> 

                    {props.tipoArticuloSelected == "Inves_tipo_articulo_otro" ? 
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Tipo de Articulo" 
                        id = 'tipoArticuloOtro'
                        error = { props.getError('tipoArticuloOtro', props.errores).length > 0}
                        helperText = { props.getError('tipoArticuloOtro', props.errores) }
                        value={props.tipoArticuloOtro} 
                        onChange={handleOnchange}
                        name="tipoArticuloOtro"/>
                    </Grid> : ''}

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Título del artículo" 
                        id = 'tituloArticulo'
                        error = { props.getError('tituloArticulo', props.errores).length > 0}
                        helperText = { props.getError('tituloArticulo', props.errores) }
                        value={props.tituloArticulo} 
                        onChange={handleOnchange}
                        name="tituloArticulo"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="ISSN" 
                        id = 'issn'
                        error = { props.getError('issn', props.errores).length > 0}
                        helperText = { props.getError('issn', props.errores) }
                        value={props.issn} 
                        onChange={handleOnchange}
                        name="issn"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Nombre de la revista" 
                        id = 'nombreRevista'
                        error = { props.getError('nombreRevista', props.errores).length > 0}
                        helperText = { props.getError('nombreRevista', props.errores) }
                        value={props.nombreRevista} 
                        onChange={handleOnchange}
                        name="nombreRevista"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año de publicación"
                                value={props.anioPublicacion}
                                fullWidth
                                onChange={props.setAnioPublicacion}
                                animateYearScrolling
                            />
                            </Fragment> 
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField 
                        fullWidth 
                        label="Número de páginas totales" 
                        id = 'numPagTotales'
                        error = { props.getError('numPagTotales', props.errores).length > 0}
                        helperText = { props.getError('numPagTotales', props.errores) }
                        value={props.numPagTotales} 
                        onChange={handleOnchange}
                        name="numPagTotales"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Volumen de la revista" 
                        id = 'volumenRevista'
                        error = { props.getError('volumenRevista', props.errores).length > 0}
                        helperText = { props.getError('volumenRevista', props.errores) }
                        value={props.volumenRevista} 
                        onChange={handleOnchange}
                        name="volumenRevista"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="No. de la revista" 
                        id = 'numeroRevista'
                        error = { props.getError('numeroRevista', props.errores).length > 0}
                        helperText = { props.getError('numeroRevista', props.errores) }
                        value={props.numeroRevista} 
                        onChange={handleOnchange}
                        name="numeroRevista"/>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisTipoArticuloSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisTipoArticuloSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisTipoArticuloSelected"
                                >
                                    <MenuItem value="defecto">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisTipoArticuloSelected', props.errores) }</FormHelperText>
                    </Grid> 

                    {/* <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaTipoArticuloSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaTipoArticuloSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaTipoArticuloSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaTipoArticuloSelected', props.errores) }</FormHelperText>
                    </Grid> */}

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaTipoArticuloSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaTipoArticuloSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaTipoArticuloSelected"
                                >
                                    <MenuItem value="defecto">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaTipoArticuloSelected', props.errores) }</FormHelperText>
                    </Grid> 

                    {/* <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Idioma" 
                        id = 'idioma'
                        error = { props.getError('idioma', props.errores).length > 0}
                        helperText = { props.getError('idioma', props.errores) }
                        value={props.idioma} 
                        onChange={handleOnchange}
                        name="idioma"/>
                    </Grid> */}

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Enlace" 
                        id = 'enlace'
                        error = { props.getError('enlace', props.errores).length > 0}
                        helperText = { props.getError('enlace', props.errores) }
                        value={props.enlace} 
                        onChange={handleOnchange}
                        name="enlace"/>
                    </Grid>

                   <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="DOI" 
                        id = 'doi'
                        error = { props.getError('doi', props.errores).length > 0}
                        helperText = { props.getError('doi', props.errores) }
                        value={props.doi} 
                        onChange={handleOnchange}
                        name="doi"/>
                </Grid>

                </Grid>: ''}
                { props.datos == "Inves_tipo_biblio_libro" ? 
                <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Libro</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.tipoLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoDeLibro"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposLibros.length} Libros para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tiposLibros.map((item, index) => (
                                    <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('tipoLibroSelected', props.errores) }</FormHelperText>
                    </Grid>

                    {props.tipoLibroSelected == "Inves_tipo_libro_otro" ? 
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Tipo de Libro" 
                        id = 'tipoLibroOtro'
                        error = { props.getError('tipoLibroOtro', props.errores).length > 0}
                        helperText = { props.getError('tipoLibroOtro', props.errores) }
                        value={props.tipoLibroOtro} 
                        onChange={handleOnchange}
                        name="tipoLibroOtro"/>
                    </Grid> : ''}
                    
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Título del libro" 
                        id = 'tituloLibro'
                        error = { props.getError('tituloLibro', props.errores).length > 0}
                        helperText = { props.getError('tituloLibro', props.errores) }
                        value={props.tituloLibro} 
                        onChange={handleOnchange}
                        name="tituloLibro"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="ISBN" 
                        id = 'isbnLibro'
                        error = { props.getError('isbnLibro', props.errores).length > 0}
                        helperText = { props.getError('isbnLibro', props.errores) }
                        value={props.isbnLibro} 
                        onChange={handleOnchange}
                        name="isbnLibro"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Editorial" 
                        id = 'editorialLibro'
                        error = { props.getError('editorialLibro', props.errores).length > 0}
                        helperText = { props.getError('editorialLibro', props.errores) }
                        value={props.editorialLibro} 
                        onChange={handleOnchange}
                        name="editorialLibro"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año de publicación"
                                value={props.anioPublicacionLibro}
                                fullWidth
                                onChange={props.setAnioPublicacionLibro}
                                animateYearScrolling
                            />
                            </Fragment> 
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisTipoLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisTipoLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisTipoLibroSelected"
                                >
                                    <MenuItem value="defecto">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisTipoLibroSelected', props.errores) }</FormHelperText>
                    </Grid> 
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaLibroSelected"
                                >
                                    <MenuItem value="defecto">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasLibro.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasLibro.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaLibroSelected', props.errores) }</FormHelperText>
                    </Grid> 
                    {/* <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaLibroSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasLibro.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasLibro.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaLibroSelected', props.errores) }</FormHelperText>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Idioma" 
                        id = 'idiomaLibro'
                        error = { props.getError('idiomaLibro', props.errores).length > 0}
                        helperText = { props.getError('idiomaLibro', props.errores) }
                        value={props.idiomaLibro} 
                        onChange={handleOnchange}
                        name="idiomaLibro"/>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <TextField 
                        fullWidth 
                        label="Volumen del libro" 
                        id = 'volumenLibro'
                        error = { props.getError('volumenLibro', props.errores).length > 0}
                        helperText = { props.getError('volumenLibro', props.errores) }
                        value={props.volumenLibro} 
                        onChange={handleOnchange}
                        name="volumenLibro"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                        fullWidth 
                        label="No. de edición" 
                        id = 'numeroEdicionLibro'
                        error = { props.getError('numeroEdicionLibro', props.errores).length > 0}
                        helperText = { props.getError('numeroEdicionLibro', props.errores) }
                        value={props.numeroEdicionLibro} 
                        onChange={handleOnchange}
                        name="numeroEdicionLibro"/>
                    </Grid>

                </Grid> : ''}
                {/* Seccion de Produccion Bibliografica Libros capitulo*/}
                { props.datos == "Inves_tipo_biblio_capituloLib" ? 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de Libro</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoLibroSelectedCap', props.errores).length > 0}>
                                <Select
                                    value={props.tipoLibroSelectedCap}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoDeLibro"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposLibrosCap.length} Libros para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tiposLibrosCap.map((item, index) => (
                                    <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('tipoLibroSelectedCap', props.errores) }</FormHelperText>
                    </Grid>

                    {props.tipoLibroSelectedCap == "Inves_tipo_capLibro_otro" ? 
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Tipo de Libro" 
                        id = 'tipoLibroOtroCap'
                        error = { props.getError('tipoLibroOtroCap', props.errores).length > 0}
                        helperText = { props.getError('tipoLibroOtroCap', props.errores) }
                        value={props.tipoLibroOtroCap} 
                        onChange={handleOnchange}
                        name="tipoLibroOtroCap"/>
                    </Grid> : ''}

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Título del capítulo de libro" 
                        id = 'tituloCapLibro'
                        error = { props.getError('tituloCapLibro', props.errores).length > 0}
                        helperText = { props.getError('tituloCapLibro', props.errores) }
                        value={props.tituloCapLibro} 
                        onChange={handleOnchange}
                        name="tituloCapLibro"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Título del libro" 
                        id = 'tituloLibroCap'
                        error = { props.getError('tituloLibroCap', props.errores).length > 0}
                        helperText = { props.getError('tituloLibroCap', props.errores) }
                        value={props.tituloLibroCap} 
                        onChange={handleOnchange}
                        name="tituloLibroCap"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="ISBN" 
                        id = 'isbnLibroCap'
                        error = { props.getError('isbnLibroCap', props.errores).length > 0}
                        helperText = { props.getError('isbnLibroCap', props.errores) }
                        value={props.isbnLibroCap} 
                        onChange={handleOnchange}
                        name="isbnLibroCap"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Editorial" 
                        id = 'editorialLibroCap'
                        error = { props.getError('editorialLibroCap', props.errores).length > 0}
                        helperText = { props.getError('editorialLibroCap', props.errores) }
                        value={props.editorialLibroCap} 
                        onChange={handleOnchange}
                        name="editorialLibroCap"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Año de publicación"
                                value={props.anioPublicacionLibroCap}
                                fullWidth
                                onChange={props.setAnioPublicacionLibroCap}
                                animateYearScrolling
                            />
                            </Fragment> 
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisTipoCapLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisTipoCapLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisTipoCapLibroSelected"
                                >
                                    <MenuItem value="defecto">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisTipoCapLibroSelected', props.errores) }</FormHelperText>
                    </Grid> 

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaCapituloLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaCapituloLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaCapituloLibroSelected"
                                >
                                    <MenuItem value="defecto">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasCapituloLibro.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasCapituloLibro.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaCapituloLibroSelected', props.errores) }</FormHelperText>
                    </Grid> 

                    {/* <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Idiomas</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('idiomaCapituloLibroSelected', props.errores).length > 0}>
                                <Select
                                    value={props.idiomaCapituloLibroSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="idiomaCapituloLibroSelected"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.idiomasCapituloLibro.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.idiomasCapituloLibro.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('idiomaCapituloLibroSelected', props.errores) }</FormHelperText>
                    </Grid> */}

                    {/* <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Idioma" 
                        id = 'idiomaLibroCap'
                        error = { props.getError('idiomaLibroCap', props.errores).length > 0}
                        helperText = { props.getError('idiomaLibroCap', props.errores) }
                        value={props.idiomaLibroCap} 
                        onChange={handleOnchange}
                        name="idiomaLibroCap"/>
                    </Grid> */}

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Volumen del libro" 
                        id = 'volumenLibroCap'
                        error = { props.getError('volumenLibroCap', props.errores).length > 0}
                        helperText = { props.getError('volumenLibroCap', props.errores) }
                        value={props.volumenLibroCap} 
                        onChange={handleOnchange}
                        name="volumenLibroCap"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="No. de edición" 
                        id = 'numeroEdicionLibroCap'
                        error = { props.getError('numeroEdicionLibroCap', props.errores).length > 0}
                        helperText = { props.getError('numeroEdicionLibroCap', props.errores) }
                        value={props.numeroEdicionLibroCap} 
                        onChange={handleOnchange}
                        name="numeroEdicionLibroCap"/>
                    </Grid>

                </Grid> : ''}
                {/* Seccion de Produccion Bibliografica Libros capitulo*/}
                { props.datos == "Inves_tipo_biblio_patente" ? 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Tipo de patente</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('tipoPatente', props.errores).length > 0}>
                                <Select
                                    value={props.tipoPatenteSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="tipoPatente"
                                >
                                    <MenuItem value="">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tipoPatente.length} Patentes para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.tipoPatente.map((item, index) => (
                                    <MenuItem key= { index } value = { item.codigo }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('tipoPatente', props.errores) }</FormHelperText>
                    </Grid>

                    {props.tipoPatenteSelected == "Inves_tipo_patente_otro" ? 
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Tipo de Patente" 
                        error = { props.getError('tipoPatenteOtro', props.errores).length > 0}
                        helperText = { props.getError('tipoPatenteOtro', props.errores) }
                        value={props.tipoPatenteOtro} 
                        onChange={handleOnchange}
                        name="tipoPatenteOtro"/>
                    </Grid> : ''}

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Titulo de la Patente" 
                        id = 'tituloPatente'
                        error = { props.getError('tituloPatente', props.errores).length > 0}
                        helperText = { props.getError('tituloPatente', props.errores) }
                        value={props.tituloPatente} 
                        onChange={handleOnchange}
                        name="tituloPatente"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Numero Patente" 
                        id = 'numeroPatente'
                        error = { props.getError('numeroPatente', props.errores).length > 0}
                        helperText = { props.getError('numeroPatente', props.errores) }
                        value={props.numeroPatente} 
                        onChange={handleOnchange}
                        name="numeroPatente"/>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">País de Publicación</InputLabel>
                            <FormControl className={classes.formControl} fullWidth error = { props.getError('paisPatenteSelected', props.errores).length > 0}>
                                <Select
                                    value={props.paisPatenteSelected}
                                    displayEmpty
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleOnchange}
                                    name="paisPatenteSelected"
                                >
                                    <MenuItem value="defecto">
                                        <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.paisesTipoArticulo.length} Paises para Seleccionar` }</em>
                                    </MenuItem>
                                    {props.paisesTipoArticulo.map((item, index) => (
                                    <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        <FormHelperText>{ props.getError('paisPatenteSelected', props.errores) }</FormHelperText>
                    </Grid> 

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Gaceta Industrial de Publicación" 
                        id = 'gacetaIndustrialPatente'
                        error = { props.getError('gacetaIndustrialPatente', props.errores).length > 0}
                        helperText = { props.getError('gacetaIndustrialPatente', props.errores) }
                        value={props.gacetaIndustrialPatente} 
                        onChange={handleOnchange}
                        name="gacetaIndustrialPatente"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Institución" 
                        id = 'institucionPatente'
                        error = { props.getError('institucionPatente', props.errores).length > 0}
                        helperText = { props.getError('institucionPatente', props.errores) }
                        value={props.institucionPatente} 
                        onChange={handleOnchange}
                        name="institucionPatente"/>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                            <Fragment>
                            <DatePicker
                                views={["year"]}
                                label="Fecha de Solicitud"
                                value={props.anioObtencionPatente}
                                fullWidth
                                onChange={props.setAnioObtencionPatente}
                                animateYearScrolling
                            />
                            </Fragment> 
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid> : ''}
        </div>
    )
}

const ModalStepperFormEventos = (props) =>{
    const classes = useStyles()
    return (
        <div className={classes.root}>
          <Stepper activeStep={props.hola} alternativeLabel>
            {props.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {/* Aqui es cuando el Stepper llega al final.*/}
            {props.hola === props.steps.length ? (
              <div>
                <Typography className={classes.instructions}>{props.hola}</Typography>
                <Button onClick={props.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {getStepContent(props.hola, props)}
                </div>
            )}
          </div>
        </div>
    )
}

const getStepContent = (stepIndex, props) => {
    
    switch (stepIndex) {
        case 0:
            return (
                <FormularioEventos
                    //Informacion de los inputs
                    datos={props.datos}

                    cargandoModal={props.cargandoModal}
                    setCargandoModal={props.setCargandoModal}

                    //Variables Eventos
                    nombreDelEvento={props.nombreDelEvento}
                    setNombreDelEvento={props.setNombreDelEvento}

                    anio={props.anio}
                    setAnio={props.setAnio}

                    departamentos={props.departamentos}
                    setDepartamentos={props.setDepartamentos}

                    departamento={props.departamento}
                    setDepartamento={props.setDepartamento}

                    ciudades={props.ciudades}
                    setCiudades={props.setCiudades}

                    ciudad={props.ciudad}
                    setCiudad={props.setCiudad}

                    nombreDelAmbito={props.nombreDelAmbito}
                    setNombreDelAmbito={props.setNombreDelAmbito}

                    nombreAmbitos={props.nombreAmbitos}
                    setNombreAmbitos={props.setNombreAmbitos}

                    nombreModalidadPar={props.nombreModalidadPar}
                    setNombreModalidadPar={props.setNombreModalidadPar}

                    nombreModalidadPars={props.nombreModalidadPars}
                    setNombreModalidadPars={props.setNombreModalidadPars}

                    fechaInicioEvento={props.fechaInicioEvento}
                    setFechaInicioEvento={props.setFechaInicioEvento}

                    fechaFinEvento={props.fechaFinEvento}
                    setFechaFinEvento={props.setFechaFinEvento}

                    adj_evento_edi={props.adj_evento}
                    setAdj_evento_edi={props.setAdj_evento}
                    //Proyectos
                    fechaInicioProyecto={props.fechaInicioProyecto}
                    setFechaInicioProyecto = {props.setFechaInicioProyecto}
                    fechaFinProyecto={props.fechaFinProyecto}
                    setFechaFinProyecto={props.setFechaFinProyecto}
                    nombreProyecto={props.nombreProyecto}
                    setNombreProyecto={props.setNombreProyecto}
                    descripcionProyecto={props.descripcionProyecto}
                    setDescripcionProyecto={props.setDescripcionProyecto}

                    asignaturaProyecto={props.asignaturaProyecto}
                    setAsignaturaProyecto={props.setAsignaturaProyecto}

                    nombreprofesorProyecto={props.nombreprofesorProyecto}
                    setNombreprofesorProyecto={props.setNombreprofesorProyecto}

                    profesorProyecto={props.profesorProyecto}
                    setProfesorProyecto={props.setProfesorProyecto}
                    //Usuarios
                    usuarios={props.usuarios}
                    setUsuarios={props.setUsuarios}

                    //Errores
                    getError={props.getError}
                    errores={props.errores}
                    setErrores={props.setErrores}
                />
            )
            case 1:
                return(
                    <ModalFormGestionAutores 
                    tipoSolicitudActual={props.tipoSolicitudActual}
                    setTipoSolicitudActual={props.setTipoSolicitudActual}
                
                    //Variables Autores y Particiapntes
                    numeroIdParEvent={props.usuarios}
                    setNumeroIdParEvent={props.setUsuarios}
                    arrai={props.arrai}
                    setArrai={props.setArrai}

                    usuariosEliminados = {props.usuariosEliminados}
                    setUsuariosEliminados = {props.setUsuariosEliminados}
                    
                    modalidadParticipacionActive={props.modalidadParticipacionActive}
                    setModalidadParticipacionActive={props.setModalidadParticipacionActive}
                    
                    nombreModalidadPars={props.nombreModalidadPars}
                    setNombreModalidadPars={props.setNombreModalidadPars}
                    />
                )
    }
}

const FormularioEventos = (props) =>{
    const classes = useStyles()
    const fileInput = React.createRef();
    const obtenerCiudades = async (value) =>{

        if(props.ciudad != ''){
            props.setCiudad('')
        }
        let ciudades = await obtenerPermisosValor(value.value);
        ciudades = ciudades.map(({ secundario }) => ({
            value: secundario.id,
            label: secundario.nombre,
          }));
        props.setCiudades(ciudades)
    }

    const handleOnchange = (e) =>{
        if(props.datos.tipo.codigo == "Semi_inves_proyectos" ){
            if(e.target.name == "nombreProyecto"){
                props.setNombreProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if (e.target.name == "descripcionProyecto") {
                props.setDescripcionProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "descripcionProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if (e.target.name == "asignaturaProyecto") {
                props.setAsignaturaProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "asignaturaProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if (e.target.name == "nombreprofesorProyecto") {
                props.setNombreprofesorProyecto(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreprofesorProyecto"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }else if(props.datos.tipo.codigo == "Soli_inves_eventos"){
            if(e.target.name == "nombreDelEvento"){
                props.setNombreDelEvento(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreDelEvento"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "nombreDelAmbito"){
                props.setNombreDelAmbito(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "nombreDelAmbito"));
                    // Elimina error cuando se escribe en el campo
                }
            }else if(e.target.name == "adj_evento"){
                props.setAdj_evento_edi(e.target.value)
                if(props.errores.length > 0){
                    props.setErrores(props.errores.filter(er => er.llave != "adj_evento"));
                    // Elimina error cuando se escribe en el campo
                }
            }
        }
    }

    return (
        <div>
            {props.datos.tipo.codigo == "Soli_inves_eventos" ? 
                <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField 
                    fullWidth 
                    label="Nombre del Evento" 
                    id = 'nombreEvento'
                    error = { props.getError('nombreDelEvento', props.errores).length > 0}
                    helperText = { props.getError('nombreDelEvento', props.errores) }
                    value={props.nombreDelEvento} 
                    onChange={handleOnchange}
                    name="nombreDelEvento"/>
                </Grid>
                
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                        <Fragment>
                        <DatePicker
                            views={["year"]}
                            label="Año"
                            value={props.anio}
                            fullWidth
                            onChange={props.setAnio}
                            animateYearScrolling
                        />
                        </Fragment> 
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth error = { props.getError('departamento', props.errores).length > 0}>
                    <BuscarSelect 
                        datos={props.departamentos} 
                        cambiarEstado={(value) => {
                            obtenerCiudades(value)

                            props.setDepartamento(value)
                            if(props.errores.length > 0){
                                props.setErrores(props.errores.filter(er => er.llave != "departamento"));
                            }
                        }} 
                        valor={props.departamento} 
                        id='select-departamento' 
                        nombre='Departamento' 
                        name='departamento'
                        placeholder='Seleccione Opción' />
                    </FormControl>
                    <FormHelperText style={{color: "#FF2D00"}}>{ props.getError('departamento', props.errores) }</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth error = { props.getError('ciudad', props.errores).length > 0}>
                        <BuscarSelect 
                        datos={props.ciudades} 
                        cambiarEstado={(value) => {
                            props.setCiudad(value)
                            if(props.errores.length > 0){
                                props.setErrores(props.errores.filter(er => er.llave != "ciudad"));
                            }
                        }} 
                        valor={props.ciudad} 
                        id='select-ciudad' 
                        nombre='Ciudad' 
                        name='ciudad'
                        placeholder='Seleccione Opción'/>
                    </FormControl>
                    <FormHelperText style={{color: "#FF2D00"}}>{ props.getError('ciudad', props.errores) }</FormHelperText>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor="age-native-simple">Ambito</InputLabel>
                    <FormControl className={classes.formControl} fullWidth error = { props.getError('nombreDelAmbito', props.errores).length > 0}>
                        <Select
                            value={props.nombreDelAmbito}
                            displayEmpty
                            fullWidth
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleOnchange}
                            name="nombreDelAmbito"
                        >
                        <MenuItem value="">
                            <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.nombreAmbitos.length} Ambitos para Seleccionar` }</em>
                        </MenuItem>
                        {props.nombreAmbitos.map((item, index) => (
                        <MenuItem key= { index } value = { item.id }>{`${item.nombre}`}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormHelperText>{ props.getError('nombreDelAmbito', props.errores) }</FormHelperText>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="MM/dd/yyyy"
                            margin="normal"
                            label="Fecha de Inicio"
                            value={props.fechaInicioEvento}
                            onChange={props.setFechaInicioEvento}
                            fullWidth 
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                        <Fragment>
                        <KeyboardDatePicker
                            required={true}
                            style={{ marginTop: 3, width: '100%' }}
                            format="MM/dd/yyyy"
                            margin="normal"
                            label="Fecha de Fin"
                            value={props.fechaFinEvento}
                            onChange={props.setFechaFinEvento}
                            fullWidth 
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            invalidDateMessage='Formato Invalido'
                        />
                        </Fragment> 
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} className='oculto'>
                        <TextField
                        ref={fileInput}
                        required
                        type="file"
                        id="adj_evento"
                        name="adj_evento"
                        label="Adjuntar Identificación"
                        fullWidth
                        autoComplete="billing address-line1"
                        onChange={handleOnchange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <InputFile 
                    label='Adjuntar Soporte de Evento' 
                    id='adj_evento' 
                    value={props.adj_evento_edi} 
                    required={props.adj_evento_edi} 
                    error={props.getError('adj_evento', props.errores) > 0}
                    helperText = { props.getError('adj_evento', props.errores) }/>
                   </Grid>

            </Grid> : ''}

            {props.datos.tipo.codigo == "Semi_inves_proyectos" ? 
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        label="Nombre del Proyecto" 
                        error = { props.getError('nombreProyecto', props.errores).length > 0}
                        helperText = { props.getError('nombreProyecto', props.errores) }
                        value={props.nombreProyecto} 
                        onChange={handleOnchange}
                        name="nombreProyecto"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Descripción del proyecto"
                        error = { props.getError('descripcionProyecto', props.errores).length > 0}
                        helperText = { props.getError('descripcionProyecto', props.errores) }
                        value={props.descripcionProyecto}
                        onChange={handleOnchange}
                        name="descripcionProyecto"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Nombre de asignatura o área de conocimiento"
                        error = { props.getError('asignaturaProyecto', props.errores).length > 0}
                        helperText = { props.getError('asignaturaProyecto', props.errores) }
                        value={props.asignaturaProyecto}
                        onChange={handleOnchange}
                        name="asignaturaProyecto"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="age-native-simple">Profesor Asesor</InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('nombreprofesorProyecto', props.errores).length > 0}>
                        <Select
                            value={props.nombreprofesorProyecto}
                            displayEmpty
                            fullWidth
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleOnchange}
                            name="nombreprofesorProyecto"
                        >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.profesorProyecto.length} Profesores para Seleccionar` }</em>
                            </MenuItem>
                            {props.profesorProyecto.map((item, index) => (
                            <MenuItem key= { index } value = { item.encargado_id }>{`${item.nombre}`}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('nombreprofesorProyecto', props.errores) }</FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} > 
                        <Grid item xs={12}>
                            <Fragment>
                            <KeyboardDatePicker
                                required={true}
                                style={{ marginTop: 3, width: '100%' }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Fecha de Inicio"
                                value={props.fechaInicioProyecto}
                                onChange={props.setFechaInicioProyecto}
                                fullWidth 
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage='Formato Invalido'
                            />
                            </Fragment> 
                        </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                required={true}
                                style={{ marginTop: 3, width: '100%' }}
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Fecha de Fin"
                                value={props.fechaFinProyecto}
                                onChange={props.setFechaFinProyecto}
                                fullWidth 
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                invalidDateMessage='Formato Invalido'
                            />
                        </Grid> 
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>: ''}
        </div>

    )
}

const ModalFormGestionAutores = (props) => {

    const [openModalAddParti, setopenModalAddParti] = React.useState(false);
    const actions = (requisito) => {
  
      const eventoDetalle = (requisito) => {
        if(requisito.nuevo == false){
            props.usuariosEliminados.push(requisito)
        }
        let i = props.numeroIdParEvent.indexOf(requisito)
        let filtredData = ''
        let filtredDataArrai = ''
        if ( i !== -1 ) {
          filtredData = props.numeroIdParEvent.filter(item => item.id != requisito.id);
          filtredDataArrai = props.arrai.filter(item => item.id != requisito.id);
        }
        props.setNumeroIdParEvent(filtredData)
        props.setArrai(filtredDataArrai)
    }
  
      let detalle = <BtnEliminar callback={() => eventoDetalle(requisito)} />;
      
      return detalle
  
    }

    return(
        props.cargando == true ? <div> <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> <LinearProgress /></div> :
        <div>
        <ListarDatos
            id="tbl_listar_encargados"
            datos={props.numeroIdParEvent}
            opciones={true}
            agregar={true}
            buscar={true}
            actAgregar={() => setopenModalAddParti(true)}
            css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
            }}
            acciones={(row) => actions(row)}
            titulo={"Lista de encargados"}
            fila_principal={({ nombre, id }) => `${nombre}`}
            filas={[
            {
                'mostrar': ({ nombre }) => `${nombre}`,
                'id': 'nombre', 'enLista': false
            },
            {
                'mostrar': ({ correo }) => `${correo}`,
                'id': 'nombre', 'enLista': true
            },
            {
                'mostrar': ({ identificacion }) => `${identificacion}`,
                'id': 'nombre', 'enLista': true
            },
            {
                'mostrar': ({ modalidadParticipacion }) => {
                    if(modalidadParticipacion != null){
                        return `${modalidadParticipacion.nombre}`
                    }else{
                        return ''
                    }
                },
                'id': 'nombre', 'enLista': true
            },
            { 'id': 'descripcion', 'enLista': false },
            ]}
        />
      
        <SemilleroAddParti 
            openModalAddParti={openModalAddParti}
            setopenModalAddParti={setopenModalAddParti}

            participantes={props.numeroIdParEvent}
            setParticipantes={props.setNumeroIdParEvent}

            arrai={props.arrai}
            setArrai={props.setArrai}

            modalidadParticipacion={props.modalidadParticipacionActive}
            rol={false}

            tiposModalidadParticipacion={props.nombreModalidadPars}
            setTiposModalidadParticipacion={props.setNombreModalidadPars}
        />
    </div>
    )

}

export default SemillerosGestionEdit 