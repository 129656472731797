import React, { Component, forwardRef } from 'react'
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MaterialTable, { MTableToolbar } from "material-table";
import moment from 'moment'
import ReactExport from "react-data-export";
import FilterListIcon from '@material-ui/icons/FilterList';
import { coloresEmma } from '../../global/js/funciones';
import ExportarReporteInter from '../internacionalizacion/registro/ExportarReporteInter';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ExportarReporte({ data, nombre, columnas}) {
   return (
      <ExcelFile filename={`${nombre}-${moment().format('YYYY-MM-DD, h:mm a')}`} element={<Tooltip title='Descargar' aria-label="add"><IconButton edge="end" color="secondary"><GetAppIcon /></IconButton></Tooltip>}>
         <ExcelSheet data={data} name="Datos">
            {columnas.map(({ field, title }, i) => <ExcelColumn key={i} label={title} value={field} />)}
         </ExcelSheet>
      </ExcelFile>
   )
}

class ListarTabla extends Component {
  ordenar(columnas){
    columnas = columnas.sort(function (a, b) {
      if (a['orden'] > b['orden']) return 1
      if (a['orden'] < b['orden']) return -1
      return 0;
    })

    return columnas
  }

  generarTotal(){
    let { columnas, datos } = this.props
    let objeto = {}, total = 0, conta=0

    columnas.map(({field})=>{
      total=0
    
      if(typeof datos[0][field]!=='string'){
        datos.map((e)=>{
          total+=parseInt(e[field])
        })
        objeto[field]=total
      }else{
        if(conta==0) objeto[field]='TOTAL'; conta++
      }
    })

    return objeto
  }

  render() {
    let { columnas, datos, titulo, filtrar, actFiltrar, acciones, orden, total, inter } = this.props;

    if(orden) columnas=this.ordenar(columnas)

    if(total) datos.push(this.generarTotal(columnas, datos))

    const options = {
      search: false,
      filtering: false,
      sorting: false,
      headerStyle: {
        border: `${coloresEmma.fondocolor} 1px solid`,
        fontSize: '1.1rem',
        backgroundColor: coloresEmma.secondarycolor,
        color: coloresEmma.fondocolor
      },
      cellStyle: {
        border: `${coloresEmma.fondocolor} 1px solid`,
      },
      paging: false,
      maxBodyHeight: '500px'
    }

    const idioma = {
      header: {
          actions: 'Detalle'
      },
      body: {
        emptyDataSourceMessage: 'No se encontraron resultados'
      },
      toolbar: {
        searchTooltip: 'Buscar',
        searchPlaceholder: 'Buscar'
      },
      pagination: {
        labelRowsSelect: 'Filas',
        labelDisplayedRows: '{count} de {from}-{to}',
        firstTooltip: 'Primera Página',
        previousTooltip: 'Página anterior',
        nextTooltip: 'Próxima página',
        lastTooltip: 'Última página'
      }
    }

    let opciones = {
      Toolbar: props => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '20px' }}>
            <div style={{width: '90%'}}>
                <MTableToolbar {...props} />
            </div>
            { 
              filtrar && 
              <Tooltip title='Filtrar' aria-label="add" onClick={() => actFiltrar() }>
                <IconButton edge="end" color="secondary"><FilterListIcon /></IconButton>
              </Tooltip>
          }
          {
            inter ? 
              <ExportarReporteInter data={datos} columnas={columnas} nombre={titulo} />
              : 
              <ExportarReporte data={datos} columnas={columnas} nombre={titulo} />
          }
          </div>
      )
    }
  
  return (
    <MaterialTable
      title={titulo}
      columns={columnas}
      data={datos}
      options={options}
      localization={idioma}
      components={opciones}
      actions={acciones}
    />
  )
}
}

ListarTabla.propTypes = {

}

ListarTabla.defaultProps = {
  filtrar: false,
  acciones: [],
  orden: false,
  total: false,
  inter: false
};

export default ListarTabla
