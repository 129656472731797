import React from 'react';
import { BottomNavigation, BottomNavigationAction, Tabs, Tab, Hidden, Badge } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Procesos from '@material-ui/icons/ImportContacts';
import CreateIcon from '@material-ui/icons/Create';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import SchoolIcon from '@material-ui/icons/School';
import MatriculasNotificaciones from '../matriculas/matriculas/MatriculasNotificaciones';
import Notificaciones from '@material-ui/icons/NotificationImportant'
import CachedIcon from '@material-ui/icons/Cached';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import EditIcon from '@material-ui/icons/Edit';

export function MenuMatriculas({  anchorEl, setanchorEl, notificaciones = [], sele = 0, seleccionar = () => {} } ) {
	const clase = (item, selec, tipo = '') => (item === selec ? `item_selec${tipo}` : `item_modulo`);

	const pintar = (app, claseN = '') => {
		if (!app) {
			return (
				<div className={claseN}>
					<BottomNavigation
						showLabels
						className={clase('proceso', sele)}
						onClick={() => seleccionar('proceso')}
					>
						<BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
					</BottomNavigation>
					
					<MatriculasNotificaciones notificaciones={notificaciones} 
						render={(click) => <BottomNavigation showLabels onClick={click} className={clase('notificaciones', sele)} ><BottomNavigationAction label={'Notificación'} icon={<Badge badgeContent={notificaciones.length} color="secondary"><Notificaciones /></Badge>} />  </BottomNavigation>} 
						anchorEl={anchorEl} setanchorEl={setanchorEl} 
					/>

					<BottomNavigation
						showLabels
						className={clase('agregar', sele)}
						onClick={() => seleccionar('agregar')}
					>
						<BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
					</BottomNavigation>
					<BottomNavigation
						showLabels
						className={clase('historial', sele)}
						onClick={() => seleccionar('historial')}
					>
						<BottomNavigationAction label={'Historial'} icon={<Procesos />} />
					</BottomNavigation>
				</div>
			);
		} else {
			return (
				<div className={claseN}>
					<Tabs value={sele} variant='fullWidth' scrollButtons='off'>
						<Tab
							label={'Proceso'}
							icon={<LocationOnIcon className='menu_icono' />}
							className={clase('proceso', sele, '_app')}
							value='proceso'
							onClick={() => seleccionar('proceso')}
						/>
						<Tab
							label={'Nuevo'}
							icon={<CreateIcon className='menu_icono' />}
							className={clase('agregar', sele, '_app')}
							value='agregar'
							onClick={() => seleccionar('agregar')}
						/>
						<Tab
							label={'Historial'}
							icon={<Procesos className='menu_icono' />}
							className={clase('historial', sele, '_app')}
							value='historial'
							onClick={() => seleccionar('historial')}
						/>
					</Tabs>
				</div>
			);
		}
	};

	return (
		<div>
			<div>
				<Hidden xsDown> {pintar(false, 'menu_modulo_procesos')} </Hidden>
				<Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
			</div>
		</div>
	);
}

export function MenuAdministrar({ notificaciones = 0, sele = 0, seleccionar = () => {}, admin, submodulos }) {
	const clase = (item, selec, tipo = '') => (item === selec ? `item_selec${tipo}` : `item_modulo`);

	const pintar = (app, claseN = '') => {
		if (!app) {
			return (
				<div className={claseN} style={{width: '400px !important'}}>
					{submodulos && (
						submodulos.map(({codigo, nombre }) => (
							<BottomNavigation showLabels className={clase(codigo, sele)} onClick={() => seleccionar(codigo)}>
								<BottomNavigationAction label={nombre} icon={iconSeleccion(codigo)} />
							</BottomNavigation>
						))
					)}
					{admin && (
						<BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')} >
							<BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} />
						</BottomNavigation>
					)}
				</div>
			);
		} else {
			return (
				<div className={claseN} >
					<Tabs value={sele} variant='fullWidth' scrollButtons='off'>
						<Tab
							label={'Administrar'}
							icon={<SettingsIcon className='menu_icono' />}
							className={clase('administrar', sele, '_app')}
							value='administrar'
							onClick={() => seleccionar('administrar')}
						/>
						{submodulos && (
							submodulos.map(({codigo, nombre}) => (
								<Tab
									label={nombre}
									icon={iconSeleccion(codigo)}
									className={clase(codigo, sele, '_app')}
									value='codigo'
									onClick={() => seleccionar(codigo)}
								/>
							))
						)}
					</Tabs>
				</div>
			);
		}
	};

	return (
		<div>
			<div>
				<Hidden xsDown> {pintar(false, 'menu_modulo_procesos')} </Hidden>
				<Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
			</div>
		</div>
	);
}

function formatoNombre(nombre){
	switch (nombre) {
		case "Mat_Pro_Int": return "Inter semestrales";
		case "Mat_Pro_Pos": return "Cursos Posgrado"
		case "Mat_Pro_Pre": return "Cursos Pregrado"
	}
	return nombre;
}

function iconSeleccion(nombre){
	switch (nombre) {
		case "Mat_Pro_Int": return <AssignmentIcon />;
		case "Mat_Pro_Pos": return <SchoolIcon/>
		case "Mat_Pro_Pre": return <SchoolIcon/>
		case "Mat_Pro_Act_Datos": return <CachedIcon/>
		case "Mat_Pro_Certi": return <CardGiftcardIcon/>
		case "Mat_Pro_Cor_Nota": return <EditIcon/>
	}
	return <ChromeReaderModeIcon/>;

}

	export function MenuConfiguracion({ sele = 0, seleccionar = () => {}, menu }) {
		const clase = (item, selec, tipo = '') => (item === selec ? `item_selec${tipo}` : `item_modulo`);
		const pintar = (app, claseN = '') => {
			if (!app) {
				return (
					<div className={claseN}>
						{
							menu.map((item , index) =>{
								return <BottomNavigation
									key={index}
									showLabels
									className={clase( item.nombre , sele)}
									onClick={() => seleccionar( item.nombre)}
								>
									<BottomNavigationAction label={ formatoNombre(item.codigo)} icon={iconSeleccion(item.codigo)} />
								</BottomNavigation>
							})
						}
					</div>
				);
			} else {
				return (
					<div className={claseN}>
						<Tabs value={sele} variant='fullWidth' scrollButtons='off'>
							{
								menu.map((item , index) =>{
									return <Tab
										key={index}
										label={formatoNombre(item.codigo)}
										icon={iconSeleccion(item.codigo)}
										className={clase(formatoNombre(item.nombre), sele, '_app')}
										value ="/matriculasConf"
										onClick={() => seleccionar(item.nombre)}
									/>
								})
							}
						</Tabs>
					</div>
				);
			}
		};

	return (
		<div>
			<div>
				<Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
				<Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
			</div>
		</div>
	);
}
