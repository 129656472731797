import React, { Component } from 'react';
import { Grid, Paper } from '@material-ui/core'

//components
import ListarDatos from '../general/ListarDatos';
import { BtnDetalle } from '../general/BotonesAccion';

class Historial extends Component {

    actions = row => {
        let { abrirDetallePqrs } = this.props
        return (
            <div>
                <BtnDetalle 
                    callback={() => {
                        abrirDetallePqrs(row)
                    }}
                />
            </div>
        )
    }

    obtenerColor = codigo => {
        let color = '#ffffff'
        const colores = {
            '1': { 'backgroundColor': '#f5f5f5', 'color': '#000000' },
            '2': { 'backgroundColor': '#44c408', 'color': color },
            '3': { 'backgroundColor': '#c21b1b', 'color': color }
        }
        return codigo == null ? colores["1"] : colores["2"] ; 
    }

    render() {
        let { listaPqrs, mostrarModalAddPqrs } = this.props
        return (
            <ListarDatos
                id="tbl_inf_pqrs"
                datos={listaPqrs}
                titulo={"Historial de PQRS"}
                agregar={true}
                descargar={''}
                actAgregar={() => mostrarModalAddPqrs(true)}
                opciones={true}
                buscar={true}
                sub_titulo={"Mi PQRS"}
                avatar={({ estado_proceso }) => null}
                color_avatar={({ respuesta }) => this.obtenerColor(respuesta)}
                acciones={row => this.actions(row)}
                fila_principal={({ titulo }) => `Titulo: ${titulo.toUpperCase()}`}
                filas={[
                    // {
                    // 'mostrar': ({ estado_proceso }) => `Estado: ${estado_proceso.nombre}`,
                    // 'id': 'n_vacantes',
                    // 'enLista': true
                    // },
                ]}
            />
        );
    }
}

export default Historial;