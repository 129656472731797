import React from "react";
import { connect } from "react-redux";
import "../../global/css/floatingButtom.css";
import Contacts from "./CampusVirtual";
import { EstiloApp } from "./EstiloApp";
import { ThemeProvider } from '@material-ui/core'

class BotonQuestion extends React.Component {
    render() {
        return (
            <ThemeProvider theme={EstiloApp}>
                <Contacts></Contacts>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => {
    let { modulo } = state.redGlobal;
    return {
        modulo,
    };
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BotonQuestion);
