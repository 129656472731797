import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, } from '@material-ui/core/';

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "300px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },

}));

export default function RetosActivos({ fecha, descripcion, estado, nombre, imagen }) {
  let fecha_r = moment(fecha).format('YYYY-MM-DD, h:mm a');
  const classes = useStyles();

  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Ret_Gru': { 'backgroundColor': '#0044ff', color },
      'Ret_Env': { 'backgroundColor': '#ffc107', color },
      'Ret_Cal': { 'backgroundColor': '#5f02f5', color },
      'Ret_Env_e2': { 'backgroundColor': '#005E66', color },
      'Ret_Cal_e2': { 'backgroundColor': '#AF601A', color },
      'Ret_Env_e3': { 'backgroundColor': '#2E4053', color },
      'Ret_Cal_e3': { 'backgroundColor': '#685DE3', color },
      'Ret_Env_Fn': { 'backgroundColor': '#B329E6', color },
      'Ret_Cal_Fn': { 'backgroundColor': '#E4D281', color },
      'Ret_Sel': { 'backgroundColor': '#09BA69', color },
      'Ret_Dep': { 'backgroundColor': '#5af7fa', color },
      'Ret_Ire': { 'backgroundColor': '#bffa5a', color },
      'Ret_Gse': { 'backgroundColor': '#46fa64', color },
      'Ret_Act': { 'backgroundColor': '#6c036e', color },
      'Ret_Des': { 'backgroundColor': '#fa3802', color },
      'Ret_Des_Prof': { 'backgroundColor': '#fa3802', color },
      'Ret_Des_Depr': { 'backgroundColor': '#fa3802', color },
      'Ret_Des_Intr': { 'backgroundColor': '#fa3802', color },
      'Tod_Ret': { 'backgroundColor': '#0d47a1', color },
    }
    return (colores[codigo])
  }
  
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={obtenerColor(estado.codigo)}> {estado.nombre.charAt(0).toUpperCase()} </Avatar>}
        subheader={fecha_r}
        title={<span style={{ fontSize: '14px' }}>{nombre}</span>}
      />
      <CardContent>
        <Grid container direction={'row'} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={imagen} className={classes.image} alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
