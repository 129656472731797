import React, { useEffect } from 'react'
import Instituciones from '../administrar/instituciones/InstitucionesCrear'
import { useDispatch, useSelector } from "react-redux";
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { mostrarModalSolInst, mostrarModalSolRed } from '../../../redux/actions/actInternacionalizacion';
import RedCrear from './RedCrear';
import { crear_form_data, formulario, mostrarError } from '../../../global/js/funciones';

const SolicitarInstitucion = ({ periodo, tipoSolicitud, subtipoSolicitud, mostrarModalOpci }) => {
  const dispatch = useDispatch();
  const { modalSolInsti } = useSelector((state) => state.redInternacionalizacion);

  useEffect(() => {
    dispatch(mostrarModalSolInst(true))
  }, [])

  const handleCloseModal = () => {
    dispatch(mostrarModalSolInst(false));
  };

  const guardarInstitucion = async (datos, callback) => {
    let data = await crear_form_data(datos);
    data.append('periodo', periodo);
    data.append('tipo_solicitud', tipoSolicitud);
    data.append('subtipo_solicitud', subtipoSolicitud);
    formulario(
      `api/v1.0/internacionalizacion/convenios/solicitar_universidad`,
      data,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            // this.setState({ cargando: true });
            // this.props.obtenerInstituciones();
            handleCloseModal();
            mostrarModalOpci(false);
            // mostrarModalAddInstituciones(false);
            // this.setState({ cargando: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
        callback();

      }

    )
  }

  return (
    <Instituciones
      titulo={'¡ Nueva Institución !'}
      modalAddInstituciones={modalSolInsti}
      mostrarModalAddInstituciones={handleCloseModal}
      guardarInstitucion={guardarInstitucion}
      actualizarMensaje={actualizarMensaje}
      multiple={true}
    />
  )
}


const SolicitarRedAcademica = ({ periodo, tipoSolicitud, subtipoSolicitud, mostrarModalOpci, opcionDetalle }) => {
  const dispatch = useDispatch();

  const { modalSolRed } = useSelector((state) => state.redInternacionalizacion);

  useEffect(() => {
    dispatch(mostrarModalSolRed(true))
  }, [])

  const handleCloseModal = () => {
    dispatch(mostrarModalSolRed(false));
  };

  const guardarRedAcademica = async (datos, callback) => {
    let data = await crear_form_data(datos);
    data.append('periodo', periodo);
    data.append('tipo_solicitud', tipoSolicitud);
    data.append('subtipo_solicitud', subtipoSolicitud);
    formulario(
      `api/v1.0/internacionalizacion/convenios/solicitar_universidad`,
      data,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            // this.setState({ cargando: true });
            // this.props.obtenerInstituciones();
            handleCloseModal();
            mostrarModalOpci(false);
            // mostrarModalAddInstituciones(false);
            // this.setState({ cargando: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
        callback();
      }
    )

  }

  const tituloDinamico = opcionDetalle && opcionDetalle.nombre
    ? `Registro de ${opcionDetalle.nombre}`
    : 'Registro de red académica';

  return (
    <RedCrear modalAddRed={modalSolRed} opcionDetalle={opcionDetalle} handleCloseModal={handleCloseModal} titulo={tituloDinamico} guardarRedAcademica={guardarRedAcademica} actualizarMensaje={actualizarMensaje} />
  )
}

export { SolicitarInstitucion, SolicitarRedAcademica };
