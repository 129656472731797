import React, { Component } from 'react'

// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import { DialogActions, Grid, Dialog, DialogContent, ListSubheader, Stepper, Step, StepLabel, Paper } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Text } from '@material-ui/core';


// FUNCIONES GENERALES
import { BtnForm, BtnCargar } from "../../general/BotonesAccion";
import { obtenerEstadosProceso, obtenerPermisosValorAlt, obtenerValoresFiltros } from "../../../global/js/funciones";
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from "../../general/TareasTerminadas";
import AlertasSimple from '../../general/AlertasSimple';

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

// HELPERS
import { ESTADOS } from '../Helpers'

const configStep = ['Datos Iniciales', 'Requisitos'];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>

      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre, id } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion({ codigo, id })} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

function AttachComponent(component_props) {
  let { submit, actualizarArchivos, estado } = component_props


  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);

    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    return <div>{cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                actualizarArchivos={actualizarArchivos}
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                estado={estado}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


class RetosDesecharGrupo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      estados_siguiente: [],
      seleccion: 'gestion',
      activeStep: 0,
    }
  }

  async componentDidMount() {
  }


  async componentDidUpdate({ modalDesecharGrupo }, { estado }) {
    let { modalDesecharGrupo: modalDesecharGrupoNew, reto } = this.props;
    let { estado: estadoNew } = this.state;

    if (estadoNew.codigo !== estado.codigo && estadoNew.codigo) {
      let acuerdo_reto_acta_reto = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Ret_Act' }])

      this.setState({ acuerdo_reto_acta_reto: acuerdo_reto_acta_reto.length > 0 ? acuerdo_reto_acta_reto[0].descripcion : '' });
    }

    if (modalDesecharGrupo !== modalDesecharGrupoNew) {
      if (modalDesecharGrupoNew) {
        this.reiniciarEstado();
        let estados_siguiente = await obtenerEstadosProceso(reto.tipo_reto.id, [{ 'llave': 'principal', 'valor': reto.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS }]);
        estados_siguiente.forEach((objeto, index) => {
          if (objeto.secundario.codigo == 'Ret_Env') {
            estados_siguiente.splice(index, 1)
          }
        });
        // let mensaje = []

        this.setState({
          estados_siguiente,
          porcentaje: reto && reto.porcentaje ? reto.porcentaje : '',
          cargando: false,
          // mensaje: mensaje.length > 0 ? mensaje[0].descripcion : ''
        });

      }
    }
  }

  configStepContent = () => {
    let { actualizarMensaje } = this.props
    let { activeStep, initialTransition, estado, acuerdo_reto_acta_reto } = this.state
    let informativo_acta_reto = [{ item: acuerdo_reto_acta_reto }]

    switch (activeStep) {
      case 0:
        return (
          this.pintar()
        );
      case 1:
        return (
          <>
            <AttachComponent
              {...{
                initialTransition,
                actualizarMensaje,
                submit: this.submit,
                estado: estado
              }}
            />

            {
              estado.codigo == 'Ret_Gru' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={informativo_acta_reto}
                  margin='0px'
                />
              </React.Fragment>
            }
          </>
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
    }
  }

  submit = () => {
    let { estado } = this.state;
    let { reto, gestionarRetosNegados } = this.props;

    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {  estado: estado.codigo, codigo: estado.id }
    gestionarRetosNegados(reto.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }))
  }

  obtenerObservaciones = async () => {
    this.setState({ cargando: true });
    let { acuerdo_validaciones } = this.state
    let obs = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Val_Vol_Can' }])
    let acu_validaciones = [{ item: acuerdo_validaciones }]
    obs.map(({ secundario }) => acu_validaciones.push({ item: secundario.nombre }));
    this.setState({ acu_validaciones });
  }



  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      estados_siguiente: [],
      seleccion: 'gestion',
      activeStep: 0,
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  handleStep = direction => {
    let { actualizarMensaje } = this.props
    let { activeStep, estado } = this.state
    let sw = true

    if (!estado.codigo && activeStep == 0) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false
    }
    if (sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }



  pintarEstados() {
    let { estado, estados_siguiente } = this.state;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado.codigo} estados_siguiente={estados_siguiente} />
          </Grid>
        </Grid>
      </>
    )
  }

  pintar() {
    let { seleccion } = this.state;
    return (<div>
      {seleccion === 'gestion' && this.pintarEstados()}
    </div>)
  }

  render() {
    let { modalDesecharGrupo, mostrarModalDesecharGrupo } = this.props;
    let { cargando, titulo_res, mensaje } = this.state;
    // let mensaje_reto = mensaje ? [{ item: mensaje }] : []

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalDesecharGrupo} onClose={() => mostrarModalDesecharGrupo(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={'¿ Gestionar  ?'} mostrarModal={mostrarModalDesecharGrupo} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          {
            !cargando ?
              <Grid container>
                {
                  <Grid item xs={12} style={{ marginTop: "30px" }}>
                    {this.configStepContent()}
                    {/* {
                      mensaje &&
                      <AlertasSimple
                        tipo='info'
                        titulo={'Estimado gestor, tener en cuenta: '}
                        lista={mensaje_reto}
                      />
                    } */}
                  </Grid>
                }
              </Grid> :
              <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </DialogContent>
        <DialogActions>
          {
            !cargando &&
            <BtnForm texto="GUARDAR" callback={() => this.submit([])} type="submit" />
          }

        </DialogActions>
      </Dialog>
    )
  }
}

export default RetosDesecharGrupo;