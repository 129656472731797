const { MODAL_INFORME } = require("./actPracticas")

// RETOS ESTUDIANTES
const MODAL_ADD_RETOS = 'MODAL_ADD_RETOS'
const MODAL_ADD_GRUPO_RETOS = 'MODAL_ADD_GRUPO_RETOS'
const MODAL_DET_RETOS = 'MODAL_DET_RETOS'
const MODAL_ADJ_RETOS = 'MODAL_ADJ_RETOS'
const MODAL_FILTRO_RETOS = 'MODAL_FILTRO_RETOS'
const MODAL_UPDATE_PERIODO = 'MODAL_UPDATE_PERIODO'
const MODAL_GES_RETOS = 'MODAL_GES_RETOS'
const MODAL_PERM_ASIGNATURAS = 'MODAL_PERM_ASIGNATURAS'
const MODAL_DESECHAR_GRUPO = 'MODAL_DESECHAR_GRUPO'
const MODAL_UPDATE_EVIDENCIA = 'MODAL_UPDATE_EVIDENCIA'
const MODAL_ADD_FILTROS_RETOS = 'MODAL_ADD_FILTROS_RETOS'

// RETOS GESTION


//MODAL CREAR RETOS
const mostrarModalAddRetos = show => {
    return {
        type: MODAL_ADD_RETOS,
        show,
    }
}

// MONDAL CREAR GRUPO RETOS
const mostrarModalAddGrupoRetos = show => {
    return {
        type: MODAL_ADD_GRUPO_RETOS,
        show,
    }
}

// MODAL DETALLES RETOS
const mostrarModalDetalleRetos = show => {
    return {
        type: MODAL_DET_RETOS,
        show,
    }
}

// MODAL DETALLE ADJUENTO RETOS

const mostrarModalAdjuntos = show => {
    return {
        type: MODAL_ADJ_RETOS,
        show,
    }
}

// MODAL GESTION RETOS

const mostrarGestionRetos = show => {
    return {
        type: MODAL_GES_RETOS,
        show,
    }
}

// MODAL FILTRO RETOS

const mostrarModalFiltroRetos = show => {
    return {
        type: MODAL_FILTRO_RETOS,
        show,
    }
}

// MODAL MODIFICAR PERIODO
const mostrarModalModificarPeriodo = show => {
    return {
        type: MODAL_UPDATE_PERIODO,
        show,
    }
}

// MODAL MOSTAR INFORME
const mostrarModalInforme = show => {
    return {
        type: MODAL_INFORME,
        show
    }
}

// MODAL PERMISOS ASIGNATURAS
const mostrarModalPermisoAsignatura = show => {
    return {
        type: MODAL_PERM_ASIGNATURAS,
        show,
    }
}

// MODAL DESECHAR GRUPO
const mostrarModalDesecharGrupo = show => {
    return{
        type: MODAL_DESECHAR_GRUPO,
        show,
    }
}

// MODAL ACTUALIZAR EVIDENCIA
const mostrarModalActualizarEvidencia = show => {
    return{
        type: MODAL_UPDATE_EVIDENCIA,
        show,
    }
}

const mostrarModalAddFiltrosRetos = show => {
    return {
      type: MODAL_ADD_FILTROS_RETOS,
      show,
    }
  }


module.exports = {
    MODAL_ADD_RETOS,
    MODAL_ADD_GRUPO_RETOS,
    MODAL_DET_RETOS,
    MODAL_ADJ_RETOS,
    MODAL_FILTRO_RETOS,
    MODAL_UPDATE_PERIODO,
    MODAL_GES_RETOS,
    MODAL_INFORME,
    MODAL_PERM_ASIGNATURAS,
    MODAL_DESECHAR_GRUPO,
    MODAL_UPDATE_EVIDENCIA,
    MODAL_ADD_FILTROS_RETOS,

    mostrarModalAddRetos,
    mostrarModalAddGrupoRetos,
    mostrarModalDetalleRetos,
    mostrarModalAdjuntos,
    mostrarModalFiltroRetos,
    mostrarModalModificarPeriodo,
    mostrarGestionRetos,
    mostrarModalInforme,
    mostrarModalPermisoAsignatura,
    mostrarModalDesecharGrupo,
    mostrarModalActualizarEvidencia,
    mostrarModalAddFiltrosRetos,
}