import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

// COMPONETES

// ICONOS MENU
import { Hidden, Badge } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SettingsIcon from '@material-ui/icons/Settings';

const clase = (item, selec, tipo = "") =>
  item === selec ? `item_selec${tipo}` : `item_modulo`;

// MENU VALIDACIONES - ESTUDIANTES

export function MenuAdmInvitados({ seleccionar, sele, administrador }) {
  const pintar = (app, claseN = "") => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase("btn_proceso", sele)} onClick={() => seleccionar("btn_proceso")} >
            <BottomNavigationAction label={"Procesos"} icon={<LocationOnIcon />} />
          </BottomNavigation>
          {administrador && 
            <BottomNavigation showLabels className={clase("btn_admin", sele)} onClick={() => seleccionar("btn_admin")} >
              <BottomNavigationAction label={"Administrar"} icon={<SettingsIcon />} />
            </BottomNavigation>
          }
        </div>
      );
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels>
            <BottomNavigationAction label={"Procesos"} icon={<LocationOnIcon />} className={clase("btn_proceso", sele)}  onClick={() => seleccionar("btn_proceso")}  />
            <BottomNavigationAction label={"Administrar"} icon={<SettingsIcon />} className={clase("btn_admin", sele)} onClick={() => seleccionar("btn_admin")} />
          </BottomNavigation>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
        <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
      </div>
    </div>
  );
}
