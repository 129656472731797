import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardHeader, CardContent, Avatar, Typography } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    content_text: {
        padding: "15px 10px 0px 10px",
    },
    content_img: {
        textAlign: 'center',
        height: "130px",
    },

}));
export default function CreditoOpcionesTipo({ data, image, avatarcss, cardcss = { maxWidth: "100%", height: '380px', cursor: 'pointer' }, imagecss = { width: '130px', maxWidth: '200px', minWidth: '100px', height: "130px" } }) {
    let { nombre, descripcion, opciones } = data;
    const classes = useStyles();
    return (
        <Card style={cardcss} elevation={1}>
            <CardHeader
                avatar={<Avatar aria-label="recipe" style={avatarcss}> {nombre.charAt(0)} </Avatar>}
                title={`${nombre}`}
                subheader={opciones}
            />
            <CardContent >
                <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
                        <img src={image} style={imagecss} alt='Imagen Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.content_text}>
                        <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                            {descripcion}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}