
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//COMPONENTES DEL MODULO
import PracticasEmpresasListar from "./PracticasEmpresasListar";
import ValoresAgregar from '../../genericas/valores/ValoresAgregar'
import ValoresModificar from '../../genericas/valores/ValoresModificar'
import ValoresAgregarPermiso from '../../genericas/valores/ValoresAgregarPermiso'
import ConfirmarAccion from '../../general/ConfirmarAccion';
import Detalle from '../../general/Detalle';
import { api } from "../../../global/js/funciones";
import PracticaDetalleEmpresa from './PracticaDetalleEmpresa';
import ValoresAgregarPractica from './ValoresAgregarPractica';
import ValoresModificarPractica from './ValoresModificarPractica';
//import {mostrarModalAddPermisoValoresPractica,modalAddSucursalPractica,modalAddPermisosValoresPractica,mostrarModalAddSucursalPractica} from '../../../redux/actions/actPracticas';



class PracticasEmpresas extends Component {


  render() {

    let {
      mostrarModalDelEmpresa,
      mostrarModalModEmpresa,
      mostrarModalAddEmpresa,
      actualizarDataEmpresa,
      empresas,
      empresa,
      modalAddPermisosValores,
      
    
      
      actualizarDataPermisos,
      mostrarModalAddPermisoValores,
      
      modalAddEmpresa,
      modalDelEmpresa,
      modalModEmpresa,
      eliminarEmpresa,
      guardarEmpresa,
      modificarEmpresa,
      modalDetValores,
      mostrarModalDetValores,
      modalAddSucursal,
      mostrarModalAddSucursal,
      guardarSucursalPermiso,
      modalAddSucursalPractica,
      mostrarModalAddSucursalPractica
    
    } = this.props;

   

    const configEmpresa = () => {
      return {
        inputCodigo: { 'label': 'NIT(Ejemplo : 890104530-9)', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputNombre: { 'label': 'Nombre', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputDescripcion: { 'label': 'Actividad Económica', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputValora: { 'label': 'Dirección', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorb: { 'label': 'Barrio', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorc: { 'label': 'Ciudad', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValord: { 'label': 'Departamento', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValore: { 'label': 'Telefono', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorf: { 'label': 'Fax', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputValorg: { 'label': 'Programa - Area de la empresa', 'mostrar': false, 'tipo': 'text', 'requerido': false, 'mayusculas': false },
        inputArchivo: { 'label': 'Adjuntar certificado (Camara de comercio)', 'mostrar': true, 'tipo': 'file', 'requerido': true },
      }
    }

    const configSucursal = () => {
      return {
        inputCodigo: { 'label': 'NIT(Ejemplo : 890104530-9)', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputNombre: { 'label': 'Direccion', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputDescripcion: { 'label': 'Actividad Económica', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputValora: { 'label': 'Barrio', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorb: { 'label': 'Ciudad', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorc: { 'label': 'Departamento', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValord: { 'label': 'Telefono', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValore: { 'label': 'Fax', 'mostrar': false, 'tipo': 'text', 'requerido': true },
        inputValorf: { 'label': 'Correo', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorg: { 'label': 'Programa - Area de la empresa', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorh: { 'label': '', 'mostrar': false, 'tipo': 'text', 'requerido': false },
				inputValori: { 'label': '', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputArchivo: { 'label': 'Adjuntar certificado (Camara de comercio)', 'mostrar': false, 'tipo': 'file', 'requerido': false },
      }
    }

    const configDetail = data => {
      let { codigo, descripcion, archivo, nombre,id } = data
      
      return (
        [
          { 'label': 'Nombre', 'value': nombre, },
          { 'label': 'NIT', 'value': codigo, },
          { 'label': 'Actividad Económica', 'value': descripcion, },
          { 'label': 'Certificado', 'value': archivo ? <a href={`${api}${archivo}`} target="_balck" className='sin_decorador'>Abrir</a> : 'Sin Adjunto' },
        ]
      )
    }

    return (
      <div>

        <PracticasEmpresasListar
          mostrarModalAddPermisoValores={mostrarModalAddPermisoValores}
          mostrarModalModValores={mostrarModalModEmpresa}
          mostrarModalDelValores={mostrarModalDelEmpresa}
          actualizarDataValor={actualizarDataEmpresa}
          empresas={empresas}
          modalDelValores={modalDelEmpresa}
          mostrarModalDetValores={mostrarModalDetValores}
          mostrarModalAddValores={mostrarModalAddEmpresa}
        />

        {/* <ValoresAgregar
          mostrarABC={true}
          modalAddValores={modalAddEmpresa}
          mostrarModalAddValores={mostrarModalAddEmpresa}
          guardarValor={guardarEmpresa}
          inputs={configEmpresa()}
          titulo='¡ Nueva Empresa !'
        /> */}
        <ValoresAgregarPractica
          mostrarABC={true}
          modalAddValores={modalAddEmpresa}
          mostrarModalAddValores={mostrarModalAddEmpresa}
          guardarValor={guardarEmpresa}
          inputs={configEmpresa()}
          titulo='¡ Nueva Empresa !'
        />
        {/* <ValoresModificar
          mostrarABC={true}
          modalModValores={modalModEmpresa}
          mostrarModalModValores={mostrarModalModEmpresa}
          valor={empresa}
          modificarValor={modificarEmpresa}
          inputs={configEmpresa()}
          titulo='¡ Modificar Empresa !' 
        />*/}
        <ValoresModificarPractica
          mostrarABC={true}
          modalModValores={modalModEmpresa}
          mostrarModalModValores={mostrarModalModEmpresa}
          valor={empresa}
          modificarValor={modificarEmpresa}
          inputs={configEmpresa()}
          titulo='¡ Modificar Empresa !'
        />

        {/* <ValoresAgregarPermiso
          mostrarModalAddPermisoValores={(show) => mostrarModalAddPermisoValores(show)}
          modalAddPermisosValores={modalAddPermisosValores}
          actualizarDataPermisos={actualizarDataPermisos}
          valor={empresa}
          filtro={17}
          titulo='¡ Asignar Sucursales !'
          administra={false}
          modalAddValorPermiso={modalAddSucursal}
          mostrarModalAddValorPermiso={mostrarModalAddSucursal}
          inputs_agregar={configSucursal()}
          titulo_agregar='¡ Nueva Sucursal !'
          agregarValor={true}
          guardar_agregar={guardarSucursalPermiso}
        /> */}

        <PracticaDetalleEmpresa
          titulo={'¡ Detalle Empresa !'}
          actualizarDataPermisos={actualizarDataPermisos}
          open={modalDetValores}
          config={empresa ? configDetail(empresa) : []}
          cerrar={() => mostrarModalDetValores(false)}
          idEmpresa={empresa.id}
          modalAddPermisosValores={modalAddPermisosValores}
          mostrarModalAddPermisoValores={(show) => mostrarModalAddPermisoValores(show)}
          valor={empresa}
          filtro={17}
          administra={false}
          modalAddValorPermiso={modalAddSucursal}
          mostrarModalAddValorPermiso={mostrarModalAddSucursal}
          inputs_agregar={configSucursal()}
          agregarValor={true}
          guardar_agregar={guardarSucursalPermiso}
          
          
         

        />
        <ConfirmarAccion mensaje1={`La empresa "${empresa.nombre}" será eliminada, `} ejecutarAccion={eliminarEmpresa} mostrarModalConfAccion={mostrarModalDelEmpresa} modalConAccion={modalDelEmpresa} dato={empresa} titulo={'¿ Eliminar Empresa ?'} />
      </div >
    )
  }
}



//Props del componente
PracticasEmpresas.propTypes = {
  //variables
  empresas: PropTypes.array.isRequired,
  modalModEmpresa: PropTypes.bool.isRequired,
  modalAddEmpresa: PropTypes.bool.isRequired,
  modalDelEmpresa: PropTypes.bool.isRequired,
  empresa: PropTypes.object.isRequired,
 
  
  //funciones
  actualizarDataEmpresa: PropTypes.func.isRequired,
  mostrarModalAddEmpresa: PropTypes.func.isRequired,
  mostrarModalModEmpresa: PropTypes.func.isRequired,
  mostrarModalDelEmpresa: PropTypes.func.isRequired,
 
}



export default PracticasEmpresas;
