
const MODAL_DET_OVA = 'MODAL_DET_OVA'


const mostrarModalDetalleOva = show => {
    return {
      type: MODAL_DET_OVA,
      show,
    }
  }

  
module.exports = {
    MODAL_DET_OVA,
    mostrarModalDetalleOva,
  }
  