import {
  MODAL_ADD_PERSONA,
  MODAL_MOD_PERSONA,
  MODAL_DEL_PERSONA,
  MODAL_EDIT_PASS,
  ACT_DATA__PERSONA,
  ACT_DATA__PERSONAS,
  ACT_RENDER_PERSONAS,
  MODAL_DETALLE_PERSONA,
  PROGRAMAS,
} from "../actions/actPersonas";

const initialState = {
  modalAddPersona: false,
  modalModPersona: false,
  modalDelPersona: false,
  modalEditPass: false,
  modalDetallePersona: false,
  persona: {
    id: 0,
    tipo: 0,
    tipo_identificacion: {},
    identificacion: 0,
    login_directorio: 0,
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    correo: '',
    usuario: '',
  },
  personas: [],
  programas: [],
  renderPersona: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_PERSONA:
      return Object.assign({}, state, {
        modalAddPersona: action.show
      })
    case MODAL_MOD_PERSONA:
      return Object.assign({}, state, {
        modalModPersona: action.show
      })
    case MODAL_DEL_PERSONA:
      return Object.assign({}, state, {
        modalDelPersona: action.show
      })
    case MODAL_EDIT_PASS: 
      return Object.assign({}, state, {
        modalEditPass: action.show
      })
    case ACT_DATA__PERSONA:
      return Object.assign({}, state, {
        persona: action.persona
      })
    case ACT_DATA__PERSONAS:
      return Object.assign({}, state, {
        personas: action.personas
      })
    case ACT_RENDER_PERSONAS:
      return Object.assign({}, state, {
        renderPersona: action.render
      })
    case MODAL_DETALLE_PERSONA:
      return Object.assign({}, state, {
        modalDetallePersona: action.show
      })
    case PROGRAMAS:
      return Object.assign({}, state, {
        programas: action.programas
      })
    default:
      return state;
  }
}

export default reducer;
