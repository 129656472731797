import React, { Component } from 'react';
import { IconButton, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, OutlinedInput, Checkbox, ListItemText, ButtonGroup, InputAdornment, List, ListItem, Link, Table, TableBody, TableRow, TableCell, Divider, Accordion, AccordionSummary, AccordionDetails, TableHead, TableContainer, Box, FormControlLabel, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TareasTerminadas from '../../../general/TareasTerminadas';
import AppBarModal from '../../../general/AppBarModal';
import InputFile from '../../../general/InputFile';
import emma_w from '../../../../global/imagenes/emma_w.png';
import { BtnDetalle, BtnForm } from '../../../general/BotonesAccion';
import { api, consulta, formulario, getError, mostrarError, obtenerPermisosValorAlt, obtenerValoresFiltros, validateInputs } from '../../../../global/js/funciones';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CODIGOS_TIPO_ACTIVIDAD_NO_APLICA, CODIGO_CURSO_CORTO, CODIGO_CURSO_CORTO_PROFE, CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD, CODIGO_TIPO_CONCEPTO_OTRO, DPTO_ADMINISTRATIVO, MODALIDADES_VIRTUALES, OTRA_PLATAFORMA, OTRO_SUBTIPO_ACTIVIDAD, OTRO_TIPO_ACTIVIDAD, SUBTIPOS_ACTIVIDADES_MOVILIDAD, SUBTIPO_ACTI_ACADEMICO, SUBTIPO_ACTI_EMPRESARIAL, TIPOS_ACTIVIDADES_MOVILIDAD, TIPOS_MODALIDADES, TIPOS_MOVILIDADES, TIPOS_PLATAFORMAS, TIPO_FIN_INSTITUCIONAL, TIPO_MOVILIDADES_PROFESOR, TIPO_MOV_DOCENTE, TIPO_MOV_ESTUDIANTE, TIPO_PARTICIPANTE_ADMIN, TIPO_PARTICIPANTE_DOC, TIPO_PARTICIPANTE_ESTU } from '../helper';
import { Person, PersonAdd, Save, Cancel, LocalLibrary, Class, ExpandMore } from '@material-ui/icons/';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale'
import EnviarArchivo from '../../../general/EnviarArchivo';
import BuscarPersona from '../../../general/BuscarPersona';
import ConvocatoriasBuscarConvenio from '../../convocatorias/ConvocatoriasBuscarConvenio';
import AddIcon from '@material-ui/icons/Add';
import ConfirmarAccion from '../../../general/ConfirmarAccion';
import DeleteIcon from '@material-ui/icons/Delete';
import emma_s1 from '../../../../global/imagenes/emma_s1.png';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import { actualizarMensaje } from '../../../../redux/actions/actGlobal';

const styles = theme => ({
  root: {
    border: '1px solid #BDBBBB',
    borderRadius: '30px',
    margin: 'auto',
    marginTop: '10px',
    fontSize: '10px',
    textAlign: 'center',
    width: 200,
    maxWidth: 250,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  img: {
    margin: '0 auto',
    width: '100px',
    paddingTop: '20px',
    paddingBottom: '10px',
  },
  colorEmma: {
    color: 'rgb(199, 158, 50, 0.5)',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contFooter: {
    width: '95%',
    backgroundColor: '#c79e32',
    textAlign: 'center',
    borderRadius: '12px',
  },
  textoFooter: {
    textTransform: 'uppercase',
    color: 'white',
    fontSize: 13,
  },
  inactiva: {
    opacity: '0.5', /* Opacidad reducida */
    // filter: 'blur(2px)'/* Filtro de desenfoque */
  }
});

class AgregarActividad extends Component {
  render() {
    let { mostrarForm, cargandoForm, errores, tipo_actividad, tipos_actividades, otro_tipo_actividad, subtipo_actividad, subtipos_actividades, otro_subtipo_actividad, horas, institucion_seleccionada, instituciones, modal_buscar_institucion, modal_quitar_institucion, fecha_inicio, fecha_fin, responsable_actividad, tipos_responsables, administrativos_responsable, administrativos_resp, dpto_responsable, departamentos_resp, facultad_responsable, facultades_resp, titulo_tema, descripcion_actividad, resultados_descripcion, info_complementaria, institucion, departamento_docente, programas_docente, programa_docente, info_complementaria_url } = this.props.state
    let { onChange, onChangeDate, onChangeFile, onChangeMulti, actualizarEstado, seleccionarInstitucionActividad, fileInput, obtenerBorradorActividad } = this.props.actions
    let { instructivo } = this.props
    const CHARACTER_LIMIT = 250; //Antes 750
    const CHARACTER_LIMIT_TITULO = 250; //Antes 450
    const isDptoAdmin = departamento_docente == DPTO_ADMINISTRATIVO ? true : false;
    const seleccion = institucion_seleccionada ? instituciones.find(inst => inst.id === institucion_seleccionada) : { id: 0 };

    const quitarInstiListarDatos = (id) => {
      const inst = instituciones.filter(inst => inst.id !== id);
      actualizarEstado('instituciones', inst);
    };

    // if (info_complementaria) {
    //   let f = info_complementaria.includes("fakepath") ? document.getElementById('info_complementaria').files[0] : '';
    //   console.log(f)
    //   onChangeFile(f)
    //   // this.props.actions.setState({ info_complementaria_f: f });
    //   // archivoSend = modelo_convenio.includes("fakepath") ? document.getElementById('modelo_convenio').files[0] : '';

    // }

    const onClickEliminar = data => data && actualizarEstado('modal_quitar_institucion', true);

    const quitarInstitucionSeleccionada = id => {
      if (id) {
        actualizarEstado('modal_quitar_institucion', false);
        actualizarEstado('instituciones', instituciones.filter(inst => inst.id !== id));
        actualizarEstado('institucion_seleccionada', '');
      }
    };

    return (
      <>
        {
          mostrarForm ?
            <>
              {!cargandoForm ?
                <Grid container spacing={2} style={{}}>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Aquí, el solicitante debe seleccionar el departamento y programa al que pertenece.
                    </Alert>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                      <FormControl className='form-control' required error={getError('departamento_docente', errores).length > 0} size='small'>
                        <InputLabel id='departamento_docente_label'>Departamento del solicitante</InputLabel>
                        <Select
                          labelId='departamento_docente_label'
                          id='departamento_docente'
                          name='departamento_docente'
                          value={departamento_docente}
                          label='Departamento al que pertenece el solicitante'
                          onChange={onChange}
                          fullWidth
                        >
                          {
                            departamentos_resp.length > 0 ?
                              departamentos_resp
                                // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                                .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                              : <MenuItem value=""><em>Seleccione el departamento del solicitante</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('departamento_docente', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                      <FormControl className='form-control' required error={getError('programa_docente', errores).length > 0} size='small'>
                        <InputLabel id='programa_docente_label'>{isDptoAdmin ? 'Departamento' : 'Programa'} al que tributará la movilidad</InputLabel>
                        <Select
                          labelId='programa_docente_label'
                          id='programa_docente'
                          name='programa_docente'
                          value={programa_docente}
                          label={isDptoAdmin ? 'Departamento al que tributará la movilidad' : 'Programa al que tributará la movilidad'}
                          onChange={onChange}
                          fullWidth
                        >
                          {
                            programas_docente.length > 0 ? (
                              programas_docente
                                .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                .map(tipo =>
                                  isDptoAdmin ? (
                                    <MenuItem key={tipo.id} value={tipo.id}>
                                      {tipo.nombre}
                                    </MenuItem>
                                  ) : (
                                    <MenuItem key={tipo.secundario.id} value={tipo.secundario.id}>
                                      {tipo.secundario.nombre}
                                    </MenuItem>
                                  )
                                )
                            ) : (
                              <MenuItem value="">
                                <em>{isDptoAdmin ? 'Seleccione el departamento' : 'Seleccione el programa'}</em>
                              </MenuItem>
                            )
                          }
                        </Select>
                        <FormHelperText>{getError('programa_docente', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Por favor, seleccione las instituciones que hicieron parte de la actividad.
                    </Alert>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl className='form-control' required error={getError('instituciones', errores).length > 0} size='small' >
                      <Select
                        id='institucion_seleccionada'
                        name='institucion_seleccionada'
                        value={institucion_seleccionada}
                        fullWidth
                        displayEmpty
                        onChange={onChange}
                        style={{ height: '2.8rem' }}
                      >

                        <MenuItem value="">
                          <em>{`${instituciones.length} institucion(es) seleccionada(s)`}</em>
                        </MenuItem>

                        {instituciones && instituciones.length > 0 &&
                          instituciones.sort(({ id: previousID }, { id: currentID }) => previousID - currentID).map((inst) => <MenuItem key={inst.id} value={inst.id}>{inst.codigo_convenio}{" " + inst.institucion}</MenuItem>)
                        }
                      </Select>
                      <FormHelperText>{getError('instituciones', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <ConvocatoriasBuscarConvenio
                      seleccionar={({ id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio, actividades, tipoConvenio }) => seleccionarInstitucionActividad({ id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio, actividades, tipoConvenio })}
                      quitar={({ id }) => quitarInstiListarDatos(id)}
                      // quitar={({ id }) => console.log(id)}
                      // quitar={({ id }) => actualizarEstado('instituciones', instituciones.filter(inst => inst.id !== id))}
                      seleccionadas={instituciones}
                      modalBuscarUniversidad={modal_buscar_institucion}
                      mostrarModalBuscarUniversidad={(modal_buscar_institucion) => actualizarEstado('modal_buscar_institucion', modal_buscar_institucion)}
                    />
                    <ConfirmarAccion
                      mensaje1={institucion_seleccionada && `Se quitará ${seleccion.institucion} de la lista, `}
                      ejecutarAccion={quitarInstitucionSeleccionada}
                      mostrarModalConfAccion={(modal_quitar_institucion) => actualizarEstado('modal_quitar_institucion', modal_quitar_institucion)}
                      modalConAccion={modal_quitar_institucion}
                      dato={institucion_seleccionada ? seleccion : { id: 0 }}
                      titulo={'¿ Quitar institución ?'}
                    />
                    <IconButton aria-label="add" style={styles.colorDelete} onClick={() => actualizarEstado('modal_buscar_institucion', true)}>
                      <AddIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => onClickEliminar(institucion_seleccionada)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      En esta sección el solicitante debe registrar la información de la actividad.
                    </Alert>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className='form-control' required error={getError('tipo_actividad', errores).length > 0} size='small'>
                      <InputLabel id='tipo_actividad_label'>Tipo de actividad</InputLabel>
                      <Select
                        labelId='tipo_actividad_label'
                        id='tipo_actividad'
                        name='tipo_actividad'
                        value={tipo_actividad}
                        label='Tipo de actividad'
                        onChange={onChange}
                        fullWidth
                      >
                        {
                          tipos_actividades.length > 0 ?
                            tipos_actividades
                              // .sort(({ valorh: previousID }, { valori: currentID }) => previousID - currentID)
                              .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                            : <MenuItem value=""><em>Seleccione tipo de actividad</em></MenuItem>
                        }
                      </Select>
                      <FormHelperText>{getError('tipo_actividad', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {OTRO_TIPO_ACTIVIDAD == tipo_actividad &&
                    <Grid item xs={6}>
                      <TextField
                        value={otro_tipo_actividad}
                        error={getError('otro_tipo_actividad', errores).length > 0}
                        helperText={getError('otro_tipo_actividad', errores)}
                        name='otro_tipo_actividad'
                        label='Otro tipo de actividad'
                        onChange={onChange}
                        size='small'
                        fullWidth
                        required
                      />
                    </Grid>
                  }
                  {/* {!(CODIGOS_TIPO_ACTIVIDAD_NO_APLICA.includes(tipo_actividad)) && */}
                  {
                    subtipos_actividades.length > 0 && subtipo_actividad !== CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('subtipo_actividad', errores).length > 0} size='small'>
                        <InputLabel id='subtipo_actividad_label'>Subtipo de actividad</InputLabel>
                        <Select
                          labelId='subtipo_actividad_label'
                          id='subtipo_actividad'
                          name='subtipo_actividad'
                          value={subtipo_actividad}
                          label='Subtipo de actividad'
                          fullWidth
                          onChange={onChange}
                        >
                          {subtipos_actividades.length > 0 ?
                            subtipos_actividades
                              // .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(({ secundario: { id, codigo, nombre } }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                            // .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                            : <MenuItem value=""><em>No hay subtipos</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('subtipo_actividad', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  {/* } */}
                  {subtipos_actividades.length > 0 && subtipo_actividad == OTRO_SUBTIPO_ACTIVIDAD &&
                    <Grid item xs={6}>
                      <TextField
                        required
                        value={otro_subtipo_actividad}
                        error={getError('otro_subtipo_actividad', errores).length > 0}
                        helperText={getError('otro_subtipo_actividad', errores)}
                        name='otro_subtipo_actividad'
                        label='Otro subtipo de actividad'
                        onChange={onChange}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                  }
                  {/* {CODIGOS_DURACION_ACTIVIDADES.includes(tipo_actividad) &&
                    <Grid item xs={6}>
                      <TextField
                        value={horas}
                        required
                        error={getError('horas', errores).length > 0}
                        helperText={getError('horas', errores)}
                        type='number'
                        id='horas'
                        name='horas'
                        label='Duración (horas)'
                        fullWidth
                        onChange={onChange}
                        size='small'
                      />
                    </Grid>
                  } */}
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        id='fecha_inicio'
                        error={getError('fecha_inicio', errores).length > 0}
                        helperText={getError('fecha_inicio', errores)}
                        label='Fecha de inicio'
                        name='fecha_inicio'
                        format='dd/MM/yyyy'
                        value={fecha_inicio}
                        onChange={(date) => onChangeDate('fecha_inicio', date)}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        required
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id='fecha_fin'
                        required
                        error={getError('fecha_fin', errores).length > 0}
                        helperText={getError('fecha_fin', errores)}
                        label='Fecha final'
                        name='fecha_fin'
                        format="dd/MM/yyyy"
                        value={fecha_fin}
                        onChange={(date) => onChangeDate('fecha_fin', date)}
                        fullWidth
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>


                  <Grid item xs={6}>
                    <FormControl className='form-control' required error={getError('responsable_actividad', errores).length > 0}>
                      <InputLabel id='responsable_actividad_label'>Seleccione responsable/s de la actividad</InputLabel>
                      <Select
                        labelId='responsable_actividad_label'
                        required
                        id='responsable_actividad'
                        name='responsable_actividad'
                        error={getError('responsable_actividad', errores).length > 0}
                        helperText={getError('responsable_actividad', errores)}
                        value={responsable_actividad}
                        fullWidth
                        onChange={onChange}
                        multiple
                        renderValue={selected => {
                          return onChangeMulti(selected, tipos_responsables, 'codigo')
                        }}
                      >
                        {
                          tipos_responsables.map(({ nombre, id, codigo }) => {
                            return (
                              <MenuItem key={id} value={codigo}>
                                <Checkbox checked={responsable_actividad.indexOf(codigo) > -1} />
                                <ListItemText primary={nombre} />
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                      <FormHelperText>{getError('responsable_actividad', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {responsable_actividad.includes('tipo_resp_admin') &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('administrativos_responsable', errores).length > 0}>
                        <InputLabel id='ud_administrativa_responsable_label'>Unidad administrativa responsable/s de la actividad</InputLabel>
                        <Select
                          required
                          labelId='ud_administrativa_responsable_label'
                          id='administrativos_responsable'
                          name='administrativos_responsable'
                          error={getError('administrativos_responsable', errores).length > 0}
                          helperText={getError('administrativos_responsable', errores)}
                          value={administrativos_responsable}
                          fullWidth
                          onChange={onChange}
                          multiple
                          renderValue={selected => {
                            return onChangeMulti(selected, administrativos_resp, 'id')
                          }}
                        >
                          {
                            administrativos_resp.map(({ nombre, id }) => {
                              return (<MenuItem key={id} value={id}>
                                <Checkbox checked={administrativos_responsable.indexOf(id) > -1} />
                                <ListItemText primary={nombre} />
                              </MenuItem>)
                            })
                          }
                        </Select>
                        <FormHelperText>{getError('administrativos_responsable', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  {responsable_actividad.includes('tipo_resp_dpto') &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('dpto_responsable', errores).length > 0}>
                        <InputLabel id='facultad_departamento_label'>Departamento responsable/s de la actividad</InputLabel>
                        <Select
                          required
                          labelId='facultad_departamento_label'
                          id='dpto_responsable'
                          name='dpto_responsable'
                          error={getError('dpto_responsable', errores).length > 0}
                          helperText={getError('dpto_responsable', errores)}
                          value={dpto_responsable}
                          fullWidth
                          onChange={onChange}
                          multiple
                          renderValue={selected => {
                            return onChangeMulti(selected, departamentos_resp, 'id')
                          }}
                        >
                          {
                            departamentos_resp
                              .map(({ nombre, id }) => {
                                return (
                                  <MenuItem key={id} value={id}>
                                    <Checkbox checked={dpto_responsable.indexOf(id) > -1} />
                                    <ListItemText primary={nombre} />
                                  </MenuItem>)
                              })
                          }
                        </Select>
                        <FormHelperText>{getError('departamento_sel', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  {responsable_actividad.includes('tipo_resp_facul') &&
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('facultad_responsable', errores).length > 0}>
                        <InputLabel id='facultad_responsable_label'>Facultad responsable/s de la actividad</InputLabel>
                        <Select
                          required
                          labelId='facultad_responsable_label'
                          id='facultad_responsable'
                          name='facultad_responsable'
                          error={getError('facultad_responsable', errores).length > 0}
                          helperText={getError('facultad_responsable', errores)}
                          value={facultad_responsable}
                          fullWidth
                          onChange={onChange}
                          multiple
                          renderValue={selected => {
                            return onChangeMulti(selected, facultades_resp, 'id')
                          }}
                        >
                          {
                            facultades_resp.map(({ nombre, id }) => {
                              return (
                                <MenuItem key={id} value={id}>
                                  <Checkbox checked={facultad_responsable.indexOf(id) > -1} />
                                  <ListItemText primary={nombre} />
                                </MenuItem>)
                            })
                          }
                        </Select>
                        <FormHelperText>{getError('facultad_responsable', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                  }
                  <Grid item xs={6}>
                    <TextField
                      value={titulo_tema}
                      required
                      error={getError('titulo_tema', errores).length > 0}
                      id='titulo_tema'
                      name='titulo_tema'
                      label='Titulo del evento o tema tratado'
                      fullWidth
                      onChange={onChange}
                      multiline
                      helperText={`${titulo_tema.length}/${CHARACTER_LIMIT_TITULO}`}
                      inputProps={{ maxLength: CHARACTER_LIMIT_TITULO }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={descripcion_actividad}
                      multiline
                      required
                      error={getError('descripcion_actividad', errores).length > 0}
                      helperText={`${descripcion_actividad.length}/${CHARACTER_LIMIT}`}
                      rows={4}
                      id='descripcion_actividad'
                      name='descripcion_actividad'
                      label='Descripción de la actividad'
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: CHARACTER_LIMIT }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={resultados_descripcion}
                      multiline
                      required
                      error={getError('resultados_descripcion', errores).length > 0}
                      helperText={`${resultados_descripcion.length}/${CHARACTER_LIMIT}`}
                      rows={4}
                      id='resultados_descripcion'
                      name='resultados_descripcion'
                      label='Descripción de resultados y/o productos obtenidos'
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: CHARACTER_LIMIT }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className='oculto'
                      ref={fileInput}
                      type="file"
                      id="info_complementaria"
                      name="info_complementaria"
                      label='Anexar información complementaria'
                      fullWidth
                      autoComplete="billing address-line1"
                      onChange={(e) => onChangeFile(e, document.getElementById('info_complementaria').files[0])}
                    />
                    <Grid item xs={12}>
                      <InputFile
                        label='Anexar información complementaria'
                        required={false}
                        id='info_complementaria'
                        value={info_complementaria}
                      />
                      {info_complementaria_url && (
                        <div style={{ marginTop: '8px', marginBottom: '12px' }}>
                           <Link href={`${api}${info_complementaria_url}`} target='_blank'>
                                Ver archivo en borrador
                            </Link>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />}
            </>
            :
            <>
              <TareasTerminadas
                mensaje={
                  <React.Fragment>
                    En el siguiente enlace encontrarás el instructivo para el registro de movilidades. <br />
                    <Link href={instructivo} target='_blank'>
                      ¡ Clic Aquí ! Instructivo Registro de Movilidades
                    </Link> <br /><br />
                    Para comenzar el registro, haz clic en el botón que aparece a continuación
                  </React.Fragment>
                }
                marginTop='4%'
                imagen={emma_s1}
                widthImg="9%"
                style={{ minWidth: '60%' }}
              />
              <div style={{ textAlign: 'center', position: 'sticky', bottom: '0', zIndex: '1000', backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white' }} onClick={obtenerBorradorActividad}>Añadir actividad<AddIcon style={{ marginLeft: '0.6rem' }} /></Button>
              </div>
            </>
        }
      </>
    )
  }
}

class AgregarParticipante extends Component {
  render() {
    let { modalAddParticipante, modalAddParticipantExt, errores, tipos_identificacion, cargandoForm, participantes, datosParticipante: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, identificacion, nacionalidad, correo_personal, indicativo_celular, celular, tipo_identificacion, correo } } = this.props.state
    let { actualizarEstado, guardarParticipanteNuevoInt, guardarParticipanteEmma, onChangeParticipante } = this.props.actions
    let { editar } = this.props
    return (
      <>
        {
          modalAddParticipante &&
          <Dialog fullWidth={true} maxWidth='sm' open={modalAddParticipante} onClose={() => actualizarEstado('modalAddParticipante', false)} disableBackdropClick={true}>
            <AppBarModal titulo={'¡ Agregar Participante !'} mostrarModal={(modalAddParticipante) => actualizarEstado('modalAddParticipante', modalAddParticipante)} />
            <DialogContent className='scroll'>
              <Alert severity="error">
                <strong>Importante:</strong> Ten en cuenta lo siguiente:
                <ul>
                  <li>Puedes buscar a la persona digitando su número de identificación, correo o nombre en el buscador.</li>
                  <li>Si el participante no se encuentra y es externo, debes crearlo haciendo clic en el botón <strong>"AGREGAR PERSONA NUEVA"</strong>.</li>
                  <li>Si el participante es un profesor o estudiante de la CUC, primero realiza la búsqueda, ya que <strong>no</strong> debes crearlo como una nueva persona.</li>
                  </ul>
              </Alert>
              <BuscarPersona
                callback={({
                  id,
                  primer_nombre,
                  segundo_nombre,
                  primer_apellido,
                  segundo_apellido,
                  tipo_identificacion,
                  identificacion,
                  correo,
                  correo_personal,
                  indicativo_celular,
                  celular,
                  nacionalidad
                }) =>
                  guardarParticipanteEmma({
                    id,
                    primer_nombre,
                    segundo_nombre: segundo_nombre ? segundo_nombre : '',
                    primer_apellido,
                    segundo_apellido: segundo_apellido ? segundo_apellido : '',
                    tipo_identificacion: tipo_identificacion ? tipo_identificacion.id : '',
                    identificacion: identificacion ? identificacion : '',
                    correo: correo ? correo : '',
                    correo_personal: correo_personal ? correo_personal : '',
                    indicativo_celular: indicativo_celular ? indicativo_celular : '',
                    celular: celular ? celular : '',
                    nacionalidad: nacionalidad ? nacionalidad : ''
                  })
                }
                persona_inter={true} // Buscar solo personas internas
                seleccionarVarios={true} // Poder seleccionar varios
                listaSeleccionados={participantes} // Lista para ir emparejando con los que ya están seleccionados
              // buscarEstudiante = { TIPO_MOVILIDAD_ESTUDIANTES.includes(tipo_movilidad) ? '1' : '0' } // Filtro para buscar solo estudiantes o profesores
              // buscarTipo = { TIPO_MOVILIDAD_ENTRANTE.includes(tipo_movilidad) ? '2' : '1' } // Filtro para buscar solo entrantes o salientes
              />
            </DialogContent>
            <DialogActions>
              <>
                <BtnForm texto="AGREGAR PERSONA NUEVA" callback={(modalAddParticipantExt) => actualizarEstado('modalAddParticipantExt', modalAddParticipantExt)} />
                <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddParticipante', false)} />
                {/* <BtnForm texto="AGREGAR" callback={guardarParticipanteInt} /> */}
              </>
            </DialogActions>
          </Dialog>
        }
        {
          modalAddParticipantExt &&
          <Dialog fullWidth={true} maxWidth='sm' open={modalAddParticipantExt} onClose={(modalAddParticipantExt) => actualizarEstado('modalAddParticipantExt', modalAddParticipantExt)} disableBackdropClick={true}>
            <AppBarModal titulo={!editar ? '¡ Agregar Participante Externo !' : 'Editar Participante Externo'} mostrarModal={(modalAddParticipantExt) => actualizarEstado('modalAddParticipantExt', modalAddParticipantExt)} />
            <DialogContent className='scroll'>
              {
                !cargandoForm ?
                  <Grid container spacing={3} style={{ marginTop: '0.2%', marginBottom: '2%' }} >
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Chip label="Información del Participante" variant="outlined" color="primary" icon={<Person />} />
                      {/* <Chip label="Información del Participante" color="primary" icon={<Person />} style={{color:'white'}} /> */}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='primer_nombre'
                        label='Primer nombre'
                        name='primer_nombre'
                        value={primer_nombre}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('primer_nombre', errores).length > 0}
                        helperText={getError('primer_nombre', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='segundo_nombre'
                        label='Segundo nombre'
                        name='segundo_nombre'
                        value={segundo_nombre}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='primer_apellido'
                        label='Primer apellido'
                        name='primer_apellido'
                        value={primer_apellido}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('primer_apellido', errores).length > 0}
                        helperText={getError('primer_apellido', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='segundo_apellido'
                        label='Segundo apellido'
                        name='segundo_apellido'
                        value={segundo_apellido}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className='form-control' error={getError('tipo_identificacion', errores).length > 0} variant='outlined' required color='primary' size='small'>
                        <InputLabel id='tipo_identificacion_label'>Tipo de identificación</InputLabel>
                        <Select
                          id='tipo_identificacion'
                          label='Tipo de identificación'
                          labelId='tipo_identificacion_label'
                          name='tipo_identificacion'
                          value={tipo_identificacion}
                          onChange={onChangeParticipante}
                          size='small'
                          fullWidth
                        >
                          {
                            tipos_identificacion
                              .filter(({ valorg }) => valorg == '1')
                              .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                          }
                        </Select>
                        <FormHelperText>{getError('tipo_identificacion', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='identificacion'
                        label='Número de identificación'
                        name='identificacion'
                        value={identificacion}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('identificacion', errores).length > 0}
                        helperText={getError('identificacion', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='indicativo_celular'
                        label='Indicativo del país'
                        name='indicativo_celular'
                        value={indicativo_celular}
                        onChange={onChangeParticipante}
                        type='number'
                        placeholder='Ej: 57'
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField // se agrego (celular)
                        id='celular'
                        label='Celular'
                        name='celular'
                        value={celular}
                        onChange={onChangeParticipante}
                        type='number'
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='correo_personal'
                        label='Correo Personal'
                        name='correo_personal'
                        value={correo_personal}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='correo'
                        label='Correo Institucional'
                        name='correo'
                        value={correo}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='nacionalidad'
                        label='Nacionalidad'
                        name='nacionalidad'
                        value={nacionalidad}
                        onChange={onChangeParticipante}
                        variant='outlined'
                        color='primary'
                        size='small'
                        error={getError('nacionalidad', errores).length > 0}
                        helperText={getError('nacionalidad', errores)}
                        fullWidth
                        required
                      />
                    </Grid>
                    {/* <Grid item xs={12} >
                  <div style={{ textAlign: 'center', position: 'sticky', bottom: '0', zIndex: '1000', backgroundColor: 'white' }}>
                    <Button variant='outlined' size="small" startIcon={<Cancel />} style={{ color: 'red' }} > CANCELAR </Button>
                    <Button variant='outlined' color="primary" size="small" startIcon={<Save />}  > GUARDAR </Button>
                  </div>
                </Grid> */}
                  </Grid>
                  : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
              }
            </DialogContent>
            <DialogActions>
              <>
                <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddParticipantExt', false)} />
                <BtnForm texto="AGREGAR" callback={guardarParticipanteNuevoInt} />
              </>
            </DialogActions>
          </Dialog>
        }

      </>

    )
  }
}

AgregarParticipante.propTypes = {
  state: PropTypes.shape({
    modalAddParticipante: PropTypes.bool,
    modalAddParticipantExt: PropTypes.bool,
    errores: PropTypes.object,
    tipos_identificacion: PropTypes.array,
    cargandoForm: PropTypes.bool,
    participantes: PropTypes.array,
    datosParticipante: PropTypes.shape({
      primer_nombre: PropTypes.string,
      segundo_nombre: PropTypes.string,
      primer_apellido: PropTypes.string,
      segundo_apellido: PropTypes.string,
      identificacion: PropTypes.string,
      nacionalidad: PropTypes.string,
      correo_personal: PropTypes.string,
      indicativo_celular: PropTypes.string,
      celular: PropTypes.string,
      tipo_identificacion: PropTypes.string,
      correo: PropTypes.string
    })
  }),
  actions: PropTypes.shape({
    actualizarEstado: PropTypes.func,
    guardarParticipanteNuevoInt: PropTypes.func,
    guardarParticipanteEmma: PropTypes.func,
    onChangeParticipante: PropTypes.func
  })
};

AgregarParticipante.defaultProps = {
  state: {
    modalAddParticipante: false,
    modalAddParticipantExt: false,
    errores: {},
    tipos_identificacion: [],
    cargandoForm: false,
    participantes: [],
    datosParticipante: {
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      identificacion: '',
      nacionalidad: '',
      correo_personal: '',
      indicativo_celular: '',
      celular: '',
      tipo_identificacion: '',
      correo: ''
    }
  },
  actions: {
    actualizarEstado: () => { },
    guardarParticipanteNuevoInt: () => { },
    guardarParticipanteEmma: () => { },
    onChangeParticipante: () => { }
  }
};

class AgregarParticipanteMasivo extends Component {
  render() {
    let { modalMasivo, cerrarModal, cargandoForm, enviarParticipantesMasivo, masivos } = this.props;
    return (
      // !cargandoForm ?
      <EnviarArchivo
        modalAdd={modalMasivo}
        mostrarModalAdd={cerrarModal}
        enviarArchivo={(file) => enviarParticipantesMasivo(file)}
        titulo="Cargar Participantes Masivos"
        formatos='Excel'
        mensaje={
          <Grid>
            Tenga en cuenta las siguientes indicaciones:
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={
                    <Link
                      target="_blank"
                      underline='none'
                      href={masivos[0].archivo}
                      style={{ color: '#B22222', textDecoration: 'none' }}
                    >
                      ¡Haga clic para descargar la plantilla y registrar los datos de los participantes!
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText primary='No realice cambios en el formato de la plantilla.' />
              </ListItem>
            </List>
          </Grid>
        }
      />
      // : <TareasTerminadas mensaje={'Cargando..'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />

    )
  }
}

class ModalContenedorAll extends Component {
  renderCards() {
    const { classes } = this.props;
    const { tipos_forms, editar } = this.props.state;
    const { handleModalContenedor, actualizarEstado } = this.props.actions;
    const tiposFormsOrdenados = [...tipos_forms].sort((a, b) => a.valorf - b.valorf || a.valorg - b.valorg);

    return (
      <>
        <Grid container spacing={2}>
          {tiposFormsOrdenados.map((data, index) => (
            <Grid key={index} item xs={12} sm={6} md={editar ? 3 : 4}>
              <Card className={classes.root} onClick={() => handleModalContenedor(data.codigo)}>
                <CardActionArea className={classes.colorEmma} disabled={index < 3}>
                  <CardMedia component="img" image={editar ? `${api}${data.archivo}` : data.archivo} className={classes.img} />
                  <CardContent className={classes.cardContent}>
                    <div className={classes.contFooter}>
                      <Typography gutterBottom variant="body1" component="body1" className={classes.textoFooter}>
                        {data.nombre}
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        {
          editar &&
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: '25px' }}>
            <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white' }} onClick={() => actualizarEstado('modal_confirmar', true)}>Enviar Corrección</Button>
          </Grid>
        }
      </>
    );
  }


  render() {
    const { modalContenedor, cargandoContenedor, tit_cargando } = this.props.state
    const { actualizarEstado } = this.props.actions

    const { classes } = this.props;
    return (
      <Dialog fullWidth={true} maxWidth='md' open={modalContenedor} onClose={() => actualizarEstado('modalContenedor', false)} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Información !'} mostrarModal={(modalContenedor) => actualizarEstado('modalContenedor', modalContenedor)} />
        <DialogContent className='scroll'>
          <Grid container direction='row' justify='center' lg={12} md={12} sm={12} xs={12} style={{ padding: '2% 4% 2% 4%' }} >
            {
              cargandoContenedor ?
                <TareasTerminadas mensaje={tit_cargando} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
                : this.renderCards()
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalContenedor', false)} />
          </>
        </DialogActions>
      </Dialog>
    );
  }
}

class AgregarInfoBasica extends Component {
  render() {
    let { props_info } = this.props;
    let { modalAddInfoBasica, cerrarModal, cargandoForm, errores, onChange, tipos_movilidades, tipos_modalidades, plataformas, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, guardarInfoPrincipalParticipantes } = props_info
    let CHARACTER_LIMIT = 50;
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAddInfoBasica} onClose={cerrarModal} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Información !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {
            !cargandoForm ?
              <Grid container spacing={3} style={{ marginTop: '1%', marginBottom: '2%' }}>
                {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Chip label="Información de Movilidad" variant="outlined" color="primary" icon={<LocalLibrary />} />
                  <Chip label="Información del Participante" color="primary" icon={<Person />} style={{color:'white'}} />
                </Grid> */}
                <Grid item xs={12} >
                  <FormControl className='form-control' required error={getError('tipo_movilidad', errores).length > 0} size='small'>
                    <InputLabel id='tipo_movilidad_label'>Tipo de movilidad</InputLabel>
                    <Select
                      labelId='tipo_movilidad_label'
                      id='tipo_movilidad'
                      name='tipo_movilidad'
                      value={tipo_movilidad}
                      label='Tipo de movilidad'
                      onChange={onChange}
                      fullWidth
                    >
                      {
                        tipos_movilidades
                          .map(({ secundario: { id, nombre } }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)

                        // .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                      }
                    </Select>
                    <FormHelperText>{getError('tipo_movilidad', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className='form-control' required error={getError('tipo_modalidad', errores).length > 0} size='small'>
                    <InputLabel id='modalidad_label'>Tipo de modalidad</InputLabel>
                    <Select
                      labelId='modalidad_label'
                      id='tipo_modalidad'
                      name='tipo_modalidad'
                      value={tipo_modalidad}
                      label='tipo_modalidad'
                      onChange={onChange}
                      fullWidth
                      disabled={tipo_movilidad !== '' ? false : true}
                    >
                      {tipos_modalidades.map(({ secundario: { id, codigo, nombre } }) => <MenuItem key={id} value={codigo}>{nombre}</MenuItem>)
                      }
                    </Select>
                    <FormHelperText>{getError('tipo_modalidad', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                {MODALIDADES_VIRTUALES.includes(tipo_modalidad) && //validacion campo plataforma a utilizar
                  <Grid item xs={12} /* xs={plataforma_utilizada && CODIGO_PLATAFORMA_OTRO == plataforma_utilizada ? 6 : 6} */>
                    <FormControl className='form-control' required error={getError('plataforma', errores).length > 0} size='small'>
                      <InputLabel id='plataforma_utilizada_label'>Plataforma utilizada</InputLabel>
                      <Select
                        labelId='plataforma_utilizada_label'
                        id='plataforma'
                        name='plataforma'
                        value={plataforma}
                        label='plataforma'
                        fullWidth
                        onChange={onChange}
                      >
                        {
                          plataformas
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                        }
                      </Select>
                      <FormHelperText>{getError('plataforma', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {MODALIDADES_VIRTUALES.includes(tipo_modalidad) && plataforma && OTRA_PLATAFORMA == plataforma &&
                  <Grid item xs={12}>
                    <TextField
                      required
                      value={otra_plataforma}
                      error={getError('otra_plataforma', errores).length > 0}
                      // helperText={getError('otra_plataforma', errores)}
                      id='otra_plataforma'
                      name='otra_plataforma'
                      label='Otra plataforma'
                      fullWidth
                      onChange={onChange}
                      size='small'
                      inputProps={{ maxLength: CHARACTER_LIMIT }}
                      helperText={`${otra_plataforma.length}/${CHARACTER_LIMIT}`}
                    />
                  </Grid>
                }
              </Grid>
              : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
          }
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
            <BtnForm texto="GUARDAR" callback={guardarInfoPrincipalParticipantes} />
          </>
        </DialogActions>
      </Dialog >
    )
  }
}

class AgregarFinanciacion extends Component {
  render() {
    let { props_financiación } = this.props;
    let { modalAddFinanciacion, cerrarModal, cargandoForm, onChange, es_financiado, tipos_financiaciones, tipo_financiacion, errores, dependencia, dependencias, fuente_financiacion, fuentes_financiacion, entidad, valor_cop, tipo_moneda, monedas, valor_ext, participantesSelect, info_inicial_participantes, tipos_conceptos, clasificacion_de_financiacion, otro_tipo_de_concepto, fileInput, comprobante_financiacion, guardarFinanciacionParticipantes, financiaciones, agregarFinanciacion, onChangeFinanciaciones, nuevaFinanciacion, mostrarFormularioNuevaFinanciacion, mostrarFormulario, eliminarFinanciacion, buscarDataFinanciacion } = props_financiación
    const CHARACTER_LIMIT = 750;
    let CHARACTER_LIMIT_ENT = 50;

    let movilidadEst = ''
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAddFinanciacion} onClose={cerrarModal} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Financiación !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {
            !cargandoForm ?
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FormControl className='form-control' required error={getError('es_financiado', errores).length > 0}>
                    <InputLabel id="label_financiacion">¿Hubo financiación de la actividad?</InputLabel>
                    <Select
                      labelId="label_financiacion"
                      id="es_financiado"
                      name='es_financiado'
                      value={es_financiado}
                      onChange={onChange}
                    >
                      <MenuItem value="1">Si</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                    <FormHelperText>{getError('es_financiado', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                {
                  (es_financiado === '1' && !mostrarFormulario) &&
                  financiaciones.map((financiacion, index) => {
                    const tipo_fin = financiacion.tipo_financiacion ? tipos_financiaciones.find(({ id }) => id === financiacion.tipo_financiacion) : null;
                    const fuente_fin = financiacion.fuente_financiacion ? fuentes_financiacion.find(({ id }) => id === financiacion.fuente_financiacion) : null;
                    const depen = financiacion.dependencia ? dependencias.find(({ id }) => id === financiacion.dependencia) : null;
                    const clasi = financiacion.clasificacion_de_financiacion ? tipos_conceptos.find(({ codigo }) => codigo === financiacion.clasificacion_de_financiacion) : null;

                    return (
                      <Grid item xs={12} key={index}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} // Ajuste del ancho
                          >
                            <Typography>Financiación {index + 1}</Typography>
                            {/* Botón de eliminar */}
                            <Button
                              color="secondary"
                              onClick={() => eliminarFinanciacion(index)}
                              style={{ marginLeft: 'auto' }}
                            >
                              Eliminar
                            </Button>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ width: '100%' }}>
                              <Typography>Tipo de financiación: {tipo_fin ? tipo_fin.nombre : 'No disponible'}</Typography>
                              <Typography>Dependencia: {depen ? depen.nombre : 'No disponible'}</Typography>
                              <Typography>Fuente de Financiación: {fuente_fin ? fuente_fin.nombre : 'No disponible'}</Typography>
                              <Typography>Entidad: {financiacion.entidad}</Typography>
                              <Typography>Valor COP: {financiacion.valor_cop}</Typography>
                              <Typography>Tipo de Moneda: {financiacion.tipo_moneda}</Typography>
                              <Typography>Valor en Moneda Extranjera: {financiacion.valor_ext}</Typography>
                              <Typography>Clasificación de Financiación: {clasi ? clasi.nombre : 'No disponible'}</Typography>
                              <Typography>Otro Tipo de Concepto: {financiacion.otro_tipo_de_concepto}</Typography>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )
                  })

                }

                {
                  (es_financiado === '1' && mostrarFormulario) &&
                  <>
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('tipo_financiacion', errores).length > 0}>
                        <InputLabel id='tipo_financiacion_label'>Tipo de financiación</InputLabel>
                        <Select
                          labelId='tipo_financiacion_label'
                          id='tipo_financiacion'
                          name='tipo_financiacion'
                          value={nuevaFinanciacion.tipo_financiacion}
                          label='tipo_financiacion'
                          fullWidth
                          onChange={onChangeFinanciaciones}
                        >
                          {tipos_financiaciones
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                          }
                        </Select>
                        <FormHelperText>{getError('tipo_financiacion', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {nuevaFinanciacion.tipo_financiacion !== '' &&
                      <>
                        {nuevaFinanciacion.tipo_financiacion == TIPO_FIN_INSTITUCIONAL ?
                          <Grid item xs={6}>
                            <FormControl className='form-control' required error={getError('dependencia', errores).length > 0}>
                              <InputLabel id='dependencia_label'>Dependencia</InputLabel>
                              <Select
                                labelId='dependencia_label'
                                id='dependencia'
                                name='dependencia'
                                value={nuevaFinanciacion.dependencia}
                                label='dependencia'
                                fullWidth
                                onChange={onChangeFinanciaciones}
                              >
                                {dependencias
                                  .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                  .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                                }
                              </Select>
                              <FormHelperText>{getError('dependencia', errores)}</FormHelperText>
                            </FormControl>
                          </Grid>
                          :
                          <>
                            <Grid item xs={6}>
                              <FormControl className='form-control' required error={getError('fuente_financiacion', errores).length > 0}>
                                <InputLabel id='fuente_financiacion_label'>Fuente de Financiación</InputLabel>
                                <Select
                                  labelId='fuente_financiacion_label'
                                  id='fuente_financiacion'
                                  name='fuente_financiacion'
                                  value={nuevaFinanciacion.fuente_financiacion}
                                  label='fuente_financiacion'
                                  fullWidth
                                  onChange={onChangeFinanciaciones}
                                >
                                  {fuentes_financiacion ?
                                    fuentes_financiacion
                                      // .filter(({ valora }) => fuentes_financiacion == TIPO_FINANCIACION_INTERNACIONAL ? valora == 'internacional' : valora == 'nacional')
                                      // .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                                      .map((item, index) => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                    : <MenuItem value=""><em>No hay Fuentes de financiación disponibles</em></MenuItem>
                                  }
                                </Select>
                                <FormHelperText>{getError('fuente_financiacion', errores)}</FormHelperText>
                              </FormControl>
                            </Grid>
                            {nuevaFinanciacion.fuente_financiacion &&
                              <Grid item xs={12}>
                                <TextField
                                  value={nuevaFinanciacion.entidad}
                                  required
                                  error={getError('entidad', errores).length > 0}
                                  id='entidad'
                                  name='entidad'
                                  label='Entidad'
                                  fullWidth
                                  onChange={onChangeFinanciaciones}
                                  helperText={`${entidad.length}/${CHARACTER_LIMIT_ENT}`}
                                  inputProps={{ maxLength: CHARACTER_LIMIT_ENT }}
                                />
                              </Grid>
                            }
                          </>
                        }
                      </>
                    }
                    <Grid item xs={12}>
                      <TextField
                        value={nuevaFinanciacion.valor_cop}
                        required
                        type='number'
                        error={getError('valor_cop', errores).length > 0}
                        helperText={getError('valor_cop', errores)}
                        id='valor_cop'
                        name='valor_cop'
                        label={`Indique el valor en pesos colombianos`}
                        fullWidth
                        onChange={onChangeFinanciaciones}
                        InputProps={{ startAdornment: <InputAdornment position="start">COP$</InputAdornment>, }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className='form-control' error={getError('tipo_moneda', errores).length > 0}>
                        <InputLabel id='tipo_moneda_label'>Moneda extranjera</InputLabel>
                        <Select
                          labelId='tipo_moneda_label'
                          id='tipo_moneda'
                          name='tipo_moneda'
                          value={nuevaFinanciacion.tipo_moneda}
                          label='Moneda extranjera'
                          fullWidth
                          onChange={onChangeFinanciaciones}
                        >
                          {monedas
                            .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                            .map(({ code, name }) => <MenuItem key={name} value={code}>{name}</MenuItem>)
                          }
                        </Select>
                        <FormHelperText>{getError('tipo_moneda', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {nuevaFinanciacion.tipo_moneda != 'none' && nuevaFinanciacion.tipo_moneda != '' &&
                      <>
                        <Grid item xs={6}>
                          <TextField
                            value={nuevaFinanciacion.valor_ext}
                            type='number'
                            error={getError('valor_ext', errores).length > 0}
                            helperText={getError('valor_ext', errores)}
                            id='valor_ext'
                            name='valor_ext'
                            label={`Equivalencia en moneda extranjera`}
                            fullWidth
                            onChange={onChangeFinanciaciones}
                            InputProps={{ startAdornment: <InputAdornment position="start">{tipo_moneda ? tipo_moneda : ''}</InputAdornment>, }}
                          />
                        </Grid>
                      </>
                    }
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('clasificacion_de_financiacion', errores).length > 0}>
                        <InputLabel id='clasificacion_de_financiacion_label'>Clasificación de financiación</InputLabel>
                        <Select
                          labelId='clasificacion_de_financiacion_label'
                          id='clasificacion_de_financiacion'
                          name='clasificacion_de_financiacion'
                          value={nuevaFinanciacion.clasificacion_de_financiacion}
                          label='Clasificación de financiación'
                          fullWidth
                          onChange={onChangeFinanciaciones}
                        >
                          {tipos_conceptos.length > 0 ?
                            tipos_conceptos
                              // .filter(({ valorg }) => modalidad_escogida == MODALIDAD_VIRTUAL ? valorg == '1' || valorg == '7' : valorg == '1' || valorg == '2' )
                              // .filter(({ valorg }) => valorg == '1' || valorg == '2' )
                              .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map(tipo => <MenuItem key={tipo} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                            : <MenuItem value=""><em>No hay clasificaciones disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('clasificacion_de_financiacion', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {nuevaFinanciacion.clasificacion_de_financiacion == CODIGO_TIPO_CONCEPTO_OTRO &&
                      <Grid item xs={12}>
                        <TextField
                          value={nuevaFinanciacion.otro_tipo_de_concepto}
                          error={getError('otro_tipo_de_concepto', errores).length > 0}
                          helperText={`${otro_tipo_de_concepto.length}/${CHARACTER_LIMIT}`}
                          id='otro_tipo_de_concepto'
                          name='otro_tipo_de_concepto'
                          label='Indique el otro tipo de concepto'
                          fullWidth
                          required
                          onChange={onChangeFinanciaciones}
                          inputProps={{ maxLength: CHARACTER_LIMIT }}
                        />
                      </Grid>
                    }
                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                      <Button
                        onClick={agregarFinanciacion}
                        color="primary"
                        variant="contained"
                        style={{
                          marginRight: '10px',
                          borderRadius: '20px',
                          padding: '10px 20px',
                          minWidth: '150px',
                        }}
                      >
                        Agregar financiación
                      </Button>
                      <Button
                        onClick={() => mostrarFormularioNuevaFinanciacion(false, 'cancelar')}
                        color="secondary"
                        variant="outlined"
                        style={{
                          padding: '10px 20px',
                          borderRadius: '20px',
                          minWidth: '150px',
                        }}
                      >
                        Cancelar
                      </Button>
                    </Grid>

                  </>
                }
                {(!mostrarFormulario && es_financiado == 1) && (
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Button
                      onClick={() => mostrarFormularioNuevaFinanciacion(true, null)}
                      color="secondary"
                      variant="outlined"
                      style={{
                        padding: '10px 20px',
                        borderRadius: '20px',
                        marginTop: '20px',
                        minWidth: '150px',
                      }}
                    >
                      Agregar otra financiación
                    </Button>
                  </Grid>
                )}
              </Grid>
              : <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
          }
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
            {(mostrarFormulario && es_financiado == '0') ? <BtnForm texto="GUARDAR" callback={guardarFinanciacionParticipantes} />
              : (es_financiado == '1' && !mostrarFormulario) && <BtnForm texto="GUARDAR" callback={guardarFinanciacionParticipantes} />
            }
            {/* {(!mostrarFormulario && (es_financiado == '1' || es_financiado == '0')) && (
              <BtnForm texto="GUARDAR" callback={guardarFinanciacionParticipantes} />
            )} */}
          </>
        </DialogActions>
      </Dialog>
    )
  }
}

class AgregarInstitucion extends Component {
  render() {
    let { modalAddInstitucion, cargandoForm, errores, institucion_participante, modal_buscar_institucion, participante_tipo, tipos_participantes, tipo_participante, error, dptos_academicos, dpto_participante, dependencias, programa_participante, facultades_resp, facultad_participante, niveles_educativos, nivel_participante, programas_receptores, programas_receptores_docente, modal_elimi_institucion, ident_tipo_movilidad, instituciones } = this.props.state
    let { actualizarEstado, agregarInstitucionParticipante, onChange, guardarInstitucionParticipante } = this.props.actions

    const mensajeProps = { marginTop: '7%', imagen: emma_s1, widthImg: '11%' };

    const mensajes = {
      cargando: 'Cargando',
      distinto: (
        <React.Fragment>
          Para añadir una institución, es necesario que los participantes seleccionados pertenezcan al mismo tipo de movilidad. <br />
          Te recomendamos revisar tus selecciones
        </React.Fragment>
      ),
      error: 'Para añadir una institución, es necesario registrar la información básica del participante seleccionado'
    };

    const mensaje = mensajes[cargandoForm ? 'cargando' : participante_tipo === 'distinto' && !error ? 'distinto' : error ? 'error' : null];

    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAddInstitucion} onClose={() => actualizarEstado('modalAddInstitucion', false)} disableBackdropClick={true}>
        <AppBarModal titulo={'¡ Agregar Institución !'} mostrarModal={(modalAddInstitucion) => actualizarEstado('modalAddInstitucion', modalAddInstitucion)} />
        <DialogContent className='scroll'>
          {mensaje && <TareasTerminadas mensaje={mensaje} {...mensajeProps} cargando={cargandoForm} />}
          {participante_tipo !== 'distinto' && !cargandoForm && !error && ident_tipo_movilidad &&
            <>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <TextField
                    value={institucion_participante.institucion}
                    required
                    error={getError('institucion_participante', errores).length > 0}
                    helperText={getError('institucion_participante', errores)}
                    id='institucion_participante'
                    name='institucion_participante'
                    label={ident_tipo_movilidad == 'saliente' ? 'Institución Destino' : ident_tipo_movilidad == 'entrante' ? 'Institución de origen' : 'Institución externa'}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={3} >
                  <ConvocatoriasBuscarConvenio
                    seleccionar={({ id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio, actividades, tipoConvenio }) => agregarInstitucionParticipante(id, institucion, pais_vinculante, ciudad_vinculante, codigo_convenio, actividades, tipoConvenio)}
                    quitar={() => actualizarEstado('institucion_participante', { id: 0, institucion: '', pais_vinculante: '', ciudad_vinculante: '', codigo_convenio: '' })}
                    // () => this.setState({ institucion_participante: { id: 0, nombre: '', pais: '', ciudad: '', codigo_convenio: '' } })
                    seleccionadas={[institucion_participante]}
                    modalBuscarUniversidad={modal_buscar_institucion}
                    mostrarModalBuscarUniversidad={(modal_buscar_institucion) => actualizarEstado('modal_buscar_institucion', modal_buscar_institucion)}
                    buscarMovilidad={true}
                    dataBuscarMovilidad={instituciones}
                  />
                  <ConfirmarAccion
                    mensaje1={`Se quitará ${institucion_participante.institucion}, `}
                    ejecutarAccion={() => actualizarEstado('institucion_participante', { id: 0, institucion: '', pais_vinculante: '', ciudad_vinculante: '', codigo_convenio: '' })}
                    mostrarModalConfAccion={(modal_elimi_institucion) => actualizarEstado('modal_elimi_institucion', modal_elimi_institucion)}
                    modalConAccion={modal_elimi_institucion}
                    dato={institucion_participante ? institucion_participante : { id: 0 }}
                    titulo={'¿ Quitar institución ?'}
                  />
                  <IconButton aria-label="add" /* className={classes.colorEmmma}  */ onClick={(modal_buscar_institucion) => actualizarEstado('modal_buscar_institucion', modal_buscar_institucion)}>
                    <AddIcon />
                  </IconButton>
                  <IconButton aria-label="clear" /* className={classes.colorDelete} */ onClick={() => actualizarEstado('modal_elimi_institucion', true)}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
                {institucion_participante.id !== 0 &&
                  <>
                    <Grid item xs={6} md={6}>
                      <TextField
                        value={institucion_participante.pais_vinculante ? institucion_participante.pais_vinculante.nombre : institucion_participante ? institucion_participante.pais : ""}
                        required
                        id='pais_movilidad'
                        name='pais_movilidad'
                        label='País de la institución'
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        value={institucion_participante ? institucion_participante.ciudad_vinculante : "No registra"}
                        required
                        disabled="true"
                        id='ciudad_movilidad'
                        name='ciudad_movilidad'
                        label='Ciudad de la institución'
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </>
                }
                <Grid item xs={12} md={12}>
                  <FormControl className='form-control' required error={getError('tipo_participante', errores).length > 0}>
                    <InputLabel id='tipo_participante_label'>Tipo de participante</InputLabel>
                    <Select
                      labelId='tipo_participante_label'
                      id='tipo_participante'
                      disabled={participante_tipo}
                      name='tipo_participante'
                      value={tipo_participante}
                      fullWidth
                      onChange={onChange}
                    >
                      {tipos_participantes.map((tipo) => <MenuItem key={tipo} value={tipo.id}>{tipo.nombre}</MenuItem>)}
                    </Select>
                    <FormHelperText>{getError('tipo_participante', errores)}</FormHelperText>
                  </FormControl>
                </Grid>
                {tipo_participante == TIPO_PARTICIPANTE_ESTU &&
                  <>
                    <Grid item xs={6} md={6}>
                      <FormControl className='form-control' required error={getError('facultad_participante', errores).length > 0}>
                        <InputLabel id='facultad_receptora_label'>Facultad</InputLabel>
                        <Select
                          labelId='facultad_receptora_label'
                          id='facultad_participante'
                          name='facultad_participante'
                          value={facultad_participante}
                          fullWidth
                          onChange={onChange}
                        >
                          {facultades_resp.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)}
                        </Select>
                        <FormHelperText>{getError('facultad_participante', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {facultad_participante && facultad_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('nivel_participante', errores).length > 0}>
                          <InputLabel id='nivel_educativo_label'>Nivel de estudio</InputLabel>
                          <Select
                            labelId='nivel_educativo_label'
                            id='nivel_participante'
                            name='nivel_participante'
                            value={nivel_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              niveles_educativos.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                            }
                          </Select>
                          <FormHelperText>{getError('nivel_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                    {
                      facultad_participante && facultad_participante !== '' && nivel_participante && nivel_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('programa_participante', errores).length > 0}>
                          <InputLabel id='programa_receptor_label'>Programa</InputLabel>
                          <Select
                            labelId='programa_receptor_label'
                            id='programa_participante'
                            name='programa_participante'
                            value={programa_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              programas_receptores.length > 0 ?
                                programas_receptores
                                  .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                                : <MenuItem value=""><em>No hay programas académicos disponibles</em></MenuItem>
                            }
                          </Select>
                          <FormHelperText>{getError('programa_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                  </>
                }
                {tipo_participante == TIPO_PARTICIPANTE_DOC &&
                  <>
                    <Grid item xs={6} md={6}>
                      <FormControl className='form-control' required error={getError('dpto_participante', errores).length > 0}>
                        <InputLabel id='dpto_participante_label'>{ident_tipo_movilidad == 'saliente' ? 'Departamento adscrito' : ident_tipo_movilidad == 'entrante' ? 'Departamento' : 'Institución externa'}</InputLabel>
                        <Select
                          labelId='dpto_participante_label'
                          id='dpto_participante'
                          name='dpto_participante'
                          value={dpto_participante}
                          fullWidth
                          onChange={onChange}
                        >
                          {
                            dptos_academicos.length > 0 ?
                              dptos_academicos
                                .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                              : <MenuItem value=""><em>No hay departamentos académicos disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('dpto_participante', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {dpto_participante && dpto_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('programa_participante', errores).length > 0}>
                          <InputLabel id='programa_receptor_label'>Programa</InputLabel>
                          <Select
                            labelId='programa_receptor_label'
                            id='programa_participante'
                            name='programa_participante'
                            value={programa_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              programas_receptores_docente.length > 0 ?
                                programas_receptores_docente
                                  // .map((item,index) => <MenuItem key = { index } value = { item.secundario.id }>{ item.secundario.nombre }</MenuItem>)
                                  .map(({ secundario }, index) => <MenuItem key={index} value={secundario.id}>{secundario.nombre}</MenuItem>)
                                : <MenuItem value=""><em>No hay programas académicos disponibles</em></MenuItem>
                            }
                          </Select>
                          <FormHelperText>{getError('programa_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                  </>
                }
                {tipo_participante == TIPO_PARTICIPANTE_ADMIN &&
                  <>
                    <Grid item xs={6} md={6}>
                      <FormControl className='form-control' required error={getError('dpto_participante', errores).length > 0}>
                        <InputLabel id='dpto_participante_label'>Departamento</InputLabel>
                        <Select
                          labelId='dpto_participante_label'
                          id='dpto_participante'
                          name='dpto_participante'
                          value={dpto_participante}
                          fullWidth
                          onChange={onChange}
                        >
                          {
                            dptos_academicos
                              .length > 0 ?
                              dptos_academicos
                                .map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                              : <MenuItem value=""><em>No hay departamentos académicos disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('dpto_participante', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {dpto_participante && dpto_participante !== '' &&
                      <Grid item xs={6} md={6}>
                        <FormControl className='form-control' required error={getError('programa_participante', errores).length > 0}>
                          <InputLabel id='dependencia_receptora_label'>Dependencia</InputLabel>
                          <Select
                            labelId='dependencia_receptora_label'
                            id='programa_participante'
                            name='programa_participante'
                            value={programa_participante}
                            fullWidth
                            onChange={onChange}
                          >
                            {
                              dependencias.length > 0 ?
                                dependencias.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>)
                                : <MenuItem value=""><em>No hay dependencias disponibles</em></MenuItem>
                            }
                          </Select>
                          <FormHelperText>{getError('programa_participante', errores)}</FormHelperText>
                        </FormControl>
                      </Grid>
                    }
                  </>
                }
              </Grid>
            </>
          }
        </DialogContent>
        <DialogActions>
          {
            participante_tipo == 'distinto' ?
              <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddInstitucion', false)} />
              :
              <>
                <BtnForm texto="CERRAR" callback={() => actualizarEstado('modalAddInstitucion', false)} />
                <BtnForm texto="GUARDAR" callback={guardarInstitucionParticipante} />
              </>
          }
        </DialogActions>
      </Dialog >
    )
  }
}

// MODALES DETALLE

class DetalleParticipante extends Component {
  render() {
    let { modalDetParticipante, cerrarModal, participanteDetalle, cargando } = this.props;
    let { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, tipo_identificacion: { nombre }, identificacion, indicativo_celular, celular, correo_personal, correo, nacionalidad } = participanteDetalle;
    let nombre_completo = `${primer_nombre ?? ''} ${segundo_nombre ?? ''} ${primer_apellido ?? ''} ${segundo_apellido ?? ''}`;
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalDetParticipante} onClose={cerrarModal} >
        <AppBarModal titulo={'¡ Detalle Participante !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {cargando ? <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} /> :
            <Table>
              <TableBody>
                <TableRow key={1}>
                  <TableCell component="th" scope="row">Nombre completo</TableCell>
                  <TableCell align="left">{nombre_completo.toUpperCase()}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell component="th" scope="row">Tipo de identificación</TableCell>
                  <TableCell align="left">{nombre}</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell component="th" scope="row">Número de identificación</TableCell>
                  <TableCell align="left">{identificacion.toUpperCase()}</TableCell>
                </TableRow>
                {
                  indicativo_celular &&
                  <TableRow key={4}>
                    <TableCell component="th" scope="row">Indicativo país</TableCell>
                    <TableCell align="left">+{indicativo_celular}</TableCell>
                  </TableRow>
                }
                {
                  celular &&
                  <TableRow key={5}>
                    <TableCell component="th" scope="row">Celular</TableCell>
                    <TableCell align="left">{celular}</TableCell>
                  </TableRow>
                }
                {
                  correo_personal &&
                  <TableRow key={6}>
                    <TableCell component="th" scope="row">Correo personal</TableCell>
                    <TableCell align="left">{correo_personal}</TableCell>
                  </TableRow>
                }
                {
                  correo &&
                  <TableRow key={7}>
                    <TableCell component="th" scope="row">Correo institucional</TableCell>
                    <TableCell align="left">{correo}</TableCell>
                  </TableRow>
                }
                {
                  nacionalidad &&
                  <TableRow key={8}>
                    <TableCell component="th" scope="row">Nacionalidad</TableCell>
                    <TableCell align="left">{nacionalidad.toUpperCase()}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          }
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
          </>
        </DialogActions>
      </Dialog>
    )

  }
}

class FinanciacionParticipante extends Component {
  render() {
    let { modal_fin_participante, cerrarModal, financiacionDetalle, cargando, financiacion, editar, setEditarFin, } = this.props;
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modal_fin_participante} onClose={cerrarModal} >
        <AppBarModal titulo={!editar ? '¡ Detalle Financiación !' : 'Editar Financiación'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {cargando ? (
            <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
          ) : (
            <>
              {financiacion && financiacion.length > 0 ? (
                financiacion.length === 1 ? (
                  financiacion[0].es_financiado == "1" ?
                    <Table>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell component="th" scope="row">Tipo de financiación</TableCell>
                          <TableCell align="left">{financiacion[0].tipo_financiacion.nombre || 'N/A'}</TableCell>
                        </TableRow>
                        {financiacion[0].tipo_financiacion.id === TIPO_FIN_INSTITUCIONAL ? (
                          <TableRow key={2}>
                            <TableCell component="th" scope="row">Dependencia</TableCell>
                            <TableCell align="left">{financiacion[0].dependencia?.nombre || 'N/A'}</TableCell>
                          </TableRow>
                        ) : (
                          <>
                            <TableRow key={3}>
                              <TableCell component="th" scope="row">Fuente de financiación</TableCell>
                              <TableCell align="left">{financiacion[0].fuente_financiacion?.nombre || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow key={4}>
                              <TableCell component="th" scope="row">Entidad</TableCell>
                              <TableCell align="left">{financiacion[0].entidad || 'N/A'}</TableCell>
                            </TableRow>
                          </>
                        )}
                        <TableRow key={5}>
                          <TableCell component="th" scope="row">Valor en pesos Colombianos</TableCell>
                          <TableCell align="left">${financiacion[0].valor_cop || 0}</TableCell>
                        </TableRow>
                        <TableRow key={6}>
                          <TableCell component="th" scope="row">Equivalencia en moneda extranjera</TableCell>
                          <TableCell align="left">{financiacion[0].tipo_moneda || 'N/A'}: {financiacion[0].valor_ext || 0}</TableCell>
                        </TableRow>
                        <TableRow key={8}>
                          <TableCell component="th" scope="row">Clasificación de financiación</TableCell>
                          <TableCell align="left">{financiacion[0].clasificacion_financiacion?.nombre || 'N/A'}</TableCell>
                        </TableRow>
                        {financiacion[0].clasificacion_financiacion?.id === CODIGO_TIPO_CONCEPTO_OTRO && (
                          <TableRow key={9}>
                            <TableCell component="th" scope="row">Otro tipo de concepto</TableCell>
                            <TableCell align="left">{financiacion[0].otro_tipo_de_concepto || 'N/A'}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    :
                    <TareasTerminadas mensaje={'No hay financiación para este participante'} marginTop='7%' imagen={emma_w} widthImg='7%' />
                ) : (
                  financiacion.map((fin, index) => (
                    <Grid item xs={12} key={index} style={{ marginBottom: '16px', marginTop: '5px' }}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                          style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Typography style={{ fontWeight: 'bold' }}>FINANCIACIÓN {index + 1}</Typography>
                            <Button
                              color="secondary"
                              onClick={() => setEditarFin(fin)}
                              style={{ marginLeft: 'auto' }}
                            >
                              Editar
                            </Button>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ width: '100%' }}>
                            <Typography>Tipo de financiación: {fin.tipo_financiacion.nombre || 'N/A'}</Typography>
                            <Typography>Dependencia: {fin.dependencia?.nombre || 'N/A'}</Typography>
                            <Typography>Fuente de Financiación: {fin.fuente_financiacion?.nombre || 'N/A'}</Typography>
                            <Typography>Entidad: {fin.entidad || 'N/A'}</Typography>
                            <Typography>Valor COP: {fin.valor_cop || 0}</Typography>
                            <Typography>Tipo de Moneda: {fin.tipo_moneda || 'N/A'}</Typography>
                            <Typography>Valor en Moneda Extranjera: {fin.valor_ext || 0}</Typography>
                            <Typography>Clasificación de Financiación: {fin.clasificacion_financiacion?.nombre || 'N/A'}</Typography>
                            <Typography>Otro Tipo de Concepto: {fin.otro_tipo_de_concepto || 'N/A'}</Typography>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))
                )
              ) : (
                <TareasTerminadas mensaje={'No hay financiación para esta actividad'} marginTop='7%' imagen={emma_w} widthImg='7%' />
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
          </>
        </DialogActions>
      </Dialog>
    )
  }
}

class InstitucionParticipante extends Component {
  render() {
    let { modal_insti_participante, cerrarModal, cargando, institucion_participante } = this.props;
    console.log(institucion_participante)
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modal_insti_participante} onClose={cerrarModal} >
        <AppBarModal titulo={'¡ Detalle Institución !'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          {(cargando && !institucion_participante) ? (
            <TareasTerminadas mensaje={'Cargando'} marginTop='7%' imagen={emma_w} widthImg='7%' cargando={true} />
          ) : (
            <>
              <Table>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell component="th" scope="row">Institución</TableCell>
                    <TableCell align="left">{institucion_participante?.institucion_externa?.institucion}</TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell component="th" scope="row">País de la institución</TableCell>
                    <TableCell align="left">{institucion_participante?.institucion_externa?.pais_vinculante?.nombre}</TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell component="th" scope="row">Ciudad de la institución</TableCell>
                    <TableCell align="left">{institucion_participante?.institucion_externa?.ciudad_vinculante}</TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell component="th" scope="row">Tipo de participante</TableCell>
                    <TableCell align="left">{institucion_participante?.tipo_participante?.nombre}</TableCell>
                  </TableRow>
                  {institucion_participante?.tipo_participante?.id == TIPO_PARTICIPANTE_ESTU &&
                    <>
                      <TableRow key={5}>
                        <TableCell component="th" scope="row">Facultad receptora</TableCell>
                        <TableCell align="left">{institucion_participante?.facultad_receptora?.nombre}</TableCell>
                      </TableRow>
                      <TableRow key={6}>
                        <TableCell component="th" scope="row">Programa receptor</TableCell>
                        <TableCell align="left">{institucion_participante?.programa_receptor?.nombre}</TableCell>
                      </TableRow>
                      <TableRow key={7}>
                        <TableCell component="th" scope="row">Nivel de estudio</TableCell>
                        <TableCell align="left">{institucion_participante?.nivel_estudio?.nombre}</TableCell>
                      </TableRow>
                    </>
                  }
                  {institucion_participante?.tipo_participante?.id == TIPO_PARTICIPANTE_DOC &&
                    <>
                      <TableRow key={8}>
                        <TableCell component="th" scope="row">Departamento receptor</TableCell>
                        <TableCell align="left">{institucion_participante?.dpto_academico_receptor?.nombre}</TableCell>
                      </TableRow>
                      <TableRow key={9}>
                        <TableCell component="th" scope="row">Programa receptor</TableCell>
                        <TableCell align="left">{institucion_participante?.programa_receptor?.nombre}</TableCell>
                      </TableRow>
                    </>
                  }
                  {institucion_participante?.tipo_participante?.id == TIPO_PARTICIPANTE_ADMIN &&
                    <>
                      <TableRow key={10}>
                        <TableCell component="th" scope="row">Departamento receptor</TableCell>
                        <TableCell align="left">{institucion_participante?.dpto_academico_receptor?.nombre}</TableCell>
                      </TableRow>
                      <TableRow key={11}>
                        <TableCell component="th" scope="row">Dependencia receptora</TableCell>
                        <TableCell align="left">{institucion_participante?.programa_receptor?.nombre}</TableCell>
                      </TableRow>
                    </>
                  }


                </TableBody>
              </Table>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CERRAR" callback={cerrarModal} />
        </DialogActions>
      </Dialog>
    )
  }
}

class ModificarFinanciacion extends Component {
  render() {
    const CHARACTER_LIMIT = 750;
    let CHARACTER_LIMIT_ENT = 50;
    let { modalAddFinanciacion, cerrarModal } = this.props
    let { tipo_financiacion, dependencia, fuente_financiacion, entidad, tipos_financiaciones, dependencias, fuentes_financiacion, errores, valor_cop, tipo_moneda, valor_ext, monedas, clasificacion_de_financiacion, tipos_conceptos, otro_tipo_de_concepto } = this.props.state
    let { onChange, guardarFinanciacion } = this.props.actions
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={modalAddFinanciacion} onClose={cerrarModal} >
        <AppBarModal titulo={'Editar Financiación'} mostrarModal={cerrarModal} />
        <DialogContent className='scroll'>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl className='form-control' required error={getError('tipo_financiacion', errores).length > 0}>
                <InputLabel id='tipo_financiacion_label'>Tipo de financiación</InputLabel>
                <Select
                  labelId='tipo_financiacion_label'
                  id='tipo_financiacion'
                  name='tipo_financiacion'
                  value={tipo_financiacion}
                  label='tipo_financiacion'
                  fullWidth
                  onChange={onChange}
                >
                  {tipos_financiaciones
                    .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                    .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                  }
                </Select>
                <FormHelperText>{getError('tipo_financiacion', errores)}</FormHelperText>
              </FormControl>
            </Grid>
            {tipo_financiacion !== '' &&
              <>
                {tipo_financiacion == TIPO_FIN_INSTITUCIONAL ?
                  <Grid item xs={6}>
                    <FormControl className='form-control' required error={getError('dependencia', errores).length > 0}>
                      <InputLabel id='dependencia_label'>Dependencia</InputLabel>
                      <Select
                        labelId='dependencia_label'
                        id='dependencia'
                        name='dependencia'
                        value={dependencia}
                        label='dependencia'
                        fullWidth
                        onChange={onChange}
                      >
                        {dependencias
                          .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                          .map(tipo => <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>)
                        }
                      </Select>
                      <FormHelperText>{getError('dependencia', errores)}</FormHelperText>
                    </FormControl>
                  </Grid>
                  :
                  <>
                    <Grid item xs={6}>
                      <FormControl className='form-control' required error={getError('fuente_financiacion', errores).length > 0}>
                        <InputLabel id='fuente_financiacion_label'>Fuente de Financiación</InputLabel>
                        <Select
                          labelId='fuente_financiacion_label'
                          id='fuente_financiacion'
                          name='fuente_financiacion'
                          value={fuente_financiacion}
                          label='fuente_financiacion'
                          fullWidth
                          onChange={onChange}
                        >
                          {fuentes_financiacion ?
                            fuentes_financiacion
                              // .filter(({ valora }) => fuentes_financiacion == TIPO_FINANCIACION_INTERNACIONAL ? valora == 'internacional' : valora == 'nacional')
                              // .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                              .map((item, index) => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                            : <MenuItem value=""><em>No hay Fuentes de financiación disponibles</em></MenuItem>
                          }
                        </Select>
                        <FormHelperText>{getError('fuente_financiacion', errores)}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {fuente_financiacion &&
                      <Grid item xs={12}>
                        <TextField
                          value={entidad}
                          required
                          error={getError('entidad', errores).length > 0}
                          id='entidad'
                          name='entidad'
                          label='Entidad'
                          fullWidth
                          onChange={onChange}
                          helperText={`${entidad.length}/${CHARACTER_LIMIT_ENT}`}
                          inputProps={{ maxLength: CHARACTER_LIMIT_ENT }}
                        />
                      </Grid>
                    }
                  </>
                }
              </>
            }
            <Grid item xs={12}>
              <TextField
                value={valor_cop}
                required
                type='number'
                error={getError('valor_cop', errores).length > 0}
                helperText={getError('valor_cop', errores)}
                id='valor_cop'
                name='valor_cop'
                label={`Indique el valor en pesos colombianos`}
                fullWidth
                onChange={onChange}
                InputProps={{ startAdornment: <InputAdornment position="start">COP$</InputAdornment>, }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl className='form-control' error={getError('tipo_moneda', errores).length > 0}>
                <InputLabel id='tipo_moneda_label'>Moneda extranjera</InputLabel>
                <Select
                  labelId='tipo_moneda_label'
                  id='tipo_moneda'
                  name='tipo_moneda'
                  value={tipo_moneda}
                  label='Moneda extranjera'
                  fullWidth
                  onChange={onChange}
                >
                  {monedas
                    .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                    .map(({ code, name }) => <MenuItem key={name} value={code}>{name}</MenuItem>)
                  }
                </Select>
                <FormHelperText>{getError('tipo_moneda', errores)}</FormHelperText>
              </FormControl>
            </Grid>
            {tipo_moneda != 'none' && tipo_moneda != '' &&
              <>
                <Grid item xs={6}>
                  <TextField
                    value={valor_ext}
                    type='number'
                    error={getError('valor_ext', errores).length > 0}
                    helperText={getError('valor_ext', errores)}
                    id='valor_ext'
                    name='valor_ext'
                    label={`Equivalencia en moneda extranjera`}
                    fullWidth
                    onChange={onChange}
                    InputProps={{ startAdornment: <InputAdornment position="start">{tipo_moneda ? tipo_moneda : ''}</InputAdornment>, }}
                  />
                </Grid>
              </>
            }
            <Grid item xs={6}>
              <FormControl className='form-control' required error={getError('clasificacion_de_financiacion', errores).length > 0}>
                <InputLabel id='clasificacion_de_financiacion_label'>Clasificación de financiación</InputLabel>
                <Select
                  labelId='clasificacion_de_financiacion_label'
                  id='clasificacion_de_financiacion'
                  name='clasificacion_de_financiacion'
                  value={clasificacion_de_financiacion}
                  label='Clasificación de financiación'
                  fullWidth
                  onChange={onChange}
                >
                  {tipos_conceptos.length > 0 ?
                    tipos_conceptos
                      // .filter(({ valorg }) => modalidad_escogida == MODALIDAD_VIRTUAL ? valorg == '1' || valorg == '7' : valorg == '1' || valorg == '2' )
                      // .filter(({ valorg }) => valorg == '1' || valorg == '2' )
                      .sort(({ id: previousID }, { id: currentID }) => previousID - currentID)
                      .map(tipo => <MenuItem key={tipo} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                    : <MenuItem value=""><em>No hay clasificaciones disponibles</em></MenuItem>
                  }
                </Select>
                <FormHelperText>{getError('clasificacion_de_financiacion', errores)}</FormHelperText>
              </FormControl>
            </Grid>

            {clasificacion_de_financiacion == CODIGO_TIPO_CONCEPTO_OTRO &&
              <Grid item xs={12}>
                <TextField
                  value={otro_tipo_de_concepto}
                  error={getError('otro_tipo_de_concepto', errores).length > 0}
                  helperText={`${otro_tipo_de_concepto.length}/${CHARACTER_LIMIT}`}
                  id='otro_tipo_de_concepto'
                  name='otro_tipo_de_concepto'
                  label='Indique el otro tipo de concepto'
                  fullWidth
                  required
                  onChange={onChange}
                  inputProps={{ maxLength: CHARACTER_LIMIT }}
                />
              </Grid>
            }

          </Grid>
        </DialogContent>
        <DialogActions>
          <>
            <BtnForm texto="CERRAR" callback={cerrarModal} />
            <BtnForm texto="GUARDAR" callback={guardarFinanciacion} />
          </>
        </DialogActions>
      </Dialog>
    )
  }
}

class ModificarActividad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargandoForm: true,
      cargandoParticipantes: false,
      errores: [],
      activeTab: 0,
      // Actividad y subtipo
      tipos_actividades: [],
      subtipos_actividades: [],
      tipo_actividad: this.props.tipo_actividad ? this.props.tipo_actividad.codigo : '',
      subtipo_actividad: this.props.subtipo_actividad ? this.props.subtipo_actividad.codigo : '',
      
      // Información de movilidad y modalidad
      tipo_movilidad: '',
      tipo_modalidad: '',
      plataforma: '',
      otra_plataforma: '',
      
      // Listas para los selects
      tipos_movilidades: [],
      tipos_modalidades: [],
      plataformas: [],
      participantesSelect: [],
      info_inicial_participantes: [],   
      participantesSeleccionados: [], 
      movilidadesAsignadas: {},
    };
  }

  async componentDidMount() {
    await this.cargarDatosActividad();
    await this.cargarDatosMovilidad();
    await this.cargarParticipantes();
  }

  cargarDatosActividad = async () => {
    const { tipo_actividad } = this.state;
    try {
      const tipos_actividades = await obtenerValoresFiltros([
        { llave: 'generica', valor: TIPOS_ACTIVIDADES_MOVILIDAD },
        { llave: 'estado', valor: 1 },
        { llave: 'valora', valor: '1' }
      ]);

      let subtipos_actividades = [];
      if (tipo_actividad) {
        subtipos_actividades = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: tipo_actividad },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: SUBTIPOS_ACTIVIDADES_MOVILIDAD },
          { llave: 'secundario__valora', valor: '1' }
        ]);
      }
      
      this.setState({
        tipos_actividades,
        subtipos_actividades,
        cargandoForm: false
      });
    } catch (error) {
      console.error("Error al cargar datos de actividad:", error);
      this.setState({ cargandoForm: false });
    }
  }


  cargarParticipantes = async () => {
    const { solicitud_id, actualizarMensaje } = this.props;
    this.setState({ cargandoParticipantes: true });
    consulta(
      `api/v1.0/internacionalizacion/registro/ObtenerParticipantesSolicitud/${solicitud_id}`,
      null,
      'GET',
      (error, estado, resp) => {
        if (error) {
          console.error('Error al cargar participantes:', error);
          this.setState({ cargandoParticipantes: false });
          actualizarMensaje && actualizarMensaje({
            titulo: 'Error al cargar los participantes',
            tipo: 'error',
            mostrar: true,
            tiempo: 5000
          });
        } else {
          if (estado === 200) {
            this.setState({
              participantes: resp.participantes || [],
              cargandoParticipantes: false
            });
          } else {
            console.error('Error al cargar participantes:', resp);
            this.setState({ cargandoParticipantes: false });
            actualizarMensaje && actualizarMensaje({
              titulo: resp.titulo || 'Error al cargar participantes',
              tipo: 'warning',
              mostrar: true,
              tiempo: 5000
            });
          }
        }
      }
    );
  }

  // Métodos de actividad y subtipo (se mantienen sin cambios)
  onChangeActividad = async (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    if (name === 'tipo_actividad') {
      this.setState({ cargandoForm: true });
      try {
        const subtipos_actividades = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: value },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: SUBTIPOS_ACTIVIDADES_MOVILIDAD },
          { llave: 'secundario__valora', valor: '1' }
        ]);
        
        const tieneSubtiposReales = subtipos_actividades.some(item =>
          item.secundario.codigo !== CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD
        );
        const nuevoSubtipo = subtipos_actividades.some(item =>
          item.secundario.codigo === CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD
        ) ? CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD : '';

        this.setState({
          subtipos_actividades,
          cargandoForm: false,
          subtipo_actividad: tieneSubtiposReales ? '' : nuevoSubtipo
        });
      } catch (error) {
        this.setState({ cargandoForm: false });
      }
    }
    
    if (name === 'tipo_actividad' || name === 'subtipo_actividad') {
      this.setState({
        tipo_movilidad: '',
        tipo_modalidad: '',
        plataforma: '',
        otra_plataforma: '',
        tipos_movilidades: [],
        tipos_modalidades: []
      });
      
      if (name === 'subtipo_actividad' && value && this.state.tipo_actividad) {
        await this.cargarTiposMovilidad(this.state.tipo_actividad, value);
      } else if (name === 'tipo_actividad' && value && this.state.subtipo_actividad) {
        await this.cargarTiposMovilidad(value, this.state.subtipo_actividad);
      }
    }
  };

  actualizarActividadInfoBasica = async () => {
    let { info_inicial_participantes, tipo_actividad, subtipo_actividad, participantes, cargandoForm } = this.state;
    let { actualizarMensaje, solicitud_id, cerrarModal, cambiarSeleccionProc, actualizarEstadosRegMovInt, cerraModalDetalleSolic } = this.props;
    
    if (cargandoForm) return;

    const infoCompleta = {
      solicitud_id,
      info_actividad: { tipo_actividad, subtipo_actividad },
      info_inicial_participantes
    };

    const todasIdentificaciones = participantes.map(p => p.identificacion);
    const identificacionesConInfo = [];
    
    
    info_inicial_participantes.forEach(info => {
      if (info.participantes && Array.isArray(info.participantes)) {
        identificacionesConInfo.push(...info.participantes);
      }
    });
    
    
    const participantesSinInfo = todasIdentificaciones.filter(id => 
      !identificacionesConInfo.includes(id)
    );


    if (participantesSinInfo.length > 0) {
     actualizarMensaje({
       titulo: 'Debe asignarle información a todos los participantes',
       tipo: 'info',
       mostrar: true,
       tiempo: 3000
     });
     return;
    }
    
    this.setState({ cargandoForm: true });

    const data = new FormData();
    data.append('solicitud_id', solicitud_id);
    data.append('info_actividad', JSON.stringify({ tipo_actividad, subtipo_actividad }));
    data.append('info_inicial_participantes', JSON.stringify(info_inicial_participantes));

    formulario(
      'api/v1.0/internacionalizacion/registro/ModificarActividadInfoBasica',
      data,
      'put',
      (error, estado, resp) => {
        this.setState({ cargandoForm: false });
        
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo || 'Actividad y movilidades actualizadas correctamente';
            cerrarModal();

            cambiarSeleccionProc('Reg_Mov_Int');
            cerraModalDetalleSolic();
            //actualizarEstadosRegMovInt();
            
          } else {
            tipo = 'info';
            titulo = resp.titulo || 'Error al procesar la solicitud';
          }
        }
        
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 5000 });
      }
    );

  }

  modificarActividadAndSubtipo = () => {
    const { tipo_actividad, subtipo_actividad } = this.state;
    const { tipo_solicitud, solicitud_id, actualizarMensaje } = this.props;
    const data = new FormData();
    data.append('tipo_actividad', tipo_actividad);
    data.append('subtipo_actividad', subtipo_actividad);
    data.append('solicitud_id', solicitud_id);
    data.append('tipo_solicitud', JSON.stringify(tipo_solicitud));

    formulario(
      'api/v1.0/internacionalizacion/registro/ModificarActividadSubActividad',
      data,
      'put',
      (error, estado, resp) => {
        this.setState({ cargandoForm: false });
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.actualizarEdicionActividad();
            this.cargarTiposMovilidad(tipo_actividad, subtipo_actividad);
            this.setState({ activeTab: 1 });
          } else {
            tipo = 'info';
            titulo = resp.titulo || 'Error al procesar la solicitud';
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  // Métodos de movilidad y modalidad
  cargarTiposMovilidad = async (tipoActividad, subtipoActividad) => {
    this.setState({ cargandoForm: true });
    try {
      let tipo_movilidad_act;
      if (tipoActividad === CODIGO_CURSO_CORTO && subtipoActividad === SUBTIPO_ACTI_EMPRESARIAL) {
        tipo_movilidad_act = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: CODIGO_CURSO_CORTO_PROFE },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: TIPOS_MOVILIDADES }
        ]);
      } else {
        tipo_movilidad_act = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: tipoActividad },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: TIPOS_MOVILIDADES }
        ]);
      }

      const tipo_movilidad_subact = await obtenerPermisosValorAlt([
        { llave: 'principal__codigo', valor: subtipoActividad },
        { llave: 'estado', valor: 1 },
        { llave: 'secundario__generica', valor: TIPOS_MOVILIDADES }
      ]);

      const coincidencias_movilidad = tipo_movilidad_act.filter(obj1 =>
        tipo_movilidad_subact.some(obj2 => obj1.secundario.id === obj2.secundario.id)
      );

      const todos_tipos_movilidad = [...tipo_movilidad_act, ...tipo_movilidad_subact];
      const tipos_movilidades_all = todos_tipos_movilidad.filter((item, index, self) =>
        index === self.findIndex(t => t.secundario.id === item.secundario.id)
      );

      this.setState({
        tipos_movilidades: coincidencias_movilidad,
        tipos_movilidades_all,
        cargandoForm: false
      });
    } catch (error) {
      console.error("Error al cargar tipos de movilidad:", error);
      this.setState({ cargandoForm: false });
    }
  };

  onChangeMovilidad = async (event) => {
    const { name, value } = event.target;
    if (name === "tipo_movilidad") {
      this.setState((prevState) => {
        const newAssignments = { ...prevState.movilidadesAsignadas };
        if (Array.isArray(prevState.participantesSeleccionados)) {
          prevState.participantesSeleccionados.forEach((id) => {
            newAssignments[id] = value;
          });
        }
        return {
          tipo_movilidad: value,
          movilidadesAsignadas: newAssignments,
          tipo_modalidad: '',
          plataforma: '',
          otra_plataforma: ''
        };
      }, async () => {
        // Verificar si el método existe antes de llamarlo
        if (typeof this.cargarModalidades === 'function') {
          await this.cargarModalidades(value);
        }
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  
  cargarDatosMovilidad = async () => {
    this.setState({ cargandoForm: true });
    try {
      const plataformas = await obtenerValoresFiltros([
        { llave: 'generica', valor: TIPOS_PLATAFORMAS },
        { llave: 'estado', valor: 1 }
      ]);

      if (this.props.tipo_actividad && this.props.subtipo_actividad) {
        await this.cargarTiposMovilidad(this.props.tipo_actividad.codigo, this.props.subtipo_actividad.codigo);
      }

      this.setState({ 
        plataformas,
        cargandoForm: false
      });
    } catch (error) {
      console.error("Error al cargar datos de movilidad:", error);
      this.setState({ cargandoForm: false });
    }
  }

  cargarModalidades = async (tipoMovilidad) => {
    this.setState({ cargandoForm: true, tipo_modalidad: '' });
    try {
      let modalidadActividad, modalidadSubActividad;
      const { tipo_actividad, subtipo_actividad } = this.state;
      
      // Caso especial: curso corto, subtipo académico y movilidad de profesor
      if (
        tipo_actividad === CODIGO_CURSO_CORTO &&
        subtipo_actividad === SUBTIPO_ACTI_ACADEMICO &&
        TIPO_MOVILIDADES_PROFESOR.includes(tipoMovilidad)
      ) {
        modalidadActividad = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: CODIGO_CURSO_CORTO },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: TIPOS_MODALIDADES }
        ]);
        modalidadSubActividad = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: 'Academico_subtipo_actividad_profesor' },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: TIPOS_MODALIDADES }
        ]);
      } else {
        // Consulta estándar según el tipo y subtipo de actividad
        modalidadActividad = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: tipo_actividad },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: TIPOS_MODALIDADES }
        ]);
        modalidadSubActividad = await obtenerPermisosValorAlt([
          { llave: 'principal__codigo', valor: subtipo_actividad },
          { llave: 'estado', valor: 1 },
          { llave: 'secundario__generica', valor: TIPOS_MODALIDADES }
        ]);
      }
      
      // Se obtiene la intersección de modalidades entre la actividad y el subtipo
      const coincidencias = modalidadActividad.filter(obj1 =>
        modalidadSubActividad.some(obj2 => obj1.secundario.id === obj2.secundario.id)
      );
      
      this.setState({
        tipos_modalidades: coincidencias,
        cargandoForm: false
      });
    } catch (error) {
      console.error("Error al cargar modalidades:", error);
      this.setState({ cargandoForm: false, tipos_modalidades: [] });
    }
  };

  validarFormularioMovilidad = async () => {
    const { tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, participantesSeleccionados } = this.state;
    let errores = await validateInputs([
      { value: tipo_movilidad, name: 'tipo_movilidad', err: { empty: true } },
      { value: tipo_modalidad, name: 'tipo_modalidad', err: { empty: true } }
    ]);
    if (MODALIDADES_VIRTUALES.includes(tipo_modalidad) && !plataforma) {
      errores.push({ llave: 'plataforma', mensaje: 'Este campo es obligatorio para modalidades virtuales' });
    }
    if (plataforma === OTRA_PLATAFORMA && !otra_plataforma) {
      errores.push({ llave: 'otra_plataforma', mensaje: 'Debe especificar la otra plataforma' });
    }
    if (participantesSeleccionados.length === 0) {
      errores.push({ llave: 'participantes', mensaje: 'Debe seleccionar al menos un participante' });
    }
    this.setState({ errores });
    return errores.length === 0;
  };
  
  modificarMovilidadModalidad = async () => {
    const { onActualizacionExitosa, cerrarModal, actualizarMensaje, solicitud_id } = this.props;
    const esValido = await this.validarFormularioMovilidad();
    if (!esValido) {
      actualizarMensaje({ 
        titulo: 'Debes llenar los campos obligatorios.', 
        tipo: 'info', 
        mostrar: true, 
        tiempo: 6000 
      });
      return;
    }
    const { tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, participantesSeleccionados } = this.state;
    const data = new FormData();
    data.append('solicitud_id', solicitud_id);
    data.append('tipo_movilidad', tipo_movilidad);
    data.append('tipo_modalidad', tipo_modalidad);
    if (MODALIDADES_VIRTUALES.includes(tipo_modalidad)) {
      data.append('plataforma', plataforma);
      if (plataforma === OTRA_PLATAFORMA) {
        data.append('otra_plataforma', otra_plataforma);
      }
    }
    data.append('participantes', JSON.stringify(participantesSeleccionados));
    this.setState({ cargandoForm: true });
    formulario(
      'api/v1.0/internacionalizacion/registro/ModificarMovilidadModalidad',
      data,
      'put',
      (error, estado, resp) => {
        this.setState({ cargandoForm: false });
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo || 'Movilidad y modalidad actualizadas correctamente';
            onActualizacionExitosa && onActualizacionExitosa();
            cerrarModal();
          } else {
            tipo = 'info';
            titulo = resp.titulo || 'Error al procesar la solicitud';
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };


  handleToggle = (identificacion, id) => {
    console.log("identificacion", identificacion, id)
    const { participantesSelect } = this.state;
    console.log("participantesSelect", participantesSelect)
    const currentIndex = participantesSelect.findIndex(participante => participante.identificacion === identificacion);

    if (currentIndex === -1) this.setState(prevState => ({ participantesSelect: [...prevState.participantesSelect, { identificacion }] }));
    else this.setState(prevState => ({ participantesSelect: prevState.participantesSelect.filter(participante => !(participante.identificacion === identificacion)) }));
  };

  
  getError = (field) => {
    const { errores } = this.state;
    const error = errores.find(e => e.llave === field);
    return error ? error.mensaje : '';
  };

  handleModalContenedor = () => {
    const { participantesSelect } = this.state;
    if (Array.isArray(participantesSelect) && participantesSelect.length > 0) {
      this.setState({ modalContenedor: true });
    }
  };
  
  
  cerrarModalContenedor = () => {
    this.setState({ modalContenedor: false });
  };
  
  
  asignarMovilidadParticipantes = () => {
    const { 
      tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma,
      participantesSelect, participantes, tipos_movilidades = [],
      tipos_modalidades = [], plataformas = [], info_inicial_participantes = []
    } = this.state;
    let { actualizarMensaje } = this.props;
    
    // Validar entrada
    if (!this.validarEntradaMovilidad()) return;
    
    const tipoMovilidadObj = tipos_movilidades.find(item => item.secundario?.codigo === tipo_movilidad);
    const tipoModalidadObj = tipos_modalidades.find(item => item.secundario?.codigo === tipo_modalidad);
    const plataformaObj = plataformas.find(item => item.codigo === plataforma);
    
    const infoMovilidad = {
      codigo_movilidad: tipo_movilidad,
      nombre_movilidad: tipoMovilidadObj?.secundario?.nombre || '',
      codigo_modalidad: tipo_modalidad,
      nombre_modalidad: tipoModalidadObj?.secundario?.nombre || '',
      codigo_plataforma: plataforma,
      nombre_plataforma: plataformaObj?.nombre || 
                        (plataforma === OTRA_PLATAFORMA ? otra_plataforma : '')
    };
    
    // Identificar participantes seleccionados
    const identificacionesSeleccionadas = participantesSelect
      .map(p => typeof p === 'object' ? p.identificacion : 
           participantes.find(part => part.id === p)?.identificacion)
      .filter(Boolean);
    
    // Actualizar el objeto de cada participante seleccionado
    const nuevosParticipantes = participantes.map(participante => {
      const estaSeleccionado = participantesSelect.some(p => 
        p === participante.id || (p.identificacion && p.identificacion === participante.identificacion)
      );
      return estaSeleccionado ? {...participante, movilidad_asignada: infoMovilidad} : participante;
    });
    
    // Datos para info_inicial_participantes
    const nuevaConfigMovilidad = {
      tipo_movilidad: tipoMovilidadObj?.secundario?.id || '',
      tipo_modalidad: tipoModalidadObj?.secundario?.id || '',
      plataforma: plataforma,
      otra_plataforma: otra_plataforma
    };
    
    // Agrupar participantes por configuración de movilidad
    const mapaMovilidades = new Map();
    
    info_inicial_participantes.forEach(item => {
      if (!item.participantes?.length) return;
      
      // Filtrar participantes que no están siendo modificados
      const participantesNoSeleccionados = item.participantes.filter(
        id => !identificacionesSeleccionadas.includes(id)
      );
      
      if (participantesNoSeleccionados.length > 0) {
        const clave = JSON.stringify({
          tipo_movilidad: item.tipo_movilidad,
          tipo_modalidad: item.tipo_modalidad,
          plataforma: item.plataforma,
          otra_plataforma: item.otra_plataforma
        });
        
        const listaActual = mapaMovilidades.get(clave) || [];
        mapaMovilidades.set(clave, [...listaActual, ...participantesNoSeleccionados]);
      }
    });
    
    // Agregar los participantes con la nueva configuración
    const claveNueva = JSON.stringify(nuevaConfigMovilidad);
    const listaActual = mapaMovilidades.get(claveNueva) || [];
    mapaMovilidades.set(claveNueva, [...listaActual, ...identificacionesSeleccionadas]);
    
    // Convertir mapa a formato para el estado
    const infoInicialActualizada = Array.from(mapaMovilidades).map(([clave, ids]) => ({
      ...JSON.parse(clave),
      participantes: ids
    }));
    
    // Actualizar estado y mostrar confirmación
    this.setState({ 
      modalContenedor: false,
      participantes: nuevosParticipantes,
      info_inicial_participantes: infoInicialActualizada,
      participantesSelect: [],
      tipo_movilidad: '',
      tipo_modalidad: '',
      plataforma: '',
      otra_plataforma
    });
    
    actualizarMensaje({
      titulo: 'Movilidad asignada correctamente',
      tipo: 'success',
      mostrar: true,
      tiempo: 2000
    });
  };
  
  
  validarEntradaMovilidad = () => {
    const { tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma } = this.state;
    let errores = [];
    
    if (!tipo_movilidad) {
      errores.push({ llave: 'tipo_movilidad_modal', mensaje: 'Seleccione un tipo de movilidad' });
    }
    
    if (!tipo_modalidad) {
      errores.push({ llave: 'tipo_modalidad_modal', mensaje: 'Seleccione un tipo de modalidad' });
    }
    
    if (MODALIDADES_VIRTUALES.includes(tipo_modalidad) && !plataforma) {
      errores.push({ llave: 'plataforma_modal', mensaje: 'Seleccione una plataforma' });
    }
    
    if (plataforma === OTRA_PLATAFORMA && !otra_plataforma) {
      errores.push({ llave: 'otra_plataforma_modal', mensaje: 'Especifique la plataforma' });
    }
    
    this.setState({ errores });
    return errores.length === 0;
  };
  
  
  renderModalAsignacionMovilidad() {
    const { 
      modalContenedor, 
      tipo_movilidad, 
      tipo_modalidad, 
      plataforma,
      otra_plataforma,
      tipos_movilidades = [],
      tipos_modalidades = [],
      plataformas = [],
      participantesSelect = []
    } = this.state;
    
    return (
      <Dialog
        open={modalContenedor}
        onClose={this.cerrarModalContenedor}
        fullWidth
        maxWidth="md"
      >
        <AppBarModal titulo="Asignar Movilidad a Participantes" mostrarModal={this.cerrarModalContenedor} />
        <DialogContent>
          <Grid container spacing={3} style={{ marginTop: '1%', marginBottom: '2%' }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Asignar movilidad a {participantesSelect.length} participante(s) seleccionado(s)
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl className="form-control" required error={Boolean(this.getError('tipo_movilidad_modal'))} size="small" fullWidth>
                <InputLabel id="tipo_movilidad_modal_label">Tipo de Movilidad</InputLabel>
                <Select
                  labelId="tipo_movilidad_modal_label"
                  id="tipo_movilidad_modal"
                  name="tipo_movilidad"
                  value={tipo_movilidad}
                  label="Tipo de Movilidad"
                  onChange={this.onChangeMovilidad}
                  fullWidth
                >
                  <MenuItem value=""><em>Seleccione tipo de movilidad</em></MenuItem>
                  {Array.isArray(tipos_movilidades) && tipos_movilidades.map(({ secundario }) => (
                    <MenuItem key={secundario.id} value={secundario.codigo}>
                      {secundario.nombre}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{this.getError('tipo_movilidad_modal')}</FormHelperText>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl className="form-control" required error={Boolean(this.getError('tipo_modalidad_modal'))} size="small" fullWidth disabled={!tipo_movilidad}>
                <InputLabel id="tipo_modalidad_modal_label">Tipo de Modalidad</InputLabel>
                <Select
                  labelId="tipo_modalidad_modal_label"
                  id="tipo_modalidad_modal"
                  name="tipo_modalidad"
                  value={tipo_modalidad}
                  label="Tipo de Modalidad"
                  onChange={this.onChangeMovilidad}
                  fullWidth
                >
                  <MenuItem value=""><em>Seleccione tipo de modalidad</em></MenuItem>
                  {Array.isArray(tipos_modalidades) && tipos_modalidades.map(({ secundario }) => (
                    <MenuItem key={secundario.id} value={secundario.codigo}>
                      {secundario.nombre}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{this.getError('tipo_modalidad_modal')}</FormHelperText>
              </FormControl>
            </Grid>
            
            {MODALIDADES_VIRTUALES.includes(tipo_modalidad) && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl className="form-control" required error={Boolean(this.getError('plataforma_modal'))} size="small" fullWidth>
                    <InputLabel id="plataforma_modal_label">Plataforma</InputLabel>
                    <Select
                      labelId="plataforma_modal_label"
                      id="plataforma_modal"
                      name="plataforma"
                      value={plataforma}
                      label="Plataforma"
                      onChange={this.onChangeMovilidad}
                      fullWidth
                    >
                      <MenuItem value=""><em>Seleccione plataforma</em></MenuItem>
                      {Array.isArray(plataformas) && plataformas.map(plataforma => (
                        <MenuItem key={plataforma.id} value={plataforma.codigo}>
                          {plataforma.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{this.getError('plataforma_modal')}</FormHelperText>
                  </FormControl>
                </Grid>
                
                {plataforma === OTRA_PLATAFORMA && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Especifique otra plataforma"
                      name="otra_plataforma"
                      value={otra_plataforma}
                      onChange={this.onChangeMovilidad}
                      error={Boolean(this.getError('otra_plataforma_modal'))}
                      helperText={this.getError('otra_plataforma_modal')}
                      fullWidth
                      size="small"
                      required
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.cerrarModalContenedor}>
            CANCELAR
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={this.asignarMovilidadParticipantes}
          >
            ASIGNAR MOVILIDAD
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  
  pintarParticipantes() {
  let { participantesSelect = [], participantes = [], info_inicial_participantes = [], tipos_movilidades_all = [], cargando} = this.state;

  // Verifica que info_inicial_participantes sea un array
  if (!Array.isArray(info_inicial_participantes)) {
    info_inicial_participantes = [];
  }

  // Verifica que tipos_movilidades_all sea un array
  if (!Array.isArray(tipos_movilidades_all)) {
    tipos_movilidades_all = [];
  }

  const handleToggle = (identificacion, id) => {
    this.setState(prevState => {
      const { participantesSelect } = prevState;
      const currentIndex = participantesSelect.findIndex(p => p === id);
      const newSeleccionados = [...participantesSelect];
      
      if (currentIndex === -1) {
        newSeleccionados.push(id);
      } else {
        newSeleccionados.splice(currentIndex, 1);
      }
      
      return { participantesSelect: newSeleccionados };
    });
  };

  const handleSelectAllToggle = () => {
    this.setState(prevState => {
      const { participantes, participantesSelect, selectAll } = prevState;
      
      if (!selectAll) {
        // Seleccionar todos
        return { 
          participantesSelect: participantes.map(p => p.id), 
          selectAll: true 
        };
      } else {
        // Deseleccionar todos
        return { 
          participantesSelect: [], 
          selectAll: false 
        };
      }
    });
  };

  const isParticipanteSelected = (identificacion, id) => {
    return this.state.participantesSelect.includes(id);
  };

  const acciones = (data, tipo) => {
    if (tipo === 'participante') {
      return (
        <>
          <Tooltip title="Seleccionar">
            <Checkbox 
              edge="start" 
              onChange={() => handleToggle(data.identificacion, data.id)} 
              checked={isParticipanteSelected(data.identificacion, data.id)} 
              style={{ color: '#2196f3' }} 
            />
          </Tooltip>
        </>
      );
    } else {
      return (
        <Tooltip title="Seleccionar todo">
          <Checkbox 
            onChange={handleSelectAllToggle} 
            checked={this.state.selectAll} 
            style={{ color: '#2196f3' }} 
          />
        </Tooltip>
      );
    }
  };

  const renderIconos = (data, tipo) => {
    // Verificaciones previas para evitar errores
    if (!data || !data.identificacion || !info_inicial_participantes.length) {
      return <CheckBoxIcon color="disabled" />;
    }

    const participantesData = { 'info_basica': info_inicial_participantes };
    // Usa el operador opcional de encadenamiento para evitar errores
    const info_inicial = participantesData[tipo]?.find(participante => 
      participante && participante.participantes && 
      Array.isArray(participante.participantes) && 
      participante.participantes.includes(data.identificacion)
    );
    
    return <CheckBoxIcon color={info_inicial ? 'primary' : 'disabled'} />;
  };

  
  const renderMovilidad = (data) => {
    // Verificaciones previas para evitar errores
    if (!data) return 'No registra';
    
    // Si el participante tiene movilidad_asignada directamente, usar esa información
    if (data.movilidad_asignada) {
      let infoMovilidad = `${data.movilidad_asignada.nombre_movilidad || 'No especificada'}`;
      if (data.movilidad_asignada.nombre_modalidad) {
        infoMovilidad += ` / ${data.movilidad_asignada.nombre_modalidad}`;
      }
      if (MODALIDADES_VIRTUALES.includes(data.movilidad_asignada.codigo_modalidad) && data.movilidad_asignada.nombre_plataforma) {
        infoMovilidad += ` / ${data.movilidad_asignada.nombre_plataforma}`;
        if (data.movilidad_asignada.codigo_plataforma === OTRA_PLATAFORMA && data.movilidad_asignada.otra_plataforma) {
          infoMovilidad += `: ${data.movilidad_asignada.otra_plataforma}`;
        }
      }
      return infoMovilidad;
    }
    
    // Verificaciones para la búsqueda por info_inicial_participantes
    if (!data.identificacion || !this.state.info_inicial_participantes || !this.state.info_inicial_participantes.length || !this.state.tipos_movilidades_all || !this.state.tipos_movilidades_all.length) {
      return 'No registra';
    }
    
    // Buscar en info_inicial_participantes
    const info_inicial = this.state.info_inicial_participantes.find(participante => 
      participante && participante.participantes && 
      Array.isArray(participante.participantes) && 
      participante.participantes.includes(data.identificacion)
    );
    
    if (info_inicial) {
      // Encontrar la movilidad
      const tipoMovilidadId = info_inicial.tipo_movilidad;
      const tipoMovilidadEncontrado = this.state.tipos_movilidades_all.find(tipo => 
        tipo && tipo.id === tipoMovilidadId
      );
      
      let infoCompleta = tipoMovilidadEncontrado ? tipoMovilidadEncontrado.nombre : 'No especificada';
      
      // Añadir modalidad si existe
      if (info_inicial.tipo_modalidad) {
        const modalidad = this.state.tipos_modalidades.find(m => m.secundario && m.secundario.id === info_inicial.tipo_modalidad);
        if (modalidad) {
          infoCompleta += ` / ${modalidad.secundario.nombre}`;
        }
      }
      
      // Añadir plataforma si es modalidad virtual
      if (info_inicial.plataforma && MODALIDADES_VIRTUALES.includes(info_inicial.tipo_modalidad)) {
        const plataforma = this.state.plataformas.find(p => p.codigo === info_inicial.plataforma);
        if (plataforma) {
          infoCompleta += ` / ${plataforma.nombre}`;
          if (info_inicial.plataforma === OTRA_PLATAFORMA && info_inicial.otra_plataforma) {
            infoCompleta += `: ${info_inicial.otra_plataforma}`;
          }
        }
      }
      
      return infoCompleta;
    } 
    
    return 'No registra';
  };

  // Verifica que participantes sea un array
  if (!Array.isArray(participantes)) {
    participantes = [];
  }
  
  // Verifica que participantesSelect sea un array
  if (!Array.isArray(participantesSelect)) {
    participantesSelect = [];
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {cargando && <TareasTerminadas mensaje={'Cargando..'} marginTop='1%' imagen={emma_s1} widthImg="9%" style={{ minWidth: '60%' }} />}
          {(participantes.length > 0 && !cargando) ?
            <div style={{ margin: '0 0 3% 0 ' }}>
              <div style={{ display: 'flex', justifyContent: 'end', margin: '0% 0 1% 0' }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  style={{ borderRadius: '20px', color: 'white' }} 
                  disabled={participantesSelect.length === 0} 
                  onClick={this.handleModalContenedor} 
                >
                  <AddIcon />
                </Button>
              </div>

              <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: '5%', marginTop: '3%' }}>
                <TableContainer sx={{ maxWidth: '100%' }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow key={0} style={{ padding: '5px', justifyContent: 'space-between' }} >
                        <TableCell style={{ minWidth: '2rem', maxWidth: '9.5rem', padding: '3px' }} >{acciones(participantes, 'todo')}</TableCell>
                        <TableCell style={{ minWidth: '2rem', maxWidth: '9rem', padding: '3px', textAlign: 'center' }} >NOMBRE</TableCell>
                        <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >IDENTIFICACIÓN</TableCell>
                        <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >TIPO MOVILIDAD</TableCell>
                        <TableCell style={{ minWidth: '2rem', maxWidth: '4rem', padding: '3px', textAlign: 'center' }} >MOVILIDAD EDITAR</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {participantes.map((participante, i) => {
                        let nombre_completo = `${participante.primer_nombre ?? ''} ${participante.segundo_nombre ?? ''} ${participante.primer_apellido ?? ''} ${participante.segundo_apellido ?? ''}`;
                        return (
                          <TableRow key={i} style={{ padding: '5px', justifyContent: 'space-between' }}>
                            <TableCell style={{ minWidth: '2rem', maxWidth: '9.5rem' }} >{acciones(participante, 'participante')}</TableCell>
                            <TableCell style={{ minWidth: '2rem', maxWidth: '9rem', padding: '3px', textAlign: 'center' }}>{nombre_completo.toUpperCase()}</TableCell>
                            <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >{participante.identificacion}</TableCell>
                            <TableCell style={{ minWidth: '2rem', maxWidth: '8rem', padding: '3px', textAlign: 'center' }} >{renderMovilidad(participante)}</TableCell>
                            <TableCell style={{ minWidth: '2rem', maxWidth: '4rem', textAlign: 'center' }} >{renderIconos(participante, 'info_basica')}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            :
            <TareasTerminadas mensaje={<React.Fragment>No hay participantes registrados.<br /></React.Fragment>} marginTop='1%' imagen={emma_s1} widthImg="9%" style={{ minWidth: '60%' }} />
          }
        </Grid>
      </Grid>
      
      {/* Renderizar el modal para asignar movilidad */}
      {this.renderModalAsignacionMovilidad()}
    </>
  );
  }
  
  render() {
    const { activeTab, cargandoForm, tipos_actividades, subtipos_actividades, tipo_actividad, subtipo_actividad, cargandoParticipantes } = this.state;
     const { open, cerrarModal } = this.props;

    if (activeTab === 0) {
      return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={cerrarModal}>
          <AppBarModal titulo="Modificar Tipo/Subtipo de Actividad" mostrarModal={cerrarModal} />
          <DialogContent className="scroll">
            {cargandoForm ? (
              <TareasTerminadas mensaje="Cargando" marginTop="7%" imagen={emma_w} widthImg="7%" cargando />
            ) : (
              <Grid container spacing={3} style={{ marginTop: '1%', marginBottom: '2%' }}>
                <Grid item xs={12}>
                  <FormControl className="form-control" required error={Boolean(this.getError('tipo_actividad'))} size="small" fullWidth>
                    <InputLabel id="tipo_actividad_label">Tipo de actividad</InputLabel>
                    <Select
                      labelId="tipo_actividad_label"
                      id="tipo_actividad"
                      name="tipo_actividad"
                      value={tipo_actividad}
                      label="Tipo de actividad"
                      onChange={this.onChangeActividad}
                      fullWidth
                    >
                      {tipos_actividades.length > 0 ?
                        tipos_actividades
                          .sort((a, b) => a.id - b.id)
                          .map(tipo => <MenuItem key={tipo.id} value={tipo.codigo}>{tipo.nombre}</MenuItem>)
                        : <MenuItem value=""><em>Seleccione tipo de actividad</em></MenuItem>
                      }
                    </Select>
                    <FormHelperText>{this.getError('tipo_actividad')}</FormHelperText>
                  </FormControl>
                </Grid>
                {subtipos_actividades.length > 0 && subtipo_actividad !== CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD && (
                  <Grid item xs={12}>
                    <FormControl className="form-control" size="small" fullWidth disabled={!tipo_actividad}>
                      <InputLabel id="subtipo_actividad_label">Subtipo de Actividad</InputLabel>
                      <Select
                        labelId="subtipo_actividad_label"
                        id="subtipo_actividad"
                        name="subtipo_actividad"
                        value={subtipo_actividad}
                        label="Subtipo de Actividad"
                        onChange={this.onChangeActividad}
                        fullWidth
                      >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        {subtipos_actividades.map(({ secundario }) => (
                          <MenuItem key={secundario.id} value={secundario.codigo}>
                            {secundario.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={cerrarModal}>CANCELAR</Button>
            <Button variant="contained" color="secondary" onClick={() => this.setState({ activeTab: 1 })}>
              MODIFICAR MOVILIDAD
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={cerrarModal}>
          <AppBarModal titulo="Modificar Movilidad y Modalidad" mostrarModal={cerrarModal} />
          <DialogContent className="scroll">
            {cargandoForm || cargandoParticipantes ? (
              <TareasTerminadas mensaje="Cargando..." marginTop="7%" imagen={emma_w} widthImg="7%" cargando />
            ) : (
              <Grid container spacing={3} style={{ marginTop: '1%', marginBottom: '2%' }}>
                {/* Sección para mostrar participantes */}
                <Grid item xs={12}>
                  {this.pintarParticipantes()}
                </Grid>
                
                {/* Sección de opciones de movilidad y modalidad */}
               
                {Boolean(this.getError('participantes')) && (
                  <Grid item xs={12}>
                    <Alert severity="error">{this.getError('participantes')}</Alert>
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => this.setState({ activeTab: 0, movilidadesAsignadas: {}, participantesSeleccionados: [], info_inicial_participantes: [], participantesSelect: [] })}>VOLVER</Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={this.actualizarActividadInfoBasica} 
              disabled={cargandoForm || cargandoParticipantes}
            >
              GUARDAR
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }
}

export const ModalContenedor = withStyles(styles)(ModalContenedorAll);

export { AgregarParticipante, AgregarInfoBasica, AgregarActividad, AgregarFinanciacion, AgregarParticipanteMasivo, DetalleParticipante, AgregarInstitucion, FinanciacionParticipante, InstitucionParticipante, ModificarFinanciacion, ModificarActividad };
