import React, { Component } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core'
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from '../general/BotonesAccion';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

export default class Pasos extends Component {

    agruparRespuestas = (list) => {
        let sinRepetir = []
        list.forEach(element => {
            if (!sinRepetir.includes(element.pregunta.id)) sinRepetir.push(element.pregunta.id)
        });

        let nuevaLista = sinRepetir.map(id => {
            let aux = list.filter(d => d.pregunta.id == id);
            let respuesta = aux[0].respuesta;
            
            if(aux.length > 1 && respuesta){
                respuesta.nombre = aux.map( d => d.respuesta ? d.respuesta.nombre : "" ).join(",")
            }

            return {
                ...aux[0],
                respuesta,  
            }
        })

        return nuevaLista
    }

    render() {
        let {
            openModal,
            closeModal,
            data,
            detalles,
        } = this.props
        
        return (
            <Dialog open={openModal} onClose={closeModal} fullWidth={true} maxWidth="md" fullScreen={window.innerWidth < 600}>
                <AppBarModal titulo={"Secciones"} mostrarModal={closeModal} titulo_accion="" />
                <DialogContent style={{ padding: 0, overflowX: 'hidden' }} >
                    <React.Fragment>
                        {
                            data.map((paso) =>
                                <Accordion >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                        <Stepper style={{ margin: 0, padding: 0 }} activeStep={1}>
                                            <Step key={paso.nombre}>
                                                <StepLabel>{paso.nombre}</StepLabel>
                                            </Step>
                                        </Stepper>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {
                                                this.agruparRespuestas(detalles.filter(d => d.paso.id == paso.id)).map(d =>
                                                    <div style={{
                                                        width: "100%",
                                                        margin: "5px 30px"
                                                    }}>
                                                        <span style={{

                                                        }}>
                                                            {
                                                                d.pregunta.nombre
                                                            }
                                                        </span>
                                                        <br />
                                                        {
                                                            `RT:// ${d.respuesta ? d.respuesta.nombre : d.respuesta_texto}`
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    <BtnForm texto="CERRAR" callback={closeModal} />
                </DialogActions>
            </Dialog>

        )
    }
}