
import React, { Component } from 'react';
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../../redux/actions/actGlobal";
import { MenuAdministrar } from "./ConsultoriaMenu";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import {
  consulta, crear_form_data, mostrarError, obtenerValoresFiltros,
  generarFiltros, formulario, modificarValorGenericaAlter,obtenerPermisosValorAlt
} from "../../../global/js/funciones";
import ListarDatos from "../../general/ListarDatos";
import TituloAccion from '../../general/TituloAccion';
import moment from 'moment'
import DetalleConsultoria from './detalleConsultoria';
import ConsultoriaGestionar from './ConsultoriaGestionar';
import { mostrarModalDetalleConsultoria, mostrarModalAddConsultas, mostrarModalGestionarConsultoria, mostrarModalAddFiltrosConsultoria, mostrarModalModPeriodoConsultoria, mostrarModalDuplicarConsultoria, mostrarModalEditarConsultoria } from "../../../redux/actions/actConsultoria";
import { Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm, BtnDetalle, BtnGestionar, BtnRegresar, BtnDuplicar, BtnEditar } from "../../general/BotonesAccion";
import { Paper, Grid, Tooltip, IconButton, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog } from '@material-ui/core'
import { orange } from '@material-ui/core/colors';
import FilterListIcon from '@material-ui/icons/FilterList';
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import assigment from '../../../global/imagenes/books.png';
import ConsultoriaAgregar from './ConsultoriaAgregar';
import ConsultoriaDuplicar from './ConsultoriaDuplicar';
import ConsultoriaEditar from './ConsultoriaEditar';
import ConsultoriaPerfilesAdministrar from './ConsultoriaPerfilesAdministrar';
import AccionesRapidas from '../../general/AccionesRapidas';
import ConsultoriaFiltrar from "./ConsultoriaFiltrar";
import {DOCUMENTOS_INFORMATIVOS} from "../helper"



class ConsultoriaAdministrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menu: true,
      seleccion: 'proceso',
      cargando: true,
      filtrarPeriodo: false,
      administrador: false,
      procesos: [],
      proceso: '',
      estados_procesos: [],
      accionProceso: 'inicio',
      periodos: [],
      periodo_filtro: null,
      filtros: [],
      filtros_consultoria: [],
      informe: [],
      cargarFiltroP: true,
      descargarInforme: false,
      seleccionMasiva: [],
      periodo: '',
      doc_informativos: [],
      historial:[],
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/consultoria_administrar', nombre: 'Consultas Administración' })
    this.esAdministrador()
    this.obtenerDatosIniciales();
    this.setState({ cargando: true })
    this.setState({
         historial: await this.getProcesos(),
         doc_informativos: (await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'consultoria_admin_act' }, { 'llave': 'secundario__generica', 'valor': DOCUMENTOS_INFORMATIVOS }])).map(({secundario}) => secundario),
         cargando: false
    })
  }

  async componentDidUpdate({ }, { seleccion, accionProceso }) {
    let { seleccion: seleccionNew, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'proceso') this.actualizarDataConsultoria();
      if (seleccionNew === 'historial') this.getProcesos();
    }


    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew != 'Tod_Consul') {
        this.cambiarEstado({ cargando: true })
        if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
        else this.actualizarDataConsultoria([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
      }
    }
  }

  async actualizarDataConsultoria(filtros = [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    this.setState({ cargando: true })
    let procesos = await this.obtenerSolicitudes(filtros);
    this.obtenerDatosIniciales()
    this.setState({ procesos, cargando: false, filtros_consultoria: filtros })
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Consul_Sol' }, { 'llave': 'estado', 'valor': '1' }]);
    let filtros = periodo.length > 0 ? [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : []
    this.obtenerEstadoSolicitudes(periodo[0].nombre);
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtros: periodo.length > 0 ? periodo[0].nombre : '' });
  }


  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Consul_Adm" || usuario.perfil.codigo === "Per_Consul_Cor")) {
      this.setState({ administrador: true })
    }
  }


  async obtenerSolicitudes(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/consultoria/listar/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  obtenerEstadoSolicitudes = (periodo) => {
    consulta(`api/v1.0/consultoria/estados/${periodo}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte);
          })
        }
        this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden), cargando: false });
      }
    })
  }


  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(periodo_filtro)
  }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/consultoria/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  configFiltros = (accion_actual) => {
    let { periodo_filtro } = this.state
    this.setState({ seleccionMasiva: [] })
    switch (accion_actual) {
      case 'Tod_Consul':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      case 'inicio':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
    }
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }


  guardarConsulta = async (data) => {
    let { actualizarMensaje, mostrarModalAddConsultas } = this.props;
    actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 4000 });
    formulario(`api/v1.0/consultoria/crear/interno`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddConsultas(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });

    })
  }

  actualizarConsulta = async (data, id) => {
    /*for (let [key, value] of data) {
      console.log(`${key}: ${value}`)
    }*/
    let { mostrarModalEditarConsultoria, actualizarMensaje } = this.props
    formulario(`api/v1.0/consultoria/consultoria_actualizar/${id}`, data, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalEditarConsultoria(false)
        } else {
          tipo = 'info'
          titulo = resp.titulo
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
    })
  }

  gestionarConsultoria = async (id, data, callback) => {
    let { mostrarModalGestionarConsultoria, actualizarMensaje } = this.props;
    actualizarMensaje({ titulo: 'Enviado datos, por favor espere...', tipo: 'info', mostrar: true, tiempo: 4000 });
    let form = await crear_form_data(data);
    data.archivos.map((file, index) => form.append(`archivo${index}`, file));
    formulario(`api/v1.0/consultoria/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalGestionarConsultoria(false);
          this.actualizarDataConsultoria(this.state.filtros_consultoria);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }

  modificarPeriodo = async (periodo) => {
    let { actualizarMensaje, mostrarModalModPeriodoConsultoria } = this.props;
    if (periodo) {
      let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Consul_Sol' }]);
      if (p.length > 0) {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'nombre': periodo })
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalModPeriodoConsultoria(false);
            this.cambiarEstado({ periodo, periodo_filtro: periodo });
            this.setState({ cargando: true })
            this.obtenerEstadoSolicitudes(periodo)
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      } else actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 });
    } else actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 });
  }

  renderProcesos() {
    let { accionProceso } = this.state;
    return (
      <>
        {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
        {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_consultoria: [], accionProceso: 'inicio' })} /></div>}
      </>
    )
  }

  getProcesos = () => {
    return new Promise(resolve => {
        consulta("api/v1.0/consultoria/procesos", null, null, (error, estado, resp) => {
            resolve(resp)
        })
    })
}

  pintarInicioProcesos = () => {
    let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo, descargarInforme, informe } = this.state;
    let { mostrarModalAddFiltrosConsultoria } = this.props
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Consul_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_Consul_Pro': { 'backgroundColor': '#009688', color },
        'Est_Consul_Fin': { 'backgroundColor': '#0d47a1', color },
        'Est_Consul_Rech': { 'backgroundColor': '#b71c1c', color },
        'Est_Consul_Asig': { 'backgroundColor': '#9315b8', color },
        'Est_Consul_Hech': { 'backgroundColor': '#n76c1c', color },
        'Est_Consul_Reg': { 'backgroundColor': '#d34c1c', color },
        'Est_Consul_CentroC': { 'backgroundColor': '#d34c1c', color },
        'Est_Consul_Centro_Nega': { 'backgroundColor': '#b71c1c', color },
        'Est_Consul_Subsa': { 'backgroundColor': '#009933', color },
        'Est_Consul_Correg':{'backgroundColor': '#25F803', color },
        'Tod_Consul': { 'backgroundColor': '#0d47a1', color },
      }
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      if (codigo != 'Tod_Consul') this.setState({ accionProceso: codigo })
      else {
        mostrarModalAddFiltrosConsultoria(true);
      }
    };

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {(estados_procesos.length === 0) ?
                  <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                          <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                            <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true} />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >

        <Dialog fullWidth={true} maxWidth="sm" open={descargarInforme} onClose={() => this.cambiarEstado({ descargarInforme: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Descargar Informe !' mostrarModal={() => this.cambiarEstado({ descargarInforme: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            <ListarDatos
              datos={informe}
              titulo='Lista Solicitudes'
              id='tbl_consultoria_listar'
              opciones={true}
              descargar='INFORME CONSULTAS'
              filtrar={false}
              refrescar={false}
              actRefrescar={() => { }}
              avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
              fila_principal={({ nombre }) => nombre}
              filas={[
                { 'id': 'Nombre Area Derecho', 'mostrar': ({ nombre }) => nombre },
                { 'id': 'Cantidad Solicitudes', 'mostrar': ({ cantidad }) => cantidad },
              ]}
            />
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog >
      </>
    )
  }

  pintarProcesos() {
    let { usuario } = this.props;
    let { procesos, administrador } = this.state;
    let { mostrarModalDetalleConsultoria, mostrarModalGestionarConsultoria, mostrarModalDuplicarConsultoria, mostrarModalEditarConsultoria } = this.props;
    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso } = data;

      const onClickDetalle = proceso => {
        this.setState({ proceso })
        mostrarModalDetalleConsultoria(true)
      }
      const onClickGestionar = proceso => {
        this.setState({ proceso })
        mostrarModalGestionarConsultoria(true)
      }

      const onClickDuplicar = proceso => {
        this.setState({ proceso })
        mostrarModalDuplicarConsultoria(true)
      }

      const onClickEditar = proceso => {
        this.setState({ proceso })
        mostrarModalEditarConsultoria(true)
      }

      if(codigo != "Est_Consul_CentroC" && codigo != "Est_Consul_Fin" && codigo != "Est_Consul_Reg" && codigo != "Est_Consul_Correg"){
        let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
        let gestionar = (permiso === '1' || administrador || usuario.perfil.codigo === "Per_Consul_Est") && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
        let duplicar = <BtnDuplicar callback={() => onClickDuplicar(data)} color='#01579b' texto='Duplicar' />;
        let editar = <BtnEditar callback={() => onClickEditar(data)} color='#01579b' texto='Editar' />;
        return <div>{detalle}{gestionar}{duplicar}{editar}</div>;
      }else if(codigo == "Est_Consul_Reg"){
        let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
        let gestionar = (permiso === '1' || administrador || usuario.perfil.codigo === "Per_Consul_Est") && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
        let editar = <BtnEditar callback={() => onClickEditar(data)} color='#01579b' texto='Editar' />;
        return <div>{detalle}{gestionar}{editar}</div>;
      }else{
        let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
        let gestionar = (permiso === '1' || administrador || usuario.perfil.codigo === "Per_Consul_Est") && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
        return <div>{detalle}{gestionar}</div>;
      }
    }
    return (
      <>
        <ListarDatos
          datos={procesos}
          titulo='Lista Solicitudes'
          id='tbl_consultoria_listar'
          opciones={true}
          descargar=''
          filtrar={false}
          refrescar={false}
          actfiltrar={() => this.actualizarDataConsultoria(this.state.filtros_consultoria)}
          actRefrescar={() => { }}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ solicitante: { primer_nombre } }) => primer_nombre.toUpperCase().charAt(0)}
          fila_principal={({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'usuario_registro', 'mostrar': ({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
            { 'id': 'identificacion', 'mostrar': ({ solicitante: { identificacion } }) => identificacion },
            { 'id': 'radicado ID', 'mostrar': ({ id }) => `Radicado  SC-${id}` },
            { 'id': 'radicado asociado', 'mostrar': ({ id_asociado }) => `Asociado a solicitud con radicado ${id_asociado}` },
            { 'id': 'area', 'mostrar': ({ area }) => `${area != null ? `${`${area.nombre}`}` : ''}` },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            { 'id': 'estado_actual', 'mostrar': ({ estado_actual, gestor_nombre }) => `${estado_actual.nombre} ${gestor_nombre ? `${` - ${gestor_nombre}`}` : ''}` },
          ]}
        />
      </>
    )
  }
  pintarNuevo() {
    let { mostrarModalAddConsultas } = this.props
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalAddConsultas(true) }}>
          <Paper elevation={8} square>
            <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
              <CardHeader
                avatar={<Avatar aria-label="recipe" style={{ backgroundColor: orange[500] }}>C</Avatar>}
                title={`Consultas Juridicas`}
              />
              <CardContent >
                <Grid container direction={'row'} justifyContent={'center'}>
                  <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                    <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                    <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                      Aqui podras solicitar tu asesoria legal
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  pintarAdministrar() {
    let { periodo, } = this.state;
    let { actualizarMensaje, modalModPeriodoConsultoria, mostrarModalModPeriodoConsultoria } = this.props;
    return <ConsultoriaPerfilesAdministrar
      periodo={periodo}
      modificarPeriodo={this.modificarPeriodo}
      actualizarMensaje={actualizarMensaje}
      modalModPeriodoConsultoria={modalModPeriodoConsultoria}
      mostrarModalModPeriodoConsultoria={mostrarModalModPeriodoConsultoria}
    />
  }

  pintarHistorial() {
    let {historial} = this.state;
    let { mostrarModalDetalleConsultoria} = this.props;

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso } = data;

      const onClickDetalle = proceso => {
        this.setState({ proceso })
        mostrarModalDetalleConsultoria(true)
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;

      return <div>{detalle}</div>;

    }

    const obtenerColor = codigo => {
        let color = '#ffffff'
        const colores = {
          'Est_Consul_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
          'Est_Consul_Pro': { 'backgroundColor': '#009688', color },
          'Est_Consul_Fin': { 'backgroundColor': '#0d47a1', color },
          'Est_Consul_Rech': { 'backgroundColor': '#b71c1c', color },
          'Est_Consul_Asig': { 'backgroundColor': '#9315b8', color },
          'Est_Consul_Hech': { 'backgroundColor': '#n76c1c', color },
          'Est_Consul_Reg': { 'backgroundColor': '#d34c1c', color },
          'Est_Consul_CentroC': { 'backgroundColor': '#d34c1c', color },
          'Est_Consul_Centro_Nega': { 'backgroundColor': '#b71c1c', color },
          'Est_Consul_Subsa': { 'backgroundColor': '#009933', color },
          'Est_Consul_Correg':{'backgroundColor': '#25F803', color },
        }
        return colores[codigo]
    }

    return (
      <>
        <ListarDatos
          datos={historial}
          titulo='Lista Solicitudes'
          id='tbl_consultoria_listar'
          opciones={true}
          descargar=''
          filtrar={false}
          refrescar={false}
          actfiltrar={() => this.actualizarDataConsultoria(this.state.filtros_consultoria)}
          actRefrescar={() => { }}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ solicitante: { primer_nombre } }) => primer_nombre.toUpperCase().charAt(0)}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'usuario_registro', 'mostrar': ({ solicitante: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
            { 'id': 'identificacion', 'mostrar': ({ solicitante: { identificacion } }) => identificacion },
            { 'id': 'radicado ID', 'mostrar': ({ id }) => `Radicado  SC-${id}` },
            { 'id': 'radicado asociado', 'mostrar': ({ id_asociado }) => `Asociado a solicitud con radicado ${id_asociado}` },
            { 'id': 'area', 'mostrar': ({ area }) => `${area != null ? `${`${area.nombre}`}` : ''}` },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            { 'id': 'estado_actual', 'mostrar': ({ estado_actual, gestor_nombre }) => `${estado_actual.nombre} ${gestor_nombre ? `${` - ${gestor_nombre}`}` : ''}` },
          ]}
        />
      </>
    )
  }



  render() {
    let { actualizarMensaje, modalDetalleConsultoria, mostrarModalDetalleConsultoria, modalAddConsultas, mostrarModalDuplicarConsultoria, modalDuplicarConsultoria, mostrarModalEditarConsultoria, modalEditarConsultoria,
      mostrarModalAddConsultas, modalGestionarConsultoria, mostrarModalGestionarConsultoria, mostrarModalAddFiltrosConsultoria, ModalAddFiltrosConsultoria, usuario } = this.props;

    let { seleccion, accionProceso, cargando, proceso, administrador,doc_informativos } = this.state;

    return (
      <>
        <MenuAdministrar
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
          administrador={administrador}
          doc_informativos={doc_informativos}
        />
        <ConsultoriaAgregar
          mostrarModalAddConsultas={mostrarModalAddConsultas}
          modalAddConsultas={modalAddConsultas}
          actualizarMensaje={actualizarMensaje}
          guardarConsulta={this.guardarConsulta}
        />
        <ConsultoriaDuplicar
          proceso={proceso ? proceso : 0}
          mostrarModalDuplicarConsultoria={mostrarModalDuplicarConsultoria}
          modalDuplicarConsultoria={modalDuplicarConsultoria}
          actualizarMensaje={actualizarMensaje}
          guardarConsulta={this.guardarConsulta}
        />
        <ConsultoriaEditar
          proceso={proceso ? proceso : 0}
          mostrarModalEditarConsultoria={mostrarModalEditarConsultoria}
          modalEditarConsultoria={modalEditarConsultoria}
          actualizarMensaje={actualizarMensaje}
          actualizarConsulta={this.actualizarConsulta}
        />
        <DetalleConsultoria
          proceso={proceso ? proceso : 0}
          actualizarMensaje={actualizarMensaje}
          modalDetalleConsultoria={modalDetalleConsultoria}
          mostrarModalDetalleConsultoria={mostrarModalDetalleConsultoria}
          cargar="true"
          administrador={administrador}
          usuario={usuario}
        />
        <ConsultoriaGestionar
          usuario={usuario}
          proceso={proceso ? proceso : 0}
          actualizarMensaje={actualizarMensaje}
          modalGestionarConsultoria={modalGestionarConsultoria}
          mostrarModalGestionarConsultoria={mostrarModalGestionarConsultoria}
          gestionarConsultoria={this.gestionarConsultoria}
        />
        <ConsultoriaFiltrar
          ModalAddFiltrosConsultoria={ModalAddFiltrosConsultoria}
          mostrarModalAddFiltrosConsultoria={mostrarModalAddFiltrosConsultoria}
          actualizarMensaje={actualizarMensaje}
          limpiar={() => this.actualizarDataConsultoria(this.configFiltros(accionProceso))}
          filtrar={(filtros) => { this.actualizarDataConsultoria(this.configFiltros(accionProceso).concat(filtros)) }}
          accionProceso={accionProceso}
          mostrarTodos={(seleccion) => this.setState({ accionProceso: seleccion })}
        />
        <div className='contenido_modulo'>
          {
            !cargando
              ?
              <div>
                {seleccion === 'proceso' && this.renderProcesos()}
                {seleccion === 'nuevo' && this.pintarNuevo()}
                {seleccion === 'administrar' && this.pintarAdministrar()}
                {seleccion === 'historial' && this.pintarHistorial()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
          }
        </div>
      </>
    )
  }
}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { modalDetalleConsultoria, modalGestionarConsultoria, modalAddConsultas, ModalAddFiltrosConsultoria, modalModPeriodoConsultoria, modalDuplicarConsultoria, modalEditarConsultoria } = state.redConsultoria;
  return {
    usuario,
    modalDetalleConsultoria,
    modalGestionarConsultoria,
    modalAddConsultas,
    ModalAddFiltrosConsultoria,
    modalModPeriodoConsultoria,
    modalDuplicarConsultoria,
    modalEditarConsultoria,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalDetalleConsultoria,
  mostrarModalGestionarConsultoria,
  mostrarModalAddConsultas,
  mostrarModalAddFiltrosConsultoria,
  mostrarModalModPeriodoConsultoria,
  mostrarModalDuplicarConsultoria,
  mostrarModalEditarConsultoria,
}



export default connect(mapStateToProps, mapDispatchToProps)(ConsultoriaAdministrar);

