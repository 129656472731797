// valores generica local
// const NOTIFICACIONES = 81;
// const PASOS = 85;

// valores generica emma
const NOTIFICACIONES = 108;
const PASOS = 128;


export {
    NOTIFICACIONES,
    PASOS
}