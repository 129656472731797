const MODAL_AGREGAR = 'MODAL_AGREGAR'
const MODAL_AGREGAR_PREG = 'MODAL_AGREGAR_PREG'
const MODAL_EDITAR_SYLL= "MODAL_EDITAR_SYLL"


const mostrarAgregarSisef = show => {
  return {
    type: MODAL_AGREGAR,
    show,
  }
}

const mostrarAgregarPregunta = show => {
  return {
    type: MODAL_AGREGAR_PREG,
    show,
  }
}
const mostrarModificarSisef = show => {
  return {
    type: MODAL_EDITAR_SYLL,
    show,
  }
}

module.exports = {
  MODAL_AGREGAR,
  MODAL_AGREGAR_PREG,
  MODAL_EDITAR_SYLL,
  mostrarAgregarSisef,
  mostrarAgregarPregunta,
  mostrarModificarSisef,
}