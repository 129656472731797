import React from "react";

// Material UI
import {
  BottomNavigation,
  BottomNavigationAction,
  Hidden,
} from "@material-ui/core";

// Materiañ ui-ICONS
import {
  ImportContacts,
  LocationOn,
  Loop,
  MenuBook,
  PersonPin,
  School,
  Settings,
} from "@material-ui/icons";

const clase = (item, selec, tipo = "") =>
  item === selec ? `item_selec${tipo}` : "item_modulo";

export const MenuInscripciones = ({
  administrador,
  selec = 0,
  seleccionar = () => {},
}) => {
  const pintar = (app, claseN = "") => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation
            className={clase("procesos", selec)}
            showLabels
            onClick={() => seleccionar("procesos")}
          >
            <BottomNavigationAction label={"Procesos"} icon={<LocationOn />} />
          </BottomNavigation>
          {administrador && (
            <BottomNavigation
              className={clase("administrar", selec)}
              showLabels
              onClick={() => seleccionar("administrar")}
            >
              <BottomNavigationAction
                label={"Administrar"}
                icon={<Settings />}
              />
            </BottomNavigation>
          )}
        </div>
      );
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels>
            <BottomNavigationAction
              className={clase("procesos", selec, "_ app")}
              label={"Procesos"}
              icon={<LocationOn />}
              onClick={() => seleccionar("procesos")}
            />
            {administrador && (
              <BottomNavigationAction
                className={clase("administrar", selec, "_ app")}
                label={"Administrar"}
                icon={<Settings />}
                onClick={() => seleccionar("administrar")}
              />
            )}
          </BottomNavigation>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <Hidden xsDown>{pintar(false, "menu_modulo")}</Hidden>
        <Hidden smUp>{pintar(true, "menu_modulo_app")}</Hidden>
      </div>
    </div>
  );
};

export const MenuDetalleInscripciones = ({
  selec = 0,
  seleccionar = () => {},
}) => {
  const pintar = (claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={"Detalle"}
            icon={<ImportContacts />}
            className={clase("detalle", selec)}
            onClick={() => seleccionar("detalle")}
          />
          <BottomNavigationAction
            label={"Documentos"}
            icon={<MenuBook />}
            className={clase("adjuntos", selec)}
            onClick={() => seleccionar("adjuntos")}
          />
          <BottomNavigationAction
            label={"Contacto"}
            icon={<PersonPin />}
            className={clase("contacto", selec)}
            onClick={() => seleccionar("contacto")}
          />
          <BottomNavigationAction
            label={"Carrera"}
            icon={<School />}
            className={clase("carrera", selec)}
            onClick={() => seleccionar("carrera")}
          />
          <BottomNavigationAction
            label={"Estados"}
            icon={<Loop />}
            className={clase("estados", selec)}
            onClick={() => seleccionar("estados")}
          />
        </BottomNavigation>
      </div>
    );
  };

  return pintar("menu_modal");
};

export function MenuGestionarinscripcion({
  selec = 0,
  seleccionar = () => {},
  estadoAdjunto,
  estado,
}) {
  const pintar = (claseN = "") => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label={"Gestionar"}
            icon={<Loop />}
            className={clase("gestion", selec)}
            onClick={() => seleccionar("gestion")}
          />
          {estadoAdjunto[0]?.secundario.codigo === estado && (
            <BottomNavigationAction
              label={"Documentos"}
              icon={<MenuBook />}
              className={clase("adjuntos", selec)}
              onClick={() => seleccionar("adjuntos")}
            />
          )}
        </BottomNavigation>
      </div>
    );
  };

  return pintar("menu_modal");
}
