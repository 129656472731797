import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'

export function Beca({ beca }) {
  let {
    tipo_beca,
    estado_actual,
    programa,
    solicitante: { primer_nombre, primer_apellido, segundo_apellido },
  } = beca;

  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Beca</TableCell>
          <TableCell align="left">{tipo_beca.nombre}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export function DetalleSolicitud({ datos, gestor }){
  console.log(datos)
  let {
    tipo_beca,
    estado_actual,
    programa,
    fecha_registro,
    solicitante: { id, primer_nombre, primer_apellido, segundo_apellido, identificacion, correo },
    observaciones,
    empresa,
    mensaje,
    becado,
    porcentaje,
    subtipo
  } = datos;
  let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mm a');
  return (
    <Table>
      <TableBody>
        <TableRow key={1}>
          <TableCell component="th" scope="row">Solicitante</TableCell>
          <TableCell align="left">{primer_nombre} {primer_apellido} {segundo_apellido}</TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell component="th" scope="row">Identificacion</TableCell>
          <TableCell align="left">{identificacion}</TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell component="th" scope="row">Correo</TableCell>
          <TableCell align="left">{correo}</TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell component="th" scope="row">Programa</TableCell>
          <TableCell align="left">{programa.nombre}</TableCell>
        </TableRow>
        <TableRow key={5}>
          <TableCell component="th" scope="row">Beca</TableCell>
          <TableCell align="left">{tipo_beca.nombre}</TableCell>
        </TableRow>
        { subtipo ?
          <TableRow key={14}>
            <TableCell component="th" scope="row">Subtipo</TableCell>
            <TableCell align="left">{subtipo.nombre}</TableCell>
          </TableRow>
          : <TableRow key={14}></TableRow>
        }
        { gestor && porcentaje ?
        <TableRow key={6}>
          <TableCell component="th" scope="row">Porcentaje Beca</TableCell>
          <TableCell align="left">{porcentaje}</TableCell>
        </TableRow>
        : <TableRow key={6}></TableRow>
        }
        {empresa ?
          <TableRow key={7}>
            <TableCell component="th" scope="row">Empresa Convenio</TableCell>
            <TableCell align="left">{empresa.nombre}</TableCell>
          </TableRow> :
          <TableRow key={7}></TableRow>
        }
        {becado && becado.id !== id ?
          <TableRow key={8}>
            <TableCell component="th" scope="row">Becado</TableCell>
            <TableCell align="left">{becado.primer_nombre} {becado.primer_apellido} {becado.segundo_apellido}</TableCell>
          </TableRow>
          :
          <TableRow key={8}></TableRow>
        }
        {becado && becado.id !== id ?
          <TableRow key={9}>
            <TableCell component="th" scope="row">Becado Identificacion</TableCell>
            <TableCell align="left">{becado.identificacion} </TableCell>
          </TableRow>
          :
          <TableRow key={9}></TableRow>
        }
        <TableRow key={10}>
          <TableCell component="th" scope="row">Estado Actual</TableCell>
          <TableCell align="left">{estado_actual.nombre}</TableCell>
        </TableRow>
        {mensaje ?
          <TableRow key={11}>
            <TableCell component="th" scope="row">Observaciones Solicitante</TableCell>
            <TableCell align="left">{mensaje}</TableCell>
          </TableRow> :
          <TableRow key={11}></TableRow>
        }
        {observaciones ?
          <TableRow key={12}>
            <TableCell component="th" scope="row">Observaciones Gestor</TableCell>
            <TableCell align="left">{observaciones}</TableCell>
          </TableRow> :
          <TableRow key={12}></TableRow>
        }
        <TableRow key={13}>
          <TableCell component="th" scope="row">Fecha Registro</TableCell>
          <TableCell align="left">{fecha}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
