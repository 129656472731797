import {
  MODAL_VALIDACION_ID,
  MODAL_ADD_FILTRO,
  MODAL_DETALLE,
  MODAL_GESTIONAR,
  MODAL_PERIODO,
  MODAL_GESTIONAR_PROGRAMAS,
  MODAL_DET_PROGRAMAS,
  MODAL_ADD_PROGRAMAS,
  MODAL_MOD_PROGRAMAS,
} from "../actions/actInscripcionesPosgrado";

const initialState = {
  modalValidacionId: false,
  modalAddFiltro: false,
  modalDetalle: false,
  modalGestionar: false,
  modalPeriodo: false,
  modalGestionarProgramas: false,
  modalDetProgramas: false,
  modalAddProgramas: false,
  modalModProgramas: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_VALIDACION_ID:
      return Object.assign({}, state, {
        modalValidacionId: action.show,
      });

    case MODAL_ADD_FILTRO:
      return Object.assign({}, state, {
        modalAddFiltro: action.show,
      });

    case MODAL_DETALLE:
      return Object.assign({}, state, {
        modalDetalle: action.show,
      });

    case MODAL_GESTIONAR:
      return Object.assign({}, state, {
        modalGestionar: action.show,
      });

    case MODAL_PERIODO:
      return Object.assign({}, state, {
        modalPeriodo: action.show,
      });

    case MODAL_GESTIONAR_PROGRAMAS:
      return Object.assign({}, state, {
        modalGestionarProgramas: action.show,
      });

    case MODAL_DET_PROGRAMAS:
      return Object.assign({}, state, {
        modalDetProgramas: action.show,
      });

    case MODAL_ADD_PROGRAMAS:
      return Object.assign({}, state, {
        modalAddProgramas: action.show,
      });

    case MODAL_MOD_PROGRAMAS:
      return Object.assign({}, state, {
        modalModProgramas: action.show,
      });

    default:
      return state;
  }
};

export default reducer;
