import React, { Component } from 'react';
import moment from 'moment'

//components
import ListarDatos from '../../general/ListarDatos'
import { BtnDetalle, BtnGestionar, BtnPersonas,Btnestados } from '../../general/BotonesAccion'

class VacanteGestion extends Component {

    actions = row => {
        let { listarPostulantes, abrir_modal_gestion_oferta, abrir_modal_detalle_oferta,obtenerEstados } = this.props
        return (
            <div>
                <BtnDetalle
                    callback={() => {
                        abrir_modal_detalle_oferta(row)
                    }}
                />
                < BtnPersonas
                        callback={() => {
                            listarPostulantes(row);
                        }}
                />
                {
                    row.estado_solicitud.codigo != "Por_Fin" && row.estado_solicitud.codigo != "Por_Rec" && (
                        <BtnGestionar
                            callback={() => {
                                abrir_modal_gestion_oferta(row);
                            }}
                        />
                    )
                }
                <Btnestados
                    callback={() => {
                        obtenerEstados(row.id)
                    }}
                />
            </div>
        )
    }

    obtenerColor = (codigo) => {
        let color = '#ffffff';
        const colores = {
            'Por_Sol': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Por_Rev': { 'backgroundColor': '#ffc107', color },
            'Por_Ace': { 'backgroundColor': '#0d47a1', color },
            'Por_Fin': { 'backgroundColor': '#81c784', color },
            'Por_Rec': { 'backgroundColor': '#e57373', color },
        }
        return (colores[codigo])
    }

    renderListarVacantes = () => {
        let { vacantes,activarFiltro } = this.props
        return (
            <ListarDatos
                id="tbl_inf_vacante"
                titulo={"Lista de Vacantes"}
                datos={vacantes}
                agregar={false}
                descargar={''}
                actAgregar={() => console.log('Hola')}
                filtrar={activarFiltro == 1 ? true:false}
                refrescar={true}
                actfiltrar={() => this.props.obtenerFiltrosGestion()}
                actRefrescar={() => this.props.actualizarData()}
                opciones={true}
                buscar={true}
                sub_titulo={"Lista de mis Vacantes"}
                avatar={({ estado_solicitud }) => null}
                color_avatar={({ estado_solicitud }) => this.obtenerColor(estado_solicitud.codigo)}
                acciones={row => this.actions(row)}
                fila_principal={({ nombre_cargo }) => nombre_cargo.toUpperCase()}
                filas={[
                    {
                        'mostrar': ({ tipo_oferta }) => `Tipo: ${tipo_oferta.nombre}`,
                        'id': 'tipo_oferta',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ id, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Pra' ? `Código de la práctica laboral: ${id}` : '',
                        'id': 'codigo_practica',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ id, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Lab' ? `Código de la vacante laboral: ${id}` : '',
                        'id': 'codigo_vacante',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ salario, valor_auxilio, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Lab' ? `Salario: ${salario}` : `Valor del auxilio de práctica: ${valor_auxilio}`,
                        'id': 'salario',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ n_vacantes, numero_plazas, tipo_oferta }) => tipo_oferta.codigo == 'Vac_Lab' ? `Cantidad de vacantes: ${n_vacantes}` : `Número de plazas: ${numero_plazas}`,
                        'id': 'n_vacantes',
                        'enLista': true
                    },
                    {
                        'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD'),
                        'id': 'fecha_inicio',
                        'enLista': true
                    }
                ]}
            />
        )
    }


    render() {
        return (
            <>
                {this.renderListarVacantes()}
            </>
        );
    }
}

export default VacanteGestion;