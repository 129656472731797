import React, { Component } from 'react';
import { mostrarModulo } from "./../../redux/actions/actGlobal";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Paper, Grid, Button, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { actualizarMensaje } from '../../redux/actions/actGlobal'
import moment from 'moment'
import emma_s from '../../global/imagenes/emma_s1.png';
import emma_w from './../../global/imagenes/emma_w.png';
import revisar from '../../global/imagenes/revisar.png';
import TareasTerminadas from "../general/TareasTerminadas"
import AccionesRapidas from '../general/AccionesRapidas';
import TituloAccion from "../general/TituloAccion";
import { ImgPortalPiel } from './ImgPortalPiel';
import {
    AREAS_INTERES, DOCUMENTOS_INFORMATIVOS, REQUISITOS_PORTAL
} from './helper'
//Imagenes
import mintrabajo from '../../global/imagenes/portal/mintrabajo.png';


//Funciones globales
import { consulta, obtenerValores, formulario, crear_form_data, obtenerPermisosValorAlt,obtenerValoresFiltros } from '../../global/js/funciones'

//FUNCIONES DEL MÓDULO
import {
    mostrarModalAddPerfilPortal,
    mostrarModalAccionesRapidas,
    mostrarModalListarInformacionPerfil,
    mostrarModalDetalleVacante,
    mostrarModalAddPqrs,
    mostrarModalDetallePqrs
} from './../../redux/actions/actPortal'

//component
import PortalPerfilData from "./Portal/PortalPerfilData";
import DetallePerfil from "./DetallePerfil/DetallePerfil"
import OpcionesPerfil from './DetallePerfil/OpcionesPerfil'
import Historial from './Portal/Historial'
import Pqrs from './Pqrs'
import VacanteDetalle from './Portal/VacanteDetalle'
import ListarOpcionesPerfil from './DetallePerfil/ListarOpcionesPerfil'
import { Menu } from './Portal/Menu'
import ProcesoActivo from './Portal/ProcesoActivo'
import ProcesoDetalleSimple from './Portal/ProcesoDetalle'
import ProcesoNuevo from './Portal/ProcesoAgregar'
import VacanteDetalleLista from './Portal/VacanteDetalleLista'
import AddPqrs from './Pqrs/AddPqrs'
import DetallePqrs from './Pqrs/DetallePqrs'

class Portal extends Component {

    constructor() {
        super();
        this.state = {
            seleccion: 'vacante',
            cargando: true,
            llave: null,
            archivos: [],
            datos_modal: {
                nombre_modal: '',
                btnAdd: null
            },
            getDatos: null,
            Personal: {
                email: '',
                genero: '',
                direccion: '',
                ciudad: '',
                telefono: '',
                perfil: '',
                salario_min: '',
                salario_max: '',
                linkedin: ''
            },
            Academica: {
                universidad: null,
                tipo: '',
                titulo: null,
                ciudad: null,
                departamento: null,
                fecha_inicio_est: moment(new Date()).format('YYYY-MM-DD'),
                fecha_final_est: moment(new Date()).format('YYYY-MM-DD'),
                en_curso_aca: '0'
            },
            Experiencia: {
                empresa: null,
                cargo: null,
                fecha_inicio_exp: moment(new Date()).format('YYYY-MM-DD'),
                fecha_final_exp: moment(new Date()).format('YYYY-MM-DD'),
                responsabilidades: null,
                en_curso_lab: '0',
                no_cuento_lab: '0',
            },
            Habilidad: {
                tipo: '',
                descripcion: null,
                nivel: null
            },
            Distincion: {
                descripcion: null
            },
            Intereses: null,
            misIntereses: null,
            getPersonal: {
                resp: []
            },
            getAcademico: {
                resp: []
            },
            getExperiencia: {
                resp: []
            },
            getHabilidad: {
                resp: []
            },
            validarPerfilEstudiante: 0,
            getDistincion: null,
            getLogros: {
                resp: []
            },
            historial: [],
            getVacantes: [],
            DetalleVacante: [],
            procesos: [],
            proceso: null,
            enListado: true,
            id_proceso: 0,
            vistaDetalle: 'proceso',
            titulo_pqrs: '',
            body_pqrs: '',
            listaPqrs: '',
            pqrs: {},
            cargando: true,
            doc_informativos: [],
            cargandoPerfil: false,
            vaidarPerfil: false,
            requisitos: [],
        }
    }

    async componentDidMount() {
        let { mostrarModulo } = this.props;
        mostrarModulo({ ruta: '/portal', nombre: 'Portal de Empleo' })
        this.setState({ cargando: true })
        this.setState({
            procesos: await this.getProcesos(),
            misIntereses: await this.getMisIntereses(),
            doc_informativos: (await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'portal_act' }, { 'llave': 'secundario__generica', 'valor': DOCUMENTOS_INFORMATIVOS }])).map(({ secundario }) => secundario),
            cargando: false
        })
    }

    async componentDidUpdate({ }, { seleccion, id_proceso }) {
        let { seleccion: seleccionNew, id_proceso: id_procesoNew } = this.state;
        if (id_proceso !== id_procesoNew && seleccionNew === 'vacante') await this.obtenerDataProcesoActual(id_procesoNew);
        if (seleccion !== seleccionNew) {
            this.setState({ cargando: true })
            if (seleccionNew === 'vacante') this.setState({ procesos: await this.getProcesos() })
            else if (seleccionNew === 'historial') this.setState({ historial: await this.getProcesos() })
            else if (seleccionNew === 'pqrs') this.setState({ listaPqrs: await this.getPqrs() })
            else if (seleccionNew === 'perfil') this.setState({ getLogros: await this.getLogros(), getPersonal: await this.obtenerDatosPersonal(), getExperiencia: await this.getLaboral(), getAcademico: await this.getAcademico(), getHabilidad: await this.getHabilidad(), validarPerfilEstudiante: await this.getValidacionPerfilEstudiante(), requisitos: await obtenerValoresFiltros([{"llave": 'generica', 'valor': REQUISITOS_PORTAL}]) })
            else if (seleccionNew === 'nuevo') this.setState({ getVacantes: await this.vacanteDisponibles(), validarPerfilEstudiante: await this.getValidacionPerfilEstudiante() })
            this.setState({ cargando: false })
        }
    }

    async obtenerDataProcesoActual(id = 0) {
        this.setState({ cargando: true })
        let data = await this.obtenerProceso(id);
        if (data) this.setState({ proceso: data[0] });
        this.setState({ cargando: false });
    }

    cambiarEstado = (estados) => {
        this.setState(estados)
    }

    cambiarOpcion = (seleccion) => {
        this.cambiarEstado({ seleccion })
    }

    async obtenerProceso(id = 0) {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/${id}/detalle`, null, null, (error, estado, resp) => {
                resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    detalle__vacante = async (data) => {
        let { mostrarModalDetalleVacante } = this.props
        await this.setState({ DetalleVacante: data })
        mostrarModalDetalleVacante(true)
    }

    vista = () => {
        let {
            misIntereses,
            seleccion,
            llave,
            datos_modal,
            archivos,
            id_proceso,
            getVacantes,
            DetalleVacante,
            historial,
            listaPqrs,
            pqrs,
            cargando,
            validarPerfilEstudiante,
            cargandoPerfil,
            validarPerfil,
            requisitos
        } = this.state
        let {
            modalAddPerfilPortal,
            mostrarModalAddPerfilPortal,
            modalAccionesRapidas,
            mostrarModalAccionesRapidas,
            modalListarInformacionPerfil,
            mostrarModalListarInformacionPerfil,
            modalDetalleVacante,
            mostrarModalDetalleVacante,
            actualizarMensaje,
            modalAddPqrs,
            mostrarModalAddPqrs,
            modalDetallePqrs,
            mostrarModalDetallePqrs
        } = this.props
        switch (seleccion) {
            case 'nuevo':
                return (
                    <div>
                        {!cargando ?
                            <ProcesoNuevo
                                validarPerfilEstudiante={validarPerfilEstudiante}
                                actualizarMensaje={actualizarMensaje}
                                vacantes={getVacantes}
                                crearPostulantes={this.crearPostulantes}
                                detalle_vacante={this.detalle__vacante}
                                misIntereses={misIntereses}
                            /> :
                            // validarPerfil ?
                            //     <ProcesoNuevo
                            //         actualizarMensaje={actualizarMensaje}
                            //         vacantes={getVacantes}
                            //         crearPostulantes={this.crearPostulantes}
                            //         detalle_vacante={this.detalle__vacante}
                            //         misIntereses={misIntereses}
                            //     /> :
                            //     <TareasTerminadas mensaje='Para postularte a las ofertas, debes completar tu perfil' marginTop='7%' imagen={emma_s} widthImg="7%" />
                            // :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <VacanteDetalleLista
                            {...{
                                modalDetalleVacante,
                                mostrarModalDetalleVacante
                            }}
                            proceso={DetalleVacante}
                        />
                    </div>
                )
            case 'vacante':
                return (
                    <div>
                        {!cargando ?
                            this.pintarProcesoActual() :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <VacanteDetalle
                            id_proceso={id_proceso}
                            vistaDetalle={this.state.vistaDetalle}
                            modalDetalleVacante={modalDetalleVacante}
                            mostrarModalDetalleVacante={mostrarModalDetalleVacante}
                        />
                    </div>
                )
            case 'perfil':
                return (
                    <div>
                        {!cargando ?
                            this.pintarPerfil() :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <PortalPerfilData
                            modalAddPerfilPortal={modalAddPerfilPortal}
                            mostrarModalAddPerfilPortal={mostrarModalAddPerfilPortal}
                            llave={llave}
                            datos_modal={datos_modal}
                            onChangeState={this.onChangeState}
                            enviarFormActual={this.enviarFormActual}
                            datosPrincipales={this.state.Personal}
                            datosAcademicos={this.state.Academica}
                            datosExperiencia={this.state.Experiencia}
                            datosHabilidad={this.state.Habilidad}
                            datosDistincion={this.state.Distincion}
                            agregarArchivos={this.agregarArchivos}
                            eliminarArchivo={this.eliminarArchivo}
                            archivos={archivos}
                            actualizarMensaje={actualizarMensaje}
                            cargandoPerfil={cargandoPerfil}
                            requisitos={requisitos}
                            guardarArchivos={this.guardarArchivos}
                        />
                        <OpcionesPerfil
                            modalAccionesRapidas={modalAccionesRapidas}
                            mostrarModalAccionesRapidas={mostrarModalAccionesRapidas}
                            abrirListarOpcionesPerfil={this.abrirListarOpcionesPerfil}
                            abrirFormInformacionPrincipal={this.abrirFormInformacionPrincipal}
                            listarIntereses={this.listarIntereses}
                            validarPerfilEstudiante={validarPerfilEstudiante}
                        />
                        <ListarOpcionesPerfil
                            cargarFunciones={this.cargarFunciones}
                            modalListarInformacionPerfil={modalListarInformacionPerfil}
                            mostrarModalListarInformacionPerfil={mostrarModalListarInformacionPerfil}
                            llave={llave}
                            datos_modal={datos_modal}
                            datos={this.state.getDatos}
                            funcion={this.funcion}
                            funcionEliminar={this.funcionEliminar}
                            actualizarValidarp={this.actualizarValidarp}
                            agregarInteres={this.agregarInteres}
                            eliminarInteres={this.eliminarInteres}
                            misIntereses={misIntereses}
                        />
                    </div>
                )
            case 'historial':
                return (
                    <>
                        {!cargando ?
                            <Historial
                                {...{
                                    historial
                                }}
                                abrirDetalleVacante={this.abrirDetalleVacante}
                            /> :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <VacanteDetalleLista
                            {...{
                                modalDetalleVacante,
                                mostrarModalDetalleVacante
                            }}
                            proceso={DetalleVacante}
                        />
                        <DetallePqrs
                            pqrs={pqrs}
                            modalDetallePqrs={modalDetallePqrs}
                            mostrarModalDetallePqrs={mostrarModalDetallePqrs}
                        />
                    </>
                )
            case 'pqrs':
                return (
                    <div>
                        {!cargando ?
                            <Pqrs
                                listaPqrs={listaPqrs}
                                mostrarModalAddPqrs={mostrarModalAddPqrs}
                                abrirDetallePqrs={this.abrirDetallePqrs}
                            /> :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <AddPqrs
                            modalAddPqrs={modalAddPqrs}
                            mostrarModalAddPqrs={mostrarModalAddPqrs}
                            enviarFormActual={this.crearPqrs}
                            onChangeState={this.onChangeStatePqrs}
                        />
                        <DetallePqrs
                            pqrs={pqrs}
                            modalDetallePqrs={modalDetallePqrs}
                            mostrarModalDetallePqrs={mostrarModalDetallePqrs}
                        />
                    </div>
                )
            default:
                break;
        }
    }

    abrirDetallePqrs = (pqrs) => {
        this.setState({ pqrs });
        this.props.mostrarModalDetallePqrs(true);
    }

    onChangeStatePqrs = ({ target }) => {
        let { name, value } = target
        this.setState({
            [name]: value
        })
    }

    crearPqrs = () => {
        let { mostrarModalAddPqrs, actualizarMensaje } = this.props
        let { titulo_pqrs, body_pqrs } = this.state;

        if (titulo_pqrs == '' || titulo_pqrs == null) return;
        if (body_pqrs == '' || body_pqrs == null) return;

        let data = {
            titulo_pqrs,
            body_pqrs
        }

        consulta(`api/v1.0/portal/pqrs/add`, data, "post", async (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalAddPqrs(false);
                    this.setState({
                        listaPqrs: await this.getPqrs(),
                    })
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    getPqrs = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/pqrs/list`, null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }

    cargarFunciones = async (llave) => {
        let { mostrarModalAddPerfilPortal } = this.props
        await this.setState({
            llave,
            [llave]: this.copiaState(llave)
        })
        mostrarModalAddPerfilPortal(true)
    }

    agregarInteres = (datos) => {
        let data = {
            area_interes: datos.id
        }
        let { actualizarMensaje } = this.props
        consulta("api/v1.0/portal/interes", data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    this.refrescarMisIntereses();
                    this.actualizarValidarp()
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 2000 })
        })
    }

    refrescarMisIntereses = async () => {
        let misIntereses = await this.getMisIntereses();
        this.setState({
            misIntereses
        })
    }

    eliminarInteres = (datos) => {
        let data = {
            area_interes: datos.id
        }
        let { actualizarMensaje } = this.props
        consulta("api/v1.0/portal/interes/eliminar", data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    this.refrescarMisIntereses();
                    this.actualizarValidarp()
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 2000 })
        })
    }

    guardarArchivos = (data_archivos, endpoint) => {
        let { mostrarModalAddPerfilPortal,
            mostrarModalListarInformacionPerfil,
            actualizarMensaje
        } = this.props
        const data = new FormData()
        data_archivos.map(({archivo,codigo}, index) => {
            data.append(`archivo${index}`, archivo)
            data.append(`codigo${index}`, codigo);
            return true;
        })
        data.append('total', data_archivos.length)
        formulario(`api/v1.0/portal/${endpoint}`, data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalAddPerfilPortal(false)
                    mostrarModalListarInformacionPerfil(false)
                    this.setState({
                        archivos: []
                    })
                    this.actualizarValidarp()
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    funcion = (data, llave) => {
        let { mostrarModalAddPerfilPortal } = this.props
        this.setState({
            [llave]: data
        })
        mostrarModalAddPerfilPortal(true)
    }

    funcionEliminar = (row, llave) => {
        let endpoint = ''
        if (llave === "Anexos") endpoint = "anexo"
        else if (llave === "Distincion") endpoint = "distincion"
        else if (llave === "Habilidad") endpoint = "habilidad"
        else if (llave === "Experiencia") endpoint = "experiencia"
        else if (llave === "Academica") endpoint = "academica"
        this.modificarInformacion(row, endpoint)
    }

    onChangeState = ({ target }) => {
        let { name, value } = target
        let { llave } = this.state
        this.setState(prevState => ({
            [llave]: {
                ...prevState[llave],
                [name]: value
            }
        })
        )
    }

    enviarFormActual = () => {
        let { llave } = this.state
        let { actualizarMensaje } = this.props
        let data = this.state[llave]
        let archivos = this.state.archivos
        if (archivos.length > 0 && llave === "Anexos") {
            return this.guardarArchivos(archivos, "anexos_crear")
        }
        if (data.id) {
            switch (llave) {
                case "Personal":
                    this.setState({ cargandoPerfil: true });
                    this.modificarInformacion(data, "personal")
                    this.setState({ cargandoPerfil: false });
                    break;
                case "Academica":
                    this.modificarInformacion(data, "academica")
                    break;
                case "Experiencia":
                    this.modificarInformacion(data, "experiencia")
                    break;
                case "Habilidad":
                    this.modificarInformacion(data, "habilidad")
                    break;
                case "Distincion":
                    this.modificarInformacion(data, "distincion")
                    break;
            }
        } else {
            switch (llave) {
                case "Personal":
                    this.guardarInformacion(data, "personal_crear")
                    break;
                case "Academica":
                    this.guardarInformacion(data, "academica_crear")
                    break;
                case "Experiencia":
                    this.guardarInformacion(data, "experiencia_crear")
                    break;
                case "Habilidad":
                    if (parseInt(data.nivel) > 0 && parseInt(data.nivel) <= 5) this.guardarInformacion(data, "habilidad_crear")
                    else actualizarMensaje({ titulo: 'Digite un valor valido en Nivel', tipo: 'info', mostrar: true, tiempo: 3000 })
                    break;
                case "Distincion":
                    this.guardarInformacion(data, "distincion_crear")
                    break;
            }
        }
    }

    actualizarDataPerfil = async tipo => {
        this.setState({ cargando: true })
        if (tipo == "personal_crear" || tipo == "personal") this.setState({ getPersonal: await this.obtenerDatosPersonal() })
        else if (tipo == "academica_crear" || tipo == "academica") this.setState({ getAcademico: await this.getAcademico() })
        else if (tipo == "experiencia_crear" || tipo == "experiencia") this.setState({ getExperiencia: await this.getLaboral() })
        else if (tipo == "habilidad_crear" || tipo == "habilidad") this.setState({ getHabilidad: await this.getHabilidad() })
        else if (tipo == "distincion_crear" || tipo == "distincion") this.setState({ getLogros: await this.getLogros() })
        this.setState({ cargando: false })
    }

    guardarInformacion = (data, endpoint) => {
        let { mostrarModalAddPerfilPortal, mostrarModalListarInformacionPerfil, actualizarMensaje } = this.props
        consulta(`api/v1.0/portal/${endpoint}`, data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalAddPerfilPortal(false)
                    mostrarModalListarInformacionPerfil(false)
                    this.actualizarValidarp()
                    this.actualizarDataPerfil(endpoint)
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    filtrarDatosInformacion = async (data, endpoint) => {
        switch (endpoint) {
            case "habilidad":
                if (data.persona) {
                    data.persona = data.usuario_registro
                }
                if (data.usuario_elimino == null) {
                    delete data.usuario_elimino
                }
                return await crear_form_data(data);
            case "personal":
                let { nombre_archivo } = data;
                delete data.persona
                if (nombre_archivo) {
                    data.archivo = document.getElementById('info_archivo_mod').files[0];
                } else {
                    delete data.archivo
                }
                let data2 = await crear_form_data(data);
                data.archivo = nombre_archivo;
                return data2;
            default:
                return await crear_form_data(data);
        }
    }

    modificarInformacion = async (data, endpoint) => {
        let { mostrarModalAddPerfilPortal, mostrarModalListarInformacionPerfil, actualizarMensaje } = this.props
        let data2 = await this.filtrarDatosInformacion(data, endpoint);
        formulario(`api/v1.0/portal/${data.id}/${endpoint}`, data2, "patch", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'danger';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalAddPerfilPortal(false)
                    mostrarModalListarInformacionPerfil(false)
                    this.actualizarDataPerfil(endpoint)
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    obtenerDatosPerfil = (llave) => {
        let url = ""
        if (llave === "Academica") url = "academica_listar"
        else if (llave === "Experiencia") url = "experiencia_listar"
        else if (llave === "Habilidad") url = "habilidad_listar"
        else if (llave === "Distincion") url = "distincion_listar"
        else if (llave === "Anexos") url = "anexos_listar"

        return new Promise(resolve => {
            consulta(`api/v1.0/portal/${url}`, null, null, (error, estado, resp) => {
                resolve({ resp })
            })
        })
    }

    crearPostulantes = (data, action) => {
        // let data = {
        //     vacante: row.id
        // }
        let { actualizarMensaje, } = this.props
        consulta(`api/v1.0/portal/postulante_crear`, data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    action(false);
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    obtenerDatosPersonal = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/personal_listar`, null, null, (error, estado, resp) => {
                resolve({ resp })
            })
        })
    }

    getLogros = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/distincion_listar`, null, null, (error, estado, resp) => {
                resolve({ resp })
            })
        })
    }

    getLaboral = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/experiencia_listar`, null, null, (error, estado, resp) => {
                resolve({ resp })
            })
        })
    }

    getAcademico = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/academica_listar`, null, null, (error, estado, resp) => {
                resolve({ resp })
            })
        })
    }

    getHabilidad = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/habilidad_listar`, null, null, (error, estado, resp) => {
                resolve({ resp })
            })
        })
    }

    getValidacionPerfilEstudiante = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/validar_perfil_estudiante`, null, null, (error, estado, resp) => {
                resolve(resp.validacion)
            })
        })
    }

    getVacantes = () => {
        return new Promise(resolve => {
            consulta("api/v1.0/portal/vacantes", null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }

    getMisIntereses = () => {
        return new Promise(resolve => {
            consulta("api/v1.0/portal/interes/listar", null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }

    getProcesos = () => {
        return new Promise(resolve => {
            consulta("api/v1.0/portal/procesos", null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }


    actualizarValidarp = async () => {
        let { validarp } = this.state
        validarp = await this.getValidacionPerfilEstudiante()
        this.setState({ validarPerfilEstudiante: validarp })
    }

    getValidacionPerfil = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/validar_perfil/estudiante`, null, null, (error, estado, resp) => {
                resolve(resp.validacion)
            })
        })

    }

    vacanteDisponibles = async () => {
        let validarPerfil = await this.getValidacionPerfil()
        let vacantesNew = []
        if (validarPerfil) {
            let vacante = await this.getVacantes()
            let procesos = await this.getProcesos()
            let procesosFiltrar = procesos.map(i => i.vacante.id)
            vacantesNew = vacante.filter(i => !procesosFiltrar.includes(i.id))
        }
        this.setState({ validarPerfil })
        return vacantesNew
    }

    abrirFormInformacionPrincipal = async (llave, nombre_modal) => {
        let { resp } = await this.obtenerDatosPersonal();
        let data = null;
        if (resp.length == 0) {
            data = this.copiaState("Personal")
        } else {
            data = resp[0]
        }
        let { mostrarModalAddPerfilPortal } = this.props
        this.setState(prevState => ({
            llave,
            datos_modal: {
                ...prevState.datos_modal,
                nombre_modal
            },
            [llave]: data
        })
        )
        mostrarModalAddPerfilPortal(true)
    }

    abrirOpcionesPerfil = () => {
        let { mostrarModalAccionesRapidas } = this.props
        mostrarModalAccionesRapidas(true)
    }

    abrirListarOpcionesPerfil = async (llave, nombre_modal, btnAdd = true) => {
        let { resp } = await this.obtenerDatosPerfil(llave)
        this.setState(prevState => ({
            llave,
            getDatos: resp,
            datos_modal: {
                ...prevState.datos_modal,
                nombre_modal,
                btnAdd
            }
        })
        )
        let { mostrarModalListarInformacionPerfil } = this.props
        mostrarModalListarInformacionPerfil(true)
    }

    listarIntereses = async (llave, nombre_modal, btnAdd) => {
        let intereses = await obtenerValores(AREAS_INTERES)
        let { mostrarModalListarInformacionPerfil } = this.props
        this.setState(prevState => ({
            llave,
            getDatos: intereses,
            datos_modal: {
                ...prevState.datos_modal,
                nombre_modal,
                btnAdd
            }
        })
        )
        mostrarModalListarInformacionPerfil(true)
    }

    agregarArchivos = files => {
        let { actualizarMensaje } = this.props
        let { archivos,requisitos } = this.state
        let contador = 0
        let contador2 = 0
        requisitos.map((info) => {
        if (info.valora === '1') {
            contador = contador + 1;
            files.map((j) => {
            if (j.codigo === info.id) {
                contador2 = contador2 + 1
            }
            })
        }

        })
        if (contador2 >= contador) {
            files.map((archivo => {
                if (archivos.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos`, tipo: 'info', mostrar: true, tiempo: 6000 })
                else archivos.push(archivo)
            }))
        } else actualizarMensaje({ titulo: 'Debe adjuntar los documentos que son obligatorio', tipo: 'info', mostrar: true, tiempo: 6000 });
        this.setState({ archivos })
    }

    eliminarArchivo = (i) => {
        let { actualizarMensaje } = this.props;
        let { archivos } = this.state;
        archivos.splice(i, 1)
        this.setState({ archivos })
        actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
    }

    copiaState = (llave) => {
        switch (llave) {
            case 'Personal':
                return {
                    email: '',
                    genero: '',
                    direccion: '',
                    ciudad: '',
                    telefono: '',
                    perfil: '',
                    salario_min: '',
                    salario_max: '',
                    linkedin: ''
                }
            case 'Academica':
                return {
                    universidad: null,
                    tipo: '',
                    titulo: null,
                    ciudad: null,
                    departamento: null,
                    fecha_inicio_est: moment(new Date()).format('YYYY-MM-DD'),
                    fecha_final_est: moment(new Date()).format('YYYY-MM-DD'),
                    en_curso_aca: '0'
                }
            case 'Experiencia':
                return {
                    empresa: null,
                    cargo: null,
                    fecha_inicio_exp: moment(new Date()).format('YYYY-MM-DD'),
                    fecha_final_exp: moment(new Date()).format('YYYY-MM-DD'),
                    responsabilidades: null,
                    en_curso_lab: '0',
                }
            case 'Habilidad':
                return {
                    tipo: '',
                    descripcion: null,
                    nivel: null
                }
            case 'Distincion':
                return {
                    descripcion: null
                }
        }
    }

    abrirDetalleVacante = (data) => {
        let { mostrarModalDetalleVacante } = this.props
        this.setState({
            DetalleVacante: data
        })
        mostrarModalDetalleVacante(true)
    }

    pintarPerfil = () => {
        return (
            <div className='' >
                <div style={{ padding: '2% 2% 0 2%' }} >
                    <Grid container direction={'row'} spacing={2} >
                        <Grid item xs={12} sm={12} md={12} >
                            <Paper elevation={0} square className="scroll" style={{ width: "100%" }}>
                                <DetallePerfil
                                    abrirOpcionesPerfil={this.abrirOpcionesPerfil}
                                    personal={this.state.getPersonal}
                                    logros={this.state.getLogros.resp}
                                    experiencia={this.state.getExperiencia}
                                    academica={this.state.getAcademico}
                                    habilidad={this.state.getHabilidad}
                                    todo={this.state.getTodo}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    pintarProcesoActual() {
        let { enListado } = this.state;
        return enListado ? this.pintarProcesosActivos() : this.pintarProcesoSeleccionado();
    }

    pintarProcesosActivos() {
        let { procesos } = this.state;
        if (procesos.length === 0) return <div className='pointer' onClick={() => this.setState({ seleccion: 'nuevo' })}><TareasTerminadas mensaje='No tienes procesos activos, clic aquí para iniciar un nuevo proceso de selección' marginTop='7%' imagen={emma_s} widthImg="7%" /></div>;
        return (
            <div style={{ padding: '2% 2% 0% 2%' }} >
                <Grid container direction={'row'} spacing={1} >
                    <TituloAccion principal='' alterno='¡ Estos son tus procesos solicitados !' />
                    {
                        procesos.map((data, i) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                    <Paper elevation={1} onClick={() => this.setState({ enListado: false, id_proceso: data.id })} className='pointer'>
                                        <ProcesoActivo
                                            titulo={data.vacante.nombre_cargo}
                                            fecha={data.vacante.fecha_registro}
                                            descripcion={`${data.vacante.descripcion.substring(0, 100)}...`}
                                            estado={data.vacante.estado_solicitud}
                                        />
                                    </Paper>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        )
    }

    pintarProcesoSeleccionado = () => {
        let { mostrarModalDetalleVacante } = this.props;
        let { proceso } = this.state;

        const ejecutarAccion = vistaDetalle => {
            this.setState({ vistaDetalle })
            mostrarModalDetalleVacante(true);
        }

        if (proceso) {
            return (
                <div className='' >
                    <Button onClick={() => this.setState({ enListado: true })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
                    <div style={{ padding: '0% 2% 0% 2%' }} >
                        <Grid container direction={'row'} spacing={1} >
                            <Grid item xs={12} sm={12} md={6} >
                                <TituloAccion principal='' alterno='¡ Información Básica !' />
                                <Paper elevation={0} square className="scroll">
                                    <ProcesoDetalleSimple {...{ proceso }} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                                <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                                    <Grid item xs={12} sm={12} md={12} >
                                        <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccion('proceso')}>
                                            <AccionesRapidas imagen={revisar} titulo='Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información de tu proceso.' />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div >
                </div>
            )
        }
    }

    render() {
        let { seleccion, doc_informativos } = this.state;
        return (
            <div>
                <Menu sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} doc_informativos={doc_informativos} />
                <div style={{
                    position: 'fixed',
                    top: '80px',
                    left: '120px',
                    zIndex: '1000'
                }} >
                    <img src={mintrabajo} className="logoMT" />
                </div>
                <div className='contenido_modulo' style={{'marginTop' : '70px'}}>
                        {this.vista()}
                </div>
                <ImgPortalPiel />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { modalDetallePqrs, modalAddPerfilPortal, modalAccionesRapidas, modalListarInformacionPerfil, modalDetalleVacante, modalAddPqrs } = state.redPortal
    return {
        modalAddPerfilPortal,
        modalAccionesRapidas,
        modalListarInformacionPerfil,
        modalDetalleVacante,
        modalAddPqrs,
        modalDetallePqrs
    }
}

const mapDispatchToProps = {
    actualizarMensaje,
    mostrarModulo,
    mostrarModalAddPerfilPortal,
    mostrarModalAccionesRapidas,
    mostrarModalListarInformacionPerfil,
    mostrarModalDetalleVacante,
    mostrarModalAddPqrs,
    mostrarModalDetallePqrs
}

Portal.propTypes = {
    //funciones
    mostrarModulo: PropTypes.func.isRequired,
    mostrarModalAddPerfilPortal: PropTypes.func.isRequired,
    modalAddPerfilPortal: PropTypes.bool.isRequired,
    mostrarModalAccionesRapidas: PropTypes.func.isRequired,
    modalAccionesRapidas: PropTypes.bool.isRequired,
    mostrarModalListarInformacionPerfil: PropTypes.func.isRequired,
    modalListarInformacionPerfil: PropTypes.bool.isRequired,
    mostrarModalDetalleVacante: PropTypes.func.isRequired,
    modalDetalleVacante: PropTypes.bool.isRequired,
    actualizarMensaje: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(Portal);