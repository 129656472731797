import React, { Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { actualizarMensaje, mostrarModulo } from './../../redux/actions/actGlobal';
import emma_w from '../../global/imagenes/emma_w.png';
import TareasTerminadas from "../general/TareasTerminadas";
import { consulta, generarFiltros, obtenerValoresFiltros, } from '../../global/js/funciones';
import ListarDatos from './../general/ListarDatos';
import { BtnDetalle } from './../general/BotonesAccion';
import Detalle from '../general/Detalle';
import moment from 'moment';
import ClaseFiltrar from './ClaseFiltrar';

const API_URI = 'api/v1.0/invitados/';

class InvitadosGestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cargando: true,
      periodo: '',
      clases: [],
      clase: [],
      filtros: [],
      openDetalleModal: false,
      openFiltrosModal: false,
    }
  }

  componentDidMount() {
    const { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/gestion_estudiantes_invitados', nombre: 'Gestión Estudiantes Invitados' });
    this.obtenerDatosIniciales();
  }

  async actualizarDataClases(filtros = [{ 'llave': 'estado', 'valor': 1 }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    this.setState({ cargando: true })
    let clases = await this.getClases(filtros);
    this.setState({ openFiltrosModal: false, clases, cargando: false, filtros });
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Est_Inv' }]);
    let filtros = periodo.length > 0 ? [{ 'llave': 'estado', 'valor': 1 }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : [];
    let clases = await this.getClases(filtros);
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', clases, cargando: false, filtros });
  }

  async getClases(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`${API_URI}clases/tipo/1?${f}`, null, null, (error, estado, clases) => {
        resolve(!error && estado === 200 ? clases : null)
      });
    });
  }

  pintarDetalle = (row) => {
    let { asignatura, grupo, profesor, usuario_registro, horario, periodo, estado } = row;
    let value_horario = '';
    let sin_asignar = false;
    JSON.parse(horario).forEach(({ dia, hora_inicio, hora_fin }) => {
      if (dia == null || hora_inicio == null || hora_fin == null) {
        sin_asignar = true;
        return;
      }
      value_horario += `${dia} de ${hora_inicio} - ${hora_fin}\n`;
    });

    if (sin_asignar) value_horario = 'Sin asignar';

    let clase = [
      { label: 'Asignatura', value: asignatura},
      { label: 'Grupo', value: grupo},
      { label: 'Profesor', value: `${profesor.primer_nombre} ${profesor.primer_apellido} ${profesor.segundo_apellido}`},
      { label: 'Estudiante', value: `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`},
      { label: 'Correo Personal', value: usuario_registro.correo_personal },
      { label: 'Celular', value: usuario_registro.celular },
      { label: 'Telefono', value: usuario_registro.telefono },
      { label: 'Horario', value: value_horario },
      { label: 'Periodo', value: periodo },
      { label: 'Estado', value: estado === '1' ? 'Activo' : 'Cancelado' },
    ];

    this.setState({ clase, openDetalleModal: true });
  }

  pintarHistorial = () => {
    let { clases, clase, openDetalleModal, filtros } = this.state;

    return (
      <div>
        <ListarDatos
          id='tbl_clases_historial_adm'
          titulo='Lista de Clases'
          datos={clases}
          avatar={({ asignatura }) => asignatura.charAt(0)}
          color_avatar={({ estado }) => estado === '1' ? { 'backgroundColor': '#1abc9c', 'color': 'white' } : { 'backgroundColor': '#b71c1c', 'color': 'white' }}
          fila_principal={({ asignatura }) => asignatura}
          filas={[
            { 'id': 'codigo_asignatura', 'enLista': false },
            { 'id': 'asignatura', 'enLista': false },
            { 'mostrar': ({ grupo }) => ` ${grupo}`, nombre: 'Grupo ' },
            { 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion, nombre: 'Identificacion ', enLista: false },
            { 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, nombre: 'Estudiante ' },
            { 'mostrar': ({ usuario_registro: { programa } }) => programa ? programa.nombre : '', nombre: 'Programa ', enLista: false },
            { 'mostrar': ({ usuario_registro: { programa } }) => programa ? programa.codigo : '', nombre: 'codigo_programa ', enLista: false },
            { 'mostrar': ({ usuario_registro: { correo } }) => correo, nombre: 'Correo Institucional ', enLista: false },
            { 'mostrar': ({ usuario_registro: { correo_personal } }) => correo_personal, nombre: 'Correo Personal ', enLista: false },
            { 'mostrar': ({ usuario_registro: { celular } }) => celular, nombre: 'Celular ', enLista: false },
            { 'mostrar': ({ usuario_registro: { telefono } }) => telefono, nombre: 'Telefono ', enLista: false },
            { 'mostrar': ({ profesor: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, nombre: 'Profesor ' },
            { 'mostrar': ({ horario }) => {
              let result = [];
              JSON.parse(horario).map(({ dia, hora_inicio, hora_fin, magistral }) => (
                result.push(dia === null || hora_inicio === null || hora_fin === null ? 'Horario sin asignar' : `${dia} de ${hora_inicio} - ${hora_fin}${magistral ? ' - Magistral' : ''}`)
              ));
              return result.join(', ');
            }, 'enLista': false, nombre: 'Horario'},
            { 'id': 'periodo', 'enLista': false },
            { 'mostrar': ({ fecha_registro }) =>  moment(fecha_registro).format('YYYY-MM-DD, h:mma'), nombre: 'Fecha de registro ' },
          ]}
          acciones={(row) => (<BtnDetalle callback={() => this.pintarDetalle(row)} />)}
          opciones={true}
          filtrar={true}
          refrescar={true}
          descargar='estudiantes_inscritos_como_invitados'
          actfiltrar={() => this.setState({ openFiltrosModal: true })}
          actRefrescar={() => this.actualizarDataClases(filtros)}
          buscar={true}
          css={{}}
        />

        <Detalle
          titulo='Detalle de la clase'
          open={openDetalleModal}
          cerrar={() => this.setState({ openDetalleModal: false })}
          config={clase}
        />
      </div>
    )
  }

  render() {
    let { actualizarMensaje } = this.props;
    let { cargando, openFiltrosModal } = this.state;

     return (
      <Fragment>
          {
            !cargando
            ? this.pintarHistorial()
            : <TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg='7%' />
          }

          <ClaseFiltrar actualizarMensaje={actualizarMensaje} openFiltrosModal={openFiltrosModal} closeFiltrosModal={() => this.setState({ openFiltrosModal: false })} limpiar={() => this.actualizarDataClases()} filtrar={(filtros) => this.actualizarDataClases(filtros)} />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
	actualizarMensaje,
	mostrarModulo,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitadosGestion);