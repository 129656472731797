import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import BuscarSelect from "../general/BuscarSelect";


export default class SolicitudesAgregarHechos extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      mensaje: ''
    }
  }

  render() {
    let {
      hechos,
      onChange,
      Pretensiones,
      area,
      area_derecho,
      setArea_derecho,
      setTipo_Consultoria,
      tipo_consultoria,
      tipos_consultorias
    } = this.props.props_c;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <BuscarSelect datos={tipos_consultorias} cambiarEstado={(value) => setTipo_Consultoria(value)} valor={tipo_consultoria} id='select-tipo-asesoria' placeholder='Seleccione Tipo de Asesoría' />
        </Grid>
        <Grid item xs={12}>
            <BuscarSelect datos={area_derecho} cambiarEstado={(value) => setArea_derecho(value)} valor={area} id='select-area-derecho' placeholder='Seleccione Area del derecho' />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={Pretensiones}
            required
            type="text"
            id="Pretensiones"
            name="Pretensiones"
            label="Pretensiones"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
            <TextField
            value={hechos}
            required
            type="text"
            id="hechos"
            name="hechos"
            label="Escriba los hechos"
            multiline
            fullWidth
            rows={8}
            onChange={onChange}
            />
        </Grid>
      </Grid>
    );
  }
}
