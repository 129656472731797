// React
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Components
import { BtnDetalle, BtnFolder, BtnGestionar } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
// Material
import { IconButton, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ErrorOutlined';
// Funciones
import moment from "moment";
import { obtenerColorAvatarSolicitud } from '../funciones';


class GradosListar extends Component {
	constructor(props) {
		super(props);
	}

	componentWillUnmount() {
		this.props.setDataSolicitud({})
	}

	configurarAcciones = (rowData) => {
		let { admin, solicitud, setDataSolicitud, setEstado, setAdjuntosEstado, showModalDetalleGrado, showModalAdjuntos, showModalGestionarSolicitud, showModalEvalComite } = this.props
		let { permiso_estado, estado_actual } = rowData;
		let { codigo } = estado_actual

		const eventoDetalle = (rowData) => {
			setDataSolicitud(rowData);
			showModalDetalleGrado(true);
		}

		const eventoAdjuntos = async (rowData) => {
			setDataSolicitud(rowData)
			setEstado(rowData.estado_actual)
			showModalAdjuntos(true)
		}

		const eventoGestionar = async (rowData) => {
			setDataSolicitud(rowData)
			setEstado(rowData.estado_actual)
			showModalGestionarSolicitud(true)
		}

		let ver = <BtnDetalle texto="Ver" callback={() => eventoDetalle(rowData)} />
		let adjuntos = <BtnFolder callback={() => eventoAdjuntos(rowData)} color='#ffd600' />;
		let gestion = <BtnGestionar callback={() => eventoGestionar(rowData)} color='#00bfa5' texto='Gestionar' />

		let botones = <div>{ver}</div>
		if (codigo !== 'gr_est_fin' && codigo !== 'gr_est_rec' && permiso_estado == 1 && admin) botones = <div>{ver} {adjuntos} {gestion}</div>
		// if (codigo !== 'gr_est_fin' && codigo !== 'gr_est_rec' && permiso_estado == 0 && admin) botones = <div>{ver} {adjuntos}</div>
		return botones
	}

	calcularLimiteTiempo = (fecha_final) => {
		const fecha_actual = moment(new Date()); //día actial
		let limite = moment.duration(moment(fecha_final).diff(fecha_actual))
		let dias = parseInt(limite.asDays())
		if (dias == 1) return `Queda ${dias} día para gestionar este proceso`
		if (dias > 1) return `Quedan ${dias} días para gestionar este proceso`
		// else return "Proceso atrasado, gestione inmediatamante."
	}

	pintarIconoWarning = (fecha_final) => {
		const fecha_actual = moment(new Date()); //día actial
		let limite = moment.duration(moment(fecha_final).diff(fecha_actual))
		let dias = parseInt(limite.asDays())
		if (this.props.admin && dias < 1) {
			return (
				<Tooltip title={"Proceso Atrasado"} placement="right" >
					<IconButton color={"primary"} spacing={1} size="small">
						<WarningIcon fontSize="inherit" />
					</IconButton>
				</Tooltip>
			)
		}
	}

	render() {
		let { grados, admin, refrescar, showModalFiltros } = this.props;

		return (
			<div style={{ padding: '0% 0% 0% 0%' }}>
				<ListarDatos
					id="tbl_listar_grados"
					datos={grados}
					titulo={'Lista Solicitudes'}
					acciones={(row) => this.configurarAcciones(row)}
					actfiltrar={() => showModalFiltros(true)}
					actRefrescar={() => refrescar()}
					opciones={true}
					buscar={true}
					filtrar={admin}
					refrescar={admin}
					descargar={admin ? "EMMA - GRADOS" : ""}
					// avatarImg={admin}
					// avatar={({ usuario_registro, tipo }) => admin ? `${rutaImg}${usuario_registro.identificacion}.jpg` : tipo.nombre.toUpperCase().charAt(0)}
					avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
					color_avatar={({ estado_actual, condecoracion }) => obtenerColorAvatarSolicitud(estado_actual.codigo, condecoracion)}
					fila_principal={({ tipo }) => `${tipo.nombre}`}
					filas={[
						{
							'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido} `,
							'enLista': true,
							'id': 'Estudiante',
						},
						{
							'mostrar': ({ usuario_registro: { identificacion } }) => `${identificacion}`,
							'enLista': true,
							'id': 'Identificacion',
						},
						{
							// 'mostrar': ({ estado_actual, limite_estado_actual }) =>  <span> {estado_actual.nombre} {this.pintarIconoWarning(limite_estado_actual)} </span>,
							'mostrar': ({ estado_actual, limite_estado_actual }) =>  estado_actual.nombre,
							'id': 'Estado',
						},
						{
							'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
							'id': 'Fecha registro',
						},
						{
							'mostrar': ({ limite_estado_actual }) => admin && limite_estado_actual && this.calcularLimiteTiempo(limite_estado_actual),
							'id': 'Límte para gestionar',
						},
						{
							'mostrar': ({ diplomado }) => diplomado && diplomado.nombre ? diplomado.nombre : '',
							'id': 'Diplomado',
							'enLista': false
						},
					]}
				/>
			</div>
		);
	}
}

GradosListar.propTypes = {
	//variables
	admin: PropTypes.bool.isRequired,
	grados: PropTypes.array.isRequired,
	//funciones
	setDataSolicitud: PropTypes.func.isRequired,
	showModalDetalleGrado: PropTypes.func.isRequired,
	//funciones Adm
	setAdjuntosEstado: PropTypes.func,
	showModalAdjuntos: PropTypes.func,
}

export default GradosListar;
