import React, {Fragment} from "react";

// COMPONETES
import AppBarModal from "../../general/AppBarModal";
import cursosLibres from "../../../global/imagenes/cursoslibres.png";
import { red } from "@material-ui/core/colors";
import { Grid, Paper, Card, CardHeader, CardContent, Avatar, Typography, Dialog } from '@material-ui/core';
// IMAGENES
import emma_s1 from "../../../global/imagenes/emma_s1.png";
import emma_w from "../../../global/imagenes/emma_w.png";
import TareasTerminadas from "../../general/TareasTerminadas";

// ICONOS MENU
import { Hidden, Badge } from "@material-ui/core";


export function MenuSeleccionar({openModalNuevo, informacionPrincipal, cargandoGuardar, cards, inscripcion_seleccionada}) {
    const  onClick = (id) => {
      inscripcion_seleccionada(id);
      openModalNuevo(true);
    };
    const pintar  = (app, claseN = "") => {
    if (informacionPrincipal && informacionPrincipal.inscribirse) {
        return (
            <Fragment>
                {/* <Grid container direction="row" justifyContent="center" style={{ marginBottom: "100px" }} > */}
                {
                  cards.length === 0 ?
                    <TareasTerminadas mensaje = 'Aún no hay opciones disponibles a mostrar.' marginTop = '7%' imagen = { emma_s1 } widthImg = "7%" />
                    : <Grid container direction = { 'row' } justifyContent="center" spacing = { 1 } style = {{ width: "100%" }}>
                        {
                          cards.map(({ nombre, descripcion, id }) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} style={{ padding: "2% 2% 0 2%" }} onClick={() => onClick(id)} >
                                    <Paper elevation={8} square>
                                        <Card style={{ maxWidth: "100%", marginTop: 35, cursor: "pointer" }} elevation={0} >
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }} > I </Avatar>
                                                }
                                                title={nombre}
                                            />
                                            <CardContent style={{ height: "10%", textAlign: "center" }}>
                                                <img src={cursosLibres} style={{ width: "40%", margin: "0 auto" }} alt="Imagen Cursos" />
                                            </CardContent>
                                            <CardContent>
                                                <Typography style={{ minHeight: 100 }} variant="body2" color="textSecondary" component="p" align="justify" >
                                                    {descripcion}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Paper>
                                </Grid>
                            );
                          })
                        }
                      </Grid>
                }

                <Dialog open={cargandoGuardar} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}  >
                    <AppBarModal titulo={"Enviando Datos"} mostrarModal={() => {}} titulo_accion="" accion="" />
                    <TareasTerminadas mensaje="Se están guardando los datos, un momento por favor" imagen={emma_w} widthImg="7%" marginTop="5%" cargando={true}/>
                </Dialog>
            </Fragment>
        );
    } else
        return (
            <TareasTerminadas mensaje="Hola, no puedes hacer más inscripciones, ya has alcanzado el límite de inscripción como invitado a una clase" imagen={emma_s1}  widthImg="7%"  marginTop="5%" />
        );
    };

    return (
      <div>
        <div>
          <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
          <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
        </div>
      </div>
    );
}
