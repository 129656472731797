import React, { Component } from 'react';
// Imagenes
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import revisar from '../../global/imagenes/revisar.png';
import subjects from '../../global/imagenes/subject.png';
import study from '../../global/imagenes/study.png';
import correction from '../../global/imagenes/correction.png';
import moment from 'moment';
// Componentes
import OpcionTipoSolicitud from './matriculas/OpcionTipoSolicitud';
import ModalCrearSolicitud from './matriculas/ModalCrearSolicitud';
import ModalCrearSolicitudGrado from './matriculas/ModalCrearSolicitudGrado';
import ModalCrearSolicitudReintegro from './matriculas/ModalCrearSolicitudReintegro';
import { MenuMatriculas } from './MenuMatriculas';
import TareasTerminadas from '../general/TareasTerminadas';
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import BarraProceso from '../general/BarraProceso';
import { Historial } from './matriculas/Historial';
import ModalDetalleSolicitud from './matriculas/ModalDetalleSolicitud';
import { consulta, obtenerValoresFiltros, obtenerMisDatos, crear_form_data, mostrarError, formulario } from '../../global/js/funciones';
import MatriculaActiva from './matriculas/MatriculaActiva';
// Redux
import { connect } from 'react-redux';
import {
	showModalSolicitudes,
	showModalDetalle,
	showModalFiltros,
	setMisCursos,
	setMisDatos,
	mostrarAdjuntarVolante,
	mostrarConfirmacion,
	mostrarEditarMatricula
} from '../../redux/actions/actMatriculas';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal';
import { PROCESOS } from './matriculas/helper';
// Material components
import { Grid, Paper, Table, TableRow, TableCell, TableBody, Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BtnVer } from '../general/BotonesAccion';
import notification from '../../global/imagenes/notification.png';

import MatriculasVolantePago from './matriculas/MatriculasVolantePago';
import ModalConfirmarHomologacion from './matriculas/ModalConfirmarHomologacion';
import folder from "../../global/imagenes/folder.png";
import interrogacion from "../../global/imagenes/interrogacion.png";
import ModalActualizarDatos from "./matriculas/ModalActualizarDatos";
import ModalCorreccionNotas from "./matriculas/ModalCorreccionNotas";
import ModalSolicitudGrado from './matriculas/ModalSolicitudGrado';

class Matriculas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seleccion: "proceso", //'proceso', agregar
			render: false,
			tipoSolicitud: null,
			parametro: null,
			programas: [],
			asignaturas: [],
			historial: [],
			procesosRecientes: [],
			procesoActual: {},
			estados: [],
			enListado: true,
			id_matricula: null,
			procesos: [],
			opcion: 'detalle',
			filtros: [],
			modalSolicitudGrado: false,
			modalSolicitudReintegro: false,
			anchorEl : null,
			modalCertificado: false
		};
	}

	cambiarEstado = (estados) => {
		this.setState(estados);
	};

	async componentDidMount() {
		const { mostrarModulo, setMisDatos, usuario } = this.props;
		if (usuario) {
			mostrarModulo({ ruta: '/procesos_academicos', nombre: 'Procesos Académicos' });
			const misDatos = await obtenerMisDatos();
			let procesos = []
			this.setState({ render: false });
			
			// validacion de usuarios docente y estudiante
			if (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === 'Per_Adm_Mat') procesos = await obtenerValoresFiltros([{ llave: 'generica', valor: PROCESOS }, { llave: 'valora', valor: 'si' }])
			else if (usuario.perfil.codigo === "Per_Doc" ) procesos = await obtenerValoresFiltros([{ llave: 'generica', valor: PROCESOS }, { llave: 'valora', valor: 'si' }, { llave: 'estado', valor: '1' }, { llave: 'valore', valor: 'docente' }])
			else procesos = await obtenerValoresFiltros([{ llave: 'generica', valor: PROCESOS }, { llave: 'valora', valor: 'si' }, { llave: 'estado', valor: '1' }, { llave: 'valore', 'valor': 'estudiante' }])

			setMisDatos(misDatos);
			this.setState({ procesos, render: true });
			this.obtenerDatosIniciales();
		}
	}

	// getProcesosRecientes = () => {
	// 	this.setState({ render: false, cargando: true })
	// 	consulta(`api/v1.0/matriculas/recientes`, null, null, (error, estado, procesosRecientes) => {
	// 		this.setState({ procesosRecientes, render: true });
	// 	});
	// };

	getProcesosRecientes = () => {
		return new Promise(resolve => {
		  consulta(`api/v1.0/matriculas/recientes`, null, null, (error, estado, resp) => {
			resolve(estado === 200 && !error ? resp : null);
		  })
		})
	  }

	async componentDidUpdate(prevProps, prevState) {
		const { seleccion } = this.state;
		if (seleccion !== prevState.seleccion) {
			switch (seleccion) {
				case 'historial':
					this.setState({ render: false })
					consulta(`api/v1.0/matriculas/persona`, null, null, (error, estado, historial) =>
						this.setState({ historial, render: true })
					);
					break;
				case 'proceso':
					this.obtenerDatosIniciales();
					break;
				default:
			}
		}
	}

	async obtenerDatosIniciales(){
		this.setState({ cargando: true, enListado: true, render:false });
		let procesosRecientes = await this.getProcesosRecientes()
		this.setState({procesosRecientes})
		await this.obtenerNotificacines_matricula();
		this.setState({ cargando: false,  render: true, })
	}


	obtenerNotificacines_matricula = () => {
		consulta(`api/v1.0/matriculas/notificaciones_matricula`, null, null,
		  (error, estado, resp) => {
			if (estado === 200) {
			  this.notificacionRequistosPendientes(resp);
			}
		  }
		)
	}

	async notificacionRequistosPendientes(matriculasProcesadas) {
		let { procesosRecientes } = this.state;
		let notificaciones = []
		let { mostrarAdjuntarVolante } = this.props
		const accionesSolicitudes = (data) => {
			let found = '';
			const onClickAbrir = async ({ id }) => {
				found = procesosRecientes.find(element => element.id == id);
				this.setState({ seleccion: 'proceso', enListado: false, procesoActual: found, anchorEl: null });
				const estados = await this.getEstados(id);
				this.setState({ estados, cargando: true });
			}
			return <BtnVer callback={() => onClickAbrir(data)} texto='Ver' />;
		}
	
		matriculasProcesadas.map((data) => {
			let notificacion = {
			icono: notification,
			titulo: `¡ Documento Pendiente !`,
			mensaje: `Ya puedes subir el volante cancelado `,
			tipo: 'mensaje',
			acciones: () => accionesSolicitudes(data),
			}
			notificaciones.push(notificacion)
		})
	
		this.setState({ notificaciones });
	}

	guardarVolantePagado = async (id, total, archivos, callback) => {
		let { actualizarMensaje, mostrarAdjuntarVolante } = this.props;
		let sw = true;
		if(archivos.length <= 0 ){
            sw = false;
			actualizarMensaje({ 
				titulo: "Adjuntar documento.", 
				tipo: "info", 
				mostrar: true, 
				tiempo: 6000 
			});
			this.setState({ cargando: false})
		}
		
		if(sw){
			let data = await crear_form_data({ total });
			archivos.map(({ archivo, codigo }, index) => {
			data.append(`archivo${index}`, archivo)
			data.append(`codigo${index}`, codigo)
			return true;
			})
			formulario(`api/v1.0/matriculas/volanteadjuntos/${id}`, data, "post", (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				mostrarAdjuntarVolante(false);
				this.obtenerDatosIniciales();
				} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
			callback();
			})
		}
	  }


	  guardarConfirmacion = async (id,dato,callback) => {
		let { actualizarMensaje, mostrarConfirmacion } = this.props;
		let data = await crear_form_data({ dato });
		formulario(`api/v1.0/matriculas/confirmacion/${id}`, data, "post", (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				mostrarConfirmacion(false);
				this.obtenerDatosIniciales();
				} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
			callback();
		})
	  }

	getEstados(id) {
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/estados/${id}`, null, null, (error, estado, resp) => {
				resolve(resp);
			});
		});
	}


	

	pintar = () => {
		let { seleccion, render, enListado, opcion, procesoActual,  notificaciones, anchorEl } = this.state;
		const { showModalDetalle, modalDetalle, modalAdjuntarVolante, mostrarAdjuntarVolante,mostrarConfirmacion,modalConfirmar } = this.props;
		return (
			<div>
				<MenuMatriculas
					notificaciones={notificaciones}
					anchorEl={anchorEl} 
					setanchorEl={(valor) => this.setState({'anchorEl': valor})}
					seleccionar={(seleccion) => this.cambiarEstado({ seleccion })}
					sele={seleccion}
				/>
				<MatriculasVolantePago
					mostrarAdjuntarVolante={mostrarAdjuntarVolante}
					modalAdjuntarVolante={modalAdjuntarVolante}
					matricula={procesoActual}
					guardarVolantePagado={this.guardarVolantePagado}
				/> 
				<ModalConfirmarHomologacion
					mostrarConfirmacion={mostrarConfirmacion}
					modalConfirmar={modalConfirmar}
					matricula={procesoActual}
					guardarConfirmacion={this.guardarConfirmacion}
				/>     
				<div className="contenido_modulo">
					{render ? (
						<div>
							{seleccion === 'agregar' && this.pintarNuevo()}
							{seleccion === 'proceso' && (enListado ? this.pintarprocesosRecientes() : this.pintarProcesoActual())}
							{seleccion === 'historial' && this.pintarHistorial()}
							<ModalDetalleSolicitud
								open={modalDetalle}
								setOpen={showModalDetalle}
								matricula={procesoActual}
								opcion={opcion}
								cambiarEstado={this.cambiarEstado}
							/>
						</div>
					) : (
						<TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" cargando={true}/>
					)}
				</div>
			</div>
		);
	};

	pintarprocesosRecientes() {
		let { procesosRecientes } = this.state;
		if (procesosRecientes.length === 0)
			return (
				<div className="pointer" onClick={() => this.setState({ seleccion: 'agregar' })}>
					<TareasTerminadas
						mensaje="No tienes solicitudes activas, clic aquí para solicitar matrícula"
						marginTop="7%"
						imagen={emma_s}
						widthImg="7%"
					/>
				</div>
			);

		const handleClick = async (matricula) => {
			this.setState({ enListado: false, procesoActual: matricula });
			const estados = await this.getEstados(matricula.id);
			this.setState({ estados, cargando: true });
		};

		return (
			<div style={{ padding: '2% 2% 0% 2%' }}>
				<Grid container direction={'row'} spacing={1}>
					<TituloAccion principal="" alterno="¡ Estos son tus solicitudes de matrícula para este periodo !" />
					{procesosRecientes.map((matricula, i) => {
						return (
							<Grid item xs={12} sm={6} md={4} lg={3} key={i}>
								<Paper elevation={1} onClick={() => handleClick(matricula)} className="pointer">
									<MatriculaActiva
										tipo={matricula.tipo}
										fecha={matricula.fecha_registro}
										descripcion={`Tu solicitud de matrícula para ${matricula.tipo
											.nombre} se encuentra en estado ${matricula.estado_solicitud
												.nombre.toLowerCase()}.`}
										estado={matricula.estado_solicitud}
									/>
								</Paper>
							</Grid>
						);
					})}
				</Grid>
			</div>
		);
	}

	abrirDetalle = async (opcion) => {
		const procesoActual = await this.getDetalleMatricula(this.state.procesoActual.id);
		this.setState({ procesoActual });
		this.props.showModalDetalle(true);
		this.setState({ opcion });
	};

	pintarProcesoActual = () => {
		let { mostrarAdjuntarVolante,mostrarConfirmacion } = this.props

		const ejecutarAdjunarVolante = () => {
			mostrarAdjuntarVolante(true);
		}

		const ejecutarConfirmacion = () => {
			mostrarConfirmacion(true);
		}

		const {
			procesoActual: {
				usuario_registro: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido },
				fecha_registro,
				tipo,
				estado_solicitud: { codigo: estado, nombre },
				motivo_negado,
				programa
			},
			render
		} = this.state;
		return (
			<div>
				<Button
					onClick={() => this.setState({ enListado: true })}
					style={{ padding: '0px 3px 0px 3px', marginTop: '1%' }}
					color="secondary"
				>
					{' '}
					<ArrowBackIcon />{' '}
					<Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align="justify">
						{' '}
						Atras{' '}
					</Typography>{' '}
				</Button>
				<Grid container direction={'row'} spacing={1}>
					<Grid item xs={12} sm={12} md={6}>
						<TituloAccion principal="" alterno="¡ Información Básica !" />
						<Paper elevation={0} square className="scroll">
							{render && (
								<Table>
									<TableBody>
										<TableRow>
											<TableCell>Solicitante</TableCell>
											<TableCell
											>{`${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
										</TableRow>
										{programa && (
											<TableRow>
												<TableCell>Programa</TableCell>
												<TableCell>{`${programa.nombre}`}</TableCell>
											</TableRow>
										)}
										<TableRow>
											<TableCell>Tipo de Solicitud:</TableCell>
											<TableCell>{tipo.nombre}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Estado de la Solicitud:</TableCell>
											<TableCell>{nombre}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Fecha Registro:</TableCell>
											<TableCell>{moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}</TableCell>
										</TableRow>
										{estado === 'Mat_Rec' &&
											motivo_negado && (
												<TableRow>
													<TableCell>Motivo Rechazo:</TableCell>
													<TableCell>{motivo_negado}</TableCell>
												</TableRow>
											)}
									</TableBody>
								</Table>
							)}
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<TituloAccion principal="" alterno="¿ Que deseas hacer ?" />
						<Paper elevation={0} square className="scroll">
							<Grid container direction={'row'} spacing={1} style={{ width: '100%' }}>
								<Grid item xs={12} sm={12} md={12}>
									<Paper
										elevation={1}
										style={{ border: '1px solid #e0e0e0' }}
										onClick={() => this.abrirDetalle('detalle')}
									>
										<AccionesRapidas
											imagen={revisar}
											titulo="¡ Más Información !"
											sub_titulo="Bien"
											des_sub_titulo=", Aquí puedes ver toda la información actual."
										/>
									</Paper>
								</Grid>
								{
									tipo.codigo !== "Mat_Pro_Sol_Gra" && tipo.codigo !== "Mat_Pro_Sol_Rein_Tran" && tipo.codigo !== "Mat_Pro_Act_Datos" && tipo.codigo !== "Mat_Pro_Cor_Nota" && tipo.codigo !== "Mat_Pro_Certi" &&(
										<Grid item xs={12} sm={12} md={12}>
											<Paper
												elevation={1}
												style={{ border: '1px solid #e0e0e0' }}
												onClick={() => this.abrirDetalle('materias')}
											>
												<AccionesRapidas
													imagen={subjects}
													titulo={`¡ Ver mis ${tipo.nombre} !`}
													sub_titulo="A continuación"
													des_sub_titulo=", puedes ver tus materias solicitadas."
												/>
											</Paper>
										</Grid>
									)
								}
								{ tipo.codigo !== "Mat_Pro_Act_Datos" && tipo.codigo !== "Mat_Pro_Cor_Nota" && tipo.codigo !== "Mat_Pro_Certi" &&
									<Grid item xs={12} sm={12} md={12}>
									<Paper
										elevation={1}
										style={{ border: '1px solid #e0e0e0' }}
										onClick={() => this.abrirDetalle('documentos')}
									>
										<AccionesRapidas
											imagen={study}
											titulo="¡ Mi Volante !"
											sub_titulo="Listo"
											des_sub_titulo=", Aquí puedes ver y descargar tu volante de matrícula."
										/>
									</Paper>
								</Grid>
								}
								
								{
									tipo.codigo === "Mat_Pro_Sol_Gra" && (
										<Grid item xs={12} sm={12} md={12}>
											<Paper
												elevation={1}
												style={{ border: '1px solid #e0e0e0' }}
												onClick={() => this.abrirDetalle('linea')}
											>
												<AccionesRapidas
													imagen={correction}
													titulo="¡ Mis Comprobados  !"
													sub_titulo="Listo"
													des_sub_titulo=", Aquí puedes ver los comprobados de tu proceso."
												/>
											</Paper>
										</Grid>
									)
								}
								{
									tipo.codigo === "Mat_Pro_Sol_Rein_Tran" && estado === 'Mat_Pro' && (
										<Grid item xs={12} sm={12} md={12} >
											<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAdjunarVolante()}>
												<AccionesRapidas imagen={folder} titulo='¡ Documentos Pendientes !' sub_titulo='Listo' des_sub_titulo=', Aquí puedes cargar tu volante de pago cancelado.' />
											</Paper>
										</Grid>
									)
								}
								{
									tipo.codigo === "Mat_Pro_Certi" && estado === 'Mat_cer_pago_vol' && (
										<Grid item xs={12} sm={12} md={12} >
											<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAdjunarVolante()}>
												<AccionesRapidas imagen={folder} titulo='¡ Documentos Pendientes !' sub_titulo='Listo' des_sub_titulo=', Aquí puedes cargar tu volante de pago cancelado.' />
											</Paper>
										</Grid>
									)
								}
								{
									tipo.codigo === "Mat_Pro_Sol_Rein_Tran" && estado === 'Mat_Rein_Tran_Est' && (
										<Grid item xs={12} sm={12} md={12} >
											<Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarConfirmacion()}>
												<AccionesRapidas imagen={interrogacion} titulo='¿ Confirmar Estudio Homologacion ?' sub_titulo='Listo' des_sub_titulo=', Aquí puedes confirmar tu proceso.' />
											</Paper>
										</Grid>
									)
								}
							</Grid>
						</Paper>
					</Grid>

					{estado !== 'Mat_Rec' && (
						<Grid item xs={12} sm={12} md={12}>
							<TituloAccion principal="" alterno="¿ Como va mi proceso ?" />
							{this.pintarTiempo()}
						</Grid>
					)}
				</Grid>
			
			</div>
		);
	};

	handleClickOpcion = (opcion) => {
		this.setState({ tipoSolicitud: opcion, enListado: true });
	};

	pintarNuevo = () => {
		const {
			modalSolicitudes,
			showModalSolicitudes,
			showModalCursos,
			modalCursos,
			misCursos,
			setMisCursos,
			misDatos,
			setMisDatos,
			actualizarMensaje,
			modalAdjuntarVolante, 
			mostrarAdjuntarVolante,
			mostrarConfirmacion,
			modalConfirmar,
		} = this.props;
		const { tipoSolicitud, procesos, modalActualizarDatos, modalSolicitudGrado, modalSolicitudReintegro, modalCorreccionNotas, modalCertificado } = this.state;
		return (
			<section style={{ paddingTop: 100 }}>
				<Grid container justifyContent={'center'} alignItems={'center'} spacing={3} style={{ textAlign: 'center' }}>
					{procesos.length > 0 ?
						procesos.map((el) => (
							<Grid item xs={12} md={6} lg={3} key={el.codigo}>
								<OpcionTipoSolicitud
									img={`${el.archivo}`}
									handleClick={() => {
										this.handleClickOpcion(el);
										if (el.codigo === "Mat_Pro_Sol_Gra") {
											this.setState({ modalSolicitudGrado: true })
										} else if (el.codigo === "Mat_Pro_Sol_Rein_Tran") {
											this.setState({ modalSolicitudReintegro: true })
										} else if (el.codigo === "Mat_Pro_Act_Datos") {
											this.setState({modalActualizarDatos: true})
										}else if (el.codigo === "Mat_Pro_Certi") {
											this.setState({modalCertificado: true})
										} else if (el.codigo === "Mat_Pro_Cor_Nota") {
											this.setState({ modalCorreccionNotas: true })
										} else {
											showModalSolicitudes(true);
										}
									}}
									titulo={el.nombre}
								/>
							</Grid>
						))
						: (
							<TareasTerminadas mensaje="En estos momentos no cuenta con modulos habilitados" marginTop="7%" imagen={emma_s} widthImg="7%" />
						)}
				</Grid>
				{procesos.length > 0 &&
					<ModalCrearSolicitud
						open={modalSolicitudes}
						setOpen={showModalSolicitudes}
						showModalCursos={showModalCursos}
						modalCursos={modalCursos}
						tipo={tipoSolicitud}
						misCursos={misCursos}
						setMisCursos={setMisCursos}
						misDatos={misDatos}
						setMisDatos={setMisDatos}
						actualizarMensaje={actualizarMensaje}
					/>
				}

				{/* <ModalSolicitudGrado
					open={modalSolicitudGrado}
					setOpen={() => this.setState({ modalSolicitudGrado: false })}
					tipo={tipoSolicitud}
					misDatos={misDatos}
					setMisDatos={setMisDatos}
					actualizarMensaje={actualizarMensaje}
					mostrarAdjuntarVolante={mostrarAdjuntarVolante}
					modalAdjuntarVolante={modalAdjuntarVolante}
				/> */}

				<ModalCrearSolicitudGrado
					open={modalSolicitudGrado}
					setOpen={() => this.setState({ modalSolicitudGrado: false })}
					tipo={tipoSolicitud}
					misDatos={misDatos}
					setMisDatos={setMisDatos}
					actualizarMensaje={actualizarMensaje}
					mostrarAdjuntarVolante={mostrarAdjuntarVolante}
					modalAdjuntarVolante={modalAdjuntarVolante}
				/>

				<ModalActualizarDatos
					open={modalActualizarDatos || modalCertificado}
					setOpen={() => modalActualizarDatos == true ? this.setState({ modalActualizarDatos: false }): this.setState({ modalCertificado: false })}
					tipo={tipoSolicitud}
					misDatos={misDatos}
					setMisDatos={setMisDatos}
					actualizarMensaje={actualizarMensaje}
				/>

				<ModalCrearSolicitudReintegro
					open={modalSolicitudReintegro}
					setOpen={() => this.setState({ modalSolicitudReintegro: false })}
					tipo={tipoSolicitud}
					misDatos={misDatos}
					setMisDatos={setMisDatos}
					actualizarMensaje={actualizarMensaje}
				/>

				<ModalCorreccionNotas
					open={modalCorreccionNotas}
					setOpen={() => this.setState({ modalCorreccionNotas: false })}
					tipo={tipoSolicitud}
					misDatos={misDatos}
					setMisDatos={setMisDatos}
					actualizarMensaje={actualizarMensaje}
				/>
			</section>
		);
	};

	pintarHistorial() {
		const { procesoActual, historial } = this.state;
		const { showModalDetalle, modalDetalle, showModalFiltros, modalFiltros, mostrarEditarMatricula, modalEditarMatricula, actualizarMensaje } = this.props;
		return (
			<>
				<Historial
					procesoActual={procesoActual}
					modalDetalle={modalDetalle}
					showModalDetalle={showModalDetalle}
					modalFiltros={modalFiltros}
					showModalFiltros={showModalFiltros}
					historial={historial}
					cambiarEstado={this.cambiarEstado}
					resetOpcion={() => this.setState({ opcion: 'detalle' })}
					mostrarEditarMatricula={mostrarEditarMatricula}
					modalEditarMatricula={modalEditarMatricula}
					actualizarMensaje={actualizarMensaje}
				/>
		    </>
		);
	}

	getDetalleMatricula = (id) => {
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/${id}`, null, null, (error, estado, resp) => {
				resolve(resp);
			});
		});
	};

	pintarTiempo() {
		const { estados, procesoActual, procesoActual:{ tipo:{ codigo } } } = this.state;
		let activeStep = 2;
		const steps = [];
		if(codigo=="Mat_Pro_Sol_Gra"){
			let aux = estados;
			aux.sort((a,b)=>{
				return a.valord - b.valord
			})
			// aux.map(({ id, nombre }, i) => {
			aux.map(({ principal, secundario }, i) => {	
				if (secundario.id === procesoActual.estado_solicitud.id) activeStep = i;
				steps.push({ label: secundario.nombre });
			});
		}else{
			if (estados && estados.length > 0 ){
				estados.map(({ secundario }, i) => {
					if (secundario.id === procesoActual.estado_solicitud.id) activeStep = i;
					steps.push({ label: secundario.nombre });
				});
			}
		}
		return <BarraProceso steps={steps} activeStep={activeStep} />;
	}

	render() {
		return this.pintar();
	}
}

const mapStateToProps = (state) => {
	let { modalSolicitudes, modalDetalle, modalCursos, misCursos, misDatos, modalFiltros, modalAdjuntarVolante, modalEditarMatricula,modalConfirmar } = state.redMatriculas;
  let { usuario } = state.redGlobal;
	return {
		modalSolicitudes,
		modalDetalle,
		modalCursos,
		misCursos,
		misDatos,
		modalFiltros,
		modalAdjuntarVolante,
		modalConfirmar,
		modalEditarMatricula,
		usuario
	};
};

const mapDispatchToProps = {
	showModalSolicitudes,
	showModalDetalle,
	showModalFiltros,
	setMisCursos,
	setMisDatos,
	mostrarModulo,
	actualizarMensaje,
    mostrarAdjuntarVolante,
	mostrarConfirmacion,
	mostrarEditarMatricula
};

export default connect(mapStateToProps, mapDispatchToProps)(Matriculas);
