import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core'
import '../../../global/css/stylePortal.css'
import StopIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import Looks4Icon from '@material-ui/icons/Looks4';
import Looks5Icon from '@material-ui/icons/Looks5';
import { coloresEmma } from '../../../global/js/funciones';
class DetalleHabUniq extends Component {

    nivel = (nivel) => {
        let iconStyle = {
            fontSize: '30px',
            color: coloresEmma.secondarycolor
        }
        let iconStyleTwo = {
            fontSize: '30px',
            color: '#F5EBB6'
        }
        switch(nivel){
            case "5":
                return (
                    <>
                        <LooksOneIcon style={iconStyle}/>
                        <LooksTwoIcon style={iconStyle}/>
                        <Looks3Icon style={iconStyle}/>
                        <Looks4Icon style={iconStyle}/>
                        <Looks5Icon style={iconStyle}/>
                    </>
                )
            case "4":  
                return (
                    <>
                        <LooksOneIcon style={iconStyle}/>
                        <LooksTwoIcon style={iconStyle}/>
                        <Looks3Icon style={iconStyle}/>
                        <Looks4Icon style={iconStyle}/>    
                        <StopIcon style={iconStyleTwo}/>    
                    </>
                )
            case "3":  
                return (
                    <>
                        <LooksOneIcon style={iconStyle}/>
                        <LooksTwoIcon style={iconStyle}/>
                        <Looks3Icon style={iconStyle}/>
                        <StopIcon style={iconStyleTwo}/>
                        <StopIcon style={iconStyleTwo}/>
                    </>
                )
            case "2":  
                return (
                    <>
                        <LooksOneIcon style={iconStyle}/>
                        <LooksTwoIcon style={iconStyle}/>
                        <StopIcon style={iconStyleTwo}/>
                        <StopIcon style={iconStyleTwo}/>
                        <StopIcon style={iconStyleTwo}/>
                    </>
                )
            case "1":  
                return (
                    <>
                        <LooksOneIcon style={iconStyle}/>
                        <StopIcon style={iconStyleTwo}/>   
                        <StopIcon style={iconStyleTwo}/>   
                        <StopIcon style={iconStyleTwo}/>   
                        <StopIcon style={iconStyleTwo}/>   
                    </>
                )
        }
    }

    render(){
        let { data } = this.props
        return (
            <Grid container direction="row">
                <Grid item xs={4}>
                    <Typography component="p" className='__textHab'>
                        { data.descripcion }
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    { this.nivel(data.nivel) }
                </Grid>
            </Grid>
        )
    }
}

class DetalleHab extends Component {
    render(){
        let { data } = this.props
        let personal = data.filter(i => i.tipo === "personal")
        let laboral = data.filter(i => i.tipo === "laboral")
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" align="left" style={{'margin': '10px 0' }}>
                        LABORALES
                    </Typography>
                    {
                        Object.keys(laboral).map(i=> (
                            <DetalleHabUniq 
                                key={i}
                                data={laboral[i]}
                            />
                        ))
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="left" style={{'margin': '10px 0' }}>
                        PERSONALES
                    </Typography>
                    {
                        Object.keys(personal).map(i=> (
                            <DetalleHabUniq 
                                key={i}
                                data={personal[i]}
                            />
                        ))
                    }
                </Grid>
            </Grid>
        );
    }
};

export default DetalleHab;