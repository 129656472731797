// React
import React, { useState } from 'react';
// Material-ui Components
import { Grid, IconButton, Tooltip, Dialog, DialogContent, DialogActions, Button, Card, CardHeader, CardContent, Avatar, Typography, } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// Utils
import moment from 'moment';
import { rutaImg, crear_form_data, formulario, mostrarError } from "../../../global/js/funciones";
import { obtenerColorAvatarSolicitud } from "../funciones"
// Funciones
import { getEstadosSolicitud, getEvaluadoresEstado } from '../funciones';
// Componentes
import ListarDatos from '../../general/ListarDatos'
import AppBarModal from '../../general/AppBarModal';
import ConfirmarAccion from "../../general/ConfirmarAccion"
import AlertasSimple from "../../general/AlertasSimple"
import { BtnPersonas, BtnEditar, BtnForm, BtnPendiente } from "../../general/BotonesAccion";



const useStyles = makeStyles(theme => ({
	card: {
		marginTop: 5,
		cursor: 'pointer'
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		backgroundColor: red[500],
	},
	image: {
		width: '10%',
		margin: "0 auto"
	},
	contentImg: {
		height: '10%',
		textAlign: "center"
	},
}));


function ModalMensajesGestor(dataMensaje) {
	const classes = useStyles();
	return (
		<Card className={classes.card} elevation={0}>
			<CardHeader
				avatar={<Avatar aria-label="recipe" className={classes.avatar}> {dataMensaje.nombre_completo.charAt(0)} </Avatar>}
				title={dataMensaje.nombre_completo}
				subheader={moment(dataMensaje.fecha_registro).format('YYYY-MM-DD, h:mm a')}
			/>
			<CardContent>
				<Typography variant="body2" color="textSecondary" component="p" align="justify">
					{dataMensaje.mensaje}
				</Typography>
			</CardContent>
		</Card>
	);
}

export default function GradoEstados(props) {
	let [modalMsg, showModalMsg] = useState(false)
	let [modalEdit, showModalEdit] = useState(false)
	let [modalEval, showModalEval] = useState(false)
	let [modalConfir, showModalConfir] = useState(false)
	let [evaluadores, setEvaluadores] = useState([])
	let [fecha_sustentacion, setFecha] = useState(null)
	let [hora_inicio, setHoraInicio] = useState(null)
	let [hora_fin, setHoraFin] = useState(null)
	let [dataMensaje, setDataMensaje] = useState({ mensaje: "NN", nombre_completo: "NN", fecha_registro: "NN" })
	let { admin, perfil, solicitud, estado, estados, showModalConfirAplazamiento, showModalAdjuntos, setEstadosSolicitud, setAdjuntosEstado, setEstado, actualizarMensaje } = props;

	const ModificarEstado = async () => {
		fecha_sustentacion = fecha_sustentacion ? moment(fecha_sustentacion).format('YYYY-MM-DD') : '';
		hora_inicio = hora_inicio ? moment(hora_inicio).format('HH:mm') : '';
		hora_fin = hora_fin ? moment(hora_fin).format('HH:mm') : '';
		let data = await crear_form_data({ fecha_sustentacion, hora_inicio, hora_fin });
		formulario(`api/v1.0/grados/estado/${estado.id}`, data, "patch", (err, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!err) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					showModalEdit(false)
					showModalConfir(false)
				} else if (estado === 302) {
					tipo = 'info';
					titulo = resp.titulo;
				}
				else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			getEstadosSolicitud(solicitud.id, setEstadosSolicitud);
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});
	}

	const acciones = (estado) => {
		const handleClicEvaluadores = (id) => {
			getEvaluadoresEstado(id, (resp) => setEvaluadores(resp));
			showModalEval(true);
		}
		const handleClicAplazar = (id) => {
			setEstado(estado)
			showModalConfirAplazamiento()
		}
		const handleClicEditar = (id) => {
			setFecha(moment(estado.fecha_sustentacion).format('YYYY-MM-DD, h:mm a'))
			setHoraInicio(moment(estado.hora_inicio, "h:mm A"))
			setHoraFin(moment(estado.hora_fin, "h:mm A"))
			setEstado(estado)
			showModalEdit(true);
		}
		const handleClicMsj = () => {
			let { usuario_registro: { primer_nombre, primer_apellido, segundo_apellido }, mensaje, fecha_registro } = estado
			setEstado(estado)
			setDataMensaje({
				nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
				mensaje,
				fecha_registro,
			})
			showModalMsg(true);
		}

		let evaluadores = estado.fecha_sustentacion && <BtnPersonas callback={() => handleClicEvaluadores(estado.id)} texto='Evaluadores' />
		let aplazar = estado.fecha_sustentacion && estado.prorrogas < 1 && solicitud.estado_actual.codigo == estado.tipo_estado.codigo && <BtnPendiente callback={() => handleClicAplazar(estado.id)} title='Solicitar aplazamiento' />
		let editar = perfil && (perfil.tipo == "Per_Adm_Gra2" || perfil.tipo == "Per_Adm") && estado.fecha_sustentacion && estado.prorrogas < 1 && solicitud.estado_actual.codigo == estado.tipo_estado.codigo && <BtnEditar callback={() => handleClicEditar(estado.id)} />
		// let msg = estado.mensaje ?
		// 	<Tooltip title="Comunicado del gestor" aria-label="add" onClick={() => handleClicMsj()}><IconButton aria-label="delete" style={{ color: "#009688" }}><AssignmentIcon fontSize="small" /></IconButton></Tooltip> :
		// 	<Tooltip title="Comunicado del gestor" aria-label="add"><span><IconButton disabled aria-label="delete"><AssignmentIcon fontSize="small" /></IconButton></span></Tooltip>
		if (admin) return <div>{evaluadores} {editar}</div>
		return <div>{evaluadores} {aplazar}</div>
	}

	// Modales

	const modalEvaluadores = () => {
		return (
			<Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={modalEval} onClose={() => showModalEval(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<AppBarModal titulo={"Jurados Evaluadores Sustentación"} mostrarModal={() => showModalEval(false)} titulo_accion="" />
				<DialogContent style={{ padding: '2% 0% 0% 0%' }} className='scroll'>
					<ListarDatos
						id="tbl_evaluadores"
						datos={evaluadores}
						avatarImg={true}
						avatar={({ tipo, persona_interna }) => tipo == 1 ? `${rutaImg}${persona_interna.identificacion}.jpg` : ""}
						fila_principal={({ tipo, persona_interna, persona_externa }) =>
							tipo == 1 ?
								`${persona_interna.primer_nombre} ${persona_interna.primer_apellido} ${persona_interna.segundo_apellido} `.toUpperCase() :
								persona_externa.nombre.toUpperCase()
						}
						filas={[
							{ 'mostrar': ({ tipo, persona_interna, persona_externa }) => tipo == 1 ? persona_interna.correo : persona_externa.valora },
						]}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => showModalEval(false)} color="primary">Cerrar</Button>
				</DialogActions>
			</Dialog >
		)
	}

	const modalEditarEstado = () => {
		return (
			<Dialog onClose={() => showModalEdit(false)} open={modalEdit} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} style={{ width: 480, margin: "auto" }}>
				<AppBarModal titulo={'¡ Modificar Estado !'} mostrarModal={() => showModalEdit(false)} titulo_accion="" />
				<DialogContent className='scroll'>
					<Grid container spacing={1} alignItems="flex-end">
						<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} >
								<Grid container>
									<KeyboardDatePicker
										required={true}
										style={{ marginTop: 3, width: '100%' }}
										minDate={Date("YYYY-MM-dd")}
										margin="normal"
										label="Fecha evento"
										format="yyyy-MM-dd"
										value={fecha_sustentacion}
										onChange={(fecha_sustentacion) => setFecha(fecha_sustentacion)}
										KeyboardButtonProps={{ 'aria-label': 'change date', }}
										minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
										invalidDateMessage='Formato no valido'
									/>
								</Grid>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} md={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} >
								<Grid container>
									<KeyboardTimePicker
										required={true}
										style={{ marginTop: 3, width: '100%' }}
										margin="normal"
										label="Hora Inicio"
										value={hora_inicio}
										onChange={(hora_inicio) => setHoraInicio(hora_inicio)}
										KeyboardButtonProps={{ 'aria-label': 'change date', }}
										invalidDateMessage='Formato no valido'
									/>
								</Grid>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} md={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} >
								<Grid container >
									<KeyboardTimePicker
										required={true}
										style={{ marginTop: 1, width: '100%' }}
										margin="normal"
										label="Hora Fin"
										value={hora_fin}
										onChange={(hora_fin) => setHoraFin(hora_fin)}
										KeyboardButtonProps={{ 'aria-label': 'change date', }}
										invalidDateMessage='Formato no valido'
									/>
								</Grid>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
					<AlertasSimple tipo="warnig" titulo="Advertencia" sub_titulo="Solo podrá modificar estos datos una única vez."/>
				</DialogContent>
				<DialogActions>
					<BtnForm texto="CANCELAR" callback={() => showModalEdit(false)} />
					<BtnForm texto="MODIFICAR" callback={() => showModalConfir(true)} />
				</DialogActions>
			</Dialog>
		)
	}

	const modalConfirEditEstado = () => {
		return (
			<ConfirmarAccion
				titulo={'¿ Esta seguro que desea ejecutar esta acción ?'}
				dato={{ id: 0 }}
				mensaje1="Esta Seguro que desea modificar los datos de este estado, tenga en cuenta que solo los podrá modificar una única vez"
				modalConAccion={modalConfir}
				mostrarModalConfAccion={() => showModalConfir(false)}
				ejecutarAccion={() => ModificarEstado()} />
		)
	}

	const modalComunicadoEvaluador = () => {
		return (
			<Dialog onClose={() => showModalMsg(false)} open={modalMsg} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} style={{ width: 480, margin: "auto" }}>
				<AppBarModal titulo={'Comunicado del gestor'} mostrarModal={() => showModalMsg(false)} titulo_accion="" />
				<DialogContent style={{ padding: '0' }} className='scroll'>
					{ModalMensajesGestor(dataMensaje)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => showModalMsg(false)} color="primary">Cerrar</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<div>
			<ListarDatos
				datos={estados}
				css={{}}
				titulo={'Lista de estados'}
				avatar={({ tipo_estado }) => tipo_estado.nombre.toUpperCase().charAt(0)}
				color_avatar={({ tipo_estado }) => obtenerColorAvatarSolicitud(tipo_estado.codigo, solicitud.condecoracion)}
				id='tbl_estados_grado'
				acciones={(row) => acciones(row)}
				fila_principal={({ tipo_estado }) => tipo_estado.nombre}
				filas={[
					{ 'id': 'usuario_registro', 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`, 'nombre': 'Registrado por: ' },
					{ 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => `${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`, 'nombre': 'Fecha Registro: ' },
					{ 'id': 'fecha_sustentacion', 'mostrar': ({ fecha_sustentacion, hora_inicio, hora_fin }) => fecha_sustentacion ? <span>{fecha_sustentacion}, {moment(hora_inicio, "HH:mm").format("h:mm A")} - {moment(hora_fin, "HH:mm").format("h:mm A")}</span> : "", 'nombre': 'Fecha Sustentación: ' },
					{ 'id': 'lugar_sustentacion', 'mostrar': ({ lugar_sustentacion }) => lugar_sustentacion, 'nombre': 'Lugar: ' }
				]}
			/>
			{modalEvaluadores()}
			{modalEditarEstado()}
			{modalConfirEditEstado()}
		</div>
	);
}
