import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Grid } from '@material-ui/core';
import { red } from '@material-ui/core/colors';


//COMPONENTES DE SISEF
import SisefTipoSolicitudes from "./sisef/SisefTipoSolicitudes";
import { TIPOS_SISEF } from "./helper";
import { SisefMenu } from "./sisef/MenuSisef";
import SisefAsignaturas from './estudiante/SisefAsignaturas';


//COMPONENTES GENERALES
import { mostrarModulo } from "../../redux/actions/actGlobal";
import { obtenerValoresFiltros } from "../../global/js/funciones";
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from './../../global/imagenes/emma_w.png';


class SisefEstudiante extends Component {

  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'miproceso_ini',
      cargando: true,
      nuevo: true,
      mensaje: '',
      tipos: [],
      tipoSolicitud: {},
      estudiante: false,
      estudianteSisef: '',
      periodo: '',
      codigo_asignatura: ''
    }
  }


  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ rutas: 'mi_mentora', nombre: 'Mi Mentora' })
    this.obtenerDatosIniciales();
    this.esEstudiante();
    let periodo = await obtenerValoresFiltros([{ llave: 'codigo', valor: 'Per_Sisef' }]);
    this.setState({ periodo: periodo[0].nombre });
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  
  async obtenerDatosIniciales() {
    this.setState({ cargando: true })
    let mensaje = "Estas en el proceso";
    let tipos = await obtenerValoresFiltros([{ llave: 'generica', valor: TIPOS_SISEF }, { llave: 'estado', valor: '1'}, { llave: 'valore', valor: 'estudiante'}])
    this.setState({ tipos: tipos.sort((a, b) => a.valora - b.valora), mensaje, cargando: false })
  }

  vista = () => {
    let { seleccion } = this.state;
    switch (seleccion) {
      case 'miproceso_ini':
        return this.pintarOpciones()
      case 'proceso_estudiante':
        return this.pintarAsignaturasSisef()
      default:
        break;
    }
  }

  pintarOpciones = () => {
    let { tipos } = this.state;

    const tipoSeleccion = tipoSolicitud => {
      this.setState({ tipoSeleccion })
      switch (tipoSolicitud.codigo) {
        case 'Pro_Est':
          this.cambiarEstado({ seleccion: 'proceso_estudiante' })
          break;
        default:
          break;
      }
    }

    return (
      <>
        <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 5 }}  >
          {tipos.length > 0 ?
            tipos.map((tipo) => (
              <Grid item xs={12} md={6} lg={4} style={{ padding: '2% 2% 0% 2%' }} key={tipo.codigo}>
                <Paper elevation={8} square onClick={() => tipoSeleccion(tipo)} >
                  <SisefTipoSolicitudes
                    avatarcss={{ backgroundColor: red[500] }}
                    data={tipo}
                    image={tipo.archivo}
                  />
                </Paper>
              </Grid>
            ))
            : <TareasTerminadas mensaje="Cargando ..." marginTop="7%" imagen={emma_w} widthImg="7%" cargando={true} />}
        </Grid>
      </>
    )
  }

  esEstudiante() {
    let { usuario } = this.props;
    console.log(usuario);
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Nor" || usuario.perfil.codigo === "Per_Adm")) {
      this.setState({ estudiante: true, estudianteSisef: usuario.idenfificacion });
    }
    this.setState({ cargando: false })
  }

  actualizarEstado = (stateVista, valor) => {
    this.setState({ [stateVista]: valor });
  };

  pintarAsignaturasSisef() {
    let { estudianteSisef } = this.state;
    return (
      <div>
        <SisefAsignaturas
          estudianteSisef={estudianteSisef}
          seleccionar={(select, valor) => this.actualizarEstado(select, valor)}
          estudiante={() => this.setState({ estudiante: true })}
          periodo={this.state.periodo}
          codigo_asignatura={this.state.codigo_asignatura}
        />
      </div>
    )
  }


  render() {
    let { cargando, seleccion, estudiante } = this.state
    return (
      seleccion === "miproceso_ini" ?
        <div>
          <SisefMenu estudiante={estudiante} sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} />
          <div className='contenido_modulo'>
            {
              !cargando ? this.vista() :
                <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
            }
          </div>
        </div>
        : <div>
          {
            !cargando ? this.vista() :
              <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
          }
        </div>

    );
  }
}

const mapStateToProps = state => {
  let { usuario } = state.redGlobal;
  let { ModalAdd } = state.redSisef;
  return { usuario, ModalAdd }
}

const mapDispatchToProps = {
  mostrarModulo
}

SisefEstudiante.propTypes = {
  ModalAdd: PropTypes.bool.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
}



export default connect(mapStateToProps, mapDispatchToProps)(SisefEstudiante);