import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import BuscarSelect from "../../general/BuscarSelect";
import AlertasSimple from '../../general/AlertasSimple';


export default function InscripcionAgregarMiCarerra({ props_c }) {


  let {
    carrera,
    carrera2,
    calidad,
    nivel_educacion,
    niveles_educacion,
    costear_estudios,
    costear_estudio,
    setOpcion_nivelEducacion,
    setOpcion_costearEstudio,
    otro_costear,
    onChange
  } = props_c;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos Carrera
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            value={carrera.toUpperCase()}
            required
            id="carrera"
            name="carrera"
            label="Primera opción de carrera"
            fullWidth
            autoComplete="fname"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={carrera2.toUpperCase()}
            required
            id="carrera2"
            name="carrera2"
            label="Segunda opción de carrera"
            fullWidth
            autoComplete="fname"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect datos={niveles_educacion} cambiarEstado={(value) => setOpcion_nivelEducacion(value)} valor={nivel_educacion} id='select-tipo-nivel' nombre='¿A que nivel de la educación superior planeas ingresar ?' placeholder='Seleccione Opción' />
        </Grid>
        <Grid item xs={12}>
          <BuscarSelect datos={costear_estudios} cambiarEstado={(value) => setOpcion_costearEstudio(value)} valor={costear_estudio} id='select-tipo-costear' nombre='Cómo tienes pensado costear tus estudios de educación superior' placeholder='Seleccione Opción' />
        </Grid>
        {costear_estudio.label === 'OTRO' && (
          <Grid item xs={12}>
          <TextField
            value={otro_costear.toUpperCase()}
            required
            id="otro_costear"
            name="otro_costear"
            label="Ingrese Otra Forma de Costear sus Estudios"
            fullWidth
            autoComplete="fname"
            onChange={onChange}
          />
        </Grid>
        )}
        <AlertasSimple
          tipo="danger"
          titulo={<><p style={{ textAlign: 'justify', margin: '0', fontSize: '.9rem' }}>
            Estimado usuario al presionar el boton Siguiente esta aceptando los terminos de <b>CALIDAD</b> de la Corporacion Universitaria de la Costa CUC.</p>
            <p>Para ver los terminos y condiciones click sobre este mensaje</p></>}
          callback={() =>calidad(true)}
          />
      </Grid>
    </React.Fragment>
  );
}
