import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppBarModal from '../../general/AppBarModal';
import { TableRow, TableCell, TableBody, Table, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import moment from 'moment'
import TareasTerminadas from '../../general/TareasTerminadas'
import emma_w from '../../../global/imagenes/emma_w.png';
import { ConsultoriaMenu, ConsultoriaMenuDuplicar } from "./ConsultoriaMenu";
import { api, consulta,crear_form_data,formulario } from "../../../global/js/funciones";
import ListarDatos from "./../../general/ListarDatos";
import { BtnDetalle, BtnForm,BtnEliminar } from "../../general/BotonesAccion";
import {actualizarMensaje} from '../../../redux/actions/actGlobal'




class DetalleConsultoria extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      seleccion: 'detalle',
      datos: [],
      tipo_identificacion: '',
      identificacion: '',
      primer_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      telefono: '',
      departamento: '',
      ciudad: '',
      barrio: '',
      direccion: '',
      comunidad: '',
      genero: '',
      discapacidad: '',
      hechos: '',
      pretensiones: '',
      correo: '',
      fecha: '',
      programa: '',
      documentos: [],
      datos:[],
      tipo_identificacion:'',
      identificacion:'',
      primer_nombre:'',
      primer_apellido:'',
      segundo_apellido:'',
      telefono:'',
      departamento:'',
      ciudad:'',
      barrio:'',
      direccion:'',
      comunidad:'',
      genero:'',
      discapacidad:'',
      hechos:'',
      pretensiones:'',
      correo:'',
      fecha:'',
      programa:'',
      documentos:[],
      estados: [],
      tipo_consultoria:'',
    }
  }

  
  componentDidUpdate({modalDetalleConsultoria},{ seleccion }) {
    let { modalDetalleConsultoria: modalDetalleConsultoriaNew, cargar } = this.props;
    if (modalDetalleConsultoria !== modalDetalleConsultoriaNew && cargar) {
      this.setState({ cargando: true, cargar:false })
      this.obtenerDatos()
      this.obtenerAdjuntos()
      this.obtenerEstados()
    }
    let { seleccion: seleccionNew } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'adjunto')this.obtenerAdjuntos();
      else if (seleccionNew === 'detalle')this.obtenerDatos();
      else if (seleccionNew === 'estado') this.obtenerEstados();
    } 
  } 

  async obtenerAdjuntos() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    consulta(`api/v1.0/consultoria/adjuntos/${f}?`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, documentos: resp });
    })
  }
  
  async obtenerEstados() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    consulta(`api/v1.0/consultoria/estados/detalle/${f}?`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  async obtenerDatos(){
  let datos = await this.obtenerSolicitud();
  this.setState({
    tipo_identificacion:datos[0].solicitante.tipo_identificacion.nombre,
    identificacion:datos[0].solicitante.identificacion,
    primer_nombre:datos[0].solicitante.primer_nombre,
    primer_apellido:datos[0].solicitante.primer_apellido,
    segundo_apellido:datos[0].solicitante.segundo_apellido,
    telefono:datos[0].solicitante.telefono,
    departamento:datos[0].solicitante.departamento ? datos[0].solicitante.departamento.nombre:'',
    ciudad:datos[0].solicitante.ciudad ? datos[0].solicitante.ciudad.nombre:'',
    barrio:datos[0].solicitante.lugar_residencia,
    direccion:datos[0].solicitante.direccion,
    comunidad:datos[0].solicitante.comunidad ? datos[0].solicitante.comunidad.nombre:'',
    genero:datos[0].solicitante.genero ? datos[0].solicitante.genero.nombre:'',
    discapacidad:datos[0].solicitante.discapacidad === '0'? 'NO': 'SI',
    area:datos[0].area.nombre,
    hechos:datos[0].hechos,
    pretensiones:datos[0].Pretensiones,
    correo:datos[0].solicitante.correo,
    fecha:datos[0].fecha_registro,
    programa:datos[0].programa?datos[0].programa.nombre:'',
    anho:datos[0].anho?datos[0].anho:'',
    cargando: false, 
    cargar: false,
    tipo_consultoria: datos[0].tipo.nombre,
  })
}

async obtenerSolicitud() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    return new Promise(resolve => {
       consulta(`api/v1.0/consultoria/detalle/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  modificarInformacion = async (data) => {
    let { actualizarMensaje } = this.props
    let row = {
      "id":data.id,
      "estado":"0",
      "fecha_elimino":data.fecha_elimino,
      "fecha_registro":data.fecha_registro,
      "nombre_archivo":data.nombre_archivo,
      "usuario_elimino":data.usuario_elimino,
      "usuario_registro":data.usuario_registro
    }
    let data2 = await crear_form_data(row);
    formulario(`api/v1.0/consultoria/${data.id}/eliminar`, data2, "patch", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'danger';
        if (!error) {
            if (estado === 200) {
                tipo = 'success';
                titulo = resp.titulo;
                this.obtenerAdjuntos();
            } else {
                tipo = 'info'
                titulo = resp.titulo
            }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
    })
}

  pintarDetalle =() =>{
    let {tipo_identificacion,
      identificacion,
      primer_nombre,
      primer_apellido,
      segundo_apellido,
      telefono,
      departamento,
      ciudad,
      barrio,
      direccion,
      comunidad,
      genero,
      discapacidad,
      fecha,
      anho,
      programa,
      area,
      correo} = this.state
    
    let fecha_registro = moment(fecha).format('YYYY-MM-DD, h:mm a');
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Solicitante</TableCell>
            <TableCell align="left">{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Tipo Identificacion</TableCell>
            <TableCell align="left">{tipo_identificacion}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Identificacion</TableCell>
            <TableCell align="left">{identificacion}</TableCell>
          </TableRow>
          { correo == 'consultario'+identificacion+'@cuc.edu.co'?
          <TableRow key={4}>
            <TableCell component="th" scope="row">Correo</TableCell>
            <TableCell align="left">No ingreso correo</TableCell>
          </TableRow>:
          <TableRow key={4}>
            <TableCell component="th" scope="row">Correo</TableCell>
            <TableCell align="left">{correo}</TableCell>
          </TableRow>
          }
          <TableRow key={5}>
            <TableCell component="th" scope="row">Telefono</TableCell>
            <TableCell align="left">{telefono}</TableCell>
          </TableRow>
          <TableRow key={6}>
            <TableCell component="th" scope="row">Barrio</TableCell>
            <TableCell align="left">{barrio}</TableCell>
          </TableRow>
          <TableRow key={7}>
            <TableCell component="th" scope="row">Direccion</TableCell>
            <TableCell align="left">{direccion}</TableCell>
          </TableRow>
          <TableRow key={8}>
            <TableCell component="th" scope="row">Departamento</TableCell>
            <TableCell align="left">{departamento}</TableCell>
          </TableRow>
          <TableRow key={9}>
            <TableCell component="th" scope="row">Ciudad</TableCell>
            <TableCell align="left">{ciudad}</TableCell>
          </TableRow>
          <TableRow key={10}>
            <TableCell component="th" scope="row">Comunidad</TableCell>
            <TableCell align="left">{comunidad}</TableCell>
          </TableRow>
          
          <TableRow key={11}>
            <TableCell component="th" scope="row">Genero</TableCell>
            <TableCell align="left">{genero}</TableCell>
          </TableRow>
          <TableRow key={12}>
            <TableCell component="th" scope="row">Discapacidad</TableCell>
            <TableCell align="left">{discapacidad}</TableCell>
          </TableRow>
          <TableRow key={12}>
            <TableCell component="th" scope="row">Area</TableCell>
            <TableCell align="left">{area}</TableCell>
          </TableRow>
          {programa !== '' && (
          <TableRow key={13}>
            <TableCell component="th" scope="row">Programa de Egreso</TableCell>
            <TableCell align="left">{programa}</TableCell>
          </TableRow>
          )}
          {anho !== '' && (
          <TableRow key={14}>
            <TableCell component="th" scope="row">Año de Egreso</TableCell>
            <TableCell align="left">{anho}</TableCell>
          </TableRow>
          )}
          <TableRow key={15}>
            <TableCell component="th" scope="row">Fecha Registro</TableCell>
            <TableCell align="left">{fecha_registro}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
        )
  }

  pintarDetalleHechos =() =>{
    let { hechos, pretensiones, tipo_consultoria } = this.state
    return (
      <Table>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row">Tipo Asesoría</TableCell>
            <TableCell align="left">{tipo_consultoria}</TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row">Hechos</TableCell>
            <TableCell align="left">{hechos}</TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row">Pretensiones</TableCell>
            <TableCell align="left">{pretensiones}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
        )
  }

  pintarDetalleAdjuntos =() =>{
    let { documentos } = this.state;
    let { proceso,administrador,usuario } = this.props
    let { estado_actual: { codigo }, permiso } = proceso;
  
    const acciones = (row) => {

      if(administrador || (codigo == "Est_Consul_Reg" && usuario.perfil.codigo === "Per_Consul_Est")){

        let detalle = (
          <a href={`${api}${row.archivo}`} target="_blank" rel="noopener noreferrer" className="sin_decorador">
            <BtnDetalle callback={() => { }} color="#01579b" texto="Abrir" />
          </a>
        );

        let eliminar = (
          <BtnEliminar
                  callback={() => {
                      this.modificarInformacion(row)
                  }}
          />
        );

        return <div>{detalle}{eliminar}</div>;
      }else{
        let detalle = (
          <a href={`${api}${row.archivo}`} target="_blank" rel="noopener noreferrer" className="sin_decorador">
            <BtnDetalle callback={() => { }} color="#01579b" texto="Abrir" />
          </a>
        );
        return <div>{detalle}</div>;
      }
      
    };

    return (
        <ListarDatos
        datos={documentos}
        cargando={this.state.cargando}
        id="tbl_adjuntos_consultoria"
        avatar={({ requisito: { nombre } }) => nombre.charAt(0)}
        acciones={(data) => acciones(data)}
        color_avatar={() => ({ backgroundColor: '#f5f5f5', color: '#757575' })}
        fila_principal={({ requisito: { nombre } }) => nombre}
        filas={[
          {
            id: 'nombre_archivo',
            mostrar: ({ nombre_archivo }) => nombre_archivo,
            nombre: 'Documento : '
          },
          {
            id: 'fecha_registro',
            mostrar: ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
            nombre: 'Fecha Registro : '
          }
        ]}
      />
    )
  }


  pintarDetalleEstado = () => {
    let { estados } = this.state
    const obtenerColor = (estado) => {
      let color = 'white';
      const colores = {
        'Est_Consul_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_Consul_Pro': { 'backgroundColor': '#009688', color },
        'Est_Consul_Fin': { 'backgroundColor': '#0d47a1', color },
        'Est_Consul_Rech': { 'backgroundColor': '#b71c1c', color },
        'Est_Consul_Asig': { 'backgroundColor': '#9315b8', color },
        'Est_Consul_Hech': { 'backgroundColor': '#n76c1c', color },
        'Est_Consul_Reg': { 'backgroundColor': '#d34c1c', color },
        'Est_Consul_CentroC': { 'backgroundColor': '#d34c1c', color },
        'Est_Consul_Centro_Nega': { 'backgroundColor': '#b71c1c', color },
        'Est_Consul_Subsa': { 'backgroundColor': '#009933', color },
        'Est_Consul_Correg':{'backgroundColor': '#25F803', color },
        'Tod_Consul': { 'backgroundColor': '#0d47a1', color },
        }
        return (colores[estado])
      }
  
      return (
        <ListarDatos
          datos={estados}
          titulo=''
          id='tbl_estados_consultoria'
          avatar={({ tipo_estado }) => tipo_estado.nombre.toUpperCase().charAt(0)}
          color_avatar={({ tipo_estado }) => obtenerColor(tipo_estado.codigo)}
          fila_principal={({ tipo_estado }) => tipo_estado.nombre}
          filas={[
            { 'id': 'fecha', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha: ' },
            { 'id': 'usuario', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
            { 'id': 'mensaje', 'mostrar': ({ mensaje }) => mensaje },
            { 'id': 'observacion', 'mostrar': ({ observaciones }) => observaciones },
  
          ]}
        />
      )
  }


  pintar = () => {
    let { cargando, seleccion} = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <>
        {seleccion === 'detalle' && this.pintarDetalle()}
        {seleccion === 'hechos' && this.pintarDetalleHechos()}
        {seleccion === 'adjunto' && this.pintarDetalleAdjuntos()}
        {seleccion === 'estado' && this.pintarDetalleEstado()}
      </>
    )

  }


  render() {
    let { modalDetalleConsultoria, mostrarModalDetalleConsultoria} = this.props
    let { seleccion,documentos } = this.state;
    return (
      <>
         
         <Dialog fullWidth={true} maxWidth="sm" open={modalDetalleConsultoria} onClose={() => mostrarModalDetalleConsultoria(false)}>
         <AppBarModal titulo={" ¡ Detalle Solicitud ! "} mostrarModal={() => mostrarModalDetalleConsultoria(false)} titulo_accion="Cerrar" accion={() => mostrarModalDetalleConsultoria(false)}/>
         {documentos.length == 0 ?
          <ConsultoriaMenuDuplicar sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
         :<ConsultoriaMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
         }
         <DialogContent className='scroll'>
            <Grid container>
              <Grid item xs={12}  md={12}> 
                {this.pintar()}
              </Grid>
           </Grid>
         </DialogContent>
         <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleConsultoria(false)} />
        </DialogActions>
       </Dialog > 
      </>
    )
    }
  
}

DetalleConsultoria.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
  modalDetalleConsultoria: PropTypes.bool.isRequired,
}



export default DetalleConsultoria