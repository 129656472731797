import React, { Component, useEffect } from 'react';
import { Typography, Grid, Fab, Link, Box } from '@material-ui/core'
import imagen from '../../../global/imagenes/personas/users.png'
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { api, coloresEmma } from '../../../global/js/funciones';
import { Document, Image, Page, PDFDownloadLink, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';


//css
import '../../../global/css/stylePortal.css'

//components
// import ListarDatos from '../../general/ListarDatos'
import DetalleExp from './DetalleExp'
import DetalleAca from './DetalleAca'
import DetalleLogros from './DetalleLogros'
import DetalleHab from './DetalleHab'

import emma_s1 from '../../../global/imagenes/emma_s1.png';
import TareasTerminadas from "../../general/TareasTerminadas";


const useStyles = makeStyles((theme) => ({
    // appBar: {
    //   top: 'auto',
    //   bottom: 0,
    //   backgroundColor: '#f5f5f5',
    //   zIndex: 1,
    //   border: '1px solid #eeeeee ',
    // },
    fabButton: {
        position: 'fixed',
        left: "45%",
        zIndex: 1000,
        top: '80%',
        [theme.breakpoints.up('sm')]: {
            top: '85%',
            left: "50%",
        },
    },
}));

const styles = StyleSheet.create({
  body: {
    margin: 28,
    flexGrow: 1,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row'
  }, 
  titulo_nombre:{
    color: coloresEmma.secondarycolor,
    fontSize: '20px',
    textAlign: 'center'
  },
  titulos: {
    backgroundColor: coloresEmma.secondarycolor,
    color: '#FFF',
    padding: '2px',
    textAlign: 'center',
    margin: '10px 0px',
    fontSize: '11px'
  },
  Tprincipal: {
    marginTop: '5px'
  },
  Tprincipal2:{
    color: 'gray',
    fontSize: '11px',
    textAlign: 'right',
    paddingTop: '12px'
  },  
  Tsecundario: {
    fontSize: '12px',
    color: coloresEmma.secondarycolor,
    marginTop: '5px'
  },
  Ttexto: {
    fontSize: '11px',
    marginTop: '5px',
    textAlign: 'justify'
  },
  TextosInfo: {
    fontSize: '11px',
    marginTop: '3px',
    textAlign: 'center'
  },
  THabilidades:{
    marginTop: '5px',
    fontSize: '13px',
    paddingLeft: '5px'
  },
  bloque: {
    marginTop: '15px'
  },
  nivelesSi: {
    backgroundColor: coloresEmma.secondarycolor,
    color: '#FDFEFE',
    textAlign: 'center',
    width: '18px',
    fontSize: '12px',
    borderRadius: '2px',
    padding: '2px'
  },
  nivelesNo: {
    color: '#FDFEFE',
    textAlign: 'center',
    width: '18px',
    fontSize: '12px',
    border: '2px solid #F5EBB6',
    borderRadius: '2px'
  },
  foto: {
    display: 'grid',
    justify: 'center',
    self: 'center',
    width: '100px',
    height: '100px',
    border: '50px',
    padding: '2px',
    borderRight: `50px solid ${coloresEmma.secondarycolor}`,
    borderLeft: `50px solid ${coloresEmma.secondarycolor}`,
    borderTop: `50px solid ${coloresEmma.secondarycolor}`,
    borderBottom: `50px solid ${coloresEmma.secondarycolor}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px'
  }
});


function generarPDF(personal, habilidad, experiencia, academica, logros, fotoPerfil) {
  let personales = habilidad.filter(i => i.tipo === "personal")
  let laboral = habilidad.filter(i => i.tipo === "laboral")

  const niveles = (nivel) => {
    switch (nivel) {
      case "5":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>4</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>5</Text></View>
          </View>
        )
      case "4":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>4</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "3":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>3</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "2":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>2</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
      case "1":
        return (
          <View style={styles.row}>
            <View style={{ width: '20%' }}><Text style={styles.nivelesSi}>1</Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
            <View style={{ width: '20%' }}><Text style={styles.nivelesNo}> </Text></View>
          </View>
        )
    }
  }

  return (
    <Document>
      <Page size="A4">
        <View style={styles.body}>
          <View style={styles.row}>
            <View style={{ width: '40%', paddingRight: '15px' }}>
              <Image style={styles.foto} src={fotoPerfil} alt="images" />

              <Text style={[styles.TextosInfo, { fontWeight: 'bold' }]}>{`${personal.persona ? personal.persona.primer_nombre : ""} ${personal.persona ? personal.persona.primer_apellido : ""} ${personal.persona ? personal.persona.segundo_apellido : ""}`}</Text>
              <Text style={styles.TextosInfo}>{personal ? personal.telefono : ""}</Text>
              <Text style={styles.TextosInfo}>{personal ? personal.email : ""}</Text>
              <Text style={[styles.TextosInfo, { color: coloresEmma.secondarycolor }]}>{`Linkedin de ${personal.persona ? personal.persona.primer_nombre : ""} ${personal.persona ? personal.persona.primer_apellido : ""}`}</Text>

              <Text style={styles.titulos}>SOBRE MI</Text>
              <Text style={styles.Ttexto}>{personal ? personal.perfil : ""}</Text>

              <Text style={styles.titulos}>HABILIDADES</Text>
              <Text style={styles.Tprincipal}>LABORALES</Text>
              {laboral.map(e => (
                <View style={[styles.row, { marginTop: '7px' }]}>
                  <View style={{ width: '50%' }}><Text style={styles.THabilidades}>{e.descripcion}</Text></View>
                  <View style={{ width: '50%' }}>{niveles(e.nivel)}</View>
                </View>
              ))}
              <Text style={styles.Tprincipal}>PERSONALES</Text>
              {personales.map(e => (
                <View style={[styles.row, { marginTop: '7px' }]}>
                  <View style={{ width: '50%' }}><Text style={styles.THabilidades}>{e.descripcion}</Text></View>
                  <View style={{ width: '50%' }}>{niveles(e.nivel)}
                  </View>
                </View>
              ))}
            </View>
            <View style={{ width: '60%', paddingLeft: '15px' }}>
              <Text style={styles.titulo_nombre}>{`${personal.persona ? personal.persona.primer_nombre : ""} ${personal.persona ? personal.persona.primer_apellido : ""} ${personal.persona ? personal.persona.segundo_apellido : ""}`}</Text>

              <Text style={styles.titulos}>EXPERIENCIA</Text>
              {
                experiencia.map(e => (
                  e.empresa ?
                    <View style={styles.bloque}>
                      <View style={styles.row}>
                        <View style={{ width: '50%' }}>
                          <Text style={styles.Tprincipal}>{e.cargo}</Text>
                        </View>
                        <View style={{ width: '50%' }}>
                          <Text style={styles.Tprincipal2}>{`(${e.fecha_inicio_exp.substring(0, 4)} - ${e.en_curso_lab == '1' ? 'Presente' : e.fecha_final_exp.substring(0, 4)})`}</Text>
                        </View>
                      </View>
                      <Text style={styles.Tsecundario}>{e.empresa}</Text>
                      <Text style={styles.Ttexto}>{e.responsabilidades}</Text>
                    </View>
                    :
                    <View style={styles.bloque}>
                      <Text style={styles.Tprincipal}>No cuento con experiencia laboral</Text>
                    </View>
                ))
              }

              <Text style={styles.titulos}>EDUCACIÓN</Text>
              {
                academica.map(e => (
                  <View style={styles.bloque}>
                    <Text style={styles.Tprincipal}>{e.profesion ? `${e.profesion.toUpperCase()} - ${e.titulo}` : ''}</Text>
                    <Text style={styles.Tsecundario}>{e.universidad}</Text>
                    <Text style={styles.Ttexto}>{`(${e.fecha_inicio_est.substring(0, 4)} - ${e.en_curso_aca == '1' ? 'Presente' : e.fecha_final_est.substring(0, 4)})`}</Text>
                  </View>
                ))
              }

              <Text style={styles.titulos}>DISTINCIONES Y LOGROS</Text>
              {
                logros.map(e => (
                  <View style={styles.bloque}>
                    <Text style={styles.Tprincipal}>{e.descripcion.toUpperCase()}</Text>
                  </View>
                ))
              }
            </View>
          </View>
        </View>
      </Page>
    </Document>

  )

}

function BtnGenerarImg({ obtenerImagenDesdeUrl }) {

  const handleClick = async () => {
    await obtenerImagenDesdeUrl(); 
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 'auto' }}>
      <Fab
        color="secondary"
        aria-label="add"
        onClick={handleClick} 
      >
        <GetAppIcon />
      </Fab>
    </Box>
  );
}

function BtnDescargar({ personal, habilidad, experiencia, academica, logros, base64Image, reset }) {
  useEffect(() => {
    const generarYDescargarPDF = async () => {
      if (base64Image) {
        const doc = generarPDF(personal, habilidad, experiencia, academica, logros, base64Image);
        const pdfBlob = await pdf(doc).toBlob();
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Hoja_de_vida.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        reset();
      }
    };

    generarYDescargarPDF();
  }, [base64Image, personal, habilidad, experiencia, academica, logros]);

  return null;
}

function BtnActualizar({ abrirOpcionesPerfil }) {
    let classes = useStyles();
    return (
        <Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={() => abrirOpcionesPerfil()}>
            <EditIcon />
        </Fab>
    )
}

class DetallePerfil extends Component {

  constructor(props) {
    super(props);
    this.state = {
      base64Image: "",
      cargando: false,
      imagenLista: false,

    };
  }

  obtenerImagenDesdeUrl = async (intentos = 5, delay = 2000) => {
    const { personal } = this.props;
    if (personal.resp[0].archivo == null) {
      this.setState({ base64Image: imagen, cargando: false, imagenLista: true });
    } else {
      const archivoUrl = api + personal.resp[0].archivo;
      const proxyUrl = 'https://api.allorigins.win/get?url=';
      const targetUrl = encodeURIComponent(archivoUrl);
      const finalUrl = proxyUrl + targetUrl;
      this.setState({ cargando: true });
      for (let intento = 1; intento <= intentos; intento++) {

        try {
          const response = await fetch(finalUrl);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
          const data = await response.json();
          this.setState({ base64Image: data.contents, cargando: false, imagenLista: true });
        } catch (error) {
          console.error("Error al obtener la imagen desde la URL:", error);
          console.error(`Intento ${intento} fallido: `, error);
          if (intento < intentos) {
            await new Promise(res => setTimeout(res, delay));
          } else {
            console.error("Error al obtener la imagen después de varios intentos.");
            throw error;
          }
          this.setState({ cargando: false });
        }

      }


    }
  };

  reset = () => { 
    this.setState({ base64Image: "", cargando: false, imagenLista: false });
   }

  render() {
    let { abrirOpcionesPerfil, logros, experiencia, academica, habilidad, personal } = this.props
    let { base64Image, cargando, imagenLista } = this.state;
    return (
      <>
        {cargando ? <TareasTerminadas mensaje='Descargando hoja de vida...' marginTop='3%' imagen={emma_s1} widthImg="8%" cargando={true} /> :
          <>
            <Grid container spacing={2} style={{ padding: "0px" }}>
              <BtnGenerarImg obtenerImagenDesdeUrl={this.obtenerImagenDesdeUrl} />
            </Grid>
            <Grid container spacing={2} style={{ padding: "20px 40px 160px" }}>
              {experiencia.resp.length == 0 && academica.resp.length == 0 && habilidad.resp.length == 0 && personal.resp.length == 0 ?
                <TareasTerminadas mensaje='Aún no ha diligenciado la información de su perfil.' marginTop='3%' imagen={emma_s1} widthImg="8%" /> :
                <>
                  <Grid item xs={12} md={4} className="informacion_principal">
                    {
                      personal.resp[0] != null && personal.resp[0].archivo ?
                        <img src={api + personal.resp[0].archivo} className="imagen_perfil"></img> :
                        <img src={imagen} className="imagen_perfil"></img>
                    }

                    <br></br>
                    {
                      (personal.resp[0]) ?
                        <>
                          <Grid>
                            <Typography variant="h6">
                              {`${personal.resp[0].persona.primer_nombre} ${personal.resp[0].persona.primer_apellido} ${personal.resp[0].persona.segundo_apellido}`}
                            </Typography>
                            <Typography className="telefono_and_email">
                              {personal.resp[0].telefono}
                            </Typography>
                            <Typography className="telefono_and_email">
                              {personal.resp[0].email}
                            </Typography>
                            {
                              personal.resp[0].linkedin !== "" && personal.resp[0].linkedin !== "null" &&
                              <Typography className="telefono_and_email">
                                <Link href={` ${personal.resp[0].linkedin} `} target="_blank">
                                  {`Linkedin de ${personal.resp[0].persona.primer_nombre} ${personal.resp[0].persona.primer_apellido}`}
                                </Link>
                              </Typography>
                            }
                            <br></br>
                          </Grid>
                          <Typography className="ttitulos">
                            SOBRE MI
                          </Typography>
                          <br></br>
                          <div>
                            <Typography component="p" className='__textJustify'>
                              {personal.resp[0].perfil}
                            </Typography>
                          </div>
                        </>
                        : <div className="__sindatos"> Sin Datos </div>
                    }
                    <br></br>
                    <Typography className="ttitulos">
                      HABILIDADES
                    </Typography>
                    <br></br>
                    <DetalleHab
                      data={habilidad.resp}
                    />
                  </Grid>

                  <Grid item xs={12} md={8} className="informacion_secuandaria">
                    <Grid item xs={12} md={12}>
                      {
                        (personal.resp[0]) ?
                          <Typography variant="h3" className="nombre_principal">
                            {`${personal.resp[0].persona.primer_nombre} ${personal.resp[0].persona.primer_apellido} ${personal.resp[0].persona.segundo_apellido}`}
                          </Typography>
                          : <div className="__sindatos"> Sin Datos </div>
                      }
                      <br></br>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography className="ttitulos">
                        EXPERIENCIA
                      </Typography>
                      {
                        (experiencia.resp) ?
                          Object.keys(experiencia.resp).map(i => (
                            <DetalleExp
                              key={i}
                              detalle={experiencia.resp[i]}
                            />
                          ))
                          : <div className="__sindatos"> Sin Datos </div>
                      }
                      <Typography className="ttitulos">
                        EDUCACIÓN
                      </Typography>
                      {
                        (academica.resp) ?
                          Object.keys(academica.resp).map(i => (
                            <DetalleAca
                              key={i}
                              detalle={academica.resp[i]}
                            />
                          ))
                          : <div className="__sindatos"> Sin Datos </div>
                      }
                      <Typography className="ttitulos">
                        DISTINCIONES Y LOGROS
                      </Typography>
                      {
                        (logros) ?
                          Object.keys(logros).map(i => (
                            <DetalleLogros
                              key={i}
                              logros={logros[i]}
                            />
                          ))
                          : <div className="__sindatos"> Sin Datos </div>
                      }
                    </Grid>
                  </Grid>
                </>
              }
              <BtnActualizar
                abrirOpcionesPerfil={abrirOpcionesPerfil}
              />
            </Grid>
          </>
        }
        {
          imagenLista && (
            <BtnDescargar
              personal={personal.resp[0] ? personal.resp[0] : {}}
              logros={logros ? logros : {}}
              experiencia={experiencia.resp ? experiencia.resp : {}}
              habilidad={habilidad.resp ? habilidad.resp : {}}
              academica={academica.resp ? academica.resp : {}}
              base64Image={base64Image}
              reset={this.reset}
            />
          )
        }
      </>
    );
  }
}

export default DetallePerfil;
