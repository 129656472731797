
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PracticasAgregar from './PracticasAgregar';
import PracticasModalidad from './PracticasModalidad';

class PracticasModalidades extends Component {

  render() {
    let {
      modalAddPractica,
      programas,
      actualizarModalidadPractica,
      mostrarModalAddPracticas,
      actualizarRequisitosModalidad,
      modalidad,
      requisitos,
      modalidades,
      api,
      guardarPractica,
      tipo,
      lg,
      actualizarMensaje,
      telefono,
      celular,
      correo,
      opcionesPraCli,
      lugar_expedicion,
      fecha_nacimiento,
      fecha_expedicion,
      generos
    } = this.props;
    return (
      <div>
        <PracticasAgregar
          actualizarMensaje={actualizarMensaje}
          programas={programas}
          telefono={telefono}
          celular={celular}
          correo={correo}
          lugar_expedicion={lugar_expedicion}
          fecha_nacimiento={fecha_nacimiento}
          fecha_expedicion={fecha_expedicion}
          api={api}
          mostrarModalAddPracticas={mostrarModalAddPracticas}
          modalAddPractica={modalAddPractica}
          requisitos={requisitos}
          modalidad={modalidad}
          modalidades={modalidades}
          actualizarModalidadPractica={actualizarModalidadPractica}
          actualizarRequisitosModalidad={actualizarRequisitosModalidad}
          guardarPractica={guardarPractica}
          opcionesPraCli={opcionesPraCli}
          generos={generos}
          />
        <Grid container direction={'row'} style={{ padding: "0% 5% 0 5%" }} justify={'center'} alignContent='center'>
          {modalidades.map((item, key) => {
            if (item.valora === tipo) {
              return (
                <Grid item xs={12} sm={6} md={4} lg={lg} style={{ padding: "5px 5px 5px 5px" }} key={key}>
                  <PracticasModalidad
                    mod={item}
                    actualizarModalidadPractica={actualizarModalidadPractica}
                    mostrarModalAddPracticas={mostrarModalAddPracticas}
                    modalAddPractica={modalAddPractica}
                    actualizarRequisitosModalidad={actualizarRequisitosModalidad}
                    modalidad={modalidad}
                    programas={programas}
                  />
                </Grid>
              )
            } else return '';
          })
          }
        </Grid>
      </div>
    )
  }
}

//Props del componente
PracticasModalidades.propTypes = {
  //variables
  modalAddPractica: PropTypes.bool.isRequired,
  tipo: PropTypes.string.isRequired,
  modalidades: PropTypes.array.isRequired,
  modalidad: PropTypes.object.isRequired,
  requisitos: PropTypes.array.isRequired,
  //funciones
  actualizarModalidadPractica: PropTypes.func.isRequired,
  actualizarRequisitosModalidad: PropTypes.func.isRequired,
  mostrarModalAddPracticas: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
}

export default PracticasModalidades;



