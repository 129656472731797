import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
// Componetes
import { BtnForm } from "../general/BotonesAccion";
import AppBarModal from '../general/AppBarModal'
import Adjuntos from "./adjuntos/Adjuntos";
import TareasTerminadas from "../general/TareasTerminadas"
//imagenes
import emma_w from '../../global/imagenes/emma_w.png'

class GradosAdjuntos extends Component {


  constructor(props) {
    super(props)
    this.state = {

    }
  }

//   btnAccionNuevo = () => {
//     if (Object.entries(this.props.solicitud).length > 0) {
//         let { admin, solicitud, estado, showModalAdjuntos, showModalNewAdjuntos } = this.props;
//         // let { codigo, valora } = estado //Codigo del estado seleccionado de la solicitudy
//         // let { codigo: codigo_s } = solicitud.estado_actual //Codigo del estado Actual de la solicitud

//         if (!admin) {
//             return (
//                 <AppBarModal titulo={'Adjuntos Solicitud'} mostrarModal={() => showModalAdjuntos(false)} titulo_accion="Nuevo" accion={() => showModalNewAdjuntos(true)}/>
//             )
//         } else {
//             return (
//                 <AppBarModal titulo={'Adjuntos Solicitud'} titulo_accion="" mostrarModal={() => showModalAdjuntos(false)} />
//             )
//         }
//     }
// }

  render() {

    let { admin, adj_faltantes, getAdjFaltantes, modalAdjuntos, showModalAdjuntos, mostrarModalAdjFaltantes } = this.props;
    return (
      <React.Fragment>
        <Dialog open={modalAdjuntos} onClose={() => showModalAdjuntos(false)} fullWidth={true} fullScreen={window.innerWidth < 600} maxWidth='sm' aria-labelledby="max-width-dialog-title">
          {/* {this.btnAccionNuevo()} */}
          <AppBarModal titulo={'Adjuntos Solicitud'} mostrarModal={() => showModalAdjuntos(false)} titulo_accion={"pendientes"} accion={adj_faltantes.length > 0 ? () => mostrarModalAdjFaltantes(true): ""}/>
          <DialogContent style={{ padding: '2% 0% 0% 0%' }} className='scroll'>
            <Adjuntos admin={admin} getAdjFaltantes={getAdjFaltantes} accionesActivar={true}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => showModalAdjuntos(false)} color="primary">
              Cerrar
    				</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );


  }
}

//Props del componente
GradosAdjuntos.propTypes = {
  //variables
  modalAdjuntos: PropTypes.bool.isRequired,
  solicitud: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  showModalAdjuntos: PropTypes.func.isRequired,
  getAdjFaltantes: PropTypes.func.isRequired,
}

GradosAdjuntos.defaultProps = {
  adj_faltantes: [],
  getAdjFaltantes: () => { },
};


export default GradosAdjuntos;
