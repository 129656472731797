import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PracticasAgregarActa from './PracticasAgregarActa';


const useStyles = makeStyles(theme => ({
  contentModulo: {
    width: "100%",
    backgroundColor: 'white',
    flexGrow: 1,
    height: '100vh',
  },
  logo_emma: {
    minWidth: '200px',
    width: "10%",
  },
  div_logo_cuc: {
    width: "100%",
    textAlign: "center"
  },

}));

function Pintar({ id_requisito }) {
  const classes = useStyles();
  return (

    <div className={classes.contentModulo}>
      <PracticasAgregarActa id_requisito={id_requisito} tipo={2} />
    </div>


  );
}

export default class PracticasAgregarActaAlt extends Component {
  render() {
    const { match: { params } } = this.props;
    return <Pintar id_requisito={params.id_requisito} />
  }
}

