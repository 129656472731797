const MODAL_RESPUESTAS = 'MODAL_RESPUESTAS'
const MODAL_FILTRO = 'MODAL_FILTRO'
const MODAL_GESTION = 'MODAL_GESTION'
const MODAL_AGREGAR = 'MODAL_AGREGAR'

const mostrarModalRespuestas = show => {
  return {
    type: MODAL_RESPUESTAS,
    show,
  }
}

const mostrarModalFiltrosAutodiagnostico = show => {
  return {
    type: MODAL_FILTRO,
    show,
  }
}

const mostrarGestionAutodiag = show => {
  return {
    type: MODAL_GESTION,
    show,
  }
}

const mostrarAgregarAutodiag = show => {
  return {
    type: MODAL_AGREGAR,
    show,
  }
}

module.exports = {
  MODAL_RESPUESTAS,
  MODAL_FILTRO,
  MODAL_GESTION,
  MODAL_AGREGAR,
  mostrarModalRespuestas,
  mostrarModalFiltrosAutodiagnostico,
  mostrarGestionAutodiag,
  mostrarAgregarAutodiag
}