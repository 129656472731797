import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm, BtnSeleccion, BtnCancelar } from '../../general/BotonesAccion';
import { showModalProgramas } from '../../../redux/actions/actMatriculas';
import ListarDatos from '../../general/ListarDatos';
import { obtenerValoresPermisoPersonaTipo, Toast } from '../../../global/js/funciones';
import { PROGRAMAS, asignarPrograma, desasignarPrograma } from './helper';

const ModalProgramas = ({ persona }) => {
	const filtros = [
		{ llave: 'generica', valor: PROGRAMAS },
		{ llave: 'valorc', valor: 'pregrado' },
		{ llave: 'estado', valor: 1 }
	];
	const tipo = 11;

	const [ programas, setProgramas ] = useState([]);
	const [ cargando, setCargando ] = useState(true);

	const { modalProgramas } = useSelector((state) => state.redMatriculas);
	const dispatch = useDispatch();

	const handleCloseModal = () => {
		dispatch(showModalProgramas(false));
	};

	const acciones = ({ id: programa, permiso, nombre }) => {
		const handleAsignarPrograma = async () => {
			setCargando(true);
			await asignarPrograma(persona, programa);
			const programas = await obtenerValoresPermisoPersonaTipo(persona, tipo, filtros);
			Toast.fire({ icon: 'success', title: `Persona asignada a ${nombre}` });
			setProgramas(programas);
			setCargando(false);
		};

		const handleDesasignarPrograma = async () => {
			setCargando(true);
			await desasignarPrograma(persona, programa);
			const programas = await obtenerValoresPermisoPersonaTipo(persona, tipo, filtros);
			Toast.fire({ icon: 'success', title: `${nombre} desasignado` });
			setProgramas(programas);
			setCargando(false);
		};

		const btnAsignar = <BtnSeleccion callback={handleAsignarPrograma} color='#01579b' texto='Asignar' />;
		const btnDesasignar = <BtnCancelar callback={handleDesasignarPrograma} color='#900807' texto='Desasignar' />;

		return permiso ? btnDesasignar : btnAsignar;
	};

	useEffect(
		() => {
			if (modalProgramas) {
				obtenerValoresPermisoPersonaTipo(persona, tipo, filtros)
					.then((data) => {
						setProgramas(data);
						setCargando(false);
					})
					.catch((err) => {
						console.log(err);
						setCargando(false);
					});
			}
		},
		[ modalProgramas ]
	);

	return (
		<Dialog open={modalProgramas} fullWidth={true} maxWidth='sm' onClose={handleCloseModal}>
			<AppBarModal
				titulo='¡ Lista Programas !'
				mostrarModal={handleCloseModal}
				accion={handleCloseModal}
				titulo_accion='CERRAR'
			/>
			<DialogContent style={{ padding: 0 }} className='scroll'>
				<ListarDatos
					datos={programas}
					id='tbl_programas'
					avatar={({ nombre }) => nombre.charAt(0)}
					buscar={true}
					opciones={true}
					titulo='Lista programas pregrado'
					acciones={(data) => acciones(data)}
					fila_principal={({ nombre }) => nombre}
					filas={[]}
					cargando={cargando}
				/>
			</DialogContent>
			<DialogActions>
				<BtnForm texto='Cerrar' callback={handleCloseModal} />
			</DialogActions>
		</Dialog>
	);
};

export default ModalProgramas;
