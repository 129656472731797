import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { consulta } from './../../global/js/funciones';
import { obtenerMisDatos } from '../../global/js/funciones';
import { Dialog, DialogContent, DialogActions, TextField, FormControl, MenuItem, InputLabel, Checkbox, Select, StepLabel, Step, Stepper, Grid, Paper, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import AppBarModal from '../general/AppBarModal';
import ListarDatos from './../general/ListarDatos';
import { BtnForm } from './../general/BotonesAccion';
import { actualizarMensaje } from './../../redux/actions/actGlobal';
import AlertasSimple from '../general/AlertasSimple';
import BuscarSelect from './../general/BuscarSelect'
import emma_w from '../../global/imagenes/emma_w.png';
import TareasTerminadas from "../general/TareasTerminadas";

const steps = ['Datos iniciales', 'Grupo', 'Información'];

const API_URI = `api/v1.0/invitados/`;

class ModalNuevaConsulta extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cargando: true,
			activeStep: 0,
			programas: [],
			programa: null,
			celular: '',
			telefono: '',
			correo: '',
			asignaturas: [],
			asignatura: null,
			grupos: [],
			grupo: null,
			checked: [],
			checkedSubGrupos: [],
			abrirProgramas: false,
		}
	}

	async obtenerAsignaturas(programa) {
		return new Promise(resolve => {
			consulta(`${API_URI}asignaturas/programa/${programa}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			})
		})
	}

	async obtenerGrupos(asignatura) {
		return new Promise(resolve => {
			consulta(`${API_URI}grupos/asignatura/${asignatura}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			});
		});
	}

	componentDidMount() {
		this.getDatos();
		this.setState({ cargando: false });
	}

	getDatos = async () => {
		let { persona, programas } = await obtenerMisDatos();
		this.setState({
			telefono: persona.telefono,
			celular: persona.celular,
			correo: persona.correo_personal,
			programas: programas,
		});
	}

	configStepContent = () => {
		let { activeStep } = this.state;

		switch (activeStep) {
			case 0:
				return this.pintarDatosIniciales();

			case 1:
				return this.pintarGrupos();

			case 2:
				return this.pintarInformacion();

			default:
				break;
		}
	}

	pintarDatosIniciales = () => {
		let { cargando, telefono, celular, correo, programas, programa, asignaturas, asignatura, abrirProgramas } = this.state;

		if (!cargando) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField type='number' id='telefono' name='telefono' label='Telefono' value={telefono} onChange={this.onChange} required fullWidth />
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField type='number' id='celular' name='celular' label='Celular' value={celular} onChange={this.onChange} required fullWidth />
					</Grid>

					<Grid item xs={12}>
						<TextField type='email' id='correo' name='correo' label='Correo Personal' value={correo || ''} onChange={this.onChange} required fullWidth />
					</Grid>

					<Grid item xs={12}>
						<FormControl className="form-control">
							<InputLabel shrink required={true}>Programa</InputLabel>
							<Select open={abrirProgramas} onClose={() => this.setState({ abrirProgramas: false })} onOpen={() => this.setState({ abrirProgramas: true })} value={programa && programa.codigo} onChange={this.onChangeProgramas} labelid="demo-simple-select-placeholder-label-label" id="demo-simple-select-placeholder-label" >
								{programas.map(({ relacion: { codigo, nombre } }) => <MenuItem key={codigo} value={codigo} name={nombre}>{nombre}</MenuItem>)}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl className="form-control" style={{ marginBottom: '100px' }}>
							<BuscarSelect
								datos={asignaturas}
								cambiarEstado={this.onChangeAsignaturas}
								valor={asignatura}
								id='select-asignatura'
								nombre='Asignatura'
								placeholder='Seleccione una asignatura'
							/>
						</FormControl>
					</Grid>
				</Grid>
			)
		} else return <TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg='7%' />
	}

	handleToggle = (data) => {
		let { checked } = this.state;
		const currentIndex = checked.indexOf(data.codigo);

		if (currentIndex === -1) {
			this.setState({ checked: [data.codigo], grupo: data });
		} else {
			this.setState({ checked: [], grupo: null });
		}
	}

	pintarGrupos = () => {
		let { grupos, checked } = this.state;
		const acciones = (data) => {
			return (
				<Checkbox edge="end" onChange={() => this.handleToggle(data)} checked={checked.indexOf(data.codigo) !== -1} />
			)
		};

		return (
			<Grid container spacing={3} className='scroll'>
				<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
					<ListarDatos
						id='tbl_grupos'
						titulo='Lista de Grupos'
						datos={grupos}
						avatar={({ nombre }) => nombre.charAt(0)}
						fila_principal={({ nombre }) => nombre}
						filas={[
							{ 'mostrar': ({ profesor }) => `Profesor: ${profesor || 'sin asignar'}` },
							{
								'mostrar': ({ horario }) => (
									<div>
										{horario.map(({ dia, hora_inicio, hora_fin, magistral }) => (
											<p style={{ padding: 0, margin: 0 }}>
												{dia === null || hora_inicio === null || hora_fin === null ? 'Horario sin asignar' : `${dia} de ${hora_inicio} - ${hora_fin} ${magistral ? '- Magistral' : ''}`}
											</p>
										))}
									</div>
								)
							},
						]}
						acciones={(data) => acciones(data)}
						opciones={true}
						buscar={true}
						css={{}}
						mensajeVacio='La asignatura seleccionada no tiene grupos remotos o es una electiva. Si deseas asistir como invitado(a) a una asignatura electiva, su auxiliar académico lo guiará en este proceso, debe ponerse en contacto con él/ella'
					/>
				</Grid>
			</Grid>
		)
	}

	handleToggleSubGrupos = (data) => {
		let { checkedSubGrupos } = this.state;
		const currentIndex = checkedSubGrupos.indexOf(data);

		if (currentIndex === -1) {
			this.setState({ checkedSubGrupos: [data] });
		} else {
			this.setState({ checkedSubGrupos: [] });
		}
	}

	pintarInformacion = () => {
		let { asignatura, grupo, checkedSubGrupos } = this.state;
		let { condiciones } = this.props;

		const acciones = (data) => {
			return (
				<Checkbox edge="end" onChange={() => this.handleToggleSubGrupos(data)} checked={checkedSubGrupos.indexOf(data) !== -1} />
			)
		};
		return (
			<Fragment>
				{grupo.isMagistral ?
					<ListarDatos
						id='tbl_subgrupos'
						titulo='Lista Subgrupos Magistrales'
						datos={grupo.horario.filter(e => e.magistral === false)}
						avatar={({ sub_grupo }) => sub_grupo && sub_grupo.charAt(0)}
						fila_principal={({ sub_grupo }) => sub_grupo}
						filas={[
							{ 'mostrar': ({ profesor }) => profesor },
							{ 'mostrar': ({ dia, hora_inicio, hora_fin }) => `${dia} de ${hora_inicio} - ${hora_fin}` },
						]}
						acciones={(data) => acciones(data)}
						opciones={true}
						buscar={true}
						css={{}}
					/> :
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell align='center'>Asignatura</TableCell>
									<TableCell align='center'>Grupo</TableCell>
									<TableCell align='center'>Horario</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell align='center'>{asignatura.nombre}</TableCell>
									<TableCell align='center'>{grupo.nombre}</TableCell>
									<TableCell align='center'>
										{grupo.horario.map(({ dia, hora_inicio, hora_fin }) => (
											<TableRow>
												<TableCell align='center'>{dia === null || hora_inicio === null || hora_fin === null ? 'Horario sin asignar' : `${dia} de ${hora_inicio} - ${hora_fin}`}</TableCell>
											</TableRow>
										))}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				}
				<AlertasSimple
					tipo="info"
					titulo="Estimado estudiante, por favor tener en cuenta que:"
					lista={condiciones}
				/>
			</Fragment>
		)
	}

	handleStep = direction => {
		const { actualizarMensaje } = this.props;
		let { activeStep, telefono, celular, programa, asignatura, grupo } = this.state;

		switch (activeStep) {
			case 0:
				if (telefono && celular && programa && asignatura) this.setState({ activeStep: activeStep + 1 });
				else actualizarMensaje({ titulo: 'Debes diligenciar todos los campos para poder continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
				break;

			case 1:
				if (direction) {
					if (grupo) this.setState({ activeStep: activeStep + 1 });
					else actualizarMensaje({ titulo: 'Por favor elegir un grupo', tipo: 'info', mostrar: true, tiempo: 6000 });
				} else this.setState({ activeStep: activeStep - 1 });
				break;

			case 2:
				this.setState({ activeStep: activeStep - 1 });
				break;

			default:
				break;
		}
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		});
	}

	onChangeProgramas = async (e) => {
		this.setState({ cargando: true });
		const { actualizarMensaje } = this.props;
		let { error, estado, titulo, asignaturas } = await this.obtenerAsignaturas(e.target.value);

		if (!error && estado === 200) {
			this.setState({
				programa: { codigo: e.target.value, nombre: e.nativeEvent.srcElement.attributes.name.value },
				asignaturas,
				asignatura: null,
				grupos: null,
				grupo: null,
				checked: [],
				checkedSubGrupos: []
			});
		} else {
			actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
		}
		this.setState({ cargando: false });
	}

	onChangeAsignaturas = async (asignatura) => {
		this.setState({ cargando: true });
		const { actualizarMensaje } = this.props;
		let { error, estado, titulo, grupos } = await this.obtenerGrupos(asignatura.value);

		if (!error && estado === 200) this.setState({ asignatura, grupos, grupo: null, checked: [], checkedSubGrupos: [] });
		else actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
		this.setState({ cargando: false });
	}

	handleSubmit = () => {
		let { handleSubmitNuevaConsulta, actualizarMensaje } = this.props;
		let { asignatura, grupo, celular, telefono, correo, checkedSubGrupos } = this.state;

		if (grupo.isMagistral && checkedSubGrupos.length === 0) actualizarMensaje({ titulo: 'Por favor elige un subgrupo', mostrar: true, tipo: 'info', tiempo: 6000 })
		else {
			if (grupo.isMagistral) {
				let horario = grupo.horario.find(({ magistral }) => magistral);
				grupo.horario = [horario];
			}
			let datos = [
				{ codigo_asignatura: asignatura.value, asignatura: asignatura.label, grupo: grupo.nombre, profesor: grupo.id_profesor, horario: JSON.stringify(grupo.horario) }
			];

			if (grupo.isMagistral) {
				let horario = { dia: checkedSubGrupos[0].dia, hora_inicio: checkedSubGrupos[0].hora_inicio, hora_fin: checkedSubGrupos[0].hora_fin }
				datos.push({
					codigo_asignatura: asignatura.value, asignatura: asignatura.label, grupo: checkedSubGrupos[0].sub_grupo, profesor: checkedSubGrupos[0].id_profesor, horario: JSON.stringify([horario])
				});
			}
			handleSubmitNuevaConsulta(datos, celular, telefono, correo);
		}
	}

	render() {
		let { openModalNuevo, closeModalNuevo } = this.props;
		let { activeStep } = this.state;

		return (
			<Dialog open={openModalNuevo} onClose={closeModalNuevo} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
				<AppBarModal titulo={'Nueva Inscripción'} mostrarModal={closeModalNuevo} titulo_accion="" accion="" />
				<DialogContent className='scroll'>
					<Grid container >
						<Grid item xs={12} md={12}>
							<Stepper activeStep={activeStep}>
								{steps.map(label => (
									<Step key={label}>
										<StepLabel><p>{label}</p></StepLabel>
									</Step>
								))}
							</Stepper>
						</Grid>
						<Grid item xs={12} md={12}>
							{this.configStepContent()}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{
						activeStep === 0 ?
							<BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} /> :
							activeStep === 2 ?
								<div>
									<BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
									<BtnForm texto="GUARDAR" callback={this.handleSubmit} type="submit" />
								</div> :
								<div>
									<BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
									<BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} />
								</div>
					}
				</DialogActions>
			</Dialog>
		)
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	actualizarMensaje,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaConsulta);
