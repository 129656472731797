import {
  MODULO,
  ACT_AUTH,
  ACT_MENSAJE,
  ACT_DATA_USUARIO_AUTH,
  MODAL_CERRAR,
  ACTIVIDADES,
  PERFILES,
  MENSAJES,
  MOSTRAR_MODAL_MENSAJES,
  MOSTRAR_MODAL_ADJ_FALTANTES,
  CARGANDO_MENU,
  MOSTRAR_MODAL_NOTIFICACIONES,
  MODAL_REGISTRAR,
  MOSTRAR_MODAL_VIDEOS
} from "../actions/actGlobal";

const initialState = {
  modulo: {
    ruta: "/",
    nombre: "inicio",
  },
  auth: false,
  usuario: {
    primer_nombre: '',
    primer_apellido: '',
    correo: '',
  },
  mensaje: {
    mostrar: false,
    tipo: "none",
    titulo: "",
    tiempo: 6000,
    onScreen: false,
  },
  modalCerrar: false,
  actividades: [],
  perfiles: [],
  mensajes: [],
  modalMensajes: false,
  modalAdjFaltantes: false,
  cargandoMenu: false,
  modalNotificaciones: false,
  modalRegistrar: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODULO:
      return Object.assign({}, state, {
        modulo: action.modulo
      })
    case ACT_MENSAJE:
      return Object.assign({}, state, {
        mensaje: action.mensaje
      })
    case ACT_AUTH:
      return Object.assign({}, state, {
        auth: action.auth
      })
    case ACT_DATA_USUARIO_AUTH:
      return Object.assign({}, state, {
        usuario: action.usuario
      })
    case MODAL_CERRAR:
      return Object.assign({}, state, {
        modalCerrar: action.show
      })
    case ACTIVIDADES:
      return Object.assign({}, state, {
        actividades: action.actividades
      })
    case PERFILES:
      return Object.assign({}, state, {
        perfiles: action.perfiles
      })
    case MENSAJES:
      return Object.assign({}, state, {
        mensajes: action.mensajes
      })
    case MOSTRAR_MODAL_MENSAJES:
      return Object.assign({}, state, {
        modalMensajes: action.show
      })
    case MOSTRAR_MODAL_ADJ_FALTANTES:
      return Object.assign({}, state, {
        modalAdjFaltantes: action.show
      })
    case CARGANDO_MENU:
      return Object.assign({}, state, {
        cargandoMenu: action.value
      })
    case MOSTRAR_MODAL_NOTIFICACIONES:
      return Object.assign({}, state, {
        modalNotificaciones: action.show
      })
    case MODAL_REGISTRAR:
      return Object.assign({}, state, {
        modalRegistrar: action.show
      })
    case MOSTRAR_MODAL_VIDEOS:
      return Object.assign({}, state, {
        modalVideos: action.show
      })
    default:
      return state;
  }
}

export default reducer;
