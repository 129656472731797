import React, { useEffect, useState } from "react";
import moment from "moment";

// Images
import emma_inscripcion_inicio from "../../global/imagenes/emma_inscripcion_inicio.png";
import emma_inscripcion_cierre from "../../global/imagenes/emma_inscripcion_cierre.png";
import emma_s from "../../global/imagenes/emma_s1.png";
import cuc from "../../global/imagenes/logo_cuc.png";

// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// general components
import Copyright from "../general/app/Copyright";
import TareasTerminadas from "../general/TareasTerminadas";

// Global functions
import { obtenerValoresFiltros } from "../../global/js/funciones";
import MensajeContacto from "../general/MensajeContacto";


const useStyles = makeStyles((theme) => ({
  logo: {
    minWidth: "300px",
    width: "28%",
  },
  logo_texto: {
    minWidth: "250px",
    width: "20%",
  },
  div_logo: {
    padding: theme.spacing(8, 0, 0, 0),
    textAlign: "center",
  },
  button: {
    marginTop: theme.spacing(5),
    minWidth: "300px",
    width: "25%",
    borderRadius: "20px",
    color: "white",
  },
  logo_cuc: {
    minWidth: "240px",
    width: "20%",
  },
  div_logo_cuc: {
    textAlign: "center",
  },
  div_message: {
    zIndex: "1",
    position: "fixed",
    top: "0%",
    right: ".5%",
    left: "75%",
    fontSize: ".9em",
    lineHeight: "normal",
  },
  div_message2: {
    width: "90%",
    margin: "auto",
    marginBottom: "10px",
    fontSize: ".9em",
    lineHeight: "normal",
  },
}));

const InscripcionPosgradoInicio = ({ accion }) => {
  const classes = useStyles();
  const [cargando, setCargando] = useState(true);
  const [valido, setValido] = useState(true);
  const [linkProgramasVirtuales, setLinkProgramasVirtuales] = useState("");

  const transformarData = (data) => {
    return data.map((item) => ({
      title: item.nombre,
      link: item.valora,
    }));
  };

  const cargarLinkProgramasVirtuales = async () => {
    let link = await obtenerValoresFiltros([
      { llave: "codigo", valor: "Link_Pro_Vir" },
    ]);
    setLinkProgramasVirtuales(transformarData(link));
  };

  useEffect(() => {
    cargarLinkProgramasVirtuales();
    (async () => {
      let periodo = await obtenerValoresFiltros([
        { llave: "codigo", valor: "Per_Ins_Posg" },
      ]);
      let fecha_hoy = moment();
      let fecha_vence = moment(periodo[0].valora);
      let dias = fecha_vence.diff(fecha_hoy, "days");

      if (dias >= 0) {
        setValido(true);
      } else {
        setValido(false);
      }
      setCargando(false);
    })();
  }, []);

  /**
   * @param {string} classes - Clases de material ui
   * @returns {JSX}
   * @description Muestra la información de inicio de inscripción
   *
   */
  return (
    <div className="Container" >
      {cargando ? (
        <TareasTerminadas
          mensaje="Cargando.."
          marginTop="7%"
          imagen={emma_s}
          widthImg="7%"
          cargando={true}
        />
      ) : (
        <Grid container className={classes.div_logo}>
          {valido ? (
            <>
              <Grid item xs={12}>
                <img
                  src={emma_inscripcion_inicio}
                  alt="Logo Emma plataforma universidad de la costa"
                  className={classes.logo}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  aria-label="add"
                  className={classes.button}
                  onClick={accion}
                >
                  <TouchAppIcon />
                  <Hidden mdUp>Continuar</Hidden>
                  <Hidden smDown>Haz click para continuar</Hidden>
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <img
                src={emma_inscripcion_cierre}
                alt="Logo Emma plataforma universidad de la costa"
                className={classes.logo}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Box mt={2} />
      <div className={classes.div_logo_cuc}>
        <img
          src={cuc}
          alt="Logo unversidad de la costa CUC"
          className={classes.logo_cuc}
        />
      </div>
      <Box mt={2} />

      {!cargando && valido && (
        <React.Fragment>
          <Hidden smDown>
            <div className={classes.div_message}>
              <MensajeContacto
                title={"Información"}
                content={
                  "Para realizar la inscripción en programas virtuales de maestría o doctorado, ingrese al siguiente enlace."
                }
                enlaces={linkProgramasVirtuales}
                typeIcon={"duda"}
              />
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.div_message2}>
              <MensajeContacto
                title={"Información"}
                content={
                  "Para realizar la inscripción en programas virtuales de maestría o doctorado, ingrese al siguiente enlace."
                }
                enlaces={linkProgramasVirtuales}
                typeIcon={"duda"}
              />
            </div>
          </Hidden>
        </React.Fragment>
      )}
      <Copyright />
    </div>
  );
};

export default InscripcionPosgradoInicio;
