import React from 'react';
import { Typography } from '@material-ui/core';
import useWindowSize from '../../../general/hooks/useWindowSize';


const Banner = () => {
    const windowSize = useWindowSize();

    return (
        <div style={{
            margin: '10px 0 0 0 ',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 20px',
            backgroundColor: '#eaefef',
            borderRadius: '10px',
            width: windowSize < 600 ? 'auto' : '100%'
        }}>
            <Typography style={{ margin: '15px 0 0px 0' }} variant="h5" gutterBottom>Hola, Bienvenido !</Typography>
            <h3 style={{ margin: '10px 0 0 0' }} >Estas son las Salas Asignadas por el Administrador.</h3>
            <span style={{ margin: '5px 0' }}>En Este Apartado podras actualizar la capacidad de las Salas dando Click en Actualizar Capacidad.</span>
        </div>
    )
}

export default Banner