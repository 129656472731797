import React from 'react'
import { Grid, Paper, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

export default function TiposAtencion (props) {
  let {
    modal,
    datos,
    api
  } = props
  return (
    <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { modal(true) }}>
      <Paper elevation={8} square>
        <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
          <CardHeader
            avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>{datos.valora}</Avatar>}
            title={datos.nombre}
          />
          <CardContent >
            <Grid container direction={'row'} justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                <img src={`${api}${datos.archivo}`} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                    {datos.descripcion}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  )
}