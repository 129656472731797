import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AppBarModal from '../../general/AppBarModal'
import ListarDatos from '../../general/ListarDatos'
import { BtnCancelar, BtnForm, BtnSeleccion } from '../../general/BotonesAccion'
import TareasTerminadas from '../../general/TareasTerminadas'
import emma_w from '../../../global/imagenes/emma_w.png';

class AgregarValor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      dataSearch: [],
      dato_buscar: ''
    }
  }

  async componentDidMount() {
    let { listMode } = this.props;
    if (listMode) {
      this.setState({ cargando: true });
      let dataSearch = await this.props.obtenerDatos();
      this.setState({ cargando: false, dataSearch });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    let { obtenerDatos } = this.props
    let { dato_buscar } = this.state
    if (dato_buscar !== prevState.dato_buscar) {
      // this.setState({ cargando: true });
      let dataSearch = await this.props.obtenerDatos(dato_buscar);
      this.setState({  dataSearch });
    }
  }


  acciones = data => {
    let { seleccionarVarios, listaSeleccionados, listMode } = this.props;

    const renderBotones = (data) => {
      const seleccionado = listaSeleccionados.some(({ id }) => id == data.id);

      if (seleccionarVarios) {
        return !seleccionado
          ? <BtnSeleccion callback={() => onClickSeleccionar(data)} />
          : <BtnCancelar callback={() => onClickQuitar(data)} />;
      } else {
        if (listaSeleccionados.length > 0 && !seleccionado) return null
        return seleccionado
          ? <BtnCancelar callback={() => onClickQuitar(data)} />
          : <BtnSeleccion callback={() => onClickSeleccionar(data)} />;
      }
    };

    const onClickSeleccionar = (data) => {
      if (!seleccionarVarios) listaSeleccionados.forEach((seleccionado) => this.props.quitar(seleccionado))
      this.props.seleccionar(data);
    };

    const onClickQuitar = (data) => {
      this.props.quitar(data);
    };

    let resp = renderBotones(data);
    return resp;
  }

  render() {
    const { modalOpen, mostrarModal, nombreModal, datos, opciones, buscar, fila_principal, filas, listMode } = this.props
    const { cargando, dataSearch, dato_buscar } = this.state
    return (
      <Dialog open={modalOpen} onClose={mostrarModal.bind(this, false)} aria-labelledby='form-dialog-title' fullWidth={true} maxWidth='sm'>
        <AppBarModal titulo={`¡ Lista de ${nombreModal} !`} mostrarModal={mostrarModal} titulo_accion='' accion='' />
        <DialogContent className='scroll' style={{ padding: 0 }}>
          {
            dataSearch.length === 0 && datos.length === 0 && !cargando && 
            <TareasTerminadas mensaje = 'No se encontraron datos..' marginTop = '7%' imagen = { emma_w } widthImg = '7%' />
          }
          {
            cargando ? <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = '7%' /> :
              (dataSearch.length > 0 || datos.length > 0) &&
              <ListarDatos
              datos={listMode ? dataSearch : datos}
              titulo={`¡ Lista de ${nombreModal} !`}
              opciones={opciones}
              buscar={buscar}
              actBuscar={listMode ? (dato_buscar) => this.setState({ dato_buscar }): null}
              datoBuscarAlt={listMode ? dato_buscar : null} 
              sub_titulo='Rankings'
              id='tbl_rankings_seleccion'
              acciones={listMode ? null : this.acciones}
              fila_principal={fila_principal}
              filas={filas}
            />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto='CERRAR' callback={() => mostrarModal(false)} />
        </DialogActions>
      </Dialog>
    )
  }
}

AgregarValor.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  mostrarModal: PropTypes.func.isRequired,
  nombreModal: PropTypes.string.isRequired,
  datos: PropTypes.array.isRequired,
  opciones: PropTypes.bool.isRequired,
  buscar: PropTypes.bool.isRequired,
  fila_principal: PropTypes.string.isRequired,
  filas: PropTypes.array.isRequired,
  seleccionarVarios: PropTypes.bool,
  listaSeleccionados: PropTypes.array.isRequired,
}

AgregarValor.defaultProps = {
  filas: [],
  seleccionarVarios: false,
  listMode: false,
};

export default AgregarValor