import {
  MODAL_ADD_PRACTICA,
  MODAL_DEL_PRACTICA,
  MODAL_TEST_PRACTICAS,
  MODAL_DETALLE_PRACTICA,
  MODAL_ADD_ADJUNTO,
  MODAL_GES_PRACTICA,
  ACT_DATA_PRACTICAS,
  ACT_DATA_PRACTICA,
  ACT_RENDER_PRACTICAS,
  ACT_DATA_ADJUNTOS_PRACTICA,
  ACT_DATA_ADJUNTO_PRACTICA,
  ACT_DATA_ESTADO,
  MODAL_ADM_ADJUNTOS_PRACTICA,
  ACT_RENDER_ADJUNTOS_PRACTICAS,
  MODAL_MOD_PRACTICAS,
  ACT_DATA_MODALIDADES,
  ACT_MOD_PRACTICA,
  ACT_REQ_MODALIDAD,
  ACT_DATA_ESTADOS,
  MODAL_PROG_PART_PRACTICAS,
  MODAL_CAN_PRACTICA,
  ACT_DATA_EMPRESA,
  ACT_DATA_EMPRESAS,
  MODAL_ADD_EMPRESA,
  MODAL_DEL_EMPRESA,
  MODAL_MOD_EMPRESA,
  ACT_DATA_SUCURSALES,
  ACT_DATA_SUCURSAL,
  MODAL_ADD_SUCURSAL,
  MODAL_ADD_SUCURSAL_PRACTICA,
  MODAL_DEL_SUCURSAL,
  MODAL_MOD_SUCURSAL,
  MODAL_ADD_FILTROS_PRACTICA,
  MODAL_MOD_PERIODO_PRACTICA,
  MODAL_AYUDA,
  MODAL_ENVIAR_HV,
  MODAL_ADD_EMPRESA_PROCESO,
  MODAL_ADM_PERMISO_ESTADO,
  MODAL_ADM_PERMISO_MODALIDAD,
  MODAL_ADM_NOTIFICACIONES_PRACTICAS,
  MODAL_INI_ACTA,
  MODAL_DET_ADJUNTO,
  MODAL_ADM_BUSCAR_EMPRESA,
  MODAL_ADM_BUSCAR_EMPRESA_OTRA_VEZ,
  MODAL_ADD_FILTROS_HV,
  MODAL_VAL_REQUISITO,
  MODAL_EMPRESAS_HV,
  MODAL_INFORME,
  MODAL_ACCIONES_MASIVAS_PRACTICA,
  MODAL_MASIVO_FILTRO_PRACTICA,
  MODAL_FUISTE_CONTRATADO,
  MODAL_ADD_NOTA,
  MODAL_ADD_VALORES_SUCURSAL,
  MODAL_FILTRO_ID
} from "../actions/actPracticas";


const initialState = {
  modalPermisoModalidad: false,
  modalPermisoEstado: false,
  modalAprobarRequisito: false,
  modalAdmBuscarEmpresa: false,
  modalAdmBuscarEmpresaotravez: false,
  modalIniActa: false,
  modalDetalleAdjunto: false,
  modalPracticasNotificaciones: false,
  modalAddFiltros: false,
  modalMasivoFiltro: false,
  modalAddFiltrosHv: false,
  modalEnviarHV: false,
  modalAyuda: false,
  modalModPeriodo: false,
  modalAddEmpresaProceso: false,
  modalAddPractica: false,
  modalCanPractica: false,
  modalDelPractica: false,
  modalAdmAdjuntosPractica: false,
  modalProParticipante: false,
  modalModalidadPractica: false,
  modalTestPracticas: false,
  modalDetallePractica: false,
  modalAddAdjunto: false,
  modalAccionesMasivas: false,
  practicas: [],
  adjuntos: [],
  renderPracticas: false,
  modalGestionarPractica: false,
  renderAdjuntosPracticas: false,
  practica: {
    id: 0,
    persona: {},
  },
  adjunto: {
    id: 0,
  },
  estado: {
    id: 0,
  },
  modalidades: [],
  modalidad: {
    id: 0,
    codigo: '',
    nombre: ''
  },
  requisitos: [],
  estados: [],
  empresas: [],
  empresa: {
    id: 0,
  },
  modalAddEmpresa: false,
  modalDelEmpresa: false,
  modalModEmpresa: false,
  sucursales: [],
  sucursal: {
    id: 0,
  },
  modalAddSucursal: false,
  modalAddSucursalPractica: false,
  modalDelSucursal: false,
  modalModSucursal: false,
  modalEmpresasHv: false,
  modalInforme: false,
  modalprueba: false,
  modaladdnota:false,
  modalAddValoresSucursal:false,
  modalfiltroid:false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_PRACTICA:
      return Object.assign({}, state, {
        modalAddPractica: action.show
      })
    case MODAL_DEL_PRACTICA:
      return Object.assign({}, state, {
        modalDelPractica: action.show
      })
    case MODAL_MOD_PRACTICAS:
      return Object.assign({}, state, {
        modalModalidadPractica: action.show
      })
    case MODAL_TEST_PRACTICAS:
      return Object.assign({}, state, {
        modalTestPracticas: action.show
      })
    case MODAL_DETALLE_PRACTICA:
      return Object.assign({}, state, {
        modalDetallePractica: action.show
      })
    case MODAL_ADD_ADJUNTO:
      return Object.assign({}, state, {
        modalAddAdjunto: action.show
      })
    case ACT_DATA_PRACTICAS:
      return Object.assign({}, state, {
        practicas: action.practicas
      })
    case ACT_DATA_PRACTICA:
      return Object.assign({}, state, {
        practica: action.practica
      })
    case ACT_RENDER_PRACTICAS:
      return Object.assign({}, state, {
        renderPracticas: action.render
      })
    case ACT_DATA_ADJUNTO_PRACTICA:
      return Object.assign({}, state, {
        adjunto: action.adjunto
      })
    case ACT_DATA_ADJUNTOS_PRACTICA:
      return Object.assign({}, state, {
        adjuntos: action.adjuntos
      })
    case ACT_DATA_MODALIDADES:
      return Object.assign({}, state, {
        modalidades: action.modalidades
      })
    case MODAL_ADM_ADJUNTOS_PRACTICA:
      return Object.assign({}, state, {
        modalAdmAdjuntosPractica: action.show
      })
    case ACT_RENDER_ADJUNTOS_PRACTICAS:
      return Object.assign({}, state, {
        renderAdjuntosPracticas: action.render
      })
    case ACT_MOD_PRACTICA:
      return Object.assign({}, state, {
        modalidad: action.modalidad
      })
    case ACT_REQ_MODALIDAD:
      return Object.assign({}, state, {
        requisitos: action.requisitos
      })
    case MODAL_GES_PRACTICA:
      return Object.assign({}, state, {
        modalGestionarPractica: action.show
      })
    case ACT_DATA_ESTADO:
      return Object.assign({}, state, {
        estado: action.estado
      })
    case ACT_DATA_ESTADOS:
      return Object.assign({}, state, {
        estados: action.estados
      })
    case MODAL_PROG_PART_PRACTICAS:
      return Object.assign({}, state, {
        modalProParticipante: action.show
      })
    case MODAL_CAN_PRACTICA:
      return Object.assign({}, state, {
        modalCanPractica: action.show
      })
    case ACT_DATA_EMPRESA:
      return Object.assign({}, state, {
        empresa: action.empresa
      })
    case ACT_DATA_EMPRESAS:
      return Object.assign({}, state, {
        empresas: action.empresas
      })
    case MODAL_ADD_EMPRESA:
      return Object.assign({}, state, {
        modalAddEmpresa: action.show
      })
    case MODAL_MOD_EMPRESA:
      return Object.assign({}, state, {
        modalModEmpresa: action.show
      })
    case MODAL_DEL_EMPRESA:
      return Object.assign({}, state, {
        modalDelEmpresa: action.show
      })
    case ACT_DATA_SUCURSAL:
      return Object.assign({}, state, {
        sucursal: action.sucursal
      })
    case ACT_DATA_SUCURSALES:
      return Object.assign({}, state, {
        sucursales: action.sucursales
      })
    case MODAL_ADD_SUCURSAL:
      return Object.assign({}, state, {
        modalAddSucursal: action.show
      })
    case MODAL_ADD_SUCURSAL_PRACTICA:
      return Object.assign({}, state, {
        modalAddSucursalPractica: action.show
      })
     
    case MODAL_MOD_SUCURSAL:
      return Object.assign({}, state, {
        modalModSucursal: action.show
      })
    case MODAL_DEL_SUCURSAL:
      return Object.assign({}, state, {
        modalDelSucursal: action.show
      })
    case MODAL_ADD_FILTROS_PRACTICA:
      return Object.assign({}, state, {
        modalAddFiltros: action.show
      })
    case MODAL_MASIVO_FILTRO_PRACTICA: 
      return Object.assign({}, state, {
        modalMasivoFiltro: action.show
      })
    case MODAL_ADD_FILTROS_HV:
      return Object.assign({}, state, {
        modalAddFiltrosHv: action.show
      })
    case MODAL_MOD_PERIODO_PRACTICA:
      return Object.assign({}, state, {
        modalModPeriodo: action.show
      })
    case MODAL_AYUDA:
      return Object.assign({}, state, {
        modalAyuda: action.show
      })
    case MODAL_ENVIAR_HV:
      return Object.assign({}, state, {
        modalEnviarHV: action.show
      })
    case MODAL_ADD_EMPRESA_PROCESO:
      return Object.assign({}, state, {
        modalAddEmpresaProceso: action.show
      })

    case MODAL_ADM_PERMISO_ESTADO:
      return Object.assign({}, state, {
        modalPermisoEstado: action.show
      })
    case MODAL_ADM_PERMISO_MODALIDAD:
      return Object.assign({}, state, {
        modalPermisoModalidad: action.show
      })
    case MODAL_ADM_NOTIFICACIONES_PRACTICAS:
      return Object.assign({}, state, {
        modalPracticasNotificaciones: action.show
      })
    case MODAL_INI_ACTA:
      return Object.assign({}, state, {
        modalIniActa: action.show
      })
    case MODAL_DET_ADJUNTO:
      return Object.assign({}, state, {
        modalDetalleAdjunto: action.show
      })
    case MODAL_ADM_BUSCAR_EMPRESA:
      return Object.assign({}, state, {
        modalAdmBuscarEmpresa: action.show
      })
    case MODAL_ADM_BUSCAR_EMPRESA_OTRA_VEZ:
      return Object.assign({}, state, {
        modalAdmBuscarEmpresaotravez: action.show
      })
    case MODAL_VAL_REQUISITO:
      return Object.assign({}, state, {
        modalAprobarRequisito: action.show
      })
    case MODAL_EMPRESAS_HV:
      return Object.assign({}, state, {
        modalEmpresasHv: action.show
      })
    case MODAL_INFORME:
      return Object.assign({}, state, {
        modalInforme: action.show
      })
    case MODAL_ACCIONES_MASIVAS_PRACTICA:
      return Object.assign({}, state, {
        modalAccionesMasivas: action.show
      })
    case MODAL_ADD_NOTA:
      return Object.assign({}, state, {
        modaladdnota: action.show
      })
    case MODAL_FUISTE_CONTRATADO:
      return Object.assign({}, state, {
        modalprueba: action.show
      })
    case MODAL_ADD_VALORES_SUCURSAL:
      return Object.assign({}, state, {
        modalAddValoresSucursal: action.show
      })
    case MODAL_FILTRO_ID:
      return Object.assign({}, state, {
        modalfiltroid: action.show
      })
    default:
      return state;
  }
}

export default reducer;
