import React from 'react';
import { BottomNavigation, BottomNavigationAction, Hidden } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Procesos from '@material-ui/icons/ImportContacts';
import CreateIcon from '@material-ui/icons/Create';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoopIcon from '@material-ui/icons/Loop';
import SettingsIcon from '@material-ui/icons/Settings';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuAdministrar({ administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('Procesos', sele)} onClick={() => seleccionar('Procesos')}>
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          {
            administrador &&
            <BottomNavigation showLabels className={clase('Administrar', sele)} onClick={() => seleccionar('Administrar')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon className='menu_icono' />} />
            </BottomNavigation>
          }
        </div>
      )
      
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('Proceso', sele, '_app')} onClick={() => seleccionar('proceso')} />
            {
              administrador &&
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('Administrar', sele, '_app')} onClick={() => seleccionar('administrar')} />
            }
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function MenuEstudiante({ sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('proceso', sele)} onClick={() => seleccionar('proceso')}>
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('agregar', sele)} onClick={() => seleccionar('agregar')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
            <BottomNavigationAction label={'Historial'} icon={<Procesos />} />
          </BottomNavigation>
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('proceso', sele, '_app')} onClick={() => seleccionar('proceso')} />
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} className={clase('agregar', sele, '_app')} onClick={() => seleccionar('agregar')} />
            <BottomNavigationAction label={'Historial'} icon={<Procesos />} className={clase('historial', sele, '_app')} onClick={() => seleccionar('historial')} />
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function CreditoMenu({ sele = 0, seleccionar = () => { }, administrador,pagos, docs, tipo_proceso_actual  }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('credito', sele)} onClick={() => seleccionar('credito')} />
            { tipo_proceso_actual !== 'sol_conestfin' && !docs && <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('adjuntos', sele)} onClick={() => seleccionar('adjuntos')} />}
            { pagos && <BottomNavigationAction label={tipo_proceso_actual === 'sol_enti' ? 'Conceptos' : 'Pagos'} icon={<AttachMoneyIcon />} className={clase('pagos', sele)} onClick={() => seleccionar('pagos')} />}
            <BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
            { administrador && <BottomNavigationAction label={'Encuestas'} icon={<LiveHelpIcon />} className={clase('encuestas', sele)} onClick={() => seleccionar('encuestas')} />}
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}

export function MenuGestionar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Gestionar'} icon={<LoopIcon />} className={clase('gestion', sele)} onClick={() => seleccionar('gestion')} />
          <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('adjuntos', sele)} onClick={() => seleccionar('adjuntos')} />
        </BottomNavigation>
      </div>
    )

  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}
