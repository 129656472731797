import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Avatar, Grid, Typography, } from '@material-ui/core/';

import moment from 'moment'
import { coloresEmma } from '../../../global/js/funciones';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    minHeight: "50px",
    height: "300px",
  },
  image: {
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    height: "100px",
  },
  content_text: {
    padding: "0px 0px 0px 10px",
  },
  content_img: {
    textAlign: 'center',
    height: "150px",
  },

}));

export default function SolicitudesActivas({ fecha, descripcion, estado, nombre, imagen }) {
  let fecha_r = moment(fecha).format('YYYY-MM-DD, h:mm a');
  const classes = useStyles();

  const obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'STATE_CREATE_ATENCION': { 'backgroundColor': coloresEmma.filtrotodos, color  },
      'STATE_ASIGNADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudactiva, color },
      'STATE_GESTION_ATENCION': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
      'STATE_FINALIZADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
    }
    return (colores[codigo])
  }
  
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label="recipe" style={obtenerColor(estado.codigo)}> {estado.nombre.charAt(0).toUpperCase()} </Avatar>}
        subheader={fecha_r}
        title={nombre}
      />
      <CardContent>
        <Grid container direction={'row'} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
            <img src={imagen} className={classes.image} alt='Imagen Tipo' />
            <Typography variant="body2" color="textSecondary" component="p" align='justify'>
              {descripcion}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
