import React from 'react';
import PropTypes, { element } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReactStars from "react-rating-stars-component";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from "../../global/imagenes/emma_w.png";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(3),
        width: '60%',
    },
    containerBox: {
        display: 'flex',
        width: 'fit-content',
        height: '50px',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: '2px solid #999999',
        borderRadius: '6px',
        overflow: 'hidden',
        marginTop: '15px',
    },
    box: {
        display: 'flex',
        width: '80px',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #999999',
        background: '#CCCCCC',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(),
        width: '95%',
    },
});

class Respuestas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            target: null,
            listCkeckbox: [],
        };
    }

    handleTypeBox = (target, id) => {
        if (this.state.target) this.state.target.style.background = '#cccccc';
        target.target.style.background = '#666666';
        this.setState({ target: target.target })
        this.props.responder(this.props.index, id);
    }

    typeBox = (list, classes) => {
        const { index, listaRespuesta } = this.props
        const { respuesta } = listaRespuesta[index];
        const sortedList = list.sort((a, b) => a.id - b.id); // ordenando las respuestas numericas ascendente 
        return (
            <div className={classes.containerBox}>
                {
                    sortedList.map((element, index) => {
                        let responder = respuesta && respuesta == element.id;
                        return (
                            <div
                                style={responder ? { background: '#666666' } : null}
                                key={index}
                                className={classes.box}
                                onClick={(target) => this.handleTypeBox(target, element.id)}
                            >
                                {
                                    element.nombre
                                }
                            </div>
                        )
                    })
                }
            </div>
        )

    }

    ratingChanged = (newRating) => {
        this.props.responder(this.props.index, newRating);
    };

    typeStars() {
        const { index, listaRespuesta } = this.props
        const { respuesta_texto } = listaRespuesta[index];
        return (
            <ReactStars
                size={50}
                value={respuesta_texto ? respuesta_texto : 0}
                count={5}
                onChange={this.ratingChanged}
                activeColor="#ffd700"
                a11y={false}
                isHalf={true}
            />
        )
    }

    handleTypeCkeckbox = (accion, id) => {
        let { listCkeckbox } = this.state;
        if (accion) {
            listCkeckbox.push(id)
        } else {
            listCkeckbox = listCkeckbox.filter(auxid => auxid != id)
        }
        this.setState({ listCkeckbox });
        if (listCkeckbox.length == 0) listCkeckbox = null;
        this.props.responder(this.props.index, listCkeckbox);
    }

    typeCkeckbox = (list) => {
        const { index, listaRespuesta } = this.props
        const { respuesta } = listaRespuesta[index];
        const sortedList = list.sort((a, b) => a.id - b.id); // ordenando las respuestas numericas ascendente 
        return sortedList.map(
            (element, index) => {
                let checked = respuesta && respuesta.find(item => item == element.id);
                return (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                defaultChecked={checked}
                                onChange={(f, k) => this.handleTypeCkeckbox(k, element.id)}
                                value={element.id}
                            />
                        }
                        label={element.nombre}
                    />
                )
            }
        )
    }

    handleTypeInput = (target) => {
        let texto = target.target.value;
        texto = (texto == "") ? null : texto;
        this.props.responder(this.props.index, texto);
    }

    typeInput = (classes) => {
        const { index, listaRespuesta } = this.props
        const { respuesta_texto } = listaRespuesta[index];
        return <form className={classes.container} noValidate autoComplete="off">
            <TextField
                defaultValue={respuesta_texto}
                id="standard-name"
                className={classes.textField}
                onChange={this.handleTypeInput}
                margin="normal"
            />
        </form>
    }

    typeRadio = (respuestas) => {
        const { index, listaRespuesta } = this.props
        const { respuesta } = listaRespuesta[index];
        return respuestas.map((value, index) => {
            let checked = respuesta && respuesta == value.id;
            return <FormControlLabel
                checked={checked}
                onClick={() => this.props.responder(this.props.index, value.id)}
                key={index}
                value={value.nombre}
                control={<Radio />}
                label={value.nombre}
            />
        });
    }

    renderSwitch = (tipo, classes, respuestas) => {
        switch (tipo) {
            case 'tipo1':
                return this.typeBox(respuestas, classes);
            case 'tipo2':
                return this.typeCkeckbox(respuestas);
            case 'tipo3':
                return this.typeStars(respuestas, classes);
            case 'tipo4':
                return this.typeInput(classes);
            default:
                return this.typeRadio(respuestas);
        }
    }
    
    render() {
        const { classes, pregunta, respuestas, tipo, index, obligatoria } = this.props;
        
        return (
            !this.props.cargado ?
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">{pregunta}{`${obligatoria ? "*" : ""}`}</FormLabel>
                    <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        className={classes.group}
                        value={this.state.value}
                    >
                        {
                            this.renderSwitch(tipo, classes, respuestas, index)
                        }
                    </RadioGroup>
                </FormControl>
            : <TareasTerminadas mensaje="Cargando.." marginTop="7%" imagen={emma_w} widthImg="7%" />

        );
    }
}

Respuestas.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Respuestas);