import React from 'react';
import { BottomNavigation, BottomNavigationAction, Hidden } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import LoopIcon from '@material-ui/icons/Loop';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SchoolIcon from '@material-ui/icons/School';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';


const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuAdministrar({ administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('proceso', sele)} onClick={() => seleccionar('proceso')}>
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} />
          </BottomNavigation>
          {
            administrador &&
            <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon className='menu_icono' />} />
            </BottomNavigation>
          }
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
            <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('proceso', sele, '_app')} onClick={() => seleccionar('proceso')} />
            {
              administrador &&
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('administrar', sele, '_app')} onClick={() => seleccionar('administrar')} />
            }
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function MenuGestionar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Gestionar'} icon={<LoopIcon />} className={clase('gestion', sele)} onClick={() => seleccionar('gestion')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}

export function InscripcionMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('inscripcion', sele)} onClick={() => seleccionar('inscripcion')} />
          <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', sele)} onClick={() => seleccionar('documentos')} />
          <BottomNavigationAction label={'Contacto'} icon={<PersonPinIcon />} className={clase('contacto', sele)} onClick={() => seleccionar('contacto')} />
          <BottomNavigationAction label={'Carrera'} icon={<SchoolIcon />} className={clase('carrera', sele)} onClick={() => seleccionar('carrera')} />
          <BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}

export function InscripcionMenuPreUniversitario({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('inscripcion', sele)} onClick={() => seleccionar('inscripcion')} />
          <BottomNavigationAction label={'Contacto'} icon={<PersonPinIcon />} className={clase('contacto', sele)} onClick={() => seleccionar('contacto')} />
          <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', sele)} onClick={() => seleccionar('documentos')} />
          <BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}

export function InscripcionMenuEditar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Datos'} icon={<ImportContactsIcon />} className={clase('Tipo_Ins', sele)} onClick={() => seleccionar('Tipo_Ins')} />
          <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', sele)} onClick={() => seleccionar('documentos')} />
          <BottomNavigationAction label={'Contacto'} icon={<PersonPinIcon />} className={clase('contacto', sele)} onClick={() => seleccionar('contacto')} />
          <BottomNavigationAction label={'Carrera'} icon={<SchoolIcon />} className={clase('carrera', sele)} onClick={() => seleccionar('carrera')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}

export function InscripcionMenuEditarPreUniversitario({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Datos'} icon={<ImportContactsIcon />} className={clase('Tipo_PreUni', sele)} onClick={() => seleccionar('Tipo_PreUni')} />
          <BottomNavigationAction label={'Contacto'} icon={<ImportContactsIcon />} className={clase('Contacto', sele)} onClick={() => seleccionar('Contacto')} />
          <BottomNavigationAction label={'Verificado'} icon={<PersonPinIcon />} className={clase('verificado', sele)} onClick={() => seleccionar('verificado')} />
          <BottomNavigationAction label={'Documentos'} icon={<MenuBookIcon />} className={clase('documentos', sele)} onClick={() => seleccionar('documentos')} />
          <BottomNavigationAction label={'Gestion Para Matricula'} icon={<SchoolIcon />} className={clase('gestion_matricula', sele)} onClick={() => seleccionar('gestion_matricula')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}