import {
  MODAL_ADD_CANDIDATO,
  MODAL_DEL_CANDIDATO,
  ACT_DATA_CANDIDATOS,
  ACT_DATA_CANDIDATO,
  ACT_RENDER_CANDIDATOS,
  MODAL_ADD_CANDIDATO_FIN,
} from "../actions/actCandidatos";


const initialState = {
  modalAddCandidatos: false,
  modalDelCandidatos: false,
  modalAddCandidatosFin: false,
  candidatos: [],
  renderCandidatos: false,
  candidato: {
    id: 0,
    nombre: "",
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_CANDIDATO:
      return Object.assign({}, state, {
        modalAddCandidatos: action.show
      })
    case MODAL_DEL_CANDIDATO:
      return Object.assign({}, state, {
        modalDelCandidatos: action.show
      })
    case ACT_DATA_CANDIDATOS:
      return Object.assign({}, state, {
        candidatos: action.candidatos
      })
    case ACT_DATA_CANDIDATO:
      return Object.assign({}, state, {
        candidato: action.candidato
      })
    case ACT_RENDER_CANDIDATOS:
      return Object.assign({}, state, {
        renderCandidatos: action.render
      })
    case MODAL_ADD_CANDIDATO_FIN:
      return Object.assign({}, state, {
        modalAddCandidatosFin: action.show
      })
    default:
      return state;
  }
}

export default reducer;
