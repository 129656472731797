import {
  MODAL_ADD_BECA,
  MODAL_VALIDACION,
  MODAL_COMMENT,
  MODAL_ADJUNTOS,
  MODAL_DET_BECA,
  MODAL_GES_BECA,
  MODAL_VOLANTE,
  MODAL_FILTRO_BECA,
  MODAL_ADD_ADJBECA,
  MODAL_HABILITAR_ESTUDIANTE
} from "../actions/actBecas"

const initialState = {
  modalAddBeca: false,
  modalValidacion: false,
  modalAddComment: false,
  modalAdjuntos: false,
  modalDetBeca: false,
  modalGestionBeca: false,
  modalVolante : false,
  modalFiltro : false,
  modalAddAdj : false,
  modalHabiliEstu : false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_BECA:
      return Object.assign({}, state, {
        modalAddBeca: action.show
      })
    case MODAL_VALIDACION:
      return Object.assign({}, state, {
        modalValidacion: action.show
      })
    case MODAL_COMMENT:
      return Object.assign({}, state, {
        modalAddComment: action.show
      })
    case MODAL_ADJUNTOS:
      return Object.assign({}, state, {
        modalAdjuntos: action.show
      })
    case MODAL_DET_BECA:
      return Object.assign({}, state, {
        modalDetBeca: action.show
      })
    case MODAL_GES_BECA:
      return Object.assign({}, state, {
        modalGestionBeca: action.show
      })
    case MODAL_VOLANTE:
      return Object.assign({}, state, {
        modalVolante: action.show
      })
    case MODAL_FILTRO_BECA:
      return Object.assign({}, state, {
        modalFiltro: action.show
      })
    case MODAL_ADD_ADJBECA:
      return Object.assign({}, state, {
        modalAddAdj: action.show
      })
    case MODAL_HABILITAR_ESTUDIANTE:
      return Object.assign({}, state, {
        modalHabiliEstu: action.show
      }) 
    default:
      return state;
  }
}

export default reducer;
