import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { BtnForm } from "./BotonesAccion";
import AppBarModal from './AppBarModal'

class ConfirmarAccion extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: 0,
    }
  }

  componentDidUpdate({ modalConAccion }) {
    let { modalConAccion: modalConNew, dato } = this.props;
    if (modalConNew !== modalConAccion) {
      this.changeState(dato);
    }
  }

  changeState = ({ id }) => {
    this.setState({
      id,
    })
  }

  onSubmit = e => {
    let { id } = this.state;
    this.props.ejecutarAccion(id);
    e.preventDefault();
  }

  render() {
    let { modalConAccion, mostrarModalConfAccion, titulo, mensaje1, mensaje2 } = this.props;
    return (
      <Dialog open={modalConAccion} onClose={mostrarModalConfAccion.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={titulo} mostrarModal={mostrarModalConfAccion} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <DialogContentText id="alert-dialog-description">
            {mensaje1} {mensaje2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalConfAccion(false)} />
          <BtnForm texto="ACEPTAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
ConfirmarAccion.propTypes = {
  //variables
  modalConAccion: PropTypes.bool.isRequired,
  titulo: PropTypes.string,
  dato: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  //funciones
  mostrarModalConfAccion: PropTypes.func.isRequired,
  ejecutarAccion: PropTypes.func.isRequired,
}

ConfirmarAccion.defaultProps = {
  titulo: 'Eliminar Dato ?',
  mensaje1: 'Tener en cuenta que no podrá revertir esta acción.',
  mensaje2: 'Si desea continuar presione la opción de "Aceptar".'
};

export default ConfirmarAccion;
