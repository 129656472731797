import React from 'react';
import { BottomNavigation, BottomNavigationAction, Hidden,Tab} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CreateIcon from '@material-ui/icons/Create';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import LoopIcon from '@material-ui/icons/Loop';
import Historial from '@material-ui/icons/ImportContacts'
import DescriptionIcon from '@material-ui/icons/Description';
import DocInformativos from './DocInformativos';


const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuAdministrar({doc_informativos,administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('proceso', sele)} onClick={() => seleccionar('proceso')}>
            <BottomNavigationAction label={'Procesos'} icon={<LibraryBooks />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('nuevo', sele)} onClick={() => seleccionar('nuevo')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
          </BottomNavigation>
          <BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
            <BottomNavigationAction label={'Historial'} icon={<Historial />} />
          </BottomNavigation>
          <DocInformativos
            Documentos={doc_informativos}
            rendering={(click) => <BottomNavigation showLabels onClick={click} className={clase('documentos', sele)} ><BottomNavigationAction label={'Documentos'} icon={<DescriptionIcon />} /> </BottomNavigation>}
          />
          {
            administrador &&
            <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon className='menu_icono' />} />
            </BottomNavigation>
          }
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
          <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('proceso', sele, '_app')} onClick={() => seleccionar('proceso')} />
          <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} className={clase('nuevo', sele, '_app')} onClick={() => seleccionar('nuevo')} />
          <BottomNavigationAction label={'Historial'} icon={<Historial/>} className={clase('historial', sele, '_app')} onClick={() => seleccionar('historial')} />
          <DocInformativos
              Documentos={doc_informativos}
              rendering = { (click) => <Tab label='Documentos' icon={<DescriptionIcon className='menu_icono' />} className={clase('documentos', sele, '_app')} value='documentos' onClick = { click } /> }
            />
            {
              administrador &&
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('administrar', sele, '_app')} onClick={() => seleccionar('administrar')} />
            }
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}



export function ConsultoriaMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} />
          <BottomNavigationAction label={'Hechos'} icon={<PersonPinIcon/>} className={clase('hechos', sele)} onClick={() => seleccionar('hechos')} />
          <BottomNavigationAction label={'Adjuntos'} icon={<MenuBookIcon />} className={clase('adjunto', sele)} onClick={() => seleccionar('adjunto')} />
          <BottomNavigationAction label={'Estado'} icon={<LoopIcon />} className={clase('estado', sele)} onClick={() => seleccionar('estado')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}

export function ConsultoriaMenuDuplicar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} />
          <BottomNavigationAction label={'Hechos'} icon={<PersonPinIcon/>} className={clase('hechos', sele)} onClick={() => seleccionar('hechos')} />
          <BottomNavigationAction label={'Estado'} icon={<LoopIcon />} className={clase('estado', sele)} onClick={() => seleccionar('estado')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}

export function MenuGestionar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Gestionar'} icon={<LoopIcon />} className={clase('gestion', sele)} onClick={() => seleccionar('gestion')} />
          <BottomNavigationAction label={'Adjuntos'} icon={<MenuBookIcon/>} className={clase('adjuntos', sele)} onClick={() => seleccionar('adjuntos')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}


export function MenuGestionarEstudiante({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Adjuntos'} icon={<MenuBookIcon/>} className={clase('adjuntos', sele)} onClick={() => seleccionar('adjuntos')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}

