export const DIPLOMADOS = 30;
export const POSGRADOS = 29;
export const CURSOS_LIBRES = 31;
export const ESTADOS_ID = 33;
export const PROCESOS = 34;
export const MATERIAS = 32;
export const PROGRAMAS = 5;
export const CURSOS_LIBRES_PRE = 46;
export const NOTAS = 47;
export const SEMI_DEPARTAMENTOS = 144;
export const SEMI_PROGRAMAS = 5;
export const SEMI_LINEA = 160;
export const SEMI_SUBLINEA = 161;
export const SEMI_GRUPOS_INVES = 162;
export const SEMI_AREAS_INVES = 163;
export const SEMI_ROLES = 164;
export const SOLICITUDES_INVESTIGACION = 194;
export const ESTADO_INVESTIGACION = 166;
export const SOLICITUDES_PAR_INVES = 167;
export const GESTION_TIPOS_INVESTIGACION = 168;
export const MODALIDAD_PAR_INVESTIGACION = 171;
export const AMBITO_INVESTIGACION = 172;
export const CATEGORIA_INVESTIGACION = 173;
export const TIPOS_DE_PROD_BIBLIO_INVES = 174;
export const INDEXACION_INVESTIGACION = 175;
export const TIPO_DE_ARTICULO_INVESTIGACION = 176;
export const TIPO_DE_LIBRO_INVESTIGACION = 177;
export const TIPO_DE_CAPITULO_LIBRO_INVESTIGACION = 178;
export const TIPOS_DE_PROD_TEC_INVES = 179;
export const INPUTS_INVES_TIPO_TEC = 181;
export const TIPOS_DE_PATENTE_INVESTIGACION = 182;
export const DISEÑO_INDUSTRIAL_PROTOTIPO_TIENE = 183;
export const TIPOS_DE_PROTOTIPOS_INVESTIGACION = 184;
export const TIPOS_DE_SOFTWARE_INVESTIGACION = 185;
export const TIPO_SIGNO_DISTINTIVO_INVESTIGACION = 186;
export const TIPO_DE_EMPRESA_INVESTIGACION = 187;
export const TIPO_INNOVACION_EMP_INVESTIGACION = 188;
export const TIPO_ESPACIO_EVENTO_INVESTIGACION = 189;
export const TIPO_DISCIPLINA_AMBITO_INVESTIGACION = 190;
export const TIPO_AMBITO_INVESTIGACION = 191;
export const TIPO_ROL_INVESTIGACION = 192;
export const TIPO_EVENTO_INVESTIGACION = 193;
export const TIPO_INFORME_SEMILLEROS = 201;
export const PAISES = 195;
export const IDIOMAS = 169;
// local
// export const DIPLOMADOS = 30;
// export const POSGRADOS = 29;
// export const CURSOS_LIBRES = 31;
// export const ESTADOS_ID = 33;
// export const PROCESOS = 34;
// export const MATERIAS = 32;
// export const PROGRAMAS = 5;
// export const CURSOS_LIBRES_PRE = 46;
// export const NOTAS = 47;
// export const SEMI_DEPARTAMENTOS = 117;
// export const SEMI_PROGRAMAS = 5;
// export const SEMI_LINEA = 119;
// export const SEMI_SUBLINEA = 120;
// export const SEMI_GRUPOS_INVES = 121;
// export const SEMI_AREAS_INVES = 122;
// export const SEMI_ROLES = 123;
// export const SOLICITUDES_INVESTIGACION = 153;
// export const ESTADO_INVESTIGACION = 125;
// export const SOLICITUDES_PAR_INVES = 126;
// export const GESTION_TIPOS_INVESTIGACION = 127;
// export const MODALIDAD_PAR_INVESTIGACION = 130;
// export const AMBITO_INVESTIGACION = 131;
// export const CATEGORIA_INVESTIGACION = 132;
// export const TIPOS_DE_PROD_BIBLIO_INVES = 133;
// export const INDEXACION_INVESTIGACION = 134;
// export const TIPO_DE_ARTICULO_INVESTIGACION = 135;
// export const TIPO_DE_LIBRO_INVESTIGACION = 136;
// export const TIPO_DE_CAPITULO_LIBRO_INVESTIGACION = 137;
// export const TIPOS_DE_PROD_TEC_INVES = 138;
// export const INPUTS_INVES_TIPO_TEC = 140;
// export const TIPOS_DE_PATENTE_INVESTIGACION = 141;
// export const DISEÑO_INDUSTRIAL_PROTOTIPO_TIENE = 142;
// export const TIPOS_DE_PROTOTIPOS_INVESTIGACION = 143;
// export const TIPOS_DE_SOFTWARE_INVESTIGACION = 144;
// export const TIPO_SIGNO_DISTINTIVO_INVESTIGACION = 145;
// export const TIPO_DE_EMPRESA_INVESTIGACION = 146;
// export const TIPO_INNOVACION_EMP_INVESTIGACION = 147;
// export const TIPO_ESPACIO_EVENTO_INVESTIGACION = 148;
// export const TIPO_DISCIPLINA_AMBITO_INVESTIGACION = 149;
// export const TIPO_AMBITO_INVESTIGACION = 150;
// export const TIPO_ROL_INVESTIGACION = 151;
// export const TIPO_EVENTO_INVESTIGACION = 152;
// export const TIPO_INFORME_SEMILLEROS = 2118;
// export const PAISES = 154;
// export const IDIOMAS = 128;
// export const REQUISITO_CURSOS = 4125