
import React, { Component } from 'react';
import { connect } from "react-redux";
import { formulario, consulta, mostrarError, obtenerValoresFiltros, generarFiltros, crear_form_data } from "../../global/js/funciones";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { mostrarModalGestionarCredito, mostrarModalAddCredito, actualizarDataCredito, mostrarModalDetalleCredito, mostrarModalAdjuntoCredito, mostrarModalAddFiltrosCredito } from "../../redux/actions/actCreditos";
import { MenuAdministrar } from "./creditos/CreditoMenu";
import Credito from "./creditos/Credito";
import CreditoPermisos from "./creditos/CreditoPermisos";
import CreditosGestionar from "./creditos/CreditosGestionar";
import CreditoFiltrar from "./creditos/CreditoFiltrar";
import TareasTerminadas from "../general/TareasTerminadas";
import ListarDatos from "../general/ListarDatos";
import { BtnDetalle, BtnGestionar } from "../general/BotonesAccion";
import emma_w from '../../global/imagenes/emma_w.png';
import moment from 'moment'
import { BtnRegresar } from "../general/BotonesAccion";
import { Paper, Grid, Tooltip, IconButton, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import AppBarModal from '../general/AppBarModal'
import emma_s1 from '../../global/imagenes/emma_s1.png';
import { BtnForm } from "../general/BotonesAccion";
class CreditosAdministrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      accionProceso: 'inicio',
      cargando: true,
      administrador: false,
      filtros: [],
      creditos: [],
      periodo: '',
      credito: null,
      tipo_proceso_actual: '',
      estado_actual_credito: '',
      accion: 'Procesos',
      becas: [],
      beca: null,
      volante: '',
      modalAddFiltrosCredito: false,
      vistaDetalle : 'detalle',
      id_estudiante : 0,
      estados_procesos: [],
      cargarFiltroP : true,
      periodo_filtro : null,
      periodos:[],
      filtrarPeriodo : false,
      cargandoPeriodo: true,
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/gestion_procesos_financieros', nombre: 'Procesos Financieros' })
    this.obtenerDatosIniciales();
    this.esAdministrador();
  }


  async componentDidUpdate({ }, { accion, accionProceso }) {
    let { accion: accionNew, periodo, enNotificacion: enNotificacionNew, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
    if (accion !== accionNew) {
      if (accionNew === 'Procesos') this.obtenerEstadoSolicitudes(periodo);
    }
    
    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew != 'Tod_Cre') {
        this.cambiarEstado({ cargando: true })
        if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
        else this.actualizarDataCreditos([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
      }
    }
  }

  obtenerEstadoSolicitudes = (periodo) => {
    consulta(`api/v1.0/creditos/estados/${periodo}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte);
          })
        }
        this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden ), cargando: false });
      }
    })
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_cred_n1")) {
      this.setState({ administrador: true })
    }
  }

  gestionarCredito = async (id, data, callback) => {
    let { mostrarModalGestionarCredito, actualizarMensaje } = this.props;
    if (typeof data.motivo === "object" && data.motivo !== null) {
      data.motivo = data.motivo.id;
  }

  if (typeof data.respuesta === "object" && data.respuesta !== null) {
    data.respuesta = JSON.stringify(data.respuesta);
}

    let form = await crear_form_data(data);
    data.archivos.map((file, index) => form.append(`archivo${index}`, file));
    formulario(`api/v1.0/creditos/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalGestionarCredito(false);
          this.actualizarDataCreditos(this.state.filtros);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }

  cargarArchivos(id, archivos, callback = () => { }) {
    if (archivos.length === 0) callback();
    else {
      const data = new FormData();
      data.append(`total`, archivos.length);
      archivos.map((file, index) => data.append(`archivo${index}`, file));
      formulario(`api/v1.0/creditos/${id}/adjuntar`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo; 
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        this.props.actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        callback();
      })
    }
  }

  async actualizarDataCreditos(filtros = [{ 'llave': 'estado_actual__valord', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    this.setState({ cargando: true })
    let creditos = await this.obtenerCreditos(filtros);
    // this.props.mostrarModalAddFiltrosCredito(false);
    this.setState({ creditos, cargando: false, filtros })
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Cre' }]);
    this.obtenerEstadoSolicitudes(periodo[0].nombre);
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtro: periodo.length > 0 ? periodo[0].nombre : ''});
  }

  async obtenerCreditos(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/creditos/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  pintarProcesos() {
    let { creditos, credito } = this.state;
    let { mostrarModalDetalleCredito, mostrarModalGestionarCredito, modalGestionarCredito, actualizarMensaje, mostrarModalAddFiltrosCredito } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Cre_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Cre_Rev': { 'backgroundColor': '#ffc107', color },
        'Cre_Rec': { 'backgroundColor': '#b71c1c', color },
        'Cre_Car': { 'backgroundColor': '#009688', color },
        'Cre_Val': { 'backgroundColor': '#0d47a1', color },
        'Cre_Pen': { 'backgroundColor': '#02A6F2', color },
        'Cre_Ege': { 'backgroundColor': '#e65100', color },
        'Cre_Pag': { 'backgroundColor': '#6200ea', color },
        'Cre_Ges': { 'backgroundColor': '#bdbdbd', color },
        'Cre_Pfac': { 'backgroundColor': '#F5951B', color },
        'Cre_Rech': { 'backgroundColor': '#b71c1c', color },
        'Cre_Afac': { 'backgroundColor': '#1B8FF5', color },
        'Cre_ProRea': { 'backgroundColor': '#06df3e', color }
      }
      return (colores[codigo])

    }

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso } = data;
      const onClickDetalle = credito => {
        this.setState({ credito, tipo_proceso_actual : credito.tipo.codigo, estado_actual_credito: credito.estado_actual.codigo })
        mostrarModalDetalleCredito(true)
      }

      const onClickGestionar = credito => {
        this.setState({ credito })
        mostrarModalGestionarCredito(true)
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      let gestionar = (codigo !== 'Cre_Rec' && codigo !== 'Cre_Car' && codigo !== 'Cre_Rech' && codigo !== 'Cre_Fvic' && codigo !== 'Cre_ProRea' && permiso === '1') && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
      return <div>{detalle}{gestionar}</div>;
    }
    return (
      <div>
        <CreditosGestionar actualizarMensaje={actualizarMensaje} credito={credito} mostrarModalGestionarCredito={mostrarModalGestionarCredito} modalGestionarCredito={modalGestionarCredito} gestionarCredito={this.gestionarCredito} />
        <ListarDatos
          datos={creditos}
          titulo='Lista de Procesos Financieros'
          id='tbl_historial_credito'
          opciones={true}
          descargar='CRÉDITOS - EMMA'
          filtrar={true}
          refrescar={true}
          actfiltrar={() => mostrarModalAddFiltrosCredito(true)}
          actRefrescar={() => this.actualizarDataCreditos(this.state.filtros)}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ estado_actual }) => estado_actual.valora}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false, nombre: 'Estudiante' },
            { 'id': 'contacto', 'mostrar': ({ usuario_registro: { celular, telefono } }) => `${celular} - ${telefono}`, 'enLista': false },
            { 'id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
            { 'id': 'tipo_solicitud', 'mostrar': ({ tipo }) => tipo.nombre },
            { 'id': 'entidad', 'mostrar': ({ entidad, categoria }) => entidad && entidad.codigo != 'Ent_Def' && entidad.codigo != 'Ent_Na' ? `${entidad.nombre} ${categoria === '1' ? '- Nuevo' : categoria === '2' ? '- Renovación' : ''}`.toUpperCase() : ''},
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            { 'id': 'estado_actual', 'mostrar': ({ estado_actual, gestor_nombre }) => `${estado_actual.nombre} ${gestor_nombre ? `${` - ${gestor_nombre}`}` : ''}` },
            { 'id': 'gestor', 'mostrar': ({ gestor_nombre }) => gestor_nombre, enLista: false },
            { 'id': 'fecha_finalizado', 'mostrar': ({ fecha_limite }) => fecha_limite ? moment(fecha_limite).format('YYYY-MM-DD') : '', enLista: false },
            { 'id': 'Calificación', 'mostrar': ({ experiencia }) => experiencia, enLista: false },
            { 'id': 'Aspectos a mejorar', 'mostrar': ({ aspectos_a_mejorar }) => aspectos_a_mejorar, enLista: false },
            { 'id': 'gestor', 'mostrar': ({ comentario }) => comentario, enLista: false },
          ]}
        />
      </div>
    )
  }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      if(!(periodos.find(e => e.periodo == periodo))) periodos.push({'periodo': periodo})
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/creditos/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  pintarInicioProcesos = () => {
    let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo } = this.state;
    let { mostrarModalAddFiltrosCredito } = this.props
    
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Tod_Cre': { 'backgroundColor': '#01579b', color },
        'Cre_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Cre_Rev': { 'backgroundColor': '#ffc107', color },
        'Cre_Rec': { 'backgroundColor': '#b71c1c', color },
        'Cre_Car': { 'backgroundColor': '#009688', color },
        'Cre_Val': { 'backgroundColor': '#0d47a1', color },
        'Cre_Pen': { 'backgroundColor': '#02A6F2', color },
        'Cre_Ege': { 'backgroundColor': '#e65100', color },
        'Cre_Pag': { 'backgroundColor': '#6200ea', color },
        'Cre_Ges': { 'backgroundColor': '#bdbdbd', color },
        'Cre_Pfac': { 'backgroundColor': '#F5951B', color },
        'Cre_Rech': { 'backgroundColor': '#b71c1c', color },
        'Cre_Afac': { 'backgroundColor': '#1B8FF5', color },
        'Cre_ProRea': { 'backgroundColor': '#06df3e', color }
      }

      if(!(colores[codigo])) return ({ 'backgroundColor': '#f5f5f5', 'color': '#757575' })
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      if (codigo != 'Tod_Cre') this.setState({ accionProceso: codigo })
      else {mostrarModalAddFiltrosCredito(true); 
      }
    };

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                { (estados_procesos.length === 0) ?
                <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                  {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol}, i) => {
                    return (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                        <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                          <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name= {nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] :  ['Debe aplicar los filtros.']}/>
                        </Paper>
                      </Grid>
                    )
                  })}
                </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({filtrarPeriodo : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({filtrarPeriodo : false})} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({filtrarPeriodo : false})} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >
      </>
    )
  }

  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(periodo_filtro)
  }

  renderProcesos() {
    let { accionProceso } = this.state;
    return (
      <>
        {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
        {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros: [], accionProceso: 'inicio' })} /></div>}
      </>
    )
  }

  configFiltros = (accion_actual) => {
    let { periodo_filtro } = this.state
    switch (accion_actual) {
      case 'Tod_Cre':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      case 'inicio':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
    }
  }

  render() {
    let { accion, cargando, credito, administrador, tipo_proceso_actual, estado_actual_credito, accionProceso } = this.state;
    let { modalDetalleCredito, mostrarModalDetalleCredito, modalAddAdjuntosCredito, mostrarModalAdjuntoCredito, actualizarMensaje, modalAddFiltrosCredito, mostrarModalAddFiltrosCredito } = this.props;

    return (
      <div>
        <MenuAdministrar
          seleccionar={(accion) => this.setState({ accion })}
          sele={accion}
          administrador={administrador}
        />
        <div className='contenido_modulo'>
          <CreditoFiltrar 
          actualizarMensaje={actualizarMensaje} 
          modalAddFiltrosCredito={modalAddFiltrosCredito} 
          mostrarModalAddFiltrosCredito={mostrarModalAddFiltrosCredito} 
          filtrar={(filtros) => this.actualizarDataCreditos(this.configFiltros(accionProceso).concat(filtros))} 
          limpiar={() => this.actualizarDataCreditos(this.configFiltros(accionProceso))} 
          accionProceso={accionProceso}
          mostrarTodos = {(accion) => this.setState({ accionProceso : accion})}
          />
          <Credito actualizarMensaje={actualizarMensaje} modalAddAdjuntosCredito={modalAddAdjuntosCredito} mostrarModalAdjuntoCredito={mostrarModalAdjuntoCredito} modalDetalleCredito={modalDetalleCredito} mostrarModalDetalleCredito={mostrarModalDetalleCredito} credito_id={credito ? credito.id : 0} vistaDetalle='credito' tipo_proceso_actual={tipo_proceso_actual} estado_actual_credito={estado_actual_credito}/>
          {
            !cargando
              ?
              <div>
                {accion === 'Procesos' && this.renderProcesos()}
                {accion === 'Administrar' && <CreditoPermisos />}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    )
  }
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalAddCredito, credito, modalDetalleCredito, modalGestionarCredito, modalAddAdjuntosCredito, modalAddFiltrosCredito } = state.redCreditos;
  let { usuario } = state.redGlobal;
  return {
    modalAddCredito,
    credito,
    modalDetalleCredito,
    modalGestionarCredito,
    modalAddAdjuntosCredito,
    usuario,
    modalAddFiltrosCredito,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalAddCredito,
  actualizarDataCredito,
  mostrarModalDetalleCredito,
  mostrarModalGestionarCredito,
  mostrarModalAdjuntoCredito,
  mostrarModalAddFiltrosCredito
}


export default connect(mapStateToProps, mapDispatchToProps)(CreditosAdministrar);