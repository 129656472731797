
import { createMuiTheme } from '@material-ui/core/styles'
import { coloresEmma } from '../../global/js/funciones'
export const EstiloApp = createMuiTheme({
  palette: {
    primary: {
      main: coloresEmma.secondarycolor,

    },
    secondary: {
      main: coloresEmma.secondarycolor,
    },
  },
})

