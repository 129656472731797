import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import BuscarSelect from '../../general/BuscarSelect';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta, obtenerValoresFiltros, transformar } from '../../../global/js/funciones';
import { DPTOS_ACADEMICOS, ESTADOS, PROGRAMAS, TIPOS_SOLICITUDES } from './helper';
import { BtnForm } from '../../general/BotonesAccion';

class SolicitudFiltros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estados: [],
      periodos: [],
      programas: [],
      tipos: [],
      departamentos: [],
      estado_actual: '',
      tipo: '',
      cargando: true,
      periodo: null,
      programa: null,
      departamento : null,
    }
  }

  async componentDidUpdate({ modalFiltro }) {
    let { modalFiltro: modalFiltroNew } = this.props;
    if (modalFiltro !== modalFiltroNew) {
      this.setState({ cargando: true });
      let periodos = await this.obtenerPeriodos();
      let tipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_SOLICITUDES }, { 'llave': 'valorb', 'valor': 1 }, { 'llave': 'estado', 'valor': 1 }]);
      let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'estado', 'valor': 1 }]);
      let departamentos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DPTOS_ACADEMICOS }, { 'llave': 'estado', 'valor': 1 }]);
      let estados = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ESTADOS }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({
        'periodos': transformar(periodos, 'periodo', 'periodo'),
        'programas': transformar(programas),
        'departamentos': transformar(departamentos),
        tipos,
        estados,
        cargando: false,
      })
    }
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitudes/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje, mostrarModalFiltros, tipoSolicitud } = this.props;
    let { tipo, programa, periodo, estado_actual, departamento } = this.state;
    let filtros = [];
    if (tipo !== '') filtros.push({ 'llave': 'tipo_solicitud', 'valor': tipo });
      else filtros.push({ 'llave': 'tipo_solicitud__codigo', 'valor': tipoSolicitud });
    if (programa !== null) filtros.push({ 'llave': 'programa', 'valor': programa.value });
    if (departamento !== null) filtros.push({ 'llave': 'departamento_docente__id', 'valor': departamento.value });
    if (periodo !== null) filtros.push({ 'llave': 'periodo', 'valor': periodo.value });
    if (estado_actual !== '') filtros.push({ 'llave': 'estado_actual', 'valor': estado_actual });
    if (filtros.length > 0){
      mostrarModalFiltros(false)
      filtrar(filtros);
    } else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  resetState = () => {
    let { limpiar, mostrarModalFiltros } = this.props;
    this.setState({
      periodo: null,
      programa: null,
      tipo: '',
      estado_actual: '',
      departamento: null,
    })
    limpiar();
    mostrarModalFiltros(false);
  }

  render () {
    let { modalFiltro, mostrarModalFiltros, tipoSolicitud } = this.props;
    let { cargando, periodos, periodo, programas, programa, tipos, tipo, estados, estado_actual, departamento, departamentos } = this.state;
    return (
      <Dialog fullWidth = { true } maxWidth = "sm" open = { modalFiltro } onClose = { () => mostrarModalFiltros(false) } aria-labelledby = "alert-dialog-title" aria-describedby = "alert-dialog-description">
        <AppBarModal titulo = '¡ Aplicar Filtros !' mostrarModal = { () => mostrarModalFiltros(false) } titulo_accion = "Limpiar" accion = { this.resetState } />
        <DialogContent className = 'scroll' style={{ overflow: 'visible' }}>
          {
            !cargando ?
              <Grid container spacing = { 3 } className = 'margin_cont'>
                <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                  <BuscarSelect
                    datos = { periodos }
                    cambiarEstado = { (value) => this.setState({ periodo: value }) }
                    valor = { periodo }
                    id = 'select-periodo'
                    nombre = 'Período'
                    placeholder = 'Seleccione Período'
                  />
                </Grid>
                {/* <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                  <FormControl className = 'form-control'>
                    <InputLabel htmlFor = "tipo">Seleccione Tipo de Solicitud</InputLabel>
                    <Select
                      inputProps = { {
                        name: 'tipo',
                        id: 'tipo',
                      } }
                      value = { tipo }
                      onChange = { this.onChange }>
                      {
                        tipos.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid> */}
                {
                  tipoSolicitud && tipoSolicitud == 'Reg_Mov_Int' ? (
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                      <BuscarSelect
                        datos={departamentos}
                        cambiarEstado={(value) => this.setState({ departamento: value })}
                        valor={departamento}
                        id='select-departamento'
                        nombre='departamento'
                        placeholder='Seleccione departamento'
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                      <BuscarSelect
                        datos={programas}
                        cambiarEstado={(value) => this.setState({ programa: value })}
                        valor={programa}
                        id='select-programa'
                        nombre='Programa'
                        placeholder='Seleccione Programa'
                      />
                    </Grid>
                  )
                }
                {/* Comentado por ser redudante al filtrar por estado en registros de movilidad */}
                {/* <Grid item xs = { 12 } xl = { 12 } sm = { 12 } md = { 12 } lg = { 12 }>
                  <FormControl className = 'form-control'>
                    <InputLabel htmlFor = "estado_actual">Seleccione Estado</InputLabel>
                    <Select
                      inputProps = { {
                        name: 'estado_actual',
                        id: 'estado_actual',
                      } }
                      value = { estado_actual }
                      onChange = { this.onChange }>
                      {
                        estados.map(({ id, nombre }) => <MenuItem key = { id } value = { id }>{ nombre }</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              : <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = "7%" />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto = "CANCELAR" callback = { () => mostrarModalFiltros(false) } />
          { !cargando && <BtnForm texto = "FILTRAR" callback = { this.onSubmit } /> }
        </DialogActions>
      </Dialog>
    )
  }
}

export default SolicitudFiltros;
