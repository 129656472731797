import { makeStyles } from '@material-ui/core/styles';
import { coloresEmma } from '../../global/js/funciones';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: coloresEmma.fondocolor,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: coloresEmma.fondocolor,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: coloresEmma.fondocolor,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
  button: {
    display: 'inline-block',
  },
  buttons: {
    color:coloresEmma.fondocolor,
    width:'60%',
    height:'10%',
    margin:'5px',
    textTransform: 'capitalize',
    borderRadius:'5px',
    fontSize:'20px',
    
  },
  buttonsUnidades: {
    background:'#154360',
    color:coloresEmma.fondocolor,
    width: '70%',
    margin: '5px 50%',
    height:'15%',
    textTransform: 'capitalize',
    borderRadius:'10px',
  },
  completed: {
  },
  instructions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  informacion: {
    background: '#c5eaea',
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      height: '470px',
    },
  },
  tablero: {
    border: '20px',
    borderStyle: 'solid',
    borderRadius: '30px',
    background: 'white',
    width: '200px',
    height:'350px',
    borderColor: '#90aeb2',
    textAlign:'left',
    paddingLeft:'10px',
    [theme.breakpoints.down('sm')]: {
      height: '450px',
      margin:'10px 0' 
    },
    
  },
  introduccion: {
    background: 'linear-gradient(80deg, #ffffff 50%, #e4f8f8 50%);',
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      height: '480px',
    },
  },
  introduccionUnidades: {
    background: 'linear-gradient(80deg, #ffffff 70%, #e4f8f8 70%);',
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      height: '550px',
    },
  },
  redondo: {
    border: '3px solid #fff',
    width: '130px',
    height: '130px',
    borderRadius: '100%',
    color: 'white',
    fontSize: '20px',
    background: coloresEmma.secondarycolor,
    margin: '30px',
    backgroundColor: '#05242c',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    background: '#140f42',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      width: '100px',
      fontSize: '15px',
      margin: '10px',
      display: 'flex',
      justifyContent:'center',
      alignItems:'center'
    },
  },
  redondo2: {
    width:'20px',
    height:'40px',
    borderRadius: '100%',
    color: 'white',
    background: coloresEmma.secondarycolor,
  },
  redondo3: {
    border: '1px solid #fff',
    width: '20px',
    height: '20px',
    borderRadius: '100%',
    color: 'white',
    background: '#d49b00',
    backgroundColor: '#05242c',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    background: '#140f42',
    display: 'inline-block',
  },
  menuPrincipal: {
    background: '#e8eaed',
    alignItems: 'left',
    height: '150px',
    [theme.breakpoints.down('sm')]: {
      height: '600px',
    },
  },
  botonesMenu: {
    height: '35px',
    width: '150px',
    color: 'white',
    background: '#2C2D2D',
    fontSize:'15px',
    '&:hover': {
      backgroundColor: coloresEmma.secondarycolor,
      boxShadow: 'none',
      color: coloresEmma.fondocolor,
    },
    // [theme.breakpoints.down('sm')]: {
    //   height: '30px',
    //   width: '100px',
    //   fontSize:'8px',
    // },
  },
  textoMenu: {
    fontSize: '20px',
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '15px',
    // },
  },

  solosimg: {
    marginLeft: '100px',
  },

  granPregunta: {
    background: coloresEmma.fondocolor,
    height:'400px',
    [theme.breakpoints.down('sm')]: {
      height:'350px',
      paddingTop:'30px'
    },
  },
  unidadesFormacion: {
    background: coloresEmma.fondocolor,
    height:'400px'
  },
  RespuestasP:{
    background:coloresEmma.fondocolor,
    height:'400px',
    paddingLeft:'20px',
    paddingRight:'20px',
    borderTopLeftRadius:'40px',
    borderBottomLeftRadius:'40px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  RespuestaMovil:{
    height:'350px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  avatar:{
    width: "30px", 
    height: "30px" 
  },

  //TABLA
  table: {
    minWidth: 600,
    width:'100%',
    height:'500px'
  },

  rootPregunta: {
    width: '100%',
    height:'480px',
  },

  rootUnidades:{
    width: '100%',
    height:'509px',
  },

  rootReferencias:{
    width: '100%',
    maxWidth: 360,
  },
  botonesM:{
    paddingTop: "20px",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "0px",
      'div:nth-of-type(1)': {
        paddingTop:'50px'
      },
    },
    
  }
  
  
}));

export default useStyles;