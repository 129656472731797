import React, { Component } from 'react';

import { connect } from 'react-redux';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal';

import { Button } from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';


import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import ConfirmarAccion from "../general/ConfirmarAccion";
import {
	obtenerPermisosValor,
	// formulario,
	// crear_form_data,
	consulta,
	obtenerValoresFiltros
} from '../../global/js/funciones';

import Respuestas from './Respuestas';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { PASOS } from './util' 


class Test extends Component {
	constructor(props) {
		super(props);
		this.state = {
			principal: true,
			iniciar: false,
			terminado: false,
			preguntas: [],
			listaRespuesta: [],
			open: false,
			message: "Hola, soy EMMA. Te invito a responder una serie de pregunta solo te tomara unos minutos",
			token: null,
			seguir: false,
			activeStep: 0,
			steps: [],
			stepsLabel: [],
			description: "",
			questions: [],
			cargando: true,
			message_cargando: 'Cargando ..',
			confirmar: false
		};
		this.cambiarEstado = this.cambiarEstado.bind(this);
	}

	async componentDidMount() {
		const { mostrarModulo } = this.props;
		const { tokenEcuesta } = this.props.match.params;

		let encuesta = await obtenerValoresFiltros([{
			llave: "codigo", valor: tokenEcuesta
		}]);

		if (encuesta.length == 1) {
			mostrarModulo({ ruta: '/encuesta_general', nombre: encuesta[0].nombre });
			let pasos = await obtenerPermisosValor(encuesta[0].id);
			pasos = pasos.map(valores => valores.secundario);
			pasos = pasos.filter(secundario => secundario.generica == PASOS);
			this.setState(
				{
					description: encuesta[0].descripcion,
					steps: pasos,
					stepsLabel: pasos.map(valores => valores.nombre),
					poll: encuesta
				}
				)
				this.searchAllQuestions()
				this.setState({ cargando: false })
		} else {

		}
	}

	validarToken = (token) => {
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/config/encuesta/permiso/${token}`, null, null, (error, estado, resp) => {
				resolve(resp);
			});
		});
	}

	cambiarEstado(state) {
		this.setState(state);
	}

	listaRespuesta = (preguntas) => {
		return preguntas.map(pregunta => {
			let obligatorio = pregunta.valora
			return {
				obligatorio: (
					obligatorio != null
					&& obligatorio.toLowerCase() == "obligatoria")
					? true
					: false,
				respuesta: null,
				idPregunta: pregunta.id,
				tipo: pregunta.valorb,
			}
		});
	}

	responder = (index, respuesta, respuesta_texto) => {
		let { listaRespuesta } = this.state;
		listaRespuesta[index].respuesta = respuesta;
		listaRespuesta[index].respuesta_texto = respuesta_texto ?? "";
		this.cambiarEstado({ listaRespuesta });
	}

	hayObligatorio = () => {
		const { listaRespuesta } = this.state;
		for (const index in listaRespuesta) {
			if (
				listaRespuesta[index].obligatorio == true &&
				(
					listaRespuesta[index].respuesta == null && (listaRespuesta[index].respuesta_texto == null || listaRespuesta[index].respuesta_texto == "")
				)
			) return true;
		}
		return false;
	}

	formatAllResponse = () => {
		const { allResponse } = this.state;
		let newAllResponse = []
		allResponse.forEach(item => {
			newAllResponse = [
				...newAllResponse,
				...item
			]
		})
		return newAllResponse
	}

	arrayToString = () => {
		// let { listaRespuesta } = this.state;
		let listaRespuesta = this.formatAllResponse();
		let questionsWithArray = listaRespuesta.filter(item => Array.isArray(item.respuesta));
		let questionsWithoutArray = listaRespuesta.filter(item => !Array.isArray(item.respuesta));

		let newReponses = [];
		questionsWithArray.forEach(item => {
			item.respuesta.forEach(idResponse => newReponses.push({ ...item, respuesta: idResponse }))
		})

		let sendParams = [
			...questionsWithoutArray,
			...newReponses
		]
		return JSON.stringify(sendParams);
	}

	handleClick = () => {
		this.cambiarEstado({ open: true });
	};

	handleClose = () => {
		this.cambiarEstado({ open: false });
	};

	// metodos steps

	// sendQuestions = async () => {
	// 	let { poll } = this.state;

	// 	let data = {
	// 		questions: this.arrayToString(),
	// 	}
	// 	await consulta(
	// 		`api/v1.0/poll/save/session/${poll[0].id}`,
	// 		data,
	// 		"POST",
	// 		(err, estado, data) => {
	// 			if (!err) {
					
	// 			}
	// 		}
	// 	);
	// }

	sendAllQuestions = async () => {
		const { poll } = this.state
		this.cambiarEstado({
			principal: false,
			iniciar: false,
			terminado: true,
			cargando: true,
			confirmar: false,
			message_cargando: "Guardando respuestas, por favor espere un momento .."
		})
		let data = {
			questions: this.arrayToString(),
		}
		await consulta(
			`api/v1.0/poll/save/session/all`,
			data,
			"POST",
			(err, estado, data) => {
				if (!err) {
					this.cambiarEstado({
						message: poll[0].valorb,
						message_cargando: "Cargando ..",
						cargando: false
					})
				}
			}
		);
	}

	handleNext = async () => {
		const { activeStep, steps, allResponse, allQuestions, listaRespuesta } = this.state

		if (this.hayObligatorio()) {
			// alert("Hay preguntas obligatorias")
    	this.props.actualizarMensaje({ titulo: `Seleccione respuestas en las preguntas obligatorias.`, tipo: 'info', mostrar: true, tiempo: 6000 });
			return
		}

		if (activeStep < steps.length && activeStep != steps.length - 1) {
			let next = activeStep + 1
			if (next < steps.length) {
				this.setState({
					activeStep: next
				})

				let newAllResponse = allResponse;
				if (activeStep > -1) newAllResponse[activeStep] = listaRespuesta;

				this.setState({	
					listaRespuesta: allResponse[next],
					questions: allQuestions[next],
					allResponse: newAllResponse
				})
			}

		} else {
			let newAllResponse = allResponse;
			newAllResponse[activeStep] = listaRespuesta;
			this.setState({
				allResponse: newAllResponse,
				confirmar: true
			})
			// this.sendAllQuestions();
		}
	};

	handleBack = () => {
		const { activeStep, allResponse, allQuestions, listaRespuesta } = this.state
		if (activeStep > 0) {
			this.setState({
				activeStep: this.state.activeStep - 1
			})

			let newAllResponse = allResponse;
			newAllResponse[activeStep] = listaRespuesta;

			this.setState({
				listaRespuesta: allResponse[activeStep - 1],
				questions: allQuestions[activeStep - 1],
				allResponse: newAllResponse
			})
		}
	};

	handleReset = () => {
		this.setState({
			activeStep: 0
		});
	};

	searchQuestions = async (activeStep) => {
		const { steps } = this.state
		if (activeStep < steps.length) {
			let preguntas = await obtenerPermisosValor(steps[activeStep].id);
			preguntas = preguntas.map(valores => valores.secundario);

			let aux;
			let listaRespuesta = [];
			for (let index = 0; index < preguntas.length; index++) {
				aux = await obtenerPermisosValor(preguntas[index].id);
				preguntas[index].respuestas = aux.map(element => element.secundario);
				listaRespuesta.push(
					{
						paso: steps[activeStep].id,
						pregunta: preguntas[index].id,
					}
				)
			}
			this.setState({ questions: preguntas, listaRespuesta })
		}

	}
	// implementar
	searchAllQuestions = async () => {
		const { steps, poll } = this.state
		let contador = 0;
		let allPreguntas = [];
		let AllListaRespuesta = [];
		let aux;
		while (contador < steps.length) {
			let preguntas = await obtenerPermisosValor(steps[contador].id);
			preguntas = preguntas.map(valores => valores.secundario);
			let respuesta = [];

			for (let index = 0; index < preguntas.length; index++) {
				aux = await obtenerPermisosValor(preguntas[index].id);
				preguntas[index].respuestas = aux.map(element => element.secundario);
				let obligatorio = preguntas[index].valora;
				respuesta.push(
					{
						paso: steps[contador].id,
						pregunta: preguntas[index].id,
						encuesta: poll[0].id,
						obligatorio: (
							obligatorio != null && obligatorio.toLowerCase() == "obligatoria"
						) ? true : false,
						respuesta: null,
						respuesta_texto: ''
					}
				)

			}
			AllListaRespuesta.push(respuesta)
			allPreguntas.push(preguntas)
			contador++;
		}
		this.setState({
			allQuestions: allPreguntas,
			allResponse: AllListaRespuesta,
			activeStep: -1
		})
		// this.handleNext();
	}

	startQuestions = () => {
		this.cambiarEstado({
			principal: false,
			iniciar: true,
			terminado: false,

		})
		this.handleNext();
		// this.searchAllQuestions();
	}

	render() {
		const {
			iniciar,
			open,
			message,
			activeStep,
			description,
			stepsLabel,
			questions,
			steps,
			principal,
			terminado,
			listaRespuesta,
			cargando,
			message_cargando,
			confirmar
		} = this.state;

		return (
			<>
				{
					!cargando ?
						<>
							<div>
								{
									principal && (
										<div>
											<TareasTerminadas
												mensaje={message}
												marginTop='7%'
												imagen={emma_s}
												widthImg='7%'
												marginBottom='0%'
											/>
											<Typography align='center'>{description}.</Typography>
											<div>
												<Stepper activeStep={-1} alternativeLabel>
													{
														stepsLabel.map((label) => (
															<Step key={label}>
																<StepLabel>{label}</StepLabel>
															</Step>
														))
													}
												</Stepper>
											</div>
											<div style={{
												display: "flex",
												width: "100%",
												justifyContent: "center",

											}}>
												{
													(
														<Button
															style={{
																color: "white"
															}}
															variant="contained"
															color="primary"
															onClick={this.startQuestions}
															size='large'
														>
															Iniciar
														</Button>
													)
												}
											</div>
										</div>
									)

								}
								{
									iniciar && (
										<div>
											<div style={{ marginBottom: "30px" }}>
												<AppBar position="fixed" elevation={0} style={{ backgroundColor: 'white', top: 55 }}>
													<Toolbar>
														<div style={{ padding: "5px 0px 5px 3px", color: 'black' }}>
															<p><span className='step_pers' style={{ color: 'white' }}>{activeStep + 1}</span> <span style={{ marginLeft: "10px" }} > {stepsLabel[activeStep]}</span> </p>
														</div>
													</Toolbar>
												</AppBar>
											</div>
											{
												questions.map((pregunta, index) => {
													return <Respuestas
														key={index}
														index={index}
														pregunta={pregunta.nombre}
														tipo={pregunta.valorb}
														respuestas={pregunta.respuestas}
														responder={this.responder}
														obligatoria={
															(
																pregunta.valora != null && pregunta.valora.toLowerCase() == "obligatoria"
															) ? true : false
														}
														listaRespuesta={listaRespuesta}
													/>
												})
											}
											<AppBar position="fixed" color="primary" style={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}/*className={classes.appBar}*/>
												<Toolbar>
													<Button disabled={activeStep === 0} onClick={this.handleBack} >  REGRESAR </Button>
													<Button color="primary" onClick={this.handleNext} style={{ marginLeft: 10 }}> {activeStep == steps.length - 1 ? 'GUARDAR' : 'SIGUIENTE'} </Button>
												</Toolbar>
											</AppBar>
										</div>
									)
								}
								{
									terminado && (
										<TareasTerminadas
											mensaje={message}
											marginTop='7%'
											imagen={emma_s}
											widthImg='7%'
											marginBottom='0%'
										/>
									)
								}
								<div >
									<Snackbar style={{ width: "100%" }}
										message={message}
										open={open}
										autoHideDuration={6000}
										severity="success"
										onClose={this.handleClose}>

									</Snackbar>
								</div>
							</div>
							<ConfirmarAccion
								dato={{ id: 0 }}
								titulo={' ¿ Enviar Datos ?'}
								ejecutarAccion={() => this.sendAllQuestions()}
								modalConAccion={confirmar}
								mostrarModalConfAccion={() => this.cambiarEstado({ confirmar: false })}
							/>
						</>
						: <TareasTerminadas mensaje={message_cargando} marginTop='7%' imagen={emma_w} widthImg="7%" />
				}
			</>
		);
	}
}


const mapStateToProps = (state) => {
	let { usuario } = state.redGlobal;
	return {
		usuario,
	};
};

const mapDispatchToProps = {
	mostrarModulo,
	actualizarMensaje
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
