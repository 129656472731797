import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BtnEliminar, BtnNotificacion } from "../../general/BotonesAccion";
import ListarDatos from '../../general/ListarDatos';

class GenericaListar extends Component {

  render() {
    let { notificaciones, activarNotificaciones, genericas, mostrarModalDel, actualizarDataGenerica, mostrarModalAdd } = this.props;

    const actions = (rowData) => {
      const onClickEliminar = (data) => {
        actualizarDataGenerica({ id: data.id, 'nombre': data.relacion.nombre });
        mostrarModalDel(true);
      }
      const onClickNotifica = (data) => {
        activarNotificaciones(data.id, { valora: !data.valora ? "si" : null });
      }

      let notifica = notificaciones && <BtnNotificacion texto={rowData.valora ? 'Desactivar Notificaciones' : 'Activar Notificaciones'} color={rowData.valora ? '#009688' : '#607d8b'} callback={() => onClickNotifica(rowData)} />;
      let elimina = <BtnEliminar callback={() => onClickEliminar(rowData)} />;
      return <div>{elimina}{notifica}</div>;
    }

    return (
      <div className='seccion-modal'>
        <ListarDatos
          id="tbl_listar_genericas_personas"
          descar="EMMA - GENERICA - PERSONA"
          titulo='Lista Permisos'
          datos={genericas}
          opciones={true}
          acciones={row => actions(row)}
          sub_titulo={"Lista de genericas"}
          buscar={true}
          agregar={true}
          actAgregar={() => mostrarModalAdd(true)}
          fila_principal={({ relacion }) => relacion.nombre.toUpperCase()}
          filas={[
            {
              'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
              'id': 'fecha_registro',
              'enLista': true
            },
            { 'id': 'usuario_registra', 'enLista': false }
          ]}
        />
      </div>
    );
  }

}

//Props del componente
GenericaListar.propTypes = {
  //variables
  genericas: PropTypes.array.isRequired,
  tituloAdd: PropTypes.string.isRequired,
  //funciones
  actualizarDataGenerica: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
}

GenericaListar.defaultProps = {
  tituloAdd: 'Nuevo',
};


export default GenericaListar;


