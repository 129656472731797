// CODIGOS GENERALES
export const CODIGO_TIPO_CONCEPTO_OTRO = 'Otro_tipo_concepto'; //New
export const CODIGO_NO_APLICA_TIPO_SUBACTIVIDAD = 'no_aplica_subtipo_actividad'
export const CODIGO_CURSO_CORTO = 'Curso_corto_tipo_actividad'
export const CODIGO_CURSO_CORTO_PROFE = 'Curso_corto_tipo_actividad_profe'
export const SUBTIPO_ACTI_EMPRESARIAL = 'Empresarial_subtipo_actividad'
export const SUBTIPO_ACTI_ACADEMICO = 'Academico_subtipo_actividad'
export const MODALIDADES_VIRTUALES = ['Mod_Vir'];
export const OTRA_PLATAFORMA = 'Otra_plataforma_utilizada'
export const OTRO_TIPO_ACTIVIDAD = 'Otro_tipo_actividad'
export const OTRO_SUBTIPO_ACTIVIDAD = 'Otro_subtipo_actividad'

// HELPERS NUEVOS MOVILIDAD LOCAL
// export const DEPARTAMENTO_RESPONSABLE = 1239;
// export const DEPENDENCIAS_FINANCIACIONES_MOVILIDAD = 5265;
// export const FACULTAD_POSGRADOS_PARTICI = 11594;
// export const FACULTAD_RESPONSABLE = 2259;
// export const FUENTES_FINANCIACIONES_MOVILIDAD = 5266;
// export const MASIVOS_INTER = 5271;
// export const PROGRAMAS = 5;
// export const REQUISITOS_MOV = 1249;
// export const SUBTIPOS_ACTIVIDADES_MOVILIDAD = 3267;
// export const TIPO_FIN_INSTITUCIONAL = 10517;
// export const TIPO_FIN_INTERNACIONAL = 10515;
// export const TIPO_FIN_NACIONAL = 10516;
// export const TIPO_MOVILIDADES_PROFESOR = [8515, 8516];
// export const TIPO_MOV_DOCENTE = [8515, 8516];
// export const TIPO_MOV_ESTUDIANTE = [8517, 8518];
// export const TIPO_PARTICIPANTE_ADMIN = 11592;
// export const TIPO_PARTICIPANTE_DOC = 11593;
// export const TIPO_PARTICIPANTE_ESTU = 11591;
// export const TIPO_RESPONSABLE = 4264;
// export const TIPOS_ACTIVIDADES_MOVILIDAD = 1250;
// export const TIPOS_CONCEPTOS_MOV = 5267;
// export const TIPOS_FINANCIACIONES_MOVILIDAD = 5264;
// export const TIPOS_FORMS_MOVILIDAD = 5268;
// export const TIPOS_IDENTICACION = 2;
// export const TIPOS_INFORMES = 6271;
// export const TIPOS_MODALIDADES = 1245;
// export const TIPOS_MOV_ENTRANTES = [8515, 8517];
// export const TIPOS_MOV_SALIENTES = [8516, 8518];
// export const TIPOS_MOVILIDADES = 3264; //149
// export const TIPOS_NIVELES_EDU = 5270;
// export const TIPOS_PARTICIPANTES = 5269;
// export const TIPOS_PLATAFORMAS = 3266; //151
// export const UNIDAD_ADMIN_RESPONSABLE = 1240;
// export const DPTO_ADMINISTRATIVO = 1130;

// HELPERS EMMA TEST

// export const DEPARTAMENTO_RESPONSABLE = 144;
// export const DEPENDENCIAS_FINANCIACIONES_MOVILIDAD = 156;
// export const FACULTAD_POSGRADOS_PARTICI = 18512;
// export const FACULTAD_RESPONSABLE = 150;
// export const FUENTES_FINANCIACIONES_MOVILIDAD = 154;
// export const MASIVOS_INTER = 292;
// export const PROGRAMAS = 5;
// export const REQUISITOS_MOV = 138;
// export const SUBTIPOS_ACTIVIDADES_MOVILIDAD = 204;
// export const TIPO_FIN_INSTITUCIONAL = 18504;
// export const TIPO_FIN_INTERNACIONAL = 18506;
// export const TIPO_FIN_NACIONAL = 18505;
// export const TIPO_MOV_DOCENTE = [18515, 18516];
// export const TIPO_MOV_ESTUDIANTE = [18513, 18514];
// export const TIPO_MOVILIDADES_PROFESOR = [18515, 18516];
// export const TIPO_PARTICIPANTE_ADMIN = 29872;
// export const TIPO_PARTICIPANTE_DOC = 29873;
// export const TIPO_PARTICIPANTE_ESTU = 29874;
// export const TIPO_RESPONSABLE = 206;
// export const TIPOS_ACTIVIDADES_MOVILIDAD = 139;
// export const TIPOS_CONCEPTOS_MOV = 207;
// export const TIPOS_FINANCIACIONES_MOVILIDAD = 152;
// export const TIPOS_FORMS_MOVILIDAD = 290;
// export const TIPOS_IDENTICACION = 2;
// export const TIPOS_INFORMES = 6271 //CREAR;
// export const TIPOS_MODALIDADES = 134;
// export const TIPOS_MOV_ENTRANTES = [18515, 18513];
// export const TIPOS_MOV_SALIENTES = [18516, 18514];
// export const TIPOS_MOVILIDADES = 149;
// export const TIPOS_NIVELES_EDU = 153;
// export const TIPOS_PARTICIPANTES = 291;
// export const TIPOS_PLATAFORMAS = 151;
// export const UNIDAD_ADMIN_RESPONSABLE = 145;


// // HELPERS EMMA PRODUCCION
export const DEPARTAMENTO_RESPONSABLE = 144;
export const DEPENDENCIAS_FINANCIACIONES_MOVILIDAD = 156;
export const FACULTAD_POSGRADOS_PARTICI = 18512;
export const FACULTAD_RESPONSABLE = 150;
export const FUENTES_FINANCIACIONES_MOVILIDAD = 154;
export const MASIVOS_INTER = 292;
export const PROGRAMAS = 5;
export const REQUISITOS_MOV = 138;
export const SUBTIPOS_ACTIVIDADES_MOVILIDAD = 204;
export const TIPO_FIN_INSTITUCIONAL = 18504;
export const TIPO_FIN_INTERNACIONAL = 18506;
export const TIPO_FIN_NACIONAL = 18505;
export const TIPO_MOV_DOCENTE = [18515, 18516];
export const TIPO_MOV_ESTUDIANTE = [18513, 18514];
export const TIPO_MOVILIDADES_PROFESOR = [18515, 18516];
export const TIPO_PARTICIPANTE_ADMIN = 29938;
export const TIPO_PARTICIPANTE_DOC = 29939;
export const TIPO_PARTICIPANTE_ESTU = 29940;
export const TIPO_RESPONSABLE = 206;
export const TIPOS_ACTIVIDADES_MOVILIDAD = 139;
export const TIPOS_CONCEPTOS_MOV = 207;
export const TIPOS_FINANCIACIONES_MOVILIDAD = 152;
export const TIPOS_FORMS_MOVILIDAD = 290;
export const TIPOS_IDENTICACION = 2;
export const TIPOS_INFORMES = 296;
export const TIPOS_MODALIDADES = 134;
export const TIPOS_MOV_ENTRANTES = [18515, 18513];
export const TIPOS_MOV_SALIENTES = [18516, 18514];
export const TIPOS_MOVILIDADES = 149;
export const TIPOS_NIVELES_EDU = 153;
export const TIPOS_PARTICIPANTES = 291;
export const TIPOS_PLATAFORMAS = 151;
export const UNIDAD_ADMIN_RESPONSABLE = 145;
export const DPTO_ADMINISTRATIVO = 18462;