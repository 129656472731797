// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Material-UI Components
import { Tabs, Tab, Button, BottomNavigation, BottomNavigationAction, Dialog, DialogContent, DialogActions, } from '@material-ui/core/';
// Material Icons
import LoopIcon from '@material-ui/icons/Loop';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import FormatListIcon from '@material-ui/icons/FormatListNumberedRtl';
// Components
import GradoEstados from './historial/GradoEstados';
import GradoDetalle, { Cursoslibres } from './historial/GradoDetalle';
import AppBarModal from '../general/AppBarModal';
import Eventos from './Eventos'
import Adjuntos from './adjuntos/Adjuntos'
import TareasTerminadas from '../general/TareasTerminadas';
//imagenes
import emma_w from '../../global/imagenes/emma_w.png';
// Funciones
import { getEstadosSolicitud, getGestoresSolicitud, getEventosSolictud, obtenerAdjuntos } from './funciones';
import Gestores from './Gestores';


function Menu({ solicitud, sele = "detalle", seleccionar = () => { } }) {
	const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

	const pintar = (claseN = '') => {
		return (
			<div className={claseN}>
				<BottomNavigation showLabels style={{  alignItems: "stretch", overflowX: "auto" }}>
					<BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon className='menu_icono' />} className={clase("detalle", sele)} onClick={() => seleccionar("detalle")} />
					{/* {solicitud.tipo.codigo != "gr_trabajo_grado" && <BottomNavigationAction label={'Solicitantes'} icon={<PeopleIcon />} className={clase("solicitantes", sele)} onClick={() => seleccionar("solicitantes")} />} */}
					{solicitud.tipo.codigo!=="gr_cursos" && <BottomNavigationAction label={'Participantes'} icon={<PeopleIcon className='menu_icono' />} className={clase("gestores", sele)} onClick={() => seleccionar("gestores")} />}
					{solicitud.tipo.codigo ==="gr_cursos" && <BottomNavigationAction label={'Cursos libres'} icon={<PeopleIcon className='menu_icono' />} className={clase("listcursos", sele)} onClick={() => seleccionar("listcursos")} />}
					{/* {solicitud.tipo.codigo == "gr_trabajo_grado" && <BottomNavigationAction label={'Tutorias'} icon={<EventIcon className='menu_icono' />} className={clase("eventos", sele)} onClick={() => seleccionar("eventos")} />} */}
					<BottomNavigationAction label={'Adjuntos'} icon={<FormatListIcon className='menu_icono' />} className={clase("adjuntos", sele)} onClick={() => seleccionar("adjuntos")} />
					<BottomNavigationAction label={'Estados'} icon={<LoopIcon className='menu_icono' />} className={clase("estados", sele)} onClick={() => seleccionar("estados")} />
				</BottomNavigation>
			</div>
		)
	}

	return (
		<div>
			{pintar('menu_modal')}
		</div>
	);
}
class Grado extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seleccion: 0,
			cargando: false,
		};
	}

	componentDidUpdate({ sele }, { }) {
		let { sele: seleNew } = this.props;
		let { setEstadosSolicitud, setTutores, setEventos, setAdjuntosEstado, setEstado, solicitud } = this.props;
		if (seleNew !== sele) {
			this.setState({ cargando: true })
			switch (seleNew) {
				case "detalle":
					this.setState({ cargando: false })
					break;
				case "listcursos":
					this.setState({ cargando: false })
					break;
				case "gestores":
					getGestoresSolicitud(solicitud.id, (resp) => {
						setTutores(resp)
						this.setState({ cargando: false })
					});
					break;
				case "estados":
					getEstadosSolicitud(solicitud.id, (resp) => {
						setEstadosSolicitud(resp)
						this.setState({ cargando: false })
					});
					break;
				case "eventos":
					getEventosSolictud(solicitud.id, (resp) => {
						setEventos(resp)
						this.setState({ cargando: false })
					})
					break;
				case "adjuntos":
					setEstado(solicitud.estado_actual)
					this.setState({ cargando: false })
					break;
				default:
					break;
			}
		}
	}

	handleClose = () => {
		let { showModalDetalleGrado } = this.props
		this.props.seleccionar("detalle")
		showModalDetalleGrado(false);
	};


	pintar = () => {
		let { sele, admin, perfil, estado, estados, solicitud, adjuntos, showModalAdjuntos, showModalConfirAplazamiento, setAdjuntosEstado, setEstadosSolicitud, setEstado, actualizarMensaje,data_session } = this.props;
		switch (sele) {
			case "solicitantes":
				return <Gestores admin={admin} perfil={perfil} />
			case "listcursos":
				return solicitud && <Cursoslibres solicitud={solicitud}/>
			case "gestores":
				return <Gestores admin={admin} perfil={perfil} solicitud={solicitud} data_session={data_session}/>
			case "estados":
				return (
					<GradoEstados
						admin={admin}
						perfil={perfil}
						solicitud={solicitud}
						estado={estado}
						estados={estados}
						adjuntos={adjuntos}
						setEstado={setEstado}
						setEstadosSolicitud={setEstadosSolicitud}
						setAdjuntosEstado={setAdjuntosEstado}
						showModalAdjuntos={showModalAdjuntos}
						showModalConfirAplazamiento={showModalConfirAplazamiento}
						actualizarMensaje={actualizarMensaje}
					/>
				)
			case "eventos":
				return (
					<Eventos
						admin={admin}
						solicitud={solicitud}
						eventos={this.props.eventos}
						setEventos={this.props.setEventos}
						modalEventos={this.props.modalEventos}
						modalNewEvento={this.props.modalNewEvento}
						showModalEventos={this.props.showModalEventos}
						showModalNewEvento={this.props.showModalNewEvento}
						actualizarMensaje={this.props.actualizarMensaje}
					/>
				)
			case "adjuntos":
				return (
					<Adjuntos
						admin={admin}
						getAdjFaltantes={() => { }}
						accionesActivar={false}
					/>
				)
			default:
				return <GradoDetalle solicitud={solicitud} info_completa={true} />;
		}
	};


	render() {
		return (
			<div>
				<Dialog onClose={this.handleClose} open={this.props.modalDetalleGrado} fullWidth={true} fullScreen={window.innerWidth < 600} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo={'Detalle Grado'} mostrarModal={this.handleClose} titulo_accion="Cerrar" />
					<DialogContent className="scroll" style={{ padding: 0 }}>
						<Menu solicitud={this.props.solicitud} sele={this.props.sele} seleccionar={seleccion => this.props.seleccionar(seleccion)} />
						{!this.state.cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.handleClose} color="primary">
							Cerrar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}


export default Grado;
