import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions, Link, Fab, Grid, TableCell, TableRow, TableBody, TableHead, Table, TableContainer, Paper, Button, IconButton, Tooltip } from '@material-ui/core';
import { SolicitudesMenu } from './administrar/MenuInternacionalizacion';
import emma_w from './../../global/imagenes/emma_w.png';
import { consulta, crear_form_data, formulario, generarFiltros, mostrarError } from '../../global/js/funciones';
import { DetalleInstituciones, DetalleRed, MovEstudiantilDetalle, RegistroMovilidadDetalle } from './DetalleSolicitud';
import AppBarModal from '../general/AppBarModal';
import TareasTerminadas from '../general/TareasTerminadas';
import { BtnCancelar, BtnDetalle, BtnEditar, BtnForm, BtnSeleccion } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';
import EnviarArchivo from '../general/EnviarArchivo';
import moment from 'moment';
import EnviarMensaje from '../general/EnviarMensaje';
import ConfirmarAccion from '../general/ConfirmarAccion';
import Detalle from '../general/Detalle';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import ConvenioCrearDetalle from './convenios/ConvenioCrearDetalle';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { DetalleParticipante, FinanciacionParticipante, InstitucionParticipante, ModalContenedor } from './registro/forms/Forms';
import EditarParticipanteExt from './registro/forms/EditarParticipanteExt';
import SchoolIcon from '@material-ui/icons/School';
import DescargarPdfRegistro from './registro/DownloadPdf';
import EditarInformacion from './registro/EditarInformacion';


const useStyles = makeStyles(theme => ({
  fabButton: {
    position: 'fixed',
    left: "45%",
    zIndex: 1000,
    top: '83%',
    [theme.breakpoints.up('sm')]: {
      top: '89%',
      left: "50%",
    },
  },
}));

function Agregar({ actAgregar, iconoFab }) {
  const classes = useStyles();
  return (
    <Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={() => actAgregar()}>
      {iconoFab}
    </Fab>
  )
}

class InternacionalizacionDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seleccion: '',
      tipo_solicitud: '',
      subtipo_solicitud: '',
      adjunto: null,
      administrador: false,
      cargando: false,
      modal_detalle_participante: false,
      modal_facultades_departamentos: false,
      modal_programas: false,
      modal_unidades_adm: false,
      modal_financiaciones: false,
      adjuntos: [],
      datos: [],
      estados: [],
      participante_seleccionado: {
        id: 0,
        nombre_completo: '',
        identificacion: '',
        academica: [0, 0, 0, 0],
        formulario: 0,
        tipo_participante: '',
        dpto_receptor: 0,
      },
      participantes: [],
      financiaciones: [],
      tipo_paso_infoconveni: [],
      pasocumplimientorequisito: [],
      pasotipoactividad: [],
      financiacion_seleccionada: '',
      modal_detalle_financiacion: false,
      data_participante: '',
      participante: [],
      cargandoInfo: false,
      modal_fin_participante: false,
      financiacion: '',
      editarParticipante: false,
      dataParticipante: {},
      actividad_movilidad: [],
      modal_insti_participante: false,
      institucion_participante: {},
      descargandopdf: false,
      id_solicitud: 0,
      tipos_forms: [],
      editarInfo: false,
      editar: false,
      id_registro: 0,
      persona_inter: false
    }
  }

  async componentDidUpdate({ modalDetSolicitud }, { seleccion, datos, modal_detalle_participante, modal_fin_participante, modal_insti_participante }) {
    let { modalDetSolicitud: modalDetSolicitudNew, solicitud: { id, tipo_solicitud, item_correccion }, vistaDetalle,
      obtenerAdjuntos, obtenerParticipantesRegistro, obtenerFinanciacionesRegistro } = this.props;
    let { seleccion: seleccionNew, datos: datosNew, modal_detalle_participante: modal_detalle_participanteNew, modal_fin_participante: modal_fin_participanteNew, participante, modal_insti_participante: modal_insti_participanteNew } = this.state;
    if (seleccion !== seleccionNew) {
      this.setState({ cargando: true });
      if (seleccionNew !== seleccion) {
        let adjuntos = [], participantes = [], financiaciones = [];
        if (seleccionNew === 'detalle') await this.obtenerInfoSolicitud(id, tipo_solicitud.codigo)
        else if (seleccionNew === 'financiaciones') financiaciones = await obtenerFinanciacionesRegistro('', id);
        else if (seleccionNew === 'participantes') participantes = await obtenerParticipantesRegistro('', id);
        else if (seleccionNew === 'documentos') adjuntos = await obtenerAdjuntos(id);
        else if (seleccionNew === 'adjuntos') adjuntos = await obtenerAdjuntos(id, 0);
        else if (seleccionNew === 'estados') await this.obtenerEstadosSolicitud(id);
        this.setState({ participantes, adjuntos, financiaciones, cargando: false });
      }
    }
    if (modalDetSolicitud !== modalDetSolicitudNew) {
      this.esAdministrador();
      this.setState({ seleccion: modalDetSolicitudNew ? vistaDetalle : '', datos: modalDetSolicitudNew ? datos : '', tipos_forms: item_correccion });
    }
    if (datos !== datosNew && datosNew.length > 0) {
      let tipo_paso_infoconveni = [], pasocumplimientorequisito = [], pasotipoactividad = []
      if (datosNew) {
        this.setState({ cargando: true })
        tipo_paso_infoconveni = await this.obtenerBorradorInfConvenio(datosNew[0].id)
        pasocumplimientorequisito = await this.obtenerBorradorCumplimiento(datosNew[0].id)
        pasotipoactividad = await this.obtenerTipoActividad(datosNew[0].id)
        this.setState({ tipo_paso_infoconveni, pasocumplimientorequisito, pasotipoactividad, cargando: false })
      }
    }
    if (modal_detalle_participante !== modal_detalle_participanteNew) {
      if (!modal_detalle_participanteNew) this.setState({ participante: [] });
    }
    if (modal_fin_participante !== modal_fin_participanteNew) {
      if (modal_fin_participanteNew) {
        this.setState({ cargandoInfo: true });
        let financiacion = await this.obtenerFinanciacionParticipanteMovilidad(participante.financiacion_registro ? participante.financiacion_registro : 0);
        this.setState({ financiacion: financiacion ? financiacion : [], cargandoInfo: false });
      }

      if (!modal_fin_participanteNew) this.setState({ participante: '' });
    }
    if (modal_insti_participante !== modal_insti_participanteNew) {
      if (modal_insti_participanteNew) {
        this.setState({ cargandoInfo: true });
        let institucion_participante = await this.obtenerInstitucionParticipante(participante.institucion_registro ? participante.institucion_registro : 0);
        this.setState({ institucion_participante: institucion_participante ? institucion_participante[0] : [], cargandoInfo: false });
      }
      if (!modal_insti_participanteNew) this.setState({ institucion_participante: [] });
    }
  }

  async obtenerEstadosSolicitud(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    consulta(`api/v1.0/internacionalizacion/solicitud/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }

  async obtenerInfoSolicitud(id, tipo_solicitud) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    if (tipo_solicitud === 'Mov_Est_Int') {
      consulta(`api/v1.0/internacionalizacion/solicitud/mov_estudiantil?${f}`, null, null, (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, datos: resp, tipo_solicitud: tipo_solicitud });
      });
    } else if (tipo_solicitud === 'Reg_Mov_Int') {
      console.log("Entrnado a obtener info Reg_Mov_Int")
      consulta(`api/v1.0/internacionalizacion/solicitud/${id}`, null, null, (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, tipo_solicitud: tipo_solicitud, actividad_movilidad: resp.actividad ? resp.actividad : '', })
      });
    } else if (tipo_solicitud === 'Con_Int' || tipo_solicitud === 'Ies_Int') {
      consulta(`api/v1.0/internacionalizacion/solicitud/convenio?${f}`, null, null, (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, datos: resp, tipo_solicitud: tipo_solicitud, subtipo_solicitud: resp[0].subtipo_solicitud?.codigo });
      });
    }
  }

  reemplazarAdjunto = async (archivo) => {
    let { actualizarMensaje, mostrarModalModAdjuntos, obtenerAdjuntos } = this.props;
    let { adjunto: { id, solicitud } } = this.state;
    let data = await crear_form_data({ archivo });
    let adjuntos = []
    this.setState({ cargando: true });
    formulario(`api/v1.0/internacionalizacion/solicitud/adjunto/${id}/reemplazar`, data, "post", async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.';
      let tipo = 'warning';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          adjuntos = await obtenerAdjuntos(solicitud);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
        mostrarModalModAdjuntos(false);
      }
      this.setState({ adjuntos, cargando: false });
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }
  async obtenerBorradorInfConvenio(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoInfConvenio`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }
  async obtenerBorradorCumplimiento(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_pasoCumplimiento`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }
  async obtenerTipoActividad(id_convenio) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/solicitudes/${id_convenio}/obtener_tipoActividad`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerFinanciacionParticipanteMovilidad(id) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/registro/financiacion/participante/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async obtenerInstitucionParticipante(id) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/internacionalizacion/registro/institucion/participante/${id}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  cerraModalDetalleSolic() {
    let { mostrarModalDetSolicitud } = this.props;
    mostrarModalDetSolicitud(false);
  }

  async cargarInfoInstParticipante() {
    let { participante } = this.state;
    this.setState({ cargandoInfo: true });
    let institucion_participante = await this.obtenerInstitucionParticipante(participante.institucion_registro ? participante.institucion_registro : 0);
    this.setState({ institucion_participante: institucion_participante ? institucion_participante[0] : [], cargandoInfo: false });
  }

  validarAdjunto = async (adjunto, validacion, nota = '') => {
    let { actualizarMensaje, mostrarModalAprobarAdjunto, mostrarModalNegarAdjunto, obtenerAdjuntos } = this.props;
    let { id, solicitud } = adjunto;
    let adjuntos = [];
    this.setState({ cargando: true });
    mostrarModalNegarAdjunto(false);
    mostrarModalAprobarAdjunto(false);
    consulta(`api/v1.0/internacionalizacion/solicitud/adjunto/${id}/validar`, { validacion, nota }, "put",
      async (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            mostrarModalNegarAdjunto(true);
            mostrarModalAprobarAdjunto(true);
          }
          adjuntos = await obtenerAdjuntos(solicitud);
        }
        this.setState({ adjuntos, cargando: false });
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      })
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm")) {
      this.setState({ administrador: true })
    }
  }

  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'detalle' && this.pintarSolicitud()}
        {/* {seleccion === 'financiaciones' && this.pintarFinanciaciones()} */}
        {seleccion === 'participantes' && this.pintarParticipantes()}
        {seleccion === 'documentos' && this.pintarRequisitos()}
        {/* {seleccion === 'adjuntos' && this.pintarRequisitos()} */}
        {seleccion === 'estados' && this.pintarEstados()}
        {seleccion === 'descargar_pdf' && this.pintarDescargarPDF()}
      </div>
    )
  }

  pintarSolicitud = () => {
    let { modalListaUniversidades, mostrarModalListaUniversidades, solicitud, actualizarDataSolicitudes, cambiarSeleccionProc, actualizarEstadosRegMovInt } = this.props;
    let { datos, tipo_solicitud, modal_facultades_departamentos, modal_programas, modal_unidades_adm, modal_financiaciones, cargando, tipo_paso_infoconveni, pasocumplimientorequisito, pasotipoactividad, actividad_movilidad, subtipo_solicitud } = this.state;
    const configDetail = valores => {
      let valores_lista = valores.map(val => ({ "label": 'Nombre: ', "value": val.nombre }));
      return valores_lista.length > 0 ? valores_lista : [{ "label": <InfoIcon />, "value": 'Lista vacia' }];
    }
    if (datos[0] || solicitud) {
      return (
        <div>
          {
            tipo_solicitud == 'Mov_Est_Int' &&
            <MovEstudiantilDetalle
              solicitud={datos[0]}
            />
          }
          {
            tipo_solicitud == 'Reg_Mov_Int' &&
            <RegistroMovilidadDetalle
              solicitud={solicitud}
              actividad_movilidad={actividad_movilidad}
              cargando={cargando}
              cambiarSeleccionProc={() => cambiarSeleccionProc('Reg_Mov_Int')}
              actualizarEstadosRegMovInt={actualizarEstadosRegMovInt}
              cerraModalDetalleSolic={() => this.cerraModalDetalleSolic()}
            />
          }
          {
            tipo_solicitud == 'Con_Int' && datos[0] &&
            <ConvenioCrearDetalle
              solicitud={datos[0]}
              tipo_paso_infoconveni={tipo_paso_infoconveni}
              pasocumplimientorequisito={pasocumplimientorequisito}
              pasotipoactividad={pasotipoactividad}
            />
          }
          {
            (tipo_solicitud == 'Ies_Int' && subtipo_solicitud == 'institucion_sol' && datos[0]) &&
            <DetalleInstituciones datos={datos[0]} tipo_paso_infoconveni={tipo_paso_infoconveni } pasocumplimientorequisito={pasocumplimientorequisito} />
          }
          {
            (tipo_solicitud == 'Ies_Int' && (subtipo_solicitud == 'red_aca_sol' || subtipo_solicitud == 'otras_inst_sol') && datos[0]) &&
            <DetalleRed datos={datos[0]} tipo_paso_infoconveni={tipo_paso_infoconveni } pasocumplimientorequisito={pasocumplimientorequisito} />
          }

          {/* <Grid>
            <Dialog fullWidth = { true } maxWidth = "sm" open = { modalListaUniversidades } onClose = { () => mostrarModalListaUniversidades(false) }>
              <AppBarModal titulo = {'¡ Lista de instituciones !'} mostrarModal = { () => mostrarModalListaUniversidades(false) } titulo_accion = "CERRAR" accion = { () => mostrarModalListaUniversidades(false) } />
              <DialogContent style = { { padding: '0' } } className = 'scroll'>
              <ListarDatos
                id = "tbl_listas_instituciones"
                datos = { datos[0].instituciones_de_convenio }
                fila_principal = { ({ institucion }) => institucion.toUpperCase() }
                filas = {[
                  { 'id': 'valora', 'enLista': true, 'mostrar': ({ pais_vinculante }) => pais_vinculante?pais_vinculante.nombre:"", 'nombre': 'País: ' },
                ]}
              />
              </DialogContent>
              <DialogActions>
                <BtnForm texto = "CANCELAR" callback = { () => mostrarModalListaUniversidades(false) } />
              </DialogActions>
            </Dialog>
          </Grid>
          <Detalle
            titulo = {'¡ Lista de facultades responsables !' }
            open = { modal_programas }
            config = { datos[0].facultad_resp && cargando==false ? configDetail(datos[0].facultad_resp) : [] }
            cerrar = { () => this.setState({ modal_programas: false }) }
          />
          <Detalle
            titulo = {'¡ Lista de departamento responsables !' }
            open = { modal_facultades_departamentos }
            config = { datos[0].departamento_resp ? configDetail(datos[0].departamento_resp) : [] }
            cerrar = { () => this.setState({ modal_facultades_departamentos: false }) }
          />
          <Detalle
            titulo = {'¡ Lista de unidades administrativas responsables !' }
            open = { modal_unidades_adm }
            config = { datos[0].unidades_adm_resp ? configDetail(datos[0].unidades_adm_resp) : [] }
            cerrar = { () => this.setState({ modal_unidades_adm: false }) }
          /> */}
        </div>
      )
    }
  }

  pintarFinanciaciones = () => {
    let { financiaciones } = this.state;
    const accionesFinanciacionActividad = data => {
      const onClickDetalle = data => this.setState({ modal_detalle_financiacion: true, financiacion_seleccionada: data });
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      return <div>{detalle}</div>;
    }
    const configDetailFinanciacion = data => {
      let { tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop, tipo_moneda,
        valor_ext, clasificacion_de_financiacion, tipo_de_concepto, comprobante_financiacion } = data;
      return (
        [
          { 'label': 'Tipo de financiación:', 'value': tipo_financiacion ? tipo_financiacion.nombre : 'Sin diligenciar' },
          { 'label': 'Dependencia:', 'value': dependencia ? dependencia.nombre : 'Sin diligenciar' },
          { 'label': 'Fuente de financiación:', 'value': fuente_financiacion ? fuente_financiacion.nombre : 'Sin diligenciar' },
          { 'label': 'Entidad:', 'value': entidad ? entidad : 'Sin diligenciar' },
          { 'label': 'Valor COP:', 'value': valor_cop ? valor_cop : 'Sin diligenciar' },
          { 'label': 'Tipo de moneda:', 'value': tipo_moneda ? tipo_moneda : 'Sin diligenciar' },
          { 'label': 'Valor moneda extranjera', 'value': valor_ext ? valor_ext : 'Sin diligenciar' },
          { 'label': 'Clasificación de financiación:', 'value': clasificacion_de_financiacion ? clasificacion_de_financiacion.nombre : 'Sin diligenciar' },
          { 'label': 'Otro tipo de concepto:', 'value': tipo_de_concepto ? tipo_de_concepto : 'Sin diligenciar' },
          { 'label': 'Comprobante de financiación:', 'value': comprobante_financiacion ? <BtnDetalle callback={() => { }} href={comprobante_financiacion} target="_blank" color='#01579b' texto='Abrir' /> : 'Sin diligenciar' },
        ]
      )
    }
    return (
      <Grid>
        <ListarDatos
          id="tbl_financiaciones"
          titulo='Lista de Financiaciones'
          datos={financiaciones}
          sub_titulo="Lista de financiaciones"
          acciones={(row) => accionesFinanciacionActividad(row)}
          fila_principal={({ tipo_financiacion }) => "FINANCIACIÓN " + tipo_financiacion.nombre.toUpperCase()}
          filas={[
            { 'nombre': 'Tipo de financiacion: ', 'id': 'tipo_financiacion', 'mostrar': ({ tipo_financiacion }) => tipo_financiacion.nombre },
            { 'nombre': 'Clasificacion de financiacion: ', 'id': 'clasificacion_de_financiacion', 'mostrar': ({ clasificacion_de_financiacion }) => clasificacion_de_financiacion.nombre },
            { 'nombre': 'Otra clasificacion de financiacion: ', 'id': 'tipo_de_concepto', 'mostrar': ({ tipo_de_concepto }) => tipo_de_concepto },
            { 'nombre': 'Valor Peso Colombiano: ', 'id': 'valor_cop', 'mostrar': ({ valor_cop }) => "$" + valor_cop },
          ]}
        />
        <Detalle
          titulo={'¡ Detalle financiacion !'}
          open={this.state.modal_detalle_financiacion}
          config={this.state.financiacion_seleccionada.id !== 0 ? configDetailFinanciacion(this.state.financiacion_seleccionada) : []}
          cerrar={() => this.setState({ modal_detalle_financiacion: false })}
        />
      </Grid>
    )
  }

  pintarParticipantes = () => {
    let { participantes, modal_detalle_participante, participante_seleccionado, tipos_forms } = this.state;
    let { solicitud } = this.props;
    console.log(solicitud);
    const transformarDataParticipantes = data => {
      let p = [];
      if (data) {
        data.map(({ id, persona_registro: { persona_cuc, persona_inter, tipo_modalidad, tipo_movilidad }, financiacion_registro, institucion_registro }) => {
          const participante = persona_cuc ? persona_cuc : persona_inter;
          let tipo_participante = persona_cuc ? 'CUC' : 'INTER';
          p.push({
            id,
            primer_nombre: participante.primer_nombre,
            segundo_nombre: participante.segundo_nombre,
            primer_apellido: participante.primer_apellido,
            segundo_apellido: participante.segundo_apellido,
            tipo_identificacion: {
              'id': participante.tipo_identificacion ? participante.tipo_identificacion.id : null,
              'nombre': participante.tipo_identificacion ? participante.tipo_identificacion.nombre : 'Sin diligenciar'
            },
            indicativo_celular: participante.indicativo_celular,
            celular: participante.celular,
            correo_personal: participante.correo_personal,
            correo: participante.correo,
            nacionalidad: participante.nacionalidad,
            identificacion: participante.identificacion,
            tipo_movilidad: tipo_movilidad ? tipo_movilidad.nombre : 'Sin diligenciar',
            tipo_modalidad: tipo_modalidad ? tipo_modalidad.nombre : 'Sin diligenciar',
            financiacion_registro,
            isInter: !!persona_inter, // Campo adicional para identificar persona_inter
            id_participante: participante.id,
            institucion_registro,
          });
        });
      }
      return p;
    }
    participantes = transformarDataParticipantes(participantes);
    const acciones = data => {
      const onClickDetalle = data => {
        this.setState({ modal_detalle_participante: true, participante: data });
      }
      const onClickFinanciacion = data => {
        this.setState({ modal_fin_participante: true, participante: data });
      }

      const onClickInstitucion = data => {
        this.setState({ modal_insti_participante: true, participante: data });
      }
      const onClickEditar = data => {
        if (!data.isInter && tipos_forms.length > 0) {
          tipos_forms = tipos_forms.filter(tipo => tipo.codigo !== "edi_info_parti");
          this.setState({ tipos_forms });
        }
        this.setState({ editarInfo: true, dataParticipante: data, solicitud: solicitud, editar: true, id_registro: data.id, persona_inter: data.isInter });
        // this.setState({ editarParticipante: true, dataParticipante: data, solicitud: solicitud });
      }

      const onClickEditarInfo = (adjunto) => {
        this.setState({ adjunto });
        // mostrarModalNegarAdjunto(true);
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Detalle' />;
      let financiacion = (
        <Tooltip title={"Financiación"} aria-label="add">
          <span>
            <IconButton
              style={{ color: 'green' }}
              aria-label="delete"
              onClick={() => onClickFinanciacion(data)}
            >
              <AttachMoneyIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      );
      let institucion = (
        <Tooltip title={"Instituciones"} aria-label="add">
          <span>
            <IconButton
              style={{ color: 'tomato' }}
              aria-label="delete"
              onClick={() => onClickInstitucion(data)}
            >
              <SchoolIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )
      // let editar = (data.isInter && solicitud.estado_actual && solicitud.estado_actual.codigo == 'Est_Int_Cor') ? <BtnEditar texto='Editar' callback={() => onClickEditar(data)} /> : null; // Solo mostrar para persona_inter
      let editarInfo = solicitud.estado_actual.codigo == 'Est_Int_Cor' ? <Button variant="contained" color="primary" style={{ borderRadius: '20px', color: 'white' }} onClick={() => onClickEditar(data)}>Editar</Button> : null;
      return <div>{detalle}{financiacion}{institucion}{editarInfo}</div>;
    }
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ margin: '1% 1% 1% 1%' }}>
          {/* <Grid container justifyContent="flex-end" style={{ marginBottom: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white', borderRadius: '20px' }}
            >
              Agregar Participante
            </Button>
          </Grid> */}
          <Paper style={{ width: '100%', overflow: 'hidden', marginTop: '10px' }} elevation={2}>
            <TableContainer style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              {participantes.length > 0 ? (
                <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '25%', margin: '3px', textAlign: 'center' }}>NOMBRE</TableCell>
                      <TableCell style={{ width: '13%', margin: '3px', textAlign: 'center' }}>IDENTIFICACIÓN</TableCell>
                      <TableCell style={{ width: '22%', margin: '3px', textAlign: 'center' }}>TIPO MOVILIDAD</TableCell>
                      <TableCell style={{ width: '13%', margin: '3px', textAlign: 'center' }}>TIPO MODALIDAD</TableCell>
                      <TableCell style={{ width: '27%', margin: '3px', textAlign: 'center' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {participantes.map((participante, i) => {
                      let nombreCompleto = participante ? `${participante.primer_nombre ?? ''} ${participante.segundo_nombre ?? ''} ${participante.primer_apellido ?? ''} ${participante.segundo_apellido ?? ''}` : 'Sin diligenciar';
                      return (
                        <TableRow key={i++}>
                          <TableCell style={{ width: '25%', margin: '3px', textAlign: 'center' }}>{nombreCompleto.toUpperCase()}</TableCell>
                          <TableCell style={{ width: '13%', margin: '3px', textAlign: 'center' }}>{participante.identificacion}</TableCell>
                          <TableCell style={{ width: '22%', margin: '3px', textAlign: 'center' }}>{participante.tipo_movilidad ? participante.tipo_movilidad : 'Sin diligenciar'}</TableCell>
                          <TableCell style={{ width: '13%', margin: '3px', textAlign: 'center' }}>{participante.tipo_modalidad ? participante.tipo_modalidad : 'Sin diligenciar'}</TableCell>
                          <TableCell style={{ width: '27%', margin: '3px', textAlign: 'center' }}>{acciones(participante)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Grid item xs={12}>
                  <p style={{ textAlign: 'center', margin: '10px 0' }}>No hay participantes.</p>
                </Grid>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    )
  }


  // Vista que muestra los requisitos y la opcion de adjuntos adicionales también
  pintarRequisitos = () => {
    let { mostrarModalModAdjuntos, mostrarModalAprobarAdjunto, mostrarModalNegarAdjunto, admin, solicitud: { permiso } } = this.props;
    let { adjuntos, administrador } = this.state;
    let adjuntos_llenos = adjuntos.filter(adjunto => adjunto.archivo);
    const obtenerColor = ({ tipo }) => {
      let color = 'white';
      const colores = {
        '0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        '1': { 'backgroundColor': '#01519B', color },
      }
      return (colores[tipo]);
    }
    const obtenerValidacion = (validacion) => {
      const nombres = {
        '0': 'Enviado',
        '1': 'Negado',
        '2': 'Aprobado',
        '3': 'Reemplazado',
      }
      return (nombres[validacion]);
    }
    const acciones = (data) => {
      let { validacion, tipo } = data;
      const onClickEditar = (adjunto) => {
        this.setState({ adjunto });
        mostrarModalModAdjuntos(true);
      }
      const onClickAprobar = (adjunto) => {
        this.setState({ adjunto });
        mostrarModalAprobarAdjunto(true);
      }
      const onClickNegar = (adjunto) => {
        this.setState({ adjunto });
        mostrarModalNegarAdjunto(true);
      }
      let detalle = <Link href={`${data.archivo}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>;
      let negar = validacion === '0' && admin ? <BtnCancelar texto='Negar' callback={() => onClickNegar(data)} /> : '';
      let editar = (validacion === '1' || (admin && validacion === '0')) && tipo === '1' ? <BtnEditar texto='Editar' callback={() => onClickEditar(data)} /> : '';
      let aprobar = validacion === '0' && admin ? <BtnSeleccion texto='Aprobar' callback={() => onClickAprobar(data)} /> : '';
      return (permiso == 1 || administrador) ? <div>{detalle}{negar}{editar}{aprobar}</div> : <div>{detalle}{!admin && editar}</div>;
    }
    return (
      <ListarDatos
        id="tbl_mis_adjuntos"
        datos={adjuntos_llenos}
        avatar={({ requisito, nombre_archivo }) => requisito ? requisito.nombre.charAt(0).toUpperCase() : nombre_archivo.charAt(0).toUpperCase()}
        color_avatar={data => obtenerColor(data)}
        acciones={data => acciones(data)}
        fila_principal={({ requisito, nombre_archivo }) => requisito ? requisito.nombre.toUpperCase() : nombre_archivo.toUpperCase()}
        filas={[
          { 'id': 'nombre', 'mostrar': ({ requisito, nombre_archivo }) => nombre_archivo ? nombre_archivo : requisito.nombre },
          { 'id': 'validacion', 'mostrar': ({ tipo, validacion }) => tipo === '1' && obtenerValidacion(validacion) },
          { 'id': 'nota', 'mostrar': ({ nota, nota2 }) => nota2 ? nota2 : nota },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
        ]}
      />
    )
  }

  pintarEstados = () => {
    let { estados, tipo_solicitud } = this.state;
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Int_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_Int_Cor': { 'backgroundColor': '#ffc107', color },
        'Est_Int_Rec': { 'backgroundColor': '#b71c1c', color },
        'Est_Int_Hom': { 'backgroundColor': '#01519B', color },
        'Est_Int_Rev': { 'backgroundColor': '#32C79E', color },
        'Est_Int_Pos': { 'backgroundColor': '#6D32C7', color },
        'Est_Int_Doc': { 'backgroundColor': '#009688', color },
        'Est_Int_Ace': { 'backgroundColor': '#C0C732', color },
        'Est_Int_Fin': { 'backgroundColor': '#C74632', color },
        'Est_Int_Neg': { 'backgroundColor': '#C77332', color },
      }
      return (colores[codigo])
    }
    const obtenerColorConv = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_Int_Bor': { 'backgroundColor': '#EEEEEE', 'color': '#757575' },
        'Est_Int_Env': { 'backgroundColor': '#01579b', color },
        'Sol_aval_jefe': { 'backgroundColor': '#ffc107', color },
        'En_Revi_Con': { 'backgroundColor': '#ffc107', color },
        'Con_rev_sec': { 'backgroundColor': '#ffc107', color },
        'Con_corr': { 'backgroundColor': '#ffc107', color },
        'Con_rev_ins_vin': { 'backgroundColor': '#ffc107', color },
        'Est_Int_Can': { 'backgroundColor': '	#FF0000', color },
        'Con_rev_sec_gen_2': { 'backgroundColor': '#ffc107', color },
        'con_corr_2': { 'backgroundColor': '#ffc107', color },
        'Con_ava_sec_gen': { 'backgroundColor': '	#ffc107', color },
        'Rev_ges_conv_2': { 'backgroundColor': '#ffc107', color },
        'Fir_ins_vincu': { 'backgroundColor': '#ffc107', color },
        'Ges_fir_sg': { 'backgroundColor': '#ffc107', color },
        'Con_sus': { 'backgroundColor': '#ffc107', color },
        'En_corr_soli': { 'backgroundColor': '#ffc107', color },
        'Fir_cuc': { 'backgroundColor': '#ffc107', color },
        'Con_aval_juri_ins_vin': { 'backgroundColor': '#ffc107', color },
        'sin_conv_uni': { 'backgroundColor': '#EEEEEE', color },
        'Con_regis': { 'backgroundColor': '#008000', color },
        'Sol_rechazada': { 'backgroundColor': '#FF0000', color },
      }
      return (colores[codigo])
    }
    return (
      <ListarDatos
        id="tbl_estados_solicitud"
        datos={estados}
        avatar={({ tipo_solicitud, tipo_estado }) => tipo_solicitud === 'Con_Int' ? tipo_estado.valora.charAt(0).toUpperCase() : tipo_estado.codigo.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => tipo_solicitud === 'Con_Int' ? obtenerColorConv(codigo) : obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
        filas={[
          { 'id': 'usuario_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'observaciones', 'enLista': true, 'mostrar': ({ observacion }) => observacion },
        ]}
      />
    )
  }

  pintarDescargarPDF = () => {
    let { solicitud: { id } } = this.props;
    let { descargandopdf } = this.state;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ margin: '1% 1% 1% 1%' }}>
          <Grid container justifyContent="center" >
            {
              descargandopdf ?
                <TareasTerminadas mensaje='Descargando...' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={descargandopdf} />
                :
                <div onClick={() => this.setState({ descargandopdf: true, id_solicitud: id })} className='pointer'>
                  <TareasTerminadas mensaje='Click aquí para descargar el PDF..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                </div>
            }
            {/* <Button
              variant="contained"
              color="primary"
              style={{ color: 'white', borderRadius: '30px' }}
              onClick={() => console.log("hol")}
            >
              Descargar PDF
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  render() {
    let { admin, actualizarMensaje, modalDetSolicitud, mostrarModalDetSolicitud, modalModAdjuntos,
      modalAprobarAdjunto, modalNegarAdjunto, mostrarModalModAdjuntos, mostrarModalAprobarAdjunto, mostrarModalNegarAdjunto,
      mostrarModalAdjuntarDocumentos, solicitud: { estado_actual, tipo_solicitud, id }, obtenerParticipantesRegistro, } = this.props;
    let { adjunto, seleccion, cargando, modal_detalle_participante, participante, cargandoInfo, modal_fin_participante, financiacion, editarParticipante, dataParticipante, modal_insti_participante, institucion_participante, id_solicitud, editarInfo, tipos_forms, actividad_movilidad, id_registro, persona_inter,  } = this.state;
    return (
      <> 
        <Dialog open={modalDetSolicitud} maxWidth={seleccion == 'participantes' ? 'lg' : 'md'} /* "md o sm" */ fullWidth={true} onClose={mostrarModalDetSolicitud.bind(this, false)}>
          <AppBarModal titulo='¡ Detalle Solicitud !' mostrarModal={mostrarModalDetSolicitud} titulo_accion='' />
          <SolicitudesMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} tipo_solicitud={tipo_solicitud && tipo_solicitud.codigo} admin={admin} />
          <DialogContent style={{ padding: 0 }} className='scroll' >
            {
              !cargando ?
                this.pintar()
                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
            <EnviarArchivo
              enviarArchivo={(archivo) => archivo ? this.reemplazarAdjunto(archivo) : actualizarMensaje({ titulo: 'Seleccione Archivo', tipo: 'info', mostrar: true, tiempo: 6000 })}
              modalAdd={modalModAdjuntos}
              mostrarModalAdd={mostrarModalModAdjuntos}
              titulo='Cargar Archivo'
            />
            <EnviarMensaje modal={modalNegarAdjunto} mostrarModal={mostrarModalNegarAdjunto} titulo='¿ Negar Requisito ? ' label='Observaciones' type='text' enviar={(mensaje) => this.validarAdjunto(adjunto, 1, mensaje)} />
            <ConfirmarAccion mensaje1={`El adjunto seleccionado será aprobado, `} ejecutarAccion={() => this.validarAdjunto(adjunto, 2)} mostrarModalConfAccion={mostrarModalAprobarAdjunto} modalConAccion={modalAprobarAdjunto} dato={adjunto} titulo={'¿ Aprobar Archivo ?'} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalDetSolicitud(false)} />
          </DialogActions>
          {
            (!admin && seleccion === 'adjuntos')
            && (estado_actual && estado_actual.codigo != 'Est_Int_Can' && estado_actual.codigo != 'Est_Int_Bor' && estado_actual.codigo != 'Est_Int_Neg' && estado_actual.codigo != 'Est_Int_Fin')
            && <Agregar actAgregar={() => mostrarModalAdjuntarDocumentos(true)} iconoFab={<AddIcon />} />
          }
        </Dialog>
        {
          modal_detalle_participante && participante &&
          <DetalleParticipante modalDetParticipante={modal_detalle_participante} cerrarModal={() => this.setState({ modal_detalle_participante: false })} participanteDetalle={participante} cargando={cargandoInfo} />
        }
        {
          modal_fin_participante &&
          <FinanciacionParticipante modal_fin_participante={modal_fin_participante} cerrarModal={() => this.setState({ modal_fin_participante: false })} cargando={cargandoInfo} financiacion={financiacion} />
        }
        {
          editarParticipante && dataParticipante &&
          <EditarParticipanteExt participante={dataParticipante} resetState={() => this.setState({ editarParticipante: false, dataParticipante: {} })} actualizarMensaje={actualizarMensaje} obtenerParticipantesRegistro={obtenerParticipantesRegistro} id_solicitud={id} consultaParticipantes={(data) => this.setState({ participantes: data })} setCargando={(value) => this.setState({ cargando: value })} />
        }
        {
          modal_insti_participante &&
          <InstitucionParticipante modal_insti_participante={modal_insti_participante} cerrarModal={() => this.setState({ modal_insti_participante: false })} cargando={cargando} institucion_participante={institucion_participante} cargarInfoInstParticipante={this.cargarInfoInstParticipante} />
        }
        {
          id_solicitud !== 0 &&
          <DescargarPdfRegistro id_solicitud={id_solicitud} data={[]} no_cargando={() => this.setState({ descargandopdf: false, id_solicitud: 0 })} />
        }

        {
          editarInfo && <EditarInformacion tipos_forms={tipos_forms} onClose={() => this.setState({ editarInfo: false })} id_registro={id_registro} actualizarMensaje={actualizarMensaje} editarParticipante={() => this.setState({ editarParticipante: true })} persona_inter={persona_inter} id_solicitud={id} mostrarModalDetSolicitud={() => mostrarModalDetSolicitud(false)} obtenerInfoSolicitud={()=>this.obtenerInfoSolicitud(id, tipo_solicitud.codigo)} />
        }

        {/* {modalContenedor && <ModalContenedor state={this.state} actions={this} />} */}
        

      </>
    )
  }
}

export default InternacionalizacionDetalle;