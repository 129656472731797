import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
// import moment from 'moment'

export default function ProcesoDetalleSimple({ proceso }) {
    let {
        vacante: {
            titulo,
            descripcion,
            anos,
            meses,
            nombre_cargo,
            salario,
            fecha_vencimiento,
            profesion,
        },
        estado_proceso:{
            nombre,
        }
    } = proceso;

    return (
        <Table>
            <TableBody>

                <TableRow key={1}>
                    <TableCell component="th" scope="row">Titulo</TableCell>
                    <TableCell align="left">{`${titulo}`}</TableCell>
                </TableRow>
                <TableRow key={2}>
                    <TableCell component="th" scope="row">
                        Descripción de la vacante
                    </TableCell>
                    <TableCell align="left">{`${descripcion}`}</TableCell>
                </TableRow>
                <TableRow key={3}>
                    <TableCell component="th" scope="row">
                        Experiencia relacionada(años)
                    </TableCell>
                    <TableCell align="left">{`${anos}`}</TableCell>
                </TableRow>
                <TableRow key={4}>
                    <TableCell component="th" scope="row">
                        Experiencia relacionada(Meses)
                    </TableCell>
                    <TableCell align="left">{`${meses}`}</TableCell>
                </TableRow>
                <TableRow key={5}>
                    <TableCell component="th" scope="row">Cargo</TableCell>
                    <TableCell align="left">{`${nombre_cargo}`}</TableCell>
                </TableRow>
                <TableRow key={6}>
                    <TableCell component="th" scope="row">
                        Fecha de vencimiento
                    </TableCell>
                    <TableCell align="left">{`${fecha_vencimiento}`}</TableCell>
                </TableRow>
                <TableRow key={7}>
                    <TableCell component="th" scope="row">
                        Profesión
                    </TableCell>
                    <TableCell align="left">{`${profesion}`}</TableCell>
                </TableRow>
                <TableRow key={8}>
                    <TableCell component="th" scope="row">Salario</TableCell>
                    <TableCell align="left">{salario}</TableCell>
                </TableRow>
                <TableRow key={8}>
                    <TableCell component="th" scope="row">Estado del Proceso</TableCell>
                    <TableCell align="left">{nombre}</TableCell>
                </TableRow>
                <TableRow key={8}>
                    <TableCell component="th" scope="row">Ten en cuenta lo siguiente, el estado {nombre}</TableCell>
                    <TableCell align="left">{proceso.estado_proceso.descripcion}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}