// React
import React, { Fragment, PureComponent } from 'react';
// Material-UI Components
import { Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import diplomadoIcon from '../../global/imagenes/diploma.png';
// Redux
import { connect } from 'react-redux';
import { actualizarDataPersona, actualizarDataPersonas, actualizarRenderPersona, mostrarModalAddPersona } from '../../redux/actions/actPersonas';
import { actualizarMensaje, mostrarModalAdjFaltantes, mostrarModulo } from './../../redux/actions/actGlobal';
import {
	setAdjuntosEstado,
	setDataSolicitud,
	setEstado,
	setEstadosSolicitud,
	setEventos,
	setTutores,
	showModalAdjuntos,
	showModalDetalleGrado,
	showModalEventos,
	showModalFiltros,
	showModalGestionarSolicitud,
	showModalModSolicitud,
	showModalNewEvento,
	showModalNewGestor,
	showModalPermisoEstadoGestor,
	showModalProgramaGestor,
	showModalTrabajoGrado,
	solicitudesGrados
} from './../../redux/actions/actGrados';
// Components
import AccionesRapidas from '../general/AccionesRapidas';
import { BtnForm, BtnRegresar, BtnSeleccion, BtnVer } from "../general/BotonesAccion";
import EnviarMultiplesArchivos from "../general/EnviarMultiplesArchivos";
import TituloAccion from '../general/TituloAccion';
import TareasTerminadas from './../general/TareasTerminadas';
import Grado from './Grado';
import GradosAdjuntos from './GradosAdjuntos';
import GradosFiltrar from './administrar/GradosFiltrar';
import GradosGestionar from './administrar/GradosGestionar';
import GradosMenu from './administrar/GradosMenu';
import GradosPermisos from './administrar/GradosPermisos';
import PersonasExternas from './administrar/PersonasExternas';
import GradosListar from './historial/GradosListar';
// Imagenes
import fechaIcon from '../../global/imagenes/calendar.png';
import mensajesIcon from '../../global/imagenes/email.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import emma_w from '../../global/imagenes/emma_w.png';
import habla from '../../global/imagenes/habla.png';
import permisosIcon from '../../global/imagenes/lightbulb.png';
// Funciones
import moment from 'moment';
import { consulta, crear_form_data, formulario, generarFiltros, guardarValorGenerica, mostrarError, obtenerMisDatos, obtenerValoresFiltros } from "../../global/js/funciones";
import AppBarModal from '../general/AppBarModal';
import GradosPermisos2 from './administrar/GradosPermisos2';
import { getGradosAdm, obtenerAdjuntos, obtenerColorAvatarSolicitud } from './funciones';
import { PERSONAS_EXTERNAS } from "./helper";
import MatriculaValoresGenericas from "../matriculas/matriculas/MatriculaValoresGenericas";


class GradosAdm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			seleccion: 0,
			cargando: true,
			accion: "procesos",
			accionAdm: 'inicio',
			seleccion_detalle: "detalle",
			numAlertas: 0,
			enNotificacion: '',
			notificaciones: [],
			adj_faltantes: [],
			perfil: { tipo: "", administrador: false },
			cargando_envio: false,
			data_session: {},
			periodo: '',
			estados_procesos: [],
			accionProceso: 'inicio',
			periodo_filtro: null,
			periodos: [],
			cargandoPeriodo: true,
			filtrarPeriodo: false,
			cargarFiltroP: true,
		};
	}
		//ejemplo
	async componentDidMount() {
		const { mostrarModulo } = this.props;
		let data_session = await obtenerMisDatos()
		mostrarModulo({ ruta: '/gestion_grados', nombre: 'Gestión Opciones de Grado' });
		let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Gra' }]);
		await this.obtenerEstadoSolicitudes(periodo[0].nombre)
		this.isAdministrador()
		this.obtenerAlertas()
		this.setState({ data_session, periodo: periodo[0].nombre, periodo_filtro: periodo[0].nombre })
	}

	renderValoresGenericas = (idgenerica, tipo) =>{
		this.cambiarEstado({ cargando: true})
    	return <MatriculaValoresGenericas idGenerica = {idgenerica} tipo = {tipo}/>
 	}

	componentDidUpdate({ }, { accion, accionAdm, accionProceso }) {
		let { accion: accionNew, accionAdm: accionAdmNew, enNotificacion: enNotificacionNew, periodo, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
		if (accion !== accionNew) {
			this.cambiarEstado({ cargando: true, periodo_filtro: periodo })
			if (accionNew === 'procesos' && enNotificacionNew !== 'si') this.obtenerEstadoSolicitudes(periodo)
			if (accionNew === 'externos') this.getExternos()
			if (accionNew === 'personal') this.getPersonal()
			if (accionNew === 'administrar') this.setState({ cargando: false, accionAdm: "inicio" })
		}
		if (accionAdm !== accionAdmNew) {
			this.cambiarEstado({ cargando: true })
			if (accionAdmNew === 'permisosDirectores') this.getAdministradoresModulo(2);
			if (accionAdmNew === 'permisosCoorTutores') this.getAdministradoresModulo(3);
			if (accionAdmNew === 'diplomados')  this.setState({ cargando: false });
			if (accionAdmNew === 'inicio') this.setState({ cargando: false });
		}
		if (accionProceso !== accionProcesoNew) {
			if (accionProcesoNew != 'Tod_Gra') {
				this.setState({ cargando: true })
				if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
				else this.getGrados([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
			}
		}
	}

	isAdministrador() {
		let { usuario } = this.props;
		if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Adm_Gra")) {
			this.setState({ perfil: { tipo: usuario.perfil.codigo, administrador: true } })
		}
		else {
			this.setState({ perfil: { tipo: usuario.perfil.codigo, administrador: false } })
		}
	}

	trasnformarDataAdj = (data) => {
		let adj_faltantes = [];
		data.map(({ secundario: { nombre, descripcion, id, archivo, valord, valorc } }) => {
			adj_faltantes.push({ nombre, descripcion, codigo: id, archivo, valord, tipo: valorc });
			return true;
		})
		this.setState({ adj_faltantes });
	}

	async obtenerAlertas() {
		let mensajes = await this.getMensajes();
		let sol_vencidas = await this.getGradosAlertas();
		let notificaciones = [];

		const accionesMensajes = (data) => {
			const onClickSeleccionar = ({ id }) => {
				this.gestionarMensaje(id);
			}

			const onClickAbrir = ({ grado }) => {
				this.setState({ accionProceso: 'Tod_Gra' });
				this.getGrados([{ 'llave': 'id', 'valor': grado }])
			}

			return <><BtnSeleccion callback={() => onClickSeleccionar(data)} texto='Leído' /> <BtnVer callback={() => onClickAbrir(data)} texto='Ver' /></>;
		}

		const accionesSolicitudes = (data) => {
			const onClickAbrir = ({ id }) => {
				this.setState({ accionProceso: 'procesos' });
				this.getGrados([{ 'llave': 'id', 'valor': id }])
			}
			return <BtnVer callback={() => onClickAbrir(data)} texto='Ver' />;
		}


		mensajes.map((data) => {
			let { tipo, mensaje, fecha_registro, usuario_registro } = data;
			let notificacion = {}
			if (tipo === "1") {
				notificacion = {
					icono: mensajesIcon,
					titulo: '¡ Nuevo Mensaje !',
					mensaje: `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} dice : ${mensaje}`,
					fecha: `Registrado el ${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
					tipo: 'mensaje',
					acciones: () => accionesMensajes(data),
				}
			}
			else {
				notificacion = {
					icono: habla,
					titulo: '¡ Solicitud de Prórroga !',
					mensaje: `El Estudiante ${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ha solicitado prórroga para sustentar.`,
					motivo: `Motivo: ${mensaje}`,
					fecha: `Registrado el ${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
					tipo: 'mensaje',
					acciones: () => accionesMensajes(data),
				}
			}
			notificaciones.push(notificacion)
		});


		sol_vencidas.map((data) => {
			let { limite_estado_actual, usuario_registro } = data;
			let notificacion = {
				icono: fechaIcon,
				titulo: `¡ Proceso Atrasado !`,
				mensaje: `La solicitud de ${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} está atrasada, gestione inmediatamente.`,
				fecha: `Fecha Límite: ${moment(limite_estado_actual).format('YYYY-MM-DD')}`,
				tipo: 'mensaje',
				acciones: () => accionesSolicitudes(data),
			}
			notificaciones.push(notificacion)
		})

		this.setState({ notificaciones });
	}

	getGradosAlertas = async (filtros = [{ 'llave': 'limite', 'valor': moment().format('YYYY-MM-DD') }]) => {
		let f = await generarFiltros(filtros);
		return new Promise(resolve => {
			consulta(`api/v1.0/grados/1?${f}`, null, null,
				(error, estado, resp) => {
					resolve(resp);
				}
			)
		})
	}

	getMensajes = async () => {
		return new Promise(resolve => {
			consulta(`api/v1.0/grados/comentarios?leido=0`, null, null,
				(error, estado, resp) => {
					resolve(resp);
				}
			)
		})
	}

	// Handlers
	cambiarEstado = (estados) => this.setState(estados)

	getAdministradoresModulo = (tipo) => {
		let { actualizarDataPersonas } = this.props
		consulta(`api/v1.0/grados/administradores/${tipo}`, null, null, (error, estado, resp) => {
			actualizarDataPersonas(resp)
			this.cambiarEstado({ cargando: false })
		})
	}

	getGrados = (filtros) => {
		let { solicitudesGrados } = this.props
		this.cambiarEstado({ cargando: true })
		getGradosAdm(filtros, (estado, resp) => {
			if (estado === 200) {
				solicitudesGrados(resp)
				this.cambiarEstado({ cargando: false })
			}
		});
	}

	getAdjFaltantes = () => {
		let { solicitud } = this.props;
		consulta(`api/v1.0/grados/${solicitud.id}/adjuntos/faltantes/2`, null, null,
			(error, estado, resp) => {
				if (estado === 200) {
					this.trasnformarDataAdj(resp);
					this.notificacionAdjuntosPendientes(resp);
				}
			})
	}

	getExternos = () => {
		let { actualizarDataPersonas } = this.props
		// ojo
		consulta(`api/v1.0/generica/${PERSONAS_EXTERNAS}/valores`, null, null, (error, estado, resp) => {
			actualizarDataPersonas(resp)
			this.cambiarEstado({ cargando: false })
		})
	}

	getPersonal = () => {
		let { actualizarDataPersonas } = this.props
		consulta(`api/v1.0/grados/personal`, null, null, (error, estado, resp) => {
			actualizarDataPersonas(resp)
			this.cambiarEstado({ cargando: false })
		})
	}

	// Accciones

	gestionarGrado = async (grado, estado, mensaje = '', coordinador, tutor, cotutor, fecha_sustentacion, hora_inicio, hora_fin, tipo_sustentacion, lugar_sustentacion, evaluadores, nota, condecoracion, actasFaltantes) => {
		let { showModalAdjuntos, showModalGestionarSolicitud, actualizarMensaje } = this.props
		let { accionProceso, periodo_filtro } = this.state
		let total_evaluadores = evaluadores.length
		let total_archivos = actasFaltantes.length
		let data = await crear_form_data({ estado, mensaje, coordinador, tutor, cotutor, fecha_sustentacion, hora_inicio, hora_fin, tipo_sustentacion, lugar_sustentacion, total_evaluadores, nota, condecoracion, total_archivos })

		evaluadores.map(({ id, tipo }, index) => {
			data.append(`evaluador${index}`, id);
			data.append(`evaluador_tipo${index}`, tipo);
		})
		actasFaltantes.map(({ archivo, codigo }, index) => {
			data.append(`archivo${index}`, archivo);
			data.append(`codigo${index}`, codigo);
		})

		this.setState({ cargando_envio: true })
		formulario(`api/v1.0/grados/${grado}/gestionar`, data, "post", (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.'
			let tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					showModalGestionarSolicitud(false);
					showModalAdjuntos(false);
				} else if (estado === 302) {
					tipo = 'info';
					titulo = resp.titulo;
					obtenerAdjuntos(this.props.solicitud.id, (resp) => this.props.setAdjuntosEstado(resp))
				} else if (estado === 303) {
					tipo = 'warning';
					titulo = resp.titulo;
				}
				else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			this.getGrados([{ 'llave': 'estado_actual__codigo', 'valor': accionProceso }, { 'llave': 'periodo', 'valor': periodo_filtro }, {'llave': 'estado_actual__valora', 'valor': 1}]);
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			this.setState({ cargando_envio: false })
		})
	}

	guardarPersonaExterna = async (codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => {
		let { mostrarModalAddPersona, actualizarMensaje } = this.props;
		let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
		// ojo
		let { error, estado, resp } = await guardarValorGenerica(PERSONAS_EXTERNAS, codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo)
		if (!error) {
			if (estado === 200) {
				tipo = 'success'
				titulo = resp.titulo
				this.getExternos()
				mostrarModalAddPersona(false)
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp)
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
	}

	guardarPersonal = (persona, relaciones, tipo) => {
		let { actualizarMensaje } = this.props;
		consulta(`api/v1.0/personas/${persona}/genericas/crear`, { relaciones, tipo }, "post",
			(error, estado, resp) => {
				let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
				if (!error) {
					if (estado === 200) {
						tipo = 'success';
						titulo = resp.titulo;
						this.getPersonal();
					} else {
						tipo = 'info';
						titulo = resp.titulo ? resp.titulo : mostrarError(resp);
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			}
		);
	}

	gestionarMensaje = (id, data = { leido: 1 }) => {
		let { actualizarMensaje, } = this.props;
		consulta(`api/v1.0/grados/comentario/${id}`, data, "patch", async (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.obtenerAlertas();
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp)
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		}
		);
	}

	// Obtener estados
	obtenerEstadoSolicitudes = async (periodo) => {
		consulta(`api/v1.0/grados/estados/${periodo}`, null, null, (error, estado, resp) => {
			if (estado === 200) {
				let { general, extra } = resp
				if (extra.length > 0) {
					extra.map(({ solicitudes, nombre, codigo, orden }) => {
						let reporte = {
							'estado_actual__codigo': codigo,
							'estado_actual__nombre': nombre,
							'num_sol': solicitudes,
							'orden': orden
						}
						general.push(reporte);
					})
				}
				this.setState({ estados_procesos: general.sort((a, b) => a.orden - b.orden), cargando: false });
			}
		})
	}

	ModalfiltroPeriodo = async () => {
		let { cargarFiltroP, periodo } = this.state
		this.cambiarEstado({ filtrarPeriodo: true })
		if (cargarFiltroP) {
			this.cambiarEstado({ cargandoPeriodo: true })
			let periodos = await this.getPeriodos();
			if (!(periodos.find(e => e.periodo == periodo))) periodos.push({ 'periodo': periodo })
			this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
		}
	}

	getPeriodos = () => {
		return new Promise(resolve => {
			consulta(`api/v1.0/grados/periodos`, null, null,
				(error, estado, resp) => {
					resolve(resp)
				}
			);
		})
	}

	filtrarEstadoSolicitudes = () => {
		let { periodo_filtro } = this.state
		this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
		this.obtenerEstadoSolicitudes(periodo_filtro)
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		})
	}

	configFiltros = (accion_actual) => {
    let { periodo_filtro } = this.state
    switch (accion_actual) {
      case 'Tod_Pra':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      case 'inicio':
      return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
    }
  }

	// Componentes

	renderEstados = () => {
		let {showModalFiltros} = this.props;
		let { estados_procesos, cargandoPeriodo, filtrarPeriodo, periodo_filtro, periodos } = this.state

		const handleClick = (codigo) => {
			if (codigo != 'Tod_Gra') this.setState({  accionProceso: codigo })
			else {
				showModalFiltros(true);
			}
		};
		return (
			<>
			<div style={{ padding: '2% 2% 0% 2%' }} >
				<Grid container direction={'row'} spacing={1} justify={'center'} >
					<Grid item xs={12} sm={12} md={12} lg={10} >
						<Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
							<Grid item xs={6} sm={6} md={6} lg={6} >
								<TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={6} >
								<Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
									<IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
								</Tooltip>
							</Grid>
						</Grid>
						<Paper elevation={0} square className="scroll contenido_fijo">
							{(estados_procesos.length === 0) ?
								<TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
								<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
									{estados_procesos.map((item, i) => {
										return (
											<Grid item xs={12} sm={12} md={6} lg={6} key={i}>
												<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(item.estado_actual__codigo)}>
													<AccionesRapidas imagen='' titulo={`¡ ${item ? item.estado_actual__nombre : ''} !`} sub_titulo='' des_sub_titulo='' avatar={Object.entries(item).length > 0 ? obtenerColorAvatarSolicitud(item.estado_actual__codigo) : ''} list_desc={Number.isInteger(item.num_sol) ? [`Numero de Solicitudes : ${item.num_sol}`] : ['Debe aplicar los filtros.']} avatar_name={Object.entries(item).length > 0 ? item.estado_actual__nombre.charAt(0).toUpperCase() : ''} />
												</Paper>
											</Grid>
										)
									})}
								</Grid>
							}
						</Paper>
					</Grid>
				</Grid>
				</div>
				
				<Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
					<DialogContent className='scroll'>
						{!cargandoPeriodo ?
							<Grid container spacing={13} className='margin_cont' >
								<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
									<FormControl className='form-control'>
										<InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
										<Select
											inputProps={{
												name: 'periodo_filtro',
												id: 'periodo_filtro',
											}}
											value={periodo_filtro}
											onChange={this.onChange}
											required={true}>
											{periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							: <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' cargando={true} />
						}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
						<BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
					</DialogActions>
				</Dialog >
			</>
		)
	}

	pintarSolicitud = () => {
		let {
			grados,
			estado,
			solicitud,
			setDataSolicitud,
			setAdjuntosEstado,
			setEstado,
			showModalDetalleGrado,
			showModalAdjuntos,
			showModalGestionarSolicitud,
			showModalFiltros,
		} = this.props;
		let {accionProceso, periodo_filtro} = this.state 
		return (
			<GradosListar
				admin={true}
				grados={grados}
				solicitud={solicitud}
				estado={estado}
				setEstado={setEstado}
				setDataSolicitud={setDataSolicitud}
				setAdjuntosEstado={setAdjuntosEstado}
				showModalDetalleGrado={showModalDetalleGrado}
				showModalAdjuntos={showModalAdjuntos}
				showModalGestionarSolicitud={showModalGestionarSolicitud}
				showModalFiltros={showModalFiltros}
				refrescar={() => this.getGrados([{ 'llave': 'estado_actual__codigo', 'valor': accionProceso }, { 'llave': 'periodo', 'valor': periodo_filtro }, {'llave': 'estado_actual__valora', 'valor': 1}])}
			/>
		)
	}

	renderAdministradores = () => {
		let { persona, personas, modalProgramaGestor, modalPermisoEstadoGestor, actualizarDataPersona, showModalProgramaGestor, showModalPermisoEstadoGestor } = this.props
		return (
			<div >
				<GradosPermisos2
					// persona={persona}
					// personas={personas}
					actProgramas={true}
					actEstados={true}
					actualizarDataPersona={actualizarDataPersona}
					modalProgramaGestor={modalProgramaGestor}
					modalPermisoEstadoGestor={modalPermisoEstadoGestor}
					showModalProgramaGestor={showModalProgramaGestor}
					showModalPermisoEstadoGestor={showModalPermisoEstadoGestor}
					guardarPersonal={this.guardarPersonal}
				/>
			</div>
		)
	}

	renderExternos = () => {
		let { personas, modalAddPersona, mostrarModalAddPersona, actualizarMensaje } = this.props
		return (
			<div>
				<PersonasExternas
					personas={personas}
					modalAddPersona={modalAddPersona}
					mostrarModalAddPersona={mostrarModalAddPersona}
					guardarPersonaExterna={this.guardarPersonaExterna}
					actualizarMensaje={actualizarMensaje}
				/>
			</div>
		)
	}

	renderPersonal = () => {
		let { persona, personas, modalProgramaGestor, modalPermisoEstadoGestor, actualizarDataPersona, showModalProgramaGestor, showModalPermisoEstadoGestor } = this.props
		return (
			<div>
				<GradosPermisos
					persona={persona}
					personas={personas}
					actProgramas={false}
					actEstados={false}
					actualizarDataPersona={actualizarDataPersona}
					modalProgramaGestor={modalProgramaGestor}
					modalPermisoEstadoGestor={modalPermisoEstadoGestor}
					showModalProgramaGestor={showModalProgramaGestor}
					showModalPermisoEstadoGestor={showModalPermisoEstadoGestor}
					guardarPersonal={this.guardarPersonal}
				/>
			</div>
		)
	}

	renderProcesos() {
		let { accionProceso, cargando } = this.state;
		return (
			<>
				{
					!cargando ?
						<>
							{accionProceso === 'inicio' ? this.renderEstados() : this.pintarSolicitud()}
							{accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionProceso: 'inicio' })} /></div>}
						</> : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='7%' marginTop='7%' cargando={true} />
				}
			</>
		)
	}

	pintar = () => {
		let { accion, cargando, perfil, notificaciones } = this.state
		return (
			<Fragment>
				<GradosMenu
					perfil={perfil}
					notificaciones={notificaciones}
					seleccionar={
						(accion) => {
							if (accion === 'notificacion') {
								this.setState({ enNotificacion: 'si' });
								this.obtenerAlertas();
							} else this.setState({ enNotificacion: 'no', accion })
						}
					}
					sele={this.state.accion} />
				{!cargando ?
					<div className='contenido_modulo'>
						{accion === 'procesos' && this.renderProcesos()}
						{accion === 'externos' && this.renderExternos()}
						{accion === 'personal' && this.renderPersonal()}
						{accion === 'administrar' && this.pintarAdministrar()}
					</div>
					: <TareasTerminadas imagen={emma_w} mensaje='Cargando...' cargando={cargando} widthImg='7%' marginTop='7%' />
				}
			</Fragment>
		)
	}

	pintarInicioAdm = () => {
		return (
			<div style={{ padding: '2% 2% 0% 2%' }} >
				<Grid container direction={'row'} spacing={1} justifyContent={'center'} >
					<Grid item xs={12} sm={12} md={10} lg={8} >
						<TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
						<Paper elevation={0} square className="scroll contenido_fijo">
							<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'permisosDirectores' })}>
										<AccionesRapidas imagen={permisosIcon} titulo='Permisos Directores de Programa !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los directores.' />
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'permisosCoorTutores' })}>
										<AccionesRapidas imagen={permisosIcon} titulo={`Permisos Coordinadores y Tutores !`} sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los Coordinadores y tutores.' />
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdm: 'diplomados' })}>
										<AccionesRapidas imagen={diplomadoIcon} titulo='¡ Administrar Diplomados !' sub_titulo='' des_sub_titulo='Crea, modifica Diplomados.' />
									</Paper>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</div>
		)
	}

	pintarAdministrar() {
		let { accionAdm, perfil } = this.state;
		if (!perfil) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
		return (
			<>
				{accionAdm === 'inicio' && this.pintarInicioAdm()}
				{accionAdm !== 'inicio' && <div className='regresar' ><BtnRegresar callback={() => this.setState({ accionAdm: 'inicio' })} /></div>}
				{accionAdm === 'permisosDirectores' && this.renderAdministradores()}
				{accionAdm === 'permisosCoorTutores' && this.renderAdministradores()}
				{/* {accionAdm === 'diplomados' && this.renderAdministradores()} */}
				{accionAdm === 'diplomados' && this.renderValoresGenericas(30, 'diplomados')}
			</>
		)

	}

	modales = () => {
		let {
			actualizarMensaje,
			estado,
			adjuntos,
			setAdjuntosEstado,
			estados,
			eventos,
			setEventos,
			setEstadosSolicitud,
			setEstado,
			setTutores,
			modalDetalleGrado,
			modalAdjuntos,
			modalGestionarSolicitud,
			modalNewGestor,
			modalFiltros,
			modalEventos,
			modalNewEvento,
			showModalAdjuntos,
			showModalDetalleGrado,
			showModalGestionarSolicitud,
			showModalNewGestor,
			showModalFiltros,
			showModalEventos,
			showModalNewEvento,
			mostrarModalAdjFaltantes,
			solicitud
		} = this.props;
		let { perfil, cargando, seleccion_detalle, adj_faltantes, cargando_envio,data_session, accionProceso } = this.state
		if (!cargando) {
			return (
				<Fragment>
					<Grado
						admin={true}
						perfil={perfil}
						solicitud={solicitud}
						estado={estado}
						adjuntos={adjuntos}
						estados={estados}
						eventos={eventos}
						sele={seleccion_detalle}
						seleccionar={(seleccion_detalle) => this.setState({ seleccion_detalle })}
						setAdjuntosEstado={setAdjuntosEstado}
						setEstadosSolicitud={setEstadosSolicitud}
						setEstado={setEstado}
						setTutores={setTutores}
						setEventos={setEventos}
						showModalAdjuntos={showModalAdjuntos}
						showModalDetalleGrado={showModalDetalleGrado}
						showModalEventos={showModalEventos}
						showModalNewEvento={showModalNewEvento}
						modalDetalleGrado={modalDetalleGrado}
						modalEventos={modalEventos}
						modalNewEvento={modalNewEvento}
						actualizarMensaje={actualizarMensaje}
						data_session={data_session}
					/>
					<GradosAdjuntos
						admin={true}
						solicitud={solicitud}
						modalAdjuntos={modalAdjuntos}
						showModalAdjuntos={showModalAdjuntos}
						mostrarModalAdjFaltantes={mostrarModalAdjFaltantes}
						adj_faltantes={adj_faltantes}
						getAdjFaltantes={this.getAdjFaltantes} />
					<GradosGestionar
						solicitud={solicitud}
						gestionarGrado={this.gestionarGrado}
						cargando_envio={cargando_envio}
						modalGestionarSolicitud={modalGestionarSolicitud}
						modalNewGestor={modalNewGestor}
						showModalGestionarSolicitud={showModalGestionarSolicitud}
						showModalNewGestor={showModalNewGestor}
						showModalDetalleGrado={showModalDetalleGrado}
						actualizarMensaje={actualizarMensaje}
					/>
					<GradosFiltrar
						modalFiltros={modalFiltros}
						showModalFiltros={showModalFiltros}
						actualizarMensaje={actualizarMensaje}
						limpiar={() => this.cambiarEstado({ accion: "procesos" })}
						filtrar={(filtros_grados) => {this.getGrados(this.configFiltros(accionProceso).concat(filtros_grados))}}
						mostrarTodos={(accion) => this.setState({ accionProceso: accion })}
						accionProceso={accionProceso}
						/>
					<EnviarMultiplesArchivos
						// acciones={this.accionesAdjFaltantes}
						modalMulArchivos={this.props.modalAdjFaltantes}
						titulo='¡ Adjuntos Pendientes !'
						enviarArchivos={(archivos) => { }}
						mostrarModalMulArchivos={() => this.props.mostrarModalAdjFaltantes(false)}
						limite={2}
						mostrarRender={true}
						mostrar={true}
						enLista={true}
						lista={this.state.adj_faltantes}
					/>
				</Fragment>
			)
		}
	}


	render() {
		return (
			<div>
				{this.pintar()}
				{this.modales()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	let {
		adjuntos,
		dataCoordinadores,
		estados,
		grados,
		eventos,
		estado,
		modalModSolicitud,
		modalDetalleGrado,
		modalAdjuntos,
		modalGestionarSolicitud,
		modalNuevoGrado,
		modalProgramaGestor,
		modalPermisoEstadoGestor,
		modalNewGestor,
		modalFiltros,
		modalEventos,
		modalNewEvento,
		solicitud,
		coordinador,
	} = state.redGrados;
	let { persona, personas, modalAddPersona } = state.redPersonas;
	let { usuario, modalAdjFaltantes } = state.redGlobal;

	return {
		usuario,
		adjuntos,
		dataCoordinadores,
		estados,
		grados,
		eventos,
		estado,
		modalModSolicitud,
		modalDetalleGrado,
		modalAdjuntos,
		modalGestionarSolicitud,
		modalNuevoGrado,
		modalProgramaGestor,
		modalPermisoEstadoGestor,
		modalNewGestor,
		modalFiltros,
		modalEventos,
		modalNewEvento,
		modalAddPersona,
		modalAdjFaltantes,
		persona,
		personas,
		solicitud,
		coordinador,
	};
};

const mapDispatchToProps = {
	actualizarDataPersona,
	actualizarDataPersonas,
	actualizarMensaje,
	mostrarModalAdjFaltantes,
	setAdjuntosEstado,
	setEstadosSolicitud,
	setTutores,
	setEstado,
	setEventos,
	mostrarModulo,
	setDataSolicitud,
	actualizarRenderPersona,
	mostrarModalAddPersona,
	showModalAdjuntos,
	showModalModSolicitud,
	showModalDetalleGrado,
	showModalTrabajoGrado,
	showModalGestionarSolicitud,
	showModalProgramaGestor,
	showModalPermisoEstadoGestor,
	showModalNewGestor,
	showModalFiltros,
	showModalEventos,
	showModalNewEvento,
	solicitudesGrados
};

export default connect(mapStateToProps, mapDispatchToProps)(GradosAdm);
