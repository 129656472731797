
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

//FUNCIONES GENERALES
import { consulta, mostrarError, formulario, crear_form_data } from "../../../global/js/funciones";

//COMPONENTES DEL MODULO
import CandidatosListar from './CandidatosListar'
import CandidatosAgregar from './CandidatosAgregar'
import ConfirmarAccion from '../../general/ConfirmarAccion';
import TareasTerminadas from '../../general/TareasTerminadas'

//IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

//ACCIONES DEL MODULO
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import { mostrarModalAddCandidatos, actualizarDataCandidatos, actualizarRenderCandidatos, mostrarModalAddCandidatosFin, mostrarModalDelCandidatos, actualizarDataCandidato } from "../../../redux/actions/actCandidatos";
import { actualizarDataPersonas } from "../../../redux/actions/actPersonas";

class Candidatos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
    };
  }

  componentDidMount() {
    let { eleccion } = this.props;
    this.obtenerCandidatos(eleccion.id);
  }

  guardarCandidato = async (candidato, foto, descripcion) => {
    let { actualizarMensaje, eleccion, mostrarModalAddCandidatosFin, actualizarDataPersonas } = this.props;
    let data = await crear_form_data({ foto, "eleccion": eleccion.id, candidato, descripcion });
    formulario(`api/v1.0/elecciones/${eleccion.id}/candidatos/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerCandidatos(eleccion.id);
          mostrarModalAddCandidatosFin(false);
          actualizarDataPersonas([]);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      // document.getElementById('txt_buscar_persona').value = '';
      // document.getElementById('txt_buscar_persona').focus();
    })
  }

  eliminarCandidato = (id) => {
    let { actualizarMensaje, mostrarModalDelCandidatos, eleccion } = this.props;
    consulta(`api/v1.0/elecciones/${eleccion.id}/candidatos/${id}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            this.obtenerCandidatos(eleccion.id);
            mostrarModalDelCandidatos(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }
  obtenerCandidatos = (eleccion) => {
    let { actualizarDataCandidatos, actualizarRenderCandidatos } = this.props;
    this.setState({ cargando: true })
    consulta(`api/v1.0/elecciones/${eleccion}/candidatos`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          actualizarDataCandidatos(resp);
          actualizarRenderCandidatos(true);
        }
        this.setState({ cargando: false })
      }
    );

  }

  render() {
    let { desactivar_acciones, eleccion, actualizarDataCandidato, modalAddCandidatos, mostrarModalAddCandidatos, candidatos, modalAddCandidatosFin, mostrarModalAddCandidatosFin, candidato, modalDelCandidatos, mostrarModalDelCandidatos } = this.props;
    return (
      <Fragment>
        {!this.state.cargando ?
          <div>
            <CandidatosListar eleccion={eleccion} desactivar_acciones={desactivar_acciones} actualizarDataCandidato={actualizarDataCandidato} mostrarModalDelCandidatos={mostrarModalDelCandidatos} mostrarModalAddCandidatos={mostrarModalAddCandidatos} candidatos={candidatos} />
            <CandidatosAgregar modalAddCandidatosFin={modalAddCandidatosFin} mostrarModalAddCandidatosFin={mostrarModalAddCandidatosFin} modalAddCandidatos={modalAddCandidatos} mostrarModalAddCandidatos={mostrarModalAddCandidatos} guardarCandidato={this.guardarCandidato} />
            <ConfirmarAccion mensaje1={`El candidato "${candidato.nombre}" será eliminado, `} ejecutarAccion={this.eliminarCandidato} mostrarModalConfAccion={mostrarModalDelCandidatos} modalConAccion={modalDelCandidatos} dato={candidato} titulo="¿ Eliminar Candidato ?" />
          </div> :
          <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
        }
      </Fragment>
    )
  }
}
//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalDelCandidatos, modalAddCandidatos, candidatos, renderCandidatos, modalAddCandidatosFin, candidato } = state.redCandidatos;
  return {
    modalAddCandidatos,
    candidatos,
    renderCandidatos,
    modalAddCandidatosFin,
    candidato,
    modalDelCandidatos,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  mostrarModalAddCandidatos,
  mostrarModalDelCandidatos,
  actualizarMensaje,
  actualizarDataCandidatos,
  actualizarRenderCandidatos,
  mostrarModalAddCandidatosFin,
  actualizarDataCandidato,
  actualizarDataPersonas
}

//Props del componente
Candidatos.propTypes = {
  //variables
  eleccion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
    fecha_inicio: PropTypes.string.isRequired,
    fecha_fin: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalAddCandidatos: PropTypes.func.isRequired,
  mostrarModalAddCandidatosFin: PropTypes.func.isRequired,
  mostrarModalDelCandidatos: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarDataCandidatos: PropTypes.func.isRequired,
  actualizarDataCandidato: PropTypes.func.isRequired,
  actualizarRenderCandidatos: PropTypes.func.isRequired,
  actualizarDataPersonas: PropTypes.func.isRequired,
  desactivar_acciones: PropTypes.func.isRequired,
}



export default connect(mapStateToProps, mapDispatchToProps)(Candidatos);
