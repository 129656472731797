import React, { useState, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../redux/actions/actInscripcionesPosgrado";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";

// Material UI
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/locale";

// General components
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";

// Global functions
import {
  modificarValorGenericaAlter,
  mostrarError,
  obtenerValoresFiltros,
} from "../../../global/js/funciones";
import moment from "moment";

const InscripcionActualizarPeriodo = (props) => {
  let { periodo, setPeriodo, setCargando } = props;
  const [periodoFormulario, setPeriodoForm] = useState("");
  const [fechaFormulario, setFechaForm] = useState(null);

  const { modalPeriodo } = useSelector(
    (state) => state.redInscripcionesPosgrado
  );

  const dispatch = useDispatch();
  const { mostrarModalPeriodo } = bindActionCreators(actionCreators, dispatch);

  const handleSubmit = async () => {
    const fecha = moment(fechaFormulario).format("YYYY-MM-DD");
    if (periodo.length > 0) {
      let titulo = "Ha ocurrido un error, contacte con el administrador.";
      let tipo = "warning";
      setCargando(true);
      let { error, estado, resp } = await modificarValorGenericaAlter(
        periodo[0].id,
        {
          nombre: periodoFormulario ? periodoFormulario : periodo[0].nombre,
          valora: fecha && fecha !== "Invalid date" ? fecha : periodo[0].valora,
        }
      );
      if (!error) {
        if (estado === 200) {
          titulo = resp.titulo;
          tipo = "success";
          obtenerPeriodo();
          setCargando(false);
          dispatch(mostrarModalPeriodo(false));
        } else {
          setCargando(false);
          tipo = "info";
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      dispatch(
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 5000 })
      );
    } else {
      dispatch(
        actualizarMensaje({
          titulo: "Error al obtener el periodo actual.",
          tipo: "warning",
          mostrar: true,
          tiempo: 5000,
        })
      );
    }
  };

  const obtenerPeriodo = async () => {
    const periodoConsulta = await obtenerValoresFiltros([
      { llave: "codigo", valor: "Per_Ins_Posg" },
    ]);
    setPeriodo(periodoConsulta);
  };

  return (
    <Dialog
      open={modalPeriodo}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => dispatch(mostrarModalPeriodo(false))}
    >
      <AppBarModal
        titulo="¡ Actualizar periodo !"
        mostrarModal={mostrarModalPeriodo}
        titulo_accion=""
      />
      <DialogContent style={{ padding: 0 }} className="scroll">
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <TextField
                id="periodo_actual"
                label="Ingrese período"
                value={
                  periodoFormulario ? periodoFormulario : periodo[0].nombre
                }
                fullWidth
                onChange={(e) => setPeriodoForm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  style={{ marginTop: 3, width: "100%" }}
                  id="fecha_periodo"
                  margin="normal"
                  value={
                    // fechaFormulario
                    fechaFormulario ?? 
                    moment(periodo[0]?.valora).format("YYYY-MM-DD") 
                  }
                  label="Fecha limite"
                  onChange={(e) => setFechaForm(e)}
                  fullWidth
                  format="yyyy-MM-dd"
                  minDate={new Date()}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                  invalidDateMessage="Formato Invalido"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <BtnForm
          texto="Cerrar"
          callback={() => dispatch(mostrarModalPeriodo(false))}
        />
        <BtnForm texto="Aceptar" callback={() => handleSubmit()} />
      </DialogActions>
    </Dialog>
  );
};

export default InscripcionActualizarPeriodo;
