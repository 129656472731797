import React, { useEffect, useState, useRef } from 'react';
import { obtenerValores, obtenerPermisosValorAlt, obtenerValoresFiltrosContenido } from '../../global/js/funciones.js'
import {
  Grid, Typography, Fab, ExpansionPanel,
  createMuiTheme, ExpansionPanelSummary,
  ExpansionPanelDetails, InputBase, useMediaQuery,
  Button, Box, Link
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Img from './../../global/imagenes/ImgPreguntas.png'
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { green } from '@material-ui/core/colors';
import Swal from 'sweetalert2'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { mostrarModulo } from "../../redux/actions/actGlobal";
import { useDispatch } from 'react-redux';
import LaunchIcon from '@material-ui/icons/Launch';
import { coloresEmma } from '../../global/js/funciones.js';
const useStyles = makeStyles((theme) => ({
  titulo: {
    paddingTop: '60px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  },
  img: {
    margin: '0 auto',
  },
  input: {
    paddingBottom: '20px',
    width: '80%',
    borderRadius: '50px',
    [`& fieldset`]: {
      borderRadius: '50px',
      background: '#ffffff',
    },
  },
  root: {
    background: coloresEmma.secondarycolor,
    width: '100%',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px'
    },
  },
  extendedIcon: {
    '& > *': {
      margin: theme.spacing(1),
    },
    marginRight: theme.spacing(1),
  },
  division: {
    height: '10%',
    [theme.breakpoints.down('sm')]: {
      height: '0px',
    },
  },
  ///////////////////////////////////////
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    width: '100%',
    paddingBottom: '5px',
    color: '#525252'
  },
  heading_item: {
    fontSize: theme.typography.pxToRem(14),
    paddingTop: '15px',
    color: '#525252'
  },
  inputRoot: {
    color: 'black',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(0)}px)`,
    width: '100%',
  },
  search: {
    borderRadius: '50px',
    backgroundColor: '#ffffff',
    width: '100%',
    height: '40px'
  },
  search2: {
    borderRadius: '50px',
    backgroundColor: '#ffffff',
    height: '40px',
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },

  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: coloresEmma.secondarycolor,
    height: '40px'
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});


const Preguntas = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [respuesta, setRespuesta] = useState([]);
  const [buscar, setBuscar] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = React.useState(false);
  const modulo = useDispatch();
  const [cargando, setCargando] = useState(true);
  //SCROLL
  const [background, setBackground] = useState(coloresEmma.secondarycolor);
  const GridBuscar = useRef();
  const GridBuscarMovil = useRef();
  const [opacity, setOpacity] = useState("0");
  const [position, setPosition] = useState('0')
  const [opacityM, setOpacityM] = useState("0");
  const [positionM, setPositionM] = useState('0')
  const [top, setTop]= useState(80)


  const handleChange = (panel, id) => (event, isExpanded) => {
    obtenerRespuesta(id);
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    modulo(mostrarModulo({ ruta: '/preguntas', nombre: 'preguntas' }));
    obtenerData();

    const handleScroll = () => {
      const grid = GridBuscar.current;
      if(grid){
        const { y } = grid.getBoundingClientRect();
        const opacityCambiar = y - 20 <= 0 ? "1" : "0"
        setOpacity(opacityCambiar)
        const positionCambiar = y - 20 <= 0 ? "fixed" : ""
        setPosition(positionCambiar)
      }


    }
    try {
      document.getElementById('seccion-vista').addEventListener('scroll', handleScroll)
    }catch (e){
      window.addEventListener('scroll', handleScroll)
      setTop(30)
    }

    return () => {
      try {
        document.getElementById('seccion-vista').addEventListener('scroll', handleScroll)
      }catch (e){
        window.addEventListener('scroll', handleScroll)
      }
    }
  }, []);

  const obtenerData = async () => {
    let pregunta = await obtenerValores(79);
    setPreguntas(pregunta)
  }
  const limpiar = () => {
    //Pintar('Combarranquilla: No se recibirán en físico. Debe ingresar a la página <link>www.combarranquilla.com.co<link> y diligenciar el formulario, adjuntar los documentos necesarios. Una vez aprobado se refleja en el volante de matrícula dentro de 3 días hábiles después de su solicitud.')
    obtenerData()
    setBuscar('');
  }

  const Buscar = async () => {
    if (buscar == '') {
      Swal.fire({ confirmButtonColor:coloresEmma.secondarycolor, title: 'El Campo es requerido' })
      obtenerData();
    } else {
      let question = await obtenerValoresFiltrosContenido(buscar, [{ 'llave': 'generica', 'valor': 79 }]);
      setPreguntas(question)
      if (question == '') {
        Swal.fire({ confirmButtonColor: coloresEmma.secondarycolor, title: 'Pregunta no encontrada' })
        obtenerData();
        setBuscar('');
      }
    }
  }

  const obtenerRespuesta = async (id) => {
    setCargando(true);
    let resp = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': id }, { 'llave': 'secundario__generica', 'valor': 80 }]);
    resp.sort(function (a, b) {
      return ((a.secundario.valorb < b.secundario.valorb) ? -1 : ((a.secundario.valorb > b.secundario.valorb) ? 1 : 0));
    })


    setCargando(false);
    setRespuesta(resp)
  }

  const handleKeyPress = (e) => {
    // console.log(buscar)
    if (e.key === 'Enter') {
      Buscar(buscar);
      e.preventDefault();
    }
  }


  const Pintar = ({ respuesta }) => {
    let resp_array = respuesta.split("<link>");
    let inicio = 1;
    // console.log(resp_array);
    let mensaje = '';
    // console.log("aqui", mensaje);
    return resp_array.map((e, i) => {
      let con = e;
      if (i == inicio) {
        con = <Link className="linkPreguntas" target="_blank" href={e}>aquí<LaunchIcon fontSize="small" style={{color:coloresEmma.secondarycolor,width:15}}/></Link>
        inicio = inicio + 2;
      }
      return con;
    });;
  }

  return (
    <div id="ScrollP">
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        style={{ background: background, width: '100%', color: '#ffffff' }}
        className="containerBuscar"
      >

        {
          isMobile ? (
            <>

              <Grid container className="Preguntas">
                <Grid item xs={12} className={classes.titulo}>
                  <Typography gutterBottom variant="h4" component="h1" style={{ letterSpacing: '6px' }}>PREGUNTAS FRECUENTES</Typography>
                  <img src={Img} width="100%" className={classes.img} />
                  <Typography variant="body1" >En este espacio podrás encontrar todas las preguntas frecuentes relacionadas con la parte académica de la universidad</Typography><br />

                  <Grid container className={classes.search} >
                    <Grid xs={6} >
                      <InputBase
                        value={buscar}
                        onChange={(e) => setBuscar(e.target.value)}
                        onKeyUp={handleKeyPress}
                        placeholder="Buscar preguntas"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                      />
                    </Grid>
                    <Grid xs={6} className={classes.searchIcon} >
                      <Button onClick={limpiar} style={{ color: coloresEmma.secondarycolor }}>
                        <DeleteForeverIcon />
                      </Button>
                      <Fab onClick={Buscar} style={{ background: '#3F4E54' }}>
                        <SearchIcon />
                      </Fab>
                    </Grid>
                  </Grid><br /><br /><br />
                </Grid>
              </Grid>


            </>
          ) : (
            <>
              <Grid container className="Preguntas">
                <Grid item xs={6} className={classes.titulo}>
                  <Typography gutterBottom variant="h4" component="h1" style={{ letterSpacing: '6px' }}>PREGUNTAS FRECUENTES</Typography>
                  <Typography variant="body1" style={{ width: '90%' }}>En este espacio podrás encontrar todas las preguntas frecuentes relacionadas con la parte académica de la universidad</Typography><br />

                  <Grid container className={classes.search}>
                    <Grid xs={6}>
                      <InputBase
                        value={buscar}
                        onChange={(e) => setBuscar(e.target.value)}
                        placeholder="Buscar preguntas"
                        onKeyUp={handleKeyPress}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                      />
                    </Grid>
                    <Grid xs={6} className={classes.searchIcon} >
                      <Button onClick={limpiar} style={{ color: coloresEmma.secondarycolor }}>
                        <DeleteForeverIcon />
                      </Button>

                      <Fab onClick={Buscar} style={{ background: '#3F4E54' }}>
                        <SearchIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} >
                  <img src={Img} width="100%" className={classes.img} />
                </Grid>
              </Grid>

            </>
          )

        }

      </Grid>

      {/* Scroll de buscar */}
      <Grid item xs={12} className={("scrollBuscar", "buscarS")} ref={GridBuscar} style={{ opacity: opacity }}>
        <Grid container className={(classes.search2)} ref={GridBuscar} style={{ position: position, zIndex: 100, top: top, border: `solid 1px ${coloresEmma.secondarycolor}` }}>
          <Grid xs={6}>
            <InputBase
              value={buscar}
              onChange={(e) => setBuscar(e.target.value)}
              placeholder="Buscar preguntas"
              onKeyUp={handleKeyPress}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{ color: coloresEmma.secondarycolor }}
            />
          </Grid>
          <Grid xs={6} className={classes.searchIcon} >
            <Button onClick={limpiar} style={{ color: coloresEmma.secondarycolor }}>
              <DeleteForeverIcon />
            </Button>

            <Fab onClick={Buscar} style={{ background: '#3F4E54' }}>
              <SearchIcon />
            </Fab>
          </Grid>
        </Grid><br />
      </Grid>



      {/* Card con las respuestas */}
      <Grid
        container
        direction="row"
        justify="space-between"
        spacing={5}
        className="ContainerPreguntas">

        <Grid item xs={12} className={classes.division}/>

        {preguntas.map(({ nombre, id }, i) => (

          <Grid item xs={12} md={6}>
            <ExpansionPanel expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`, id)} key={i}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                <Typography style={{ color: '#525252' }}>
                  <Box fontWeight="fontWeightMedium" >
                    {nombre}
                  </Box>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="contenidoRespuestas">
                {cargando ?
                  <Typography style={{ color: coloresEmma.secondarycolor }}>
                    Cargando...
                  </Typography> :
                  <>
                    {respuesta.map(({ secundario }) => {
                      if (secundario.valora == 'parrafo') {
                        return <Typography className={classes.heading}> <Pintar respuesta={secundario.nombre} /> </Typography>
                      } else {
                        return (
                          <Typography className={classes.heading_item}>
                            <span className='item_p'> {secundario.valorb} </span>
                            <Pintar respuesta={secundario.nombre} />
                          </Typography>

                        )
                      }
                    })

                    }
                  </>

                }
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

        ))}

      </Grid>

    </div>
  )
}


export default Preguntas
