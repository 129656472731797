import React from 'react';
import { BottomNavigation, BottomNavigationAction, Hidden } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CreateIcon from '@material-ui/icons/Create';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import LoopIcon from '@material-ui/icons/Loop';


const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function MenuAdministrar({administrador, sele = 0, seleccionar = () => { } }) {
  const pintar = (app, claseN = '') => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase('proceso', sele)} onClick={() => seleccionar('proceso')}>
            <BottomNavigationAction label={'Procesos'} icon={<LibraryBooks />} />
          </BottomNavigation>
          {
            administrador &&
            <BottomNavigation showLabels className={clase('administrar', sele)} onClick={() => seleccionar('administrar')}>
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon className='menu_icono' />} />
            </BottomNavigation>
          }
        </div>
      )
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels >
          <BottomNavigationAction label={'Procesos'} icon={<LocationOnIcon />} className={clase('proceso', sele, '_app')} onClick={() => seleccionar('proceso')} />
            {
              administrador &&
              <BottomNavigationAction label={'Administrar'} icon={<SettingsIcon />} className={clase('administrar', sele, '_app')} onClick={() => seleccionar('administrar')} />
            }
          </BottomNavigation>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
}

export function CentroMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} />
          <BottomNavigationAction label={'Hechos'} icon={<PersonPinIcon/>} className={clase('hechos', sele)} onClick={() => seleccionar('hechos')} />
          <BottomNavigationAction label={'Adjuntos'} icon={<MenuBookIcon />} className={clase('adjunto', sele)} onClick={() => seleccionar('adjunto')} />
          <BottomNavigationAction label={'Estado'} icon={<LoopIcon />} className={clase('estado', sele)} onClick={() => seleccionar('estado')} />
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}

export function MenuGestionar({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Gestionar'} icon={<LoopIcon />} className={clase('gestion', sele)} onClick={() => seleccionar('gestion')} />
          <BottomNavigationAction label={'Adjuntos'} icon={<MenuBookIcon/>} className={clase('adjuntos', sele)} onClick={() => seleccionar('adjuntos')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}


export function MenuGestionarEstudiante({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Adjuntos'} icon={<MenuBookIcon/>} className={clase('adjuntos', sele)} onClick={() => seleccionar('adjuntos')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}