import React, { Component } from 'react';

import AlertasSimple from '../../general/AlertasSimple';
import CustomDropzone from '../../general/CustomDropzone';
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
export default class InscripcionAgregarMisDocumentos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cargando: true,
            mensaje: '',
        }
        this.fileInput = React.createRef();
    }


    agregarArchivos = (arc) => {
        let { archivos, setArchivos } = this.props.props_c
        archivos = archivos.concat(arc);
        setArchivos(archivos);
    };

    eliminarArchivo = (i) => {
        const { archivos, setArchivos } = this.props.props_c
        archivos.splice(i, 1);
        setArchivos(archivos);
        // this.setState({ archivos })
        actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
    };

    render() {
        const { archivos } = this.props.props_c;
        const { agregarArchivos, eliminarArchivo } = this
        let lista = [
            {
                item: `
                Si su solicitud es transferencia externa adjuntar certificados de notas 
                escaneado (obligatorio), certificado de buena conducta (opcional), si 
                cuenta con los contenidos programáticos enviar al siguiente correo: acharras1@cuc.edu.co
                `
            },
        ]
        return (
            <div>
                <AlertasSimple tipo='info' titulo='Estimado estudiante, subir los siguientes Documentos:' sub_titulo='Si necesitas subir varios archivos puedes comprimirlos en cualquier formato(rar, zip) y adjuntarlos.' lista={lista} />
                <CustomDropzone archivos={archivos} addFunction={agregarArchivos} deleteFunction={eliminarArchivo} />
            </div>
        )
    }
}
