//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'

import AppBarModal from '../../general/AppBarModal'
import BuscarPersona from '../../general/BuscarPersona';
import SemillerosAddRol from './SemillerosAddRol'

import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';

export default function SemillerosAddRes(props) {
    const [rolSelect, setRolSelect] = React.useState([]);
    const [modalRol, setModalRol] = React.useState(false);
    const dispatch = useDispatch();
    const [objRol, setObjRol] = React.useState({});
    var obj = {}
    const onClickChange = async () =>{
      if(rolSelect.length == 0){

        let array = await props.validateInputs([
          { value: rolSelect, name: 'rolSelect', err: { empty: false } },
        ])

        props.setErrores(array)

      }else{
        let objFinal = {
          id: objRol.id,
          nombre: objRol.nombre,
          rol: rolSelect
        }

        if(props.responsables.length == 0){
          props.array.push(objFinal)
          props.setResponsables(props.array)
          setModalRol(false)
          setRolSelect([])
          props.setOpenModalAddEnc(false)
        }else{
          let sw = false
          let estudianteLid = 0
          let profesorAse = 0
          let profesorlider = 0
          let titulo = "Ya se agrego a esa persona."

          props.responsables.map((item, i) =>{
            if(item.id == objFinal.id){
              sw = true
              return
            }

            if(item.rol.codigo == 'Semi_rol1') estudianteLid += 1
            else if(item.rol.codigo == 'Semi_rol2') profesorAse += 1
            else if(item.rol.codigo == 'Semi_rol3') profesorlider += 1
          })

          if(estudianteLid >= 1 && objFinal.rol.codigo == 'Semi_rol1'){
            sw = true
            titulo = "Debe registrar maximo 1 Estudiante Lider."
          }
          else if(profesorlider >= 1 && objFinal.rol.codigo == 'Semi_rol3'){
            sw = true
            titulo = "Debe registrar maximo 1 rofesor lider."
          }

          if(sw){
            let tipo = "info"

            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
              );
          }else{
            props.array.push(objFinal)
            props.responsables.push(objFinal)
            setModalRol(false)
            setRolSelect([])
            props.setOpenModalAddEnc(false)
          }
        }
      }
    }
    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalAddEnc} onClose={() => props.setOpenModalAddEnc(false)}>
          <AppBarModal titulo={'¡ Buscar Lider !'} mostrarModal={() => props.setOpenModalAddEnc(false)} titulo_accion="" accion="" />
          <DialogContent>
            < BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => {
                setModalRol(true)
                setObjRol({id: id, nombre:  `${primer_nombre} ${primer_apellido} ${segundo_apellido}`})
                {/*props.setResponsables([{
                    id: id,
                    nombre:  `${primer_nombre} ${primer_apellido} ${segundo_apellido}`
                }])*/}
            }} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => props.setOpenModalAddEnc(false)}>
                Atras
            </Button>
          </DialogActions>
        </Dialog>
        <SemillerosAddRol 
          modalRol={modalRol}
          setModalRol={setModalRol}

          rol={props.rol}
          setRol={props.setRol}

          rolSelect={rolSelect}
          setRolSelect={setRolSelect}

          onClickChange={onClickChange}

          getError={props.getError}
          errores={props.errores}
          setErrores={props.setErrores}

          validateInputs={props.validateInputs}
        />
      </div>
    )
}