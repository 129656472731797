import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { api } from "../../../global/js/funciones";
import {coloresEmma } from '../../../global/js/funciones';
const useStyles2 = makeStyles(theme => ({
  card: {
    width: "100%",
    height: "100%"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  avatar: {
    backgroundColor: coloresEmma.primarycolor,
    color: 'white'
  }
  ,
  button: {
    width: "100%",
    backgroundColor: "#FAFAFA",
    color: coloresEmma.primarycolor
  },
  CardContent: {
    height: 100,
    textAlign: "justify"
  },
  GridItem: {
    margin: 10
  }
}));



export default function Candidato({ id, foto, descripcion, nombre, votarCandidato, eleccion }) {
  const classes = useStyles2();
  return (
    <Grid item key={id} xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {nombre.charAt(0)}
            </Avatar>
          }
          title={nombre}
          // subheader={eleccion.nombre}
        />
        <CardMedia
          className={classes.media}
          image={`${api}${foto}`}
          title="Imagen Candidato"
        />
        <CardContent className={classes.CardContent} >
          <Typography variant="body2" color="textSecondary" component="p">
            {`${descripcion.substr(0, 130)}${descripcion.length > 130 ? '... Ver mas' : ''}`}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Button className={classes.button} onClick={votarCandidato.bind(this, eleccion.id, id, nombre)} >
              <Icon className={classes.leftIcon}>check</Icon>
              Votar
              </Button>
          </Grid>
        </CardActions>
      </Card>
    </Grid>



  );
}



//Props del componente
Candidato.propTypes = {
  //variables
  eleccion: PropTypes.object.isRequired,
  candidato: PropTypes.shape({
    id: PropTypes.number.isRequired,
    foto: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
  }),
  //funciones
  votarCandidato: PropTypes.func.isRequired,
}

