import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import useWindowSize from '../../general/hooks/useWindowSize';

const Banner = ({ nombre, programa }) => {

  const windowSize = useWindowSize();

  return (
    <div style={{
      alignSelf: 'flex-start',
      margin: '10px 30px',
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 20px',
      backgroundColor: '#eaefef',
      borderRadius: '10px',
      width: windowSize < 600 ? 'auto' : '95%'
    }}>
      <Typography variant="h4" gutterBottom>Hola, {nombre} !</Typography>
      <span>Bienvenido al resumen de tu estilo de aprendizaje, perteneces al programa de: {programa}</span>
    </div>
  )
}

export default Banner
