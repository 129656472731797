import React, { Component } from 'react';

import { connect } from 'react-redux';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal';


import { Hidden, BottomNavigation, BottomNavigationAction, Tab, Tabs } from '@material-ui/core'
import PollIcon from '@material-ui/icons/Poll';

import ListarDatos from '../general/ListarDatos';
import { BtnDetalle } from '../general/BotonesAccion';


import {
    obtenerValores,
    obtenerPermisosValor,
    consulta
} from '../../global/js/funciones';

import ModalEncuestados from './ModalEncuestados'
import ModalPasos from './ModalPasos'
import { NOTIFICACIONES } from './util'

import ReactExport from "react-data-export";

import{ PASOS } from './util'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const MenuAdm = ({ sele, seleccionar }) => {
    const clase = (item, selec) => item === selec ? 'item_selec' : 'item_modulo';

    const pintar = (app, classN = '') => {
        if (!app) {
            return (
                <div className={classN}>
                    <BottomNavigation showLabels className={clase('Encuesta', sele)} onClick={() => seleccionar('Encuesta')}>
                        <BottomNavigationAction label={'Encuesta'} icon={<PollIcon />} />
                    </BottomNavigation>
                </div>
            );
        } else {
            return (
                <div className={classN}>
                    <Tabs value={sele} variant='fullwidth' scrollButtons="off" >
                        <Tab label='Encuesta' icon={<PollIcon className='menu_icono' />} className={clase('Encuesta', sele, '_app')} value='Encuesta' onClick={() => seleccionar('Encuesta')} />
                    </Tabs>
                </div>
            )
        }
    }

    return (
        <div>
            <div>
                <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
                <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
            </div>
        </div>
    );
};


class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seleccion: 'Encuesta',
            listaEncuestas: [],
            openModal: false,
            openModalPasos: false,
            listaEncuestados: [],
            pasos: [],
            detalles: [],
            detallesGeneral: [],
            encuesta: null
        };
    }
    async componentDidMount() {
        const { mostrarModulo } = this.props;
        mostrarModulo({ ruta: '/encuesta_admin', nombre: 'Administrar Encuestas' });
        const notificaciones = await obtenerValores(NOTIFICACIONES);
        this.setState({
            listaEncuestas: notificaciones
        })
    }
    componentDidUpdate(prevProps, prevState) {
    }

    getStudents = (id) => {
        consulta(
            `api/v1.0/poll/getstudentsbypoll/${id}`,
            null,
            'get',
            (err, estado, data) => {
                if (!err && data) {
                    this.setState({ listaEncuestados: data })
                }
            },
        )
    }

    getDetalles = (idEncuesta, idEstudiante) => {
        consulta(
            `api/v1.0/poll/detalles/${idEncuesta}/${idEstudiante}`,
            null,
            'get',
            (err, estado, data) => {
                if (!err && data) {
                    this.setState({ detalles: data })
                }
            },
        )
    }

    getDetallesById = (idEncuesta) => {
        consulta(
            `api/v1.0/poll/detalles/${idEncuesta}`,
            null,
            'get',
            (err, estado, data) => {
                if (!err && data) {
                    this.setState({ detallesGeneral: data })
                }
            },
        )
    }

    cambiarEstado = (estados) => {
        this.setState(estados)
    }

    cambiarOpcion = (seleccion) => {
        this.cambiarEstado({ seleccion })
    }

    actions = row => {
        let { } = this.props
        return (
            <div>
                <BtnDetalle
                    callback={async () => {
                        let pasos = await obtenerPermisosValor(row.id);
                        pasos = pasos.filter(valores => valores.secundario.generica == PASOS)
                        pasos = pasos.map(valores => valores.secundario);

                        this.setState({ openModal: true, listaEncuestados: [], pasos, encuesta: row });
                        this.getStudents(row.id);
                        this.getDetallesById(row.id);
                    }}
                />
            </div>
        )
    }

    actionsModalEncuestados = row => {
        const {
            encuesta
        } = this.state
        return (
            <div>
                <BtnDetalle
                    callback={async () => {
                        this.getDetalles(encuesta.id, row.id);
                        this.setState({ openModalPasos: true });
                    }}
                />
            </div>
        )
    }

    onClickDescargar = () => document.getElementById("btn_descargar").click()

    descargar = () => {
        let { detallesGeneral, listaEncuestados } = this.state;
        const filas = [
            "nombre",
            "paso",
            "pregunta",
            "respuesta"
        ]
        return (
            <ExcelFile filename={`test`} element={<button className='oculto' id={"btn_descargar"} />}>
                <ExcelSheet data={detallesGeneral} name='datos'>
                    {
                        filas.map((f, i) =>
                            <ExcelColumn
                                key={i}
                                label={f}
                                value={
                                    (row) => {
                                        if (i == 0) return `${row.usuario.primer_nombre.toUpperCase()} ${row.usuario.primer_apellido.toUpperCase()}`;
                                        if (i == 1) return row.paso.nombre;
                                        if (i == 2) return row.pregunta.nombre;
                                        if (i == 3) return row.respuesta ? row.respuesta.nombre : row.respuesta_texto;
                                    }
                                }
                            />
                        )
                    }
                </ExcelSheet>
            </ExcelFile>
        );
    }


    vista = () => {

        let {
            seleccion,
            listaEncuestas,
            openModal,
            openModalPasos,
            listaEncuestados,
            pasos,
            detalles,
            detallesGeneral
        } = this.state

        let {

        } = this.props


        switch (seleccion) {
            case 'Encuesta':
                return (
                    <div style={{ padding: '0% 0% 0% 5%' }}>
                        {detallesGeneral.length > 0 && this.descargar()}
                        <ModalEncuestados
                            openModal={openModal}
                            closeModal={() => this.setState({ openModal: false, detallesGeneral: [] })}
                            data={listaEncuestados}
                            actions={this.actionsModalEncuestados}
                            descargar={this.onClickDescargar}
                        />
                        <ModalPasos
                            openModal={openModalPasos}
                            closeModal={() => this.setState({ openModalPasos: false })}
                            data={pasos}
                            detalles={detalles}
                        />
                        <ListarDatos
                            id="tbl_inf_encuestas"
                            datos={listaEncuestas}
                            titulo={"Encuestas"}
                            opciones={true}
                            buscar={true}
                            acciones={row => this.actions(row)}
                            fila_principal={({ nombre }) => `${nombre.toUpperCase()}`}
                            filas={[]}
                        />
                    </div>
                )
            default:
                return (
                    <>
                    </>
                )
        }

    }

    render() {
        let { seleccion } = this.state
        return (
            <div>
                <MenuAdm sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} />
                {this.vista()}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    let { usuario } = state.redGlobal;
    return {
        usuario,
    };
};

const mapDispatchToProps = {
    mostrarModulo,
    actualizarMensaje
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);