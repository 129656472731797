import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// FUNCIONES GLOBALES
import { Paper, Grid, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import { consulta, mostrarError, formulario, generarFiltros, obtenerValoresFiltros, crear_form_data, modificarValorGenericaAlter } from "../../global/js/funciones";
import ListarDatos from "../general/ListarDatos";
import TareasTerminadas from "../general/TareasTerminadas";
import { BtnDetalle, BtnGestionar,  BtnForm, BtnRegresar, BtnEditar, BtnVer} from "../general/BotonesAccion";
import AppBarModal from '../general/AppBarModal'
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import EnviarMensajeFecha from '../general/EnviarMensajeFecha';
import { coloresEmma } from '../../global/js/funciones';
// FUNCIONES - COMPONENTES DEL MODULO
import { mostrarModalDetalleAtencion, mostrarGestionValidacion, mostrarModalFiltroAtencion, mostrarModalModificarPeriodo } from "../../redux/actions/actAtencion";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { MenuValidaciones } from './atencion/MenuAtencion';
import AtencionDetalle from './atencion/Atencion';
import AtencionGestionar from './atencion/AtencionGestionar';
import AtencionPermisos from './atencion/AtencionPermisos';
import ValidacionesFiltro from './atencion/AtencionFiltro';

// IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import fechaIcon from '../../global/imagenes/calendar.png';
import permisosIcon from '../../global/imagenes/lightbulb.png';
import vistaIcon from '../../global/imagenes/hourglass.png';
import moment from 'moment'


export class AtencionAdministrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filtros_validaciones: [],
      cargando: false,
      accion: 'Procesos',
      accionProceso: 'inicio',
      accionAdmin: 'inicio',
      administrador: false,
      periodo: '',
      atenciones: [],
      validacion: null,
      modalFiltros: false,
      vistaDetalle: 'detalle',
      id_estudiante: 0,
      estados_procesos: [],
      cargarFiltroP: true,
      periodo_filtro: '',
      periodos: [],
      filtrarPeriodo: false,
      periodo_mod: '',
      fecha_periodo: '',
      datosNoti: [],
			notificaciones: [],
			enNotificacion: "",
      cargandoNotif: true
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/AtencionEstudiantes', nombre: 'casos' })
    let periodo_mod = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Aten' }]);
    this.setState({periodo_mod: periodo_mod[0].nombre,fecha_periodo:periodo_mod[0].valora})
    this.obtenerDatosIniciales();
		this.obtenerAlertasAtencion();
    this.esAdministrador();
  }

  async componentDidUpdate(evnt, { accion, accionProceso }) {
    let { accionProceso: accionProcesoNew, periodo_filtro } = this.state;

    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew !== 'Tod_Ate') {
        this.cambiarEstado({ cargando: true })
        if (accionProcesoNew === 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
        else this.actualizarDataAtenciones([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
      }
    }
  }

  // esFestivo = (fecha_inicio) => {
  //   const festivos = [
  //     // moment('2024-05-01'), // Año Nuevo
  //   ];

  //   return festivos.some(festivo => fecha_inicio.isSame(festivo, 'day'));
  // };

  calcularLimiteTiempo = (fecha_inicio, diasHabilesTotales) => {
    const fecha_actual = moment(new Date());

    // let diasTranscurridos = fecha_actual.diff(fecha_inicio, 'days');
    // let diasHabilesRestantes = diasHabilesTotales - diasTranscurridos;

    let diasTranscurridos = 0;
    let diasHabilesRestantes = diasHabilesTotales;

    // for (let fecha = moment(fecha_inicio).clone(); fecha.diff(fecha_actual) <= 0; fecha = fecha.add(1, 'days')) {
    //   if (fecha.day() >= 1 && fecha.day() <= 5) {
    //     diasTranscurridos++;
    //   }
    // }

    // Exclude the current day from the loop / Excluir el día actual del bucle
    for (let fecha = moment(fecha_inicio).add(1, 'days'); fecha.diff(fecha_actual) <= 0; fecha = fecha.add(1, 'days')) {
      if (fecha.day() >= 1 && fecha.day() <= 5) {
      // if (fecha.day() >= 1 && fecha.day() <= 5 && this.esFestivo(fecha)) {
        diasTranscurridos++;
      }
    }

    diasHabilesRestantes = diasHabilesTotales - diasTranscurridos;

    if (diasHabilesRestantes === 1) {
      return `Queda ${diasHabilesRestantes} día hábil`;
    } else if (diasHabilesRestantes > 1) {
      return `Quedan ${diasHabilesRestantes} días hábiles`;
    } else {
      return "Proceso atrasado, gestione inmediatamante";
    }
  };

  FILTERS_DATA_ATENCION() { 
    return [
      {
        'llave': 'estado_actual__valorc',
        'valor': 'si'
      },
      {
        'llave': 'periodo',
        'valor': this.state.periodo
      }
    ]
  }

  async actualizarDataAtenciones(filtros = this.FILTERS_DATA_ATENCION()) {
    this.setState({ cargando: true })
    let atenciones = await this.obtenerAtencion(filtros)
    this.setState({ atenciones, cargando: false, filtros_beca: filtros })
  }


  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Ate_n1")) {
      this.setState({ administrador: true })
    }
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Aten' }]);
    this.obtenerEstadoSolicitudes(periodo[0].nombre);
    this.setState({
      periodo: periodo.length > 0 ? periodo[0].nombre : '',
      periodo_filtro: periodo.length > 0 ? periodo[0].nombre : ''
    });
  }

  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerEstadoSolicitudes(periodo_filtro)
  }

  obtenerEstadoSolicitudes = (periodo) => {
    consulta(`api/v1.0/atencion/estados/${periodo}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        console.log(resp)
        if (extra.length > 0) {
          extra.forEach(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte);
          })
        }
        this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden), cargando: false });
      }
    })
  }

  async obtenerAtencion(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/atencion/listar/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : []);
      })
    })
  }

  gestionarValidaciones = async (id, data, archivos, callback,) => {
    let { mostrarGestionValidacion, actualizarMensaje } = this.props
    let form = await crear_form_data(data);

    formulario(`api/v1.0/atencion/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarGestionValidacion(false)
          this.obtenerAlertasAtencion();
					this.cargarNotificaciones([{ llave: "id", valor: id }])
          this.actualizarDataAtenciones(this.state.filtros_validaciones);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback()
    })
  }


  cambiarEstado = (estados) => this.setState(estados)

  pintarProcesos() {
    let { atenciones, validacion, id_estudiante } = this.state;
    let { mostrarModalDetalleAtencion, mostrarGestionValidacion, modalGestionValidacion, actualizarMensaje, mostrarModalFiltroAtencion, modalDetValidaciones } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'STATE_CREATE_ATENCION': { 'backgroundColor': coloresEmma.filtrotodos, color  },
        'STATE_ASIGNADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'STATE_GESTION_ATENCION': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'STATE_FINALIZADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso, diashabiles } = data;
      console.log(permiso)

      const onClickDetalle = validacion => {
        this.setState({ validacion, vistaDetalle: 'detalle', id_estudiante: validacion.usuario_registro.id })
        mostrarModalDetalleAtencion(true)
      }

      const onClickGestionar = validacion => {
        this.setState({ validacion })
        mostrarGestionValidacion(true)
      }

      let detalle = (
      <BtnDetalle
        callback={() => onClickDetalle(data)}
        color='#01579b'
        texto='Detalle Solicitud'
      />
      );

      let gestionar = (codigo !== 'STATE_FINALIZADO_ATENCION' && parseInt(permiso) === 1) && (
        <BtnGestionar
          callback={() => onClickGestionar(data)}
          color='#00bfa5'
          texto='Gestionar'
        />
      );

      return <div>{detalle}{gestionar}</div>;
    }

    return (
      <div>

        <AtencionDetalle
          id_estudiante={id_estudiante}
          mostrarModalDetalleAtencion={mostrarModalDetalleAtencion}
          modalDetValidaciones={modalDetValidaciones}
          vistaDetalle='detalle'
          admin={false}
          validacionActual={validacion ? validacion.id : 0}
        />

        <AtencionGestionar
          actualizarMensaje={actualizarMensaje}
          validacion={validacion}
          gestionarValidaciones={this.gestionarValidaciones}
          mostrarGestionValidacion={mostrarGestionValidacion}
          modalGestionValidacion={modalGestionValidacion}
          guardarVolante={this.cambiarEstado}
        />

        <ListarDatos
          datos={atenciones}
          titulo='Lista de Solicitudes'
          id='tbl_atencion_listar'
          opciones={true}
          descargar='ATENCIONES - EMMA'
          filtrar={false}
          refrescar={true}
          actfiltrar={() => mostrarModalFiltroAtencion(true)}
          actRefrescar={() => this.actualizarDataAtenciones(this.state.filtros_validaciones)}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
            { 'nombre': 'Identificación: ','id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            { 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => `${estado_actual.nombre}`.toUpperCase(), 'enLista': true },
            { 'nombre': 'Días habiles: ', 'id': 'diashabiles', 'mostrar': ({ diashabiles }) => `${diashabiles}` },
            { 'id': 'dias_habiles_restantes', 'mostrar': ({ fecha_registro, diashabiles }) => this.calcularLimiteTiempo(fecha_registro, diashabiles), 'enLista': true },
          ]}
        />
      </div>
    )
  }

  pintarInicioProcesos = () => {
    let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo } = this.state;
    let { mostrarModalFiltroAtencion } = this.props

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
      'STATE_CREATE_ATENCION': { 'backgroundColor': coloresEmma.filtrotodos, color  },
      'STATE_ASIGNADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudactiva, color },
      'STATE_GESTION_ATENCION': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
      'STATE_FINALIZADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Tod_Ate': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      }
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      if (codigo !== 'Tod_Ate') this.setState({ accionProceso: codigo })
      else {
        mostrarModalFiltroAtencion(true);
      }
    };

    return (
      <>
        <div style={{ padding: '2% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justifyContent={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
                {/* <Grid item xs={6} sm={6} md={6} lg={6} >
                  <Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
                    <IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
                  </Tooltip>
                </Grid> */}
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {(estados_procesos.length === 0) ?
                  <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                          <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                            <AccionesRapidas
                              imagen=''
                              titulo={`¡ ${nombre} !`}
                              sub_titulo=''
                              des_sub_titulo=''
                              avatar={obtenerColor(codigo)}
                              avatar_name={nombre.charAt(0).toUpperCase()}
                              list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions>
        </Dialog >
      </>
    )
  }

  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      if(!(periodos.find(e => e.periodo === periodo))) periodos.push({'periodo': periodo})
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/atencion/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  renderProcesos() {
    let { accionProceso } = this.state;
    return (
      <>
        {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
        {accionProceso !== 'inicio' && <div className={'regresar'} >
          <BtnRegresar callback={() => this.setState({ filtros_validaciones: [], accionProceso: 'inicio', seleccion: '' })} />
        </div>}
      </>
    )
  }

  pintarAdministrar() {
    let { accionAdmin, administrador } = this.state;
    if (!administrador) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
    return (
      <>
        {accionAdmin === 'inicio' && this.pintarInicioAdm()}
        {accionAdmin !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionAdmin: 'inicio', seleccion: '' })} /></div>}

        {accionAdmin === 'permisos' && <AtencionPermisos />}
      </>
    )
  }

  pintarInicioAdm = () => {
    let { periodo_mod, fecha_periodo } = this.state;
    return (
      <div style={{ padding: '2% 2% 0% 2%' }}>
        <Grid container direction={'row'} spacing={1} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <TituloAccion principal='' alterno='¿ Qué deseas hacer ?' />
            <Paper elevation={0} square className='scroll contenido_fijo'>
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.props.mostrarModalModificarPeriodo(true)}>
                    <AccionesRapidas imagen={fechaIcon} titulo='¡ Gestionar el periodo !' sub_titulo='' des_sub_titulo={`El periodo actual ${periodo_mod} esta disponible hasta ${fecha_periodo}.`}/>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdmin: 'permisos' })}>
                    <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores del módulo de validaciones.' />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  detalleNotificacion() {
		let {
      mostrarModalDetalleAtencion,
      mostrarGestionValidacion,
      mostrarModalFiltroAtencion,
      modalDetValidaciones,
      actualizarMensaje,
      modalGestionValidacion,
    } = this.props;
    let {
      validacion,
      id_estudiante,
    } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
      'STATE_CREATE_ATENCION': { 'backgroundColor': coloresEmma.filtrotodos, color  },
      'STATE_ASIGNADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudactiva, color },
      'STATE_GESTION_ATENCION': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
      'STATE_FINALIZADO_ATENCION': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      }
      return (colores[codigo])
    }

		const acciones = (data) => {
			let {
				estado_actual: { codigo }, permiso
			} = data;

      const onClickDetalle2 = validacion => {
        this.setState({ validacion, vistaDetalle: 'detalle', id_estudiante: validacion.usuario_registro.id })
        mostrarModalDetalleAtencion(true)
      }

      const onClickGestionar = validacion => {
        this.setState({ validacion })
        mostrarGestionValidacion(true)
      }

			let detalle = (
				<BtnDetalle
					callback={() => onClickDetalle2(data)}
					color="#01579b"
					texto="Abrir"
				/>
			);

			// let gestionar = codigo !== "Est_Vis_Fin" && codigo !== "Est_Vis_Rec" && (
      let gestionar = (codigo !== 'STATE_FINALIZADO_ATENCION' && parseInt(permiso) === 1) && (
				<BtnGestionar
					callback={() => onClickGestionar(data)}
					color="#009688"
					texto="Gestionar"
				/>
			);

			return (
				<div>
					{detalle}
					{gestionar}
				</div>
			);
		};
		let { cargando, datosNoti, accionProceso } = this.state;
		if (!cargando) {
			return (
				<>
          {accionProceso !== 'inicio' && <div className={'regresar'} >
            <BtnRegresar callback={() => this.setState({ filtros_validaciones: [], seleccion: '', accion: 'Procesos', accionProceso:'inicio' })} />
          </div>}

          <AtencionDetalle
            id_estudiante={id_estudiante}
            mostrarModalDetalleAtencion={mostrarModalDetalleAtencion}
            modalDetValidaciones={modalDetValidaciones}
            vistaDetalle='detalle'
            admin={false}
            validacionActual={validacion ? validacion.id : 0}
          />

          <AtencionGestionar
            actualizarMensaje={actualizarMensaje}
            validacion={validacion}
            gestionarValidaciones={this.gestionarValidaciones}
            mostrarGestionValidacion={mostrarGestionValidacion}
            modalGestionValidacion={modalGestionValidacion}
            guardarVolante={this.cambiarEstado}
          />

          <ListarDatos
            datos={datosNoti}
            titulo='Lista de Solicitudes'
            id='tbl_atencion_listar'
            opciones={true}
            descargar='ATENCIONES - EMMA'
            filtrar={false}
            refrescar={true}
            actfiltrar={() => mostrarModalFiltroAtencion(true)}
            actRefrescar={() => this.actualizarDataAtenciones(this.state.filtros_validaciones)}
            acciones={(data) => acciones(data)}
            buscar={true}
            avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
            color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
            fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
            filas={[
              { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
              { 'nombre': 'Identificación: ', 'id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
              { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
              { 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => `${estado_actual.nombre}`.toUpperCase(), 'enLista': true },
              { 'nombre': 'Días habiles: ', 'id': 'diashabiles', 'mostrar': ({ diashabiles }) => `${diashabiles}` },
              { 'id': 'dias_habiles_restantes', 'mostrar': ({ fecha_registro, diashabiles }) => this.calcularLimiteTiempo(fecha_registro, diashabiles), 'enLista': true },
            ]}
          />

				</>
			);
		} else
			return (
				<TareasTerminadas
					imagen={emma_w}
					mensaje="Cargando..."
					widthImg="7%"
					marginTop="7%"
				/>
			);
	}

  configFiltros = (accion_actual) => {
    let { periodo_filtro } = this.state
    switch (accion_actual) {
      case 'STATE_CREATE_ATENCION':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      case 'inicio':
        return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
      default:
        return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
    }
  }

  modificarPeriodo = async (periodo_mod, fecha_periodo) => {
    let { actualizarMensaje, mostrarModalModificarPeriodo } = this.props;
    if (!periodo_mod || !fecha_periodo) return (actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 }))
    let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Aten' }]);
    if (!p.length > 0) return (actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 }))
    
    let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
    let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'nombre': periodo_mod, 'valora': fecha_periodo })
    if (!error) {
      if (estado === 200) {
        tipo = 'success';
        titulo = resp.titulo;
        mostrarModalModificarPeriodo(false);
        this.cambiarEstado({ periodo_mod, fecha_periodo, periodo_filtro: periodo_mod });
        this.obtenerAlertas(periodo_mod, fecha_periodo);
        this.setState({ cargando: true })
        this.obtenerEstadoSolicitudes(periodo_mod)
      } else {
        tipo = 'info';
        titulo = resp.titulo ? resp.titulo : mostrarError(resp)
      }
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }

  async obtenerAlertas(periodo_mod, fecha_periodo) {
    this.setState({ cargandoNotif: true })
    let periodo_noti = await this.notificacionPeriodo(periodo_mod, fecha_periodo);
    let notificaciones = [];
    if (periodo_noti) notificaciones.push(periodo_noti);
    this.setState({ notificaciones, cargandoNotif: false });
  }

  notificacionPeriodo(periodo_mod, fecha_periodo) {
    let { administrador } = this.state;
    let notificacion = null;
    if (fecha_periodo) {
      let fecha1 = moment();
      let fecha2 = moment(fecha_periodo);
      let dias = fecha2.diff(fecha1, 'days');
      if (dias < 15) {
        let mensaje = dias < 0 ? `El período actual ${periodo_mod} se ha cerrado, tener en cuenta que los estudiantes no podrán realizar solicitudes. ${!administrador ? 'Por favor, contacta al administrador del modulo para que actualice el periodo.' : ''}` : `El periodo actual ${periodo_mod} se cerrara ${dias === 0 ? 'el día de hoy' : `en ${dias} días`}, una vez el periodo se cierre los estudiantes no podrán realizar solicitudes.`
        notificacion = {
          icono: vistaIcon,
          titulo: '¡ Actualizar Período !',
          mensaje,
          tipo: 'fecha_periodo',
          acciones: () => administrador && <BtnEditar texto='Actualizar Periodo' callback={() => this.props.mostrarModalModificarPeriodo(true)} color='#01579b' />,
        }
      }
    }
    return notificacion;
  }

  async cargarNotificaciones(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/atencion/listar/1?${f}`, null, null, (error, estado, resp) => {
        // resolve(estado === 200 && !error ? resp : []);
        if (estado === 200) {
          this.setState({ cargando: false, datosNoti: resp });
        }
      })
    })
  }

  obtenerNotificacionesAtencion = () => {
		return new Promise((resolve) => {
			consulta(
				`api/v1.0/atencion/notificaciones`,
				null,
				null,
				(error, estado, resp) => {
					resolve(resp);
				}
			);
		});
	};

  obtenerAlertasAtencion = async () => {
		console.log(this.state.cargando)
		this.setState({ cargandoNotif: true });
		let noti = await this.obtenerNotificacionesAtencion();
		let notificaciones = [];
		const accionesMensajes = (data) => {
			const onClickAbrir = ({ id }) => {
				this.setState({ seleccion: "detalleNoti", accion: '', accionProceso: '' });
        this.cargarNotificaciones([{ llave: "id", valor: id }]);
        // this.calcularLimiteTiempo(fecha_inicio, diasHabilesTotales);
			};
			return (
				<>
					<BtnVer callback={() => onClickAbrir(data)} texto="Ver" />
				</>
			);
		};

    if (noti) {
      noti.map((data) => {
        let { usuario_registro, tipo_incidente, estado_actual, diashabiles, fecha_registro } = data;
        let notificacion = {
          titulo: `¡ Solicitud Atención !`,
          mensaje: `
            ASUNTO: ${tipo_incidente.nombre} -
            ESTADO: ${estado_actual.nombre} -
            FECHA: ${moment(fecha_registro).format('YYYY/MM/DD')} -
            DÍAS HÁBILES: ${diashabiles ? diashabiles : 'N/A'}
            (${this.calcularLimiteTiempo(fecha_registro, diashabiles)})
          `,
          tipo: "mensaje",
          acciones: () => accionesMensajes(data),
        };
        notificaciones.push(notificacion);
      });
    }
    this.setState({ notificaciones, cargandoNotif: false });
  }

  /* async actualizarDataAtenciones(filtros = [{ 'llave': 'estado_actual__valorc', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
    this.setState({ cargando: true })
    let atenciones = await this.obtenerAtencion(filtros);
    this.setState({ atenciones, cargando: false, filtros_validaciones: filtros })
  }
 */
  render() {
    let { accion, cargando, validacion, administrador, id_estudiante, accionProceso, notificaciones, cargandoNotif, seleccion } = this.state;
    let { mostrarModalDetalleAtencion, modalDetValidaciones, mostrarModalFiltroAtencion, modalFiltroValidacion, modalModificarPeriodo, mostrarModalModificarPeriodo, modalGestionValidacion } = this.props;
    return (
      <div>
        <MenuValidaciones
          seleccionar={(accion) => this.cambiarEstado({ accion })}
          sele={accion}
          administrador={administrador}
          notificaciones={notificaciones}
					sele2={seleccion}
          seleccionar2={(seleccion) => {if (seleccion === "Notificaciones") {this.setState({ enNotificacion: "si" });this.obtenerAlertasAtencion();} else this.setState({ enNotificacion: "no", seleccion });}}
					cargandoNotif={cargandoNotif}
        />
        <div className='contenido_modulo'>
          <ValidacionesFiltro
            actualizarMensaje={actualizarMensaje}
            modalFiltroValidacion={modalFiltroValidacion}
            mostrarModalFiltroAtencion={mostrarModalFiltroAtencion}
            filtrar={(filtros) => this.actualizarDataAtenciones(this.configFiltros(accionProceso).concat(filtros))}
            limpiar={() => this.actualizarDataAtenciones(this.configFiltros(accionProceso))}
            accionProceso={accionProceso}
            mostrarTodos={(accion) => this.setState({ accionProceso: accion })}
          />
          {/* <AtencionDetalle
            id_estudiante={id_estudiante}
            mostrarModalDetalleAtencion={mostrarModalDetalleAtencion}
            modalDetValidaciones={modalDetValidaciones}
            vistaDetalle='detalle'
            admin={false}
            validacionActual={validacion ? validacion.id : 0}
          />
          <AtencionGestionar
            actualizarMensaje={actualizarMensaje}
            validacion={validacion}
            gestionarValidaciones={this.gestionarValidaciones}
            mostrarGestionValidacion={mostrarGestionValidacion}
            modalGestionValidacion={modalGestionValidacion}
            guardarVolante={this.cambiarEstado}
          /> */}
          <EnviarMensajeFecha 
            modal={modalModificarPeriodo} 
            mostrarModal={mostrarModalModificarPeriodo} 
            enviar={(periodo_mod, fecha) => this.modificarPeriodo(periodo_mod, fecha)} label='Periodo' type='text' titulo={`Nuevo Periodo`} label_fecha='Fecha Vencimiento' 
          />
          {
            !cargando ?
              <div>
                {/* MENU */}
                {accion === 'Procesos' && this.renderProcesos(seleccion='')}
                {accion === 'Permisos' && this.pintarAdministrar(seleccion='')}

                {/* NOTIFICACION */}
                {seleccion === 'detalleNoti' && this.detalleNotificacion()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalDetValidaciones, modalGestionValidacion, modalFiltroValidacion, modalModificarPeriodo, } = state.redAtencion
  let { usuario } = state.redGlobal;
  return {
    usuario,
    modalDetValidaciones,
    modalGestionValidacion,
    modalFiltroValidacion,
    modalModificarPeriodo,
  }
}

const mapDispatchToProps = {
  mostrarModulo,
  mostrarModalDetalleAtencion,
  mostrarGestionValidacion,
  actualizarMensaje,
  mostrarModalFiltroAtencion,
  mostrarModalModificarPeriodo
}

AtencionAdministrar.propTypes = {
  //funciones
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalDetalleAtencion: PropTypes.func.isRequired,
  mostrarGestionValidacion: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalModificarPeriodo: PropTypes.func.isRequired,

  //variables
  modalDetValidaciones: PropTypes.bool.isRequired,
  modalGestionValidacion: PropTypes.bool.isRequired,
  modalFiltroValidacion: PropTypes.bool.isRequired,
  modalModificarPeriodo: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AtencionAdministrar);
