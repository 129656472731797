const MODAL_ADD_ELECCION = 'MODAL_ADD_ELECCION'
const MODAL_MOD_ELECCION = 'MODAL_MOD_ELECCION'
const MODAL_CAN_ELECCION = 'MODAL_CAN_ELECCION'
const MODAL_FIN_ELECCION = 'MODAL_FIN_ELECCION'
const MODAL_PROL_ELECCION = 'MODAL_PROL_ELECCION'
const MODAL_LINK_ELECCION = 'MODAL_LINK_ELECCION'
const MODAL_ADM_CANDIDATOS = 'MODAL_ADM_CANDIDATOS'
const MODAL_VOTAR_CANDIDATOS = 'MODAL_VOTAR_CANDIDATOS'
const MODAL_FILTRAR_ELLECIONES= 'MODAL_FILTRAR_ELLECIONES'
const ACT_DATA_ELECCION = 'ACT_DATA_ELECCION '
const ACT_DATA_ELECCIONES = 'ACT_DATA_ELECCIONES'
const ACT_RENDER_ELECCIONES = 'ACT_RENDER_ELECCIONES'


const mostrarModalAddEleccion = show => {
  return {
    type: MODAL_ADD_ELECCION,
    show,
  }
}

const mostrarModalModEleccion = show => {
  return {
    type: MODAL_MOD_ELECCION,
    show,
  }
}

const mostrarModalCanEleccion = show => {
  return {
    type: MODAL_CAN_ELECCION,
    show,
  }
}

const mostrarModalFinEleccion = show => {
  return {
    type: MODAL_FIN_ELECCION,
    show,
  }
}

const mostrarModalProlEleccion = show => {
  return {
    type: MODAL_PROL_ELECCION,
    show,
  }
}

const mostrarModalLinkEleccion = show => {
  return {
    type: MODAL_LINK_ELECCION,
    show,
  }
}
const mostrarModalAdmCandidatos = show => {
  return {
    type: MODAL_ADM_CANDIDATOS,
    show,
  }
}
const mostrarModalFiltrarEleccion = show => {
  return {
    type: MODAL_FILTRAR_ELLECIONES,
    show,
  }
}
const actualizarDataEleccion = eleccion => {
  return {
    type: ACT_DATA_ELECCION,
    eleccion,
  }
}


const actualizarDataElecciones = elecciones => {
  return {
    type: ACT_DATA_ELECCIONES,
    elecciones,
  }
}

const actualizarRenderEleccion = renderElecciones => {
  return {
    type: ACT_RENDER_ELECCIONES,
    renderElecciones,
  }
}

const mostrarModalVotarCandidatos = show => {
  return {
    type: MODAL_VOTAR_CANDIDATOS,
    show,
  }
}


module.exports = {
  mostrarModalAddEleccion,
  mostrarModalModEleccion,
  mostrarModalCanEleccion,
  mostrarModalFinEleccion,
  mostrarModalProlEleccion,
  actualizarDataEleccion,
  actualizarDataElecciones,
  actualizarRenderEleccion,
  mostrarModalAdmCandidatos,
  MODAL_ADM_CANDIDATOS,
  MODAL_ADD_ELECCION,
  MODAL_MOD_ELECCION,
  MODAL_CAN_ELECCION,
  MODAL_FIN_ELECCION,
  MODAL_PROL_ELECCION,
  ACT_DATA_ELECCION,
  ACT_DATA_ELECCIONES,
  ACT_RENDER_ELECCIONES,
  mostrarModalLinkEleccion,
  MODAL_LINK_ELECCION,
  MODAL_VOTAR_CANDIDATOS,
  mostrarModalVotarCandidatos,
  MODAL_FILTRAR_ELLECIONES,
  mostrarModalFiltrarEleccion,
}
