import React from 'react';
import { List, ListItem, Grid, ListItemText } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { coloresEmma} from "../../global/js/funciones";


const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    color: 'black',
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
    marginLeft: 0,
    width: '100%',
    height: '35px',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: coloresEmma.secondarycolor,
  },
  inputRoot: {
    color: 'black',

  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        // width: 200,
      },
    },
  },
}));
export default function TituloAccion({ dato_buscar = '', principal = '', alterno = '', buscar = false, callback = () => { } }) {
  const classes = useStyles();
  return (
    <Grid container direction={'row'} style={{ backgroundColor: 'white' }}>
      {
        principal || alterno &&
        <Grid item xs={12} sm={8} md={8} style={{'marginLeft': '8px'}}>
          <List >
            <ListItem className='titulo'>
              <ListItemText primary={principal} secondary={alterno} />
            </ListItem>
          </List>
        </Grid>
      }

      {
        buscar ?
          <Grid item xs={12} sm={4} md={4} className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar"
              value={dato_buscar}
              onChange={(e) => callback(e.target.value)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              color='secondary'
              inputProps={{ 'aria-label': 'search' }}
            />
          </Grid>
          : ''
      }

    </Grid>
  );
}
