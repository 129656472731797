import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import emma_s from "../../../global/imagenes/emma_s1.png";
import TareasTerminadas from "../TareasTerminadas";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(10, 0, 0, 0),
    flexGrow: 1
  }
}))

export default function Not_Found() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TareasTerminadas mensaje='  Es posible que la pagina que trata de buscar no exista o no tenga acceso a la misma, si el problema persiste contacte con el administrador.' marginTop='4%' imagen={emma_s} widthImg="7%" />
    </div>
  )
}
