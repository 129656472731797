import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { BtnEditar, BtnDetalle } from "../general/BotonesAccion";
import moment from 'moment'
import ListarDatos from '../general/ListarDatos';
import MenuModulo from '../general/MenuModulo';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';

class GenericasListar extends Component {

  render() {
    let { genericas, mostrarModalMod, actualizarDataGenerica, mostrarModalAdd, mostrarModalAdmValores } = this.props;

    const actions = (rowData) => {
      const onClickEditar = (data) => {
        actualizarDataGenerica(data);
        mostrarModalMod(true);
      }

      const onClickValores = (data) => {
        actualizarDataGenerica(data);
        mostrarModalAdmValores(true);
      }
      return <div>  <BtnEditar callback={() => onClickEditar(rowData)} /> <BtnDetalle callback={() => onClickValores(rowData)} texto=' Valores' color='#004d40' /></div>;
    }

    return (

      <ListarDatos
        id="tbl_listar_genericas"
        descargar="EMMA - GENERICAS"
        datos={genericas}
        opciones={true}
        agregar={true}
        buscar={true}
        actAgregar={() => mostrarModalAdd(true)}
        css={{
          list: { padding: '0px 0px 20px 0px' },
          appBar: { padding: '0px 0px 0px 0px' },
        }}
        acciones={(row) => actions(row)}
        titulo={"Lista genericas"}
        fila_principal={({ nombre, id }) => `${id} - ${nombre}`}
        filas={[
          {
            'mostrar': ({ id }) => `${id}`,
            'id': 'id', 'enLista': false
          },
          {
            'mostrar': ({ nombre }) => `${nombre}`,
            'id': 'nombre', 'enLista': false
          },
          {
            'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'),
            'id': 'fecha_registro',
          },
          { 'id': 'descripcion', 'enLista': false },
        ]}
      />
    );
  }

}

//Props del componente
GenericasListar.propTypes = {
  //variables
  genericas: PropTypes.array.isRequired,
  //funciones
  actualizarDataGenerica: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired,
  mostrarModalMod: PropTypes.func.isRequired,
  mostrarModalDel: PropTypes.func.isRequired,
  mostrarModalAdmValores: PropTypes.func.isRequired,
}


export default GenericasListar;


