import React, { Component } from 'react';
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Card, CardHeader, CardContent, Avatar, 
    Typography, useMediaQuery, useTheme } from '@material-ui/core'
import {
    mostrarModalAccionesRapidas,
    mostrarModalAddPerfilPortal,
    mostrarModalCrearOferta,
    mostrarModalListarPostulantes,
    mostrarModalGestionProcesoEmpresa,
    mostrarModalEstadoProceso,
    mostrarModalDetallePostulante,
    mostrarModalDetalleEstados,
    mostrarModalAddPqrs,
    mostrarModalDetallePqrs,
    mostrarModalListaEstadistica,
    mostrarModalGestionOferta,
} from '../../redux/actions/actPortal'
import { consulta, crear_form_data, formulario, mostrarError, obtenerValores, api, obtenerPermisosValorAlt } from '../../global/js/funciones';
import { DOCUMENTOS_INFORMATIVOS, TIPOS_VACANTES } from './helper';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import TareasTerminadas from "../general/TareasTerminadas";
//Components
import MenuEmpresa from './Empresa/MenuEmpresa'
import CrearOferta from './Empresa/CrearOferta'
import Postulantes from './Empresa/Postulantes'
import DetalleEmpresa from './Empresa/DetalleEmpresa'
import AccionesRapidasEmpresa from './Empresa/AccioneRapidasEmpresa'
import EmpresaForms from './Empresa/EmpresaForms'
import ListarPostulantes from './Empresa/ListarPostulantes';
import GestionarProceso from './Empresa/GestionarProceso'
import DetallePostulante from './Empresa/DetallePostulante'
import DetalleEstados from './Empresa/DetalleEstados'
import Pqrs from './Pqrs'
import AddPqrs from './Pqrs/AddPqrs'
import DetallePqrs from './Pqrs/DetallePqrs'
import BuscarCV from './Empresa/BuscarPostulantes'
import GestionOferta from './PortalGestion/GestionOferta'
import { ImgPortalPiel } from './ImgPortalPiel';
//Imagenes
import mintrabajo from '../../global/imagenes/portal/mintrabajo.png';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: "100%", height: '350px'
    },
    image: {
        width: '150px',
        maxWidth: '200px',
        minWidth: '100px',
        height: "150px",
    },
    content_text: {
        padding: "15px 10px 0px 10px",
    },
    content_img: {
        textAlign: 'center',
        height: "150px",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

// const theme = useTheme();
// const isMobile = useMediaQuery(theme.breakpoints.down("md"));


function Tipos({ data, image, avatarcss, cardcss = { maxWidth: "100%", height: '350px', cursor: 'pointer' } }) {
    let { nombre, descripcion, opciones } = data;
    const classes = useStyles();
    return (
        <Card style={cardcss} elevation={1}>
            <CardHeader
                avatar={<Avatar aria-label="recipe" style={avatarcss}> {nombre.charAt(0)} </Avatar>}
                title={`${nombre}`}
                subheader={opciones}
            />
            <CardContent >
                <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} className={classes.content_img}>
                        <img src={image} className={classes.image} alt='Imagen Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.content_text}>
                        <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                            {descripcion}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}


class Empresa extends Component {
    constructor() {
        super();
        this.state = {
            seleccion: 'postulante',
            tipoVacanteSeleccion: '',
            llave: null,
            getDatos: null,
            getOfertas: [],
            getDatosPrincipales: [],
            getResponsable: [],
            tiposVacantes: [],
            postulante: {},
            // detallePostulante: [],
            // postulantes: [],
            // estados: [],
            postulante: { estado_proceso: { id: '0' } },
            datos_modal: {
                nombre_modal: '',
                btnAdd: null
            },
            Empresa: {
                nit: '',
                nombre: '',
                razon_social: '',
                numero_empleados: '',
                direccion: '',
                departamento: '',
                ciudad: '',
                localidad: '',
                descripcion: '',
                year_creacion: '',
                web_side: '',
                archivo: '',
            },
            Responsable: {
                nombre: '',
                apellido: '',
                cargo: '',
                email: '',
                telefono: ''
            },
            Oferta: {
                nombre_cargo: '',
                n_vacantes: '',
                tipo_cargo: '',
                area: '',
                descripcion: '',
                tipo_contrato: '',
                duracion_contrato: '',
                jornada: '',
                salario: ''
            },
            mensaje_crear: 'Click aquí para crear un nueva oferta, especifique con claridad todos los campos que sean requeridos. Una vez llenados debe darle click en el boton GUARDAR',
            titulo_pqrs: '',
            body_pqrs: '',
            listaPqrs: '',
            pqrs: {},
            oferta: {},
            cargando: true,
            idVacantePostulantes: 0,
            idDetallePostulante: 0,
            idVacanteEstados: 0,
            doc_informativos: [],
            validarPerfil: false
        };
    }

    async componentDidMount() {
        let { mostrarModulo } = this.props;
        mostrarModulo({ ruta: '/empresa', nombre: 'Portal Empleo (Empresas)' })
        this.setState({ cargando: true })
        this.setState({
            getDatosPrincipales: await this.getDatosPrincipales(),
            getOfertas: await this.getVacantes(),
            tiposVacantes: await obtenerValores(TIPOS_VACANTES),
            doc_informativos: (await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': 'empresa_act' }, { 'llave': 'secundario__generica', 'valor': DOCUMENTOS_INFORMATIVOS }])).map(({secundario}) => secundario),
            cargando: false
        })
    }

    async componentDidUpdate({ }, { seleccion }) {
        let { seleccion: seleccionNew } = this.state;
        if (seleccion !== seleccionNew) {
            this.setState({ cargando: true })
            if (seleccionNew === 'mi_empresa') this.setState({ getDatosPrincipales: await this.getDatosPrincipales(), getResponsable: await this.getResponsable() })
            else if (seleccionNew === 'postulante') this.setState({ getOfertas: await this.getVacantes() })
            else if (seleccionNew === 'pqrs') this.setState({ listaPqrs: await this.getPqrs() })
            else if (seleccionNew === 'oferta') this.setState({ validarPerfil: await this.getValidacionPerfil() })
            this.setState({ cargando: false })
        }
    }

    cambiarEstado = (estados) => {
        this.setState(estados)
    }

    cambiarOpcion = (seleccion) => {
        this.cambiarEstado({ seleccion })
    }

    abrirOpcionesPerfil = () => {
        let { mostrarModalAccionesRapidas } = this.props
        mostrarModalAccionesRapidas(true)
    }

    abrirCrearOferta = (tipoVacante) => {
        this.setState({
            llave: 'Oferta',
            tipoVacanteSeleccion: tipoVacante,
            Oferta: {
                nombre_cargo: '',
                n_vacantes: '',
                tipo_cargo: '',
                area: '',
                descripcion: '',
                tipo_contrato: '',
                duracion_contrato: '',
                jornada: '',
                salario: ''
            }
        })
        let { mostrarModalCrearOferta } = this.props
        mostrarModalCrearOferta(true)
    }

    onChangeState = ({ target }) => {
        let { name, value } = target
        let { llave } = this.state
        this.setState(prevState => ({
            [llave]: {
                ...prevState[llave],
                [name]: value
            }
        })
        )
    }

    copiaState = (llave) => {
        switch (llave) {
            case 'Empresa':
                return {
                    nit: '',
                    nombre: '',
                    razon_social: '',
                    numero_empleados: '',
                    direccion: '',
                    departamento: '',
                    ciudad: '',
                    localidad: '',
                    descripcion: '',
                    year_creacion: '',
                    web_side: ''
                }
            case 'Responsable':
                return {
                    nombre: '',
                    apellido: '',
                    cargo: '',
                    email: '',
                    telefono: ''
                }
            default:
                break;
        }
    }

    obtenerDatosEmpresa = (llave) => {
        let url = ""
        if (llave === "Empresa") url = "empresa_listar"
        else if (llave === "Responsable") url = "responsable_listar"

        return new Promise(resolve => {
            consulta(`api/v1.0/portal/${url}`, null, null, (error, estado, resp) => {
                resolve({ resp })
            })
        })
    }

    getVacantes = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/oferta_listar`, null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }

    getValidacionPerfil = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/validar_perfil/empresa`, null, null, (error, estado, resp) => {
                resolve(resp.validacion)
            })
        })
    }

    getDatosPrincipales = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/empresa_listar`, null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }

    getResponsable = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/responsable_listar`, null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }

    crearVacante = (data) => {
        let { getDatosPrincipales } = this.state;
        data.tipo_documento = "Nit"
        data.identificacion = getDatosPrincipales[0].nit
        data.razon_social = getDatosPrincipales[0].razon_social
        let { mostrarModalCrearOferta, actualizarMensaje } = this.props
        consulta(`api/v1.0/portal/oferta_crear`, data, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalCrearOferta(false)
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    actualizarVacante = (data) => {
        let { mostrarModalCrearOferta, actualizarMensaje } = this.props
        consulta(`api/v1.0/portal/oferta_actualizar/${data.id}`, data, "put", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalCrearOferta(false)
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    abrirFormConLaInfo = async (llave, nombre_modal) => {
        let { mostrarModalAddPerfilPortal } = this.props
        let { resp } = await this.obtenerDatosEmpresa(llave)
        let data = {}
        if (resp.length >= 1) {
            data = resp[0]
        } else {
            data = this.copiaState(llave)
        }
        this.setState(prevState => ({
            llave,
            datos_modal: {
                ...prevState.datos_modal,
                nombre_modal
            },
            [llave]: data
        })
        )
        mostrarModalAddPerfilPortal(true)
    }

    enviarFormActual = () => {
        let { llave, getDatosPrincipales } = this.state;
        let { actualizarMensaje } = this.props
        let data = this.state[llave]

        const validarEmpresa = info => {
            let { nit, nombre, razon_social, numero_empleados, direccion, departamento, ciudad, localidad, descripcion, year_creacion, web_side } = info
            let campo = ''
    
            if (!nit) campo = 'Nit'
            else if (!nombre) campo = 'Nombre'
            else if (!razon_social) campo = 'Razon Social'
            else if (!numero_empleados) campo = 'Numero Empleados'
            else if (!direccion) campo = 'Direccion'
            else if (!departamento) campo = 'Departamento'
            else if (!ciudad) campo = 'Ciudad'
            else if (!localidad) campo = 'Localidad'
            else if (!descripcion) campo = 'Descripcion Empresa'
            else if (!year_creacion) campo = 'Año Creacion'
            else if (!web_side) campo = 'Web Side'

            if(campo){
                actualizarMensaje({ titulo: `El campo ${campo} es obligatorio.`, tipo: 'info', mostrar: true, tiempo: 3000 });
                return false
            }

            return true
        }

        const validarResponsable = info => {
            let { nombre, apellido, cargo, telefono, celular, email} = info
            let campo = ''
    
            if (!nombre) campo = 'Nombre'
            else if (!apellido) campo = 'Apellido'
            else if (!cargo) campo = 'Cargo'
            else if (!telefono) campo = 'Telefono'
            else if (!celular) campo = 'Celular'
            else if (!email) campo = 'Email'
            else if (!/^\d+$/.test(telefono)) campo = 'Telefono debe ser solo numérico,'
             else if (!/^\d+$/.test(celular)) campo = 'Celular debe ser solo numérico,'

            if(campo){
                actualizarMensaje({ titulo: `El campo ${campo} es obligatorio.`, tipo: 'info', mostrar: true, tiempo: 3000 });
                return false
            }

            return true
        }

        if (data.id) {
            switch (llave) {
                case 'Empresa':
                    if(validarEmpresa(data)) this.modificarInformacionEmpresa(data, 'empresa')
                    break;
                case 'Responsable':
                    if(validarResponsable(data)) this.modificarInformacionEmpresa(data, 'responsable')
                default:
                    break;
            }
        } else {
            switch (llave) {
                case 'Empresa':
                    if(validarEmpresa(data)) this.guardarInformacionEmpresa(data, 'empresa_crear')
                    break;
                case 'Responsable':
                    if (getDatosPrincipales[0]) {
                        data.empresa = getDatosPrincipales[0].id
                        if(validarResponsable(data)) this.guardarInformacionEmpresa(data, 'responsable_crear')
                    } else {
                        let tipo = 'info';
                        let titulo = 'Ha ocurrido un error, Perfil no creado.';
                        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
                    }
                default:
                    break;
            }
        }
        
    }

    guardarInformacionEmpresa = (data, endpoint) => {
        let { mostrarModalAddPerfilPortal, actualizarMensaje } = this.props
        consulta(`api/v1.0/portal/${endpoint}`, data, "post", async (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalAddPerfilPortal(false)
                    this.setState({ cargando: true })
                    this.setState({
                        getDatosPrincipales: await this.getDatosPrincipales(),
                        getResponsable: await this.getResponsable(),
                        cargando: false
                    })
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    modificarInformacionEmpresa = async (data, endpoint) => {
        let { mostrarModalAddPerfilPortal, actualizarMensaje } = this.props;
        let { nombre_archivo } = data;
        if (nombre_archivo) {
            data.archivo = document.getElementById('info_archivo_mod').files[0];
        } else {
            delete data.archivo
        }
        let data2 = await crear_form_data(data);
        data.archivo = nombre_archivo;
        formulario(`api/v1.0/portal/${data.id}/${endpoint}`, data2, "patch", async (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalAddPerfilPortal(false);
                    this.setState({ cargando: true })
                    this.setState({
                        getDatosPrincipales: await this.getDatosPrincipales(),
                        getResponsable: await this.getResponsable(),
                        cargando: false
                    })
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    editar_oferta = (data) => {
        let { mostrarModalCrearOferta } = this.props
        this.setState({
            Oferta: data,
            tipoVacanteSeleccion: data.tipo_oferta
        })
        mostrarModalCrearOferta(true)
    }

    obtenerEstadoProceso = async (row) => {
        let { mostrarModalEstadoProceso } = this.props
        this.setState({
            postulante: row
        });
        mostrarModalEstadoProceso(true)
    }

    gestionarProceso = async (id, data, callback) => {
        let { mostrarModalEstadoProceso, actualizarMensaje, mostrarModalListarPostulantes } = this.props;
        let form = await crear_form_data(data);
        formulario(`api/v1.0/portal/${id}/gestionar_proceso`, form, "post", (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalEstadoProceso(false);
                    mostrarModalListarPostulantes(false);
                } else {
                    tipo = 'info';
                    titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
            callback();
        })
    }

    listarPostulantes = (row) => {
        let idVacantePostulantes = row.id
        let { mostrarModalListarPostulantes } = this.props
        this.setState({ idVacantePostulantes })
        // consulta(`api/v1.0/portal/postulantes_listar`, { id }, "post", (error, estado, resp) => {
        //     this.setState({
        //         postulantes: resp.filter(vac => vac.estado_proceso.codigo == "Por_Acep")
        //     })
        // })
        mostrarModalListarPostulantes(true)
    }

    obtenerEstados = (id) => {
        let { mostrarModalDetalleEstados } = this.props
        this.setState({ idVacanteEstados: id })
        // consulta(`api/v1.0/portal/estados_listar`, { id }, "post", (error, estado, resp) => {
        //     this.setState({
        //         estados: resp
        //     })
        // })
        mostrarModalDetalleEstados(true)
    }

    listarInformacionPostulante = (row) => {
        let idDetallePostulante = row.postulante.id
        let { mostrarModalDetallePostulante } = this.props
        this.setState({ idDetallePostulante })
        // consulta("api/v1.0/portal/obtener_detalle_postulante", { id }, "post", (error, estado, resp) => {
        //     this.setState({
        //         detallePostulante: resp
        //     })
        // })
        mostrarModalDetallePostulante(true)
    }

    abrir_modal_gestion_oferta = async (data) => {
        let { mostrarModalGestionOferta } = this.props
        this.setState({
            oferta: await data
        })
        mostrarModalGestionOferta(true)
    }

    gestionarOferta = async (id, data, callback) => {
        let { mostrarModalGestionOferta, actualizarMensaje } = this.props;
        let form = await crear_form_data(data);
        formulario(`api/v1.0/portal/${id}/gestionar`, form, "post", async (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalGestionOferta(false);
                    this.setState({ cargando: true })
                    this.setState({
                        // getResponsable: await this.getResponsable(),
                        getOfertas: await this.getVacantes(),
                        cargando: false
                    })
                } else {
                    tipo = 'info';
                    titulo = resp.titulo ? resp.titulo : mostrarError(resp);
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
            callback();
        })
    }

    //-------------------------- pqrs

    abrirDetallePqrs = (pqrs) => {
        this.setState({ pqrs });
        this.props.mostrarModalDetallePqrs(true);
    }

    onChangeStatePqrs = ({ target }) => {
        let { name, value } = target
        this.setState({
            [name]: value
        })
    }

    crearPqrs = () => {
        let { mostrarModalAddPqrs, actualizarMensaje } = this.props
        let { titulo_pqrs, body_pqrs } = this.state;

        if (titulo_pqrs == '' || titulo_pqrs == null) return;
        if (body_pqrs == '' || body_pqrs == null) return;

        let data = {
            titulo_pqrs,
            body_pqrs
        }

        consulta(`api/v1.0/portal/pqrs/add`, data, "post", async (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
                if (estado === 200) {
                    tipo = 'success';
                    titulo = resp.titulo;
                    mostrarModalAddPqrs(false);
                    this.setState({ cargando: true })
                    this.setState({
                        listaPqrs: await this.getPqrs(),
                        cargando: false
                    })
                } else {
                    tipo = 'info'
                    titulo = resp.titulo
                }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 })
        })
    }

    getPqrs = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/portal/pqrs/list`, null, null, (error, estado, resp) => {
                resolve(resp)
            })
        })
    }



    vista = () => {

        let {
            seleccion,
            llave,
            datos_modal,
            // postulantes,
            postulante,
            // detallePostulante,
            // estados,
            listaPqrs,
            pqrs,
            oferta,
            tipoVacanteSeleccion,
            cargando,
            idVacantePostulantes,
            idDetallePostulante,
            idVacanteEstados
        } = this.state
        let {
            modalAccionesRapidas,
            mostrarModalAccionesRapidas,
            mostrarModalAddPerfilPortal,
            modalAddPerfilPortal,
            mostrarModalCrearOferta,
            modalCrearOferta,
            modalListarPostulantes,
            mostrarModalListarPostulantes,
            mostrarModalEstadoProceso,
            modalEstadoProceso,
            modalDetallePostulante,
            mostrarModalDetallePostulante,
            mostrarModalDetalleEstados,
            modalDetalleEstados,
            modalAddPqrs,
            mostrarModalAddPqrs,
            modalDetallePqrs,
            mostrarModalDetallePqrs,
            modalGestionOferta,
            mostrarModalGestionOferta,
            actualizarMensaje
        } = this.props
        switch (seleccion) {
            case 'mi_empresa':
                return (
                    <div>
                        {!cargando ?
                            this.pintarDatosDeLaEmpresa() :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <AccionesRapidasEmpresa
                            modalAccionesRapidas={modalAccionesRapidas}
                            mostrarModalAccionesRapidas={mostrarModalAccionesRapidas}
                            abrirFormConLaInfo={this.abrirFormConLaInfo}
                        />
                        <EmpresaForms
                            llave={llave}
                            onChangeState={this.onChangeState}
                            datos_modal={datos_modal}
                            datosEmpresa={this.state.Empresa}
                            datosResponsable={this.state.Responsable}
                            enviarFormActual={this.enviarFormActual}
                            modalAddPerfilPortal={modalAddPerfilPortal}
                            mostrarModalAddPerfilPortal={mostrarModalAddPerfilPortal}
                        />
                    </div>
                )
            case 'postulante':
                return (
                    <div>
                        {!cargando ?
                            this.pintarPostulantes() :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <CrearOferta // Validar cargue de valores
                            mostrarModalCrearOferta={mostrarModalCrearOferta}
                            modalCrearOferta={modalCrearOferta}
                            data={this.state.Oferta}
                            actualizar={true}
                            actualizarVacante={this.actualizarVacante}
                            tipoVacante={tipoVacanteSeleccion}
                            actualizarMensaje={actualizarMensaje}
                        />
                        <ListarPostulantes
                            {...{
                                // postulantes,
                                mostrarModalListarPostulantes,
                                modalListarPostulantes
                            }}
                            obtenerEstadoProceso={this.obtenerEstadoProceso}
                            listarInformacionPostulante={this.listarInformacionPostulante}
                            idVacantePostulantes={idVacantePostulantes}
                        />
                        <DetallePostulante
                            {...{
                                mostrarModalDetallePostulante,
                                modalDetallePostulante,
                                // detallePostulante
                            }}
                            idDetallePostulante={idDetallePostulante}
                        />
                        <GestionarProceso
                            {...{
                                postulante,
                                mostrarModalEstadoProceso,
                                modalEstadoProceso
                            }}
                            gestionarProceso={this.gestionarProceso}
                        />
                        <DetalleEstados
                            {...{
                                // estados,
                                mostrarModalDetalleEstados,
                                modalDetalleEstados,
                                idVacanteEstados
                            }}
                        />
                        {
                            oferta.id && (
                                <GestionOferta
                                    modalGestionOferta={modalGestionOferta}
                                    mostrarModalGestionOferta={mostrarModalGestionOferta}
                                    oferta={oferta}
                                    gestionarOferta={this.gestionarOferta}
                                />
                            )
                        }
                    </div>
                )
            case 'oferta':
                return (
                    <div>
                        {!cargando ?
                            this.pintarFormCrearOferta() :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <CrearOferta
                            onChangeState={this.onChangeState}
                            data={this.state.Oferta}
                            modalCrearOferta={modalCrearOferta}
                            mostrarModalCrearOferta={mostrarModalCrearOferta}
                            crearVacante={this.crearVacante}
                            tipoVacante={tipoVacanteSeleccion}
                            actualizarMensaje={actualizarMensaje}
                        />
                    </div>
                )
            case 'pqrs':
                return (
                    <>
                        {!cargando ?
                            <Pqrs
                                listaPqrs={listaPqrs}
                                mostrarModalAddPqrs={mostrarModalAddPqrs}
                                abrirDetallePqrs={this.abrirDetallePqrs}
                            /> :
                            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                        }
                        <AddPqrs
                            modalAddPqrs={modalAddPqrs}
                            mostrarModalAddPqrs={mostrarModalAddPqrs}
                            enviarFormActual={this.crearPqrs}
                            onChangeState={this.onChangeStatePqrs}
                        />
                        <DetallePqrs
                            pqrs={pqrs}
                            modalDetallePqrs={modalDetallePqrs}
                            mostrarModalDetallePqrs={mostrarModalDetallePqrs}
                        />
                    </>
                )
            case 'CV':
                return (
                    <>
                        <BuscarCV />
                    </>
                )
            default:
                return (
                    <>
                    </>
                )
        }

    }

    pintarPostulantes = () => {
        return (
            <div>
                <Postulantes
                    editar_oferta={this.editar_oferta}
                    getOfertas={this.state.getOfertas}
                    listarPostulantes={this.listarPostulantes}
                    obtenerEstados={this.obtenerEstados}
                    cerrar_oferta={this.abrir_modal_gestion_oferta}
                />
            </div>
        )
    }

    pintarDatosDeLaEmpresa = () => {
        let { getDatosPrincipales, getResponsable } = this.state
        return (
            <div className='' >
                <div style={{ padding: '2% 2% 0% 2%' }} >
                    <Grid container direction={'row'} spacing={2} >
                        <Grid item xs={12} sm={12} md={12} >
                            <Paper elevation={0} square className="scroll" style={{ maxHeight: 400, width: "100%" }}>
                                <DetalleEmpresa
                                    abrirOpcionesPerfil={this.abrirOpcionesPerfil}
                                    {...{
                                        getDatosPrincipales,
                                        getResponsable
                                    }}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    pintarFormCrearOferta = () => {
        let { tiposVacantes, validarPerfil } = this.state;
        return (
            <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }} >
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        {validarPerfil ?
                            tiposVacantes.map((tipo, i) => {
                                return (
                                    <Grid item key={i} xs={12} sm={6} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => this.abrirCrearOferta(tipo)}>
                                        <Paper elevation={8} square>
                                            <Tipos
                                                avatarcss={{ background: '#f44336' }}
                                                cardcss={{ maxWidth: "100%", height: '420px', cursor: 'pointer' }}
                                                image={`${api}${tipo.archivo}`}
                                                data={{
                                                    opciones: `Click! para crear una nueva ${tipo.nombre.toLowerCase()}`,
                                                    nombre: `${tipo.nombre.toUpperCase()}`,
                                                    descripcion: `Click aquí para crear una nueva ${tipo.nombre.toLowerCase()}, especifique con claridad todos los campos que sean requeridos. Una vez llenados debe darle click en el boton GUARDAR`
                                                }}
                                            />
                                        </Paper>
                                    </Grid>
                                )
                            }) :
                            <TareasTerminadas mensaje='Para crear ofertas, debe completar su perfil' marginTop='5%' imagen={emma_s} widthImg="12%" />
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    render() {
        let { seleccion, doc_informativos } = this.state
        // const theme = theme();
        // const isMobile = isMobile();
        
        return (
            <>
                <div>
                    <MenuEmpresa sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} doc_informativos = { doc_informativos } />
                    <div style={{
                    position: 'fixed',
                    top: '80px',
                    left: '120px',
                    zIndex: '1000'
                }} >
                    <img src={mintrabajo} className="logoMT" />
                </div>
                    <div className='contenido_modulo' style={{'marginTop' : '70px'}}>
                            { this.vista() }
                    </div>
                    <ImgPortalPiel/>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    let {
        modalAccionesRapidas,
        modalAddPerfilPortal,
        modalCrearOferta,
        modalListarPostulantes,
        modalGestionProcesoEmpresa,
        modalEstadoProceso,
        modalDetallePostulante,
        modalDetalleEstados,
        modalAddPqrs,
        modalDetallePqrs,
        modalListaEstadistica,
        modalGestionOferta,
    } = state.redPortal
    return {
        modalAccionesRapidas,
        modalAddPerfilPortal,
        modalCrearOferta,
        modalListarPostulantes,
        modalGestionProcesoEmpresa,
        modalEstadoProceso,
        modalDetallePostulante,
        modalDetalleEstados,
        modalAddPqrs,
        modalDetallePqrs,
        modalListaEstadistica,
        modalGestionOferta,
    }
}

const mapDispatchToProps = {
    mostrarModulo,
    actualizarMensaje,
    mostrarModalAccionesRapidas,
    mostrarModalAddPerfilPortal,
    mostrarModalCrearOferta,
    mostrarModalListarPostulantes,
    mostrarModalGestionProcesoEmpresa,
    mostrarModalEstadoProceso,
    mostrarModalDetallePostulante,
    mostrarModalDetalleEstados,
    mostrarModalAddPqrs,
    mostrarModalDetallePqrs,
    mostrarModalListaEstadistica,
    mostrarModalGestionOferta,
}

Empresa.propTypes = {
    //funciones
    mostrarModulo: PropTypes.func.isRequired,
    mostrarModalAccionesRapidas: PropTypes.func.isRequired,
    mostrarModalAddPerfilPortal: PropTypes.func.isRequired,
    mostrarModalCrearOferta: PropTypes.func.isRequired,
    mostrarModalListarPostulantes: PropTypes.func.isRequired,
    mostrarModalGestionProcesoEmpresa: PropTypes.func.isRequired,
    mostrarModalEstadoProceso: PropTypes.func.isRequired,
    mostrarModalDetallePostulante: PropTypes.func.isRequired,
    mostrarModalDetalleEstados: PropTypes.func.isRequired,
    actualizarMensaje: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Empresa);
