import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  mostrarModulo,
  actualizarMensaje,
} from "../../redux/actions/actGlobal";
import { useDispatch, useSelector } from "react-redux";
import {
  mostrarModalAddFiltrosInsPosgrado,
  mostrarModalDetalleInsPosgrado,
  mostrarModalGestionarInsPosgrado,
} from "../../redux/actions/actInscripcionesPosgrado";

// Global Functions
import {
  consulta,
  crear_form_data,
  formulario,
  generarFiltros,
  mostrarError,
  obtenerValoresFiltros,
} from "../../global/js/funciones";

// Material UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";

// General Components
import TituloAccion from "../general/TituloAccion";
import TareasTerminadas from "../general/TareasTerminadas";
import AccionesRapidas from "../general/AccionesRapidas";
import AppBarModal from "../general/AppBarModal";
import {
  BtnDetalle,
  BtnForm,
  BtnGestionar,
  BtnRegresar,
} from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos";

// Components
import { MenuInscripciones } from "./administrar/MenuInscripciones";
import InscripcionPosgradoFiltro from "./administrar/InscripcionPosgradoFiltro";
import ProcesosInscripcionesPosgrado from "./administrar/InscripcionesPosgradoProcesos";
import InscripcionesPosgradoPermisos from "./administrar/InscripcionesPosgradoPermisos";
import InscripcionesPosgradoGestionar from "./administrar/InscripcionesPosgradoGestionar";

// Images
import emma_w from "../../global/imagenes/emma_w.png";
import emma_s1 from "../../global/imagenes/emma_s1.png";

const InscripcionesPosgrados = () => {
  const dispatch = useDispatch();
  const { usuario } = useSelector((state) => state.redGlobal);

  const [accionProceso, setAccionProceso] = useState("inicio");
  const [accion, setAccion] = useState("procesos");
  const [administrador, setAdministrador] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [periodo, setPeriodo] = useState("");
  const [periodos, setPeriodos] = useState([]);
  const [periodoFiltro, setPeriodoFiltro] = useState("");
  const [estadosProcesos, setEstadosProcesos] = useState([]);
  const [filtrarPeriodo, setFiltrarPeriodo] = useState(false);
  const [cargandoPeriodo, setCargandoPeriodo] = useState(true);
  const [filtros, setFiltros] = useState([]);
  const [cargarFiltroPerido, setCargarFiltroPerido] = useState(true);
  const [procesoInscripciones, setProcesoInscripciones] = useState(null);
  const [procesosInscripciones, setProcesosInscripciones] = useState([]);

  useEffect(() => {
    dispatch(
      mostrarModulo({
        ruta: "/gestion_inscripciones_posgrados",
        nombre: "inscripciones posgrados",
      })
    );
    obtenerDatosIniciales();
    esAdministrador();
  }, []);

  useEffect(() => {
    // obtenerEstadoInscripciones(periodo);

    if (accionProceso !== "Tod_Ins") {
      if (accionProceso === "inicio") {
        obtenerEstadoInscripciones(periodoFiltro);
      } else {
        actualizarDataProcesos([
          { llave: "estado_actual__codigo", valor: accionProceso },
          { llave: "periodo", valor: periodoFiltro },
        ]);
      }
    }
  }, [accionProceso, accion]);

  const esAdministrador = () => {
    if (
      usuario &&
      usuario.perfil &&
      (usuario.perfil.codigo === "Per_Adm" ||
        usuario.perfil.codigo === "Per_Ges_Pos")
    ) {
      setAdministrador(true);
    }
  };

  const obtenerDatosIniciales = async () => {
    setCargando(true);
    let periodo = await obtenerValoresFiltros([
      { llave: "codigo", valor: "Per_Ins_Posg" },
    ]);
    obtenerEstadoInscripciones(periodo[0].nombre);
    setPeriodo(periodo.length > 0 && (periodo[0].nombre ?? ""));
    setPeriodoFiltro(periodo.length > 0 && (periodo[0].nombre ?? ""));
  };

  const filtrarEstadoSolicitudes = () => {
    setFiltrarPeriodo(false);
    setCargando(true);
    obtenerEstadoInscripciones(periodoFiltro);
  };

  const obtenerPeriodos = () => {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/inscripciones_posgrado/periodos`,
        null,
        null,
        (error, estado, res) => {
          resolve(res);
        }
      );
    });
  };

  const modalfiltroPeriodo = async () => {
    setFiltrarPeriodo(true);
    if (cargarFiltroPerido) {
      setCargandoPeriodo(true);
      let periodos = await obtenerPeriodos();
      if (!periodos.find((e) => e.periodo === periodo)) {
        periodos.push({ periodo: periodo });
      }
      setPeriodos(periodos);
      setCargarFiltroPerido(false);
      setCargandoPeriodo(false);
    }
  };

  const obtenerEstadoInscripciones = (periodo) => {
    setCargando(true);
    consulta(
      `api/v1.0/inscripciones_posgrado/estados/${periodo}`,
      null,
      null,
      (error, estado, res) => {
        if (estado === 200) {
          let { general, extra } = res;
          if (extra.length > 0) {
            extra.forEach(({ solicitudes, nombre, codigo, orden }) => {
              let reporte = {
                estado_actual__codigo: codigo,
                estado_actual__nombre: nombre,
                num_sol: solicitudes,
                orden: orden,
              };
              general.push(reporte);
            });
          }

          setEstadosProcesos(general.sort((a, b) => a.orden - b.orden));
          setCargando(false);
        }
      }
    );
  };

  const gestionarInscripcion = async (id, data, callback) => {
    let form = await crear_form_data(data);
    data.archivos.forEach((file, index) => {
      form.append(`archivos${index}`, file);
    });
    formulario(
      `api/v1.0/inscripciones_posgrado/${id}/gestionar`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un  error, contacte con el administrador";
        let tipo = "warning";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            dispatch(mostrarModalGestionarInsPosgrado(false));
            actualizarDataProcesos(filtros);
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        dispatch(
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
        );
        callback();
      }
    );
  };

  const actualizarDataProcesos = async (
    filtros = [{ llave: "periodo", valor: periodo }]
  ) => {
    setCargando(true);
    let procesosInscripciones = await obtenerProcesosInscripciones(filtros);
    setProcesosInscripciones(procesosInscripciones);
    setCargando(false);
    setFiltros(filtros);
  };

  const obtenerProcesosInscripciones = async (filtros = []) => {
    let filtro = await generarFiltros(filtros);
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/inscripciones_posgrado/listar/1?${filtro}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  };

  const renderProceso = () => {
    return (
      <>
        {accionProceso === "inicio" ? pintarInicioProcesos() : pintarProcesos()}
        {accionProceso !== "inicio" && (
          <div className="regresar">
            <BtnRegresar
              callback={() => {
                setAccionProceso("inicio");
                setFiltros([]);
              }}
            />
          </div>
        )}
      </>
    );
  };

  const configurarFiltro = (filtro) => {
    switch (filtro) {
      case "Tod_Ins":
        return [{ llave: "periodo", valor: periodoFiltro }];
      case "inicio":
        return [{ llave: "periodo", valor: periodoFiltro }];
      default:
        return [
          { llave: "estado_actual__codigo", valor: filtro },
          { llave: "periodo", valor: periodoFiltro },
        ];
    }
  };

  const vistaPrincipal = () => {
    switch (accion) {
      case "procesos":
        return renderProceso();
      case "administrar":
        return <InscripcionesPosgradoPermisos />;
      default:
        break;
    }
  };

  const pintarInicioProcesos = () => {
    const obtenerColor = (codigo) => {
      const color = "white";
      const colores = {
        Ins_Pos_Reg: { backgroundColor: "#f5f5f5", color: "#757575" },
        Ins_Pos_Pre: { backgroundColor: "#ffc107", color },
        Ins_Pos_Ins: { backgroundColor: "#54a0ff", color },
        Ins_Pos_Adm: { backgroundColor: "#27ae60", color },
        Ins_Pos_Nadm: { backgroundColor: "#e67e22", color },
        Ins_Pos_Mat: { backgroundColor: "#706fd3", color },
        Ins_Pos_Can: { backgroundColor: "#e95a43", color },
        Ins_Pen_Est: { backgroundColor: "#b2bec3", color },
        Tod_Ins: { backgroundColor: "#0d47a1", color },
      };

      if (!colores[codigo])
        return { backgroundColor: "#f5f5f5", color: "#757575" };
      return colores[codigo];
    };

    const handleClick = (codigo) => {
      if (codigo !== "Tod_Ins") setAccionProceso(codigo);
      else {
        dispatch(mostrarModalAddFiltrosInsPosgrado(true));
      }
    };

    return (
      <>
        <div style={{ padding: "2% 2% 0% 2%" }}>
          <Grid
            container
            direction={"row"}
            spacing={1}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid
                container
                direction={"row"}
                alignItems="center"
                spacing={1}
                style={{ width: "100%" }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <TituloAccion
                    principal=""
                    alterno="Seleccione el estado a gestionar: "
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Tooltip
                    title="Filtrar"
                    aria-label="add"
                    onClick={() => modalfiltroPeriodo()}
                  >
                    <IconButton
                      edge={false}
                      color="secondary"
                      style={{ float: "right" }}
                    >
                      <FilterList />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {estadosProcesos.length === 0 ? (
                  <TareasTerminadas
                    mensaje="Aún no hay solicitudes para este periodo."
                    marginTop="7%"
                    imagen={emma_s1}
                    widthImg="7%"
                  />
                ) : (
                  <Grid
                    container
                    direction={"row"}
                    spacing={1}
                    style={{ width: "100%" }}
                  >
                    {estadosProcesos.map(
                      (
                        {
                          estado_actual__codigo: codigo,
                          estado_actual__nombre: nombre,
                          num_sol,
                        },
                        i
                      ) => {
                        return (
                          <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                            <Paper
                              elevation={1}
                              style={{ border: "1px solid #e0e0e0" }}
                              onClick={() => handleClick(codigo)}
                            >
                              <AccionesRapidas
                                imagen=""
                                titulo={`¡ ${nombre} !`}
                                sub_titulo=""
                                des_sub_titulo=""
                                avatar={obtenerColor(codigo)}
                                avatar_name={nombre.charAt(0).toUpperCase()}
                                list_desc={
                                  Number.isInteger(num_sol)
                                    ? [`Numero de Solicitudes : ${num_sol}`]
                                    : ["Debe aplicar los filtros."]
                                }
                              />
                            </Paper>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>

        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={filtrarPeriodo}
          onClose={() => setFiltrarPeriodo(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBarModal
            titulo="¡ Aplicar Filtros !"
            mostrarModal={() => setFiltrarPeriodo(false)}
            titulo_accion=""
            accion=""
          />
          <DialogContent className="scroll">
            {!cargandoPeriodo ? (
              <Grid container className="margin_cont">
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="periodo_filtro">
                      Seleccione Periodo
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "periodo_filtro",
                        id: "periodo_filtro",
                      }}
                      value={periodoFiltro}
                      onChange={(e) => setPeriodoFiltro(e.target.value)}
                      required={true}
                    >
                      {periodos.map(({ periodo }, i) => (
                        <MenuItem key={i} value={periodo}>
                          {periodo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <TareasTerminadas
                imagen={emma_w}
                mensaje="Cargando..."
                widthImg="5%"
                marginTop="5%"
              />
            )}
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() => setFiltrarPeriodo(false)}
            />
            <BtnForm
              texto="FILTRAR"
              callback={() => filtrarEstadoSolicitudes()}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const pintarProcesos = () => {
    const obtenerColores = (codigo) => {
      const color = "white";
      const colores = {
        Ins_Pos_Reg: { backgroundColor: "#f5f5f5", color: "#757575" },
        Ins_Pos_Pre: { backgroundColor: "#ffc107", color },
        Ins_Pos_Ins: { backgroundColor: "#54a0ff", color },
        Ins_Pos_Adm: { backgroundColor: "#27ae60", color },
        Ins_Pos_Nadm: { backgroundColor: "#e67e22", color },
        Ins_Pos_Mat: { backgroundColor: "#706fd3", color },
        Ins_Pos_Can: { backgroundColor: "#e95a43", color },
        Ins_Pen_Est: { backgroundColor: "#b2bec3", color },
        Tod_Ins: { backgroundColor: "#0d47a1", color },
      };

      if (!colores[codigo]) {
        return { backgroundColor: "#f5f5f5", color: "#757575" };
      }
      return colores[codigo];
    };

    const acciones = (data) => {
      let {
        estado_actual: { codigo },
        permiso,
      } = data;
      const onClickDetalle = (procesoInscripciones) => {
        setProcesoInscripciones(procesoInscripciones);
        dispatch(mostrarModalDetalleInsPosgrado(true));
      };
      const onClickGestionar = (procesoInscripciones) => {
        setProcesoInscripciones(procesoInscripciones);
        dispatch(mostrarModalGestionarInsPosgrado(true));
      };

      return (
        <div>
          <BtnDetalle
            callback={() => onClickDetalle(data)}
            color="#01579b"
            texto="Abrir"
          />
          {
          codigo !== "Ins_Pos_Mat" &&
            codigo !== "Ins_Pos_Nadm" &&
            codigo !== "Ins_Pos_Can" &&
            permiso === "1" && (
              <BtnGestionar
                callback={() => onClickGestionar(data)}
                color="#00bfa5"
                texto="Gestionar"
              />
            )}
        </div>
      );
    };

    return (
      <>
        <InscripcionesPosgradoGestionar
          inscripcion={procesoInscripciones}
          gestionarInscripcion={gestionarInscripcion}
        />
        <ListarDatos
          titulo="Lista de inscripciones posgrados"
          id="tbl_historial_procesos_inscripcion_posgrado"
          descargar="INSCRIPCIONES POSGRADO - EMMA"
          datos={procesosInscripciones}
          opciones={true}
          buscar={true}
          acciones={(data) => acciones(data)}
          filtrar={true}
          refrescar={true}
          actfiltrar={() => dispatch(mostrarModalAddFiltrosInsPosgrado(true))}
          actRefrescar={() => actualizarDataProcesos(filtros)}
          avatar={({ estado_actual }) => estado_actual.nombre.charAt(0)}
          color_avatar={({ estado_actual }) =>
            obtenerColores(estado_actual.codigo)
          }
          fila_principal={({
            estudiante: { primer_nombre, primer_apellido, segundo_apellido },
          }) =>
            `${primer_nombre} ${primer_apellido} ${segundo_apellido}`.toUpperCase()
          }
          filas={[
            {
              id: "PRIMER NOMBRE",
              mostrar: ({ estudiante: { primer_nombre } }) =>
                `${primer_nombre ? primer_nombre : ""}`,
              enLista: false,
            },
            {
              id: "SEGUNDO NOMBRE",
              mostrar: ({ estudiante: { segundo_nombre } }) =>
                `${segundo_nombre ? segundo_nombre : ""}`,
              enLista: false,
            },
            {
              id: "PRIMER APELLIDO",
              mostrar: ({ estudiante: { primer_apellido } }) =>
                `${primer_apellido ? primer_apellido : ""}`,
              enLista: false,
            },
            {
              id: "SEGUNDO APELLIDO",
              mostrar: ({ estudiante: { segundo_apellido } }) =>
                `${segundo_apellido ? segundo_apellido : ""}`,
              enLista: false,
            },
            {
              id: "IDENTIFICACIÓN",
              mostrar: ({ estudiante: { identificacion } }) => identificacion,
            },
            {
              id: "CARRERA",
              mostrar: ({ carrera: { nombre } }) => nombre,
              enLista: false,
            },
            {
              id: "FECHA REGISTRO",
              mostrar: ({ fecha_registro }) =>
                moment(fecha_registro).format("YYYY-MM-DD, h:mm a"),
            },
            {
              id: "ESTADO ACTUAL",
              mostrar: ({ estado_actual, gestor_nombre }) =>
                `${estado_actual.nombre} ${
                  gestor_nombre ? `${` - ${gestor_nombre}`}` : ""
                }`,
            },
          ]}
        />
      </>
    );
  };

  return (
    <div>
      <MenuInscripciones
        seleccionar={(accion) => setAccion(accion)}
        selec={accion}
        administrador={administrador}
      />
      <div className="contenido_modulo">
        <InscripcionPosgradoFiltro
          accionProceso={accionProceso}
          filtrar={(filtros) => {
            actualizarDataProcesos(
              configurarFiltro(accionProceso).concat(filtros)
            );
          }}
          limpiar={() => {
            actualizarDataProcesos(configurarFiltro(accionProceso));
          }}
          mostrarTodo={(accion) => setAccionProceso(accion)}
        />
        <ProcesosInscripcionesPosgrado
          inscripcion={procesoInscripciones ? procesoInscripciones.id : 0}
          procesoSeleccionDetalle="detalle"
        />
        {!cargando ? (
          <>{vistaPrincipal()}</>
        ) : (
          <TareasTerminadas
            mensaje="Cargando..."
            marginTop="7%"
            widthImg="7%"
            imagen={emma_w}
            cargando={true}
          />
        )}
      </div>
    </div>
  );
};

export default InscripcionesPosgrados;
