import {
  MODAL_ADD_VALORES,
  MODAL_MOD_VALORES,
  MODAL_DEL_VALORES,
  MODAL_DET_VALORES,
  ACT_DATA_VALOR,
  ACT_DATA_VALORES,
  ACT_DATA_PERMISOS,
  ACT_RENDER_VALORES,
  MODAL_ADD_PERMISO_VALORES,
  MODAL_DET_VALORES_PRACTICA
} from "../actions/actValores";

const initialState = {
  modalAddValores: false,
  modalAddPermisosValores: false,
  modalModValores: false,
  modalDelValores: false,
  modalDetValores: false,
  modalDetValoresPractica: false,
  valor: {
    id: 0,
    codigo: "",
    nombre: "",
    descripcion: "Ninguna",
  },
  valores: [],
  permisos: [],
  renderValores: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_VALORES:
      return Object.assign({}, state, {
        modalAddValores: action.show
      })
    case MODAL_MOD_VALORES:
      return Object.assign({}, state, {
        modalModValores: action.show
      })
    case MODAL_DEL_VALORES:
      return Object.assign({}, state, {
        modalDelValores: action.show
      })
    case ACT_DATA_VALOR:
      return Object.assign({}, state, {
        valor: action.valor
      })
    case ACT_DATA_VALORES:
      return Object.assign({}, state, {
        valores: action.valores
      })
    case ACT_RENDER_VALORES:
      return Object.assign({}, state, {
        render: action.render
      })
    case MODAL_ADD_PERMISO_VALORES:
      return Object.assign({}, state, {
        modalAddPermisosValores: action.show
      })
    
    case ACT_DATA_PERMISOS:
      return Object.assign({}, state, {
        permisos: action.permisos
      })
    case MODAL_DET_VALORES:
      return Object.assign({}, state, {
        modalDetValores: action.show
      })
    case MODAL_DET_VALORES_PRACTICA:
      return Object.assign({}, state, {
        modalDetValoresPractica: action.show
      })
    default:
      return state;
  }
}

export default reducer;
