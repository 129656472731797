import React, { Component } from 'react';

import { connect } from 'react-redux';
import { mostrarModulo, actualizarMensaje } from '../../redux/actions/actGlobal';

import { Button } from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';


import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import { BtnGuardar } from '../general/BotonesAccion';
import {
	obtenerValores,
	obtenerPermisosValor,
	formulario,
	crear_form_data,
	consulta
} from '../../global/js/funciones';

import Respuestas from './Respuestas';


class Encuesta extends Component {
	constructor(props) {
		super(props);
		this.state = {
			iniciar:false,
			preguntas:[],
			listaRespuesta:[],
			open:false,
			message:"Cargando..",
			token: null,
			seguir: false,
			cargando: false, 
			terminado: false
		};
		this.cambiarEstado = this.cambiarEstado.bind(this);
	}

	async componentDidMount() {
		const { mostrarModulo} = this.props;
		const { tokenEcuesta } = this.props.match.params;
		mostrarModulo({ ruta: '/encuesta', nombre: 'encuesta' });
		// valor de la generica 65
		this.setState({cargando: true});
		let preguntas = await obtenerValores(65);
		let aux;
		for (let index = 0; index < preguntas.length; index++) {
			aux = await obtenerPermisosValor(preguntas[index].id);
			preguntas[index].respuestas = aux.map(element => element.secundario);
		}
		let listaRespuesta = this.listaRespuesta(preguntas);
		this.cambiarEstado({preguntas,listaRespuesta})
		
		let token = await this.validarToken(tokenEcuesta);
		if( token.successfull == true){
			this.cambiarEstado({
				token,
				seguir: true,
				message:"¡Click sobre el botón iniciar, por favor!"
			});
		}else{
			this.cambiarEstado({message:"¡El token ya no es valido o ya ha respondido la encuesta con anterioridad!"});
		}
		this.setState({cargando: false});
	}

	async componentDidUpdate(prevProps, prevState) {

	}

	validarToken = (token) =>{
		return new Promise((resolve) => {
			consulta(`api/v1.0/matriculas/config/encuesta/permiso/${token}`, null, null, (error, estado, resp) => {
				resolve(resp);
			});
		});
	}

	cambiarEstado(state) {
		this.setState(state);
	}

	listaRespuesta=(preguntas)=>{
		return preguntas.map( pregunta => {
			let obligatorio = pregunta.valora
			return {
				obligatorio: (
					obligatorio != null 
					&& obligatorio.toLowerCase() == "obligatoria")
					? true
					: false,
				respuesta: null,
				idPregunta: pregunta.id,
				tipo: pregunta.valorb,
			}
		});
	}

	responder = (index,respuesta)=>{
		let { listaRespuesta } = this.state;
		listaRespuesta[index].respuesta = respuesta;
		this.cambiarEstado({ listaRespuesta });
	}

	hayObligatorio=()=>{
		const { listaRespuesta } = this.state;
		for (const index in listaRespuesta) {
			if (listaRespuesta[index].obligatorio == true && listaRespuesta[index].respuesta == null) return true; 
		}
		return false;
	}

	arrayToString = () =>{
		let { listaRespuesta } = this.state;
		listaRespuesta = listaRespuesta.map( item =>{
			if( Array.isArray(item.respuesta) ){
				item.respuesta = item.respuesta.toString();
			}
			return item;
		})
		return JSON.stringify(listaRespuesta);
	}

	enviarRespuesta= async ()=>{
		if(this.hayObligatorio()){
			let message = "Hay preguntas obligatorias sin respuesta.";
			this.cambiarEstado({message})
			actualizarMensaje({ titulo: message, tipo: 'info', mostrar: true, tiempo: 6000 });
			this.handleClick();
		}else{
			const { tokenEcuesta } = this.props.match.params;
			let data = {
				token: JSON.stringify(this.state.token.token),
				respuestas: this.arrayToString(),
				tokenEcuesta
			}
			data = await crear_form_data( data );
			formulario(
				`api/v1.0/matriculas/config/encuesta/${this.state.token.token.encuesta}`, 
				data, 
				"POST", 
				(err,estado,data)=>{
					if(!err){
						let {actualizarMensaje} = this.props;
						let message = "Se ha almacenado la encuenta exitosamente";
						actualizarMensaje({ titulo: message, tipo: 'success', mostrar: true, tiempo: 6000 });
						this.setState({terminado: true})
					}
				}
			);
		}
	}

	handleClick = () => {
		this.cambiarEstado({open:true});
	};

	handleClose = () => {
		this.cambiarEstado({open:false});
	};

	render() {
		const { 
			iniciar,
			preguntas,
			open,
			message,
			seguir,
			terminado
		} = this.state;	

		return (
			<div>
				{
					!terminado ?  (
						!iniciar ? (
							<div>
								<TareasTerminadas 
									mensaje={message}
									marginTop='7%' 
									imagen={emma_w} 
									widthImg='7%'
								/>
								<div style={{
									display:"flex",
									width:"100%",
									justifyContent: "center",

								}}>
									{
										seguir  && (
											<Button 
												variant="contained"
												color="primary"
												onClick={()=>this.cambiarEstado({iniciar:!iniciar})}
											>
												Iniciar
											</Button>
										)
									}	
								</div>
							</div>
						):(
							<div>
								{
									preguntas.map( (pregunta, index) =>{
										return <Respuestas
											key={index}
											index={index}
											pregunta={pregunta.nombre}
											tipo={pregunta.valorb}
											respuestas={pregunta.respuestas}
											responder={this.responder}
											listaRespuesta = {this.state.listaRespuesta}
											cargando={this.state.cargando}
											guardado={this.state.guardado}
											
										/>
									})
								}
								<div style={{
									width:'70%',
									height: '50px',
									marginLeft: '24px'
								}}>
									<BtnGuardar
										callback={this.enviarRespuesta}
										texto="Guardar"
									/>
								</div>
							</div>
							
						)

					): (
						<TareasTerminadas mensaje='Esta encuenta ha finalizado con exito' marginTop='7%' imagen={emma_s1} widthImg='7%' />
					)
				}
				<div >
					<Snackbar style={{ width:"100%" }} 
					message={message} 
					open={open} 
					autoHideDuration={6000} 
					severity="success" 
					onClose={this.handleClose}>
						
					</Snackbar>
				</div>
    		</div>
		);
	}
}


const mapStateToProps = (state) => {
	let { usuario } = state.redGlobal;
	return {
		usuario,
	};
};

const mapDispatchToProps = {
	mostrarModulo,
	actualizarMensaje
};

export default connect(mapStateToProps, mapDispatchToProps)(Encuesta);
