import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { VisitaMenu } from "./PromocionMenu";
import { BtnForm } from '../../general/BotonesAccion';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { List, ListSubheader, ListItem, ListItemIcon, Checkbox, ListItemText } from '@material-ui/core';
import { obtenerEstadosProceso } from "../../../global/js/funciones";
import { makeStyles } from '@material-ui/core/styles';
import { Id_Estado_visita } from '../helper';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estado, estados_siguiente, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List
      className={classes.root}
      subheader={
        <ListSubheader component="div" >
          Seleccione Estado
        </ListSubheader>
      }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre, valore } = secundario;
        return (
          <ListItem
            key={codigo}
            role={undefined}
            dense
            button
            onClick={() => cambiarSeleccion({ codigo, valore })}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={estado === codigo}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  )
}
class VisitaGestionar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      seleccion: '',
      estado: { codigo: "", valore: "" },
      estados_siguiente: [],
      titulo_res: "Cargando..",
    }
  }


  async componentDidUpdate({ modalGestVisita }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { visita, modalGestVisita: modalDetVisitaNew, vistaDetalle } = this.props;
    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'gestionar') {
        this.reiniciarEstado();
        let estados_siguiente = await obtenerEstadosProceso(visita.estado_actual.id, [
          { llave: "principal", valor: visita.estado_actual.id },
          { llave: "secundario__generica", valor: Id_Estado_visita },
        ]);
        this.setState({
          estados_siguiente, cargando: false,
        });
      }
    }
    if (modalGestVisita !== modalDetVisitaNew) this.setState({ seleccion: modalDetVisitaNew ? vistaDetalle : '' })
  }

  onSubmit = (e) => {
    let { estado } = this.state
    let { gestionarVisita, visita } = this.props;
    this.setState({ cargando: true, titulo_res: "Enviando.." });
    let data = { estado: estado.codigo };
    gestionarVisita(visita.id, data, () =>
      this.setState({ cargando: false, titulo_res: 'Cargando..' })
    )
    e.preventDefault();
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      estado: { codigo: "", valore: "" },
      estados_siguiente: [],
      titulo_res: "Cargando..",
    })
  }

  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'gestionar' && this.pintarEstados()}
        {seleccion === 'estados' && this.pintarEstadosHistorial()}
      </div>
    )
  }

  pintarEstados() {
    let { estado, estados_siguiente } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado.codigo} estados_siguiente={estados_siguiente} />
        </Grid>
      </Grid>)
  }

  render() {
    let { seleccion } = this.state;
    let { modalGestVisita, mostrarModalGestionarVisita } = this.props;
    return (
      <>
        <Dialog open={modalGestVisita} maxWidth='sm' fullWidth={true} onClose={() => mostrarModalGestionarVisita(false)}>
          <AppBarModal titulo='¡ Gestionar Visita !' mostrarModal={mostrarModalGestionarVisita} titulo_accion='' />
          <VisitaMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
          <DialogContent style={{ padding: '1' }} className='scroll' >
            {this.pintar()}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalGestionarVisita(false)} />
            <BtnForm texto="ACEPTAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>
      </>)
  }
}

VisitaGestionar.propTypes = {
  modalGestVisita: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
}

export default VisitaGestionar;