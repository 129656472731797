import {
    MODAL_ADD_REA,
    MODAL_DET_REA,
    MODAL_FIL_REA,
    MODAL_FIL_DES_REA,
    MODAL_DET_GRUPO,
    MODAL_REP_REA

  } from "../actions/actRea"
  
  const initialState = {
    modalAddRea: false,
    modalDetalleRea: false,
    modalFiltro: false,
    modalFiltroDescarga: false,
    modalDetalleGrupo: false,
    modalRepInfoRea: false,
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case MODAL_ADD_REA:
        return Object.assign({}, state, {
          modalAddRea: action.show
        })
      case MODAL_DET_REA:
        return Object.assign({}, state, {
          modalDetalleRea: action.show
        })
      case MODAL_FIL_REA:
        return Object.assign({}, state, {
           modalFiltro: action.show
      })
      case MODAL_FIL_DES_REA:
        return Object.assign({}, state, {
           modalFiltroDescarga: action.show
      })
      case MODAL_DET_GRUPO:
        return Object.assign({}, state, {
          modalDetalleGrupo: action.show
        })
      case MODAL_REP_REA:
        return Object.assign({}, state, {
          modalRepInfoRea: action.show
        })
      default:
        return state;
    }
  }
  
  export default reducer;
  