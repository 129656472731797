import {
  MODAL_AGREGAR,MODAL_AGREGAR_PREG, MODAL_EDITAR_SYLL,
} from "../actions/actSisef";

const initialState = {
  modalAgregarSisef: false,
  modalModificarSisef :false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_AGREGAR:
      return Object.assign({}, state, {
        modalAgregarSisef: action.show
      })
      case MODAL_EDITAR_SYLL:
      return Object.assign({}, state, {
        modalModificarSisef: action.show
      })
      case MODAL_AGREGAR_PREG:
      return Object.assign({}, state, {
        ModalAdd: action.show
      })
    default:
      return state;
  }
}

export default reducer;