import React, { Component } from 'react';
import { obtenerMisDatos } from '../../../global/js/funciones';
import emma_w from '../../../global/imagenes/emma_w.png';
import { Dialog, DialogContent, DialogActions, TextField, FormControl, MenuItem, InputLabel, Checkbox, Select, StepLabel, Step, Stepper, Grid, Paper, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal';
import TareasTerminadas from '../../general/TareasTerminadas'
import ListarDatos from '../../general/ListarDatos'
import moment from 'moment'
import { BtnDetalle } from '../../general/BotonesAccion'



const steps = ['Datos iniciales', 'Vacantes'];

class ModalAgregarProceso extends Component {

	constructor() {
		super()
		this.state = {
			cargando: true,
			activeStep: 0,
			celular: '',
			telefono: '',
			correo: '',
			checked: [],
			grupo: null,
			cargar: true
		}
	}

	async componentDidUpdate({ openModalNuevo }) {
		let { cargar } = this.state;
		let { openModalNuevo: openModalNuevoNew } = this.props;

		if (openModalNuevo !== openModalNuevoNew && openModalNuevoNew) {
			if (cargar) {
				this.setState({ cargando: true });
				let datos = await obtenerMisDatos();
				let { persona } = datos;
				this.setState({ cargando: false, cargar: false, telefono: persona.telefono, celular: persona.telefono, correo: persona.correo_personal});
			}
		}
	}


    configStepContent = () => {
		let { activeStep } = this.state;

		switch (activeStep) {
			case 0:
				return this.pintarDatosIniciales();
			case 1:
				return this.pintarGrupos();
			default:
				break;
		}
    }
    
    pintarDatosIniciales = () => {
		let { cargando, telefono, celular, correo } = this.state;
		if (!cargando) {
			return (
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField type='number' id='telefono' name='telefono' label='Telefono' value={telefono} onChange={this.onChange} required fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField type='number' id='celular' name='celular' label='Celular' value={celular} onChange={this.onChange} required fullWidth />
					</Grid>
					<Grid item xs={12}>
						<TextField type='email' id='correo' name='correo' label='Correo Personal' value={correo || ''} onChange={this.onChange} required fullWidth />
					</Grid>
				</Grid>
			)
		} else return <TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg='7%' />
    }
    
    handleToggle = (data) => {
		let { checked } = this.state;
		const currentIndex = checked.indexOf(data.id);

		if (currentIndex === -1) {
			this.setState({ checked: [data.id], grupo: data });
		} else {
			this.setState({ checked: [], grupo: null });
		}
    }
    
    pintarGrupos = () => {
        let { checked } = this.state;
        let { vacantes, detalle_vacante, misIntereses} = this.props
		const acciones = (data) => {
			/* let auxBtn =  misIntereses.find(( interes ) => {
				let encontrado = data.area_interes.find( (interesOferta) => interesOferta.area_interes.id == interes.area_interes.id) ;
				return encontrado ? true : false;
			}); */
			return (
				<>
					<BtnDetalle 
						callback={() => {
							detalle_vacante(data)
						}}
					/>
					{/* {
						auxBtn &&
							<Checkbox edge="end" onChange={() => this.handleToggle(data)} checked={checked.indexOf(data.id) !== -1} />
					} */}
					<Checkbox edge="end" onChange={() => this.handleToggle(data)} checked={checked.indexOf(data.id) !== -1} />
				</>
			)
        };
        
        const obtenerColor = (codigo) => {
            let color = '#ffffff';
            const colores = {
              'Por_Sol': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
              'Por_Rev': { 'backgroundColor': '#ffc107', color },
              'Por_Ace': { 'backgroundColor': '#0d47a1', color }
            }
            return (colores[codigo])
        }

		return (
			<Grid container spacing={3} className='scroll'>
				<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
					<ListarDatos
						id='tbl_vacantes'
						titulo='Lista de Vacantes'
						datos={vacantes}
						filtrar={false}
            			refrescar={false}
                		// actfiltrar={() => console.log('ola')}
						avatar={() => null}
                        fila_principal={({ nombre_cargo }) => nombre_cargo}
                        color_avatar={({ estado_solicitud }) => obtenerColor(estado_solicitud.codigo)}
						filas={[
							{
                            'mostrar': ({ salario }) => `Salario $${new Intl.NumberFormat().format(salario)}`,
                            'id': 'salario',
                            'enLista': true
                            },
                            {
                            'mostrar': ({ n_vacantes }) => `Número de vacantes ${n_vacantes}`,
                            'id': 'n_vacantes',
                            'enLista': true
                            },
                            {
                            'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD'),
                            'id': 'fecha_inicio',
                            'enLista': true         
                            }
						]}
						acciones={(data) => acciones(data)}
						opciones={true}
						buscar={true}
						css={{}}
					/>
				</Grid>
			</Grid>
		)
    }
    
    handleStep = direction => {
		const { actualizarMensaje } = this.props;
		let { activeStep, telefono, celular } = this.state;

		switch (activeStep) {
			case 0:
				if (telefono && celular ) this.setState({ activeStep: activeStep + 1 });
				else actualizarMensaje({ titulo: 'Debes diligenciar todos los campos para poder continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
                break;
            case 1:
                if (!direction) {
                    this.setState({ activeStep: activeStep - 1 });
                }
                break;
			default:
				break;
		}
    }
    
    onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		});
    }
    
    handleSubmit = () => {
        let { grupo, celular, telefono, correo } = this.state
        let { crearPostulantes, closeModalNuevo } = this.props
				let data = {celular, telefono, correo, vacante: grupo.id}
        crearPostulantes(data, closeModalNuevo)
    }

    render() {
		let { openModalNuevo, closeModalNuevo } = this.props;
		let { activeStep } = this.state;

		return (
			<Dialog open={openModalNuevo} onClose={closeModalNuevo} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
				<AppBarModal titulo={'Nuevo Proceso'} mostrarModal={closeModalNuevo} titulo_accion="" accion="" />
				<DialogContent className='scroll'>
					<Grid container >
						<Grid item xs={12} md={12}>
							<Stepper activeStep={activeStep}>
								{steps.map(label => (
									<Step key={label}>
										<StepLabel><p>{label}</p></StepLabel>
									</Step>
								))}
							</Stepper>
						</Grid>
						<Grid item xs={12} md={12}>
							{this.configStepContent()}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{
						activeStep === 0 ?
							<BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} /> :
							activeStep === 1 ?
								<div>
									<BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
									<BtnForm texto="GUARDAR" callback={this.handleSubmit} type="submit" />
								</div> :
								<div>
									<BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
									<BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} />
								</div>
					}
				</DialogActions>
			</Dialog>
		)
	}
}

export default ModalAgregarProceso;