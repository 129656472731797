const MODAL_ADD_VALORES = 'MODAL_ADD_VALORES'
const MODAL_ADD_PERMISO_VALORES = 'MODAL_ADD_PERMISO_VALORES'
const MODAL_MOD_VALORES = 'MODAL_MOD_VALORES'
const MODAL_DEL_VALORES = 'MODAL_DEL_VALORES'
const MODAL_DET_VALORES = 'MODAL_DET_VALORES'
const MODAL_DET_VALORES_PRACTICA = 'MODAL_DET_VALORES_PRACTICA'
const ACT_DATA_VALOR = 'ACT_DATA_VALOR'
const ACT_DATA_VALORES = 'ACT_DATA_VALORES'
const ACT_DATA_PERMISOS = 'ACT_DATA_PERMISOS'
const ACT_RENDER_VALORES = 'ACT_RENDER_VALORES'

const mostrarModalAddValores = show => {
  return {
    type: MODAL_ADD_VALORES,
    show,
  }
}

const mostrarModalAddPermisoValores = show => {
  return {
    type: MODAL_ADD_PERMISO_VALORES,
    show,
  }
}


const mostrarModalModValores = show => {
  return {
    type: MODAL_MOD_VALORES,
    show,
  }
}

const mostrarModalDelValores = show => {
  return {
    type: MODAL_DEL_VALORES,
    show,
  }
}

const mostrarModalDetValores = show => {
  return {
    type: MODAL_DET_VALORES,
    show,
  }
}
const mostrarModalDetValoresPractica = show => {
  return {
    type: MODAL_DET_VALORES_PRACTICA,
    show,
  }
}

const actualizarDataValor = valor => {
  return {
    type: ACT_DATA_VALOR,
    valor,
  }
}

const actualizarDataPermisos = permisos => {
  return {
    type: ACT_DATA_PERMISOS,
    permisos,
  }
}

const actualizarDataValores = valores => {
  return {
    type: ACT_DATA_VALORES,
    valores,
  }
}

const actualizarRenderValores = render => {
  return {
    type: ACT_RENDER_VALORES,
    render,
  }
}

module.exports = {
  mostrarModalAddValores,
  mostrarModalModValores,
  mostrarModalDelValores,
  mostrarModalDetValores,
  mostrarModalDetValoresPractica,
  actualizarDataValores,
  actualizarDataValor,
  actualizarRenderValores,
  mostrarModalAddPermisoValores,
  actualizarDataPermisos,
  MODAL_ADD_VALORES,
  MODAL_MOD_VALORES,
  MODAL_DEL_VALORES,
  MODAL_DET_VALORES,
  MODAL_DET_VALORES_PRACTICA,
  ACT_DATA_VALORES,
  ACT_DATA_PERMISOS,
  ACT_DATA_VALOR,
  ACT_RENDER_VALORES,
  MODAL_ADD_PERMISO_VALORES,
}
