import React, { Component, useState } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm} from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import { consulta, generarFiltros } from '../../../global/js/funciones'
import { TutoriasMenu } from "../tutoria/TutoriaMenu"
import { DetalleSolicitud } from "./Tutoria"
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment'
import PropTypes from 'prop-types'




class TutoriaDetalle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estados: [],
      adjuntos: [],
      previos : [],
      datos: [],
      cargando: false,
      seleccion: '',
      adjunto : [],
      nota : ''
    };
  }
    
    
  async componentDidUpdate({ modalDetTutoria }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    let { tutoria, modalDetTutoria: modalDetTutoNew, vistaDetalle } = this.props;
    if (seleccionNew !== seleccion) {
      if (seleccionNew === 'detalle') this.ObtenerTutoria(tutoria.id);
      else if (seleccionNew === 'estados') this.obtenerEstadosTutoria(tutoria.id);
    }
    if (modalDetTutoria !== modalDetTutoNew) this.setState({ seleccion: modalDetTutoNew ? vistaDetalle : '' })
  }

  async ObtenerTutoria(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
    consulta(`api/v1.0/tutorias?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
    })
  }


  async obtenerEstadosTutoria(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'tutoria', 'valor': id }]);
    consulta(`api/v1.0/tutoria/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, estados: resp });
    })
  }



  

  pintarEstados = () => {
    let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_tut_env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Est_tut_rev': { 'backgroundColor': '#ffc107', color },
        'Est_tut_apr': { 'backgroundColor': '#01519B', color },
        'Est_tut_apl': { 'backgroundColor': '#009688', color },
        'Est_tut_neg': { 'backgroundColor': '#b71c1c', color },
        'Est_tut_can': { 'backgroundColor': '#b71c1c', color }
      }
      return (colores[codigo])
    }

    return (
      <ListarDatos
        id="tbl_estados_tutoria"
        datos={estados}
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        color_avatar={({ tipo_estado: { codigo } }) => obtenerColor(codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre.toUpperCase()}
        filas={[
          { 'id': 'usurio_registro', 'enLista': true, 'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.segundo_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`.toUpperCase() },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
          { 'id': 'mensaje', 'enLista': true, 'mostrar': ({ mensaje }) => mensaje },
        ]}
      />
    )
  };
    
  


  pintarTutoria = () => {
    let { datos } = this.state;
    let { admin } = this.props;
    if (datos[0]) {
      return <DetalleSolicitud datos={datos[0]} gestor={admin}/>;
    }
  }
    
  pintar = () => {
    let { cargando, seleccion } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {seleccion === 'detalle' && this.pintarTutoria()}
        {seleccion === 'estados' && this.pintarEstados()}
      </div>
    )

  }
    
  render() {
    let { seleccion } = this.state;
    let { modalDetTutoria, mostrarModalDetalleTutoria } = this.props
  

    return (
    <>
      <Dialog open={modalDetTutoria} maxWidth="sm" fullWidth={true} onClose={() => mostrarModalDetalleTutoria(false)}>
        <AppBarModal titulo='¡ Detalle Tutoria !' mostrarModal={mostrarModalDetalleTutoria} titulo_accion='' />
        <TutoriasMenu sele={seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleTutoria(false)} />
        </DialogActions>
      </Dialog>
      
    </>
    );
  }
}
TutoriaDetalle.propTypes={
  modalDetTutoria:PropTypes.bool.isRequired,
}


export default TutoriaDetalle