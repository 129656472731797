// VALIDACIONES ESTUDIANTES
const MODAL_ADD_VALIDACIONES = 'MODAL_ADD_VALIDACIONES'
const MODAL_DET_VALIDACIONES = 'MODAL_DET_VALIDACIONES'
const MODAL_ADJ_VALIDACIONES = 'MODAL_ADJ_VALIDACIONES'
// VALIDACIONES GESTION
const MODAL_DET_GES_VALIDACION = 'MODAL_DET_GES_VALIDACION'
const MODAL_GES_VALIDACION = 'MODAL_GES_VALIDACION'
const MODAL_ADD_ADJVALIDACION = 'MODAL_ADD_ADJVALIDACION'
const MODAL_ADJ_VOLANTE = 'MODAL_ADJ_VOLANTE'
const MODAL_FILTRO_VALIDACION = 'MODAL_FILTRO_VALIDACION'
const MODAL_UPDATE_PERIODO = 'MODAL_UPDATE_PERIODO'


// MODAL CREAR VALIDACIONES
const mostrarModalAddValidaciones = show => {
    return {
        type: MODAL_ADD_VALIDACIONES,
        show,
    }
}

// MODAL DETALLES VALIDACIONES
const mostrarModalDetalleValidaciones = show => {
  return {
    type: MODAL_DET_VALIDACIONES,
    show,
  }
}
// MODAL DETALLE ADJUNTO VALIDACIONES
const mostrarModalAdjuntos = show => {
  return {
    type: MODAL_ADJ_VALIDACIONES,
    show,
  }
}

// MODAL GESTIONAR VALIDACIONES
const mostrarModalGestionarValidacion = show => {
  return {
    type: MODAL_DET_GES_VALIDACION,
    show,
  }
}

// MODAL GESTION VALIDACIONES

const mostrarGestionValidacion = show => {
  return {
    type: MODAL_GES_VALIDACION,
    show,
  }
}

const mostrarModalAddAdjValidacion = show => {
  return {
    type: MODAL_ADD_ADJVALIDACION,
    show,
  }
}

const mostrarAdjuntarVolante = show => {
  return {
    type: MODAL_ADJ_VOLANTE,
    show
  }
}

//  MODAL FILTRO VALIDACIONES

const mostrarModalFiltroValidacion = show => {
  return {
    type: MODAL_FILTRO_VALIDACION,
    show,
  }
}

// MODAL MODIFICAR PERIODO

const mostrarModalModificarPeriodo = show => {
  return {
    type: MODAL_UPDATE_PERIODO,
    show
  }
}


module.exports = {
    MODAL_ADD_VALIDACIONES,
    MODAL_DET_VALIDACIONES,
    MODAL_DET_GES_VALIDACION,
    MODAL_GES_VALIDACION,
    MODAL_ADJ_VALIDACIONES,
    MODAL_ADD_ADJVALIDACION,
    MODAL_ADJ_VOLANTE,
    MODAL_FILTRO_VALIDACION,
    MODAL_UPDATE_PERIODO,
    mostrarModalAddValidaciones,
    mostrarModalDetalleValidaciones,
    mostrarModalGestionarValidacion,
    mostrarGestionValidacion,
    mostrarModalAdjuntos,
    mostrarModalAddAdjValidacion,
    mostrarAdjuntarVolante,
    mostrarModalFiltroValidacion,
    mostrarModalModificarPeriodo,
}
