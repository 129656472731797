import React from 'react'
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "../../personas/generica/Generica";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";

class PracticasProgramas extends React.Component {

  render() {
    let { coordinador, mostrarModalProParticipante, modalProParticipante } = this.props;
    if (coordinador && coordinador.id > 0) {
      return (
        <Dialog open={modalProParticipante} fullWidth={true} maxWidth='sm' onClose={mostrarModalProParticipante.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Programas Asignados !' mostrarModal={mostrarModalProParticipante} accion={() => mostrarModalProParticipante(false)} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica tipo={4} persona={coordinador} genericaRelacionId={5} titulo="¡ Nuevo Programa !" tituloInput="Programas" tituloEliminar='¿ Eliminar Programa ?' />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalProParticipante(false)} />
          </DialogActions>
        </Dialog>
      );
    }
    return '';

  }

}
//Props del componente
PracticasProgramas.propTypes = {
  //variables
  coordinador: PropTypes.object.isRequired,
  modalProParticipante: PropTypes.bool.isRequired,
  //funciones
  mostrarModalProParticipante: PropTypes.func.isRequired,

}


export default PracticasProgramas;


