
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
//FUNCIONES GENERALES
import { consulta, mostrarError, obtenerValores } from "../../global/js/funciones";
//COMPONENTES DEL MODULO
import PersonasAgregar from './PersonasAgregar'
import PersonasListar from './PersonasListar'
import ConfirmarAccion from '../general/ConfirmarAccion';
import PersonasModificar from './PersonasModificar'
import PersonasPassword from './PersonasPassword'
import Persona from "./Persona";
//ACCIONES DEL MODULO
import { mostrarModalDetallePersona, mostrarModalAddPersona, mostrarModalModPersona, mostrarModalDelPersona, actualizarDataPersona, actualizarDataPersonas, actualizarRenderPersona, mostrarModalCambiarPassword } from "../../redux/actions/actPersonas";
import { mostrarModalAdd } from "../../redux/actions/actGenericas"
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";


class Personas extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tipos_roles: [],
      tipos_identificacion: [],
      cargando: false,
      id: null,
      administrador: false
    }
  }


  componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/personas', nombre: 'personas' })
    this.cargarTipos();
    this.esAdministrador();
  }


  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" )) {
      this.setState({ administrador: true })
    }
  }

  obtenerPersonas = (dato) => {
    let { actualizarDataPersonas, actualizarRenderPersona } = this.props;
    let tipo = 1
    this.setState({ cargando: true })
    if (dato.length > 4) {
      consulta("api/v1.0/personas/buscar", { dato, tipo }, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            actualizarDataPersonas(resp);
            actualizarRenderPersona(true);
            this.setState({ cargando: false })
          }
        }
      );
    } else {
      actualizarDataPersonas([]);
      actualizarRenderPersona(true);
      this.setState({ cargando: false });
    }
  }

  guardarPersona = (data, callback) => {
    let { actualizarMensaje, mostrarModalAddPersona } = this.props;
    consulta("api/v1.0/personas/crear", data, "post",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalAddPersona(false);
            this.obtenerPersonas(data.identificacion);
          } else {
            tipo = 'info';
            titulo = mostrarError(resp)
          }
        }
        callback()
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  modificarPersona = (id, data) => {
    let { actualizarMensaje, mostrarModalModPersona } = this.props;
    consulta(`api/v1.0/personas/${id}`, data, "patch",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalModPersona(false);
            this.buscarPersonaAccion();
          } else {
            tipo = 'info';
            titulo = mostrarError(resp)
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }


  eliminarPersona = id => {
    let { actualizarMensaje, mostrarModalDelPersona } = this.props;
    consulta(`api/v1.0/personas/${id}/eliminar`, {}, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalDelPersona(false);
            this.buscarPersonaAccion();
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  actualizarEstadoVacunacion = id => {
    let { actualizarMensaje, mostrarModalDelPersona } = this.props;
    consulta(`api/v1.0/personas/${id}`, {reporte_vacuna : 1}, "patch",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalDelPersona(false);
            this.buscarPersonaAccion();
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  id_persona = id => { this.setState({id}) }

  cambiarPassword = (pass) => {
    let id = this.state.id
    let { mostrarModalCambiarPassword, actualizarMensaje } = this.props
    consulta(`api/v1.0/personas/${id}/cambiar_pass`, { pass }, "put",
      (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalCambiarPassword(false);
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  }

  buscarPersonaAccion = () => {
    let { persona } = this.props;
    let dato = `${persona.identificacion}`;
    this.obtenerPersonas(dato);
  }

  cargarTipos = async () => {
    this.setState({ cargando: true })
    let { actualizarDataPersonas, actualizarRenderPersona } = this.props
    const tipos_roles = await obtenerValores(1);
    const tipos_identificacion = await obtenerValores(2);
    actualizarDataPersonas([]);
    actualizarRenderPersona(true);
    this.setState({
      tipos_roles,
      tipos_identificacion,
      cargando: false
    })
  }


  render() {
    let { mostrarModalDetallePersona, modalDetallePersona, modalAddPersona, modalModPersona, modalDelPersona, mostrarModalAddPersona, mostrarModalModPersona, mostrarModalDelPersona, actualizarDataPersona, personas, persona, mostrarModalAdd, modalEditPass, mostrarModalCambiarPassword, actualizarMensaje } = this.props;
    let { tipos_roles, tipos_identificacion, cargando,administrador} = this.state;
    return (
      <div>
        <Persona mostrarModalDetallePersona={mostrarModalDetallePersona} modalDetallePersona={modalDetallePersona} persona={persona} mostrarModalAdd={mostrarModalAdd} />
        <PersonasAgregar tipos_roles={tipos_roles} tipos_identificacion={tipos_identificacion} mostrarModalAddPersona={mostrarModalAddPersona} modalAddPersona={modalAddPersona} guardarPersona={this.guardarPersona} obtenerValores={obtenerValores} />
        <PersonasModificar tipos_roles={tipos_roles} tipos_identificacion={tipos_identificacion} mostrarModalModPersona={mostrarModalModPersona} modalModPersona={modalModPersona} modificarPersona={this.modificarPersona} obtenerValores={obtenerValores} persona={persona} />
        <ConfirmarAccion mensaje1={`La persona "${persona.primer_nombre} ${persona.primer_apellido}" será actualizada, `} ejecutarAccion={this.actualizarEstadoVacunacion} mostrarModalConfAccion={mostrarModalDelPersona} modalConAccion={modalDelPersona} dato={persona} titulo={'Actualizar de estado de vacunación.'} />
        <PersonasListar cargando={cargando} mostrarModalDetallePersona={mostrarModalDetallePersona} mostrarModalAddPersona={mostrarModalAddPersona} mostrarModalCambiarPassword={mostrarModalCambiarPassword} mostrarModalDelPersona={mostrarModalDelPersona} mostrarModalModPersona={mostrarModalModPersona} actualizarDataPersona={actualizarDataPersona} personas={personas} obtenerPersonas={this.obtenerPersonas} id_persona={this.id_persona} administrador={administrador}/>
        <PersonasPassword 
          modalEditPass={modalEditPass}
          mostrarModalCambiarPassword={mostrarModalCambiarPassword}
          cambiarPassword={this.cambiarPassword}
          actualizarMensaje={actualizarMensaje}
        />
      </div>
    )
  }
}

//CARGO ESTADOS DEL MODULO

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalAddPersona, modalModPersona, modalDelPersona, modalEditPass, persona, personas, renderPersona, modalDetallePersona } = state.redPersonas;
  let { usuario } = state.redGlobal;
  return {
    modalAddPersona,
    modalModPersona,
    modalDelPersona,
    modalEditPass,
    persona,
    personas,
    renderPersona,
    modalDetallePersona,
    usuario
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModalAddPersona,
  mostrarModalModPersona,
  mostrarModalDelPersona,
  mostrarModalCambiarPassword,
  actualizarDataPersona,
  actualizarDataPersonas,
  actualizarRenderPersona,
  mostrarModulo,
  mostrarModalDetallePersona,
  mostrarModalAdd
}

//Props del componente
Personas.propTypes = {
  //variables
  renderPersona: PropTypes.bool.isRequired,
  personas: PropTypes.array.isRequired,
  modalModPersona: PropTypes.bool.isRequired,
  modalDetallePersona: PropTypes.bool.isRequired,
  modalAddPersona: PropTypes.bool.isRequired,
  modalDelPersona: PropTypes.bool.isRequired,
  modalEditPass: PropTypes.bool.isRequired,
  persona: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // tipo: PropTypes.number.isRequired,
    tipo_identificacion: PropTypes.object.isRequired,
    identificacion: PropTypes.number.isRequired,
    primer_nombre: PropTypes.string.isRequired,
    segundo_nombre: PropTypes.string,
    primer_apellido: PropTypes.string.isRequired,
    segundo_apellido: PropTypes.string,
    correo: PropTypes.string.isRequired,
    usuario: PropTypes.string.isRequired,
  }),
  //funciones
  actualizarMensaje: PropTypes.func.isRequired,
  actualizarRenderPersona: PropTypes.func.isRequired,
  actualizarDataPersonas: PropTypes.func.isRequired,
  actualizarDataPersona: PropTypes.func.isRequired,
  mostrarModalAddPersona: PropTypes.func.isRequired,
  mostrarModalModPersona: PropTypes.func.isRequired,
  mostrarModalDelPersona: PropTypes.func.isRequired,
  mostrarModalCambiarPassword: PropTypes.func.isRequired,
  mostrarModalDetallePersona: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalAdd: PropTypes.func.isRequired
}


export default connect(mapStateToProps, mapDispatchToProps)(Personas);

