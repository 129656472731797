import React from "react";
import {
    FormControlLabel,
    Radio,
    TextField,
} from "@material-ui/core";
import qr_enfoque_enquidad_genero from "../../../global/imagenes/qr_enfoque_equidad_genero.png";
import logo_retos from "../../../global/imagenes/logo_retos.png";
import logo_enfoque_equidad_genero from "../../../global/imagenes/logo_enfoque_equidad_genero.png";

const PintarPreguntas = ({ pregunta, respuestas, handleChange, respuestasSeleccionadas, isMobile }) => {
    switch (pregunta.tipo) {
        case "1":
            return (
                <TextField
                    required
                    label="Escriba su respuesta"
                    id={`pregunta-${pregunta.id}`}
                    onChange={(e) => handleChange(pregunta.id, e.target.value, pregunta.tipo)}
                />
            );
        case "2":
            return (
                <>
                    {respuestas?.map((item) => (
                        <FormControlLabel
                            value={item.id}
                            control={<Radio checked={respuestasSeleccionadas[pregunta.id]?.respuesta === item.id} name={`pregunta-${pregunta.id}`} onChange={() => handleChange(pregunta.id, item.id, pregunta.tipo)} />}
                            label={item.nombre}
                            key={item.id}
                        />
                    ))}
                </>
            );
        case "3":
            return (
                <>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                        {respuestas?.map((item) => (
                            <FormControlLabel
                                value={item.id}
                                control={<Radio checked={respuestasSeleccionadas[pregunta.id]?.respuesta === item.id} name={`pregunta-${pregunta.id}`} onChange={() => handleChange(pregunta.id, item.id, pregunta.tipo)} />}
                                label={item.nombre}
                                key={item.id}
                            />
                        ))}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "5px" }} >
                        <p>
                            Revisa aquí nuestro violentometro y ruta de atención de VBG: <a href="https://bienestar.cuc.edu.co/violento-metro-y-ruta/">https://bienestar.cuc.edu.co/violento-metro-y-ruta/</a>
                        </p>
                    </div>
                    {
                        isMobile ? (
                            <>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px"}} >
                                    <img src={logo_retos} alt="Logo retos" style={{ width: "50%", height: "6rem" }} />
                                    <img src={qr_enfoque_enquidad_genero} alt="Enfoque en equidad y género" style={{ width: "33%", height: "7rem" }} />
                                    <img src={logo_enfoque_equidad_genero} alt="Logo Enfoque en equidad y género" style={{ width: "80%", height:"5rem" }} />
                                </div>
                            </>
                        ) : (
                            <>
                                <div style={{ display: "flex", justifyContent: "center", marginBottom: "15px", gap: "20px" }} >
                                    <img src={logo_enfoque_equidad_genero} alt="Logo Enfoque en equidad y género" style={{ width: "45%", height: "6rem", marginTop: ".7rem" }} />
                                    <img src={qr_enfoque_enquidad_genero} alt="Enfoque en equidad y género" style={{ width: "15%", height: "7rem" }} />
                                    <img src={logo_retos} alt="Logo retos" style={{ width: "30%" }} />
                                </div>
                            </>
                        )
                    }
                </>
            );
        default:
            return <></>;
    }
};

export default PintarPreguntas;