export const PASOS = 297; // test 296 // local 3227 // pro 297
export const ENCUESTA_AUTOGESTION_ESTUDIANTIL_PREGUNTAS = 298; // test 297 // local // 3228 // pro 298
export const ENCUESTA_AUTOGESTION_ESTUDIANTIL_RESPUESTAS = 299; // test 298 // local 3229 // pro 299
export const OPCION_OTRO__ENCUESTA_AUTOGESTION_ESTUDIANTIL = 30529; // test 30139 // local 10714 // pro 30529 
export const OPCION_SI_ENCUESTA_AUTOGESTION_ESTUDIANTIL =30532; // local 10703 // pro 30532 
export const ID_PRE2 = 30508; // test 30118 // local 9704 // pro 30508 
export const ID_PRE1 = 30715  // test  // local 13723 // pro 30715   
export const OPCION_MINORIAS = 30721 // test  // local 13730 // pro 30721   
export const RESPUESTAS_MULTI_RADIO_BUTTONS = [
	{
	  id: "0",
	  nombre: "SI",
	  pre:'1',
	},
	{
	  id: "1",
	  nombre: "NO",
	  pre:'1',
	},
	{
	  id: "2",
	  nombre: "No iniciado",
	  pre:'2',
	},
	{
	  id: "3",
	  nombre: "En proceso",
	  pre:'2',
	},
	{
	  id: "4",
	  nombre: "Finalizado",
	  pre:'2',
    },
  ];

export const DERECHO = 43; // PRO 43 // local // 60  test 43
export const DERECHO_CONVENIO_MOVILIDAD = 21898; // PRO 21898 // local 11715 // test 21898
export const DERECHO_VIRTUAL = 21008; // PRO 21008 // local // 11716 test 21008
export const DERECHO_CONVALIDACIÓN = 21241; // PRO 21241 // local 11717 // test  21241
export const DERECHO_EXTENSIÓN_SABANALARGA = 2716; // PRO 2716 // local 11718 // test 2716
export const DERECHO_EXTENSIÓN_VILLAVICENCIO = 2715; // PRO 2715 // local 11719 // test 2715
export const DERECHO_EXTENSION_VILLAVICENCIO_CONVENIO_MOVILIDAD = 21896; // PRO 21896 // local 11720 // test 21896
export const PREGUNTA_DERECHO = 30524 // local 10719 // test 30134 // PRO 30524 
export const PREGUNTA_DOS_DERECHO = 30522 // local 10713 // test 30132 // PRO 30522 
  

