const MODAL_ADD_CANDIDATO = 'MODAL_ADD_CANDIDATO'
const MODAL_DEL_CANDIDATO = 'MODAL_DEL_CANDIDATO'
const MODAL_ADD_CANDIDATO_FIN = 'MODAL_ADD_CANDIDATO_FIN'
const ACT_DATA_CANDIDATOS = 'ACT_DATA_CANDIDATOS'
const ACT_DATA_CANDIDATO = 'ACT_DATA_CANDIDATO'
const ACT_RENDER_CANDIDATOS = 'ACT_RENDER_CANDIDATOS'



const mostrarModalAddCandidatos = show => {
  return {
    type: MODAL_ADD_CANDIDATO,
    show,
  }
}
const mostrarModalDelCandidatos = show => {
  return {
    type: MODAL_DEL_CANDIDATO,
    show,
  }
}


const actualizarDataCandidatos = candidatos => {
  return {
    type: ACT_DATA_CANDIDATOS,
    candidatos,
  }
}
const actualizarDataCandidato = candidato => {
  return {
    type: ACT_DATA_CANDIDATO,
    candidato,
  }
}
const actualizarRenderCandidatos = render => {
  return {
    type: ACT_RENDER_CANDIDATOS,
    render,
  }
}
const mostrarModalAddCandidatosFin = show => {
  return {
    type: MODAL_ADD_CANDIDATO_FIN,
    show,
  }
}


module.exports = {
  mostrarModalAddCandidatos,
  MODAL_ADD_CANDIDATO,
  MODAL_DEL_CANDIDATO,
  actualizarDataCandidatos,
  ACT_DATA_CANDIDATOS,
  actualizarRenderCandidatos,
  ACT_RENDER_CANDIDATOS,
  MODAL_ADD_CANDIDATO_FIN,
  mostrarModalAddCandidatosFin,
  mostrarModalDelCandidatos,
  actualizarDataCandidato,
  ACT_DATA_CANDIDATO
}
