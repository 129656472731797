import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, Container, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'

class DetallePqrs extends Component {
    render() {
        let { 
            pqrs, 
            modalDetallePqrs, 
            mostrarModalDetallePqrs 
        } = this.props
        return (
            <div>
                <Dialog open={modalDetallePqrs} onClose={() => mostrarModalDetallePqrs(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={"Detalle de la PQRS"} mostrarModal={() => mostrarModalDetallePqrs(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 10, overflowX: 'hidden' }} className="scroll">                        
                        <div>
                            <Container maxWidth="sm">
                                <Grid
                                    container
                                    spacing={3}
                                    direction='column'
                                    justify='center'
                                >
                                    {
                                        pqrs.usuario_registro &&
                                            <Table>
                                                <TableBody>
                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row">Usuario:</TableCell>
                                                        <TableCell align="center">{`${pqrs.usuario_registro.primer_nombre} ${pqrs.usuario_registro.primer_apellido}`}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row">Perfil:</TableCell>
                                                        <TableCell align="center">{`${pqrs.perfil}`}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row">Titulo</TableCell>
                                                        <TableCell align="center">{pqrs.titulo}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={2}>
                                                        <TableCell component="th" scope="row">Mensaje</TableCell>
                                                        <TableCell align="center">{pqrs.body}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={3}>
                                                        <TableCell component="th" scope="row">Respuesta</TableCell>
                                                        <TableCell align="center">{pqrs.respuesta}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={3}>
                                                        <TableCell component="th" scope="row">Fecha de solicitud</TableCell>
                                                        <TableCell align="center">{pqrs.fecha_registro}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={3}>
                                                        <TableCell component="th" scope="row">Fecha de Cierre</TableCell>
                                                        <TableCell align="center">{pqrs.fecha_cierre}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                    }
                                </Grid>
                            </Container>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalDetallePqrs(false)} />
                    </DialogActions>
                </Dialog>                            
            </div>
        );
    }
}

export default DetallePqrs;