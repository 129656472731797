import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CheckIcon from '@material-ui/icons/Check';
import { coloresEmma } from '../../global/js/funciones';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'red',
  },
  label: {
    fontWeight: 'normal',
  },
  avatarSelec: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: coloresEmma.secondarycolor
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  bar: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
}));

const useTabStyles = makeStyles({
  root: {
    justifyContent: "center"
  },
  scroller: {
    flexGrow: "0"
  }
});

function Render({ orientation, steps, activeStep }) {
  const classes = useStyles();
  const classes2 = useTabStyles();

  return (
    <AppBar className={classes.bar} position="static" color="default">
      <Tabs
          classes={{ root: classes2.root, scroller: classes2.scroller }}
          value={activeStep}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs"
        >
          {
            steps.map(({ label }, key) => (
              <Tab key={key} label={label} icon={iconLabel(key, activeStep, [classes.avatar, classes.avatarSelec])}/>
            ))
          }               
      </Tabs>
    </AppBar>
  )
}

const iconLabel = (num, seleccion, clase) => {
  if (num < seleccion) {
    return (
      <Avatar className={clase[1]}><CheckIcon /></Avatar>
    )
  } else {
    return (
      <Avatar className={(num == seleccion) ? clase[1] : clase[0]}><Typography style={{'fontSize': '18px', 'color': coloresEmma.fondocolor}} variant="subtitle1" color="textSecondary" component="p">{++num}</Typography></Avatar>
    )
  }
}

export default function BarraProceso({ steps, activeStep }) {
  return (
    <div>
      <Hidden smDown><Render steps={steps} activeStep={activeStep} orientation='horizontal' /></Hidden>
      <Hidden mdUp><Render steps={steps} activeStep={activeStep} orientation='vertical' /></Hidden>
    </div>
  )

}
