import React, { Component } from 'react';
import ReactExport from "react-data-export";
import Button from '@material-ui/core/Button';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportarRubricasExcel extends Component {

  render() {
    let { data, nombre } = this.props;
    return (
      <ExcelFile filename={nombre} element={<Button style={{ color: "#c9a23b" }}>Descargar</Button>}>
        <ExcelSheet data={data} name="Rubricas">
            <ExcelColumn label="CRITERIO DE EVALUACIÓN" value="criterio"/>
            <ExcelColumn label="EXCELENTE" value="excelente" />
            <ExcelColumn label="AVANZADO" value="avanzado" />
            <ExcelColumn label="SATISFACTORIO" value="satisfactorio" />
            <ExcelColumn label="MÍNIMO" value="minimo" />
            <ExcelColumn label="INSUFICIENTE" value="insuficiente" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
export default ExportarRubricasExcel;