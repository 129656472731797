//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'
//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';
//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';

import AppBarModal from '../../general/AppBarModal'

export default function SemilleroMessage(props) {
    
    const handleOnchange = (e) =>{
        if(e.target.name == "msg"){
            props.setMsg(e.target.value)
        }
    }

    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
          <AppBarModal titulo={'¡ Digite su Mensaje !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
          <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth 
                        multiline
                        rowsMax={6}
                        label="Escriba un Mensaje" 
                        id = 'msg'
                        //error = { props.getError('celular', props.errores).length > 0}
                        //helperText = { props.getError('celular', props.errores) }
                        value={props.msg} 
                        onChange={handleOnchange}
                        name="msg"
                    />
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={props.handleClickDelete}>
                Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )

}