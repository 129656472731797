export const PREGUNTAS = [ 'Nombre de Entrevista de Ingreso',
                          'Fecha de creación de entrevista',
                          'Entrevista realizada por',
                          'Periodo',
                          'Nombre completo',
                          'Número de Documento de Identificación',
                          'PROGRAMA',
                          '¿Posee EPS?',
                          'Nombre de la EPS',
                          '¿Observación u Orientación psicológica?',
                          'Nombre del acudiente',
                          'Ocupación del acudiente',
                          'Celular del acudiente',
                          'Parentesco del acudiente',
                          'Nivel educativo del acudiente',
                          'Nombre del padre o madre',
                          'Celular Madre o padre',
                          'Nivel educativo del padre o madre',
                          'Ocupación del padre o madre',
                          'Nivel de Ingresos',
                          'Numero de Hermanos',
                          '# de hermanos con estudios superior',
                          'Posición entre hermanos.',
                          '# de personas que viven con el estudiant',
                          'Colegio donde gradúo',
                          'Carácter del colegio donde graduo',
                          'Posee Computador',
                          'Posee Celular Inteligente',
                          'Tiene Acceso a Internet',
                          'Tiene Plan de Datos',
                          '¿Usa usted lentes? (Recetados)',
                          'Motivo',
                          'Lectura Crítica',
                          'Rango Lectura Crítica',
                          'Rango Razonamiento Cuantitativo',
                          '¿Trabaja mientras estudia?',
                          'Nombre de la empresa donde trabaja',
                          'Telefono de la empresa',
                          '¿Pertenece a alguno de estos grupos?',
                          'Zona difícil acceso a la que pertenece',
                          'Otra Zona de difícil acceso',
                          'Discapacidades',
                          'Especifique Diagnóstico - Discapacidad',
                          '¿A cuál minoría  pertenece?',
                          'Otra Minoría',
                          'Victima del conflicto - Número del RUV',
                          'Estado de la Entrevista',
                          'Tipo de Acompañamiento',
                          'Acompañamiento Académico',
                          'Conclusiones',
                          'Talento o Capacidad Excepcional',
                          'Relación a Contacto: Numero de Hijos',
                          'Diagnóstico médico enfermedad',
                          'Tipo de Inscripción',
                          '¿Tipo de Vinculación?',
                          'Intereses por las ciencias básicas',
                          'Razonamiento Cuantitativo',
                          '¿Cúal diagnostico?',
                          'PLC',
                          'PMA',
                          'GLOBAL',
                         ]