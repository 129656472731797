import {
  MODAL_INICIAR_ASISTENCIA,
} from "../actions/actAsistencia";


const initialState = {
  modalIniciarAsistencia: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_INICIAR_ASISTENCIA:
      return Object.assign({}, state, {
        modalIniciarAsistencia: action.show
      })
    default:
      return state;
  }
}

export default reducer;
