import React, { Component } from 'react';
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { Grid, TextField } from '@material-ui/core'
import BuscarSelect from '../general/BuscarSelect';
import { mostrarModalDetalleGrupo } from "../../redux/actions/actRea";
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useWindowSize from '../general/hooks/useWindowSize';
import { consulta } from "../../global/js/funciones";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import DetalleGrupo from './rea/detalleGrupo';
import AlertasSimple from '../general/AlertasSimple';
import { MenuDocente } from "./rea/ReaMenu";
import { mostrarModalDetalleRea } from "../../redux/actions/actRea";
import DetalleRea from './rea/detalleRea'
import { BtnDetalle } from "../general/BotonesAccion";
import ListarDatos from "../general/ListarDatos";
import moment from 'moment'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// GRAFICA
import { PieChart } from 'react-minimal-pie-chart';

// ICONS
import LoopIcon from '@material-ui/icons/Loop';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { AccordionToggle } from 'react-bootstrap';


const BorderCircularProgress = withStyles(theme => ({
  circle: {
    strokeLinecap: 'round',
    strokeWidth: '2'
  },
}))(CircularProgress);

function ProgressCircle({ percent, color, text, loading }) {

  const windowSize = useWindowSize();

  return (
    <Box position="relative" display="inline-flex">
      <BorderCircularProgress
        variant={!loading ? 'determinate' : 'indeterminate'}
        value={Math.round(percent)}
        style={{
          width: `${windowSize < 600 ? '150px' : '150px'}`,
          height: `${windowSize < 600 ? '150px' : '150px'}`,
          color: `${color}`
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '70%',
          height: '70%',
          borderRadius: '50%',
          justifyContent: 'center',
          background: 'linear-gradient(145deg, #ffffff, #dadfdf)',
          boxShadow: '5px 5px 40px #c2c6c6, -5px -5px 40px #ffffff'
        }}>
          <span
            style={{
              color: '#b5b3bc',
              fontWeight: 'bold',
              fontSize: `${windowSize < 600 ? '15px' : '15px'}`
            }}
          >
            {text}
          </span>
          <span
            style={{
              fontSize: `${windowSize < 600 ? '20px' : '50px'}`,
              color: '#3F3665',
              display: 'flex',
              fontWeight: 'bold'
            }}
          >
            {percent ? `${Math.round(percent)}` : null}
            <span style={{ fontSize: '15px', alignSelf: 'center' }}>%</span>
          </span>
        </div>
      </Box>
    </Box>
  )
}

class ReaDocente extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      seleccion: 'reporte',
      asignaturas: [],
      asignatura: '',
      estadistica: [],
      codigoGrupo: '',
      asimilador: 0,
      acomodador: 0,
      divergente: 0,
      convergente: 0,
      asi: 0, aco: 0, div: 0, con: 0,
      periodo: '',
      loading: false,
      resCheck: false,
      dato_buscar: '',
      procesos: [],
      proceso: null,
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/docente_rea', nombre: 'REA DOCENTE' })
  }

  componentDidUpdate({ }, { seleccion, dato_buscar }) {
    let { dato_buscar: dato_buscarNew } = this.state;
    if (dato_buscar !== dato_buscarNew) {
      this.obtenerEncuestas(this.state.dato_buscar)
    }
  }

  async obtenerEncuestas(dato) {
    let tipo = 1
    if (dato.length > 4) {
      consulta("api/v1.0/rea/list/docente/1?", { dato, tipo }, "post",
        (error, estado, resp) => {
          if (estado === 200) {
            this.setState({ cargando: false, procesos: resp })
          }
        }
      );
    } else {
      this.setState({ cargando: false });
    }
  }

  async obtenerEstadistica(codigo) {
    this.setState({ loading: true })
    let f = codigo
    return new Promise(resolve => {
      consulta(`api/v1.0/rea/list/estadisticaDocentes/${f}?`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerResultados(periodo) {
    return new Promise(resolve => {
      consulta(`api/v1.0/rea/list/consultaSicuc/${periodo}?`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  resetDatos() {
    this.setState({
      asignaturas: [],
      asignatura: '',
      estadistica: [],
      codigoGrupo: '',
      asimilador: 0,
      acomodador: 0,
      divergente: 0,
      convergente: 0,
    })
  }

  transformar = (data, id = 'id', nombre = 'nombre', programa = 'programa') => {
    return data.map((e) => ({
      value: e[id],
      label: e[nombre],
      programa: e[programa]
    }));
  };

  async buscarAsignaturas() {
    this.resetDatos()
    let { periodo } = this.state
    let { actualizarMensaje } = this.props;
    if (periodo === '') {
      actualizarMensaje({ titulo: 'El campo PERIODO es obligatorio', tipo: 'info', mostrar: true, tiempo: 6000 });
    } else {
      this.setState({ cargando: true })
      let respuestaAsignaturas = await this.obtenerResultados(periodo)
      let asignaturas = this.transformar(respuestaAsignaturas.respuesta)
      this.setState({ asignaturas, cargando: false })
    }
  }

  async buscar() {
    let { codigoGrupo } = this.state
    this.setState({ asimilador: 0, acomodador: 0, divergente: 0, convergente: 0 })
    let { actualizarMensaje } = this.props;
    if (codigoGrupo === '') {
      actualizarMensaje({ titulo: 'Debe escribir el ID del grupo', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargando: false })
    } else {
      let estadistica = await this.obtenerEstadistica(codigoGrupo)
      this.setState({ estadistica, asimilador: 0, acomodador: 0, divergente: 0, convergente: 0 })
      this.calcularPorcentajes()
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onChange = ({ target }) => {// evento que escucha los cambios del formulario y asigna el valor al estado de la variable
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  async cambiarEstado(estados) {
    this.setState(estados)
    let estadistica = await this.obtenerEstadistica(estados.asignatura.value)
    this.setState({ estadistica, asimilador: 0, acomodador: 0, divergente: 0, convergente: 0 })
    this.calcularPorcentajes()
  }


  calcularPorcentajes() {
    let { asimilador, acomodador, divergente, convergente, asi, aco, div, con, estadistica } = this.state;
    if (estadistica) {
      estadistica.forEach(function (elemento) {
        if (elemento.tipo_aprendizaje.codigo === 'rea_divergente')
          divergente = divergente + 1
        if (elemento.tipo_aprendizaje.codigo === 'rea_acomodador')
          acomodador = acomodador + 1
        if (elemento.tipo_aprendizaje.codigo === 'rea_asimilador')
          asimilador = asimilador + 1
        if (elemento.tipo_aprendizaje.codigo === 'rea_convergente')
          convergente = convergente + 1
      })
      asi = asimilador
      aco = acomodador
      div = divergente
      con = convergente
      asimilador = (asimilador / estadistica.length) * 100
      acomodador = (acomodador / estadistica.length) * 100
      divergente = (divergente / estadistica.length) * 100
      convergente = (convergente / estadistica.length) * 100
      this.setState({ asimilador, acomodador, divergente, convergente, loading: false, asi, aco, div, con })
    }
  }


  pintarObservaciones() {
    let obligatorio = [{ item: 'Si no aparecen sus asignaturas por favor seleccione buscar por ID Grupo.' },
    { item: 'Digite el periodo actual en el siguiente formato: ', subitem: ['PREGRADO: 20221, 20222.', 'POSGRADO: 2021B, 2022I.'] }]
    return (
      <AlertasSimple
        tipo='info'
        titulo={`Estimado docente tener en cuenta`}
        lista={obligatorio}
        margin='0px'
      />
    )
  }
  pintarConsultas() {
    let { asignaturas, asignatura, asimilador, acomodador, convergente, divergente, loading,
      codigoGrupo, periodo, resCheck, cargando, asi, aco, div, con, estadistica } = this.state;

    let { mostrarModalDetalleGrupo } = this.props;
    return (
      <>
        <Grid container spacing={3} style={{ marginBottom: '40px' }}>
          <Grid item xs={12} md={12} >
            <FormControlLabel
              control={
                <Checkbox
                  checked={resCheck}
                  onChange={this.handleChange}
                  name="resCheck"
                  color="primary"
                />
              }
              label="Buscar por ID del Grupo"
            />
          </Grid>
          {resCheck === false && <>
            <Grid item xs={12} md={5} >
              <TextField
                value={periodo}
                required
                size="small"
                type="text"
                id="periodo"
                name="periodo"
                label="Periodo"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Button variant="outlined" size='large' color='primary' onClick={() => this.buscarAsignaturas()}>Ver Asignaturas</Button>
            </Grid>
            <Grid item xs={12} >
              <BuscarSelect datos={asignaturas} cambiarEstado={(value) => this.cambiarEstado({ asignatura: value })} valor={asignatura} id='select-asigantura' nombre='Asignatura' placeholder='Seleccione Asignatura' />
            </Grid>
          </>
          }
          {resCheck === true && <>
            <Grid item xs={12} md={6} >
              <TextField
                value={codigoGrupo}
                type="number"
                id="codigoGrupo"
                name="codigoGrupo"
                label="ID Grupo"
                size="small"
                fullWidth
                onChange={this.onChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Button variant="outlined" color='primary' size="large" onClick={() => this.buscar()}>Buscar</Button>
            </Grid>
          </>}
        </Grid>

        <Grid item xs={12}>
          <h2>Gráfica porcentual</h2>
          {!cargando && <>
            <ProgressCircle
              percent={asimilador}
              loading={loading}
              color='#FF5733'
              text='Asimilador'
            />
            <ProgressCircle
              percent={convergente}
              loading={loading}
              color='#3399FF'
              text='Convergente'
            />
            <ProgressCircle
              percent={divergente}
              loading={loading}
              color='#73E237'
              text='Divergente'
            />
            <ProgressCircle
              percent={acomodador}
              loading={loading}
              color='#4b0082'
              text='Acomodador'
            />
          </>
          }
          {cargando && <TareasTerminadas mensaje='Cargando....' marginTop='7%' imagen={emma_w} widthImg="13%" />}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}><h2>Estadistica de estilos de aprendizaje</h2></Grid>
          
          <Grid item xs={12} md={6} lg={6}>
            <Table>
              <TableBody>
                <TableRow key={1}>
                  <TableCell component="th" scope="row">Asimilador</TableCell>
                  <TableCell align="left">{asi}</TableCell>
                  <TableCell><FiberManualRecordIcon style={{ color: '#FF5733' }} /></TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell component="th" scope="row">Convergente</TableCell>
                  <TableCell align="left">{con}</TableCell>
                  <TableCell><FiberManualRecordIcon style={{ color: '#3399FF' }} /></TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell component="th" scope="row">Divergente</TableCell>
                  <TableCell align="left">{div}</TableCell>
                  <TableCell><FiberManualRecordIcon style={{ color: '#73E237' }} /></TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell component="th" scope="row">Acomodador</TableCell>
                  <TableCell align="left">{aco}</TableCell>
                  <TableCell><FiberManualRecordIcon style={{ color: '#6A2135' }} /></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} direction="row" justifyContent="center" alignItems="center" >
            {
              (asi == 0 && aco == 0 && div == 0 && con == 0)
              ? 
              <TareasTerminadas mensaje='Selecione una asignatura para visualizar la grafica' marginTop='5%' imagen={emma_s1 } widthImg="10%" style={{minWidth: '60%'}}  />
              : 
              <PieChart style={{ height: '170px' }}
              data={[
                { title: 'Asimilador', value: asi, color: '#FF5733' },
                { title: 'Acomodador', value: aco, color: '#6A2135' },
                { title: 'Divergente', value: div, color: '#73E237' },
                { title: 'Convergente', value: con, color: '#3399FF' },
               ]}
              />
            }
            

          </Grid>
        </Grid>

        {estadistica.length > 0 &&
          <Grid container direction={'row'} justifyContent={'center'} style={{ marginTop: 10 }}>
            <Grid item xs={4}>
              <Button variant="contained" color='primary' size="large" style={{ color: 'white' }} onClick={() => mostrarModalDetalleGrupo(true)}>Ver</Button>
            </Grid>
          </Grid>
        }
      </>
    )
  }
  pintarProceso() {
    let { actualizarMensaje, modalDetalleGrupo, mostrarModalDetalleGrupo } = this.props;
    let { estadistica, asignatura, codigoGrupo, asi, aco, div, con } = this.state
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ marginTop: 10 }} >

        <Grid item xs={10} md={6}>
          {this.pintarObservaciones()}
          {this.pintarConsultas()}
        </Grid>

        <DetalleGrupo
          actualizarMensaje={actualizarMensaje}
          modalDetalleGrupo={modalDetalleGrupo}
          estadistica={estadistica}
          mostrarModalDetalleGrupo={mostrarModalDetalleGrupo}
          nombrePrograma={asignatura.programa}
          nombreAsignatura={asignatura.label}
          grupo={codigoGrupo ? codigoGrupo : asignatura.value}
          descargar={false}
          asimilador={asi}
          acomodador={aco}
          divergente={div}
          convergente={con}
        />
      </Grid>
    )
  }


  pintarEstudiante() {
    let { procesos, cargando, dato_buscar, proceso } = this.state;
    let { actualizarMensaje, modalDetalleRea, mostrarModalDetalleRea } = this.props;

    const acciones = (data) => {
      const onClickDetalle = proceso => {
        this.setState({ proceso })

        mostrarModalDetalleRea(true)
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;

      return <div>{detalle}</div>;
    }
    return (
      <>
        <ListarDatos
          titulo='Lista Aplicacion Encuesta'
          id='tbl_rea_listar'
          descargar="EMMA - REA"
          datos={procesos}
          opciones={true}
          // descargar=''
          filtrar={false}
          refrescar={false}
          buscar={true}
          actfiltrar={false}
          actRefrescar={false}
          acciones={(data) => acciones(data)}
          cargando={cargando}
          avatarImg={true}
          css={{
            list: { padding: '0px 0px 20px 0px' },
            appBar: { padding: '0px 0px 0px 0px' },
          }}
          actBuscar={(dato_buscar) => this.setState({ dato_buscar })}
          datoBuscarAlt={dato_buscar}
          avatar={({ usuario_registro: { primer_nombre } }) => primer_nombre.toUpperCase().charAt(0)}
          fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'tipo_aprendizaje', 'mostrar': ({ tipo_aprendizaje: { nombre } }) => nombre },
            { 'id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },

          ]}

        />
        <DetalleRea
          proceso={proceso ? proceso : 0}
          actualizarMensaje={actualizarMensaje}
          modalDetalleRea={modalDetalleRea}
          mostrarModalDetalleRea={mostrarModalDetalleRea}
          cargar="true"

        />
      </>
    )
  }

  render() {
    let { cargando, seleccion } = this.state
    return (
      <>
        <MenuDocente
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
        />
        <div className='contenido_modulo'>
          {
            !cargando
              ?
              <div>
                {seleccion === 'proceso' && this.pintarEstudiante()}
                {seleccion === 'reporte' && this.pintarProceso()}
              </div>
              : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>

      </>
    )
  }
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  let { modalDetalleGrupo, modalDetalleRea } = state.redRea
  return {
    usuario,
    modalDetalleGrupo,
    modalDetalleRea,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalDetalleGrupo,
  mostrarModalDetalleRea,

}



export default connect(mapStateToProps, mapDispatchToProps)(ReaDocente);
