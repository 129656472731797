// React
import React, { Component } from 'react';

// Material-UI Components
import { Paper, Select, FormControl, MenuItem, InputLabel, StepLabel, Step, Stepper, Button, TextField, Dialog, DialogContent, DialogActions, Grid, List, BottomNavigation, BottomNavigationAction, Checkbox, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';

// Material Icons
import PeopleIcon from '@material-ui/icons/People';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
// Components
import AppBarModal from './../general/AppBarModal';
import { BtnForm, BtnCancelar, BtnCargar, BtnDescargar } from './../general/BotonesAccion';
import EnviarMultiplesArchivos from "./../general/EnviarMultiplesArchivos";
import PersonasBuscarGrado from './PersonasBuscarGrado';
import AlertasSimple from './../general/AlertasSimple';
import ListarDatos from './../general/ListarDatos';
import BuscarPersona from './../general/BuscarPersona'
import TareasTerminadas from './../general/TareasTerminadas'
import BuscarSelect from './../general/BuscarSelect'

//imagenes
import emma_w from './../../global/imagenes/emma_w.png';
import emma_s from './../../global/imagenes/emma_s1.png';
//funciones
import { consulta, rutaImg, obtenerMisDatos, obtenerPermisosValorAlt, valor_peso, obtenerValoresFiltros, valoresToSelect } from '../../global/js/funciones'
import { validarDOI, validarISSN } from "./funciones"
import { REQUISITOS_GRADOS, DIPLOMADOS, GR_INVESTIGACION_GRADOS,LISTA_CURSOS_POSGRADO } from "./helper";


function Menu({ sele = "integrantes", seleccionar = () => { } }) {
	const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

	const pintar = (claseN = '') => {
		return (
			<div className={claseN}>
				<BottomNavigation showLabels >
					<BottomNavigationAction label={'Participantes'} icon={<PeopleIcon />} className={clase("integrantes", sele)} onClick={() => seleccionar("integrantes")} />
					<BottomNavigationAction label={'Tutores'} icon={<SupervisorAccountIcon />} className={clase("tutores", sele)} onClick={() => seleccionar("tutores")} />
				</BottomNavigation>
			</div>
		)
	}

	return (
		<div>
			{pintar('menu_modal')}
		</div>
	);
}

class ModalCrearSolicitud extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cargando: true,
			abrirProgramas: false,
			solicitante: { id: 0, nombre: "" },
			celular: "",
			programa: "",
			abrirEstadoArticulo: false,
			activeStep: 0,
			requisitos: [],
			archivos: [],
			seleccion: "integrantes",
			//Integrantes extras
			integrantes: [],
			modalBuscarIntegrante: false,
			modalConfirIntegrante: false,
			abrirProgramasIntegrante: false,
			integrante: { id: 0, nombre: "", identificacion: "" },
			programa_integrante: "",
			programas_integrante: [],
			//Tutores propuestos
			tutores: [],
			modalBuscarTutor: false,
			modalConfirTutor: false,
			abrirRolesTutor: false,
			rolTutor: "",
			tutor: { id: 0, nombre: "", rol: "" },
			// campos articulos
			estado_articulo: "",
			nombre_articulo: "",
			revista: "",
			grupo_investigacion: { value: "", nombre: "" },
			url_articulo: "",
			doi_articulo: "",
			issn_revista: "",
			volumen_revista: "",
			numero_revista: "",
			// campos certificacion o concurso
			emisor_certificado: "",
			descripcion_certificado: "",
			lista_diplomados: [],
			diplSeleccionado: { id: 0, nombre: '' },
			steps: ['Datos iniciales', 'Integrantes', 'Requisitos'],
			cargando_titulo: 'Cargando..',
			gruposInv: [],
			programas: [],
			programassinpregrado: [],
			listcursos:[],
			cursoselect:[]
		};
	}

	async componentDidMount() {
		let {programassinpregrado}=this.state;
		const datos_sesion = await obtenerMisDatos();
		programassinpregrado=await obtenerValoresFiltros([{ 'llave': 'valorc', 'valor': 'posgrado' }]);
		this.setState({
			celular: datos_sesion.persona.celular,
			solicitante: { 
				id: datos_sesion.persona.id, 
				nombre: `${datos_sesion.persona.primer_nombre} ${datos_sesion.persona.primer_apellido} ${datos_sesion.persona.segundo_apellido}`, 
				identificacion: datos_sesion.persona.identificacion ,
				primer_nombre: datos_sesion.persona.primer_nombre,
				primer_apellido: datos_sesion.persona.primer_apellido,
				segundo_nombre: "",
				segundo_apellido: datos_sesion.persona.segundo_apellido
			},
			programas: datos_sesion.programas, 
			programassinpregrado
		})
	}

	async componentDidUpdate({ opcionGrado }, { estado_articulo, activeStep, programas_integrante, programas,programa,listcursos }) {
		let { opcionGrado: opcionGradoNew } = this.props;
		let { programas: programasNew, estado_articulo: estado_articuloNew, activeStep: activeStepNew, programas_integrante: programas_participanteNew } = this.state;

		if (programas !== programasNew && programasNew.length === 1) {
			this.setState({ programa: { id: programasNew[0].relacion.id, nombre: programasNew[0].relacion.nombre } })
		}
		if (programas_integrante !== programas_participanteNew && programas_participanteNew.length === 1) {
			this.setState({ programa_integrante: { id: programas_participanteNew[0].id, nombre: programas_participanteNew[0].nombre } })
		}

		if (opcionGrado !== opcionGradoNew) {
			if (opcionGradoNew) {
				this.setState({ cargando: true, cargando_titulo: 'Cargando..' })
				let requisitos = await this.obtenerRequisitos(opcionGradoNew.id);
				let lista_diplomados = []
				let gruposInv = []
				let steps = ['Datos iniciales', 'Integrantes', 'Requisitos']
				if (opcionGradoNew.codigo == "gr_diplomado"|| opcionGradoNew.codigo == "gr_cursos") {
					lista_diplomados = await this.obtenerDiplomados()
					steps = ['Datos iniciales', 'Elegir Curso', 'Informacion']
				} else if (opcionGradoNew.codigo == "gr_articulo" ) {
					let valores_art = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': GR_INVESTIGACION_GRADOS }, { 'llave': 'estado', 'valor': '1' }]);
					gruposInv = await valoresToSelect(valores_art)
				}
				this.setState({ activeStep: 0, lista_diplomados, gruposInv, requisitos, steps, cargando: false })
			} else {
				this.setState({
					abrirProgramas: false,
					abrirEstadoArticulo: false,
					abrirGruposInv: false,
					activeStep: 0,
					integrantes: [],
					integrante: { id: 0, nombre: "", identificacion: "" },
					programa_integrante: "",
					programas_integrante: [],
					archivos: [],
					tutores: [],
					seleccion: "integrantes",
					estado_articulo: "",
					nombre_articulo: "",
					revista: "",
					grupo_investigacion: { value: "", nombre: "" },
					url_articulo: "",
					doi_articulo: "",
					issn_revista: "",
					volumen_revista: "",
					numero_revista: "",
					emisor_certificado: "",
					descripcion_certificado: "",
					lista_diplomados: [],
					diplSeleccionado: { id: 0, nombre: '' },
					steps: ['Datos iniciales', 'Integrantes', 'Requisitos'],
					cargando_titulo: 'Cargando..'
				})
			}
		}
		if (estado_articulo !== estado_articuloNew) {
			if (estado_articuloNew) {
				this.setState({
					url_articulo: "",
					doi_articulo: "",
					issn_revista: "",
					volumen_revista: "",
					numero_revista: "",
				})
			}
		}
		if (activeStep !== activeStepNew) {
			if (activeStepNew == 1) this.pushSolicitante(this.state.solicitante, this.state.programa, this.state.integrantes, false)
			if(activeStep == 0 && activeStepNew == 1 && opcionGradoNew.codigo == "gr_cursos")listcursos=await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': programa.id }, { 'llave': 'secundario__generica', 'valor': LISTA_CURSOS_POSGRADO }])
			this.setState({listcursos})
		}
	}

	//Handles

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		});
	}

	 handleStep = async direccion => {
		let { actualizarMensaje, opcionGrado } = this.props
		let { cursoselect,activeStep, celular, programa, estado_articulo, nombre_articulo, revista, grupo_investigacion, url_articulo, doi_articulo, issn_revista, numero_revista, volumen_revista, emisor_certificado, descripcion_certificado, diplSeleccionado,listcursos } = this.state

		if (opcionGrado.codigo == "gr_trabajo_grado" && celular && programa) this.setState({ activeStep: direccion ? activeStep + 1 : activeStep - 1 })
		else if (opcionGrado.codigo == "gr_articulo") {
			if (estado_articulo && estado_articulo != 3 && nombre_articulo && revista && grupo_investigacion.value) this.setState({ activeStep: direccion ? activeStep + 1 : activeStep - 1 })
			else if (estado_articulo && estado_articulo == 3 && nombre_articulo && revista && grupo_investigacion.value && url_articulo && doi_articulo && issn_revista && numero_revista && volumen_revista) {
				if (!validarDOI(doi_articulo)) return actualizarMensaje({ titulo: 'Formato de DOI no valido', tipo: 'info', mostrar: true, tiempo: 2000 });
				if (!validarISSN(issn_revista)) return actualizarMensaje({ titulo: 'Formato de ISSN no valido', tipo: 'info', mostrar: true, tiempo: 2000 });
				this.setState({ activeStep: direccion ? activeStep + 1 : activeStep - 1 })
			}
			else actualizarMensaje({ titulo: 'Debe diligenciar todos los campos para poder continuar', tipo: 'info', mostrar: true, tiempo: 2000 });
		}
		else if (opcionGrado.codigo == "gr_diplomado"){
			if(!(celular && programa) && activeStep == 0) actualizarMensaje({ titulo: 'Debe diligenciar todos los campos para poder continuar.', tipo: 'info', mostrar: true, tiempo: 2000 });
			else if(!diplSeleccionado.id && activeStep == 1) actualizarMensaje({ titulo: 'Debe escoger el diplomado a cursar.', tipo: 'info', mostrar: true, tiempo: 2000 });
			else this.setState({ activeStep: direccion ? activeStep + 1 : activeStep - 1 })
		}
		else if ((opcionGrado.codigo == "gr_empresarial") && celular && programa) this.setState({ activeStep: direccion ? activeStep + 1 : activeStep - 1 })
		else if ((opcionGrado.codigo == "gr_cursos")) {
			if(!(celular && programa) && activeStep == 0) actualizarMensaje({ titulo: 'Debe diligenciar todos los campos para poder continuar.', tipo: 'info', mostrar: true, tiempo: 2000 });
			else if(cursoselect.length == 0 && activeStep == 1) actualizarMensaje({ titulo: 'Debe escoger el curso libre a cursar.', tipo: 'info', mostrar: true, tiempo: 2000 });
			else this.setState({ activeStep: direccion ? activeStep + 1 : activeStep - 1, cursoselect:!direccion && activeStep==1?[]: cursoselect})	 
		}
		else if (["gr_concurso", "gr_certificado"].includes(opcionGrado.codigo) && celular && programa && emisor_certificado && descripcion_certificado) this.setState({ activeStep: direccion ? activeStep + 1 : activeStep - 1 })
			else actualizarMensaje({ titulo: 'Debe diligenciar todos los campos para poder continuar', tipo: 'info', mostrar: true, tiempo: 2000 });
	}

	handleClickSeleccionarIntegrante = (data) => {
		consulta(`api/v1.0/personas/${data.id}/genericas/3`, null, null, (error, estado, resp) => {
			if (estado === 200) {
				let programas = resp.map(e => new Object({ id: e.relacion.id, nombre: e.relacion.nombre }))
				this.setState({ programas_integrante: programas })
			}
		})
		this.setState({
			programa_integrante: "",
			integrante: { id: data.id, nombre: `${data.primer_nombre} ${data.primer_apellido} ${data.segundo_apellido}`, identificacion: data.identificacion },
			modalConfirIntegrante: true,
		})
	}

	handleClickSeleccionarTutor = (data) => {
		this.setState({
			tutor: { id: data.id, nombre: `${data.primer_nombre} ${data.primer_apellido}`, identificacion: data.identificacion },
			modalConfirTutor: true,
		})
	}

	validarLimiteIntegrantes = (programa, integrantes) => {
		let { opcionGrado: { codigo, valora } } = this.props
		let msj = `Solo se permiten ${valora} integrante(s).`
		if (codigo != "gr_trabajo_grado") {
			if (valora && integrantes.length + 1 > valora) return { sw: false, msj }
		}
		else if (codigo == "gr_trabajo_grado" && integrantes.length + 1 > 2) {
			if (integrantes.length + 1 > 3) return { sw: false, msj: 'Solo se permiten 3 integrantes como máximo.' }
			if ((integrantes[0].programa.id == integrantes[1].programa.id && integrantes[0].programa.id == programa.id) && programa.id != 2717 ) return { sw: false, msj: 'Solo se permiten un tercer integrante en caso de vincularse varios programas.' }
		}
		return { sw: true }
	}

	pushSolicitante = (persona, programa, integrantes, notificar = true) => {
		let limi = this.validarLimiteIntegrantes(programa, integrantes)
		if (limi.sw) {
			let array = integrantes.filter(e => e.id != persona.id)
			if (array.length < integrantes.length && notificar) return this.props.actualizarMensaje({ titulo: 'Esta persona ya fue seleccionada', tipo: 'info', mostrar: true, tiempo: 3000 });
			let data = { id: persona.id, nombre: persona.nombre, identificacion: persona.identificacion, programa }
			persona.id == this.state.solicitante.id ? array.unshift(data) : array.push(data)
			this.setState({ integrantes: array, modalConfirIntegrante: false })
			notificar && this.props.actualizarMensaje({ titulo: 'Bien, Persona seleccionada', tipo: 'success', mostrar: true, tiempo: 2000 });
		}
		else notificar && this.props.actualizarMensaje({ titulo: limi.msj, tipo: 'info', mostrar: true, tiempo: 3000 });
	}

	pushTutor = (persona, rol, tutores) => {
		let array = tutores
		if (array.some(e => e.id == persona.id)) return this.props.actualizarMensaje({ titulo: 'Esta persona ya fue seleccionada', tipo: 'info', mostrar: true, tiempo: 3000 });
		if (array.some(e => e.rol == rol)) return this.props.actualizarMensaje({ titulo: `Ya cuentas con un ${rol.split(" ")[0]} seleccionado`, tipo: 'info', mostrar: true, tiempo: 3000 });

		let data = { id: persona.id, nombre: persona.nombre, identificacion: persona.identificacion, rol }
		array.push(data)
		this.setState({ tutores: array, modalConfirTutor: false })
		this.props.actualizarMensaje({ titulo: 'Bien, Persona seleccionada', tipo: 'success', mostrar: true, tiempo: 2000 });
	}

	submit = (archivos) => {
		this.setState({ archivos })
		let { guardarSolicitud, opcionGrado, actualizarMensaje, guardarDiplomado } = this.props;
		// campos generales
		let { celular, programa, integrantes, requisitos, tutores, diplSeleccionado, solicitante,cursoselect } = this.state
		// campos articulos
		let { estado_articulo, nombre_articulo, revista, grupo_investigacion, url_articulo, doi_articulo, issn_revista, numero_revista, volumen_revista } = this.state
		// campos certificados o concurso
		let { emisor_certificado, descripcion_certificado } = this.state
		let tutor_propuesto = tutores.find(e => e.rol == "Tutor Propuesto")
		let cotutor_propuesto = tutores.find(e => e.rol == "Cotutor Propuesto")
		tutor_propuesto ? tutor_propuesto = tutor_propuesto.nombre : tutor_propuesto = ""
		cotutor_propuesto ? cotutor_propuesto = cotutor_propuesto.nombre : cotutor_propuesto = ""
		let data = {}
		if (opcionGrado.codigo == "gr_articulo" && estado_articulo == 3) {
			// guardarSolicitud(celular, archivos, programa.id, opcionGrado.id, integrantes, tutor_propuesto, cotutor_propuesto, estado_articulo, nombre_articulo, revista, grupo_investigacion.value, url_articulo, doi_articulo, issn_revista, numero_revista, volumen_revista, emisor_certificado, descripcion_certificado);
			data = {
				celular,
				programa: programa.id,
				tipo: opcionGrado.id,
				estado_articulo, 
				nombre_articulo, 
				revista, 
				grupo_investigacion: grupo_investigacion.value, 
				url_articulo, 
				doi_articulo, 
				issn_revista, 
				numero_revista, 
				volumen_revista, 
				emisor_certificado, 
				descripcion_certificado
			}
			this.setState({ cargando: true, cargando_titulo: 'Enviando..' })
			guardarSolicitud(data, integrantes, () => this.setState({ cargando: 'false' }));
		}else if(opcionGrado.codigo == "gr_diplomado"){
			data = {
				celular,
				programa: programa.id,
				tipo: opcionGrado.id,
				diplomado: diplSeleccionado.id
			}
			this.setState({ cargando: true, cargando_titulo: 'Enviando..' })
			guardarSolicitud(data, integrantes, () => this.setState({ cargando: 'false' }));
		}else if(opcionGrado.codigo == "gr_cursos"){
			let cursoreform=[]
			cursoselect.map((curso)=>{
				cursoreform.push(curso.secundario.id)
			})
			console.log(cursoreform)
			data = {
				celular,
				programa: programa.id,
				tipo: opcionGrado.id,
				cursoselect: cursoreform
			}
			this.setState({ cargando: true, cargando_titulo: 'Enviando..' })
			guardarSolicitud(data, integrantes, () => this.setState({ cargando: 'false' }));
		} else if (opcionGrado.codigo == "gr_trabajo_grado") {
			if (archivos.length >= requisitos.length) {
				data = {
					celular,
					programa: programa.id,
					tipo: opcionGrado.id,
					tutor_propuesto, 
					cotutor_propuesto
				}
				this.setState({ cargando: true, cargando_titulo: 'Enviando..' })
				guardarSolicitud(data, integrantes, () => this.setState({ cargando: 'false' }), archivos);
			} else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito', tipo: 'info', mostrar: true, tiempo: 2000 });
		}else{
			console.log('otro tipo')
		}
		// else if (archivos.length >= requisitos.length) {
		// 	data = {
		// 		celular,
		// 		programa: programa.id,
		// 		tipo: opcionGrado.id,
		// 		tutor_propuesto, 
		// 		cotutor_propuesto, 
		// 		// estado_articulo, 
		// 		// nombre_articulo, 
		// 		// revista, 
		// 		// grupo_investigacion: grupo_investigacion.value, 
		// 		// url_articulo, 
		// 		// doi_articulo, 
		// 		// issn_revista, 
		// 		// numero_revista, 
		// 		// volumen_revista, 
		// 		// emisor_certificado, 
		// 		// descripcion_certificado
		// 	}
		// 	guardarSolicitud(data, integrantes, archivos );
		// } else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito', tipo: 'info', mostrar: true, tiempo: 2000 });
	}

	configStepContent = () => {
		let { activeStep, seleccion } = this.state
		let { opcionGrado } = this.props;
		switch (activeStep) {
			case 0:
				return (
					<div>
						{this.pintar()}
						{this.alertasInfoGrado()}
					</div>
				)
			case 1:
				if (opcionGrado.codigo == "gr_diplomado") return this.listaDiplomados()
				else if(opcionGrado.codigo == "gr_cursos") return this.listaCursos()
				else {
					return (
						<div>
							{seleccion == "integrantes" &&
								<>
									{this.integrantesExtras()}
									{this.alertasCantIntegrantes()}
								</>
							}
							{seleccion == "tutores" &&
								<>
									{this.tutoresPropuestos()}
								</>
							}
						</div>
					)
				}
			case 2:
				if (opcionGrado.codigo == "gr_diplomado") return this.resumenDiplomado()
				if (opcionGrado.codigo == "gr_cursos") return this.resumencursos()
				else return this.requistosGrado()
			default:
				break
		}
	}

	handleToggleDip = curso => {
		let { diplSeleccionado } = this.state
		if (diplSeleccionado.id == curso.id) this.setState({ diplSeleccionado: { id: 0, nombre: '' } })
		else this.setState({ diplSeleccionado: curso })
	} 
	 handleCheckboxChange = (data) => {
		let {cursoselect}=this.state
		console.log(data)
		if (cursoselect.includes(data)) {
			this.setState({cursoselect:cursoselect.filter((cursoselect) => cursoselect.id !== data.id)})
		} else {
		  this.setState({cursoselect:[...cursoselect,data ]})
		}
	  };

	listaCursos =()=>{
	let {listcursos,cursoselect} = this.state
	const acciones = data => {
		const detalle = (
			<Checkbox
				edge="end"
				onChange={() => this.handleCheckboxChange(data)}
				checked={cursoselect.includes(data)}
			/>
		);
		return detalle
	}
		return(

		<>
			{listcursos.length > 0 ? (
					<ListarDatos
						datos={listcursos}
						id="tbl_cursos"
						avatar={({ secundario:{nombre} }) => nombre.charAt(0)}
						buscar={true}
						opciones={true}
						titulo={'Lista de cursos libres'}
						acciones={(data) => acciones(data)}
						fila_principal={({ secundario:{nombre} }) => nombre}
						filas={[
							{
								id: "valora",
								mostrar: ({ secundario:{valora} }) => valora?valora:"No registra total de creditos",
								nombre: "Valor: "
							},
							{
								id: "valorb",
								mostrar: ({ secundario:{valorb} }) => valorb?valor_peso(valorb):"No registra valor",
								nombre: "Valor: "
							},
						
						]}
					/>
				) : (
					<TareasTerminadas
						mensaje="No hay cursos libre disponibles para este programa"
						marginTop="7%"
						imagen={emma_s}
						widthImg="7%"
					/>
				)}
		</>
		)
	}

	listaDiplomados = () => {
		let { lista_diplomados, diplSeleccionado } = this.state

		const acciones = data => {
			const detalle = (
				<Checkbox
					edge="end"
					onChange={() => this.handleToggleDip(data)}
					checked={diplSeleccionado.id == data.id}
				/>
			);
			return detalle
		}
		return (
			<div>
				{lista_diplomados.length > 0 ? (
					<ListarDatos
						datos={lista_diplomados}
						id="tbl_cursos_diplomados"
						avatar={({ nombre }) => nombre.charAt(0)}
						buscar={true}
						opciones={true}
						titulo={'Lista de Diplomados'}
						acciones={(data) => acciones(data)}
						fila_principal={({ nombre }) => nombre}
						filas={[
							{
								id: "valor",
								mostrar: ({ valora }) => valor_peso(valora),
								nombre: "Valor: "
							},
							{
								mostrar: ({ valorc }) =>
									(valorc == null || valorc == "null") ? null : valorc,
								nombre: "Opción a grado de: ",
							}
						]}
					/>
				) : (
					<TareasTerminadas
						mensaje="No hay diplomados disponibles para este programa"
						marginTop="7%"
						imagen={emma_s}
						widthImg="7%"
					/>
				)}
			</div>
		)
	}

	resumenDiplomado = () => {
		let { diplSeleccionado } = this.state
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography variant={"subtitle2"} component={"span"}>
								Diplomado
							</Typography>
						</TableCell>
						<TableCell align={"right"}>
							<Typography variant={"subtitle2"} component={"span"}>
								Valor
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>

					<TableRow key={diplSeleccionado.id}>
						<TableCell>
							{diplSeleccionado.nombre}
						</TableCell>
						<TableCell align={"right"}>
							{valor_peso(parseInt(diplSeleccionado.valorb))}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		)
	}

	resumencursos = () => {
		let valorreal=0
		const MyTableRow = () => {
			let { cursoselect } = this.state
			let acum=0
			return cursoselect.map((item,index) => (
			<>
			<TableRow key={item.id}>
				<TableCell>{item.secundario.nombre}</TableCell>
				<TableCell align={"right"}>{acum=acum+parseInt(item.secundario.valorb)*parseInt(item.secundario.valora)}</TableCell>
			</TableRow>
			<TableRow>
				{index === cursoselect.length - 1 ?<TableCell> <Typography style={{ fontWeight: "bold" }}>Total</Typography></TableCell>:""}
				{index === cursoselect.length - 1 ?<TableCell align={"right"} >{valor_peso(acum)}</TableCell>:""}
			</TableRow>
			</>
			));

		};
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography variant={"subtitle2"} component={"span"}>
								Curso libre
							</Typography>
						</TableCell>
						<TableCell align={"right"}>
							<Typography variant={"subtitle2"} component={"span"}>
								Valor
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{MyTableRow()}
					
				</TableBody>
			</Table>
		)
	}

	obtenerDiplomados = async () => {
		let lista_diplomados = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DIPLOMADOS }, { 'llave': 'estado', 'valor': '1' }, { 'llave': 'valore', 'valor': 'mostrar' }]);
		return lista_diplomados
	}

	obtenerRequisitos = async tipo => {
		// Requisitos Iniciales del proceso
		//ojo
		let requisitos = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': tipo }, { 'llave': 'secundario__generica', 'valor': REQUISITOS_GRADOS }, { 'llave': 'secundario__valora', 'valor': 1 },])
		return requisitos
	}


	// Componentes
	requistosGrado = () => {
		let { opcionGrado } = this.props
		let { requisitos, estado_articulo } = this.state

		const trasnformarDataAdj = (data) => {
			let r = [];
			data.map(({ secundario }) => r.push({ nombre: secundario.nombre, descripcion: secundario.descripcion, codigo: secundario.id, archivo: secundario.archivo }))
			if (opcionGrado.codigo == "gr_articulo" && estado_articulo == 3) r = []
			return r;
		}

		const acciones = (data, callback) => {
			const onClickCargar = (data) => callback(data);
			const onClickDescargar = ({ archivo }) => {
				if (archivo) window.open(`${archivo}`, '_blank')
			}

			let descargar = data.archivo && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
			let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />

			return <div>{descargar} {cargar}</div>;
		}

		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper elevation={0} square>
							<List component="nav" arial-label="main">
								<EnviarMultiplesArchivos
									enModal={false}
									enviarArchivos={(archivos) => this.submit(archivos)}
									limite={2}
									mostrarMensaje={false}
									enLista={true}
									lista={trasnformarDataAdj(requisitos)}
									acciones={acciones}
								/>
							</List>
						</Paper>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}

	datosPersonales = () => {
		let {opcionGrado} = this.props
		let { celular, programa, abrirProgramas, programas,programassinpregrado } = this.state;
		// programas = programas.filter(programa =>programa.relacion.valorc == "posgrado")
		return (
			<React.Fragment>
				<Grid item xs={12} md={6} lg={6}>
					<TextField type="number" id={'celular'} name={'celular'} label={'Celular'} defaultValue={celular} fullWidth autoComplete="billing address-line1" onChange={this.onChange} value={celular} required />
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<FormControl className="form-control">
						<InputLabel shrink required={true}>Programa</InputLabel>
						<Select 
							open={abrirProgramas} 
							onClose={() => this.setState({ abrirProgramas: false })} 
							onOpen={() => this.setState({ abrirProgramas: true })} 
							value={programa.id} 
							onChange={(e) => this.setState({ programa: { id: e.target.value, nombre: e.nativeEvent.srcElement.attributes.name.value }, integrantes: [] })} 
							labelid="demo-simple-select-placeholder-label-label" 
							id="demo-simple-select-placeholder-label" >
							{opcionGrado.codigo=="gr_cursos" ? programassinpregrado.map(({  id, nombre }) => <MenuItem key={id} value={id} name={nombre}>{nombre}</MenuItem>):programas.map(({ relacion: { id, nombre } }) => <MenuItem key={id} value={id} name={nombre}>{nombre}</MenuItem>)}
						</Select>
					</FormControl>
				</Grid>
			</React.Fragment>
		)
	}

	integrantesExtras = () => {
		let { integrantes, solicitante } = this.state
		return (
			<Grid container spacing={3} className='scroll'>
				<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
					{/* <Button
						variant="contained"
						color="primary"
						size="small"
						onClick={() => this.setState({ modalBuscarIntegrante: true })}
						>
						<PersonAddIcon style={{ color: '#FFFFFF', marginRight: "2px" }} />
						<span style={{ color: '#FFFFFF' }}>Agregar integrante</span>
					</Button> */}
					<ListarDatos
						datos={integrantes}
						avatarImg={true}
						avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
						acciones={(row) => {
							return solicitante.id != row.id && <BtnCancelar texto='Quitar' callback={() => this.setState({ integrantes: integrantes.filter(e => e.id != row.id) })} />
						}}
						fila_principal={({ nombre }) => nombre}
						filas={[{ 'mostrar': ({ programa }) => programa && programa.nombre },]}
						css={{}}
					/>
				</Grid>
			</Grid>
		)
	}

	tutoresPropuestos = () => {
		let { tutores } = this.state
		return (
			<Grid container spacing={3} className='scroll'>
				<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
					{/* <Button
						variant="contained"
						color="primary"
						size="small"
						onClick={() => this.setState({ modalBuscarTutor: true })}
						>
						<PersonAddIcon style={{ color: '#FFFFFF', marginRight: "2px" }} />
						<span style={{ color: '#FFFFFF' }}>Agregar Tutores</span>
					</Button> */}
					{tutores.length > 0 ?
						<ListarDatos
							datos={tutores}
							avatarImg={true}
							avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
							acciones={(row) => {
								return <BtnCancelar texto='Quitar' callback={() => this.setState({ tutores: tutores.filter(e => e.id != row.id) })} />
							}}
							fila_principal={({ nombre }) => nombre.toUpperCase()}
							filas={[{ 'mostrar': ({ rol }) => rol && rol.toUpperCase() },]}
							css={{}}
						/> :
						""
					}
					<AlertasSimple callback={() => this.setState({ modalBuscarTutor: true })} tipo='info' titulo='¿Tutores propuestos?' sub_titulo={"Clic aquí para buscar y sugerir tus tutores de grado, los cuales podrán ser tenidos en cuenta para su proceso. De lo contario puede omitir este paso"} />
				</Grid>
			</Grid>
		)
	}

	alertasCantIntegrantes = () => {
		let { opcionGrado } = this.props
		if (opcionGrado.valorb) return <AlertasSimple callback={() => this.setState({ modalBuscarIntegrante: true })} tipo='info' titulo='¿Agregar más integrantes?' sub_titulo={`Clic aquí para agregar integrantes a tu solicitud, ${opcionGrado.valorb}`} />
		else return ""
	}

	alertasInfoGrado = () => {
		let { opcionGrado } = this.props
		if (opcionGrado.valorc) {
			let lista = [], items = []
			items = opcionGrado.valorc && opcionGrado.valorc.split('-')
			items.map(item => item && lista.push({ item }))
			return < AlertasSimple tipo='info' titulo='Estimado estudiante, tener en cuenta los siguientes requisitos:' lista={lista} />
		} else return ""
	}

	pintar = () => {
		let { opcionGrado } = this.props;
		let { estado_articulo, nombre_articulo, revista, grupo_investigacion, url_articulo, doi_articulo, issn_revista, volumen_revista, numero_revista, abrirEstadoArticulo, emisor_certificado, descripcion_certificado, gruposInv } = this.state;
		if (opcionGrado.codigo == "gr_trabajo_grado") {
			return (
				<Grid container spacing={3} className='scroll' style={{marginBottom: '20px'}}>
					{this.datosPersonales()}
				</Grid>
			)
		} else if (opcionGrado.codigo == "gr_articulo") {
			return (
				<Grid container spacing={3} className='scroll'>
					{this.datosPersonales()}
					<Grid item xs={12}>
						<TextField id='nombre_articulo' name='nombre_articulo' label='Nombre del articulo' value={nombre_articulo} onChange={this.onChange} placeholder="Ingrese el nombre del artículo" fullWidth required />
					</Grid>
					<Grid item xs={12}>
						<TextField id='revista' name='revista' label='Nombre de la Revista' value={revista} onChange={this.onChange} placeholder="Ingrese el nombre de la revista" fullWidth required />
					</Grid>
					<Grid item xs={12}>
						<FormControl className="form-control">
							<BuscarSelect
								datos={gruposInv}
								cambiarEstado={row => this.setState({ grupo_investigacion: row })}
								valor={grupo_investigacion}
								id='select-grupo'
								nombre='Grupo de investigación CUC vinculado'
								placeholder='Seleccione grupo'
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className="form-control">
							<InputLabel shrink required={true}>Estado Articulo</InputLabel>
							<Select open={abrirEstadoArticulo} onClose={() => this.setState({ abrirEstadoArticulo: false })} onOpen={() => this.setState({ abrirEstadoArticulo: true })} value={estado_articulo} onChange={(e) => this.setState({ estado_articulo: e.target.value })}>
								<MenuItem key={1} value={1}>Articulo postulado</MenuItem>
								<MenuItem key={2} value={2}>Articulo en espera de publicación</MenuItem>
								<MenuItem key={3} value={3}>Articulo publicado</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{estado_articulo == 3 && <>
						<Grid item xs={12} md={12} lg={12}>
							<TextField id='url_articulo' name='url_articulo' label='URL Articulo' value={url_articulo} onChange={this.onChange} placeholder="Ingrese Url del artículo" fullWidth />
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<TextField id='doi_articulo' name='doi_articulo' label='DOI Articulo' value={doi_articulo} onChange={this.onChange} placeholder="Ejemplo: 10.1109/CST.2019.1.22.23" fullWidth />
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<TextField id='issn_revista' name='issn_revista' label='ISSN Revista' value={issn_revista} onChange={this.onChange} placeholder="Ejemplo: 8212-2132" fullWidth />
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<TextField type="number" id='volumen_revista' name='volumen_revista' label='Volumen Revista' value={volumen_revista} onChange={this.onChange} placeholder="Ejemplo: 2" fullWidth />
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<TextField type="number" id='numero_revista' name='numero_revista' label='Número Revista' value={numero_revista} onChange={this.onChange} placeholder="Ejemplo: 14" fullWidth />
						</Grid>
					</>}
				</Grid>
			)
		} else if (opcionGrado.codigo == "gr_concurso") {
			return (
				<Grid container spacing={3} className='scroll'>
					{this.datosPersonales()}
					<Grid item xs={12} md={12} lg={12}>
						<TextField id='emisor_certificado' name='emisor_certificado' label='Entidad organizadora del concurso' value={emisor_certificado} onChange={this.onChange} fullWidth autoComplete="billing address-line1" required placeholder="Ejemplo: Colciencias" />
					</Grid>
					<Grid item xs={12} md={12} lg={12}>
						<TextField id='descripcion_certificado' name='descripcion_certificado' label='Decripción del proyecto con el que se ganó el concurso' value={descripcion_certificado} onChange={this.onChange} fullWidth autoComplete="billing address-line1" required placeholder="Ejemplo: Dispositivo de medición cardiaca de bajo costo, elaborado Con Arduino para personas de escasos recursos." multiline rowsMax={4} />
					</Grid>
				</Grid>
			)
		} else if (opcionGrado.codigo == "gr_certificado") {
			return (
				<Grid container spacing={3} className='scroll'>
					{this.datosPersonales()}
					<Grid item xs={12} md={12} lg={12}>
						<TextField id='emisor_certificado' name='emisor_certificado' label='Entidad que emite el certificado' value={emisor_certificado} onChange={this.onChange} fullWidth autoComplete="billing address-line1" required placeholder="Ejemplo: IEEE(Institute of Electrical and Electronics Engineers)" />
					</Grid>
					<Grid item xs={12} md={12} lg={12}>
						<TextField id='descripcion_certificado' name='descripcion_certificado' label='Descripción de la certificación' value={descripcion_certificado} onChange={this.onChange} fullWidth autoComplete="billing address-line1" required multiline rowsMax={4} placeholder="Ejemplo: La certificación ISO 9001 permite instaurar en la empresa un control de calidad que..." />
					</Grid>
				</Grid>
			)
		} else if (opcionGrado.codigo == "gr_empresarial") {
			return (
				<Grid container spacing={3} className='scroll'>
					{this.datosPersonales()}
				</Grid>
			)
		} else if( opcionGrado.codigo == "gr_diplomado" || opcionGrado.codigo=="gr_cursos"){
			return (
				<Grid container spacing={3} className='scroll'>
					{this.datosPersonales()}
				</Grid>
			)
		}
	}


	render() {
		let { opcionGrado, mostrarModalAdd, modalAdd } = this.props;
		let { activeStep, integrante, integrantes, modalBuscarIntegrante, abrirProgramasIntegrante, programas_integrante, programa_integrante, modalConfirIntegrante, tutor, tutores, modalBuscarTutor, modalConfirTutor, abrirRolesTutor, rolTutor, steps, cargando, cargando_titulo } = this.state;
		return (
			<div>
				<Dialog open={modalAdd} onClose={() => mostrarModalAdd(false)} fullWidth={true} fullScreen={window.innerWidth < 600} maxWidth='sm' aria-labelledby="max-width-dialog-title">
					<AppBarModal titulo={opcionGrado.nombre} mostrarModal={mostrarModalAdd} titulo_accion="" accion="" />
					<DialogContent className='scroll' >
						{!cargando ?
							<Grid container>
								<Grid item xs={12} md={12}>
									<Stepper activeStep={activeStep}>
										{steps.map(label => (
											<Step key={label}>
												<StepLabel><p>{label}</p></StepLabel>
											</Step>
										))}
									</Stepper>
								</Grid>
								<Grid item xs={12} md={12}>
									{activeStep == 1 && opcionGrado.codigo == "gr_trabajo_grado" &&
										< Menu sele={this.state.seleccion} seleccionar={seleccion => this.setState({ seleccion })} />
									}
									{this.configStepContent()}
								</Grid>
							</Grid>
							:
							<div>
								<TareasTerminadas imagen={emma_w} mensaje={cargando_titulo} widthImg='7%' marginTop='7%' cargando={true} />
							</div>
						}
					</DialogContent>
					<DialogActions>
						{!cargando && (
							activeStep == 0 ?
								<BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} type="submit" /> :
								activeStep == 2 ?
									<div>
										<BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
										<BtnForm texto="ENVIAR" callback={() => { (opcionGrado.codigo == 'gr_diplomado' || opcionGrado.codigo == 'gr_cursos') ? this.submit([]) : document.getElementById('enviar_form_multi_archivo').click() }} />
									</div> :
									<div>
										<BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
										<BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} type="submit" />
									</div>)
						}
					</DialogActions>
				</Dialog>

				{/* Modal Buscar Participante */}
				<Dialog onClose={() => this.setState({ modalBuscarIntegrante: false })} open={modalBuscarIntegrante} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
					<AppBarModal titulo={'Buscar Estudiante'} mostrarModal={() => this.setState({ modalBuscarIntegrante: false })} />
					<DialogContent style={{ padding: '0' }} className='scroll'>
						<PersonasBuscarGrado tipo="estudiantes" callback={(data) => this.handleClickSeleccionarIntegrante(data)} />
					</DialogContent>
					<DialogActions></DialogActions>
				</Dialog>

				{/* Modal Buscar Tutor */}
				<Dialog onClose={() => this.setState({ modalBuscarTutor: false })} open={modalBuscarTutor} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
					<AppBarModal titulo={'Buscar Tutores'} mostrarModal={() => this.setState({ modalBuscarTutor: false })} />
					<DialogContent style={{ padding: '0' }} className='scroll'>
						<BuscarPersona callback={(data) => this.handleClickSeleccionarTutor(data)} />
					</DialogContent>
					<DialogActions></DialogActions>
				</Dialog>

				{/* Modal confirmar integrante selecionado */}
				<Dialog open={modalConfirIntegrante} fullWidth={true} maxWidth="xs" onClose={() => this.setState({ modalConfirIntegrante: false })} aria-labelledby="form-dialog-title">
					<AppBarModal titulo={"¿Seleccionar Participante?"} mostrarModal={() => this.setState({ modalConfirIntegrante: false })} titulo_accion="" accion="" />
					<DialogContent className='scroll'>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								<TextField id={'integrante'} name={'integrante'} label={'Nombre'} defaultValue={integrante.nombre} fullWidth autoComplete="billing address-line1" value={integrante.nombre} disabled />
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<FormControl className="form-control">
									<InputLabel shrink required={true}>Programa</InputLabel>
									<Select open={abrirProgramasIntegrante} onClose={() => this.setState({ abrirProgramasIntegrante: false })} onOpen={() => this.setState({ abrirProgramasIntegrante: true })} value={programa_integrante.id} onChange={(e) => this.setState({ programa_integrante: { id: e.target.value, nombre: e.nativeEvent.srcElement.attributes.name.value } })} labelid="demo-simple-select-placeholder-label-label" id="demo-simple-select-placeholder-label" >
										{programas_integrante && programas_integrante.map(({ id, nombre }) => <MenuItem key={id} value={id} name={nombre}>{nombre}</MenuItem>)}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Button color="primary" type="submit" id='enviar_form_add_archivo' className="oculto"> Guardar </Button>
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => this.setState({ modalConfirIntegrante: false })} />
						<BtnForm texto="SELECCIONAR" callback={() => {
							if (integrante.id > 0 && programa_integrante) this.pushSolicitante(integrante, programa_integrante, integrantes)
							else this.props.actualizarMensaje({ titulo: 'Seleccione un programa', tipo: 'info', mostrar: true, tiempo: 2000 });
						}}
						/>
					</DialogActions>
				</Dialog>

				{/* Modal confirmar tutor selecionado */}
				<Dialog open={modalConfirTutor} fullWidth={true} maxWidth="xs" onClose={() => this.setState({ modalConfirTutor: false })} aria-labelledby="form-dialog-title">
					<AppBarModal titulo={"¿Seleccionar Tutor?"} mostrarModal={() => this.setState({ modalConfirTutor: false })} titulo_accion="" accion="" />
					<DialogContent className='scroll'>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								<TextField id={'tutor'} name={'tutor'} label={'Nombre'} defaultValue={tutor.nombre} fullWidth autoComplete="billing address-line1" value={tutor.nombre} disabled />
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<FormControl className="form-control">
									<InputLabel shrink required={true}>Rol</InputLabel>
									<Select open={abrirRolesTutor} onClose={() => this.setState({ abrirRolesTutor: false })} onOpen={() => this.setState({ abrirRolesTutor: true })} value={rolTutor} onChange={(e) => this.setState({ rolTutor: e.target.value })} labelid="demo-simple-select-placeholder-label-label" id="demo-simple-select-placeholder-label" >
										<MenuItem key={1} value={"Tutor Propuesto"}>Tutor Propuesto</MenuItem>
										<MenuItem key={2} value={"Cotutor Propuesto"}>Cotutor Propuesto</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Button color="primary" type="submit" id='enviar_form_add_archivo' className="oculto"> Guardar </Button>
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => this.setState({ modalConfirTutor: false })} />
						<BtnForm texto="SELECCIONAR" callback={() => {
							if (tutor.id > 0 && rolTutor) this.pushTutor(tutor, rolTutor, tutores)
							else this.props.actualizarMensaje({ titulo: 'Seleccione un rol', tipo: 'info', mostrar: true, tiempo: 2000 });
						}}
						/>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default ModalCrearSolicitud;
