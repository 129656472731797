import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, CardActions, Avatar, Grid, Typography, IconButton, Collapse, Tooltip } from '@material-ui/core/';
import asignatura1 from '../../global/imagenes/asignatura1.png';
import asignatura2 from '../../global/imagenes/asignatura2.png';
import asignatura3 from '../../global/imagenes/asignatura3.png';
import asignatura4 from '../../global/imagenes/asignatura4.png';
import asignatura5 from '../../global/imagenes/asignatura5.png';
import asignatura6 from '../../global/imagenes/asignatura6.png';
import asignatura7 from '../../global/imagenes/asignatura7.png';
import asignatura8 from '../../global/imagenes/asignatura8.png';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import red from '@material-ui/core/colors/red';
import BlockIcon from '@material-ui/icons/Block';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: '100%',
		minHeight: '50px',
	},
	hover_delete_icon: {
		'&:hover': {
			color: red[800]
		}
	},
	image: {
		width: '100px',
		maxWidth: '100px',
		minWidth: '100px',
		height: '100px'
	},
	card_content_collapse: {
		padding: '10px 16px'
	},
	content_text: {
		padding: '0px 0px 0px 10px'
	},
	content_img: {
		textAlign: 'center',
		height: '120px'
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
}));

export default function ClaseCard({ data, handleClickDelete }) {
	let { id, asignatura, grupo, profesor: { primer_nombre, primer_apellido, segundo_apellido }, fecha_registro, estado } = data;
	let horario = JSON.parse(data.horario);
	let codigo = Math.floor(Math.random() * (7 - 0 + 1)) + 0;
	let fecha = moment(fecha_registro).format('YYYY-MM-DD, h:mma');

	const obtenerColor = (estado) => {
		let color = { 'color': 'white' };
		color.backgroundColor = (estado === '1') ? '#1abc9c' : '#b71c1c';
		return color;
	}

	const obtenerImagen = (codigo) => {
		const imagenes = [asignatura1, asignatura2, asignatura3, asignatura4, asignatura5, asignatura6, asignatura7, asignatura8];
		return imagenes[codigo];
	}

	const [expanded, setExpanded] = React.useState(false);
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<CardHeader avatar={
				<Avatar aria-label='Clase' style={obtenerColor(estado)}>
					{asignatura[0]}
				</Avatar>
			}
				action={
					<Fragment>
						{
							(estado === '1') ?
								<Tooltip title='Cancelar inscripción'>
									<IconButton className={classes.hover_delete_icon} aria-label="cancel"
										onClick={handleClickDelete(id)}>
										<BlockIcon />
									</IconButton>
								</Tooltip> : ''
						}
					</Fragment>
				}
				title={asignatura}
				subheader={fecha} />

			<CardContent>
				<Grid container direction='row' justify='center'>
					<Grid item xs={12} sm={6} md={4} className={classes.content_img}>
						<img src={obtenerImagen(codigo)} className={classes.image} alt={'Imagen Clase'} />
					</Grid>
				</Grid>
			</CardContent>

			<CardActions disableSpacing>
				<div>
					Grupo:
					<Typography variant="body2" color='textSecondary' component="p" align="justify">{grupo}</Typography>

					Profesor:
					<Typography variant="body2" color='textSecondary' component="p" align="justify">
						{`${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
					</Typography>
				</div>
				<Tooltip title='Ver horario'>
					<IconButton className={clsx(classes.expand, { [classes.expandOpen]: expanded, })}
						onClick={() => setExpanded(!expanded)}
						aria-expanded={expanded}
						aria-label='Ver horario'>
						<ExpandMoreIcon />
					</IconButton>
				</Tooltip>
			</CardActions>

			<Collapse in={expanded} timeout='auto' unmountOnExit>
				<CardContent className={classes.card_content_collapse}>
					<Typography variant="body2" color='textSecondary' component="p" align="justify">Horario:</Typography>

					{horario && horario.map(({ dia, hora_inicio, hora_fin }, index) => {
						if (dia === null || hora_inicio === null || hora_fin === null) {
							return (
								<Typography variant="body2" color='textSecondary' component="p" align="justify" key={index}>
									Sin asignar
								</Typography>
							);
						} else {
							return (
								<Typography variant="body2" color='textSecondary' component="p" align="justify" key={index}>
									{`${dia} de ${hora_inicio} - ${hora_fin}`}
								</Typography>
							)
						}
					})}
				</CardContent>
			</Collapse>
		</Card>
	)
}
