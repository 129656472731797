import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import { actualizarMensaje } from "../../redux/actions/actGlobal";

class Mensajes extends Component {

  componentDidUpdate({ mensaje }) {
    let { mensaje: mensajeNew, actualizarMensaje } = this.props;
    if (mensajeNew.mostrar !== mensaje.mostrar) {
      if (mensajeNew.mostrar) {
        document.getElementById('crear_mensaje_app').click()
        actualizarMensaje({
          mostrar: false,
          tipo: "none",
          titulo: "",
          tiempo: 6000,
          onScreen: false
        });
      }
    }
  }

  obtenerTitulo = (tipo) => {
    const mensajes = {
      'success': 'Bien .!',
      'info': 'Oops .!',
      'warning': 'Cuidado.!',
      'danger': 'Oops.!',
    }
    return (mensajes[tipo])
  }

  render() {
    const { mensaje } = this.props;
    let {
      tipo,
      titulo,
      tiempo,
      onScreen,
    } = mensaje;


    return (
      <button
        id="crear_mensaje_app"
        className='oculto'
        onClick={() => {
          store.addNotification({
            title: this.obtenerTitulo(tipo),
            message: `${titulo}`,
            type: tipo,                         // 'default', 'success', 'info', 'warning'
            insert: "top",                // where to position the notifications
            container: 'top-left',
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: tiempo,
              onScreen,
              showIcon: true,
              pauseOnHover: false,
            },


          })
        }}
      />
    );
  }
}


const mapStateToProps = (state) => {
  let { mensaje } = state.redGlobal;
  return {
    mensaje
  }
};

const mapDispatchToProps = {
  actualizarMensaje,
}

Mensajes.propTypes = {
  mensaje: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    tipo: PropTypes.oneOf(['success', 'error', 'info', 'warning', 'none']).isRequired,
    mostrar: PropTypes.bool.isRequired,
    tiempo: PropTypes.number,
  }),
}


export default connect(mapStateToProps, mapDispatchToProps)(Mensajes);

