import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Candidato from './Candidato'
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { consulta, mostrarError } from "../../../global/js/funciones";
import { actualizarMensaje } from "../../../redux/actions/actGlobal";
import ConfirmarAccion from '../../general/ConfirmarAccion';
import TareasTerminadas from '../../general/TareasTerminadas';
import { mostrarModalAddCandidatosFin, actualizarDataCandidato } from "../../../redux/actions/actCandidatos";
import TituloAccion from '../../general/TituloAccion'
import { BtnForm } from "../../general/BotonesAccion";
//imagenes
import emma_s from '../../../global/imagenes/emma_s1.png';
import emma_w from '../../../global/imagenes/emma_w.png';

class PintarVotaciones extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      candidatos: [],
      eleccion: {}
    }
  }


  async componentDidMount() {
    let { id } = this.props;
    let eleccion = await this.obtenerEleccion(id);
    let candidatos = await this.obtenerCandidatos(id);
    this.changeState({ candidatos, eleccion })
  }

  changeState = (nuevo) => {
    this.setState(nuevo)
  }

 votarCandidato = (eleccion, candidato) => {
    this.setState({cargando:true})
    this.props.mostrarModalAddCandidatosFin(false);
    consulta(`api/v1.0/elecciones/${eleccion}/votar`, { candidato }, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.props.actualizar();
          this.props.mostrarModalVotarCandidatos(false);
          this.props.modalAddCandidatosFin(false)
          this.setState({cargando:false})
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          this.setState({cargando:false})
        }
      }
      

      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }
  obtenerCandidatos = (id) => {
    this.setState({ cargando: true })
    return new Promise(resolve => {
      consulta(`api/v1.0/elecciones/${id}/candidatos`, null, null,
        (error, estado, resp) => {
          resolve(resp)
          this.setState({ cargando: false })
        }
      );
    })
  }

  obtenerEleccion = (id) => {
    return new Promise(resolve => {
      consulta(`api/v1.0/elecciones/${id}`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  pintarCandidatos = (candidatos) => {
    let { eleccion } = this.state;
    let { actualizarDataCandidato, mostrarModalAddCandidatosFin } = this.props;
    const abrir = (id_eleccion, id, nombre) => {
      actualizarDataCandidato({ id, nombre });
      mostrarModalAddCandidatosFin(true);
    }
    if (candidatos.length > 0) {
      return (
        <>
          <TituloAccion principal='' alterno={eleccion.nombre.toUpperCase()} />
          {candidatos.map(({ id, candidato: persona, foto, descripcion }) => <Candidato key={id} eleccion={eleccion} votarCandidato={(id_eleccion, id, nombre) => abrir(id_eleccion, id, nombre)} id={id} foto={foto} descripcion={descripcion} nombre={`${persona.primer_nombre} ${persona.primer_apellido}`} />)}
        </>
      )
    }
    else return <TareasTerminadas mensaje='Hola, Lo sentimos aún no hay candidatos disponibles, vuelva más tarde' marginTop='7%' imagen={emma_s} widthImg="7%" />
  }


  render() {
    let { cargando, candidatos } = this.state;
    return (
      <Grid container spacing={4}>
        {!cargando ?
          <>
            {this.pintarCandidatos(candidatos)}
          </> :
          <TareasTerminadas mensaje='Cargando..' cargando={true} marginTop='7%' imagen={emma_w} widthImg="7%" />
        }
        <ConfirmarAccion mensaje1={`El voto será registrado al candidato "${this.props.candidato.nombre}", `} ejecutarAccion={(id_candidato) => this.votarCandidato(this.props.id, id_candidato)} mostrarModalConfAccion={this.props.mostrarModalAddCandidatosFin} modalConAccion={this.props.modalAddCandidatosFin} dato={this.props.candidato} titulo={'¿ Estas Seguro ?'} />
      </Grid>
      
    )
  }
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


function CandidatosVotar({ id, actualizarMensaje, modalVotarCandidatos, mostrarModalVotarCandidatos, actualizar, mostrarModalAddCandidatosFin, modalAddCandidatosFin, candidato, actualizarDataCandidato }) {
  const classes = useStyles();

  const votarCandidato = (eleccion, candidato,cargando) => {
    cargando=true
    consulta(`api/v1.0/elecciones/${eleccion}/votar`, { candidato }, "put", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          actualizar();
          mostrarModalVotarCandidatos(false);
          mostrarModalAddCandidatosFin(false);
          cargando=false
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
          cargando=false
        }
      }
      

      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    })
  }

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            <PintarVotaciones id={id} mostrarModalVotarCandidatos={mostrarModalVotarCandidatos} actualizar={actualizar} mostrarModalAddCandidatosFin={mostrarModalAddCandidatosFin} actualizarDataCandidato={actualizarDataCandidato} candidato={candidato} modalAddCandidatosFin={modalAddCandidatosFin} />
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { modalAddCandidatosFin, candidato } = state.redCandidatos;
  return {
    modalAddCandidatosFin,
    candidato,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModalAddCandidatosFin,
  actualizarDataCandidato,

}

//Props del componente
CandidatosVotar.propTypes = {
  id: PropTypes.number.isRequired,
  modalAddCandidatosFin: PropTypes.bool.isRequired,
  candidato: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombre: PropTypes.string.isRequired,
  }),
  actualizarMensaje: PropTypes.func.isRequired,
  actualizar: PropTypes.func.isRequired,
  mostrarModalAddCandidatosFin: PropTypes.func.isRequired,
  actualizarDataCandidato: PropTypes.func.isRequired,
}


export default connect(mapStateToProps, mapDispatchToProps)(CandidatosVotar);

