import React, { Component, forwardRef } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl, Checkbox, ListItemText, IconButton, Tooltip } from '@material-ui/core';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, obtenerValoresFiltros, transformar, generarFiltros } from '../../../global/js/funciones'
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import BuscarSelect from '../../general/BuscarSelect';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListarTabla from '../../general/ListarTabla';

class ReaInforme extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columnas: [],
            data_informe: [],
            cargando: false,
            periodo: '',
            periodos: [],
            maxWidth: 'sm',
            cargar: true,
            mostrar: 'filtros',
            periodoFiltro: null,
            programa: '',
        };
    }

    async componentDidUpdate({ modalRepInfoRea }) {
        let { modalRepInfoRea: modalRepInfoReaNew } = this.props;
        console.log(modalRepInfoRea !== modalRepInfoReaNew)
        if (modalRepInfoRea !== modalRepInfoReaNew) {
            if (modalRepInfoReaNew) {
                if (this.state.cargar) {
                    this.setState({ cargando: true })
                    let data = await this.obtenerPeriodos();
                    this.setState({
                        periodos: data,
                        cargando: false,
                        cargar: false,
                    })
                }

            } else this.resetState()
        }
    }

    obtenerPeriodos = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/rea/periodos`, null, null,
                (error, estado, resp) => {
                    resolve(resp)
                }
            );
        })
    }

    resetState = () => {
        let { mostrar } = this.state

        if (mostrar === 'filtros') this.setState({ data_informe: [], columnas: [], mostrar: 'filtros', maxWidth: 'sm', periodo: '', cargando: false, periodoFiltro: '' })
        else if (mostrar === 'informe') this.setState({ mostrar: 'filtros', maxWidth: 'sm', columnas: [], todoMulti: [], cargando: false, periodoFiltro: '' })

    }

    obtenerInforme = async (filtros = []) => {
        let { actualizarMensaje } = this.props
        let f = await generarFiltros(filtros);
        this.setState({ cargando: true });
        consulta(`api/v1.0/rea/informe?${f}`, null, null,
            (error, estado, resp) => {
                if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
                else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
            }
        );
    }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
            [name]: value
        })
    }


    pintarFiltros = () => {
        let { periodos, periodoFiltro } = this.state

        return (
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                <FormControl className='form-control'>
                    <InputLabel htmlFor="periodoFiltro">Seleccione Periodo</InputLabel>
                    <Select
                        inputProps={{
                            name: 'periodoFiltro',
                            id: 'periodoFiltro',
                        }}
                        value={periodoFiltro}
                        onChange={this.onChange}
                        required={true}>
                        {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        )
    }

    onSubmit = (e) => {
        let { periodoFiltro, programa, } = this.state
        let { actualizarMensaje, informe } = this.props
        let filtros = [], titulo = '';

        if (informe.codigo == "Rea_Est_Eva") {
            if (periodoFiltro !== '') {
                filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
                filtros.push({ 'llave': 'periodo', 'valor': periodoFiltro });
                // if (programa !== '') filtros.push({ 'llave': 'programa', 'valor': programa.value });
            } else titulo = 'Por favor, seleccione el periodo.'
        }

        if (titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
        else this.obtenerInforme(filtros)
        e.preventDefault();
    }

    obtenerInforme = async (filtros = []) => {
        let { periodoFiltro } = this.state
        let { actualizarMensaje,informe} = this.props
        let f = await generarFiltros(filtros);
        this.setState({ cargando: true });
        consulta(`api/v1.0/rea/informe?${f}`, {periodo: periodoFiltro, informe: informe.codigo}, 'POST',
            (error, estado, resp) => {
                if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
                else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });
            }
        );
    }

    pintarInforme = () => {
        let { data_informe, periodo, columnas } = this.state
        let { informe } = this.props
        let ordenar = false;
        let Total = true;

        console.log(data_informe);

        if (data_informe.length > 0) {
            if (columnas.length == 0) {
                let codigo = informe.codigo

                if (codigo === 'Rea_Est_Eva') {

                    columnas.push(
                        { title: 'Estudiante', field: 'nombre'},
                        { title: 'Identificación', field: 'identificacion'},
                        { title: 'Correo', field: 'correo'},
                        { title: 'Tipo de Aprendiaje', field: 'aprendizaje' },
                        { title: 'Periodo', field: 'periodo' },
                        { title: 'Programa', field: 'programa' },

                    )

                }

            }

            const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo} - ${informe.tipo_aprendizaje}` : informe.nombre

            const acciones = [];

            return (
                <ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe} acciones={acciones} orden={ordenar} />
            )
        } else {
            return (
                <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
            )
        }
    }

    pintar = () => {
        let { cargando, mostrar } = this.state;
        if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
        return (
            <div>
                {mostrar === 'filtros' && this.pintarFiltros()}
                {mostrar === 'informe' && this.pintarInforme()}
            </div>
        )
    }

    render() {
        let { cargando, maxWidth, mostrar } = this.state;
        let { modalRepInfoRea, mostrarModalReportRea, informe } = this.props
        return (
            <Dialog fullWidth={true} maxWidth={maxWidth} open={modalRepInfoRea} onClose={() => mostrarModalReportRea(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalReportRea(false)} titulo_accion={(mostrar === 'filtros') ? "Limpiar" : 'Volver'} accion={this.resetState} />

                <DialogContent className='scroll'>
                    {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
                </DialogContent>

                <DialogActions>
                    <BtnForm texto="Cerrar" callback={() => mostrarModalReportRea(false)} />
                    {!cargando && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
                </DialogActions>
            </Dialog>
        );
    }
}

export default ReaInforme