//Importaciones necesaria para poder utilizar react
import React from 'react'
import * as Funciones from '../../../global/js/funciones';
import ListarDatos from '../../general/ListarDatos';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import moment from 'moment'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
//Importacion para utilizar el AppBarModel modificado de Emma
import AppBarModal from '../../general/AppBarModal'
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch } from 'react-redux';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { BtnRegresar } from "../../general/BotonesAccion";
import { Paper, Grid, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import emma_s1 from '../../../global/imagenes/emma_s1.png';
import FilterListIcon from '@material-ui/icons/FilterList';
import TituloAccion from '../../general/TituloAccion';
import AccionesRapidas from '../../general/AccionesRapidas';
import { BtnForm } from "../../general/BotonesAccion";
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from '../../general/BuscarPersona';
import SemillerosListSemi from '../Hijos/SemillerosListSemi';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import * as Helper from '../Hijos/Helper';
import { coloresEmma } from '../../../global/js/funciones';
const useStyles = makeStyles((theme) => ({
    colorEmmma: {
      color: coloresEmma.secondarycolor,
      transition: 'all 0.3s',
      '&:hover': {
        color: "#fff",
        backgroundColor: coloresEmma.secondarycolor,
        transform: "scale(1.3)",
      },
    },
    colorDelete: {
      color: "#d11a2a",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#d11a2a",
        transform: "scale(1.3)",
      },
    },
    colorEdit: {
      color: "#0074C5",
      transition: "all 0.3s",
      '&:hover': {
        color: "#fff",
        backgroundColor: "#0074C5",
        transform: "scale(1.3)",
      },
    },
    colorGestion:{
        color: "#41DD5B",
        transition: "all 0.3s",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#41DD5B",
            transform: "scale(1.3)",
        },
    }
}));

export default function AdminListarSemi() {
    const classes = useStyles();
    const isInitialMount = React.useRef(true);
    const [semilleros, setSemilleros] = React.useState([]);
    const [cargando, setCargando] = React.useState(true)
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalAll, setOpenModalAll] = React.useState(false);
    const [profesor, setProfesor] = React.useState('')
    const [fila, setFila] = React.useState('');
    const [accion, setAccion] = React.useState('Procesos');
    const [solicitudes, setSolicitudes] = React.useState([]);
    const [periodo, setPeriodo] = React.useState('');
    const [modalFilterCard, setModalFilterCard] = React.useState(false);
    const [modalFilterCardAll, setModalFilterCardAll] = React.useState(false);
    const [codigoActually, setCodigoActually] = React.useState('') 
    const [pos, setPos] = React.useState('Información del Semillero')
    const [openModalInfo, setOpenModalInfo] = React.useState(false);
    const [semiActual, setSemiActual] = React.useState([])
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                //let data = await getAllSemilleros();
                let periodo = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Semi' }]);
                obtenerEstadoSolicitudes(periodo[0].nombre);
                if(periodo.length > 0){
                    setPeriodo(periodo[0].nombre)
                }
            }
            obtenerProcesos()
        }
    })

    const modalChangeCategoriaClose = () =>{
        setOpenModalAll(false)
    }

    const modalAllCategoriaFilter = async () =>{
        let filtro =  `usuario_registro__identificacion=${profesor.identificacion}`
        let data = await getAllSemilleros(filtro);
        setSemilleros(data)
        setOpenModalAll(false)
        setAccion('Listar')
    }

    const getAllSemilleros = async (f) => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listaSolicitudes/1?${f}`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const obtenerEstadoSolicitudes = (periodo) => {
        Funciones.consulta(`api/v1.0/investigacion/estados/${periodo}`, null, null, (error, estado, resp) => {
          if (estado === 200) {
            let { general, extra } = resp
            if (extra.length > 0) {
              extra.map(({ solicitudes, nombre, codigo, orden }) => {
                let reporte = {
                  'estado_actual__codigo': codigo,
                  'estado_actual__nombre': nombre,
                  'num_sol': solicitudes,
                  'orden': orden
                }
                
                general.push(reporte);
              })
            }
            setSolicitudes(general.sort((a, b) => a.orden - b.orden ))
            setPeriodo(periodo)
            setCargando(false)
          }
        })
      }

    const acciones = (row) => {
        let detalle
        const eventoDetalle = (row) => {
            setFila(row)
            setOpenModal(true)
        }

        const openModalInfo = (row) => {
            setFila(row)
            setOpenModalInfo(true)
        }
        if(row.permiso == "1" || row.permiso == "0"){
           if(codigoActually != 'Semi_historial'){
            detalle = (<React.Fragment>
                <IconButton className={classes.colorGestion} onClick={() => eventoDetalle(row)}><VerifiedUserIcon /></IconButton>
                <IconButton className={classes.colorEmmma} onClick={() => openModalInfo(row)}><MenuBookIcon /></IconButton>
                </React.Fragment>);
           }else{
            detalle = (<React.Fragment>
                <IconButton className={classes.colorEmmma} onClick={() => openModalInfo(row)}><MenuBookIcon /></IconButton>
                </React.Fragment>);
           }
            
        }else{
            detalle = null
        }
        
        return detalle
    }

    const handleClick = () => {
        setCargando(true)
        obtenerEstadoSolicitudes(periodo)
        setAccion("Procesos")
    };

    const PintarLista = (props) => {

        return (
            <div>
                
                {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> 
                :
                <div>
                    <ListarDatos
                        id="tbl_listar_semilleros_participantes"
                        avatar={({ tipo }) => tipo.nombre.charAt(0)}
                        datos={semilleros}
                        descargar='INVESTIGACION SOLICITUDES - EMMA'
                        opciones={true}
                        buscar={true}
                        filtrar={true}
                        actfiltrar={() => setModalFilterCardAll(true)}
                        acciones={(row) => acciones(row)}
                        titulo={"Lista de Solicitudes"}
                        fila_principal={({ usuario_registro}) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido}`}
                        filas={[
                        {
                            'mostrar': ({usuario_registro}) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`,
                            'id': 'Solicitante', 'enLista': false
                        },
                        {
                            'mostrar': ({usuario_registro}) => `${usuario_registro.correo}`,
                            'id': 'Correo de Solicitante', 'enLista': false
                        },
                        {
                            'mostrar': ({estado_actual}) => `${estado_actual.nombre}`,
                            'id': 'Estado Actual', 'enLista': true
                        },
                        {
                            'mostrar': ({fecha_registro}) => `${moment(fecha_registro).format('YYYY-MM-DD, h:mm a')}`,
                            'id': 'Fecha de Registro', 'enLista': true
                        },
                        {
                            'mostrar': ({tipo}) => `${tipo.nombre}`,
                            'id': 'Tipo de Solicitud', 'enLista': true
                        },
                        {
                            'mostrar': ({solicitud_semillero_investigacion}) => `${solicitud_semillero_investigacion[0].nombre}`,
                            'id': 'Nombre Semillero', 'enLista': true
                        },
                        {
                            'mostrar': ({periodo}) => `${periodo}`,
                            'id': 'Periodo', 'enLista': false
                        },
                        ]}
                    />
                </div>}
                {openModal == true ?
                <ModalChangeStates 
                    openModal={openModal}
                    setOpenModal={setOpenModal}

                    row={fila}

                    semilleros={semilleros}
                    setSemilleros={setSemilleros}

                    cargandoList={cargando}
                    setCargandoList={setCargando}
                    
                    getAllSemilleros={getAllSemilleros}

                    codigoActually={codigoActually}
                    periodo={periodo}
                /> : null}
                {openModalInfo == true ? <SemillerosListSemi
                openModalInfo={openModalInfo}
                setOpenModalInfo={setOpenModalInfo}

                semiActual={fila.solicitud_semillero_investigacion[0]}
                setAccion={setAccion}
                pos={pos}
                setPos={setPos}

                profesor={true}

                vistaEstudiante={true}
                gestor={true}
            /> : ''}
            </div>
        )
    }

    const PintarInicioProcesos = (props) => {
        
        const cambiarEstadoProceso = async (codigo) =>{
            setCargando(true)
            if (codigo != 'Tod_Bec'){
                setCodigoActually(codigo)
                let filtro =  `estado_actual__codigo=${codigo}&periodo=${periodo}`
                let data = await getAllSemilleros(filtro);
                setSemilleros(data)
                setAccion('Listar')
            }else{
                setOpenModalAll(true)
            }
            setCargando(false)
        }

        const obtenerColor = (codigo) => {
          let color = 'white';
          const colores = {
            'Semi_activo': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
            'Semi_pause': { 'backgroundColor': '#ffc107', color },
            'Semi_detenido': { 'backgroundColor': '#01519B', color },
            'Semi_env': { 'backgroundColor': '#009688', color },
            'Semi_historial':{ 'backgroundColor': '#dc143c'},
            'Est_bec_neg': { 'backgroundColor': '#b71c1c', color },
            'Est_bec_can': { 'backgroundColor': '#b71c1c', color },
            'Est_bec_Apr_th': { 'backgroundColor': '#bdbdbd', color },
            'Est_bec_Apr_Rev': { 'backgroundColor': '#bdbdbd', color },
            'Tod_Bec': { 'backgroundColor': '#0d47a1', color }
        }
          return (colores[codigo])
        }
    
        return (
        <div>
            {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> 
                :
                <div>
                    <div style={{ padding: '2% 2% 0% 2%' }} >
                    <Grid container direction={'row'} spacing={1} justify={'center'} >
                        <Grid item xs={12} sm={12} md={12} lg={10} >
                        <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                            <Grid item xs={6} sm={6} md={6} lg={6} >
                            <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} >
                            <Tooltip title='Filtrar' aria-label="add" >
                                <IconButton edge={false} color="secondary" style={{ float: 'right' }} onClick={() => setModalFilterCard(true)}><FilterListIcon /></IconButton>
                            </Tooltip>
                            </Grid>
                        </Grid>
                        <Paper elevation={0} square className="scroll contenido_fijo">
                            { (solicitudes.length === 0) ?
                            <TareasTerminadas mensaje='Aún no hay solicitudes para este periodo.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                            <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                            {solicitudes.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol}, i) => {
                                return (
                                  <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                                    <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => cambiarEstadoProceso(codigo)}>
                                      <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name= {nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] :  ['Debe aplicar los filtros.']}/>
                                    </Paper>
                                  </Grid>
                                )
                              })}
                              </Grid>
                              }
                        </Paper>
                        </Grid>
                    </Grid>
                </div>
                
                </div>}
            </div>
        )
      }

    return (
        <div>
            {
                !cargando ?
                <div>
                    {accion == 'Procesos' ? <PintarInicioProcesos/> : <PintarLista />}
                    {accion != 'Procesos' && <div className={'regresar'} ><BtnRegresar callback={handleClick} /></div>}
                </div>
                : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
            {modalFilterCard == true ? 
            <ModalChangeFilterPeriodo
                modalFilterCard = {modalFilterCard}
                setModalFilterCard = {setModalFilterCard}

                obtenerEstadoSolicitudes={obtenerEstadoSolicitudes}

                cargando={cargando}
                setCargando={setCargando}
                periodo_generica={periodo}


            /> : ''}
            {modalFilterCardAll == true ? 
            <ModalChangeFilterPeriodoAll
                modalFilterCardAll = {modalFilterCardAll}
                setModalFilterCardAll = {setModalFilterCardAll}

                obtenerEstadoSolicitudes={obtenerEstadoSolicitudes}

                cargandoList={cargando}
                setCargandoList={setCargando}

                periodo={periodo}
                setPeriodo={setPeriodo}

                codigoActually={codigoActually}
                setCodigoActually={setCodigoActually}

                getAllSemilleros={getAllSemilleros}

                semilleros={semilleros}
                setSemilleros={setSemilleros}
            /> : ''}
            {openModalAll == true ? 
                <FiltrosCategoriasTodos 
                    openModalChange={openModalAll}
                    setOpenModal={setOpenModalAll}
                    modalChangeCategoriaClose={modalChangeCategoriaClose}

                    modalAllCategoriaFilter={modalAllCategoriaFilter}

                    profesor={profesor}
                    setProfesor={setProfesor}
                />: ''}
        </div>
    )
}

const FiltrosCategoriasTodos = (props) =>{
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = React.useState(false)
    const handleClickFilter = async () =>{
        if(props.profesor.nombre == ''){
            let titulo = 'Elija a un Usuario'
            let tipo = 'info'
            dispatch(
                actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
              );
        }else{
            props.modalAllCategoriaFilter()
        }
    }
    return(
        <div>
              <Dialog open={props.openModalChange} onClose={()=>{props.modalChangeCategoriaClose()}} aria-labelledby="form-dialog-title">
  
                  <AppBarModal titulo='Aplicar Filtros' mostrarModal={()=>{props.modalChangeCategoriaClose()}} titulo_accion="" accion="" />
                  {/* Contenido del modal del formulario de */}
                  <DialogContent className='scroll'>
                    <Grid container spacing={3} className='margin_cont' >
                        <Grid item xs={12}>
                        <InputBuscar
                            id='profesor'
                            label='Usuario'
                            placeholder='Clic aquí para buscar'
                            value={props.profesor.nombre}
                            callback={() => setOpenModal(true)} />
                        </Grid>
                    </Grid>
                  </DialogContent>
  
                  <DialogActions>
                    <BtnForm texto="FILTRAR" callback={() => handleClickFilter()} />
                  </DialogActions>
  
              </Dialog>
              {openModal == true ? 
                <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={openModal} onClose={() => setOpenModal(false)}>
                <AppBarModal titulo={'¡ Buscar Profesor !'} mostrarModal={() => setOpenModal(false)} titulo_accion="CERRAR" accion={() => setOpenModal(false)} />
                <DialogContent style={{ padding: '0' }} className='scroll'>
                    <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido, identificacion }) => {
                        props.setProfesor({id: id, nombre:  `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, identificacion: identificacion})
                        setOpenModal(false)
                    }} />
                </DialogContent>
                <DialogActions>
                        <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => setOpenModal(false)}>
                            Cancelar
                        </Button>
                </DialogActions>
                </Dialog>
            : ''}
          </div>
    )
}

const ModalChangeStates = function (props) {
    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true)
    const [estadosSig, setEstadosSig] = React.useState('')
    const [checked, setChecked] = React.useState([])
    const dispatch = useDispatch();
    let array = []
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            obtenerEstados()
        }
    })

    const obtenerEstados = async() =>{
        let estados_siguiente = await Funciones.obtenerEstadosProceso(props.row.tipo.id, [{ 'llave': 'principal', 'valor': props.row.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': Helper.ESTADO_INVESTIGACION }]);
        setEstadosSig(estados_siguiente)
        setCargando(false)
    }
   
    const handleToggle = (value) =>{
        const seleccionado = checked.indexOf(value);
        if (seleccionado === -1) array.push(value);
        else array.splice(seleccionado, 1);
        
        setChecked(array)
    }

    const acciones = (data) => {
        const detalle = (
          <Checkbox edge="end" onChange={() => handleToggle(data.secundario.id)} checked={checked.indexOf(data.secundario.id) !== -1} inputProps={{ 'aria-labelledby': data.secundario.id }}/>
          );
        return detalle;
    };

    const sendChangeStatToApi = async() =>{
        if(checked.length == 0){
            alert("Elija una opcion")
        }else{

            props.setCargandoList(true)
            setCargando(true)

            let data = {
                estado: checked[0],
                solicitud: props.row
            }
            let url = "api/v1.0/investigacion/cambiarEstadoAdm"
            const callback = async(error, estado, resp) => {
                let titulo = "Ha ocurrido un error, contacte con el administrador."
                let tipo = "error"

                if(!error){
                    if(estado === 201){
                        tipo = "success"
                        titulo = resp.titulo
                    }
                }

                dispatch(
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                  );
                let filtro =  `estado_actual__codigo=${props.codigoActually}&periodo=${props.periodo}`
                let data = await props.getAllSemilleros(filtro);
                props.setSemilleros(data)
                setCargando(false)
                props.setCargandoList(false)
                props.setOpenModal(false)
            }
            const msg = await Funciones.consulta(url, data, 'POST', callback)
        }
    }

    return(
        <div>
            <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModal} onClose={() => props.setOpenModal(false)}>
                <AppBarModal titulo={'¡ Cambiar Estado !'} mostrarModal={() => props.setOpenModal(false)} titulo_accion="" accion="" />
                <DialogContent>
                {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> :
                    <ListarDatos
                        id="tbl_listar_estados_Adm"
                        //avatar={({ nombre }) => nombre.charAt(0)}
                        datos={estadosSig}
                        opciones={true}
                        buscar={true}
                        acciones={(row) => acciones(row)}
                        titulo={"Lista de Semilleros de Investigación"}
                        fila_principal={({ secundario }) => `${secundario.nombre}`}
                        filas={[]}
                    />
                }
                </DialogContent>
                <DialogActions>
                    <Button disabled={cargando == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={sendChangeStatToApi}>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const ModalChangeFilterPeriodo = (props) => {

    const isInitialMount = React.useRef(true);
    const [periodo_filtro, setPeriodo_filtro] = React.useState([])
    const [periodo_value, setPeriodo_value] = React.useState('')
    const [cargando, setCargando] = React.useState(true)

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const peticion = async () => {
                let data = await obtenerPeriodos()
                if(!(data.find(e => e.periodo == props.periodo_generica))) data.push({'periodo': props.periodo_generica})
                setPeriodo_filtro(data)
                setCargando(false)
            }
            peticion()
        }
    })


    const obtenerPeriodos = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/periodos`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }

    const onChangeFiltro = (event) =>{
        setPeriodo_value(event.target.value);
    }


    const onclickFriltro = (event) => {
        if(periodo_value == ""){
            alert("Seleccione una opcion")
        }else{
            props.setCargando(true)
            props.obtenerEstadoSolicitudes(periodo_value)
            props.setModalFilterCard(false)
        }
    }

    return (
        <Dialog fullWidth={true} open={props.modalFilterCard} onClose={() => props.setModalFilterCard(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => props.setModalFilterCard(false)} titulo_accion="" accion="" />
                <DialogContent className='scroll'>
                    {!cargando ?
                    <Grid container className='margin_cont' >
                        <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Periodos disponibles</InputLabel>
                        <FormControl className='form-control'>
                            <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                            <Select
                            value={periodo_value}
                            onChange={onChangeFiltro}
                            required={true}>
                            {periodo_filtro.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                            </Select>
                        </FormControl>
                        </Grid>
                    </Grid>
                    : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
                        }
                </DialogContent>
                <DialogActions>
                    <Button disabled={cargando == true} variant="contained" color="primary" style={{color: "#fff"}} onClick={() => onclickFriltro()}>
                        Filtrar
                    </Button>
                </DialogActions>
        </Dialog >
    )
}

const ModalChangeFilterPeriodoAll = (props) => {

    const [profesor, setProfesor] = React.useState({nombre: ''})
    const [openModal, setOpenModal] = React.useState(false)

    const handleClickFilter = async () =>{
        if(profesor.nombre == ''){
            alert("Elija un profesor")
        }else{
            props.setCargandoList(true)
            let filtro =  `estado_actual__codigo=${props.codigoActually}&periodo=${props.periodo}&usuario_registro=${profesor.id}`
            let data = await props.getAllSemilleros(filtro);
            props.setSemilleros(data)
            props.setCargandoList(false)
            props.setModalFilterCardAll(false)
        }
    }

    return (
        <>
        <Dialog fullWidth={true} open={props.modalFilterCardAll} onClose={() => props.setModalFilterCardAll(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => props.setModalFilterCardAll(false)} titulo_accion="" accion="" />
                <DialogContent className='scroll'>
                    <Grid container spacing={3} className='margin_cont' >
                        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                        <InputBuscar
                            id='profesor'
                            label='Profesor'
                            placeholder='Clic aquí para buscar'
                            value={profesor.nombre}
                            callback={() => setOpenModal(true)} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <BtnForm texto="FILTRAR" callback={() => handleClickFilter()} />
                </DialogActions>
        </Dialog >
        {openModal == true ? 
            <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={openModal} onClose={() => setOpenModal(false)}>
            <AppBarModal titulo={'¡ Buscar Profesor !'} mostrarModal={() => setOpenModal(false)} titulo_accion="CERRAR" accion={() => setOpenModal(false)} />
            <DialogContent style={{ padding: '0' }} className='scroll'>
                <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => {
                    setProfesor({id: id, nombre:  `${primer_nombre} ${primer_apellido} ${segundo_apellido}`})
                    setOpenModal(false)
                }} />
            </DialogContent>
            <DialogActions>
                    <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => setOpenModal(false)}>
                        Cancelar
                    </Button>
            </DialogActions>
            </Dialog>
        : ''}
        </>
    )
}