//CORE
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//COMPONENTS
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Card,
  CardActions,
  Button,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import { consulta, api } from "../../global/js/funciones";
import { BtnSyllabus, BtnDetalle } from "../general/BotonesAccion";
import AppBarModal from "../general/AppBarModal";
import { BtnForm } from "../general/BotonesAccion";
import ScheduleIcon from "@material-ui/icons/Schedule";
import asignatura1 from "../../global/imagenes/asignatura1.png";
import asignatura2 from "../../global/imagenes/asignatura2.png";
import asignatura3 from "../../global/imagenes/asignatura3.png";
import asignatura4 from "../../global/imagenes/asignatura4.png";
import asignatura5 from "../../global/imagenes/asignatura5.png";
import asignatura6 from "../../global/imagenes/asignatura6.png";
import asignatura7 from "../../global/imagenes/asignatura7.png";
import asignatura8 from "../../global/imagenes/asignatura8.png";
import ListarSyllabus from "./ListarSyllabus";
import { red } from "@material-ui/core/colors";
import ListarDatos from "../general/ListarDatos";

import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";
//UTILS
import useWindowSize from "../general/hooks/useWindowSize";

const ListarHorario = ({
  status,
  toggle,
  data,
  setDataPlan,
  setModalRubrica,
  setIndice,
}) => {
  const [cargando, setCargando] = useState(false);
  const [materias, setMaterias] = useState([]);
  const [plan, setPlan] = useState({
    id: -1,
    asignatura: "",
    competencia_especifica: "",
    perfil_de_docente: "",
    valores: "",
  });
  const [modalPlan, setModalPlan] = useState(false);
  const [horario, setHorario] = useState([]);
  const [dataModal, setDataModal] = useState("");

  const windowSize = useWindowSize();

  const itemData = [
    asignatura1,
    asignatura2,
    asignatura3,
    asignatura4,
    asignatura5,
    asignatura6,
    asignatura7,
    asignatura8,
  ];

  useEffect(() => {
    const newData = [];
    data.forEach((materia) => {
      const index = newData.findIndex((data) => data.grupo === materia[5]);
      if (index !== -1) {
        newData[index].horarios.push({
          dia: materia[6],
          hora_inicio: materia[8],
          hora_fin: materia[9],
        });
      } else {
        newData.push({
          nombre: materia[3],
          codigo: materia[4],
          grupo: materia[5],
          docente: materia[10],
          horarios: [
            { dia: materia[6], hora_inicio: materia[8], hora_fin: materia[9] },
          ],
          programa: materia[11],
          tipo: materia[12],
          creditos: materia[13],
          horas: materia[14],
          prerrequisito: materia[15]
        });
      }
    });
    setHorario(newData);
    console.log(newData)
  }, [data]);

  function eventoVer(data) {
    setDataPlan(data);
    toggle({ modal: "dialogSyllabus", value: true, data: data });
    setDataModal(data);
    setModalPlan(true);
  }

  function eventoVerRubrica(data) {
    setModalRubrica(true);
    setDataPlan(data);
    setIndice(1);
  }

  function eventoVerRetroalimentacion(data) {
    setModalRubrica(true);
    setDataPlan(data);
    setIndice(2);
  }

  return (
    <Dialog
      open={status}
      onClose={() => toggle({ modal: "dialogHorario", value: false })}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <AppBarModal
        titulo="Horario"
        mostrarModal={() => toggle({ modal: "dialogHorario", value: false })}
        titulo_accion=""
        accion=""
      />
      <DialogContent style={{ padding: 0 }} className="scroll">
        <Grid
          container
          alingItems="center"
          justifyContent="center"
          style={{ padding: "10px" }}
        >
          {horario.map((e, i) => {
            return (
              <Card
                item
                xs={12}
                sm={12}
                md={4}
                style={{ margin: "10px 5%", paddingBottom: "10px" }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{
                        backgroundColor: "#5DADE2  ",
                        color: "#ECF0F1 ",
                      }}
                    >
                      {e.nombre.charAt()}
                    </Avatar>
                  }
                  title={e.nombre}
                  subheader={e.grupo}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    Docente: { e.docente ? e.docente.toUpperCase() : 'N/A'}
                  </Typography>
                  {/* <Typography variant="body2" color="text.secondary">
                    Ubicacion: {e.ubicacion}
                  </Typography> */}
                  <Typography variant="body2" color="text.secondary">
                    Codigo: {e.codigo}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Horario:
                    <span>
                      {e.horarios.map((dia) =>
                        dia.dia != null &&
                        dia.hora_inicio != null &&
                        dia.hora_fin != null ? (
                          <p style={{ padding: 0, margin: "0 0 0 10px" }}>
                            {dia.dia} | {dia.hora_inicio} - {dia.hora_fin}
                          </p>
                        ) : (
                          " N/A"
                        )
                      )}
                    </span>
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button
                    className="btnListarHorario"
                    style={{ backgroundColor: "#c79e32", color: "#fff" }}
                    texto="Syllabus"
                    onClick={() => eventoVer(horario[i])}
                  >
                    Syllabus
                  </Button>
                  <Button
                    className="btnListarHorario"
                    style={{ backgroundColor: "#c79e32", color: "#fff" }}
                    texto="Rubrica"
                    onClick={() => eventoVerRubrica(horario[i])}
                  >
                    Rubríca
                  </Button>
                  <Button
                    className="btnListarHorario"
                    style={{ backgroundColor: "#c79e32", color: "#fff" }}
                    texto="Retroalimentacion"
                    onClick={() => eventoVerRetroalimentacion(horario[i])}
                  >
                    Retroalimentación
                  </Button>
                </CardActions> */}
              </Card>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <BtnForm
          texto="Cerrar"
          callback={() => toggle({ modal: "dialogHorario", value: false })}
        />
      </DialogActions>
    </Dialog>
  );
};

ListarHorario.protoTypes = {
  status: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ListarHorario;
