import TareasTerminadas from "./../../general/TareasTerminadas";
import { consulta, generarFiltros} from '../../../global/js/funciones';
import emma_w from './../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import React, { Component, forwardRef } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl,Checkbox,ListItemText, IconButton, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListarTabla from '../../general/ListarTabla';
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal';




class PracticaInformeSeguimiento extends Component {

  constructor(props) {
    super(props)
    this.state = {
       columnas: [],
       data_informe: [],
       cargando: false,
       mostrar: 'filtros',
       maxWidth:'sm',
       cargar: true,
       periodo: '',
       periodos: [],

       

      };
    }

   async componentDidMount() {
      let { mostrar } = this.state;
      let { modalInforme, informe } = this.props;
      if (modalInforme) {
         this.setState({ cargando: true })
         const periodos = await this.obtenerPeriodos();
         this.setState({ cargando: false,periodos })
      }  
    }

  async componentDidUpdate({ modalInforme }, { mostrar }) {
      let { mostrar: mostrarNew, cargar } = this.state;
      let { modalInforme: modalInformeNew, informe } = this.props;
      if (modalInforme !== modalInformeNew) {
         if (modalInformeNew) {
            this.setState({ cargando: true })
            const periodos = await this.obtenerPeriodos();
            this.setState({ cargando: false,periodos })
         }
         else this.resetState()
      }
   }

   
   onChange = ({ target }) => {
      let { value, name } = target
      this.setState({ [name]: value })
   }

    resetState = () => {
      let { mostrar } = this.state
      if(mostrar === 'informe') this.setState({ mostrar:'filtros', maxWidth: 'sm', columnas: [], cargando: false })
      else if(mostrar==='filtros') this.setState({ data_informe: [], columnas: [], mostrar: 'filtros', maxWidth: 'sm', periodo: '', cargando: false })
     
   }
   obtenerPeriodos = () => {
      return new Promise(resolve => {
         consulta(`api/v1.0/practicas/periodos`, null, null,
            (error, estado, resp) => {
               resolve(resp)
            }
         );
      })
   }
    pintar = () => {
      let { cargando, mostrar } = this.state;
      if (cargando) return <TareasTerminadas mensaje='Cargando..'  marginTop='7%' imagen={emma_w} widthImg="7%" />;
      return (
         <div>
          
            {mostrar === 'filtros' && this.pintarFiltros()}
            {mostrar === 'informe' && this.pintarInforme()}
          
         </div>
      )
   }
//    onSubmit = (e) => {
//     this.obtenerInforme()
//     e.preventDefault();
//  }

  obtenerInforme = async (filtros=[]) => {
    let { actualizarMensaje } = this.props
    let f = await generarFiltros(filtros);
    this.setState({ cargando: true });
    consulta(`api/v1.0/practicas/informe?${f}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) this.setState({ cargando: false, data_informe: resp, maxWidth: 'lg',mostrar: 'informe' 
       });
        else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', tiempo: 6000 });
      }
    );

 }
 onSubmit = (e) => {
   let { periodo, programa, modalidad, trabaja, busca_empresa, programasMulti, periodosMulti, modalidadesMulti, contratosMulti } = this.state
   let { actualizarMensaje, informe } = this.props
   let filtros = [], titulo='';
  

      if(periodo !== ''){
         filtros.push({ 'llave': 'informe', 'valor': informe.codigo }); 
         filtros.push({ 'llave': 'periodo', 'valor': periodo });
      }else titulo = 'Por favor, seleccione el periodo.'
   if(titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
   else this.obtenerInforme(filtros)
   console.log(filtros)
   e.preventDefault();
}

pintarFiltros = () => {
   let { informe } = this.props
   let { periodo, periodos } = this.state
   console.log(informe)

     
      return(
         <Grid container spacing={3} className='margin_cont'>
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
               <FormControl className='form-control'>
                  <InputLabel htmlFor="periodo">Seleccione Periodo</InputLabel>
                  <Select
                     inputProps={{
                        name: 'periodo',
                        id: 'periodo',
                     }}
                     value={periodo}
                     onChange={this.onChange}
                     required={true}>
                     {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                  </Select>
               </FormControl>
            </Grid>
            </Grid>
            )
         }

    pintarInforme = () => {
    let { data_informe, columnas, } = this.state
    let { informe } = this.props
    let ordenar = false;
    

    if(data_informe.length>0){
      if(columnas.length===0){
        
        let archivo = data_informe.map((consulta) => consulta.archivoEmpresa);

        columnas = [
         {title: "Periodo de la practica",field: "periodopractica",orden: 1,},
          {title: "Cedula del estudiante",field: "cedulaestudiante",orden: 2,},
          {title: "Nombre del estudiante",field: "concatnombreestudiante",orden: 3,},
          {title: "Programa del estudiante",field: "programaestudiante",orden: 4,},
          {title: "Empresa",field: "empresanombre",orden: 6,},
          {title: "Sucursal",field: "sucursalnombre",orden: 7,},
          {title: "Coordinador",field: "coordinadorenviohv",orden: 8,},
          {title: "Fecha enviado",field: "fechaenviado",orden: 9,},
          {title: "Estado seguimiento",field: "estadoseguimiento",orden: 10,},
          {title: "¿Contratado?",field: "contrado",orden: 11,},
          {title: "Fecha finalizado",field: "fechafinalizado",orden: 12,},


          ];
         
         //    archivo.map((archivo) => {
         //       if(archivo!=''){
         //      columnas.push({title: "Archivo Adjunto", field: "archivocontEmpresa", orden: 4 })
            
         //    }else{

         //     columnas.push({title: "Archivo Adjunto", field: "sinarchivoEmpresa", orden: 4 })


         //    }
         // }
         //    )
          

          




      }
      const titulo_informe=informe.nombre
      
      return (
        <ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe}  orden={ordenar} />
     )

    }else{
      return(
         <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
      )
   }



}
 
     render() {
      let { cargando, maxWidth, mostrar } = this.state;
      let { modalInforme, mostrarModalInforme, informe } = this.props

      return (
        <Dialog fullWidth={true} maxWidth={maxWidth} PaperProps={ (informe.codigo === 'Inf_Pro') ? {style: {overflowY: 'visible'}} : {} } open={modalInforme} onClose={() => mostrarModalInforme(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={informe.nombre} mostrarModal={() => mostrarModalInforme(false)} />
        <DialogContent className='scroll' style={mostrar === 'informe' ? { padding: 0 } : ((informe.codigo === 'Inf_Pro') ? { overflowY: 'visible' }:{})}>
           {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' cargando={cargando} imagen={emma_w} widthImg="7%" />}
        </DialogContent>
        <DialogActions>
           <BtnForm texto="Cerrar" callback={() => mostrarModalInforme(false)} />
           {!cargando && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
           {/* {!cargando && <BtnForm texto="GENERAR" callback={this.onSubmit} />} */}
           
        </DialogActions>
     </Dialog>
      );
    }
  }
  export default PracticaInformeSeguimiento