import {
  MODAL_ADD_VOTANTE,
  MODAL_DEL_VOTANTE,
  ACT_DATA_VOTANTES,
  ACT_DATA_VOTANTE,
  ACT_RENDER_VOTANTES,
} from "../actions/actVotantes";


const initialState = {
  modalAddVotantes: false,
  modalDelVotantes: false,
  votantes: [],
  renderVotantes: false,
  votante: {
    id: 0,
    nombre: "",
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_VOTANTE:
      return Object.assign({}, state, {
        modalAddVotantes: action.show
      })
    case MODAL_DEL_VOTANTE:
      return Object.assign({}, state, {
        modalDelVotantes: action.show
      })
    case ACT_DATA_VOTANTES:
      return Object.assign({}, state, {
        votantes: action.votantes
      })
    case ACT_DATA_VOTANTE:
      return Object.assign({}, state, {
        votante: action.votante
      })
    case ACT_RENDER_VOTANTES:
      return Object.assign({}, state, {
        renderVotantes: action.render
      })
    default:
      return state;
  }
}

export default reducer;
