import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BuscarSelect from "../../general/BuscarSelect";
import TextField from '@material-ui/core/TextField';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from "../../general/AppBarModal";
import TareasTerminadas from "../../general/TareasTerminadas";
import { BtnForm } from "../../general/BotonesAccion";
import emma_w from './../../../global/imagenes/emma_w.png';

import { consulta, generarFiltros } from "../../../global/js/funciones";


function MensajeValidacionId({ modal, mostrarmodal, mensaje, cargando }) {
    return (
        <Dialog open={modal} maxWidth="sm" fullWidth={true} onClose={() => mostrarmodal(false)} >
        <AppBarModal titulo='¡ Mensaje !' mostrarModal={mostrarmodal} titulo_accion='' />
        <DialogContent className='scroll' >
            {cargando ?
            <TareasTerminadas mensaje="Espere un momento. Estamos validando su numero de identificacion" marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/> :
            <Typography variant="body1" color="textSecondary" component="p" align="justify">{mensaje}</Typography>
            }
        </DialogContent>
        <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarmodal(false)} />
        </DialogActions>
        </Dialog>
    );
}
export default class InscripcionAgregarMisDatos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cargando: true,
            mensaje: '',
            datos: {},
        }
        this.fileInput = React.createRef();
    }

    validar_id = async ({ target }) => {
        let { actualizarMensaje,  mostrarModalMensajeValidacionId, setExisteBD } = this.props.props_c
        let identificacion = target.value
        if (identificacion) {
            this.setState({ mensaje: '', cargando: true, datos: {} });
            mostrarModalMensajeValidacionId(true)
            let f = await generarFiltros([{ 'llave': 'identificacion', 'valor': identificacion }]);
            consulta(`api/v1.0/matriculas/validar_id?${f}`, null, null, (error, estado, resp) => {
                let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
                if (!error) {
                    if (estado === 200) {
                        if (resp.length > 0) {
                            // setMostrarBtn(true)
                            this.setState({ mensaje: `¡Ya estás registrado en la plataforma y no es posible tomar tu solicitud. Por favor comunicate con: tecnologia@cuc.edu.co!`, cargando: false});
                            setExisteBD(true)
                        } else {
                            // setMostrarBtn(true);
                            this.setState({ mensaje: `Tu identificación es válida para el registro. Puedes continuar.`, cargando: false });
                            setExisteBD(false)
                        }
                    }
                } else {
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                }
            }, false)
        }
    }

    validar_email = async ({ target }) => {
        let { actualizarMensaje,  mostrarModalMensajeValidacionId, setExisteBD_Correo } = this.props.props_c
        let correo_electronico = target.value
        if (correo_electronico) {
            this.setState({ mensaje: '', cargando: true, datos: {} });
            mostrarModalMensajeValidacionId(true)
            let f = await generarFiltros([{ 'llave': 'correo', 'valor': correo_electronico }]);
            consulta(`api/v1.0/matriculas/validar_correo?${f}`, null, null, (error, estado, resp) => {
                let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
                if (!error) {
                    if (estado === 200) {
                        if (resp.length > 0) {
                            // setMostrarBtn(true)
                            this.setState({ mensaje: `¡El correo ya está registrado en nuestro sistema y no es posible tomar tu solicitud con este correo!`, cargando: false});
                            setExisteBD_Correo(true)
                        } else {
                            this.setState({cargando: false})
                            mostrarModalMensajeValidacionId(false)
                            // setMostrarBtn(true);
                            setExisteBD_Correo(false)
                        }
                    }
                } else {
                    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
                }
            }, false)
        }
    }

    render() {
        let {setTipo_identificacion, tipos_identificacion, tipo_identificacion, identificacion, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, fecha_nacimiento, ciudad_nacimiento, ciudad_residencia, direccion_residencia, celular, telefono, correo_electronico, autorizo, onChange, modalMensajeValidacionId, mostrarModalMensajeValidacionId, ChangeState, setFecha_nacimiento, setEps, epss, eps} = this.props.props_c;
        let { mensaje, cargando } = this.state
        return (
            <React.Fragment>
                <MensajeValidacionId modal={modalMensajeValidacionId} mostrarmodal={mostrarModalMensajeValidacionId} mensaje={mensaje} cargando={cargando} />
                <Typography variant="h6" gutterBottom> Datos Personales </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <BuscarSelect datos={tipos_identificacion} cambiarEstado={(value) => setTipo_identificacion(value)} valor={tipo_identificacion} id='select-tipo-identificacion' nombre='¿Cuál es tu tipo de identificación?' placeholder='Seleccione Tipo' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField value={identificacion} required type="number" id="identificacion" name="identificacion" label="¿Cuál es tu número de identificación?" fullWidth autoComplete="fname" onChange={onChange} onBlur={this.validar_id}/>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <TextField value={primer_nombre} required id="primer_nombre" name="primer_nombre" label="¿Cuál es tu primer nombre?" fullWidth autoComplete="fname" onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField value={segundo_nombre} id="segundo_nombre" name="segundo_nombre" label="¿Cuál es tu segundo nombre?" fullWidth autoComplete="fname" onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField value={primer_apellido} required id="primer_apellido" name="primer_apellido" label="¿Cuál es tu primer apellido?" fullWidth autoComplete="lname" onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField value={segundo_apellido} id="segundo_apellido" name="segundo_apellido" label="¿Cuál es tu segundo apellido?" fullWidth autoComplete="lname" onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl className='form-control'>
                            <TextField id="date" label="Fecha de Nacimiento" type="date" InputLabelProps={{ shrink: true, }} value={fecha_nacimiento} onChange={(e) => setFecha_nacimiento(e.target.value)}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <BuscarSelect datos={epss} cambiarEstado={(value) => setEps(value)} valor={eps} id='eps' name='eps' nombre='¿Cuál es tu EPS?' placeholder='Seleccione Eps' />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField value={ciudad_nacimiento} id="ciudad_nacimiento" name="ciudad_nacimiento" label="¿Cuál es tu ciudad de nacimiento?" fullWidth onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField value={direccion_residencia} required id="direccion_residencia" name="direccion_residencia" label="¿Cuál es tu dirección de residencia?" fullWidth onChange={onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField value={ciudad_residencia} id="ciudad_residencia" name="ciudad_residencia" label="¿Cuál es tu ciudad de residencia?" fullWidth onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField value={telefono} required type="number" id="telefono" name="telefono" label="¿Como es tu número telefonico?" fullWidth autoComplete="lname" onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField value={celular} required type="number" id="celular" name="celular" label="¿Como es tu número celular?" fullWidth autoComplete="lname" onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField value={correo_electronico} required type="email" id="correo_electronico" name="correo_electronico" label="Correo Personal" fullWidth autoComplete="billing address-line1" onChange={onChange} onBlur={this.validar_email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <InputLabel>Autorizo</InputLabel>
                        <Select name='autorizo' value={autorizo} required onChange={onChange} >
                            <MenuItem key={1} value={1}> SI </MenuItem>
                            <MenuItem key={2} value={2}> NO </MenuItem>
                        </Select>
                        <FormHelperText>
                            Autorizo de manera expresa e inequívoca que mis datos personales
                            sean usados por la Corporación Universidad de la Costa “CUC”, dentro
                            de los términos previsto por la ley 1581 de 2012 y el decreto 1377
                            de 2013; para efectos que la Universidad considere pertinentes.
                        </FormHelperText>
                    </FormControl>
                </Grid>

                </Grid>
            </React.Fragment>
        );
    }
}
