//Importaciones necesaria para poder utilizar react
import React, { useState }  from 'react'

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'

import AppBarModal from '../../general/AppBarModal'
import BuscarPersona from '../../general/BuscarPersona';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
const useStyles = makeStyles((theme) => ({
  root: {
      [theme.breakpoints.up('xs')]: {
          minWidth: "400px"
        },
    },
    flexGrow: {
        flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    noneScroll:{
      overflowY: 'hidden',
      [theme.breakpoints.up('sm')]: {
        minWidth: "600px"
      },
  }
}));

export default function SemilleroAddParti(props) {
    
    const [objRol, setObjRol] = React.useState({});
    const [openModalRol, setOpenModalRol] = React.useState(false)
    const [openModalRolTec, setOpenModalRolTec] = React.useState(false)
    const [information, setInformation] = React.useState({})
    const [modalidadSelect, setModalidadSelect] = React.useState('')
    const [rolSelect, setRolSelect] = React.useState('')
    //Errores
    const [errores, setErrores] = React.useState([]);
    var obj = {}
    //Recuerda meter dentro de un Array la informacion de los responsables. Metes la info de id y nombre en un objeto y luego de que el otro modal se cierra con el click le seteas
    //El rol y se lo insertas a responsables y cierras los 2 modales. Si la persona no asigno el rol no lo agregues y muestra el debido mensaje.
    
    const getError = (llave, errores) => {
      let existe = errores.find(e => e.llave === llave);
      return existe ? existe.mensaje : '';
    }

    const validateInputs = (campos = []) => {
      return new Promise((resolve) => {
      let errores = [];
      let date_now = new Date()

      function isObjEmpty(obj) {

          if(typeof obj === 'object'){
              for (var prop in obj) {
                  if (obj.hasOwnProperty(prop)) return false;
                }
              
                return true;
          }else if(typeof obj === 'string'){
              return true
          }else{
              return false;
          }
          
      }

      campos.map(({ value, name, err, value2 }) => {
          
          if (!err.obj && err.empty && value.replace(/\s+/g, '') == "") errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` });
          if (!err.obj && err.empty == false && value.length == 0) errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
          //Validacion exclusiva para buscar Select.            
          if (err.empty && isObjEmpty(value) && err.obj){
              errores.push({ llave: name, 'mensaje': `Este campo es obligatorio` })
          } 
          /*if (err.numeric && isNaN(value)) errores.push({ llave: name, 'mensaje': `Este campo debe ser un numero` });
          if (err.email && !validarCorreo(value)) errores.push({ llave: name, 'mensaje': `Correo invalido` });
          if (err.before_today && value >= date_now) errores.push({ llave: name, 'mensaje': `Fecha futura invalida` });
          if (err.after_today && value <= date_now) errores.push({ llave: name, 'mensaje': `Fecha pasada invalida` });
          if (err.finish_date && value <= value2) errores.push({ llave: name, 'mensaje': `Fecha invalida` });
          if (err.range && (value < 1 || value > value2)) errores.push({ llave: name, 'mensaje': `El valor está fuera del rango permitido` });
          if (err.not_null && value == null) errores.push({ llave:name, 'mensaje': `Este campo es obligatorio` });
          if (err.not_decimal && value % 1 != 0) errores.push({ llave: name, 'mensaje': `El valor no puede ser decimal` });*/
      });
      
      resolve(errores);
      });
    }

    const onClickChange = (informacion) =>{
        let objFinal={}
        if(props.modalidadParticipacion == true){
          objFinal = {
            id: information.id,
            nombre: information.nombre,
            correo: information.correo,
            identificacion: information.identificacion,
            nuevo: true,
            modalidadParticipacion: modalidadSelect,
            rolDeParticipante: null,
          }
          setOpenModalRol(false)
        }else if(props.rol == true){
          objFinal = {
            id: information.id,
            nombre: information.nombre,
            correo: information.correo,
            identificacion: information.identificacion,
            nuevo: true,
            rolDeParticipante: rolSelect,
            modalidadParticipacion: null,
          }
          setOpenModalRolTec(false)
        }else{
          objFinal = {
            id: informacion.id,
            nombre: informacion.nombre,
            correo: informacion.correo,
            identificacion: informacion.identificacion,
            modalidadParticipacion: null,
            rolDeParticipante: null,
            nuevo: true,
          }
        }

        if(props.participantes.length == 0){
          props.arrai.push(objFinal)
          console.log(props.arrai)
          console.log("Entro")
          props.setParticipantes(props.arrai)
          props.setopenModalAddParti(false)
        }else{
          let sw = false
          props.participantes.map((item, i) =>{
            if(item.id == objFinal.id){
              sw = true
              return
            }
          })
          if(sw){
            alert("Ya se agrego a esa persona")
          }else{
            props.arrai.push(objFinal)
            props.setParticipantes(props.arrai)
            props.setopenModalAddParti(false)
          }
        }
    }

    const validateModalidadSelect = async ()=>{
      let array = await validateInputs([
        { value: modalidadSelect, name: 'rolSelect', err: { empty: true, obj: true } },
      ])
      setErrores(array)
      if(array.length > 0){
        
      }else{
        onClickChange()
      }
    }

    const validateRolSelect = async ()=>{
      let array = await validateInputs([
        { value: rolSelect, name: 'rolSelect', err: { empty: true, obj: true } },
      ])
      setErrores(array)
      if(array.length > 0){
        
      }else{
        onClickChange()
      }
    }

    return (
      <div>
        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalAddParti} onClose={() => props.setopenModalAddParti(false)}>
          <AppBarModal titulo={'¡ Buscar Participante !'} mostrarModal={() => props.setopenModalAddParti(false)} titulo_accion="" accion="" />
          <DialogContent>
            < BuscarPersona callback={(data) => {
                if(props.modalidadParticipacion == true){
                  let information = {
                    id: data.id, 
                    nombre: `${data.primer_nombre} ${data.primer_apellido} ${data.segundo_apellido}`, 
                    correo: `${data.correo}`, 
                    identificacion: `${data.identificacion}`,
                    nuevo: true,
                  }
                  setInformation(information)
                  setOpenModalRol(true)
              }else if(props.rol == true){
                  let information = {
                    id: data.id, 
                    nombre: `${data.primer_nombre} ${data.primer_apellido} ${data.segundo_apellido}`, 
                    correo: `${data.correo}`, 
                    identificacion: `${data.identificacion}`,
                    nuevo: true,
                  }
                  setInformation(information)
                  setOpenModalRolTec(true)
              }else{
                let informacion = {
                  id: data.id, 
                  nombre: `${data.primer_nombre} ${data.primer_apellido} ${data.segundo_apellido}`, 
                  correo: `${data.correo}`, 
                  identificacion: `${data.identificacion}`,
                  nuevo: true,
                }
                onClickChange(informacion)
              }
            }} />
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>

        {props.modalidadParticipacion == true ? 
        <GetModalidadParticipacion 
          openModalAddParti={openModalRol}
          setopenModalAddParti={setOpenModalRol}

          modalidadSelect={modalidadSelect}
          setModalidadSelect={setModalidadSelect}

          tiposModalidadParticipacion={props.tiposModalidadParticipacion}

          onClickChange={onClickChange}

          //Errores
          getError={getError}
          errores={errores}
          setErrores={setErrores}

          //Validar modalidad
          validateModalidadSelect={validateModalidadSelect}
          
        /> : ''}

        {props.rol == true ? 
        <GetRol 
          openModalAddParti={openModalRolTec}
          setopenModalAddParti={setOpenModalRolTec}

          rolSelect={rolSelect}
          setRolSelect={setRolSelect}

          tiposDeRoles={props.tiposModalidadParticipacion}

          onClickChange={onClickChange}

          //Errores
          getError={getError}
          errores={errores}
          setErrores={setErrores}

          //Validar modalidad
          validateModalidadSelect={validateRolSelect}
        /> : ''}
      </div>
    )
}

const GetModalidadParticipacion = (props) =>{
  const classes = useStyles();
  const handleChangeModalidad = (event) => {
    props.setModalidadSelect(event.target.value);
    if(props.errores.length > 0){
      props.setErrores(props.errores.filter(er => er.llave != "rolSelect"));
      // Elimina error cuando se escribe en el campo
    }
  };

  return(
    <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalAddParti} onClose={() => props.setopenModalAddParti(false)}>
      <AppBarModal titulo={'¡ Buscar Participante !'} mostrarModal={() => props.setopenModalAddParti(false)} titulo_accion="" accion="" />
      <DialogContent>
        <InputLabel htmlFor="age-native-simple">Modalidad Participacion</InputLabel>
          <FormControl className={classes.formControl} fullWidth error = { props.getError('rolSelect', props.errores).length > 0}>
              <Select
              value={props.modalidadSelect}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={handleChangeModalidad}
              >
              <MenuItem value="">
                  <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposModalidadParticipacion.length} Roles Disponibles` }</em>
              </MenuItem>
              {props.tiposModalidadParticipacion.map((item, index) => (
              <MenuItem key= { index } value = { item }>{ item.nombre }</MenuItem>
                  ))}
              </Select>

          </FormControl>
          <FormHelperText>{ props.getError('rolSelect', props.errores) }</FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => props.validateModalidadSelect({})}>
            Asignar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const GetRol = (props) =>{
  const classes = useStyles();
  const handleChangeRol = (event) => {
    props.setRolSelect(event.target.value);
    if(props.errores.length > 0){
      props.setErrores(props.errores.filter(er => er.llave != "rolSelect"));
      // Elimina error cuando se escribe en el campo
    }
  };

  return(
    <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalAddParti} onClose={() => props.setopenModalAddParti(false)}>
      <AppBarModal titulo={'¡ Buscar Participante !'} mostrarModal={() => props.setopenModalAddParti(false)} titulo_accion="" accion="" />
      <DialogContent>
        <InputLabel htmlFor="age-native-simple">Rol: </InputLabel>
          <FormControl className={classes.formControl} fullWidth error = { props.getError('rolSelect', props.errores).length > 0}>
              <Select
              value={props.rolSelect}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={handleChangeRol}
              >
              <MenuItem value="">
                  <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${props.tiposDeRoles.length} Roles Disponibles` }</em>
              </MenuItem>
              {props.tiposDeRoles.map((item, index) => (
              <MenuItem key= { index } value = { item }>{ item.nombre }</MenuItem>
                  ))}
              </Select>

          </FormControl>
          <FormHelperText>{ props.getError('rolSelect', props.errores) }</FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={() => props.validateModalidadSelect({})}>
            Asignar
        </Button>
      </DialogActions>
    </Dialog>
  )
}