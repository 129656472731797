
import React, { Component } from 'react';
import { connect } from "react-redux";

import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import { mostrarModalDetalleOva } from "../../redux/actions/actOva";
import {consulta, obtenerValoresFiltros} from "../../global/js/funciones";
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';
import ListarDatos from "../general/ListarDatos";
import moment from 'moment'
import DetalleOva from './detalleOva';
import AppBarModal from '../general/AppBarModal'
import {Grid, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog, IconButton, Box } from '@material-ui/core'
import {BtnForm, BtnDetalle} from "../general/BotonesAccion";
import { Document, Image, Page, PDFDownloadLink, Text, View } from '@react-pdf/renderer';
import { Tooltip } from 'react-bootstrap';
import GetAppIcon from '@material-ui/icons/GetApp';
import LogoCuc from '../../global/imagenes/logo_cuc2.png';



class Ova extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      procesosInfo:[],
      proceso:null,
      periodos: [],
      filtrarPeriodo: false,
      cargarFiltroP: true,
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/ova', nombre: 'OVA' })
    await this.obtenerDatosIniciales()
    this.setState({cargando: false});
    
  }

  async componentDidUpdate({}, { seleccion }) {
    
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_ova' }, { 'llave': 'estado', 'valor': '1' }]);
    let filtros = periodo.length > 0 ? [{ 'llave': 'estado_actual__valorb', 'valor': 'si' }, { 'llave': 'periodo', 'valor': periodo[0].nombre }] : []
    let procesosInfo = await this.listarOva(periodo[0].nombre);
    this.setState({procesosInfo, periodo: periodo.length > 0 ? periodo[0].nombre : '', filtros, periodo_filtro: periodo.length > 0 ? periodo[0].nombre : ''});
  }
  async listarOva(periodo) {
    return new Promise(resolve => {
       consulta(`api/v1.0/ova/listar/${periodo}`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
        consulta(`api/v1.0/ova/periodos`, null, null,
            (error, estado, resp) => {
                resolve(resp)
            }
        );
    })
  }

  async actualizarDataOva() {
    let { periodo_filtro } = this.state
    this.setState({ cargando: true })
    let procesosInfo = await this.listarOva(periodo_filtro)
    this.setState({procesosInfo, cargando: false});
  }

  async obtenerDatosFiltro(filtro) {
    let procesosInfo = await this.listarOva(filtro);
    this.setState({procesosInfo, cargando: false});
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }


  filtrarEstadoSolicitudes = () => {
    let { periodo_filtro } = this.state
    this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
    this.obtenerDatosFiltro(periodo_filtro)
  }


  ModalfiltroPeriodo = async () => {
    let { cargarFiltroP, periodo } = this.state
    this.cambiarEstado({ filtrarPeriodo: true })
    if (cargarFiltroP) {
      this.cambiarEstado({ cargandoPeriodo: true })
      let periodos = await this.obtenerPeriodos();
      if(!(periodos.find(e => e.periodo == periodo))) periodos.push({'periodo': periodo})
      this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
    }
  }


    onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  generarPDF(data) {
    return (
      <Document>
        <Page size="A4">
          <View style={{
            border: '1.1px solid #DFDDDD',
            width: '100%',
            height: '100%',
            paddingBottom: '60px',
            paddingLeft: '25px',
            paddingRight: '25px'
          }}>
            <Text style={{
              fontSize: '14px',
              textAlign: 'center',
              margin: '20px 0 20px 0',
            }}>
                RESULTADOS TEST DE HABILIDADES
            </Text>
            <View style={{
              width: '100%',
              display: 'flex',
              margin: '20px 0 20px 0',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Image style={{
                  objectFit: 'cover',
                  width: '250px'
              }}  src={LogoCuc} alt="images" />
            </View>
            {/* TABLA DE INFORMACION PERSONAL */}
            <View style={{
              border: '1.1px solid #DFDDDD',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              marginTop:'20px'
            }}>
              {/* VALORES INFORMACION PERSONAL */}
              <View style={{
                padding: '10px 0px 10px 0px',
                width: '100%',
                backgroundColor: 'rgb(234, 239, 239)',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}>
                <Text style={{
                  fontSize: '7px',
                  textAlign: 'center'
                }}>
                    INFORMACIÓN PERSONAL
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD'
              }}>
                <Text style={{fontSize: '7px'}}>
                  NOMBRE
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.nombre}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  DOCUMENTO
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.cedula}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  CORREO
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.correo}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  EDAD - GENERO
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.edad} - {data.sexo}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  TELEFONOS
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.telefono1} - {data.telefono2}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  VIDA UNIVERSITARIA
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.iniciar_Estudios}
                </Text>
              </View>

              
            </View>

            {/* TABLA DE RESULTADOS */}
            <View style={{
              border: '1.1px solid #DFDDDD',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              marginTop:'20px'
            }}>
              <View style={{
                padding: '10px 0px 10px 0px',
                width: '100%',
                backgroundColor: 'rgb(234, 239, 239)',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}>
                <Text style={{
                  fontSize: '7px',
                  textAlign: 'center'
                }}>
                    RESULTADOS CONSOLIDADO DE ACUERDO A CADA TIPO DE INTELIGENCIA
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  LINGUISTICA
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                {data.question_1_01}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  LÓGICO-MATEMÁTICA
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                {data.question_2_01}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  MUSICAL
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.question_3_01}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  CORPORAL - KINESTÉSICA
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.question_4_01}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  INTRAPERSONAL
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.question_5_01}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  INTERPERSONAL
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.question_6_01}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  NATURALISTA-PICTÓRICA
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.question_7_01}
                </Text>
              </View>

              <View style={{
                padding: '2px',
                width: '30%',
                borderRight: '1.1px solid #DFDDDD',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  ESPACIAL
                </Text>
              </View>
              <View style={{
                padding: '2px',
                width: '70%',
                borderTop: '1.1px solid #DFDDDD',
              }}>
                <Text style={{fontSize: '7px'}}>
                  {data.question_8_01}
                </Text>
              </View>


            </View>
            
            <View style={{
              border: '1.1px solid #DFDDDD',
              borderRadius: '10px',
              backgroundColor: 'rgb(234, 239, 239)',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: '20px',
              flexWrap: 'wrap',
            }}>
              <View style={{
                width: '100%',
              }}>
                <Text style={{
                  fontSize: '7px',
                  padding: '10px 0px 10px 0px',
                  textAlign: 'center'
                }}>
                    AMBIENTE DONDE LE GUSTARIA TRABAJAR
                </Text>
              </View>
              <View style={{
                width: '100%',
              }}>
                <Text style={{
                  fontSize: '7px',
                  padding: '2px',
                  
                  textAlign: 'center'
                }}>
                    Aire libre
                </Text>
              </View>
            </View>


            <View style={{
              border: '1.1px solid #DFDDDD',
              borderRadius: '10px',
              backgroundColor: 'rgb(234, 239, 239)',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: '20px',
              flexWrap: 'wrap',
            }}>
              <View style={{
                width: '100%',
              }}>
                <Text style={{
                  fontSize: '7px',
                  padding: '10px 0px 10px 0px',
                  textAlign: 'center'
                }}>
                    CARRERAS SEGERIDAS DE ACUERDO A LOS TIPOS DE INTELIGENCIA PREDOMINANTE
                </Text>
              </View>
              <View style={{
                width: '100%',
              }}>
                <Text style={{
                  fontSize: '7px',
                  padding: '2px',
                  
                  textAlign: 'center'
                }}>
                    Ingeniería Civil, Ingeniería Naval, Ingeniería Industrial, Ingeniería de Minas, ingeniería mecánica, ingeniería espacial,
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  pintarDatos(){
    let {filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo, procesosInfo } = this.state;
    let {mostrarModalDetalleOva} = this.props
    const acciones = (data) => { 
      const onClickDetalle = proceso => {
        this.setState({ proceso })
        mostrarModalDetalleOva(true)
      }
      console.log(data)
      let detalle = <Box style={{display:'flex', justifyContent: 'space-evenly'}}>
        <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />
        <PDFDownloadLink style={{ textDecoration: 'none' }} document={this.generarPDF(data)} fileName={`Resultados_OVA.pdf`}>
          {
            () => (
            <Tooltip title='Descargar PDF' aria-label="add">
              <IconButton edge="end" color="secondary">
                <GetAppIcon />
              </IconButton>
            </Tooltip>
            )
          } 
        </PDFDownloadLink>
        </Box>;
      return <React.Fragment>{detalle}</React.Fragment>; 
    }

    return(
      <>
        <Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({filtrarPeriodo : false})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
           <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({filtrarPeriodo : false})} titulo_accion="" accion="" />
          <DialogContent className='scroll'>
            {!cargandoPeriodo ?
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
                    <Select
                      inputProps={{
                        name: 'periodo_filtro',
                        id: 'periodo_filtro',
                      }}
                      value={periodo_filtro}
                      onChange={this.onChange}
                      required={true}>
                      {periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              : <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({filtrarPeriodo : false})} />
            <BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
          </DialogActions> 
        </Dialog >

        <ListarDatos
          datos={procesosInfo}
          titulo='Lista Aplicacion OVA'
          id='tbl_ova_listar'
          opciones={true} 
          descargar='OVA'
          refrescar={true}
          filtrar={true}
          actfiltrar={() => this.ModalfiltroPeriodo()}
          actRefrescar={() => this.actualizarDataOva()}
          buscar={true}
          avatar={({ nombre }) => nombre.toUpperCase().charAt(0)}
          acciones={(data) => acciones(data)}
          fila_principal={({ nombre } ) => nombre.toUpperCase()}
          filas={[
            { 'id': 'NOMBRE', 'mostrar': ({nombre}) => `${nombre}`,  'enLista': true},
            { 'id': 'CEDULA', 'mostrar': ({cedula}) => `${cedula}`,  'enLista': true},
            { 'id': 'CORREO', 'mostrar': ({correo}) => `${correo}`,  'enLista': true},
            { 'id': 'EDAD', 'mostrar': ({edad}) => `${edad}`,  'enLista': false},
            { 'id': 'SEXO', 'mostrar': ({sexo}) => `${sexo}`,  'enLista': false},
            { 'id': 'TELEFONO1', 'mostrar': ({telefono1}) => `${telefono1}`,  'enLista': false},
            { 'id': 'TELEFONO2', 'mostrar': ({telefono2}) => `${telefono2}`,  'enLista': false},
            { 'id': 'COLEGIO', 'mostrar': ({colegio}) => `${colegio}`,  'enLista': false},
            { 'id': 'TIPO DE COLEGIO', 'mostrar': ({tipo_Colegio}) => `${tipo_Colegio}`,  'enLista': false},
            { 'id': 'TIPO DE EMPRESA', 'mostrar': ({tipo_Empresa}) => `${tipo_Empresa}`,  'enLista': false},
            { 'id': 'PROFESION', 'mostrar': ({ejercer_Profesion}) => `${ejercer_Profesion}`,  'enLista': false},
            { 'id': 'ACTIVIDADES', 'mostrar': ({actividades}) => `${actividades}`,  'enLista': false},
            { 'id': 'INICIAR ESTUDIOS', 'mostrar': ({iniciar_Estudios}) => `${iniciar_Estudios}`,  'enLista': false},
            { 'id': 'CONOCER VIDA UNIVERSITARIA', 'mostrar': ({conocer_vida_Universitaria}) => `${conocer_vida_Universitaria}`,  'enLista': false},
            { 'id': 'EXPERIENCIA', 'mostrar': ({experiencia}) => `${experiencia}`,  'enLista': false},
            { 'id': 'REGALO', 'mostrar': ({regalo}) => `${regalo}`,  'enLista': false},
            { 'id': 'LINGUISTICA', 'mostrar': ({question_1_01}) => `${question_1_01}`,  'enLista': false},
            { 'id': 'LOGICO MATEMATICA', 'mostrar': ({question_2_01}) => `${question_2_01}`,  'enLista': false},
            { 'id': 'MUSICAL', 'mostrar': ({question_3_01}) => `${question_3_01}`,  'enLista': false},
            { 'id': 'CORPORAL KINESTESICA', 'mostrar': ({question_4_01}) => `${question_4_01}`,  'enLista': false},
            { 'id': 'INTRAPERSONAL', 'mostrar': ({question_5_01}) => `${question_5_01}`,  'enLista': false},
            { 'id': 'INTERPERSONAL', 'mostrar': ({question_6_01}) => `${question_6_01}`,  'enLista': false},
            { 'id': 'NATURALISTICA PICTORICA', 'mostrar': ({question_7_01}) => `${question_7_01}`,  'enLista': false},
            { 'id': 'ESPACIAL', 'mostrar': ({question_8_01}) => `${question_8_01}`,  'enLista': false},
            { 'id': 'RESULTADO', 'mostrar': ({resultado}) => `${resultado}`,  'enLista': false},
            { 'id': 'PERIODO', 'mostrar': ({periodo}) => `${periodo}`,  'enLista': false},
            { 'id': 'FECHA DE REGISTRO', 'mostrar': ({fecha_registrado}) => `${moment(fecha_registrado).format('YYYY-MM-DD, h:mm a')}`,  'enLista': false},
          ]}
        />
      </>
      
    )
  }


  render() {
    let {cargando, proceso} = this.state
    let { actualizarMensaje, modalDetalleOva, mostrarModalDetalleOva  } = this.props;
    if(!cargando){
    return (
      <>
        <DetalleOva
        proceso={proceso ? proceso : 0}
        actualizarMensaje={actualizarMensaje}
        modalDetalleOva={modalDetalleOva}
        mostrarModalDetalleOva={mostrarModalDetalleOva}
        />
        {
          this.pintarDatos()
        }
      </>
      )
    }else{
      return(
        <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
      )
    }
    }
}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => { 
  let { modalDetalleOva } = state.redOva
  let { usuario } = state.redGlobal;
  return {
    usuario,
    modalDetalleOva 
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
  mostrarModalDetalleOva,

}



export default connect(mapStateToProps, mapDispatchToProps)(Ova);


