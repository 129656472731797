import {
  MODAL_ADD_CREDITO,
  ACT_DATA_CREDITO,
  MODAL_DET_CREDITO,
  MODAL_GES_CREDITO,
  MODAL_ADD_ADJUNTO_CREDITO,
  MODAL_ADD_FILTRO_CREDITO,
  MODAL_FORMU_CREDITO,
  MODAL_ENCUESTA_CREDITO,
} from "../actions/actCreditos";


const initialState = {
  modalGestionarCredito: false,
  modalAddAdjuntosCredito: false,
  modalAddFiltrosCredito: false,
  modalAddCredito: false,
  modalDetalleCredito: false,
  modalEncuestaCredito: false,
  ModalFormuDirecto:false,
  credito: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ADD_CREDITO:
      return Object.assign({}, state, {
        modalAddCredito: action.show
      })
    case ACT_DATA_CREDITO:
      return Object.assign({}, state, {
        credito: action.credito
      })
    case MODAL_DET_CREDITO:
      return Object.assign({}, state, {
        modalDetalleCredito: action.show
      })
      case MODAL_ENCUESTA_CREDITO:
        return Object.assign({}, state, {
          modalEncuestaCredito: action.show
        })
    case MODAL_GES_CREDITO:
      return Object.assign({}, state, {
        modalGestionarCredito: action.show
      })
    case MODAL_ADD_ADJUNTO_CREDITO:
      return Object.assign({}, state, {
        modalAddAdjuntosCredito: action.show
      })
    case MODAL_ADD_FILTRO_CREDITO:
      return Object.assign({}, state, {
        modalAddFiltrosCredito: action.show
      })
    case MODAL_FORMU_CREDITO:
      return Object.assign({}, state, {
        ModalFormuDirecto: action.show
      })
    default:
      return state;
  }
}

export default reducer;
