import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AppBarModal from './AppBarModal';
import ListarDatos from './ListarDatos';
import { BtnCancelar, BtnForm, BtnSeleccion } from './BotonesAccion';
import TareasTerminadas from './TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';
import { obtenerValores } from '../../global/js/funciones';

class BuscarValorGenerica extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
      cargando: false
    }
  }

  async componentDidUpdate({ modal }) {
    let { modal: modalNew, generica, sw_cargar, cambiar_sw } = this.props;
    if (modal !== modalNew && sw_cargar) {
      this.setState({ cargando: true });
      let datos = await obtenerValores(generica);
      cambiar_sw(false);
      this.setState({ datos, cargando: false });
    }
  }

  acciones = data => {
    let { seleccionadas, seleccionar, quitar } = this.props;
    const onClickSeleccionar = data => seleccionar(data);
    const onClickQuitar = data => quitar(data);
    return (
      // comparar si ya está seleccionada para mostrar un icono de (✓) o (x)
      seleccionadas.map(sel => sel.id).includes(data.id) ? <BtnCancelar callback = { () => onClickQuitar(data) } />
        : <BtnSeleccion callback = { () => onClickSeleccionar(data) } />
    );
  }

  render () {
    let { modal, mostrarModal, nombre_generica } = this.props;
    let { datos, cargando } = this.state;
    return (
      <>
        <Dialog open = { modal } onClose = { mostrarModal.bind(this, false) } aria-labelledby = "form-dialog-title" fullWidth = { true } maxWidth = "sm">
          <AppBarModal titulo = { `Buscar ${nombre_generica}` } mostrarModal = { mostrarModal } titulo_accion = "" accion = "" />
          <DialogContent className = 'scroll' style = { { padding: 0 } }>
            {
              cargando ? 
                <TareasTerminadas mensaje = 'Cargando..' marginTop = '7%' imagen = { emma_w } widthImg = "7%" /> 
                : <ListarDatos
                    datos = { datos }
                    titulo = { `Lista de ${nombre_generica}` }
                    opciones = { true }
                    buscar = { true }
                    filtrar = { false }
                    // actfiltrar = { () => this.setState({ modal_filtro_pais: true }) }
                    sub_titulo = { nombre_generica }
                    id = { `tbl_${nombre_generica}_lista` }
                    acciones = { row => this.acciones(row) }
                    fila_principal = { ({ nombre }) => nombre.toUpperCase() }
                    filas = { [
                      { 'id': 'codigo', 'enLista': true, 'mostrar': ({ codigo }) => codigo ? codigo : '' },
                      { 'id': 'valora', 'enLista': true, 'mostrar': ({ valora }) => valora ? valora : '' },
                    ] }
                  />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto = "CERRAR" callback = { () => mostrarModal(false) } />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

BuscarValorGenerica.defaultProps = {
  nombre_generica: 'Valores',
}

BuscarValorGenerica.propTypes = {
  // variables
  modal: PropTypes.bool.isRequired,
  sw_cargar: PropTypes.bool.isRequired,
  nombre_generica: PropTypes.string.isRequired, 
  generica: PropTypes.string.isRequired,
  seleccionadas: PropTypes.array.isRequired,
  // funciones
  mostrarModal: PropTypes.func.isRequired,
  seleccionar: PropTypes.func.isRequired,
  quitar: PropTypes.func.isRequired,
  cambiar_sw: PropTypes.func.isRequired
}

export default BuscarValorGenerica;
