import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { obtenerValoresFiltros, transformar } from "../../../global/js/funciones";
import  obtenerValoresFiltrosPracticas from './PracticasAdministrar'
import BuscarSelect from '../../general/BuscarSelect';
import BuscarPersona from '../../general/BuscarPersona';
import InputBuscar from '../../general/InputBuscar';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { CARERRAS_CUC, TIPOS_PRACTICAS } from '../helper';


class PracticasFiltrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      programas: [],
      tipos: [],
      programa: null,
      busca_empresa: '',
      trabaja: '',
      en_area: '',
      tipo: '',
      cargando: true,
      cargar: true,
      buscarEstudiante: false,
      estudiante: {
        id: 0,
        nombre: ''
      },
    }
  }
async componentDidUpdate({ modalAddFiltros, accionProceso }) {
  let { modalAddFiltros: modalAddFiltrosNew, accionProceso: accionProcesoNew, obtenerValoresFiltrosPracticas } = this.props;
    if (modalAddFiltros !== modalAddFiltrosNew && this.state.cargar) {this.setState({ cargando: true });
    try {
      const filtros = [{ llave: 'generica', valor: CARERRAS_CUC }, { llave: 'estado', valor: 1 },{ llave: 'valorc', valor: ['pregrado', 'uabarrio', 'pregrado_virtual'] }];
      let resultados = await obtenerValoresFiltrosPracticas(filtros);
      // Filtrar todos los programas por valorc
      const todosLosProgramas = resultados.filter(result => {
        return result.valorc === 'pregrado' || result.valorc === 'uabarrio' || result.valorc === 'pregrado_virtual';
      });
      const tipos = await obtenerValoresFiltros([
        { llave: 'generica', valor: TIPOS_PRACTICAS },{ llave: 'estado', valor: 1 }]);
       // Actualizar estado con los datos obtenidos
        this.setState({
        programas: transformar(todosLosProgramas),tipos,
        cargando: false
      });
    } catch (error) {
      console.error('Error al obtener valores:', error);
      this.setState({ cargando: false });
    }}
  if (accionProceso != accionProcesoNew && accionProceso != 'inicio') {
      this.setState({
        programa: null,
        busca_empresa: '',
        trabaja: '',
        en_area: '',
        tipo: '',
        estudiante: {
          id: 0,
          nombre: ''
        }
      })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  resetState = () => {
    let { limpiar, mostrarModalAddFiltros } = this.props;
    this.setState({
      programa: null,
      busca_empresa: '',
      trabaja: '',
      en_area: '',
      tipo: '',
      estudiante: {
        id: 0,
        nombre: ''
      }
    })
    limpiar();
    mostrarModalAddFiltros(false)
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje, accionProceso, mostrarTodos, mostrarModalAddFiltros } = this.props;
    let {
      busca_empresa,
      trabaja,
      en_area,
      tipo,
      programa,
      estudiante
    } = this.state;

    let filtros = [];
    if (busca_empresa !== '') filtros.push({ 'llave': 'busca_empresa', 'valor': busca_empresa });
    if (trabaja !== '') filtros.push({ 'llave': 'trabaja', 'valor': trabaja });
    if (en_area !== '') filtros.push({ 'llave': 'en_area', 'valor': en_area });
    if (tipo !== '') filtros.push({ 'llave': 'tipo', 'valor': tipo });
    if (programa !== null) filtros.push({ 'llave': 'programa', 'valor': programa.value });
    if (estudiante.id) filtros.push({ 'llave': 'usuario_registro', 'valor': estudiante.id });

      if (accionProceso == 'inicio') mostrarTodos('Tod_Pra')
      mostrarModalAddFiltros(false)
      filtrar(filtros);
    
    // else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { modalAddFiltros, mostrarModalAddFiltros, accionProceso } = this.props;
    let {
      busca_empresa,
      trabaja,
      en_area,
      tipo,
      programa,
      programas,
      tipos,
      cargando,
      buscarEstudiante,
      estudiante
    } = this.state;
    let habilitar_limpiar = accionProceso !== 'Tod_Pra' && accionProceso !== 'inicio'
    return (
      <>
        <Dialog fullWidth={true} maxWidth="sm" open={modalAddFiltros} onClose={() => mostrarModalAddFiltros(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalAddFiltros} titulo_accion={ habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.resetState : ''} />
          <DialogContent className='scroll'>
            {cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={estudiante.nombre}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <BuscarSelect datos={programas} cambiarEstado={(value) => this.setState({ programa: value })} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione Programa' />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="trabaja">Trabajan</InputLabel>
                    <Select
                      inputProps={{
                        name: 'trabaja',
                        id: 'trabaja',
                      }}
                      value={trabaja}
                      onChange={this.onChange}
                      required={true}>
                      <MenuItem value={1}>SI</MenuItem>
                      <MenuItem value={0}>NO</MenuItem>
                      <MenuItem value={-1}>No Aplica</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="en_area">Trabajan en su área </InputLabel>
                    <Select
                      inputProps={{
                        name: 'en_area',
                        id: 'en_area',
                      }}
                      value={en_area}
                      onChange={this.onChange} >
                      <MenuItem value={1}>SI</MenuItem>
                      <MenuItem value={0}>NO</MenuItem>
                      <MenuItem value={-1}>No Aplica</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="busca_empresa">Requieren Ubicación</InputLabel>
                    <Select
                      inputProps={{
                        name: 'busca_empresa',
                        id: 'busca_empresa',
                      }}
                      value={busca_empresa}
                      onChange={this.onChange}>
                      <MenuItem value={1}>SI</MenuItem>
                      <MenuItem value={0}>NO</MenuItem>
                      <MenuItem value={-1}>No Aplica</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="busca_empresa">Seleccione Modalidad</InputLabel>
                    <Select
                      inputProps={{
                        name: 'tipo',
                        id: 'tipo',
                      }}
                      value={tipo}
                      onChange={this.onChange}>
                      {
                        tipos.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => mostrarModalAddFiltros(false)} />
            {!cargando && <BtnForm texto="FILTRAR" callback={this.onSubmit} />}
          </DialogActions>
        </Dialog >

        <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              estudiante: {
                id,
                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarEstudiante: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

//Props del componente
PracticasFiltrar.propTypes = {
  //variables
  modalAddFiltros: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAddFiltros: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
  limpiar: PropTypes.func.isRequired,
}
export default PracticasFiltrar;
