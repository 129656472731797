import DoneIcon from '@material-ui/icons/AssignmentTurnedIn';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BtnDetalle, BtnEditar, BtnFolder, BtnGestionar, BtnLocalizacionOn, BtnReset } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";

import { Checkbox, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PlaylistAddCheckSharpIcon from '@material-ui/icons/PlaylistAddCheckSharp';

class PracticasListar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      BtnAcciones: false,
      interes:[],
      interesnombre:''
    }
  }

  async componentDidMount(){
    let {practicas, estadoSeleccion, seleccionMasiva} = this.props;
    let {interesnombre}=this.state

    if(estadoSeleccion && seleccionMasiva.length==0){
      practicas.map(( practica )=>{
        this.seleccionar(practica.id)
      })
    }
  }


  seleccionar = value => {
    let { seleccionMasiva, estadoSeleccion } = this.props;
    let sele = seleccionMasiva;
    const seleccionado = sele.indexOf(value);
    
    if (seleccionado === -1) sele.push(value);
    else sele.splice(seleccionado, 1);
    this.setState({ seleccionMasiva: sele })

    if(estadoSeleccion){
      let sw = seleccionMasiva.length>0
      this.setState({ BtnAcciones: sw })
    }
  }

  acciones = (data) => {
    let { mostrarModalAddEmpresaProceso, mostrarModalAdmBuscarEmpresa,mostrarModalAdmBuscarEmpresaotravez,administra, actualizarDataPractica, mostrarModalAdmAjuntosPractica, actualizarDataEstado, mostrarModalDetallePractica, mostrarModalGestionarPractica, usuario_id, estadoSeleccion, seleccionMasiva } = this.props;
    let { estado_actual, usuario_registro, permiso, gestor } = data;
    
    data.nombre_completo = `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`;

    const abrirAdjuntos = (data) => {
      actualizarDataPractica(data)
      actualizarDataEstado(data.estado_actual);
      mostrarModalAdmAjuntosPractica(true)
    }

    const onClickGestionar = (data) => {
      actualizarDataPractica(data)
      actualizarDataEstado(data.estado_actual);
      mostrarModalGestionarPractica(true)
    }


    const onClickDetalle = (data) => {
      actualizarDataPractica(data)
      actualizarDataEstado(data.estado_actual);
      mostrarModalDetallePractica(true)
    }

    const onClickBuscarEmpresa = (data) => {
      actualizarDataPractica(data);
      mostrarModalAdmBuscarEmpresa(true);
    }

    const onClickBuscarEmpresaotravez = (data) => {
      actualizarDataPractica(data);
      mostrarModalAdmBuscarEmpresaotravez(true);
    }

    const onClickEditar = (data) => {
      actualizarDataPractica(data);
      mostrarModalAddEmpresaProceso(true);
      
    }

    let editar2 = ((data.empresa && estado_actual.codigo==='Est_Pra_Ini') || (estado_actual.codigo !== 'Est_Pra_Rec' || estado_actual.codigo !== 'Est_Pra_Fin' || estado_actual.codigo!=='Est_Pra_Ini' || (estado_actual.codigo === 'Est_Pra_Env' && data.tipo.codigo === 'Mod_Rce_Pra') || (estado_actual.codigo === 'Est_Pra_Env' && data.trabaja === '1' && data.en_area === '1') )) && <BtnEditar callback={() => onClickEditar(data)} />;
    let editar = (data.empresa && estado_actual.codigo==='Est_Pra_Ini' && data.tipo.codigo === 'Mod_Emp_Pra' )  ? <BtnEditar callback={() => onClickEditar(data)} />: (( (data.empresa || data.tipo.codigo === 'Mod_Cli_Pre') && (data.tipo.codigo !== 'Mod_Emp_Pra' || estado_actual.codigo!='Est_Pra_Ini' )  )&& (estado_actual.codigo !== 'Est_Pra_Rec' || estado_actual.codigo !== 'Est_Pra_Fin' || estado_actual.codigo!=='Est_Pra_Ini' || (estado_actual.codigo === 'Est_Pra_Env' && data.tipo.codigo === 'Mod_Rce_Pra') || (estado_actual.codigo === 'Est_Pra_Env' && data.trabaja === '1' && data.en_area === '1')) ) ? <BtnEditar callback={() => onClickEditar(data)} />:''
    let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
    let adjuntos = <BtnFolder callback={() => abrirAdjuntos(data)} color='#ffd600' />;
    let gestionar = <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
    let buscaEmpresa = (data.busca_empresa === '0' && estado_actual.codigo === 'Est_Pra_Ini') && <BtnLocalizacionOn callback={() => onClickBuscarEmpresa(data)} color='#3f51b5' texto='Busca Empresa' />;
    let rotarhv = (data.busca_empresa==='1'&& estado_actual.codigo==='Est_Pra_Ini'&& data.empresa!=null)&&<BtnReset callback={() => onClickBuscarEmpresaotravez(data)}  color='#3f51b5' texto='Rotar HV' />
    
    let ver = (estadoSeleccion) ? <Checkbox edge="end" onChange={() => {this.seleccionar(data.id)} } checked={seleccionMasiva.indexOf(data.id) !== -1} inputProps={{ 'aria-labelledby': data.id }} /> : ''

    let opciones = (!estadoSeleccion) ? <div> {detalle} {rotarhv}{buscaEmpresa} {editar} {adjuntos} {gestionar} { ver } </div> : <div> { ver } </div> ;

    return (permiso == 1 && administra && estado_actual.codigo !== 'Est_Pra_Rec' && estado_actual.codigo !== 'Est_Pra_Fin') ? opciones : detalle;
  }

  obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Est_Pra_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Est_Pra_Ini': { 'backgroundColor': '#01579b', color },
      'Est_Pra_Rec': { 'backgroundColor': '#b71c1c', color },
      'Est_Pra_Fin': { 'backgroundColor': '#009688', color },
      'Est_Pra_Rev': { 'backgroundColor': '#ffc107', color },
      'Est_Pra_Acep': { 'backgroundColor': '#1a237e', color },
      'Est_Pra_Vis': { 'backgroundColor': '#6200ea', color },
      'Est_Pra_Vif': { 'backgroundColor': '#6200ea', color },
      'Est_Pra_Vio': { 'backgroundColor': '#6200ea', color },
    }
    return (colores[codigo])
  }
  
  accionesMasivas = () => {
    let { estadoSeleccion, limpiar, mostrarModalMasivoFiltro, practicas } = this.props
    let i = 0, sw = false;

    while(!sw && i<practicas.length){
      if(practicas[i].estado_actual.valorf==1) sw=true
      i++;
    }
    
    let filtroMasivo = (!estadoSeleccion && practicas.length>0 && sw) ? <Tooltip title='Acciones Masivas' aria-label="add" onClick={() => mostrarModalMasivoFiltro(true) } >
                          <IconButton edge="end" color="secondary" >
                            <PlaylistAddCheckSharpIcon />
                          </IconButton>
                        </Tooltip> : '';

    let BtnLimpiar = (estadoSeleccion) ? <Tooltip title='Limpiar' aria-label="add" onClick={() => limpiar()} >
                    <IconButton edge="end" color="secondary" >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip> : '';

    return <> {filtroMasivo} {BtnLimpiar} </>;
  }

  abrirAccionesMasivas = () => {
    let { practicas, actualizarDataPractica, actualizarDataEstado, mostrarModalAccionesMasivasPractica } = this.props

    if(practicas.length>0){
      actualizarDataPractica(practicas[0])
      actualizarDataEstado(practicas[0].estado_actual);
    }
    
    mostrarModalAccionesMasivasPractica(true);
  }

  

  render() {
    let { practicas, administra, mostrarModalAddFiltros, refrescar, estadoSeleccion } = this.props;
 
    
    return (
      <ListarDatos
        id='tbl_lista_practica'
        descargar={ estadoSeleccion ? '' : 'EMMA - PRACTICAS' }
        datos={practicas}
        opciones={true}
        titulo={'Lista Procesos'}
        acciones={(row) => this.acciones(row)}
        buscar={true}
        avatar={({ estado_actual }) => estado_actual.valorc}
        color_avatar={({ estado_actual }) => this.obtenerColor(estado_actual.codigo)}
        fila_principal={({ tipo }) => tipo.nombre.toUpperCase()}
        filtrar={!estadoSeleccion}
        refrescar={!estadoSeleccion}
        actfiltrar={() => mostrarModalAddFiltros(true)}
        actRefrescar={() => refrescar()}
        agregar={this.state.BtnAcciones}
        actAgregar={() => this.abrirAccionesMasivas()}
        iconoFab={() => <DoneIcon />}
        accionesList={() => this.accionesMasivas()}
        filas={[
          {
            'mostrar': ({ estado_actual }) => estado_actual ? estado_actual.nombre.toUpperCase() : '',
            'id': 'estado_actual',
          },
          {
            'mostrar': ({ usuario_registro: { identificacion } }) => identificacion,
            'id': 'identificacion',
            'enLista': false
          },
          {
            'mostrar': ({ usuario_registro }) => `${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido} ${usuario_registro.primer_nombre} `,
            'id': 'nombre_completo',
            'enLista': administra
          },
          {
            'mostrar': ({ usuario_registro }) => `Telefono: ${usuario_registro.telefono} / ${usuario_registro.celular}`,
            'id': 'telefono_estudiante',
            'enLista': administra
          },
          {
            'mostrar': ({ usuario_registro }) => usuario_registro.telefono,
            'id': 'telefono',
            'enLista': false
          },
          {
            'mostrar': ({ usuario_registro }) => usuario_registro.celular,
            'id': 'celular',
            'enLista': false
          },
          {
            'mostrar': ({ usuario_registro }) => usuario_registro.correo,
            'id': 'correo_institucional',
            'enLista': false
          },
          {
            'mostrar': ({ usuario_registro }) => usuario_registro.correo_personal,
            'id': 'correo_personal',
            'enLista': false
          },
          {
            'mostrar': ({ promedioacumulado }) => promedioacumulado ? promedioacumulado : '',
            'id': 'promedioacumulado',
            'enLista': false
          },
          {
            'mostrar': ({ programa }) => `${programa.nombre}`,
            'id': 'programa',
          },
          {
            'mostrar': ({ tipo }) => tipo ? tipo.nombre : '',
            'id': 'tipo',
            'enLista': false
          },
          {
            'mostrar': ({ segundo_idioma }) => segundo_idioma == 1  ? 'SI' : 'NO',
            'id': 'segundo_idioma',
            'enLista': false
          },
          {
            'mostrar': ({areainteres}) => areainteres  ? areainteres : '',
            'id': 'areainteres',
            'enLista': false
          },
          {
            'mostrar': ({ aprendizaje }) => aprendizaje == 1  ? 'SI' : 'NO',
            'id': 'aprendizaje',
            'enLista': false
          },
          {
            'mostrar': ({ area_pro_mejora }) => area_pro_mejora ? area_pro_mejora: 'No aplica',
            'id': 'area_pro_mejora',
            'enLista': false,
          },
          {
            'mostrar': ({ empresa, busca_empresa }) => busca_empresa == 1 ? empresa ? 'SI' : 'NO' : '',
            'nombre': 'Ubicado: ',
            'id': 'ubicado',
          },
          {
            'mostrar': ({ busca_empresa }) => busca_empresa == 1 ? 'SI' : 'NO',
            'id': 'buscar_empresa',
            'enLista': false
          },
          {
            'mostrar': ({ en_area }) => en_area == 1 ? 'SI' : 'NO',
            'id': 'trabaja_en_area',
            'enLista': false
          },
          {
            'mostrar': ({ empresa }) => empresa ? empresa.nombre : '',
            'id': 'nombre_empresa',
            'enLista': false
          },
          {
            'mostrar': ({ empresa }) => empresa ? empresa.codigo : '',
            'id': 'nit_empresa',
            'enLista': false
          },
          {
            'mostrar': ({ empresa }) => empresa ? empresa.descripcion : '',
            'id': 'actividad_empresa',
            'enLista': false
          },
          {
            'mostrar': ({ sucursal }) => sucursal ? sucursal.nombre : '',
            'id': 'direccion_sucursal',
            'enLista': false
          },
          {
            'mostrar': ({ sucursal }) => sucursal ? sucursal.valorb : '',
            'id': 'ciudad_sucursal',
            'enLista': false
          },
          {
            'mostrar': ({ sucursal }) => sucursal ? sucursal.valorc : '',
            'id': 'departamento_sucursal',
            'enLista': false
          },
           
          {
            'mostrar': ({ contrato }) => contrato ? contrato.nombre : '',
            'id': 'contrato',
            'enLista': false
          },
          {
            'mostrar': ({ tipoempresa }) => tipoempresa ? tipoempresa.nombre : '',
            'id': 'tipoempresa',
            'enLista': false
          },
          {
            'mostrar': ({ contratadoempresa }) => contratadoempresa == 1  ? 'SI' : (contratadoempresa == 0) ? 'NO' : 'NO APLICA',
            'id': 'contratadoempresa',
            'enLista': false
          },
          {
            'mostrar': ({ duracion }) => duracion ? duracion : '',
            'id': 'duracion',
            'enLista': false
          },
          {
            'mostrar': ({ fecha_inicio }) => fecha_inicio ? fecha_inicio : '',
            'id': 'fecha_inicio',
            'enLista': false
          },
          {
            'mostrar': ({ fecha_inicio, duracion }) => fecha_inicio && duracion ? moment(fecha_inicio).add(duracion, 'M').format('YYYY-MM-DD') : '',
            'id': 'fecha_fin',
            'enLista': false
          },
          {
            'mostrar': ({ horario_entrada }) => horario_entrada ? horario_entrada : '',
            'id': 'horario_entrada',
            'enLista': false
          },
          {
            'mostrar': ({ horario_salida }) => horario_salida ? horario_salida : '',
            'id': 'horario_salida',
            'enLista': false
          },
          {
            'mostrar': ({ auxilio }) => auxilio !== null ? auxilio : '',
            'id': 'auxilio',
            'enLista': false
          },
          {
            'mostrar': ({ area_desarrollo }) => area_desarrollo ? area_desarrollo : '',
            'id': 'area_desarrollo',
            'enLista': false
          },
          {
            'mostrar': ({ funciones }) => funciones ? funciones : '',
            'id': 'funciones',
            'enLista': false
          },
          {
            'mostrar': ({ jefe }) => jefe,
            'id': 'nombre_jefe',
            'enLista': false
          },
          {
            'mostrar': ({ cargo_jefe }) => cargo_jefe,
            'id': 'cargo_jefe',
            'enLista': false
          },
          {
            'mostrar': ({ correo_jefe }) => correo_jefe,
            'id': 'correo_jefe',
            'enLista': false
          },
          {
            'mostrar': ({ telefono_jefe }) => telefono_jefe,
            'id': 'telefono_jefe',
            'enLista': false
          },
          {
            'mostrar': ({ nota }) => nota,
            'id': 'nota',
            'enLista': false,
          },
          {
            'mostrar': ({ nota70 }) => nota70,
            'id': 'nota70',
            'enLista': false,
          },
          {
            'mostrar': ({ nota30 }) => nota30,
            'id': 'nota30',
            'enLista': false,
          },
          {
            'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD'),
            'id': 'fecha_registro',
            'enLista': false
          },
          {
            'mostrar': ({ id }) => id,
            'nombre': 'Número de solicitud: ',
            'id': 'numero_solicitud',
          },
        ]}
      />
    )
  }
}

PracticasListar.propTypes = {
  //variables
  practicas: PropTypes.array.isRequired,
  //funciones
  actualizarDataPractica: PropTypes.func.isRequired,
  administra: PropTypes.bool.isRequired,
  actualizarDataEstado: PropTypes.func.isRequired,
  mostrarModalAdmAjuntosPractica: PropTypes.func.isRequired,
  mostrarModalDetallePractica: PropTypes.func.isRequired,
}

export default PracticasListar
