import React, { Component } from 'react';
import { connect } from "react-redux";
import { actualizarMensaje, mostrarModulo } from "../../redux/actions/actGlobal";
import TareasTerminadas from "../general/TareasTerminadas";
// Material UI
import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@material-ui/core";

import emma_w from "../../global/imagenes/emma_w.png";
import emma_s1 from "../../global/imagenes/emma_s1.png";
import cuc from '../../global/imagenes/logo_cuc2.png';
import BarraProceso from "../general/BarraProceso";

// Funciones globales
import {
  consulta,
  generarFiltros,
  obtenerMisDatos,
  obtenerValores,
} from "../../global/js/funciones";

import { PASOS } from "./helper"
import PintarEncuesta from "./PintarEncuesta";

class AutogestionEstudiante extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: true,
      seleccion: 'proceso',
      cargando: true,
      iniciar: false,
      encuestaDisponible: false,
      datosUsuario: {},
      pasos: [],
      numero_paso: "",
      programa: null,
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/autogestion_estudiantil', nombre: 'Autogestion Estudiantil' })
    const misDatos = await obtenerMisDatos();
    const programaValido = misDatos.programas.length > 0 &&
      misDatos.programas[0].relacion &&
      misDatos.programas[0].relacion.id !== undefined;

    if (programaValido) {
      this.setState({ programa: misDatos.programas[0].relacion.id });
    }
    await this.obtenerEncuestas(misDatos);
    const transformar = (data) => {
      return data.map((item) => ({
        value: item.id,
        label: item.nombre,
      }));
    };
    const pasosEncuesta = await obtenerValores(PASOS);
    pasosEncuesta.sort((a, b) => (a.valora > b.valora ? 1 : -1));
    this.setState({ cargando: false, datosUsuario: misDatos.persona, pasos: transformar(pasosEncuesta) })
  }

  obtenerEncuestas = async ({ persona, programas }) => {
    this.setState({ cargando: true })
    let filtro = await generarFiltros([
      { llave: "usuario_registro", valor: persona.id },
    ]);
    consulta(
      `api/v1.0/autogestion_estudiantil/encuesta/datos?${filtro}`,
      null,
      null,
      (error, estado, resp) => {
        if (estado === 200) {
          this.setState(
            {
              cargando: false,
              numero_paso: resp.length == 0 ? 0 : resp[0].numero_paso
            })
        }
      }
    );
  };


  pintar() {
    let { encuestaDisponible, datosUsuario, pasos, numero_paso } = this.state;
    encuestaDisponible = numero_paso == pasos.length ? false : true
    return encuestaDisponible ? (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0 auto",
          padding: "10px 0px 30px 0px",
          height: "100hv",
          backgroundColor: "white",
        }}
      >
        <AppBar position="fixed" elevation={0}>
          <Toolbar>
            <div
              style={{
                padding: "5px 0px 5px 3px",
                color: "white",
                textAlign: "left",
              }}
            >
              <p>
                <span style={{ marginLeft: "10px" }}>
                  {"ENCUESTA  AUTOGESTION ESTUDIANTIL"}
                </span>
              </p>
            </div>
          </Toolbar>
        </AppBar>
        <Box px={5}>
          <TareasTerminadas
            marginBottom={"3px"}
            mensaje={`Hola ${datosUsuario.primer_nombre} ${datosUsuario.primer_apellido} ${datosUsuario.segundo_apellido}, soy EMMA. Te invito a responder una serie de preguntas solo te tomara unos minutos`}
            marginTop="2%"
            imagen={emma_s1}
            widthImg="7%"
          />
          <TareasTerminadas
            mensaje={
              <>
                A continuación, encontrarás una herramienta de autogestión, donde nos permites conocer de manera activa cómo va tu proceso académico y personal. Recuerda que la información suministrada es confidencial conforme a nuestra política de tratamiento de datos personales{' '}
                <a
                  href="https://www.cuc.edu.co/wp-content/uploads/normatividad/documentos-institucionales/13.%20POLITICA%20TRATAMIENTO%20DE%20DATOS%20PERSONALES%20-%20ACUERDO%20No.%201550.pdf?_t=1646063996"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline', color: 'blue' }}
                >
                  (Conócela aquí)
                </a>
                . Te pedimos que tus respuestas sean claras y transparentes para brindarte una atención oportuna.
              </>
            }
            marginTop="7%"
            imagen={cuc}
            widthImg="7%"
          />
          <div style={{ width: "100%", textAlign: "left", padding: "20px 0" }}>
            <BarraProceso steps={pasos} activeStep={numero_paso} />
          </div>
        </Box>
        <div
          style={{
            width: "30%",
            minWidth: 200,
            padding: "20px 0",
            textAlign: "left",
            margin: "0 auto",
          }}
        >
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "30%", minWidth: 200, color: "white" }}
          onClick={() => this.setState({ iniciar: true })}
        >
          Iniciar
        </Button>
      </div>
    ) : (
      <TareasTerminadas
        mensaje={`Hola ${datosUsuario.primer_nombre} ${datosUsuario.primer_apellido} ${datosUsuario.segundo_apellido}, no tienes encuestas disponibles o fueron completadas anteriormente. Gracias por tu tiempo`}
        marginTop="7%"
        imagen={emma_s1}
        widthImg="7%"
      />
    );
  };

  render() {
    let { cargando, iniciar, pasos, numero_paso, programa } = this.state;
    const id = programa ? programa : null;

    return !cargando ? (
      iniciar ? (
        <PintarEncuesta
          pasos={pasos}
          programa={id !== null && id}
          pasoActualPrograma={numero_paso}
        />
      ) : (
        this.pintar()
      )
    ) : (
      <TareasTerminadas
        mensaje="Cargando.."
        marginTop="7%"
        imagen={emma_w}
        widthImg="7%"
        cargando={true}
      />
    );
  };

}


//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  return {
    usuario,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
}




export default connect(mapStateToProps, mapDispatchToProps)(AutogestionEstudiante);