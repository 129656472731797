import React, { Component, Fragment } from 'react';
import { Grid, Paper, Card, CardHeader, CardContent, Avatar, Typography, Dialog } from '@material-ui/core';
import AddCircleIcon from '../../../global/imagenes/portal/businessman.png';
import emma_w from '../../../global/imagenes/emma_w.png'
import AppBarModal from '../../general/AppBarModal'
import TareasTerminadas from '../../general/TareasTerminadas'
import { red } from '@material-ui/core/colors';
import emma_s from '../../../global/imagenes/emma_s1.png';

//components
import ModalAgregarProceso from './ModalAgregarProceso'

class ProcesoAgregar extends Component {

    constructor(){
        super()
        this.state = {
            openModalNuevo: false
        }
    }

    render() {
        let { actualizarMensaje, misIntereses, vacantes, crearPostulantes, detalle_vacante,validarPerfilEstudiante } = this.props
        let { openModalNuevo } = this.state
        return (
            <>
            {validarPerfilEstudiante == 100 ? 
            <Fragment>
                <Grid container direction='row' justify='center' style={{ marginBottom: '100px' }}>
                    <Grid item xs={12} sm={6} md={4} style={{ padding: '2% 2% 0 2%' }} onClick={() => this.setState({ openModalNuevo: true })}>
                    <Paper elevation={8} square>
                        <Card style={{ maxWidth: '100%', marginTop: 35, cursor: 'pointer' }} elevation={0}>
                        <CardHeader avatar={<Avatar aria-label='recipe' style={{ backgroundColor: red[500] }}>I</Avatar>} title={'Iniciar nuevo proceso'} />
                        <CardContent style={{ height: '10%', textAlign: 'center' }}>
                            <img src={AddCircleIcon} style={{ width: '40%', margin: '0 auto' }} alt="Imagen Cursos"/>
                        </CardContent>
                        <CardContent>
                            <Typography style={{ minHeight: 100 }} variant='body2' color='textSecondary' component='p' align='justify'>
                            {'Para iniciar un nuevo proceso, primero debe actualizar algunos datos personales, despues darle al boton de SIGUIENTE y selecciona la oferta a la cual se quiere postular y le da al boton de GUARDAR.'}
                            </Typography>
                        </CardContent>
                        </Card>
                    </Paper>
                    </Grid>
                </Grid>

                <ModalAgregarProceso
                    misIntereses={misIntereses}
                    vacantes={vacantes}
                    crearPostulantes={crearPostulantes}
                    openModalNuevo={openModalNuevo}
                    actualizarMensaje={actualizarMensaje}
                    closeModalNuevo={() => this.setState({ openModalNuevo: false })}
                    detalle_vacante={detalle_vacante}
                />
                {/* <Dialog open={openModalNuevo} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={'Enviando Datos'} mostrarModal={() => { }} titulo_accion="" accion="" />
                    <TareasTerminadas mensaje='Se están guardando los datos, un momento por favor' imagen={emma_w} widthImg='7%' marginTop='5%' />
                </Dialog> */}
            </Fragment>:
            <TareasTerminadas mensaje='Para postularte a ofertas, debe completar su perfil al 100%' marginTop='7%' imagen={emma_s} widthImg="7%" />
        } 
        </>
        );
    }
}

export default ProcesoAgregar;
