import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Grid, Link, Paper, Button, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccionesRapidas from '../general/AccionesRapidas'
//FUNCIONES GENERALES
import { consulta, mostrarError, formulario, obtenerValores, obtenerMisDatos, generarFiltros, obtenerValoresFiltros, api, crear_form_data, coloresEmma } from "../../global/js/funciones";
import { BtnDetalle } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';
import TituloAccion from "../general/TituloAccion";
import BarraProceso from "../general/BarraProceso"
//FUNCIONES DEL MODULO
import { mostrarModalAddBeca, mostrarModalDetalleBeca, mostrarModalAdjuntos, mostrarModalAddAdjBeca, mostrarModalValidacion } from "../../redux/actions/actBecas";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { MenuEstudiante } from "./administrar/MenuBecas"
import { Beca } from './Beca';
import BecasCrear from './BecasCrear';
import BecaActiva from './BecaActiva'
import BecaAdjuntos from './BecaAdjuntos';
import BecaDetalle from './BecaDetalle';
import email from '../../global/imagenes/email.png'
import revisar from '../../global/imagenes/revisar.png';
import study from '../../global/imagenes/study.png';
import assigment from '../../global/imagenes/assigment.png';
import ConfirmarAccion from "./../general/ConfirmarAccion";
import TareasTerminadas from '../general/TareasTerminadas';
import emma_s from '../../global/imagenes/emma_s1.png'
import emma_w from './../../global/imagenes/emma_w.png';
import moment from 'moment';

import { red } from '@material-ui/core/colors';

import {TIPOS_BECAS}from './helper'

class Becas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'miproceso',
      tipos: [],
      carreras: [],
      datos_sesion: [],
      telefono: '',
      celular: '',
      lineaEstados: [],
      cargando: true,
      becaActual : 0,
			procesoActual: null,
      enListado : true,
      procesos : [],
      historial: [],
      periodo: '',
      id_estudiante : 0,
      mensaje_beca : '',
      openModalEliminar: false,
      becaCancelar: 0
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: 'becas', nombre: 'Mis Becas' });
    let datos_sesion = await obtenerMisDatos();
    let filtros = [
      { 'llave': 'codigo', 'valor': 'Men_Bec' },
      { 'llave': 'codigo', 'valor': 'Men_Bec1' },
      { 'llave': 'codigo', 'valor': 'Men_Bec2' },
    ];
  
    // Iterar sobre los filtros y obtener los mensajes
    let mensaje_beca = [];
    for (let filtro of filtros) {
      let resultado = await obtenerValoresFiltros([filtro]);
      console.log(`Resultado para ${filtro.valor}:`, resultado); 
      if (resultado && resultado.length > 0) {
        mensaje_beca.push(resultado[0].nombre || '');
      }
    }
    this.cambiarEstado({
      datos_sesion,
      mensaje_beca, 
    });
    this.obtenerDatosBasicos();
    this.obtenerCarreras();
    this.obtenerDatosIniciales();
  }
  async componentDidUpdate({}, { seleccion, becaActual }) {
    let { seleccion: seleccionNew, becaActual : becaActualNew } = this.state;
    if (becaActual !== becaActualNew && seleccionNew === 'miproceso' && becaActualNew != 0) this.obtenerDataProcesoActual(becaActualNew);
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'miproceso') this.obtenerDatosIniciales();
      else if (seleccionNew === 'historial') await this.obtenerHistorial();
    }
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true, enListado: true, })
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Bec' }]);
    let procesos = await this.obtenerBecas([{ 'llave': 'periodo', 'valor': periodo.length > 0 ? periodo[0].nombre : '' }]);
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', procesos, cargando: false }) 
  }

  async obtenerBecas(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/listar/0?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerDataProcesoActual(id = 0) {
    this.setState({ cargando: true })
    let data = await this.obtenerBeca(id);
    if (data) this.setState({ lineaEstados: data.linea, procesoActual: data.beca, id_estudiante: data.beca.solicitante.id });
    this.setState({ cargando: false });
  }

  async obtenerBeca(id = 0) {
    return new Promise(resolve => {
      consulta(`api/v1.0/becas/${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  obtenerTiposBecas = async () => {
    const transformar = data => {
      return data.map(e => ({
        value: e.id,
        label: e.nombre,
        codigo: e.codigo,
        valorb: e.valorb,
        valorh: e.valorh,
      }));
    }
  
    const valores = await obtenerValores(TIPOS_BECAS);
    let tipos = transformar(valores);
    let datos_sesion = await obtenerMisDatos();
    let perfiles = datos_sesion.perfiles;
  
    let tiposEstudiante = new Set();
    let tiposAdminDoc = new Set();
  
    perfiles.forEach(per => {
      if (
        per.relacion.codigo === 'Per_Nor' ||
        per.relacion.codigo === 'Per_Est_Pos' ||
        per.relacion.codigo === 'Per_Est_Hep' ||
        per.relacion.codigo === 'Est_Inv' ||
        per.relacion.codigo === 'Est_Pas_Inv' ||
        per.relacion.codigo === 'Per_Est'
      ) {
        tipos
          .filter(tip => tip.valorh === 'estudiante')
          .forEach(t => tiposEstudiante.add(t));
      }
  
      if (
        per.relacion.codigo === 'Per_Adm' ||
        per.relacion.codigo === 'Per_Col' ||
        per.relacion.codigo === 'Per_Bec_n1' ||
        per.relacion.codigo === 'Per_Bec_n2' ||
        per.relacion.codigo === 'Per_Doc'
      ) {
        tipos
          .filter(tip => tip.valorh === 'adminDoc')
          .forEach(t => tiposAdminDoc.add(t));
      }
    });
  
    // Combinar resultados sin duplicados
    let tiposFinal = new Set([...tiposEstudiante, ...tiposAdminDoc]);
    let tiposArray = Array.from(tiposFinal);
  
    this.cambiarEstado({ tipos: tiposArray });
  }
  

  obtenerCarreras = () => {
    let { datos_sesion } = this.state
    let carreras = datos_sesion.programas
    this.cambiarEstado({ carreras })
    
  }

  obtenerDatosBasicos = () => {
    let { datos_sesion } = this.state
    let telefono = datos_sesion.persona.telefono
    let celular = datos_sesion.persona.celular
    this.cambiarEstado({ telefono, celular })
  }

  async obtenerHistorial() {
    this.setState({ cargando: true })
    let historial = await this.obtenerBecas();
    this.setState({ historial, cargando: false })
  }

  cambiarEstado = (estados) => {
    this.setState(estados)
  }

  cambiarOpcion = (seleccion) => {
    this.cambiarEstado({ seleccion })
  }

  guardarBeca = async (archivos, datos, callback) => {
    let { actualizarMensaje, mostrarModalAddBeca } = this.props
    let data = await crear_form_data(datos);
    archivos.map(({archivo, codigo}, index) => {
      data.append(`archivo${index}`, archivo)
      data.append(`codigo${index}`, codigo)
      return true;
    })
    formulario(`api/v1.0/becas/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddBeca(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      callback();
    })
  }  

  cancelarSolicitud = () => {
    let { becaCancelar } = this.state
    let { actualizarMensaje } = this.props

    consulta(`api/v1.0/becas/${becaCancelar}/cancelar`, {}, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.obtenerDatosIniciales()
          this.setState({ openModalEliminar: false, becaCancelar: 0 })
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
    })
  }

  vista = () => {
    let { seleccion, enListado } = this.state

    switch (seleccion) {
      case 'miproceso':
        return (
          (enListado ? this.pintarSolicitudes() : this.pintarSolicitudSeleccionada())
        )
      case 'nuevo':
        return (this.pintarNuevo())
      case 'historial':
        return (
          this.pintarHistorial()
        )
      default:
        break;
    }
  }

  pintarSolicitudes() {
    let { procesos, openModalEliminar } = this.state;
    if (procesos.length === 0) {
      return (
        <div className="pointer" onClick={() => this.setState({ seleccion: "nuevo" })}>
          <TareasTerminadas
            mensaje="No tienes solicitudes activas, clic aquí para solicitar beca"
            marginTop="7%"
            imagen={emma_s}
            widthImg="7%"
          />
        </div>
      );
    }
		const handleClick = (beca) => {
			this.setState({ enListado: false, becaActual: beca.id });
		};

    const handleClickDelete = (e, id) => {
      this.setState({ becaCancelar: id, openModalEliminar: true }); 
      e.stopPropagation();
		};

		return (
			<div style={{ padding: '2% 2% 0% 2%' }}>
        <>
          <Grid container direction={'row'} spacing={1}>
            <TituloAccion principal="" alterno="¡ Estas son tus solicitudes de becas para este periodo !" />
            {procesos.map((beca, i) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <Paper elevation={1} onClick={() => handleClick(beca)} className="pointer">
                    <BecaActiva
                      id={beca.id}
                      tipo={beca.tipo_beca}
                      fecha={beca.fecha_registro}
                      descripcion={`Tu solicitud de beca para ${beca.tipo_beca
                        .nombre} se encuentra en estado ${beca.estado_actual
                          .nombre}. Clic aquí para obtener mas información.`}
                      estado={beca.estado_actual}
                      handleClickDelete={handleClickDelete}
                    />
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
          <ConfirmarAccion
            titulo='¿ Cancelar Solicitud ?'
            dato={{ id: 0 }}
            mensaje1='¿Estás seguro(a) que deseas cancelar la solicitud de beca?'
            modalConAccion={openModalEliminar}
            mostrarModalConfAccion={() => this.setState({ openModalEliminar: false, becaCancelar: 0 })}
            ejecutarAccion={this.cancelarSolicitud}
          />
        </>
			</div>
		);
  }
  
  pintarSolicitudSeleccionada() {
    let { mostrarModalDetalleBeca, mostrarModalAdjuntos } = this.props
    let { procesoActual } = this.state;

    const ejecutarAccionDetalle = vistaDetalle => {
      this.setState({ vistaDetalle })
      mostrarModalDetalleBeca(true);
    }

    const ejecutarAccionAdjuntos = () => {
      mostrarModalAdjuntos(true);
    }

    if (procesoActual) {
      return (
        <div className='' >
          <Button onClick={() => this.setState({ enListado: true, becaActual: 0 })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
          <div style={{ padding: '0% 2% 0% 2%' }} >
            <Grid container direction={'row'} spacing={1} >
              <Grid item xs={12} sm={12} md={6} >
                <TituloAccion principal='' alterno='¡ Información Básica !' />
                <Paper elevation={0} square className="scroll">
                  <Beca beca={procesoActual} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={12} md={12} >
                    <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccionDetalle('detalle')}>
                      <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccionAdjuntos()}>
                      <AccionesRapidas imagen={study} titulo='¡ Mis Documentos !' sub_titulo='Listo' des_sub_titulo=', Aquí puedes administrar tus documentos.' />
                    </Paper>
                  </Grid>
                  {procesoActual && procesoActual.volante ?
                    <Grid item xs={12} sm={12} md={12} >
                      <Link href={`${api}${procesoActual.volante}`} target="_blank" underline='none'>
                        <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => { }}>
                          <AccionesRapidas imagen={email} titulo='¡ Mi Volante !' sub_titulo='Felicidades' des_sub_titulo=', Aqui puedes ver y descargar tu volante.' />
                        </Paper>
                      </Link>
                    </Grid>
                    : ''}
                </Grid>
              </Grid>
              {
                procesoActual.estado_actual.codigo !== 'Est_bec_neg' && procesoActual.estado_actual.codigo !== 'Est_bec_can' &&
                <Grid item xs={12} sm={12} md={12} >
                  <TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
                  {this.lineaDeEstados()}
                </Grid>
              }
            </Grid>
          </div >
        </div>
      )
    }
  }

  pintarNuevo = () => {
    let { mostrarModalAddBeca } = this.props
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
        <Grid item xs={10} sm={8} md={6} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalAddBeca(true) }}>
          <Paper elevation={8} square>
            <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
              <CardHeader
                avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>N</Avatar>}
                title={`Nueva Solicitud`}
              />
              <CardContent >
                <Grid container direction={'row'} justifyContent={'center'}>
                  <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                    <img src={assigment} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                    <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                      Para iniciar una solicitud de becas haz clic aqui.
                        </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  pintarHistorial() {
    let { historial } = this.state;
    let { mostrarModalDetalleBeca } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Est_bec_env': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Est_bec_rev': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Est_bec_apr': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_apl': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Est_bec_neg': { 'backgroundColor': coloresEmma.solicitudcancelada, color },
        'Est_bec_can': { 'backgroundColor': coloresEmma.solicitudcancelada, color }
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      const onClickDetalle = ({ id, solicitante }) => {
        this.setState({ becaActual: id, id_estudiante: solicitante.id })
        mostrarModalDetalleBeca(true)
      }
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      return detalle;
    }

    return (
      <div>
        <ListarDatos
          id="tbl_mis_adjuntos"
          titulo='Lista de Becas'
          datos={historial}
          buscar={true}
          opciones={true}
          sub_titulo="Lista de adjuntos"
          avatar={({ estado_actual }) => estado_actual.nombre.charAt(0).toUpperCase()}
          color_avatar={({ estado_actual: { codigo } }) => obtenerColor(codigo)}
          acciones={(row) => acciones(row)}
          fila_principal={({ tipo_beca }) => tipo_beca.nombre.toUpperCase()}
          filas={[
            { 'id': 'tipo_estado', 'enLista': true, 'mostrar': ({ estado_actual }) => estado_actual.nombre.toUpperCase() },
            { 'id': 'beca', 'enLista': false, 'mostrar': ({ tipo_beca }) => tipo_beca.nombre.toUpperCase() },
            { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), nombre: 'Fecha Registro: ' }
          ]}
        />
      </div>
    )
  }

  lineaDeEstados = () => {
		let { cargando, lineaEstados, procesoActual } = this.state;
		if (!cargando && procesoActual && lineaEstados.length > 0) {
			let activeStep = 0;
			let steps = [];
			lineaEstados.map(({ secundario }, i) => {
				if (secundario.id === procesoActual.estado_actual.id) activeStep = i;
				steps.push({ label: secundario.nombre })
      })
      activeStep = procesoActual.estado_actual.codigo === 'Est_bec_apr' ? lineaEstados.length + 1 : activeStep;
			return <BarraProceso steps={steps} activeStep={activeStep} />;
		}
		return null;
	}
  render() {
    let { modalAddBeca, modalDetBeca, mostrarModalDetalleBeca, mostrarModalAddBeca, modalAdjuntos, actualizarMensaje, mostrarModalAdjuntos, modalAddAdj, mostrarModalAddAdjBeca, mostrarModalValidacion, modalValidacion } = this.props
    let { tipos, carreras, telefono, celular, seleccion, cargando, becaActual, procesoActual, id_estudiante, mensaje_beca } = this.state

    return (
      <div>
        <MenuEstudiante sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} />
        <div className='contenido_modulo'>
          <BecasCrear
            obtenerTiposBecas={this.obtenerTiposBecas}
            obtenerCarreras={this.obtenerCarreras}
            guardarBeca={this.guardarBeca}
            actualizarMensaje={actualizarMensaje}
            modalAddBeca={modalAddBeca}
            mostrarModalAddBeca={mostrarModalAddBeca}
            mostrarModalValidacion={mostrarModalValidacion}
            modalValidacion={modalValidacion}
            tipos={tipos}
            carreras={carreras}
            telefono={telefono}
            celular={celular}
            mensaje_beca={mensaje_beca}
          />
          <BecaAdjuntos 
            mostrarModalAdjuntos = {mostrarModalAdjuntos}
            modalAdjuntos = {modalAdjuntos}
            mostrarModalAddAdjBeca = {mostrarModalAddAdjBeca}
            modalAddAdj = {modalAddAdj}
            beca = {becaActual}
            actualizarMensaje = {actualizarMensaje}
            infoBeca = {procesoActual}
          />
          <BecaDetalle 
            beca = {becaActual}
            id_estudiante = {id_estudiante}
            mostrarModalDetalleBeca = {mostrarModalDetalleBeca}
            modalDetBeca = {modalDetBeca}
            vistaDetalle='detalle'
            admin = {false}
          />        
          {
            !cargando ?  this.vista() :
            <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }  
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalAddBeca, modalAdjuntos, modalDetBeca, modalAddAdj, modalValidacion } = state.redBecas
  return {
    modalAddBeca,
    modalAdjuntos,
    modalDetBeca,
    modalAddAdj,
    modalValidacion
  }
}

const mapDispatchToProps = {
  mostrarModulo,
  actualizarMensaje,
  mostrarModalAddBeca,
  mostrarModalAdjuntos,
  mostrarModalDetalleBeca,
  mostrarModalAddAdjBeca,
  mostrarModalValidacion
}

Becas.propTypes = {
  //funciones
  mostrarModulo: PropTypes.func.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalAddBeca: PropTypes.func.isRequired,
  mostrarModalAdjuntos: PropTypes.func.isRequired,
  mostrarModalDetalleBeca: PropTypes.func.isRequired,
  mostrarModalAddAdjBeca: PropTypes.func.isRequired,
  mostrarModalValidacion: PropTypes.func.isRequired,
  //variables
  modalAdjuntos: PropTypes.bool.isRequired,
  modalAddBeca: PropTypes.bool.isRequired,
  modalDetBeca: PropTypes.bool.isRequired,
  modalAddAdj: PropTypes.bool.isRequired,
  modalValidacion: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Becas);
