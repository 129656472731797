import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../general/BotonesAccion";
import AppBarModal from '../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { transformar, consulta } from "../../global/js/funciones";
import InputBuscar from '../general/InputBuscar';
import BuscarSelect from '../general/BuscarSelect';
import BuscarPersona from '../general/BuscarPersona'
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';

class ClaseFiltrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
      periodos: [],
      periodo: null,
      asignaturas: [],
      asignatura: null,
      estado: null,
      estudiante: {},
      profesor: {},
      openModalBuscarPersona: false,
      tipoBuscarPersona: 0,
    };
  }

  async componentDidUpdate({ openFiltrosModal }) {
    let { openFiltrosModal: openFiltrosModalNew } = this.props;
    if (openFiltrosModal !== openFiltrosModalNew && openFiltrosModalNew) {
      this.setState({ cargando: true });
      let periodos = await this.obtenerPeriodos();
      let asignaturas = await this.obtenerAsignaturas();
      this.setState({
        'periodos': transformar(periodos, 'periodo', 'periodo'),
        'asignaturas': transformar(asignaturas, 'codigo_asignatura', 'asignatura'),
        cargando: false,
      });
    }
  }

  obtenerPeriodos = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/invitados/periodos`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    })
  }

  obtenerAsignaturas = () => {
    return new Promise(resolve => {
      consulta(`api/v1.0/invitados/asignaturas`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  handleClickSeleccionarPersona = (data) => {
    let { actualizarMensaje } = this.props;
    let { tipoBuscarPersona } = this.state;
    let persona = { id: data.id, nombre: `${data.primer_nombre} ${data.primer_apellido} ${data.segundo_apellido}` };

    if (tipoBuscarPersona === 0) this.setState({ estudiante: persona });
    else this.setState({ profesor: persona });

    this.setState({ openModalBuscarPersona: false });
    actualizarMensaje({ titulo: '¡Persona seleccionada!', tipo: 'success', mostrar: true, tiempo: 2000 });
  }

  resetState = () => {
    let { limpiar, closeFiltrosModal } = this.props;
    this.setState({
      periodo: null,
      estado: null,
      profesor: {},
      estudiante: {},
      asignatura: null,
    });
    limpiar();
    closeFiltrosModal();
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje } = this.props;
    let {
      estudiante,
      profesor,
      asignatura,
      estado,
      periodo
    } = this.state;

    let filtros = [];
    if (Object.entries(estudiante).length > 0) filtros.push({ 'llave': 'usuario_registro', 'valor': estudiante.id });
    if (Object.entries(profesor).length > 0) filtros.push({ 'llave': 'profesor', 'valor': profesor.id });
    if (asignatura !== null) filtros.push({ 'llave': 'codigo_asignatura', 'valor': asignatura.value })
    if (estado !== null) filtros.push({ 'llave': 'estado', 'valor': estado });
    if (periodo !== null) filtros.push({ 'llave': 'periodo', 'valor': periodo.value });
    if (filtros.length > 0) filtrar(filtros);
    else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { openFiltrosModal, closeFiltrosModal } = this.props;
    let { cargando, periodos, periodo, estado, estudiante, profesor, asignaturas, asignatura, openModalBuscarPersona, tipoBuscarPersona } = this.state;
    return (
      <Fragment>
        <Dialog open={openFiltrosModal} onClose={closeFiltrosModal} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
          <AppBarModal titulo='¡Aplicar Filtros!' mostrarModal={closeFiltrosModal} titulo_accion="Limpiar" accion={this.resetState} />

          <DialogContent className='scroll'>
            {
              cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <Grid container spacing={3} className='margin_cont'>
                <Grid item xs={12} >
                  <BuscarSelect datos={periodos} cambiarEstado={(value) => this.setState({ periodo: value })} valor={periodo} id='select-periodo' nombre='Periodo' placeholder='Seleccione Periodo' />
                </Grid>

                <Grid item xs={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="estado">Estado</InputLabel>
                    <Select
                      inputProps={{
                        name: 'estado',
                        id: 'estado',
                      }}
                      value={estado}
                      onChange={this.onChange}
                      required={true}>
                      <MenuItem value={1}>Activo</MenuItem>
                      <MenuItem value={0}>Cancelado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} >
                  <BuscarSelect datos={asignaturas} cambiarEstado={(value) => this.setState({ asignatura: value })} valor={asignatura} id='select-asignatura' nombre='Asignatura' placeholder='Seleccione Asignatura' />
                </Grid>

                <Grid item xs={12} >
						      <InputBuscar placeholder="Buscar Estudiante" label="Estudiante" id="estudiante" value={estudiante.nombre || ''} callback={() => this.setState({ openModalBuscarPersona: true, tipoBuscarPersona: 0 })} />
                </Grid>

                <Grid item xs={12} >
						      <InputBuscar placeholder="Buscar Profesor" label="Profesor" id="profesor" value={profesor.nombre || ''} callback={() => this.setState({ openModalBuscarPersona: true, tipoBuscarPersona: 1 })} />
                </Grid>
              </Grid>
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={closeFiltrosModal} />
            <BtnForm texto="FILTRAR" callback={this.onSubmit} />
          </DialogActions>
        </Dialog>

        <Dialog onClose={() => this.setState({ openModalBuscarPersona: false })} open={openModalBuscarPersona} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
					<AppBarModal titulo={tipoBuscarPersona === 0 ? 'Buscar Estudiante' : 'Buscar Profesor'} mostrarModal={() => this.setState({ openModalBuscarPersona: false })} titulo_accion="Buscar Persona" />
					<DialogContent style={{ padding: '0' }} className='scroll'>
						<BuscarPersona callback={(data) => this.handleClickSeleccionarPersona(data)} />
					</DialogContent>
					<DialogActions></DialogActions>
				</Dialog>
      </Fragment>
    )
  }
}

export default ClaseFiltrar;
