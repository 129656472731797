
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import AppBarModal from '../general/AppBarModal';
import {Dialog, DialogContent, Grid, Paper, Typography} from '@material-ui/core';
import TareasTerminadas from "../general/TareasTerminadas";
import emma_w from '../../global/imagenes/emma_w.png';
import { consulta} from "../../global/js/funciones";
import {Banner } from './ui';
import { ArgumentAxis, ValueAxis, Chart, BarSeries} from '@devexpress/dx-react-chart-material-ui';
import { TableHead, TableRow, TableCell, TableBody, Table} from '@material-ui/core';
  

 
class DetalleOva extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      procesosInfo:[],
      proceso:null,
      datos:[],
      linguistica:0,
      matematica:0,
      musical:0,
      kinestesica:0,
      intrapersonal:0,
      interpersonal:0,
      naturalistaPictorica:0,
      espacial:0,
    }
  }
  

   componentDidUpdate({modalDetalleOva },{ seleccion }) {
    let { modalDetalleOva: modalDetalleOvaNew } = this.props;
    if (modalDetalleOva !== modalDetalleOvaNew) {
      this.obtenerInformacionPrincipal()
    } 
  }

  async obtenerInformacionPrincipal(){
    this.setState({ cargando: true })
    let datos = await this.obtenerDatos();
    let linguistica = parseFloat(datos[0].question_1_01)
    let matematica = parseFloat(datos[0].question_2_01)
    let musical = parseFloat(datos[0].question_3_01)
    let kinestesica  = parseFloat(datos[0].question_4_01)
    let intrapersonal = parseFloat(datos[0].question_5_01)
    let interpersonal = parseFloat(datos[0].question_6_01)
    let naturalistaPictorica = parseFloat(datos[0].question_7_01)
    let espacial = parseFloat(datos[0].question_8_01)
    this.setState({datos, linguistica, matematica, musical, kinestesica, intrapersonal, interpersonal, 
      naturalistaPictorica, espacial, cargando: false})
  }

   obtenerDatos(){
      let {proceso} = this.props
      let f = proceso.id
      return new Promise(resolve => {
         consulta(`api/v1.0/ova/listar/detalle/${f}?`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
        })
      })

  } 

  pintar(){
    let { linguistica, matematica, musical, kinestesica, intrapersonal, interpersonal, naturalistaPictorica,
      espacial, cargando, datos} = this.state;
    let {proceso} = this.props
  const data = [
    { argument: 'Linguistica', value: linguistica },
    { argument: 'Matematica', value: matematica },
    { argument: 'Musical', value: musical },
    { argument: 'Kinestesica', value: kinestesica },
    { argument: 'Intrapersonal', value: intrapersonal },
    { argument: 'Interpersonal', value: interpersonal },
    { argument: 'Pictorica', value: naturalistaPictorica },
    { argument: 'Espacial', value: espacial },
  ];
  if(!cargando){
    return(
      <>
        <Banner nombre={proceso.nombre}/>
       
         <Grid container direction={'row'} justifyContent={'center'} style={{ padding: '30px 0px' }} >
          <Grid item xs={12}  md={8}> 
          <Table >
            <TableHead>
              <TableRow>
              <TableCell align="center" colSpan={3}> Informacion Basica del Aspirante</TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Nombre</TableCell>
                <TableCell align="left">{datos[0].nombre.toUpperCase()}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Documento</TableCell>
                <TableCell align="left">{datos[0].cedula.toUpperCase()}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Correo</TableCell>
                <TableCell align="left">{datos[0].correo.toUpperCase()}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Edad - Genero</TableCell>
                <TableCell align="left">{`${datos[0].edad} - ${datos[0].sexo.toUpperCase()}`}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Telefonos</TableCell>
                <TableCell align="left">{`${datos[0].telefono1} - ${datos[0].telefono2}`}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Vida Universitaria</TableCell>
                <TableCell align="left">{datos[0].conocer_vida_Universitaria.toUpperCase()}</TableCell>
              </TableRow>
            </TableBody>
          </Table> 
          
          </Grid> 
          <Grid item xs={12} md={8} style={{marginTop:'50px'}}> 
            <Paper>
            <Chart data={data}>
              <ArgumentAxis />
              <ValueAxis />
              <BarSeries valueField="value" argumentField="argument" />
            </Chart>
          </Paper>
          </Grid>
          <Grid item xs={12} md={8} style={{marginTop:'50px'}}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell align="center" colSpan={3}> Resultado Consolidado de Acuerdo a Cada tipo de Inteligencia</TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Lingüistica</TableCell>
                <TableCell align="left">{linguistica}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Lógico-Matemática</TableCell>
                <TableCell align="left">{matematica}</TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="th" scope="row">Musical</TableCell>
                <TableCell align="left">{musical}</TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="th" scope="row">Corporal - Kinestésica</TableCell>
                <TableCell align="left">{kinestesica}</TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="th" scope="row">Intrapersonal</TableCell>
                <TableCell align="left">{intrapersonal}</TableCell>
              </TableRow>
              <TableRow key={6}>
                <TableCell component="th" scope="row">Interpersonal</TableCell>
                <TableCell align="left">{interpersonal}</TableCell>
              </TableRow>
              <TableRow key={7}>
                <TableCell component="th" scope="row">Naturalista-Pictórica</TableCell>
                <TableCell align="left">{intrapersonal}</TableCell>
              </TableRow>
              <TableRow key={8}>
                <TableCell component="th" scope="row">Espacial</TableCell>
                <TableCell align="left">{espacial}</TableCell>
              </TableRow>
            </TableBody>
          </Table> 
          </Grid> 
          <Grid item xs={12}  md={8} style={{ paddingTop: 20 }}> 
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px 20px',
              backgroundColor: '#eaefef',
              borderRadius: '10px',
              width:'100%'
            }}>
              <Typography variant="h6" gutterBottom>Ambiente donde le gustaria trabajar:</Typography>
              <span>{datos[0].tipo_Empresa}</span>
            </div>
          </Grid>
          <Grid item xs={12}  md={8} style={{ paddingTop: 20 }}> 
            <div style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              padding: '30px 20px',
              backgroundColor: '#eaefef',
              borderRadius: '10px',
              width:'100%'
            }}>
              <Typography variant="h6" gutterBottom>Carreras sugeridas de acuerdo a los tipos de Inteligencia Predominante:</Typography>
              <span>{datos[0].resultado}</span>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }else{
    return(
      <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
    )
  }

  }


  render() {
    let { modalDetalleOva, mostrarModalDetalleOva} = this.props
    return (
      <>
         
         <Dialog fullWidth={true} maxWidth="lg" open={modalDetalleOva} onClose={() => mostrarModalDetalleOva(false)}>
         <AppBarModal titulo={" ¡ Detalle OVA ! "} mostrarModal={() => mostrarModalDetalleOva(false)} titulo_accion="Cerrar" accion={() => mostrarModalDetalleOva(false)}/>
         <DialogContent className='scroll'>
            <Grid container>
            <Grid item xs={12}  md={12}> 
                {
                   this.pintar()
                }
              </Grid>
           </Grid>
             
         </DialogContent>
        
       </Dialog > 
      </>
    )
    }
  
}

DetalleOva.propTypes = {
  actualizarMensaje: PropTypes.func.isRequired,
  modalDetalleOva: PropTypes.bool.isRequired,
}



export default DetalleOva


