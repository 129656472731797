import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//GENERALES
import emma_s from "../../global/imagenes/emma_s1.png";
import emma_w from "./../../global/imagenes/emma_w.png";
import TareasTerminadas from "../general/TareasTerminadas";
import { StyleSheet } from "@react-pdf/renderer";
import {
  coloresEmma,
  consulta,
  crear_form_data,
  formulario,
  mostrarError,
  obtenerValoresFiltros,
} from "../../global/js/funciones";
import AppBarModal from "../general/AppBarModal";
import {
  BtnForm,
  BtnEliminar,
  BtnRegresar,
  BtnEditar,
  BtnRegresarinicio,
} from "../general/BotonesAccion";
import matricula_ from "./../../global/imagenes/matricula_.png";
import TituloAccion from "../general/TituloAccion";
import AccionesRapidas from "../general/AccionesRapidas";
import { actualizarMensaje } from "../../redux/actions/actGlobal";
//SISEF
import { Menu } from "./sisef/MenuSisef";
import { ITEMS_PROCESOS, SISEF_CORTES, SISEF_MASIVO } from "./helper";
import SisefOpcionesTipo from "./sisef/SisefOpcionesTipo";
import Sisef from "./Sisef";
import { RubricasTables } from "./sisef/SisefTables";
import TablasRubricas from "./sisef/TablasRubricas";
import SisefCardProcesos from "./sisef/SisefCardProcesos";
import ConfirmarAccion from "../general/ConfirmarAccion";
import ListarDatos from "../general/ListarDatos";
import AlertasSimple from "../general/AlertasSimple";
import test from "./../../global/imagenes/rubricas.png";
import { CargaMasiva } from "./sisef/cargaMasiva";
const steps = ["DATOS INICIALES", "OBJETIVOS"];

const styles = StyleSheet.create({
  btn_carga: {
    backgroundColor: coloresEmma.secondarycolor,
    color: "white",
    borderRadius: "10px",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  },
});

class Sisef_Rubrica extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      seleccion: "miproceso",
      mensaje: "",
      rubricas: [],
      cortes_sisef: [],
      asignaturas: [],
      corte: {},
      asignatura: {},
      criterio: {},
      excelente: {},
      avanzado: {},
      satisfactorio: {},
      minimo: {},
      insuficiente: {},
      rubrica_actual: {},
      relacion_rubrica: {},
      id_actual: {},
      objetivo: {},
      retroalimentacion: {},
      data_rubrica: [],
      objetivos: [],
      CrearAsignaturaRel: false,
      AgregarRubrica: false,
      ModificarRubrica: false,
      Listado: false,
      estado: true,
      MostrarRubricas: false,
      Eliminar: false,
      ModalDetalle: false,
      ModificarObjetivo: false,
      EliminarObj: false,
      periodo_actual: null,
      objetivo_actual: {},
      activeStep: 0,
      Modal: {},
      nivel_criterio: {},
      modalCargarRubricas: false,
      titulo: 'Cargando..',
      archivo_rubrica: []
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  async componentDidMount() {
    this.obtenerDatosIniciales();
  }

  async componentDidUpdate({ }, { seleccion, ModalDetalle, modalCargarRubricas }) {
    let { ModalDetalle: ModalDetalleNew, modalCargarRubricas: modalCargarRubricasNew } = this.state;
    let { seleccion: seleccionNew } = this.state;
    if (seleccion !== seleccionNew) {
      if (seleccionNew === "miproceso") this.obtenerDatosIniciales();
      else if (seleccionNew === "nuevo") this.setState({ nuevo: true });
    }
    if (ModalDetalle !== ModalDetalleNew) {
      if (!ModalDetalleNew) {
        this.setState({ objetivos: [] })
      }
    }
    if (modalCargarRubricas !== modalCargarRubricasNew) {
      if (modalCargarRubricasNew) {
        let archivo_rubrica = await obtenerValoresFiltros([{ llave: "generica", valor: SISEF_MASIVO }, { llave: "estado", valor: "1" }, { llave: "codigo", valor: "masivo_rubricas" }]);
        this.setState({ archivo_rubrica: archivo_rubrica[0].archivo })
      }
    }
  }

  conError(valor) {
    return valor ? true : false;
  }

  reset() {
    this.setState({
      criterio: {},
      excelente: {},
      avanzado: {},
      satisfactorio: {},
      minimo: {},
      insuficiente: {},
      objetivos: [],
      objetivo: {},
      retroalimentacion: {},
      AgregarObjetivo: false,
      activeStep: 0,
      nivel_criterio: {},
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: { valor: value },
    });
  };

  toggleDialog(toggle) {
    let { modal, value } = toggle;
    this.setState({ [modal]: value });
  }
  cambiarEstado = (estados) => {
    this.setState(estados);
  };

  cambiarOpcion = (seleccion) => {
    this.cambiarEstado({ seleccion });
  };

  async obtenerDatosIniciales() {
    this.setState({ cargando: true });
    let mensaje = "Usted aún no cuenta con un proceso";
    let cortes_sisef = await obtenerValoresFiltros([
      { llave: "generica", valor: SISEF_CORTES },
      { llave: "estado", valor: "1" },
    ]);
    let niveles_criterios = await obtenerValoresFiltros([
      { llave: "generica", valor: ITEMS_PROCESOS },
      { llave: "estado", valor: "1" },
    ]);
    let data_periodo = await obtenerValoresFiltros([
      { llave: "codigo", valor: "Per_Sisef" },
      { llave: "estado", valor: "1" },
    ]);
    data_periodo = data_periodo[0].nombre;
    let rela_rub = await this.ObtenerRelaciones(data_periodo);
    let asignaturas = await this.obtenerAsignaturas(data_periodo);
    console.log(rela_rub);
    this.setState({
      mensaje,
      cargando: false,
      cortes_sisef,
      rubricas: rela_rub,
      asignaturas: asignaturas,
      periodo_actual: data_periodo,
      niveles_criterios: niveles_criterios,
    });
  }

  async obtenerAsignaturas(periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/docente_asignaturas/${0}/${periodo}/${0}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObjetivosRubrica(rubrica) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/obtener_objetivos/${rubrica}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async VerificarRelacion(asignatura) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/verificar_relacion/${asignatura}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerRelaciones(periodo) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_relaciones/${periodo}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  async ObtenerRubricas(corte, relacion) {
    return new Promise((resolve) => {
      consulta(
        `api/v1.0/sisef/listar_rubricas/${corte}/${relacion}`,
        null,
        null,
        (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
        }
      );
    });
  }

  ObtenerObjetivos = async (rubrica) => {
    console.log(rubrica);
    let obj = await this.ObjetivosRubrica(rubrica);
    this.setState({ objetivos: obj, ModalDetalle: true, id_actual: rubrica });
  };

  AddObjetivos = async (e) => {
    let { objetivo, retroalimentacion, objetivos, ModalDetalle, id_actual, nivel_criterio, niveles_criterios } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    let nombre_nivel = ""
    if (objetivos.some(obj => obj.criterio_tipo.id === nivel_criterio.valor)) {
      nivel_criterio.error = "Ya existe un objetivo con este nivel de criterio";
      error = true;
    } else {
      nombre_nivel = niveles_criterios.filter(nivel => nivel.id == nivel_criterio.valor)
      error = false
    }
    if (!objetivo.valor || !retroalimentacion.valor || !nivel_criterio.valor) {
      if (!objetivo.valor) {
        objetivo.error = obligatorio;
        error = true;
      }
      if (!retroalimentacion.valor) {
        retroalimentacion.error = obligatorio;
        error = true;
      }
      if (!nivel_criterio.valor) {
        nivel_criterio.error = obligatorio;
        error = true;
      }
    } else if (!error) {
      let cont = objetivos.length;
      if (ModalDetalle) {
        let data = {
          objetivo: objetivo.valor,
          criterio_tipo: {
            id: nivel_criterio.valor,
            nombre: nombre_nivel[0].nombre,
          },
          retroalimentacion: retroalimentacion.valor,
        };
        this.AgregarObjetivoDB(data)
      }
      let nuevo = {
        id: cont,
        objetivo: objetivo.valor,
        criterio_tipo:
          ModalDetalle ? {
            id: nivel_criterio.valor,
            nombre: nombre_nivel[0].nombre,
          } : nivel_criterio.valor,
        retroalimentacion: retroalimentacion.valor,
      };
      objetivos.push(nuevo);
      this.setState({
        objetivos: objetivos,
        objetivo: {},
        retroalimentacion: {},
        nivel_criterio: {},
        AgregarObjetivo: false,
      });
    }
    if (error) this.setState({ objetivo, retroalimentacion, nivel_criterio });
  };

  ModObjetivos = async (e) => {
    let { objetivo, retroalimentacion, objetivo_actual, nivel_criterio, objetivos, niveles_criterios } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    let nombre_nivel = ""
    if (objetivos.some(obj => obj.criterio_tipo.id == nivel_criterio.valor) && objetivo_actual.criterio_tipo.id != nivel_criterio.valor) {
      nivel_criterio.error = "Ya existe un objetivo con este nivel de criterio";
      error = true;
    } else error = false
    if (!objetivo.valor || !retroalimentacion.valor || !nivel_criterio.valor) {
      if (!objetivo.valor) {
        objetivo.error = obligatorio;
        error = true;
      }
      if (!retroalimentacion.valor) {
        retroalimentacion.error = obligatorio;
        error = true;
      }
      if (!nivel_criterio.valor) {
        nivel_criterio.error = obligatorio;
        error = true;
      }
    } else if (!error) {
      let data = {
        id: objetivo_actual.id,
        objetivo: objetivo.valor,
        criterio_tipo: nivel_criterio.valor,
        retroalimentacion: retroalimentacion.valor,
      };
      this.UpdateObjetivo(data, objetivo_actual.id);
    }
    if (error) this.setState({ objetivo, retroalimentacion, nivel_criterio });
  };

  handleStep = (direction) => {
    const { actualizarMensaje } = this.props;
    let {
      criterio,
      excelente,
      avanzado,
      satisfactorio,
      minimo,
      insuficiente,
      activeStep,
      objetivos,
    } = this.state;
    switch (activeStep) {
      case 0:
        let obligatorio = "Este campo es obligatorio";
        let error = false;
        if (!criterio.valor) {
          criterio.error = obligatorio;
          error = true;
        }
        if (!excelente.valor) {
          excelente.error = obligatorio;
          error = true;
        }
        if (!avanzado.valor) {
          avanzado.error = obligatorio;
          error = true;
        }
        if (!satisfactorio.valor) {
          satisfactorio.error = obligatorio;
          error = true;
        }
        if (!minimo.valor) {
          minimo.error = obligatorio;
          error = true;
        }
        if (!insuficiente.valor) {
          insuficiente.error = obligatorio;
          error = true;
        }
        if (!error) this.setState({ activeStep: activeStep + 1 });
        else {
          actualizarMensaje({
            titulo: "Debes diligenciar todos los campos para poder continuar",
            tipo: "info",
            mostrar: true,
            tiempo: 6000,
          });
          this.setState({
            criterio,
            excelente,
            avanzado,
            satisfactorio,
            minimo,
            insuficiente,
          });
        }

        break;

      case 1:
        if (direction) {
          if (objetivos.length != 0) {
            this.GuardarRubricas();
            // this.reset();
          } else {
            actualizarMensaje({
              titulo: "Debe crear objetivo para poder continuar",
              tipo: "info",
              mostrar: true,
              tiempo: 6000,
            });
          }
        } else this.setState({ activeStep: activeStep - 1 });
        break;

      default:
        break;
    }
  };

  configStepContent = () => {
    let { activeStep } = this.state;

    switch (activeStep) {
      case 0:
        return this.PintarFormCriterios();

      case 1:
        return this.PintarTablaObjetivos();

      default:
        break;
    }
  };

  handleClickCerrar = () => {
    this.setState({ CrearAsignaturaRel: false });
  };

  handleClickCerrarAgregar = () => {
    this.setState({ ModificarRubrica: false, AgregarRubrica: false });
    this.reset();
  };

  pintarNuevo() {
    let { seleccion } = this.state;
    return (
      <div className="">
        <div style={{ padding: "1% 0% 0% 1%" }}>
          <BtnRegresar
            callback={() => this.setState({ seleccion: "inicio" })}
          />
        </div>
        <div style={{ padding: "1% 0% 0% 1%" }}></div>
        <BtnRegresarinicio
          callback={() => this.setState({ seleccion: "inicio" })}
        />
        <div>

          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            style={{ paddingTop: 30 }}
          >
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <Grid container direction={"row"} justify={"center"}>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={6}
                  lg={5}
                  style={{ padding: "1% 2% 0% 2%" }}
                  onClick={() => {
                    this.setState({ CrearAsignaturaRel: true });
                  }}
                >
                  <Paper elevation={8} square>
                    <SisefOpcionesTipo
                      avatarcss={{ background: "#01579b" }}
                      cardcss={{
                        maxWidth: "100%",
                        height: "420px",
                        cursor: "pointer",
                      }}
                      image={test}
                      data={{ opciones: `Nuevo`, nombre: "RUBRICA" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  pintarRubricas() {
    let { rubricas, mensaje } = this.state;
    if (!rubricas || rubricas == null || rubricas.length === 0) {
      return (
        <>
          <div style={{ padding: "1% 0% 0% 1%" }}>
            <BtnRegresar
              callback={() => this.setState({ seleccion: "inicio" })}
            />
          </div>
          <Grid container direction={"row"} spacing={2}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={10} sm={10} md={11} lg={11} container style={{ justifyContent: 'flex-end', marginLeft: '4.5rem' }} >
                <Button
                  onClick={() => this.setState({ modalCargarRubricas: true })}
                  variant="contained"
                  color="secondary"
                  style={styles.btn_carga}
                >
                  Cargar Rúbricas
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <div
            className="pointer"
            onClick={() => this.setState({ seleccion: "nuevo" })}
          >
            <TareasTerminadas
              mensaje="Usted aún no cuenta con un proceso"
              marginTop="7%"
              imagen={emma_s}
              widthImg="7%"
            />
          </div>
        </>
      );
    } else {
      const handleClick = async (syll) => {
        this.setState({ estado: false, rubrica_actual: syll });
      };
      return (
        <div className="pointer">
          <div style={{ padding: "1% 0% 0% 1%" }}>
            <BtnRegresar
              callback={() => this.setState({ seleccion: "inicio" })}
            />
          </div>
          <div style={{ padding: "2% 2% 0% 2%" }}>
            <>
              <Grid container direction={"row"} spacing={2}>
                <Grid
                  container
                  direction={"row"}
                  alignItems="center"
                  spacing={1}
                  style={{ width: "100%" }}
                >
                  <Grid container spacing={1} direction="row">
                    <Grid item xs={5} sm={5} md={7} lg={9}>
                      <TituloAccion principal="" alterno="¡ Lista de Procesos !" />
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={2} style={{ marginLeft: '4.5rem' }}>
                      <Button
                        onClick={() => this.setState({ modalCargarRubricas: true })}
                        variant="contained"
                        color="secondary"
                        style={styles.btn_carga}
                      >
                        Cargar Rúbricas
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {rubricas.map((syll) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={syll.id}>
                    <Paper
                      elevation={1}
                      onClick={() => handleClick(syll)}
                      className="pointer"
                    >

                      <SisefCardProcesos
                        procesos={`Rubrica`}
                        nombre={syll.nombre_asignatura}
                        descripcion={`Esta rubrica es de ${syll.nombre_asignatura}. Clic aquí para obtener mas información.`}
                        imagen={matricula_}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </>
          </div>
        </div>
      );
    }
  }

  PintarFormCriterios() {
    let { criterio, excelente, avanzado, satisfactorio, minimo, insuficiente } =
      this.state;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl className="form-control">
            <TextField
              id="criterio"
              type="text"
              name="criterio"
              label="Criterio de evaluación"
              multiline
              defaultValue=""
              value={criterio.valor}
              onChange={this.onChange}
              error={this.conError(criterio.error)}
              helperText={criterio.error}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl className="form-control">
            <TextField
              id="excelente"
              type="text"
              name="excelente"
              label="Excelente"
              multiline
              defaultValue=""
              value={excelente.valor}
              onChange={this.onChange}
              error={this.conError(excelente.error)}
              helperText={excelente.error}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl className="form-control">
            <TextField
              id="avanzado"
              type="text"
              name="avanzado"
              label="Avanzado"
              multiline
              value={avanzado.valor}
              defaultValue=""
              onChange={this.onChange}
              error={this.conError(avanzado.error)}
              helperText={avanzado.error}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl className="form-control">
            <TextField
              id="satisfactorio"
              type="text"
              name="satisfactorio"
              label="Satisfactorio"
              multiline
              value={satisfactorio.valor}
              defaultValue=""
              onChange={this.onChange}
              error={this.conError(satisfactorio.error)}
              helperText={satisfactorio.error}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl className="form-control">
            <TextField
              id="minimo"
              type="text"
              name="minimo"
              label="Minimo"
              value={minimo.valor}
              multiline
              defaultValue=""
              onChange={this.onChange}
              error={this.conError(minimo.error)}
              helperText={minimo.error}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl className="form-control">
            <TextField
              id="insuficiente"
              type="text"
              name="insuficiente"
              label="Insuficiente"
              multiline
              value={insuficiente.valor}
              defaultValue=""
              onChange={this.onChange}
              error={this.conError(insuficiente.error)}
              helperText={insuficiente.error}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  PintarTablaObjetivos() {
    let { objetivos, objetivo, AgregarObjetivo, retroalimentacion } =
      this.state;
    return (
      <>
        <ListarDatos
          id="tbl_objetivos"
          datos={objetivos}
          agregar={true}
          acciones={(row) => this.accionesObj(row)}
          actAgregar={() => this.setState({ AgregarObjetivo: true })}
          fila_principal={({ objetivo }) => `${objetivo}`}
          filas={[
            {
              mostrar: ({ retroalimentacion }) => `${retroalimentacion}`,
              id: "retroalimentacion",
              enLista: true,
            },
          ]}
        />
      </>
    );
  }

  async ActualizarRubricas(cod) {
    let { rubrica_actual } = this.state;
    let rubric = await this.ObtenerRubricas(cod, rubrica_actual.id);
    this.setState({ data_rubrica: rubric });
  }

  RubricaSeleccionada() {
    let { rubrica_actual } = this.state;
    const handleClick = async (syll) => {
      this.setState({ estado: true });
    };

    const obtenerColor = () => {
      let color = "white";
      const colores = { backgroundColor: "#FD9727", color };
      return colores;
    };

    const handleClickCortes = async (cod, titulo) => {
      this.ActualizarRubricas(cod);
      let { Modal, data_rubrica } = this.state;
      Modal.Titulo = titulo;
      Modal.Codigo = cod;
      this.setState({ Modal, MostrarRubricas: true, data_rubrica: [] });
    };

    return (
      <>
        <div className="">
          <Button
            onClick={() => handleClick()}
            style={{ padding: "0px 3px 0px 3px", marginTop: "1%" }}
            color="secondary"
          >
            {" "}
            <ArrowBackIcon />{" "}
            <Typography
              variant="body2"
              style={{ color: "#9e9e9e" }}
              component="p"
              align="justify"
            >
              {" "}
            </Typography>{" "}
          </Button>
          <div style={{ padding: "0% 2% 0% 2%", marginTop: "2%" }}>
            <BtnRegresarinicio
              callback={() => this.setState({ seleccion: "inicio" })}
            />
            <Grid container direction={"row"}>
              <Grid item xs={12} sm={12} md={6}>
                <Paper elevation={0} square className="scroll">
                  <RubricasTables nombre={rubrica_actual.nombre_asignatura} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  style={{ width: "100%", margin: "0px 10px" }}
                >
                  <TituloAccion principal="" alterno="¿ Que deseas hacer ?" />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    onClick={() =>
                      handleClickCortes("primer_corte_sisef", "Primer Corte")
                    }
                  >
                    <Paper
                      elevation={1}
                      style={{ border: "1px solid #e0e0e0" }}
                    >
                      <AccionesRapidas
                        imagen=""
                        titulo="¡ Primer Corte !"
                        sub_titulo="Bien"
                        des_sub_titulo=", Aquí puedes ver toda la información actual."
                        avatar={obtenerColor()}
                        avatar_name={"1"}
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    onClick={() =>
                      handleClickCortes("segundo_corte_sisef", "Segundo Corte")
                    }
                  >
                    <Paper elevation={3}>
                      <AccionesRapidas
                        imagen=""
                        titulo="¡ Segundo Corte !"
                        sub_titulo="Bien"
                        des_sub_titulo=", Aquí puedes ver toda la información actual."
                        avatar={obtenerColor()}
                        avatar_name={"2"}
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    onClick={() =>
                      handleClickCortes("tercer_corte_sisef", "Tercer Corte")
                    }
                  >
                    <Paper
                      elevation={1}
                      style={{ border: "1px solid #e0e0e0" }}
                    >
                      <AccionesRapidas
                        imagen=""
                        titulo="¡ Tercer Corte !"
                        sub_titulo="Bien"
                        des_sub_titulo=", Aquí puedes ver toda la información actual."
                        avatar={obtenerColor()}
                        avatar_name={"3"}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }

  pintarInicio() {
    return <Sisef />;
  }

  accionesObj(rowData) {
    const Eliminar = (row) => {
      let { objetivos } = this.state;
      let aux = objetivos.filter((data) => data.id != row.id);
      this.setState({ objetivos: aux });
      console.log(row);
    };

    return (
      <div>
        <BtnEliminar texto="Eliminar" callback={() => Eliminar(rowData)} />
      </div>
    );
  }

  AddRubricas = () => {
    let {
      criterio,
      excelente,
      avanzado,
      satisfactorio,
      minimo,
      insuficiente,
    } = this.state;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    if (!criterio.valor) {
      criterio.error = obligatorio;
      error = true;
    }
    if (!excelente.valor) {
      excelente.error = obligatorio;
      error = true;
    }
    if (!avanzado.valor) {
      avanzado.error = obligatorio;
      error = true;
    }
    if (!satisfactorio.valor) {
      satisfactorio.error = obligatorio;
      error = true;
    }
    if (!minimo.valor) {
      minimo.error = obligatorio;
      error = true;
    }
    if (!insuficiente.valor) {
      insuficiente.error = obligatorio;
      error = true;
    }
    if (!error) {
      this.Enviar();
    } else
      this.setState({
        criterio,
        excelente,
        avanzado,
        satisfactorio,
        minimo,
        insuficiente,
      });
  };

  Enviar = () => {
    let {
      criterio,
      excelente,
      avanzado,
      satisfactorio,
      minimo,
      insuficiente,
      Modal,
      rubrica_actual,
      id_actual,
    } = this.state;
    let data = {
      criterio: criterio.valor,
      excelente: excelente.valor,
      avanzado: avanzado.valor,
      satisfactorio: satisfactorio.valor,
      minimo: minimo.valor,
      insuficiente: insuficiente.valor,
      relacion: rubrica_actual.id,
      corte: Modal.Codigo,
    };
    data.corte = id_actual.corte.id;
    this.ModificarRubrica(data, Modal.Codigo, id_actual.id);
  };

  onSubmit = async (e) => {
    let { asignatura, asignaturas, periodo_actual } = this.state;
    let { actualizarMensaje } = this.props;
    let obligatorio = "Este campo es obligatorio";
    let error = false;
    let titulo = "";
    let tipo = "info";
    if (!asignatura.valor) {
      asignatura.error = obligatorio;
      error = true;
    }
    if (!error) {
      asignatura.nombre = asignaturas.filter(
        (item) => item.codigo_asignatura == asignatura.valor
      )[0].nombre_asignatura;
      let data = {
        nombre_asignatura: asignatura.nombre,
        codigo_asignatura: asignatura.valor,
        periodo: periodo_actual,
      };
      this.Guardar_Relacion(data);
    } else {
      if (titulo != "") {
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
      this.setState({ asignatura });
    }
  };

  Guardar_Relacion = async (data) => {
    let { actualizarMensaje } = this.props;
    let { seleccion } = this.state;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/crear_rel_rubrica`,
      form,
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.setState({
              CrearAsignaturaRel: false,
              seleccion: "miproceso",
            });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  GuardarRubricas = async () => {
    let {
      criterio,
      excelente,
      avanzado,
      satisfactorio,
      minimo,
      insuficiente,
      Modal,
      rubrica_actual,
      objetivos,
    } = this.state;
    criterio = criterio.valor;
    excelente = excelente.valor;
    avanzado = avanzado.valor;
    satisfactorio = satisfactorio.valor;
    minimo = minimo.valor;
    insuficiente = insuficiente.valor;
    let relacion = rubrica_actual.id;
    let corte = Modal.Codigo;
    const { actualizarMensaje } = this.props;
    consulta(
      `api/v1.0/sisef/${corte}/crear_rubricas`,
      {
        criterio,
        excelente,
        avanzado,
        insuficiente,
        satisfactorio,
        minimo,
        relacion,
        objetivos,
      },
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.setState({ data_rubrica: [] });
            this.ActualizarRubricas(corte);
            this.setState({ AgregarRubrica: false });
            this.reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  ModificarRubrica = async (data, corte, id) => {
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_rubricas`,
      form,
      "patch",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.setState({ data_rubrica: [] });
            this.ActualizarRubricas(corte);
            this.setState({ ModificarRubrica: false });
            this.reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        }
      }
    );
  };

  AgregarObjetivoDB = async (data) => {
    let { id_actual, Modal } = this.state
    let { actualizarMensaje } = this.props;
    console.log(data);
    let retroalimentacion = data.retroalimentacion
    let objetivo = data.objetivo
    let criterio_tipo = data.criterio_tipo.id
    consulta(
      `api/v1.0/sisef/${id_actual}/crear_objetivo`,
      { retroalimentacion, objetivo, criterio_tipo },
      "post",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.ActualizarRubricas(Modal.Codigo);
            this.setState({ AgregarObjetivo: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  UpdateObjetivo = async (data, id) => {
    let { id_actual, Modal } = this.state;
    let { actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(
      `api/v1.0/sisef/${id}/modificar_objetivo`,
      form,
      "patch",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.setState({ data_rubrica: [] });
            this.ActualizarRubricas(Modal.Codigo);
            this.ObtenerObjetivos(id_actual);
            this.setState({
              ModificarObjetivo: false,
              objetivo: {},
              retroalimentacion: {},
            });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        }
      }
    );
  };

  EliminarRubrica = () => {
    let { id_actual, Modal } = this.state;
    let { actualizarMensaje } = this.props;
    console.log(id_actual);
    let codigo = Modal.Codigo;
    consulta(
      `api/v1.0/sisef/${id_actual.id}/eliminar_rubrica`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.setState({ data_rubrica: [] });
            this.ActualizarRubricas(codigo);
            this.setState({ Eliminar: false });
            this.reset();
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };


  EliminarObjetivo = () => {
    let { objetivo_actual, Modal, id_actual } = this.state;
    let { actualizarMensaje } = this.props;
    consulta(
      `api/v1.0/sisef/${objetivo_actual.id}/eliminar_objetivo`,
      {},
      "put",
      (error, estado, resp) => {
        let titulo = "Ha ocurrido un error, contacte con el administrador.",
          tipo = "error";
        if (!error) {
          if (estado === 200) {
            tipo = "success";
            titulo = resp.titulo;
            this.ActualizarRubricas(Modal.Codigo);
            this.ObtenerObjetivos(id_actual);
            this.setState({ EliminarObj: false });
          } else {
            tipo = "info";
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      }
    );
  };

  enviarArchivo = async (archivo) => {
    let { periodo_actual } = this.state;
    let { actualizarMensaje } = this.props;

    this.setState({ modalCargarRubricas: false, cargando: true, titulo: 'Subiendo archivo...' });
    // if (archivo.length === 0) return true;
    let data = await crear_form_data({ archivo });
    formulario(`api/v1.0/sisef/cargarRubricasMasivo`, data, 'post', async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ titulo: 'Cargando...' });
          let rubricas = await this.ObtenerRelaciones(periodo_actual);
          this.setState({ rubricas });
        } else {
          tipo = 'info';
          console.log(resp);
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      this.setState({ cargando: false });
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
    });
  }

  vista = () => {
    let { seleccion, estado } = this.state;
    switch (seleccion) {
      case "miproceso":
        return estado ? this.pintarRubricas() : this.RubricaSeleccionada();
      case "nuevo":
        return this.pintarNuevo();
      default:
        break;
    }
  };

  acciones(rowData) {
    const Eliminar = (row) => {
      this.setState({ EliminarObj: true, objetivo_actual: row });
    };
    const Modificar = (row) => {
      let { objetivo, retroalimentacion, nivel_criterio } = this.state;
      console.log("ROW");
      console.log(row);
      objetivo.valor = row.objetivo;
      nivel_criterio.valor = row.criterio_tipo.id;
      retroalimentacion.valor = row.retroalimentacion;
      this.setState({
        objetivo_actual: row,
        objetivo,
        nivel_criterio,
        retroalimentacion,
        ModificarObjetivo: true,
      });
    };

    return (
      <div>
        <BtnEditar texto="Modificar" callback={() => Modificar(rowData)} />
        <BtnEliminar texto="Eliminar" callback={() => Eliminar(rowData)} />
      </div>
    );
  }

  render() {
    let {
      seleccion,
      cargando,
      asignatura,
      CrearAsignaturaRel,
      ModificarRubrica,
      AgregarRubrica,
      criterio,
      excelente,
      minimo,
      satisfactorio,
      avanzado,
      insuficiente,
      data_rubrica,
      MostrarRubricas,
      Modal,
      Eliminar,
      asignaturas,
      activeStep,
      ModalDetalle,
      objetivos,
      objetivo,
      retroalimentacion,
      ModificarObjetivo,
      EliminarObj, AgregarObjetivo,
      nivel_criterio,
      niveles_criterios,
      modalCargarRubricas, titulo,
      archivo_rubrica
    } = this.state;
    return (
      <>
        <div>
          {seleccion != "inicio" ? (
            <div>
              <Menu
                sele={seleccion}
                seleccionar={(seleccion) => this.cambiarOpcion(seleccion)}
              />
              <div className="contenido_modulo">
                {!cargando ? (
                  this.vista()
                ) : (
                  <TareasTerminadas
                    mensaje={titulo}
                    marginTop="7%"
                    imagen={emma_w}
                    widthImg="7%"
                    cargando={true}
                  />
                )}
              </div>
            </div>
          ) : (
            this.pintarInicio()
          )}
          <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={CrearAsignaturaRel}
            onClose={this.handleClickCerrar}
          >
            <AppBarModal
              titulo={"¡ Rúbrica !"}
              titulo_accion="U. Formación"
              mostrarModal={this.handleClickCerrar}
            />
            <DialogContent className="scroll">
              {
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl
                      className="form-control"
                      error={this.conError(asignatura.error)}
                    >
                      <InputLabel id="asignatura_label">Asignatura</InputLabel>
                      <Select
                        labelId="asignatura_label"
                        id="asignatura"
                        name="asignatura"
                        inputProps={{ min: "0", step: "1" }}
                        fullWidth
                        onChange={this.onChange}
                      >
                        {asignaturas.map((cor) => (
                          <MenuItem
                            key={cor.codigo_asignatura}
                            value={cor.codigo_asignatura}
                          >
                            {cor.nombre_asignatura}
                          </MenuItem>
                        ))}
                      </Select>
                      {asignatura.error && (
                        <FormHelperText>{asignatura.error}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              }
            </DialogContent>
            <DialogActions>
              <BtnForm texto="CANCELAR" callback={this.handleClickCerrar} />
              <BtnForm texto="CREAR" callback={this.onSubmit} />
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={AgregarRubrica}
            onClose={() => this.setState({ AgregarRubrica: false })}
          >
            <AppBarModal
              titulo={"¡ Nueva !"}
              titulo_accion="Rubrica"
              mostrarModal={this.handleClickCerrarAgregar}
            />
            <DialogContent className="scroll">
              {
                <>
                  <Grid item xs={12} md={12}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>
                            <p>{label}</p>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {this.configStepContent(this.reset)}
                  </Grid>
                </>
              }
            </DialogContent>
            <DialogActions>
              {activeStep === 0 ? (
                <div>
                  <BtnForm
                    texto="CANCELAR"
                    callback={this.handleClickCerrarAgregar}
                  />
                  <BtnForm
                    texto="SIGUIENTE"
                    callback={() => this.handleStep(true)}
                  />
                </div>
              ) : (
                <div>
                  <BtnForm
                    texto="ATRAS"
                    callback={() => this.handleStep(false)}
                  />
                  <BtnForm
                    texto="GUARDAR"
                    onSubmit={this.onSubmit}
                    callback={() => this.handleStep(1)}
                  />
                </div>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={ModificarRubrica}
            onClose={this.handleClickCerrarAgregar}
          >
            <AppBarModal
              titulo={"¡ Modificar !"}
              titulo_accion="Rubrica"
              mostrarModal={this.handleClickCerrarAgregar}
            />
            <DialogContent className="scroll">
              {
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="form-control">
                      <TextField
                        id="criterio"
                        type="text"
                        name="criterio"
                        label="Criterio de evaluación"
                        multiline
                        defaultValue=""
                        value={criterio.valor}
                        onChange={this.onChange}
                        error={this.conError(criterio.error)}
                        helperText={criterio.error}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="form-control">
                      <TextField
                        id="excelente"
                        type="text"
                        name="excelente"
                        label="Excelente"
                        multiline
                        defaultValue=""
                        value={excelente.valor}
                        onChange={this.onChange}
                        error={this.conError(excelente.error)}
                        helperText={excelente.error}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="form-control">
                      <TextField
                        id="avanzado"
                        type="text"
                        name="avanzado"
                        label="Avanzado"
                        multiline
                        value={avanzado.valor}
                        defaultValue=""
                        onChange={this.onChange}
                        error={this.conError(avanzado.error)}
                        helperText={avanzado.error}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="form-control">
                      <TextField
                        id="satisfactorio"
                        type="text"
                        name="satisfactorio"
                        label="Satisfactorio"
                        multiline
                        value={satisfactorio.valor}
                        defaultValue=""
                        onChange={this.onChange}
                        error={this.conError(satisfactorio.error)}
                        helperText={satisfactorio.error}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="form-control">
                      <TextField
                        id="minimo"
                        type="text"
                        name="minimo"
                        label="Minimo"
                        value={minimo.valor}
                        multiline
                        defaultValue=""
                        onChange={this.onChange}
                        error={this.conError(minimo.error)}
                        helperText={minimo.error}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="form-control">
                      <TextField
                        id="insuficiente"
                        type="text"
                        name="insuficiente"
                        label="Insuficiente"
                        multiline
                        value={insuficiente.valor}
                        defaultValue=""
                        onChange={this.onChange}
                        error={this.conError(insuficiente.error)}
                        helperText={insuficiente.error}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
            </DialogContent>
            <DialogActions>
              <BtnForm
                texto="CANCELAR"
                callback={this.handleClickCerrarAgregar}
              />
              <BtnForm texto="MODIFICAR" callback={() => this.AddRubricas()} />
            </DialogActions>
          </Dialog>
          <TablasRubricas
            modal={MostrarRubricas}
            modalAgregar={(resp) => this.setState({ AgregarRubrica: resp })}
            cerrarModal={(resp) => this.setState({ MostrarRubricas: resp })}
            EditarModal={(resp) => this.setState({ ModificarRubrica: resp })}
            Eliminar={(resp) => this.setState({ Eliminar: resp })}
            criterio={(resp) => this.setState({ criterio: resp })}
            avanzado={(resp) => this.setState({ avanzado: resp })}
            satisfactorio={(resp) => this.setState({ satisfactorio: resp })}
            minimo={(resp) => this.setState({ minimo: resp })}
            excelente={(resp) => this.setState({ excelente: resp })}
            insuficiente={(resp) => this.setState({ insuficiente: resp })}
            id_actual={(resp) => this.setState({ id_actual: resp })}
            DetalleObjetivo={this.ObtenerObjetivos}
            Titulo={Modal.Titulo}
            Codigo={Modal.Codigo}
            setcodigo={(resp) => this.setState({ Modal: resp })}
            data_rubrica={data_rubrica}
            imagen={emma_s}
          />
          <ConfirmarAccion
            titulo="¿ Eliminar rubrica ?"
            dato={{ id: 0 }}
            mensaje1="¿Estás seguro(a) que deseas eliminar la rubrica?"
            modalConAccion={Eliminar}
            mostrarModalConfAccion={(resp) => this.setState({ Eliminar: resp })}
            ejecutarAccion={this.EliminarRubrica}
          />
        </div>
        {ModalDetalle ? (
          <>
            <Dialog
              fullWidth={true}
              maxWidth="md"
              open={ModalDetalle}
              onClose={() =>
                this.setState({
                  ModalDetalle: false,
                })
              }
              aria-labelledby="form-dialog-title"
            >
              <AppBarModal
                titulo="DETALLE"
                mostrarModal={() =>
                  this.setState({
                    ModalDetalle: false,
                  })
                }
                titulo_accion="DETALLE"
                accion=""
              />
              <DialogContent className="scroll">
                <ListarDatos
                  id="tbl_objetivos"
                  datos={objetivos}
                  titulo="Objetivos"
                  buscar={true}
                  opciones={true}
                  agregar={true}
                  actAgregar={() => this.setState({ AgregarObjetivo: true })}
                  acciones={(row) => this.acciones(row)}
                  fila_principal={({ objetivo }) => `${objetivo}`}
                  filas={[
                    {
                      mostrar: ({ retroalimentacion }) =>
                        `${retroalimentacion}`,
                      id: "retroalimentacion",
                      enLista: true,
                    },
                    {
                      mostrar: ({ criterio_tipo: { nombre } }) =>
                        `${nombre}`,
                      id: "criterio_tipo",
                      enLista: true,
                    },
                  ]}
                />
              </DialogContent>
              <DialogActions>
                <BtnForm
                  texto="CANCELAR"
                  callback={() =>
                    this.setState({
                      ModalDetalle: false,
                    })
                  }
                />
                {/* <BtnForm texto="GUARDAR" callback={this.AddObjetivos} /> */}
              </DialogActions>
            </Dialog>
            <ConfirmarAccion
              titulo="¿ Eliminar Objetivo ?"
              dato={{ id: 0 }}
              mensaje1="¿Estás seguro(a) que deseas eliminar el objetivo?"
              modalConAccion={EliminarObj}
              mostrarModalConfAccion={(resp) =>
                this.setState({ EliminarObj: resp })
              }
              ejecutarAccion={this.EliminarObjetivo}
            />
          </>
        ) : (
          ""
        )}
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={ModificarObjetivo}
          onClose={() =>
            this.setState({
              ModificarObjetivo: false,
              objetivo: {},
              retroalimentacion: {},
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo="MODIFICAR OBJETIVO"
            mostrarModal={() =>
              this.setState({
                ModificarObjetivo: false,
                objetivo: {},
                nivel_criterio: {},
                retroalimentacion: {},
              })
            }
            titulo_accion="MODIFICAR OBJETIVO"
            accion=""
          />
          <DialogContent className="scroll">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="objetivo"
                    type="text"
                    name="objetivo"
                    label="Objetivo"
                    multiline
                    defaultValue=""
                    value={objetivo.valor}
                    onChange={this.onChange}
                    error={this.conError(objetivo.error)}
                    helperText={objetivo.error}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={this.conError(nivel_criterio.error)}
                >
                  <InputLabel htmlFor="parametro">Nivel Criterio</InputLabel>
                  <Select
                    id="nivel_criterio"
                    onChange={this.onChange}
                    name="nivel_criterio"
                    value={nivel_criterio.valor}

                  >
                    {niveles_criterios?.map(({ nombre, id }) => (
                      <MenuItem
                        key={id}
                        value={id}
                      >
                        {`${nombre.toUpperCase()}`}
                      </MenuItem>
                    )
                    )}
                  </Select>
                  {nivel_criterio.error && (
                    <FormHelperText>{nivel_criterio.error}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="retroalimentacion"
                    type="text"
                    name="retroalimentacion"
                    label="Retroalimentación"
                    multiline
                    defaultValue=""
                    value={retroalimentacion.valor}
                    onChange={this.onChange}
                    error={this.conError(retroalimentacion.error)}
                    helperText={retroalimentacion.error}
                  />
                </FormControl>
              </Grid>
              <AlertasSimple
                tipo="info"
                titulo="NOTA:"
                sub_titulo="Estimado profesor en el campo retroalimentación debe describir de una forma clara la forma en la que el estudiante puede cumplir el objetivo"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() =>
                this.setState({
                  ModificarObjetivo: false,
                  objetivo: {},
                  nivel_criterio: {},
                  retroalimentacion: {},
                })
              }
            />
            <BtnForm texto="MODIFICAR" callback={this.ModObjetivos} />
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={AgregarObjetivo}
          onClose={() =>
            this.setState({
              AgregarObjetivo: false,
              objetivo: {},
              retroalimentacion: {},
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <AppBarModal
            titulo="CREAR OBJETIVO"
            mostrarModal={() =>
              this.setState({
                AgregarObjetivo: false,
                objetivo: {},
                retroalimentacion: {},
              })
            }
            titulo_accion="CREAR OBJETIVO"
            accion=""
          />
          <DialogContent className="scroll">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="objetivo"
                    type="text"
                    name="objetivo"
                    label="Objetivo"
                    multiline
                    defaultValue=""
                    value={objetivo.valor}
                    onChange={this.onChange}
                    error={this.conError(objetivo.error)}
                    helperText={objetivo.error}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className="form-control"
                  error={this.conError(nivel_criterio.error)}
                >
                  <InputLabel htmlFor="parametro">Nivel Criterio</InputLabel>
                  <Select
                    id="nivel_criterio"
                    onChange={this.onChange}
                    name="nivel_criterio"
                    value={nivel_criterio.valor}
                  >
                    {niveles_criterios?.map(({ nombre, id }) => (
                      <MenuItem
                        key={id}
                        value={id}
                      >
                        {`${nombre.toUpperCase()}`}
                      </MenuItem>
                    )
                    )}
                  </Select>
                  {nivel_criterio.error && (
                    <FormHelperText>{nivel_criterio.error}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className="form-control">
                  <TextField
                    id="retroalimentacion"
                    type="text"
                    name="retroalimentacion"
                    label="Retroalimentación"
                    multiline
                    defaultValue=""
                    value={retroalimentacion.valor}
                    onChange={this.onChange}
                    error={this.conError(retroalimentacion.error)}
                    helperText={retroalimentacion.error}
                  />
                </FormControl>
              </Grid>
              <AlertasSimple
                tipo="info"
                titulo="NOTA:"
                sub_titulo="Estimado profesor en el campo retroalimentación debe describir de una forma clara la forma en la que el estudiante puede cumplir el objetivo"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() =>
                this.setState({
                  AgregarObjetivo: false,
                  objetivo: {},
                  retroalimentacion: {},
                })
              }
            />
            <BtnForm texto="GUARDAR" callback={this.AddObjetivos} />
          </DialogActions>
        </Dialog>
        {
          modalCargarRubricas && archivo_rubrica && archivo_rubrica.length > 0 && (
            <CargaMasiva
              enviarArchivo={this.enviarArchivo}
              modalCargarMasivo={modalCargarRubricas}
              titulo="Cargar Rúbricas"
              cerrarModal={(resp) => this.setState({ modalCargarRubricas: resp })}
              archivo={archivo_rubrica}
            />
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let { usuario } = state.redGlobal;
  return { usuario };
};

const mapDispatchToProps = {
  actualizarMensaje,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sisef_Rubrica);
