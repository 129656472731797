//Importaciones necesaria para poder utilizar react
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch,useSelector } from 'react-redux';
import { mostrarModulo } from '../../redux/actions/actGlobal';
import { MenuSemillerosAdministrador } from './Hijos/MenuSemilleros'
import AdminListarSemi from './HijosAdmin/AdminListarSemi'
import AdminSemiPermisos from './HijosAdmin/AdminSemiPermisos';
import AdminAdministrar from './HijosAdmin/AdminAdministrar';
import TareasTerminadas from '../general/TareasTerminadas';
import emma_s from '../../global/imagenes/emma_s1.png'
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { coloresEmma} from '../../global/js/funciones';
const useStyles = makeStyles((theme) => ({
    contenidoModulo: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "90px !important"
        },
    }
}));
const SemilleroEstudiante = () => {
    const usuario = useSelector(state => state.redGlobal.usuario);
    const [seleccion, setSeleccion] = React.useState('miproceso');
    const [mensaje, setMensaje] = React.useState(null);
    const [detalle, setDetalle] = React.useState(true)
    const [procesos, setProcesos] = React.useState([])
    const [participanteActual, setParticipanteActual] = React.useState({});
    const [openModalInfo, setOpenModalInfo] = React.useState(false);
    const classes = useStyles()
    const dispatchI = useDispatch();

    React.useEffect(() => {
        console.log(usuario);
        if(usuario.perfil.codigo=='Per_Semi_Ges'||usuario.perfil.codigo=='Per_Adm'){
          dispatchI(mostrarModulo({ ruta: '/gestion_semilleros', nombre: 'Semilleros Gestión' }))
        }else{
            setMensaje('permiso')
        }
      }, []);

    const vista = () => {
    
        switch (seleccion) {
          case 'miproceso':
            return (
                <AdminListarSemi />
            )
          case 'administrar':
              return(
                  <AdminAdministrar />
              )
          default:
            break;
        }
    }

    return (
        <div>
            {mensaje ? (
            <div>
                <Link to="/semilleros">
                 <ArrowBackIcon style={{ fontSize: '35px', color: coloresEmma.secondarycolor, marginTop: '5px' }} />
                </Link>
                <TareasTerminadas mensaje='No tienes permiso para este modulo' marginTop='7%' imagen={emma_s} widthImg="7%" /> 
            </div>
            ) : (
            <>
            <MenuSemillerosAdministrador sele={seleccion} seleccionar={(seleccion) => setSeleccion(seleccion)}/>
            <div className={classes.contenidoModulo}>
                {vista()}
            </div>
            </>
            )}
        </div>
    )
}


/*const PintarSolicitudes = (props) => {

    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true);
    

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                let data = await obtenerParticipantesSolicitudes();
                props.setProcesos(data)
                setCargando(false)
            }
            obtenerProcesos()
        }
    })
    //Obtener semilleros solicitudes
    const obtenerParticipantesSolicitudes = async () => {
        return new Promise(resolve => {
            Funciones.consulta(`api/v1.0/investigacion/listarParticipante`, null, null, (error, estado, resp) => {
              resolve(estado === 200 && !error ? resp : null);
            })
        })
    }
    
    if (props.procesos.length == 0) {
        return (
          <div className="pointer" onClick={() => props.setSeleccion("nuevo")}>
            <TareasTerminadas
              mensaje="No tienes solicitudes activas, clic aquí para crear un Semillero"
              marginTop="7%"
              imagen={emma_s}
              widthImg="7%"
            />
          </div>
        );
    }

    const handleClick = (participante) => {
        props.setDetalle(false)
        props.setParticipanteActual(participante)
    };

    return (
        <div>
            {cargando == true ? <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> 
            : <div style={{ padding: '2% 2% 0% 2%' }}>
                <>
                <Grid container direction={'row'} spacing={1}>
                    <TituloAccion principal="" alterno="¡ Lista de Solicitudes !" />
                    {props.procesos.map((participante, i) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                        <Paper elevation={1} onClick={() => handleClick(participante)} className="pointer">
                            <SemilleroCardParticipante
                            id={participante.id}
                            tipo={participante}
                            fecha={participante.fecha_registro}
                            descripcion={`Esta es su solicitud para el Semillero de Investigación ${participante.semillero.nombre} 
                            se encuentra en estado ${participante.estado_actual.nombre}. Clic aquí para obtener mas información.`}
                            estado={participante.estado_actual}
                            //handleClickDelete={handleClickDelete}
                            />
                        </Paper>
                        </Grid>
                    );
                    })}
                </Grid>
                </>
			</div>}
        </div>
    )

}*/

/*const PintarSolicitudSeleccionada = (props) =>{

    const [pos, setPos] = React.useState('Información del Semillero')

    const handleClick = () => {
        props.setDetalle(true)
        props.setParticipanteActual([])
    };

    const openModalInfo = () =>{
        props.setOpenModalInfo(true)
    }

    return (
        <div className='' >
            {console.log(props.participanteActual)}
            <Button onClick={() => handleClick()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
            <div style={{ padding: '0% 2% 0% 2%' }} >
                <Grid container direction={'row'} spacing={1} >
                    <Grid item xs={12} sm={12} md={6} >
                        <TituloAccion principal='' alterno='¡ Información Básica !' />
                        <Paper elevation={0} square className="scroll">
                            <SemilleroTableParticipante semi={props.participanteActual} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                        <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                            <Grid item xs={12} sm={12} md={12} >

                                <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => openModalInfo()}>
                                    <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {props.openModalInfo == true ? <SemillerosListSemi 
                openModalInfo={props.openModalInfo}
                setOpenModalInfo={props.setOpenModalInfo}

                semiActual={props.participanteActual.semillero}
                
                pos={pos}
                setPos={setPos}

                profesor={false}
            /> : ''}
            
        </div>
    )
}*/

export default SemilleroEstudiante 