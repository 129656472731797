import React from 'react'
import { Grid, InputLabel, MenuItem, FormControl, DialogActions, DialogContent, Select, Dialog, Checkbox } from '@material-ui/core'
import { rutaImg, consulta, generarFiltros, obtenerValoresFiltros, mostrarError } from "../../../global/js/funciones";
import AppBarModal from '../../general/AppBarModal'
import ListarDatos from "../../general/ListarDatos";
import { BtnGestionar, BtnForm } from "../../general/BotonesAccion";
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';


class PracticaTraslado extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      enProcesos: false,
      mensaje: 'Cargando..',
      procesos: [],
      programas: [],
      estados: [],
      procesos_selec: [],
      programa: '',
      estado: ''
    }
  }

  async componentDidUpdate({ modalTraslado }, { enProcesos }) {
    let { enProcesos: enProcesosNew, programa, estado } = this.state;
    let { modalTraslado: modalTrasladoNew } = this.props;

    if (modalTraslado !== modalTrasladoNew) {
      if (modalTrasladoNew) {
        this.setState({ cargando: true })
        let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'pregrado' }]);
        let estados = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 4 }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorb', 'valor': '1' }]);
        this.setState({ cargando: false, programas, estados })
      } else this.setState({
          cargando: false,
          enProcesos: false,
          mensaje: 'Cargando..',
          procesos: [],
          programas: [],
          estados: [],
          procesos_selec: [],
          programa: '',
          estado: ''
      })
    }
    if (enProcesos !== enProcesosNew) {
      if (enProcesosNew) {
        this.setState({ cargando: true, })
        let filtros = [{ 'llave': 'programa', 'valor': programa }, { 'llave': 'estado_actual__valorb', 'valor': '1' }, { 'llave': 'sin_gestor', 'valor': '1' }]
        if (estado) filtros.push({ 'llave': 'estado_actual', 'valor': estado })
        let procesos = await this.obtenerPracticas(filtros);
        this.setState({ procesos, cargando: false, })
      }
    }
  }

  obtenerPracticas = async (filtros_practica = []) => {
    let f = await generarFiltros(filtros_practica);
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/listar/1?${f}`, null, null,
        (error, estado, resp) => {
          resolve(resp);
        }
      );
    });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  obtenerColor = (codigo) => {
    let color = 'white';
    const colores = {
      'Est_Pra_Env': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      'Est_Pra_Ini': { 'backgroundColor': '#01579b', color },
      'Est_Pra_Rec': { 'backgroundColor': '#b71c1c', color },
      'Est_Pra_Fin': { 'backgroundColor': '#009688', color },
      'Est_Pra_Rev': { 'backgroundColor': '#ffc107', color },
      'Est_Pra_Acep': { 'backgroundColor': '#1a237e', color },
      'Est_Pra_Vis': { 'backgroundColor': '#6200ea', color },
      'Est_Pra_Vif': { 'backgroundColor': '#6200ea', color },
      'Est_Pra_Vio': { 'backgroundColor': '#6200ea', color },
    }
    return (colores[codigo])
  }

  seleccionarProcesos = (id) => {
    let { procesos_selec } = this.state;
    let seleccionado = procesos_selec.indexOf(id);
    if (seleccionado === -1) procesos_selec.push(id);
    else procesos_selec.splice(seleccionado, 1);
    this.setState({ procesos_selec })
  }

  accionesProcesos = (data) => {
    let ver = <Checkbox edge="end" onChange={() => this.seleccionarProcesos(data.id)} checked={this.state.procesos_selec.indexOf(data.id) !== -1} inputProps={{ 'aria-labelledby': data.id }} />
    return ver;
  }

  pintarLista = () => {
    let { cargando, enProcesos, procesos, mensaje, programa, programas, estado, estados } = this.state;
    if (cargando) return <TareasTerminadas imagen={emma_w} mensaje={mensaje} widthImg='10%' marginTop='10%' cargando={true} />;
    if (!enProcesos) {
      return (
        <Grid container spacing={3} className='margin_cont'>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="programa">Seleccione programa</InputLabel>
              <Select
                inputProps={{
                  name: 'programa',
                  id: 'programa',
                }}
                value={programa}
                onChange={this.onChange}
                required={true}>
                {programas.map(({ id, nombre }, i) => <MenuItem key={i} value={id}>{nombre}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="estado">Seleccione estado</InputLabel>
              <Select
                inputProps={{
                  name: 'estado',
                  id: 'proestadograma',
                }}
                value={estado}
                onChange={this.onChange}
                required={true}>
                {estados.map(({ id, nombre }, i) => <MenuItem key={i} value={id}>{nombre}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <ListarDatos
          id='tbl_lista_practica_hv'
          datos={procesos}
          agregar={false}
          opciones={true}
          titulo={'¡ Lista Procesos !'}
          sub_titulo={'Seleccionar y Terminar'}
          acciones={(row) => this.accionesProcesos(row)}
          accion_general={(row) => this.seleccionarProcesos(row.id)}
          avatar={({ estado_actual }) => estado_actual.valorc}
          color_avatar={({ estado_actual }) => this.obtenerColor(estado_actual.codigo)}
          fila_principal={({ usuario_registro }) => `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}`}
          filas={[{ 'mostrar': ({ programa }) => `${programa.nombre}`, 'id': 'programa' },
          { 'mostrar': ({ estado_actual }) => estado_actual.nombre, 'id': 'tipo' },
          { 'mostrar': ({ tipo }) => tipo ? tipo.nombre : '', 'id': 'tipo' }
          ]}
        />
      )
    }
  }

  terminar = () => {
    let { programa, enProcesos, procesos_selec } = this.state;
    let { coordinador, actualizarMensaje } = this.props;
    if (!enProcesos) {
      if (programa) this.setState({ enProcesos: true })
      else actualizarMensaje({ titulo: 'Seleccione Por lo menos el Programa', tipo: 'info', mostrar: true, tiempo: 6000 });
    }
    else {
      if (procesos_selec.length === 0) actualizarMensaje({ titulo: 'Seleccione Procesos', tipo: 'info', mostrar: true, tiempo: 6000 });
      else this.trasladarSolicitudes({ 'coordinador': coordinador.id, 'procesos': procesos_selec });
    }
  }

  trasladarSolicitudes = (data) => {
    this.setState({ cargando: true, mensaje: 'Cargando... Por favor espere.' })
    let { actualizarMensaje, mostramodalTraslado } = this.props;
    consulta(`api/v1.0/practicas/traslado`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostramodalTraslado(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      this.setState({ cargando: false, mensaje: 'Cargando..' })
    }
    );
  }

  render() {
    let { enProcesos, cargando } = this.state
    let { mostramodalTraslado, modalTraslado, coordinadores, datosCoordinador } = this.props;

    const acciones = (data) => {
      const onClickSeleccionar = (data) => {
        datosCoordinador(data)
        mostramodalTraslado(true)
      }

      let asignar = <BtnGestionar callback={() => onClickSeleccionar(data)} texto='Trasladar' color='#2196f3' />;
      return <div>{asignar} </div>
    }

    return (
      <>
        <ListarDatos
          datos={coordinadores}
          titulo='Lista Gestores'
          buscar={true}
          agregar={false}
          opciones={true}
          sub_titulo='Coordinadores'
          id='tbl_coordinadores'
          acciones={row => acciones(row)}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'correo' },
            { 'id': 'identificacion' },
          ]}
        />
        <Dialog fullWidth={true} maxWidth="sm" open={modalTraslado} onClose={() => mostramodalTraslado(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='Trasladar Solicitudes' mostrarModal={() => mostramodalTraslado(false)} titulo_accion="" accion="" />
          <DialogContent className='scroll' style={enProcesos ? { padding: 0 } : {}}>
            {this.pintarLista()}
          </DialogContent>
          <DialogActions>
            {(enProcesos && !cargando) && <BtnForm texto="REGRESAR" callback={() => this.setState({ enProcesos: false, procesos_selec: [] })} />}
            {!cargando && <BtnForm texto={enProcesos ? 'TERMINAR' : 'CONTINUAR'} callback={this.terminar} />}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default PracticaTraslado;
