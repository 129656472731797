import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { Component } from 'react';
import AppBarModal from '../../general/AppBarModal';
import { BtnEditar, BtnEliminar, BtnForm } from '../../general/BotonesAccion';
import ListarDatos from '../../general/ListarDatos';
import { consulta, generarFiltros } from '../../../global/js/funciones';
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from "../../../global/imagenes/emma_w.png";
import SeguimientoCrear from './SeguimientoCrear';
import moment from 'moment';
import ConfirmarAccion from '../../general/ConfirmarAccion';

class PromocionSeguimiento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
      historialSeguimiento: [],
      modalAggSeguimiento: false,
      modalEliPromocion: false,
      opcion: null,
      dataSeguimiento: { id: 0 }
    }
  }

  async componentDidUpdate({ modalSeguimiento }, { modalAggSeguimiento }) {
    let { modalSeguimiento: modalSeguimientoNew } = this.props;
    let { modalAggSeguimiento: modalAggSeguimientoNew } = this.state;
    if (modalSeguimiento !== modalSeguimientoNew) {
      if (modalSeguimientoNew) await this.obtenerHistorialSeguimiento();
      if (!modalSeguimientoNew) this.setState({ historialSeguimiento: [], cargando: false });
    }
    if (modalAggSeguimiento !== modalAggSeguimientoNew) {
      if (!modalAggSeguimientoNew) this.setState({ dataSeguimiento: { id: 0 }, opcion: null });
    }
  }

  async obtenerHistorialSeguimiento() {
    this.setState({ cargando: true });
    let historialSeguimiento = await this.obtenerSeguimiento();
    this.setState({ historialSeguimiento, cargando: false });
  }

  async obtenerSeguimiento() {
    let { dataColegio: { id } } = this.props;
    let f = await generarFiltros([{ 'llave': 'colegio', 'valor': id }]);
    return new Promise((resolve, reject) => {
      consulta(`api/v1.0/seguimiento/listar?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : []);
      })
    })
  }

  eliminarSeguimiento = async (id) => {
    let { actualizarMensaje } = this.props;
    consulta(`api/v1.0/seguimiento/eliminar/${id}`, null, "put", async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.setState({ modalEliPromocion: false, id_seguimiento: { id: 0 } });
          await this.obtenerHistorialSeguimiento();
        } else {
          tipo = 'info';
          titulo = resp.titulo;
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
    });
  }

  pintar() {
    let { historialSeguimiento, cargando, dataSeguimiento } = this.state;
    const acciones = (row) => {
      const onClickEditar = (data) => {
        let dataSeguimiento = { id: data.id, fecha_gestion: data.fecha_gestion, observacion: data.observacion }
        this.setState({ modalAggSeguimiento: true, opcion: 'editar', dataSeguimiento });
      }
      const onClickEliminar = (data) => {
        this.setState({ modalEliPromocion: true, dataSeguimiento: { id: data.id } });
      }

      let editar = (
        <BtnEditar callback={() => onClickEditar(row)} texto='Editar' />
      )

      let eliminar = (
        <BtnEliminar callback={() => onClickEliminar(row)} texto='Eliminar' />
      )

      return <>
        {editar} {/* {eliminar} */}
      </>
    }
    return (
      <>
        <ListarDatos
          id='seguimiento'
          titulo='Historial de seguimiento'
          datos={historialSeguimiento}
          buscar={true}
          opciones={true}
          acciones={(row) => acciones(row)}
          fila_principal={({ fecha_gestion }) =>
            `Fecha de gestión: ${fecha_gestion ? moment(fecha_gestion).format("YYYY-MM-DD") : 'Sin fecha'}`
          }
          filas={[
            {
              id: 'observacion', enLista: true, mostrar: ({ observacion }) => observacion ? observacion : '',
              nombre: 'Observación: '
            },
            {
              id: 'responsable', enLista: true, mostrar: ({ usuario_registro: { primer_nombre, segundo_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`,
              nombre: 'Responsable: '
            },
            {
              id: 'fecha_registro', enLista: true, mostrar: ({ fecha_registro }) => fecha_registro ? moment(fecha_registro).format("YYYY-MM-DD") : '',
              nombre: 'Fecha de registro: '
            }
          ]}
          agregar={true}
          actAgregar={() => this.setState({ modalAggSeguimiento: true })}
        />
      </>
    )
  }

  render() {
    let { cargando, modalAggSeguimiento, modalEliPromocion, dataSeguimiento, opcion } = this.state;
    let { modalSeguimiento, cerrarModalSeguimiento, dataColegio, actualizarMensaje } = this.props;
    return (
      <>
        <Dialog open={modalSeguimiento} maxWidth='sm' fullWidth={true} onClose={cerrarModalSeguimiento}>
          <AppBarModal titulo={`Seguimiento de ${dataColegio.nombreColegio}`} mostrarModal={cerrarModalSeguimiento} titulo_accion='' />
          <DialogContent style={{ padding: 0 }} className='scroll' >
            {
              cargando ?
                <TareasTerminadas
                  mensaje="Cargando.."
                  marginTop="7%"
                  imagen={emma_w}
                  widthImg="7%"
                />
                : this.pintar()
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={cerrarModalSeguimiento} />
          </DialogActions>
        </Dialog>

        {
          modalAggSeguimiento &&
          <SeguimientoCrear
            dataColegio={dataColegio}
            modalAggSeguimiento={modalAggSeguimiento}
            cerrarModalAggSeguimiento={() => this.setState({ modalAggSeguimiento: false })}
            obtenerHistorialSeguimiento={() => this.obtenerHistorialSeguimiento()}
            actualizarMensaje={actualizarMensaje}
            opc={opcion}
            dataSeguimiento={dataSeguimiento}
          />
        }

        <ConfirmarAccion
          mensaje1={`El seguimiento será eliminado. ¿Desea continuar?`}
          ejecutarAccion={this.eliminarSeguimiento}
          mostrarModalConfAccion={() => this.setState({ modalEliPromocion: true })}
          modalConAccion={modalEliPromocion}
          dato={dataSeguimiento}
          titulo={"¿ Eliminar Seguimiento ?"}
        />

      </>
    )
  }

}

export default PromocionSeguimiento;