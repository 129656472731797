import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { BtnDetalle, BtnForm } from "../../general/BotonesAccion";
import ListarDatos from "../../general/ListarDatos";
import AppBarModal from '../../general/AppBarModal';
import { consulta, generarFiltros } from '../../../global/js/funciones'
import PracticasEmpresasHV from "./PracticasEmpresasHV";
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';


class PracticasListarHV extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datos: [],
      cargando: true,
      estudiante: { id: 0 }
    };
  }

  async componentDidUpdate({ modalEmpresasHv }, { }) {
    let { modalEmpresasHv: modalEmpresasHvNew } = this.props;
    let { estudiante } = this.state;
    if (modalEmpresasHv !== modalEmpresasHvNew && modalEmpresasHvNew) this.obtenerEmpresasHV(estudiante.id)
  }

  async obtenerEmpresasHV(id) {
    this.setState({ cargando: true });
    let f = await generarFiltros([{ 'llave': 'contratado', 'valor': '0' }, { 'llave': 'practica__usuario_registro', 'valor': id }]);
    consulta(`api/v1.0/practicas/listarhv?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) this.setState({ cargando: false, datos: resp });
    })
  }


  accionesEstudiante = (data) => {
    let { mostrarModalEmpresasHV } = this.props;

    const onClickEmpresas = (estudiante) => {
      this.setState({ estudiante })
      mostrarModalEmpresasHV(true);
    }

    let detalle = <BtnDetalle color='#0d47a1' callback={() => onClickEmpresas(data)} texto='Empresas' />;
    return <div> {detalle}</div>;

  }

  render() {
    let { mostrarModalEnviarHV, estudianteshv, gestionarHV, mostrarModalGestionar, configurarAccionConf, refrescar, mostrarModalEmpresasHV, modalEmpresasHv } = this.props;
    let { estudiante, cargando, datos } = this.state

    return (
      <>
        <ListarDatos
          datos={estudianteshv}
          titulo={'Lista Estudiantes HV Enviadas'}
          buscar={true}
          agregar={true}
          refrescar={true}
          actRefrescar={() => refrescar()}
          actAgregar={() => mostrarModalEnviarHV(true)}
          opciones={true}
          sub_titulo='HV enviadas'
          descargar='EMMA - ESTUDIANTES HV'
          id='tbl_hv_enviadas'
          acciones={(row) => this.accionesEstudiante(row)}
          avatar={({ primer_apellido }) => primer_apellido.charAt(0)}
          fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_apellido} ${segundo_apellido} ${primer_nombre}`}
          filas={[
            { 'id': 'estudiante', 'mostrar': ({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_apellido} ${segundo_apellido} ${primer_nombre} `, 'enLista': false },
            { 'id': 'identificacion', 'mostrar': ({ identificacion }) => identificacion },
            { 'id': 'celular', 'mostrar': ({ celular }) => celular ? celular : '' },
            { 'id': 'programa', 'mostrar': ({ programa }) => programa ? programa : '' },
            { 'id': 'empresas', 'mostrar': ({ empresas }) => `Hoja de vida enviada a ${empresas} ${empresas === 1 ? 'empresa' : 'empresas'}.` },
          ]}
        />

        <Dialog open={modalEmpresasHv} maxWidth="sm" fullWidth={true} onClose={() => mostrarModalEmpresasHV(false)}>
          <AppBarModal titulo={estudiante.id ? `¡ ${estudiante.primer_apellido} ${estudiante.primer_nombre} !` : ''} mostrarModal={mostrarModalEmpresasHV} titulo_accion='' />
          <DialogContent style={{ padding: 0 }} className='scroll' >
            {!cargando ?
              <PracticasEmpresasHV hojas={datos} configurarAccionConf={configurarAccionConf} mostrarModalGestionar={mostrarModalGestionar} gestionarHV={gestionarHV} id_estudiante={estudiante.id} obtenerEmpresasHV={(id) => this.obtenerEmpresasHV(id)} setCargando= {(valor) => this.setState({cargando: valor})}/>
              :
              <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
            }
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalEmpresasHV(false)} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default PracticasListarHV

//Props del componente
PracticasListarHV.propTypes = {
  //variables
  estudianteshv: PropTypes.array.isRequired,
}

