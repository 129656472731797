import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { actualizarMensaje, mostrarModulo } from './../../redux/actions/actGlobal';
import { InvitadosMenu } from "./InvitadosMenu";
import AppBarModal from '../general/AppBarModal';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import cursosLibres from '../../global/imagenes/cursoslibres.png';
import TareasTerminadas from "../general/TareasTerminadas";
import Cargando from "../general/app/Cargando";
import TituloAccion from '../general/TituloAccion';
import ModalNuevaConsulta from './ModalNuevaConsulta';
import ListarDatos from './../general/ListarDatos';
import ClaseCard from './ClaseCard';
import { Grid, Paper, Card, CardHeader, CardContent, Avatar, Typography, Dialog } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { consulta, generarFiltros, obtenerValoresFiltros } from '../../global/js/funciones';
import { BtnDetalle } from './../general/BotonesAccion';
import ConfirmarAccion from '../general/ConfirmarAccion';
import Detalle from '../general/Detalle';
import moment from 'moment';

const API_URI = `api/v1.0/invitados/`;

class Invitados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seleccion: 'procesos',
      cargando: true,
      cargandoGuardar: false,
      informacionPrincipal: null,
      openModalNuevo: false,
      openDetalleModal: false,
      clases: [],
      clase: [],
      idClase: null,
      openModalEliminar: false,
    }
  }

  async componentDidMount() {
    const { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/estudiantes_invitados', nombre: 'Estudiantes Invitados' });
    this.getClases(1);
  }

  componentDidUpdate({ }, { seleccion }) {
    let { seleccion: seleccionNew } = this.state;
    if (seleccion !== seleccionNew && seleccionNew === 'procesos') this.getClases(1);
    if (seleccion !== seleccionNew && seleccionNew === 'historial') this.getClases(0);
    if (seleccion !== seleccionNew && seleccionNew === 'nuevo') {
      this.getInformacionPrincipal();
    }
  }

  getInformacionPrincipal = () => {
    this.setState({ cargando: true });
    const { actualizarMensaje } = this.props;

    consulta(`${API_URI}informacion_principal`, null, null, (error, estado, informacionPrincipal) => {
      if (!error && estado === 200) this.setState({ informacionPrincipal });
      else actualizarMensaje({ titulo: 'Hubo un error, inténtalo más tarde o contacta con el administrador', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargando: false });
    });
  }

  getClases = async (periodo_actual) => {
    this.setState({ cargando: true });
    const { actualizarMensaje } = this.props;
    let f = [];
    if (periodo_actual === 1) {
      let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Est_Inv' }]);
      f = [{ 'llave': 'periodo', 'valor': periodo[0].nombre }];
    }
    let filtros = await generarFiltros(f);

    consulta(`${API_URI}clases/tipo/0?${filtros}`, null, null, (error, estado, clases) => {
      if (!error && estado === 200) this.setState({ clases });
      else actualizarMensaje({ titulo: 'Hubo un error, inténtalo más tarde o contacta con el administrador', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargando: false });
    })
  }

  pintarNuevo = () => {
    let { openModalNuevo, informacionPrincipal, cargandoGuardar } = this.state;

    if (informacionPrincipal && informacionPrincipal.inscribirse) {
      return (
        <Fragment>
          <Grid container direction='row' justify='center' style={{ marginBottom: '100px' }}>
            <Grid item xs={12} sm={6} md={4} style={{ padding: '2% 2% 0 2%' }} onClick={() => this.setState({ openModalNuevo: true })}>
              <Paper elevation={8} square>
                <Card style={{ maxWidth: '100%', marginTop: 35, cursor: 'pointer' }} elevation={0}>
                  <CardHeader avatar={<Avatar aria-label='recipe' style={{ backgroundColor: red[500] }}>I</Avatar>} title={'Inscripción'} />
                  <CardContent style={{ height: '10%', textAlign: 'center' }}>
                    <img src={cursosLibres} style={{ width: '40%', margin: '0 auto' }} alt="Imagen Cursos"/>
                  </CardContent>
                  <CardContent>
                    <Typography style={{ minHeight: 100 }} variant='body2' color='textSecondary' component='p' align='justify'>
                      {informacionPrincipal ? informacionPrincipal.mensajeInicial : ''}
                    </Typography>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>

          <ModalNuevaConsulta
            condiciones={informacionPrincipal ? informacionPrincipal.condiciones : []}
            openModalNuevo={openModalNuevo}
            closeModalNuevo={() => this.setState({ openModalNuevo: false })}
            handleSubmitNuevaConsulta={this.handleSubmitNuevaConsulta}
          />

          <Dialog open={cargandoGuardar} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
            <AppBarModal titulo={'Enviando Datos'} mostrarModal={() => { }} titulo_accion="" accion="" />
            <TareasTerminadas mensaje='Se están guardando los datos, un momento por favor' imagen={emma_s1} widthImg='7%' marginTop='5%' />
          </Dialog>
        </Fragment>
      )
    } else return <TareasTerminadas mensaje='Hola, no puedes hacer más inscripciones, ya has alcanzado el límite de inscripción como invitado a una clase' imagen={emma_s1} widthImg='7%' marginTop='5%' />
  }

  handleClickDelete = id => () => this.setState({ idClase: id, openModalEliminar: true });

  eliminarClase = () => {
    this.setState({ cargando: true });
    const { actualizarMensaje } = this.props;
    let { idClase } = this.state;

    consulta(`${API_URI}clase/${idClase}`, null, 'put', (error, estado, resp) => {
      let titulo = '';
      let tipo = '';

      if (!error && estado === 200) {
        this.setState({ idClase: null, openModalEliminar: false, seleccion: 'procesos' });
        titulo = resp.titulo;
        tipo = 'success';
      } else {
        titulo = resp.titulo || 'Hubo un error al eliminar'
        titulo = 'info';
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      this.getClases(1);
    })
  }

  pintarProcesos = () => {
    let { cargando, clases, openModalEliminar } = this.state;

    if (!cargando) {
      if (clases.length > 0) {
        return (
          <div style={{ padding: '2% 2% 0 2%' }}>
            <Grid container direction={'row'} spacing={2}>
              <TituloAccion principal='' alterno='¡Estas son las clases en las que te has inscrito como invitado(a) en el periodo actual!' />

              {clases.map((data, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <Paper elevation={1}>
                    <ClaseCard data={data} handleClickDelete={this.handleClickDelete} />
                  </Paper>
                </Grid>
              ))}

              <ConfirmarAccion
                titulo='Cancelar inscripción'
                dato={{ id: 0 }}
                mensaje1='¿Estás seguro(a) de que deseas cancelar la inscripción de la clase seleccionada?'
                modalConAccion={openModalEliminar}
                mostrarModalConfAccion={() => this.setState({ openModalEliminar: false, idClase: null })}
                ejecutarAccion={this.eliminarClase}
              />
            </Grid>
          </div>
        )
      } else return <div onClick={() => this.setState({ seleccion: 'nuevo' })} className='pointer'><TareasTerminadas mensaje='Hola, no cuentas con ningún proceso activo, click aquí para iniciar un nuevo proceso' imagen={emma_s1} widthImg='7%' marginTop='7%' /></div>
    } else return <Cargando width='100%' marginTop='15%' />;
  }

  pintarHistorial = () => {
    let { cargando, clases, clase, openDetalleModal } = this.state;

    if (!cargando) {
      return (
        <div>
          <ListarDatos
            id='tbl_clases_historial'
            titulo='Lista de Clases'
            datos={clases}
            avatar={({ asignatura }) => asignatura.charAt(0)}
            color_avatar={({ estado }) => estado === '1' ? { 'backgroundColor': '#1abc9c', 'color': 'white' } : { 'backgroundColor': '#b71c1c', 'color': 'white' }}
            fila_principal={({ asignatura }) => asignatura}
            filas={[
              { 'mostrar': ({ grupo }) => `Grupo: ${grupo}` },
              { 'mostrar': ({ profesor: { primer_nombre, primer_apellido, segundo_apellido } }) => `Profesor: ${primer_nombre} ${primer_apellido} ${segundo_apellido}` },
              { 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mma') }
            ]}
            acciones={(row) => (<BtnDetalle callback={() => this.pintarDetalle(row)} />)}
            opciones={true}
            buscar={true}
            css={{}}
          />

          <Detalle
            titulo='Detalle de la clase'
            open={openDetalleModal}
            cerrar={() => this.setState({ openDetalleModal: false })}
            config={clase}
          />
        </div>
      )
    } else return <Cargando width='100%' marginTop='15%' />;
  }

  pintarDetalle = (row) => {
    let { asignatura, grupo, profesor, usuario_registro, horario, periodo, estado } = row;
    let value_horario = '';
    let sin_asignar = false;
    JSON.parse(horario).forEach(({ dia, hora_inicio, hora_fin }) => {
      if (dia == null || hora_inicio == null || hora_fin == null) {
        sin_asignar = true;
        return;
      }
      value_horario += `${dia} de ${hora_inicio} - ${hora_fin}\n`;
    });

    if (sin_asignar) value_horario = 'Sin asignar';

    let clase = [
      { label: 'Asignatura', value: asignatura },
      { label: 'Grupo', value: grupo },
      { label: 'Profesor', value: `${profesor.primer_nombre} ${profesor.primer_apellido} ${profesor.segundo_apellido}` },
      { label: 'Estudiante', value: `${usuario_registro.primer_nombre} ${usuario_registro.primer_apellido} ${usuario_registro.segundo_apellido}` },
      { label: 'Horario', value: value_horario },
      { label: 'Periodo', value: periodo },
      { label: 'Estado', value: estado === '1' ? 'Activo' : 'Cancelado' },
    ];

    this.setState({ clase, openDetalleModal: true });
  }

  handleSubmitNuevaConsulta = (datos, celular, telefono, correo) => {
    this.setState({ cargandoGuardar: true });
    const { actualizarMensaje } = this.props;
    let tipo = '';
    let titulo = '';

    datos.forEach(dato => {
      let { codigo_asignatura, asignatura, grupo, profesor, horario } = dato;
      let data = { codigo_asignatura, asignatura, grupo, profesor, horario, celular, telefono, correo };

      consulta(`${API_URI}inscribirse`, data, 'post', (error, estado, resp) => {
        if (!error && estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
        } else {
          tipo = 'info';
          titulo = resp.titulo || 'Ha ocurrido un error';
        }
        this.setState({ cargandoGuardar: false, openModalNuevo: false });
        this.setState({ seleccion: 'procesos' });
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 10000 });
      });
    });
  }

  render() {
    let { seleccion, cargando } = this.state;

    return (
      <div>
        <InvitadosMenu
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
        />

        <div className='contenido_modulo'>
          {
            !cargando ?
              <div>
                {seleccion === 'procesos' && this.pintarProcesos()}
                {seleccion === 'nuevo' && this.pintarNuevo()}
                {seleccion === 'historial' && this.pintarHistorial()}
              </div>
              : <TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg='7%' />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModulo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invitados);
