// React
import React from 'react';

// Redux
import { connect } from "react-redux";

// Material-UI components
import { Dialog, DialogActions, DialogContent, } from '@material-ui/core/';

// Componentes
import { BtnCancelar, BtnEditar } from '../general/BotonesAccion';
import AppBarModal from '../general/AppBarModal';
import ListarDatos from '../general/ListarDatos';
import ConfirmarAccion from '../general/ConfirmarAccion';
import PersonasBuscarGrado from './PersonasBuscarGrado';

// Funciones
import { mostrarError, formulario, crear_form_data, rutaImg } from "../../global/js/funciones";

//ACCIONES DEL MODULO
import { actualizarMensaje } from "../../redux/actions/actGlobal";
import { setTutores, showModalNewGestor } from '../../redux/actions/actGrados';
import { mostrarModalAdd, mostrarModalDel, mostrarModalMod } from "../../redux/actions/actGenericas";
import { getGestoresSolicitud } from "./funciones"


class Gestores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      gestor: '',
      gestor_remplazo: '',
      confirmar_negar:false
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    });
  };

  pintarRolGestor = (tipo) => {
    if (tipo == 1) return "COORDINADOR DE ÁREA"
    if (tipo == 2) return "TUTOR DE GRADO"
    if (tipo == 3) return "COTUTOR DE GRADO"
    if (tipo == 4) return "ESTUDIANTE"
  }


  // Acciones Generales

  remplazarGestor = async (gestor, remplazo) => {
    let { solicitud, setTutores, actualizarMensaje, mostrarModalMod, mostrarModalDel } = this.props;
    let { id } = gestor;
    let data = await crear_form_data({ remplazo_id: remplazo.id });
    formulario(`api/v1.0/grados/gestor/${id}/remplazar`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          getGestoresSolicitud(solicitud.id, setTutores);
          mostrarModalMod(false)
          mostrarModalDel(false)
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          mostrarModalDel(false)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
    })
  }
  negarGestor = async (gestor) => {
    let { solicitud, setTutores, actualizarMensaje, mostrarModalAdd } = this.props;
    let { id } = gestor;
    // let data = await crear_form_data({ remplazo_id: remplazo.id });
    formulario(`api/v1.0/grados/gestor/${id}/negar`, {}, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          getGestoresSolicitud(solicitud.id, setTutores);
          mostrarModalAdd(false)
          this.setState({confirmar_negar:false})
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          mostrarModalDel(false)
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 3000 });
    })
  }

  configurarAcciones = (data) => {
    let { solicitud, admin, perfil, mostrarModalMod,data_session:{persona},mostrarModalAdd } = this.props
    let { validacion } = data
    const eventoEditar = () => {
      this.setState({ gestor: data });
      mostrarModalMod(true)
    }
    const eventoNegar = () => {
      this.setState({ gestor: data,confirmar_negar:true});
      mostrarModalAdd(true)

    }

    let remplazar = solicitud.estado_actual.codigo != "gr_est_fin" && perfil && ((perfil.administrador ) || (perfil.tipo == "Per_Adm_Gra3")) && data.tipo != 4 && data.estado_gestor=="2"&&<BtnEditar texto='Remplazar' callback={() => eventoEditar()} />
    let negar = solicitud.estado_actual.codigo != "gr_est_fin" && perfil && ((perfil.administrador ) || (perfil.tipo == "Per_Adm_Gra2"&& persona.id == data.persona.id)) && data.tipo != 4 && data.estado_gestor=="1" &&<BtnCancelar callback={() => eventoNegar()} texto='Negar' />;


    let botones = <div></div>
    if (admin) botones = <div>{remplazar}{negar}</div>
    return botones
  }

  // Modales

  modalremplazarGestor = () => {
    let { modalMod, modalDel, mostrarModalMod, mostrarModalDel } = this.props;
    let { gestor, gestor_remplazo } = this.state
    return (
      <div>
        <Dialog onClose={() => mostrarModalMod(false)} open={modalMod} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
          <AppBarModal titulo={'Buscar Gestor'} mostrarModal={() => mostrarModalMod(false)} titulo_accion="" />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <PersonasBuscarGrado tipo="personal" callback={(data) => {
              this.setState({ gestor_remplazo: data })
              mostrarModalDel(true)
            }} />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
        {gestor && gestor_remplazo &&
          <ConfirmarAccion
            mensaje1={`El gestor ${gestor.persona.primer_nombre} ${gestor.persona.primer_apellido} será remplazado por ${gestor_remplazo.primer_nombre} ${gestor_remplazo.primer_apellido},`}
            ejecutarAccion={() => this.remplazarGestor(gestor, gestor_remplazo)} mostrarModalConfAccion={mostrarModalDel}
            modalConAccion={modalDel}
            dato={gestor}
            titulo={'¿ Remplazar Gestor ?'}
          />}
      </div>
    )
  };


  render() {
    let { tutores,modalAdd,mostrarModalAdd } = this.props;
    let { confirmar_negar,gestor}= this.state
    return (
      <React.Fragment>
        <ListarDatos
          id="tbl_listar_tutores"
          datos={tutores}
          acciones={(row) => this.configurarAcciones(row)}
          accion_general={() => { }}
          avatarImg={true}
          avatar={({ persona }) => `${rutaImg}${persona.identificacion}.jpg`}
          fila_principal={({ tipo }) => this.pintarRolGestor(tipo)}
          filas={[
            { 'mostrar': ({ persona }) => `${persona.primer_nombre} ${persona.primer_apellido} ${persona.segundo_apellido} `.toUpperCase() },
            { 'mostrar': ({ programa }) => programa && programa.nombre },
            { 'mostrar': ({ persona }) => persona.correo },
          ]}
          css={{}}
        />
        {this.modalremplazarGestor()}
        { gestor && confirmar_negar&&<ConfirmarAccion
            mensaje1={`Se enviará una apelación al director de programa sobre esta asignación.`}
            ejecutarAccion={() => this.negarGestor(gestor)} mostrarModalConfAccion={mostrarModalAdd}
            modalConAccion={modalAdd}
            dato={gestor}
            titulo={'¿ Apelacion Tutoria ?'}
          />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let { modalAdd, modalDel, modalMod } = state.redGenericas;
  let { solicitud, estado, tutores, modalNewGestor } = state.redGrados;

  return {
    solicitud,
    estado,
    tutores,
    modalAdd,
    modalDel,
    modalMod,
    modalNewGestor,
  }
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
  setTutores,
  mostrarModalAdd,
  mostrarModalDel,
  mostrarModalMod,
  showModalNewGestor,
  actualizarMensaje,
}

export default connect(mapStateToProps, mapDispatchToProps)(Gestores);

