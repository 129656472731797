//Importaciones necesaria para poder utilizar react
import React, { useState, useEffect }  from 'react'

//Importaciones necesarias para que el modal del formulario de creacion de semilleros se pueda abrir, este es para que se habra el modal.
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'
//Importe de los text faild para los formularios de los campos.
import TextField from '@material-ui/core/TextField';
//Importe de los grid para los textfield de los formularios entre otros aspecto.
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

import AppBarModal from '../../general/AppBarModal'

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

//MenuItem
import MenuItem from '@material-ui/core/MenuItem';

//Importo las funciones para hacer peticiones
import * as Funciones from '../../../global/js/funciones';

//Constantes del Helper
import * as Helper from './Helper';

import FormHelperText from '@material-ui/core/FormHelperText';

import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';

//Modal del agregar Objetivos General del Semillero

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('xs')]: {
            //minWidth: "400px"
          },
      },
      flexGrow: {
          flexGrow: 1,
      },
      formControl: {
        margin: theme.spacing(1),
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      noneScroll:{
        overflowY: 'hidden',
        [theme.breakpoints.up('sm')]: {
          minWidth: "600px"
        },
    }
}));

export default function SemillerosAddObj(props) {
    const classes = useStyles();
    const [programas, setProgramas] = React.useState([]);
    const [departamento, setDepartamento] = React.useState([]);
    const [cargando, setCargando] = React.useState(true)

    React.useEffect( () => {
        obtenerDepartamentos()
        //obtenerProgramas()
    }, [])

    //Obtengo los departamentos con la funcion obtenerValores de funciones.js
    const obtenerDepartamentos = async () => {
        // const data = await Funciones.obtenerValoresFiltros([{'llave':'generica', 'valor': Helper.SEMI_DEPARTAMENTOS}, { 'llave': 'valora', 'valor': 'si' }])
        const data = await Funciones.obtenerValores(Helper.SEMI_DEPARTAMENTOS)
        setDepartamento(data)
        setCargando(false)
    }

    //Obtengo los programas con la funcion otenerValores de funciones.js
    // const obtenerProgramas = async () => {
    //     const data = await Funciones.obtenerValores(Helper.SEMI_PROGRAMAS)
    //     setProgramas(data)
    //     setCargando(false)
    // }

    //Funcion para asginar Departamentos.
    const handleChangeDepa = async (event) => {
        if(event){
            props.setDepaSelect(event)
            const data = await Funciones.obtenerPermisosValorAlt([{'llave':'principal', 'valor':event.id}, {'llave': 'secundario__generica', 'valor': Helper.SEMI_PROGRAMAS } ])
            if(data){
                setProgramas(data)
            }else{
                setProgramas()
    
            }
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "addDepaPro" && er.llave != "depaSelect"));
            // Elimina error cuando se escribe en el campo
        }
        }
    };

    //Funcion para asginar Programas.
    const handleChangePro = (event) => {
        props.setProSelect(event)
        if(props.errores.length > 0){
            props.setErrores(props.errores.filter(er => er.llave != "addDepaPro" && er.llave != "proSelect"));
            // Elimina error cuando se escribe en el campo
        }
    };

    return (
        <div>
            
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                
                <AppBarModal titulo='Elija un Departamento y Programa' mostrarModal={props.handleClose} titulo_accion="" accion="" />
                {cargando == false ? 
                <DialogContent className={classes.noneScroll}>
                    <Grid item container>
                        <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Departamento</InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('depaSelect', props.errores).length > 0}>
                            <Select
                            value={props.depaSelect}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(e)=>handleChangeDepa(e.target.value)}
                            >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${departamento.length} departamentos disponibles` }</em>
                            </MenuItem>
                            {departamento.map((item, index) => (
                            <MenuItem key= { index } value = { item }>{ item.nombre }</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('depaSelect', props.errores) }</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                        <InputLabel htmlFor="age-native-simple">Programa</InputLabel>
                        <FormControl className={classes.formControl} fullWidth error = { props.getError('proSelect', props.errores).length > 0}>
                            <Select
                            value={props.proSelect}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(e)=>handleChangePro(e.target.value)}
                            >
                            <MenuItem value="">
                                <em style={{color: "rgba(0, 0, 0, 0.54)"}}>{ `${programas.length} programas disponibles` }</em>
                            </MenuItem>
                            {programas.map((item, index) => (
                            <MenuItem key= { index } value = { item }>{ item.secundario.nombre }</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{ props.getError('proSelect', props.errores) }</FormHelperText>
                        </Grid>
                    </Grid>
                </DialogContent>
                : <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' />}
                <DialogActions>
                        <Button variant="contained" color="primary" style={{color: "#fff"}} onClick={props.onClickChange}>
                            Agregar
                        </Button>
                </DialogActions>
                
            </Dialog>
            
        </div>
    );
}