import React, { Component } from 'react'
import PropTypes from 'prop-types';

// FUNCIONE GENERALES - GLOBALES
import { Dialog, DialogActions, DialogContent, Paper, List, Grid} from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal';
import { BtnForm, BtnCargar } from '../../general/BotonesAccion';
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import TareasTerminadas from "../../general/TareasTerminadas";
import { obtenerPermisosValorAlt,obtenerValoresFiltros, generarFiltros, consulta } from "../../../global/js/funciones";
import AlertasSimple from '../../general/AlertasSimple';

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';
import { Gif } from '@material-ui/icons';

// HELPERS
import {REQUISITOS} from './helper'

export default class MatriculasVolantePago extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cargando : true,
            titulo_res: 'Cargando..',
            documentos: [],
            adjunto : [],
            requisitos: [],
        }
    }
    
    async componentDidUpdate({ modalAdjuntarVolante }, {}) {
        let { modalAdjuntarVolante: modalAdjuntarVolanteNew, matricula }  = this.props;
        if (modalAdjuntarVolante !== modalAdjuntarVolanteNew){
            if (modalAdjuntarVolanteNew) {
                this.obtenerDatos();
                let requisitos = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': matricula.estado_solicitud.id }, { 'llave': 'secundario__generica', 'valor': REQUISITOS }, {'llave': 'secundario__valora', 'valor': 'estudiante'}])
                this.setState({ cargando: false, requisitos });
            }
        }
    }

    async obtenerDatos (){
        let acuerdo_matriculas = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Mat_Pro' }]);
        this.setState({ cargando: false, acuerdo_matriculas : acuerdo_matriculas.length > 0 ? acuerdo_matriculas[0].descripcion : '' });
        
      }
      
      obtenerObservaciones = async () => {
          this.setState({ cargando: true });
        let {acuerdo_matriculas} = this.state
        let obs = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Mat_Pro'}])
        let acu_matriculas = [{item : acuerdo_matriculas}]
        obs.map(({ secundario }) => acu_matriculas.push({item : secundario.nombre}));
        this.setState({ acu_matriculas });
    }

    submit = (archivos) => {
        let { estado, motivo, } = this.state;
        let { matricula, mostrarModalVolante, guardarVolantePagado, actualizarMensaje } = this.props;
        let sw = true;
        if(archivos.length <= 0 ) sw = false;
        if(sw) this.setState({ cargando: true, titulo_res: 'Enviando..' })
        guardarVolantePagado(matricula.id, archivos.length, archivos, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
        
    }

    
    render() {
        let { modalAdjuntarVolante, mostrarAdjuntarVolante } = this.props
        let { requisitos, cargando, titulo_res, acuerdo_matriculas } = this.state
        let mensaje_matriculas = [{item: acuerdo_matriculas}]
        
        
        // let { requisitos, submit } = component_props
        
        const trasnformarDataAdj = (data) => {
            let r = [];
              data.map(({ secundario }) => r.push({ nombre: secundario.nombre, descripcion: secundario.descripcion, codigo: secundario.id, archivo: secundario.archivo }))
              return r;
            }
            
            const acciones = (data, callback) => {
                const onClickCargar = (data) => callback(data);
                let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
                return <div>{cargar}</div>;
            }
            
        
            
            return (
            <div>
                <Dialog open={modalAdjuntarVolante} onClose={() => mostrarAdjuntarVolante(false)} fullWidth={true} maxWidth="sm">
                    <AppBarModal titulo="¡ Volante Cancelado !" mostrarModal={() => mostrarAdjuntarVolante(false)} titulo_accion="" />
                    <DialogContent  className="scroll">
                      {
                          !cargando ?
                          <Paper elevation={0} square>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <List component="nav" arial-label="main">
                                    <EnviarMultiplesArchivos
                                    acciones={acciones}
                                    enModal={false}
                                    enviarArchivos={(archivos) => this.submit(archivos)}
                                    limite={2}
                                    mostrarMensaje={false}
                                    enLista={true}
                                    lista={trasnformarDataAdj(requisitos)}
                                    />
                                </List>
                                </Grid>
                                <Grid item xs={12}>
                                    {/* {this.configStepContent()} */}
                                    <AlertasSimple
                                    tipo='info'
                                    titulo={'Estimado Estudiante, tener en cuenta: '}
                                    lista={mensaje_matriculas}
                                    margin = '0px'
                                    />
                                </Grid>
                            </Grid>
                        </Paper> :
                        <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" />
                      }
                    </DialogContent>
                    {
                        !cargando &&
                        <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarAdjuntarVolante(false)} />
                        <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />
                        </DialogActions>
                    }
                </Dialog>
            </div>
        )
    }

}
