import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";

class GenericasAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      codigo: '',
      nombre: '',
      descripcion: '',
    }
  }

  componentDidUpdate({ modalAdd }) {
    let { modalAdd: modalAddNew } = this.props;
    if (modalAddNew !== modalAdd) {
      this.changeState();
    }
  }

  changeState = () => {
    this.setState({
      nombre: '',
      descripcion: '',
    })
  }
  onSubmit = e => {
    let { codigo, nombre, descripcion } = this.state;
    this.props.guardarGenerica(codigo, nombre, descripcion);
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  render() {
    let { codigo, nombre, descripcion } = this.state;
    let { modalAdd, mostrarModalAdd } = this.props;

    return (
      <Dialog open={modalAdd} onClose={mostrarModalAdd.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xs">
        <AppBarModal titulo="Nueva Genérica" mostrarModal={mostrarModalAdd} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          <ValidatorForm onSubmit={this.onSubmit}>
          <TextValidator
              variant="outlined"
              autoFocus
              margin="dense"
              id="codigo"
              label="Codigo"
              type="text"
              fullWidth
              name="codigo"
              value={codigo}
              validators={["required"]}
              errorMessages={["El campo es requerido"]}
              onChange={this.onChange}
            />
            <TextValidator
              variant="outlined"
              autoFocus
              margin="dense"
              id="nombre"
              label="Nombre"
              type="text"
              fullWidth
              name="nombre"
              value={nombre}
              validators={["required"]}
              errorMessages={["El campo es requerido"]}
              onChange={this.onChange}
            />
            <TextValidator
              variant="outlined"
              margin="dense"
              id="descripcion"
              label="Descripción"
              type="text"
              fullWidth
              name="descripcion"
              value={descripcion}
              onChange={this.onChange}
            />
            <Button color="primary" type="submit" id='enviar_form_add_generica' className="oculto"> Guardar </Button>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAdd(false)} />
          <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_add_generica').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
GenericasAgregar.propTypes = {
  //variables
  modalAdd: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAdd: PropTypes.func.isRequired,
  guardarGenerica: PropTypes.func.isRequired,
}


export default GenericasAgregar;

