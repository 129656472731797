import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnForm } from '../../general/BotonesAccion'

//components
import EmpresaAddData from './EmpresaAddData'

class EmpresaForms extends Component {
    render() {
        let { modalAddPerfilPortal, mostrarModalAddPerfilPortal, datos_modal, llave, onChangeState, datosEmpresa, datosResponsable, enviarFormActual } = this.props
        return (
            <div>
                <Dialog open={modalAddPerfilPortal} onClose={() => mostrarModalAddPerfilPortal(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={datos_modal.nombre_modal} mostrarModal={() => mostrarModalAddPerfilPortal(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 0, overflowX: 'hidden' }} className="scroll">
                        <div>
                            <EmpresaAddData
                                llave={llave}
                                onChangeState={onChangeState}
                                datosEmpresa={datosEmpresa}
                                datosResponsable={datosResponsable}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalAddPerfilPortal(false)} />
                        <BtnForm texto="GUARDAR" callback={() => enviarFormActual()} />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default EmpresaForms;