import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import AppBarModal from '../general/AppBarModal'
import { BtnForm } from "../general/BotonesAccion";
import TareasTerminadas from '../general/TareasTerminadas';
import emma_w from '../../global/imagenes/emma_w.png';


class PersonasModificar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      tipo: '',
      tipo_identificacion: '',
      identificacion: 0,
      primer_nombre: '',
      segundo_nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      correo: '',
      usuario: '',
      showtipo: false,
      login_directorio: 1,
      showtipoIdentificacion: false,
      tipos_persona: [],
      indicativo_celular: null,
      celular: null,
      telefono: null,
      correo_personal: '',
      activo: 0,
      cargando: true,
      titulo_carg: 'Cargando..',
      tipos_persona: [
        {
          id: 1,
          nombre: 'PERSONA INTERNA'
        },
        {
          id: 2,
          nombre: 'PERSONA EXTERNA'
        }
      ]
    }
  }

  componentDidUpdate({ modalModPersona }) {
    let { modalModPersona: modalModNew, persona } = this.props;
    if (modalModNew !== modalModPersona) {
      if (modalModNew) {
        this.changeState(persona);
        this.setSelecT('tipo_identificacion', persona.tipo_identificacion.id);
      }
    }
  }


  changeState = ({ id, tipo, identificacion, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo, usuario, login_directorio, indicativo_celular, celular, telefono, correo_personal, activo }) => {
    this.setState({
      id,
      tipo,
      identificacion,
      primer_nombre,
      segundo_nombre: segundo_nombre ? segundo_nombre : "",
      primer_apellido,
      segundo_apellido: segundo_apellido ? segundo_apellido : "",
      correo,
      usuario,
      login_directorio,
      indicativo_celular,
      celular,
      telefono,
      correo_personal,
      activo: parseInt(activo),
      cargando: false,
      titulo_carg: 'Cargando..'
    })
  }
  onSubmit = e => {
    this.setState({ cargando: true, titulo_carg: 'Enviando..' })
    let { tipo, tipo_identificacion, identificacion, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, correo, usuario, login_directorio, indicativo_celular, celular, telefono, correo_personal, activo } = this.state;
    indicativo_celular = indicativo_celular ? indicativo_celular : null
    let estado = 1;
    celular = celular ? celular : null
    telefono = telefono ? telefono : null
    correo_personal = correo_personal ? correo_personal : null
    let data = { estado, tipo, tipo_identificacion, identificacion, primer_nombre: primer_nombre.toUpperCase(), segundo_nombre: segundo_nombre.toUpperCase(), primer_apellido: primer_apellido.toUpperCase(), segundo_apellido: segundo_apellido.toUpperCase(), correo, usuario, login_directorio, indicativo_celular, celular, telefono, correo_personal, activo, carnet: true }
    this.props.modificarPersona(this.state.id, data);
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  setSelecT = (name, show) => {
    this.setState({
      [name]: show
    })
  }

  pintarMenuItem = (lista) => {
    return Array.isArray(lista) ? lista.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>) : [];
  }


  render() {
    let {
      tipo,
      tipo_identificacion,
      identificacion,
      primer_nombre,
      segundo_nombre,
      primer_apellido,
      segundo_apellido,
      correo,
      usuario,
      login_directorio,
      indicativo_celular,
      celular,
      telefono,
      correo_personal,
      activo,
      cargando,
      titulo_carg,
      tipos_persona
    } = this.state;
    let { modalModPersona, mostrarModalModPersona, tipos_identificacion } = this.props;

    return (
      <Dialog open={modalModPersona} onClose={mostrarModalModPersona.bind(this, false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
        <AppBarModal titulo="¡ Modificar Persona !" mostrarModal={mostrarModalModPersona} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          {!cargando ?
            <ValidatorForm onSubmit={this.onSubmit}>
              <Grid container spacing={1} style={{ marginTop: 15 }}>
                <Grid item xs={12}>
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="select-tipo-identificacion">Tipo Identificación</InputLabel>
                    <Select
                      value={tipo_identificacion}
                      onChange={this.onChange}
                      id='select-tipo-identificacion'
                      name='tipo_identificacion'
                    >
                      {this.pintarMenuItem(tipos_identificacion)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="identificacion"
                    label="Identificación"
                    type="number"
                    fullWidth
                    name="identificacion"
                    value={identificacion}
                    validators={["required", 'minNumber:0']}
                    errorMessages={["El campo es requerido", "El campo no puede ser menor a 0"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ marginTop: 5 }}>
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="select-tipo-identificacion" id='tipo_persona_label'>Tipo de Persona</InputLabel>
                    <Select
                      labelId='tipo_persona_label'
                      id='select-tipo-persona'
                      name='tipo'
                      value={tipo}
                      label='Tipo de Persona'
                      fullWidth
                      onChange={this.onChange}
                    >
                      {this.pintarMenuItem(tipos_persona)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="primer_nombre"
                    label="Primer Nombre"
                    type="text"
                    fullWidth
                    name="primer_nombre"
                    value={primer_nombre}
                    validators={["required"]}
                    errorMessages={["El campo es requerido"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="segundo_nombre"
                    label="Segundo Nombre"
                    type="text"
                    fullWidth
                    name="segundo_nombre"
                    value={segundo_nombre}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="primer_apellido"
                    label="Primer Apelido"
                    type="text"
                    fullWidth
                    name="primer_apellido"
                    value={primer_apellido}
                    validators={["required"]}
                    errorMessages={["El campo es requerido"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="segundo_apellido"
                    label="Segundo Apellido"
                    type="text"
                    fullWidth
                    name="segundo_apellido"
                    value={segundo_apellido}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="correo"
                    label="Correo"
                    type="text"
                    fullWidth
                    name="correo"
                    value={correo}
                    validators={['required', 'isEmail']}
                    errorMessages={["El campo es requerido", "El correo no es valido"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="usuario"
                    label="Usuario"
                    type="text"
                    fullWidth
                    name="usuario"
                    validators={['required']}
                    errorMessages={["El campo es requerido"]}
                    value={usuario}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={3} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="indicativo_celular"
                    label="Indicativo celular"
                    type="number"
                    fullWidth
                    name="indicativo_celular"
                    value={indicativo_celular}
                    validators={['minNumber:1' && 'maxNumber:1999']}
                    placeholder="Ej: 57"
                    errorMessages={["Número inválido"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={9} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="celular"
                    label="Celular"
                    type="number"
                    fullWidth
                    name="celular"
                    value={celular}
                    validators={['minNumber:0']}
                    errorMessages={["El campo no puede ser menor a 0"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="telefono"
                    label="Telefono"
                    type="number"
                    fullWidth
                    name="telefono"
                    value={telefono}
                    validators={['minNumber:0']}
                    errorMessages={["El campo no puede ser menor a 0"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    margin="dense"
                    id="correo_personal"
                    label="Correo Personal"
                    type="text"
                    fullWidth
                    name="correo_personal"
                    value={correo_personal}
                    validators={['isEmail']}
                    errorMessages={["El correo no es valido"]}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={login_directorio ? true : false}
                        onChange={this.setSelecT.bind(this, "login_directorio", login_directorio ? 0 : 1)}
                        value="login_directorio"
                        color="primary"
                      />
                    }
                    label="¿ Autenticación con directorio activo ?"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activo ? true : false}
                        onChange={this.setSelecT.bind(this, "activo", activo ? 0 : 1)}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="¿ Carnet activo ?"
                  />
                </Grid>
              </Grid>
              <Button color="primary" type="submit" id='enviar_form_mod_persona' className="oculto"> Guardar </Button>
            </ValidatorForm>
            :
            <TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalModPersona(false)} />
          <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_mod_persona').click() }} />
        </DialogActions>
      </Dialog>
    )
  }
}

//Props del componente
PersonasModificar.propTypes = {
  //variables
  modalModPersona: PropTypes.bool.isRequired,
  persona: PropTypes.shape({
    id: PropTypes.number.isRequired,
    login_directorio: PropTypes.number.isRequired,
    activo: PropTypes.number.isRequired,
    tipo_identificacion: PropTypes.object.isRequired,
    identificacion: PropTypes.number.isRequired,
    primer_nombre: PropTypes.string.isRequired,
    segundo_nombre: PropTypes.string,
    primer_apellido: PropTypes.string.isRequired,
    segundo_apellido: PropTypes.string,
    correo: PropTypes.string.isRequired,
    usuario: PropTypes.string.isRequired,
  }),
  //funciones
  mostrarModalModPersona: PropTypes.func.isRequired,
}


export default PersonasModificar;

