import React, { Component } from 'react'
import { Dialog, DialogActions, DialogContent,Grid, List,Paper } from '@material-ui/core'
import AppBarModal from '../../general/AppBarModal'
import { BtnCargar, BtnForm } from '../../general/BotonesAccion';
import emma_w from '../../../global/imagenes/emma_w.png';
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import AlertasSimple from '../../general/AlertasSimple';

//Components
import Perfil from './Perfil'
import TareasTerminadas from '../../general/TareasTerminadas';

export default class PortalPerfilData extends Component {
    constructor() {
        super();
        this.state = {
            archivosTotales: [],
        }
    }

    submitDatos = () => {
        document.getElementById('enviar_form_multi_archivo').click()
    }

    submit = (archivo) => {
        let { archivosTotales } = this.state
        let { actualizarMensaje,requisitos } = this.props
        let contador = 0
        let contador2 = 0
        console.log(archivo)
        requisitos.map((info) => {
        if (info.valora === '1') {
            contador = contador + 1;
            archivo.map((j) => {
            if (j.codigo === info.id) {
                contador2 = contador2 + 1
            }
            })
        }

        })
        if (contador2 >= contador) {
            archivo.map((archi => {
                if (archivosTotales.length === 10000) actualizarMensaje({ titulo: `Se ha superado el numero maximo de archivos`, tipo: 'info', mostrar: true, tiempo: 6000 })
                else archivosTotales.push(archi)
            }))
        } else actualizarMensaje({ titulo: 'Debe adjuntar los documentos que son obligatorio', tipo: 'info', mostrar: true, tiempo: 6000 });
        this.enviarArchivo(archivosTotales)
        this.setState({
            archivosTotales: []
        })
    }

    enviarArchivo = (arc) => {
        let {llave,guardarArchivos } = this.props
        console.log(arc)
        if (arc.length > 0 && llave === "Anexos") {
            return guardarArchivos(arc, "anexos_crear")
        }
    }

    Requisitos = () => {

        let { requisitos } = this.props
      
        let obligatorio = []
        requisitos.map((info) => {
          if (info.valora === '1') {
            obligatorio.push({ item: info.nombre })
          }
        })
      
        const trasnformarDataAdj = (data) => {
          let r = [];
          data.map((info) => r.push({ nombre: info.nombre, /*descripcion: info.descripcion,*/ codigo: info.id, archivo: info.archivo, formatos: info.valorg, obligatorio: info.valora }))
          return r;
        }
      
        const acciones = (data, callback) => {
          const onClickCargar = (data) => callback(data);
      
          let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
      
          return <div>{cargar}</div>;
        }
      
        return (
          <React.Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper elevation={0} square>
                  <List component="nav" arial-label="main">
                    <EnviarMultiplesArchivos
                      acciones={acciones}
                      enModal={false}
                      enviarArchivos={(archivos) => this.submit(archivos)}
                      limite={3}
                      mostrarMensaje={false}
                      enLista={true}
                      lista={trasnformarDataAdj(requisitos)}
                      formatos={true}
                    />
                  </List>
                </Paper>
              </Grid>
              <AlertasSimple
                tipo='info'
                titulo={'Estimado, tener en cuenta que estos documentos son obligatorios: '}
                lista={obligatorio}
                margin='0px'
              />
            </Grid>
          </React.Fragment>
        );
      }

    render() {
        let {
            modalAddPerfilPortal,
            mostrarModalAddPerfilPortal,
            llave,
            datos_modal,
            onChangeState,
            enviarFormActual,
            datosPrincipales,
            datosAcademicos,
            datosHabilidad,
            datosExperiencia,
            datosNoExperiencia,
            datosDistincion,
            agregarArchivos,
            eliminarArchivo,
            archivos,
            actualizarMensaje,
            imgPerfil,
            cargandoPerfil,
            requisitos,
            guardarArchivos
        } = this.props
        return (
            <div>
                <Dialog open={modalAddPerfilPortal} onClose={() => mostrarModalAddPerfilPortal(false)} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                    <AppBarModal titulo={'Agregar ' + datos_modal.nombre_modal} mostrarModal={() => mostrarModalAddPerfilPortal(false)} titulo_accion="" />
                    <DialogContent style={{ padding: 0, overflowX: 'hidden' }} className="scroll">
                        <div>
                            <Perfil
                                llave={llave}
                                onChangeState={onChangeState}
                                datosPrincipales={datosPrincipales}
                                datosAcademicos={datosAcademicos}
                                datosExperiencia={datosExperiencia}
                                datosNoExperiencia={datosNoExperiencia}
                                datosHabilidad={datosHabilidad}
                                datosDistincion={datosDistincion}
                                agregarArchivos={agregarArchivos}
                                eliminarArchivo={eliminarArchivo}
                                archivos={archivos}
                                actualizarMensaje={actualizarMensaje}
                                requisitos={requisitos}
                            />
                        </div>
                        {llave == "Anexos" &&
                            <React.Fragment>
                                <DialogContent>
                                    <AlertasSimple
                                        tipo='info'
                                        titulo={'Estimado Estudiante/Graduado, para la efectividad de tu proceso, asegúrate de cargar todos los documentos solicitados.'}
                                        /* lista={'hola'} */
                                        margin='0px'
                                    />
                                </DialogContent>
                                <Grid container>
                                    {this.Requisitos()}
                                </Grid>
                            </React.Fragment>

                        }

                        {/* cargandoPerfil ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
                                :   <div>
                                        <Perfil
                                            llave={llave}
                                            onChangeState={onChangeState}
                                            datosPrincipales={datosPrincipales}
                                            datosAcademicos={datosAcademicos}
                                            datosExperiencia={datosExperiencia}
                                            datosHabilidad={datosHabilidad}
                                            datosDistincion={datosDistincion}
                                            agregarArchivos={agregarArchivos}
                                            eliminarArchivo={eliminarArchivo}
                                            archivos={archivos}
                                            actualizarMensaje={actualizarMensaje}
                                        />
                                    </div> */}

                    </DialogContent>
                    <DialogActions>
                        <BtnForm texto="CERRAR" callback={() => mostrarModalAddPerfilPortal(false)} />
                        {llave == "Anexos" ? <BtnForm texto="GUARDAR" callback={() => this.submitDatos()} />:<BtnForm texto="GUARDAR" callback={() => enviarFormActual()} />}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}