import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { obtenerValoresFiltros, transformar, consulta } from "../../../global/js/funciones";
import BuscarSelect from '../../general/BuscarSelect';
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from '../../general/BuscarPersona';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { PROGRAMAS, ENTIDADES_FINANCIERAS, TIPOS_FINANCIEROS } from "./../helper";

class CreditoFiltrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      cargar: true,
      programas: [],
      entidades: [],
      programa: null,
      entidad: '',
      categoria: '',
      tipos: [],
      tipo: '',
      estudiante: {
        id: 0,
        nombre: ''
      },
      buscarEstudiante: false,
    }
  }


  async componentDidUpdate({ modalAddFiltrosCredito, accionProceso }) {
    let { modalAddFiltrosCredito: modalAddFiltrosCreditoNew,  accionProceso: accionProcesoNew } = this.props;
    if (modalAddFiltrosCredito !== modalAddFiltrosCreditoNew && this.state.cargar) {
      this.setState({ cargando: true })
      let programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': PROGRAMAS }, { 'llave': 'valorc', 'valor': 'pregrado' }, { 'llave': 'estado', 'valor': 1 }]);
      let entidades = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': ENTIDADES_FINANCIERAS }, { 'llave': 'estado', 'valor': 1 }]);
      let tipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_FINANCIEROS }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({
        'programas': transformar(programas),
        entidades,
        cargando: false,
        cargar: false,
        tipos: tipos.sort((a, b) => a.valora - b.valora)
      })

    }

    if (accionProceso != accionProcesoNew && accionProceso != 'inicio') {
      this.setState({
        programa: null,
        tipo: '',
        entidad: '',
        categoria: '',
        estudiante: {
          id: 0,
          nombre: ''
        }
      })
    }
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  resetState = () => {
    let { limpiar, mostrarModalAddFiltrosCredito } = this.props;
    this.setState({
      programa: null,
      entidad: '',
      categoria: '',
      tipo: '',
    })
    limpiar();
    mostrarModalAddFiltrosCredito(false)
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje, accionProceso, mostrarTodos, mostrarModalAddFiltrosCredito } = this.props;
    let {
      categoria,
      entidad,
      programa,
      tipo,
      estudiante
    } = this.state;

    let filtros = [];
    if (categoria !== '') filtros.push({ 'llave': 'categoria', 'valor': categoria });
    if (entidad !== '') filtros.push({ 'llave': 'entidad', 'valor': entidad });
    if (programa !== null) filtros.push({ 'llave': 'programa', 'valor': programa.value });
    if (tipo !== '') filtros.push({ 'llave': 'tipo', 'valor': tipo });
    if (estudiante.id) filtros.push({ 'llave': 'usuario_registro', 'valor': estudiante.id });
    if (filtros.length > 0) {
      if (accionProceso == 'inicio') mostrarTodos('Tod_Cre')
      mostrarModalAddFiltrosCredito(false)
      filtrar(filtros);
    }
    else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { modalAddFiltrosCredito, mostrarModalAddFiltrosCredito, accionProceso } = this.props;
    let {
      categoria,
      entidad,
      programa,
      tipo,
      programas,
      entidades,
      tipos,
      cargando,
      estudiante,
      buscarEstudiante
    } = this.state;
    let habilitar_limpiar = accionProceso !== 'Tod_Cre' && accionProceso !== 'inicio'
    return (
      <>
      <Dialog fullWidth={true} maxWidth="sm" open={modalAddFiltrosCredito} onClose={() => mostrarModalAddFiltrosCredito(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalAddFiltrosCredito} titulo_accion={ habilitar_limpiar ? "Limpiar" : ''} accion={habilitar_limpiar ? this.resetState : ''} />
        <DialogContent className='scroll'>
          {
            cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <InputBuscar
                    id='estudiante'
                    label='Estudiante'
                    placeholder='Clic aquí para buscar'
                    value={estudiante.nombre}
                    callback={() => this.setState({ buscarEstudiante: true })} />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <BuscarSelect datos={programas} cambiarEstado={(value) => this.setState({ programa: value })} valor={programa} id='select-programa' nombre='Programa' placeholder='Seleccione Programa' />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="tipo">Seleccione Tipo de Proceso</InputLabel>
                    <Select
                      inputProps={{
                        name: 'tipo',
                        id: 'tipo',
                      }}
                      value={tipo}
                      onChange={this.onChange}>
                      {
                        tipos.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="entidad">Seleccione Entidad</InputLabel>
                    <Select
                      inputProps={{
                        name: 'entidad',
                        id: 'entidad',
                      }}
                      value={entidad}
                      onChange={this.onChange}>
                      {
                        entidades.map(({ id, nombre }) => <MenuItem key={id} value={id}>{nombre}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="categoria">Categoria</InputLabel>
                    <Select
                      inputProps={{
                        name: 'categoria',
                        id: 'categoria',
                      }}
                      value={categoria}
                      onChange={this.onChange}
                      required={true}>
                      <MenuItem value={1}>Nuevo</MenuItem>
                      <MenuItem value={2}>Renovación</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAddFiltrosCredito(false)} />
          <BtnForm texto="FILTRAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog >

      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={buscarEstudiante} onClose={() => this.setState({ buscarEstudiante: false })}>
          <AppBarModal titulo={'¡ Buscar Estudiante !'} mostrarModal={() => this.setState({ buscarEstudiante: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarEstudiante: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>
            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              estudiante: {
                id,
                nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarEstudiante: false
            })} />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarEstudiante: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

//Props del componente
CreditoFiltrar.propTypes = {
  //variables
  modalAddFiltrosCredito: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAddFiltrosCredito: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
  limpiar: PropTypes.func.isRequired,
}
export default CreditoFiltrar;
