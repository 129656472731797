import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Select, FormControl, MenuItem, InputLabel, StepLabel, Step, Stepper, TextField,
  Dialog, DialogContent, DialogActions, Grid} from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal'
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { BtnForm } from "../../general/BotonesAccion";
import { consulta, obtenerValoresFiltros } from "../../../global/js/funciones";
import { AREA_DERECHO } from '../helper';


const configStep = ['Hechos'];

function Hechos(component_props) {
  let {hechos,onChange, area, area_derecho,Pretensiones,areaAnterior}= component_props
  return (
    <>
      <Grid container spacing={2} style={{ marginBotton: '40px' }}>
        <Grid item xs={12}>
          <TextField
            value={areaAnterior}
            required
            type="text"
            id="areaAnterior"
            name="areaAnterior"
            label="Area de derecho seleccionada inicialmente"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl className='form-control' required>
            <InputLabel>Area del Derecho Nueva</InputLabel>
            <Select id='area' value={area} onChange={onChange} name='area' required>
              {
                area_derecho.map((datos) => (
                  <MenuItem key={datos.id} value={datos.id}>{datos.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={Pretensiones}
            required
            type="text"
            id="Pretensiones"
            name="Pretensiones"
            label="Pretensiones"
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={hechos}
            required
            type="text"
            id="hechos"
            name="hechos"
            label="Escriba los hechos"
            multiline
            fullWidth
            rows={8}
            onChange={onChange}
          />
        </Grid>

      </Grid>
    </>
    );
  }


class ConsultoriaDuplicar extends Component{

  constructor(props) {
    super(props)
    this.state={
      activeStep:0,
      cargando: true,
      titulo_res: 'Cargando',
      tipo_identificacion:'',
      id_asociado:'',
      hechos: '',
      Pretensiones:'',
      primer_nombre: '',
      primer_apellido: '',
      segundo_nombre: '',
      segundo_apellido: '',
      telefono: '',
      email: '',
      barrio: '',
      direccion: '',
      documento: '',
      genero: '',
      departamento: '',
      ciudad: '',
      comunidad: '',
      discapacidad:'',
      area: '',
      areaAnterior:'',
      egresado:false,
      anho:'',
      programa:'',
      Pretensiones:'',
      tipo_consultoria: '',
      area_derecho:[],
      cargar: true
    }
  }
  async componentDidMount(){

  }
 
  async componentDidUpdate({ modalDuplicarConsultoria, proceso }, { }) {
    let { cargar } = this.state
    let { modalDuplicarConsultoria: modalDuplicarConsultoriaNew, proceso: procesoNew } = this.props;
    if (modalDuplicarConsultoria !== modalDuplicarConsultoriaNew) {
      if (modalDuplicarConsultoriaNew) {
        if (cargar) {
          this.setState({ cargando: true })
          let area_derecho = await obtenerValoresFiltros([{ "llave": 'generica', 'valor': AREA_DERECHO }])
          this.setState({ area_derecho, cargando: false, cargar: false })
        }
        if (proceso.id !== procesoNew.id) this.obtenerDatos()
      }
    }
  }

  async obtenerSolicitud() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    return new Promise(resolve => {
       consulta(`api/v1.0/consultoria/detalle/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerAdjuntos() {
    let {proceso} = this.props
    let f = proceso.id
    this.setState({ cargando: true });
    return new Promise(resolve => {
      consulta(`api/v1.0/consultoria/adjuntos/${f}?`, null, null, (error, estado, resp) => {
          resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerDatos(){
    let datos = await this.obtenerSolicitud();
    this.setState({
      id_asociado:datos[0].id,
      tipo_identificacion:datos[0].solicitante.tipo_identificacion.id,
      documento:datos[0].solicitante.identificacion,
      primer_nombre:datos[0].solicitante.primer_nombre,
      segundo_nombre:datos[0].solicitante.segundo_nombre,
      primer_apellido:datos[0].solicitante.primer_apellido,
      segundo_apellido:datos[0].solicitante.segundo_apellido,
      telefono:datos[0].solicitante.telefono,
      departamento:datos[0].solicitante.departamento.id,
      ciudad:datos[0].solicitante.ciudad.id,
      barrio:datos[0].solicitante.lugar_residencia,
      direccion:datos[0].solicitante.direccion,
      comunidad:datos[0].solicitante.comunidad.id,
      genero:datos[0].solicitante.genero.id,
      discapacidad:datos[0].solicitante.discapacidad === '0'? 'NO': 'SI',
      areaAnterior:datos[0].area.nombre,
      hechos:datos[0].hechos,
      email:datos[0].solicitante.correo,
      genero:datos[0].solicitante.genero.id,
      comunidad:datos[0].solicitante.comunidad.id,
      fecha:datos[0].fecha_registro,
      programa:datos[0].programa?datos[0].programa.nombre:'',
      Pretensiones:datos[0].Pretensiones,
      anho:datos[0].anho?datos[0].anho:'',
      tipo_consultoria: datos[0].tipo.id,
      cargando: false
    })
  }

  onChange=({target})=>{
    let {value, name}= target;
  this.setState({
    [name]:value
  })
}


changeState = (name, value) => {
  this.setState({
    [name]: value
  })
}

checked = ({target}) => {
  this.setState({
    [target.name]: target.checked
  })
}


  enviarDatos = async () => {
    let { tipo_identificacion, ciudad, departamento, primer_nombre, genero, discapacidad,
      comunidad, area, primer_apellido, segundo_nombre, segundo_apellido, telefono, email, barrio,
      direccion, documento, hechos, Pretensiones, programa, anho, id_asociado, tipo_consultoria } = this.state
    let { actualizarMensaje } = this.props
    const data = new FormData();
    if (!area) {
      actualizarMensaje({ titulo: 'Para duplicar la solicitud por favor seleccione la nueva Área de derecho', tipo: 'info', mostrar: true, tiempo: 6000 });
    } else {
      this.setState({ cargando: true, titulo_res: 'Duplicando..' })
      data.append('id_asociado', `Radicado  SC-${id_asociado}`)
      data.append('total', null);
      data.append('hechos', hechos);
      data.append('Pretensiones', Pretensiones);
      data.append('discapacidad', discapacidad);
      data.append('tipo_identificacion', tipo_identificacion);
      data.append('identificacion', documento);
      data.append('telefono', telefono);
      data.append('correo', email);
      data.append('area', area);
      data.append('primer_nombre', primer_nombre.toUpperCase());
      data.append('segundo_nombre', segundo_nombre.toUpperCase());
      data.append('primer_apellido', primer_apellido.toUpperCase());
      data.append('segundo_apellido', segundo_apellido.toUpperCase());
      data.append('departamento', departamento);
      data.append('ciudad', ciudad);
      data.append('comunidad', comunidad);
      data.append('genero', genero);
      data.append('lugar_residencia', barrio);
      data.append('direccion', direccion);
      data.append('tipo_consultoria', tipo_consultoria);
      if (programa !== '') {
        data.append('programa', programa.value);
      }
      if (anho !== '') {
        data.append('anho', anho);
      }
      this.props.guardarConsulta(data, () => this.setState({ cargando: false, titulo_res: 'Cargando' }));
      this.reiniciarEstados()
      this.props.mostrarModalDuplicarConsultoria(false)
    }
  }

  reiniciarEstados(){
    this.setState({
      activeStep:0,
      tipo_identificacion:'',
      id_asociado:'',
      hechos: '',
      Pretensiones:'',
      primer_nombre: '',
      primer_apellido: '',
      segundo_nombre: '',
      segundo_apellido: '',
      telefono: '',
      email: '',
      barrio: '',
      direccion: '',
      documento: '',
      genero: '',
      departamento: '',
      ciudad: '',
      comunidad: '',
      discapacidad:'',
      area: '',
      anho:'',
      programa:'',
      tipo_consultoria: ''
    })
  }
  

  configStepContent = () =>{
    let { activeStep, area, hechos, Pretensiones, areaAnterior, area_derecho } = this.state
    let { actualizarMensaje } = this.props
    switch(activeStep){
      case 0:
      return(
        <Hechos{...{
          onChange:this.onChange,
          actualizarMensaje,
          hechos,
          area,
          area_derecho,
          Pretensiones,
          areaAnterior,
        }}
        />
      )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacia' widthImg='10%' marginTop='10%'/>

    }
  }
  render(){
    let { modalDuplicarConsultoria, mostrarModalDuplicarConsultoria } = this.props
    let { activeStep, cargando, titulo_res } = this.state
    return (
      <Dialog fullWidth={true} maxWidth="md" open={modalDuplicarConsultoria} onClose={() => mostrarModalDuplicarConsultoria(false)}>
        <AppBarModal titulo={"Duplicar Consultas Juridicas"} mostrarModal={() => mostrarModalDuplicarConsultoria(false)} titulo_accion="Cerrar" accion={() => mostrarModalDuplicarConsultoria(false)} />
        <DialogContent className='scroll'>
          {!cargando ?
            <Grid container>
              <Grid item xs={12} md={12} >
                <Stepper activeStep={activeStep}>
                  {
                    configStep.map(label => (
                      <Step key={label}>
                        <StepLabel><p>{label}</p></StepLabel>
                      </Step>
                    ))
                  }
                </Stepper>
              </Grid>
              <Grid item xs={12} md={12}>
                {this.configStepContent()}
              </Grid>
            </Grid>
            : <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true} />
          }
        </DialogContent>
        <DialogActions>
          {!cargando && <BtnForm texto="GUARDAR" callback={() => this.enviarDatos()} />}
        </DialogActions>
      </Dialog >
    )
  }
}
ConsultoriaDuplicar.propTypes = {
  //variables
  actualizarMensaje: PropTypes.func.isRequired,
  modalDuplicarConsultoria: PropTypes.bool.isRequired,

}

export default ConsultoriaDuplicar;