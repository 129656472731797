import React from 'react';
import { Hidden, BottomNavigation, BottomNavigationAction, Tab, Tabs } from '@material-ui/core'
import MiEmpresa from '@material-ui/icons/LocationOn'
import CreateIcon from '@material-ui/icons/Create';
import MiPerfil from '@material-ui/icons/Person'
import Historial from '@material-ui/icons/ImportContacts'
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import DocInformativos from '../DocInformativos';

const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;

export function Menu({ sele, seleccionar, doc_informativos }) {
  const clase = (item, selec) => item === selec ? 'item_selec' : 'item_modulo';
  const pintar = (app, classN = '') => {
    if (!app) {
      return (
        <div className={classN}>
          <BottomNavigation showLabels className={clase('vacante', sele)} onClick={() => seleccionar('vacante')}>
            <BottomNavigationAction label={'Procesos'} icon={<MiEmpresa />} />
          </BottomNavigation>

          <BottomNavigation showLabels className={clase('nuevo', sele)} onClick={() => seleccionar('nuevo')}>
            <BottomNavigationAction label={'Nuevo'} icon={<CreateIcon />} />
          </BottomNavigation>

          <BottomNavigation showLabels className={clase('perfil', sele)} onClick={() => seleccionar('perfil')}>
            <BottomNavigationAction label={'Perfil'} icon={<MiPerfil />} />
          </BottomNavigation>

          <BottomNavigation showLabels className={clase('historial', sele)} onClick={() => seleccionar('historial')}>
            <BottomNavigationAction label={'Historial'} icon={<Historial />} />
          </BottomNavigation>

          <BottomNavigation showLabels className={clase('pqrs', sele)} onClick={() => seleccionar('pqrs')}>
            <BottomNavigationAction label={'PQRS'} icon={<HelpOutlineIcon />} />
          </BottomNavigation>

          <DocInformativos
            Documentos={doc_informativos}
            rendering={(click) => <BottomNavigation showLabels onClick={click} className={clase('documentos', sele)} ><BottomNavigationAction label={'Documentos'} icon={<DescriptionIcon />} /> </BottomNavigation>}
          />
        </div>
      );
    } else {
      return (
        <div className={classN}>
          <Tabs value={sele} variant='fullwidth' scrollButtons="off" >
            <Tab label='Procesos' icon={<MiEmpresa className='menu_icono' />} className={clase('vacante', sele, '_app')} value='vacante' onClick={() => seleccionar('vacante')} />

            <Tab label='Nuevo' icon={<CreateIcon className='menu_icono' />} className={clase('nuevo', sele, '_app')} value='nuevo' onClick={() => seleccionar('nuevo')} />

            <Tab label='Perfil' icon={<MiPerfil className='menu_icono' />} className={clase('perfil', sele, '_app')} value='perfil' onClick={() => seleccionar('perfil')} />

            <Tab label='Historial' icon={<Historial className='menu_icono' />} className={clase('historial', sele, '_app')} value='historial' onClick={() => seleccionar('historial')} />

            <Tab label='PQRS' icon={<HelpOutlineIcon className='menu_icono' />} className={clase('pqrs', sele, '_app')} value='pqrs' onClick={() => seleccionar('pqrs')} />

            <DocInformativos
              Documentos={doc_informativos}
              rendering = { (click) => <Tab label='Documentos' icon={<DescriptionIcon className='menu_icono' />} className={clase('documentos', sele, '_app')} value='documentos' onClick = { click } /> }
            />
          </Tabs>
        </div>
      )
    }
  }

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, 'menu_modulo')} </Hidden>
        <Hidden smUp> {pintar(true, 'menu_modulo_app')}</Hidden>
      </div>
    </div>
  );
};

export function ProcesoMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('proceso', sele)} onClick={() => seleccionar('proceso')} />
        </BottomNavigation>
      </div>
    )
  }

  return (
    <div>
      {pintar('menu_modal')}
    </div>
  );
}