import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { obtenerPermisosValor } from "../../../global/js/funciones";
import BuscarSelect from '../../general/BuscarSelect';
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import PracticasBuscarEmpresa from "../PracticasBuscarEmpresa";
import InputBuscar from '../../general/InputBuscar';

class PracticasHvFiltrar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      empresas: [],
      sucursales: [],
      empresa: '',
      sucursal: null,
      contratado: '',
      estado_actual: '',
      cargando: false,
      sw: true,
      modal_buscar_empresa: false,
    }
  }

  async componentDidUpdate({ }, { empresa }) {
    let { empresa: empresaNew, sw } = this.state;
    if (((empresaNew && empresa && empresaNew.value !== empresa.value) || (!empresa && empresaNew.value && sw))) {
      this.setState({ sw: false })
      this.cargarSucursalesEmpresa(empresaNew.value)
    }
  }

  cargarSucursalesEmpresa = async (empresa) => {
    this.setState({ cargando: true });
    let sucursales = await obtenerPermisosValor(empresa);
    sucursales = sucursales.map(({ secundario }) => ({
      value: secundario.id,
      label: secundario.nombre,
    }));
    this.setState({ sucursales, cargando: false });
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  resetState = () => {
    let { limpiar, mostrarModalAddFiltrosHv } = this.props;
    this.setState({
      sucursales: [],
      empresa: null,
      sucursal: null,
      contratado: '',
      estado_actual: '',
      cargando: true,
    })
    limpiar();
    mostrarModalAddFiltrosHv(false)
  }

  onSubmit = e => {
    let { filtrar, actualizarMensaje } = this.props;

    let {
      empresa,
      sucursal,
      contratado,
      estado_actual,
    } = this.state;

    let filtros = [];
    if (empresa !== '') filtros.push({ 'llave': 'empresa', 'valor': empresa.value });
    if (sucursal !== null) filtros.push({ 'llave': 'sucursal', 'valor': sucursal.value });
    if (contratado !== '') filtros.push({ 'llave': 'contratado', 'valor': contratado });
    if (estado_actual !== '') filtros.push({ 'llave': 'estado_actual', 'valor': estado_actual });
    if (filtros.length > 0) filtrar(filtros);
    else actualizarMensaje({ titulo: 'Seleccione Algún filtro', tipo: 'info', mostrar: true, tiempo: 6000 });
    e.preventDefault();
  }

  render() {
    let { modalAddFiltrosHv, mostrarModalAddFiltrosHv } = this.props;
    let {
      empresa,
      sucursal,
      estado_actual,
      contratado,
      sucursales,
      cargando,
      modal_buscar_empresa
    } = this.state;
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalAddFiltrosHv} onClose={() => mostrarModalAddFiltrosHv(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={mostrarModalAddFiltrosHv} titulo_accion="Limpiar" accion={this.resetState} />
        <DialogContent className='scroll'>
          {
            cargando ? <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" /> :
              <Grid container spacing={3} className='margin_cont' >
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  < PracticasBuscarEmpresa
                    seleccionar={({ id, nombre }) => {
                      this.setState({ empresa: { value: id, label: nombre }, modal_buscar_empresa: false })
                    }}
                    modalBuscarEmpresa={modal_buscar_empresa}
                    mostrarModalBuscarEmpresa={(modal_buscar_empresa) => this.setState({ modal_buscar_empresa })}
                  />
                  <InputBuscar
                    placeholder='Seleccione Empresa'
                    label={'Empresa'}
                    id='empresa_id' value={(empresa && empresa.label) && empresa.label}
                    callback={() => this.setState({ modal_buscar_empresa: true })} />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                  <BuscarSelect datos={sucursales} cambiarEstado={(value) => this.setState({ sucursal: value })} valor={sucursal} id='select-sucursal' nombre='Sucursal' placeholder='Seleccione Sucursal' />
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="estado_actual">Estado</InputLabel>
                    <Select
                      inputProps={{
                        name: 'estado_actual',
                        id: 'estado_actual',
                      }}
                      value={estado_actual}
                      onChange={this.onChange}
                      required={true}>
                      <MenuItem value={0}>No Llamaron</MenuItem>
                      <MenuItem value={1}>Asistió</MenuItem>
                      <MenuItem value={2}>No Asistió</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12} sm={12} md={6} lg={6} >
                  <FormControl className='form-control'>
                    <InputLabel htmlFor="contratado">Contratado</InputLabel>
                    <Select
                      inputProps={{
                        name: 'contratado',
                        id: 'contratado',
                      }}
                      value={contratado}
                      onChange={this.onChange} >
                      <MenuItem value={0}>En espera</MenuItem>
                      <MenuItem value={1}>NO</MenuItem>
                      <MenuItem value={2}>SI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
          }
        </DialogContent>
        <DialogActions>
          <BtnForm texto="CANCELAR" callback={() => mostrarModalAddFiltrosHv(false)} />
          <BtnForm texto="FILTRAR" callback={this.onSubmit} />
        </DialogActions>
      </Dialog >
    )
  }
}

//Props del componente
PracticasHvFiltrar.propTypes = {
  //variables
  modalAddFiltrosHv: PropTypes.bool.isRequired,
  //funciones
  mostrarModalAddFiltrosHv: PropTypes.func.isRequired,
  filtrar: PropTypes.func.isRequired,
  limpiar: PropTypes.func.isRequired,
}
export default PracticasHvFiltrar;
