import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { mostrarModulo, actualizarMensaje } from "../../../redux/actions/actGlobal";
import { Paper, Grid, DialogActions, DialogContent, Dialog, Card, CardContent, TextField, List, ListItem, ListItemText, Link } from '@material-ui/core'
import { consulta, rutaImg, obtenerValoresFiltros, mostrarError, crear_form_data, formulario } from "../../../global/js/funciones";
import { BtnDetalle, BtnPermiso, BtnForm, BtnRegresar, BtnFolder } from "../../general/BotonesAccion";
import ListarDatos from "./../../general/ListarDatos";
import AppBarModal from "./../../general/AppBarModal";
import Generica from "./../../personas/generica/Generica";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import AccionesRapidas from '../../general/AccionesRapidas';
import TituloAccion from '../../general/TituloAccion';
import { ESTADOS_INSCRIPCIONES, TIPO_INSCRIPCIONES } from '../helper';
import fechaIcon from '../../../global/imagenes/calendar.png';
import permisosIcon from '../../../global/imagenes/lightbulb.png';
import librespregrado from '../../../global/imagenes/librespregrado.png'
import icono_excel from '../../../global/imagenes/icono_excel.png'
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { mostrarModalPeriodoActualizarInscripcion } from "../../../redux/actions/actInscripciones";
import Genericas from "../../genericas/Genericas"
import { COLEGIOS_PREUNIVERSITARIO, CALIFICACION_CONTACTO, LINEAEDU, COSTEAR_ESTUDIOS } from '../helper';
import BuscarSelect from '../../general/BuscarSelect';
import EnviarArchivo from '../../general/EnviarArchivo';



function ActualizarPeriodo(component_props) {
  let {
    mostrarModalPeriodoActualizarInscripcion,
    modalPeriodoActualizarInscripcion,
    periodo_actualizar,
    fecha_periodo,
    enviarDatos,
    onChange,
    onChangeFecha,
    changeState,
    tipo_inscripcion_seleccionado,
    setSelect_value,
    años,
    periodos,
    periodo_seleccionado,
    opciones_tipo_inscripcion,
    periodo_inscripciones,
    periodo_educo,
  } = component_props

  return (
    <Dialog open={modalPeriodoActualizarInscripcion} maxWidth='xs' fullWidth={true} onClose={mostrarModalPeriodoActualizarInscripcion.bind(this, false)}>
      <AppBarModal titulo='¡ Actualizar Periodo !' mostrarModal={mostrarModalPeriodoActualizarInscripcion} titulo_accion='' />
      <DialogContent style={{ padding: 0 }} className='scroll' >
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <BuscarSelect
                datos={opciones_tipo_inscripcion}
                cambiarEstado={(value) => setSelect_value('tipo_inscripcion_seleccionado', value)}
                valor={tipo_inscripcion_seleccionado}
                id='select-inscripcion'
                nombre='Seleccionar Inscripcion'
                placeholder='Seleccione Modulo'
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                style={{ width: '100%', margin: '5px 0px 5px 0px' }}
                id="periodo_actual"
                label="Periodo"
                value={tipo_inscripcion_seleccionado ? tipo_inscripcion_seleccionado.value === 'Per_Edu' ? periodo_educo : periodo_inscripciones : ""}
                // value={periodo_inscripciones}
                onChange={(e)=>{changeState((tipo_inscripcion_seleccionado.value === 'Per_Edu'? 'periodo_educo' : 'periodo_inscripciones'), e.target.value)}}
                // disabled={false}
              />
              {/* <BuscarSelect
                datos={tipo_inscripcion_seleccionado ? (tipo_inscripcion_seleccionado.value === 'Per_Edu' ? años : periodos) : []}
                cambiarEstado={(value) => setSelect_value('periodo_seleccionado', value)}
                valor={periodo_seleccionado}
                id='select-periodo'
                nombre='Seleccionar Periodo'
                placeholder='Seleccione periodo'
              /> */}
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  margin="normal"
                  id="fecha_periodo"
                  format="yyyy/MM/dd"
                  value={fecha_periodo}
                  fullWidth
                  onChange={onChangeFecha}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <BtnForm texto="Aceptar" callback={() => enviarDatos()} />
        <BtnForm texto="Cerrar" callback={() => mostrarModalPeriodoActualizarInscripcion(false)} />
      </DialogActions>
    </Dialog>
  )
}


class InscripcionesPermisos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      personas: [],
      generica: {
        tipo: 0,
        id: 0,
      },
      modal: false,
      id_persona: null,
      periodo_actualizar: '',
      fecha_periodo: '',
      accionProceso: 'inicio',
      accion: 'procesos',
      periodo: [],
      tipo_inscripcion_seleccionado: null,
      opciones_tipo_inscripcion: [
        {
          value: 'Per_Edu',
          label: 'Inscripciones EDU.co',
        },
        {
          value: 'Per_Ins',
          label: 'Inscripciones',
        }
      ],
      periodos: [],
      años: [],
      periodo_inscripciones: "",
      periodo_educo: "",
      periodo_seleccionado: null,
      setSelect_value: (key, value) => {
        this.changeState(key, value)
      },
      openModalCargarExcel: false,
      setModalCargarExcel: (value) => {
        this.setState({ openModalCargarExcel: value })
      },
      masivo: '',
    }
  }

  changeState = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  async obtenerPeriodos(tipo) {
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/periodos/${tipo}`, null, null,
        (error, estado, resp) => {
          resolve(resp)
        }
      );
    })
  }

  async componentDidMount() {
    let personas = await this.obtenerGestores();
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Ins' }, { 'llave': 'estado', 'valor': '1' }]);
    let periodos = await this.obtenerPeriodos(1)
    let años = await this.obtenerPeriodos(2)
    let periodo_inscripciones = await obtenerValoresFiltros([{ llave: 'codigo', valor: 'Per_Ins' }])
    let periodo_educo = await obtenerValoresFiltros([{ llave: 'codigo', valor: 'Per_Edu' }])
    this.setState({ periodo_inscripciones: periodo_inscripciones[0].nombre, periodo_educo: periodo_educo[0].nombre, periodos: this.transformarEnviadoPeriodos(periodos), años: this.transformarEnviadoPeriodos(años), personas, cargando: false, periodo, periodo_actualizar: periodo[0].nombre, fecha_periodo: periodo[0].valora })
    let masivo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'insc_xlsx' }, { 'llave': 'estado', 'valor': '1' }]);
    this.setState({ masivo: masivo[0] });
  }

  transformarEnviadoPeriodos(data) {
    return data.map((e, index) => ({
      value: index,
      label: e.periodo,
      codigo: e.periodo,
    }));
  }

  async obtenerGestores() {
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/gestores`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }



  onChangeFecha = (date) => {
    this.setState({ fecha_periodo: date })
  }
  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  enviarDatos = (info) => {
    let { fecha_periodo, periodo_actualizar, tipo_inscripcion_seleccionado, periodo_seleccionado, periodo_inscripciones, periodo_educo} = this.state;
    let { actualizarMensaje } = this.props;
    let error = false
    periodo_seleccionado = tipo_inscripcion_seleccionado.value === 'Per_Edu' ? periodo_educo : periodo_inscripciones
    if (!fecha_periodo || !periodo_seleccionado || !tipo_inscripcion_seleccionado) { error = true }
    if (!error) {
      let data = {
        fecha: moment(fecha_periodo).format('YYYY-MM-DD'),
        periodo_actualizar: periodo_seleccionado,
        tipo_inscripcion: tipo_inscripcion_seleccionado.value,
      }
      this.guardarDatos(data)
      this.setState({ periodo_seleccionado, fecha_periodo })

    }
    else { actualizarMensaje({ titulo: 'Los Campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 }); }
  }

  guardarDatos = async (data) => {
    let { actualizarMensaje, mostrarModalPeriodoActualizarInscripcion } = this.props;
    return new Promise(resolve => {
      consulta(`api/v1.0/inscripciones/actualizar/fechaPeriodo`, data, "post", (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            mostrarModalPeriodoActualizarInscripcion(false);

          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
        resolve(estado === 200 && !error ? resp : null);

      })
    })
  }

  renderProcesos() {
    let { accionProceso } = this.state;
    return (
      <>
        {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.renderPracticas()}
        {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionProceso: 'inicio' })} /></div>}
      </>
    )
  }

  acciones(data) {
    const onClickPrograma = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 11, id: 5 } });
    const onClickEstado = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 12, id: ESTADOS_INSCRIPCIONES } });
    const onClickTipoInscripcion = (data) => this.setState({ id_persona: data.id, modal: true, generica: { tipo: 28, id: TIPO_INSCRIPCIONES } });
    let tipos = <BtnFolder callback={() => onClickTipoInscripcion(data)} texto='Tipo Inscripcion' />;
    let programa = <BtnDetalle callback={() => onClickPrograma(data)} texto='Programas' color='#2196f3' />;
    let estados = <BtnPermiso callback={() => onClickEstado(data)} texto='Estados' />;
    return <div>{tipos}{programa} {estados}</div>
  }

  pintarInicioProcesos() {
    let {
      periodo,
      periodo_actualizar,
      fecha_periodo,
      tipo_inscripcion_seleccionado,
      opciones_tipo_inscripcion,
      setSelect_value,
      años,
      periodos,
      periodo_seleccionado,
      periodo_inscripciones,
      periodo_educo,
      masivo
    } = this.state;
    let { modalPeriodoActualizarInscripcion, mostrarModalPeriodoActualizarInscripcion } = this.props
    return (
      <div style={{ padding: '2% 2% 0% 2%' }} >
        <Grid container direction={'row'} spacing={1} justify={'center'} >
          <Grid item xs={12} sm={12} md={10} lg={8} >
            <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
            <Paper elevation={0} square className="scroll contenido_fijo">
              <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => mostrarModalPeriodoActualizarInscripcion(true)}>
                    <AccionesRapidas imagen={fechaIcon} titulo={`¡ Actualizar Período !`} sub_titulo='' des_sub_titulo={`Actualiza la fecha y el periodo lectivo.`} />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionProceso: 'permisos' })}>
                    <AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores.' />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionProceso: 'adminpreuni' })}>
                    <AccionesRapidas imagen={librespregrado} titulo='¡ Administrar listas EDU.co !' sub_titulo='' des_sub_titulo='Agrega, Modifica y Elimina datos.' />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ openModalCargarExcel: true })}>
                    <AccionesRapidas imagen={icono_excel} titulo='¡ Cargar estudiantes !' sub_titulo='' des_sub_titulo='Agrega estudiantes mediante un excel.' />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <ActualizarPeriodo
          {...{
            modalPeriodoActualizarInscripcion,
            mostrarModalPeriodoActualizarInscripcion,
            periodo_actualizar,
            tipo_inscripcion_seleccionado,
            opciones_tipo_inscripcion,
            setSelect_value,
            años,
            periodo_inscripciones,
            periodo_educo,
            periodos,
            periodo_seleccionado,
            fecha_periodo,
            changeState: this.changeState,
            onChange: this.onChange,
            onChangeFecha: this.onChangeFecha,
            enviarDatos: this.enviarDatos
          }}
        />
        <EnviarArchivo modalAdd={this.state.openModalCargarExcel} mostrarModalAdd={this.state.setModalCargarExcel} titulo='Cargar excel' id='adj_archivo' name='adj_archivo' label='Adjuntar Archivo' formatos='Excel' mensaje={
          <Grid>
            Tenga en cuenta las siguientes indicaciones:
            <List dense={true}>
              <ListItem>
                <ListItemText
                  primary={
                    <Link
                      target="_blank"
                      underline='none'
                      href={masivo.archivo}
                      style={{ color: '#B22222', textDecoration: 'none' }}
                    >
                      ¡Haga clic para descargar la plantilla y registrar los datos de los participantes!
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText primary='No realice cambios en el formato de la plantilla.' />
              </ListItem>
            </List>
          </Grid>
        } enviarArchivo={(archivo) => this.enviarExcel(archivo)} />
      </div>
    )
  }

  renderPermisosInscripcion = () => {
    let { modal, id_persona, generica, personas } = this.state;
    return (
      <>
        <ListarDatos
          datos={personas}
          titulo='Lista Gestores'
          buscar={true}
          agregar={false}
          opciones={true}
          descargar='EMMA - GESTORES INSCRIPCIONES'
          id='tbl_gestores_ins'
          acciones={row => this.acciones(row)}
          avatarImg={true}
          avatar={({ identificacion }) => `${rutaImg}${identificacion}.jpg`}
          fila_principal={({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'nombre', 'mostrar': ({ primer_nombre, primer_apellido, segundo_apellido }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
            { 'id': 'correo' },
            { 'id': 'identificacion' },
          ]}
        />

        <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => this.setState({ modal: false })}>
          <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => this.setState({ modal: false })} accion={() => this.setState({ modal: false })} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica tipo={generica.tipo} persona={{ id: id_persona }} genericaRelacionId={generica.id} titulo="¡ Nuevo Permiso !" tituloInput="Permisos" tituloEliminar='¿ Eliminar Permiso ?' />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => this.setState({ modal: false })} />
          </DialogActions>
        </Dialog>
      </>
    )
  }

  renderProcesos() {
    let { accionProceso } = this.state;
    let dato = [COLEGIOS_PREUNIVERSITARIO, CALIFICACION_CONTACTO,
      LINEAEDU, COSTEAR_ESTUDIOS]// Se envia para que pueda agregar y modificar las listas
    return (
      <>
        {accionProceso === 'inicio' && this.pintarInicioProcesos()}
        {accionProceso === 'permisos' && this.renderPermisosInscripcion()}
        {accionProceso === 'adminpreuni' && <Genericas {...{ busquedaFija: true, idGenerica: dato }} />}
        {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ filtros_practica: [], accionProceso: 'inicio' })} /></div>}
      </>
    )
  }

  pintar = () => {
    let { cargando, accion } = this.state;
    return (
      <>
        {!cargando ?
          <div >
            {accion === 'procesos' && this.renderProcesos()}
          </div>
          : <TareasTerminadas imagen={emma_w} mensaje='Cargando..' widthImg='7%' marginTop='7%' cargando={true} />
        }
      </>
    )
  }

  enviarExcel = async (archivo) => {
    this.setState({ cargando: true });
    let { actualizarMensaje } = this.props;
    let formdata = new FormData();
    formdata.append('archivo', archivo);
    if (!archivo) {
      actualizarMensaje({ titulo: 'Debe seleccionar un archivo', tipo: 'info', mostrar: true, tiempo: 6000 });
      return;
    }
    formulario('api/v1.0/inscripciones/cargar_excel_estudiantes', formdata, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          this.state.setModalCargarExcel(false)
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      this.setState({ cargando: false });
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  render() {
    let { cargando } = this.state;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" cargando={true}/>;
    return (
      <>
        {this.pintar()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let { modalPeriodoActualizarInscripcion } = state.redInscripciones;
  let { usuario } = state.redGlobal;
  return {
    modalPeriodoActualizarInscripcion,
  }
};

const mapDispatchToProps = {
  actualizarMensaje,
  mostrarModalPeriodoActualizarInscripcion,
}

InscripcionesPermisos.propTypes = {
  mostrarModalPeriodoActualizarInscripcion: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(InscripcionesPermisos);