import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'


//FUNCIONES GLOBALES
import { Paper, Grid, Tooltip, Dialog, DialogActions, DialogContent, IconButton, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import { consulta, mostrarError, formulario, obtenerMisDatos, generarFiltros, obtenerValoresFiltros, crear_form_data, modificarValorGenericaAlter } from "../../global/js/funciones";
import { BtnDetalle, BtnGestionar, BtnEliminar, BtnForm, BtnRegresar, BtnEditar, BtnFolder } from "../general/BotonesAccion";
import { actualizarMensaje, mostrarModulo} from "../../redux/actions/actGlobal";
import ListarDatos from "../general/ListarDatos";
import TareasTerminadas from "../general/TareasTerminadas";
import AppBarModal from '../general/AppBarModal'
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import FilterListIcon from '@material-ui/icons/FilterList';
import EnviarMensajeFecha from '../general/EnviarMensajeFecha';
import moment from 'moment'

// FUNCIONES - COMPONENTES DEL MODULO
import { mostrarModalDetalleRetos, mostrarGestionRetos, mostrarModalFiltroRetos, mostrarModalModificarPeriodo, mostrarModalPermisoAsignatura, mostrarModalDesecharGrupo,mostrarModalAddFiltrosRetos } from "../../redux/actions/actRetoscuc";
import { MenuAdministrar } from './retoscuc/MenuRetos'
import RetosPermisos from './retoscuc/RetosPermisos'
import RetosDetalle from './retoscuc/SolicitudRetos'
import RetosGestionar from './retoscuc/RetosGestionar'
import Banner from './retoscuc/ui/BannerGestion'
import AdminInforme from './retoscuc/AdminInforme'
import RetosDesecharGrupo from './retoscuc/RetosDesecharGrupo';
import RetoFiltrar from './retoscuc/RetoFiltrar';

// IMAGENES
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import fechaIcon from '../../global/imagenes/calendar.png';
import permisosIcon from '../../global/imagenes/lightbulb.png';
import vistaIcon from '../../global/imagenes/hourglass.png';
import reportIcon from '../../global/imagenes/report.png';
// import fechaLimitesIcon from '../../global/imagenes/reloj_fechas_limites.png';


class RetosAdministrar extends Component {
	constructor(props) {
		super(props)
		this.state =
		{
			cargando: false,
			accion: 'Procesos',
			accionProceso: 'inicio',
			accionAdmin: 'inicio',
			accionInforme: 'inicio',
			vistaDetalle: 'detalle',
			administrador: false,
			filtros_retos: [],
			estados_procesos: [],
			periodos: [],
			retos: [],
			reto: null,
			periodo_filtro: '',
			periodo: '',
			filtrarPeriodo: false,
			cargarFiltroP: true,
			id_estudiante: 0,
			obtenerMisDatos: [],
			mostrarAdj: false,
			mostrarDoc: false,
		}
	}

	async componentDidMount() {
		let { mostrarModulo } = this.props;
		this.obtenerDatosIniciales();
		this.misDatos()
		mostrarModulo({ rutas: 'RetosAdministrar', nombre: 'retos' })
		this.esAdministrador();
	}

	async componentDidUpdate({},{ accion, accionProceso }) {
		let { accion: accionNew, accionProceso: accionProcesoNew, periodo_filtro } = this.state;
		if (accion !== accionNew) {
			if (accionNew === 'Procesos');
			this.setState({ accionAdmin: 'inicio' })
		}

		if (accionProceso !== accionProcesoNew) {
			if (accionProcesoNew != 'Tod_Ret') {
				this.cambiarEstado({ cargando: true })
				if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes(periodo_filtro)
				else this.actualizarRetos([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'periodo', 'valor': periodo_filtro }]);
			}
		}
	}

	misDatos = async () => {
		const datos_sesion = await obtenerMisDatos();
		this.setState({ datos_sesion })
	}

	async actualizarRetos(filtros = [{ 'llave': 'estado_actual__valorc', 'valor': 'si' }, { 'llave': 'periodo', 'valor': this.state.periodo }]) {
		this.setState({ cargando: true })
		let retos = await this.obtenerRetos(filtros);
		this.setState({ retos, cargando: false, filtros_retos: filtros })
	}

	esAdministrador() {
		let { usuario } = this.props;
		if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Ret_n1")) {
			this.setState({ administrador: true })
		}
	}

	cambiarEstado = (estados) => {
		this.setState(estados)
	}

	async obtenerDatosIniciales() {
		this.setState({ cargando: true });
		let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Ret' }]);
		this.obtenerEstadoSolicitudes(periodo[0].nombre);
		this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', periodo_filtro: periodo.length > 0 ? periodo[0].nombre : '' });
	}

	gestionarRetosNegados = async (id, data, callback) => {
		let { mostrarModalDesecharGrupo, actualizarMensaje } = this.props
		let form = await crear_form_data(data);

		formulario(`api/v1.0/retoscuc/${id}/gestionar`, form, "post", (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					mostrarModalDesecharGrupo(false)
					this.actualizarRetos(this.state.filtros_retos);
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			callback()
		})
	}


	gestionarRetos = async (id, data, archivos, callback,) => {
		let { mostrarGestionRetos, actualizarMensaje } = this.props
		let form = await crear_form_data(data);
		// archivos.map(({ archivo, codigo }, index) => {
		// 	form.append(`archivo${index}`, archivo)
		// 	form.append(`codigo${index}`, codigo)
		// 	return true;
		// })
		if (data.codigo) {
			archivos.map((archivo, index) => {
				form.append(`archivo${index}`, archivo)
				form.append(`codigo${index}`, data.codigo)
				return true;
			})
		} else {
			archivos.map(({ archivo, codigo }, index) => {
				form.append(`archivo${index}`, archivo)
				form.append(`codigo${index}`, codigo)
				return true;
			})
		}
		formulario(`api/v1.0/retoscuc/${id}/gestionar`, form, "post", (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					mostrarGestionRetos(false)
					this.actualizarRetos(this.state.filtros_retos);
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			callback()
		})
	}


	filtrarEstadoSolicitudes = () => {
		let { periodo_filtro } = this.state
		this.cambiarEstado({ filtrarPeriodo: false, cargando: true })
		this.obtenerEstadoSolicitudes(periodo_filtro)
	}

	obtenerEstadoSolicitudes = (periodo) => {
		consulta(`api/v1.0/retos/estados/${periodo}`, null, null, (error, estado, resp) => {
			if (estado === 200) {
				let { general, extra } = resp
				if (extra.length > 0) {
					extra.map(({ solicitudes, nombre, codigo, orden }) => {
						let reporte = {
							'estado_actual__codigo': codigo,
							'estado_actual__nombre': nombre,
							'num_sol': solicitudes,
							'orden': orden
						}
						general.push(reporte);
					})
				}
				this.cambiarEstado({ estados_procesos: general.sort((a, b) => a.orden - b.orden), cargando: false });
			}
		})
	}

	async obtenerRetos(filtros = []) {
		let f = await generarFiltros(filtros);
		return new Promise(resolve => {
			consulta(`api/v1.0/retos/listar/1?${f}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : []);
			})
		})
	}


	configFiltros = (accion_actual) => {
		let { periodo_filtro } = this.state
		this.setState({ seleccionMasiva: [] })
		switch (accion_actual) {
		  case 'Tod_Ret':
			return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
		  case 'inicio':
			return [{ 'llave': 'periodo', 'valor': periodo_filtro }]
		  default:
			return [{ 'llave': 'estado_actual__codigo', 'valor': accion_actual }, { 'llave': 'periodo', 'valor': periodo_filtro }]
		}
	  }

	renderProcesos() {
		let { accionProceso } = this.state;
		let { ModalAddFiltrosRetos,mostrarModalAddFiltrosRetos, actualizarMensaje } = this.props
		return (
			<>
				<RetoFiltrar
					ModalAddFiltrosRetos={ModalAddFiltrosRetos}
					mostrarModalAddFiltrosRetos={mostrarModalAddFiltrosRetos}
					actualizarMensaje={actualizarMensaje}
					limpiar={() => this.actualizarRetos(this.configFiltros(accionProceso))}
					filtrar={(filtros) => { this.actualizarRetos(this.configFiltros(accionProceso).concat(filtros)) }}
					accionProceso={accionProceso}
					mostrarTodos={(seleccion) => this.setState({ accionProceso: seleccion })}
				/>
				{accionProceso == 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
				{accionProceso != 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ retos:[], filtros_retos: [], accionProceso: 'inicio' })} /></div>}
			</>
		)
	}

	pintarInicioAdm = () => {
		let { periodo_mod, fecha_periodo } = this.state;
		return (
			<div style={{ padding: '2% 2% 0% 2%' }}>
				<Grid container direction={'row'} spacing={1} justifyContent={'center'}>
					<Grid item xs={12} sm={12} md={10} lg={8}>
						<TituloAccion principal='' alterno='¿ Qué deseas hacer ?' />
						<Paper elevation={0} square className='scroll contenido_fijo'>
							<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.props.mostrarModalModificarPeriodo(true)}>
										<AccionesRapidas imagen={fechaIcon} titulo='¡ Gestionar el periodo !' sub_titulo='' des_sub_titulo={`El periodo academico actual ${periodo_mod} esta disponible hasta ${fecha_periodo}.`} />
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdmin: 'permisos' })}>
										<AccionesRapidas imagen={permisosIcon} titulo='¡ Controlar Permisos !' sub_titulo='' des_sub_titulo='Verifica, asigna y retira permisos a los gestores del módulo de validaciones.' />
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdmin: 'informes' })}>
										<AccionesRapidas imagen={reportIcon} titulo='¡ Informes !' sub_titulo='' des_sub_titulo='Generar reportes de los procesos de Retos Unicosta' />
									</Paper>
								</Grid>
								{/* <Grid item xs={12} sm={12} md={6} lg={6} >
									<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => this.setState({ accionAdmin: 'fechas_limites' })}>
										<AccionesRapidas imagen={fechaLimitesIcon} titulo='¡ Fechas Limites !' sub_titulo='' des_sub_titulo='Generar reportes de los procesos de Retos Unicosta' />
									</Paper>
								</Grid> */}
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}

	pintarAdministrar() {
		let { accionAdmin, administrador } = this.state;
		let { modalPermisoAsignatura, mostrarModalPermisoAsignatura, actualizarMensaje } = this.props
		if (!administrador) return <TareasTerminadas imagen={emma_s1} mensaje='No tiene los permisos asignados' widthImg='7%' marginTop='7%' />
		return (
			<>
				{accionAdmin === 'inicio' && this.pintarInicioAdm()}
				{accionAdmin !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionAdmin: 'inicio' })} /></div>}

				{accionAdmin === 'permisos' && <RetosPermisos mostrarModalPermisoAsignatura={mostrarModalPermisoAsignatura} modalPermisoAsignatura={modalPermisoAsignatura} actualizarMensaje={actualizarMensaje} />}
				{accionAdmin === 'informes' && <AdminInforme />}
			</>
		)
	}


	pintarProcesos() {
		let { retos, reto, administrador } = this.state;
		let { mostrarModalDetalleRetos, mostrarGestionRetos, modalGestionRetos, modalDesecharGrupo, mostrarModalDesecharGrupo, actualizarMensaje, mostrarModalFiltroRetos } = this.props;
		const obtenerColor = (codigo) => {
			let color = 'white';
			const colores = {
				'Ret_Gru': { 'backgroundColor': '#0044ff', color },
				'Ret_Env': { 'backgroundColor': '#ffc107', color },
				'Ret_Cal': { 'backgroundColor': '#5f02f5', color },
				'Ret_Env_e2': { 'backgroundColor': '#005E66', color },
				'Ret_Cal_e2': { 'backgroundColor': '#AF601A', color },
				'Ret_Env_e3': { 'backgroundColor': '#2E4053', color },
				'Ret_Cal_e3': { 'backgroundColor': '#685DE3', color },
				'Ret_Env_Fn': { 'backgroundColor': '#B329E6', color },
				'Ret_Cal_Fn': { 'backgroundColor': '#E4D281', color },
				'Ret_Sel': { 'backgroundColor': '#09BA69', color },
				'Ret_Dep': { 'backgroundColor': '#5af7fa', color },
				'Ret_Ire': { 'backgroundColor': '#bffa5a', color },
				'Ret_Gse': { 'backgroundColor': '#46fa64', color },
				'Ret_Act': { 'backgroundColor': '#6c036e', color },
				'Ret_Des': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Prof': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Depr': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Intr': { 'backgroundColor': '#fa3802', color },
				'Ret_Cer': { 'backgroundColor': '#fa3802', color },
				'Tod_Ret': { 'backgroundColor': '#0d47a1', color },
			}
			return (colores[codigo])
		}

		const acciones = (data) => {
			let { estado_actual: { codigo }, permiso } = data;

			const onClickDetalle = reto => {
				this.setState({ reto, vistaDetalle: 'detalle', id_estudiante: reto.usuario_registro.id })
				mostrarModalDetalleRetos(true)
			}

			const onClickGestionar = reto => {
				this.setState({ reto })
				mostrarGestionRetos(true)
			}

			const onClickDesecharGrupo = reto => {
				this.setState({ reto })
				
				mostrarModalDesecharGrupo(true)
			}

			const onClickEvidencias = reto => {
				this.setState({ reto, vistaDetalle: 'documentos', id_estudiante: reto.usuario_registro.id, mostrarAdj: true, mostrarDoc: true })
				mostrarModalDetalleRetos(true)
			}

			//  BOTONES DE GESTION 

			let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Detalle Solicitud' label='Detalle Retos' />;

			let gestionar = (codigo !== 'Ret_Gru' && codigo !== 'Ret_Des' && codigo !== 'Ret_Des_Prof' && codigo !== 'Ret_Des_Depr' && codigo !== 'Ret_Des_Intr' && codigo !== 'Ret_Act' && codigo !== 'Ret_Cal'  && codigo !== 'Ret_Cal_e2' && codigo !== 'Ret_Cal_e3' && codigo !== 'Ret_Gse' && codigo !== 'Ret_Cer' && (permiso == 1)) && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;

			let deshacer_grupo = (codigo == 'Ret_Gru' || codigo == 'Ret_Cal' || codigo == 'Ret_Cal_e2' || codigo == 'Ret_Cal_e3' && (permiso == 1)) && <BtnEliminar callback={() => 
				onClickDesecharGrupo(data)} color='#f50202' texto='Deshacer grupo' />
	
			let adjuntos = (codigo !== 'Ret_Gru' && permiso == 1) && <BtnFolder callback={() => onClickEvidencias(data)} color='#ffd600' texto='Evidencias' />;

			return (administrador || codigo == 'Ret_Gru' ? <div>{detalle}{deshacer_grupo}{adjuntos}{gestionar}</div> : <div>{detalle}{adjuntos}{gestionar}</div>);

		}

		return (
			<div>

				<RetosGestionar
					actualizarMensaje={actualizarMensaje}
					reto={reto}
					gestionarRetos={this.gestionarRetos}
					mostrarGestionRetos={mostrarGestionRetos}
					modalGestionRetos={modalGestionRetos}
				/>

				<ListarDatos
					datos={retos}
					titulo='Lista de Retos'
					id='tbl_retos_listar'
					opciones={true}
					descargar='RETOS - EMMA'
					filtrar={true}
					refrescar={true}
					actfiltrar={() => mostrarModalFiltroRetos(true)}
					actRefrescar={() => this.actualizarRetos(this.state.filtros_retos)}
					acciones={(data) => acciones(data)}
					buscar={true}
					avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
					color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
					fila_principal={({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
					filas={[
						{ 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
						{ 'id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
						{ 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
						{ 'id': 'estado_actual', 'mostrar': ({ estado_actual }) => `${estado_actual.nombre}`.toUpperCase(), 'enLista': true },
						{ 'id': 'asignatura', 'mostrar': ({ asignatura }) => `${asignatura}`.toUpperCase(), 'enLista': false },
						{ 'id': 'programa', 'mostrar': ({ programa }) => `${programa.nombre}`, 'enLista': false }
					]}
				/>

				<RetosDesecharGrupo
					actualizarMensaje={actualizarMensaje}
					reto={reto}
					gestionarRetosNegados={this.gestionarRetosNegados}
					modalDesecharGrupo={modalDesecharGrupo}
					mostrarModalDesecharGrupo={mostrarModalDesecharGrupo}
				/>
			</div>
		)
	}

	pintarInicioProcesos = () => {
		let { estados_procesos, filtrarPeriodo, periodo_filtro, periodos, cargandoPeriodo, datos_sesion } = this.state;
		let { mostrarModalAddFiltrosRetos } = this.props
		const obtenerColor = (codigo) => {
			let color = 'white';
			const colores = {
				'Ret_Gru': { 'backgroundColor': '#0044ff', color },
				'Ret_Env': { 'backgroundColor': '#ffc107', color },
				'Ret_Cal': { 'backgroundColor': '#5f02f5', color },
				'Ret_Env_e2': { 'backgroundColor': '#005E66', color },
				'Ret_Cal_e2': { 'backgroundColor': '#AF601A', color },
				'Ret_Env_e3': { 'backgroundColor': '#2E4053', color },
				'Ret_Cal_e3': { 'backgroundColor': '#685DE3', color },
				'Ret_Env_Fn': { 'backgroundColor': '#B329E6', color },
				'Ret_Cal_Fn': { 'backgroundColor': '#E4D281', color },
				'Ret_Sel': { 'backgroundColor': '#09BA69', color },
				'Ret_Dep': { 'backgroundColor': '#5af7fa', color },
				'Ret_Ire': { 'backgroundColor': '#bffa5a', color },
				'Ret_Gse': { 'backgroundColor': '#46fa64', color },
				'Ret_Act': { 'backgroundColor': '#6c036e', color },
				'Ret_Des': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Prof': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Depr': { 'backgroundColor': '#fa3802', color },
				'Ret_Des_Intr': { 'backgroundColor': '#fa3802', color },
				'Ret_Cer': { 'backgroundColor': '#fa3802', color },
				'Tod_Ret': { 'backgroundColor': '#0d47a1', color },
			}
			return (colores[codigo])
		}

		const handleClick = (codigo) => {
			if (codigo != 'Tod_Ret') this.setState({ accionProceso: codigo })
			else {
				mostrarModalAddFiltrosRetos(true);
			}
		};

		let nombre_programa = datos_sesion ? datos_sesion.programas.length > 0 ? datos_sesion.programas[0].relacion.nombre : 'N/A' : 'N/A'

		return (
			<>
				<div style={{ padding: '2% 2% 0% 2%' }} >
					<Grid container direction={'row'} spacing={1} justify={'center'} >
						<Grid item xs={12} sm={12} md={12} lg={10} >
							<Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
								<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
									<Banner style={{ width: "100%", margin: 0 }} nombre={datos_sesion?.persona.primer_nombre} programa={nombre_programa} />
								</Grid>

								<Grid item xs={6} sm={6} md={6} lg={6} >
									<TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6} >
									<Tooltip title='Filtrar' aria-label="add" onClick={() => this.ModalfiltroPeriodo()} >
										<IconButton edge={false} color="secondary" style={{ float: 'right' }}><FilterListIcon /></IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Paper elevation={0} square className="scroll contenido_fijo">


								{estados_procesos.length === 1 ?
									<TareasTerminadas mensaje='Aún no hay solicitudes para este periodo' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
									<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
										{estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
											return (
												<Grid item xs={12} sm={12} md={6} lg={6} key={i}>
													<Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
														<AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
													</Paper>
												</Grid>
											)
										})}
									</Grid>
								}
							</Paper>
						</Grid>
					</Grid>
				</div>

				<Dialog fullWidth={true} maxWidth="xs" open={filtrarPeriodo} onClose={() => this.cambiarEstado({ filtrarPeriodo: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<AppBarModal titulo='¡ Aplicar Filtros !' mostrarModal={() => this.cambiarEstado({ filtrarPeriodo: false })} titulo_accion="" accion="" />
					<DialogContent className='scroll'>
						{!cargandoPeriodo ?
							<Grid container spacing={3} className='margin_cont' >
								<Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
									<FormControl className='form-control'>
										<InputLabel htmlFor="periodo_filtro">Seleccione Periodo</InputLabel>
										<Select
											inputProps={{
												name: 'periodo_filtro',
												id: 'periodo_filtro',
											}}
											value={periodo_filtro}
											onChange={this.onChange}
											required={true}>
											{periodos.map(({ periodo }, i) => <MenuItem key={i} value={periodo}>{periodo}</MenuItem>)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							: <TareasTerminadas imagen={emma_w} mensaje='Cargando...' widthImg='5%' marginTop='5%' />
						}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => this.cambiarEstado({ filtrarPeriodo: false })} />
						<BtnForm texto="FILTRAR" callback={() => this.filtrarEstadoSolicitudes()} />
					</DialogActions>
				</Dialog >
			</>
		)
	}

	ModalfiltroPeriodo = async () => {
		let { cargarFiltroP, periodo } = this.state
		this.cambiarEstado({ filtrarPeriodo: true })
		if (cargarFiltroP) {
			this.cambiarEstado({ cargandoPeriodo: true })
			let periodos = await this.obtenerPeriodos();
			
			if (!(periodos.find(e => e.periodo == periodo))) periodos.push({ 'periodo': periodo })
			this.cambiarEstado({ periodos, cargandoPeriodo: false, cargarFiltroP: false })
		}
	}

	modificarPeriodo = async (periodo_mod, fecha_periodo) => {
		let { actualizarMensaje, mostrarModalModificarPeriodo } = this.props;
		if (periodo_mod && fecha_periodo) {
			let p = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Ret' }]);
			if (p.length > 0) {
				let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
				let { error, estado, resp } = await modificarValorGenericaAlter(p[0].id, { 'nombre': periodo_mod, 'valora': fecha_periodo })
				if (!error) {
					if (estado === 200) {
						tipo = 'success';
						titulo = resp.titulo;
						mostrarModalModificarPeriodo(false);
						this.cambiarEstado({ periodo_mod, fecha_periodo, periodo_filtro: periodo_mod });
						this.obtenerAlertas(periodo_mod, fecha_periodo);
						this.setState({ cargando: true })
						this.obtenerEstadoSolicitudes(periodo_mod)
					} else {
						tipo = 'info';
						titulo = resp.titulo ? resp.titulo : mostrarError(resp)
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			} else actualizarMensaje({ titulo: 'error al cargar el periodo actual', tipo: 'error', mostrar: true, tiempo: 6000 });
		} else actualizarMensaje({ titulo: 'Todos los campos son obligatorios', tipo: 'info', mostrar: true, tiempo: 6000 });
	}

	async obtenerAlertas(periodo_mod, fecha_periodo) {
		this.setState({ cargandoNotif: true })
		let mensajes = [];
		let periodo_noti = await this.notificacionPeriodo(periodo_mod, fecha_periodo);
		let notificaciones = [];

		if (periodo_noti) notificaciones.push(periodo_noti);

		this.setState({ notificaciones, cargandoNotif: false });
	}


	notificacionPeriodo(periodo_mod, fecha_periodo) {
		let { administrador } = this.state;
		let notificacion = null;
		if (fecha_periodo) {
			let fecha1 = moment();
			let fecha2 = moment(fecha_periodo);
			let dias = fecha2.diff(fecha1, 'days');
			if (dias < 15) {
				let mensaje = dias < 0 ? `El período actual ${periodo_mod} se ha cerrado, tener en cuenta que los estudiantes no podrán realizar solicitudes. ${!administrador ? 'Por favor, contacta al administrador del modulo para que actualice el periodo.' : ''}` : `El periodo actual ${periodo_mod} se cerrara ${dias === 0 ? 'el día de hoy' : `en ${dias} días`}, una vez el periodo se cierre los estudiantes no podrán realizar solicitudes.`
				notificacion = {
					icono: vistaIcon,
					titulo: '¡ Actualizar Período !',
					mensaje,
					tipo: 'fecha_periodo',
					acciones: () => administrador && <BtnEditar texto='Actualizar Periodo' callback={() => this.props.mostrarModalModificarPeriodo(true)} color='#01579b' />,
				}
			}
		}
		return notificacion;
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		})
	}

	obtenerPeriodos = () => {
		return new Promise(resolve => {
			consulta(`api/v1.0/retoscuc/periodos`, null, null,
				(error, estado, resp) => {
					resolve(resp)
				}
			);
		})
	}

	render() {
		let { accion, administrador, cargando, reto, id_estudiante, mostrarDoc } = this.state
		let { mostrarModalDetalleRetos, modalDetRetos, modalModificarPeriodo, mostrarModalModificarPeriodo } = this.props

		return (
			<div>
				<MenuAdministrar
					seleccionar={(accion) => this.cambiarEstado({ accion })}
					sele={accion}
					administrador={administrador}
				/>

				<RetosDetalle
				  actualizarMensaje={this.props.actualizarMensaje}
					id_estudiante={id_estudiante}
					mostrarModalDetalleRetos={mostrarModalDetalleRetos}
					modalDetRetos={modalDetRetos}
					vistaDetalle={this.state.mostrarAdj && mostrarDoc ? 'documentos' :  'detalle'}
					admin={false}
					retoActual={reto ? reto.id : 0}
					reset={() => this.setState({ vistaDetalle: 'detalle',  mostrarDoc: false })}
				/>



				<div className='contenido_modulo'>
					{
						!cargando ?
							<div>
								{accion === 'Procesos' && this.renderProcesos()}
								{accion === 'Permisos' && this.pintarAdministrar()}
							</div>
							: <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
					}
				</div>

				<EnviarMensajeFecha
					modal={modalModificarPeriodo}
					mostrarModal={mostrarModalModificarPeriodo}
					enviar={(periodo_mod, fecha) => this.modificarPeriodo(periodo_mod, fecha)} label='Periodo' type='text' titulo={`Nuevo Periodo`} label_fecha='Fecha Vencimiento'
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	let { modalDetRetos, modalGestionRetos, modalFiltroRetos, modalModificarPeriodo, modalPermisoAsignatura, modalDesecharGrupo,ModalAddFiltrosRetos } = state.redRetoscuc
	let { usuario } = state.redGlobal;
	return {
		usuario,
		modalDetRetos,
		modalGestionRetos,
		modalFiltroRetos,
		modalModificarPeriodo,
		modalPermisoAsignatura,
		modalDesecharGrupo,
		ModalAddFiltrosRetos,
	}
}

const mapDispatchToProps = {
	mostrarModulo,
	actualizarMensaje,
	mostrarModalDetalleRetos,
	mostrarGestionRetos,
	mostrarModalFiltroRetos,
	mostrarModalModificarPeriodo,
	mostrarModalPermisoAsignatura,
	mostrarModalDesecharGrupo,
	mostrarModalAddFiltrosRetos,
}

RetosAdministrar.propTypes = {
	//funciones
	actualizarMensaje: PropTypes.func.isRequired,
	mostrarModalDetalleRetos: PropTypes.func.isRequired,
	mostrarGestionRetos: PropTypes.func.isRequired,
	mostrarModalModificarPeriodo: PropTypes.func.isRequired,
	mostrarModalFiltroRetos: PropTypes.func.isRequired,
	mostrarModalPermisoAsignatura: PropTypes.func.isRequired,
	mostrarModalDesecharGrupo: PropTypes.func.isRequired,
	mostrarModulo: PropTypes.func.isRequired,

	//variables
	modalFiltroRetos: PropTypes.bool.isRequired,
	modalModificarPeriodo: PropTypes.bool.isRequired,
	modalPermisoAsignatura: PropTypes.bool.isRequired,
	modalDesecharGrupo: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(RetosAdministrar)