
import DateFnsUtils from '@date-io/date-fns';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, List, ListItemText, MenuItem, Paper, Select, Step, StepLabel, Stepper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import emma_w from '../../global/imagenes/emma_w.png';
import { api, consulta, obtenerPermisosValorAlt, obtenerValoresFiltros } from '../../global/js/funciones';
import AlertasSimple from '../general/AlertasSimple';
import AppBarModal from '../general/AppBarModal';
import { BtnCargar, BtnDescargar, BtnForm } from "../general/BotonesAccion";
import EnviarMultiplesArchivos from "../general/EnviarMultiplesArchivos";
import TareasTerminadas from "../general/TareasTerminadas";
import { AREA_DE_INTERES } from './helper';



const steps = ['Mis datos', 'Requisitos'];

function getStepContent(step, props_c) {
  switch (step) {
    case 0:
      return <MisDatos props_c={props_c} />;
    case 1:
      return <Requisitos props_c={props_c} />;
    default:
      return '';
  }
}

const useStyles2 = makeStyles(theme => ({
  layout: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepper: {
    backgroundColor: "transparent",
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));


function Agregar({ props_c }) {
  const classes = useStyles2();
  let { activeStep } = props_c;
  const renderContenido = () => {
    return (
      <Paper elevation={0}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          <React.Fragment>
            {getStepContent(activeStep, props_c)}
          </React.Fragment>
        </React.Fragment>
      </Paper>
    )
  }

  return (<main className='padding_cont'>  {renderContenido()}  </main>);
}

function MisDatos({ props_c }) {
  let { segundo_idioma,en_area, conError,trabaja, obetnerAreadeInteres,areainteres,area_pro_mejora,backareadeinteres,busca_empresa, changeState, programas, programa, telefono, modalidad, modalidades, modalidadMod, celular, correo, eps, opcionesPraCli, opcionPraCli, afiliacion, fecha_expedicion, lugar_expedicion, fecha_nacimiento, genero,onChange,pintarTiposSeleccionado,promedioacumulado,aprendizaje,idioma_habla, generos} = props_c;
  
  const setFechaNacimiento = (fecha_nacimiento) => {
    changeState({
      fecha_nacimiento
    })
  }
 
  const setFechaExpedicion = (fecha_expedicion) => {
    changeState({
      fecha_expedicion
    })
  }

  console.log(backareadeinteres)


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            value={telefono}
            required
            type="number"
            id="telefono"
            name="telefono"
            label="Telefono"
            fullWidth
            onChange={(e) => changeState({ telefono: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={celular}
            required
            type="number"
            id="celular"
            name="celular"
            label="Celular"
            fullWidth
            onChange={(e) => changeState({ celular: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={correo}
            required
            type="text"
            id="correo"
            name="correo"
            label="Correo Personal"
            fullWidth
            onChange={(e) => changeState({ correo: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl className='form-control'>
            <InputLabel id="demo-simple-select-label">Seleccione Programa</InputLabel>
            <Select id="programa" value={programa} onChange={(e) => changeState({ programa: e.target.value  })}  >
              {
                programas.map(({ relacion }) => {
                  return <MenuItem key={relacion.id} value={relacion.id}>{relacion.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
        { 
        modalidad.codigo !== 'Mod_Cli_Pre'? 
        <>
        <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="segundo_idioma">¿ Hablas otro idioma ? (aparte del español)</InputLabel>
              <Select
                inputProps={{
                  name: 'segundo_idioma',
                  id: 'segundo_idioma',
                }}
                value={segundo_idioma}
                onChange={(e) => changeState({ segundo_idioma: e.target.value })}
                required={true}>
                <MenuItem value={1}>SI</MenuItem>
                <MenuItem value={0}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {
            segundo_idioma===1 &&
            <Grid item xs={12}>
          <TextField
            value={idioma_habla}
            required
            type="text"
            id="idioma_habla"
            name="idioma_habla"
            label="Ingrese el otro idioma que habla"
            fullWidth
            onChange={(e) => changeState({ idioma_habla: e.target.value })}
          />
        </Grid>
          }      
           <Grid item xs={12}>
          <FormControl className='form-control' >
            <InputLabel id="select_areainteres">Seleccione las áreas de interés</InputLabel>
            <Select
              multiple
              labelid='select_areainteres'
              name='areainteres'
              value={areainteres}
              onChange={onChange}
              renderValue={selected => {
                return pintarTiposSeleccionado(selected)
              }}
             
            >
              {
                backareadeinteres.map(({ nombre, id }) => {
                  console.log('consulta',areainteres.indexOf(id))
                  return (<MenuItem key={id} value={id}>
                    <Checkbox checked={areainteres.indexOf(id) > -1} />
                    <ListItemText primary={nombre} />
                  </MenuItem>)
                })
              }
            </Select>
            {/* {areainteres.error && <FormHelperText>{areainteres.error}</FormHelperText>} */}
          </FormControl>
        </Grid> 
        <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="aprendizaje">¿ Has tenido un contrato de aprendizaje previo ?</InputLabel>
              <Select
                inputProps={{
                  name: 'aprendizaje',
                  id: 'aprendizaje',
                }}
                value={aprendizaje}
                onChange={(e) => changeState({ aprendizaje: e.target.value })}
                required={true}>
                <MenuItem value={1}>SI</MenuItem>
                <MenuItem value={0}>NO</MenuItem>
              </Select>
            </FormControl>
          
        </Grid>
        </>:''
}
      </Grid>
      
      {modalidad.codigo === 'Mod_Emp_Pra' ?
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel htmlFor="trabaja">¿ Usted Trabaja ?</InputLabel>
              <Select
                inputProps={{
                  name: 'trabaja',
                  id: 'trabaja',
                }}
                value={trabaja}
                onChange={(e) => changeState({ trabaja: e.target.value })}
                required={true}>
                <MenuItem value={1}>SI</MenuItem>
                <MenuItem value={0}>NO</MenuItem>
              </Select>
            </FormControl>
            
            </Grid>
            
         
          
          {trabaja === 1 ?
            <Grid item xs={12}>
              <FormControl className='form-control'>
                <InputLabel htmlFor="en_area">¿ haces funciones relacionas a tu carrera ?</InputLabel>
                <Select
                  inputProps={{
                    name: 'en_area',
                    id: 'en_area',
                  }}
                  value={en_area}
                  onChange={(e) => changeState({ en_area: e.target.value })}  >
                  <MenuItem value={1}>SI</MenuItem>
                  <MenuItem value={0}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            : ''
          }
          {trabaja === 0 ?
            <Grid item xs={12}>
              <FormControl className='form-control'>
                <InputLabel htmlFor="busca_empresa">¿ Quiere que la CUC lo ubique en una empresa ?</InputLabel>
                <Select
                  inputProps={{
                    name: 'busca_empresa',
                    id: 'busca_empresa',
                  }}
                  value={busca_empresa}
                  onChange={(e) => changeState({ busca_empresa: e.target.value })}
                >
                  <MenuItem value={1}>SI</MenuItem>
                  <MenuItem value={0}>NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            : ''
          }
          {trabaja === 1 && en_area === 0 ?
            <Grid item xs={12} style={{ marginBottom: '50px' }}>
              <FormControl className='form-control'>
                <InputLabel htmlFor="cambiar_modalidad">Seleccione Modalidad</InputLabel>
                <Select
                  inputProps={{
                    name: 'cambiar_modalidad',
                    id: 'cambiar_modalidad',
                  }}
                  value={modalidadMod}
                  onChange={(e) => {
                    let modalidadMod = e.target.value;
                    changeState({ modalidadMod });
                  }}
                >
                  {
                    modalidades.map(({ id, nombre, codigo }) => {
                      if (codigo === 'Mod_Emt_Pra' || codigo === 'Mod_Epr_Pra' || codigo === 'Mod_Inv_Pra') return <MenuItem key={id} value={id}>{nombre}</MenuItem>
                      return ''
                    })
                  }
                </Select>
                <FormHelperText>Teniendo en cuenta que no trabajas en tu área, debes cambiar de modalidad</FormHelperText>
              </FormControl>
            </Grid>
            : ''}
        </Grid> : ''}
      { (modalidad.codigo === 'Mod_Cli_Pre' || modalidad.codigo === 'Mod_Cli_Tel') ? 
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField value={lugar_expedicion}
              required
              type="text"
              id="lugar_expedicion"
              name="lugar_expedicion"
              label="Lugar de expedición"
              fullWidth
              onChange={(e) => changeState({ lugar_expedicion: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <Grid container>
                <KeyboardDatePicker
                  required={true}
                  style={{ marginTop: 3, width: '100%' }}
                  maxDate={Date("YYYY-MM-dd")}
                  margin="normal"
                  label="Fecha de expedición"
                  format="yyyy-MM-dd"
                  value={fecha_expedicion}
                  onChange={setFechaExpedicion}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDateMessage={`La fecha no puede ser mayor que la fecha actual.`}
                  invalidDateMessage='Formato Invalido'
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <Grid container>
                <KeyboardDatePicker
                  required={true}
                  style={{ marginTop: 3, width: '100%' }}
                  maxDate={Date("YYYY-MM-dd")}
                  margin="normal"
                  label="Fecha de nacimiento"
                  format="yyyy-MM-dd"
                  value={fecha_nacimiento}
                  onChange={setFechaNacimiento}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDateMessage={`La fecha no puede ser mayor que la fecha actual.`}
                  invalidDateMessage='Formato Invalido'
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel id="label-genero">Género</InputLabel>
              <Select id="genero" value={genero} onChange={(e) => changeState({ genero: e.target.value })}  >
              {
                generos.map(( item, key ) => {
                  return <MenuItem key={key} value={item.id}>{item.nombre}</MenuItem>
                })
              }
                {/* <MenuItem value={1}>Masculino</MenuItem> */}
                {/* <MenuItem value={2}>Femenino</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField value={eps}
              required
              type="text"
              id="eps"
              name="eps"
              label="EPS"
              fullWidth
              onChange={(e) => changeState({ eps: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl className='form-control'>
              <InputLabel id="label-afiliacion">Tipo de afiliación</InputLabel>
              <Select id="afiliacion" value={afiliacion} onChange={(e) => changeState({ afiliacion: e.target.value })}  >
                <MenuItem value={1}>CONTRIBUTIVO</MenuItem>
                <MenuItem value={0}>SUBSIDIADO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className='form-control'>
              <InputLabel id="label-tema">Seleccione un tema</InputLabel>
              <Select id="opcionPraCli" value={opcionPraCli} onChange={(e) => changeState({ opcionPraCli: e.target.value })}  >
                {
                    opcionesPraCli.map(( item, key ) => {
                      return <MenuItem key={key} value={item.id}>{item.nombre}</MenuItem>
                    })
                }
              </Select>
            </FormControl>
          </Grid>
       </Grid>
        : ''
      }
      
      {
        modalidad.codigo === 'Mod_Epr_Pra' &&
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <FormControl className="form-control" >
              <TextField
                value={area_pro_mejora}
                id="Área de desarrollo de proyecto de mejora"
                label="Área de desarrollo de proyecto de mejoras"
                placeholder="Escriba Área de desarrollo de proyecto de mejoraes"
                multiline
                onChange={(e) => changeState({ area_pro_mejora: e.target.value })}
              />
            </FormControl>
          </Grid>
        </Grid>
      }
      <AlertasSimple tipo='warnig' titulo='Promedio' sub_titulo={`Promedio Acumulado : ${promedioacumulado} `} conCallback={false} />
    </>
  );
}

function Requisitos({ props_c }) {
  let { requisitos, submit, requisitoInicial, hv } = props_c;

  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map(({ secundario }) => requisitoInicial(secundario) && r.push({ nombre: secundario.nombre, descripcion: secundario.descripcion, codigo: secundario.id, archivo: secundario.archivo, esHv: secundario.codigo === 'req_for_hoj_int', formatos : secundario.valorg }))
    return r;
  }
  
  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);
    const onClickDescargar = ({ archivo, esHv }) => {
      if (esHv && hv) window.open(`${api}${hv}`, '_blank');
      else if (archivo) window.open(`${archivo}`, '_blank')
    }

    let descargar = (data.archivo || data.esHv) && <BtnDescargar callback={() => onClickDescargar(data)} color='#01579b' texto='Abrir' />
    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />

    return <div>{descargar} {cargar}</div>;
  }

 

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={trasnformarDataAdj(requisitos)}
                formatos={true}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}



class PracticasAgregar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      archivos: [],
      archivos_guardados: [],
      programa: 0,
      render_buscar_p: false,
      telefono: '',
      celular: '',
      correo: '',
      activeStep: 0,
      trabaja: '',
      backareadeinteres: [],
      areainteres: [],
      segundo_idioma: '',
      en_area: '',
      busca_empresa: '',
      modalidadMod: '',
      hv: '',
      eps: '',
      fecha_nacimiento: null,
      fecha_expedicion: null,
      lugar_expedicion: '',
      genero: '',
      afiliacion: '',
      opcionPraCli: '',
      cargando: false,
      promedioacumulado: '',
      aprendizaje: '',
      area_pro_mejora: '',
      tiposSolPracticas: null,
    }
  }

  // async componentDidMount() {
  //   this.setState({ cargando: true })
  //        this.obetnerAreadeInteres();
  //        this.setState({ cargando: false, })
  // }

  async componentDidUpdate({ modalAddPractica }, { modalidadMod,programa }) {
    let { modalidades, modalAddPractica: modalAddNew, programas, modalidad, telefono, actualizarModalidadPractica, celular, correo, lugar_expedicion, fecha_nacimiento, fecha_expedicion } = this.props;
    let { modalidadMod: modalidadModNew,backareadeinteres,programa: programaNew,promedioacumulado } = this.state;
    if(programa!=programaNew){
      this.setState({cargando:true})
      const elemento = programas.find(e => e.relacion.id === programaNew);
      this.setState({ tiposSolPracticas: (elemento.relacion.valorc === 'ubarrio' || elemento.relacion.valorc === 'convgob') ? 1 : 0 });
      let backareadeinteresnuevo = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor':programaNew}, { 'llave': 'secundario__generica', 'valor': AREA_DE_INTERES }])
      let backareadeinteres=[]
      backareadeinteresnuevo.map((int) => (backareadeinteres.push(int.secundario)))
      console.log(backareadeinteres)
      this.setState({ backareadeinteres })
      this.setState({cargando:false})
    }
    if (modalAddNew !== modalAddPractica) {
      this.setState({
        archivos: [],
        telefono,
        celular,
        correo,
        programa: programas.length > 0 ? programas[0].relacion.id : 0,
        hv: programas.length > 0 ? programas[0].relacion.archivo : '',
        render_buscar_p: modalidad.codigo === 'Mod_Emp_Pra' ? true : false,
        activeStep: 0,
        trabaja: '',
        segundo_idioma: '',
        en_area: '',
        busca_empresa: '',
        modalidadMod: '',
        eps: '',
        lugar_expedicion,
        fecha_nacimiento,
        fecha_expedicion,
        genero: '',
        afiliacion: '',
        opcionPraCli:'',
        areainteres: [],
        area_pro_mejora:'',
        aprendizaje: '',
        idioma_habla:'',
      })
      if (modalAddNew) {
        // backareadeinteres =  await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 126 }, { 'llave': 'estado', 'valor': 1 }]) || [];
        let backareadeinteresnuevo = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': programas.length > 0 ? programas[0].relacion.id : 0 }, { 'llave': 'secundario__generica', 'valor': AREA_DE_INTERES }])
        let backareadeinteres=[]
        backareadeinteresnuevo.map((int) => (backareadeinteres.push(int.secundario)))
        console.log(backareadeinteres)
        this.setState({ backareadeinteres })
        this.setState({ cargando: true })
        let promedioacumulado =  await this.ObtenerPromedio(programas.length > 0 ? programas[0].relacion.id : 0) ;
        console.log(promedioacumulado)
        this.setState({ promedioacumulado,cargando: false })
      }
    }
    if (modalidadMod !== modalidadModNew) {
      if (modalidadModNew) {
        let modalidad = modalidades.find(({ id, codigo }) => modalidadModNew === 'Mod_Emp_Pra' ? codigo === modalidadModNew : id === modalidadModNew);
        actualizarModalidadPractica(modalidad);
        if (modalidadModNew === 'Mod_Emp_Pra') this.setState({ modalidadMod: '' });
        this.obtenerRequisitos(modalidad.id);
      }
    }
  }
  conError(valor) {
    return valor ? true : false;
  }

  obetnerAreadeInteres = async () => {
    let { backareadeinteres } = this.state;
    this.setState({ cargando: true });
    backareadeinteres =  await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 126 }, { 'llave': 'estado', 'valor': 1 }]) || [];
    console.log(backareadeinteres)
    this.setState({ backareadeinteres, cargando: false });
  }

  eliminarArchivoArray = (i) => {
    let { actualizarMensaje } = this.props;
    let { archivos } = this.state;
    archivos.splice(i, 1)
    this.setState({ archivos })
    actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 })
  }
  pintarTiposSeleccionado = (selected) => {
    let resp = [];
    let { backareadeinteres } = this.state;
    selected.map((element) => {
      backareadeinteres.map((e) => {
        if (element === e.id) return resp.push(e.nombre)
        return null;
      })
      return null;
    });
    return resp.join(", ")
  }
  ObtenerPromedio(programa) {
    return new Promise(resolve => {
      consulta(`api/v1.0/practicas/estudiante/promedio`, { programa }, 'post', (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }
  agregarArchivoArray = (archivos) => {
    let { requisitos, actualizarMensaje } = this.props;
    let titulo = 'Archivo Agregado';
    let total = requisitos.length + 5;
    let tipo = 'success';
    archivos = this.state.archivos.concat(archivos);
    if (archivos.length <= total) this.setState({ archivos })
    else {
      tipo = 'info';
      titulo = `Se ha excedido el número máximo permitido de archivos ${total}.`
    }
    actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
  }


  obtenerRequisitos = async (modalidad) => {
    const requisitosDB = await obtenerPermisosValorAlt([{ 'llave': 'principal', 'valor': modalidad }, { 'llave': 'secundario__generica', 'valor': 12 }, { 'llave': 'secundario__valorb', 'valor': 1 }])
    this.props.actualizarRequisitosModalidad(requisitosDB);
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  changeState = (estados) => {
    this.setState(estados)
  }

  cambiarStep = (n) => {
    let { activeStep } = this.state;
    let campos = this.validarCampos();
    if (campos) {
      if (n > 0 && activeStep === 1) {
        // this.setState({ cargando: true })
        document.getElementById('enviar_form_multi_archivo').click()
        return true;
      }
      this.setState({ 'activeStep': activeStep + n })
    }
  }

  validarCampos = () => {
    let {segundo_idioma,trabaja, en_area, telefono, programa, busca_empresa, celular, correo, eps, opcionPraCli, afiliacion, fecha_nacimiento, fecha_expedicion, lugar_expedicion, genero,areainteres,area_pro_mejora,aprendizaje,idioma_habla } = this.state;
    let { modalidad, actualizarMensaje } = this.props;
    console.log(modalidad.codigo)
    let titulo = '';
    if (!telefono) titulo = 'Ingrese numero de telefono.'
    else if (!celular) titulo = 'Ingrese numero de celular.'
    else if (!correo) titulo = 'Ingrese correo personal.'
    else if (!programa) titulo = 'Seleccione Programa.'
    
    else if(modalidad.codigo == 'Mod_Epr_Pra'){
      console.log('entre')
      if(!area_pro_mejora) titulo='Ingrese el area de desarrollo de proyecto de mejora'
      }
    else if(modalidad.codigo !== 'Mod_Cli_Pre'){
      
     if(segundo_idioma===1 ){ 
      if (idioma_habla==='') {
        titulo = 'Ingrese el otro idioma que habla.'}
    }
    else if (segundo_idioma==='') titulo = 'Seleccione segundo idioma.'
    else if (areainteres.length===0) titulo = 'Seleccione área de interés.'
    
  
    else if (modalidad.codigo === 'Mod_Emp_Pra') {
      if (trabaja === '') titulo = 'Debe especificar si trabaja o no.'
      else if (aprendizaje==='') titulo = 'Seleccione aprendizaje.'
      else if (trabaja === 1 && en_area === '') titulo = 'Debe especificar si realiza funciones de su carrera o no.'
      else if (trabaja === 1 && en_area === 0) titulo = 'Debe seleccionar una nueva modalidad.'
      else if (trabaja === 0 && busca_empresa === '') titulo = 'Debe especificar si quiere que la CUC lo ubique en una empresa o no.'
    }
    
  }else if(modalidad.codigo === 'Mod_Cli_Pre' || modalidad.codigo === 'Mod_Cli_Tel'){
      if (!lugar_expedicion) titulo = 'Ingrese el lugar de expedición..'
      else if (!fecha_expedicion) titulo = 'Ingrese su fecha de expedición.'
      else if (!fecha_nacimiento) titulo = 'Ingrese su fecha de nacimiento.'
      else if (!genero) titulo = 'Seleccione género.'
      else if (!eps) titulo = 'Ingrese su EPS.'
      else if(afiliacion === '') titulo = 'Seleccione la afiliación.'
      else if(!opcionPraCli) titulo = 'Seleccione la Opcion Correspondiente.'
  }

    if (titulo) {
      actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 })
      return false;
    }
    return true;
  }

  requisitoInicial = (requisito) => {
    let { trabaja, en_area } = this.state;
    let { modalidad } = this.props;
    let adicionales = trabaja === 1 && en_area === 1 ? true : false;
    if (requisito.valora === '1' || (requisito.valora === '2' && adicionales && modalidad.codigo === 'Mod_Emp_Pra') || (requisito.valora === '2' && modalidad.codigo !== 'Mod_Emp_Pra')) return true;
    return false;
  }

  submit = (archivos) => {
    this.setState({ archivos })
    let { programa, busca_empresa, telefono,segundo_idioma, trabaja, en_area, modalidadMod, celular, correo, eps, opcionPraCli, afiliacion, fecha_nacimiento, fecha_expedicion, lugar_expedicion, genero,areainteres,area_pro_mejora,aprendizaje,promedioacumulado,idioma_habla, tiposSolPracticas } = this.state;
    let { requisitos, actualizarMensaje, modalidad, guardarPractica } = this.props;
    let total = 0;
    requisitos.map(({ secundario }) => (this.requisitoInicial(secundario)) && total++)
    if (archivos.length >= total) {
      this.setState({ cargando: true })
      trabaja = modalidad.codigo === 'Mod_Emp_Pra' || modalidadMod ? trabaja : -1;
      busca_empresa = trabaja === 1 || trabaja === -1 ? -1 : busca_empresa;
      en_area = trabaja === 0 || trabaja === -1 ? -1 : en_area;
      eps = eps.toUpperCase();
      lugar_expedicion = (lugar_expedicion) ? lugar_expedicion.toUpperCase() : ''
      fecha_expedicion = (fecha_expedicion) ? moment(fecha_expedicion).format('YYYY-MM-DD') : ''
      fecha_nacimiento = (fecha_nacimiento) ? moment(fecha_nacimiento).format('YYYY-MM-DD') : ''

      guardarPractica(archivos, programa, busca_empresa, telefono, celular, correo, segundo_idioma,trabaja, en_area, eps, afiliacion, opcionPraCli, fecha_nacimiento, fecha_expedicion, lugar_expedicion, genero,areainteres,area_pro_mejora,aprendizaje,promedioacumulado,idioma_habla, tiposSolPracticas, () => this.setState({cargando: false}))
    } else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito', tipo: 'info', mostrar: true, tiempo: 6000 });

  }

  pintarAcciones = () => {
    let { mostrarModalAddPracticas } = this.props;
    return (
      <div>
        <BtnForm texto="REGRESAR" callback={() => mostrarModalAddPracticas(0)} />
        <BtnForm texto="GUARDAR" callback={this.onSubmit} />
      </div>
    );
  }

  render() {
    
    let { modalAddPractica, mostrarModalAddPracticas, requisitos, modalidad, api, programas, actualizarMensaje, modalidades, opcionesPraCli, generos } = this.props;
    let { activeStep, trabaja, en_area, busca_empresa, segundo_idioma,programa, archivos, telefono, modalidadMod, hv, cargando, celular, correo, eps, opcionPraCli, afiliacion, fecha_nacimiento, fecha_expedicion, lugar_expedicion, genero ,backareadeinteres,areainteres,area_pro_mejora,promedioacumulado,aprendizaje,idioma_habla} = this.state;
    console.log(backareadeinteres)
    let props_c = {

      cambiarStep: this.cambiarStep,
      activeStep,
      
      onChange: this.onChange,
      changeState: this.changeState,
      obetnerAreadeInteres: this.obetnerAreadeInteres,
      handleChange: this.handleChange,
      eliminar: this.eliminarArchivoArray,
      agregar: this.agregarArchivoArray,
      trabaja,
      segundo_idioma,
      en_area,
      busca_empresa,
      programas,
      programa,
      requisitos,
      api,
      aprendizaje,
      actualizarMensaje,
      archivos,
      telefono,
      celular,
      correo,
      eps,
      afiliacion,
      opcionPraCli,
      modalidad,
      modalidades,
      modalidadMod,
      hv,
      pintarTiposSeleccionado:this.pintarTiposSeleccionado,
      submit: this.submit,
      requisitoInicial: this.requisitoInicial,
      opcionesPraCli,
      fecha_nacimiento,
      fecha_expedicion,
      lugar_expedicion,
      genero,
      backareadeinteres,
      areainteres,
      area_pro_mejora,
      promedioacumulado,
      idioma_habla,
      generos
      
    }
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalAddPractica} onClose={() => mostrarModalAddPracticas(false)}>
        <AppBarModal titulo={modalidad.nombre} mostrarModal={() => mostrarModalAddPracticas(false)} titulo_accion="Cerrar" accion={() => mostrarModalAddPracticas(false)} />
        <DialogContent className='scroll' style={{ padding: 0 }}>
          {!cargando ?
            <Agregar
              props_c={props_c}
            /> :
            <TareasTerminadas mensaje='Cargando...' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </DialogContent>
        <DialogActions>
          <div>
            {activeStep !== 0 && (
              <Button onClick={() => this.cambiarStep(-1)} >
                Regresar
              </Button>
            )}
            {!cargando &&
            <Button
              color="primary"
              onClick={() => this.cambiarStep(1)}
            >
            
              {activeStep === 1 ? 'Terminar' : 'Siguiente'}
            </Button>
            }
          </div>
        </DialogActions>
      </Dialog >
    );
  }
}

//Props del componente
PracticasAgregar.propTypes = {
  //variables
  modalAddPractica: PropTypes.bool.isRequired,
  programas: PropTypes.array.isRequired,
  api: PropTypes.string.isRequired,
  //funciones
  mostrarModalAddPracticas: PropTypes.func.isRequired,
}

export default PracticasAgregar;
