const MODAL_ADD_PERFIL_PORTAL = 'MODAL_ADD_PERFIL_PORTAL'
const MODAL_ACCIONES_RAPIDAS = 'MODAL_ACCIONES_RAPIDAS'
const MODAL_LISTAR_INFORMACION_PERFIL = 'MODAL_LISTAR_INFORMACION_PERFIL'
const MODAL_CREAR_OFERTA = 'MODAL_CREAR_OFERTA'
const MODAL_DETALLE_VACANTE = 'MODAL_DETALLE_VACANTE'
const MODAL_GESTION_OFERTA = 'MODAL_GESTION_OFERTA'
const MODAL_LISTAR_POSTULANTES = 'MODAL_LISTAR_POSTULANTES'
const MODAL_GESTION_PROCESO_EMPRESA = 'MODAL_GESTION_PROCESO_EMPRESA'
const MODAL_ESTADO_PROCESO = 'MODAL_ESTADO_PROCESO'
const MODAL_ADD_FILTROS = 'MODAL_ADD_FILTROS'
const MODAL_DETALLE_POSTULANTE = 'MODAL_DETALLE_POSTULANTE'
const MODAL_DETALLE_ESTADOS = 'MODAL_DETALLE_ESTADOS'
const MODAl_PQRS_AGREGAR = 'MODAl_PQRS_AGREGAR' 
const MODAl_PQRS_EDITAR = 'MODAl_PQRS_EDITAR' 
const MODAl_PQRS_DETALLE = 'MODAl_PQRS_DETALLE' 
const MODAl_LISTA_ESTADISTICA = 'MODAl_LISTA_ESTADISTICA' 
const MODAL_DESCARGAR_HOJA_DE_VIDA = 'MODAL_DESCARGAR_HOJA_DE_VIDA'
const MODAL_GESTIONAR_ESTUDIANTE = 'MODAL_GESTIONAR_ESTUDIANTE'
const MODAL_MOSTRAR_CORREO = 'MODAL_MOSTRAR_CORREO'
const MODAL_GESTIONAR_EMPRESA = 'MODAL_GESTIONAR_EMPRESA'
const MODAL_MOSTRAR_DATOS_EMPRESA = 'MODAL_MOSTRAR_DATOS_EMPRESA'
const MODAL_DETALLE_ESTADOS_GESTION = 'MODAL_DETALLE_ESTADOS_GESTION'


const mostrarModalListaEstadistica = show => {
    return  {
        type: MODAl_LISTA_ESTADISTICA,
        show
    }
}

const mostrarModalDetallePqrs = show => {
    return  {
        type: MODAl_PQRS_DETALLE,
        show
    }
}

const mostrarModalEditPqrs = show => {
    return  {
        type: MODAl_PQRS_EDITAR,
        show
    }
}

const mostrarModalAddPqrs = show => {
    return  {
        type: MODAl_PQRS_AGREGAR,
        show
    }
}


const mostrarModalAddPerfilPortal = show => {
    return  {
        type: MODAL_ADD_PERFIL_PORTAL,
        show
    }
}

const mostrarModalAccionesRapidas = show => {
    return {
        type: MODAL_ACCIONES_RAPIDAS,
        show
    } 
}

const mostrarModalListarInformacionPerfil = show => {
    return {
        type: MODAL_LISTAR_INFORMACION_PERFIL,
        show
    }
}

const mostrarModalCrearOferta = show => {
    return {
        type: MODAL_CREAR_OFERTA,
        show
    }
}

const mostrarModalDetalleVacante = show => {
    return {
        type: MODAL_DETALLE_VACANTE,
        show
    }
}

const mostrarModalGestionOferta = show => {
    return {
        type: MODAL_GESTION_OFERTA,
        show
    }
}

const mostrarModalListarPostulantes = show => {
    return {
        type: MODAL_LISTAR_POSTULANTES,
        show
    }
}

const mostrarModalGestionProcesoEmpresa = show => {
    return {
        type: MODAL_GESTION_PROCESO_EMPRESA,
        show
    }
}

const mostrarModalEstadoProceso = show => {
    return {
        type: MODAL_ESTADO_PROCESO,
        show
    }
}

const mostrarModalAddFiltros = show => {
    return {
        type: MODAL_ADD_FILTROS,
        show
    }
}

const mostrarModalDetallePostulante = show => {
    return {
        type: MODAL_DETALLE_POSTULANTE,
        show
    }
}

const mostrarModalDescargarHojaDeVida = show => {
    return {
        type: MODAL_DESCARGAR_HOJA_DE_VIDA,
        show
    }
}

const mostrarModalDetalleEstados = show => {
    return {
        type: MODAL_DETALLE_ESTADOS,
        show
    }
}

const mostrarModalGestionarestudiante = show => {
    return {
      type: MODAL_GESTIONAR_ESTUDIANTE,
      show,
    }
}

const mostrarModalActualizarCorreo = show => {
    return {
      type: MODAL_MOSTRAR_CORREO,
      show,
    }
}

const mostrarModalGestionarEmpresa = show => {
    return {
      type: MODAL_GESTIONAR_EMPRESA,
      show,
    }
}

const mostrarModalActualizarDatosEmpresa = show => {
    return {
      type: MODAL_MOSTRAR_DATOS_EMPRESA,
      show,
    }
}

const mostrarModalDetalleEstadosGestion = show => {
    return {
        type: MODAL_DETALLE_ESTADOS_GESTION,
        show
    }
}

module.exports = {
    MODAL_ADD_PERFIL_PORTAL,
    mostrarModalAddPerfilPortal,
    MODAL_ACCIONES_RAPIDAS,
    mostrarModalAccionesRapidas,
    MODAL_LISTAR_INFORMACION_PERFIL,
    mostrarModalListarInformacionPerfil,
    MODAL_CREAR_OFERTA,
    mostrarModalCrearOferta,
    MODAL_DETALLE_VACANTE,
    mostrarModalDetalleVacante,
    MODAL_GESTION_OFERTA,
    mostrarModalGestionOferta,
    MODAL_LISTAR_POSTULANTES,
    mostrarModalListarPostulantes,
    MODAL_GESTION_PROCESO_EMPRESA, 
    mostrarModalGestionProcesoEmpresa,
    MODAL_ESTADO_PROCESO,
    mostrarModalEstadoProceso,
    MODAL_ADD_FILTROS,
    mostrarModalAddFiltros,
    MODAL_DETALLE_POSTULANTE,
    MODAL_DESCARGAR_HOJA_DE_VIDA,
    mostrarModalDetallePostulante,
    mostrarModalDescargarHojaDeVida,
    MODAL_DETALLE_ESTADOS,
    mostrarModalDetalleEstados,
    mostrarModalDetalleEstadosGestion,
    MODAL_DETALLE_ESTADOS_GESTION,
    MODAl_PQRS_AGREGAR,
    mostrarModalAddPqrs,
    MODAl_PQRS_EDITAR,
    mostrarModalEditPqrs,
    MODAl_PQRS_DETALLE,
    mostrarModalDetallePqrs,
    MODAl_LISTA_ESTADISTICA,
    mostrarModalListaEstadistica,
    mostrarModalGestionarestudiante,
    MODAL_GESTIONAR_ESTUDIANTE,
    mostrarModalActualizarCorreo,
    MODAL_MOSTRAR_CORREO,
    mostrarModalGestionarEmpresa,
    MODAL_GESTIONAR_EMPRESA,
    mostrarModalActualizarDatosEmpresa,
    MODAL_MOSTRAR_DATOS_EMPRESA
}