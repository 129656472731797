import React , { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch ,useSelector} from 'react-redux';
import { mostrarModulo,actualizarMensaje } from '../../../redux/actions/actGlobal';
import MenuSalas from '../AdminSalas/MenuSalas'
import AdminSalasPermisos from './AdminSalasPermisos';
import * as Funciones from "../../../global/js/funciones";
import emma_s from '../../../global/imagenes/emma_s1.png';
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';
import * as Helper from '../children/helpers';
import { Box, Button, Checkbox, FormControl, FormHelperText} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, Typography } from '@material-ui/core'
import ListarDatos from '../../general/ListarDatos';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBarModal from '../../general/AppBarModal';
import InputLabel from '@material-ui/core/InputLabel';
import Banner from './ui/BannerGestoSalas'
import BannerAdmin from './ui/BannerAdminProcesos'
import Permisos from './Permisos';
import { api, coloresEmma } from '../../../global/js/funciones';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
    contenidoModulo: {
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: "90px !important"
                },
    },
    header: {
        background: coloresEmma.secondarycolor,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            padding: '20px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '40px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
    },
    imgInicio: {
        [theme.breakpoints.down('md')]: {
            minHeight: '300px',
            minHeight: '70%',
            marginTop: '25px',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '300px',
            maxWidth: '60%'
        },
    },
    headerTitleTextBox:{
        [theme.breakpoints.down('md')]: {
            maxHeight: '30%',

        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40%',
            
        },
        
    },
    headerTitle: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '26px',
            fontWeight: '800',
            color: '#ffffff',
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '46px',
            fontWeight: '800',
            color: '#ffffff'
        },
    },
    headerText: {
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px',
            fontWeight: '300',
            color: '#ffffff',
            textAlign: 'center'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '24px',
            fontWeight: '300',
            color: '#ffffff'
        },
    },
    cardsBox:{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    cardsbloques:{
        position: 'relative',
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        margin: '20px',
        '&:hover': {
            backgroundColor: coloresEmma.secondarycolor,
            color: '#FFFFFF',
            transition: "all 0.1s",
            justifyContent: 'center',
            padding: '0px',
            transform: 'scale(1.05)',
            "& $cardsImgBloques": {
                display: "none"
            },
            "& $cardsTitleBloques": {
                display: "block",
                transform: 'translate(0%, -180%)',
            },
            "& $cardsText": {
                display: "block"
            },
            "& $cardsButton": {
                display: "block"
            }
        },
    },
    cards:{
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        margin: '20px',
        '&:hover': {
            backgroundColor: coloresEmma.primarycolor,
            color: '#FFFFFF',
            transition: "all 0.1s",
            justifyContent: 'center',
            padding: '0px',
            transform: 'scale(1.05)',
            "& $cardsImg": {
                display: "none"
            },
            "& $cardsText": {
                display: "block"
            },
            "& $cardsButton": {
                display: "block"
            }
        },
    },
    cards1:{
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        margin: '20px',
        '&:hover': {
            backgroundColor: '#ad2623',
            color: '#FFFFFF',
            transition: "all 0.1s",
            justifyContent: 'center',
            padding: '0px',
            transform: 'scale(1.05)',
            "& $cardsImg": {
                display: "none"
            },
            "& $cardsText": {
                display: "block"
            },
            "& $cardsButton": {
                display: "block"
            }
        },
    },
    cardsSoporte:{
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        width: '350px',
        height: '340px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px',
    },
    cardsTextSoporte:{
        fontSize: '20px',
        fontWeight: '300',
        maxWidth: '70%',
        textAlign: 'center'
    },
    cardsImg:{
        maxHeight: '70%',
        maxWidth: '90%',
        display: 'block'
    },
    cardsTitle:{
        maxHeight: '20%',
        maxWidth: '90%',
    },
    cardsImgBloques:{
        width: '100%',
        height: '130%',
        objectFit: 'cover',
        borderRadius: '2%',
        position: 'relative',
        zIndex: 1,
    },
    cardsTitleBloques:{
        position: 'absolute',
        margin: 0,
        padding: '10px',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '10px',
        zIndex: 2,
    },
    cardsText:{
        fontSize: '20px',
        fontWeight: '300',
        color: '#FFFFFF',
        display: 'none',
        maxWidth: '70%',
        margin:'1px 0 3px 0',
        // padding:'0'
    },
    cardsText1:{
        fontSize: '20px',
        fontWeight: '300',
        color: '#FFFFFF',
        display: 'none',
        maxWidth: '70%',
        marginTop:'5px'
    },
    cardsButton:{
        display: 'none',
        textDecoration: 'none',
        border: 'none',
        borderRadius: '100px',
        background: '#FDFDFD',
        width: '188px',
        height: '50px',
        fontSize: '20px',
        color: coloresEmma.primarycolor,
        cursor: 'pointer',
        marginTop: '5px'
    },
    cardsButton1:{
        display: 'none',
        textDecoration: 'none',
        border: 'none',
        borderRadius: '100px',
        background: '#FDFDFD',
        width: '188px',
        height: '50px',
        fontSize: '20px',
        color: coloresEmma.primarycolor,
        cursor: 'pointer',
        padding:'10px'
    },
    searchBox:{
        marginTop: '50px'
    },
    searchButton:{
        textDecoration: 'none',
        border: 'none',
        borderRadius: '100px',
        background: coloresEmma.primarycolor,
        width: '150px',
        height: '50px',
        fontSize: '20px',
        color: '#FDFDFD',
        cursor: 'pointer'
    },
    inputRoot: {
        color: 'black',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(0)}px)`,
        width: '100%',
        background: '#FFFFFF',
        borderRadius: '50px',
        height: '30px'
    },
    searchIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: coloresEmma.secondarycolor,
        height: '50px',
        borderTopRightRadius: '50px',
        borderBottomRightRadius: '50px'
    },
    search: {
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '50px',  
    },
    searchFirstChildBox:{
        maxWidth: '500px', 
        border: '1px solid rgba(0, 0, 0, 0.25)', 
        borderRadius: '50px'
    },
    search2: {
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        height: '50px',
        width: '40%',
        [theme.breakpoints.down('xs')]: {
          width: '85%',
        },
    },
    cardSolicitudes: {
        maxWidth: "100%",
    },
    image: {
        width: '50%',
        maxWidth: '200px',
        minWidth: '100px',
    },
    content_text: {
        padding: "0px 10px 0px 10px",
    },
    content_img: {
        textAlign: 'center'
    },

    avatar: {
        backgroundColor: coloresEmma.secondarycolor,
    },

    paperCard:{
        borderRadius: '25px', 
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border:'1px solid rgba(0, 0, 0, 0.25)',
        '&:hover': {
            transition: "all 0.1s",
            transform: 'scale(1.05)'
        },
    },

    midTitle: {
        fontSize: '36px',
        fontWeight: '800',
        color: '#000000'
    },
    midSubTitle: {
        fontSize: '26px',
        fontWeight: '600',
        color: '#000000'
    },
    midDescription:{
        fontSize: '16px',
        fontWeight: '400',
        color: '#000000'
    },Typography: {
        color: '#9e9e9e',
    }, buttonProgress: {
        color: coloresEmma.secondarycolor,
    },
}));

const AdminSalas = () => {

    const [accion, setAccion] = React.useState('miproceso'); 
    const [modalCambiarCapacidad, setModalCambiarCapacidad] = React.useState(false);
    const [codigoSala, setCodigoSala]= React.useState('')
    const [codigobloque, setCodigobloque]= React.useState(0)
    const [ocupado, setOcupado]=React.useState(0)
    const [capacidadActual,setCapacidadActual] = React.useState('')
    const classes = useStyles()
    const dispatchI = useDispatch();
    const usuario = useSelector(state => state.redGlobal.usuario);

    React.useEffect(() => {
        dispatchI(mostrarModulo({ ruta: '/adminsalas', nombre: 'Salas Gestión' }))
      
     
      },[]);

    const vista = () => {
        if(accion=='miproceso'){
            if(usuario.perfil.codigo=='Per_Salas'){
                return(
                    <ListarSalasGestores
                    modalCambiarCapacidad={modalCambiarCapacidad}
                    setModalCambiarCapacidad={setModalCambiarCapacidad}
                    codigoSala={codigoSala}
                    setCodigoSala={setCodigoSala}
                    setCodigobloque={setCodigobloque}
                    codigobloque={codigobloque}
                    ocupado={ocupado}
                    setOcupado={setOcupado}
                    setCapacidadActual={setCapacidadActual}
                    capacidadActual={capacidadActual}
                    />
                )
            }else if(usuario.perfil.codigo=='Per_Adm_Salas'  ||usuario.perfil.codigo=='Per_Adm'){
                return(
                    <MostrarBloques 
                    modalCambiarCapacidad={modalCambiarCapacidad}
                    setModalCambiarCapacidad={setModalCambiarCapacidad}
                    codigoSala={codigoSala}
                    setCodigoSala={setCodigoSala}
                    setCodigobloque={setCodigobloque}
                    codigobloque={codigobloque}
                    ocupado={ocupado}
                    setOcupado={setOcupado}
                    setCapacidadActual={setCapacidadActual}
                    capacidadActual={capacidadActual}
                    />
                )
            }
        }else if(accion=='administrar'){
            return(
                <AdminSalasPermisos 
                handleClicki={handleClicki}
                />
            )
        }
    }
    const handleClicki = () =>{
        setAccion('miproceso')
    }
    
    return (
        <div>
            <MenuSalas usuario={usuario} sele={accion} seleccionar={(seleccion) => setAccion(seleccion)}/>
            <div className={classes.contenidoModulo}>
                {vista()}
            </div>
        </div>
    )
}
const MostrarBloques = (props) => {

    const classes = useStyles()
    const isInitialMount = React.useRef(true);
    const [cargando, setCargando] = React.useState(true);
    const [bloques, setBloques] = React.useState([]);
    const [salas, setSalas] = React.useState([]);
    const [salaId, setSalaId] = React.useState(0);
    const [seleccion, setSeleccion] = React.useState("BLOQUES");
    const [salaTitle, setSalaTitle] = React.useState("");
    const [bloqueTitle, setBloqueTitle] = React.useState("");
    const [software, setSoftware] = React.useState([]);
    const [openModalAddSoftware, setOpenModalAddSoftware] = React.useState(false)
    const dispatchI = useDispatch();

    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            dispatchI(mostrarModulo({ ruta: '/salas', nombre: 'Salas' }))
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                await getBloques()
  
            }
            obtenerProcesos()
        }
    }, [])

    // const Softwaretodos = async () =>{
    //     try{
    //         let data = await Funciones.obtenerValores(Helper.SOFTWARES)
    //         setSoftwaretodos(data)    
    //     }catch(err){
    //       console.log(err)
    //     } 
    // }
    //Obtener Bloques
    
    const getBloques = async () =>{
        try{
            let data = await Funciones.obtenerValores(Helper.BLOQUES)
            if(data.codigo!='soporte_tecnologia')setBloques(data)
            setSeleccion('BLOQUES')        
        }catch(err){
          console.log(err)
        } 
    }
    
    //Obtener Salas
    const getSalas= async (id) => {
        try{
            let data = await Funciones.obtenerPermisosValor(id)
            
            data = data.map(({ secundario }) => ({
                id: secundario.id,
                nombre: secundario.nombre,
                codigo: secundario.codigo,
                valora: secundario.valora,
                valorb: secundario.valorb,
                valorc: secundario.valorc,
                valord: secundario.valord,
                valore: secundario.valore,
                valorf: secundario.valorf,
                archivo: secundario.archivo
            }));
            data.sort((a, b) => {
                if(a.valorb < b.valorb) return -1;
                if(a.valorb > b.valorb) return 1;
            
                return 0;
            })
            setSalas(data)
        }catch(err){
          console.log(err)
        } 
    }

    //Evento click bloques a salas
    const changeBloquesToSalas = (id, nombre) =>{
        // setCargando(true)
        const obtenerProcesos = async (id) => {
            
            await getSalas(id);
            setBloqueTitle(nombre)
            setSeleccion('SALAS')
            // setCargando(false)
            
        }
        obtenerProcesos(id)
    }

    //Obtener Software
    const getSoftware= async (id) => {
        try{
            let data = await Funciones.obtenerPermisosValor(id)
            data = data.map(({ secundario }) => ({
                id: secundario.id,
                nombre: secundario.nombre,
                codigo: secundario.codigo,
                valora: secundario.valora,
                valorb: secundario.valorb
            }));
            setSoftware(data)
        }catch(err){
          console.log(err)
        } 
    }

    //Evento click salas a SOFTWARE
    const changeSalasToSoftware = (id, nombre) =>{
        setCargando(true)
        const obtenerProcesos = async (id) => {
            
            await getSoftware(id);
            setSeleccion('SOFTWARE')
            setSalaTitle(nombre)
            setCargando(false)
            
        }
        obtenerProcesos(id)
       
    }
    
    //Evento atras vista
    const buttonBack = () =>{
        if(seleccion == "SOFTWARE"){
            setSeleccion("SALAS")
        }else if(seleccion == "SALAS"){
            setSeleccion("BLOQUES")
        }
    }

    const vista = () => {
        let titulo= <h2 style={{ margin: '10px 0 0px 0' }}>Selecciona un Bloque</h2>
        let subtitulo=<p style={{ margin: '0 0 10px 0' }}>Estos son los bloques con salas disponibles, puedes dar clic en el bloque que desees para mas información.</p>
        let titulo1= <h3 style={{ margin: '10px 0 0px 0' }}>Estas son las Salas disponibles del {bloqueTitle}</h3>
        let titulo2= <h2 style={{ margin: '10px 0 0px 0' }}>Lista de Softwares disponibles en {salaTitle}</h2>
        let subtitulo2=<p style={{ margin: '10px 0 10px 0' }}>Ten en cuenta que puedes acceder de forma virtual a estos Softwares dando <Button target="_blank" href="https://labs.cuc.edu.co/" variant="contained" color="secondary" style={{color: '#FFFFFF'}}>click aquí</Button></p>
        switch (seleccion) {
            case 'BLOQUES':
                return(
                    <React.Fragment>
                        <Grid container direction={'row'} justifyContent={'center'}>
                            
                            <Grid style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                                <Grid container direction={'column'}>
                                    <Grid xs={12} style={{paddingLeft:'2%',paddingRight:'2%',margin:'50px, 0 0 0'}}>
                                        <BannerAdmin titulo={titulo}subtitulo={subtitulo}/>
                                     </Grid>
                                    <Grid container direction={'row'} justifyContent={'center'} style={{marginTop: '50px'}}>
                                        {bloques.map((element) => (
                                            <>
                                            {element.codigo!='soporte_tecnologia' ? 
                                            <div key={element.id} className={classes.cardsbloques}>
                                                <h2 className={classes.cardsTitleBloques}>{element.nombre}</h2>
                                                <p className={classes.cardsText}>{element.valora}</p>
                                                <button className={classes.cardsButton} onClick={() => (changeBloquesToSalas(element.id, element.nombre), props.setCodigobloque(element.id))}>Ver mas...</button>
                                                <img className={classes.cardsImgBloques} src={api+element.archivo}></img>
                                                </div>
                                                :''}
                                            </>
                                        ))}
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </React.Fragment> 
                )
          case 'SALAS':
            return (
                <React.Fragment>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        
                        <Grid  style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                            <Grid container direction={'column'}>
                                <Grid item  md={4}>
                                    <Button  onClick={() => buttonBack()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon/> <Typography variant="body2" style={{ color: '#000000' }} component="p" align='justify'> Regresar</Typography> </Button>
                                </Grid>
                                <Grid xs={12} style={{paddingLeft:'12.5%',paddingRight:'12.5%',margin:'50px, 0 0 0'}}>
                                        <BannerAdmin titulo={titulo1}/>
                                     </Grid>
                                <Grid container direction={'row'} justifyContent={'center'} style={{marginTop: '50px'}}>
                                {salas.map((element) => (
                                    <>
                                    <div>
                                    {element.valorc==element.valorf ? 
                                        <div key={element.id} className={classes.cards1} style={{justifyContent: 'center'}}>
                                         <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                        <p className={classes.cardsText}>Piso: {element.valora}</p>
                                        <p className={classes.cardsText}>La Sala Esta Ocupada En Clase</p>
                                        <p className={classes.cardsText}>Capacidad Total Sala:{element.valorf}</p>
                                        <button className={classes.cardsButton} onClick={() => (props.setModalCambiarCapacidad(true),props.setCodigoSala(element.codigo) ,props.setOcupado(element.valorc),props.setCapacidadActual(element.valorf))}>Actualiza Puestos</button>
                                        
                                        <button className={classes.cardsButton} onClick={() => (setSalaId(element.id), changeSalasToSoftware(element.id, element.nombre))}>Software...</button>
                                        <img className={classes.cardsImg} src={element.archivo == null ? element.archivo: Funciones.api+element.archivo}></img>
                                        </div>
                                    :   
                                        <div key={element.id} className={classes.cards} style={{justifyContent: 'center'}}>
                                        <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                        <p className={classes.cardsText}>Piso: {element.valora}</p>
                                        <p className={classes.cardsText}>Puestos Ocupado: {element.valorc}</p>
                                        <p className={classes.cardsText}>Cupos Disponible:{element.valord}</p>
                                        <p className={classes.cardsText}>Capacidad Total Sala:{element.valorf}</p>
                                        <button className={classes.cardsButton} onClick={() => (props.setModalCambiarCapacidad(true),props.setCodigoSala(element.codigo) ,props.setOcupado(element.valorc),props.setCapacidadActual(element.valorf))}>Actualiza Puestos</button>
                                        <button className={classes.cardsButton} onClick={() =>(setSalaId(element.id),changeSalasToSoftware(element.id, element.nombre))}>Software...</button>
                                        <img className={classes.cardsImg} src={element.archivo == null ? element.archivo: Funciones.api+element.archivo}></img>
                                    </div>
                                    }
                                    </div>
                                    </>
                                ))}
                                {props.modalCambiarCapacidad==true ?
                                    <ModalChangeCapacidad
                                    modalCambiarCapacidad={props.modalCambiarCapacidad}
                                    setModalCambiarCapacidad={props.setModalCambiarCapacidad}
                                    codigoSala={props.codigoSala}
                                    setCodigoSala={props.setCodigoSala}
                                    setCodigobloque={props.setCodigobloque}
                                    codigobloque={props.codigobloque}
                                    ocupado={props.ocupado}
                                    setOcupado={props.setOcupado}
                                    setCapacidadActual={props.setCapacidadActual}
                                    capacidadActual={props.capacidadActual}
                                    getSalas={getSalas}
                                    

                                    />
                                :''}
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </React.Fragment>      
            )
            case 'SOFTWARE':
                return(
                    <React.Fragment>
                        <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid xs={12} style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                            <Grid container direction={'column'}>
                                <Grid item xs={12} md={4}>
                                    <Button  onClick={() => buttonBack()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon/> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Regresar</Typography> </Button>
                                </Grid>
                                <Grid xs={12} style={{paddingLeft:'12.5%',paddingRight:'12.5%',margin:'50px, 0 0 0'}}>
                                        <BannerAdmin titulo={titulo2}subtitulo={subtitulo2}/>
                                     </Grid>
                                <Grid container direction={'row'} justifyContent={'center'} style={{marginTop: '50px'}}>
                                    <div style={{width: '50%', minWidth: '300px'}}>
                                        <ListarTecnologias 
                                            software={software}
                                            salaId={salaId}
                                            setSalaId={setSalaId}
                                            openModalAddSoftware={openModalAddSoftware}
                                            setOpenModalAddSoftware={setOpenModalAddSoftware}
                                            changeSalasToSoftware={changeSalasToSoftware}
                                            getSoftware={getSoftware}
                                            vistaSearch={false}
                                        />
                                    </div>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                        
                    </React.Fragment>
                )
          default:
            break;
        }
    }

    if (props.cargando)
    return (
      <TareasTerminadas
        mensaje="Cargando.."
        marginTop="7%"
        imagen={emma_w}
        widthImg="7%"
      />
    );
    return (
        <>
        {vista()}
        </>
    )
}
const ListarSalasGestores = (props) => {

    const [cargando, setCargando] = React.useState(true);
    const [salas, setSalas] = React.useState([]);
    const [salaTitle, setSalaTitle] = React.useState("");
    const [salaId, setSalaId] = React.useState(0);
    const classes = useStyles()
    const dispatchI = useDispatch();
    const tipo =Helper.PERMISO_SALAS;
    const usuario = useSelector(state => state.redGlobal.usuario);
    const [software, setSoftware] = React.useState([]);
    const [openModalAddSoftware, setOpenModalAddSoftware] = React.useState(false)
    let titulo2= <h2 style={{ margin: '10px 0 0px 0' }}>Lista de Softwares disponibles en {salaTitle}</h2>
    let subtitulo2=<p style={{ margin: '10px 0 10px 0' }}>Ten en cuenta que puedes acceder de forma virtual a estos Softwares dando <Button target="_blank" href="https://labs.cuc.edu.co/" variant="contained" color="secondary" style={{color: '#FFFFFF'}}>click aquí</Button></p>
    const [seleccion, setSeleccion] = React.useState("SALAS");
   
    React.useEffect(() => {
        dispatchI(mostrarModulo({ ruta: '/adminsalas', nombre: 'Salas Gestión' }))
        obtenerProcesos()
        
      }, []);
    
    const obtenerProcesos = async (id) => {    
        let data= await obtenerSalas(id);
        data = data.map(({relacion})=>({
            id: relacion.id,
            nombre: relacion.nombre,
            codigo: relacion.codigo,
            valora: relacion.valora,
            valorb: relacion.valorb,
            valorc: relacion.valorc,
            valord: relacion.valord,
            valore: relacion.valore,
            valorf: relacion.valorf,
        }))
        setSalas(data)
    }
    const getSoftware= async (id) => {
        try{
            let data = await Funciones.obtenerPermisosValor(id)
            data = data.map(({ secundario }) => ({
                id: secundario.id,
                nombre: secundario.nombre,
                codigo: secundario.codigo,
                valora: secundario.valora,
                valorb: secundario.valorb
            }));
            setSoftware(data)
        }catch(err){
          console.log(err)
        } 
    }
    const changeSalasToSoftware = (id, nombre) =>{
        
        const obtenerProcesosss = async (id) => {
            
            await getSoftware(id);
            setSeleccion('SOFTWARE')
            setSalaTitle(nombre)
            setCargando(false)
            
        }
        obtenerProcesosss(id)
       
    }
        
    const obtenerSalas = async () => {
        return new Promise((resolve) => {
          Funciones.consulta(`api/v1.0/personas/${usuario.id}/genericas/${tipo}`, null, null, (error, estado, resp) => {
            resolve(estado === 200 && !error ? resp : null);
          });
        });
      };
   
    const buttonBack = () =>{
        if(seleccion == "SOFTWARE"){
            setSeleccion("SALAS")
        }
    }

     return (
         <> 
         {seleccion=='SALAS'?       

         <React.Fragment>
            <Grid container direction={'row'} justifyContent={'center'}>
                
                <Grid xs={12} style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                    <Grid container direction={'column'}>
                        <Grid xs={12} style={{paddingLeft:'12.5%',paddingRight:'12.5%'}}>
                            <Banner/>
                        </Grid>
                        <Grid xs={12} container direction={'row'} justifyContent={'center'} style={{marginTop: '10px'}}>
                        {salas.map((element) => (
                            <>
                            <div>
                            {element.valorc==element.valorf ? 
                                        <div key={element.id} className={classes.cards1} style={{justifyContent: 'center'}}>
                                         <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                        <p className={classes.cardsText}>Piso: {element.valora}</p>
                                        <p className={classes.cardsText}>La Sala Esta Ocupada En Clase</p>
                                        <p className={classes.cardsText}>Capacidad Total Sala:{element.valorf}</p>
                                        <button className={classes.cardsButton} onClick={() => (props.setModalCambiarCapacidad(true),props.setCodigoSala(element.codigo) ,props.setOcupado(element.valorc),props.setCapacidadActual(element.valorf))}>Actualiza Puestos</button>
                                        
                                        <button className={classes.cardsButton} onClick={() => (setSalaId(element.id), changeSalasToSoftware(element.id, element.nombre))}>Software...</button>
                                        <img className={classes.cardsImg} src={element.archivo == null ? element.archivo: Funciones.api+element.archivo}></img>
                                        </div>
                                    :   
                                        <div key={element.id} className={classes.cards} style={{justifyContent: 'center'}}>
                                        <h2 className={classes.cardsTitle}>{element.nombre}</h2>
                                        <p className={classes.cardsText}>Piso: {element.valora}</p>
                                        <p className={classes.cardsText}>Puestos Ocupado: {element.valorc}</p>
                                        <p className={classes.cardsText}>Cupos Disponible:{element.valord}</p>
                                        <p className={classes.cardsText}>Capacidad Total Sala:{element.valorf}</p>
                                        <button className={classes.cardsButton} onClick={() => (props.setModalCambiarCapacidad(true),props.setCodigoSala(element.codigo) ,props.setOcupado(element.valorc),props.setCapacidadActual(element.valorf))}>Actualiza Puestos</button>
                                        <button className={classes.cardsButton} onClick={() =>(setSalaId(element.id),changeSalasToSoftware(element.id, element.nombre))}>Software...</button>
                                        <img className={classes.cardsImg} src={element.archivo == null ? element.archivo: Funciones.api+element.archivo}></img>
                                    </div>
                            }
                            </div>
                            </>
                        ))}
                        {props.modalCambiarCapacidad==true ?
                            <ModalChangeCapacidad
                            modalCambiarCapacidad={props.modalCambiarCapacidad}
                            setModalCambiarCapacidad={props.setModalCambiarCapacidad}
                            codigoSala={props.codigoSala}
                            setCodigoSala={props.setCodigoSala}
                            setCodigobloque={props.setCodigobloque}
                            codigobloque={props.codigobloque}
                            ocupado={props.ocupado}
                            setOcupado={props.setOcupado}
                            setCapacidadActual={props.setCapacidadActual}
                            capacidadActual={props.capacidadActual}
                            obtenerProcesos={obtenerProcesos}
                            
                        />
                        :''}
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
            
        </React.Fragment> :
        
            <React.Fragment>
                <Grid container direction={'row'} justifyContent={'center'}>
                <Grid xs={12} style={{textAlign:'center', maxWidth: '2000px', marginTop: '50px'}}>
                    <Grid container direction={'column'}>
                        <Grid item xs={12} md={4}>
                            <Button  onClick={() => buttonBack()} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon/> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Regresar</Typography> </Button>
                        </Grid>
                        <Grid xs={12} style={{paddingLeft:'12.5%',paddingRight:'12.5%',margin:'50px, 0 0 0'}}>
                                <BannerAdmin titulo={titulo2}subtitulo={subtitulo2}/>
                             </Grid>
                        <Grid container direction={'row'} justifyContent={'center'} style={{marginTop: '50px'}}>
                            <div style={{width: '50%', minWidth: '300px'}}>
                                <ListarTecnologias 
                                    software={software}
                                    salaId={salaId}
                                    setSalaId={setSalaId}
                                    openModalAddSoftware={openModalAddSoftware}
                                    setOpenModalAddSoftware={setOpenModalAddSoftware}
                                    changeSalasToSoftware={changeSalasToSoftware}
                                    getSoftware={getSoftware}
                                    vistaSearch={false}
                                />
                            </div>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
                
            </React.Fragment>
    }
    </>     
     
    )

}
const ModalChangeCapacidad =  (props) =>{
    const classes = useStyles()
    const dispatch = useDispatch();
    const [cargando, setCargando] = React.useState(false);
    const [puestosOcupados,setPuestosOcupados] = React.useState('')
    const [horaHasta,setHoraHasta] = React.useState(null)
    const isInitialMount = React.useRef(true);
    const [isChecked, setIsChecked] = React.useState(false);
    const usuario = useSelector(state => state.redGlobal.usuario);
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                if(props.modalCambiarCapacidad== true){
                    if(props.ocupado==props.capacidadActual){
                        setIsChecked(true)
                    }
                }

            }
            obtenerProcesos()
        }
    })

    const onInputChange = (e) => {
        const inputValue=e.target.value
        if(/^\d*$/.test(inputValue))setPuestosOcupados(inputValue);
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleOnchange();
        }
      };
   
    const handleCheckboxChange = (event) => {
        if(event.target.checked) {
            props.setOcupado(props.capacidadActual)
            // setPuestosOcupados(0)
            setIsChecked(true)

        } else {
            // props.setOcupado(0)
            setIsChecked(false)
        }
        
       
    }
    
    const handleOnchange = async() =>{
        let l = ''
        l = await Funciones.obtenerValoresFiltros([{'llave':'generica','valor':Helper.SALAS},{ 'llave': 'codigo', 'valor': props.codigoSala}]);
        if (!horaHasta){
            let titulo = "Seleccione la hora"
            let tipo = "info"
        
            dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
        }else if(isChecked==true){
            if(l.length>0 ){
                setCargando(true)
                await Funciones.modificarValorGenericaAlter(l[0].id, { 'valorc': props.ocupado, 'valord': '0', 'valore': moment(horaHasta).format('HH:mm')})
                props.setModalCambiarCapacidad(false)
                if(usuario.perfil.codigo=='Per_Salas'){
                props.obtenerProcesos()
                setCargando(false)
                }else if(usuario.perfil.codigo=='Per_Adm_Salas'  ||usuario.perfil.codigo=='Per_Adm'){
                    await props.getSalas(props.codigobloque);
                    setCargando(false)
                }
                let titulo = "Se Asigno la sala ocupada"
                let tipo = "success"
                dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
    
            }
            
        }else if (parseInt(puestosOcupados)>parseInt(props.capacidadActual)){
            let titulo = "Digite Una Capacidad Correcta no puede se mayor a la capacidad Total"
            let tipo = "info"
        
            dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
        }else if (puestosOcupados.trim() === ''){
            let titulo = "El campo Puesto Ocupados no puede estar vacío"
            let tipo = "info"
        
            dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
        
        }else{

           if (l.length > 0) {
               let titulo = 'Ha ocurrido un error, contacte con el administrador.'
               let tipo="error"
               setCargando(true)
               let { error, estado, resp } = await Funciones.modificarValorGenericaAlter(l[0].id, { 'valorc': puestosOcupados, 'valord':( props.capacidadActual-puestosOcupados), 'valore': moment(horaHasta).format('HH:mm')})
            //    await Funciones.modificarValorGenericaAlter(l[0].id, { 'valord':( props.capacidadActual-puestosOcupados )})
               if (!error) {
                   if (estado === 200) {
                   let titulo = 'Capacidad Actualizada Corretamente';
                   let tipo = 'success';
                       dispatch(actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 }));
                       props.setModalCambiarCapacidad(false)
                       setCargando(false)
                       if(usuario.perfil.codigo=='Per_Salas'){
                        props.obtenerProcesos()
                        setCargando(false)
                        }else if(usuario.perfil.codigo=='Per_Adm_Salas'  ||usuario.perfil.codigo=='Per_Adm'){
                            await props.getSalas(props.codigobloque);
                            setCargando(false)
                        }
   
                   } else {
                       tipo = 'info';
                       titulo = resp.titulo ? resp.titulo : Funciones.mostrarError(resp)
                   }
                   
                   }
               actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
   
           }

        } 

        
    } 

    return (
        cargando == true ? 
        (<Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress disableShrink className={classes.buttonProgress} />
        <Typography variant="h6" component="h2" className={classes.Typography}>
        Cargando...
        </Typography>
        </Box>) 
        :
                <Dialog fullWidth={true} maxWidth='xs' fullScreen={window.innerWidth < 600} open={props.modalCambiarCapacidad} onClose={()=>{props.setModalCambiarCapacidad(false)}} aria-labelledby="form-dialog-title">
        
                    <AppBarModal mostrarModal={()=>{props.setModalCambiarCapacidad(false)}} titulo='Nueva Capacidad' titulo_accion="" accion="" />
                    {/* Contenido del modal del formulario de */}
                    <DialogContent>
                        { isChecked ==false &&props.ocupado!=props.capacidadActual ?
                        <Grid item xs={12}>
                            <FormControl fullWidth >
                                <TextField 
                                value={puestosOcupados}
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={onInputChange}
                                label='Puestos Ocupados'
                                required={true}
                                onKeyDown={handleKeyDown}
                                >
                                </TextField>
                                <FormHelperText></FormHelperText>
                            </FormControl>
                                <Grid item xs={12} style={{marginTop:'15px'}}>
                                <InputLabel>La Sala Se Encuentra En Clases.
                                    <Checkbox
                                        edge="start"
                                        onChange={handleCheckboxChange}
                                        checked={isChecked}
                                        />
                            
                                </InputLabel>
                                </Grid>
                        </Grid>
                            : isChecked ==true ? 
                            <Grid item xs={12}>
                                <Grid item xs={12} style={{marginTop:'15px'}}>
                                <InputLabel>La Sala Se Encuentra En Clases.
                                    <Checkbox
                                        edge="start"
                                        onChange={handleCheckboxChange}
                                        checked={isChecked}
                                        />
                                </InputLabel>
                                </Grid>
                            </Grid>
                            : isChecked ==false && props.ocupado==props.capacidadActual?
                            <FormControl fullWidth >
                                <TextField 
                                value={puestosOcupados}
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={onInputChange}
                                label='Puestos Ocupados'
                                required={true}
                                onKeyDown={handleKeyDown}
                                >
                                </TextField>
                                <FormHelperText></FormHelperText>
                            </FormControl>
                            :''
                            }  
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Grid container>
                                <KeyboardTimePicker
                                    style={{ 
                                        // marginTop: 3, 
                                        width: '100%' }}
                                    margin="normal"
                                    label="Hasta: "
                                    value={horaHasta}
                                    onChange={setHoraHasta}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage='Formato Invalido'
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    </DialogContent>
                
                    <DialogActions>
                        <Button  onClick={()=>{handleOnchange()}} > Aceptar</Button>
                        <Button  onClick={()=>{props.setModalCambiarCapacidad(false)}} > Cerrar </Button>
                    </DialogActions>
        
                </Dialog>
        )
}
const ListarTecnologias = (props) => {

    const [cargando, setCargando] = React.useState(true);
    const isInitialMount = React.useRef(true);
    const [modal, setModal] = React.useState(false);
    const [permisos, setPermisos] = useState([]);
    const [relacion, setRelacion] = useState([]);
    const [generica, setGenerica] = React.useState({
        tipo: 42,
        id: Helper.SOFTWARES,
      });
    const usuario = useSelector(state => state.redGlobal.usuario);
    let secundario=''
    let permiso=0
    React.useEffect( () => {
        //Obtengo los prceosos activos de la persona activa.
        if(isInitialMount.current){
            isInitialMount.current = false
            const obtenerProcesos = async () => {
                if(props.vistaSearch == true){
                    setCargando(false)
                }else{
                    setCargando(false)
                }
               
            }
            obtenerProcesos()
        }
    })


    return (
       
        cargando == true ? <div> <TareasTerminadas imagen={emma_s} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /></div> :
    <>
      <React.Fragment>
          <ListarDatos
              id="tbl_listar_encargados"
              datos={props.software}
              opciones={true}
              buscar={true}
              avatar={({ nombre }) => nombre.toUpperCase().charAt(0) }
              agregar = { true }
              actAgregar={() => (setModal(true))}
              //color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
              titulo={"Lista de Software's Disponibles"}
              fila_principal={({ nombre }) => `${nombre}`}
              filas={ [
              {
                  'mostrar': ({ nombre }) => `${nombre}`,
                  'id': 'nombre', 'enLista': false
              },
              ] 
              }
          />
      { modal==true?
      <Dialog open={modal} fullWidth={true} maxWidth='sm' onClose={() => setModal(false)} >
          <AppBarModal titulo='¡ Lista Permisos !' mostrarModal={() => setModal(false)} accion={() => setModal(false)} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
              <Permisos changeSalasToSoftware={props.changeSalasToSoftware} salas={props.salaId}  genericaRelacionId={Helper.SOFTWARES} titulo="¡ Nuevo Permiso !" tituloInput="Permisos" tituloEliminar='¿ Eliminar Permiso ?' />
          </DialogContent>
          <DialogActions>
          </DialogActions>
      </Dialog>
      : ''}
      
      </React.Fragment>
    
  </>

    )
}



export default AdminSalas 