import React from "react";

// Material UI
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

// Helpers
import { EPS_OTRO, ESTADO_CIVIL_OTRO } from "./helper";

const InscripcionPosgradoContacto = ({ propsDatos }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Datos de contacto
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.departamento)}
          >
            <InputLabel>Departamento de residencia *</InputLabel>
            <Select
              value={propsDatos.departamento}
              id="select-departamento"
              name="departamentos"
              onChange={(e) => propsDatos.setDepartamento(e.target.value)}
              placeholder="Seleccione una opción"
              required
            >
              {propsDatos.departamentos.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{propsDatos.error.departamento}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.ciudad)}
          >
            <InputLabel>Ciudad o municipio de residencia *</InputLabel>
            <Select
              value={propsDatos.ciudad}
              id="select-municipio"
              name="municipios"
              onChange={(e) => propsDatos.setCiudad(e.target.value)}
              placeholder="Seleccione una opción"
              required
            >
              {propsDatos.ciudades.length > 0 ? (
                propsDatos.ciudades.map((item, index) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value={0}>
                  Seleccione un departamento
                </MenuItem>
              )}
            </Select>
            <FormHelperText>{propsDatos.error.ciudad}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={propsDatos.barrio}
            onChange={(e) => propsDatos.setBarrio(e.target.value)}
            name="barrio"
            id="barrio"
            label="Barrio de residencia"
            error={Boolean(propsDatos.error.barrio)}
            helperText={propsDatos.error.barrio}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={propsDatos.direccion}
            onChange={(e) => propsDatos.setDireccion(e.target.value)}
            name="direccion"
            id="direccion"
            label="Dirección de residencia"
            error={Boolean(propsDatos.error.direccion)}
            helperText={propsDatos.error.direccion}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.estrato)}
          >
            <InputLabel>Estrato de vivienda *</InputLabel>
            <Select
              value={propsDatos.estrato}
              id="select-estrato"
              name="estratovivienda"
              onChange={(e) => propsDatos.setEstrato(e.target.value)}
              placeholder="Seleccione una opción"
              required
            >
              {propsDatos.estratos.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{propsDatos.error.estrato}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.eps)}
          >
            <InputLabel>¿Posee EPS? *</InputLabel>
            <Select
              value={propsDatos.eps}
              id="select-eps"
              name="eps"
              onChange={(e) => propsDatos.setEps(e.target.value)}
              placeholder="Seleccione una opción"
              required
            >
              {propsDatos.epsResp.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{propsDatos.error.eps}</FormHelperText>
          </FormControl>
        </Grid>
        {propsDatos.eps === EPS_OTRO && 
          <Grid item xs={12}>
           <TextField
            value={propsDatos.otroEps}
            onChange={(e) => propsDatos.setOtroEps(e.target.value)}
            name="otroEps"
            id="otro-eps"
            label="Escriba nombre de la EPS"
            error={Boolean(propsDatos.error.otroEps)}
            helperText={propsDatos.error.otroEps}
            fullWidth
            required
          />
        </Grid>
        }
        <Grid item xs={12}>
          <FormControl
            className="form-control"
            error={Boolean(propsDatos.error.estadoCivil)}
          >
            <InputLabel>Estado civil *</InputLabel>
            <Select
              value={propsDatos.estadoCivil}
              id="select-estado-civil"
              name="estadocivil"
              onChange={(e) => propsDatos.setEstadoCivil(e.target.value)}
              placeholder="Seleccione una opción"
              required
            >
              {propsDatos.estadosCiviles.map((item, index) => (
                <MenuItem key={item.value} value={item}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{propsDatos.error.estadoCivil}</FormHelperText>
          </FormControl>
        </Grid>
        {propsDatos.estadoCivil.value === ESTADO_CIVIL_OTRO && (
          <Grid item xs={12}>
            <TextField
              value={propsDatos.estadoCivilOtro}
              onChange={(e) => propsDatos.setEstadoCivilOtro(e.target.value)}
              name="estadoCivilOtro"
              id="estado-civil-otro"
              label="Especifique cuál es el otro estado civil"
              error={Boolean(propsDatos.error.estadoCivilOtro)}
              helperText={propsDatos.error.estadoCivilOtro}
              fullWidth
              required
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InscripcionPosgradoContacto;
