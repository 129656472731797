import React, { Component } from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View, pdf, Link } from '@react-pdf/renderer';
import cuc from "../../../global/imagenes/logo_cuc_vertical.png";
import source from '../../../global/fonts/Roboto-Regular.ttf'
import sourcerobotobold from '../../../global/fonts/Roboto-Bold.ttf'
import { api, consulta } from '../../../global/js/funciones';
import { dataBound } from '@syncfusion/ej2-react-schedule';

Font.register({ family: 'Roboto', src: source });
Font.register({ family: 'RobotoBold', src: sourcerobotobold });

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    flexDirection: 'column',
    padding: 20,
  },
  header: {
    textAlign: 'start',
    fontSize: 7,
    color: 'grey',
  },
  body: {
    flexGrow: 1,
  },
  titulo_principal: {
    width: '50%',
    marginLeft: 10,
    textAlign: 'justify',
    fontSize: 12,
    fontFamily: 'RobotoBold',
  },
  titulo_tabla: {
    marginLeft: 4,
    padding: 3,
    fontSize: 10,
    fontFamily: 'RobotoBold',
  },
  table: {
    display: 'table',
    width: '100%',
    marginVertical: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#ccc',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
  },
  tableCell: {
    flex: 1,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: '#ccc',
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  tableCellSmall: {
    flex: 1,
    padding: 1,
    borderRightWidth: 1,
    borderRightColor: '#ccc',
    fontSize: 8,
    fontFamily: 'Roboto',
  },
  firstTableCell: {
    flex: 1,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: '#ccc',
    fontSize: 9,
    color: '#BA141A',
    fontFamily: 'Roboto',
    flexWrap: 'wrap', 
  },
  firstTableCellSmall: {
    flex: 1,
    padding: 1,
    borderRightWidth: 1,
    borderRightColor: '#ccc',
    fontSize: 8,
    color: '#BA141A',
    fontFamily: 'Roboto',
    flexWrap: 'wrap', 
  },
  lastTableCell: {
    flex: 1,
    padding: 5,
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  text: {
    flexShrink: 1,
    textAlign: 'left',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',

  },
});



class DescargarPdfRegistro extends Component {
  async componentDidMount() {
    let { id_solicitud } = this.props;
    let data = await this.obtenerInfoRegMov(id_solicitud);
    this.generarYDescargarPDF(data);
  }

  async obtenerInfoRegMov(id) {
    // let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/obtenerRegistro/${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  generarYDescargarPDF = (data) => {
    const { no_cargando } = this.props;
    let { solicitud: { estado_actual, departamento_docente, programa_docente }, actividad: { titulo_evento, tipo_actividad, subtipo_actividad, fecha_inicio, fecha_final, descripcion_actividad, descripcion_resultados, departamento_resp, facultad_resp, unidades_adm_resp, institucion_actividad }, participantes, adjuntos } = data;
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    const formattedTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

    const pdfDoc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text>Generado el {formattedDate} a las {formattedTime}</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', margin: '20px' }}>
            <Image src={cuc} style={{ width: '25%', height: 85 }} />
            <Text style={styles.titulo_principal} >REGISTRO DE ACTIVIDADES ACADÉMICAS DE INTERNACIONALIZACIÓN</Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.tableRow, { borderLeft: '5px solid #BA141A' }]}>
              <Text style={styles.titulo_tabla}>CATEGORIZACIÓN ACTIVIDAD ACADÉMICA</Text>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Título de la actividad académica</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                <Text>{titulo_evento || ''}</Text>
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Tipo de actividad académica</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                <Text>{tipo_actividad?.nombre || ''}</Text>
              </View>
            </View>
            {
              subtipo_actividad && (
                <View style={styles.tableRow} wrap={false}>
                  <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                    <Text>Subtipo de actividad académica</Text>
                  </View>
                  <View style={[styles.tableCell, { textAlign: 'center' }]}>
                    <Text>{subtipo_actividad?.nombre || ''}</Text>
                  </View>
                </View>
              )
            }
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Fecha de inicio</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                <Text>{fecha_inicio || ''}</Text>
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Fecha de finalización</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                <Text>{fecha_final || ''}</Text>
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Universidades</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                {institucion_actividad && institucion_actividad.length > 0 ? institucion_actividad.map((inst, index) => {
                  return (
                    <Text key={index}>
                      · {inst?.institucion}
                      {inst?.pais_vinculante?.nombre && ` - ${inst.pais_vinculante.nombre}`}
                      {inst?.ciudad_vinculante && ` - ${inst.ciudad_vinculante}`}
                    </Text>
                  )
                })
                  : <Text></Text>
                }
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Estado del registro</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                <Text>{estado_actual?.nombre || ''}</Text>
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text style={styles.text} >Unidad administrativa responsable de la actividad académica</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                {unidades_adm_resp && unidades_adm_resp.length > 0 ? unidades_adm_resp.map((dpto, index) => {
                  return (
                    <Text key={index}>· {dpto.nombre}</Text>
                  )
                })
                  : <Text></Text>
                }
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text style={styles.text} >Departamento responsable de la actividad académica</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                {departamento_resp && departamento_resp.length > 0 ? departamento_resp.map((dpto, index) => {
                  return (
                    <Text key={index}>· {dpto.nombre}</Text>
                  )
                })
                  : <Text></Text>
                }
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Facultad responsable de la actividad académica</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                {facultad_resp && facultad_resp.length > 0 ? facultad_resp.map((fac, index) => {
                  return (
                    <Text key={index}>· {fac.nombre}</Text>
                  )
                })
                  : <Text></Text>
                }
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Departamento académico del solicitante</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                {departamento_docente &&
                  <Text>{departamento_docente.nombre}</Text>
                }
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { flexBasis: 170, flexGrow: 0, flexShrink: 0 }]}>
                <Text>Programa académico al que tributará la actividad</Text>
              </View>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                {programa_docente &&
                  <Text>{programa_docente.nombre}</Text>
                }
              </View>
            </View>
            <View style={[styles.tableRow, { padding: 8 }]} wrap={false}>
            </View>
            <View style={[styles.tableRow, { borderLeft: '5px solid #BA141A' }]} wrap={false}>
              <Text style={styles.titulo_tabla}>OBJETIVOS Y RESULTADOS DE LA ACTIVIDAD</Text>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { textAlign: 'center' }]}>
                <Text>Objetivo de la actividad</Text>
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                <Text>{descripcion_actividad || ''}</Text>
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.firstTableCell, { textAlign: 'center' }]}>
                <Text>Descripción de resultados y/o productos obtenidos</Text>
              </View>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                <Text>{descripcion_resultados || ''}</Text>
              </View>
            </View>
            <View style={[styles.tableRow, { padding: 8 }]} wrap={false}>
            </View>
            {
              participantes.length > 0 && (
                participantes.map(({ participante: { tipo_movilidad, tipo_modalidad, persona_cuc, persona_inter }, institucion: { tipo_participante, institucion_externa, nivel_estudio, programa_receptor, facultad_receptora, dpto_academico_receptor }, financiacion }, index) => {
                  let persona_registro = persona_cuc !== null ? persona_cuc : persona_inter;
                  return (
                    <>
                      <View key={index} style={[styles.tableRow, { borderLeft: '5px solid #BA141A' }]} wrap={false}>
                        <Text style={styles.titulo_tabla}>PARTICIPANTE {index + 1}</Text>
                      </View>
                      <View style={styles.tableRow} wrap={false}>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Tipo de Movilidad</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{tipo_movilidad?.nombre || ''}</Text>
                        </View>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Tipo de Modalidad</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{tipo_modalidad?.nombre || ''}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow} wrap={false}>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Tipo de Participante</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{tipo_participante?.nombre || ''}</Text>
                        </View>
                        {
                          persona_registro && (
                            <>
                              <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                                <Text>Nombre</Text>
                              </View>
                              <View style={[styles.tableCell, { textAlign: 'center' }]}>
                                <Text>{`${persona_registro.primer_nombre ?? ''} ${persona_registro.segundo_nombre ?? ''} ${persona_registro.primer_apellido ?? ''} ${persona_registro.segundo_apellido ?? ''}`}</Text>
                              </View>
                            </>
                          )
                        }
                      </View>
                      <View style={styles.tableRow} wrap={false}>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Tipo de documento de identificación</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{(persona_registro && persona_registro.tipo_identificacion) ? persona_registro.tipo_identificacion.nombre : ''}</Text>
                        </View>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Número de Identificación</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{persona_registro ? persona_registro.identificacion : ''}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow} wrap={false}>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>País y Ciudad</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{persona_registro.nacionalidad ?? ''}</Text>
                        </View>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Teléfono de contacto</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{persona_registro.celular ?? persona_registro.telefono ?? ''}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow} wrap={false}>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Correo</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{persona_registro.correo ?? persona_registro.correo_personal ?? ''}</Text>
                        </View>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Institución destino/Origen</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'left' }]}>
                          <Text>Nombre: {institucion_externa?.institucion || ''}</Text>
                          <Text>Ciudad: {institucion_externa?.ciudad_vinculante || ''}</Text>
                          <Text>País: {institucion_externa?.pais_vinculante?.nombre || ''}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow} wrap={false}>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Nivel de estudios de su carrera o programa de estudios en su Institución de Origen / Tipo de dependencia a la que pertenece</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{nivel_estudio ? nivel_estudio.nombre : ''}</Text>
                        </View>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Programa de origen / Destino</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{programa_receptor ? programa_receptor.nombre : ''}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow} wrap={false}>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Facultad origen / Destino</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{facultad_receptora ? facultad_receptora.nombre : ''}</Text>
                        </View>
                        <View style={[styles.firstTableCell, { flexBasis: 95, flexGrow: 0, flexShrink: 0, textAlign: 'center' }]}>
                          <Text>Departamento origen / Destino</Text>
                        </View>
                        <View style={[styles.tableCell, { textAlign: 'center' }]}>
                          <Text>{dpto_academico_receptor ? dpto_academico_receptor.nombre : ''}</Text>
                        </View>
                      </View>
                      <View style={[styles.tableRow, { padding: 8 }]} wrap={false}>
                      </View>
                      <View style={[styles.tableRow, { textAlign: 'center' }]} wrap={false}>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Tipo de Financiación</Text>
                        </View>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Fuente de Financiación / Dependencia</Text>
                        </View>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Entidad Responsable</Text>
                        </View>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Tipo de moneda</Text>
                        </View>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Valor Unitario</Text>
                        </View>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Equivalencia en moneda extranjera</Text>
                        </View>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Clasificación de financiación</Text>
                        </View>
                        <View style={styles.firstTableCellSmall}>
                          <Text>Otro tipo de concepto</Text>
                        </View>
                      </View>
                      {
                        financiacion && financiacion.map(({ tipo_financiacion, fuente_financiacion, dependencia, entidad, valor_cop, valor_ext, tipo_moneda, clasificacion_financiacion, es_financiado, otro_tipo_concepto }, index) => {
                          return (
                            <>
                              <View key={index} style={[styles.tableRow, { textAlign: 'center' }]} wrap={false}>
                                <View style={styles.tableCellSmall}>
                                  <Text>{(es_financiado != '0' && tipo_financiacion) ? tipo_financiacion.nombre : 'N/A'}</Text>
                                </View>
                                {
                                  (es_financiado != '0' && fuente_financiacion) ?
                                    <View style={styles.tableCellSmall}>
                                      <Text>{(es_financiado != '0' && fuente_financiacion) ? fuente_financiacion.nombre : 'N/A'}</Text>
                                    </View>
                                    :
                                    <View style={styles.tableCellSmall}>
                                      <Text>{(es_financiado != '0' && dependencia) ? dependencia.nombre : 'N/A'}</Text>
                                    </View>
                                }
                                <View style={styles.tableCellSmall}>
                                  <Text>{(es_financiado != '0' && entidad) ? entidad : 'N/A'}</Text>
                                </View>
                                <View style={styles.tableCellSmall}>
                                  <Text>{es_financiado != '0' ? 'COP' : 'N/A'}</Text>
                                </View>
                                <View style={styles.tableCellSmall}>
                                  <Text>{(es_financiado != '0' && valor_cop) ? valor_cop : 'N/A'}</Text>
                                </View>
                                <View style={styles.tableCellSmall}>
                                  <Text>{(es_financiado != '0' && valor_ext && tipo_moneda) ? `${tipo_moneda} ${valor_ext}` : 'N/A'}</Text>
                                </View>
                                <View style={styles.tableCellSmall}>
                                  <Text>{(es_financiado != '0' && clasificacion_financiacion) ? clasificacion_financiacion.nombre : 'N/A'}</Text>
                                </View>
                                <View style={styles.tableCellSmall}>
                                  <Text>{(es_financiado != '0' && otro_tipo_concepto) ? otro_tipo_concepto : 'N/A'}</Text>
                                </View>
                              </View>
                            </>
                          )
                        })
                      }
                      <View style={[styles.tableRow, { padding: 8 }]} wrap={false}>
                      </View>
                    </>
                  )
                })

              )
            }
            {/* <View style={[styles.tableRow, { padding: 8 }]} wrap={false}> */}
            {/* </View> */}
            <View style={[styles.tableRow, { borderLeft: '5px solid #BA141A' }]} wrap={false}>
              <Text style={styles.titulo_tabla}>EVIDENCIA</Text>
            </View>
            <View style={styles.tableRow} wrap={false}>
              <Text style={styles.tableCell}>Enlace de Evidencias: </Text>
            </View>
            {
              adjuntos.length > 0 ? adjuntos.map(({ requisito, archivo }, index) => {
                return (
                  <View style={styles.tableRow} wrap={false}>
                    <Text key={index} style={styles.tableCell}>{`· ${requisito.nombre}  `}
                      <a href={`${api}${archivo}`} target="_blank" style={styles.link}>
                        {`${api}${archivo}`}
                      </a>
                    </Text>
                  </View>
                )
              }
              ) : <Text style={styles.tableRow}>No se han adjuntado evidencias</Text>

            }
          </View>
          <Text
            fixed
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          />
        </Page>
      </Document >
    );

    pdf(pdfDoc).toBlob().then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'RegistroMovilidad.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      no_cargando();
    });
  };

  render() {
    return (
      <></>
    );
  }
}

export default DescargarPdfRegistro;
