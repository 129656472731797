import React, { Component, forwardRef } from 'react'
import { Dialog, DialogContent, DialogActions, Grid, InputLabel, Select, MenuItem, FormControl,Checkbox,ListItemText } from '@material-ui/core';
import AppBarModal from '../../general/AppBarModal';
import { BtnForm } from '../../general/BotonesAccion';
import { consulta, obtenerValoresFiltros, transformar, generarFiltros } from '../../../global/js/funciones'
import TareasTerminadas from "./../../general/TareasTerminadas";
import emma_w from './../../../global/imagenes/emma_w.png';
import emma_s from './../../../global/imagenes/emma_s1.png';
import ListarTabla from '../../general/ListarTabla';

class MatriculaInforme extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columnas: [],
            data_informe: [],
            cargando: false,
            periodo: '',
            programa: '',
            periodos: [],
            programas: [],
            mostrar : 'filtros',
            maxWidth : 'sm',
            cargar: true,
            programasMulti: [],
            periodosMulti: [],
            programasSelect: [],
        };
    }

    resetState = () => {
      let { mostrar } = this.state

      if(mostrar==='filtros') this.setState({ data_informe: [], columnas: [], mostrar: 'filtros', periodo: '', programa: '', programasMulti: [], periodosMulti: [], cargando: false })
      else if(mostrar === 'informe') this.setState({ mostrar:'filtros', columnas: [],cargando: false, maxWidth : 'sm' })
      else if(mostrar === 'filtroDetalle') this.setState({ mostrar:'informe', cargando: false, maxWidth : 'sm' })
      else this.setState({ mostrar:'filtroDetalle', cargando: false })
   }

    async componentDidUpdate({ modalInforme }, { mostrar }) {
        let { mostrar: mostrarNew, cargar } = this.state;
        let { modalInforme: modalInformeNew, informe } = this.props;
        if (modalInforme !== modalInformeNew) {
            if (modalInformeNew && cargar) {
                this.setState({ cargando: true })
                const periodos = await this.obtenerPeriodos();
                const modalidades = [];
                const contratos = [];
                const programas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': 5 }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valorc', 'valor': 'pregrado' }]);
                
                this.setState({ periodos, cargando: false, cargar: false, 'programas': transformar(programas), 'programasSelect': programas, modalidades, contratos })
            }else this.resetState()
        }
    }

    obtenerPeriodos = () => {
        return new Promise(resolve => {
            consulta(`api/v1.0/matriculas/obtenerPeriodos`, null, null,
                (error, estado, resp) => {
                resolve(resp)
                }
            );
        })
    }

    onChange = ({ target }) => {
        let { value, name } = target
        this.setState({ [name]: value })
    }


    onSubmit = (e) => {
        let { periodo, programasMulti, periodosMulti } = this.state
        let { actualizarMensaje, informe } = this.props
        let filtros = [], titulo='';
        if(periodosMulti.length>0){
            filtros.push({ 'llave': 'informe', 'valor': informe.codigo });
            filtros.push({ 'llave': 'programa_in', 'valor': programasMulti });
            filtros.push({ 'llave': 'periodo_in', 'valor': periodosMulti });

        } else titulo = 'Por favor, seleccione el periodo.'
        if(titulo) actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
        else this.obtenerInforme(filtros)
        e.preventDefault();
    }

    obtenerInforme = async (filtros = []) => {
        let { actualizarMensaje, informe } = this.props
        let f = await generarFiltros(filtros);
        this.setState({ cargando: true });
        let ruta = informe.codigo === 'Mat_Pro_Sol_Gra' ? 'informe' : informe.codigo === 'Mat_Pro_Sol_Rein_Tran' ? 'informe_rein' : null;
        if(ruta !== null){
            consulta(`api/v1.0/matriculas/${ruta}?${f}`, null, null, (error, estado, resp) => {
                if (estado === 200) this.setState({ cargando: false, data_informe: resp, mostrar: 'informe', maxWidth: 'lg' });
                else actualizarMensaje({ titulo: 'Ha ocurrido un error, contacte con el administrador.', tipo: 'info', mostrar: true, tiempo: 6000 });}
            );
        }else{
            this.setState({ cargando: false, data_informe: [], mostrar: 'informe', maxWidth: 'lg' });
        }

    }

    pintarInforme = () => {
      let { data_informe, periodo, columnas } = this.state
      let { informe } = this.props
      
        if(data_informe.length>0){
            if(columnas.length===0){
                if (informe.codigo === 'Mat_Pro_Sol_Gra'){
                    columnas.push(
                      { title: 'Tipo de Solicitud', field: 'tipo_sol' },
                      { title: 'Solicitante', field: 'nombre_completo' },
                      { title: 'Documento Solicitante', field: 'identificacion' },
                      { title: 'Correo Solicitante', field: 'correo_personal' },
                      { title: 'Sexo', field: 'sexo_select' },
                      { title: 'Fecha de nacimiento', field: 'fecha_nacimiento' },
                      { title: 'Pais', field: 'pais' },
                      { title: 'Dirección de Residencia', field: 'direccion' },
                      { title: 'Estrato socioeconomico', field: 'estrato_select' },
                      { title: 'Programa', field: 'programa_nombre' },
                    //   { title: 'Estado', field: 'estado_solicitud.nombre' },
                      { title: 'Fecha Solicitud', field: 'fecha_creacion' },
                      { title: 'Fecha de Grado', field: 'fecha_grado' },
                      { title: 'Periodo culminado', field: 'periodo' },
                      { title: 'Opción de Grado 1', field: 'opc_grado1' },
                      { title: 'Opción de Grado 2', field: 'opc_grado2' },
                      { title: 'Tipo de grado', field: 'tipo_grado' },
                      { title: 'Egresado de otro programa', field: 'egresado_progr' },
                      { title: 'Nombre del programa', field: 'nombre_programa' },
                      { title: 'Año en que graduó', field: 'ano_graduo' },
                      { title: 'Institución Educativa', field: 'institucion_educativa' },
                      { title: 'Trabaja actualmente', field: 'trabaja_act' },
                      { title: 'Nombre Empresa', field: 'empresa_labora' },
                      { title: 'Direción Empresa', field: 'direccion' },
                      { title: 'Cargo desempeña', field: 'cargo_desempena' },
                      { title: 'Telefono Empresarial', field: 'telefono_empresarial' },
                      { title: 'Correo Empresarial', field: 'correo_empresarial' },
                      { title: 'Rango Salarial', field: 'rango_sal' },
                      { title: 'Acepto Tratamiento de Datos', field: 'acepta_trat' },
                    )
                }else if(informe.codigo === 'Mat_Pro_Sol_Rein_Tran'){
                    columnas.push(
                        { title: 'Tipo de Solicitud', field: 'tipo_sol' },
                        { title: 'Solicitante', field: 'nombre_completo' },
                        { title: 'Identificacion', field: 'identificacion' },
                        { title: 'Programa', field: 'programa_acad' },
                        { title: 'Periodo', field: 'periodo' },
                        { title: 'Fecha Solicitud', field: 'fecha_creacion' },
                        { title: 'Institución de Procedencia', field: 'institucion_procedencia' },
                        { title: 'Número de Creditos', field: 'numero_creditos' },
                        { title: 'Programa de Procedencia', field: 'programa_procedencia' },
                        { title: 'Tipo de Estudio', field: 'tipo_est' },
                        { title: 'Autorizo', field: 'autoriza' },
                        { title: '¿Cómo calificaría su experiencia en general con el servicio prestado?', field: 'primeraPregunta' },
                        { title: '¿Cuál es el aspecto o aspectos que mejoraría en su experiencia con el servicio prestado?', field: 'segundaPregunta' },
                        { title: '¿Qué tan fácil fue realizar su solicitud con Emma?', field: 'terceraPregunta' },
                        { title: '¿Tiene alguna sugerencia o comentario adicional que nos ayude a mejorar su experiencia con el servicio prestado?', field: 'cuartaPregunta' },
                    )
                }
            }
            const acciones = [];
            const titulo_informe = (periodo) ? `${informe.nombre} - ${periodo}` : informe.nombre
            return (
                <ListarTabla titulo={titulo_informe} columnas={columnas} datos={data_informe} acciones={acciones} />
            )
        }else{
            return(
                <TareasTerminadas mensaje='No se encontraron resultados' marginTop='7%' imagen={emma_s} widthImg="7%" />
            )
        }
      
    }
   
    pintar = () => {
        let { cargando, mostrar } = this.state;
        if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
        return (
            <div>
                {mostrar === 'filtros' && this.pintarFiltros()}
                {mostrar === 'informe' && this.pintarInforme()}
            </div>
        )
    }

    pintarNombresPeriodo = (selected, listado) => {
        let resp = [];

        let a = listado.filter((e) => selected.indexOf(e)!==-1)
        a.map((e)=>resp.push(e))

        return resp.join(", ")
    }

    pintarNombres = (selected, listado) => {
        let resp = [];

        let a = listado.filter(({id}) => selected.indexOf(id)!==-1)
        a.map(({nombre}) =>resp.push(nombre))

        return resp.join(", ")
    }

    pintarFiltros = () => {
        let { informe } = this.props
        let { periodo, periodos, periodosMulti, programasMulti, programasSelect } = this.state

        return(
            <>
                <Grid container spacing={3} className='margin_cont'> 
                    <InputLabel>Campos a Filtrar</InputLabel>       
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                        <FormControl className='form-control'>
                        <InputLabel htmlFor="periodosMulti">Seleccione Periodo</InputLabel>
                            <Select
                                inputProps={ { name: 'periodosMulti', id: 'periodosMulti' }}
                                multiple
                                value={periodosMulti}
                                onChange={this.onChange}
                                renderValue={selected => { return this.pintarNombresPeriodo(selected, periodosMulti)}}
                                required={true}>
                                { periodos.map(({ periodo }, i) => 
                                    <MenuItem key={i} value={periodo}>
                                        <Checkbox checked={periodosMulti.indexOf(periodo)>-1}/>
                                        <ListItemText primary={periodo} />
                                    </MenuItem>
                                ) }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12} >
                        <FormControl className='form-control'>
                        <InputLabel htmlFor="programasMulti">Seleccione programa</InputLabel>
                            <Select
                                inputProps={{ name: 'programasMulti',id: 'programasMulti' }}
                                multiple
                                value={programasMulti}
                                onChange={this.onChange}
                                renderValue={selected => { return this.pintarNombres(selected, programasSelect)}}
                                required={true}>
                                { programasSelect.map(({ id, nombre }, i) => 
                                    <MenuItem key={i} value={id}>
                                        <Checkbox checked={programasMulti.indexOf(id)>-1}/>
                                        <ListItemText primary={nombre} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid> 
               
            </>
        )
     
    }

    render() {
        let { cargando, maxWidth, mostrar } = this.state;
        let { modalInforme, mostrarModalInforme, informe } = this.props
        return (
            <Dialog fullWidth={true} maxWidth={maxWidth} open={modalInforme} onClose={() => mostrarModalInforme(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <AppBarModal titulo={'Informe: ' + informe.nombre} mostrarModal={() => mostrarModalInforme(false)} titulo_accion={  (mostrar === 'filtros') ? "Limpiar" : 'Volver'} accion={ this.resetState } />
                <DialogContent className='scroll'>
                {!cargando ? this.pintar() : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
                </DialogContent>
                <DialogActions>
                <BtnForm texto="Cerrar" callback={() => mostrarModalInforme(false)} />
                {!cargando && mostrar === 'filtros' && <BtnForm texto="GENERAR" callback={this.onSubmit} />}
                {!cargando && mostrar === 'filtroDetalle' && <BtnForm texto="FILTRAR" callback={this.obtenerPracticas} />}
                </DialogActions>
            </Dialog>
        );
    }
}

export default MatriculaInforme