// Clase que calcula y almacena los días festivos oficiales de Colombia para un año específico
class FestivosColombia {
    // Constructor de la clase
    constructor(ano = new Date().getFullYear()) {
      // Año actual o el que se pase como argumento
      this.ano = ano;
  
      // Calcula la fecha de Pascua para el año dado
      this.pascua = this.calcularFechaPascua(this.ano);
  
      // Objeto que almacenará todos los días festivos en formato {mes: {día: true}}
      this.festivos = {};
  
      // Inicializa el objeto de festivos para el año dado con los días festivos fijos
      this.festivos[ano] = {
        1: { 1: true }, // 1 de enero: Año Nuevo
        5: { 1: true }, // 1 de mayo: Día del Trabajo
        7: { 20: true }, // 20 de julio: Independencia de Colombia
        8: { 7: true }, // 7 de agosto: Batalla de Boyacá
        12: { 8: true, 25: true }, // 8 y 25 de diciembre: Inmaculada y Navidad
      };
  
      // Cálculo de los días festivos móviles según la Ley Emiliani
      this.calculaEmiliani(1, 6); // Reyes Magos (6 de enero)
      this.calculaEmiliani(3, 19); // San José (19 de marzo)
      this.calculaEmiliani(6, 29); // San Pedro y San Pablo (29 de junio)
      this.calculaEmiliani(8, 15); // Asunción de la Virgen (15 de agosto)
      this.calculaEmiliani(10, 12); // Descubrimiento de América (12 de octubre)
      this.calculaEmiliani(11, 1); // Todos los Santos (1 de noviembre)
      this.calculaEmiliani(11, 11); // Independencia de Cartagena (11 de noviembre)
  
      // Cálculo de días festivos relacionados con Pascua
      this.otrasFechasCalculadas(-3); // Jueves Santo (3 días antes de Pascua)
      this.otrasFechasCalculadas(-2); // Viernes Santo (2 días antes de Pascua)
      this.otrasFechasCalculadas(38, true); // Ascensión del Señor (38 días después de Pascua)
      this.otrasFechasCalculadas(60, true); // Corpus Christi (60 días después de Pascua)
      this.otrasFechasCalculadas(68, true); // Sagrado Corazón (68 días después de Pascua)
    }
  
    // Método para calcular la fecha de Pascua utilizando el algoritmo de Gauss modificado
    calcularFechaPascua(ano) {
      const f = Math.floor; // Función para redondear hacia abajo
      const A = ano % 19; // Año mod 19 (ciclo lunar)
      const B = f(ano / 100); // Centenas del año
      const C = ano % 100; // Años restantes después de las centenas
      const D = f(B / 4); // Años bisiestos en las centenas
      const E = B % 4; // Resto de dividir las centenas entre 4
      const F = f((B + 8) / 25); // Ajuste relacionado con el calendario
      const G = f((B - F + 1) / 3); // Ajuste adicional
      const H = (19 * A + B - D - G + 15) % 30; // Día de la luna llena eclesiástica
      const I = f(C / 4); // Años bisiestos restantes
      const K = C % 4; // Resto de dividir el año restante entre 4
      const L = (32 + 2 * E + 2 * I - H - K) % 7; // Día de la semana de Pascua
      const M = f((A + 11 * H + 22 * L) / 451); // Corrección para años específicos
      const month = f((H + L - 7 * M + 114) / 31); // Mes de Pascua
      const day = ((H + L - 7 * M + 114) % 31) + 1; // Día de Pascua
      return new Date(ano, month - 1, day); // Devuelve la fecha de Pascua como objeto Date
    }
  
    // Método para calcular días festivos sujetos a la Ley Emiliani
    calculaEmiliani(mes, dia) {
      const fecha = new Date(this.ano, mes - 1, dia); // Fecha original del festivo
      const diaSemana = fecha.getDay(); // Día de la semana (0 = domingo, 1 = lunes, ..., 6 = sábado)
      let offset = 0;
  
      // Ajusta la fecha al siguiente lunes según el día de la semana
      if (diaSemana === 0) offset = 1; // Si es domingo, mover al lunes siguiente
      else if (diaSemana >= 2) offset = 8 - diaSemana; // Si es de martes a sábado, mover al lunes siguiente
  
      fecha.setDate(fecha.getDate() + offset); // Ajusta la fecha con el offset calculado
      const mesAjustado = fecha.getMonth() + 1; // Obtiene el mes ajustado (0-indexado, por eso se suma 1)
      const diaAjustado = fecha.getDate(); // Obtiene el día ajustado
  
      // Marca el día ajustado como festivo en el objeto de festivos
      if (!this.festivos[this.ano][mesAjustado]) {
        this.festivos[this.ano][mesAjustado] = {};
      }
      this.festivos[this.ano][mesAjustado][diaAjustado] = true;
    }
  
    // Método para calcular días festivos relacionados con Pascua
    otrasFechasCalculadas(diasOffset, siguienteLunes = false) {
      const fecha = new Date(this.pascua); // Toma como base la fecha de Pascua
      fecha.setDate(fecha.getDate() + diasOffset); // Ajusta la fecha con el offset dado
  
      // Si se debe mover al siguiente lunes
      if (siguienteLunes) {
        const diaSemana = fecha.getDay(); // Obtiene el día de la semana
        if (diaSemana !== 1) { // Si no es lunes
          const offset = (8 - diaSemana) % 7; // Calcula los días hasta el próximo lunes
          fecha.setDate(fecha.getDate() + offset); // Ajusta la fecha al próximo lunes
        }
      }
  
      const mes = fecha.getMonth() + 1; // Obtiene el mes de la fecha ajustada
      const dia = fecha.getDate(); // Obtiene el día de la fecha ajustada
  
      // Marca el día calculado como festivo en el objeto de festivos
      if (!this.festivos[this.ano][mes]) {
        this.festivos[this.ano][mes] = {};
      }
      this.festivos[this.ano][mes][dia] = true;
    }
  
    // Método para obtener una lista de todos los días festivos del año como objetos Date
    obtenerListaFestivos() {
      const listaFestivos = [];
      for (const [mes, dias] of Object.entries(this.festivos[this.ano])) {
        for (const dia of Object.keys(dias)) {
          listaFestivos.push(new Date(this.ano, mes - 1, dia)); // Convierte cada festivo en un objeto Date
        }
      }
      return listaFestivos; // Devuelve la lista de festivos
    }
  }
  
  export default FestivosColombia;
  