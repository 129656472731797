import React from 'react';
import PropTypes from 'prop-types';
import trophy from "../../../global/imagenes/trophy.png";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress';
import Banner from './ui/Banner';


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        margin: '0 auto',
        width: '200px',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const RetosBanner = ({ mensaje, datos_sesion, width = "100%", marginTop = '20%', imagen = trophy, widthImg = "5%", marginBottom = '5%', cargando = false }) => {
    let nombre_programa =datos_sesion ? datos_sesion.programas.length > 0 ? datos_sesion.programas[0].relacion.nombre : 'N/A': 'N/A'
    return (
        <>
            <Grid container direction={'row'} spacing={1} style={{ width: "100%", justifyContent: 'center' }}>
                <Banner nombre={datos_sesion?.persona.primer_nombre} programa={nombre_programa}/>
            </Grid>

            <div className="text-center" style={{ width, marginBottom }}>
                <img src={imagen} alt="Tareas Terminadas" style={{ width: widthImg, minWidth: "80px", marginTop }} />
                <Typography variant="subtitle1" color="textSecondary" component="p">
                    {mensaje}.
                </Typography>
                {cargando && <Cargando />}
            </div>
        </>

    );
}

function Cargando() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LinearProgress color="secondary" />
        </div>
    );
}

//Props del componente
RetosBanner.propTypes = {
    mensaje: PropTypes.string.isRequired,

}


export default RetosBanner;