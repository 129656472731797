import React from 'react'
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Generica from "../../personas/generica/Generica";
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";

class PracticasEstadosPermisos extends React.Component {

  render() {
    let { coordinador, mostrarModalPermisoEstado, modalPermisoEstado } = this.props;
    if (coordinador && coordinador.id > 0) {
      return (
        <Dialog open={modalPermisoEstado} fullWidth={true} maxWidth='sm' onClose={mostrarModalPermisoEstado.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo='¡ Estados Asignados !' mostrarModal={mostrarModalPermisoEstado} accion={() => mostrarModalPermisoEstado(false)} titulo_accion='CERRAR' />
          <DialogContent style={{ padding: 0 }} className='scroll'>
            <Generica notificaciones={true} tipo={5} persona={coordinador} genericaRelacionId={4} titulo="¡ Nuevo Estado !" tituloInput="Estados" tituloEliminar='¿ Eliminar Estado ?' />
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Cerrar" callback={() => mostrarModalPermisoEstado(false)} />
          </DialogActions>
        </Dialog>
      );
    }
    return '';

  }

}
//Props del componente
PracticasEstadosPermisos.propTypes = {
  //variables
  coordinador: PropTypes.object.isRequired,
  modalPermisoEstado: PropTypes.bool.isRequired,
  //funciones
  mostrarModalPermisoEstado: PropTypes.func.isRequired,

}


export default PracticasEstadosPermisos;


