import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { coloresEmma } from '../../global/js/funciones';
const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: coloresEmma.secondarycolor,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white',
  },
}));

const CrearAccion = (titulo_accion, accion) => {
  const resp = accion ? <Button style={{ color: 'white' }} onClick={accion.bind(this, false)}> {titulo_accion} </Button> : '';
  return resp;
}

export default function AppBarModal({ titulo, mostrarModal, titulo_accion = "Cerrar", accion }) {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={mostrarModal.bind(this, false)} aria-label="close">
          <Icon>close</Icon>
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {titulo}
        </Typography>
        {CrearAccion(titulo_accion, accion)}
      </Toolbar>
    </AppBar>
  );
}


AppBarModal.propTypes = {
  //funciones
  titulo: PropTypes.string.isRequired,
  mostrarModal: PropTypes.func.isRequired,
  titulo_accion: PropTypes.string.isRequired,
  accion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
}
