import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Grid, Link, Paper, Button, Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// FUNCIONES GLOBALES
import { consulta, mostrarError, formulario, obtenerValores, obtenerMisDatos, generarFiltros, obtenerValoresFiltros, api, crear_form_data } from "../../global/js/funciones";
import TituloAccion from "../general/TituloAccion";
import { BtnDetalle, BtnVer } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';
import AccionesRapidas from '../general/AccionesRapidas';
import BarraProceso from "../general/BarraProceso";
import moment from 'moment';


//FUNCIONES DEL MODULO
import { mostrarModalAddValidaciones, mostrarModalDetalleValidaciones, modalAdjuntosValidacion, mostrarModalAddAdjValidacion, modalAddAdjValidacion, mostrarModalAdjuntos, modalAdjuntarVolante, mostrarAdjuntarVolante } from "../../redux/actions/actValidaciones";
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { MenuEstudiantes } from "./validaciones/MenuValidaciones";
import { Validacion } from "./validaciones/ValidacionDetalle";
import ValidacionesCrear from "./validaciones/ValidacionesCrear";
import ValidacionesActivas from "./validaciones/ValidacionesActivas";
import ValidacionesDetalle from "./validaciones/Validacion";
import TareasTerminadas from '../general/TareasTerminadas';
import ValidacionesAdjuntos from './validaciones/ValidacionesAdjuntos';
import ValidacionVolantePago from './validaciones/ValidacionVolantePago';

// IMAGENES
import email from '../../global/imagenes/email.png';
import notification from '../../global/imagenes/notification.png'
import revisar from '../../global/imagenes/revisar.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import emma_w from '../../global/imagenes/emma_w.png';
import examen_validacion from '../../global/imagenes/validacion_iconos.png';
import study from '../../global/imagenes/study.png';
import folder from "../../global/imagenes/folder.png"
import { red } from '@material-ui/core/colors';

class validacionesEstudiantes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seleccion: 'miproceso',
      asignaturas: [],
      procesos: [],
      lineaEstados: [],
      cargando: true,
      id_estudiante: 0,
      historial: [],
      procesoActual: null,
      notificaciones: [],
      anchorEl: null,
      validarVolate: false,
    }
  }

  async componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ rutas: 'validacionesEstudiantes', nombre: 'validaciones' })
    this.obtenerDatosIniciales();
  }

  async componentDidUpdate({ }, { seleccion, validacionActual }) {
    let { seleccion: seleccionNew, validacionActual: validacionActualNew } = this.state;

    if (validacionActual !== validacionActualNew && seleccionNew === 'miproceso' && validacionActualNew != 0) {

      await this.obtenerDataProcesoActual(validacionActualNew);
      await this.validarVolante(validacionActualNew);

    }
    if (seleccion !== seleccionNew) {
      if (seleccionNew === 'miproceso') this.obtenerDatosIniciales();
      else if (seleccionNew === 'historial') await this.obtenerHistorial();
    }
  }


  validarVolante = async (id) => {
    consulta(`api/v1.0/validaciones/validar_volante/${id}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          console.log("Validar volante: ", resp);
          if (resp.length > 0)  {
            this.setState({ validarVolate: true });
          }
        }
      }
    )
  }



  vista = () => {
    let { seleccion, enListado } = this.state

    switch (seleccion) {
      case 'miproceso':
        return (enListado ? this.pintarSolicitudes() : this.pintarSolicitudSeleccionada())
      case 'nuevo':
        return (this.pintarNuevo())
      case 'historial':
        return (this.pintarHistorial())
      default:
        break;
    }
  }



  pintarSolicitudes() {
    let { procesos } = this.state;
    if (procesos.length === 0) {
      return (
        <div className="pointer" onClick={() => this.setState({ seleccion: "nuevo" })}>
          <TareasTerminadas
            mensaje="No tienes solicitudes activas, clic aquí para hacer una solicitud de validación"
            marginTop="7%"
            imagen={emma_s}
            widthImg="7%"
          />
        </div>
      );
    }



    const handleClick = (validacion) => {
      this.setState({ enListado: false, validacionActual: validacion.id });
    };


    return (
      <div style={{ padding: '2% 2% 0% 2%' }}>
        <Grid container direction={'row'} spacing={1}>
          <TituloAccion principal="" alterno="¡ Estas son tus solicitudes de validaciones para este periodo !" />
          {procesos.map((validacion, i) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <Paper elevation={1} onClick={() => handleClick(validacion)} className="pointer">
                  <ValidacionesActivas
                    nombre={'Validaciones'}
                    fecha={validacion.fecha_registro}
                    descripcion={`Tu solicitud de Validaciones para se encuentra en estado ${validacion.estado_actual.nombre}. Clic aquí para obtener mas información.`}
                    estado={validacion.estado_actual}
                    imagen={`${api}${validacion.estado_actual.archivo}`}
                  />
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  pintarSolicitudSeleccionada() {
    let { mostrarModalDetalleValidaciones, mostrarModalAdjuntos, mostrarAdjuntarVolante } = this.props
    let { procesoActual, validarVolate } = this.state;

    const ejecutarAccionDetalle = vistaDetalle => {
      this.setState({ vistaDetalle })
      mostrarModalDetalleValidaciones(true);
    }

    const ejecutarAccionAdjuntos = () => {
      mostrarModalAdjuntos(true);
    }

    const ejecutarAdjunarVolante = () => {
      mostrarAdjuntarVolante(true)
    }


    if (procesoActual) {
      return (
        <div className='' >
          <Button onClick={() => this.setState({ enListado: true, validacionActual: 0 })} style={{ padding: "0px 3px 0px 3px", marginTop: '1%' }} color="secondary" > <ArrowBackIcon /> <Typography variant="body2" style={{ color: '#9e9e9e' }} component="p" align='justify'> Atras </Typography> </Button>
          <div style={{ padding: '0% 2% 0% 2%' }} >
            <Grid container direction={'row'} spacing={1} >
              <Grid item xs={12} sm={12} md={6} >
                <TituloAccion principal='' alterno='¡ Información Básica !' />
                <Paper elevation={0} square className="scroll">
                  <Validacion validacion={procesoActual} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
                <Grid container direction={'row'} spacing={2} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={12} md={12} >
                    <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccionDetalle('detalle')}>
                      <AccionesRapidas imagen={revisar} titulo='¡ Más Información !' sub_titulo='Bien' des_sub_titulo=', Aquí puedes ver toda la información actual.' />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAccionAdjuntos()}>
                      <AccionesRapidas imagen={study} titulo='¡ Mis Documentos !' sub_titulo='Listo' des_sub_titulo=', Aquí puedes administrar tus documentos.' />
                    </Paper>
                  </Grid>

                  {procesoActual && procesoActual.estado_actual.codigo === 'Val_Adm' && validarVolate !== true &&
                    <Grid item xs={12} sm={12} md={12} >
                      <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => ejecutarAdjunarVolante()}>
                        <AccionesRapidas imagen={folder} titulo='¡ Documentos Pendientes !' sub_titulo='Listo' des_sub_titulo=', Aquí puedes cargar tu volante de pago cancelado.' />
                      </Paper>
                    </Grid>
                  }


                  {procesoActual && procesoActual.volante ?
                    <Grid item xs={12} sm={12} md={12} >
                      <Link href={`${api}${procesoActual.volante}`} target="_blank" underline='none'>
                        <Paper elevation={3} style={{ border: '1px solid #e0e0e0' }} onClick={() => { }}>
                          <AccionesRapidas imagen={email} titulo='¡ Mi Volante !' sub_titulo='Felicidades' des_sub_titulo=', Aqui puedes ver y descargar tu volante.' />
                        </Paper>
                      </Link>
                    </Grid>
                    : ''}
                </Grid>
              </Grid>
              {
                procesoActual.estado_actual.codigo !== 'Val_Neg' && procesoActual.estado_actual.codigo !== 'Val_Can' && procesoActual.estado_actual.codigo !== 'Val_Con' &&
                <Grid item xs={12} sm={12} md={12} >
                  <TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
                  {this.lineaDeEstados()}
                </Grid>
              }
            </Grid>
          </div >
        </div>
      )
    }
  }

  async obtenerValidaciones(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/validaciones/listar/0?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : []);
      })
    })
  }

  async obtenerDatosIniciales() {
    this.setState({ cargando: true, enListado: true, })
    let periodo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Per_Val' }]);
    let procesos = await this.obtenerValidaciones([]);
    await this.obtenerNotificacines()
    this.setState({ periodo: periodo.length > 0 ? periodo[0].nombre : '', procesos, cargando: false })
  }

  obtenerNotificacines = () => {
    consulta(`api/v1.0/validaciones/notificaciones`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          this.notificacionRequistosPendientes(resp);
        }
      }
    )
  }

  async notificacionRequistosPendientes(validacionesAprobadas) {
    let notificaciones = []
    let { mostrarAdjuntarVolante } = this.props
    const accionesSolicitudes = (data) => {
      const onClickAbrir = ({ id }) => {
        this.setState({ enListado: false, validacionActual: id, anchorEl: null });
      }
      return <BtnVer callback={() => onClickAbrir(data)} texto='Ver' />;
    }

    validacionesAprobadas.map((data) => {
      let notificacion = {
        icono: notification,
        titulo: `¡ Documento Pendiente !`,
        mensaje: `Ya puedes subir el volante cancelado `,
        tipo: 'mensaje',
        acciones: () => accionesSolicitudes(data),
      }
      notificaciones.push(notificacion)
    })

    this.setState({ notificaciones });
  }



  async obtenerDataProcesoActual(id = 0) {
    this.setState({ cargando: true })
    let data = await this.obtenerValidacion(id);
    if (data) this.setState({ lineaEstados: data.linea, procesoActual: data.validacion, id_estudiante: data.validacion.usuario_registro.id, });
    this.setState({ cargando: false });
  }

  async obtenerValidacion(id = 0) {
    return new Promise(resolve => {
      consulta(`api/v1.0/validaciones/${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async obtenerHistorial() {
    this.setState({ cargando: true })
    let historial = await this.obtenerValidaciones();
    this.setState({ historial, cargando: false })
  }


  guardarValidaciones = async (archivos, datos, callback) => {
    let { actualizarMensaje, mostrarModalAddValidaciones } = this.props
    let data = await crear_form_data(datos);
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo)
      data.append(`codigo${index}`, codigo)
      return true;
    })
    formulario(`api/v1.0/validaciones/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddValidaciones(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      callback();
    })
  }

  guardarVolantePagado = async (id, total, archivos, callback) => {
    let { actualizarMensaje, mostrarAdjuntarVolante } = this.props
    let data = await crear_form_data({ total });
    archivos.map(({ archivo, codigo }, index) => {
      data.append(`archivo${index}`, archivo)
      data.append(`codigo${index}`, codigo)
      return true;
    })
    formulario(`api/v1.0/validaciones/volanteadjuntos/${id}`, data, "post", async (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarAdjuntarVolante(false);
          await this.validarVolante(id);
          await this.obtenerDataProcesoActual(id);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      callback();
    })
  }

  cambiarOpcion = (seleccion) => {
    this.setState({ seleccion })
  }

  guardarValidaciones = async (datos, callback) => {
    let { actualizarMensaje, mostrarModalAddValidaciones } = this.props
    let data = await crear_form_data(datos);
    formulario(`api/v1.0/validaciones/crear`, data, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarModalAddValidaciones(false);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
      callback();
    })
  }


  pintarNuevo = () => {
    let { mostrarModalAddValidaciones } = this.props
    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 40 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: '2% 2% 0% 2%' }} onClick={() => { mostrarModalAddValidaciones(true) }}>
          <Paper elevation={8} square>
            <Card style={{ maxWidth: "100%", height: '380px', cursor: 'pointer' }} elevation={1}>
              <CardHeader
                avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>N</Avatar>}
                title={`Nueva Solicitud`}
              />
              <CardContent >
                <Grid container direction={'row'} justifyContent={'center'}>
                  <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                    <img src={examen_validacion} style={{ width: '150px', maxWidth: '200px', minWidth: '100px', height: "150px" }} alt='Imagen Tipo' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ padding: "15px 10px 0px 10px", }}>
                    <Typography variant="body2" color="textSecondary" component="p" align='justify'>
                      Para iniciar una solicitud de validación haz clic aqui.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  pintarHistorial = () => {
    let { historial } = this.state;
    let { mostrarModalDetalleValidaciones } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Val_Rec': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
        'Val_Rev': { 'backgroundColor': '#ffc107', color },
        'Val_Apr': { 'backgroundColor': '#01519B', color },
        'Val_Adm': { 'backgroundColor': '#18F270', color },
        'Val_Pag': { 'backgroundColor': '#A90AE2', color },
        'Val_Env': { 'backgroundColor': '#009688', color },
        'Val_Asi': { 'backgroundColor': '#636060', color },
        'Val_Not': { 'backgroundColor': '#BB0061', color },
        'Val_Aex': { 'backgroundColor': '#FF5551', color },
        'Val_Ecr': { 'backgroundColor': '#47408F', color },
        'Val_Rep': { 'backgroundColor': '#4A235A', color },
        'Val_Neg': { 'backgroundColor': '#b71c1c', color },
        'Val_Can': { 'backgroundColor': '#b71c1c', color },
        'Val_Con': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      const onClickDetalle = ({ id, usuario_registro }) => {
        this.setState({ validacionActual: id, id_estudiante: usuario_registro.id })
        mostrarModalDetalleValidaciones(true)
      }
      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      return detalle;
    }

    return (
      <div>
        <ListarDatos
          titulo='Lista de Validaciones'
          datos={historial}
          buscar={true}
          opciones={true}
          sub_titulo="Lista de adjuntos"
          avatar={({ estado_actual }) => estado_actual.nombre.charAt(0).toUpperCase()}
          color_avatar={({ estado_actual: { codigo } }) => obtenerColor(codigo)}
          acciones={(row) => acciones(row)}
          filas={[
            { 'id': 'tipo_estado', 'enLista': true, 'mostrar': ({ estado_actual }) => estado_actual.nombre.toUpperCase() },
            { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), nombre: 'Fecha Registro: ' }
          ]}
        />
      </div>
    )
  }

  lineaDeEstados = () => {
    let { cargando, lineaEstados, procesoActual } = this.state;
    if (!cargando && procesoActual && lineaEstados.length > 0) {
      let activeStep = 0;
      let steps = [];
      lineaEstados.map(({ secundario }, i) => {
        if (secundario.id === procesoActual.estado_actual.id) activeStep = i;
        steps.push({ label: secundario.nombre })
      })
      activeStep = procesoActual.estado_actual.codigo === 'Val_Not' ? lineaEstados.length + 1 : activeStep;
      return <BarraProceso steps={steps} activeStep={activeStep} />;
    }
    return null;
  }

  render() {
    let { modalAddValidaciones, mostrarModalAddValidaciones, modalDetValidaciones, mostrarModalDetalleValidaciones, mostrarModalAdjuntos, modalAdjuntosValidacion, modalAdjuntarVolante, mostrarAdjuntarVolante, actualizarMensaje } = this.props
    let { seleccion, cargando, asignaturas, mensaje, id_estudiante, validacionActual, procesoActual, notificaciones, anchorEl } = this.state
    return (
      <div>
        <MenuEstudiantes notificaciones={notificaciones} anchorEl={anchorEl} setanchorEl={(valor) => this.setState({ 'anchorEl': valor })} sele={seleccion} seleccionar={(seleccion) => this.cambiarOpcion(seleccion)} />
        <div className="contenido_modulo">
          <ValidacionesCrear
            guardarValidaciones={this.guardarValidaciones}
            actualizarMensaje={actualizarMensaje}
            mostrarModalAddValidaciones={mostrarModalAddValidaciones}
            modalAddValidaciones={modalAddValidaciones}
            asignaturas={asignaturas}
            mensaje={mensaje}
          />
          <ValidacionesAdjuntos
            mostrarModalAdjuntos={mostrarModalAdjuntos}
            modalAdjuntosValidacion={modalAdjuntosValidacion}
            validacion={validacionActual}
          />
          <ValidacionVolantePago
            mostrarAdjuntarVolante={mostrarAdjuntarVolante}
            modalAdjuntarVolante={modalAdjuntarVolante}
            validacion={procesoActual}
            guardarVolantePagado={this.guardarVolantePagado}
          />
          <ValidacionesDetalle
            id_estudiante={id_estudiante}
            mostrarModalDetalleValidaciones={mostrarModalDetalleValidaciones}
            modalDetValidaciones={modalDetValidaciones}
            vistaDetalle='detalle'
            admin={false}
            validacionActual={validacionActual}
          />
          {
            !cargando ? this.vista() :
              <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  let { modalAddValidaciones, modalDetValidaciones, modalAdjuntosValidacion, modalAddAdjValidacion, modalAdjuntarVolante } = state.redValidaciones
  return {
    modalAddValidaciones,
    modalDetValidaciones,
    modalAdjuntosValidacion,
    modalAddAdjValidacion,
    modalAdjuntarVolante
  }
}

const mapDispatchToProps = {
  mostrarModalAddValidaciones,
  mostrarModulo,
  actualizarMensaje,
  mostrarModalDetalleValidaciones,
  modalAdjuntosValidacion,
  mostrarModalAddAdjValidacion,
  mostrarModalAdjuntos,
  mostrarAdjuntarVolante
}

validacionesEstudiantes.propTypes = {
  // Funciones
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModulo: PropTypes.func.isRequired,
  mostrarModalAddValidaciones: PropTypes.func.isRequired,
  mostrarModalDetalleValidaciones: PropTypes.func.isRequired,
  mostrarModalAdjuntos: PropTypes.func.isRequired,
  mostrarModalAddAdjValidacion: PropTypes.func.isRequired,
  mostrarAdjuntarVolante: PropTypes.func.isRequired,

  // Variables
  modalAdjuntosValidacion: PropTypes.bool.isRequired,
  modalAddValidaciones: PropTypes.bool.isRequired,
  modalDetValidaciones: PropTypes.bool.isRequired,
  modalAddAdjValidacion: PropTypes.bool.isRequired,
  modalAdjuntarVolante: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(validacionesEstudiantes)