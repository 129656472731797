import {
  MODAL_RESPUESTAS,
  MODAL_FILTRO,
  MODAL_GESTION,
  MODAL_AGREGAR
} from "../actions/actAutodiagnostico";

const initialState = {
  modalRespuestas: false,
  modalFiltros: false,
  modalGestionAutodiag: false,
  modalAgregarAutodiag: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_RESPUESTAS:
      return Object.assign({}, state, {
        modalRespuestas: action.show
      })
    case MODAL_FILTRO:
      return Object.assign({}, state, {
        modalFiltros: action.show
      })
    case MODAL_GESTION:
      return Object.assign({}, state, {
        modalGestionAutodiag: action.show
      })
    case MODAL_AGREGAR:
      return Object.assign({}, state, {
        modalAgregarAutodiag: action.show
      })
    default:
      return state;
  }
}

export default reducer;