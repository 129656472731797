import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from '@material-ui/core/Button';
import Select from "@material-ui/core/Select";
import { List, ListItemText, Link, ListItem } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TareasTerminadas from "../general/TareasTerminadas";
import { obtenerValoresFiltros } from "../../global/js/funciones";
import AppBarModal from "../general/AppBarModal";
import { BtnForm } from "../general/BotonesAccion";
import moment from "moment";
import emma_w from "../../global/imagenes/emma_w.png";
import { TextField } from "@material-ui/core";
import InputFile from "./../general/InputFile";
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import plantilla from "../../global/archivos/plantillas/reporte_icfes.xlsx";

class ReportesFiltrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
      periodo: "",
      fecha_inicio: null,
      fecha_fin: null,
      cargar: true,
      periodos: [],
      modalidad: "",
      identificacion: "",
      unidadU:"",
      adj_icfes: '',

    };
    this.fileInput = React.createRef();
  }



  async componentDidUpdate({ modalFiltro }) {
    let { modalFiltro: modalFiltroNew } = this.props;
    if (modalFiltro !== modalFiltroNew && this.state.cargar) {
      this.setState({ cargando: true });
      let periodos = await obtenerValoresFiltros([
        { llave: "generica", valor: 57 },
        { llave: "estado", valor: 1 },
      ]);
      this.setState({
        periodos,
        cargando: false,
        cargar: false,
      });
    }
  }



  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  setFechaInicio = (fecha_inicio) => {
    this.setState({
      fecha_inicio,
    });
  };
  setFechaFin = (fecha_fin) => {
    this.setState({
      fecha_fin,
    });
  };

  resetState = () => {
    let { mostrarModalFiltro } = this.props;
    this.setState({
      periodo: "",
      fecha_inicio: null,
      fecha_fin: null,
      modalidad: "",
      identificacion: "",
      adj_icfes: ""
    });
    mostrarModalFiltro(false);
  };

  onSubmit = (e) => {
    let { setFiltros, mostrarModalReporte, actualizarMensaje, tipoReporte, codigo_gruporeporte } =
      this.props;
    let { periodo, fecha_inicio, fecha_fin, modalidad, identificacion, unidadU, adj_icfes } = this.state;

  //   let titulo = ''
  //   if(codigo_gruporeporte == 'grupo_admitido'){
  //     if(!periodo) titulo = 'Ingrese el periodo'
  //   }else if(codigo_gruporeporte == 'grupo_carga'){
  //     if(!periodo) titulo = 'Ingrese el periodo'
  //   }else if(codigo_gruporeporte == 'grupo_historico_notas'){
  //     if(!periodo) titulo = 'Ingrese el periodo'
  //   }else if(tipoReporte.codigo == 'rep_ac_icfes'){
  //     if(!identificacion) titulo = 'Ingrese la identificación'
  //   }else if(codigo_gruporeporte == 'grupo_inactivos'){
  //     if(!periodo) titulo = 'Ingrese el periodo'
      
  //     if(!modalidad) titulo= "Ingrese la modalidad"
  //   }else if(codigo_gruporeporte == 'grupo_matriculados' ){
  //     if(!periodo) titulo = 'Ingrese el periodo'
  //   }else if(codigo_gruporeporte == 'grupo_creditos'){
  //     if(!periodo) titulo= 'Ingrese el periodo'
  //     else if(!modalidad) titulo = 'Ingrese la modalidad'
  //   }else if(codigo_gruporeporte=='grupo_activos'){
  //     if(!periodo) titulo= "Ingrese el periodo"
  //     else if(!modalidad) titulo= "Ingrese la modalidad"
  //   }else if (tipoReporte.codigo=='rep_canceladas' || 'rep_no_aprobadas' ){
  //     if(!periodo) titulo= "Ingrese el periodo"
  //   }else if (tipoReporte.codigo=='rep_tomadas'){
  //   if(!periodo) titulo= "Ingrese el periodo"
  //   if(!modalidad) titulo= "Ingrese la modalidad"
  // }
  let titulo = '';
const GRUPO_ADMITIDO = 'grupo_admitido';
const GRUPO_CARGA = 'grupo_carga';
const GRUPO_HISTORICO_NOTAS = 'grupo_historico_notas';
const GRUPO_INACTIVOS = 'grupo_inactivos';
const GRUPO_MATRICULADOS = 'grupo_matriculados';
const GRUPO_CREDITOS = 'grupo_creditos';
const GRUPO_ACTIVOS = 'grupo_activos';
const REP_AC_ICFES = 'rep_ac_icfes';
const REP_CANCELADAS = 'rep_canceladas';
const REP_NO_APROBADAS = 'rep_no_aprobadas';
const REP_TOMADAS = 'rep_tomadas';
const REP_SEMAFORO ='rep_semaforo';
const REP_PROMEDIO = 'reporte_promedio_acumulado';
const REP_MAT_FINAN_PREGRADO = 'rep_mat_finan_pregrado';
const REP_MAT_INGLES = 'rep_mat_ingles';
const GRUPO_HEP = 'grupo_hep';
const REP_HISTORIA_ACADEMICA = 'rep_historia_academica';
const REP_PAG_DERECHO ='rep_pag';

if(codigo_gruporeporte === GRUPO_ADMITIDO || codigo_gruporeporte === GRUPO_CARGA || codigo_gruporeporte === GRUPO_HISTORICO_NOTAS) {
  if(!periodo) titulo = 'INGRESE EL PERIODO';
} else if(tipoReporte.codigo === REP_AC_ICFES) {
  if(!adj_icfes) titulo = 'CARGUE LA PLANTILLA';
} else if(codigo_gruporeporte === GRUPO_INACTIVOS) {
  if (codigo_gruporeporte === GRUPO_INACTIVOS) {
    if (!periodo || !periodo.toUpperCase()) {
      titulo = 'INGRESE EL PERIODO';
    } else {
      periodo = periodo.toUpperCase();
    }
    if (!modalidad) {
      titulo = 'INGRESE LA MODALIDAD';
    }
  }
  
} else if(codigo_gruporeporte === GRUPO_MATRICULADOS) {
  if(!periodo) titulo = 'INGRESE EL PERIODO';
} else if(codigo_gruporeporte === GRUPO_CREDITOS) {
  if(!periodo) titulo = 'INGRESE EL PERIODO';
  else if(!modalidad) titulo = 'INGRESE LA MODALIDAD';
} else if(codigo_gruporeporte === GRUPO_ACTIVOS) {
  if(!periodo) titulo = 'INGRESE EL PERIODO';
  else if(!modalidad) titulo = 'INGRESE LA MODALIDAD';
} else if (tipoReporte.codigo === REP_CANCELADAS || tipoReporte.codigo === REP_NO_APROBADAS) {
  if(!periodo) titulo = 'INGRESE EL PERIODO';
} else if (tipoReporte.codigo === REP_TOMADAS) {
  if(!periodo) titulo = 'INGRESE EL PERIODO';
  if(!modalidad) titulo = 'INGRESE LA MODALIDAD';
} else if(tipoReporte.codigo===REP_SEMAFORO) {
  if(!periodo) titulo = 'INGRESE EL PERIODO';
} else if(tipoReporte.codigo===REP_PROMEDIO) {
  if (!periodo || !periodo.toUpperCase()) {
      titulo = 'INGRESE EL PERIODO';
      periodo = periodo.toUpperCase();
    } else {
      if (!modalidad) {
        titulo = 'INGRESE LA MODALIDAD';
    }
    
    }
  } else if(tipoReporte.codigo===REP_HISTORIA_ACADEMICA) {
    if (!periodo || !periodo.toUpperCase()) {
        titulo = 'INGRESE EL PERIODO';
        periodo = periodo.toUpperCase();
      } else {
        if (!modalidad) {
          titulo = 'INGRESE LA MODALIDAD';
      }
      
      }
    } else if(codigo_gruporeporte === GRUPO_HEP) {
      if(!periodo) titulo = 'INGRESE EL PERIODO'; 
    } else if (tipoReporte.codigo=== REP_MAT_FINAN_PREGRADO){
      if (!periodo || !/^[0-9]{5}$/.test(periodo) || parseInt(periodo) < 20051 || parseInt(periodo) > 20232 || /[3-9]$/.test(periodo) || !/[0-4,6-9]/.test(periodo)) {
        titulo = 'INGRESE UN PERIODO VÁLIDO (FORMATO: AAAAS) Y DEBE ESTAR ENTRE 20051 - 20232, EXCEPTO PERIODOS TERMINADOS EN 3-9 Y QUE CONTENGAN EL CARÁCTER 5';
      } else {
        periodo = periodo.toUpperCase();
        if (!unidadU) {
          titulo = 'INGRESE LA UNIDAD';
        }
      }
      } else if (tipoReporte.codigo=== REP_MAT_INGLES){
        if (!periodo || !periodo.toUpperCase()) {
          titulo = 'INGRESE EL PERIODO';
          periodo = periodo.toUpperCase();
        } else {
          if (!modalidad) {
            titulo = 'INGRESE LA MODALIDAD';
          }
        }
        
    }else if(tipoReporte.codigo===REP_PAG_DERECHO) {
}else if(tipoReporte.codigo===REP_PAG_DERECHO) {
  if(!fecha_inicio) titulo = 'INGRESE FECHA INICIAL';
}




    // if (!fecha_inicio && tipoReporte.valorb)
    //   actualizarMensaje({
    //     titulo: "Seleccione la fecha Inicial.",
    //     tipo: "info",
    //     mostrar: true,
    //     tiempo: 6000,
    //   });
    // else if (!periodo && !fecha_inicio )
    //   actualizarMensaje({
    //     titulo: "Escribe el Periodo.", // se cambio el seleccione por escribe 
    //     tipo: "info",
    //     mostrar: true,
    //     tiempo: 6000,
    //   });
    // else if (!modalidad && tipoReporte.valorc === "grupo_matriculados")
    //   actualizarMensaje({
    //     titulo: "Seleccione la Modalidad.",
    //     tipo: "info",
    //     mostrar: true,
    //     tiempo: 6000,
    //   });
    // else if (!modalidad && tipoReporte.codigo === "rep_matriculados")
    //   actualizarMensaje({
    //     titulo: "Seleccione la Modalidad.",
    //     tipo: "info",
    //     mostrar: true,
    //     tiempo: 6000,
    //   });
    // else if (!identificacion && tipoReporte.codigo === "rep_ac_icfes" && periodo ==="")
    //   actualizarMensaje({
    //     titulo: "Digite el numero de identificacion del estudiante.",
    //     tipo: "info",
    //     mostrar: true,
    //     tiempo: 6000,
    //   });
    // else {
    //   fecha_inicio = fecha_inicio
    //     ? moment(fecha_inicio).format("DD/MM/YYYY")
    //     : null;
    //   fecha_fin = fecha_fin ? moment(fecha_fin).format("DD/MM/YYYY") : null;

    // }
  
  
  

    if(titulo){
      actualizarMensaje({titulo ,tipo: "info",
            mostrar: true,
            tiempo: 6000,
          });
    }else{
      let unidad=unidadU.toUpperCase()
      fecha_inicio = fecha_inicio
        ? moment(fecha_inicio).format("DD/MM/YYYY")
        : null;
      fecha_fin = fecha_fin ? moment(fecha_fin).format("DD/MM/YYYY") : null;
      if(adj_icfes) adj_icfes = document.getElementById('adj_icfes').files[0]
      let data = { periodo, fecha_inicio, fecha_fin, modalidad, identificacion, unidad, adj_icfes };
      setFiltros(data);
      mostrarModalReporte(true);
      this.resetState();
    }
    e.preventDefault();
  };

  render() {
    let { modalFiltro, mostrarModalFiltro, tipoReporte, codigo_gruporeporte } = this.props;
    let { periodo, fecha_inicio, fecha_fin, periodos, tipo, modalidad, cargando, unidadU, adj_icfes } =
      this.state;

    return (
      <Dialog
        open={modalFiltro}
        onClose={() => mostrarModalFiltro(false)}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <AppBarModal
          titulo="! Aplicar Filtros ¡"
          mostrarModal={mostrarModalFiltro}
          titulo_accion=""
          accion=""
        />
        <DialogContent className="scroll">
          {cargando ? (
            <TareasTerminadas
              mensaje="Cargando.."
              marginTop="7%"
              imagen={emma_w}
              widthImg="7%"
              cargando={true}
            />
          ) : (
            <Grid container spacing={3} className="margin_cont">
              <Grid item xs={12} className='oculto'>
                <TextField
                  ref={this.fileInput}
                  required
                  type="file"
                  id="adj_icfes"
                  name="adj_icfes"
                  label="Adjuntar Plantilla"
                  fullWidth
                  autoComplete="billing address-line1"
                  onChange={this.onChange}
                />
              </Grid>


              {!tipoReporte.valorb && tipoReporte.codigo !== "rep_ac_icfes" &&  (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    {/* <Select
                      inputProps={{
                        name: "periodo",
                        id: "periodo",
                      }}
                      value={periodo}
                      onChange={this.onChange}
                      required={true}
                    >
                      {periodos.map(({ id, nombre }) => (
                        <MenuItem key={id} value={nombre}>
                          {nombre}
                        </MenuItem>
                      ))}
                    </Select>  */}
                    
                      <TextField
                        label="Periodo"
                        id="periodo"
                        name="periodo"
                        value={periodo.toUpperCase()}
                        onChange={this.onChange}
                        required={true}
                        

                      />
                  </FormControl>
                </Grid>
              )}
              {tipoReporte.codigo == "rep_pag" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      label="Fecha Inicio"
                      format="dd-MM-yyyy"
                      value={fecha_inicio}
                      onChange={this.setFechaInicio}
                      style={{ width: "100%" }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      invalidDateMessage="Formato Invalido"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
              {/* {tipoReporte.codigo == "rep_ingles" &&(
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      <MenuItem key={3} value={"3"}>
                        EDUCACIÓN CONTINUADA
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              )} */}

              

              {tipoReporte.codigo === "rep_inactivos" &&  (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                        
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                      

                      
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      
                    </Select>
                  </FormControl>
                </Grid>
                
                 )}

                {tipoReporte.codigo === "reporte_promedio_acumulado" &&  (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                 )}

            {tipoReporte.codigo === "rep_historia_academica" &&  (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                 )}
                 {tipoReporte.codigo === "rep_hist_notas" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem> 
                    </Select>
                  </FormControl>
                </Grid>
                 )}
                 
                 {tipoReporte.codigo === "rep_activos" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      
                    </Select>
                  </FormControl>
                </Grid>
                 )}
                 {tipoReporte.codigo === "rep_tomadas" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      
                    </Select>
                  </FormControl>
                </Grid>
                 )}

          {tipoReporte.codigo === "rep_definitivas" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      
                    </Select>
                  </FormControl>
                </Grid>
                 )}

              {tipoReporte.codigo === "rep_semaforo" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      
                    </Select>
                  </FormControl>
                </Grid>
                 )}
                   
              {tipoReporte.codigo === "rep_ac_icfes" && (
                  <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                    <Grid container >
                      <Grid item xs={12} md={12} className={`alert_info_titulo`}>
                        <Button variant="text" size="small" color="inherit" >
                          <NotificationImportantIcon style={{ height: 15 }} /> Nota</Button>
                      </Grid>
                      <Grid item xs={12} md={12} className={`alert_info`}>
                        <Grid>
                          Tenga en cuenta las siguientes indicaciones:
                          <List dense={true}>
                            <ListItem>
                              <ListItemText primary={<Link href={plantilla} target="_blank" underline='none'>Descargue esta plantilla y anexe los datos.</Link>} />
                            </ListItem>
                            <ListItem>
                              <ListItemText primary='No realice cambios en el formato de la plantilla.' />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                    <InputFile label='Adjuntar Plantilla' id='adj_icfes' value={adj_icfes} required={true} />
                  </Grid>
              )}

              {tipoReporte.codigo === "rep_mat_finan_pregrado" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                  
                    <TextField
                      label="unidad"
                      id="unidadU"
                      name="unidadU"
                      value={unidadU}
                      onChange={this.onChange}
                      required={true}

                    />


                  </FormControl>
                </Grid>
              
              )}

      

              {tipoReporte.codigo ==  "rep_matriculados" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      <MenuItem key={3} value={"3"}>
                        INGLES
                      </MenuItem>
                      <MenuItem key={4} value={"4"}>
                        CURSOS LIBRES
                      </MenuItem>
                      <MenuItem key={5} value={"5"}>
                        DIPLOMADO
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                
                
              )}

        {tipoReporte.codigo ===  "rep_matricula_academica" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      <MenuItem key={3} value={"3"}>
                        MAESTRIAS
                      </MenuItem>
                      <MenuItem key={4} value={"4"}>
                        INTERSEMESTRALES
                      </MenuItem>
                    
                    </Select>
                  </FormControl>
                </Grid>

                
                
              )}

        {tipoReporte.codigo ===  "rep_mat_ingles" && (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                      <MenuItem key={3} value={"3"}>
                        INGLES
                      </MenuItem>
                    
                    </Select>
                  </FormControl>
                </Grid>

                
                
              )}

                
            
     {tipoReporte.codigo ==  "rep_creditos_cursados" || tipoReporte.codigo ==  "rep_creditos_aprobados"  ? (
                <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                  <FormControl className="form-control">
                    <InputLabel htmlFor="modalidad">
                      Seleccione la Modalidad
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "modalidad",
                        id: "modalidad",
                      }}
                      value={modalidad}
                      onChange={this.onChange}
                      required={true}
                    >
                      <MenuItem key={1} value={"1"}>
                        PREGRADO
                      </MenuItem>
                      <MenuItem key={2} value={"2"}>
                        POSGRADO
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
   ) : ''}               
              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  minDate={fecha_inicio}
                  id="date-picker-dialog"
                  label="Fecha Fin"
                  format="dd-MM-yyyy"
                  value={fecha_fin}
                  onChange={this.setFechaFin}
                  style={{ width: '100%' }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDateMessage={`La fecha fin no puede ser menor que la fecha inicio.`}
                  invalidDateMessage='Formato Invalido'
                />
              </MuiPickersUtilsProvider>
            </Grid> */}


            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <BtnForm
            texto="CANCELAR"
            callback={() => mostrarModalFiltro(false)}
          />
          {!cargando && <BtnForm texto="CONSULTAR" callback={this.onSubmit} />}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReportesFiltrar;
