import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, DialogActions, Grid, Dialog, DialogContent, ListSubheader, List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { BtnForm } from "../../general/BotonesAccion";
import AppBarModal from '../../general/AppBarModal';
import { obtenerEstadosProceso } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import { MenuGestionar } from "./TutoriaMenu";
import{Id_secundario_generica}from'../helper';
import { coloresEmma } from '../../../global/js/funciones';


const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));
  
  function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
    const classes = useStyles();
    //console.log(estados_siguiente)
    return (
      <List className={classes.root} subheader={
        <ListSubheader component="div" >
          Seleccione Estado
        </ListSubheader>
      }>
        {estados_siguiente.map(({ secundario }) => {
          let { codigo, nombre, valore } = secundario;
          return (
            <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion({codigo, valore})} >
              <ListItemIcon>
                <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText id={codigo} primary={nombre} />
            </ListItem>
          );
        })}
      </List>
    );
  }

class TutoriasGestionar extends Component {

    constructor(props) {
      super(props)
      this.state = {
        cargando: true,
        titulo_res: 'Cargando..',
        estado: {codigo: '', valore: ''},
        estados_siguiente: [],
        seleccion: 'gestion',
        porcentaje : ''
      }
    }
  
    async componentDidUpdate({ modalGestionTutoria }, { estado }) {
      let { modalGestionTutoria: modalGestionTutoriaNew, tutoria } = this.props;
      //console.log(tutoria)
		  let { estado: estadoNew } = this.state;
      if (estadoNew.codigo !== estado.codigo);      
      if (modalGestionTutoria !== modalGestionTutoriaNew) {
        if (modalGestionTutoriaNew) {
          this.reiniciarEstado();
          let estados_siguiente = await obtenerEstadosProceso(tutoria.tipo_tutoria.id,[{ 'llave': 'principal', 'valor': tutoria.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': Id_secundario_generica }]);
          this.setState({ estados_siguiente, cargando: false }
          );
        }
      }
    }
  
    reiniciarEstado() {
      this.setState({
        cargando: true,
        titulo_res: 'Cargando..',
        estado: {codigo: '', valore: ''},
        motivo: '',
        estados_siguiente: [],
        seleccion: 'gestion',
        porcentaje : ''
      })
    }
  
    onChange = ({ target }) => {
      let { value, name } = target;
      this.setState({
        [name]: value
      })
    }


  
    onSubmit = e => {
        let { estado, motivo } = this.state;
        let { tutoria, gestionarTutoria } = this.props;
          this.setState({ cargando: true, titulo_res: 'Enviando..' })
          //console.log(estado.codigo)
          let data = {estado: estado.codigo, mensaje: motivo }
          gestionarTutoria(tutoria.id, data, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
        
        e.preventDefault();
    }
  
    pintarEstados() {
        let { estado, estados_siguiente, motivo } = this.state;
        let { tutoria } = this.props
        return (
            <Grid container spacing={2}>
            { tutoria && tutoria.aprobado == "1" &&
              <Grid item xs={12} >
                <List dense={true}>
                  <ListItem>
                    <ListItemIcon style={{color: coloresEmma.secondarycolor}}>
                      <NotificationImportantIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="El estudiante ya cuenta con una solicitud aprobada para este periodo."
                      style={{color: '#757575'}}
                    />
                  </ListItem>
                </List>
              </Grid>
            }
            <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
              <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado.codigo} estados_siguiente={estados_siguiente} />
            </Grid>
            {estado.codigo && (
              <>
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={motivo}
                      id="txtMotivoNegado"
                      label={estado.codigo === 'Est_Tut_Neg' ? "Motivo Negado" : "Observaciones"}
                      name="motivo"
                      placeholder={estado.codigo === 'Est_Tut_Neg' ? "Escriba el motivo de negacion de la solicitud" : "Escriba Observaciones"}
                      multiline
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
                
            </Grid >
        )
    }
    
    pintar() {
      let { seleccion } = this.state;
      return (<div>
        {seleccion === 'gestion' && this.pintarEstados()}
      </div>)
    }
  
    render() {
      let { modalGestionTutoria, mostrarModalGestionTutoria} = this.props;
      let { cargando, titulo_res, seleccion } = this.state;
      return (
        <Dialog fullWidth={true} maxWidth="sm" open={modalGestionTutoria} onClose={mostrarModalGestionTutoria.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <AppBarModal titulo={'¿ Gestionar Tutoria ?'} mostrarModal={mostrarModalGestionTutoria} titulo_accion="" accion="" />
          <MenuGestionar seleccionar={(seleccion) => this.setState({ seleccion })} sele={seleccion} />
          <DialogContent style={{ padding: '1' }} className='scroll'>
            {cargando ? <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" /> : <div style={{ padding: "5px 0 5px 0" }}>{this.pintar()}</div>}
          </DialogContent>
          <DialogActions>
            <BtnForm texto="CERRAR" callback={() => mostrarModalGestionTutoria(false)} />
            {!cargando && <BtnForm texto="ACEPTAR" callback={this.onSubmit} />}
          </DialogActions>
        </Dialog>
      )
    }
  }
  
  export default TutoriasGestionar;