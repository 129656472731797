import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

const CheckboxIndeterminado = ({ initialCheckedState }) => {
    const [checked, setChecked] = useState(initialCheckedState);

    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked]);
    };

    const handleChangeDynamic = (index) => (event) => {
        const updatedChecked = [...checked];
        updatedChecked[index] = event.target.checked;
        setChecked(updatedChecked);
    };

    const children = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {checked.map((isChecked, index) => (
            <FormControlLabel
            key={index}
            label={`Participante ${index + 1}`}
            control={<Checkbox checked={isChecked} onChange={handleChangeDynamic(index)} />}
            />
        ))}
        </Box>
    );

    return (
        <div>
        {children}
        <FormControlLabel
            label="Masivo"
            control={
            <Checkbox
                checked={checked.every(Boolean)}
                indeterminate={!checked.every(Boolean) && checked.some(Boolean)}
                onChange={handleChange1}
            />
            }
        />
        </div>
    );
};

// EPropiedades requeridas para CheckboxIndeterminado
CheckboxIndeterminado.propTypes = {
    initialCheckedState: PropTypes.array.isRequired
};

export default CheckboxIndeterminado;
