import { Dialog, DialogActions, DialogContent, Link } from '@material-ui/core'
import React from 'react'
import AppBarModal from '../../general/AppBarModal'
import { BtnDetalle, BtnForm } from '../../general/BotonesAccion'
import TareasTerminadas from '../../general/TareasTerminadas'
import emma_w from '../../../global/imagenes/emma_w.png';
import * as Funciones from '../../../global/js/funciones';
import { DetalleCurso } from './CursosTables'
import { CursoMenuDetalle } from '../MenuCursosFormacion'
import ListarDatos from '../../general/ListarDatos'
import moment from 'moment/moment'
import DescargarCertificado from '../CertificadoEstudiante'

export default function CursosList(props) {
  const isInitialMount = React.useRef(true);
  const [cargando, setCargando] = React.useState(true)
  const [infoCurso, setInfoCurso] = React.useState([]);
  const [estados, setEstados] = React.useState([]);
  const [iniciocurso, setIniciocurso] = React.useState('') 
  const [fincurso, setFincurso] = React.useState('') 
  const [horas, setHoras] = React.useState('')
  const [cordinador, setCordinador] = React.useState('');
  const [firma_coordinador, setFirma_coordinador] = React.useState('');
  const [vicerrector, setVicerrector] = React.useState('');
  const [firma_Vicerrector, setFirma_Vicerrector] = React.useState('');
  const [unidadCientifica, setUnidadCientifica] = React.useState('');
  const [firma_UnidadCientifica, setFirma_UnidadCientifica] = React.useState(''); 
  React.useEffect(() => {
    //Obtengo los prceosos activos de la persona activa.
    if (isInitialMount.current) {
      isInitialMount.current = false
      const obtenerProcesos = async () => {
        let data = await obtenerCurso();
        setInfoCurso(data)
        let estados = await obtenerEstados(data[0].id);
        setEstados(estados)
        let dataSolicitudes
        setCargando(false)
      }
      obtenerProcesos()
      FechasCertificados()
      obtenerNombreJefes()
    }
  })

  const FechasCertificados = async () => {
    let fechas = await Funciones.obtenerValoresFiltros([
      { llave: "codigo", valor: "Fechas_niveles_formacion" },
      ]);
      if (fechas) {
        setIniciocurso(fechas[0].valora)
        setFincurso(fechas[0].valorb)
        setHoras(fechas[0].valorc)
      }
  }
  const obtenerNombreJefes = async () => {
    let nombrejefe = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_coordinador' }]);
    let nombrevice = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_vicerrector' }]);
    let nombreunidad = await Funciones.obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'firma_unidad_cientifica' }]);
    setCordinador(nombrejefe[0].valora)
    setFirma_coordinador(nombrejefe[0].archivo)
    setVicerrector(nombrevice[0].valora)
    setFirma_Vicerrector(nombrevice[0].archivo)
    setUnidadCientifica(nombreunidad[0].valora)
    setFirma_UnidadCientifica(nombreunidad[0].archivo)
  }
  const obtenerCurso = async () => {
    let f = await Funciones.generarFiltros([{ 'llave': 'solicitud', 'valor': props.id_solicitud }, { 'llave': 'estado', 'valor': '1' }]);
    return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/cursoformacion/curso?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  const obtenerEstados = async (id) => {
    let f = await Funciones.generarFiltros([{ 'llave': 'curso', 'valor': id }, { 'llave': 'estado', 'valor': '1' }]);
    return new Promise(resolve => {
      Funciones.consulta(`api/v1.0/cursoformacion/estados?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  const pintar = (pos, dispatch) => {
    if (props.cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />;
    return (
      <div>
        {pos === 'detalle' && !cargando ? pintarDetalle() : ''}
        {pos === 'adjuntos' && !cargando ? pintarRequisitos() : ''}
        {pos === 'estados' && !cargando ? pintarEstados() : ''}
      </div>
    )

  }

  const pintarDetalle = () => {
    return (<DetalleCurso datos={infoCurso} />)
  }

  const pintarRequisitos = () => {
    const obtenerColor = (codigo = 0) => {
      const colores = {
        '0': { 'backgroundColor': '#f5f5f5', 'color': '#757575' },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      console.log(data)

      let detalle = <Link href={`${Funciones.api}${data.adj_horario}`} target="_blank"><BtnDetalle callback={() => { }} /></Link>;
      return <div>{detalle}</div>;
    }
    return (
      <ListarDatos
        id="tbl_mis_adjuntos"
        datos={infoCurso}
        avatar={({ tipo_adjunto }) => tipo_adjunto && tipo_adjunto.nombre.charAt(0).toUpperCase()}
        color_avatar={() => obtenerColor()}
        acciones={data => acciones(data)}
        fila_principal={({ tipo_adjunto }) => tipo_adjunto && tipo_adjunto.valora.toUpperCase()}
        filas={[
          { 'id': 'estado_actual', 'mostrar': ({ solicitud: { estado_actual } }) => estado_actual && estado_actual.nombre },
          { 'id': 'fecha_registro', 'enLista': true, 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
        ]}
      />
    )
  }

  const pintarEstados = () => {
    // let { estados } = this.state;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'inscri_proceso_curso': { 'backgroundColor': '#9e9e9e', 'color': color },
        'certificado_curso': { 'backgroundColor': '#ffc107', color },
        'inscrito_curso': { 'backgroundColor': '#009688', color },
        'retirado_curso': { 'backgroundColor': '#b71c1c', color },
        'no_culmi_curso': { 'backgroundColor': '#b71c1c', color },
      }
      return (colores[codigo])
    }
    const acciones = (data) => {
      return (
        data.tipo_estado.codigo=='certificado_curso'?
        <div>
           <DescargarCertificado 
            identificacion={props.identificacion}
            nombre={props.nombre}
            idusuario={props.idusuario}
            iniciocurso={iniciocurso}
            fincurso={fincurso}
            horas={horas}
            firma_coordinador={firma_coordinador}
            cordinador={cordinador}
            vicerrector={vicerrector}
            firma_Vicerrector={firma_Vicerrector}
            unidadCientifica={unidadCientifica}
            firma_UnidadCientifica={firma_UnidadCientifica}
            />
        </div>:''
      )
    }

    return (
      <ListarDatos
        datos={estados}
        id='tbl_estados_credito'
        avatar={({ tipo_estado }) => tipo_estado.nombre.charAt(0).toUpperCase()}
        acciones={data => acciones(data)}
        color_avatar={({ tipo_estado }) => obtenerColor(tipo_estado.codigo)}
        fila_principal={({ tipo_estado }) => tipo_estado.nombre}
        filas={[
          { 'id': 'usuario_registro', 'mostrar': ({ usuario_registro: { primer_nombre, primer_apellido, segundo_apellido } }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'nombre': 'Usuario Registro : ' },
          { 'id': 'observaciones', 'mostrar': ({ mensaje }) => mensaje },
          { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a'), 'nombre': 'Fecha Registro : ' },
        ]}
      />
    )
  }

  const handleClose = () => {
    props.setPos('detalle')
    props.setOpenModalInfo(false)
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600} open={props.openModalInfo} onClose={() => props.setOpenModalInfo(false)}>
        <AppBarModal titulo={'¡ Más Información !'} mostrarModal={handleClose} titulo_accion="" accion="" />
        <CursoMenuDetalle sele={props.pos} seleccionar={seleccion => props.setPos(seleccion)} />
        <DialogContent style={{ padding: 0 }} className='scroll'>
          {cargando == true ? <TareasTerminadas imagen={emma_w} mensaje={'Cargando..'} widthImg='7%' marginTop='7%' /> : pintar(props.pos)}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  )
}
