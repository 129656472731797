import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

// COMPONETES

// ICONOS MENU
import { Hidden } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import CrearBeca from "@material-ui/icons/Edit";

const clase = (item, selec, tipo = "") =>
  item === selec ? `item_selec${tipo}` : `item_modulo`;

// MENU VALIDACIONES - ESTUDIANTES

export function MenuEstudiantes({
  sele,
  seleccionar,
}) {
  const pintar = (app, claseN = "") => {
    if (!app) {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels className={clase("miproceso", sele)} onClick={() => seleccionar("miproceso")} >
            <BottomNavigationAction label={"Procesos"} icon={<LocationOnIcon />} />
          </BottomNavigation>


          <BottomNavigation showLabels className={clase("nuevo", sele)} onClick={() => seleccionar("nuevo")} >
            <BottomNavigationAction label={"Nuevo"} icon={<CrearBeca />} />
          </BottomNavigation>

          <BottomNavigation showLabels className={clase("historial", sele)} onClick={() => seleccionar("historial")} >
            <BottomNavigationAction label={"Historial"} icon={<ImportContactsIcon />} />
          </BottomNavigation>
        </div>
      );
    } else {
      return (
        <div className={claseN}>
          <BottomNavigation showLabels>
            <BottomNavigationAction label={"Procesos"} icon={<LocationOnIcon />} className={clase("miproceso", sele)} onClick={() => seleccionar("miproceso")} />
            <BottomNavigationAction label={"Nuevo"} icon={<CrearBeca />} className={clase("nuevo", sele)} onClick={() => seleccionar("nuevo")} />
            <BottomNavigationAction label={"Historial"} icon={<ImportContactsIcon />} className={clase("historial", sele)} onClick={() => seleccionar("historial")} />
          </BottomNavigation>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <Hidden xsDown> {pintar(false, "menu_modulo")} </Hidden>
        <Hidden smUp> {pintar(true, "menu_modulo_app")}</Hidden>
      </div>
    </div>
  );
}

