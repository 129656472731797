import React, { Component } from 'react'
import { AgregarFinanciacion, AgregarInfoBasica, AgregarInstitucion, FinanciacionParticipante, ModalContenedor, ModificarFinanciacion } from './forms/Forms'
import PropTypes from 'prop-types';
import EditarParticipanteExt from './forms/EditarParticipanteExt';
import { consulta, formulario, generarFiltros, mostrarError, obtenerPermisosValorAlt, obtenerValoresFiltros, validateInputs } from '../../../global/js/funciones';
import { CODIGO_CURSO_CORTO, CODIGO_CURSO_CORTO_PROFE, DEPARTAMENTO_RESPONSABLE, DEPENDENCIAS_FINANCIACIONES_MOVILIDAD, FACULTAD_POSGRADOS_PARTICI, FACULTAD_RESPONSABLE, FUENTES_FINANCIACIONES_MOVILIDAD, MODALIDADES_VIRTUALES, OTRA_PLATAFORMA, PROGRAMAS, SUBTIPO_ACTI_EMPRESARIAL, TIPO_FIN_INTERNACIONAL, TIPO_FIN_NACIONAL, TIPO_MOV_DOCENTE, TIPO_MOV_ESTUDIANTE, TIPO_PARTICIPANTE_ADMIN, TIPO_PARTICIPANTE_DOC, TIPO_PARTICIPANTE_ESTU, TIPOS_CONCEPTOS_MOV, TIPOS_FINANCIACIONES_MOVILIDAD, TIPOS_MODALIDADES, TIPOS_MOV_ENTRANTES, TIPOS_MOV_SALIENTES, TIPOS_MOVILIDADES, TIPOS_NIVELES_EDU, TIPOS_PARTICIPANTES } from './helper';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { CURRENCIES } from '../currencies';
import ConfirmarAccion from '../../general/ConfirmarAccion';

export default class EditarInformacion extends Component {

  constructor(props) {
    super(props)
    console.log('Props recibidas:', props);

    this.state = {
      // general
      sol_id: props.id_solicitud,
      cargandoContenedor: false,
      tit_cargando: 'Cargando...',
      editar: true,
      cargandoForm: false,
      errores: [],
      tipo_movilidad: '',
      tipo_modalidad: '',
      plataforma: '',
      otra_plataforma: '',
      institucion_participante: { id: 0, institucion: '', pais: '', ciudad: '' },
      participante_tipo: null,
      tipo_participante: null,
      error: false,
      dpto_participante: '',
      programa_participante: '',
      facultad_participante: '',
      nivel_participante: '',
      ident_tipo_movilidad: null,
      datosCargados: false,
      tipo_financiacion: '',
      dependencia: '',
      fuente_financiacion: '',
      entidad: '',
      valor_cop: '',
      tipo_moneda: '',
      valor_ext: '',
      clasificacion_de_financiacion: '',
      otro_tipo_de_concepto: '',
      comprobante_financiacion: '',
      id_financiacion: 0,


      // modales
      modalContenedor: false,
      modalAddInfoBasica: false,
      modalAddFinanciacion: false,
      modalAddInstitucion: false,
      editarParticipante: false,
      modal_buscar_institucion: false,
      modal_elimi_institucion: false,
      modal_financiacion: false,
      modal_confirmar: false,

      // consultas
      tipos_forms: props.tipos_forms || [],
      tipos_movilidades: [],
      tipos_modalidades: [],
      plataformas: [],
      tipos_participantes: [],
      dptos_academicos: [],
      dependencias: [],
      facultades_resp: [],
      niveles_educativos: [],
      programas_receptores: [],
      programas_receptores_docente: [],
      instituciones: [],
      tipos_financiaciones: [],
      monedas: [],
      tipos_conceptos: [],
      financiacion: [],
      tipos_financiaciones: [],
      dependencias: [],
      fuentes_financiacion: [],


    }
  }

  async componentDidMount() {
    this.setState({ modalContenedor: true })
    await this.cargarDatos()
  }

  async componentDidUpdate(prevProps, prevState) {
    let { modalContenedor, datosCargados, tipo_participante, facultad_participante, nivel_participante, dpto_participante, tipo_financiacion } = this.state
    let { onClose, data } = this.props

    if (prevState.modalContenedor !== modalContenedor) {
      if (!modalContenedor) onClose()
    }

    if (!datosCargados) return;

    if (prevState.tipo_participante !== tipo_participante) await this.actualizarOpciones({ tipo: 'tipo_participante', tipo_participante })

    if (prevState.facultad_participante !== facultad_participante) await this.actualizarOpciones({ tipo: "facultad_participante", facultad_participante })

    if (prevState.facultad_participante !== facultad_participante || prevState.nivel_participante !== nivel_participante) await this.actualizarOpciones({ tipo: "nivel_educativo", facultad_participante, nivel_participante })

    if (prevState.dpto_participante !== dpto_participante) await this.actualizarOpciones({ tipo: "dpto_participante", dpto_participante, tipo_participante })

    if (prevState.tipo_financiacion !== tipo_financiacion) {
      if (tipo_financiacion == TIPO_FIN_NACIONAL) {
        let fuentes_financiacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FUENTES_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'nacional' }]);
        this.setState({ fuentes_financiacion });
      } else if (tipo_financiacion == TIPO_FIN_INTERNACIONAL) {
        let fuentes_financiacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FUENTES_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'internacional' }]);
        this.setState({ fuentes_financiacion });
      } else {
        let dependencias = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPENDENCIAS_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);
        this.setState({ dependencias, });
      }
    }
  }

  actualizarOpciones = async ({ tipo, tipo_participante, facultad_participante, nivel_participante, dpto_participante }) => {
    if (tipo === "tipo_participante") {
      if (!tipo_participante) return;

      this.setState({ dpto_participante: '', programa_participante: '', facultad_participante: '', facultades_resp: [], dptos_academicos: [], dependencias: [] });

      if (tipo_participante === TIPO_PARTICIPANTE_ESTU) {
        let facultades_resp = await obtenerValoresFiltros([{ llave: 'generica', valor: FACULTAD_RESPONSABLE }, { llave: 'estado', valor: 1 }]);
        this.setState({ facultades_resp });
      } else if (tipo_participante === TIPO_PARTICIPANTE_DOC) {
        let dptos_academicos = await obtenerValoresFiltros([{ llave: 'generica', valor: DEPARTAMENTO_RESPONSABLE }, { llave: 'estado', valor: 1 }, { llave: 'valorc', valor: 'docente' }]);
        this.setState({ dptos_academicos });
      } else if (tipo_participante === TIPO_PARTICIPANTE_ADMIN) {
        let dptos_academicos = await obtenerValoresFiltros([{ llave: 'generica', valor: DEPARTAMENTO_RESPONSABLE }, { llave: 'estado', valor: 1 }, { llave: 'valorc', valor: 'administrativo' }]);
        let dependencias = await obtenerValoresFiltros([{ llave: 'generica', valor: DEPENDENCIAS_FINANCIACIONES_MOVILIDAD }, { llave: 'estado', valor: 1 }]);
        this.setState({ dptos_academicos, dependencias });
      }
    }

    if (tipo === "facultad_participante") {
      if (!facultad_participante) return;

      this.setState({ nivel_participante: '' });

      let niveles_educativos = await obtenerValoresFiltros([
        { llave: 'generica', valor: TIPOS_NIVELES_EDU },
        { llave: 'valora', valor: facultad_participante === FACULTAD_POSGRADOS_PARTICI ? 'posgrado' : 'pregrado' },
        { llave: 'estado', valor: 1 }
      ]);

      this.setState({ niveles_educativos });
    }

    if (tipo === "nivel_educativo") {
      if (!facultad_participante || !nivel_participante) return;

      let permisos_programas = await obtenerPermisosValorAlt([{ llave: 'principal', valor: facultad_participante }, { llave: 'secundario__generica', valor: PROGRAMAS }]);

      let nivel = this.state.niveles_educativos.find(({ id }) => id == nivel_participante)?.nombre.toLowerCase();
      let programas_receptores = nivel
        ? permisos_programas.filter(programa => (nivel === 'pregrado' ? programa.secundario.valorc === nivel : programa.secundario.valord === nivel)).map(programa => programa.secundario)
        : [];

      this.setState({ programas_receptores });
    }

    if (tipo === "dpto_participante") {
      if (!dpto_participante || tipo_participante !== TIPO_PARTICIPANTE_DOC) return;

      let programas_receptores_docente = await obtenerPermisosValorAlt([{ llave: 'principal', valor: dpto_participante }, { llave: 'secundario__generica', valor: PROGRAMAS }]);

      this.setState({ programas_receptores_docente });
    }

  };

  cargarDatos = async () => {
    let { id_registro } = this.props
    let data = await this.obtenerInfoRegMov(id_registro);
    console.log(data)
    if (data) {
      await this.obtenerGenericas(data);
      // Información inicial
      let { persona_registro: { tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma }, institucion_registro: { institucion }, actividad_registro
        : { actividad }, financiacion_registro: { financiacion }, financiaciones_adicionales } = data;

      // Institución
      // Verificar si todos los tipos de movilidad corresponden a estudiantes
      const sonEstudiantes = tipo_movilidad?.id ? TIPO_MOV_ESTUDIANTE.includes(tipo_movilidad.id) : false;

      // Verificar si el tipo de movilidad corresponde a docentes
      const sonDocentes = tipo_movilidad?.id ? TIPO_MOV_DOCENTE.includes(tipo_movilidad.id) : false;

      // Verificar si el tipo de movilidad corresponde a salientes
      const sonSalientes = tipo_movilidad?.id ? TIPOS_MOV_SALIENTES.includes(tipo_movilidad.id) : false;

      // Verificar si el tipo de movilidad corresponde a entrantes
      const sonEntrantes = tipo_movilidad?.id ? TIPOS_MOV_ENTRANTES.includes(tipo_movilidad.id) : false;

      console.log('Son Estudiantes', sonEstudiantes);
      console.log('Son Docentes', sonDocentes);
      console.log('Son Salientes', sonSalientes);
      console.log('Son Entrantes', sonEntrantes);

      let tipos_participantes = [];
      if (sonEstudiantes) {
        tipos_participantes = await obtenerValoresFiltros([
          { llave: 'generica', valor: TIPOS_PARTICIPANTES },
          { llave: 'valora', valor: 1 },
          { llave: 'estado', valor: 1 },
        ]);
        this.setState({
          participante_tipo: true,
          tipos_participantes,
          tipo_participante: tipos_participantes.length > 0 ? tipos_participantes[0].id : null
        });
      } else if (sonDocentes) {
        tipos_participantes = await obtenerValoresFiltros([
          { llave: 'generica', valor: TIPOS_PARTICIPANTES },
          { llave: 'valora', valor: 0 },
          { llave: 'estado', valor: 1 },
        ]);
        this.setState({ participante_tipo: false, tipos_participantes, tipo_participante: tipos_participantes.length > 0 ? tipos_participantes[0].id : null });
      } else {
        this.setState({ participante_tipo: 'distinto' });
      }

      this.setState({
        ident_tipo_movilidad: sonSalientes ? 'saliente' : sonEntrantes ? 'entrante' : 'distinto'
      });

      // Validar que `institucion` y sus propiedades existan antes de acceder a ellas
      if (institucion?.tipo_participante?.id === TIPO_PARTICIPANTE_ESTU) {
        let facultades_resp = await obtenerValoresFiltros([
          { llave: 'generica', valor: FACULTAD_RESPONSABLE },
          { llave: 'estado', valor: 1 },
        ]);
        this.setState({ facultades_resp });
      }

      if (institucion?.tipo_participante?.id === TIPO_PARTICIPANTE_DOC) {
        let dptos_academicos = await obtenerValoresFiltros([
          { llave: 'generica', valor: DEPARTAMENTO_RESPONSABLE },
          { llave: 'estado', valor: 1 },
          { llave: 'valorc', valor: 'docente' },
        ]);
        this.setState({ dptos_academicos });
      } else if (institucion?.tipo_participante?.id === TIPO_PARTICIPANTE_ADMIN) {
        let dptos_academicos = await obtenerValoresFiltros([
          { llave: 'generica', valor: DEPARTAMENTO_RESPONSABLE },
          { llave: 'estado', valor: 1 },
          { llave: 'valorc', valor: 'administrativo' },
        ]);
        let dependencias = await obtenerValoresFiltros([
          { llave: 'generica', valor: DEPENDENCIAS_FINANCIACIONES_MOVILIDAD },
          { llave: 'estado', valor: 1 },
        ]);
        this.setState({ dptos_academicos, dependencias });
      }

      let niveles_educativos = [];
      if (institucion?.facultad_receptora?.id === FACULTAD_POSGRADOS_PARTICI) {
        niveles_educativos = await obtenerValoresFiltros([
          { llave: 'generica', valor: TIPOS_NIVELES_EDU },
          { llave: 'valora', valor: 'posgrado' },
          { llave: 'estado', valor: 1 },
        ]);
      } else {
        niveles_educativos = await obtenerValoresFiltros([
          { llave: 'generica', valor: TIPOS_NIVELES_EDU },
          { llave: 'valora', valor: 'pregrado' },
          { llave: 'estado', valor: 1 },
        ]);
      }
      this.setState({ niveles_educativos });

      // Validar que `institucion` y sus propiedades existen antes de acceder a ellas
      if (institucion?.facultad_receptora?.id) {
        let permisos_programas = await obtenerPermisosValorAlt([
          { llave: 'principal', valor: institucion?.facultad_receptora?.id || null },
          { llave: 'secundario__generica', valor: PROGRAMAS },
        ]);

        let programas_receptores = [];
        let nivel = niveles_educativos.find(({ id }) => id === institucion?.nivel_estudio?.id)?.nombre?.toLowerCase();

        if (nivel) {
          programas_receptores = permisos_programas.filter(programa =>
            nivel === 'pregrado' ? programa.secundario.valorc === nivel : programa.secundario.valord === nivel
          ).map(programa => programa.secundario);
        }

        this.setState({ programas_receptores });
      }

      // const mergedData = financiaciones_adicionales.length > 0 ? financiaciones_adicionales[financiaciones_adicionales.length - 1].financiacion : financiacion.financiacion;

      const unifiedArray = [financiacion, ...financiaciones_adicionales.map(item => item.financiacion)];


      this.setState({
        tipo_movilidad: tipo_movilidad ? tipo_movilidad.id : '',
        tipo_modalidad: tipo_modalidad ? tipo_modalidad.codigo : '',
        plataforma: plataforma ? plataforma.codigo : '',
        otra_plataforma: otra_plataforma ? otra_plataforma : '',
        institucion_participante: institucion ? { id: institucion.institucion_externa.id || '', institucion: institucion.institucion_externa ? institucion.institucion_externa.institucion || '' : '', pais_vinculante: institucion.institucion_externa ? institucion.institucion_externa.pais_vinculante || '' : '', ciudad_vinculante: institucion.institucion_externa ? institucion.institucion_externa.ciudad_vinculante || '' : '', } : {},
        instituciones: actividad ? actividad.institucion_actividad || [] : [],
        facultad_participante: institucion && institucion.facultad_receptora ? institucion.facultad_receptora.id || '' : '',
        nivel_participante: institucion && institucion.nivel_estudio ? institucion.nivel_estudio.id || '' : '',
        programa_participante: institucion && institucion.programa_receptor ? institucion.programa_receptor.id || '' : '',
        dpto_participante: institucion && institucion.dpto_academico_receptor ? institucion.dpto_academico_receptor.id || '' : '',
        datosCargados: true,
        financiacion: unifiedArray,

      });

    }



  }

  async obtenerInfoRegMov(id) {
    // let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/solicitud/obtener/${id}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      });
    });
  }

  enviarSolicitudCorregida = async () => {
    let { sol_id } = this.state;
    let {mostrarModalDetSolicitud, obtenerInfoSolicitud}= this.props
    // let f = await generarFiltros([{ 'llave': 'solicitud', 'valor': id }]);
    return new Promise(resolve => {
      consulta(`api/v1.0/internacionalizacion/registro/solicitudCorreccion/${sol_id}`, null, 'put', (error, estado, resp) => {
        if (estado === 200 && !error) {
          console.log("Solicitud corregida con éxito:", resp);
          this.setState({ modal_confirmar: false, modalContenedor: false })
          mostrarModalDetSolicitud()
          obtenerInfoSolicitud()
          resolve(resp);
        } else {
          resolve(null);
        }
      });
    });
  }

  async obtenerGenericas(data) {
    console.log('Data:', data);
    let { actividad_registro: { actividad: { tipo_actividad, subtipo_actividad } }, } = data;
    console.log('Tipo Actividad:', tipo_actividad);
    console.log('Subtipo Actividad:', subtipo_actividad);
    let modalidadActividad, modalidadSubActividad, tipo_movilidad_act, tipo_movilidad_subact;

    if (tipo_actividad.codigo === CODIGO_CURSO_CORTO && subtipo_actividad.codigo === SUBTIPO_ACTI_EMPRESARIAL) {
      modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO_PROFE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      tipo_movilidad_act = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': CODIGO_CURSO_CORTO_PROFE }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);
    } else {
      modalidadActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad.codigo }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
      tipo_movilidad_act = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': tipo_actividad.codigo }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);
    }

    modalidadSubActividad = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividad.codigo }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MODALIDADES }]);
    tipo_movilidad_subact = await obtenerPermisosValorAlt([{ 'llave': 'principal__codigo', 'valor': subtipo_actividad.codigo }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'secundario__generica', 'valor': TIPOS_MOVILIDADES }]);

    const coincidencias_modalidad = modalidadActividad.filter(obj1 => modalidadSubActividad.some(obj2 => obj1.secundario.id === obj2.secundario.id));
    const coincidencias_movilidad = tipo_movilidad_act.filter(obj1 => tipo_movilidad_subact.some(obj2 => obj1.secundario.id === obj2.secundario.id));

    let tipos_financiaciones = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);
    let tipos_conceptos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_CONCEPTOS_MOV }, { 'llave': 'estado', 'valor': 1 }]);
    let monedas = CURRENCIES

    this.setState({ tipos_modalidades: coincidencias_modalidad, tipos_movilidades: coincidencias_movilidad, tipos_financiaciones, monedas, tipos_conceptos, });
  }

  actualizarEstado = (nombreEstado, valor) => {
    this.setState(prevState => ({
      [nombreEstado]: typeof valor === 'object' && !Array.isArray(valor)
        ? { ...prevState[nombreEstado], ...valor }
        : valor
    }));
  };


  handleModalContenedor = async (codigo) => {
    console.log('Codigo:', codigo);
    let { editarParticipante } = this.props;
    return (
      <>
        {codigo === 'add_info_basica' && this.setState({ modalAddInfoBasica: true })}
        {codigo === 'add_financiacion' && this.setState({ modal_financiacion: true })}
        {codigo === 'add_institucion' && this.setState({ modalAddInstitucion: true })}
        {codigo === 'edi_info_parti' && editarParticipante()}
      </>
    )
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({ [name]: value });
  }

  guardarInfoPrincipalParticipantes = async () => {
    let { actualizarMensaje } = this.props;
    const { tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma } = this.state;

    // Validar campos obligatorios
    const errores = [];
    if (!tipo_movilidad) errores.push({ llave: 'tipo_movilidad', mensaje: 'Este campo es obligatorio' });
    if (!tipo_modalidad) errores.push({ llave: 'tipo_modalidad', mensaje: 'Este campo es obligatorio' });

    // Verificar plataforma en caso de modalidades virtuales
    if (MODALIDADES_VIRTUALES.includes(tipo_modalidad)) {
      if (!plataforma) errores.push({ llave: 'plataforma', mensaje: 'Este campo es obligatorio' });
      if (plataforma === OTRA_PLATAFORMA && !otra_plataforma) {
        errores.push({ llave: 'otra_plataforma', mensaje: 'Este campo es obligatorio' });
      }
    }

    // Mostrar errores si los hay
    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      this.setState({ cargandoForm: false });
      return;
    }

    const data = { tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma };
    await this.guardarDatos(data, 'add_info_basica');

  }

  guardarInstitucionParticipante = async () => {
    let { institucion_participante, tipo_participante, nivel_participante, programa_participante, dpto_participante, facultad_participante, dependencia_participante, institucion_participantes, participantesSelect } = this.state;
    let { actualizarMensaje } = this.props;

    let errores = await validateInputs([]);

    if (institucion_participante.institucion == '') errores.push({ llave: 'institucion_participante', 'mensaje': `Este campo es obligatorio` });
    if (tipo_participante == null) errores.push({ llave: 'tipo_participante', 'mensaje': `Este campo es obligatorio` });

    if (tipo_participante == TIPO_PARTICIPANTE_ESTU) {
      if (facultad_participante == '') errores.push({ llave: 'facultad_participante', 'mensaje': `Este campo es obligatorio` });
      if (nivel_participante == '') errores.push({ llave: 'nivel_participante', 'mensaje': `Este campo es obligatorio` });
      if (programa_participante == '') errores.push({ llave: 'programa_participante', 'mensaje': `Este campo es obligatorio` });
    } else if (tipo_participante == TIPO_PARTICIPANTE_DOC) {
      if (dpto_participante == '') errores.push({ llave: 'dpto_participante', 'mensaje': `Este campo es obligatorio` });
      if (programa_participante == '') errores.push({ llave: 'programa_participante', 'mensaje': `Este campo es obligatorio` });
    } else if (tipo_participante == TIPO_PARTICIPANTE_ADMIN) {
      if (dpto_participante == '') errores.push({ llave: 'dpto_participante', 'mensaje': `Este campo es obligatorio` });
      if (programa_participante == '') errores.push({ llave: 'programa_participante', 'mensaje': `Este campo es obligatorio` });
    }
    console.log(errores)

    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      return;
    }

    let data = { institucion: institucion_participante.id, tipo_participante, nivel_participante, programa_participante, facultad_participante, dpto_participante, programa_participante };
    await this.guardarDatos(data, 'add_institucion');

  }

  guardarFinanciacion = async () => {
    let { tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop, tipo_moneda, valor_ext, clasificacion_de_financiacion, otro_tipo_de_concepto, comprobante_financiacion, id_financiacion } = this.state;
    let { actualizarMensaje } = this.props;
    let errores = await validateInputs([]);

    if (tipo_financiacion == '') errores.push({ llave: 'tipo_financiacion', 'mensaje': `Este campo es obligatorio` });
    if (tipo_financiacion == TIPO_FIN_NACIONAL && dependencia == '') errores.push({ llave: 'dependencia', 'mensaje': `Este campo es obligatorio` });
    if (tipo_financiacion == TIPO_FIN_INTERNACIONAL && fuente_financiacion == '') errores.push({ llave: 'fuente_financiacion', 'mensaje': `Este campo es obligatorio` });
    if (entidad == '') errores.push({ llave: 'entidad', 'mensaje': `Este campo es obligatorio` });
    if (valor_cop == '') errores.push({ llave: 'valor_cop', 'mensaje': `Este campo es obligatorio` });
    if (tipo_moneda == '') errores.push({ llave: 'tipo_moneda', 'mensaje': `Este campo es obligatorio` });
    if (valor_ext == '') errores.push({ llave: 'valor_ext', 'mensaje': `Este campo es obligatorio` });
    if (clasificacion_de_financiacion == '') errores.push({ llave: 'clasificacion_de_financiacion', 'mensaje': `Este campo es obligatorio` });
    if (clasificacion_de_financiacion == 'otro' && otro_tipo_de_concepto == '') errores.push({ llave: 'otro_tipo_de_concepto', 'mensaje': `Este campo es obligatorio` });

    if (errores.length > 0) {
      this.setState({ errores });
      actualizarMensaje({ titulo: 'Debes llenar los campos obligatorios.', tipo: 'info', mostrar: true, tiempo: 6000 });
      return;
    }

    let data = { tipo_financiacion, dependencia, fuente_financiacion, entidad, valor_cop, tipo_moneda, valor_ext, clasificacion_de_financiacion, otro_tipo_de_concepto, comprobante_financiacion, id_financiacion };
    this.setState({ modalAddFinanciacion: false });
    await this.guardarDatos(data, 'add_financiacion');
  }


  guardarDatos = async (data, tipo) => {
    console.log(data)
    let { actualizarMensaje, id_registro } = this.props;
    let f = generarFiltros([{ 'llave': 'tipo_edicion', 'valor': tipo }]);
    consulta(`api/v1.0/internacionalizacion/registro/editar/${id_registro}?tipo_edicion=${tipo}`, data, 'post', (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          if (tipo === 'add_info_basica') this.setState({ modalAddInfoBasica: false });
          else if (tipo === 'add_institucion') this.setState({ modalAddInstitucion: false });
          else if (tipo === 'add_financiacion') this.setState({ modal_financiacion: false, modalAddFinanciacion: false });
          this.cargarDatos()
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
    });
  }

  agregarInstitucionParticipante = (id, institucion, pais_vinculante, ciudad_vinculante, actividades, tipoConvenio) => {
    let { errores } = this.state;
    this.setState({ institucion_participante: { id: id, institucion, pais_vinculante: pais_vinculante ?? '', ciudad_vinculante: ciudad_vinculante ?? '', actividades: actividades ?? [], tipoConvenio: tipoConvenio ?? [] }, errores: errores.length > 0 ? errores.filter(er => er.llave !== 'institucion_participante') : errores });
  }

  setEditarFin = async (financiacion) => {
    console.log(financiacion)
    this.setState({
      modalAddFinanciacion: true,
      id_financiacion: financiacion.id,
      tipo_financiacion: financiacion?.tipo_financiacion?.id || '',
      dependencia: financiacion?.dependencia?.id || '',
      fuente_financiacion: financiacion?.fuente_financiacion?.id || '',
      entidad: financiacion?.entidad || '',
      valor_cop: financiacion?.valor_cop || '',
      tipo_moneda: financiacion?.tipo_moneda || '',
      valor_ext: financiacion?.valor_ext || '',
      clasificacion_de_financiacion: financiacion?.clasificacion_financiacion?.codigo || '',
      otro_tipo_de_concepto: financiacion?.otro_tipo_de_concepto || ''
    });
    if (financiacion.tipo_financiacion.id == TIPO_FIN_NACIONAL) {
      let fuentes_financiacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FUENTES_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'nacional' }]);
      this.setState({ fuentes_financiacion });
    } else if (financiacion.tipo_financiacion.id == TIPO_FIN_INTERNACIONAL) {
      let fuentes_financiacion = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': FUENTES_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }, { 'llave': 'valora', 'valor': 'internacional' }]);
      this.setState({ fuentes_financiacion });
    } else {
      let dependencias = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': DEPENDENCIAS_FINANCIACIONES_MOVILIDAD }, { 'llave': 'estado', 'valor': 1 }]);
      this.setState({ dependencias });
    }
  }

  render() {
    let { editarParticipante, dataParticipante, modalAddInfoBasica, modalContenedor, cargandoForm, errores, tipos_movilidades, tipos_modalidades, plataformas, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, add_info_basica, modalAddFinanciacion, modalAddInstitucion, institucion_participante, modal_buscar_institucion, participante_tipo, tipos_participantes, tipo_participante, error, dptos_academicos, dpto_participante, dependencias, programa_participante, facultades_resp, facultad_participante, niveles_educativos, nivel_participante, programas_receptores, programas_receptores_docente, modal_elimi_institucion, ident_tipo_movilidad, instituciones, tipos_financiaciones, monedas, financiacion, modal_financiacion, modal_confirmar } = this.state;
    let props_info = { modalAddInfoBasica, cerrarModal: () => this.setState({ modalAddInfoBasica: false }), cargandoForm, errores, onChange: this.onChange, tipos_movilidades, tipos_modalidades, plataformas, tipo_movilidad, tipo_modalidad, plataforma, otra_plataforma, guardarInfoPrincipalParticipantes: this.guardarInfoPrincipalParticipantes }

    // PROPS PARA EL COMPONENTE DE FINANCIACIÓN
    // let props_financiación = { modalAddFinanciacion, cerrarModal: () => this.setState({ modalAddFinanciacion: false }), cargandoForm, onChange: this.onChange, es_financiado, tipos_financiaciones, tipo_financiacion, errores, dependencia, dependencias, fuente_financiacion, fuentes_financiacion, entidad, valor_cop, tipo_moneda, monedas, valor_ext, info_inicial_participantes, tipos_conceptos, clasificacion_de_financiacion, otro_tipo_de_concepto, fileInput: this.fileInput, comprobante_financiacion, guardarFinanciacionParticipantes: this.guardarFinanciacionParticipantes, financiaciones, agregarFinanciacion: this.agregarFinanciacion, onChangeFinanciaciones: this.onChangeFinanciaciones, nuevaFinanciacion, mostrarFormulario, mostrarFormularioNuevaFinanciacion: this.mostrarFormularioNuevaFinanciacion, eliminarFinanciacion: this.eliminarFinanciacion, buscarDataFinanciacion: this.buscarDataFinanciacion }

    // PROPS PARA EL COMPONENTE DE INSTITUCIÓN
    let props_institucion = { modalAddInstitucion, cerrarModal: () => this.setState({ modalAddInstitucion: false }), cargandoForm, errores, institucion_participante, modal_buscar_institucion, agregarInstitucionParticipante: this.agregarInstitucionParticipante, cerrarModalBuscarInstitucion: () => this.setState({ modal_buscar_institucion: false }), abrirBuscarInst: () => this.setState({ modal_buscar_institucion: true }), participante_tipo }

    return (

      <>
        {/* MODAL CONTENEDOR */}
        {modalContenedor && <ModalContenedor state={this.state} actions={this} />}
        {/* AÑADIR INFORMACIÓN BÁSICA */}
        {modalAddInfoBasica && <AgregarInfoBasica props_info={props_info} />}
        {/* AÑADIR FINANCIACIÓN */}
        {modal_financiacion && <FinanciacionParticipante modal_fin_participante={modal_financiacion} cerrarModal={() => this.setState({ modal_financiacion: false })} cargando={cargandoForm} financiacion={financiacion} editar={true} setEditarFin={this.setEditarFin} />}
        {modalAddFinanciacion && <ModificarFinanciacion modalAddFinanciacion={modalAddFinanciacion} cerrarModal={() => this.setState({ modalAddFinanciacion: false })} state={this.state} actions={this} />}
        {/* AÑADIR INSTITUCIÓN */}
        {modalAddInstitucion && <AgregarInstitucion state={this.state} actions={this} />}

        {/* {
          editarParticipante && dataParticipante &&
          <EditarParticipanteExt participante={dataParticipante} resetState={() => this.setState({ editarParticipante: false, dataParticipante: {} })} actualizarMensaje={actualizarMensaje} obtenerParticipantesRegistro={obtenerParticipantesRegistro} id_solicitud={id} consultaParticipantes={(data) => this.setState({ participantes: data })} setCargando={(value) => this.setState({ cargando: value })} />
        } */}
        <ConfirmarAccion
          mensaje1={
            <React.Fragment>
              ¿Estás seguro que desea enviar la solicitud de corrección?
            </React.Fragment>
          }
          ejecutarAccion={this.enviarSolicitudCorregida}
          mostrarModalConfAccion={(modal_confirmar) => this.setState({ modal_confirmar })}
          modalConAccion={modal_confirmar}
          dato={{ id: 0 }}
          titulo={'¿ Enviar corrección ?'}
          mensaje2={''}
        />
      </>
    )
  }
}

