import React from 'react';
import { Typography, Grid, } from '@material-ui/core'
import '../../../global/css/stylePortal.css'

const DetalleLogros = (props) => {
    let { logros } = props
    return (
        <Grid xs={12} md={12} style={{'marginBottom': '30px'}}>
            <Typography variant="h5">
                { logros.descripcion }
            </Typography>
        </Grid>
    );
};

export default DetalleLogros;