import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//FUNCIONES GENERALES
import {
	guardarValorGenerica,
	mostrarError,
	consulta,
	modificarValorGenerica,
	api
} from '../../../global/js/funciones';
//COMPONENTES DEL MODULO
import ValoresListar from './ValoresListar';
import ValoresAgregar from './ValoresAgregar';
import ValoresModificar from './ValoresModificar';
import ValoresAgregarPermiso from './ValoresAgregarPermiso';
import AppBarModal from '../../general/AppBarModal';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import Detalle from '../../general/Detalle';

//ACCIONES DEL MODULO
import {
	mostrarModalAddValores,
	mostrarModalModValores,
	mostrarModalDelValores,
	actualizarDataValor,
	actualizarDataValores,
	actualizarRenderValores,
	mostrarModalAddPermisoValores,
	mostrarModalDetValores,
	actualizarDataPermisos
} from '../../../redux/actions/actValores';
import { actualizarMensaje } from '../../../redux/actions/actGlobal';
import { BtnForm } from '../../general/BotonesAccion';
import emma_w from '../../../global/imagenes/emma_w.png';
import TareasTerminadas from '../../general/TareasTerminadas';

class Valores extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cargando: true
		};
	}

	componentDidUpdate({ modalAdmValores }) {
		let { modalAdmValores: modalModNew, generica } = this.props;
		if (modalModNew !== modalAdmValores) {
			if (modalModNew) this.obtenerValores(generica.id);
		}
	}

	obtenerValores = (generica) => {
		this.setState({ cargando: true });
		let { actualizarDataValores } = this.props;
		consulta(`api/v1.0/generica/${generica}/valores`, null, null, (error, estado, resp) => {
			actualizarDataValores(resp);
			this.setState({ cargando: false });
		});
	};

	guardarValor = async (
		codigo,
		nombre,
		descripcion,
		valora,
		valorb,
		valorc,
		valord,
		valore,
		valorf,
		valorg,
		archivo
	) => {
		let { actualizarMensaje, mostrarModalAddValores, generica } = this.props;
		let { error, estado, resp } = await guardarValorGenerica(
			generica.id,
			codigo,
			nombre,
			descripcion,
			valora,
			valorb,
			valorc,
			valord,
			valore,
			valorf,
			valorg,
			archivo
		);
		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
			tipo = 'error';
		if (!error) {
			if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				this.obtenerValores(generica.id);
				mostrarModalAddValores(false);
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	};

	modificarValor = async (
		id,
		codigo,
		nombre,
		descripcion,
		valora,
		valorb,
		valorc,
		valord,
		valore,
		valorf,
		valorg,
		valorh,
		valori,
		archivo
	) => {
		let { actualizarMensaje, mostrarModalModValores, generica } = this.props;
		let { error, estado, resp } = await modificarValorGenerica(
			id,
			codigo,
			nombre,
			descripcion,
			valora,
			valorb,
			valorc,
			valord,
			valore,
			valorf,
			valorg,
			valorh,
			valori,
			archivo
		);
		let titulo = 'Ha ocurrido un error, contacte con el administrador.',
			tipo = 'error';
		if (!error) {
			if (estado === 200) {
				tipo = 'success';
				titulo = resp.titulo;
				this.obtenerValores(generica.id);
				mostrarModalModValores(false);
			} else {
				tipo = 'info';
				titulo = resp.titulo ? resp.titulo : mostrarError(resp);
			}
		}
		actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
	};

	eliminarValor = (id) => {
		let { actualizarMensaje, mostrarModalDelValores, generica } = this.props;
		consulta(`api/v1.0/valores/${id}/eliminar`, {}, 'put', (error, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!error) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.obtenerValores(generica.id);
					mostrarModalDelValores(false);
				} else {
					tipo = 'info';
					titulo = mostrarError(resp);
				}
			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
		});
	};

	render() {
		let {
			generica,
			valores,
			permisos,
			valor,
			mostrarModalModValores,
			actualizarDataValor,
			mostrarModalDelValores,
			mostrarModalAddValores,
			modalAddValores,
			modalModValores,
			modalDelValores,
			modalAdmValores,
			modalDetValores,
			mostrarModalAdmValores,
			modalAddPermisosValores,
			mostrarModalAddPermisoValores,
			mostrarModalDetValores
		} = this.props;

		const config = () => {
			return {
				inputCodigo: { label: 'Codigo', mostrar: true, tipo: 'text', requerido: false },
				inputNombre: { label: 'Nombre', mostrar: true, tipo: 'text', requerido: true },
				inputDescripcion: { label: 'Descripción', mostrar: true, tipo: 'text', requerido: false },
				inputValora: { label: 'Valor A', mostrar: true, tipo: 'text', requerido: false },
				inputValorb: { label: 'Valor B', mostrar: true, tipo: 'text', requerido: false },
				inputValorc: { label: 'Valor C', mostrar: true, tipo: 'text', requerido: false },
				inputValord: { label: 'Valor D', mostrar: true, tipo: 'text', requerido: false },
				inputValore: { label: 'Valor E', mostrar: true, tipo: 'text', requerido: false },
				inputValorf: { label: 'Valor F', mostrar: true, tipo: 'text', requerido: false },
				inputValorg: { label: 'Valor G', mostrar: true, tipo: 'text', requerido: false },
				inputValorh: { label: 'Valor H', mostrar: true, tipo: 'text', requerido: false },
				inputValori: { label: 'Valor I', mostrar: true, tipo: 'text', requerido: false },
				inputArchivo: { label: 'Adjuntar Archivo', mostrar: true, tipo: 'file', requerido: true }
			};
		};

		const configDetail = (data) => {
			let {
				codigo,
				descripcion,
				valora,
				valorb,
				valorc,
				valord,
				valore,
				valorf,
				valorg,
				valorh,
				valori,
				archivo,
				id,
				nombre
			} = data;

			let array = [
				{ label: 'Nombre', value: nombre },
				{ label: 'Codigo', value: codigo },
				{ label: 'ID', value: id },
				{ label: 'Descripción', value: descripcion },
				{ label: 'Valor A', value: valora },
				{ label: 'Valor B', value: valorb },
				{ label: 'Valor C', value: valorc },
			];

			if (valord) {
				array.push({ label: 'Valor D', value: valord });
			}
			if (valore) {
				array.push({ label: 'Valor E', value: valore });
			}
			if (valorf) {
				array.push({ label: 'Valor F', value: valorf });
			}
			if (valorg) {
				array.push({ label: 'Valor G', value: valorg });
			}
			if (valorh) {
				array.push({ label: 'Valor H', value: valorh });
			}
			if (valori) {
				array.push({ label: 'Valor I', value: valori });
			}

			array.push({
				label: 'Archivo',
				value: archivo
					? () => (
						<a href={`${api}${archivo}`} target="_balck" className="sin_decorador">
							Ver
						</a>
					)
					: null
			})

			return array
		};
		
		let { cargando } = this.state;
		const { genericas } = this.props;
		return (
			<div>
				<Dialog
					fullWidth={true}
					maxWidth="md"
					open={modalAdmValores}
					onClose={mostrarModalAdmValores.bind(this, false)}
					aria-labelledby="form-dialog-title"
				>
					<AppBarModal
						titulo={`¡ ${generica.nombre} !`}
						mostrarModal={mostrarModalAdmValores}
						titulo_accion="CERRAR"
						accion={() => mostrarModalAdmValores(false)}
					/>
					<DialogContent style={{ padding: 0 }} className="scroll">
						{!cargando ? (
							<ValoresListar
								modalAddPermisosValores={modalAddPermisosValores}
								mostrarModalAddPermisoValores={mostrarModalAddPermisoValores}
								mostrarModalModValores={mostrarModalModValores}
								mostrarModalDelValores={mostrarModalDelValores}
								mostrarModalDetValores={mostrarModalDetValores}
								actualizarDataValor={actualizarDataValor}
								valores={valores}
								mostrarModalAddValores={mostrarModalAddValores}
								modalDelValores={modalDelValores}
							/>
						) : (
							<TareasTerminadas imagen={emma_w} mensaje="Cargando..." widthImg="7%" marginTop="7%" />
						)}
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => mostrarModalAdmValores(false)} />
					</DialogActions>
				</Dialog>
				<ValoresAgregarPermiso
					mostrarModalAddPermisoValores={mostrarModalAddPermisoValores}
					modalAddPermisosValores={modalAddPermisosValores}
					permisos={permisos}
					actualizarDataPermisos={actualizarDataPermisos}
					valor={valor}
					genericas={genericas}
				/>
				<ValoresAgregar
					mostrarABC={true}
					modalAddValores={modalAddValores}
					mostrarModalAddValores={mostrarModalAddValores}
					guardarValor={this.guardarValor}
					inputs={config()}
				/>
				<ValoresModificar
					mostrarABC={true}
					modalModValores={modalModValores}
					mostrarModalModValores={mostrarModalModValores}
					valor={valor}
					modificarValor={this.modificarValor}
					inputs={config()}
				/>
				<Detalle
					titulo={'¡ Detalle !'}
					open={modalDetValores}
					config={valor ? configDetail(valor) : []}
					cerrar={() => mostrarModalDetValores(false)}
				/>
				<ConfirmarAccion
					mensaje1={`El valor "${valor.nombre}" será eliminado, `}
					ejecutarAccion={this.eliminarValor}
					mostrarModalConfAccion={mostrarModalDelValores}
					modalConAccion={modalDelValores}
					dato={valor}
					titulo={'¿ Eliminar Valor ?'}
				/>
			</div>
		);
	}

}

//CARGO ESTADOS DEL MODULO
const mapStateToProps = (state) => {
	let {
		modalAddValores,
		modalModValores,
		modalDelValores,
		modalDetValores,
		valor,
		valores,
		modalAddPermisosValores,
		permisos
	} = state.redValores;
	let { generica } = state.redGenericas;
	return {
		modalAddValores,
		modalModValores,
		modalDelValores,
		modalDetValores,
		valor,
		valores,
		permisos,
		generica,
		modalAddPermisosValores
	};
};

//CARGO FUNCIONES DEL MODULO
const mapDispatchToProps = {
	actualizarRenderValores,
	actualizarDataValores,
	actualizarDataValor,
	mostrarModalDelValores,
	mostrarModalModValores,
	mostrarModalAddValores,
	mostrarModalDetValores,
	actualizarMensaje,
	mostrarModalAddPermisoValores,
	actualizarDataPermisos
};

//Props del componente
Valores.propTypes = {
	//variables
	valores: PropTypes.array.isRequired,
	modalModValores: PropTypes.bool.isRequired,
	modalAddValores: PropTypes.bool.isRequired,
	modalDelValores: PropTypes.bool.isRequired,
	modalAdmValores: PropTypes.bool.isRequired,
	modalDetValores: PropTypes.bool.isRequired,
	valor: PropTypes.shape({
		id: PropTypes.number.isRequired,
		codigo: PropTypes.string.isRequired,
		nombre: PropTypes.string.isRequired,
		descripcion: PropTypes.string.isRequired
	}),
	//funciones
	actualizarMensaje: PropTypes.func.isRequired,
	actualizarRenderValores: PropTypes.func.isRequired,
	actualizarDataValores: PropTypes.func.isRequired,
	actualizarDataValor: PropTypes.func.isRequired,
	mostrarModalAddValores: PropTypes.func.isRequired,
	mostrarModalModValores: PropTypes.func.isRequired,
	mostrarModalDelValores: PropTypes.func.isRequired,
	mostrarModalAdmValores: PropTypes.func.isRequired,
	mostrarModalDetValores: PropTypes.func.isRequired,
	mostrarModalAddPermisoValores: PropTypes.func.isRequired,
	actualizarDataPermisos: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Valores);
