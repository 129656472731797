import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AppBarModal from '../general/AppBarModal';
import { BtnForm } from "../general/BotonesAccion";

class PersonasPassword extends Component {

    constructor(props){
        super(props)
        this.state = {
            change_password: '',
            re_change_password: ''
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        let { actualizarMensaje } = this.props
        let { change_password, re_change_password } = this.state
        if(change_password === re_change_password){
            this.props.cambiarPassword(change_password)
            this.setState({
                change_password: '',
                re_change_password: ''
            })
        }else{
            let titulo = 'Las contraseñas no coinciden'
            let tipo = 'info'
            actualizarMensaje({titulo, tipo, mostrar: true, tiempo: 3000})
        }
    }

    onChange = ({ target }) => {
        let { value, name } = target;
        this.setState({
          [name]: value
        })
    }

    render() {
        let { modalEditPass, mostrarModalCambiarPassword } = this.props
        return (
            <Dialog open={modalEditPass} onClose={ () => mostrarModalCambiarPassword(false)} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xs">
              <AppBarModal titulo="¡ Cambiar contraseña !" titulo_accion="" accion="" mostrarModal={ () => mostrarModalCambiarPassword(false)}/>
              <DialogContent className='scroll'>
                <ValidatorForm onSubmit={this.onSubmit}>
                  <TextValidator
                    margin="dense"
                    id="change_password"
                    label="Nueva contraseña"
                    type="password"
                    fullWidth
                    name="change_password"
                    validators={["required"]}
                    value={this.state.change_password}
                    errorMessages={["El campo es requerido"]}
                    onChange={this.onChange}
                  />
                  <TextValidator
                    margin="dense"
                    id="change_password"
                    label="Repetir nueva contraseña"
                    type="password"
                    fullWidth
                    name="re_change_password"
                    validators={["required"]}
                    value={this.state.re_change_password}
                    errorMessages={["El campo es requerido"]}
                    onChange={this.onChange}
                  />
                  <Button color="primary" type="submit" id='enviar_form_edit_password' className="oculto"> Guardar </Button>
                </ValidatorForm>
              </DialogContent>
              <DialogActions>
                <BtnForm texto="CANCELAR" callback={() => mostrarModalCambiarPassword(false)} />
                <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_edit_password').click() }} />
              </DialogActions>
            </Dialog>
          )
    }
}

PersonasPassword.propTypes = {
    modalEditPass: PropTypes.bool.isRequired,
    mostrarModalCambiarPassword: PropTypes.func.isRequired,
    cambiarPassword: PropTypes.func.isRequired
}

export default PersonasPassword;
