// React
import React, { Component, Fragment } from 'react';

// Redux
import { connect } from 'react-redux';
import {
	setEstadosSolicitud,
	setEstado,
	showModalAdjuntos,
	showModalDetalleGrado,
	showModalTrabajoGrado,
	showModalNewAdjuntos,
	showModalEventos,
	showModalModSolicitud,
	setDataSolicitud,
	setAdjuntosEstado,
	setTutores,
	setEventos,
	solicitudesGrados
} from './../../redux/actions/actGrados';
import { actualizarMensaje, mostrarModulo, mostrarModalAdjFaltantes } from './../../redux/actions/actGlobal';

// Material UI
import { Grid, Paper, Card, CardActions, CardContent, CardHeader, Avatar, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
// Material Icons

// Componentes
import GradosListar from './historial/GradosListar';
import Grado from './Grado';
import { GradoDetalleSimple } from './historial/GradoDetalle';
import GradosAdjuntos from './GradosAdjuntos'
import ModalCrearSolicitud from './ModalCrearSolicitud';
import TareasTerminadas from './../general/TareasTerminadas';
import TituloAccion from './../general/TituloAccion';
import AccionesRapidas from './../general/AccionesRapidas';
import emma_s from './../../global/imagenes/emma_s1.png';
import files from './../../global/imagenes/files.png';
import revisar from '../../global/imagenes/revisar.png';
import emma_w from './../../global/imagenes/emma_w.png';
import cancel from './../../global/imagenes/cancel.png';
import Cargando from "./../general/app/Cargando";
import BarraProceso from "../general/BarraProceso";
import EnviarMensaje from "../general/EnviarMensaje";
import { BtnVer, BtnEditar, BtnCargar } from "../general/BotonesAccion";
import MenuGradosEstudiante from "./MenuGradosEstudiante";
import EnviarMultiplesArchivos from "../general/EnviarMultiplesArchivos"
import GradosModificar from "./GradosModificar"
import ConfirmarAccion from "./../general/ConfirmarAccion"
import AlertasSimple from './../general/AlertasSimple'
//imagenes
import folder from '../../global/imagenes/folder.png';
import habla from '../../global/imagenes/habla.png';
import post from '../../global/imagenes/post.png';
import hourglass from '../../global/imagenes/hourglass.png';
import asignatura4 from '../../global/imagenes/asignatura4.png';
import targetaudience from '../../global/imagenes/target-audience.png';
// Funciones
import { formulario, crear_form_data, mostrarError, consulta, obtenerValoresFiltros } from './../../global/js/funciones';
import { validarProcentajeCreditosAprobados, obtenerAdjuntos, getLineaEstados, getEventosSolictud } from './funciones'
import { TIPOS_GRADOS } from "./helper";


const useStyles = makeStyles(theme => ({
	card: {
			maxWidth: "100%",
			marginTop: 35,
			cursor: 'pointer'
	},
	avatar: {
			backgroundColor: red[500],
	},
	image: {
			width: '40%',
			margin: "0 auto"
	},
	contentImg: {
			height: '10%',
			textAlign: "center"
	},
}));

function OpcionGradoCard({ image, data }) {
	const { nombre, descripcion } = data;
	const classes = useStyles();

	return (
			<Card className={classes.card} elevation={0}>
					<CardHeader
							avatar={<Avatar aria-label="recipe" className={classes.avatar}> {nombre.charAt(0)} </Avatar>}
							title={nombre}
					/>
					<CardContent className={classes.contentImg}>
							<img src={image} className={classes.image} alt='Imagen Tipo' />
					</CardContent>
					<CardContent>
							<Typography style={{ minHeight: 100 }} variant="body2" color="textSecondary" component="p" align="justify">
									{descripcion}
							</Typography>
					</CardContent>
			</Card>
	);
}

class Grados extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validado: 0, // -1 No valido, 1 Valido, 0 default
			cargando: true,
			// cargando_envio: false, // se usa para deshabilitar los botones de enviar de los formularios, para evitar multiples peticiones
			seleccion: 'proceso',
			selecion_detalle: "detalle",
			linea_estados: [],
			adj_faltantes: [],
			notificaciones: [],
			modalMensajes: false,
			modalConfirAplazamiento: false,
			modalCancelar: false,
			tiposGrado: [],
			opcionGrado: {},
			programas: []
		};
	}

	async componentDidMount() {
		const { mostrarModulo, solicitudesGrados } = this.props;
		mostrarModulo({ ruta: '/grados', nombre: 'Opciones de Grado' });
		this.getUltimoGrado();
	}

	componentDidUpdate({ }, { seleccion }) {
		let { seleccion: seleccionNew } = this.state;
		if (seleccion != seleccionNew && seleccionNew != "proceso") this.setState({ notificaciones: [] })
		if (seleccion != seleccionNew && seleccionNew == "proceso") this.getUltimoGrado();
		if (seleccion != seleccionNew && seleccionNew == "historial") this.getGrados();
		if (seleccion != seleccionNew && seleccionNew == "nuevo") {
			if (this.state.linea_estados.length == 0) {
				this.setState({ cargando: true })
				consulta(`api/v1.0/grados/last`, null, null, async (error, estado, resp) => {
					if (Object.entries(resp).length == 0) {
						validarProcentajeCreditosAprobados(({ validado }) => {
							this.setState({ validado });
						})
        		let tipos = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOS_GRADOS }, { 'llave': 'estado', 'valor': '1' }, { 'llave': 'valore', 'valor': 'activo' }]);
						this.setState({ tiposGrado: tipos.sort((a, b) => a.valord - b.valord ), cargando: false });
					} else this.setState({ cargando: false });					
				});
			}
		}
	}

	componentWillUnmount() {
		let { setDataSolicitud } = this.props
		setDataSolicitud({})
	}


	// Consultas

	getUltimoGrado = () => {
		let { setEstado } = this.props
		this.setState({ cargando: true })
		consulta(`api/v1.0/grados/last`, null, null, (error, estado, resp) => {
			if (Object.entries(resp).length > 0) {
				this.props.setDataSolicitud(resp);
				getLineaEstados(this.props.solicitud.id, (resp) => this.setState({ linea_estados: resp }))
				this.getAdjFaltantes()
				this.notificacionEstadoActual(resp.estado_actual, resp.estado_anterior)
				this.notificacionDatosPendientes(resp)
				setEstado(resp.estado_actual)
			}
			this.setState({ cargando: false })
		});
	}

	getGrados = () => {
		this.setState({ cargando: true })
		consulta(`api/v1.0/grados/2`, null, null, (error, estado, resp) => {
			if (resp) this.props.solicitudesGrados(resp);
			this.setState({ cargando: false })
		})
	}

	getAdjFaltantes = () => {
		let { solicitud } = this.props;
		consulta(`api/v1.0/grados/${solicitud.id}/adjuntos/faltantes/2`, null, null,
			(error, estado, resp) => {
				if (estado === 200) {
					this.trasnformarDataAdj(resp);
					this.notificacionAdjuntosPendientes(resp);
				}
			})
	}

	// Handllers

	trasnformarDataAdj = (data) => {
		let adj_faltantes = [];
		data.map(({ secundario: { nombre, descripcion, id, archivo, valord, valorc } }) => {
			adj_faltantes.push({ nombre, descripcion, codigo: id, archivo, valord, tipo: valorc });
			return true;
		})
		this.setState({ adj_faltantes });
	}

	handlerClickDocumentos = () => {
		let { solicitud, setAdjuntosEstado, setEstado, showModalAdjuntos } = this.props
		setEstado(solicitud.estado_actual)
		showModalAdjuntos(true)
	}

	handlerClickEventos = () => {
		let { solicitud, setEventos, showModalEventos } = this.props
		getEventosSolictud(solicitud.id, setEventos)
		showModalEventos(true)
	}

	mensajeConfirProrroga = (tipo_grado, estado_actual) => {
		if (tipo_grado == "gr_trabajo_grado" && estado_actual == "tr_est_sus_tr") return "Por favor tenga en cuenta que la defensa del trabajo de grado es prorrógable una sola vez por maximo 2 meses adicionales."
		if (tipo_grado == "gr_trabajo_grado" && estado_actual == "tr_est_sus_tr") return "Por favor tenga en cuenta que la sustentación del trabajo de grado es prorrógable por 8 meses más, luego de pagar el valor del supletorio correspondiente que será definido por acuerdo del Consejo Directivo."
		if (tipo_grado == "gr_articulo" && estado_actual == "gr_est_sus") return "Por favor tenga en cuenta que la sustentación de artículos cientificos es prorrógable una sola vez, la nueva fecha no podrá superar 2 años después de la fecha de publicación del artículo."
		if (tipo_grado == "gr_concurso" && estado_actual == "gr_est_sus") return "Por favor tenga en cuenta que la sustentación de certificaciones y participación en concursos es prorrógable solo una vez y la nueva fecha no podrá superar 1 año después de la fecha de registro de la solicitud."
		if (tipo_grado == "gr_certificado" && estado_actual == "gr_est_sus") return "Por favor tenga en cuenta que la sustentación de certificaciones y participación en concursos es prorrógable solo una vez y la nueva fecha no podrá superar 1 año después de la fecha de registro de la solicitud."
		if (tipo_grado == "gr_empresarial" && estado_actual == "gr_est_sus") return "Por favor tenga en cuenta que la sustentación de producto empresarial es prorrógable solo una vez y la nueva fecha no podrá superar 1 año después de la fecha de registro de la solicitud."
	}

	notificacionAdjuntosPendientes(faltantes) {
		this.limpiarNotificaciones('requisitos');
		let { notificaciones } = this.state;
		let { estado } = this.props;
		if (faltantes.length > 0) {
			let mensaje = "Tienes algunos requisitos pendientes, cuando tengas la información requerida puedes cargarla para continuar con tu proceso."
			let notificacion = {
				icono: folder,
				titulo: faltantes.length > 1 ? `¡ ${faltantes.length} Requisitos Pendientes !` : `¡ ${faltantes.length} Requisito Pendiente !`,
				mensaje,
				tipo: 'requisitos',
				acciones: () => {
					if (estado.codigo == 'apr_obv') {
						return <BtnVer texto='Ver Requisitos' callback={() => this.handlerClickDocumentos()} color='#01579b' />
					} else {
						return <BtnCargar texto='Cargar Requisitos' callback={() => this.props.mostrarModalAdjFaltantes(true)} color='#01579b' />
					}
				}
			}
			notificaciones.push(notificacion)
			this.setState({ notificaciones })
		}
	}

	notificacionEstadoActual(estado_actual, estado_anterior) {
		this.limpiarNotificaciones('sustentacion');
		this.limpiarNotificaciones('tutores');
		this.limpiarNotificaciones('prorrogas');
		let { notificaciones } = this.state;
		if (estado_actual.codigo == "tr_est_sus_tr" || estado_actual.codigo == "tr_est_sus_tr" || estado_actual.codigo == "gr_est_sus") {
			let mensaje = "Tiene una defensa pendiente. Revisa tu calendario en Teams"
			let notificacion = {
				icono: habla,
				titulo: `¡ Defensa !`,
				mensaje,
				tipo: 'sustentacion',
				acciones: () => <BtnVer texto='ver' callback={() => {
					this.setState({ selecion_detalle: "estados" })
					this.props.showModalDetalleGrado(true)
				}} color='#01579b' />,
			}
			notificaciones.push(notificacion)
			this.setState({ notificaciones })
		}
		if (estado_actual.codigo == "tr_est_des_tr" && estado_anterior == "tr_est_coor") {
			let mensaje = "Hola, tu solicitud ya cuenta con tutores de grado asignados"
			let notificacion = {
				icono: targetaudience, titulo: `¡ Ya tienes tutores asiganados !`, mensaje, tipo: 'tutores', acciones: () => <BtnVer texto='ver' callback={() => {
					this.setState({ selecion_detalle: "gestores" })
					this.props.showModalDetalleGrado(true)
				}} color='#01579b' />,
			}
			notificaciones.push(notificacion)
			mensaje = "A partir de la la fecha de asignación de tutores e inicio de proyecto de investigación, tendras 4 meses para la sustentación de la propuesta de investigación."
			notificacion = { icono: hourglass, titulo: `¡ Prepara tu sustentación !`, mensaje, tipo: 'sustentacion', }
			notificaciones.push(notificacion)
			this.setState({ notificaciones })

		}
		if (estado_actual.codigo == "tr_est_des_tr" && estado_anterior == "gr_est_ini") {
			let mensaje = "A partir de la Aprobación de la sustentación del Proyecto de investigación, los estudiantes tendrá 8 meses máximo para sustentar su Trabajo de Grado."
			let notificacion = { icono: hourglass, titulo: `¡ Prepara tu defensa !`, mensaje, tipo: 'sustentacion', }
			notificaciones.push(notificacion)
			this.setState({ notificaciones })
		}
		// if (estado_actual.codigo == "tr_est_des_tr" && estado_anterior == "tr_est_sus_tr") {
		// 	let mensaje = "Debes realizar ajustes y fortalecer el proyecto de investigación para la asignación de una nueva fecha de sustentación."
		// 	let notificacion = { icono: asignatura4, titulo: `¡ Fortalece tu Proyecto de Investigación !`, mensaje, tipo: 'sustentacion', }
		// 	notificaciones.push(notificacion)
		// 	this.setState({ notificaciones })
		// }
		if (estado_actual.codigo == "tr_est_des_tr" && estado_anterior == "tr_est_sus_tr") {
			let mensaje = "Debes realizar ajustes y fortalecer tu Trabajo de Grado para la asignación de una nueva sustentación en un plazo no mayor a 7 días calendario."
			let notificacion = { icono: asignatura4, titulo: `¡ Fortalece tu Trabajo de Grado!`, mensaje, tipo: 'sustentacion', }
			notificaciones.push(notificacion)
			this.setState({ notificaciones })
		}
		if (estado_actual.codigo === 'apr_obv') {
			let mensaje = "Debes realizar los ajustes de tu trabajo de grado en un plazo no mayor a 7 días calendario."
			let notificacion = { icono: asignatura4, titulo: '¡ Fortalece tu Trabajo de Grado !', mensaje, tipo: 'sustentacion' }
			notificaciones.push(notificacion)
			this.setState({ notificaciones })
		}
	}

	notificacionDatosPendientes(solicitud) {
		this.limpiarNotificaciones('datos');
		let { notificaciones } = this.state;
		if (solicitud.tipo.codigo == "gr_articulo") {
			if (!solicitud.doi_articulo || !solicitud.url_articulo || !solicitud.issn_revista || !solicitud.volumen_revista || !solicitud.numero_revista) {
				let mensaje = "Hacen falta algunos datos, cuando tengas la información requerida puedes completarla para continuar con tu proceso."
				let notificacion = {
					icono: post,
					titulo: `¡ Datos pendientes !`,
					mensaje,
					tipo: 'datos',
					acciones: () => <BtnEditar texto='Agregar Datos' callback={() => {
						consulta(`api/v1.0/grados/last`, null, null, (error, estado, resp) => {
							if (Object.entries(resp).length > 0) {
								this.props.setDataSolicitud(resp);
							}
							this.props.showModalModSolicitud(true)
						});
					}} color='#01579b' />,
				}
				notificaciones.push(notificacion)
				this.setState({ notificaciones })
			}
		}
	}

	limpiarNotificaciones(tipo) {
		let { notificaciones } = this.state;
		let item = -1;
		notificaciones.map((noti, i) => {
			if (noti.tipo === tipo) {
				item = i;
				return true;
			}
		})

		if (item > -1) {
			notificaciones.splice(item, 1);
			this.setState({ notificaciones })
		}
	}

	// Acciones


	// guardarSolicitud = async (celular, archivos, programa, tipo, integrantes, tutor_propuesto, cotutor_propuesto, estado_articulo, nombre_articulo, revista, grupo_investigacion, url_articulo, doi_articulo, issn_revista, numero_revista, volumen_revista, emisor_certificado, descripcion_certificado) => {
	guardarSolicitud = async (data, integrantes, callback, archivos = []) => {
		let { showModalTrabajoGrado, solicitudesGrados, actualizarMensaje } = this.props;
		let total = archivos.length
		let total_integrantes = integrantes.length
		// if (total_integrantes == 0) {
		// 	total_integrantes = 1
		// 	integrantes.push({ id: this.props.usuario.id, programa: data.programa })
		// }
		data.total_integrantes = total_integrantes
		data.total = total
		let formdata = await crear_form_data(data);
		archivos.map(({ archivo, codigo }, index) => {
			formdata.append(`archivo${index}`, archivo);
			formdata.append(`codigo${index}`, codigo);
		})
		integrantes.map(({ id, programa }, index) => {
			formdata.append(`integrante${index}`, id);
			formdata.append(`programa${index}`, programa.id);
		})
		// this.setState({ cargando_envio: true })
		formulario(`api/v1.0/grados/crear`, formdata, 'post', (err, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
			if (!err) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					showModalTrabajoGrado(false);
					this.setState({ seleccion: "proceso" })
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}

			}
			callback()
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
			// this.setState({ cargando_envio: false })
		});
	};

	modificarSolicitud = async (solicitud, url_articulo, doi_articulo, issn_revista, volumen_revista, numero_revista, estado_articulo) => {
		let { showModalModSolicitud, actualizarMensaje } = this.props;
		let data = await crear_form_data({ url_articulo, doi_articulo, issn_revista, volumen_revista, numero_revista, estado_articulo });
		formulario(`api/v1.0/grados/grado/${solicitud}`, data, 'patch', (err, estado, resp) => {
			let titulo = 'Ha ocurrido un error, contacte con el administrador.',
				tipo = 'error';
			if (!err) {
				if (estado === 200) {
					tipo = 'success';
					titulo = resp.titulo;
					this.limpiarNotificaciones('datos');
					showModalModSolicitud(false);
					this.getUltimoGrado();
				} else {
					tipo = 'info';
					titulo = resp.titulo ? resp.titulo : mostrarError(resp);
				}

			}
			actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 })
		});
	};

	guardarComentario = (data) => {
		let { actualizarMensaje } = this.props;
		consulta("api/v1.0/grados/comentario/crear", data, "post",
			(error, estado, resp) => {
				let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
				if (!error) {
					if (estado === 200) {
						tipo = 'success';
						titulo = resp.titulo;
						this.setState({ modalMensajes: false, modalConfirAplazamiento: false })
					} else {
						tipo = 'info';
						titulo = resp.titulo ? resp.titulo : mostrarError(resp)
					}
				}
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
			}
		);
	}

	guardarAdjuntosNuevos = (archivos = [], callback) => {
		let { actualizarMensaje, solicitud, setAdjuntosEstado, mostrarModalAdjFaltantes } = this.props;
		let { adj_faltantes } = this.state
		// this.setState({ cargando_envio: true })
		if (archivos.length >= adj_faltantes.length) {
			const data = new FormData();
			archivos.map(({ archivo, codigo }, index) => {
				data.append(`archivo${index}`, archivo);
				data.append(`codigo${index}`, codigo);
			});
			data.append('total', archivos.length);
			formulario(`api/v1.0/grados/${solicitud.id}/adjuntos/nuevos`, data, "post", (error, estado, resp) => {
				let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
				if (!error) {
					if (estado === 200) {
						tipo = 'success';
						titulo = resp.titulo;
						this.limpiarNotificaciones('requisitos');
						mostrarModalAdjFaltantes(false)
					} else {
						tipo = 'info';
						titulo = resp.titulo ? resp.titulo : mostrarError(resp);
					}
				}
				obtenerAdjuntos(solicitud.id, setAdjuntosEstado);
				this.getAdjFaltantes();
				callback();
				actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
				// this.setState({ cargando_envio: false })
			})
		} else actualizarMensaje({ titulo: 'Debe adjuntar un archivo por cada requisito', tipo: 'info', mostrar: true, tiempo: 2000 });
	}

	// Componentes

	handleTiposDeTrabajoGrado = (opcion) => {
		this.setState({ opcionGrado: opcion });
		this.props.showModalTrabajoGrado(true);
	};

	nuevo = () => {
		let { solicitud, grados, showModalTrabajoGrado, modalNuevoGrado, actualizarMensaje } = this.props;
		let { validado, tiposGrado, opcionGrado, programas } = this.state;
		let aprobados = grados.filter(e => (e.condecoracion != 0 && e.condecoracion != 1))
		if (Object.entries(solicitud).length == 0) {
			// if ([].length == 0) {
				if (validado == 1) {
					return (
						<>
							<Grid container direction={'row'} justifyContent={'left'} style={{ marginBottom: "100px" }}>
								{tiposGrado &&
									tiposGrado.map((opcion) => (
										<Grid item xs={12} sm={6} md={4} onClick={() => this.handleTiposDeTrabajoGrado(opcion)} style={{ padding: '2% 2% 0% 2%' }} key={opcion.id}>
											<Paper elevation={8} square>
												<OpcionGradoCard image={opcion.archivo} data={{ nombre: opcion.nombre, descripcion: opcion.descripcion }} />
											</Paper>
										</Grid>
									))}
							</Grid>
							<ModalCrearSolicitud
								opcionGrado={opcionGrado}
								modalAdd={modalNuevoGrado}
								mostrarModalAdd={() => showModalTrabajoGrado(false)}
								guardarSolicitud={this.guardarSolicitud}
								actualizarMensaje={actualizarMensaje}
							/>
						</>
						)
				}
				else if (validado == 0) return <div onClick={() => this.setState({ seleccion: 'proceso', cargando: true })} className='pointer'><TareasTerminadas mensaje='Hola, solo puedes tener un proceso activo. Clic aquí para ver tu proceso actual' marginTop='7%' imagen={emma_s} widthImg="7%"  /></div>
				else if (validado == -1) return <TareasTerminadas mensaje='Este proceso aún no esta disponible para tí, debes cumplir mínimo con el 75% de los créditos aprobados de tu programa' marginTop='7%' imagen={emma_s} widthImg="7%" />
				else if (validado == null) return <TareasTerminadas mensaje='Ha ocurrido un error, contacte al administrador' marginTop='7%' imagen={emma_s} widthImg="7%"  />
			// } else return <div onClick={() => this.setState({ seleccion: 'historial' })}><TareasTerminadas mensaje='Usted ya cuenta con un proceso aprobado. Clic aquí para ver tu historial' marginTop='7%' imagen={emma_s} widthImg="7%"  /></div>
		} else return <div onClick={() => this.setState({ seleccion: 'proceso', cargando: true })} className='pointer'><TareasTerminadas mensaje='Hola, solo puedes tener un proceso activo. Clic aquí para ver tu proceso actual' marginTop='7%' imagen={emma_s} widthImg="7%" /></div>
	};

	historial = () => {
		let { grados, setDataSolicitud, showModalDetalleGrado, modalAdjuntos } = this.props;
		return (
			<GradosListar
				admin={false}
				grados={grados}
				setDataSolicitud={setDataSolicitud}
				showModalDetalleGrado={showModalDetalleGrado}
			/>
		)
	}

	proceso = () => {
		let { solicitud, showModalDetalleGrado } = this.props;
		let { cargando } = this.state;
		if (!cargando) {
			if (Object.entries(solicitud).length > 0) {
				return (
					<div style={{ padding: '2% 2% 0% 2%' }}>
						<Grid container direction={'row'} spacing={1}>
							<Grid item xs={12} sm={12} md={6}>
								<TituloAccion principal='' alterno='¡ Información Básica !' />
								<Paper elevation={0} square style={{ maxHeight: '450px', overflow: 'auto' }} className="scroll">
									<Card elevation={0}>
										<CardContent style={{ padding: 0, maxHeight: '300px', overflow: 'auto' }} className="scroll">
											<GradoDetalleSimple solicitud={solicitud} info_completa={false} />
										</CardContent>
										<CardActions disableSpacing>
										</CardActions>
									</Card>
								</Paper>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<TituloAccion principal='' alterno='¿ Que deseas hacer ?' />
								<Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
									<Grid item xs={12} sm={12} md={12}>
										<Paper onClick={() => showModalDetalleGrado(true)} elevation={1} style={{ border: '1px solid #e0e0e0' }}>
											<AccionesRapidas imagen={revisar} titulo="Más Información !" sub_titulo="Vale" des_sub_titulo=", En esta opción puedes ver los detalles de tu solicitud." />
										</Paper>
									</Grid>
									<Grid item xs={12} sm={12} md={12}>
										<Paper onClick={() => this.handlerClickDocumentos()} elevation={1} style={{ border: '1px solid #e0e0e0' }}>
											<AccionesRapidas imagen={files} titulo="Mis Documentos !" sub_titulo="Listo" des_sub_titulo=", Aquí puedes descargar, agregar o remplazar tus archivos." />
										</Paper>
									</Grid>
									{ solicitud && solicitud.estado_actual.codigo == 'gr_est_ini' && 
										<Grid item xs={12} sm={12} md={12}>
											<Paper onClick={() => this.setState({ modalCancelar: true })} elevation={1} style={{ border: '1px solid #e0e0e0' }}>
												<AccionesRapidas imagen={cancel} titulo="¿ Cancelar Proceso ?" sub_titulo="Ok" des_sub_titulo=", aqui puedes cancelar tu proceso." />
											</Paper>
										</Grid>
									}
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={12} >
								<TituloAccion principal='' alterno='¿ Como va mi proceso ?' />
								{this.lineaDeEstados()}
							</Grid>
							<EnviarMensaje
								modal={this.state.modalConfirAplazamiento}
								titulo={'¿ Solicitar Aplazamiento ?'}
								type='text'
								label='Motivo'
								placeholder='Describa brevemente un motivo'
								enviar={(mensaje) => this.guardarComentario({ mensaje, grado: solicitud.id, tipo: 2 })}
								mostrarModal={() => this.setState({ modalConfirAplazamiento: false })}
								extraComponent={<AlertasSimple tipo='warnig' titulo='Atención' sub_titulo={this.mensajeConfirProrroga(solicitud.tipo.codigo, solicitud.estado_actual.codigo)} />}
							/>
						</Grid>
					</div>
				);
			} else return <div onClick={() => this.setState({ seleccion: 'nuevo' })} className='pointer'><TareasTerminadas mensaje='Hola, no cuentas con un proceso activo, clic aquí para iniciar un nuevo proceso' imagen={emma_s} widthImg='7%' marginTop='7%' /></div>
		} else return <Cargando width='100%' marginTop='15%' />;

	}

	misAdjuntos = () => {
		let { solicitud, modalAdjuntos, showModalAdjuntos, showModalNewAdjuntos, mostrarModalAdjFaltantes } = this.props;
		let { adj_faltantes } = this.state
		return (
			<GradosAdjuntos
				admin={false}
				solicitud={solicitud}
				modalAdjuntos={modalAdjuntos}
				adj_faltantes={adj_faltantes}
				showModalAdjuntos={showModalAdjuntos}
				showModalNewAdjuntos={showModalNewAdjuntos}
				mostrarModalAdjFaltantes={mostrarModalAdjFaltantes}
				getAdjFaltantes={this.getAdjFaltantes}
			/>
		)
	}

	lineaDeEstados = () => {
		let { linea_estados } = this.state;
		let { solicitud } = this.props;
		if (linea_estados.length > 0) {
			let activeStep = 0, steps = [], aux = 0
			linea_estados.map(({ secundario }, i) => {
				if (secundario.id === solicitud.estado_actual.id) activeStep = i
				steps.push({ label: secundario.nombre })
			})
			// if (activeStep == 0 && linea_estados[0].secundario.id != solicitud.estado_actual.id) {
			// 	activeStep = linea_estados.findIndex(e => e.secundario.codigo == solicitud.estado_anterior)
			// }
      activeStep = solicitud.estado_actual.codigo === 'gr_est_fin' ? linea_estados.length + 1 : activeStep;
			return <BarraProceso steps={steps} activeStep={activeStep} />;
		}
		return '';
	}

	cancelarSolicitud = () => {
    let { actualizarMensaje, solicitud, setDataSolicitud } = this.props
    consulta(`api/v1.0/grados/${solicitud.id}/cancelar`, {}, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
					setDataSolicitud({})
					this.getUltimoGrado();
          this.setState({ linea_estados: [], modalCancelar: false })
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 9000 });
    })
  }

	render() {
		// let { selecion_detalle, modalMensajes, cargando, seleccion, notificaciones, cargando_envio } = this.state
		let { selecion_detalle, modalMensajes, cargando, seleccion, notificaciones, modalCancelar } = this.state
		let { solicitud, estado, estados, adjuntos, eventos, tutores, modalDetalleGrado, modalEventos, modalModSolicitud, showModalDetalleGrado, showModalAdjuntos, showModalEventos, showModalModSolicitud, actualizarMensaje, setEstadosSolicitud, setAdjuntosEstado, setTutores, setEstado, setEventos, persona } = this.props;
		let m = ""
		return (
			<div>
			 <MenuGradosEstudiante
					notificaciones={notificaciones}
					seleccionar={(seleccion) => seleccion !== 'notificaciones' && this.setState({ seleccion })}
					sele={seleccion}
				/>
				<div className='contenido_modulo'>
					{!cargando ?
						<>
							{seleccion === 'nuevo' && this.nuevo()}
							{seleccion === 'proceso' && this.proceso()}
							{seleccion === 'historial' && this.historial()}
						</>:
						<TareasTerminadas imagen={emma_w} mensaje='Cargando..' cargando={cargando} widthImg='7%' marginTop='7%' />
					}
					<EnviarMultiplesArchivos
						// acciones={this.accionesAdjFaltantes}
						modalMulArchivos={this.props.modalAdjFaltantes}
						titulo='¡ Adjuntos Pendientes !'
						// enviarArchivos={(archivos, callback) => !cargando_envio ? this.guardarAdjuntosNuevos(archivos, callback) : () => { }}
						enviarArchivos={(archivos, callback) => this.guardarAdjuntosNuevos(archivos, callback)}
						mostrarModalMulArchivos={() => this.props.mostrarModalAdjFaltantes(false)}
						limite={2}
						mostrarRender={true}
						mostrar={true}
						enLista={true}
						lista={this.state.adj_faltantes}
					/>
				{/* {this.pintar()} */}
				{this.misAdjuntos()}
				<Grado
					admin={false}
					sele={selecion_detalle}
					seleccionar={(selecion_detalle) => this.setState({ selecion_detalle })}
					solicitud={solicitud}
					adjuntos={adjuntos}
					tutores={tutores}
					estados={estados}
					setAdjuntosEstado={setAdjuntosEstado}
					setEstadosSolicitud={setEstadosSolicitud}
					setEstado={setEstado}
					setTutores={setTutores}
					showModalAdjuntos={showModalAdjuntos}
					showModalDetalleGrado={showModalDetalleGrado}
					showModalConfirAplazamiento={() => this.setState({ modalConfirAplazamiento: true })}
					modalDetalleGrado={modalDetalleGrado}
					eventos={eventos}
					setEventos={setEventos}
					modalEventos={modalEventos}
					showModalEventos={showModalEventos}
					actualizarMensaje={actualizarMensaje}
					data_session={persona}
				/>
				<GradosModificar
					solicitud={solicitud}
					modalModSolicitud={modalModSolicitud}
					showModalModSolicitud={showModalModSolicitud}
					actualizarMensaje={actualizarMensaje}
					modificarSolicitud={this.modificarSolicitud}
				/>
				<EnviarMensaje
					modal={modalMensajes}
					titulo={'¡ Enviar Mensaje !'}
					type='text'
					label='Redactar'
					enviar={(mensaje) => { this.guardarComentario({ mensaje, grado: solicitud.id, tipo: 1 }) }}
					mostrarModal={() => this.setState({ modalMensajes: false })}
				/>
				<ConfirmarAccion
            titulo='¿ Cancelar Solicitud ?'
            dato={{ id: 0 }}
            mensaje1='¿Estás seguro(a) que deseas cancelar la solicitud de Opciones de Grado?'
            modalConAccion={modalCancelar}
            mostrarModalConfAccion={() => this.setState({ modalCancelar: false })}
            ejecutarAccion={this.cancelarSolicitud}
        />
				</div>
			</div>)
	}


}

const mapStateToProps = (state) => {
	let { solicitud, estado, adjuntos, estados, grados, tutores, eventos, modalAdjuntos, modalNewAdjuntos, modalDetalleGrado, modalNuevoGrado, modalEventos, modalModSolicitud } = state.redGrados;

	let { persona } = state.redPersonas;
	let { usuario, modalAdjFaltantes } = state.redGlobal;

	return {
		adjuntos,
		estados,
		grados,
		tutores,
		eventos,
		modalNuevoGrado,
		modalAdjuntos,
		modalNewAdjuntos,
		modalDetalleGrado,
		modalEventos,
		modalAdjFaltantes,
		modalModSolicitud,
		persona,
		solicitud,
		usuario,
		estado
	};
};

const mapDispatchToProps = {
	actualizarMensaje,
	setEstadosSolicitud,
	setEstado,
	setAdjuntosEstado,
	mostrarModulo,
	mostrarModalAdjFaltantes,
	showModalAdjuntos,
	showModalNewAdjuntos,
	showModalDetalleGrado,
	showModalTrabajoGrado,
	showModalEventos,
	showModalModSolicitud,
	setDataSolicitud,
	setTutores,
	setEventos,
	solicitudesGrados
};

export default connect(mapStateToProps, mapDispatchToProps)(Grados);