import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Stepper, Step, StepLabel, Button, Grid, Table, TableBody, TableCell, TableRow, Dialog, DialogContent, DialogActions, IconButton, Box, TextField, Link, Chip } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import AppBarModal from '../general/AppBarModal'
import { BtnForm, BtnVer } from "../general/BotonesAccion";
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import BuscarSelect from '../general/BuscarSelect';
import InputBuscar from '../general/InputBuscar';
import emma_w from '../../global/imagenes/emma_w.png';
import emma_s from '../../global/imagenes/emma_s1.png';
import TareasTerminadas from "../general/TareasTerminadas";
import Alerta from "../general/Alerta";
import AlertasSimple from "../general/AlertasSimple";
import PracticasBuscarEmpresa from "./PracticasBuscarEmpresa";
import ValoresAgregar from '../genericas/valores/ValoresAgregar'
import { obtenerPermisosValor, obtenerValoresFiltros, consulta, generarFiltros, obtenerValores, transformar, mostrarError, crear_form_data, formulario } from "../../global/js/funciones";
import { CONTRATOS, VALOR_CONTRATO,TIPOEMPRESAS } from './helper';
import { AddIcCallOutlined, AttachFile } from '@material-ui/icons';
import { connect } from 'react-redux';
import InputFile from '../general/InputFile';
// import {mostrarModalAddValoresSucursal} from "../../redux/actions/actPracticas"

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


function obtenerItems(tipo) {
  return (tipo) ? ['Escenario', 'Tutor'] : ['Empresa', 'Funciones', 'Jefe'];
}

function obtenerContenido(step, props) {
  switch (step) {
    case 0:
      return <PintarEmpresa props={props} />;
    case 1:
      return <PintarFunciones props={props} />;
    case 2:
      return <PintarJefe props={props} />;
    default:
      return 'Unknown step';
  }
}

function obtenerContenidoClinicas(step, props) {
  switch (step) {
    case 0:
      return <PintarEscenario props={props} />;
    case 1:
      return <PintarTutor props={props} />;
    default:
      return 'Unknown step';
  }
}

function HorizontalLinearStepper({ props }) {
  const classes = useStyles();
  const [skipped] = React.useState(new Set());
  const steps = obtenerItems(props.practicaTipo);

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  return (
    <div className={classes.root}>
      <div>
        {props.activeStep === steps.length ? (
          <div>
            <PintarDatosEmpresa practica={props.practica} acuerdo={(props.acuerdos && props.acuerdos[0]) || ''}/>
          </div>
        ) : (

            <div style={{ padding: "5%" }}>
              <Stepper activeStep={props.activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              { (props.practicaTipo) ? obtenerContenidoClinicas(props.activeStep, props) : obtenerContenido(props.activeStep, props)}
            </div>
          )}
      </div>
    </div >
  );
}



const PintarEmpresa = ({ props }) => {
  let {
    cambiarEstado,
    empresa,
    fecha_inicio,
    duracion,
    setFechaInicio,
    onChange,
    objetivo_practica,
    tminimo,
    tmaximo,
    mostrarModalAddValores,
    modalAddValores,
    sucursal,
    sucursales,
    practica,
    modal_buscar_empresa,
    acuerdos,
    habilitado,
    cambiar_empresa,
    contratos,
    contrato,
    tipoempresas,
    tipoempresa,
    direccion,
    configSucursal,
    guardarSucursalPermiso,
    mostrarModalAddValoresSucursal,
    adj_arl,
    nombre_archivo,
    fileInput,
    handleFileChange
  } = props;
  
  return (

    <Grid container spacing={3}>
      {
        (practica && !practica.empresa) && <Grid item xs={12} md={12} style={{ textAlign: 'right' }}> <Button color='primary' onClick={() => mostrarModalAddValores(true)} align='ri'>Crear Empresa</Button></Grid>
      }
      <Grid item xs={12} md={12}>
        < PracticasBuscarEmpresa
          seleccionar={({ id, nombre }) => {
            cambiarEstado({ empresa: { value: id, label: nombre }, modal_buscar_empresa: false, habilitado : true, sw_sucursal : true })
          }}
          modalBuscarEmpresa={modal_buscar_empresa}
          mostrarModalBuscarEmpresa={(modal_buscar_empresa) => cambiarEstado({ modal_buscar_empresa })}
        />
        <InputBuscar
          placeholder='Seleccione Empresa'
          label={'¿ En que empresa realizaras tu proceso ?'}
          id='empresa_id' value={(empresa && empresa.label) ? empresa.label : ''}
          callback={() => cambiar_empresa && cambiarEstado({ modal_buscar_empresa: true })} />
      </Grid>
      <Box ml={1.5} sx={{ flexGrow: 1 }}>
       
          <BuscarSelect habilitado={habilitado} datos={sucursales} cambiarEstado={(value) => cambiarEstado({ sucursal: value})} valor={sucursal} id='select-sucursal' nombre='¿En que sucursal realizaras tu proceso ?' placeholder='Seleccione sucursal' />
          </Box>
         {
         empresa &&
         <Grid item>
            
          <IconButton onClick={()=>mostrarModalAddValoresSucursal(true)} aria-label="Add" style={{ color: '#c79e32' }}>
             <AddIcon /> 
          </IconButton>
          </Grid>
          
          }
          {/* <ValoresAgregar
          mostrarABC={true}
          modalAddValores={modalAddValores}
          mostrarModalAddValores={mostrarModalAddValores}
          guardarValor={guardarSucursalPermiso}
          inputs={configSucursal}
          titulo='Nueva Sucursal'
        /> */}
      

      <Grid item xs={12} md={12}>
        <BuscarSelect datos={contratos} cambiarEstado={(value) => cambiarEstado({ contrato: value })} valor={contrato} id='select-contrato' nombre='¿Con que tipo de contrato realizaras tu proceso ?' placeholder='Seleccione tipo de contrato' />
      </Grid>
      <Grid item xs={12} md={12}>
        <BuscarSelect datos={tipoempresas} cambiarEstado={(value) => cambiarEstado({ tipoempresa: value })} valor={tipoempresa} id='select-tipoempresa' nombre='¿Con que tipo de empresa realizaras tu proceso ?' placeholder='Seleccione el tipo de empresa' />
      </Grid>
      <Grid item xs={12} md={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <Grid container>
            <KeyboardDatePicker
              required={true}
              style={{ marginTop: 3, width: '100%' }}
              minDate={moment(practica.fecha_registro).subtract(2, 'M').format('YYYY-MM-DD')}
              margin="normal"
              label="Fecha Inicio"
              format="yyyy-MM-dd"
              value={fecha_inicio}
              onChange={setFechaInicio}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDateMessage={`La fecha debe ser mayor que la fecha registro.`}
              invalidDateMessage='Formato Invalido'
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Alerta elemento={(evento) => (
        <Grid item xs={12} md={6}>
          <TextValidator
            onClick={(practica && practica.tipo.valora === 'reconocimiento') && evento}
            margin="dense"
            id="duracion"
            label={`Duración(Min ${tminimo} y Max ${tmaximo} Meses)`}
            type="text"
            fullWidth
            name="duracion"
            value={duracion ? duracion : ''}
            validators={["required", "isNumber", `minNumber:${tminimo}`, `maxNumber:${tmaximo}`]}
            errorMessages={["El campo es requerido", "El campo debe ser Numérico", `El campo debe ser mayor o igual a ${tminimo}`, `El campo debe ser menor o igual a ${tmaximo}`]}
            onChange={onChange}
          />
        </Grid>

      )}
        width='60%'
        titulo='Nota'
        tipo='info'
        mensaje='La duración minima por defecto son 3 meses, pero en las practicas por reconocimiento el tiempo puede ser menor, esto depende del proceso interno que realice la universidad.'
      />
      <Grid item xs={12}>
        <TextValidator
          multiline
          margin="dense"
          id="objetivo_practica"
          label="Objetivo Practica"
          type="text"
          fullWidth
          name="objetivo_practica"
          value={objetivo_practica ? objetivo_practica : 'Afianzar conocimientos teóricos práctico a través de la participación  en el ámbito laboral.'}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className="oculto"
          ref={fileInput}
          type="file"
          id="adj_arl"
          name="adj_arl"
          label="Adjuntar ARL."
          fullWidth
          autoComplete="billing address-line1"
          onChange={handleFileChange}
        />
        <InputFile label='Adjuntar ARL' id='adj_arl' value={adj_arl.name} />
        {nombre_archivo ? <Chip size="small" color="primary" clickable label="Ver Archivo" icon={<AttachFile />} component="a" href={`${adj_arl}`} target="_blank" /> : ''}
      </Grid>
      <Grid>
      <AlertasSimple titulo='Acuerdo' sub_titulo={(acuerdos && acuerdos[0]) || ''} />
        <AlertasSimple titulo='Acuerdo' sub_titulo={(acuerdos && acuerdos[1]) || ''} tipo='warnig' />
      </Grid>
    </Grid >
  )
}

const PintarFunciones = ({ props }) => {
  let {
    // horario,
    onChange,
    auxilio,
    funciones,
    horario_salida,
    setHorarioSalida,
    horario_entrada,
    setHorarioEntrada,
    area_desarrollo
  } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <Grid container>
            <KeyboardTimePicker
              required={true}
              style={{ marginTop: 3, width: '100%' }}
              margin="normal"
              label="Hora Entrada"
              value={horario_entrada}
              onChange={setHorarioEntrada}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage='Formato Invalido'
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} md={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <Grid container >
            <KeyboardTimePicker
              required={true}
              style={{ marginTop: 1, width: '100%' }}
              margin="normal"
              label="Hora Salida"
              value={horario_salida}
              onChange={setHorarioSalida}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage='Formato Invalido'
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="horario"
          label="Horario"
          type="text"
          fullWidth
          name="horario"
          value={horario}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="auxilio"
          label="$Auxilio/Salario"
          type="text"
          fullWidth
          name="auxilio"
          value={auxilio}
          validators={["required", "isNumber"]}
          errorMessages={["El campo es requerido", "El campo debe ser Numérico"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="area_desarrollo"
          label="Área Desarrollo"
          type="text"
          fullWidth
          name="area_desarrollo"
          value={area_desarrollo}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValidator
          multiline
          margin="dense"
          id="funciones"
          label="Funciones/Plan de Trabajo"
          type="text"
          fullWidth
          name="funciones"
          value={funciones}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

const PintarJefe = ({ props }) => {
  let {
    onChange,
    jefe,
    cargo_jefe,
    correo_jefe,
    telefono_jefe,
  } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="jefe"
          label="Jefe Inmediato"
          type="text"
          fullWidth
          name="jefe"
          value={jefe}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="cargo_jefe"
          label="Cargo Jefe Inmediato"
          type="text"
          fullWidth
          name="cargo_jefe"
          value={cargo_jefe}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="correo_jefe"
          label="Correo Jefe Inmediato"
          type="text"
          fullWidth
          name="correo_jefe"
          value={correo_jefe}
          validators={["required", "isEmail"]}
          errorMessages={["El campo es requerido", "Correo Invalido"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="telefono_jefe"
          label="Teléfono Jefe"
          type="text"
          fullWidth
          name="telefono_jefe"
          value={telefono_jefe}
          validators={["required", "isNumber"]}
          errorMessages={["El campo es requerido", "El campo debe ser Numérico"]}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

const PintarEscenario = ({ props }) => {
  let {
    cambiarEstado,
    empresa,
    fecha_inicio,
    duracion,
    setFechaInicio,
    onChange,
    objetivo_practica,
    tminimo,
    tmaximo,
    mostrarModalAddValores,
    sucursal,
    sucursales,
    practica,
    modal_buscar_empresa,
    acuerdos,
    habilitado,
    cambiar_empresa,
  } = props;
  return (

    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <PracticasBuscarEmpresa
          seleccionar={({ id, nombre }) => {
            cambiarEstado({ empresa: { value: id, label: nombre }, modal_buscar_empresa: false, habilitado : true, sw_sucursal : true })
          }}
          modalBuscarEmpresa={modal_buscar_empresa}
          mostrarModalBuscarEmpresa={(modal_buscar_empresa) => cambiarEstado({ modal_buscar_empresa })}
        />
        <InputBuscar
          placeholder='Seleccione Escenario'
          label={'¿ En que escenario realizaras tu proceso ?'}
          id='empresa_id' value={(empresa && empresa.label) ? empresa.label : ''}
          callback={() => cambiar_empresa && cambiarEstado({ modal_buscar_empresa: true })} />
      </Grid>
      <Grid item xs={12} md={12}>
        <BuscarSelect habilitado={habilitado} datos={sucursales} cambiarEstado={(value) => cambiarEstado({ sucursal: value })} valor={sucursal} id='select-sucursal' nombre='¿En que sucursal realizaras tu proceso ?' placeholder='Seleccione sucursal' />
      </Grid>
      <Grid item xs={12} md={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <Grid container>
            <KeyboardDatePicker
              required={true}
              style={{ marginTop: 3, width: '100%' }}
              minDate={moment(practica.fecha_registro).subtract(2, 'M').format('YYYY-MM-DD')}
              margin="normal"
              label="Fecha Inicio"
              format="yyyy-MM-dd"
              value={fecha_inicio}
              onChange={setFechaInicio}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDateMessage={`La fecha debe ser mayor que la fecha registro.`}
              invalidDateMessage='Formato Invalido'
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid>
        <AlertasSimple titulo='Acuerdo' sub_titulo={acuerdos && acuerdos[0] || ''} />
        <AlertasSimple titulo='Acuerdo' sub_titulo={acuerdos && acuerdos[1] || ''} tipo='warnig' />
      </Grid>
    </Grid >
  )
}

const PintarTutor = ({ props }) => {
  let {
    onChange,
    jefe,
    cargo_jefe,
    correo_jefe,
    telefono_jefe,
  } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="jefe"
          label="Tutor"
          type="text"
          fullWidth
          name="jefe"
          value={jefe}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="cargo_jefe"
          label="Cargo Tutor"
          type="text"
          fullWidth
          name="cargo_jefe"
          value={cargo_jefe}
          validators={["required"]}
          errorMessages={["El campo es requerido"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="correo_jefe"
          label="Correo Tutor"
          type="text"
          fullWidth
          name="correo_jefe"
          value={correo_jefe}
          validators={["required", "isEmail"]}
          errorMessages={["El campo es requerido", "Correo Invalido"]}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextValidator
          margin="dense"
          id="telefono_jefe"
          label="Teléfono Tutor"
          type="text"
          fullWidth
          name="telefono_jefe"
          value={telefono_jefe}
          validators={["required", "isNumber"]}
          errorMessages={["El campo es requerido", "El campo debe ser Numérico"]}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

function PintarDatosEmpresa({ practica, acuerdo }) {
  if (practica && practica.id > 0 && practica.empresa) {
    let {
      fecha_inicio,
      duracion,
      empresa,
      sucursal,
      area_desarrollo,
      auxilio,
      jefe,
      cargo_jefe,
      correo_jefe,
      telefono_jefe,
      objetivo_practica,
      funciones,
      horario_salida,
      horario_entrada,
      contrato,
      tipoempresa,
      adj_arl,
    } = practica;

    return (
      <div>
        <Table>
          <TableBody>
            <TableRow key={21}>
              <TableCell component="th" scope="row">Empresa</TableCell>
              <TableCell align="left">{empresa.nombre}</TableCell>
            </TableRow>
            <TableRow key={23}>
              <TableCell component="th" scope="row">Sucursal</TableCell>
              <TableCell align="left">{sucursal.nombre}</TableCell>
            </TableRow>
            {contrato ?
              <TableRow key={24}>
                <TableCell component="th" scope="row">Tipo de Contrato</TableCell>
                <TableCell align="left">{contrato.nombre}</TableCell>
              </TableRow>
              : <TableRow key={24}></TableRow>
            }
             {tipoempresa ?
              <TableRow key={24}>
                <TableCell component="th" scope="row">Tipo de empresa</TableCell>
                <TableCell align="left">{tipoempresa.nombre}</TableCell>
              </TableRow>
              : <TableRow key={24}></TableRow>
            }
            <TableRow key={22}>
              <TableCell component="th" scope="row">Duración</TableCell>
              <TableCell align="left">{duracion}</TableCell>
            </TableRow>
            <TableRow key={1}>
              <TableCell component="th" scope="row">Fecha Inicio</TableCell>
              <TableCell align="left">{fecha_inicio}</TableCell>
            </TableRow>
            {/* <TableRow key={2}>
                <TableCell component="th" scope="row">Fecha Fin</TableCell>
                <TableCell align="left">{fecha_fin}</TableCell>
              </TableRow> */}
            <TableRow key={3}>
              <TableCell component="th" scope="row">Objetivo Practica</TableCell>
              <TableCell align="left">{objetivo_practica}</TableCell>
            </TableRow>
            <TableRow key={4}>
              <TableCell component="th" scope="row">Horario Entrada</TableCell>
              <TableCell align="left">{horario_entrada}</TableCell>
            </TableRow>
            <TableRow key={5}>
              <TableCell component="th" scope="row">Horario Salida</TableCell>
              <TableCell align="left">{horario_salida}</TableCell>
            </TableRow>
            <TableRow key={6}>
              <TableCell component="th" scope="row">$Auxilio/Salario</TableCell>
              <TableCell align="left">{auxilio}</TableCell>
            </TableRow>
            <TableRow key={7}>
              <TableCell component="th" scope="row">Area Desarrollo</TableCell>
              <TableCell align="left">{area_desarrollo}</TableCell>
            </TableRow>
            <TableRow key={8}>
              <TableCell component="th" scope="row">Funciones</TableCell>
              <TableCell align="left">{funciones}</TableCell>
            </TableRow>
            <TableRow key={9}>
              <TableCell component="th" scope="row">Jefe Inmediato</TableCell>
              <TableCell align="left">{jefe}</TableCell>
            </TableRow>
            <TableRow key={10}>
              <TableCell component="th" scope="row">Cargo Jefe</TableCell>
              <TableCell align="left">{cargo_jefe}</TableCell>
            </TableRow>
            <TableRow key={11}>
              <TableCell component="th" scope="row">Correo Jefe</TableCell>
              <TableCell align="left">{correo_jefe}</TableCell>
            </TableRow>
            <TableRow key={12}>
              <TableCell component="th" scope="row">Teléfono Jefe</TableCell>
              <TableCell align="left">{telefono_jefe}</TableCell>
            </TableRow>
            {adj_arl &&
              <TableRow key={13}>
                <TableCell component="th" scope="row">ARL</TableCell>
                <TableCell align="left"><Link href={`${adj_arl}`} target="_blank"><BtnVer callback={() => {}} texto='Ver' /></Link> </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <AlertasSimple titulo='Acuerdo' sub_titulo={acuerdo} />
      </div>

    );
  } else return '';
}

class PracticaEmpresa extends Component {

  constructor(props) {
    super(props)
    this.state = {
      renderM: false,
      activeStep: 0,
      fecha_inicio: null,
      fecha_fin: '',
      empresa: null,
      empresas: [],
      horario: '',
      area_desarrollo: '',
      auxilio: '',
      jefe: '',
      cargo_jefe: '',
      correo_jefe: '',
      telefono_jefe: '',
      telefono_estudiante: '',
      celular_estudiante: '',
      objetivo_practica: 'Afianzar conocimientos teóricos práctico a través de la participación  en el ámbito laboral.',
      funciones: '',
      horario_salida: null,
      horario_entrada: null,
      tminimo: 0,
      tmaximo: 0,
      sw: true,
      sucursal: null,
      practica: null,
      sucursales: [],
      sw_sucursal: true,
      modal_buscar_empresa: false,
      habilitado : false,
      contratos : [],
      contrato : null,
      tipoempresa : null,
      tipoempresas : [],
      practicaTipo: false,
      adj_arl: '',
      nombre_archivo: '',
      fileInput: React.createRef(null),
    }
  }

  async componentDidUpdate({ modalAddEmpresaProceso }, { empresa }) {
    let { empresa: empresaNew, sw_sucursal, practica } = this.state;
    let { modalAddEmpresaProceso: modalAddEmpresaProcesoNew, practica_id, tipo } = this.props;
    if (empresaNew) {
      if (practica && (!empresa || empresaNew.value !== empresa.value) && sw_sucursal ) this.cargarSucursalesEmpresa(empresaNew.value)
    }
    if (modalAddEmpresaProcesoNew !== modalAddEmpresaProceso) {
      if (modalAddEmpresaProcesoNew) {
        this.setState({ renderM: false, sw: true })
        let practica = await this.obtenerPractica(practica_id);
        if (practica?.adj_arl) this.setState({ adj_arl: practica.adj_arl, nombre_archivo: practica.adj_arl.replace(/^.*[\\\/]/, '') });
        this.setState({ practica })

        if(practica.tipo.valora=='clinicas'){
          this.setState({practicaTipo: true, 
            contrato: VALOR_CONTRATO,
            duracion: 3,
            objetivo_practica: 'No Aplica'
          })
        }

        if (practica && practica.id > 0 && practica.empresa) {
          let {
            fecha_inicio,
            duracion,
            empresa,
            area_desarrollo,
            auxilio,
            jefe,
            cargo_jefe,
            correo_jefe,
            telefono_jefe,
            objetivo_practica,
            funciones,
            horario_salida,
            horario_entrada,
            sucursal,
            contrato,
            tipoempresa
          } = practica;

          fecha_inicio = fecha_inicio ? moment(fecha_inicio, "YYYY-MM-DD") : null;
          horario_entrada = horario_entrada ? moment(horario_entrada, "h:mm:ss a") : null;
          horario_salida = horario_salida ? moment(horario_salida, "h:mm:ss a") : null;
          auxilio = auxilio ? auxilio : '';
          area_desarrollo = area_desarrollo ? area_desarrollo : '';
          funciones = funciones ? funciones : '';
          jefe = jefe ? jefe : '';
          cargo_jefe = cargo_jefe ? cargo_jefe : '';
          correo_jefe = correo_jefe ? correo_jefe : '';
          telefono_jefe = telefono_jefe ? telefono_jefe : '';
          empresa = {
            value: empresa.id,
            label: empresa.nombre,
          }
          sucursal = {
            value: sucursal.id,
            label: sucursal.nombre,
          }
          contrato = contrato ? {
            value: contrato.id,
            label: contrato.nombre,
          } : null
          tipoempresa = tipoempresa ? {
            value: tipoempresa.id,
            label: tipoempresa.nombre,
          } : null


          this.setState({
            fecha_inicio,
            duracion,
            empresa,
            area_desarrollo,
            auxilio,
            jefe,
            cargo_jefe,
            correo_jefe,
            telefono_jefe,
            objetivo_practica,
            funciones,
            horario_salida,
            horario_entrada,
            activeStep: tipo === 'admin' || !practica.fecha_inicio ? 0 : 3,
            sucursal,
            sw_sucursal: false,
            contrato,
            tipoempresa
          });
          if (practica && practica.busca_empresa !== '1') this.cargarSucursalesEmpresa(empresa.value, sucursal);
        }
        if (practica && (practica.busca_empresa === '1') && (!practica.empresa)) {
          this.setState({ renderM: true });
          
        } else {
          await this.cargarData();
        }
        if (practica && (practica.busca_empresa === '1') && (this.state.sw===true)) {
          await this.cargarData();
        } 
      } else this.reiniciarEstado();
    }
  }
  

  reiniciarEstado() {
    this.setState({
      renderM: false,
      activeStep: 0,
      fecha_inicio: null,
      fecha_fin: '',
      empresa: null,
      empresas: [],
      horario: '',
      area_desarrollo: '',
      auxilio: '',
      jefe: '',
      cargo_jefe: '',
      correo_jefe: '',
      telefono_jefe: '',
      telefono_estudiante: '',
      celular_estudiante: '',
      funciones: '',
      horario_salida: null,
      horario_entrada: null,
      tminimo: 0,
      tmaximo: 0,
      sw: true,
      sucursal: null,
      practica: null,
      sucursales: [],
      sw_sucursal: true,
      modal_buscar_empresa: false,
      habilitado : false,
      contrato: null,
      tipoempresa: null,
      practicaTipo: false,
      adj_arl: '',
      nombre_archivo: '',
    })
  }
   obtenertelefono =async () => {
    
    let practica123 = await this.obtenerPractica(this.props.practica_id);
    
    this.setState({telefono_estudiante: practica123.usuario_registro.telefono,celular_estudiante: practica123.usuario_registro.celular})
    
  }
  obtenerPractica(id) {
    return new Promise(async (resolve) => {
      let f = await generarFiltros([{ 'llave': 'id', 'valor': id }]);
      consulta(`api/v1.0/practica?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && resp.length > 0 ? resp[0] : null);
      })
    })
  }

  cargarSucursalesEmpresa = async (empresa, sucursal = null) => {
    let sucursales = await obtenerPermisosValor(empresa);
    sucursales = sucursales.map(({ secundario }) => ({
      value: secundario.id,
      label: secundario.nombre+' , '+secundario.valora,
    }));
    this.setState({ sucursales, sucursal, sw_sucursal: true });
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ adj_arl: file });
    } else {
      console.error("No se seleccionó ningún archivo.");
    }
  };

  onSubmit = async e => {

    let {
      fecha_inicio,
      duracion,
      empresa,
      area_desarrollo,
      auxilio,
      jefe,
      cargo_jefe,
      correo_jefe,
      telefono_jefe,
      objetivo_practica,
      funciones,
      activeStep,
      horario_entrada,
      horario_salida,
      sucursal,
      contrato,
      telefono_estudiante,
      tipoempresa,
      practicaTipo,
      celular_estudiante,
      adj_arl,
    } = this.state;

    objetivo_practica = 'Afianzar conocimientos teóricos práctico a través de la participación  en el ámbito laboral.';

    this.obtenertelefono();
    telefono_estudiante=`${telefono_estudiante}`
    celular_estudiante=`${celular_estudiante}` 
    let { actualizarMensaje, tipo: tipoUsu } = this.props;
    let titulo = '', tipo = 'info';

    if(!practicaTipo){

      if (activeStep === 0) {
        if (!empresa) titulo = "Seleccione Empresa";
        else if (!sucursal) titulo = "Seleccione Sucursal";
        else if (!contrato) titulo = "Seleccione Contrato";
        else if (!tipoempresa) titulo = "Seleccione tipo de empresa";
        else if (!fecha_inicio) titulo = "Seleccione fecha de inicio";
        else if (!duracion) titulo = "Seleccione Duración";
        else if (!objetivo_practica) titulo = "Seleccione objetivo practica";
        else if (!adj_arl) titulo = "Adjunte ARL";
      } else if (activeStep === 1) {
        if (!horario_entrada) titulo = "Seleccione Hora de entrada";
        else if (!horario_salida) titulo = "Seleccione Hora de salida";
        else if (!auxilio) titulo = "Ingrese Auxilio";
        else if (!area_desarrollo) titulo = "Ingrese área de desarrollo";
        else if (!funciones) titulo = "Ingrese funciones o plan de trabajo";
      } else if (activeStep === 2) {
            
        if (!jefe) titulo = "Ingrese Jefe";
        else if (!cargo_jefe) titulo = "Ingrese cargo del jefe";
        else if (!correo_jefe) titulo = "Ingrese correo del jefe";
        else if (!telefono_jefe) titulo = "Ingrese telefono del jefe";
        else if (telefono_jefe===telefono_estudiante) titulo = "El telefono del jefe debe ser diferente al del estudiante";
        else if (telefono_jefe===celular_estudiante) titulo = "El telefono del jefe debe ser diferente al del estudiante";
        
        
      }
    }

    if (titulo) {
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      return false;
    }

    let guardar = false;
    if ((practicaTipo && activeStep===1) || (activeStep === 2 && tipoUsu === 'admin')) guardar = true
    else this.cambiarEstado({ 'activeStep': activeStep + 1 });

    if (this.state.activeStep === 3 || guardar) {
      fecha_inicio = fecha_inicio ? moment(fecha_inicio).format('YYYY-MM-DD') : '';
      horario_entrada = horario_entrada ? moment(horario_entrada).format('HH:mm:ss') : '';
      horario_salida = horario_salida ? moment(horario_salida).format('HH:mm:ss') : '';

      let data;

      if(!practicaTipo){
        data = {
          fecha_inicio,
          duracion,
          empresa: empresa.value,
          sucursal: sucursal.value,
          area_desarrollo,
          auxilio,
          jefe,
          cargo_jefe,
          correo_jefe,
          telefono_jefe,
          objetivo_practica,
          funciones,
          horario_entrada,
          horario_salida,
          contrato : contrato.value,
          tipoempresa : tipoempresa.value,
          practicaTipo,
          adj_arl,
        }
      }else{
        data = {
          fecha_inicio,
          duracion,
          empresa: empresa.value,
          sucursal: sucursal.value,
          jefe,
          cargo_jefe,
          correo_jefe,
          telefono_jefe,
          objetivo_practica,
          contrato : contrato.value,
          tipoempresa : tipoempresa.value,
          practicaTipo,
          adj_arl,
        }
      }

      // Verificamos que adj_arl sea un archivo, si no lo es, lo eliminamos del objeto data ya que entendemos que no cargaron un documento y no es necesario volver a cargar el mismo archivo
      if (typeof adj_arl !== 'object') delete data.adj_arl;

      let form = await crear_form_data(data);
      
      this.setState({ renderM: false })
      this.props.modificarPractica(form, async () => {
        let practica = await this.obtenerPractica(this.props.practica_id);
        this.setState({ practica, renderM: true });
        
      });
    }
    e.preventDefault();
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  cambiarEstado = estados => this.setState(estados)

  setFechaInicio = (fecha_inicio) => {
    this.setState({
      fecha_inicio
    })
  }

  setHorarioSalida = (horario_salida) => {
    this.setState({
      horario_salida
    })
  }
  setHorarioEntrada = (horario_entrada) => {
    this.setState({
      horario_entrada
    })
  }

  transformar = data => {
    return data.map(e => ({
      value: e.id,
      label: e.nombre,
    }));
  }

  cargarData = async (empresa = null, sucursal = null) => {
    let tiempo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Pra_Lim' }]);
    let contratos = await obtenerValores(CONTRATOS)
    // let tipoempresas = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': TIPOEMPRESAS }, { 'llave': 'estado', 'valor': 1 }]) || [];
    let tipoempresas = await obtenerValores(TIPOEMPRESAS)
    let sw = true;
    if (tiempo && tiempo.length > 0) {
      this.setState({ tminimo: tiempo ? tiempo[0].valora : 0, tmaximo: tiempo ? tiempo[0].valorb : 0, })
    } else {sw = false;
    }
    
    


    if (empresa && sucursal) {
      empresa = {
        value: empresa.id,
        label: empresa.nombre,
      }
      sucursal = {
        value: sucursal.id,
        label: sucursal.nombre,
      }
      this.setState({ empresa, sucursal, sw_sucursal: false });
      await this.cargarSucursalesEmpresa(empresa.value, sucursal);
    }

    if (contratos && contratos.length > 0) {
      this.setState({ contratos : transformar(contratos) })
      
    } else {sw = false;
      }
    if (tipoempresas && tipoempresas.length > 0) {
      this.setState({ tipoempresas : transformar(tipoempresas) })
      
    } else {sw = false;
      }

    
    this.setState({ sw });
    this.setState({ renderM: true });
    // this.setState({ tipoempresas});
  }



  render() {
    let {
      fecha_inicio,
      duracion,
      empresa,
      empresas,
      horario,
      area_desarrollo,
      auxilio,
      jefe,
      cargo_jefe,
      correo_jefe,
      telefono_jefe,
      objetivo_practica,
      funciones,
      activeStep,
      horario_entrada,
      horario_salida,
      tminimo,
      tmaximo,
      sw,
      sucursal,
      sucursales,
      renderM,
      modal_buscar_empresa,
      habilitado,
      contratos,
      contrato,
      tipoempresas,
      tipoempresa,
      practicaTipo,
      adj_arl,
      nombre_archivo,
    } = this.state;

    let { mostrarModalAddValores, modalAddValores, modalAddValoresSucursal,guardarEmpresa, mostrarModalAddEmpresaProceso, modalAddEmpresaProceso, acuerdos, cambiar_empresa,mostrarModalAddValoresSucursal } = this.props;
    
    
    // this.cargarData()
    let { practica } = this.state;

    const guardarEmpresaFormatoSeleccion = async (id,codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo, callback = () => { }) => {
      let { actualizarMensaje, mostrarModalAddValoresSucursal, mostrarModalAddEmpresa } = this.props;

        let data = await crear_form_data({ codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo });
        formulario(`api/v1.0/practicas/sucursal/${id}/crear`, data, "post",
          (error, estado, resp) => {
            let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
            if (!error) {
              if (estado === 200) {
                tipo = 'success';
                titulo = resp.titulo;
                mostrarModalAddValoresSucursal(false);
                callback(resp.empresa, resp.sucursal);
              } else {
                tipo = 'info';
                titulo = resp.titulo ? resp.titulo : mostrarError(resp)
              }
            }
            actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          });

  
    }
    
    const config = () => {
      return {
        inputCodigo: { 'label': 'NIT(Ejemplo : 890104530-9)', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputNombre: { 'label': 'Nombre', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputDescripcion: { 'label': 'Actividad Económica', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputValora: { 'label': 'Dirección', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputValorb: { 'label': 'Barrio', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputValorc: { 'label': 'Ciudad', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputValord: { 'label': 'Departamento', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': true },
        inputValore: { 'label': 'Telefono', 'mostrar': true, 'tipo': 'number', 'requerido': true, 'mayusculas': false },
        inputValorf: { 'label': 'Fax', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': false },
        inputValorg: { 'label': 'Programa - Area de la empresa', 'mostrar': true, 'tipo': 'text', 'requerido': true, 'mayusculas': false },
        inputArchivo: { 'label': 'Adjuntar certificado (Cámara de comercio)', 'mostrar': true, 'tipo': 'file', 'requerido': true },
      }
    }
    
    const configSucursal = () => {
      return {
        inputCodigo: { 'label': 'NIT(Ejemplo : 890104530-9)', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputNombre: { 'label': 'Direccion', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputDescripcion: { 'label': 'Actividad Económica', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputValora: { 'label': 'Barrio', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorb: { 'label': 'Ciudad', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorc: { 'label': 'Departamento', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValord: { 'label': 'Telefono', 'mostrar': true, 'tipo': 'number', 'requerido': true },
        inputValore: { 'label': 'Fax', 'mostrar': false, 'tipo': 'text', 'requerido': false },
        inputValorf: { 'label': 'Correo', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputValorg: { 'label': 'Programa - Area de la empresa', 'mostrar': true, 'tipo': 'text', 'requerido': true },
        inputArchivo: { 'label': 'Adjuntar certificado (Camara de comercio)', 'mostrar': false, 'tipo': 'file', 'requerido': false },
      }
    }

    

    const habilitarEditar = () => {
      if (practica && practica.id > 0) {
        let { estado_actual } = practica;
        if (((estado_actual.codigo === 'Est_Pra_Ini' || (estado_actual.codigo == 'Est_Pra_Env' && practica.tipo.codigo === 'Mod_Rce_Pra') || (estado_actual.codigo == 'Est_Pra_Env' && practica.tipo.codigo === 'Mod_Epr_Pra') || (estado_actual.codigo == 'Est_Pra_Env' && practica.trabaja == '1' && practica.en_area == '1')) || (estado_actual.codigo == 'Est_Pra_Ini' && practica.busca_empresa=='0') && this.props.tipo === 'admin') || (practica.empresa && !practica.fecha_inicio)) {
          return <><BtnForm texto="CANCELAR" callback={() => mostrarModalAddEmpresaProceso(false)} /> <BtnForm color="secondary" callback={() => this.cambiarEstado({ activeStep: 0 })} texto='MODIFICAR' /></>;
        }
      }
      return <BtnForm texto="CANCELAR" callback={() => mostrarModalAddEmpresaProceso(false)} />;
    }

    const steps = obtenerItems(practicaTipo);
    return (
      <>
        <Dialog maxWidth="sm" fullWidth open={modalAddEmpresaProceso} onClose={() => mostrarModalAddEmpresaProceso(false)}>
          <AppBarModal titulo={`¡ Datos ${ (practicaTipo) ? 'Escenario' : 'Empresa' }  ! ${practica && practica.usuario_registro ? `${` - ${practica.usuario_registro.primer_apellido + ' ' + practica.usuario_registro.primer_nombre}`}` : ''}`} mostrarModal={() => mostrarModalAddEmpresaProceso(false)} titulo_accion="Cerrar" accion={() => mostrarModalAddEmpresaProceso(false)} />
          <DialogContent className='scroll' style={{ padding: (renderM && sw) && 0 }}>
            {
              renderM ?
                sw ?
                  <ValidatorForm
                    onSubmit={this.onSubmit}
                    className='scroll'
                  >
                    <HorizontalLinearStepper
                      props={{
                        empresas,
                        cambiarEstado: this.cambiarEstado,
                        empresa,
                        fecha_inicio,
                        duracion,
                        setFechaInicio: this.setFechaInicio,
                        setFechaFin: this.setFechaFin,
                        setHorarioSalida: this.setHorarioSalida,
                        setHorarioEntrada: this.setHorarioEntrada,
                        horario,
                        onChange: this.onChange,
                        auxilio,
                        area_desarrollo,
                        jefe,
                        cargo_jefe,
                        correo_jefe,
                        telefono_jefe,
                        objetivo_practica,
                        funciones,
                        activeStep,
                        horario_entrada,
                        horario_salida,
                        practica,
                        tminimo,
                        tmaximo,
                        mostrarModalAddValores,
                        sucursal,
                        sucursales,
                        modal_buscar_empresa,
                        acuerdos: acuerdos,
                        habilitado,
                        cambiar_empresa,
                        contratos,
                        contrato,
                        tipoempresas,
                        tipoempresa,
                        practicaTipo,
                        modalAddValoresSucursal,
                        mostrarModalAddValoresSucursal,
                        adj_arl,
                        nombre_archivo,
                        fileInput: this.state.fileInput,
                        handleFileChange: this.handleFileChange,
                      }}
                    />
                    <button type="submit" className='oculto' id='btn_crear_empresa_pro'></button>
                  </ValidatorForm>
                  : <TareasTerminadas mensaje={practica && practica.busca_empresa === '1' && !practica.empresa ? 'Actualmente su hoja de vida esta siendo postulada en las diferentes empresas y entidades solicitantes' : 'La configuración inicial no esta cargada, contacta con el administrador'} imagen={emma_s} widthImg='7%' marginTop='7%' />

                : <TareasTerminadas mensaje='Cargando..' imagen={emma_w} widthImg='10%' marginTop='5%' />

            }

          </DialogContent>
          <DialogActions>

            {sw ?
              activeStep === steps.length ?
                habilitarEditar() :
                <div>
                  {activeStep !== 0 && (<Button onClick={() => this.cambiarEstado({ 'activeStep': activeStep - 1 })} >  Regresar </Button>)}
                  <Button color="primary" onClick={() => document.getElementById('btn_crear_empresa_pro').click()}  >  {activeStep === 2 || activeStep === 3 || (practicaTipo && activeStep === 1) ? 'Terminar' : 'Siguiente'} </Button>
                </div>
              : <Button color="primary" onClick={() => mostrarModalAddEmpresaProceso(false)} > ENTIENDO  </Button>
            }

          </DialogActions>
        </Dialog >

        <ValoresAgregar
          mostrarABC={true}
          modalAddValores={modalAddValores}
          mostrarModalAddValores={(show) => mostrarModalAddValores(show)}
          guardarValor={(codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => guardarEmpresa(codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo, (empresa, sucursal) => this.cargarData(empresa, sucursal))}
          inputs={config()}
          titulo='Nueva Empresa'
        />
        <ValoresAgregar
          mostrarABC={true}
          modalAddValores={modalAddValoresSucursal}
          mostrarModalAddValores={(show) => mostrarModalAddValoresSucursal(show)}
          guardarValor={(codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo) => guardarEmpresaFormatoSeleccion(empresa.value,codigo, nombre, descripcion, valora, valorb, valorc, valord, valore, valorf, valorg, archivo, (empresa, sucursal) => this.cargarData(empresa, sucursal))}
          inputs={configSucursal()}
          titulo='Nueva Sucursal'
        />
      </>
    )
  }
}

// const mapStateToProps = (state) => {
  
// let {modalAddValoresSucursal}=state.redPracticas;
// return {
//   modalAddValoresSucursal
// }
// }

// const mapDispatchToProps = {
//   mostrarModalAddValoresSucursal
// }

//Props del componente
PracticaEmpresa.propTypes = {
  //funciones
  practica_id: PropTypes.number.isRequired,
  modificarPractica: PropTypes.func.isRequired,
}

PracticaEmpresa.defaultProps = {
  tipo: 'estudiante',
};



export default PracticaEmpresa;
// export default connect(mapStateToProps, mapDispatchToProps)(PracticaEmpresa)

