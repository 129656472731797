import React, { Component } from 'react';
import AppBarModal from "../../general/AppBarModal";
import { BtnForm } from "../../general/BotonesAccion";
import { RequisitoDetalle, ActaDetalle } from "../administrar/PracticaDetalle";
import TareasTerminadas from '../../general/TareasTerminadas';
import emma_w from '../../../global/imagenes/emma_w.png';
import { consulta } from "../../../global/js/funciones";
import Procesos from '@material-ui/icons/ImportContacts';
import CreateIcon from '@material-ui/icons/Create';
import { Tab, Tabs, DialogContent, DialogActions, Dialog } from '@material-ui/core';


function AdjuntoMenu({ sele = 0, seleccionar = () => { } }) {
  const clase = (item, selec, tipo = '') => item === selec ? `item_selec${tipo}` : `item_modulo`;
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <Tabs
          value={sele}
          variant="fullWidth"
          scrollButtons="off"
        >
          <Tab label={'DETALLE'} icon={< Procesos className='menu_icono' />} className={clase('respuestas', sele)} value='respuestas' onClick={() => seleccionar('respuestas')} />
          <Tab label={'GESTIÓN'} icon={<CreateIcon className='menu_icono' />} className={clase('detalle', sele)} value='detalle' onClick={() => seleccionar('detalle')} />
        </Tabs>
      </div>
    )
  }

  return pintar('menu_modal');
}


class Adjunto extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: false,
      accion: '',
      respuestas: [],
    }
  }

  componentDidUpdate({ modalDetalleAdjunto }, { accion }) {
    let { accion: accionNew } = this.state;
    let { modalDetalleAdjunto: modalDetalleAdjuntoNew, requisito } = this.props;
    if (accion !== accionNew) {
      this.setState({ cargando: true });
      if (accionNew === 'detalle') this.setState({ cargando: false });
      if (accionNew === 'respuestas' && requisito.tipo === '2') this.actaListarRespuestas(this.props.requisito);
      else this.setState({ cargando: false });
    }
    if (modalDetalleAdjunto !== modalDetalleAdjuntoNew && modalDetalleAdjuntoNew) {
      this.setState({ accion: 'respuestas' });
      if (requisito.tipo === '2') this.actaListarRespuestas(this.props.requisito)
    }
  }

  actaListarRespuestas = ({ id }) => {
    consulta(`api/v1.0/practicas/acta/respuestas?requisito__id=${id}`, null, null,
      (error, estado, resp) => {
        if (estado === 200) {
          this.setState({ cargando: false, respuestas: resp })
        }
      }
    )
  }



  pintar = () => {
    let { accion, cargando, respuestas } = this.state;
    let { requisito, usuario } = this.props;
    if (cargando) return <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />
    return (
      <div>
        {accion === 'detalle' && <RequisitoDetalle requisito={requisito} />}
        {accion === 'respuestas' && <ActaDetalle requisito={requisito} respuestas={respuestas} usuario={usuario}/>}
      </div>
    );

  }

  render() {

    let { modalDetalleAdjunto, mostrarModalDetalleAdjunto } = this.props;
    let { accion } = this.state;
    return (
      <Dialog open={modalDetalleAdjunto} maxWidth='sm' fullWidth={true} onClose={mostrarModalDetalleAdjunto.bind(this, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo='¡ Detalle Requisito !' mostrarModal={mostrarModalDetalleAdjunto} titulo_accion='' />
        < AdjuntoMenu sele={accion} seleccionar={(accion) => this.setState({ accion })} />
        <DialogContent style={{ padding: 0 }} className='scroll' >
          {this.pintar()}
        </DialogContent>
        <DialogActions>
          <BtnForm texto="Cerrar" callback={() => mostrarModalDetalleAdjunto(false)} />
        </DialogActions>
      </Dialog>

    );
  }
}



export default Adjunto;
