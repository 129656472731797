import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import { DialogActions, Grid, Dialog, DialogContent, ListSubheader, Stepper, Step, StepLabel, Paper } from '@material-ui/core';
import { BtnForm, BtnCargar } from "../../general/BotonesAccion";
import EnviarMultiplesArchivos from "../../general/EnviarMultiplesArchivos";
import AppBarModal from '../../general/AppBarModal';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import { obtenerEstadosProceso, obtenerPermisosValorAlt, obtenerValoresFiltros } from "../../../global/js/funciones";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from '../../../global/imagenes/emma_w.png';
import AlertasSimple from '../../general/AlertasSimple';
import InputBuscar from '../../general/InputBuscar';
import BuscarPersona from "../../general/BuscarPersona";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment'
import { TIPOS_DE_CASOS, ESTADOS, REQUISITOS } from '../Helpers'

const configStep = ['Datos Iniciales', 'Requisitos'];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Estado
      </ListSubheader>
    }>
      {estados_siguiente.map(({ secundario }) => {
        let { codigo, nombre, id } = secundario;
        return (
          <ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion({ codigo, id })} >
            <ListItemIcon>
              <Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={codigo} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

function RenderMotivos({ motivos, motivo, cambiarSeleccion }) {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={
      <ListSubheader component="div" >
        Seleccione Motivo
      </ListSubheader>
    }>
      {motivos.map(({ id, nombre, codigo }) => {
        return (
          <ListItem key={id} role={undefined} dense button onClick={() => cambiarSeleccion({id:id,codigo:codigo})} >
            <ListItemIcon>
              <Checkbox edge="start" checked={motivo.id === id} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={id} primary={nombre} />
          </ListItem>
        );
      })}
    </List>
  );
}

function AttachComponent(component_props) {
  let { requisitos, submit } = component_props

  const trasnformarDataAdj = (data) => {
    let r = [];
    data.map(({ secundario }) => r.push({ nombre: secundario.nombre, descripcion: secundario.descripcion, codigo: secundario.id, archivo: secundario.archivo }))
    return r;
  }

  const acciones = (data, callback) => {
    const onClickCargar = (data) => callback(data);

    let cargar = <BtnCargar callback={() => onClickCargar(data)} color='#00bfa5' texto='Gestionar' />
    return <div>{cargar}</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} square>
            <List component="nav" arial-label="main">
              <EnviarMultiplesArchivos
                acciones={acciones}
                enModal={false}
                enviarArchivos={(archivos) => submit(archivos)}
                limite={2}
                mostrarMensaje={false}
                enLista={true}
                lista={trasnformarDataAdj(requisitos)}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>

    </React.Fragment>

  )
}

class AtencionGestionar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      observaciones: '',
      motivo: 0,
      motivos: [],
      estados_siguiente: [],
      seleccion: 'gestion',
      activeStep: 0,
      requisitos: [],
      buscarCoordinador: false,
      coordinador: {
        id: 0,
        nombre_completo: '',
      },
      fecha_examen: null,
      hora_examen: null,
      lugar_examen: '',
      url: '',
      buscarProfesor: false,
      profesor: {
        id: 0,
        nombre_completo: '',
      },
    }
  }

  async componentDidUpdate({ modalGestionValidacion }, { estado }) {
    let { modalGestionValidacion: modalGestionValidacionesNew, validacion } = this.props;
    let { estado: estadoNew } = this.state;

    if (estadoNew.codigo !== estado.codigo && estadoNew.codigo) {


    }

    if (modalGestionValidacion !== modalGestionValidacionesNew) {
      if (modalGestionValidacionesNew) {
        this.reiniciarEstado();
        let filter = [{ 'llave': 'principal', 'valor': validacion.estado_actual.id }, { 'llave': 'secundario__generica', 'valor': ESTADOS }]
        let estados_siguiente = await obtenerEstadosProceso(validacion.tipo_atencion.id, filter);
        let coordinador = {
          id: 0,
          nombre_completo: '',
        }
        if (validacion.coordinador) {
          const {
            id,
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido
          } = validacion.coordinador
          coordinador = {
            id,
            nombre_completo: `${primer_nombre} ${segundo_nombre} ${primer_apellido} ${segundo_apellido}`,
          }
        }
        this.setState({
          estados_siguiente,
          coordinador,
          cargando: false
        });

      }
    }
  }

  // Obtner requisitos
  obtenerRequisitos = async () => {
    this.setState({ cargando: true })
    let { estado } = this.state
    let filtros = [
      { 'llave': 'principal', 'valor': estado.id },
      { 'llave': 'secundario__generica', 'valor': REQUISITOS },
      { 'llave': 'secundario__valora', 'valor': 'gestor' }
    ]

    let requisitos = await obtenerPermisosValorAlt(filtros)
    this.setState({ cargando: false, requisitos })
  }


  configStepContent = () => {
    let { actualizarMensaje } = this.props
    let { activeStep, initialTransition, archivos, requisitos, acuerdo_validaciones, estado, acuerdo_validacione_pestudio, acuerdo_validaciones_acta_examen } = this.state

    switch (activeStep) {
      case 0:
        return this.pintar()
      case 1:
        return (
          <>
            <AttachComponent
              {...{
                initialTransition,
                requisitos,
                archivos,
                actualizarMensaje,
                submit: this.submit
              }}
            />

            {/* {
              estado.codigo == 'STATE_CREATE_ATENCION' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={mensaje_validaciones}
                  margin='0px'
                />
              </React.Fragment>
            }

            {
              estado.codigo == 'STATE_ASIGNADO_ATENCION' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={mensaje_validaciones_pestudio}
                  margin='0px'
                />
              </React.Fragment>
            }

            {
              estado.codigo == 'STATE_GESTION_ATENCION' &&
              <React.Fragment>
                <AlertasSimple
                  tipo='info'
                  titulo={'Estimado gestor, tener en cuenta: '}
                  lista={mensaje_acta_examen}
                  margin='0px'
                />
              </React.Fragment>
            } */}
          </>
        )
      default:
        return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
    }
  }



  obtenerObservaciones = async () => {
    this.setState({ cargando: true });
    let { acuerdo_validaciones, acuerdo_validacione_pestudio } = this.state
    let obs = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Val_Vol_Can' }])
    let acu_validaciones = [{ item: acuerdo_validaciones }]
    obs.map(({ secundario }) => acu_validaciones.push({ item: secundario.nombre }));
    this.setState({ acu_validaciones });
  }

  submit = (archivos) => {
    let { estado, observaciones, motivo, porcentaje, coordinador, fecha_examen, hora_examen, lugar_examen, url, profesor, otro_motivo } = this.state;
    let { validacion, gestionarValidaciones } = this.props;
    this.setState({ cargando: true, titulo_res: 'Enviando..' })
    let data = {
      mensaje: observaciones,
      motivo: motivo.id,
      estado: estado.codigo,
      porcentaje,
      total: archivos.length,
      coordinador: coordinador.id,
      fecha_examen,
      hora_examen,
      lugar_examen,
      url,
      profesor: profesor.id,
      otro_motivo: otro_motivo
    }
    gestionarValidaciones(validacion.id, data, archivos, () => this.setState({ cargando: false, titulo_res: 'Cargando..' }));
  }

  reiniciarEstado() {
    this.setState({
      cargando: true,
      titulo_res: 'Cargando..',
      estado: { codigo: '', id: '' },
      motivo: '',
      estados_siguiente: [],
      seleccion: 'gestion',
      requisitos: [],
      acuerdo_validaciones_solicitado: '',
      acuerdo_validaciones_revisado: '',
      activeStep: 0,
      buscarCoordinador: false,
      coordinador: {
        id: 0,
        nombre_completo: '',
      },
      fecha_examen: null,
      lugar_examen: '',
      url: '',
      buscarProfesor: false,
      profesor: {
        id: 0,
        nombre_completo: '',
      },
    })
  }

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value
    })
  }

  handleStep = direction => {
    let { actualizarMensaje } = this.props
    let { activeStep, estado } = this.state
    let sw = true

    if (!estado.codigo && activeStep == 0) {
      actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
      sw = false
    }
    if (sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
  }


  pintarEstados() {
    let { estado, estados_siguiente, observaciones, coordinador, buscarCoordinador, buscarProfesor, otro_motivo } = this.state;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <RenderEstados cambiarSeleccion={(estado) => this.setState({ estado })} estado={estado.codigo} estados_siguiente={estados_siguiente} />
          </Grid>
          {
            estado.codigo &&
            (<>
              {
                estado.codigo === 'STATE_ASIGNADO_ATENCION' && coordinador.id === 0 &&
                <Grid item xs={12} md={12}>
                  <InputBuscar
                    placeholder='Seleccione Coordinador'
                    label={'Coordinador'}
                    id='coordinador'
                    value={(coordinador.nombre_completo)}
                    callback={() => this.setState({ buscarCoordinador: true })} />
                </Grid>
              }

              {/* {
                estado.codigo === 'STATE_GESTION_ATENCION' &&
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                    <FormControl className="form-control" utils={DateFnsUtils} >
                      <TextField
                        value={lugar_examen}
                        id="txLugarexamen"
                        label="Lugar del Examen"
                        name="lugar_examen"
                        placeholder="Escriba el lugar donde se realizara el examen"
                        onChange={this.onChange}
                      />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl className="form-control">
                      <TextField
                        value={url}
                        id="txtUrl"
                        label="URL del examen"
                        name="url"
                        placeholder="URL del examen"
                        multiline
                        onChange={this.onChange}
                      />
                    </FormControl>
                  </Grid>
                </>
              } */}

              {/* {
                estado.codigo !== 'Val_Neg' &&
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={observaciones}
                      id="txtMotivoNegado"
                      label="Observaciones"
                      name="observaciones"
                      placeholder="Escriba Observaciones"
                      multiline
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              } */}

              {
                estado.codigo === 'STATE_FINALIZADO_ATENCION' &&
                <Grid item xs={12} md={12}>
                  <FormControl className="form-control">
                    <TextField
                      value={otro_motivo}
                      id="txtSolucion"
                      label="Observaciones"
                      name="otro_motivo"
                      placeholder="Escriba Observaciones"
                      multiline
                      onChange={this.onChange}
                    />
                  </FormControl>
                </Grid>
              }
            </>)
          }
        </Grid >
        <Dialog fullWidth={true} maxWidth="sm" open={buscarCoordinador} onClose={() => this.setState({ buscarCoordinador: false })}>
          <AppBarModal titulo={'¡ Buscar Coordiandor !'} mostrarModal={() => this.setState({ buscarCoordinador: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarCoordinador: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>

            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              coordinador: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarCoordinador: false,
            })} />

          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarCoordinador: false })} />
          </DialogActions>
        </Dialog>

        <Dialog fullWidth={true} maxWidth="sm" open={buscarProfesor} onClose={() => this.setState({ buscarProfesor: false })}>
          <AppBarModal titulo={'¡ Buscar Profesor !'} mostrarModal={() => this.setState({ buscarProfesor: false })} titulo_accion="CERRAR" accion={() => this.setState({ buscarProfesor: false })} />
          <DialogContent style={{ padding: '0' }} className='scroll'>

            <BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => this.setState({
              profesor: {
                id,
                nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
              }, buscarProfesor: false
            })} />

          </DialogContent>
          <DialogActions>
            <BtnForm texto="CANCELAR" callback={() => this.setState({ buscarProfesor: false })} />
          </DialogActions>
        </Dialog>
      </>


    )
  }

  pintar() {
    let { seleccion } = this.state;
    return (
      <div>
        {seleccion === 'gestion' && this.pintarEstados()}
      </div>
    )
  }

  render() {
    let { modalGestionValidacion, mostrarGestionValidacion } = this.props;
    let { cargando, titulo_res, seleccion, accepted, activeStep} = this.state;

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={modalGestionValidacion} onClose={() => mostrarGestionValidacion(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <AppBarModal titulo={'¿ Gestionar Atención ?'} mostrarModal={mostrarGestionValidacion} titulo_accion="" accion="" />
        <DialogContent className='scroll'>
          {
            !cargando ?
              <Grid container>
                <Grid item xs={12} md={12}>
                  {/* <Stepper activeStep={activeStep}>
                    {
                      configStep.map(label => (
                        <Step key={label}>
                          <StepLabel><p>{label}</p></StepLabel>
                        </Step>
                      ))
                    }
                  </Stepper> */}
                </Grid>
                {
                  <Grid item xs={12} style={{ marginTop: "30px" }}>
                    {this.configStepContent()}
                    {/* {
                      <AlertasSimple
                        tipo='info'
                        titulo={'Estimado gestor, tener en cuenta: '}
                        lista={[]}
                      />
                    }
                    {
                      <AlertasSimple
                        tipo='info'
                        titulo={'Estimado gestor, tener en cuenta: '}
                        lista={[]}
                      />
                    } */}
                  </Grid>
                }
              </Grid> :
              <TareasTerminadas mensaje={titulo_res} marginTop='7%' imagen={emma_w} widthImg="7%" />
          }
        </DialogContent>
        <DialogActions>
          {
            /* !cargando ?
              activeStep > 0
                ? <div>
                  <BtnForm texto="ATRAS" callback={() => this.handleStep(false)} />
                  <BtnForm texto="GUARDAR" callback={() => { document.getElementById('enviar_form_multi_archivo').click() }} />
                </div>
                : <BtnForm texto="SIGUIENTE" callback={() => this.handleStep(true)} type="submit" />
              : */
              <div>
                {
                  !cargando &&
                  <BtnForm texto="GUARDAR" callback={() => this.submit([])} />
                }
              </div>
          }

        </DialogActions>
      </Dialog>
    )
  }
}

export default AtencionGestionar;