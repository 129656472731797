import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import { coloresEmma } from '../../../global/js/funciones';

const InfoBadge = ({ title, text, color, iconColor, functional, action }) => {
  const CustomLinearProgress = withStyles((theme) => ({
    root: {
      borderRadius: 5,
      height: 10  
    },
    colorPrimary: {
      backgroundColor: coloresEmma.colorblanco
    },
    bar: {
      borderRadius: 5,
      backgroundColor: color || '#3f51b5'  
    },
  }))(LinearProgress);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',  
        alignItems: 'center',  
        marginBottom: 10,  
        textAlign: 'center',  
        
      }}
      onClick={functional ? action : undefined}  
    >
      <div
        style={{
          backgroundColor: color || coloresEmma.secondarycolor, 
          width: 50,
          height: 50,
          marginRight: '12px',
          borderRadius: '50%',  
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 2px 26px rgba(0, 0, 0, 0.07)',  
          color: iconColor || coloresEmma.colorblanco,  
        }}
      >
        {text}
      </div>
      <span style={{ fontWeight: 'bold',color:coloresEmma.gray, fontSize: '18px',  marginTop: 5 }}>{title}</span>
      {typeof text !== 'number' && typeof text !== 'string' && (
        <CustomLinearProgress />
      )}
    </div>
  );
};

InfoBadge.propTypes = {
  title: PropTypes.string.isRequired, 
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),  
  color: PropTypes.string, 
  iconColor: PropTypes.string, 
  functional: PropTypes.bool, 
  action: PropTypes.func,  
};

InfoBadge.defaultProps = {
  functional: false,
  action: () => {},  
};

export default InfoBadge;
