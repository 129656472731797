import React, { Component } from 'react'
import PropTypes from 'prop-types'

// MATERIAL
import { Dialog, DialogContent, DialogActions, FormControl, Stepper, Step, StepLabel, Grid, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';

// FUNCIONES GENERALES
import BuscarSelect from "../../general/BuscarSelect";
import AppBarModal from '../../general/AppBarModal';
import AlertasSimple from '../../general/AlertasSimple';
import TareasTerminadas from '../../general/TareasTerminadas';
import { BtnFormPage, BtnCancelar, BtnForm } from '../../general/BotonesAccion';
import ConfirmarAccion from '../../general/ConfirmarAccion';
import ListarDatos from '../../general/ListarDatos';
import BuscarPersona from '../../general/BuscarPersona';
import InputBuscar from '../../general/InputBuscar';

import { consulta, obtenerValores, api, obtenerValoresFiltros, obtenerPermisosValorAlt, obtenerMisDatos } from "../../../global/js/funciones";

// IMAGENES
import emma_w from '../../../global/imagenes/emma_w.png';

// STEP
const configStep = ['Datos Iniciales', 'Crear Grupo'];


function Form(component_props) {
	let { carreras, carrera, telefono, celular, asignaturas, asignatura, profesor, buscarProfesor, habilitarelectiva, electivas, electiva, setearEstado, onChange, onChangeProgramas, onChangeAsignatura, mensaje_retos } = component_props

	return (
		<>
			<Grid container spacing={3} style={{ marginBottom: '40px' }}>

				{/* Formulario de celular*/}
				<Grid item xs={6}>
					<TextField
						value={celular}
						required
						type="number"
						id="celular"
						name="celular"
						label="Celular"
						fullWidth
						onChange={onChange}
					/>
				</Grid>

				{/* Formulario de celular */}
				<Grid item xs={6}>
					<TextField
						value={telefono}
						required
						type="number"
						id="telefono"
						name="telefono"
						label="Telefono"
						fullWidth
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControl className='form-control'>
						<InputLabel>Programa</InputLabel>
						<Select
							id='programa'
							value={carrera}
							onChange={onChangeProgramas}
							name='programa'
						>

							{
								carreras.map(({ value, label, codigo }) => (

									<MenuItem key={value} value={value} data-my-value={codigo} >
										{label}
									</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>

				{/* Asignatura  retos*/}
				<Grid item xs={12} >
					<BuscarSelect datos={asignaturas} cambiarEstado={(value) => onChangeAsignatura(value)}
						valor={asignatura} id='asignatura'
						nombre='Asignatura'
						placeholder='Seleccione Asginatura' />
				</Grid>

				{/* Electivas de retos */}

				{
					habilitarelectiva == true &&

					<Grid item xs={12} >
						<BuscarSelect datos={electivas} cambiarEstado={(value) => setearEstado({ electiva: value })} valor={electiva} id='electiva' nombre='Electiva' placeholder='Seleccione Electiva' />
					</Grid>
				}

				{/* Profesor asignatura */}

				<Grid item xs={12} md={12}>
					<InputBuscar
						placeholder='Seleccione Profesor'
						label={'Profesor'}
						id='profesor'
						value={(profesor.nombre_completo)}
						callback={() => setearEstado({ buscarProfesor: true })} />
				</Grid>


				{/* Buscar profesor */}
				<Dialog fullWidth={true} maxWidth="sm" open={buscarProfesor} onClose={() => setearEstado({ buscarProfesor: false })}>
					<AppBarModal titulo={'¡ Buscar Profesor !'} mostrarModal={() => setearEstado({ buscarProfesor: false })} titulo_accion="CERRAR" accion={() => setearEstado({ buscarProfesor: false })} />
					<DialogContent style={{ padding: '0' }} className='scroll'>

						<BuscarPersona callback={({ id, primer_nombre, primer_apellido, segundo_apellido }) => setearEstado({
							profesor: {
								id,
								nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
							}, buscarProfesor: false
						})} />

					</DialogContent>
					<DialogActions>
						<BtnForm texto="CANCELAR" callback={() => setearEstado({ buscarProfesor: false })} />
					</DialogActions>
				</Dialog>


				{/* Mensaje informativo de los datos */}
				<Grid item xs={12}>
					<AlertasSimple
						tipo='info'
						titulo={'Estimado estudiante, tener en cuenta: '}
						lista={mensaje_retos}
						margin='0px'
					/>
				</Grid>
			</Grid>

		</>
	)

}

function FormGrupoRetos(component_props) {
	let { mensaje_grupo_retos, buscar, modal_quitar_participante, integrantes_seleccionado, integrantes, setearEstado, quitarIntegrante } = component_props

	const agregarIntegrante = (integrante, integrantes) => {
		let { id, identificacion, primer_nombre, primer_apellido, segundo_apellido, correo } = integrante
		integrantes.push({ id, identificacion, nombre_completo: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, correo });

		setearEstado({ integrantes });
	}

	// QUITAR INTEGRANTE
	quitarIntegrante = (id) => {
		let { integrantes } = component_props;
		if (id) {
			let grupodeintegrantes = integrantes.filter((int) => int.id !== id);
			setearEstado({ integrantes: grupodeintegrantes, modal_quitar_participante: false });
		}
	}

	const acciones = integrantes => {
		const onClickQuitar = integrantes => setearEstado({ modal_quitar_participante: true, integrantes_seleccionado: integrantes });

		let quitar = <BtnCancelar callback={() => onClickQuitar(integrantes)} texto='Quitar' />
		return <div>{quitar}</div>;
	}

	return (

		<>
			<Grid container spacing={3} style={{ marginBottom: '40px' }}>
				<Grid item xs={12}>

					<ListarDatos
						datos={integrantes}
						titulo='Participantes de la actividad'
						buscar={false}
						opciones={false}
						agregar={true}
						actAgregar={() => setearEstado({ buscar: true })}
						sub_titulo='Participantes'
						id='tbl_participantes'
						acciones={row => acciones(row)}
						fila_principal={({ nombre_completo }) => nombre_completo.toUpperCase()}
						filas={[{ 'id': 'identificacion' }]}
					/>

					<ConfirmarAccion
						mensaje1={`Se quitará este integrante de la lista, `}
						ejecutarAccion={quitarIntegrante}
						mostrarModalConfAccion={modal_quitar_participante => setearEstado({ modal_quitar_participante })}
						modalConAccion={modal_quitar_participante}
						dato={integrantes_seleccionado}
						titulo={'¿ Quitar participante ?'}
					/>

				</Grid>

				{/* Buscar persona */}
				<Dialog fullWidth={true} maxWidth="sm" open={buscar} onClose={() => setearEstado({ buscar: false })}>
					<AppBarModal titulo={'¡ Buscar Persona !'} mostrarModal={() => setearEstado({ buscar: false })} titulo_accion="CERRAR" accion={() => setearEstado({ buscar: false })} />
					<DialogContent style={{ padding: '0' }} className='scroll'>
						<BuscarPersona
							callback={({ id, identificacion, primer_nombre, primer_apellido, segundo_apellido, correo }) => agregarIntegrante({ id, identificacion, primer_nombre, primer_apellido, segundo_apellido, correo }, integrantes)}
							seleccionarVarios={true} // Poder seleccionar varios
							listaSeleccionados={integrantes} // Lista para ir emparejando con los que ya están seleccionados
						/>
					</DialogContent>
					<DialogActions>
						<BtnForm texto="CERRAR" callback={() => setearEstado({ buscar: false })} />
					</DialogActions>
				</Dialog>
			</Grid>


			{/* Mensaje informativo del grupo retos */}
			<Grid item xs={12}>
				<AlertasSimple
					tipo='info'
					titulo={'Estimado estudiante, tener en cuenta: '}
					lista={mensaje_grupo_retos}
					margin='0px'
				/>
			</Grid>

		</>
	)
}

class GrupoRetosCrear extends Component {
	constructor(props) {
		super(props)
		this.state = {
			telefono: '',
			celular: '',
			carrera: '',
			asignatura: '',
			electiva: '',
			activeStep: 0,
			carreras: [],
			asignaturas: [],
			electivas: [],
			pregunta_retos: '',
			solucion_reto: '',
			integrantes: [],
			buscar: false,
			integrantes_seleccionado: { id: 0 },
			modal_quitar_participante: false,
			cargar: true,
			habilitarelectiva: false,
			titulo_carg: 'Cargando...',
			buscarProfesor: false,
			profesor: {
				id: 0,
				nombre_completo: '',
			},
		}
	}

	async componentDidMount() {
		this.obtenerDatos();
	}
	async obtenerDatos() {
		this.setState({ cargando: true })
		let { persona: { celular, telefono }, programas } = await obtenerMisDatos();
		let carreras = await this.transformar(programas.map(e => e.relacion));

		let informativo_retos_datos = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Dat' }]);
		let informativo_retos_grupo = await obtenerValoresFiltros([{ 'llave': 'codigo', 'valor': 'Ret_Info_Grup' }])


		this.setState({ celular, telefono, carreras, cargando: false, informativo_retos_datos: informativo_retos_datos.length > 0 ? informativo_retos_datos[0].descripcion : '', informativo_retos_grupo: informativo_retos_grupo.length > 0 ? informativo_retos_grupo[0].descripcion : '' });
	}

	transformar = data => {
		return data.map(e => ({
			value: e.id,
			label: e.nombre,
			codigo: e.codigo
		}));
	}

	// MOSTAR PROGRAMA

	onChangeProgramas = async (e) => {
		this.setState({ cargando: true })
		let id = e.currentTarget.dataset.value
		let codigo = e.currentTarget.dataset.myValue
		const { actualizarMensaje } = this.props;
		let { error, estado, titulo, asignaturas } = await this.obtenerAsignaturas(codigo);

		if (!error && estado === 200) {
			this.setState({
				codigo: codigo,
				carrera: id,
				asignaturas,
				profesor: '',
				asignatura: null,
			});
		} else {
			actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
		}
		this.setState({ cargando: false });
	}

	// MOSTRAR MATERIAS - ELECTIVAS - GENERAL

	onChangeAsignatura = async (asignatura) => {
		let { label, value } = asignatura
		let electiva = label.includes('ELECTIVA')
		this.setState({ habilitarelectiva: electiva, electiva: '' })

		if (electiva == true) {
			const { actualizarMensaje } = this.props;
			this.setState({ cargando: true })
			let { error, estado, titulo, electivas } = await this.obtenerElectivas(value)
			if (!error && estado === 200) {
				this.setState({ electivas })
			}
			else {
				actualizarMensaje({ titulo, tipo: 'info', mostrar: true, tiempo: 6000 });
			}
			this.setState({ cargando: false });

		}
		this.setState({ asignatura: asignatura });

	}

	async obtenerAsignaturas(programa) {
		return new Promise(resolve => {
			consulta(`api/v1.0/retos/materias/${programa}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			})
		})
	}

	async obtenerElectivas(value) {
		return new Promise(resolve => {
			consulta(`api/v1.0/retos/materiaselectivas/${value}`, null, null, (error, estado, resp) => {
				resolve(estado === 200 && !error ? resp : null);
			})
		})
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		})
	}

	checked = ({ target }) => {
		this.setState({
			[target.name]: target.checked,
			carrera: ''
		})
	}

	reiniciarEstado() {
		this.setState({
			activeStep: 0,
			cargando: false,
			titulo_carg: 'Cargando...',
			telefono: '',
			celular: '',
			carrera: '',
			asignatura: '',
			integrantes: [],
			profesor: {}
		})
	}


	// STEPER
	handleStep = direction => {
		let { actualizarMensaje } = this.props
		let { activeStep, carrera, asignatura, celular, profesor } = this.state
		let sw = true
		if ((!carrera || !asignatura || !celular || !profesor || celular == null) && activeStep == 0) {

			actualizarMensaje({ titulo: 'Debe diligenciar todos los campos obligatorios para continuar', tipo: 'info', mostrar: true, tiempo: 6000 });
			sw = false
		} else if ((celular.length == 0 || celular.length < 10) && activeStep == 0) {
			actualizarMensaje({ titulo: 'El numero celular debe tener 10 caracteres', tipo: 'info', mostrar: true, tiempo: 6000 });
			sw = false
		}

		if (sw) this.setState({ activeStep: direction ? activeStep + 1 : activeStep - 1 })
	}

	// OBTENER OBSERVACIONES
	obtenerObservaciones = async () => {
		this.setState({ cargando: true });
		let { informativo_retos_datos, informativo_retos_grupo } = this.state
		let ird = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Ret_Info_Dat' }])
		let irg = await obtenerPermisosValorAlt([{ 'llave': 'codigo', 'valor': 'Ret_Info_Grup' }])

		let info_retos_dat = [{ item: informativo_retos_datos }]
		let info_retos_gru = [{ item: informativo_retos_grupo }]

		ird.map(({ secundario }) => info_retos_dat.push({ item: secundario.nombre }));
		this.setState({ info_retos_dat });

		irg.map(({ secundario }) => info_retos_gru.push({ item: secundario.nombre }));
		this.setState({ info_retos_gru })
	}

	//ENVIAR INTEGRANTES DEL GRUPO RETOS

	enviarDatosGrupoRetos = () => {
		let { guardarGrupoRetos } = this.props
		let { carrera, asignatura, electiva, telefono, profesor, celular, integrantes } = this.state

		this.setState({ cargando: true, tit_cargando: 'Enviando..' })
		let grupodeintegrantes = []
		let correo_integrantes = []

		integrantes.map(({ id, correo }) => {
			grupodeintegrantes.push(id)
			correo_integrantes.push(correo)
		})

		let data = { celular: celular ? celular : '', telefono: telefono ? telefono : '', programa: carrera, profesor: profesor, codigo_asignatura: electiva ? electiva.value : asignatura.value, asignatura: electiva ? electiva.label : asignatura.label, integrantes: grupodeintegrantes, correo_integrantes: correo_integrantes }

		guardarGrupoRetos(data, () => this.reiniciarEstado())
	}

	configStepContent = () => {
		let { activeStep, carrera, asignatura, telefono, celular, programas_pregrado, check_retos, carreras, asignaturas, habilitarelectiva, electiva, electivas, profesor, buscarProfesor, informativo_retos_datos, informativo_retos_grupo, buscar, integrantes, modal_quitar_participante, integrantes_seleccionado } = this.state

		let mensaje_retos = [{ item: informativo_retos_datos }]
		let mensaje_grupo_retos = [{ item: informativo_retos_grupo }]

		switch (activeStep) {
			case 0:
				return (
					<Form
						{...{
							carrera,
							telefono,
							celular,
							carreras,
							programas_pregrado,
							asignatura,
							asignaturas,
							check_retos,
							habilitarelectiva,
							electiva,
							electivas,
							profesor,
							buscarProfesor,
							mensaje_retos,
							onChangeProgramas: this.onChangeProgramas,
							onChangeAsignatura: this.onChangeAsignatura,
							onChange: this.onChange,
							setearEstado: (estado) => this.setState(estado)
						}}

					/>
				)
			case 1:
				return (
					<FormGrupoRetos
						{...{
							mensaje_grupo_retos,
							buscar,
							integrantes,
							modal_quitar_participante,
							integrantes_seleccionado,
							onChange: this.onChange,
							setearEstado: (estado) => this.setState(estado)
						}}
					/>
				)

			default:
				return <TareasTerminadas imagen={emma_w} mensaje='Esta bandeja esta vacía' widthImg='10%' marginTop='10%' />
		}
	}


	render() {
		let { modalAddGrupoRetos, mostrarModalAddGrupoRetos } = this.props
		let { cargando, titulo_carg, activeStep } = this.state

		return (
			<>
				<Dialog fullWidth={true} maxWidth="sm" open={modalAddGrupoRetos} onClose={() => mostrarModalAddGrupoRetos(false)}>
					<AppBarModal titulo={"¡ Crea tu Grupo de Retos !"} mostrarModal={() => mostrarModalAddGrupoRetos(false)} titulo_accion="Cerrar" accion={() => mostrarModalAddGrupoRetos(false)} />
					<DialogContent className='scroll'>
						{
							!cargando ?
								<Grid container>
									<Grid item xs={12} md={12}>
										<Stepper activeStep={activeStep}>
											{
												configStep.map(label => (
													<Step key={label}>
														<StepLabel><p>{label}</p></StepLabel>
													</Step>
												))
											}
										</Stepper>
									</Grid>

									{this.configStepContent()}

								</Grid> :
								<TareasTerminadas mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" />
						}

					</DialogContent>
					<DialogActions>
						{activeStep > 0 ?
							<div>
								{!cargando  && (
									<>
										<BtnFormPage texto="ATRAS" callback={() => this.handleStep(false)} type="submit" />
										<BtnFormPage texto="GUARDAR" callback={() => this.enviarDatosGrupoRetos()} type="submit" />
									</>
								)}
							</div>

							: <BtnFormPage texto="SIGUIENTE" callback={() => this.handleStep(true)} type="submit" />
						}
					</DialogActions>
				</Dialog >
			</>
		)
	}
}

GrupoRetosCrear.propsTyps = {
	modalAddGrupoRetos: PropTypes.bool.isRequired,
	actulizarMensaje: PropTypes.bool.isRequired,
}

export default GrupoRetosCrear;