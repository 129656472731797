import React, { Component } from 'react';
import { connect } from "react-redux";
import { Grid, Paper, Card, CardHeader, CardContent, Avatar } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import { mostrarModalRespuestas, mostrarModalFiltrosAutodiagnostico, mostrarGestionAutodiag } from "../../redux/actions/actAutodiagnostico";
import { consulta, generarFiltros, crear_form_data, formulario, mostrarError, coloresEmma } from "../../global/js/funciones";
import { BtnDetalle, BtnGestionar, BtnRegresar } from "../general/BotonesAccion";
import TareasTerminadas from '../general/TareasTerminadas';
import ListarDatos from '../general/ListarDatos';
import TituloAccion from '../general/TituloAccion';
import AccionesRapidas from '../general/AccionesRapidas';
import AutodiagnosticoDetalle from "./autodiagnostico/AutodiagnosticoDetalle";
import AutodiagnosticoFiltrar from "./autodiagnostico/AutodiagnosticoFiltrar";
import AutodiagnosticoGestionar from "./autodiagnostico/AutodiagnosticoGestionar";
import AutodiagnosticoPermisos from "./autodiagnostico/AutodiagnosticoPermisos";
import { MenuAutodiagnostico } from "./autodiagnostico/MenuAutodiagnostico";
import emma_w from './../../global/imagenes/emma_w.png';
import emma_s1 from '../../global/imagenes/emma_s1.png';
import diag1 from '../../global/imagenes/diag1.png';
import diag2 from '../../global/imagenes/diag2.png';
import moment from 'moment'


class AutodiagnosticoAdministrar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cargando: true,
      autodiagnosticos: [],
      autodiagnostico: null,
      seleccion: 'Procesos',
      accionProceso: 'inicio',
      estados_procesos: [],
      filtros : [],
      menu: true,
      sintomas: 0,
      administrador: false,
      fecha_actual: ''
    }
  }

  componentDidMount() {
    let { mostrarModulo } = this.props
    mostrarModulo({ ruta: "/gestion_autodiagnostico", nombre: "Autodiagnóstico"})
    this.esAdministrador();
    this.setState({ cargando: false })
  }

  componentDidUpdate({ }, { accionProceso, menu }) {
    let { accionProceso: accionProcesoNew, menu: menuNew, filtros, sintomas } = this.state;

    if (accionProceso !== accionProcesoNew) {
      if (accionProcesoNew != 'Tod_Aut') {
        this.setState({ cargando: true })
        if (accionProcesoNew == 'inicio') this.obtenerEstadoSolicitudes([{ 'llave': 'sintomas', 'valor': sintomas }])
        else this.actualizarDataAutodiagnostico([{ 'llave': 'estado_actual__codigo', 'valor': accionProcesoNew }, { 'llave': 'sintomas', 'valor': sintomas }]);
      }
    }

    if (menu != menuNew && !menuNew) {
      this.obtenerEstadoSolicitudes(filtros)
    }
  }

  esAdministrador() {
    let { usuario } = this.props;
    if (usuario && usuario.perfil && (usuario.perfil.codigo === "Per_Adm" || usuario.perfil.codigo === "Per_Aut_n1")) {
      this.setState({ administrador: true })
    }
  }


  async obtenerAutodiagnosticos(filtros = []) {
    let f = await generarFiltros(filtros);
    return new Promise(resolve => {
      consulta(`api/v1.0/autodiagnostico/listar/1?${f}`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  async actualizarDataAutodiagnostico( filtros = [{ 'llave': 'sintomas', 'valor': this.state.sintomas }] ) {
    this.setState({ cargando: true })
    let autodiagnosticos = await this.obtenerAutodiagnosticos(filtros);
    this.setState({ autodiagnosticos, cargando: false, filtros })
  }

  obtenerEstadoSolicitudes = async ( filtros ) => {
    this.setState({ cargando: true })
    let f = await generarFiltros(filtros);
    consulta(`api/v1.0/autodiagnosticos/estados?${f}`, null, null, (error, estado, resp) => {
      if (estado === 200) {
        let { general, extra } = resp
        if (extra.length > 0) {
          extra.map(({ solicitudes, nombre, codigo, orden }) => {
            let reporte = {
              'estado_actual__codigo': codigo,
              'estado_actual__nombre': nombre,
              'num_sol': solicitudes,
              'orden': orden
            }
            general.push(reporte); 
          })
        }
        this.setState({ estados_procesos: general.sort((a, b) => a.orden - b.orden ), cargando: false });
      }
    })
  }

  gestionarAutodiagnostico = async (id, data, callback) => {
    let { filtros } = this.state
    let { mostrarGestionAutodiag, actualizarMensaje } = this.props;
    let form = await crear_form_data(data);
    formulario(`api/v1.0/autodiagnosticos/${id}/gestionar`, form, "post", (error, estado, resp) => {
      let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
      if (!error) {
        if (estado === 200) {
          tipo = 'success';
          titulo = resp.titulo;
          mostrarGestionAutodiag(false);
          this.actualizarDataAutodiagnostico(filtros);
        } else {
          tipo = 'info';
          titulo = resp.titulo ? resp.titulo : mostrarError(resp);
        }
      }
      actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
      callback();
    })
  }

  pintarInicio() {
    let { accionProceso } = this.state;
    return (
      <>
        {accionProceso === 'inicio' ? this.pintarInicioProcesos() : this.pintarProcesos()}
        {accionProceso !== 'inicio' && <div className={'regresar'} ><BtnRegresar callback={() => this.setState({ accionProceso: 'inicio', filtros: [] })} /></div>}
      </>
    )
  }

  pintarMenu = () => {
    let { menu } = this.state
    return menu ? this.pintarOpcionesMenu() : this.pintarInicio()
  }

  pintarOpcionesMenu = () => {
    const handleClickMenu = (valor) => {
      this.setState({ menu: false, sintomas: valor, filtros : [{ 'llave': 'sintomas', 'valor': valor }] })
    }

    return (
      <Grid container direction={'row'} justifyContent={'center'} style={{ paddingTop: 70 }} >
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Grid container direction={'row'} justifyContent={'center'}>
            <Grid item xs={12} sm={6} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => handleClickMenu(0)} key={1}>
              <Paper elevation={8} square>
                <Card style={{ maxWidth: "100%", height: '350px', cursor: 'pointer' }} elevation={1}>
                  <CardHeader
                    avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}> S </Avatar>}
                    title={'Sin Síntomas'}
                  />
                  <CardContent >
                    <Grid container direction={'row'} justifyContent={'center'}>
                      <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                        <img src={diag2} style={{ width: '180px', maxWidth: '200px', minWidth: '100px', height: "180px" }} alt='Imagen Tipo' />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} style={{ padding: '2% 2% 0% 2%' }} onClick={() => handleClickMenu(1)} key={2}>
              <Paper elevation={8} square>
                <Card style={{ maxWidth: "100%", height: '350px', cursor: 'pointer' }} elevation={1}>
                  <CardHeader
                    avatar={<Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}> C </Avatar>}
                    title={'Con Síntomas'}
                  />
                  <CardContent >
                    <Grid container direction={'row'} justifyContent={'center'}>
                      <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center', height: "150px" }}>
                        <img src={diag1} style={{ width: '180px', maxWidth: '200px', minWidth: '100px', height: "180px" }} alt='Imagen Tipo' />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  pintarInicioProcesos = () => {
    let { estados_procesos } = this.state;
    let { mostrarModalFiltrosAutodiagnostico } = this.props
    
    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Aut_Env': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Aut_Pen': { 'backgroundColor': coloresEmma.solicitudactiva, color },
        'Aut_Rev': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Aut_Com': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
        'Tod_Aut': { 'backgroundColor': coloresEmma.filtrotodos, color }
      }
      return (colores[codigo])
    }

    const handleClick = (codigo) => {
      console.log(codigo)
      if (codigo != 'Tod_Aut') this.setState({ accionProceso: codigo })
      else mostrarModalFiltrosAutodiagnostico(true);
    };

    return (
      <>
        <div style={{ padding: '1% 0% 0% 1%' }}>
          <BtnRegresar callback={() => this.setState({ menu: true, filtros: [] })} />
        </div>
        <div style={{ padding: '0% 2% 0% 2%' }} >
          <Grid container direction={'row'} spacing={1} justify={'center'} >
            <Grid item xs={12} sm={12} md={12} lg={10} >
              <Grid container direction={'row'} alignItems="center" spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <TituloAccion principal='' alterno='Seleccione el estado a gestionar: ' />
                </Grid>
              </Grid>
              <Paper elevation={0} square className="scroll contenido_fijo">
                {(estados_procesos.length === 0) ?
                  <TareasTerminadas mensaje='Aún no hay solicitudes para dia de hoy.' marginTop='7%' imagen={emma_s1} widthImg="7%" /> :
                  <Grid container direction={'row'} spacing={1} style={{ width: "100%" }}>
                    {estados_procesos.map(({ estado_actual__codigo: codigo, estado_actual__nombre: nombre, num_sol }, i) => {
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
                          <Paper elevation={1} style={{ border: '1px solid #e0e0e0' }} onClick={() => handleClick(codigo)}>
                            <AccionesRapidas imagen='' titulo={`¡ ${nombre} !`} sub_titulo='' des_sub_titulo='' avatar={obtenerColor(codigo)} avatar_name={nombre.charAt(0).toUpperCase()} list_desc={Number.isInteger(num_sol) ? [`Numero de Solicitudes : ${num_sol}`] : ['Debe aplicar los filtros.']} />
                          </Paper>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  pintarProcesos() {
    let { autodiagnosticos, autodiagnostico, filtros } = this.state;
    let { mostrarModalRespuestas, mostrarGestionAutodiag, modalGestionAutodiag, mostrarModalFiltrosAutodiagnostico } = this.props;

    const obtenerColor = (codigo) => {
      let color = 'white';
      const colores = {
        'Aut_Env': { 'backgroundColor': coloresEmma.colorblanco, 'color': '#757575' },
        'Aut_Pen': { 'backgroundColor':coloresEmma.solicitudactiva, color },
        'Aut_Rev': { 'backgroundColor': coloresEmma.solicitudenrevision, color },
        'Aut_Com': { 'backgroundColor': coloresEmma.solicitudfinalizada, color },
      }
      return (colores[codigo])
    }

    const acciones = (data) => {
      let { estado_actual: { codigo }, permiso } = data;
      
      const onClickDetalle = autodiagnostico => {
        this.setState({ autodiagnostico }) 
        mostrarModalRespuestas(true)
      }

      const onClickGestionar = autodiagnostico => {
        this.setState({ autodiagnostico })
        mostrarGestionAutodiag(true)
      }

      let detalle = <BtnDetalle callback={() => onClickDetalle(data)} color='#01579b' texto='Abrir' />;
      let gestionar = (codigo !== 'Aut_Com' && permiso === '1') && <BtnGestionar callback={() => onClickGestionar(data)} color='#00bfa5' texto='Gestionar' />;
      return <div>{detalle}{gestionar}</div>;
    }
    return (
      <div>
        <AutodiagnosticoGestionar 
          autodiagnostico={autodiagnostico}
          modalGestionAutodiag={modalGestionAutodiag}
          mostrarGestionAutodiag={mostrarGestionAutodiag}
          gestionarAutodiagnostico={this.gestionarAutodiagnostico}
          actualizarMensaje={actualizarMensaje}
        />
        <ListarDatos
          datos={autodiagnosticos}
          titulo='Lista de Tests Autodiagnóstico'
          id='tbl_autodiagnostico'
          opciones={true}
          descargar='TEST AUTODIAGNOSTICO - EMMA'
          filtrar={true}
          refrescar={true}
          actfiltrar={() => mostrarModalFiltrosAutodiagnostico(true)}
          actRefrescar={() => this.actualizarDataAutodiagnostico(filtros)}
          acciones={(data) => acciones(data)}
          buscar={true}
          avatar={({ estado_actual }) => estado_actual.nombre.toUpperCase().charAt(0)}
          color_avatar={({ estado_actual }) => obtenerColor(estado_actual.codigo)}
          fila_principal={({ usuario_registro : { primer_nombre, primer_apellido, segundo_apellido} }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`}
          filas={[
            { 'id': 'estudiante', 'mostrar': ({ usuario_registro : { primer_nombre, primer_apellido, segundo_apellido} }) => `${primer_nombre} ${primer_apellido} ${segundo_apellido}`, 'enLista': false },
            { 'id': 'identificacion', 'mostrar': ({ usuario_registro: { identificacion } }) => identificacion },
            { 'id': 'fecha_registro', 'mostrar': ({ fecha_registro }) => moment(fecha_registro).format('YYYY-MM-DD, h:mm a') },
            { 'id': 'sintomas', 'mostrar': ({ sintomas }) => sintomas == 1 ? 'SI': 'NO', 'nombre': 'Sintomas: ' },
            { 'id': 'estado', 'mostrar': ({ estado_actual }) => estado_actual.nombre },
          ]}
        />
      </div>
    )
  }

  configFiltros = (accion_actual) => {
    let { sintomas } = this.state
    switch (accion_actual) {
      case 'Tod_Aut':
        return [{ 'llave': 'sintomas', 'valor': sintomas }]
      case 'inicio':
        return [{ 'llave': 'sintomas', 'valor': sintomas }]
      default:
        return [{ 'llave': 'sintomas', 'valor': sintomas }, { 'llave': 'estado_actual__codigo', 'valor': accion_actual }]
    }
  }

  render() {
    let { cargando, autodiagnostico, seleccion, administrador, accionProceso } = this.state
    let { actualizarMensaje, mostrarModalRespuestas, modalRespuestas, mostrarModalFiltrosAutodiagnostico, modalFiltros } = this.props

    return (
      <div>
        <MenuAutodiagnostico
          seleccionar={(seleccion) => this.setState({ seleccion })}
          sele={seleccion}
          administrador={administrador} 
        />
        <div className="contenido_modulo">
          <AutodiagnosticoDetalle
            modalRespuestas={modalRespuestas}
            mostrarModalRespuestas={mostrarModalRespuestas}
            autodiagnostico={autodiagnostico}
            admin={true}
          />
          <AutodiagnosticoFiltrar
            actualizarMensaje={actualizarMensaje}
            modalFiltrosAutodiagnostico={modalFiltros}
            mostrarModalFiltrosAutodiagnostico={mostrarModalFiltrosAutodiagnostico}
            filtrar={(filtros) => this.actualizarDataAutodiagnostico(this.configFiltros(accionProceso).concat(filtros))} 
            limpiar={() => this.actualizarDataAutodiagnostico(this.configFiltros(accionProceso))}
            accionProceso={accionProceso}
            mostrarTodos = {(accion) => this.setState({ accionProceso : accion})}  
          />
          { !cargando ? 
            <div>
              {seleccion === 'Procesos' && this.pintarMenu()}
              {seleccion === 'Permisos' && <AutodiagnosticoPermisos/>}
            </div>
            : <TareasTerminadas mensaje='Cargando..' marginTop='7%' imagen={emma_w} widthImg="7%" />}
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  let { modalRespuestas, modalFiltros, modalGestionAutodiag } = state.redAutodiagnostico
  let { usuario } = state.redGlobal;
  return {
    modalRespuestas,
    modalFiltros,
    modalGestionAutodiag,
    usuario
  }
}

const mapDispatchToProps = {
  mostrarModulo,
  actualizarMensaje,
  mostrarModalRespuestas,
  mostrarModalFiltrosAutodiagnostico,
  mostrarGestionAutodiag
}

export default connect(mapStateToProps, mapDispatchToProps)(AutodiagnosticoAdministrar)