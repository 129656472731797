import { MODAL_SOL_TUTORIA,
          MODAL_DET_TUTORIA,
          MODAL_FILTRO_TUTORIA,
          MODAL_GES_TUTORIA,
          MODAL_FIL_DES_TUTORIA,
          MODAL_MOD_PERIODO_TUTORIA} from "../actions/actTutorias"
  
  const initialState = {
    modalSolTutoria: false,
    modalDetTutoria: false,
    modalFiltroTutoria:false,
    modalGestionTutoria:false,
    modalFiltroDescargaTutoria:false,
    modalPeriodoTutoria:false,
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case MODAL_SOL_TUTORIA:
        return Object.assign({}, state, {
          modalSolTutoria: action.show
        })
      case MODAL_DET_TUTORIA:
        return Object.assign({}, state, {
          modalDetTutoria: action.show
        })
      case MODAL_FILTRO_TUTORIA:
        return Object.assign({}, state, {
          modalFiltroTutoria: action.show
      })
      case MODAL_GES_TUTORIA:
      return Object.assign({}, state, {
        modalGestionTutoria: action.show
      })
      case MODAL_FIL_DES_TUTORIA:
        return Object.assign({}, state, {
          modalFiltroDescargaTutoria: action.show
      })
      case MODAL_MOD_PERIODO_TUTORIA:
        return Object.assign({}, state, {
          modalPeriodoTutoria: action.show
      })
      default:
        return state;
    }
  }
  
  export default reducer;
  