import React, { Component } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core'
import AppBarModal from '../general/AppBarModal'
import { BtnDetalle, BtnForm } from '../general/BotonesAccion';
import ListarDatos from '../general/ListarDatos';

export default class Preguntas extends Component {
    render() {
        let {
            openModal,
            closeModal,
            data,
            actions,
            descargar
        } = this.props
        return (
            <Dialog open={openModal} onClose={closeModal} fullWidth={true} maxWidth="sm" fullScreen={window.innerWidth < 600}>
                <AppBarModal titulo={"Encuestados"} mostrarModal={closeModal} titulo_accion="" />
                <DialogContent style={{ padding: 0, overflowX: 'hidden' }} >
                    <React.Fragment>
                        <ListarDatos
                            id="tbl_inf_encuestados"
                            datos={data}
                            titulo={"Encuestado"}
                            opciones={true}
                            buscar={true}
                            acciones={actions}
                            fila_principal={({ primer_nombre, primer_apellido }) => `${primer_nombre.toUpperCase()} ${primer_apellido.toUpperCase()}`}
                            filas={[]}
                            opcionAdicional={true}
                            actDescargar={descargar}
                        />
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    <BtnForm texto="CERRAR" callback={closeModal} />
                </DialogActions>
            </Dialog>

        )
    }
}