import React, { Component } from 'react';
import { connect } from "react-redux";
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Paper, AppBar, Toolbar, Button, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/WarningRounded';
// //FUNCIONES GENERALES
import { consulta, mostrarError } from "../../global/js/funciones";
import TareasTerminadas from '../general/TareasTerminadas';
import AppBarModal from '../general/AppBarModal';
import { BtnForm } from '../general/BotonesAccion';
// //FUNCIONES DEL MODULO
import { mostrarModulo, actualizarMensaje } from "../../redux/actions/actGlobal";
import emma_s from '../../global/imagenes/emma_s1.png'
import emma_w from './../../global/imagenes/emma_w.png';
import { coloresEmma } from '../../global/js/funciones';

class ReporteVacuna extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estado_vacuna: '',
      observaciones: '',
      cargando: true,
      titulo_carg: 'Cargando..',
      reporte: false,
      modalEnviado: false,
      mensaje_resp: ''
    }
  }

  componentDidMount() {
    let { mostrarModulo } = this.props;
    mostrarModulo({ ruta: '/reporte_vacuna', nombre: 'Reporte Vacuna' })
    this.actualizarReporte()
  }

  async actualizarReporte() {
    this.setState({ cargando: true })
    let data = await this.validarReporte()
    this.setState({ cargando: false, reporte: data.reporte })
  }

  validarReporte() {
    return new Promise(resolve => {
      consulta(`api/v1.0/reportevac/validar`, null, null, (error, estado, resp) => {
        resolve(estado === 200 && !error ? resp : null);
      })
    })
  }

  guardarReporte() {
    let { estado_vacuna, observaciones } = this.state
    let { actualizarMensaje } = this.props

    if (estado_vacuna) {
      let data = { estado_vacuna, observaciones }
      console.log(data)
      this.setState({ cargando: true, titulo_carg: 'Enviando..' })
      consulta(`api/v1.0/reportevac/crear`, data, "post", async (error, estado, resp) => {
        let titulo = 'Ha ocurrido un error, contacte con el administrador.', tipo = 'error';
        if (!error) {
          if (estado === 200) {
            tipo = 'success';
            titulo = resp.titulo;
            // await this.actualizarReporte()
            this.setState({ estado_vacuna: '', observaciones: '', mensaje_resp: titulo, modalEnviado: true })
          } else {
            tipo = 'info';
            titulo = resp.titulo ? resp.titulo : mostrarError(resp);
          }
        }
        this.setState({ cargando: false, titulo_carg: 'Cargando..' })
      })

    }
    else actualizarMensaje({ titulo: 'Seleccione su estado', tipo: 'info', mostrar: true, tiempo: 6000 });
  }


  render() {
    let { observaciones, estado_vacuna, cargando, titulo_carg, modalEnviado, mensaje_resp } = this.state
    let respuestas = ['Vacunado con dosis completa', 'Vacunado solo primera dosis', 'No vacunado']

    return (
      <>
        <Grid container direction={'row'} spacing={0} style={{ width: "100%", textAlign: "center", margin: "0 auto", padding: "3px 0px 30px 0px", height: "100hv", backgroundColor: 'white', }}>
          {cargando ?
            <TareasTerminadas marginBottom={"3px"} mensaje={titulo_carg} marginTop='7%' imagen={emma_w} widthImg="7%" /> :
            <>
              <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '40px' }}>
                <AppBar position="fixed" elevation={0} style={{ backgroundColor: 'white', top: 65 }}>
                  <Toolbar>
                    <div style={{ color: 'black' }}>
                      <p>
                        <span style={{ color: coloresEmma.secondarycolor, marginLeft: '15px' }}><WarningIcon style={{ marginTop: '10px' }} /></span>
                        <span style={{ marginLeft: "10px" }} > Para la Universidad de la Costa CUC es muy importante conocer el estado del proceso de vacunación de cada uno de sus estudiantes, por lo que te invitamos a diligenciar el siguiente formulario</span>
                      </p>
                    </div>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid item xs={12} sm={12} md={12} className='contenido_encuesta'>
                <Paper elevation={0} square className="scroll" style={{ width: "100%", padding: "0px 0px 30px 0px" }}>
                  <FormControl style={{ padding: "10px 0px 0px 10px", width: "95%" }} key={1}>
                    <FormLabel style={{ textAlign: 'justify', marginBottom: '15px' }} component="legend">{`1) Indique en que estado se encuentra su proceso de vacunación: *`}</FormLabel>
                    <RadioGroup value={estado_vacuna}>
                      {respuestas.map((respuesta, i) =>
                        <FormControlLabel key={i} onClick={() => this.setState({ estado_vacuna: respuesta })} value={respuesta} control={<Radio />} label={respuesta} />
                      )}
                    </RadioGroup>
                  </FormControl>

                  <FormControl style={{ padding: "10px 0px 0px 10px", width: "95%", marginTop: '20px' }} key={2}>
                    <FormLabel style={{ textAlign: 'justify', marginBottom: '15px' }} component="legend">{`2) Observaciones: `}</FormLabel>
                    <TextField style={{ width: '100%', margin: '5px 0px 5px 0px' }} value={observaciones} placeholder='Ingrese Respuesta' onChange={({ target: { value } }) => this.setState({ observaciones: value })} />
                  </FormControl>

                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AppBar position="fixed" color="primary" style={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}>
                  <Toolbar>
                    <Button color="primary" onClick={() => this.guardarReporte()} style={{ marginLeft: 10 }}> ENVIAR </Button>
                  </Toolbar>
                </AppBar>
              </Grid>
            </>
          }
        </Grid>

        <Dialog open={modalEnviado} maxWidth="xs" fullWidth={true} onClose={() => this.setState({ modalEnviado: false })} >
          <AppBarModal titulo='¡ Mensaje !' mostrarModal={() => this.setState({ modalEnviado: false })} titulo_accion='' />
          <DialogContent className='scroll' >
            <TareasTerminadas marginBottom={"3px"} mensaje={mensaje_resp} marginTop='7%' imagen={emma_s} widthImg="1%" />
            <Typography style={{textAlign: 'center'}} variant="body1" color="textSecondary" component="p" align="justify">{'Gracias por registrar tu estado de vacunación, ahora ya podrás visualizar tus notas académicas.'}</Typography>
          </DialogContent>
          <DialogActions>
            <BtnForm texto="Volver a Inicio" callback={() => this.props.history.push('/')} />
            <BtnForm texto="Ver Notas" callback={() => this.props.history.push('/consultas')} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = {
  mostrarModulo,
  actualizarMensaje,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReporteVacuna);
