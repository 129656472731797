import React from 'react';
import emma_s1 from "../../../global/imagenes/emma_s1.png";
import TareasTerminadas from "../../general/TareasTerminadas";
import Cargando from "../../general/app/Cargando";
import TituloAccion from "../../general/TituloAccion";
// import ClaseCard from '../ClaseCard';
import { Grid, Paper, Table, TableRow, TableCell, TableBody, } from '@material-ui/core';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ConfirmarAccion from "../../general/ConfirmarAccion";
import CardSolicitud from "./CardSolicitud";
import BarraProceso from "../../general/BarraProceso";
import revisar from "../../../global/imagenes/revisar.png";
import AccionesRapidas from "../../general/AccionesRapidas";
import moment from "moment";
import { PersonalVideo } from '@material-ui/icons';
import ImportContactsIcon from "@material-ui/icons/ImportContacts";

export const PintarProcesos = (cargando, cantidad, seleccion, datos_solicitud, valorc, abrir_detalle) => {

  if (!cargando) {
    if (cantidad > 0) {
      return (
        <div className="">
          <div style={{ padding: "2% 2% 0% 2%" }}>
            <Grid container direction={"row"} spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal="" alterno="¡ Información Básica !" />
                <Paper elevation={0} square className="scroll">
                  <Table>
                    <TableBody>
                      <TableRow key={20}>
                        <TableCell component="th" scope="row">
                          Nombre
                        </TableCell>
                        <TableCell align="left">
                          {datos_solicitud.persona
                            ? datos_solicitud.persona["primer_nombre"] +
                              " " +
                              datos_solicitud.persona["primer_apellido"] +
                              " " +
                              datos_solicitud.persona["segundo_apellido"]
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow key={1}>
                        <TableCell component="th" scope="row">
                          Identificación
                        </TableCell>
                        <TableCell align="left">
                          {datos_solicitud.persona
                            ? datos_solicitud.persona["identificacion"]
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow key={2}>
                        <TableCell component="th" scope="row">
                          Programa
                        </TableCell>
                        <TableCell align="left">
                          {datos_solicitud.programa
                            ? datos_solicitud.programa["nombre"]
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableBody>
                      <TableRow key={18}>
                        <TableCell component="th" scope="row">
                          Estado Actual
                        </TableCell>
                        <TableCell align="left">
                          {datos_solicitud.estado_solicitud
                            ? datos_solicitud.estado_solicitud["nombre"]
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow key={19}>
                        <TableCell component="th" scope="row">
                          Fecha Registro
                        </TableCell>
                        <TableCell align="left">
                          {datos_solicitud
                            ? moment(datos_solicitud["fecha_registra"]).format(
                                "YYYY-MM-DD, h:mm a"
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TituloAccion principal="" alterno="¿ Que deseas hacer ?" />
                <Paper elevation={0} square className="scroll">
                  <Grid
                    container
                    direction={"row"}
                    spacing={1}
                    style={{ width: "100%" }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Paper
                        elevation={1}
                        style={{ border: "1px solid #e0e0e0" }}
                        onClick={() => abrir_detalle("true")}
                      >
                        <AccionesRapidas
                          imagen={revisar}
                          titulo="¡ Más Información !"
                          sub_titulo="Bien"
                          des_sub_titulo=", Aquí puedes ver toda la información actual."
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TituloAccion principal="" alterno="¿ Como va mi proceso ?" />
                <BarraProceso
                  steps={[
                    { label: "SOLICITADO" },
                    { label: "EN TRAMITE" },
                    { label: "ASIGNATURAS A CURSAR" },
                    { label: "FINALIZADO" },
                  ]}
                  activeStep={valorc}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }else{
      return (
       
        <div
          onClick={() => seleccion("nuevo")}
          className="pointer"
        >
          <TareasTerminadas
            mensaje="Hola, no cuentas con ningún proceso activo, clic aquí para iniciar un nuevo proceso"
            imagen={emma_s1}
            widthImg="7%"
            marginTop="7%"
          />
        </div>
      );
    }
    
  } else return <Cargando width="100%" marginTop="15%" />;
};

export function InvVirtMenu({ sele = 0, seleccionar = () => { } }) {
  const pintar = (claseN = '') => {
    return (
      <div className={claseN}>
        <BottomNavigation showLabels >
          {/* <BottomNavigationAction label={'Detalle'} icon={<ImportContactsIcon />} className={clase('detalle', sele)} onClick={() => seleccionar('detalle')} /> */}
          {/* <BottomNavigationAction label={'Estados'} icon={<LoopIcon />} className={clase('estados', sele)} onClick={() => seleccionar('estados')} /> */}
        </BottomNavigation>
      </div>
    )
  }

  return pintar('menu_modal');
}