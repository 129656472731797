const MODAL_ADD_PRACTICA = 'MODAL_ADD_PRACTICA'
const MODAL_DEL_PRACTICA = 'MODAL_DEL_PRACTICA'
const MODAL_ADM_ADJUNTOS_PRACTICA = 'MODAL_ADM_ADJUNTOS_PRACTICA'
const MODAL_MOD_PRACTICAS = 'MODAL_MOD_PRACTICAS'
const MODAL_TEST_PRACTICAS = 'MODAL_TEST_PRACTICAS'
const MODAL_DETALLE_PRACTICA = 'MODAL_DETALLE_PRACTICAS'
const MODAL_ADD_ADJUNTO = 'MODAL_ADD_ADJUNTO'
const MODAL_PROG_PART_PRACTICAS = 'MODAL_PROG_PART_PRACTICAS'
const MODAL_GES_PRACTICA = 'MODAL_GES_PRACTICA'
const MODAL_CAN_PRACTICA = 'MODAL_CAN_PRACTICA'
const ACT_DATA_PRACTICAS = 'ACT_DATA_PRACTICAS'
const ACT_DATA_PRACTICA = 'ACT_DATA_PRACTICA'
const ACT_RENDER_PRACTICAS = 'ACT_RENDER_PRACTICAS'
const ACT_RENDER_ADJUNTOS_PRACTICAS = 'ACT_RENDER_ADJUNTOS_PRACTICAS'
const ACT_DATA_ADJUNTOS_PRACTICA = 'ACT_DATA_ADJUNTOS_PRACTICA'
const ACT_DATA_ADJUNTO_PRACTICA = 'ACT_DATA_ADJUNTO_PRACTICA'
const ACT_DATA_MODALIDADES = 'ACT_DATA_MODALIDADES'
const ACT_MOD_PRACTICA = 'ACT_MOD_PRACTICA'
const ACT_REQ_MODALIDAD = 'ACT_REQ_MODALIDAD'
const ACT_DATA_ESTADO = 'ACT_DATA_ESTADO'
const ACT_DATA_ESTADOS = 'ACT_DATA_ESTADOS'
const ACT_DATA_EMPRESA = 'ACT_DATA_EMPRESA'
const ACT_DATA_EMPRESAS = 'ACT_DATA_EMPRESAS'
const MODAL_ADD_EMPRESA = 'MODAL_ADD_EMPRESA'
const MODAL_DET_ADJUNTO = 'MODAL_DET_ADJUNTO'
const MODAL_VAL_REQUISITO = 'MODAL_VAL_REQUISITO'
const MODAL_DEL_EMPRESA = 'MODAL_DEL_EMPRESA'
const MODAL_MOD_EMPRESA = 'MODAL_MOD_EMPRESA'
const ACT_DATA_SUCURSAL = 'ACT_DATA_SUCURSAL'
const ACT_DATA_SUCURSALES = 'ACT_DATA_SUCURSALES'
const MODAL_ADD_SUCURSAL = 'MODAL_ADD_SUCURSAL'
const MODAL_INI_ACTA = 'MODAL_INI_ACTA'
const MODAL_DEL_SUCURSAL = 'MODAL_DEL_SUCURSAL'
const MODAL_MOD_SUCURSAL = 'MODAL_MOD_SUCURSAL'
const MODAL_ADM_BUSCAR_EMPRESA = 'MODAL_ADM_BUSCAR_EMPRESA'
const MODAL_ADM_BUSCAR_EMPRESA_OTRA_VEZ = 'MODAL_ADM_BUSCAR_EMPRESA_OTRA_VEZ'
const MODAL_ADD_FILTROS_PRACTICA = 'MODAL_ADD_FILTROS_PRACTICA'
const MODAL_MASIVO_FILTRO_PRACTICA = 'MODAL_MASIVO_FILTRO_PRACTICA'
const MODAL_ADD_FILTROS_HV = 'MODAL_ADD_FILTROS_HV'
const MODAL_MOD_PERIODO_PRACTICA = 'MODAL_MOD_PERIODO_PRACTICA'
const MODAL_AYUDA = 'MODAL_AYUDA'
const MODAL_ENVIAR_HV = 'MODAL_ENVIAR_HV'
const MODAL_ADD_EMPRESA_PROCESO = 'MODAL_ADD_EMPRESA_PROCESO'
const MODAL_ADM_PERMISO_ESTADO = 'MODAL_ADM_PERMISO_ESTADO'
const MODAL_ADM_PERMISO_MODALIDAD = 'MODAL_ADM_PERMISO_MODALIDAD'
const MODAL_ADM_NOTIFICACIONES_PRACTICAS = 'MODAL_ADM_NOTIFICACIONES_PRACTICAS'
const MODAL_EMPRESAS_HV = 'MODAL_EMPRESAS_HV'
const MODAL_INFORME = 'MODAL_INFORME'
const MODAL_ACCIONES_MASIVAS_PRACTICA = 'MODAL_ACCIONES_MASIVAS_PRACTICA'
const MODAL_ADD_FINALIZADO_CONTRATO = 'MODAL_ADD_FINALIZADO_CONTRATO'
const MODAL_FUISTE_CONTRATADO = 'MODAL_FUISTE_CONTRATADO'
const MODAL_ADD_NOTA='MODAL_ADD_NOTA'
const MODAL_ADD_VALORES_SUCURSAL='MODAL_ADD_VALORES_SUCURSAL'
const MODAL_FILTRO_ID='MODAL_FILTRO_ID'


const mostrarModalPermisoModalidad = show => {
  return {
    type: MODAL_ADM_PERMISO_MODALIDAD,
    show,
  }
}

const mostrarModalAprobarRequisito = show => {
  return {
    type: MODAL_VAL_REQUISITO,
    show,
  }
}

const mostrarModalAddFiltrosHv = show => {
  return {
    type: MODAL_ADD_FILTROS_HV,
    show,
  }
}

const mostrarModalAdmBuscarEmpresa = show => {
  return {
    type: MODAL_ADM_BUSCAR_EMPRESA,
    show,
  }
}
const mostrarModalAdmBuscarEmpresaotravez = show => {
  return {
    type: MODAL_ADM_BUSCAR_EMPRESA_OTRA_VEZ,
    show,
  }
}


const mostrarModalDetalleAdjunto = show => {
  return {
    type: MODAL_DET_ADJUNTO,
    show,
  }
}

const mostrarModalIniActa = show => {
  return {
    type: MODAL_INI_ACTA,
    show,
  }
}

const mostrarModalPracticasNotificaciones = show => {
  return {
    type: MODAL_ADM_NOTIFICACIONES_PRACTICAS,
    show,
  }
}

const mostrarModalPermisoEstado = show => {
  return {
    type: MODAL_ADM_PERMISO_ESTADO,
    show,
  }
}

const mostrarModalAddEmpresaProceso = show => {
  return {
    type: MODAL_ADD_EMPRESA_PROCESO,
    show,
  }
}


const mostrarModalEnviarHV = show => {
  return {
    type: MODAL_ENVIAR_HV,
    show,
  }
}

const mostrarModalAyuda = show => {
  return {
    type: MODAL_AYUDA,
    show,
  }
}

const mostrarModalModPeriodo = show => {
  return {
    type: MODAL_MOD_PERIODO_PRACTICA,
    show,
  }
}

const mostrarModalAddFiltros = show => {
  return {
    type: MODAL_ADD_FILTROS_PRACTICA,
    show,
  }
}

const mostrarModalMasivoFiltro = show => {
  return {
    type: MODAL_MASIVO_FILTRO_PRACTICA,
    show,
  }
}

const mostrarModalDelEmpresa = show => {
  return {
    type: MODAL_DEL_EMPRESA,
    show,
  }
}

const mostrarModalModEmpresa = show => {
  return {
    type: MODAL_MOD_EMPRESA,
    show,
  }
}

const mostrarModalAddEmpresa = show => {
  return {
    type: MODAL_ADD_EMPRESA,
    show,
  }
}

const actualizarDataEmpresa = empresa => {
  return {
    type: ACT_DATA_EMPRESA,
    empresa,
  }
}

const actualizarDataEmpresas = empresas => {
  return {
    type: ACT_DATA_EMPRESAS,
    empresas,
  }
}


const mostrarModalDelSucursal = show => {
  return {
    type: MODAL_DEL_SUCURSAL,
    show,
  }
}

const mostrarModalModSucursal = show => {
  return {
    type: MODAL_MOD_SUCURSAL,
    show,
  }
}

const mostrarModalAddSucursal = show => {
  return {
    type: MODAL_ADD_SUCURSAL,
    show,
  }
}

const actualizarDataSucursal = sucursal => {
  return {
    type: ACT_DATA_SUCURSAL,
    sucursal,
  }
}

const actualizarDataSucursales = sucursales => {
  return {
    type: ACT_DATA_SUCURSALES,
    sucursales,
  }
}

const mostrarModalAddPracticas = show => {
  return {
    type: MODAL_ADD_PRACTICA,
    show,
  }
}

const mostrarModalCanPracticas = show => {
  return {
    type: MODAL_CAN_PRACTICA,
    show,
  }
}

const mostrarModalProParticipante = show => {
  return {
    type: MODAL_PROG_PART_PRACTICAS,
    show,
  }
}

const actualizarDataEstados = estados => {
  return {
    type: ACT_DATA_ESTADOS,
    estados,
  }
}

const actualizarDataEstado = estado => {
  return {
    type: ACT_DATA_ESTADO,
    estado,
  }
}

const mostrarModalGestionarPractica = show => {
  return {
    type: MODAL_GES_PRACTICA,
    show,
  }
}

const mostrarModalAdmAjuntosPractica = show => {
  return {
    type: MODAL_ADM_ADJUNTOS_PRACTICA,
    show,
  }
}
const mostrarModalDelPracticas = show => {
  return {
    type: MODAL_DEL_PRACTICA,
    show,
  }
}
const mostraModalModalidadesPracticas = show => {
  return {
    type: MODAL_MOD_PRACTICAS,
    show,
  }
}
const mostrarModalTestPracticas = show => {
  return {
    type: MODAL_TEST_PRACTICAS,
    show,
  }
}
const mostrarModalDetallePractica = show => {
  return {
    type: MODAL_DETALLE_PRACTICA,
    show,
  }
}
const mostrarModalAddAdjunto = show => {
  return {
    type: MODAL_ADD_ADJUNTO,
    show,
  }
}

const actualizarDataPracticas = practicas => {
  return {
    type: ACT_DATA_PRACTICAS,
    practicas,
  }
}

const actualizarDataPractica = practica => {
  return {
    type: ACT_DATA_PRACTICA,
    practica,
  }
}

const actualizarRenderPracticas = render => {
  return {
    type: ACT_RENDER_PRACTICAS,
    render,
  }
}
const actualizarRenderAdjuntosPracticas = render => {
  return {
    type: ACT_RENDER_ADJUNTOS_PRACTICAS,
    render,
  }
}

const actualizarDataAdjuntosPractica = adjuntos => {
  return {
    type: ACT_DATA_ADJUNTOS_PRACTICA,
    adjuntos,
  }
}

const actualizarDataAdjuntoPractica = adjunto => {
  return {
    type: ACT_DATA_ADJUNTO_PRACTICA,
    adjunto,
  }
}

const actualizarModalidadesParcticas = modalidades => {
  return {
    type: ACT_DATA_MODALIDADES,
    modalidades,
  }
}

const actualizarModalidadPractica = modalidad => {
  return {
    type: ACT_MOD_PRACTICA,
    modalidad,
  }
}

const actualizarRequisitosModalidad = requisitos => {
  return {
    type: ACT_REQ_MODALIDAD,
    requisitos,
  }
}

const mostrarModalEmpresasHV = show => {
  return {
    type: MODAL_EMPRESAS_HV,
    show,
  }
}

const mostrarModalInforme = show => {
  return {
    type: MODAL_INFORME,
    show,
  }
}

const mostrarModalAccionesMasivasPractica = show => {
  return {
    type: MODAL_ACCIONES_MASIVAS_PRACTICA,
    show,
  }
}
const mostrarPrueba = show => {
  
  return {
    type: MODAL_FUISTE_CONTRATADO,
    show,
  }
}
const mostrarModalAddNota = show => {
  
  return {
    type: MODAL_ADD_NOTA,
    show,
  }
}
const mostrarModalAddValoresSucursal = show => {
  
  return {
    type: MODAL_ADD_VALORES_SUCURSAL,
    show,
  }
}
const mostrarModalFiltroId = show => {
  
  return {
    type: MODAL_FILTRO_ID,
    show,
  }
}

module.exports = {
  MODAL_ADD_PRACTICA,
  MODAL_DEL_PRACTICA,
  ACT_DATA_PRACTICAS,
  ACT_RENDER_PRACTICAS,
  ACT_DATA_PRACTICA,
  ACT_DATA_ADJUNTOS_PRACTICA,
  ACT_DATA_ADJUNTO_PRACTICA,
  MODAL_ADM_ADJUNTOS_PRACTICA,
  MODAL_MOD_PRACTICAS,
  MODAL_TEST_PRACTICAS,
  MODAL_DETALLE_PRACTICA,
  MODAL_ADD_ADJUNTO,
  ACT_DATA_MODALIDADES,
  ACT_RENDER_ADJUNTOS_PRACTICAS,
  ACT_MOD_PRACTICA,
  ACT_REQ_MODALIDAD,
  MODAL_GES_PRACTICA,
  ACT_DATA_ESTADO,
  ACT_DATA_ESTADOS,
  ACT_DATA_EMPRESA,
  ACT_DATA_EMPRESAS,
  MODAL_ADD_EMPRESA,
  MODAL_DEL_EMPRESA,
  MODAL_MOD_EMPRESA,
  MODAL_PROG_PART_PRACTICAS,
  MODAL_CAN_PRACTICA,
  ACT_DATA_SUCURSALES,
  ACT_DATA_SUCURSAL,
  MODAL_ADD_SUCURSAL,
  MODAL_DEL_SUCURSAL,
  MODAL_MOD_SUCURSAL,
  MODAL_MOD_PERIODO_PRACTICA,
  MODAL_ADD_FILTROS_PRACTICA,
  MODAL_MASIVO_FILTRO_PRACTICA,
  MODAL_AYUDA,
  MODAL_ENVIAR_HV,
  MODAL_ADD_EMPRESA_PROCESO,
  MODAL_ADM_PERMISO_ESTADO,
  MODAL_ADM_NOTIFICACIONES_PRACTICAS,
  MODAL_INI_ACTA,
  MODAL_DET_ADJUNTO,
  MODAL_ADM_BUSCAR_EMPRESA,
  MODAL_ADM_BUSCAR_EMPRESA_OTRA_VEZ,
  MODAL_ADD_FILTROS_HV,
  MODAL_VAL_REQUISITO,
  MODAL_ADM_PERMISO_MODALIDAD,
  MODAL_EMPRESAS_HV,
  MODAL_INFORME,
  MODAL_ACCIONES_MASIVAS_PRACTICA,
  MODAL_FUISTE_CONTRATADO,
  MODAL_ADD_NOTA,
  MODAL_ADD_VALORES_SUCURSAL,
  MODAL_FILTRO_ID,
  mostrarModalFiltroId,
  mostrarModalAddPracticas,
  mostrarModalTestPracticas,
  mostrarModalAddAdjunto,
  actualizarDataPracticas,
  actualizarRenderPracticas,
  mostrarModalDelPracticas,
  actualizarDataPractica,
  actualizarDataAdjuntosPractica,
  actualizarDataAdjuntoPractica,
  actualizarRenderAdjuntosPracticas,
  mostrarModalAdmAjuntosPractica,
  mostraModalModalidadesPracticas,
  mostrarModalDetallePractica,
  actualizarModalidadesParcticas,
  actualizarModalidadPractica,
  actualizarRequisitosModalidad,
  mostrarModalGestionarPractica,
  actualizarDataEstado,
  actualizarDataEstados,
  mostrarModalProParticipante,
  mostrarModalCanPracticas,
  actualizarDataEmpresas,
  mostrarModalAddEmpresa,
  mostrarModalModEmpresa,
  mostrarModalDelEmpresa,
  actualizarDataEmpresa,
  mostrarModalDelSucursal,
  mostrarModalModSucursal,
  mostrarModalAddSucursal,
  actualizarDataSucursal,
  actualizarDataSucursales,
  mostrarModalAddFiltros,
  mostrarModalMasivoFiltro,
  mostrarModalModPeriodo,
  mostrarModalAyuda,
  mostrarModalEnviarHV,
  mostrarModalAddEmpresaProceso,
  mostrarModalPermisoEstado,
  mostrarModalPracticasNotificaciones,
  mostrarModalIniActa,
  mostrarModalDetalleAdjunto,
  mostrarModalAdmBuscarEmpresa,
  mostrarModalAdmBuscarEmpresaotravez, 
  mostrarModalAddFiltrosHv,
  mostrarModalAprobarRequisito,
  mostrarModalPermisoModalidad,
  mostrarModalEmpresasHV,
  mostrarModalInforme,
  mostrarModalAccionesMasivasPractica,
  mostrarPrueba,
  mostrarModalAddNota,
  mostrarModalAddValoresSucursal
}
