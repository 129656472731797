import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BuscarSelect from "../../general/BuscarSelect";
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select,Button, } from '@material-ui/core';
import AlertasSimple from '../../general/AlertasSimple';
import { obtenerValoresFiltros } from '../../../global/js/funciones';
export default class InscripcionAgregarMisDatos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cargando: true,
            mensaje: '',
            datos: {},
            Mensaje_Progra_Virtual:'',
            Link_Prog_Academico:'',
        }
        this.fileInput = React.createRef();
    }
    componentDidMount() {
        obtenerValoresFiltros([{ llave: "codigo", valor: "Mens_Prog_Virt" }]).then(data=> this.setState({ Mensaje_Progra_Virtual:data[0].descripcion,Link_Prog_Academico: data[0].valora}));
      }
    handleCheckboxChange = async (event) => {
        let {setIsChecked1,setIsChecked2} = this.props.props_c;
        const { name } = event.target;
        this.setState({ [name]: name });
        if(name=='checked1') {
            // this.setState({isChecked1:checked,isChecked2:false})
            setIsChecked1(true)
            setIsChecked2(false)
            // this.formEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }else if (name=='checked2') {
            setIsChecked2(true)
            setIsChecked1(false)
        }
        
       
    }

    render() {
        // let {  } = this.state
        let {numero_creditos, institucion_procedencia, programa_procedencia, programas, tipo_estudios, programa_select, tipo_est_select, setProgramaSeleccionado, setTipoEstudio, onChange, isChecked1, isChecked2} = this.props.props_c;
        let { Mensaje_Progra_Virtual,Link_Prog_Academico }=this.state
        const { handleCheckboxChange } = this
        // setProgramaSeleccionado('hola')
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <BuscarSelect datos={programas} cambiarEstado={(value) => setProgramaSeleccionado(value)} valor={programa_select} id='select-programa-seleccionado' nombre='Programa' placeholder='Seleccione Programa' />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <BuscarSelect datos={tipo_estudios} cambiarEstado={(value) => setTipoEstudio(value)} valor={tipo_est_select} id='select-tipo-estudio' nombre='¿Cuál es tu tipo de estudio?' placeholder='Seleccione tipo de estudio' />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField value={programa_procedencia} id="programa_procedencia" name="programa_procedencia" label="¿Cuál es tu programa de procedencia?" fullWidth onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField value={institucion_procedencia} id="institucion_procedencia" name="institucion_procedencia" label="¿Cuál es tu institución de procedencia?" fullWidth onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={numero_creditos} type="number" id="numero_creditos" name="numero_creditos" label="Número de créditos" fullWidth onChange={onChange}/>
                        <FormHelperText>
                            De 1 a 21 créditos
                        </FormHelperText>
                    </Grid>
                    {programa_select && programa_select.valorc == 'pregrado_virtual'?
                <>
                <Grid item xs={12}>
                    <FormControl className='form-control'>
                        <AlertasSimple 
                                tipo='info'
                                titulo={<p style={{ textAlign: 'justify', marginBottom: '-15px',marginTop:'-8px', fontSize:'.9rem'}}> {Mensaje_Progra_Virtual}</p>}
                                margin = '0px'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                     <InputLabel style={{ marginLeft: '5px' }}>Desea continuar con el proceso ?
                        {' '}
                        <span style={{ marginRight: '10px' }}>SI</span>
                        <Checkbox
                        name='checked1'
                        edge="start"
                        onChange={handleCheckboxChange}
                        checked={isChecked1}
                        color="primary"
                        />
                        <span style={{ marginRight: '10px' }}>NO</span>
                        <Checkbox
                        name='checked2'
                        edge="start"
                        onChange={handleCheckboxChange}
                        checked={isChecked2}
                        color="primary"
                        />
                    </InputLabel>
                </Grid> 
                {(isChecked1 ==  true) ?
                <>
                <Grid item xs={12} >
                    <Typography variant="body1">
                    <p style={{marginLeft:'5px', marginTop: '-2px' }}>Deberá inscribirse en el siguiente enlace <Button target="_blank" href={Link_Prog_Academico} variant="contained" color="secondary" style={{color: '#FFFFFF'}} size="small">click aquí</Button> (Los documentos solicitados de inscripción son: copia del documento de identidad, copia de las pruebas saber 11 y copia del diploma de bachiller) </p>
                    </Typography>
                </Grid>
                </>
                :''}
                </>
                :''}
                </Grid>
            </React.Fragment>
        );
    }
}
