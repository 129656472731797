import React, { Component } from "react";
import { PropTypes } from "prop-types";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  InputLabel, FormControl, Select, MenuItem, Checkbox, FormControlLabel
} from "@material-ui/core";
import { LUGAR_VISITA } from '../helper';
import AppBarModal from "../../general/AppBarModal";
import { BtnFormPage } from "../../general/BotonesAccion";
import TareasTerminadas from "../../general/TareasTerminadas";
import emma_w from "../../../global/imagenes/emma_w.png";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { consulta, crear_form_data, formulario, mostrarError } from "../../../global/js/funciones";
import InputBuscar from "../../general/InputBuscar";
import BuscarPersona from "../../general/BuscarPersona";
import BuscarColegio from "../../general/BuscarColegio";
import { BtnForm } from "../../general/BotonesAccion";
import { es } from 'date-fns/locale'
import { obtenerValoresFiltros } from "../../../global/js/funciones";


class VisitaCrear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      titulo_carg: "",
      activeStep: 0,
      fecha_visita: null,
      hora_inicio: null,
      hora_fin: null,
      lugar_visita: '',
      numero_estudiante: "",
      responsable: "",
      colegio: "",
      buscarPersona: false,
      buscarColegio: false,
      persona: {
        id: 0,
        nombre: "",
      },
      Colegio: {
        id: 0,
        nombre: '',
      },
      estado_actual: 1,
      lugares_visita: [],
      check_material_audio: false,
      check_stand: false
    };

  }

  componentDidMount() {
    this.setState({ cargando: true });
    let {
      colegio,
      fecha_visita,
      hora_inicio,
      hora_fin,
      lugar,
      numero_estudiante,
      responsable,
    } = this.props;
    this.setState({
      colegio,
      // fecha_visita,
      hora_inicio,
      hora_fin,
      lugar,
      numero_estudiante,
      responsable,
      persona: { id: 0, nombre: "" },
      Colegio: { id: 0, nombre: "" },
    });

    this.obtenerGenericas()
  }


  async componentDidUpdate({ modalSolVisita }) {
    let { modalSolVisita: modalSolVisitaNew } = this.props;
    if (modalSolVisitaNew !== modalSolVisita) {
      if (!modalSolVisitaNew) {
        this.reiniciarEstado()
      }
    }
  }

  async obtenerGenericas() {
    let lugares_visita = await obtenerValoresFiltros([{ 'llave': 'generica', 'valor': LUGAR_VISITA }]);
    lugares_visita = lugares_visita.reverse()
    this.setState({ lugares_visita });
  }

  configStepContent = () => {
    let {
      activeStep,
      fecha_visita,
      hora_inicio,
      hora_fin,
      lugar,
      persona,
      Colegio,
      lugares_visita,
      lugar_visita,
      material_audio,
      check_material_audio,
      check_stand
    } = this.state;
    switch (activeStep) {
      case 0:
        return (
          <>
            <Grid container spacing={2} style={{ marginBottom: "2px" }}>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <InputBuscar
                  id="colegio"
                  label="Colegios"
                  placeholder="Clic aquí para buscar el colegio"
                  value={Colegio.nombre}
                  callback={() => this.setState({ buscarColegio: true })}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ marginTop: 3, width: "100%" }}
                    required={true}
                    minDate={Date("YYYY-MM-dd")}
                    label="Fecha Visita"
                    margin="normal"
                    id="fecha_visita"
                    format="yyyy-MM-dd"
                    value={fecha_visita}
                    fullWidth
                    onChange={this.onChangeFecha}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    minDateMessage={`La fecha debe ser mayor que la fecha actual.`}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    required={true}
                    style={{ marginTop: 3, width: "100%" }}
                    margin="normal"
                    label="Hora Inicio"
                    value={hora_inicio}
                    // format="H:mm"
                    onChange={this.onChangeHoraI}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    invalidDateMessage="Formato no valido"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    required={true}
                    style={{ marginTop: 3, width: "100%" }}
                    margin="normal"
                    label="Hora Fin"
                    // format="H:mm"
                    value={hora_fin}
                    onChange={this.onChangeHoraF}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    invalidDateMessage="Formato no valido"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid style={{ marginBottom: "20px" }} item xs={6}>
                <FormControl className="form-control" required>
                  <InputLabel id="demo-simple-select-label">Seleccione el lugar de la visita</InputLabel>
                  <Select placeholder="Clic aquí para seleccionar el lugar de visita" id="lugar_visita" value={lugar_visita} name='lugar_visita' onChange={this.onChange} >
                    {lugares_visita.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  id="numero_estudiante"
                  name="numero_estudiante"
                  label="Número de Estudiantes"
                  fullWidth
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                <InputBuscar
                  id="persona"
                  label="Responsable"
                  placeholder="Clic aquí para buscar"
                  value={persona.nombre}
                  callback={() => this.setState({ buscarPersona: true })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check_material_audio}
                      onChange={this.checked}
                      id="check_material_audio"
                      name="check_material_audio"
                      color="primary"
                    />
                  }
                  label="¿ Requiere material audiovisual ?"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check_stand}
                      onChange={this.checked}
                      id="check_stand"
                      name="check_stand"
                      color="primary"
                    />
                  }
                  label="¿ Requiere stand ?"
                />
              </Grid>

            </Grid>
          </>
        );
      default:
        return (
          <TareasTerminadas
            imagen={emma_w}
            mensaje="Est bandeja esta  vacía"
            widthImg="10%"
            marginTop="10%"
          />
        );
    }
  };

  checked = ({ target }) => {
    this.setState({
      [target.name]: target.checked,
    });
  };

  submitVisita = async () => {
    let {
      fecha_visita,
      hora_inicio,
      hora_fin,
      numero_estudiante,
      persona,
      Colegio,
      estado_actual,
      lugar_visita,
      check_material_audio,
      check_stand
    } = this.state;
    console.log(hora_inicio);
    let titulo = "";
    let { actualizarMensaje, mostrarModalCrearVisita } = this.props; //edi
    this.setState({ cagando: true, titulo_carg: "Enviando..." });
    console.log(persona)
    let data = {
      colegio: Colegio.id,
      fecha_visita: fecha_visita
        ? moment(fecha_visita).format("YYYY-MM-DD")
        : "",
      hora_inicio: moment(hora_inicio).format("H:mm:00"),
      hora_fin: moment(hora_fin).format("H:mm:00"),
      numero_estudiante,
      responsable: persona.id,
      estado_actual,
      lugar_visita,
      material_audio: check_material_audio ? 1 : 0,
      stand: check_stand ? 1 : 0
    };
    let datos = await crear_form_data(data);
    console.log(moment(hora_inicio).format("H:mm:00"))
    if (!Colegio) titulo = "Escoja un colegio.";
    else if (!fecha_visita) titulo = "Ingrese la fecha de la visita.";
    else if (!hora_inicio) titulo = "Ingrese la hora de inicio.";
    else if (!hora_fin) titulo = "Ingrese la hora de finalización.";
    else if (!lugar_visita) titulo = "Ingrese el lugar.";
    else if (!numero_estudiante) titulo = "Ingrese el número de estudiantes.";
    else if (!persona || persona.id == 0) titulo = "Ingrese al responsable.";
    else if (hora_inicio > hora_fin) titulo = "La visita no puede finalizar antes de la hora de inicio"
    if (titulo) {
      actualizarMensaje({ titulo, tipo: "info", mostrar: true, tiempo: 6000 });
    } else {
      formulario(
        `api/v1.0/visitas/crear`,
        datos,
        "POST",
        (error, estado, resp) => {
          let titulo = "Ha ocurrido un error, contacte con el administrador.",
            tipo = "error";
          if (!error) {
            if (estado === 200) {
              tipo = "success";
              titulo = resp.titulo;
              mostrarModalCrearVisita(false);
              this.props.obtenerAlertas();
              // this.props.pintarVisitasCalendario();
              this.reiniciarEstado()
            } else {
              tipo = "info";
              titulo = resp.titulo ? resp.titulo : mostrarError(resp);
            }
          }
          actualizarMensaje({ titulo, tipo, mostrar: true, tiempo: 6000 });
          // callback();
        }
      );
      // this.props.guardarVisita(data);
    }
  };

  reiniciarEstado = () => {
    this.setState({
      cargando: false,
      activeStep: 0,
      colegio: "",
      fecha_visita: null,
      hora_inicio: null,
      hora_fin: null,
      lugar: "",
      numero_estudiante: "",
      persona: "",
      Colegio: '',
      lugar_visita: '',
      check_material_audio: false,
      check_stand: false
    });
  };

  onChange = ({ target }) => {
    let { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  onChangeFecha = (date) => {
    this.setState({ fecha_visita: date });
  };

  onChangeHoraI = (date) => {
    this.setState({ hora_inicio: date });
  };

  onChangeHoraF = (date) => {
    this.setState({ hora_fin: date });
  };


  render() {
    let { modalSolVisita, mostrarModalCrearVisita } = this.props;
    let { buscarPersona, buscarColegio } = this.state;
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={modalSolVisita}
          onClose={() => mostrarModalCrearVisita(false)}
        >
          <AppBarModal
            titulo={"¡ Registrar Visita !"}
            mostrarModal={() => mostrarModalCrearVisita(false)}
            titulo_accion="Cerrar"
            accion={() => mostrarModalCrearVisita(false)}
          />
          <DialogContent className="scroll">
            <Grid container>
              {
                <Grid style={{ padding: "2% 2% 0% 2%" }} item xs={12} md={12}>
                  {this.configStepContent()}
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <div>
              <BtnFormPage
                texto="CERRAR"
                callback={() => mostrarModalCrearVisita(false)}
              />
            </div>
            <BtnFormPage
              texto="GUARDAR"
              callback={this.submitVisita}
              type="submit"
            />
          </DialogActions>
        </Dialog>


        <Dialog
          fullWidth={true}
          maxWidth="sm"
          fullScreen={window.innerWidth < 600}
          open={buscarPersona}
          onClose={() => this.setState({ buscarPersona: false })}
        >
          <AppBarModal
            titulo={"¡ Buscar Persona !"}
            mostrarModal={() => this.setState({ buscarPersona: false })}
            titulo_accion="CERRAR"
            accion={() => this.setState({ buscarPersona: false })}
          />
          <DialogContent style={{ padding: "0" }} className="scroll">
            <BuscarPersona
              callback={({
                id,
                primer_nombre,
                primer_apellido,
                segundo_apellido,
              }) =>
                this.setState({
                  persona: {
                    id,
                    nombre: `${primer_nombre} ${primer_apellido} ${segundo_apellido}`,
                  },
                  buscarPersona: false,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() => this.setState({ buscarPersona: false })}
            />
          </DialogActions>
        </Dialog>


        {/* BUSCAR COLEGIO */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          fullScreen={window.innerWidth < 600}
          open={buscarColegio}
          onClose={() => this.setState({ buscarColegio: false })}
        >
          <AppBarModal
            titulo={"¡ Buscar Colegio !"}
            mostrarModal={() => this.setState({ buscarColegio: false })}
            titulo_accion="CERRAR"
            accion={() => this.setState({ buscarColegio: false })}
          />
          <DialogContent style={{ padding: "0" }} className="scroll">
            <BuscarColegio
              callback={({
                id,
                nombreColegio,
              }) =>
                this.setState({
                  Colegio: {
                    id,
                    nombre: nombreColegio,
                  },
                  buscarColegio: false,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <BtnForm
              texto="CANCELAR"
              callback={() => this.setState({ buscarColegio: false })}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

VisitaCrear.prototypes = {
  modalSolVisita: PropTypes.bool.isRequired,
  actualizarMensaje: PropTypes.func.isRequired,
  mostrarModalCrearVisita: PropTypes.func.isRequired,
  guardarVisita: PropTypes.func.isRequired,
};
export default VisitaCrear;
