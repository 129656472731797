import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BtnForm } from '../../general/BotonesAccion';
import AppBarModal from '../../general/AppBarModal';
import { consulta } from '../../../global/js/funciones';
import TareasTerminadas from '../../general/TareasTerminadas';
import CustomDropzone from '../../general/CustomDropzone';
import emma_w from '../../../global/imagenes/emma_w.png';
import { MenuGestionar } from './MatriculasMenu';
import { formulario, obtenerValores } from '../../../global/js/funciones';
import {
	Checkbox,
	DialogActions,
	Dialog,
	DialogContent,
	FormControl,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	TextField,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core';
import { ESTADOS_ID, FECHASGRADO, TIPOS_EXONERACION } from './helper';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper
	}
}));

function RenderEstados({ estados_siguiente, estado, cambiarSeleccion }) {
	const classes = useStyles();
	return (
		<List className={classes.root} subheader={<ListSubheader component="div">Seleccione Estado</ListSubheader>}>
			{estados_siguiente.length >=1 && estados_siguiente.map(({ secundario }) => {
				let { codigo, nombre } = secundario;
				return (
					<ListItem key={codigo} role={undefined} dense button onClick={() => cambiarSeleccion(codigo)}>
						<ListItemIcon>
							<Checkbox edge="start" checked={estado === codigo} tabIndex={-1} disableRipple />
						</ListItemIcon>
						<ListItemText id={codigo} primary={nombre.toUpperCase()} />
					</ListItem>
				);
			})}
		</List>
	);
}
const obtenerEstadosProceso = (id, idMatricula, filtros = []) => {
	let f = '';
	filtros.map(({ llave, valor }) => (f = `${f}&${llave}=${valor}`));
	return new Promise((resolve) => {
		consulta(
			`api/v1.0/matriculas/estados/${idMatricula}/${id}?${f}`,
			null,
			null,
			(error, estado, resp) => {
				resolve(resp);
			},
		);
	});
};

class MatriculasGestionar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cargando: true,
			titulo_res: 'Cargando..',
			estado: '',
			motivo: '',
			motivos: false,
			estados_siguiente: [],
			archivos: [],
			seleccion: 'gestion',
			fechasGrado: [],
			tiposExoneracion: [],
			fechaGrado: '',
			tipoExoneracion: '',
			link: ''
		};
	}

	async componentDidUpdate({ open }, { estado }) {
		let { open: modalGestionarMatricula, matricula } = this.props;
		let { estado: estadoNew } = this.state;
		if (estadoNew !== estado)
			this.setState({ motivos: true, motivo: '', cargando: false });

		if (open !== modalGestionarMatricula) {
			if (modalGestionarMatricula) {
				this.reiniciarEstado();
				let estados_siguiente = await obtenerEstadosProceso(matricula.tipo.id, matricula.id, [
					{ llave: 'principal', valor: matricula.estado_solicitud.id },
					{ llave: 'secundario__generica', valor: ESTADOS_ID }
				]);
				this.setState({ estado: '', mensaje: '', estados_siguiente, cargando: false });
				obtenerValores(FECHASGRADO).then((data) => {
					this.setState({fechasGrado:data});
				});
				obtenerValores(TIPOS_EXONERACION).then((data) => {
					this.setState({tiposExoneracion:data});
				});
			}
		}

	}

	reiniciarEstado() {
		this.setState({
			cargando: true,
			titulo_res: 'Cargando..',
			estado: '',
			estados_siguiente: [],
			archivos: [],
			seleccion: 'gestion', 
			tipoExoneracion: ''
		});
	}

	onChange = ({ target }) => {
		let { value, name } = target;
		this.setState({
			[name]: value
		});
	};

	onSubmit = async () => {
		const { matricula, gestionarMatricula, cambiarEstado, actualizarMensaje } = this.props;
		const { estado, archivos, motivo, fechaGrado, tipoExoneracion, tiposExoneracion, link } = this.state;
		let sw = true;
		let data = { motivo: '', estado, archivos, link };
		let codExoneracion = '';
		if (motivo) data.motivo = motivo;
		if (fechaGrado) data.fechaGrado = fechaGrado; 
		if (tipoExoneracion){
			data.tipoExoneracion = tipoExoneracion; 
			let found = tiposExoneracion.find(element => element.id == tipoExoneracion);
			let { codigo } = found;
			codExoneracion = codigo;
		}
		if (((matricula.tipo.codigo === 'Mat_Pro_Sol_Rein_Tran' && (estado === 'Mat_Pro' || estado === 'Mat_Rein_Tran_Apr_Fac' || estado === 'Mat_Ter')) || (matricula.tipo.codigo === 'Mat_Pro_Sol_Gra' && (estado === 'Mat_Ter' ||  estado === 'Mat_Pro_Ter_Exo' || (estado === 'Mat_Pro_Ter_Exo' && codExoneracion == 'Mat_Exo_Reg_Est')))) && archivos.length <= 0) {
			sw = false;
			if(matricula.tipo.codigo === 'Mat_Pro_Sol_Rein_Tran' && estado === 'Mat_Rein_Tran_Apr_Fac') actualizarMensaje({ titulo: 'Por favor adjunte el documento de estudio de homologación', tipo: 'info', mostrar: true, tiempo: 6000 });
			else actualizarMensaje({ titulo: 'Por favor adjunte el Volante de pago', tipo: 'info', mostrar: true, tiempo: 6000 });
		}

		if (sw) {
			cambiarEstado({ render: false })
			this.setState({ cargando: true, titulo_res: 'Enviando..' });
			console.log('Eviando gestión al servidor')
			await gestionarMatricula(matricula.id, data);
			// if ((estado === 'Mat_Ter' || estado === 'Mat_Pro' || estado === 'Mat_Rein_Tran_Apr_Fac' || estado === 'Mat_Pro_Ter_Exo') && archivos.length) await this.cargarArchivos(matricula.id, archivos);
			if (archivos.length) await this.cargarArchivos(matricula.id, archivos);
			this.setState({ cargando: false, titulo_res: 'Cargando..' });
			cambiarEstado({ render: true })
		}
		this.setState({tipoExoneracion: ''});
	}

	cargarArchivos(id, archivos) {
		let { actualizarMensaje } = this.props;
		if (archivos.length === 0) return true;
		const data = new FormData();
		data.append(`total`, archivos.length);
		archivos.map((file, index) => data.append(`archivo${index}`, file));
		formulario(`api/v1.0/matriculas/${id}/adjuntar`, data, 'post', (error, estado, resp) => {
			if (error) {
				const title = 'Ha ocurrido un error al adjuntar los archivos, contacte con el administrador.';
				const icon = 'error';
				actualizarMensaje({ titulo: title, tipo: icon, mostrar: true, tiempo: 6000 });
			}
		});
	}

	pintarEstados() {
		let { estado, estados_siguiente, motivo, fechaGrado, fechasGrado, tiposExoneracion, tipoExoneracion, link } = this.state;
		return (
			<Grid container>
				<Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
					<RenderEstados
						cambiarSeleccion={(estado) => this.setState({ estado })}
						estado={estado}
						estados_siguiente={estados_siguiente}
					/>
				</Grid>
				{estado && estado !== "Mat_Pro_Sol_Cam_Fec" && (
					<Grid item xs={12} md={12}>
						<FormControl className="form-control" >
							<TextField
								value={motivo}
								id="txtMotivoRechazo"
								label="Observaciones"
								name="motivo"
								placeholder="Digite observaciones de la solicitud"
								multiline
								onChange={this.onChange}
							/>
						</FormControl>
					</Grid>
				)}
				{ estado === "Mat_Rein_Tran_Est" && (
					<Grid item xs={12} md={12}>
						<FormControl className="form-control" >
							<TextField
								value={link}
								id="txtLink"
								label="Link"
								name="link"
								placeholder="Digite el link"
								multiline
								onChange={this.onChange}
							/>
						</FormControl>
					</Grid>
				)}
				{estado === "Mat_Pro_Sol_Cam_Fec" && (
					<Grid item xs={12} md={12}>
						<FormControl className='form-control'>
							<InputLabel>Fecha de Grado</InputLabel>
							<Select
								value={fechaGrado}
								onChange={(e) => this.setState({ fechaGrado: e.target.value })}
							>
								{
									fechasGrado.map((fg) => (
										<MenuItem key={fg.id} value={fg.id}> {fg.nombre} </MenuItem>
									))
								}
							</Select>
						</FormControl>
					</Grid>
				)}
				{(estado === "Mat_Sol_Gra_Apr_Fac" || estado === 'Mat_Pro_Ter_Exo') && (
					<Grid item xs={12} md={12}>
						<FormControl className='form-control'>
							<InputLabel>Tipo de Exoneración</InputLabel>
							<Select
								value={tipoExoneracion}
								onChange={(e) => this.setState({ tipoExoneracion: e.target.value })}
							>
								{
									tiposExoneracion.map((fg) => (
										<MenuItem key={fg.id} value={fg.id}> {fg.nombre} </MenuItem>
									))
								}
							</Select>
						</FormControl>
					</Grid>
				)}
			</Grid>
		);
	}

	eliminarArchivo = (i) => {
		let { archivos } = this.state;
		let { actualizarMensaje } = this.props;
		archivos.splice(i, 1);
		this.setState({ archivos });
		actualizarMensaje({ titulo: `Archivo Retirado.`, tipo: 'success', mostrar: true, tiempo: 6000 });
	};

	agregarArchivos = (arc) => {
		let { archivos } = this.state;
		archivos = archivos.concat(arc);
		this.setState({ archivos });
	};

	pintarAdjuntos() {
		return (
			<CustomDropzone
				archivos={this.state.archivos}
				addFunction={this.agregarArchivos}
				deleteFunction={this.eliminarArchivo}
			/>
		);
	}

	pintar() {
		let { seleccion } = this.state;
		return (
			<div>
				{seleccion === 'gestion' && this.pintarEstados()}
				{seleccion === 'adjuntos' && this.pintarAdjuntos()}
			</div>
		);
	}

	render() {
		let { open, setOpen, matricula } = this.props;
		let { cargando, titulo_res, seleccion } = this.state;
		return (
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={open}
				onClose={setOpen.bind(this, false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="scroll"
			>
				<AppBarModal
					titulo={'¿ Gestionar Proceso ?'}
					mostrarModal={setOpen}
					titulo_accion="Cerrar"
					accion={() => setOpen(false)}
				/>
				<MenuGestionar
					seleccionar={(seleccion) => this.setState({ seleccion })}
					sele={seleccion}
					matricula={matricula}
				/>
				<DialogContent style={{ padding: '1' }} className="scroll">
					{cargando ? (
						<TareasTerminadas mensaje={titulo_res} marginTop="7%" imagen={emma_w} widthImg="7%" cargando={true}/>
					) : (
						<div style={{ padding: '5px 0 5px 0' }}>{this.pintar()}</div>
					)}
				</DialogContent>
				<DialogActions>
					<BtnForm texto="CANCELAR" callback={() => setOpen(false)} />
					{!cargando && <BtnForm texto="ACEPTAR" callback={() => this.onSubmit()} />}
				</DialogActions>
			</Dialog>
		);
	}
}

//Props del componente
MatriculasGestionar.propTypes = {
	//variables
	open: PropTypes.bool.isRequired,
	matricula: PropTypes.object.isRequired,
	//funciones
	setOpen: PropTypes.func.isRequired,
	gestionarMatricula: PropTypes.func.isRequired
};

export default MatriculasGestionar;
