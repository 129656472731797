import {
    MODAL_DET_CONSULTORIA,
    MODAL_GES_CONSULTORIA,
    MODAL_ADD_CONSULTAS,
    MODAL_ADD_FILTROS_CONSULTORIA,
    MODAL_INFORME_CONSULTORIA,
    MODAL_MOD_PERIODO_CONSULTORIA,
    MODAL_MOD_DUPLICAR_CONSULTORIA,
    MODAL_MOD_EDITAR_CONSULTORIA,
  } from "../actions/actConsultoria"
  
  const initialState = {
    modalDetalleConsultoria: false,
    modalGestionarConsultoria: false,
    modalAddConsultas: false,
    ModalAddFiltrosConsultoria:false,
    modalInformeConsultoria:false,
    modalModPeriodoConsultoria:false,
    modalDuplicarConsultoria:false,
    modalEditarConsultoria:false,
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
     
      case MODAL_DET_CONSULTORIA:
        return Object.assign({}, state, {
          modalDetalleConsultoria: action.show
        })
      case MODAL_ADD_CONSULTAS:
        return Object.assign({}, state, {
          modalAddConsultas: action.show
      })
      case MODAL_GES_CONSULTORIA:
        return Object.assign({}, state, {
          modalGestionarConsultoria: action.show
      })
      case MODAL_ADD_FILTROS_CONSULTORIA:
      return Object.assign({}, state, {
          ModalAddFiltrosConsultoria: action.show
      })
      case MODAL_INFORME_CONSULTORIA:
      return Object.assign({}, state, {
        modalInformeConsultoria: action.show
      })
      case MODAL_MOD_PERIODO_CONSULTORIA:
      return Object.assign({}, state, {
        modalModPeriodoConsultoria: action.show
      })
      case MODAL_MOD_DUPLICAR_CONSULTORIA:
      return Object.assign({}, state, {
        modalDuplicarConsultoria: action.show
      })
      case MODAL_MOD_EDITAR_CONSULTORIA:
      return Object.assign({}, state, {
        modalEditarConsultoria: action.show
      })
      default:
        return state;
    }
  }
  
  export default reducer;
  